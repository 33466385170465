import React from 'react';
import { Box, Typography } from '@mui/material';

export default function CustomerMessage({ customer, date, msg }) {
  return (
    <Box
      sx={{
        ml: customer ? '' : 'auto',
        mb: 2,
        maxWidth: '65%',
      }}
    >
      <Box
        sx={{
          py: 1,
          px: 2,
          mb: 1,
          ml: customer ? '' : 'auto',
          display: 'inline-block',
          background: customer ? '#fff' : '#2563eb',
          color: !customer ? '#fff' : '',
          borderRadius: '8px',
          boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
        }}
      >
        <Typography>{msg}</Typography>
      </Box>
      {date ? (
        <Typography sx={{ fontSize: '12px', color: '#666' }}>
          {new Date(date).toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Typography>
      ) : null}
    </Box>
  );
}
