import React from 'react';
import RequireAuth from '@components/core/RequireAuth';
import Layout from '@components/core/Layout';

export default function WithLayout({ comp: Component }) {
  return (
    <RequireAuth>
      {(userInfo) => (
        <Layout user={userInfo}>
          <Component />
        </Layout>
      )}
    </RequireAuth>
  );
}
