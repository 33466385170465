import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { FieldArray } from 'formik';
import ShortLinkAccordion from '@components/BPA-V2/Popups/CreateShortLinksPopup/ShortLinkAccordion';
import ShortLinkCard from '@components/BPA-V2/Popups/CreateShortLinksPopup/ShortLinkCard';
import {
  hasKeyIncludingSubstring,
  getShortLinkFields,
} from '@config/functions/createShortLinksPopupFunctions';

export default function ShortLinksList({
  shape,
  shapeAction,
  values,
  setValues,
  errors,
  setFieldValue,
  workflowId,
  workflowName,
  showAddReminder,
  setShowAddReminder,
}) {
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const deleteShortLink = (idx) => {
    const keysThatNeedsToBeModified = [];
    const beforeHandShortLinks = values.short_links.slice(0, idx);

    const newShortLinks = [
      ...beforeHandShortLinks,
      ...values.short_links.slice(idx + 1).map((short_link, eidx) => {
        const oldFields = getShortLinkFields(
          beforeHandShortLinks.length + eidx + 1
        );
        const fields = getShortLinkFields(beforeHandShortLinks.length + eidx);
        const response = {
          payload_section_key_reference:
            short_link.payload_section_key_reference,
        };

        Object.entries(oldFields).map(([key, value]) => {
          response[fields[key]] = short_link[value];
        });

        keysThatNeedsToBeModified.push([
          oldFields.short_link,
          fields.short_link,
        ]);

        return response;
      }),
    ];

    const newValues = { ...values, short_links: newShortLinks };

    keysThatNeedsToBeModified.map(([oldKey, newKey]) => {
      newValues[newKey] = values[oldKey];
      delete newValues[oldKey];
    });

    setValues(newValues);
  };

  return (
    <Box>
      <ShortLinkAccordion
        open={open}
        setOpen={handleToggle}
        label="Short Links"
        isError={hasKeyIncludingSubstring(errors, 'short_links')}
      >
        <FieldArray name="short_links">
          {({ push }) => (
            <Stack spacing={2}>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const fields = getShortLinkFields(
                      values?.short_links.length
                    );
                    const uniqueKey = uuidv4();

                    push({
                      [fields.name]: null,
                      [fields.destination_url]: null,
                      payload_section_key_reference: `payload_section_${uniqueKey}`,
                    });
                  }}
                >
                  Add New
                </Button>
              </Box>

              <Box>
                {values?.short_links?.map((shortLink, index) => (
                  <ShortLinkCard
                    index={index}
                    setFieldValue={setFieldValue}
                    remove={deleteShortLink}
                    workflowId={workflowId}
                    workflowName={workflowName}
                    showAddReminder={showAddReminder}
                    setShowAddReminder={setShowAddReminder}
                    shape={shape}
                    shapeAction={shapeAction}
                    values={values}
                    shortLink={shortLink}
                    errors={errors}
                  />
                ))}
              </Box>
            </Stack>
          )}
        </FieldArray>
      </ShortLinkAccordion>
    </Box>
  );
}
