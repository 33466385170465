import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import React from 'react';

export default function MultipleSelectField({
  options,
  value = [],
  setValue,
  isCorrect,
  category,
}) {
  const handleChange = (e, checkedId) => {
    const isChecked = e.target.checked;
    let updatedFormData = [...value];

    if (isChecked && !updatedFormData.includes(checkedId)) {
      updatedFormData.push(checkedId);
    } else if (!isChecked && updatedFormData.includes(checkedId)) {
      updatedFormData = updatedFormData.filter((id) => id !== checkedId);
    }

    setValue(updatedFormData);
  };

  return (
    <Box>
      {Array.isArray(options) ? (
        <FormControl component='fieldset' variant='standard'>
          <FormGroup>
            {options
              .sort((a, b) => a?.order - b?.order)
              .map(({ id, option }) => (
                <FormControlLabel
                  id={id}
                  control={
                    <Checkbox
                      size='small'
                      name={id}
                      checked={value.includes(id)}
                      onChange={(e) => handleChange(e, id)}
                    />
                  }
                  label={option}
                  sx={{ color: '#012970' }}
                />
              ))}
          </FormGroup>
        </FormControl>
      ) : null}

      {category === 'Quiz' && isCorrect ? (
        <Alert variant='filled' sx={{ mt: '12px' }} severity='success'>
          The selected option(s) are correct
        </Alert>
      ) : category === 'Quiz' && isCorrect === false ? (
        <Alert variant='filled' sx={{ mt: '12px' }} severity='error'>
          The selected option(s) are incorrect
        </Alert>
      ) : null}
    </Box>
  );
}
