import { listHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  countries: 'countries',
  cities: 'cities',
  postCodes: 'postcodes',
  currencies: 'currencies',
  socialMedias: 'social-medias',
  sicCodes: 'sic-codes',
  timeZones: 'timezones',
  countryCurrencies: 'countrycurrencies',
  languages: 'languages',
  countryLanguages: 'country-languages',
};

const countriesUrls = getModelUrls(listHost, models.countries);
const citiesUrls = getModelUrls(listHost, models.cities);
const postCodesUrls = getModelUrls(listHost, models.postCodes);
const currenciesUrls = getModelUrls(listHost, models.currencies);
const socialMediasUrls = getModelUrls(listHost, models.socialMedias);
const sicCodesUrls = getModelUrls(listHost, models.sicCodes);
const timeZonesUrls = getModelUrls(listHost, models.timeZones);
const countryCurrenciesUrls = getModelUrls(listHost, models.countryCurrencies);
const languagesUrls = getModelUrls(listHost, models.languages);

const countryLanguagesUrls = getModelUrls(listHost, models.countryLanguages);

const urls = {
  countriesUrls,
  citiesUrls,
  postCodesUrls,
  currenciesUrls,
  socialMediasUrls,
  sicCodesUrls,
  timeZonesUrls,
  countryCurrenciesUrls,
  languagesUrls,
  countryLanguagesUrls,
};

export default urls;
