import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Box,
} from '@mui/material';
import { pmUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { AutocompleteFieldV2 } from '@components/Inputs';
import useToast from '@hooks/useToast';
import { createRecord } from '@config/functions/requests';
// import RenderUiPrefix from '@components/core/RenderUiPrefix';
import { useQueryClient } from 'react-query';
import { formatDate } from '@config/functions/helperFunctions';

const { bulkCreateSprintBugsUrls, sprintMetaUrls, sprintsUrls } = pmUrls;

export default function AddBugToSprintDialogBox({ open, setOpen, bugs }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>
          Add Bugs to sprint
        </DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Formik
          initialValues={{
            sprintMeta: '',
            sprint: '',
          }}
          validationSchema={Yup.object({
            sprintMeta: Yup.mixed(),
            sprint: Yup.mixed(),
          })}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setFieldError }
          ) => {
            try {
              setSubmitting(true);

              if (!values?.sprint?.id) {
                setFieldError('sprint', 'Please select a sprint');
                return;
              }

              await createRecord({
                values: {
                  bugs: bugs.map((bug) => bug.id),
                  sprint: values?.sprint?.id,
                },
                url: bulkCreateSprintBugsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              queryClient.invalidateQueries(['bugs-board']);
              setOpen(false);
              notify('Bugs Added', {
                type: 'SUCCESS',
              });
            } catch (error) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form noValidate autoComplete="off" style={{ paddingTop: '6px' }}>
              <Grid sx={{ paddingBottom: '22px' }} container spacing={3}>
                <Grid item sm={12}>
                  <AutocompleteFieldV2
                    name="sprintMeta"
                    label="Sprint Meta"
                    requestKey="tasks-sprint-meta-list"
                    fetchUrl={sprintMetaUrls.list}
                  />
                </Grid>
                <Grid item sm={12}>
                  <AutocompleteFieldV2
                    name="sprint"
                    label="Sprint"
                    requestKey="tasks-sprint-list"
                    fetchUrl={sprintsUrls.list}
                    urlParams={`&ordering=start_date&sprint_meta=${
                      values?.sprintMeta?.id ?? ''
                    }`}
                    disabled={!values?.sprintMeta}
                    enabled={!!values?.sprintMeta?.id}
                    requestKeyOptions={[values?.sprintMeta?.id]}
                    renderRow={(r) => ({
                      id: r.id,
                      label: formatDate(new Date(r.start_date)),
                    })}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
