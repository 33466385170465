import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ecommerceUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import ecommerceHandleRows from '@config/handleRows/ecommerce';
import { PaymentForm } from '@config/forms/ecommerce';
import { paymentMethodMeta } from '@config/meta/ecommerce';
import DetailPageHeader from '@components/DetailPageHeader';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  paymentsUrls,
  cartsUrls,
  addressesUrls,
  paymentProvidersUrls,
  paymentMethodsUrls,
  paymentStatusesUrls,
} = ecommerceUrls;
const { paymentRowMap } = ecommerceHandleRows;

export default function PaymentMethodDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [cartTerm, setCartTerm] = useState([]);
  const [addressTerm, setAddressTerm] = useState([]);
  const [paymentProviderTerm, setPaymentProviderTerm] = useState([]);
  const [paymentMethodTerm, setPaymentMethodTerm] = useState([]);
  const [paymentStatusTerm, setPaymentStatusTerm] = useState([]);
  const [notify] = useToast();

  const {
    data: carts,
    isFetching: fetchingCarts,
    refetch: refetchCart,
  } = useAutocomplete({
    key: 'carts',
    getUrl: cartsUrls.list,
    inputValue: cartTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.customer && r.details && r.customer_details
            ? r.customer_details.first_name
            : r.customer,
      };
    },
  });

  const {
    data: addresses,
    isFetching: fetchingAddresses,
    refetch: refetchBilling,
  } = useAutocomplete({
    key: 'addresses',
    getUrl: addressesUrls.list,
    inputValue: addressTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.address1,
      };
    },
  });

  const {
    data: paymentProviders,
    isFetching: fetchingPaymentProviders,
    refetch: refetchPaymentProvider,
  } = useAutocomplete({
    key: 'paymentProviders',
    getUrl: paymentProvidersUrls.list,
    inputValue: paymentProviderTerm,
  });

  const {
    data: paymentMethods,
    isFetching: fetchingPaymentMethods,
    refetch: refetchPaymentMethod,
  } = useAutocomplete({
    key: 'paymentMethods',
    getUrl: paymentMethodsUrls.list,
    inputValue: paymentMethodTerm,
  });

  const {
    data: paymentStatuses,
    isFetching: fetchingPaymentStatuses,
    refetch: refetchPaymentStatus,
  } = useAutocomplete({
    key: 'paymentStatuses',
    getUrl: paymentStatusesUrls.list,
    inputValue: paymentStatusTerm,
  });

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => paymentMethodMeta({ data }),
    []
  );

  const paymentsColOptions = React.useMemo(() => {
    return {
      carts,
      fetchingCarts,
      setCartTerm,
      addresses,
      fetchingAddresses,
      setAddressTerm,
      paymentProviders,
      fetchingPaymentProviders,
      setPaymentProviderTerm,
      paymentMethods,
      fetchingPaymentMethods,
      setPaymentMethodTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
    };
  }, [
    carts,
    fetchingCarts,
    setCartTerm,
    addresses,
    fetchingAddresses,
    setAddressTerm,
    paymentProviders,
    fetchingPaymentProviders,
    setPaymentProviderTerm,
    paymentMethods,
    fetchingPaymentMethods,
    setPaymentMethodTerm,
    paymentStatuses,
    fetchingPaymentStatuses,
    setPaymentStatusTerm,
  ]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/payment-methods`, text: 'Payment Methods' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={paymentMethodsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice="E-Commerce"
          model="Payment"
          columnKey="paymentEcommerce"
          columnOptions={paymentsColOptions}
          label="Payments"
          prefix="0yf"
          labels={labels}
          editMode={editMode}
          urls={paymentsUrls}
          addNewForm={{
            getFields: () =>
              PaymentForm({
                carts,
                fetchingCarts,
                setCartTerm,
                addresses,
                fetchingAddresses,
                setAddressTerm,
                paymentProviders,
                fetchingPaymentProviders,
                setPaymentProviderTerm,
                paymentMethods,
                fetchingPaymentMethods,
                setPaymentMethodTerm,
                paymentStatuses,
                fetchingPaymentStatuses,
                setPaymentStatusTerm,
                refetchCart,
                refetchBilling,
                refetchPaymentProvider,
                refetchPaymentMethod,
                refetchPaymentStatus,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: {
                    ...values,
                    payment_method: recordId,
                  },
                  url: paymentsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                // notify(`Payment Created!`, {
                //   type: 'SUCCESS',
                // });
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={paymentRowMap}
          urlParams={`&payment_method=${recordId}`}
        />
      </Box>
    </Box>
  );
}
