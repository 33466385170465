export const channelRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,

  created_at: row?.created_at,
});

export const callHistoryRowsMap = (row) => ({
  id: row?.id,
  outcome: row?.outcome,
  call_list_pipeline_stage: row?.details?.call_list_pipeline_stage
    ? {
        id: row?.call_list_pipeline_stage,
        label: row?.details.call_list_pipeline_stage.name,
      }
    : null,
  created_at: row?.created_at,
});

export const callScheduleRowsMap = (row) => ({
  id: row?.id,
  schedule_datetime: row?.schedule_datetime
    ? new Date(row?.schedule_datetime)
    : null,
  person: row?.details?.person
    ? {
        id: row?.person,
        label: `${row?.details.person?.first_name || 'Unamed'} ${
          row?.details.person?.last_name || ''
        } (${row?.details.person?.email || ''})`,
      }
    : null,
  call_list_pipeline_stage: row?.details?.call_list_pipeline_stage
    ? {
        id: row?.call_list_pipeline_stage,
        label: row?.details.call_list_pipeline_stage.name,
      }
    : null,
  created_at: row?.created_at,
});

export const callListPipelineStagesRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  order: row?.order,
  rotting_days: row?.rotting_days,
  created_at: row?.created_at,
});

export const callListPipelinesRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  created_at: row?.created_at,
});

export const callListsRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  call_list_pipeline: row?.details?.call_list_pipeline
    ? {
        id: row?.call_list_pipeline,
        label: row?.details.call_list_pipeline.name,
      }
    : null,
  description: row?.description,
  created_at: row?.created_at,
});

export const territoryCompaniesRowsMap = (row) => ({
  id: row?.id,
  name: row?.details?._company?.name,
  description: row?.details?._company?.description,
  phone: row?.details?._company?.phone,
  website: row?.details?._company?.website,
  news_url: row?.details?._company?.news_url,
  staff_url: row?.details?._company?.staff_url,
  beta_partners: row?.details?._company?.beta_partners,
  branch_of: row?.details?.branch_of
    ? { id: row?.branch_of, label: row?.details.branch_of?.name ?? '' }
    : null,
  address1: row?.details?._company?.address1,
  address2: row?.details?._company?.address2,
  city: row?.details?._company?.city,
  country: row?.details?.country
    ? { id: row?.country, label: row?.details.country.name }
    : null,
  _tags: row?.details?._company?._tags,
  _color: row?.details?._company?._color,
  created_at: row?.created_at,
});

export const marketingListPersonsRowsMap = (row) => ({
  id: row?.id,
  personId: row?.person,
  processed: !!row?.workflow_session,
  first_name: row?.details?.person?.first_name,
  last_name: row?.details?.person?.last_name,
  email: row?.details?.person?.email,
  personal_mobile: row?.details?.person?.personal_mobile,
  country: row?.details?.person?.details?.country
    ? {
        id: row?.details?.person?.country,
        label: row?.details?.person?.details.country?.name,
      }
    : null,
  company_owner:
    row?.details && row?.details.company_owner
      ? { id: row?.company_owner, label: row?.details.company_owner.name }
      : null,
  city: row?.details?.person?.city,
  _color: row?.details?.person?._color,
  tags: row?.details?.person?.tags,
  created_at: row?.created_at,
});

export const personCompanyRowsMap = (row) => ({
  id: row?.id,
  work_email: row?.work_email,
  job_title: row?.job_title,
  work_phone: row?.work_phone,
  work_mobile: row?.work_mobile,
  company_id: row?.company_id,
  start_date: row?.start_date ? new Date(row?.start_date) : null,
  end_date: row?.end_date ? new Date(row?.end_date) : null,
  company_name: row?.details?.company_id?.name,
  company_phone: row?.details?.company_id?.phone,
  company_website: row?.details?.company_id?.website,
  company_news_url: row?.details?.company_id?.news_url,
  company_staff_url: row?.details?.company_id?.staff_url,
});

export const callRecordingsRowsMap = (row) => ({
  id: row?.id,
  call_from: row?.call_from,
  call_to: row?.call_to,
  recorded_file_url: row?.recorded_file_url,
  person: row?.details?.record_id
    ? `${row?.details?.record_id?.first_name || 'Unamed'} ${
        row?.details?.record_id?.last_name || ''
      } (${row?.details?.record_id?.email || ''})`
    : null,
  called_at: row?.called_at,
});

export const actionRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  created_at: row?.created_at,
});

export const activityNotesRowsMap = (row) => ({
  id: row?.id,
  kind:
    row?.details && row?.details.kind
      ? { id: row?.kind, label: row?.details.kind.name }
      : null,
  type:
    row?.details && row?.details.type
      ? { id: row?.type, label: row?.details.type.name }
      : null,
  notes: row?.notes,
  ina: row?.ina,
  created_at: row?.created_at,
});

export const salesPersonRowsMap = (row) => ({
  id: row?.id,
  sales_person:
    row?.details && row?.details.sales_person
      ? { id: row?.sales_person, label: row?.details.sales_person.work_email }
      : null,
  pipeline:
    row?.details && row?.details.pipeline
      ? { id: row?.pipeline, label: row?.details.pipeline.name }
      : null,
  pipeline_stage:
    row?.details && row.details?.pipeline_stage
      ? {
          id: row?.pipeline_stage,
          label: `${Number(row.details?.pipeline_stage?.order).toFixed(2)} ${
            row.details.pipeline_stage?.stage
          }`,
        }
      : null,
  expiry_date: row?.expiry_date ? new Date(row?.expiry_date) : null,
  target: row?.target,
  target_unit: row?.target_unit,
  notes: row?.notes,
  created_at: row?.created_at,
});

export const territoryOwnersRowsMap = (row) => ({
  id: row?.id,
  sales_person:
    row?.details && row?.details.sales_person
      ? { id: row?.sales_person, label: row?.details.sales_person.work_email }
      : null,
  expiry_date: row?.expiry_date ? new Date(row?.expiry_date) : null,
  created_at: row?.created_at,
});

export const companyTerritoriesRowsMap = (row) => ({
  id: row?.id,
  territory:
    row?.details && row?.details.territory
      ? { id: row?.territory, label: row?.details.territory.name }
      : null,
  expiry_date: row?.expiry_date ? new Date(row?.expiry_date) : null,
  created_at: row?.created_at,
});

export const accountManagersRowsMap = (row) => ({
  id: row?.id,
  account_manager: row?.details?.account_manager
    ? {
        id: row?.account_manager,
        label: row?.details.account_manager?.work_email,
      }
    : null,
  expiry_date: row?.expiry_date ? new Date(row?.expiry_date) : null,
  created_at: row?.created_at,
});

export const marketingListsRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  workflow: row?.details?.workflow
    ? {
        id: row?.workflow,
        label: row.details.workflow?.name,
      }
    : null,
  expiry_date: row?.expiry_date ? new Date(row?.expiry_date) : null,
  created_at: row?.created_at,
});

export const territoriesRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  expiry_date: row?.expiry_date ? new Date(row?.expiry_date) : null,
  created_at: row?.created_at,
});

export const socialMediaTypesRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  created_at: row?.created_at,
});

export const importLogsRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  model: row?.model,
  imported: row?.imported,
  failed: row?.failed,
  finished: `${row?.finished}%`,
  started_at: row?.created_at ? new Date(row?.created_at) : null,
  finished_at: row?.finished_at ? new Date(row?.finished_at) : null,
  created_at: row?.created_at,
});

export const importErrorLogsRowsMap = (row) => ({
  id: row?.id,
  row: row?.row,
  field: row?.field,
  error: row?.error,
  created_at: row?.created_at,
});

export const companiesRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  phone: row?.phone,
  website: row?.website,
  news_url: row?.news_url,
  state: row?.state,
  staff_url: row?.staff_url,
  beta_partners: row?.beta_partners,
  branch_of:
    row?.details && row?.details.branch_of
      ? { id: row?.branch_of, label: row?.details.branch_of?.name ?? '' }
      : null,
  address1: row?.address1,
  address2: row?.address2,
  zip: row?.zip,
  city: row?.city,
  country:
    row?.details && row?.details.country
      ? { id: row?.country, label: row?.details.country.name }
      : null,
  _tags: row?._tags,
  _color: row?._color,
  created_at: row?.created_at,
});

export const personsRowsMap = (row) => ({
  id: row?.id,
  first_name: row?.first_name,
  last_name: row?.last_name,
  email: row?.email,
  state: row?.state,
  personal_mobile: row?.personal_mobile,
  country: row?.details?.country
    ? { id: row?.country, label: row?.details.country?.name }
    : null,
  company_owner:
    row?.details && row?.details.company_owner
      ? { id: row?.company_owner, label: row?.details.company_owner.name }
      : null,
  zip: row?.zip,
  city: row?.city,
  _color: row?._color,
  tags: row?.tags,
  source_notes: row?.source_notes,
  source: row?.source,
  created_at: row?.created_at,
});

export const historyRowsMap = (row) => ({
  id: row?.id,
  history: row?.history,
  notes: row?.notes,
  created_at: row?.created_at,
});

export const personHistoryRowsMap = (row) => ({
  id: row?.id,
  history: row?.history,
  notes: row?.notes,
  created_at: row?.created_at,
});

export const sicCodeRowsMap = (row) => ({
  id: row?.id,
  sic_code: row?.sic_code,
  created_at: row?.created_at,
});

export const personSocialMediaRowsMap = (row) => ({
  id: row?.id,
  social_media:
    row?.details && row?.details.social_media
      ? { id: row?.social_media, label: row?.details.social_media.name }
      : null,
  username: row?.username,
  url: row?.url,
  created_at: row?.created_at,
});

export const companyOfficeRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  business_phone: row?.business_phone,
  country:
    row?.details && row?.details.country
      ? { id: row?.country, label: row?.details.country.name }
      : null,
  city: row?.city,
  tags: row?.tags,
  created_at: row?.created_at,
});

export const companyContactRowsMap = (row) => ({
  id: row?.id,
  work_email: row?.work_email,
  work_phone: row?.work_phone,
  work_mobile: row?.work_mobile,
  job_title: row?.job_title,
  start_date: row?.start_date ? new Date(row?.start_date) : null,
  end_date: row?.end_date ? new Date(row?.end_date) : null,
  personId: row?.person ?? null,
  person: row?.details?.person
    ? {
        id: row?.person,
        label: `${row?.details.person?.first_name || 'Unamed'} ${
          row?.details.person?.last_name || ''
        }`,
      }
    : null,
  companyId: row?.details?.company_id
    ? { id: row?.company_id, label: row?.details.company_id.name }
    : null,
  accounts: row?.accounts,
  created_at: row?.created_at,
});

export const territoryCompanyContactRowsMap = (row) => ({
  id: row?.person,
  work_email: row?.work_email,
  work_phone: row?.work_phone,
  work_mobile: row?.work_mobile,
  job_title: row?.job_title,
  start_date: row?.start_date ? new Date(row?.start_date) : null,
  end_date: row?.end_date ? new Date(row?.end_date) : null,
  person: row?.details?.person
    ? { id: row?.person, label: row?.details.person.email }
    : null,
  companyId: row?.details?.company_id
    ? { id: row?.company_id, label: row?.details.company_id.name }
    : null,
  accounts: row?.accounts,
  created_at: row?.created_at,
});

export const reminderRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  do_what: row?.details?.do_what
    ? { id: row?.do_what, label: row?.details.do_what?.name }
    : null,
  responsible: row?.details?.responsible
    ? { id: row?.responsible, label: row?.details.responsible?.email }
    : null,
  task_id: row?.details?.task_id
    ? { id: row?.task_id, label: row?.details.task_id?.name }
    : null,
  event_id: row?.details?.event_id
    ? { id: row?.event_id, label: row?.details.event_id?.name }
    : null,
  pipeline: row?.details?.pipeline
    ? { id: row?.pipeline, label: row?.details.pipeline?.name }
    : null,
  status: row?.details?.status
    ? { id: row?.status, label: row?.details.status?.name }
    : null,
  created_at: row?.created_at,
});

export const opportunitiesRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  sentiment: row?.sentiment,
  company: row?.details?.company
    ? { id: row?.company, label: row?.details.company.name }
    : null,
  customer_priority: row?.customer_priority,
  estimated_value: row?.estimated_value,
  estimated_close_date: row?.estimated_close_date,
  data_source: row?.data_source,
  probability: row?.details?.status?.confidence ?? 0,
  channel: row?.details?.channel
    ? {
        id: row?.channel,
        label: row.details.channel?.name ?? '',
      }
    : null,
  status:
    row?.details && row.details?.status
      ? {
          id: row?.status,
          label: `${Number(row.details?.status?.order).toFixed(2)} ${
            row.details.status?.stage
          }`,
        }
      : null,
  company_contact: row?.person_detail?.person
    ? {
        id: row?.company_contact,
        label: `${row?.person_detail.person?.first_name ?? ''} ${
          row?.person_detail.person?.last_name ?? ''
        }`,
      }
    : null,
  pipeline:
    row?.details && row?.details.pipeline
      ? { id: row?.pipeline, label: row?.details.pipeline.name }
      : null,
  _color: row?._color,
  owner:
    row?.details && row?.details.owner
      ? {
          id: row?.owner,
          label: row?.details.owner.work_email ?? '',
        }
      : null,
  actual_value: row?.actual_value,
  created_at: row?.created_at,
});

export const clientsRowsMap = (row) => ({
  id: row?.id,
  company_contact:
    row?.person_detail && row?.person_detail.person
      ? {
          id: row?.company_contact,
          label: row?.person_detail.person.email,
        }
      : null,
  opportunity:
    row?.details && row?.details.opportunity
      ? {
          id: row?.opportunity,
          label: row?.details.opportunity.name,
        }
      : null,
  notes: row?.notes,
  created_at: row?.created_at,
});

export const opportunityInfluencerRowsMap = (row) => ({
  id: row?.id,
  company_contact:
    row?.person_detail && row?.person_detail.person
      ? {
          id: row?.company_contact,
          label: row?.person_detail.person.email,
        }
      : null,
  role: row?.role,
  desire_for_company: row?.desire_for_company,
  desire_for_self: row?.desire_for_self,
  rating: row?.rating,
  created_at: row?.created_at,
});

export const opportunityProductRowsMap = (row) => ({
  id: row?.id,
  product_variant:
    row?.details && row?.details.product_variant
      ? {
          id: row?.product_variant,
          label: row?.details.product_variant.name,
        }
      : null,
  amount: row?.amount,
  estimated_value: row?.estimated_value,
  created_at: row?.created_at,
});

export const relationshipsRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  created_at: row?.created_at,
});

export const personRelationshipsRowsMap = (row) => ({
  id: row?.id,
  relationship:
    row?.details && row?.details.relationship
      ? { id: row?.relationship, label: row?.details.relationship.name }
      : null,
  created_at: row?.created_at,
});

export const pipelinesRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  created_at: row?.created_at,
});

export const personRelationshipHistoriesRowsMap = (row) => ({
  id: row?.id,
  notes: row?.notes,
  created_at: row?.created_at,
});

export const pipelineStagesRowsMap = (row) => ({
  id: row?.id,
  stage: row?.stage,
  description: row?.description,
  order: row?.order,
  rotting_days: row?.rotting_days,
  immediate_next_action: row?.immediate_next_action,
  confidence: row?.confidence,
  conversion: row?.conversion,
  created_at: row?.created_at,
});

export const companySocialMediaRowsMap = (row) => ({
  id: row?.id,
  social_media:
    row?.details && row?.details.social_media
      ? { id: row?.social_media, label: row?.details.social_media.name }
      : null,
  url: row?.url,
  created_at: row?.created_at,
});

export const dataNeededRowsMap = (row) => ({
  id: row?.id,
  info_needed: row?.info_needed,
  who_from: row?.who_from,
  notes: row?.notes,
  created_at: row?.created_at,
});

export const actionPlanRowsMap = (row) => ({
  id: row?.id,
  what: row?.what,
  who: row?.who,
  when: row?.when ? new Date(row?.when) : null,
  created_at: row?.created_at,
});

export const opportunityHistoryRowsMap = (row) => ({
  id: row?.id,
  url: row?.url,
  notes: row?.notes,
  created_at: row?.created_at,
});

export const companySpinsRowsMap = (row) => ({
  id: row?.id,
  situation: row?.situation,
  problem: row?.problem,
  implication: row?.implication,
  need: row?.need,
  _company:
    row?.details && row?.details._company
      ? { id: row?._company, label: row?.details._company.name }
      : null,
  buyer_influence: row?.buyer_influence,
  notes: row?.notes,
  created_at: row?.created_at,
});

export const inasRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  notes: row?.ina_notes,
  progress: row?.progress,
  reminder_start_datetime: row?.details && row?.details?.event?.start_date_time,
  responsible:
    row?.details && row?.details.responsible
      ? { id: row?.responsible, label: row?.details.responsible.work_email }
      : null,
  do_what:
    row?.details && row?.details.do_what
      ? { id: row?.do_what, label: row?.details.do_what.name }
      : null,
  created_at: row?.created_at,
});

export const customerEnquiryStatusRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  created_at: row?.created_at,
});

export const customerEnquiryPurposeRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  created_at: row?.created_at,
});

export const customerEnquiryRowsMap = (row) => ({
  id: row?.id,
  person: row?.details?.person
    ? {
        id: row?.person,
        label: `${row?.details.person?.first_name || 'Unamed'} ${
          row?.details.person?.last_name || ''
        } (${row?.details.person?.email || ''})`,
      }
    : null,
  status: row?.details?.status
    ? {
        id: row?.status,
        label: row?.details.status?.name || 'Unamed',
      }
    : null,
  purpose: row?.details?.purpose
    ? {
        id: row?.purpose,
        label: row?.details.purpose?.name || 'Unamed',
      }
    : null,
  first_name: row?.first_name,
  email: row?.details?.person ? row?.details.person?.email : '',
  last_name: row?.last_name,
  phone: row?.phone,
  message: row?.message,
  source: row?.source,
  source_notes: row?.source_notes,
  created_at: row?.created_at,
});
