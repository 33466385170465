import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import InasBoard from '@pages/KanBan/InasBoard';
import BpaBoard from '@pages/KanBan/BpaBoard';
import ManualBpaBoard from '@pages/KanBan/BpaBoard/manual';
import TasksBoard from '@pages/KanBan/TasksBoard';
import SprintTasksBoard from '@pages/KanBan/SprintTasksBoard';
import UATsBoard from '@pages/KanBan/UATsBoard';
import BugsBoard from '@pages/KanBan/BugsBoard';
import VacanciesBoard from '@pages/KanBan/VacanciesBoard';
import OpportunitiesBoard from '@pages/KanBan/OpportunitiesBoard';
import RoomsBoard from '@pages/KanBan/RoomsBoard';
import MeBoard from '@pages/KanBan/MeBoard';
import RemindersBoard from '@pages/KanBan/RemindersBoard';
import CallListsBoard from '@pages/KanBan/CallListsBoard';

export default function KanbanRoutes() {
  return (
    <>
      <Route
        path='/call-lists-board'
        element={<WithLayout comp={CallListsBoard} />}
      />
      <Route
        path='/reminders-board'
        element={<WithLayout comp={RemindersBoard} />}
      />
      <Route path='/inas-board' element={<WithLayout comp={InasBoard} />} />
      <Route path='/tasks-board' element={<WithLayout comp={TasksBoard} />} />
      <Route
        path='/sprint-tasks-board'
        element={<WithLayout comp={SprintTasksBoard} />}
      />
      <Route path='/uats-board' element={<WithLayout comp={UATsBoard} />} />
      <Route path='/bugs-board' element={<WithLayout comp={BugsBoard} />} />
      <Route
        path='/vacancies-board'
        element={<WithLayout comp={VacanciesBoard} />}
      />
      <Route
        path='/opportunities-board'
        element={<WithLayout comp={OpportunitiesBoard} />}
      />
      <Route path='/rooms-board' element={<WithLayout comp={RoomsBoard} />} />
      <Route path='/bpa-board' element={<WithLayout comp={BpaBoard} />} />
      <Route
        path='/manual-bpa-board'
        element={<WithLayout comp={ManualBpaBoard} />}
      />
      <Route path='/me-board' element={<WithLayout comp={MeBoard} />} />
    </>
  );
}
