import React from 'react';
import * as Yup from 'yup';
import { hrUrls } from '@config/routes';
import { dayOffTypesMeta } from '@config/meta/hr';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { dayOffTypesUrls } = hrUrls;

export default function DayOffTypeDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title='Day Off Types'
        initialValues={{
          ...getAddFormInitialValues(dayOffTypesMeta({}), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(dayOffTypesMeta({}), 'listView'),
        })}
        getPostUrl={() => dayOffTypesUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key='name' label='Name' name='name' required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                key='description'
                label='Description'
                name='description'
              />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
