import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { logsUrls } from '@config/routes';
import { importLogsRowsMap } from '@config/handleRows/logs';
import { importLogMeta } from '@config/meta/logs/logsMeta';
import { microservices } from '@config/constants';

const { importLogsUrls } = logsUrls;

export default function ImportLogs({ labels, editMode }) {


  return (
    <Box>
      <ListView
        label='Import Logs'
        prefix='0rw'
        labels={labels}
        editMode={editMode}
        microservice={microservices.LOGS.name}
        model={microservices.LOGS.models.importLogs}
        urls={importLogsUrls}
        columns={importLogMeta()}
        handleRowsMap={importLogsRowsMap}
        importExport={false}
        handleFunctions={() => null}
        showVisibility={false}
        hideActions={true}
      />
    </Box>
  );
}
