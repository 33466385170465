import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { computeUrls } from '@config/routes';
import { menusMeta } from '@config/meta/compute';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { menuDefnRowsMap } from '@config/handleRows/compute';
import { MenusForm } from '@config/forms/compute';
import { microservices } from '@config/constants';

const { menuDefnsUrls } = computeUrls;

export default function MenuDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [menuListData, setMenuListData] = React.useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => menusMeta({ data, microserviceId: data?.microserviceId }),
    [recordId]
  );

  const subMenuColOptions = React.useMemo(
    () => ({
      microserviceId: recordData?.microserviceId,
      hideParentGroupColumn: true,
      nextOrder: menuListData?.totalCount ?? 1,
    }),
    [menuListData?.totalCount, recordData?.microserviceId]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/microservice/${recordData.microserviceId}`,
                text: 'Menus',
              },
              {
                text: `${recordData?.order}. ${
                  recordData?.model?.name ?? 'No name'
                }`,
              },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={menuDefnsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Submenus'
          prefix='UYV'
          microservice={microservices.Compute.name}
          model={microservices.Compute.models.menuDefn}
          columnKey='menusCompute'
          columnOptions={subMenuColOptions}
          urls={menuDefnsUrls}
          addNewForm={{
            getFields: () =>
              MenusForm({
                microserviceId: recordData?.microserviceId,
                hideParentField: true,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { modelId, ...rest } = values;

                if (!modelId?.id) {
                  setFieldError('modelId', 'Please select a model.');
                  return;
                }

                await createRecord({
                  values: {
                    ...rest,
                    modelId: modelId?.id,
                    parentMenuId: recordId,
                    microserviceId: recordData?.microserviceId,
                  },
                  url: menuDefnsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={menuDefnRowsMap}
          urlParams={`&parentMenuId=${recordId}`}
          customSortColumnName='order'
          sortColumnBy='asc'
          hideSearch
          getListData={(data) => setMenuListData(data)}
        />
      </Box>
    </Box>
  );
}
