import React from 'react';
import axios from 'axios';
import { Box, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import TaskRecurringMessage from '@components/PM/TaskRecurringMessage';
import CustomRecurranceModal from '@components/Calendar/CustomRecurranceModal';
import RebaseDialogBox from '@components/PM/RebaseDialogBox';
import RebasePastErrorMessageDialogBox from '@components/PM/RebasePastErrorMessage';
import { nodeDriveUrls, pmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { tasksMeta } from '@config/meta/pm/pmMeta';
import {
  taskBacklogRowsMap,
  backlogRowsMap,
  taskBugRowsMap,
  bugRowsMap,
  taskCommentRowsMap,
} from '@config/handleRows/pm';
import DetailAccordionView from '@components/DetailAccordionView';
import UIAutocompleteField from '@ui/Inputs/AutocompleteField';
import ProjectTasksAccordion from '@components/ProjectTasksAccordion';
import useAutocomplete from '@hooks/useAutocomplete';
import { microservices } from '@config/constants';
import { TaskCommentForm } from '@config/forms/pm';
import { createRecord, updateRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { selectRebase } from '@redux/pmRebaseSlice';

const {
  tasksUrls,
  taskBacklogsUrls,
  hlrsUrls,
  backlogsUrls,
  taskBugsUrls,
  bugsUrls,
  taskCommentsUrls,
} = pmUrls;
const { filesUrls } = nodeDriveUrls;

export default function TasksDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const pmRebase = useSelector(selectRebase);
  const [recordData, setRecordData] = React.useState(null);
  const [hlrsTerm, setHLRsTerm] = React.useState('');
  const [activeHLR, setActiveHLR] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [notify, update] = useToast();
  const [rebasePopup, setRebasePopup] = React.useState(false);
  const [resolveValues, setResolveValues] = React.useState(null);

  const [taskRecurringMessageOpen, setTaskRecurringMessageOpen] =
    React.useState(false);

  const [recurrenceOpen, setRecurrenceOpen] = React.useState(false);

  const { data: hlrs, isFetching: fetchingHLRs } = useAutocomplete({
    key: ['hlrs', recordData],
    getUrl: hlrsUrls.list,
    inputValue: hlrsTerm,
    query:
      recordData && recordData.project ? `&project=${recordData.project}` : '',
  });

  const handleRecurringTask = async (newRrule, by) => {
    try {
      const response = await updateRecord({
        values: {
          rrule: newRrule,
          by: by ?? 'deadline',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        url: tasksUrls.detail(recordData?.id),
        token: user.token,
        actAs: user?.actAs,
      });
      setRecurrenceOpen(false);
      notify('Operation completed!', {
        type: 'SUCCESS',
      });
      queryClient.invalidateQueries([`${recordId}-record-details`]);
      queryClient.invalidateQueries([`Subtasks-listData`]);
      return response?.data?.id;
    } catch (err) {
      console.log(err);
      notify('There was an error, please try again', {
        type: 'ERROR',
      });
    }
  };

  const getValues = (values) => {
    if (
      (values?.started &&
        values?.started < (values?.deadline || recordData?.deadline)) ||
      (values?.deadline &&
        values?.deadline > (values?.started || recordData?.started))
    ) {
      return new Promise((resolve) => {
        setResolveValues(() => resolve);
        setRebasePopup(true);
      });
    }

    return Promise.resolve(values);
  };

  const onPopupSubmit = (valuesFromPopup) => {
    if (resolveValues) {
      resolveValues(valuesFromPopup);
      setResolveValues(null);
      setRebasePopup(false);
    }
  };

  const taskBacklogActions = React.useMemo(
    () => ({
      handleAddTaskBacklog: async (
        selectedRows,
        refetch = () => null,
        setSelectedRows
      ) => {
        try {
          const toastId = notify('Adding to task backlogs', {
            type: 'INFO',
          });
          const requests = selectedRows.map((row) =>
            axios.post(
              taskBacklogsUrls.list(),
              {
                task: recordId,
                backlog: row,
              },
              getAuthHeader(user.token, user?.actAs)
            )
          );
          await Promise.allSettled(requests);
          update(toastId, 'Backlog(s) added to the task', {
            type: 'SUCCESS',
          });
          setSelectedRows([]);
          queryClient.invalidateQueries('Task-Backlogs-listData');
          refetch();
        } catch (err) {
          console.log(err);
          notify(`There was an error, please try again.`, {
            type: 'ERROR',
          });
        }
      },
    }),
    [notify, update, recordId, queryClient, user.token]
  );

  const taskBugActions = React.useMemo(
    () => ({
      handleAddTaskBug: async (
        selectedRows,
        refetch = () => null,
        setSelectedRows
      ) => {
        try {
          const tId = notify('Adding to task bug(s)', {
            type: 'INFO',
          });

          const requests = selectedRows.map((row) =>
            axios.post(
              taskBugsUrls.list(),
              {
                task: recordId,
                bug: row,
              },
              getAuthHeader(user.token, user?.actAs)
            )
          );

          await Promise.allSettled(requests);

          update(tId, 'Bugs(s) added to the task', {
            type: 'SUCCESS',
          });
          setSelectedRows([]);
          queryClient.invalidateQueries('Task-Bugs-listData');
          refetch();
        } catch (err) {
          notify('There was an error, please try again', {
            type: 'ERROR',
          });
        }
      },
    }),
    [notify, update, recordId, queryClient, user.token]
  );

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      tasksMeta({
        data,
        subTasks: data?.sub_tasks,
        queryClient,
        projectId: recordData?.project,
        projectName: recordData?.details?.project?.name,
      }),
    []
  );

  const taskBugsColOptions = React.useMemo(() => {
    return {
      queryClient,
      projectId: recordData?.project?.id,
      showTask: false,
    };
  }, []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/projects/${recordData.project}`, text: 'Tasks' },
              { text: recordData?.name },
            ]}
          >
            {recordData?.id ? (
              <Button
                variant='outlined'
                sx={{ mr: 2 }}
                onClick={() => {
                  if (
                    !recordData?.deadline ||
                    !(
                      recordData?.started ||
                      (recordData?.duration_estimate &&
                        recordData?.duration_actual)
                    )
                  ) {
                    setTaskRecurringMessageOpen(true);
                  } else {
                    // setTaskRecurringMessageOpen(true);
                    setRecurrenceOpen(true);
                  }
                }}
              >
                Recurring
              </Button>
            ) : null}
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={tasksUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
        getValues={getValues}
        onError={(err) => {
          if (err?.response?.data?.started) {
            notify(err?.response?.data?.started, { type: 'ERROR' });
          } else if (err?.response?.data?.deadline) {
            notify(err?.response?.data?.deadline, { type: 'ERROR' });
          } else {
            notify('There was an error, please try again.', { type: 'ERROR' });
          }
        }}
        catchRebasingError={true}
        debounceDelay={0}
      />

      <Box sx={{ mt: 5 }}>
        <ProjectTasksAccordion
          projectId={recordData && recordData.project}
          label='Subtasks'
          prefix='03v'
          labels={labels}
          editMode={editMode}
          getValues={(values) => ({
            ...values,
            project: recordData && recordData.project,
            task_type: values?.task_type?.id,
            owner: values?.owner?.id,
            status: values?.status?.id,
            predecessor: values?.predecessor?.id,
            parent_task: recordId,
          })}
          programId={
            recordData && recordData.details.project
              ? recordData.details.project.program
              : null
          }
          urlParams={`&parent_task=${recordId}`}
        />

        <DetailAccordionView
          microservice={microservices.PM.name}
          model={microservices.PM.models.taskComment}
          columnKey='taskCommentPM'
          label='Task Comments'
          prefix='0e0'
          labels={labels}
          editMode={editMode}
          urls={taskCommentsUrls}
          addNewForm={{
            getFields: ({ values, errors }) =>
              TaskCommentForm({ values, errors }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                let file = values.attachment;
                if (file) {
                  const formData = new FormData();
                  formData.append('anonymous_can_see_it', true);
                  formData.append('file', file);

                  const { data: fileData } = await createRecord({
                    values: formData,
                    url: filesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  file = fileData.fileUrl;
                }

                const response = await createRecord({
                  values: {
                    ...values,
                    attachment: file,
                    task: recordId,
                  },
                  url: taskCommentsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={taskCommentRowsMap}
          urlParams={`&task=${recordId}`}
        />

        <DetailAccordionView
          columnKey='taskBacklogPM'
          label='Task Backlogs'
          prefix='0dm'
          labels={labels}
          editMode={editMode}
          urls={taskBacklogsUrls}
          handleRowMap={taskBacklogRowsMap}
          urlParams={`&task=${recordId}`}
        />

        <DetailAccordionView
          label='Backlogs'
          prefix='0jy'
          labels={labels}
          editMode={editMode}
          urls={backlogsUrls}
          handleRowMap={backlogRowsMap}
          startFetching={activeHLR}
          handleFunctions={taskBacklogActions}
          columnKey='taskBacklogPM'
          headerContent={() => (
            <Box sx={{ width: 300 }}>
              <UIAutocompleteField
                key='hlrs'
                label='Select a HLR'
                options={hlrs}
                name='hlrs'
                value={activeHLR}
                optionLabel='label'
                isLoading={fetchingHLRs}
                inputValue={inputValue}
                setValue={(value) => {
                  setActiveHLR(value);
                  queryClient.invalidateQueries('Backlogs-listData');
                }}
                setInputValue={(term) =>
                  term ? setInputValue(term) : setInputValue('')
                }
                updateData={(term) => setHLRsTerm(term)}
              />
            </Box>
          )}
          urlParams={`&hlr=${
            activeHLR ? activeHLR.id : ''
          }&hideBacklogsThatAreInTaskBacklogs=true`}
        />
        <DetailAccordionView
          columnKey='taskBugPM'
          columnOptions={taskBugsColOptions}
          label='Task Bugs'
          prefix='068'
          labels={labels}
          editMode={editMode}
          urls={taskBugsUrls}
          handleRowMap={taskBugRowsMap}
          urlParams={`&task=${recordId}`}
        />
        <DetailAccordionView
          label='Bugs'
          prefix='0ev'
          labels={labels}
          editMode={editMode}
          urls={bugsUrls}
          handleRowMap={bugRowsMap}
          handleFunctions={taskBugActions}
          columnKey='taskBugPM'
          columnOptions={taskBugsColOptions}
          urlParams={'&hideBugsThatAreInTaskBugs=true'}
        />
      </Box>

      <TaskRecurringMessage
        open={taskRecurringMessageOpen}
        setOpen={setTaskRecurringMessageOpen}
      />

      {recordData?.id ? (
        <CustomRecurranceModal
          open={recurrenceOpen}
          setOpen={setRecurrenceOpen}
          labels={labels}
          editMode={editMode}
          prefix='02u'
          rrule={recordData?.rrule}
          oSave={handleRecurringTask}
          readOnly={Boolean(recordData?.rrule)}
          taskRecurring={true}
          customFormProps={(formProps) => {
            return {
              ...formProps,
              ends: {
                ...formProps.ends,
                neverEnabled: false,
                unitlEnabled: false,
                countMax: 50,
                default: 'after',
              },
            };
          }}
        />
      ) : null}

      {rebasePopup ? (
        <RebaseDialogBox
          open={rebasePopup}
          task
          onPopupSubmit={onPopupSubmit}
        />
      ) : null}

      <RebasePastErrorMessageDialogBox
        task
        labels={labels}
        editMode={editMode}
        open={pmRebase.detail.open}
        err={pmRebase.detail.error}
      />
    </Box>
  );
}
