import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function DeleteRecurringEventModal({
  open,
  disabled,
  initialDeleteType = 'this_event',
  onCancelClick = () => null,
  onDeleteClick = async () => null,
  labels,
  editMode,
  prefix
}) {
  const [deleteType, setDeleteType] = React.useState(initialDeleteType)

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      aria-labelledby='edit-recurring-title'
    >
      {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="Delete Recurring Event"
          >
            {(text) => {
              return <DialogTitle id='edit-recurring-title'>{text}</DialogTitle>;
            }}
          </RenderUiPrefix>
        ) : (
          <DialogTitle id='edit-recurring-title'>Delete Recurring Event</DialogTitle>
        )}

      <DialogContent sx={{ minWidth: '470px' }}>
        <Box>
          <RadioGroup
            aria-labelledby='recurring-event-update-type-label'
            name='recurring-event-update-type'
            value={deleteType}
            onChange={(e) => setDeleteType(e.target.value)}
          >
            <FormControlLabel
              value='this_event'
              control={<Radio />}
              label='This event'
            />
            <FormControlLabel
              value='this_and_following_events'
              control={<Radio />}
              label='This and following events'
            />
            <FormControlLabel
              value='all_events'
              control={<Radio />}
              label='All events'
            />
          </RadioGroup>
        </Box>
        <DialogActions>
          <Button autoFocus onClick={() => {
            setDeleteType(initialDeleteType)
            onCancelClick()
          }} color="secondary">
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={disabled}
            color='error'
            autoFocus
            onClick={async () => {
                await onDeleteClick(deleteType)
                setDeleteType(initialDeleteType)
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
