import React from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { hrUrls } from '@config/routes';
import { employeesHandleRowMap } from '@config/handleRows/hr';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { EmployeesForm } from '@config/forms/hr';
import { useSelector } from 'react-redux';
import useToast from '@hooks/useToast';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';

const { employeesUrls, employeeDepartmentsUrls, departmentsUrls } = hrUrls;

export default function Employee({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [department, setDepartment] = React.useState('');
  const [notify] = useToast();

  const { data: departments } = useRQuery({
    key: ['departments', user.token, user?.actAs],
    url: departmentsUrls.list(`?&name=Sales`),
    config: getAuthHeader(user.token, user?.actAs),
  });

  React.useEffect(() => {
    if (!departments) return;
    setDepartment(departments?.results[0]?.id);
  }, [departments]);

  return (
    <Box>
      <ListView
        label="Sales people"
        prefix="0c7"
        labels={labels}
        editMode={editMode}
        microservice={microservices.HR.name}
        model={microservices.HR.models.employee}
        urls={employeesUrls}
        columnKey="employeesHR"
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            EmployeesForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { person, ...rest } = values;

              const response = await createRecord({
                values: {
                  ...rest,
                  person: person?.id,
                },
                url: employeesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              await createRecord({
                values: {
                  employee: response?.data?.id,
                  department: department,
                },
                url: employeeDepartmentsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={employeesHandleRowMap}
        urlParams={`&department_name=Sales`}
      />
    </Box>
  );
}
