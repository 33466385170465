import { eDocHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  documents: 'documents',
  documentFieldDefns: 'document-field-defns',
  documentFieldInstances: 'document-field-instances',
  recipients: 'recipients',
  fillDocument: 'documents/fill',
  cloneSystemTemplates: 'clone-system-templates',
};

const cloneSystemTemplatesUrls = getModelUrls(
  eDocHost,
  models.cloneSystemTemplates,
  false,
  'v1'
);

const documentsUrls = getModelUrls(eDocHost, models.documents, false, 'v1');
const documentFieldInstancesUrls = getModelUrls(
  eDocHost,
  models.documentFieldInstances,
  false,
  'v1'
);
const recipientsUrls = getModelUrls(eDocHost, models.recipients, false, 'v1');
const fillDocumentUrls = getModelUrls(
  eDocHost,
  models.fillDocument,
  false,
  'v1'
);
const documentFieldDefnsUrls = getModelUrls(
  eDocHost,
  models.documentFieldDefns,
  false,
  'v1'
);

const urls = {
  cloneSystemTemplatesUrls,
  documentsUrls,
  documentFieldDefnsUrls,
  fillDocumentUrls,
  recipientsUrls,
  documentFieldInstancesUrls,
};

export default urls;
