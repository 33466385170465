import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import UIAutocompleteField from '@ui/Inputs/AutocompleteField';
import EditableTableLink from './EditableTableLink';

function EditableTableAutocompleteField({
  label,
  options = [],
  isLoading,
  value,
  setValue = (v) => v,
  setSearchTerm = (v) => v,
  ...rest
}) {
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    // if (value && value.label === inputValue) return;

    const timeoutId = setTimeout(() => {
      setSearchTerm(inputValue);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <UIAutocompleteField
      label={label}
      value={value}
      setValue={setValue}
      inputValue={inputValue}
      options={options}
      isLoading={isLoading}
      setInputValue={(term) =>
        term !== 'undefined' ? setInputValue(term) : setInputValue('')
      }
      {...rest}
    />
  );
}

export function renderAutocomplete(params) {
  if (params.colDef.multiple) {
    return (
      <Typography component='div' variant='p'>
        {!params.value || !params.value.length
          ? '...'
          : params.value.map((obj) => obj.label).toString()}
      </Typography>
    );
  }

  if (params.colDef.to || params.colDef.getHref) {
    return <EditableTableLink params={params} />;
  }

  return (
    <Typography component='div' variant='p'>
      {!params.value || !params.value.id ? '...' : params.value.label}
    </Typography>
  );
}

export function AutocompleteEditInputCell(props) {
  const { id, value, api, field, colDef } = props;

  const handleChange = async (params) => {
    if (!params) return;

    if (colDef.formatValue) {
      params = colDef.formatValue(params);
    }

    api.setEditCellValue({
      id,
      field,
      value: params,
    });

    await api.commitCellChange({ id, field });
    api.setCellMode(id, field, 'view');
  };

  return (
    <EditableTableAutocompleteField
      label={colDef.headerName}
      value={
        colDef.multiple && value.constructor.name === 'Object' ? [value] : value
      }
      setValue={handleChange}
      options={colDef.options}
      isLoading={colDef.isLoading}
      setSearchTerm={colDef.setSearchTerm}
      multiple={colDef.multiple || false}
    />
  );
}

renderAutocomplete.propTypes = {
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.number.isRequired,
};

AutocompleteEditInputCell.propTypes = {
  /**
   * GridApi that let you manipulate the grid.
   */
  api: PropTypes.object.isRequired,
  /**
   * The column field of the cell that triggered the event.
   */
  field: PropTypes.string.isRequired,
  /**
   * The grid row id.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};
