import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { crmUrls } from '@config/routes';
import { companySpinsMeta } from '@config/meta/crm';
import { useQueryClient } from 'react-query';

const { companySpinsUrls } = crmUrls;

export default function CompanySpinsDetail() {
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const [recordData, setRecordData] = React.useState(null);

  const getSchema = React.useCallback(
      (data) =>
      companySpinsMeta({
          data,
          queryClient,
        }),
      []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/company-spins`, text: 'Company Spins' },
              { text: recordData && recordData.situation },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={companySpinsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
    </Box>
  );
}
