import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { nodeDriveUrls } from '@config/routes';
import { getAuthHeader } from '@config/functions/helperFunctions';

const { filesUrls } = nodeDriveUrls;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={3} px={1.5}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default function UploadFilesPopup({
  open,
  setOpen,
  selectedImages,
  setSelectedImages,
  files,
  setFiles,
  postId,
}) {
  const [value, setValue] = useState(1);
  const user = useSelector(selectProfile);
  const [driveImages, setDriveImages] = useState([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/png, image/jpeg, image/jpg, video/mp4',
    onDrop: (acceptedFiles) => {
      const filesWithPostId = acceptedFiles.map((file) => ({
        ...file,
        postId,
      }));
      setFiles([...files, ...filesWithPostId]);
    },
  });

  const handleRemove = (fileIndex) => {
    const updatedFiles = [...files];
    updatedFiles.splice(fileIndex, 1);
    setFiles(updatedFiles);
  };

  const handleClose = () => setOpen(false);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      console.log('obi-fetch');
      fetchImagesFromApi();
    }
  };

  const fetchImagesFromApi = async () => {
    try {
      const { data } = await axios.get(
        filesUrls.list('?images_only=true&public=true&page_size=100'),
        getAuthHeader(user.token, user?.actAs)
      );
      console.log('obi-data', data);
      setDriveImages(data.results);
    } catch (error) {
      console.log('obi-64-images', error);
    }
  };

  const onImageClick = (image) => {
    const isSelected = selectedImages.find((r) => r.id === image.id);
    if (isSelected) {
      setSelectedImages(selectedImages.filter((img) => img.id !== image.id));
    } else {
      const imageWithPostId = { ...image, postId };
      setSelectedImages([...selectedImages, imageWithPostId]);
    }
  };

  const isImageSelected = (id) => selectedImages.find((r) => r.id === id);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>Upload</DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ pt: 0 }}>
        <div
          className={{
            flexGrow: 1,
          }}
        >
          <Tabs value={value} onChange={handleTabChange} variant="fullWidth">
            <Tab label="From Drive" />
            <Tab label="From Computer" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Grid
              container
              sx={{ maxHeight: '450px', overflow: 'scroll' }}
              spacing={2}
            >
              {driveImages.map((image) => (
                <Grid item xs={4}>
                  <Box
                    sx={{
                      width: '150px',
                      height: '150px',
                      border: isImageSelected(image.id)
                        ? '2px solid blue'
                        : '1px solid #c1c7d0',
                      cursor: 'pointer',
                      borderRadius: '4px',
                      overflow: 'hidden',
                    }}
                    onClick={() => onImageClick(image)}
                  >
                    <img
                      key={image.id}
                      src={image.fileUrl}
                      alt={image.name}
                      style={{
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        userSelect: 'none',
                        pointerEvents: 'none',
                      }}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                minHeight: '190px',
              }}
            >
              <Box
                sx={{
                  p: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px dashed #c1c7d0',
                  borderRadius: '4px',
                  width: '100%',
                }}
              >
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input
                    {...getInputProps()}
                    onChange={(e) => {
                      const acceptedFiles = [...e.target.files];
                      console.log('obi-array', acceptedFiles);
                      const filesWithPostId = acceptedFiles.map((file) => ({
                        file,
                        postId,
                      }));
                      setFiles([...files, ...filesWithPostId]);
                    }}
                  />
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      userSelect: 'none',
                      color: '#7a869a',
                    }}
                  >
                    {isDragActive ? (
                      <span>Drop the file here...</span>
                    ) : (
                      <>
                        <span>Drop file to attach, or</span>{' '}
                        <span style={{ color: 'blue' }}>browse</span>
                      </>
                    )}
                  </Typography>
                </div>
              </Box>
              {files.length > 0 && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
                  {files.map((file, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 1,
                      }}
                    >
                      <Typography>{file?.file?.name}</Typography>
                      <IconButton onClick={() => handleRemove(index)}>
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </TabPanel>
        </div>

        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
        >
          <Button
            onClick={() => {
              setFiles([]);
              setSelectedImages([]);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleClose}>Save</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
