import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: null,
  callLists: [],
};

export const callListBoardSlice = createSlice({
  name: 'callListBoard',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setCallLists: (state, action) => {
      state.callLists = action.payload;
    },
  },
});

export const { setModal, setCallLists } = callListBoardSlice.actions;

export const selectCallListsBoard = (state) => state.callListBoard;

export default callListBoardSlice.reducer;
