import * as React from 'react';
import { CKEditor } from 'ckeditor4-react';
import { microservices } from '@config/constants';

export default function WysiwygEditor({ msName, readOnly, ...rest }) {
  return (
    <CKEditor
      {...rest}
      editorUrl={
        msName === 'digitalDoc' || msName === microservices.NOTIFICATION.name
          ? '/ckeditor/ckeditor.js'
          : 'https://cdn.ckeditor.com/4.17.2/full-all/ckeditor.js'
      }
      readOnly={readOnly}
    />
  );
}
