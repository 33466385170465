import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { formsUrls } from '@config/routes';
import { personalityMeta } from '@config/meta/forms/formsMeta';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { recommendedCourseRowsMap } from '@config/handleRows/forms';
import { RecommendedCourseForm } from '@config/forms/forms';
import { microservices } from '@config/constants';

const { personalityUrls, recommendedCourseUrls } = formsUrls;

export default function GroupDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => personalityMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/forms/${recordData.formId}`, text: 'Personalities' },
              { text: recordData?.personalityCode ?? 'No name' },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={personalityUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      {recordData ? (
        <Box sx={{ mt: 5 }}>
          <DetailAccordionView
            nodeAPI
            hideSearch
            editMode={editMode}
            labels={labels}
            label='Recommended Courses'
            prefix='ClA'
            microservice={microservices.Forms.name}
            model={microservices.Forms.models.recommendedCourse}
            columnKey='recommendedCourseForms'
            urls={recommendedCourseUrls}
            addNewForm={{
              getFields: () => RecommendedCourseForm(),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError,
                setFieldError
              ) => {
                try {
                  const { courseId } = values;

                  if (!courseId?.id) {
                    setFieldError('courseId', 'Please select a course');
                    return;
                  }

                  await createRecord({
                    values: {
                      courseId: courseId?.id,
                      personalityId: recordId,
                    },
                    url: recommendedCourseUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  refetch();
                } catch (err) {
                  console.log(err);
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={recommendedCourseRowsMap}
            urlParams={`&personalityId=${recordId}`}
            sortColumnBy='desc'
          />
        </Box>
      ) : null}
    </Box>
  );
}
