import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Box, Button, MenuItem, Stack } from '@mui/material';
import { SelectField } from '@components/Inputs';
import { updateRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { bpaUrls } from '@config/routes';
import { useQueryClient } from 'react-query';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';

const { shapeTransitionsUrls } = bpaUrls;

const types = ['True', 'False'];

export default function TransitionSidebar({ user, selectedTransition }) {
  const [notify] = useToast();
  const queryClient = useQueryClient();

  const {
    data: transition,
    isLoading,
    isError,
  } = useRQuery({
    key: [
      `${selectedTransition?.id}-transition-detail`,
      selectedTransition?.id,
      user?.token,
      user?.actAs,
    ],
    url: shapeTransitionsUrls.detail(selectedTransition?.id),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!selectedTransition?.id },
  });

  if (isError) {
    return <Box>Failed to fetch the details</Box>;
  }

  if (isLoading || !transition) {
    return <Box>loading...</Box>;
  }

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{
          type: transition.test_outcome ? 'True' : 'False',
        }}
        validationSchema={Yup.object({
          type: Yup.string().oneOf(types).required(),
        })}
        onSubmit={async ({ type }, { setSubmitting }) => {
          try {
            setSubmitting(true);

            const getTypeValue = (type) => type === 'True';

            await updateRecord({
              values: {
                test_outcome: getTypeValue(type),
              },
              url: shapeTransitionsUrls.detail(transition.id),
              token: user.token,
              actAs: user?.actAs,
            });

            queryClient.invalidateQueries([
              `${transition.id}-transition-detail`,
              transition.id,
              user.token,
            ]);
            notify('Operation completed', {
              type: 'SUCCESS',
            });
          } catch {
            notify('There was an error, please try again', {
              type: 'ERROR',
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Stack
              spacing={2}
              sx={{
                mt: 1,
                p: 2,
                border: '1px solid #b0b0b0',
                borderRadius: '4px',
              }}
            >
              <SelectField label="Type" name="type" required>
                {types.map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {unit}
                  </MenuItem>
                ))}
              </SelectField>

              <Button
                size="small"
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                disableElevation
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
