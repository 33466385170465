import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function AccountVisibilityPopup({
  open,
  setOpen,
  isSubmitting,
  handleClick,
}) {
  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: '600px',
          flexWrap: 'wrap',
        }}
      >
        <DialogTitle sx={{ fontSize: '18px', pointerEvents: 'none' }}>
          Would you like to set your account to private or public?
        </DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Typography>
          If you keep your account public, marketing may have the ability to
          post using your account. With a private account, only you will have
          access to post and share content
        </Typography>

        <DialogActions sx={{ pt: '32px' }}>
          <LoadingButton
            variant='outlined'
            onClick={() =>
              handleClick({
                accountPrivate: true,
              })
            }
            loading={isSubmitting}
            loadingIndicator='Loading…'
          >
            <span>Keep Private</span>
          </LoadingButton>

          <LoadingButton
            variant='outlined'
            loading={isSubmitting}
            loadingIndicator='Loading…'
            onClick={() =>
              handleClick({
                accountPrivate: false,
              })
            }
          >
            <span>Make Public</span>
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
