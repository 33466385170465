// handleRows

export const countryRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  iso2: r.iso2,
  iso3: r.iso3,
  dialcode: r.dialcode,
  continent: r.continent,
  tld: r.tld,
  created_at: r.created_at,
});
export const currencyRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  code: r.code,
  created_at: r.created_at,
});
export const socialMediaRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  url: r.url,
  created_at: r.created_at,
});
export const sicCodesRowsMap = (r) => ({
  id: r.id,
  code: r.code,
  description: r.description,
  created_at: r.created_at,
});
export const timeZonesRowsMap = (r) => ({
  id: r.id,
  code: r.code,
  name: r.name,
  utc_offset: r.utc_offset,
  created_at: r.created_at,
});

export const languageRowsMap = (r) => ({
  id: r.id,
  iso_639_1: r.iso_639_1,
  iso_639_2: r.iso_639_2,
  english: r.english,
  french: r.french,
  german: r.german,
  created_at: r.created_at,
});

export const cityRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  capital: r.capital,
  latitude: r.latitude,
  longitude: r.longitude,
  country: r && r.details && r.details.country
  ? { id: r.country, label: r.details.country.name }
  : null,
  created_at: r.created_at,
});
export const postCodesRowsMap = (r) => ({
  id: r.id,
  code: r.code,
  region: r.region,
  uk_region: r.uk_region,
  latitude: r.latitude,
  longitude: r.longitude,
  country:
    r.country && r.details && r.details.country
      ? { id: r.country, label: r.details.country.name }
      : r.country,
  city:
    r.city && r.details && r.details.city
      ? { id: r.city, label: r.details.city.name }
      : r.city,
  created_at: r.created_at,
    });
export const countryCurrencyHandleRow = (c) => ({
  id: c.id,
  country:
    c.country && c.details && c.details.country
      ? { id: c.country, label: c.details.country.name }
      : c.country,
  currency:
    c.currency && c.details && c.details.currency
      ? { id: c.currency, label: c.details.currency.name }
      : c.currency,
  created_at: c.created_at,
    });
export const countryLanguageHandleRow = (c) => ({
  id: c.id,
  country:
    c.country && c.details && c.details.country
      ? { id: c.country, label: c.details.country.name }
      : c.country,
  language:
    c.language && c.details && c.details.language
      ? { id: c.language, label: c.details.language.english }
      : c.language,
  created_at: c.created_at,
    });
