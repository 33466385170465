import * as Yup from 'yup';
import {
  formatToUTCDate,
} from '@config/functions/helperFunctions';

export const discountCodeMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: 'link',
    to: '/discount-codes/',
    width: 180,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.amount : null,
    validate: Yup.number().min(0).max(32767).nullable().default(null),
  },
  {
    field: 'percent',
    headerName: 'Percent',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.percent : null,
    validate: Yup.number().min(0).max(100),
  },
  {
    field: 'start_code',
    headerName: 'Start Code',
    onForm: true,
    onAccordionForm: true,
    type: 'dateTime',
    width: 180,
    editable: true,
    value: data ? new Date(data.start_code) : null,
    initialValue: null,
    validate: Yup.date()
      .nullable()
      .default(() => new Date()),
  },
  {
    field: 'end_code',
    headerName: 'End Code',
    onForm: true,
    onAccordionForm: true,
    type: 'dateTime',
    width: 180,
    editable: true,
    value: data ? new Date(data.end_code) : null,
    initialValue: null,
    validate: Yup.date()
      .nullable()
      .default(() => new Date()),
  },
  {
    field: 'universal',
    headerName: 'Universal',
    onForm: true,
    onAccordionForm: true,
    type: 'boolean',
    width: 90,
    editable: true,
    value: data ? data.universal : null,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const paymentStatusMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: 'link',
    to: '/payment-statuses/',
    width: 180,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const paymentProviderMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: 'link',
    to: '/payment-providers/',
    width: 180,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const paymentMethodMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: 'link',
    to: '/payment-methods/',
    width: 180,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const deliveryMethodMeta = ({
  data,
  paymentProviders,
  fetchingPaymentProviders,
  setPaymentProviderTerm,
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: 'link',
    to: '/delivery-methods/',
    width: 180,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'provider',
    headerName: 'Payment Provider',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setPaymentProviderTerm(value),
    options: paymentProviders,
    value:
      data && data.provider && data.details && data.details.provider
        ? { id: data.provider, label: data.details.provider.name }
        : null,
    isLoading: fetchingPaymentProviders,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const deliveryStatusMeta = ({
  data,
  deliveryMethods,
  fetchingDeliveryMethods,
  setDeliveryMethodTerm,
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: 'link',
    to: '/delivery-statuses/',
    width: 180,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'delivery_method',
    headerName: 'Delivery Method',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setDeliveryMethodTerm(value),
    options: deliveryMethods,
    value:
      data &&
      data.delivery_method &&
      data.details &&
      data.details.delivery_method
        ? { id: data.delivery_method, label: data.details.delivery_method.name }
        : null,
    isLoading: fetchingDeliveryMethods,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const cartMeta = ({
  data,
  customers,
  fetchingCustomers,
  setCustomerTerm,
  deliveryStatuses,
  fetchingDeliveryStatuses,
  setDeliveryStatusTerm,
  paymentStatuses,
  fetchingPaymentStatuses,
  setPaymentStatusTerm,
  discountCodes,
  fetchingDiscountCodes,
  setDiscountCodeTerm,
} = {}) => [
  {
    field: 'customer',
    headerName: 'Customer',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    to: '/carts/',
    setSearchTerm: (value) => setCustomerTerm(value),
    options: customers,
    value:
      data && data.customer && data.customer_details
        ? { id: data.customer, label: data.customer_details.first_name }
        : null,
    isLoading: fetchingCustomers,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'grand_total',
    headerName: 'Grand Total',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.grand_total : null,
    initialValue: 1,
    validate: Yup.number().min(1).max(32767).required(),
  },
  {
    field: 'delivery_status',
    headerName: 'Delivery Status',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setDeliveryStatusTerm(value),
    options: deliveryStatuses,
    value:
      data &&
      data.delivery_status &&
      data.details &&
      data.details.delivery_status
        ? { id: data.delivery_status, label: data.details.delivery_status.name }
        : null,
    isLoading: fetchingDeliveryStatuses,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'payment_status',
    headerName: 'Payment Status',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setPaymentStatusTerm(value),
    options: paymentStatuses,
    value:
      data && data.payment_status && data.details && data.details.payment_status
        ? { id: data.payment_status, label: data.details.payment_status.name }
        : null,
    isLoading: fetchingPaymentStatuses,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'discount_code',
    headerName: 'Discount Code',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setDiscountCodeTerm(value),
    options: discountCodes,
    value:
      data && data.discount_code && data.details && data.details.discount_code
        ? { id: data.discount_code, label: data.details.discount_code.name }
        : null,
    isLoading: fetchingDiscountCodes,
    validate: Yup.string().nullable(),
    width: 180,
    editable: true,
  },
  {
    field: 'same_delivery_for_all_items',
    headerName: 'Same Delivery',
    onForm: true,
    onAccordionForm: true,
    type: 'boolean',
    width: 90,
    editable: true,
    value: data ? data.same_delivery_for_all_items : null,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const addressMeta = ({ data } = {}) => [
  {
    field: 'first_name',
    headerName: 'First Name',
    onForm: true,
    onAccordionForm: true,
    type: 'link',
    to: '/addresses/',
    width: 180,
    editable: true,
    value: data ? data.first_name : null,
    initialValue: '',
    validate: Yup.string().max(200).required(),
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data ? data.first_name : null,
    initialValue: '',
    validate: Yup.string().max(200).required(),
  },
  {
    field: 'email',
    headerName: 'Email',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data ? data.email : null,
    initialValue: '',
    validate: Yup.string().email().max(254).required(),
  },
  {
    field: 'mobile',
    headerName: 'Mobile',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data ? data.mobile : null,
    initialValue: '',
    validate: Yup.string().max(30),
  },
  {
    field: 'organisation',
    headerName: 'Organisation',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data ? data.organisation : null,
    initialValue: '',
    validate: Yup.string().max(100),
  },
  {
    field: 'address1',
    headerName: 'Address1',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.address1 : null,
    initialValue: '',
    validate: Yup.string().max(2500).required(),
  },
  {
    field: 'address2',
    headerName: 'Address2',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.address2 : null,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'city',
    headerName: 'City',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.city : null,
    initialValue: '',
    validate: Yup.string().max(2500).required(),
  },
  {
    field: 'postcode',
    headerName: 'Post Code',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.postcode : null,
    initialValue: '',
    validate: Yup.string().max(2500).required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    onDetailCard: false,
    editable: false,
    width: 180,
    type: 'dateTime',
  },
];

export const paymentMeta = ({
  data,
  carts,
  fetchingCarts,
  setCartTerm,
  addresses,
  fetchingAddresses,
  setAddressTerm,
  paymentProviders,
  fetchingPaymentProviders,
  setPaymentProviderTerm,
  paymentMethods,
  fetchingPaymentMethods,
  setPaymentMethodTerm,
  paymentStatuses,
  fetchingPaymentStatuses,
  setPaymentStatusTerm,
} = {}) => [
  {
    field: 'cart',
    headerName: 'Cart',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setCartTerm(value),
    options: carts,
    value:
      data && data.cart
        ? { id: data.cart, label: data.cart_customer_name }
        : null,
    isLoading: fetchingCarts,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'billing_address',
    headerName: 'Billing Address',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setAddressTerm(value),
    options: addresses,
    value:
      data &&
      data.billing_address &&
      data.details &&
      data.details.billing_address
        ? {
            id: data.billing_address,
            label: data.details.billing_address.address1,
          }
        : null,
    isLoading: fetchingAddresses,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'payment_provider',
    headerName: 'Payment Provider',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setPaymentProviderTerm(value),
    options: paymentProviders,
    value:
      data &&
      data.payment_provider &&
      data.details &&
      data.details.payment_provider
        ? {
            id: data.payment_provider,
            label: data.details.payment_provider.name,
          }
        : null,
    isLoading: fetchingPaymentProviders,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'payment_method',
    headerName: 'Payment Method',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setPaymentMethodTerm(value),
    options: paymentMethods,
    value:
      data && data.payment_method && data.details && data.details.payment_method
        ? { id: data.payment_method, label: data.details.payment_method.name }
        : null,
    isLoading: fetchingPaymentMethods,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'payment_status',
    headerName: 'Payment Status',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setPaymentStatusTerm(value),
    options: paymentStatuses,
    value:
      data && data.payment_status && data.details && data.details.payment_status
        ? { id: data.payment_status, label: data.details.payment_status.name }
        : null,
    isLoading: fetchingPaymentStatuses,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'payment_date',
    headerName: 'Payment Date',
    onForm: true,
    onAccordionForm: true,
    type: 'dateTime',
    width: 100,
    editable: true,
    value: data && data.payment_date ? new Date(data.payment_date) : null,
    initialValue: null,
    validate: Yup.date()
      .nullable()
      .default(() => new Date()),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    editable: false,
    width: 180,
    type: 'dateTime',
  },
];

export const cartItemMeta = ({
  data,
  productVariants,
  fetchingProductVariants,
  setProductVariantTerm,
  carts,
  fetchingCarts,
  setCartTerm,
  deliveryStatuses,
  fetchingDeliveryStatuses,
  setDeliveryStatusTerm,
} = {}) => [
  {
    field: 'product_variant',
    headerName: 'Product Variant',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setProductVariantTerm(value),
    to: '/cart-items/',
    options: productVariants,
    value:
      data &&
      data.product_variant &&
      data.details &&
      data.details.product_variant
        ? { id: data.product_variant, label: data.details.product_variant.name }
        : null,
    isLoading: fetchingProductVariants,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'cart',
    headerName: 'Cart',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setCartTerm(value),
    options: carts,
    value:
      data && data.cart
        ? { id: data.cart, label: data.cart_customer_name }
        : null,
    isLoading: fetchingCarts,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'delivery_status',
    headerName: 'Delivery Status',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setDeliveryStatusTerm(value),
    options: deliveryStatuses,
    value:
      data &&
      data.delivery_status &&
      data.details &&
      data.details.delivery_status
        ? { id: data.delivery_status, label: data.details.delivery_status.name }
        : null,
    isLoading: fetchingDeliveryStatuses,
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.quantity : null,
    validate: Yup.number().min(0),
  },
  {
    field: 'list_price',
    headerName: 'List Price',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.list_price : null,
    validate: Yup.number().min(0).required(),
  },
  {
    field: 'discount',
    headerName: 'Discount',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.discount : null,
    validate: Yup.number().min(0),
  },
  {
    field: 'vat_percentage',
    headerName: 'VAT Percentage',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.vat_percentage : null,
    validate: Yup.number().min(0).max(32767).required(),
  },
  {
    field: 'total',
    headerName: 'Total',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.total : null,
    validate: Yup.number().min(0).max(32767).required(),
  },
  {
    field: 'campaign_id',
    headerName: 'Campaign Id',
    onForm: true,
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.campaign_id : null,
    initialValue: '',
    validate: Yup.string().max(100),
  },
  {
    field: 'campaign_discount',
    headerName: 'Campaign Discount',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.campaign_discount : null,
    validate: Yup.number().min(0).max(32767),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    editable: false,
    width: 180,
    type: 'dateTime',
  },
];

export const itemDeliveryMeta = ({
  data,
  cartItems,
  fetchingCartItems,
  setCartItemTerm,
  deliveryMethods,
  fetchingDeliveryMethods,
  setDeliveryMethodTerm,
  deliveryStatuses,
  fetchingDeliveryStatuses,
  setDeliveryStatusTerm,
  addresses,
  fetchingAddresses,
  setAddressTerm,
} = {}) => [
  {
    field: 'cart_item',
    headerName: 'Cart Item',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setCartItemTerm(value),
    options: cartItems,
    value:
      data &&
      data.cart_item &&
      data.cart_item_details &&
      data.cart_item_details.details &&
      data.cart_item_details.details.product_variant
        ? {
            id: data.cart_item,
            label: data.cart_item_details.details.product_variant.name,
          }
        : null,
    isLoading: fetchingCartItems,
    initialValue: '',
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'delivery_method',
    headerName: 'Delivery Method',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setDeliveryMethodTerm(value),
    options: deliveryMethods,
    value:
      data &&
      data.delivery_method &&
      data.details &&
      data.details.delivery_method
        ? { id: data.delivery_method, label: data.details.delivery_method.name }
        : null,
    isLoading: fetchingDeliveryMethods,
    initialValue: '',
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'delivery_status',
    headerName: 'Delivery Status',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setDeliveryStatusTerm(value),
    options: deliveryStatuses,
    value:
      data &&
      data.delivery_status &&
      data.details &&
      data.details.delivery_status
        ? { id: data.delivery_status, label: data.details.delivery_status.name }
        : null,
    isLoading: fetchingDeliveryStatuses,
    initialValue: '',
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'delivery_address',
    headerName: 'Delivery Address',
    onForm: true,
    onAccordionForm: true,
    type: 'autocomplete',
    setSearchTerm: (value) => setAddressTerm(value),
    options: addresses,
    value:
      data &&
      data.delivery_address &&
      data.details &&
      data.details.delivery_address
        ? {
            id: data.delivery_address,
            label: data.details.delivery_address.address1,
          }
        : null,
    isLoading: fetchingAddresses,
    initialValue: '',
    validate: Yup.string().required(),
    width: 180,
    editable: true,
  },
  {
    field: 'external_reference',
    headerName: 'External Reference',
    onForm: true,
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.external_reference : null,
    initialValue: '',
    validate: Yup.string().max(200),
  },
  {
    field: 'delivery_cost',
    headerName: 'Delivery Cost',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.delivery_cost : null,
    initialValue: 0,
    validate: Yup.number().min(0),
  },
  {
    field: 'vat_percent',
    headerName: 'VAT Percentage',
    onForm: true,
    onAccordionForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.vat_percent : null,
    initialValue: 0,
    validate: Yup.number().min(0).max(32767).required(),
  },
  {
    field: 'promised_delivery_date',
    headerName: 'Promised Delivery Date',
    onForm: true,
    onAccordionForm: true,
    type: 'date',
    editable: true,
    width: 200,
    initialValue: formatToUTCDate(new Date()),
    validate: Yup.string().required(),
  },
  {
    field: 'campaign_id',
    headerName: 'Campaign Id',
    onForm: true,
    onAccordionForm: true,
    editable: true,
    width: 200,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'campaign_discount',
    headerName: 'Campaign Discount',
    onForm: true,
    onAccordionForm: true,
    editable: true,
    width: 100,
    type: 'number',
  },
  {
    field: 'tracking_url',
    headerName: 'Tracking Url',
    onForm: true,
    onAccordionForm: true,
    editable: true,
    width: 200,
    validate: Yup.string().url(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    editable: false,
    width: 180,
    type: 'dateTime',
  },
];
