import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemovePlatformMenu from '@ui/SocialMedia/RemovePlatformMenu';

export default function SocialPlatformCard({
  icon,
  name,
  type,
  comingSoon,
  disabled,
  connecting,
  iconSize = '60px',
  getButton,
  connected,
  handleClick = () => null,
  handleMenuClick = () => null,
}) {
  return (
    <Box
      className='social-platform-card'
      sx={{
        width: '200px',
        height: '200px',
        background: 'white',
        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
        border: '1px solid rgb(184, 184, 184)',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        userSelect: 'none',
      }}
    >
      <Box sx={{ width: iconSize, height: iconSize, overflow: 'hidden' }}>
        <img
          src={icon}
          style={{
            width: '100%',
            height: '100%',
            display: 'block',
            objectFit: 'contain',
          }}
          alt=''
        />
      </Box>
      <Typography
        variant='h4'
        sx={{
          mt: 0.5,
          fontSize: '18px',
          lineHeight: '28px',
          color: 'rgb(61, 61, 61)',
          fontWeight: '600',
        }}
      >
        {name}
      </Typography>
      <Typography
        variant='h4'
        sx={{
          my: 0.5,
          fontSize: '14px',
          lineHeight: '20px',
        }}
      >
        {type}
      </Typography>

      {connected ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <CheckCircleIcon sx={{ color: 'rgb(135, 194, 33)' }} fontSize='sm' />
          <Typography
            sx={{ pl: 0.5, fontSize: '14px', color: 'rgb(61, 61, 61)' }}
          >
            Connected
          </Typography>
          <RemovePlatformMenu
            handleSelect={(action) => handleMenuClick({ name, action })}
          />
        </Box>
      ) : getButton ? (
        getButton()
      ) : (
        <Button
          size='small'
          disabled={disabled || comingSoon}
          onClick={handleClick}
        >
          {connecting
            ? 'Connecting...'
            : comingSoon
            ? 'Coming Soon'
            : 'Connect'}
        </Button>
      )}
    </Box>
  );
}
