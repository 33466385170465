import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import useRQuery from '@hooks/useRQuery';
import { eDocUrls, nodeDriveUrls } from '@config/routes';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import SignaturePopup from '@components/eDoc/SignaturePopup';
import SignatureField from '@components/eDoc/SignatureField';
import PDFTextField from '@components/eDoc/PDFTextField';
import { createRecord } from '@config/functions/requests';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';

const bgStyles = {
  background: '#f6f9ff',
  width: '100vw',
  height: '100vh',
  overflowX: 'hidden',
};

const pageStyles = {
  position: 'relative',
  display: 'inline-block',
  boxShadow: '3px 3px 8px #0000001a',
  mb: 3,
};

const toolbarStyles = {
  top: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '595px',
  background: '#fcfdfe',
  height: '48px',
  position: 'sticky',
  zIndex: '3',
  boxShadow: 'rgba(61, 66, 78, 0.3) 0px 1px 2px',
  mb: 2,
  mx: 'auto',
  borderRadius: '2px',
  px: 2,
};

const cardStyles = {
  width: '595px',
  mx: 'auto',
  my: '22px',
  py: '18px',
  px: '24px',
  background: 'white',
  border: '1px solid #dadce0',
  borderRadius: '8px',
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const { fillDocumentUrls, recipientsUrls } = eDocUrls;
const { filesUrls } = nodeDriveUrls;

function SEOHeader({ title }) {
  const getFavicon = () => {
    if (typeof window === 'undefined') return '';

    if (window.location.href.includes('mazards')) {
      return 'https://drive.pullstream.com/file/634e8c8f-810a-4149-b3af-ba750abca696.ico?one=true';
    }

    if (window.location.href.includes('evcharging')) {
      return 'https://drive.pullstream.com/file/e241d62a-ffe6-47fb-a91b-e59798743e73.ico?one=true';
    }

    return '/img/ps-favicon.png';
  };

  return (
    <Helmet>
      <title>{title || 'Sign Document'}</title>
      <link rel='icon' href={getFavicon()} />
    </Helmet>
  );
}

export default function SignDocument() {
  const { id: recordId } = useParams();
  const docRef = React.useRef(null);
  const user = useSelector(selectProfile);
  const [numPages, setNumPages] = React.useState(0);
  const [, setDocumentValues] = React.useState({});
  const [documentInputs, setDocumentInputs] = React.useState({});
  const [documentSignatures, setDocumentSignatures] = React.useState([]);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [responseRecorded, setResponseRecorded] = React.useState(false);
  const [documentFieldDefns, setDocumentFieldDefns] = React.useState([]);
  const [documentFieldInstances, setDocumentFieldInstances] = React.useState(
    []
  );
  const [signaturePopup, setSignaturePopup] = React.useState({
    open: false,
    data: {},
  });

  const {
    data: recipientData,
    isLoading,
    isError,
  } = useRQuery({
    key: [`${recordId}-signing-document`, user.token, user?.actAs, recordId],
    url: recipientsUrls.detail(recordId),
    options: { enabled: !!recordId },
    config: user?.token ? getAuthHeader(user.token, user?.actAs) : undefined,
  });

  const extractFieldInstances = (fieldDefns) => {
    return fieldDefns.reduce(
      (acc, { documentFieldInstances }) =>
        acc.concat(
          documentFieldInstances.filter(({ deleted }) => deleted === null)
        ),
      []
    );
  };

  const handleDocLoad = ({ numPages }) => {
    setNumPages(numPages);
    const documentFieldDefns =
      recipientData?.document?.documentFieldDefns ?? [];

    setDocumentFieldDefns(documentFieldDefns);
    setDocumentFieldInstances(extractFieldInstances(documentFieldDefns));
  };

  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const getDocumentName = (data) => data?.document?.name ?? 'Untitled';

  const handleSubmit = async () => {
    try {
      setSubmitting(true);

      const signatureUrls = [];

      for (const { id, type, selectedFile, draw } of documentSignatures) {
        const formData = new FormData();
        formData.append('created_by', recipientData.createdBy);
        formData.append('client', recipientData.client);
        formData.append('anonymous_can_see_it', true);

        if (type === 'draw') {
          const file = dataURLtoFile(draw, `${id}-signature.png`);
          formData.append('file', file);
        } else {
          formData.append('file', selectedFile);
        }

        const { data } = await createRecord({
          values: formData,
          url: filesUrls.list(),
        });

        signatureUrls.push({
          id,
          url: data.fileUrl,
        });
      }

      const documentFields = Object.entries(documentInputs).map(
        ([key, value]) => ({
          id: key,
          value,
        })
      );

      const payload = {
        signatureUrls,
        documentFields,
        client: recipientData.client,
        createdBy: recipientData.createdBy,
        recipientId: recordId,
      };

      await createRecord({
        values: payload,
        url: fillDocumentUrls.detail(recipientData?.documentId),
      });

      setResponseRecorded(true);
    } catch (err) {
      alert('Failed to record your response');
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    function handleInput(e) {
      const inputName = e.target.getAttribute('name');

      if (!e.target || !inputName) return;

      const [id] = inputName.split('-');

      setDocumentValues((prevState) => ({
        ...prevState,
        [id]: e.target.value.trim(),
      }));
    }

    const docElem = docRef.current;

    if (docElem) {
      docElem.addEventListener('input', handleInput);
    }

    return () => {
      if (docElem) {
        docElem.removeEventListener('input', handleInput);
      }
    };
  }, []);

  if (
    isError ||
    recipientData?.isEditable === false ||
    (recipientData?.expiryDate &&
      new Date(recipientData?.expiryDate) < new Date())
  ) {
    return (
      <Box
        sx={{
          ...bgStyles,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SEOHeader />
        <Box sx={{ textAlign: 'center' }}>
          <img
            src='/img/warning-custom.png'
            style={{ width: '120px', height: '120px', margin: '0 auto' }}
            alt=''
          />
          <Typography
            sx={{ my: '18px', fontWeight: '500', fontSize: '28px' }}
            variant='h4'
          >
            We can't open this edoc for you
          </Typography>
          <Typography sx={{ fontSize: '18px', color: 'rgb(19, 23, 34)' }}>
            If you're the owner of this edoc then you need to publish it to be
            able to see it.
          </Typography>
        </Box>
      </Box>
    );
  }

  if (isLoading || !recipientData) {
    return <p>loading...</p>;
  }

  if (responseRecorded) {
    return (
      <Box sx={bgStyles}>
        <SEOHeader title={getDocumentName(recipientData)} />

        <Box sx={cardStyles}>
          <Typography sx={{ width: '75%' }}>
            Your response has been recorded
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box ref={docRef} sx={bgStyles}>
      <Box sx={{ mb: 3 }}>
        <SEOHeader title={getDocumentName(recipientData)} />
      </Box>

      <Box sx={toolbarStyles}>
        <Box>{getDocumentName(recipientData)}</Box>

        <Button
          disableElevation
          variant='contained'
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>

      <Document
        error={null}
        className='eDoc-flex-col'
        onLoadSuccess={handleDocLoad}
        file={recipientData?.document?.documentUrl}
      >
        {Array.from({ length: numPages }).map((r, index) => {
          const fields = documentFieldInstances.filter(
            ({ pageNumber }) => pageNumber === index + 1
          );

          return (
            <Box key={index} sx={pageStyles}>
              <Page pageNumber={++index} />

              {fields.map(
                ({
                  id,
                  imageY,
                  imageX,
                  width,
                  height,
                  documentFieldDefnId,
                  documentFieldValues,
                }) => {
                  const found = documentFieldDefns.find(
                    ({ id }) => documentFieldDefnId === id
                  );
                  const { name, type } = found ?? {};
                  const [initialValue] = documentFieldValues;

                  if (type === 'Signature') {
                    return (
                      <SignatureField
                        key={id}
                        id={id}
                        imageY={imageY}
                        imageX={imageX}
                        width={width}
                        height={height}
                        documentSignatures={documentSignatures}
                        setDocumentSignatures={setDocumentSignatures}
                        setSignaturePopup={setSignaturePopup}
                      />
                    );
                  }

                  return (
                    <PDFTextField
                      key={id}
                      id={id}
                      type={type}
                      imageY={imageY}
                      imageX={imageX}
                      width={width}
                      height={height}
                      placeholder={name}
                      initialValue={initialValue?.value ?? ''}
                      setValue={(value) => {
                        setDocumentInputs((prevState) => ({
                          ...prevState,
                          [id]: value.trim(),
                        }));
                      }}
                    />
                  );
                }
              )}
            </Box>
          );
        })}
      </Document>

      <SignaturePopup
        open={!!signaturePopup?.open}
        options={signaturePopup?.data}
        setOpen={(open) => {
          setSignaturePopup((prevState) => ({ ...prevState, open }));
        }}
        handleSubmit={({ id, selectedFile, selectedImage, draw, type }) => {
          setDocumentSignatures((prevState) => {
            const index = prevState.findIndex((item) => item.id === id);
            if (index !== -1) {
              // If the id already exists, update the item
              const updatedItem = {
                id,
                selectedFile,
                selectedImage,
                draw,
                type,
              };
              const updatedState = [...prevState];
              updatedState[index] = updatedItem;
              return updatedState;
            } else {
              // If the id doesn't exist, add a new item
              return [
                ...prevState,
                { id, selectedFile, selectedImage, draw, type },
              ];
            }
          });
          setSignaturePopup({ open: false, data: {} });
        }}
      />
    </Box>
  );
}
