import { accountsHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  tokenRefresh: 'token-refresh',
  tokenLogout: 'token-logout',
  userProfile: 'accounts',
  signOut: 'accounts/logout',
  register: 'accounts/register',
  roles: 'accounts/roles',
  microservices: 'accounts/microservices',
  models: 'accounts/models',
  users: 'accounts/users',
  adminUsers: 'accounts/admin-users',
  profile: 'accounts/profile',
  editProfile: 'accounts/profile/user', // user profile page /profile
  passwordChange: 'accounts/password-change',
  profileRoles: 'accounts/profile-roles',
  bulkProfileRoles: 'accounts/bulk-profile-roles',
  assignRoles: 'accounts/assign-role',
  unassignRoles: 'accounts/unassign-role',
  permissions: 'accounts/permissions',
  profiles: 'accounts/profiles', // users list page in accounts /users
  passwordReset: 'accounts/password-reset',
  objectLevelPermissions: 'accounts/create-object-level-permissions',
  objectLevelPermissionsRetrieve: 'accounts/object-level-permissions',
  updateUser: 'accounts/user',
  userDetail: 'accounts',
  login: 'token',
  loginV2: 'v2/token',
  emailConfirmation: 'email-confirm',
  approveOrRejectUsers: 'accounts/approve-users/',
  resetPwd: 'accounts/password-reset/confirm',
  setEncryptionAcct: 'accounts/set-encryption-account',
  verifyEncryptionAcct: 'accounts/verify-encryption-account',
  modelFields: 'accounts/model-fields',
  resetPassphrase: 'accounts/reset-encryption-account',
  chatUsers: 'accounts/profile-clients',
};

const chatUsersUrls = getModelUrls(accountsHost, models.chatUsers);
const registerUrls = getModelUrls(accountsHost, models.register);
const tokenLogoutUrls = getModelUrls(accountsHost, models.tokenLogout);
const tokenRefreshUrls = getModelUrls(accountsHost, models.tokenRefresh);
const resetPassphraseUrls = getModelUrls(accountsHost, models.resetPassphrase);
const modelFieldsUrls = getModelUrls(accountsHost, models.modelFields);
const approveOrRejectUsersUrls = getModelUrls(
  accountsHost,
  models.approveOrRejectUsers
);

const setEncryptionAcctUrls = getModelUrls(
  accountsHost,
  models.setEncryptionAcct
);
const verifyEncryptionAcctUrls = getModelUrls(
  accountsHost,
  models.verifyEncryptionAcct
);
const loginUrls = getModelUrls(accountsHost, models.login);
const loginV2Urls = getModelUrls(accountsHost, models.loginV2);
const resetPwdUrls = getModelUrls(accountsHost, models.resetPwd);
const emailConfirmationUrls = getModelUrls(
  accountsHost,
  models.emailConfirmation
);

const userProfileUrls = getModelUrls(accountsHost, models.userProfile);
const signOutUrl = () =>
  getModelUrls(accountsHost, models.signOut).list(
    `?next=${window.location.protocol}//${window.location.host}`
  );
const rolesUrls = getModelUrls(accountsHost, models.roles);
const profileRolesUrls = getModelUrls(accountsHost, models.profileRoles);
const bulkProfileRolesUrls = getModelUrls(
  accountsHost,
  models.bulkProfileRoles
);
const microservicesUrls = getModelUrls(accountsHost, models.microservices);
const modelsUrls = getModelUrls(accountsHost, models.models);
const usersUrls = getModelUrls(accountsHost, models.users);
const adminUsersUrls = getModelUrls(accountsHost, models.adminUsers);
const editProfileUrls = getModelUrls(accountsHost, models.editProfile);
const editProfilePictureUrls = getModelUrls(
  accountsHost,
  models.editProfilePicture
);
const passwordChangeUrls = getModelUrls(accountsHost, models.passwordChange);
const assignRolesUrls = getModelUrls(accountsHost, models.assignRoles);
const unassignRolesUrls = getModelUrls(accountsHost, models.unassignRoles);
const permissionsUrls = getModelUrls(accountsHost, models.permissions);
const profilesUrls = getModelUrls(accountsHost, models.profiles);
const forgotPwdUrls = getModelUrls(accountsHost, models.passwordReset);
const updateUserUrls = getModelUrls(accountsHost, models.updateUser);
const userDetailUrls = getModelUrls(accountsHost, models.userDetail);
const approveUserUrls = (id) =>
  `${accountsHost}/api/accounts/approvals/${id}/?approved=1`;
const objectLevelPermissionsUrls = getModelUrls(
  accountsHost,
  models.objectLevelPermissions
);
const objectLevelPermissionsRetrieveUrls = getModelUrls(
  accountsHost,
  models.objectLevelPermissionsRetrieve
);
const getCreateObjectLevelPermissionsUrl = (ms, model) =>
  `${objectLevelPermissionsUrls.list()}${ms}/${model}/`;
const getRetrieveObjectLevelPermissionsUrl = (ms, model, objectId) =>
  `${objectLevelPermissionsRetrieveUrls.list()}${ms}/${model}/${objectId}/`;

const methodsOptions = [
  { id: 'POST', label: 'POST' },
  { id: 'GET', label: 'GET' },
  { id: 'PUT', label: 'PUT' },
  { id: 'PATCH', label: 'PATCH' },
  { id: 'DELETE', label: 'DELETE' },
  { id: 'OPTIONS', label: 'OPTIONS' },
];

const viewsOptions = [
  {
    id: 'ListCreateAPIView-|-ListAPIView-|-CreateAPIView',
    label: 'List Create View',
  },
  {
    id: 'RetrieveAPIView-|-RetrieveUpdateAPIView-|-RetrieveUpdateDestroyAPIView-|-UpdateAPIView-|-DestroyAPIView',
    label: 'Detail View',
  },
  { id: 'APIView', label: 'Custom View' },
];

const createBulkPermissionsUrls = (ms, model) =>
  `${accountsHost}/api/accounts/create-bulk-object-level-permissions/${ms}/${model}/`;

const urls = {
  chatUsersUrls,
  approveOrRejectUsersUrls,
  emailConfirmationUrls,
  modelFieldsUrls,
  userProfileUrls,
  signOutUrl,
  profileRolesUrls,
  bulkProfileRolesUrls,
  rolesUrls,
  microservicesUrls,
  modelsUrls,
  usersUrls,
  adminUsersUrls,
  editProfileUrls,
  editProfilePictureUrls,
  passwordChangeUrls,
  assignRolesUrls,
  unassignRolesUrls,
  permissionsUrls,
  methodsOptions,
  viewsOptions,
  profilesUrls,
  forgotPwdUrls,
  objectLevelPermissionsUrls,
  objectLevelPermissionsRetrieveUrls,
  getCreateObjectLevelPermissionsUrl,
  getRetrieveObjectLevelPermissionsUrl,
  updateUserUrls,
  approveUserUrls,
  userDetailUrls,
  createBulkPermissionsUrls,
  loginUrls,
  loginV2Urls,
  resetPwdUrls,
  setEncryptionAcctUrls,
  verifyEncryptionAcctUrls,
  resetPassphraseUrls,
  tokenRefreshUrls,
  tokenLogoutUrls,
  registerUrls,
};

export default urls;
