import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { pmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { sprintMetasMeta } from '@config/meta/pm/pmMeta';
import { sprintRowsMap, sprintMetaProjectRowsMap } from '@config/handleRows/pm';
import { SprintsForm, SprintMetaProjectForm } from '@config/forms/pm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { sprintMetaUrls, sprintMetaProjectsUrls, sprintsUrls } = pmUrls;

export default function SprintMetasDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      sprintMetasMeta({
        data,
        queryClient,
      }),
    []
  );

  const projectsColOptions = React.useMemo(() => {
    return {
      programId: recordData?.program,
      queryClient: recordData?.program,
    };
  }, [recordData?.program]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/sprint-metas`, text: 'Sprint Meta' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={sprintMetaUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        {recordData?.program ? (
          <DetailAccordionView
            microservice={microservices.PM.name}
            model={microservices.PM.models.sprintMetaProject}
            columnKey='sprintMetaProjectsPM'
            columnOptions={projectsColOptions}
            label='Projects'
            prefix='0cy'
            labels={labels}
            editMode={editMode}
            urls={sprintMetaProjectsUrls}
            addNewForm={{
              getFields: ({ setFieldValue }) =>
                SprintMetaProjectForm({
                  programId: recordData?.program,
                  setFieldValue,
                }),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError,
                setFieldError
              ) => {
                const { project, ...rest } = values;

                if (!project?.id) {
                  setFieldError('project', 'This field is required');
                  return;
                }

                try {
                  const response = await createRecord({
                    values: {
                      ...rest,
                      project: values?.project?.id,
                      sprint_meta: recordId,
                    },
                    url: sprintMetaProjectsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  // notify(`Sprint Created`, {
                  //   type: 'SUCCESS',
                  // });
                  refetch();

                  return response?.data?.id;
                } catch (err) {
                  console.log(err);
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={sprintMetaProjectRowsMap}
            urlParams={`&sprint_meta=${recordId}`}
            customSortColumnName='created_at'
            sortColumnBy='dsc'
          />
        ) : null}
        <DetailAccordionView
          microservice={microservices.PM.name}
          model={microservices.PM.models.sprint}
          columnKey='sprintsPM'
          label='Sprints'
          prefix='08i'
          labels={labels}
          editMode={editMode}
          urls={sprintsUrls}
          addNewForm={{
            getFields: ({ errors }) => SprintsForm({ errors }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, sprint_meta: recordId },
                  url: sprintsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                // notify(`Sprint Created`, {
                //   type: 'SUCCESS',
                // });
                refetch();

                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={sprintRowsMap}
          urlParams={`&sprint_meta=${recordId}`}
          customSortColumnName='start_date'
          sortColumnBy='asc'
        />
      </Box>
    </Box>
  );
}
