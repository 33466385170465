import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { formsUrls } from '@config/routes';

const { generatePersonalityReportUrls } = formsUrls;

export default function GenerateReportPopup({ open, setOpen, responseId }) {
  const [reportURL, setReportURL] = React.useState('');
  const [stage, setStage] = React.useState('confirm');
  const user = useSelector(selectProfile);

  const handleClose = () => {
    setStage('confirm');
    setOpen(false);
  };

  const handleContinue = async () => {
    if (stage !== 'confirm') {
      return;
    }

    setStage('generating');

    try {
      const { data } = await createRecord({
        values: { responseId },
        url: generatePersonalityReportUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });

      setReportURL(`${data?.fileUrl}?d=1`);
      setStage('generated');
    } catch (error) {
      console.error(error?.response?.data ?? error?.message);
      setStage('error');
    }
  };

  const renderDialogContentText = () => {
    switch (stage) {
      case 'confirm':
        return `Are you sure you want to generate the personality report for this respondent? Please confirm your action to proceed with creating the detailed personality analysis.`;
      case 'generating':
        return `Your request to generate the personality report is being processed. This may take a few moments. Please wait while we prepare the comprehensive analysis.`;
      case 'generated':
        return `The personality report has been successfully generated. Click the link below to view the detailed report.`;
      case 'error':
        return `Unfortunately, there was an error generating the personality report. Please refresh the page and try again.`;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {stage === 'confirm' && 'Confirm Report Generation'}
        {stage === 'generating' && 'Report Generation in Progress'}
        {stage === 'generated' && 'Report Generated'}
        {stage === 'error' && 'Report Generation Failed'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {renderDialogContentText()}
        </DialogContentText>

        {stage === 'generated' && (
          <a
            style={{ display: 'block', color: '#4c50ef', marginTop: '16px' }}
            href={reportURL}
            target='_blank'
            rel='noopener noreferrer'
          >
            View Report
          </a>
        )}

        {stage === 'generating' && (
          <Box sx={{ width: '100%', mt: 3 }}>
            <LinearProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {['confirm', 'generated', 'error'].includes(stage) && (
          <Button onClick={handleClose}>Close</Button>
        )}

        {stage === 'confirm' && (
          <Button onClick={handleContinue}>Continue</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
