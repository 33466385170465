export default function getAddFormInitialValues(columns, view) {
  const initialValues = {};

  if (view === 'listView') {
    const values = columns.filter((column) => column.onForm);
    values.forEach((column) => {
      initialValues[column.field] = column.initialValue;
    });

    return initialValues;
  }

  if (view === 'DetailAccordionView') {
    const values = columns.filter((column) => column.onAccordionForm);
    values.forEach((column) => {
      initialValues[column.field] = column.initialValue;
    });
    return initialValues;
  }
}
