import * as React from 'react';
import { Box } from '@mui/system';
import DetailAccordion from '@components/DetailAccordion';

export default function DetailAccordionView({
  columnKey,
  columnOptions,
  label,
  pageHeaderFormHeaderText,
  urls,
  urlParams,
  addNewForm,
  handleRowMap,
  rowActions,
  handleFunctions,
  microservice = '',
  model = '',
  headerContent,
  startFetching,
  customSortColumnName,
  sortColumnBy,
  getDetailPanelContent,
  editMode,
  labels,
  prefix,
  onFormCancel,
  hideSearch,
  autoHeight,
  dynamicActions,
  externalExpanded,
  setExternalExpanded,
  disabled = false,
  ...rest
}) {
  const [internalExpanded, setInternalExpanded] = React.useState(false);

  // Determine if the accordion is controlled externally
  const isControlledExternally =
    externalExpanded !== undefined && setExternalExpanded !== undefined;

  // Use external state if controlled externally, otherwise use internal state
  const isExpanded = isControlledExternally
    ? externalExpanded
    : internalExpanded;

  // Effect to sync internal state with external prop changes, if controlled externally
  React.useEffect(() => {
    if (isControlledExternally) {
      setInternalExpanded(externalExpanded);
    }
  }, [externalExpanded, isControlledExternally, internalExpanded]);

  const handleChange = (panel) => (event, isExpanded) => {
    if (isControlledExternally) {
      // Use external function to set expanded state
      setExternalExpanded && setExternalExpanded(isExpanded ? panel : false);
    } else {
      // Update internal state
      setInternalExpanded(isExpanded ? panel : false);
    }
  };

  return (
    <Box>
      <DetailAccordion
        disabled={disabled}
        expanded={isExpanded}
        onChange={handleChange(label)}
        label={label}
        pageHeaderFormHeaderText={pageHeaderFormHeaderText}
        addNewForm={addNewForm}
        microservice={microservice}
        model={model}
        urls={urls}
        columnKey={columnKey}
        columnOptions={columnOptions}
        handleRowMap={handleRowMap}
        urlParams={urlParams}
        handleFunctions={handleFunctions}
        rowActions={rowActions}
        headerContent={headerContent}
        startFetching={
          typeof startFetching !== 'undefined' ? startFetching : isExpanded
        }
        customSortColumnName={customSortColumnName}
        sortColumnBy={sortColumnBy}
        getDetailPanelContent={getDetailPanelContent}
        editMode={editMode}
        labels={labels}
        prefix={prefix}
        onFormCancel={onFormCancel}
        hideSearch={hideSearch}
        autoHeight={autoHeight}
        dynamicActions={dynamicActions}
        {...rest}
      />
    </Box>
  );
}
