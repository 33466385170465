import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { cmsV2Urls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { pageGroupRowsMap } from '@config/handleRows/cms-v2';
import { PageGroup } from '@config/forms/cms-v2';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { pageGroupsUrls } = cmsV2Urls;

export default function PageGroups({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        nodeAPI
        label='Page Groups'
        prefix='0ug'
        labels={labels}
        editMode={editMode}
        microservice={microservices.CMS.name}
        model={microservices.CMS.models.pageGroup}
        urls={pageGroupsUrls}
        columnKey='pageGroup'
        addNewForm={{
          getFields: ({ setFieldValue }) => PageGroup({ setFieldValue }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError,
            setFieldError
          ) => {
            try {
              const { defaultPageId, domainId, menuId, automataId, ...rest } =
                values;

              if (!domainId?.id) {
                setFieldError('domainId', 'This field is required');
                return;
              }

              const response = await createRecord({
                values: {
                  defaultPageId: defaultPageId?.id,
                  domainId: domainId?.id,
                  menuId: menuId?.id,
                  automataId: automataId?.id,
                  ...rest,
                },
                url: pageGroupsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              if (err.response.data.statusCode === 422) {
                notify(`${err.response.data.message}`, { type: 'ERROR' });
              } else {
                handleCreateFormErrors({ err, setError, notify, values });
              }
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={pageGroupRowsMap}
      />
    </Box>
  );
}
