import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import SocialPlatformCard from '@ui/SocialMedia/SocialPlatformCard';
import { createRecord, deleteRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { marketingUrls } from '@config/routes';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
// import FacebookConnectBtn from '@ui/SocialMedia/FacebookConnectBtn';
import AccountVisibilityPopup from '@ui/SocialMedia/AccountVisibilityPopup';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';

const {
  twitterAccessTokenUrls,
  linkedinAccessTokenUrls,
  userSmAccessUrls, // prettier :<
} = marketingUrls;

export default function ConnectChannel() {
  const [notify] = useToast();
  const navigate = useNavigate();
  const user = useSelector(selectProfile);
  const [linkedInCode, setLinkedInCode] = React.useState('');
  const [visibilityModal, setVisibilityModal] = React.useState(false);
  const [visibilitySubmitting, setVisibilitySubmitting] = React.useState(false);
  const [status, setStatus] = React.useState({});
  const [connectedAccounts, setConnectedAccounts] = React.useState([]);
  const isTwitter = status?.platform === 'twitter';
  const isConnecting = status?.status === 'connecting';

  const { linkedInLogin: handleLinkedInConnect } = useLinkedIn({
    scope: 'r_emailaddress openid profile r_liteprofile w_member_social',
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: `${window?.location?.origin}/connect-linkedin`,
    onSuccess: (code) => {
      setLinkedInCode(code);
      setVisibilityModal(true);
    },
    onError: ({ error }) => {
      console.log(`obi-linkedin`, error);

      if (error !== 'user_closed_popup') {
        notify('Failed to connect to LinkedIn', {
          type: 'ERROR',
        });
      }
    },
  });

  const {
    data: userAccounts,
    isLoading: loadingUserAccounts,
    refetch: refetchUserAccounts,
  } = useRQuery({
    key: [`user-sm-accounts`, user?.token, user?.actAs],
    url: userSmAccessUrls.list(`?user=${user?.details?.id}`),
    config: getAuthHeader(user?.token, user?.actAs),
    options: { enabled: !!(user?.token && user?.details?.id) },
  });

  const handleTwitterConnect = async () => {
    try {
      setStatus({ platform: 'twitter', status: 'connecting' });

      const { data } = await createRecord({
        values: {
          consumerKey: process.env.REACT_APP_TWITTER_CONSUMER_KEY,
          consumerSecret: process.env.REACT_APP_TWITTER_CONSUMER_SECRET,
          redirectURI: `${window?.location?.origin}/connect-twitter`,
        },
        url: twitterAccessTokenUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });

      const requestToken = data?.oauth_token;
      const authUrl = `https://api.twitter.com/oauth/authenticate?oauth_token=${requestToken}`;

      window.location.href = authUrl;
    } catch (err) {
      notify('Failed to connect to Twitter', {
        type: 'ERROR',
      });
      setStatus({ platform: 'twitter', status: 'failed' });
    }
  };

  // const handleFacebookConnect = async ({ accessToken, userID }) => {
  //   try {
  //     const { access_token } = await axios.get(
  //       `https://graph.facebook.com/v14.0/oauth/access_token?
  //           grant_type=fb_exchange_token&
  //           client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&
  //           client_secret=${process.env.REACT_APP_FACEBOOK_CLIENT_SECRET}&
  //           fb_exchange_token=${accessToken}`
  //     );

  //     const pages = await axios.get(
  //       `https://graph.facebook.com/${userID}/accounts?access_token=${access_token}`
  //     );

  //     if (!pages.data) throw Error('Invalid key: pages.data not found');

  //     console.log('pages', pages);
  //   } catch (err) {
  //     notify('Failed to connect to Facebook', {
  //       type: 'ERROR',
  //     });
  //   }
  // };

  const saveLinkedInToken = async ({ accountPrivate }) => {
    try {
      setVisibilitySubmitting(true);
      await createRecord({
        values: {
          private: accountPrivate,
          code: linkedInCode,
          redirect_uri: `${window?.location?.origin}/connect-linkedin`,
          client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
          client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
        },
        url: linkedinAccessTokenUrls.list(),
        token: user?.token,
        actAs: user?.actAs,
      });

      setVisibilityModal(false);
      navigate('/social-media-campaigns');
    } catch (err) {
      console.log('obi-err', err.message);
      notify(
        'Looks like we hit a tiny bump while updating your account. Please try again',
        {
          type: 'ERROR',
        }
      );
    } finally {
      setVisibilitySubmitting(false);
    }
  };

  const handleRemovePlatform = async ({ name, action }) => {
    if (action === 'removePlatform') {
      try {
        const found = connectedAccounts.find((r) => r.name === name);

        if (!found) return;

        await deleteRecord({
          url: userSmAccessUrls.detail(found.id),
          token: user.token,
          actAs: user?.actAs,
        });

        refetchUserAccounts();
      } catch (err) {
        console.log(err);
        notify('Failed to remove platform', {
          type: 'ERROR',
        });
      }
    }
  };

  React.useEffect(() => {
    if (
      !userAccounts ||
      !Array.isArray(userAccounts.results) ||
      loadingUserAccounts
    ) {
      return;
    }

    setConnectedAccounts(
      userAccounts.results.map((r) => ({
        id: r.id,
        name: r.social_media,
      }))
    );
  }, [userAccounts]);

  return (
    <Box>
      <Container maxWidth="md">
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '32px',
            lineHeight: '44px',
            mb: 1,
          }}
          variant="h2"
        >
          Connect a platform
        </Typography>

        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            color: 'rgb(99, 99, 99)',
          }}
        >
          Connecting platforms is the first step to getting up and running
        </Typography>

        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={4}>
            <SocialPlatformCard
              name="Twitter"
              type="Profile"
              icon="/img/twitter-logo.png"
              // connected={connectedAccounts.find((r) => r.name === 'Twitter')}
              handleClick={handleTwitterConnect}
              connecting={isConnecting}
              disabled={isTwitter && isConnecting}
              handleMenuClick={handleRemovePlatform}
            />
          </Grid>
          <Grid item xs={4}>
            <SocialPlatformCard
              icon="/img/linkedin-logo.png"
              name="Linkedin"
              type="Page or Profile"
              iconSize="50px"
              // connected={connectedAccounts.find((r) => r.name === 'Linkedin')}
              handleClick={handleLinkedInConnect}
              handleMenuClick={handleRemovePlatform}
            />
          </Grid>
          <Grid item xs={4}>
            <SocialPlatformCard
              icon="/img/facebook-logo.png"
              name="Facebook"
              type="Page"
              iconSize="50px"
              comingSoon
              // connected={connectedAccounts.find((r) => r.name === 'Facebook')}
              // getButton={() => (
              //   <FacebookConnectBtn handleClick={handleFacebookConnect} />
              // )}
              // handleMenuClick={handleRemovePlatform}
            />
          </Grid>
          <Grid item xs={4}>
            <SocialPlatformCard
              icon="/img/instagram-logo.png"
              name="Instagram"
              type="Profile"
              iconSize="50px"
              comingSoon
            />
          </Grid>
          <Grid item xs={4}>
            <SocialPlatformCard
              icon="/img/tiktok-logo.png"
              name="TikTok"
              type="Profile"
              iconSize="50px"
              comingSoon
            />
          </Grid>
        </Grid>

        <AccountVisibilityPopup
          open={visibilityModal}
          setOpen={setVisibilityModal}
          isSubmitting={visibilitySubmitting}
          handleClick={({ accountPrivate }) => {
            saveLinkedInToken({ accountPrivate });
          }}
        />
      </Container>
    </Box>
  );
}
