import React from 'react';
import useRQuery from '@hooks/useRQuery';
import { webChatUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import CustomerMessage from '@ui/WebChat/CustomerMessage';
import ChatMessage from '@ui/WebChat/ChatMessage';
import DetailPageHeader from '@components/DetailPageHeader';

const { roomMessagesUrls } = webChatUrls;

export default function ClosedRoom() {
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const msgContainer = React.useRef(null);

  const {
    data: room,
    isFetching: fetchingRoom,
    isError,
  } = useRQuery({
    key: ['closed-room', recordId, user.token, user?.actAs],
    url: roomMessagesUrls.detail(recordId),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  if (isError) {
    return <div>Failed to fetch the record</div>;
  }

  if (fetchingRoom || !room) {
    return <div>loading...</div>;
  }

  return (
    <Box>
      <Box>
        <DetailPageHeader
          items={[
            { to: '/rooms-board', text: 'Rooms' },
            {
              text: `${room?.customer?.first_name} ${room?.customer?.last_name}`,
            },
          ]}
        />
      </Box>

      <Box
        sx={{
          background: '#EBECF0',
          width: 'calc(100% - 250px)',
          height: '80vh',
          mx: 'auto',
        }}
      >
        <Box
          sx={{
            px: 1,
            display: 'flex',
            height: '56px',
            alignItems: 'center',
            borderBottom: '1px solid #cfcfcf',
          }}
        >
          <ChatMessage
            name={`${room?.customer?.first_name} ${room?.customer?.last_name}`}
            noHover
          />
        </Box>
        <Box
          ref={msgContainer}
          sx={{ overflowY: 'auto', height: 'calc(100% - 136px)', p: 2 }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
            <CustomerMessage msg={room?.customer?.question} customer />
            {room.messages.map((msg) => (
              <CustomerMessage
                msg={msg.message}
                date={msg.created_at}
                customer={msg.message_from === 'Customer'}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
