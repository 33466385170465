import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import { createRecord, updateRecord } from '@config/functions/requests';
import { companiesRowsMap } from '@config/handleRows/crm';
import { CompaniesForm } from '@config/forms/crm';
import { microservices } from '@config/constants';
import {
  handleErrorsWithNotify,
  handleCreateFormErrors,
  parseJSON,
} from '@config/functions/helperFunctions';
import { AutocompleteField } from '@ui/Inputs';
import useAutocomplete from '@hooks/useAutocomplete';
import { useQueryClient } from 'react-query';
import TerritoryDropdown from '@dropdown-forms/crm/TerritoryDropdown';
import ColorPicker from '@components/EditableTableComps/ColorPicker';

const {
  companiesUrls,
  fetchBulkCompanyWebsitesUrls,
  fetchBulkCompanyIntelligenceUrls,
  territoriesUrls,
} = crmUrls;

export default function Companies({ editMode, labels }) {
  const [notify, update] = useToast();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);

  const [init, setInit] = React.useState(false);
  const [territory, setTerritory] = React.useState(null);
  const [territoryTerm, setTerritoryTerm] = React.useState('');
  const [territorySelected, setTerritorySelected] = React.useState(false);

  const { data: territories, isFetching: fetchingTerritories } =
    useAutocomplete({
      key: ['companies-pinned-territories'],
      getUrl: territoriesUrls.list,
      inputValue:
        init && territory.label === territoryTerm ? '' : territoryTerm,
      selected: territorySelected,
    });

  const territoriesOrArray = territories || [];
  const territoriesWithAll = [
    { id: '5c84bcb6-18ac-423e-af3a-4d4fc21ca51b', label: 'All' },
    ...territoriesOrArray,
  ];

  const handleColorSelect = async ({ rows, setSelectedRows, color } = {}) => {
    const tId = notify('Saving....', { loading: true });
    try {
      await Promise.allSettled(
        rows.map((row) =>
          updateRecord({
            url: companiesUrls.detail(row),
            values: {
              _color: color,
            },
            token: user.token,
            actAs: user?.actAs,
          })
        )
      );
      setSelectedRows([]);
      queryClient.invalidateQueries(['Companies-listData']);
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    if (!init && !territory && !fetchingTerritories && !territories) {
      const { data } = parseJSON(
        localStorage.getItem(`${user.details.id}-pinned-territory`)
      );
      if (!data) return;
      setTerritorySelected(true);
      setTerritory(data);
      setTerritoryTerm('');
      setInit(true);
      queryClient.invalidateQueries('Companies-listData');
    }
  }, [init, territory, user, fetchingTerritories, territories]);

  return (
    <Box>
      <ListView
        label="Companies"
        editMode={editMode}
        labels={labels}
        prefix="0z4"
        refetchInterval={5000}
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.companies}
        urls={companiesUrls}
        columnKey="companiesCRM"
        customOptionsOnRight={() => (
          <Box sx={{ mr: 2, minWidth: '240px' }}>
            <TerritoryDropdown
              refetch={({ id, name }) => {
                setTerritory({ id, label: name });
                queryClient.invalidateQueries(['company-territory']);
              }}
            >
              <AutocompleteField
                label="Territory"
                value={territory}
                inputValue={territoryTerm}
                options={territoriesWithAll}
                isLoading={fetchingTerritories}
                setValue={(val) => {
                  setTerritory(val);
                  localStorage.setItem(
                    `${user.details.id}-pinned-territory`,
                    JSON.stringify(val)
                  );
                  setTerritorySelected(true);
                  queryClient.invalidateQueries('Companies-listData');
                }}
                setInputValue={(val) => {
                  console.log('obi-setInputValue', val);
                  setTerritoryTerm(val);
                  setTerritorySelected(false);
                }}
              />
            </TerritoryDropdown>
          </Box>
        )}
        customBulkHeaderButton={(selectedRows, data, setSelectedRows) => {
          return (
            <>
              <ColorPicker
                onChange={(color) =>
                  handleColorSelect({
                    rows: selectedRows,
                    setSelectedRows,
                    color,
                  })
                }
              />
            </>
          );
        }}
        reminderBulkActionType="company"
        addFunctions={{
          fetchURLs: async ({
            selectedRows,
            searchTerm,
            selectAll,
            setSelectedRows,
          }) => {
            try {
              const response = await createRecord({
                values: {
                  all: selectAll,
                  ids: selectAll ? [] : selectedRows,
                  search_query: searchTerm,
                },
                url: fetchBulkCompanyWebsitesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              setSelectedRows([]);
              notify(
                response?.data?.company ||
                  'Fetching related urls we will let you know, when we are done.',
                { type: 'SUCCESS' }
              );
            } catch (err) {
              handleErrorsWithNotify({ err, notify });
            }
          },
          buildIntelligence: async ({
            selectedRows,
            searchTerm,
            selectAll,
            setSelectedRows,
          }) => {
            try {
              const response = await createRecord({
                values: {
                  all: selectAll,
                  ids: selectAll ? [] : selectedRows,
                  search_query: searchTerm,
                },
                url: fetchBulkCompanyIntelligenceUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              setSelectedRows([]);
              notify(
                response?.data?.company ||
                  'Building intelligence: we will let you know, when we are done.',
                { type: 'SUCCESS' }
              );
            } catch (err) {
              handleErrorsWithNotify({ err, notify });
            }
          },
        }}
        tableBulkOptions={[
          { id: 'addToTerritory', text: 'Add to territory' },
          { id: 'addReminder', text: 'Add reminder' },
          { id: 'createCompanyOpportunity', text: 'Create opportunity' },
          { id: 'fetchURLs', text: 'Fetch URLs' },
          { id: 'buildIntelligence', text: 'Build Intelligence' },
        ]}
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            CompaniesForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { country, ...rest } = values;

              const response = await createRecord({
                values: {
                  country: country?.id,
                  ...rest,
                },
                url: companiesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={companiesRowsMap}
        urlParams={
          territory
            ? territory.label === 'All'
              ? ''
              : `&territory=${territory.id}`
            : ''
        }
      />
    </Box>
  );
}
