import * as React from 'react';
import { Box, Card, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import useRQuery from '@hooks/useRQuery';
import { calendarUrls } from '@config/routes';
import { Formik, Form } from 'formik';
import { TextField } from '@components/Inputs';
import Loader from '@components/Loader';
import Button from '@ui/Button';
import * as Yup from 'yup';
import PublicIcon from '@mui/icons-material/Public';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import {
  handleCreateFormErrors,
  capitalizeFirstLetter,
} from '@config/functions/helperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ErrorScreen from '@components/ErrorScreen';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useParams, useSearchParams } from 'react-router-dom';
import SEOHeader from '@components/SEOHeader';

const { getSlotsFromLinkUrls } = calendarUrls;

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const BoldSelectedDay = styled(PickersDay)(({ theme }) => ({
  '&.Mui-selected': {
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
  },
}));

//higlight the dates in highlightedDays arra
const ServerDay = (props) => {
  const { slots = [], day, outsideCurrentMonth } = props;

  const matchedStyles =
    !outsideCurrentMonth && slots.includes(formatDate(day))
      ? { backgroundColor: '#eef5ff', color: '#015fe6', fontWeight: 'bold' } // Add your custom styles for highlighting Saturdays
      : {};

  return <BoldSelectedDay {...props} sx={{ ...matchedStyles }} />;
};

export default function SelectSlotDetailView() {
  const { id: recordId } = useParams();
  const [queryParams] = useSearchParams();
  const firstName = queryParams.get('first_name');
  const lastName = queryParams.get('last_name');
  const initialEmail = queryParams.get('email');
  const [date, setDate] = React.useState(null);
  const [slot, setSlot] = React.useState(null);
  const [slots, setSlots] = React.useState([]);
  const [notify] = useToast();
  const [success, setSuccess] = React.useState(false);

  const { data, isLoading, isError, refetch } = useRQuery({
    key: ['data', recordId],
    url: getSlotsFromLinkUrls.detail(recordId),
    options: { enabled: !!recordId },
  });

  React.useEffect(() => {
    if (data && data?.dates && Object.keys(data?.dates).length && !date) {
      const initialDate = new Date(Object.keys(data.dates)[0]);
      setDate(initialDate);
      setSlots(data.dates[formatDate(initialDate)] || []);
    }
  }, [data, date]);

  if (isLoading || !data) {
    return (
      <Box
        sx={{
          background: '#f2f2f2',
          height: '100vh',
          py: 3,
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Loader />
      </Box>
    );
  }

  if (isError || (data && !Object.keys(data['dates']).length)) {
    return (
      <Box
        sx={{
          background: '#f2f2f2',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ErrorScreen
          text={
            data && !Object.keys(data['dates']).length
              ? 'Sorry, no slots are available.'
              : 'Something went wrong, try refreshing'
          }
        />
      </Box>
    );
  }

  return (
    <Box>
      <SEOHeader title={data.link_details.link_name || 'Slots'} />
      <Box sx={{ py: 6 }}>
        <Card
          sx={{ maxWidth: '900px', mx: 'auto', p: 2, mb: 2 }}
          variant='outlined'
        >
          <Box sx={{ display: 'flex', p: 1 }}>
            <Box sx={{ width: 285 }}>
              <Typography
                variant='h6'
                component='div'
                sx={{ fontWeight: 400, fontSize: 18 }}
              >
                {capitalizeFirstLetter(data.link_details.organiser_name)}
              </Typography>
              <Typography
                variant='h4'
                gutterBottom
                component='div'
                sx={{ mt: '2px', fontSize: 25, fontWeight: 401 }}
              >
                {data.link_details.link_name}
              </Typography>

              <Box
                sx={{
                  color: '#454545',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
              >
                {data?.link_details?.link_description}
              </Box>

              {slot ? (
                <Box sx={{ display: 'flex', mt: '10px' }}>
                  <AccessTimeIcon
                    sx={{ mt: '1px', mr: '4px', fontSize: '22px' }}
                  />
                  <Typography
                    variant='h6'
                    component='div'
                    sx={{ fontSize: '16px' }}
                  >
                    {date.toDateString()}{' '}
                    {new Date(slot.timestamp).toLocaleTimeString('en-US', {
                      hour12: true,
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box sx={{ mx: '10px' }}>
              <Divider orientation='vertical' />
            </Box>
            <Box sx={{ pl: '15px', width: 544 }}>
              {!slot ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant='h4'
                      gutterBottom
                      component='div'
                      sx={{ fontWeight: 500, fontSize: 25, mb: 0 }}
                    >
                      Select a Date & Time
                    </Typography>
                    <Box sx={{ display: 'flex', mt: '10px' }}>
                      <PublicIcon
                        sx={{ mt: '1px', mr: '4px', fontSize: '22px' }}
                      />
                      <Typography
                        variant='h6'
                        component='div'
                        sx={{ fontSize: '16px' }}
                      >
                        {Intl.DateTimeFormat().resolvedOptions().timeZone}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ mt: 2, p: 0 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid container spacing={3}>
                        <Grid item xs={date ? 8 : 12}>
                          <StaticDatePicker
                            displayStaticWrapperAs='desktop'
                            slots={{
                              day: ServerDay,
                            }}
                            slotProps={{
                              day: {
                                slots: Object.keys(data?.dates),
                              },
                            }}
                            shouldDisableDate={(date) => {
                              if (
                                Object.keys(data.dates).includes(
                                  formatDate(date)
                                )
                              ) {
                                return false;
                              }

                              return true;
                            }}
                            minDate={new Date()}
                            value={date}
                            onChange={(newDate) => {
                              const slots = data?.dates[formatDate(newDate)];
                              setDate(newDate);
                              setSlots(slots || []);
                            }}
                          />
                        </Grid>
                        {date ? (
                          <Grid item xs={4}>
                            <Box sx={{ overflowY: 'auto', maxHeight: '370px' }}>
                              {slots.map((slot) => (
                                <Button
                                  variant='outlined'
                                  color='primary'
                                  label={`${new Date(
                                    slot.timestamp
                                  ).toLocaleTimeString('en-US', {
                                    hour12: true,
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  })}`}
                                  fullWidth
                                  onClick={() => setSlot(slot)}
                                  sx={{ mt: 1 }}
                                />
                              ))}
                            </Box>
                          </Grid>
                        ) : null}
                      </Grid>
                    </LocalizationProvider>
                  </Box>
                </>
              ) : success ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyCenter: 'center',
                    alignItems: 'center',
                    padding: '8px 12px',
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      width: '36px',
                      height: '36px',
                      margin: 'auto',
                      color: '#8cc63f',
                      marginBottom: '12px',
                    }}
                    icon={faCheck}
                  />
                  <Typography sx={{ mb: '12px' }} variant='h5'>
                    Thank you for selecting a slot
                  </Typography>
                  <Typography sx={{ mb: '12px' }} variant='p'>
                    You should shortly receive an email confirmation of this
                    time slot.
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography
                    variant='h4'
                    gutterBottom
                    component='div'
                    sx={{ fontWeight: 500, fontSize: 25, mb: 3 }}
                  >
                    Enter Details
                  </Typography>
                  <Box sx={{ mt: 2, p: 0 }}>
                    <Formik
                      initialValues={{
                        first_name: firstName ?? '',
                        last_name: lastName ?? '',
                        email: initialEmail ?? '',
                      }}
                      validationSchema={Yup.object({
                        first_name: Yup.string().max(250).required(),
                        last_name: Yup.string().max(250).required(),
                        email: Yup.string().email().required(),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm, setFieldError }
                      ) => {
                        try {
                          await createRecord({
                            values: {
                              first_name: firstName ?? values.first_name,
                              last_name: lastName ?? values.last_name,
                              email: initialEmail ?? values.email,
                              slot: slot.id,
                            },
                            url: getSlotsFromLinkUrls.detail(recordId),
                          });
                          resetForm();
                          refetch();
                          setSuccess(true);
                        } catch (err) {
                          console.log(err);
                          handleCreateFormErrors({
                            err,
                            setError: (error) => {
                              Object.entries(error).map((err) =>
                                setFieldError(err[0], err[1])
                              );
                            },
                            values,
                            notify,
                          });
                        } finally {
                          setSubmitting(false);
                        }
                      }}
                    >
                      {({ isSubmitting, values }) => (
                        <Form noValidate>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextField
                                label='First Name'
                                name='first_name'
                                disabled={!!firstName}
                                required
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                label='Last Name'
                                name='last_name'
                                disabled={!!lastName}
                                required
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                label='Email'
                                name='email'
                                disabled={!!initialEmail}
                                required
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  display: 'flex',
                                }}
                              >
                                <Box sx={{ ml: 'auto' }}>
                                  <Button
                                    label='Back'
                                    onClick={() => setSlot(null)}
                                    sx={{ mr: 1 }}
                                    disabled={isSubmitting}
                                  />
                                  <Button
                                    type='submit'
                                    label={
                                      isSubmitting
                                        ? 'loading...'
                                        : 'Schedule Event'
                                    }
                                    variant='contained'
                                    disabled={isSubmitting}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
