import * as React from 'react';
import MuiFileField from '@ui/Inputs/accounts/FileField';
import { useField, useFormikContext } from 'formik';

export default function FileField({ ref, label, error, color, ...rest }) {
  const [field] = useField(rest);
  const { setFieldValue } = useFormikContext();

  return (
    <MuiFileField
      ref={ref}
      id={field.name}
      name={field.name}
      onBlur={(e) => {
        setFieldValue(
          field.name,
          e.target.files && e.target.files.length ? e.target.files[0] : null
        );
      }}
      label={label}
      accept='.pdf, .doc, .docx, audio/mpeg, audio/vorbis, image/png, image/jpeg'
      error={error}
      color={color}
    />
  );
}
