import { Box, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '@components/KanbanBoardHeader';
import Loader from '@components/Loader';
import { selectProfile } from '@redux/profileSlice';
import { selectRoomsBoard, setRooms } from '@redux/roomsBoardSlice';
import useWebSocket from 'react-use-websocket';
import { webChatUrls } from '@config/routes';
import ErrorScreen from '@components/ErrorScreen';
import List from '@ui/KanbanBoards/List';
import Card from '@ui/KanbanBoards/Card';
import { Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Online Switch' } };
const { roomsUrls } = webChatUrls;

export default function RoomsBoard({ labels, editMode }) {
  const dispatch = useDispatch();
  const user = useSelector(selectProfile);
  const { rooms } = useSelector(selectRoomsBoard);
  const [online, setOnline] = React.useState(false);

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    roomsUrls.list(`?access_token=${user?.token}`)
  );

  React.useEffect(() => {
    if (!lastMessage) return;
    try {
      const data = JSON.parse(lastMessage.data);
      const { type, rooms, client } = data;
      if (type === 'Error') {
        dispatch(setRooms(data));
        return;
      }
      dispatch(setRooms(rooms));
      setOnline(client?.online);
    } catch (err) {
      console.log(err.message);
    }
  }, [lastMessage, dispatch]);

  const handleOnlineClick = (online) => {
    sendMessage(JSON.stringify({ client_online: online }));
    setOnline(online);
  };

  if ((rooms && rooms?.type === 'Error') || readyState === 3) {
    console.log('rooms', rooms);
    console.log('readyState', readyState);
    return (
      <ErrorScreen text='Failed to fetch the resource from server' img='' />
    );
  }

  if (!readyState) {
    return (
      <Box sx={{ py: 16 }}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title='Rooms'
        prefix='0py'
        labels={labels}
        editMode={editMode}
        content={
          <Box sx={{ ml: 3, display: 'flex', alignItems: 'center' }}>
            <Typography>Online</Typography>
            <Switch
              checked={online}
              onChange={(e) => handleOnlineClick(e.target.checked)}
              {...label}
            />
          </Box>
        }
      />

      {!readyState || !Array.isArray(rooms) ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            {rooms?.map((list, idx) => (
              <List list={list} idx={idx}>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: '1',
                    padding: '0.5rem',
                    borderRadius: '4px',
                  }}
                >
                  {Array.isArray(list.items) &&
                    list.items.map((item) => (
                      <Card>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to={
                            list.name === 'Closed'
                              ? `/closed-rooms/${item?.id}`
                              : `/web-chat?room=${item?.id}`
                          }
                        >
                          <span
                            style={{ textDecoration: 'none', color: '#333' }}
                          >
                            {item?.customer_name} - {item?.id?.substring(0, 8)}
                          </span>
                        </Link>
                      </Card>
                    ))}
                </Box>
              </List>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
