import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import PageTemplates from '@pages/CMS-V2/PageTemplate';
import PageTemplateDetail from '@pages/CMS-V2/PageTemplate/detail';
import Pages from '@pages/CMS-V2/Page';
import PageDetail from '@pages/CMS-V2/Page/detail';
import PageGroups from '@pages/CMS-V2/PageGroup';
import PageGroupsDetail from '@pages/CMS-V2/PageGroup/detail';
import Domains from '@pages/CMS-V2/Domain';
import Menus from '@pages/CMS-V2/Menu';
import MenuDetail from '@pages/CMS-V2/Menu/detail';

export default function () {
  return (
    <>
      <Route
        path='/page-templates'
        element={<WithLayout comp={PageTemplates} />}
      />
      <Route
        path='/page-templates/:id'
        element={<WithLayout comp={PageTemplateDetail} />}
      />
      <Route path='/cms-pages' element={<WithLayout comp={Pages} />} />
      <Route path='/cms-pages/:id' element={<WithLayout comp={PageDetail} />} />
      <Route path='/page-groups' element={<WithLayout comp={PageGroups} />} />
      <Route
        path='/page-groups/:id'
        element={<WithLayout comp={PageGroupsDetail} />}
      />
      <Route path='/subdomains' element={<WithLayout comp={Domains} />} />
      <Route path='/menus' element={<WithLayout comp={Menus} />} />
      <Route path='/menus/:id' element={<WithLayout comp={MenuDetail} />} />
    </>
  );
}
