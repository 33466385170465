import React from 'react';
import Stack from '@mui/material/Stack';
import PlatformAccountsList from '@ui/SocialMedia/PlatformAccountsList';

export default function RenderPlatforms({
  userAccounts,
  selectedAccounts,
  handleAccounts,
}) {
  const userAccts = userAccounts?.results ? userAccounts : { results: [] };

  const twitterAccounts = userAccts.results.filter(
    (r) => r.social_media === 'Twitter'
  );
  const linkedinAccounts = userAccts.results.filter(
    (r) => r.social_media === 'Linkedin'
  );
  const facebookAccounts = userAccts.results.filter(
    (r) => r.social_media === 'Facebook'
  );

  const handleSelectAccount = ({ checked, account }) => {
    const found = selectedAccounts.find((r) => r.id === account.id);

    if (!found && checked) {
      handleAccounts((state) => [...state, account]);
      return;
    }

    if (found && !checked) {
      handleAccounts((state) => state.filter((r) => r.id !== account.id));
      return;
    }
  };

  return (
    <Stack spacing={0.5}>
      {Array.isArray(twitterAccounts) && twitterAccounts.length ? (
        <PlatformAccountsList
          name='Twitter:'
          accounts={twitterAccounts}
          selectedAccounts={selectedAccounts}
          handleClick={handleSelectAccount}
        />
      ) : null}
      {Array.isArray(linkedinAccounts) && linkedinAccounts.length ? (
        <PlatformAccountsList
          name='Linkedin:'
          accounts={linkedinAccounts}
          selectedAccounts={selectedAccounts}
          handleClick={handleSelectAccount}
        />
      ) : null}
      {Array.isArray(facebookAccounts) && facebookAccounts.length ? (
        <PlatformAccountsList
          name='Facebook:'
          accounts={facebookAccounts}
          selectedAccounts={selectedAccounts}
          handleClick={handleSelectAccount}
        />
      ) : null}
    </Stack>
  );
}
