import React from 'react';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import { lmsUrls, nodeDriveUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { lessonHandleRow } from '@config/handleRows/lms';
import { LessonForm } from '@config/forms/lms';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { lessonsUrls } = lmsUrls;
const { filesUrls } = nodeDriveUrls;

export default function Lessons({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const lessonColOptions = React.useMemo(() => {
    return {
      queryClient,
    };
  }, [queryClient]);

  return (
    <Box>
      <ListView
        nodeAPI
        label='Lessons'
        prefix='0bc'
        labels={labels}
        editMode={editMode}
        urls={lessonsUrls}
        columnKey='lesson'
        columnOptions={lessonColOptions}
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            LessonForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError,
            setFieldError
          ) => {
            try {
              const { homeworkId, thumbnail, presentation, video, ...rest } =
                values;

              let fileUrl;
              let presentationUrl;
              let videoUrl;

              if (!video && !presentation) {
                setFieldError('video', 'Please add a video or a presentation');
                return;
              }

              if (thumbnail) {
                try {
                  const formData = new FormData();
                  formData.append(
                    'client',
                    user?.actAs?.id || user?.details?.client
                  );
                  formData.append('created_by', user?.details?.id);
                  formData.append('anonymous_can_see_it', true);
                  formData.append('file', thumbnail);

                  const { data: fileData } = await createRecord({
                    values: formData,
                    url: filesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  fileUrl = fileData.fileUrl;
                } catch {}
              }

              if (thumbnail && !fileUrl) {
                setFieldError('thumbnail', 'Failed to upload thumbnail');
                return;
              }

              if (presentation) {
                try {
                  const formData = new FormData();
                  formData.append(
                    'client',
                    user?.actAs?.id || user?.details?.client
                  );
                  formData.append('created_by', user?.details?.id);
                  formData.append('anonymous_can_see_it', true);
                  formData.append('file', presentation);

                  const { data: fileData } = await createRecord({
                    values: formData,
                    url: filesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  presentationUrl = fileData.fileUrl;
                } catch {}
              }

              if (presentation && !presentationUrl) {
                setFieldError(
                  'presentation',
                  'Failed to upload the presentation'
                );
                return;
              }

              if (video) {
                try {
                  const formData = new FormData();
                  formData.append(
                    'client',
                    user?.actAs?.id || user?.details?.client
                  );
                  formData.append('created_by', user?.details?.id);
                  formData.append('anonymous_can_see_it', true);
                  formData.append('file', video);

                  const { data: fileData } = await createRecord({
                    values: formData,
                    url: filesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  videoUrl = fileData.fileUrl;
                } catch {}
              }

              if (video && !videoUrl) {
                setFieldError('video', 'Failed to upload the video');
                return;
              }

              const { data } = await createRecord({
                values: {
                  ...rest,
                  thumbnail: fileUrl,
                  presentation: presentationUrl,
                  video: videoUrl,
                  homeworkId: homeworkId?.id,
                },
                url: lessonsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={lessonHandleRow}
      />
    </Box>
  );
}
