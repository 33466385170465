import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';
import ForumIcon from '@mui/icons-material/Forum';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { crmUrls, voipUrls, communicationUrls } from '@config/routes';
import ListView from '@components/ListView';
import { callRecordingsRowsMap } from '@config/handleRows/crm';
import { emailRowsMap } from '@config/handleRows/communication';
import Header from '@components/KanbanBoardHeader';

const { callLogsUrls } = voipUrls;
const { personsUrls } = crmUrls;
const { notificationsUrls } = communicationUrls;

export default function PersonHistory({ labels, editMode }) {
  const [value, setValue] = React.useState(0);
  const [selectedPerson, setSelectedPerson] = React.useState(null);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const emailHistoriesColOptions = React.useMemo(() => {
    return { dataPanel: true };
  }, []);

  return (
    <Box>
      <Header
        title="Person History"
        prefix="08g"
        labels={labels}
        editMode={editMode}
        filterOptions={[
          {
            label: 'Person',
            name: 'selectedPerson',
            requestKey: 'person-history',
            fetchUrl: personsUrls.list,
            handleChange: (newValue) => {
              setSelectedPerson(newValue);
            },
            renderRow: (r) => ({
              id: r.id,
              label: `${r?.first_name || 'Unamed'} ${r?.last_name || ''} (${
                r?.email || ''
              })`,
              email: r.email,
              allEmails: r.all_emails,
              personal_mobile: r.personal_mobile,
            }),
            orderingCol: 'name',
          },
        ]}
      />

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon label tabs example"
      >
        <Tab icon={<EmailIcon />} iconPosition="start" label="Email" />
        <Tab
          icon={<WifiCalling3Icon />}
          iconPosition="start"
          label="Call Recordings"
        />
        <Tab
          disabled
          icon={<TextSnippetIcon />}
          iconPosition="start"
          label="eDoc"
        />
        <Tab disabled icon={<ForumIcon />} iconPosition="start" label="Forms" />
        <Tab
          disabled
          icon={<CalendarMonthIcon />}
          iconPosition="start"
          label="Calendar"
        />
      </Tabs>

      <Box sx={{ mt: 2 }}>
        {value === 0 ? (
          <ListView
            label=""
            labels={labels}
            editMode={editMode}
            urls={notificationsUrls}
            hideActions
            columnKey="emailCommunication"
            columnOptions={emailHistoriesColOptions}
            handleRowsMap={emailRowsMap}
            urlParams={
              selectedPerson?.allEmails
                ? `&all_inbox=true&history_email=${selectedPerson?.allEmails.toString()}`
                : '&all_inbox=true'
            }
          />
        ) : null}

        {value === 1 ? (
          <ListView
            label=""
            labels={labels}
            editMode={editMode}
            urls={callLogsUrls}
            columnKey="callRecordingsCRM"
            handleRowsMap={callRecordingsRowsMap}
            urlParams={
              selectedPerson?.id && selectedPerson?.id !== 'All'
                ? `&microservice=CRM&model=Person&record_id=${selectedPerson?.id}`
                : '&microservice=CRM&model=Person'
            }
          />
        ) : null}
      </Box>
    </Box>
  );
}
