import React from 'react';
import { Box, Typography } from '@mui/material';
import ChatMessage from '@ui/WebChat/ChatMessage';

export default function MessagesList({
  title,
  messages,
  onMessageClick,
  selectedRoom,
  withNotificationRooms,
}) {
  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ mb: 1.5, display: 'flex' }}>
        <Typography sx={{ color: '#666', fontSize: '14px' }}>
          {title}
        </Typography>

        <Typography sx={{ color: '#666', fontSize: '14px', pl: 1 }}>
          {messages ? messages.items.length : 0}
        </Typography>
      </Box>

      {messages &&
        messages.items.map((r) => (
          <Box sx={{ mb: 0.5 }}>
            <ChatMessage
              active={selectedRoom === r.id}
              name={r.customer_name}
              email={r.customer_email}
              onClick={() => onMessageClick(r.id)}
              highlight={withNotificationRooms.includes(r.id)}
            />
          </Box>
        ))}
    </Box>
  );
}
