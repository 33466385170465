import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { cmsV2Urls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { pageTemplateRowsMap } from '@config/handleRows/cms-v2';
import { PageTemplateForm } from '@config/forms/cms-v2';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import PublicUserErrorScreen from '@components/CMS-V2/PublicUserErrorScreen';

const { pageTemplatesUrls } = cmsV2Urls;

export default function PageTemplates({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  if (
    Array.isArray(user?.details?.roles_names) &&
    !(
      user?.details?.roles_names.includes('Superusers') ||
      user?.details?.roles_names.includes('Company Administrator') ||
      user?.details?.is_superuser ||
      user?.details?.roles_names.includes('Template Admins')
    )
  ) {
    return <PublicUserErrorScreen />;
  } else {
    return (
      <Box>
        <ListView
          nodeAPI
          label='Page Templates'
          prefix='0m8'
          labels={labels}
          editMode={editMode}
          microservice={microservices.CMS.name}
          model={microservices.CMS.models.pageTemplate}
          urls={pageTemplatesUrls}
          columnKey='pageTemplate'
          addNewForm={{
            getFields: () => PageTemplateForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values,
                  url: pageTemplatesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowsMap={pageTemplateRowsMap}
        />
      </Box>
    );
  }
}
