import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { listsUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { languageMeta } from '@config/meta/list';
import { countryLanguageHandleRow } from '@config/handleRows/list';
import { CountryLanguageForm } from '@config/forms/list';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { languagesUrls, countryLanguagesUrls } = listsUrls;

export default function LanguageDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  const getSchema = React.useCallback((data) => languageMeta({ data }), []);

  const countryLanguageColOptions = React.useMemo(
    () => ({
      hideCountry: false,
      hideLanguage: true,
    }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/languages', text: 'Language' },
              { text: recordData?.english },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={languagesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.LIST.name}
          model={microservices.LIST.models.countryLanguages}
          columnKey='countryLanguageLists'
          columnOptions={countryLanguageColOptions}
          label='Country Language'
          prefix='00a'
          labels={labels}
          editMode={editMode}
          urls={countryLanguagesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              CountryLanguageForm({
                setFieldValue,
                hideCountry: true,
                hideLanguage: false,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { country, ...rest } = values;

                const { data } = await createRecord({
                  values: {
                    ...rest,
                    language: recordId,
                    country: country?.id,
                  },
                  url: countryLanguagesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return data.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={countryLanguageHandleRow}
          urlParams={`&language=${recordId}`}
          hideActions
        />
      </Box>
    </Box>
  );
}
