import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid, MenuItem } from '@mui/material';
import { TextField, SelectField } from '@components/Inputs';
import { bpaFromTOUnits, bpaTimezoneUnits } from '@config/constants';
import { bpaUrls } from '@config/routes';
import { convertTo12HourFormat } from '@config/functions/helperFunctions';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import { updateRecord } from '@config/functions/requests';
import { useQueryClient } from 'react-query';

const { workflowsUrls } = bpaUrls;

export default function WorkingTimePrompt({
  recordData,
  open,
  onCancelClick,
  setWorkinTimeModalOpen,
}) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      fullWidth
      maxWidth="sm"
      aria-labelledby="start-automation"
    >
      <DialogTitle id="delete-dialog-title">Update working time</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            from_time_hour: convertTo12HourFormat(recordData?.from_hour).split(
              ' '
            )[0],
            from_time_hour_am_or_pm: convertTo12HourFormat(
              recordData?.from_hour
            ).split(' ')[1],
            to_time_hour: convertTo12HourFormat(recordData?.to_hour).split(
              ' '
            )[0],
            to_time_hour_am_or_pm: convertTo12HourFormat(
              recordData?.to_hour
            ).split(' ')[1],
            timezone: bpaTimezoneUnits[0].label,
          }}
          validationSchema={Yup.object({
            from_time_hour: Yup.number().nullable(),
            from_time_hour_am_or_pm: Yup.string().nullable(),
            to_time_hour: Yup.number().nullable(),
            to_time_hour_am_or_pm: Yup.string().nullable(),
            timezone: Yup.string().nullable(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);
              await updateRecord({
                values: {
                  from_time_hour: values?.from_time_hour,
                  from_time_hour_am_or_pm: values?.from_time_hour_am_or_pm,
                  to_time_hour: values?.to_time_hour,
                  to_time_hour_am_or_pm: values?.to_time_hour_am_or_pm,
                  timezone: values?.timezone,
                },
                url: workflowsUrls.detail(recordData?.id),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              queryClient.invalidateQueries();
              setWorkinTimeModalOpen(false);
              notify('Operation completed', {
                type: 'SUCCESS',
              });
            } catch (error) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate autoComplete="off" style={{ paddingTop: '8px' }}>
              <Grid
                key="email-grid-container"
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ mb: 2 }}
              >
                <Grid item xs={6}>
                  <TextField
                    label="Start Time (Hour)"
                    name="from_time_hour"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectField label="AM/PM" name="from_time_hour_am_or_pm">
                    {bpaFromTOUnits.map((choice) => (
                      <MenuItem value={choice.label} key={choice.label}>
                        {choice.label}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="End Time (Hour)"
                    name="to_time_hour"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectField label="AM/PM" name="to_time_hour_am_or_pm">
                    {bpaFromTOUnits.map((choice) => (
                      <MenuItem value={choice.label} key={choice.label}>
                        {choice.label}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={6}>
                  <SelectField label="Timezone" name="timezone">
                    {bpaTimezoneUnits.map((choice) => (
                      <MenuItem value={choice.label} key={choice.label}>
                        {choice.label}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={onCancelClick}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
