import { lmsHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  universities: 'universities',
  faculties: 'faculties',
  homeworks: 'homeworks',
  teams: 'teams',
  courses: 'courses',
  lessons: 'lessons',
  modules: 'modules',
  skills: 'skills',
  instructors: 'instructors',
  mentors: 'mentors',
  coaches: 'coaches',
  students: 'students',
  facultyInstructors: 'faculty-instructors',
  facultyCourses: 'faculty-courses',
  courseModules: 'course-modules',
  courseTeams: 'course-teams',
  moduleLessons: 'module-lessons',
  lessonProgresses: 'lesson-progresses',
  lessonInstructors: 'lessonInstructors',
  lessonCoaches: 'lesson-coaches',
  homeworkProgresses: 'homework-progresses',
  studentMentors: 'student-mentors',
  courseMentors: 'course-mentors',
  teamMembers: 'team-members',
  createCourseAndRelations: 'courses/create-course-and-relations',
  updateCourseWithRelations: 'courses/update-course-with-relations',
  lessonReferences: 'lesson-references',
  categories: 'categories',
};

const updateCourseWithRelationsUrls = getModelUrls(
  lmsHost,
  models.updateCourseWithRelations,
  false,
  'v1'
);

const courseMentorsUrls = getModelUrls(
  lmsHost,
  models.courseMentors,
  false,
  'v1'
);

const categoriesUrls = getModelUrls(lmsHost, models.categories, false, 'v1');

const lessonReferencesUrls = getModelUrls(
  lmsHost,
  models.lessonReferences,
  false,
  'v1'
);

const universitiesUrls = getModelUrls(
  lmsHost,
  models.universities,
  false,
  'v1'
);
const createCourseAndRelationsUrls = getModelUrls(
  lmsHost,
  models.createCourseAndRelations,
  false,
  'v1'
);
const facultiesUrls = getModelUrls(lmsHost, models.faculties, false, 'v1');
const facultyInstructorsUrls = getModelUrls(
  lmsHost,
  models.facultyInstructors,
  false,
  'v1'
);
const homeworksUrls = getModelUrls(lmsHost, models.homeworks, false, 'v1');
const teamsUrls = getModelUrls(lmsHost, models.teams, false, 'v1');
const coursesUrls = getModelUrls(lmsHost, models.courses, false, 'v1');
const lessonsUrls = getModelUrls(lmsHost, models.lessons, false, 'v1');
const modulesUrls = getModelUrls(lmsHost, models.modules, false, 'v1');
const skillsUrls = getModelUrls(lmsHost, models.skills, false, 'v1');
const instructorsUrls = getModelUrls(lmsHost, models.instructors, false, 'v1');
const coachesUrls = getModelUrls(lmsHost, models.coaches, false, 'v1');
const mentorsUrls = getModelUrls(lmsHost, models.mentors, false, 'v1');
const studentsUrls = getModelUrls(lmsHost, models.students, false, 'v1');
const facultyCoursesUrls = getModelUrls(
  lmsHost,
  models.facultyCourses,
  false,
  'v1'
);
const courseModulesUrls = getModelUrls(
  lmsHost,
  models.courseModules,
  false,
  'v1'
);
const courseTeamsUrls = getModelUrls(lmsHost, models.courseTeams, false, 'v1');
const moduleLessonsUrls = getModelUrls(
  lmsHost,
  models.moduleLessons,
  false,
  'v1'
);
const lessonProgressesUrls = getModelUrls(
  lmsHost,
  models.lessonProgresses,
  false,
  'v1'
);
const lessonInstructorsUrls = getModelUrls(
  lmsHost,
  models.lessonInstructors,
  false,
  'v1'
);
const lessonCoachesUrls = getModelUrls(
  lmsHost,
  models.lessonCoaches,
  false,
  'v1'
);
const homeworkProgressesUrls = getModelUrls(
  lmsHost,
  models.homeworkProgresses,
  false,
  'v1'
);
const studentMentorsUrls = getModelUrls(
  lmsHost,
  models.studentMentors,
  false,
  'v1'
);
const teamMembersUrls = getModelUrls(lmsHost, models.teamMembers, false, 'v1');

const urls = {
  universitiesUrls,
  facultiesUrls,
  homeworksUrls,
  teamsUrls,
  coursesUrls,
  lessonsUrls,
  modulesUrls,
  skillsUrls,
  instructorsUrls,
  coachesUrls,
  mentorsUrls,
  studentsUrls,
  facultyInstructorsUrls,
  facultyCoursesUrls,
  courseModulesUrls,
  courseTeamsUrls,
  moduleLessonsUrls,
  lessonProgressesUrls,
  lessonInstructorsUrls,
  lessonCoachesUrls,
  homeworkProgressesUrls,
  studentMentorsUrls,
  teamMembersUrls,
  createCourseAndRelationsUrls,
  updateCourseWithRelationsUrls,
  lessonReferencesUrls,
  categoriesUrls,
  courseMentorsUrls,
};

export default urls;
