import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { AutocompleteFieldV2, TextField } from '@components/Inputs';
import { bpaUrls } from '@config/routes';
import { useFormikContext } from 'formik';

const { getPayloadActionsUrls } = bpaUrls;

const headingContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mb: 2,
};

const headingStyles = { fontSize: '15px', fontWeight: '500', color: '#121826' };

const getSectionFieldInputProps = (name) => ({
  name: `${name}-payloadSectionKey`,
  id: `${name}-uncontrolled-native`,
});

export default function PayloadFieldSelector({
  label,
  required,
  workflowId,
  initialPayloadSection,
  initialPayloadSectionField,
  payloadSectionName,
  payloadSectionFieldName,
  customValueFieldName,
  setFieldValue,
}) {
  const { values, errors } = useFormikContext();
  const [payloadSectionKeys, setPayloadSectionKeys] = React.useState([]);
  const [sectionKeysInit, setSectionKeysInit] = React.useState(false);

  const customValueToggleFieldName = `${customValueFieldName}Toggle`;
  const isChecked = values[customValueToggleFieldName];

  const onPayloadSectionChange = ({ sectionKeys } = {}) => {
    setFieldValue(payloadSectionFieldName, null);
    setPayloadSectionKeys(Array.isArray(sectionKeys) ? sectionKeys : []);
  };

  const updatePayloadSectionKeys = (data) => {
    // Find a row that matches the initial payload section ID
    const matchingRow = data.find(
      (row) => row.id === initialPayloadSection?.id
    );

    // If no matching row is found and the section keys are not initialized, set them as initialized
    if (!matchingRow) {
      if (!sectionKeysInit) {
        setSectionKeysInit(true);
      }
      return;
    }

    // If the matching row has a valid 'sectionKeys' array and the section keys are not initialized,
    // update the payload section keys and set them as initialized
    if (Array.isArray(matchingRow.sectionKeys) && !sectionKeysInit) {
      setPayloadSectionKeys(matchingRow.sectionKeys);
      setSectionKeysInit(true);
    }
  };

  const onPayloadSectionFieldChange = (e) => {
    const value = e.target.value;
    setFieldValue(
      payloadSectionFieldName,
      value === 'None' ? null : e.target.value
    );
  };

  const renderPayloadSections = (row) => {
    const { id, name, keys } = row;

    return {
      id,
      label: name,
      sectionKeys: keys,
    };
  };

  const renderPayloadKeyOptions = (payloadSectionKeys) => {
    if (!Array.isArray(payloadSectionKeys)) return null;

    return payloadSectionKeys.map(({ key, label }) => (
      <option key={key} value={key}>
        {label}
      </option>
    ));
  };

  React.useEffect(() => {
    if (
      Array.isArray(initialPayloadSection?.sectionKeys) &&
      !payloadSectionKeys.length
    ) {
      setPayloadSectionKeys(initialPayloadSection.sectionKeys);
      setFieldValue(payloadSectionFieldName, initialPayloadSectionField);
    }
  }, [initialPayloadSection]);

  return (
    <Box>
      <Box sx={headingContainerStyles}>
        <Typography sx={headingStyles}>{label}</Typography>
      </Box>

      <Grid container spacing={2}>
        {isChecked && customValueFieldName ? (
          <Grid item xs={12}>
            <TextField
              multiline
              label="Custom Value"
              required={required}
              name={customValueFieldName}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <AutocompleteFieldV2
                label="Payload Section"
                name={payloadSectionName}
                requestKey="payloadSectionName-map-payload-section"
                fetchUrl={getPayloadActionsUrls.list}
                urlParams={`&workflow_id=${workflowId}&exclude_draft_stage=true`}
                renderRow={renderPayloadSections}
                onChange={onPayloadSectionChange}
                getData={updatePayloadSectionKeys}
                required={required}
              />
            </Grid>
            <Grid item xs={6}>
              {sectionKeysInit ? (
                <FormControl fullWidth size="small">
                  <InputLabel
                    variant="standard"
                    required={required}
                    htmlFor="uncontrolled-native"
                  >
                    Section Field
                  </InputLabel>
                  <NativeSelect
                    defaultValue={initialPayloadSectionField}
                    onChange={onPayloadSectionFieldChange}
                    inputProps={getSectionFieldInputProps(
                      payloadSectionFieldName
                    )}
                  >
                    <option value={null}>None</option>
                    {renderPayloadKeyOptions(payloadSectionKeys)}
                  </NativeSelect>
                  {errors[payloadSectionFieldName] ? (
                    <span
                      style={{
                        color: '#ff1744',
                        fontSize: '0.75rem',
                        marginTop: '4px',
                      }}
                    >
                      {errors[payloadSectionFieldName]}
                    </span>
                  ) : null}
                </FormControl>
              ) : (
                <Typography>loading...</Typography>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
