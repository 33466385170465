import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function RebaseDialogBox({ open, task, onPopupSubmit }) {
  return (
    <Dialog
      open={open}
      onClose={() => null}
      fullWidth
      maxWidth="sm"
      aria-labelledby="start-automation"
    >
      <DialogTitle id="delete-dialog-title">
        Rebase the {task ? `task and sub tasks` : `project`}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="delete-dialog-description"
          sx={{
            mb: '10px',
          }}
        >
          Are you sure you want to rebase the {task ? `task` : `project`}? this
          action will update all of the {task ? `subtasks` : `task`} dates if
          you click yes.
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={() =>
              onPopupSubmit({
                rebase: false,
              })
            }
          >
            No
          </Button>
          <Button
            onClick={() =>
              onPopupSubmit({
                rebase: true,
              })
            }
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
