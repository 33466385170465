import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function TaskRecurringMessage({ open, setOpen }) {
  const onCancelClick = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      fullWidth
      maxWidth='sm'
      aria-labelledby='start-automation'
    >
      <DialogTitle id='delete-dialog-title'>Recurring</DialogTitle>
      <DialogContent>
        <DialogContentText
          id='delete-dialog-description'
          sx={{
            mb: '10px',
          }}
        >
          Please set the following fields to enable recurring.
          <p style={{ marginTop: '2px' }}>
            - deadline
            <br /> - started or duration estimate
          </p>
        </DialogContentText>
        <DialogActions>
          <Button onClick={onCancelClick}>Close</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
