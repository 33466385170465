import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Alert } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import { selectProfile } from '@redux/profileSlice';
import { communicationUrls } from '@config/routes';
import { createRecord, updateRecord } from '@config/functions/requests';
import { emailTemplateRowsMap } from '@config/handleRows/communication';
import { EmailTemplateForm } from '@config/forms/communication';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import AuthWrapper from '@components/Communication/AuthWrapper';
import Button from '@ui/Button';
import formatColName from '@config/functions/removeUnderScoresFromStr';
import { useQueryClient } from 'react-query';
import TextField from '@ui/Inputs/TextField';

const {
  mailTemplatesUrls,
  cloneSystemTemplatesUrls,
  updateBulkEmailTemplatesUrls,
} = communicationUrls;

function EmailTemplates({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const navigate = useNavigate();
  // const [imapAccountTerm, setImapAccountTerm] = React.useState('');
  const [errors, setErrors] = React.useState([]);
  const [isCopying, setCopying] = React.useState(false);
  const [bulkError, setBulkError] = React.useState('');
  const queryClient = useQueryClient();
  const [tags, setTags] = React.useState('');

  const handleHtmlEditor =
    ({ id }) =>
    () =>
      navigate(`/email-templates/html-editor/${id}`);

  // const {
  //   data: imapAccounts,
  //   isFetching: fetchingImapAccounts,
  //   refetch: refetchImapAccounts,
  // } = useAutocomplete({
  //   key: 'imap-accounts',
  //   getUrl: imapAccountsUrls.list,
  //   inputValue: imapAccountTerm,
  //   query:
  //     '&connected=true&smtp_connected=true&send_emails_from_this_account=true',
  //   renderRow: (r) => ({
  //     id: r.id,
  //     label: r.username,
  //   }),
  // });

  const handleBulkUpdate = async ({ selectedRows, setSelectedRows }) => {
    try {
      await updateRecord({
        values: selectedRows.map((row) => ({
          id: row,
          _tags: tags,
        })),
        url: updateBulkEmailTemplatesUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });
      setSelectedRows([]);
      queryClient.invalidateQueries('Email-Templates-listData');
      notify('Operation Completed', {
        type: 'SUCCESS',
      });
    } catch (err) {
      if (err.response && Object.keys(err.response.data).length) {
        const error = err.response.data[Object.keys(err.response.data)[0]];
        setBulkError(typeof error === 'string' ? error : error[0]);
        setTimeout(() => {
          setBulkError('');
        }, 3000);
      }
    }
  };

  const emailTemplatesColOptions = React.useMemo(() => {
    return { onListView: true };
  }, []);

  return (
    <>
      <Box>
        {Array.isArray(errors) && errors.length ? (
          <Alert sx={{ mb: 2 }} severity='error' onClose={() => setErrors([])}>
            <h4
              style={{
                all: 'initial',
                color: 'inherit',
                fontFamily: 'inherit',
              }}
            >
              Failed to clone the following rows:
            </h4>

            {errors.map((error) => (
              <div key={error.name} style={{ marginBottom: '8px' }}>
                <p style={{ marginBottom: 0 }}>{error.name}</p>
                <ul style={{ marginTop: 0, paddingLeft: '22px' }}>
                  {Object.keys(error.errors).map((key) => (
                    <li key={key}>
                      {formatColName(key)}: {error.errors[key]}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Alert>
        ) : null}

        {!!bulkError ? (
          <Alert sx={{ mb: 2.5 }} severity='error'>
            {bulkError}
          </Alert>
        ) : null}

        <ListView
          label='Email Templates'
          prefix='0m5'
          labels={labels}
          editMode={editMode}
          microservice={microservices.NOTIFICATION.name}
          model={microservices.NOTIFICATION.models.mailTemplates}
          urls={mailTemplatesUrls}
          tableBulkOptions={[{ id: 'addFromEmail', text: 'Add From Email' }]}
          columnKey='emailTemplateCommunication'
          columnOptions={emailTemplatesColOptions}
          customInitialValues={{
            anonymous_can_see_it: true,
          }}
          customBulkHeaderButton={(selectedRows, data, setSelectedRows) => {
            return (
              <>
                <Box sx={{ marginRight: 2, minWidth: '420px' }}>
                  <TextField
                    label='Tags'
                    onChange={(e) => setTags(e.target.value)}
                  />
                </Box>

                <Box sx={{ marginRight: 2 }}>
                  <Button
                    type='button'
                    variant='outlined'
                    onClick={() =>
                      handleBulkUpdate({ selectedRows, setSelectedRows })
                    }
                    disabled={!tags}
                    label='Update'
                  />
                </Box>

                <Box sx={{ marginRight: 2 }}>
                  <Button
                    type='button'
                    variant='outlined'
                    disabled={isCopying}
                    label={isCopying ? 'Copying...' : 'Make copy'}
                    onClick={async () => {
                      try {
                        setCopying(true);

                        const keysToCopy = [
                          'name',
                          'description',
                          'subject',
                          // 'from_email',
                          'from_email_alias',
                          '_tags',
                          'html',
                          'anonymous_can_see_it',
                          'everyone_can_see_it',
                          'everyone_in_object_company_can_see_it',
                          'only_these_roles_can_see_it',
                          'only_these_users_can_see_it',
                        ];
                        const newData = selectedRows.map((row) => {
                          const origin = data.results.find(
                            (result) => result.id === row
                          );
                          const copiedValues = {};
                          keysToCopy.forEach((key) => {
                            if (key === 'name') {
                              copiedValues[key] = `${origin[key]} (copy)`;
                            } else {
                              copiedValues[key] = origin[key];
                            }
                          });
                          return copiedValues;
                        });
                        const recordCreationPromises = newData.map((values) =>
                          createRecord({
                            values,
                            url: mailTemplatesUrls.list(),
                            token: user.token,
                            actAs: user?.actAs,
                            encryptionToken: user.encryptionToken,
                          })
                        );

                        const results = await Promise.allSettled(
                          recordCreationPromises
                        );

                        const failedRecords = results.filter(
                          (result) => result.status === 'rejected'
                        );

                        let errorArray = [];

                        if (failedRecords.length > 0) {
                          failedRecords.forEach(({ reason }, index) => {
                            errorArray.push({
                              name: newData[index].name,
                              errors: reason.response.data,
                            });
                          });
                        }

                        setTimeout(() => {
                          setErrors(errorArray);
                          setSelectedRows([]);
                          queryClient.invalidateQueries([
                            'Email-Templates-listData',
                          ]);
                        }, 500);
                      } catch (err) {
                        console.log(err);
                        notify(`There was an error, please try again`, {
                          type: 'ERROR',
                        });
                      } finally {
                        setCopying(false);
                      }
                    }}
                  />{' '}
                </Box>
              </>
            );
          }}
          addNewForm={{
            getFields: () =>
              EmailTemplateForm({
                microservice: microservices.NOTIFICATION.name,
                model: microservices.NOTIFICATION.models.mailTemplates,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { template, from_email, subject, ...rest } = values;

                const doFinally = () => {
                  resetForm();
                  setAddNewForm(false);
                  refetch();
                };

                if (template) {
                  const payload = {
                    template_id: template?.id,
                    custom_params: {
                      ...rest,
                      from_email: from_email?.id,
                    },
                  };

                  if (subject && subject.length)
                    payload.custom_params.subject = subject;
                  const response = await createRecord({
                    values: payload,
                    url: cloneSystemTemplatesUrls.detail(
                      microservices.NOTIFICATION.models.mailTemplates
                    ),
                    token: user.token,
                    actAs: user?.actAs,
                  });
                  doFinally();
                  return response?.data?.id;
                } else {
                  if (!values?.subject || !values?.subject.length) {
                    return setFieldError(
                      'subject',
                      'Subject is a required field!'
                    );
                  }

                  const response = await createRecord({
                    values: {
                      ...rest,
                      subject,
                      html: '<h1>An Example Email</h1>',
                      from_email: from_email?.id,
                    },
                    url: mailTemplatesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                    encryptionToken: user.encryptionToken,
                  });
                  doFinally();
                  return response?.data?.id;
                }
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
              return null;
            },
          }}
          handleRowsMap={emailTemplateRowsMap}
          rowActions={[
            {
              icon: <CodeOutlinedIcon sx={{ mr: 1 }} />,
              label: 'Html Editor',
              color: '#808080',
              handleClick: handleHtmlEditor,
            },
          ]}
        />
      </Box>
    </>
  );
}

export default function AuthenticatedEmailTemplates(props) {
  return (
    <AuthWrapper>
      <EmailTemplates {...props} />
    </AuthWrapper>
  );
}
