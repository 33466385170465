import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import Select from '@ui/Inputs/SelectField';

export default function SelectField({
  id,
  label,
  children,
  customSetValue,
  ...rest
}) {
  const [field, meta] = useField(rest);
  const isError = Boolean(meta.error);
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    setFieldValue(field.name, e.target.value);
    if (customSetValue) customSetValue(e);
  };

  return (
    <Select
      {...rest}
      labelId={id}
      label={label}
      error={isError}
      value={field.value}
      name={field.name}
      errorMessage={meta.error}
      onChange={handleChange}
    >
      {children}
    </Select>
  );
}
