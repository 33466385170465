import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { pipelinesRowsMap } from '@config/handleRows/crm';
import { PipelineForm } from '@config/forms/crm';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { pipelinesUrls } = crmUrls;

export default function Pipelines({ editMode, labels }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label="Pipelines"
        editMode={editMode}
        labels={labels}
        prefix="0vl"
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.pipeline}
        urls={pipelinesUrls}
        columnKey="pipelineCRM"
        addNewForm={{
          getFields: () => PipelineForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: pipelinesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify('Pipeline Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={pipelinesRowsMap}
      />
    </Box>
  );
}
