import * as Yup from 'yup';
import { fieldType } from '@config/constants';

export const SOPsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.LINK,
    to: '/sops/',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    type: fieldType.TEXT,
    width: 300,
    editable: true,
    initialValue: '',
    onForm: true,
    value: data && data.description,
    validate: Yup.string().max(2500),
  },
  {
    field: 'revision',
    headerName: 'Revision',
    width: 300,
    type: fieldType.TEXT,
    editable: true,
    value: data && data.revision,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'purpose',
    headerName: 'Purpose ',
    type: fieldType.TEXT,
    width: 180,
    value: data && data.purpose,
    editable: true,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(150),
  },
  {
    field: 'scope',
    headerName: 'Scope',
    type: fieldType.TEXT,
    width: 180,
    value: data && data.scope,
    editable: true,
    initialValue: '',
    onForm: true,
    hideColumn: true,
    validate: Yup.string().max(150),
  },
  {
    field: 'roles',
    headerName: 'Roles',
    width: 180,
    value: data && data.roles,
    editable: true,
    initialValue: '',
    onForm: true,
    hideColumn: true,
    validate: Yup.string().max(150),
  },
  {
    field: 'definitions',
    headerName: 'Definitions ',
    width: 180,
    value: data && data.definitions,
    editable: true,
    initialValue: '',
    onForm: true,
    hideColumn: true,
    validate: Yup.string().max(150),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const SOPsTaskMeta = ({ data, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 75,
    onAccordionForm: true,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder + 1,
    validate: Yup.number().required(),
  },
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.LINK,
    to: '/sop-tasks/',
    width: 180,
    value: data && data.name,
    editable: true,
    onForm: true,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'explanation',
    headerName: 'Explanation',
    width: 180,
    value: data && data.explanation,
    editable: true,
    onForm: true,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(150),
  },
  {
    field: 'actioned_by',
    headerName: 'Actioned By',
    width: 180,
    value: data && data.actioned_by,
    editable: true,
    onForm: true,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(150),
  },
  {
    field: 'resources_required',
    headerName: 'Resources Required',
    width: 180,
    value: data && data.resources_required,
    editable: true,
    onForm: true,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(150),
  },
  {
    field: 'conditional_requirements',
    headerName: 'Conditional Requirements',
    width: 180,
    value: data && data.conditional_requirements,
    editable: true,
    onForm: true,
    initialValue: '',
    onAccordionForm: true,
    hideColumn: true,
    validate: Yup.string().max(150),
  },
  {
    field: 'potential_warning',
    headerName: 'Potential Warning',
    width: 180,
    value: data && data.potential_warning,
    editable: true,
    onForm: true,
    initialValue: '',
    onAccordionForm: true,
    hideColumn: true,
    validate: Yup.string().max(150),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 180,
    value: data && data.notes,
    editable: true,
    onForm: true,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(150),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const SOPTaskStepMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    value: data && data.name,
    editable: true,
    onForm: true,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    type: fieldType.TEXT,
    width: 300,
    editable: true,
    initialValue: '',
    onForm: true,
    value: data && data.description,
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
