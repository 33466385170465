import React from 'react';
import * as Yup from 'yup';
import { formsUrls } from '@config/routes';
import { questionsMeta } from '@config/meta/forms/formsMeta';
import { Grid, MenuItem } from '@mui/material';
import {
  TextField,
  AutocompleteFieldV2,
  SelectField,
  CheckBoxField,
} from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { formQuestionTypeOptions } from '@config/constants';
import FormDropdown from '@dropdown-forms/form/FormDropdown';
import { useQueryClient } from 'react-query';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import useToast from '@hooks/useToast';

const { questionsUrls, formsListUrls, groupsUrls } = formsUrls;

export default function QuestionDropdown({
  children,
  refetch,
  form: initialForm,
  group: initialGroup,
}) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Question"
        initialValues={{
          ...getAddFormInitialValues(questionsMeta(), 'DetailAccordionView'),
          formId: initialForm,
          groupId: initialGroup,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            questionsMeta(),
            'DetailAccordionView'
          ),
          group: Yup.mixed().required('This field is required'),
        })}
        getPostUrl={() => questionsUrls.list()}
        // getValues={({ formId, group, ...values }) => ({
        //   ...values,
        //   formId: formId?.id,
        //   groupId: group?.id,
        // })}
        handleSubmit={async (
          values,
          setSubmitting,
          resetForm,
          refetch,
          setError,
          setFieldError
        ) => {
          try {
            const {
              type,
              group,
              formId,
              likertLabel1,
              likertLabel2,
              likertLabel3,
              likertLabel4,
              likertLabel5,
              ...restValues
            } = values;

            if (!group?.id) {
              setFieldError('group', 'This field is required');
              return;
            }

            const typeLikert = type === 'Likert';

            if (typeLikert) {
              try {
                await Yup.object({
                  likertLabel1: Yup.string().required(),
                  likertLabel2: Yup.string().required(),
                  likertLabel3: Yup.string().required(),
                  likertLabel4: Yup.string().required(),
                  likertLabel5: Yup.string().required(),
                }).validate({
                  likertLabel1,
                  likertLabel2,
                  likertLabel3,
                  likertLabel4,
                  likertLabel5,
                });
              } catch (err) {
                setFieldError(err.path, 'Please enter likert labels');
                return;
              }
            }

            const likertLabels = [
              likertLabel1,
              likertLabel2,
              likertLabel3,
              likertLabel4,
              likertLabel5,
            ];

            await createRecord({
              values: {
                ...restValues,
                type,
                formId: formId?.id,
                groupId: group?.id,
                anonymous_can_see_it: true,
                likertLabels: typeLikert ? likertLabels : undefined,
              },
              url: questionsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });

            resetForm();
            setOpen(false);
            refetch();
          } catch (err) {
            handleCreateFormErrors({ err, setError, notify, values });
            console.log(err);
          } finally {
            setSubmitting(false);
          }
        }}
        getFields={({ setFieldValue, values }) => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField label="Order" name="order" />
            </Grid>
            <Grid item md={6}>
              <SelectField label="Type" name="type">
                {formQuestionTypeOptions.map((r) => (
                  <MenuItem value={r.value} key={r.value}>
                    {r.label}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12}>
              <TextField label="Question" name="question" multiline required />
            </Grid>
            <Grid item sm={6}>
              <AutocompleteFieldV2
                name="group"
                label="Group"
                requestKey="question-group"
                fetchUrl={groupsUrls.list}
                orderingCol="order"
                urlParams={initialForm ? `&formId=${initialForm?.id}` : ''}
                renderRow={(row) => ({
                  id: row?.id,
                  label: `${row.order}. ${row.name}`,
                })}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <FormDropdown
                refetch={({ id, name }) => {
                  setFieldValue('formId', { id, label: name });
                  queryClient.invalidateQueries(['group-form']);
                }}
              >
                <AutocompleteFieldV2
                  name="formId"
                  label="Forms"
                  requestKey="group-progress-form"
                  fetchUrl={formsListUrls.list}
                  required
                />
              </FormDropdown>
            </Grid>
            {values.type === 'MultipleChoice' ||
            values.type === 'MultipleSelect' ? (
              <Grid item sm={6}>
                <CheckBoxField
                  label="Add other field"
                  name="includeOthersField"
                />
              </Grid>
            ) : null}
            {values.type === 'Likert' ? (
              <>
                <Grid item xs={2.4}>
                  <TextField
                    label="Likert label 1"
                    name="likertLabel1"
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    label="Likert label 2"
                    name="likertLabel2"
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    label="Likert label 3"
                    name="likertLabel3"
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    label="Likert label 4"
                    name="likertLabel4"
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    label="Likert label 5"
                    name="likertLabel5"
                    multiline
                    required
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
