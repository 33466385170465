import React from 'react';
import DisplayQuestionByType from '@components/Forms/DisplayQuestionByType';

export default function RenderFormQuestions({
  currentQuestion,
  orderedQuestionsByGroup,
  getValue,
  handleChange,
  isSubmitting,
  success,
  otherValues,
  setOtherValues,
  isSuccess,
  displayAll,
}) {
  const noQuestions = !currentQuestion && !orderedQuestionsByGroup?.length;

  const commonProps = {
    getValue,
    handleChange,
    isSubmitting,
    success,
    otherValues,
    setOtherValues,
  };

  if (isSuccess || noQuestions) {
    return null;
  }

  if (displayAll) {
    return orderedQuestionsByGroup.map((question) => (
      <DisplayQuestionByType
        key={question.id}
        currentQuestion={question}
        {...commonProps}
      />
    ));
  }

  return (
    <DisplayQuestionByType currentQuestion={currentQuestion} {...commonProps} />
  );
}
