import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { handleEmailConfirmation } from '@config/functions/requests';
import { useParams } from 'react-router-dom';
import { EMAIL_CONFIRMATION_STATES } from '@config/constants';
import UnexpectedError from '@components/UnexpectedError';
import LinkExpired from '@components/accounts/LinkExpired';
import EmailConfirmed from '@components/accounts/EmailConfirmed';

const styles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export default function EmailConfirmation() {
  const { id } = useParams();
  const [state, setState] = React.useState(
    EMAIL_CONFIRMATION_STATES.CONFIRMING
  );

  React.useEffect(() => {
    handleEmailConfirmation({
      token: id,
      setState: setState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={styles}>
        {state === EMAIL_CONFIRMATION_STATES.CONFIRMED ? (
          <EmailConfirmed />
        ) : null}
        {state === EMAIL_CONFIRMATION_STATES.CONFIRMING ? (
          <CircularProgress />
        ) : null}
        {state === EMAIL_CONFIRMATION_STATES.ERROR ? <UnexpectedError /> : null}
        {state === EMAIL_CONFIRMATION_STATES.EXPIRED ? <LinkExpired /> : null}
      </Box>
    </>
  );
}
