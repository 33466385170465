import React from 'react';
import * as Yup from 'yup';
import { urlShortenerUrls } from '@config/routes';
import { linkMeta } from '@config/meta/urlShortener';
import {
  TextField,
  DateTimePickerField,
  CheckBoxField,
} from '@components/Inputs';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOCNew from '@components/DropdownAddHOCNew';
import { Grid } from '@mui/material';
import {
  formatToUTCDateTime,
  handleCreateFormErrors,
  getAuthHeader,
} from '@config/functions/helperFunctions';
import { createRecord } from '@config/functions/requests';
import useRQuery from '@hooks/useRQuery';
import useToast from '@hooks/useToast';

const { bulkCreateOrUpdateLinksUrls, combinedDataUrls } = urlShortenerUrls;

export default function LinkDropdown({
  children,
  initialId,
  refetch,
  user,
  getCustomActions = () => null,
  actionsDisabled = false,
}) {
  const [notify] = useToast();
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState('Add');
  const [link, setLink] = React.useState(null);

  const { data, isError, isFetching } = useRQuery({
    key: ['link-data', initialId, open, user?.token, user?.actAs],
    url: combinedDataUrls.detail(initialId),
    config: getAuthHeader(user?.token, user?.actAs),
    options: {
      enabled: !!user?.token && !!initialId && action === 'Edit' && open,
    },
  });

  const customInitialValues = link
    ? {
        title: link?.title ?? '',
        custom_back_half: link?.custom_back_half ?? '',
        destination_url: link?.destination_url ?? '',
        text: link?.text ?? '',
        retire: link?.retire
          ? new Date(link?.retire)
          : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        addUTMs:
          link?.source ||
          link?.medium ||
          link?.campaign ||
          link?.content ||
          link?.term,
        source: link?.source ?? '',
        medium: link?.medium ?? '',
        campaign: link?.campaign ?? '',
        content: link?.content ?? '',
        term: link?.term ?? '',
      }
    : {};

  const closePopup = () => {
    setLink(null);
    setOpen(false);
  };

  React.useEffect(() => {
    if (!data) return;

    setLink(data);
  }, [data]);

  return (
    <>
      <DropdownAddHOCNew
        setOpen={setOpen}
        setAction={setAction}
        selected={initialId}
        getCustomActions={getCustomActions}
        actionsDisabled={actionsDisabled}
      >
        {children}
      </DropdownAddHOCNew>

      {open ? (
        <FormDialog
          submitType={action}
          label={`${action} Link`}
          isFetching={
            isFetching ||
            (initialId &&
              action === 'Edit' &&
              (!link || !Object.keys(customInitialValues).length) &&
              !isError)
          }
          isError={isError}
          initialValues={customInitialValues}
          validationSchema={Yup.object({
            ...getSchemaFromColumnsForForm(linkMeta({}), 'listView'),
          })}
          getValues={(values) => values}
          handleSubmit={async (
            values,
            setSubmitting,
            resetForm,
            setOpen,
            refetch,
            setError,
            setFieldError,
            setFieldValue,
            errors
          ) => {
            try {
              const { source, medium, campaign, term, content } = values;
              const utmsHaveValue =
                source || medium || campaign || term || content;

              if (values?.addUTMs && !utmsHaveValue) {
                setFieldError('source', 'Please fill at least one utm field!');
                return;
              }

              const date = new Date();
              const twoYearsFromNow = date.setFullYear(date.getFullYear() + 1);
              const expiryDate =
                values?.retire || formatToUTCDateTime(twoYearsFromNow);

              const linkMeta = {
                id: initialId ?? null,
                title: values?.title,
                custom_back_half: values?.custom_back_half,
                destination_url: values?.destination_url,
                text: values?.text,
                retire: expiryDate,
                domain: process.env.REACT_APP_URL_SHORTENER_HOST,
              };

              if (values?.addUTMs && utmsHaveValue) {
                linkMeta.utm = {
                  source,
                  medium,
                  campaign,
                  term,
                  content,
                };

                if (action === 'Add')
                  linkMeta.utm.everyone_in_object_company_can_see_it = true;
              } else if (customInitialValues?.addUTMs && !values?.addUTMs) {
                linkMeta.delete_utms = true;
              }

              const resp = await createRecord({
                values: [linkMeta],
                url: bulkCreateOrUpdateLinksUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              refetch({
                ...resp?.data[0],
              });
              closePopup();
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          }}
          getFields={({ values }) => (
            <Grid container spacing={3.5}>
              <Grid item xs={7}>
                <TextField label="Name" name="title" required multiline />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label="Custom back half"
                  name="custom_back_half"
                  multiline
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Destination url"
                  name="destination_url"
                  placeholder="https://"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField label="Text" name="text" multiline />
              </Grid>

              <Grid item xs={6}>
                <DateTimePickerField
                  label="Expiry Date"
                  name="retire"
                  showTime
                />
              </Grid>

              <Grid item xs={6}>
                <CheckBoxField label="Add UTMs" name="addUTMs" />
              </Grid>

              {values.addUTMs ? (
                <>
                  <Grid item xs={6}>
                    <TextField label="Source" name="source" required />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Medium" name="medium" required />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Campaign" name="campaign" required />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Content" name="content" required />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Term" name="term" required />
                  </Grid>
                </>
              ) : null}
            </Grid>
          )}
          refetch={refetch}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
    </>
  );
}
