import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import {
  TextField,
  CheckBoxField,
  DateTimePickerField,
  SelectField,
  UploadField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import {
  opportunityPriority,
  opportunitySentiments,
  opportunityInfluencerRatings,
  targetUnitsChoices,
  companySpinsBuyerInfluence,
} from '@config/constants';
import {
  crmUrls,
  hrUrls,
  inventoryUrls,
  listsUrls,
  bpaUrls,
  // accountUrls,
} from '@config/routes';
import CountryDropdown from '@dropdown-forms/list/CountryDropdown';
import SocialDropdown from '@dropdown-forms/crm/SocialDropdown';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';
import OpportunityDropdown from '@dropdown-forms/crm/OpportunityDropdown';
import PipelineDropdown from '@dropdown-forms/crm/PipelineDropdown';
import CompanyContactDropdown from '@dropdown-forms/crm/CompanyContactDropdown';
import CompanyDropdown from '@dropdown-forms/crm/CompanyDropdown';
import ProductDropdown from '@dropdown-forms/inventory/ProductDropdown';
import ProductVariantDropdown from '@dropdown-forms/inventory/ProductVariantDropdown';
import EmployeeDropdownForm from '@dropdown-forms/hr/EmployeeDropdown';
import TerritoryDropdown from '@dropdown-forms/crm/TerritoryDropdown';
import PipelineStagesDropdown from '@dropdown-forms/crm/PipelineStagesDropdown';
import ActivityKindDropdown from '@dropdown-forms/crm/ActivityKindDropdown';
import ActivityTypesDropdown from '@dropdown-forms/crm/ActivityTypesDropdown';
import ChannelDropdown from '@dropdown-forms/crm/ChannelDropdown';
import CallListPipelineStageDropdown from '@dropdown-forms/crm/CallListPipelineStageDropdown';
import CallListPipelinesDropdown from '@dropdown-forms/crm/CallListPipelinesDropdown';
import { useQueryClient } from 'react-query';

const {
  companiesUrls,
  channelsUrls,
  companyContactUrls,
  customerEnquiryStatusesUrls,
  customerEnquiryPurposesUrls,
  pipelinesUrls,
  pipelineStagesUrls,
  callListPipelineStagesUrls,
  personsUrls,
  callListPipelinesUrls,
  activityTypesUrls,
  activityKindsUrls,
  territoriesUrls,
  socialMediaTypesUrls,
  opportunitiesUrls,
  relationshipsUrls,
} = crmUrls;
const { employeesUrls } = hrUrls;
const { productsUrls, productVariantsUrls } = inventoryUrls;
const { countriesUrls } = listsUrls;
// const { usersUrls } = accountUrls;
const { workflowsUrls } = bpaUrls;

export const PersonRelationshipForm = ({ personId } = {}) => (
  <>
    <Grid item xs={4}>
      <AutocompleteFieldV2
        name="relationship"
        label="Relationship"
        requestKey="person-relationship-list"
        fetchUrl={relationshipsUrls.list}
        required
      />
    </Grid>
  </>
);

export const ChannelForm = () => (
  <>
    <Grid item xs={3}>
      <TextField label="Name" name="name" multiline required />
    </Grid>

    <Grid item xs={6}>
      <TextField label="Description" name="description" multiline />
    </Grid>
  </>
);

export const CallHistoryForm = ({ callListPipelineId, setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={4}>
        <TextField label="Outcome" name="outcome" multiline required />
      </Grid>

      <Grid item xs={4}>
        <CallListPipelineStageDropdown
          refetch={({ id, name }) => {
            setFieldValue('call_list_pipeline_stage', { id, label: name });
            queryClient.invalidateQueries(['call-list-pipeline-stage']);
          }}
        >
          <AutocompleteFieldV2
            name="call_list_pipeline_stage"
            label="Call List Stage"
            requestKey="call-list-pipeline-stage"
            fetchUrl={callListPipelineStagesUrls.list}
            urlParams={
              callListPipelineId
                ? `&call_list_pipeline=${callListPipelineId}&ordering=created_at`
                : ''
            }
            required
          />
        </CallListPipelineStageDropdown>
      </Grid>
    </>
  );
};

export const CallScheduleForm = ({
  setFieldValue,
  callListPipelineId,
  callListPipelineName,
} = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={4}>
        <PersonDropdown
          refetch={({ id, email }) => {
            setFieldValue('person', {
              id: id,
              label: email,
            });
            queryClient.invalidateQueries(['call-schedule-persons']);
          }}
        >
          <AutocompleteFieldV2
            name="person"
            label="Person"
            requestKey="call-schedule-persons"
            fetchUrl={personsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.email,
            })}
            required
          />
        </PersonDropdown>
      </Grid>

      <Grid item xs={2}>
        <DateTimePickerField
          label="Schedule Time"
          name="schedule_datetime"
          showTime
          required
        />
      </Grid>

      <Grid item xs={4}>
        <CallListPipelineStageDropdown
          callListPipeline={{
            id: callListPipelineId,
            label: callListPipelineName,
          }}
          refetch={({ id, name }) => {
            setFieldValue('call_list_pipeline_stage', { id, label: name });
            queryClient.invalidateQueries(['call-list-pipeline-stage']);
          }}
        >
          <AutocompleteFieldV2
            name="call_list_pipeline_stage"
            label="Call List Stage"
            requestKey="call-list-pipeline-stage"
            fetchUrl={callListPipelineStagesUrls.list}
            getData={(data) => {
              if (data.length) {
                console.log('data', data[0]);
                setFieldValue('call_list_pipeline_stage', data[0]);
              }
            }}
            urlParams={
              callListPipelineId
                ? `&call_list_pipeline=${callListPipelineId}&ordering=created_at`
                : ''
            }
            required
          />
        </CallListPipelineStageDropdown>
      </Grid>
    </>
  );
};

export const CallListPipelineStageForm = () => (
  <>
    <Grid item xs={1.5}>
      <TextField label="Order" name="order" required />
    </Grid>

    <Grid item xs={2}>
      <TextField label="Name" name="name" required multiline />
    </Grid>

    <Grid item xs={3.5}>
      <TextField label="Description" name="description" multiline />
    </Grid>

    <Grid item xs={1.5}>
      <TextField label="Rotting Days" name="rotting_days" />
    </Grid>
  </>
);

export const CallListPipelinesForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={4}>
      <TextField label="Description" name="description" multiline />
    </Grid>
  </>
);

export const CallListsForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <TextField label="Name" name="name" required />
      </Grid>

      <Grid item xs={4}>
        <CallListPipelinesDropdown
          refetch={({ id, name }) => {
            setFieldValue('call_list_pipeline', { id, label: name });
            queryClient.invalidateQueries(['call-list-pipeline']);
          }}
        >
          <AutocompleteFieldV2
            name="call_list_pipeline"
            label="Call List Pipeline"
            requestKey="call-list-pipeline"
            fetchUrl={callListPipelinesUrls.list}
            required
          />
        </CallListPipelinesDropdown>
      </Grid>

      <Grid item xs={4}>
        <TextField label="Description" name="description" multiline />
      </Grid>
    </>
  );
};

export const PersonCompanyForm = ({ values, setFieldValue }) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <CompanyDropdown
          refetch={({ id, name }) => {
            setFieldValue('company_id', { id, label: name });
            queryClient.invalidateQueries(['person-companies']);
          }}
        >
          <AutocompleteFieldV2
            name="company_id"
            label="Company"
            requestKey="person-companies"
            fetchUrl={companiesUrls.list}
            required
          />
        </CompanyDropdown>
      </Grid>

      <Grid item xs={4}>
        <TextField label="Job Title" name="job_title" />
      </Grid>

      <Grid item xs={3}>
        <TextField label="Work Email" name="work_email" />
      </Grid>

      <Grid item xs={3}>
        <TextField label="Work Phone" name="work_phone" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Work Mobile" multiline name="work_mobile" />
      </Grid>

      <Grid item xs={2}>
        <DateTimePickerField label="Started" name="start_date" />
      </Grid>

      <Grid item xs={2}>
        <CheckBoxField label="Add end date" name="addEndDate" />
      </Grid>

      {values?.addEndDate ? (
        <Grid item xs={2}>
          <DateTimePickerField label="End Date" name="end_date" />
        </Grid>
      ) : null}
    </>
  );
};

export const MarketingListForm = ({ values }) => (
  <>
    <Grid item xs={2}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={4}>
      <TextField label="Description" name="description" multiline />
    </Grid>

    <Grid item xs={2}>
      <CheckBoxField label="Add expiry date" name="addExpiryDate" />
    </Grid>

    {values.addExpiryDate ? (
      <Grid item xs={2}>
        <DateTimePickerField label="Expiry date" name="expiry_date" />
      </Grid>
    ) : null}

    <Grid item xs={4}>
      <AutocompleteFieldV2
        name="workflow"
        label="Automata"
        requestKey="marketing-list-automata"
        fetchUrl={workflowsUrls.list}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.name,
        })}
      />
    </Grid>
  </>
);

export const ActionForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={2} sm={6}>
      <TextField label="Description" name="description" multiline />
    </Grid>
  </>
);

export const ActivityNotesForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <ActivityKindDropdown
          refetch={({ id, name }) => {
            setFieldValue('kind', { id, label: name });
            queryClient.invalidateQueries(['activity-kind']);
          }}
        >
          <AutocompleteFieldV2
            name="kind"
            label="Kind"
            requestKey="activity-kind"
            fetchUrl={activityKindsUrls.list}
            required
          />
        </ActivityKindDropdown>
      </Grid>

      <Grid item xs={4}>
        <ActivityTypesDropdown
          refetch={({ id, name }) => {
            setFieldValue('type', { id, label: name });
            queryClient.invalidateQueries(['activity-type']);
          }}
        >
          <AutocompleteFieldV2
            name="type"
            label="Type"
            requestKey="activity-type"
            fetchUrl={activityTypesUrls.list}
            required
          />
        </ActivityTypesDropdown>
      </Grid>

      <Grid item xs={4}>
        <TextField label="INA" name="ina" multiline />
      </Grid>

      <Grid item xs={4}>
        <TextField label="Notes" name="notes" multiline />
      </Grid>
    </>
  );
};

export const SalesPeopleTargetForm = ({ values, setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={3}>
        <EmployeeDropdownForm
          title="Sales person"
          isSalesPerson
          refetch={({ id, work_email }) => {
            setFieldValue('sales_person', { id, label: work_email });
            queryClient.invalidateQueries(['sales-people-target-person']);
          }}
        >
          <AutocompleteFieldV2
            name="sales_person"
            label="Sales person"
            requestKey="sales-people-target-person"
            fetchUrl={employeesUrls.list}
            urlParams={`&department_name=Sales&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.work_email,
            })}
            required
          />
        </EmployeeDropdownForm>
      </Grid>

      <Grid item xs={3}>
        <PipelineDropdown
          refetch={({ id, name }) => {
            setFieldValue('pipeline', { id, label: name });
            queryClient.invalidateQueries(['sales-people-target-pipeline']);
          }}
        >
          <AutocompleteFieldV2
            name="pipeline"
            label="Pipelines"
            requestKey="sales-people-target-pipeline"
            fetchUrl={pipelinesUrls.list}
            required
          />
        </PipelineDropdown>
      </Grid>

      <Grid item xs={3}>
        <PipelineStagesDropdown
          pipelineId={values?.pipeline}
          refetch={({ id, stage }) => {
            setFieldValue('pipeline_stage', { id, label: stage });
            queryClient.invalidateQueries([
              'sales-people-target-pipeline-stage',
            ]);
          }}
        >
          <AutocompleteFieldV2
            name="pipeline_stage"
            label="Pipeline stages"
            requestKey="sales-people-target-pipeline-stage"
            fetchUrl={pipelineStagesUrls.list}
            disabled={!values?.pipeline}
            enabled={!!values?.pipeline?.id}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row.order} ${row.stage}`,
            })}
            urlParams={
              values?.pipeline
                ? `&pipeline=${values.pipeline?.id}&ordering=created_at`
                : ''
            }
            required
          />
        </PipelineStagesDropdown>
      </Grid>

      <Grid item xs={1}>
        <TextField label="Target" name="target" required />
      </Grid>

      <Grid item xs={2}>
        <SelectField label="Target unit" name="target_unit">
          {targetUnitsChoices.map((choice) => (
            <MenuItem value={choice.label} key={choice.label}>
              {choice.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item xs={2}>
        <CheckBoxField label="Add expiry date" name="addExpiryDate" />
      </Grid>
      {values.addExpiryDate ? (
        <Grid item xs={2}>
          <DateTimePickerField label="Expiry date" name="expiry_date" />
        </Grid>
      ) : null}

      <Grid item xs={6}>
        <TextField label="Notes" name="notes" multiline />
      </Grid>
    </>
  );
};

export const TerritoryOwnersForm = ({ setFieldValue, values } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={3}>
        <EmployeeDropdownForm
          title="Sales person"
          refetch={({ id, work_email }) => {
            setFieldValue('sales_person', { id, label: work_email });
            queryClient.invalidateQueries(['territory-owner-person']);
          }}
        >
          <AutocompleteFieldV2
            name="sales_person"
            label="Sales person"
            requestKey="territory-owner-person"
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.work_email,
            })}
            required
          />
        </EmployeeDropdownForm>
      </Grid>

      <Grid item xs={2}>
        <CheckBoxField label="Add expiry date" name="addExpiryDate" />
      </Grid>
      {values.addExpiryDate ? (
        <Grid item xs={2}>
          <DateTimePickerField label="Expiry date" name="expiry_date" />
        </Grid>
      ) : null}
    </>
  );
};

export const AccountManagersForm = ({ setFieldValue, values } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <EmployeeDropdownForm
          title="Account Manager"
          refetch={({ id, work_email }) => {
            setFieldValue('account_manager', { id, label: work_email });
            queryClient.invalidateQueries(['account-manager-person']);
          }}
        >
          <AutocompleteFieldV2
            name="account_manager"
            label="Account Manager"
            requestKey="account-manager-person"
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.work_email,
            })}
            required
          />
        </EmployeeDropdownForm>
      </Grid>

      <Grid item xs={2}>
        <CheckBoxField label="Add expiry date" name="addExpiryDate" />
      </Grid>
      {values.addExpiryDate ? (
        <Grid item xs={2}>
          <DateTimePickerField label="Expiry date" name="expiry_date" />
        </Grid>
      ) : null}
    </>
  );
};

export const CompanyTerritoriesForm = ({ setFieldValue, values } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={3}>
        <TerritoryDropdown
          refetch={({ id, name }) => {
            setFieldValue('territory', { id, label: name });
            queryClient.invalidateQueries(['company-territory']);
          }}
        >
          <AutocompleteFieldV2
            name="territory"
            label="Territory"
            requestKey="company-territory"
            fetchUrl={territoriesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </TerritoryDropdown>
      </Grid>

      <Grid item xs={2}>
        <CheckBoxField label="Add expiry date" name="addExpiryDate" />
      </Grid>
      {values.addExpiryDate ? (
        <Grid item xs={2}>
          <DateTimePickerField label="Expiry date" name="expiry_date" />
        </Grid>
      ) : null}
    </>
  );
};

export const TerritoriesForm = ({ values }) => (
  <>
    <Grid item xs={2}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={4}>
      <TextField label="Description" name="description" multiline />
    </Grid>

    <Grid item xs={2}>
      <CheckBoxField label="Add expiry date" name="addExpiryDate" />
    </Grid>
    {values.addExpiryDate ? (
      <Grid item xs={2}>
        <DateTimePickerField label="Expiry date" name="expiry_date" />
      </Grid>
    ) : null}
  </>
);

export const CompaniesForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={2}>
        <TextField label="Name" name="name" required />
      </Grid>

      <Grid item xs={4}>
        <TextField label="Description" name="description" multiline />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Phone" name="phone" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Website" name="website" placeholder="https://" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="News Url" name="news_url" placeholder="https://" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Staff Url" name="staff_url" placeholder="https://" />
      </Grid>

      <Grid item xs={2}>
        <CheckBoxField label="Beta Partner" name="beta_partner" />
      </Grid>

      <Grid item xs={2.5}>
        <TextField label="Address1" name="address1" />
      </Grid>

      <Grid item xs={2.5}>
        <TextField label="Address2" name="address2" />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label="City" name="city" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="State/County" name="state" />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label="ZIP/Post code" name="zip" />
      </Grid>

      <Grid item xs={2}>
        <CountryDropdown
          refetch={({ id, name }) => {
            setFieldValue('country', { id, label: name });
            queryClient.invalidateQueries(['company-country']);
          }}
        >
          <AutocompleteFieldV2
            name="country"
            label="Country"
            requestKey="company-country"
            fetchUrl={countriesUrls.list}
          />
        </CountryDropdown>
      </Grid>

      <Grid item xs={3}>
        <TextField label="Tags" name="_tags" multiline />
      </Grid>
    </>
  );
};

export const PersonsForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={1.5}>
        <TextField label="First name" name="first_name" required />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label="Last name" name="last_name" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Email" name="email" required />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Personal Mobile" name="personal_mobile" />
      </Grid>

      <Grid item xs={2.5}>
        <TextField label="Address1" name="address1" />
      </Grid>

      <Grid item xs={2.5}>
        <TextField label="Address2" name="address2" />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label="City" name="city" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="State/County" name="state" />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label="ZIP/Post code" name="zip" />
      </Grid>

      <Grid item xs={2}>
        <CountryDropdown
          refetch={({ id, name }) => {
            setFieldValue('country', { id, label: name });
            queryClient.invalidateQueries(['person-country']);
          }}
        >
          <AutocompleteFieldV2
            name="country"
            label="Country"
            requestKey="person-country"
            fetchUrl={countriesUrls.list}
          />
        </CountryDropdown>
      </Grid>

      <Grid item xs={3}>
        <TextField label="Source" name="source" />
      </Grid>

      <Grid item xs={3.5}>
        <TextField label="Source Note" name="source_notes" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Tags" name="tags" />
      </Grid>
    </>
  );
};

export const CompanyOfficeForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2}>
        <TextField label="Name" name="name" required />
      </Grid>
      <Grid item xs={4}>
        <TextField label="Description" multiline name="description" />
      </Grid>
      <Grid item xs={2}>
        <TextField label="Phone" name="business_phone" />
      </Grid>
      <Grid item xs={2}>
        <TextField label="City" name="city" />
      </Grid>
      <Grid item xs={2}>
        <CountryDropdown
          refetch={({ id, name }) => {
            setFieldValue('country', { id, label: name });
            queryClient.invalidateQueries(['person-country']);
          }}
        >
          <AutocompleteFieldV2
            name="country"
            label="Country"
            requestKey="person-companies"
            fetchUrl={countriesUrls.list}
            required
          />
        </CountryDropdown>
      </Grid>
      <Grid item xs={3}>
        <TextField label="Tags" name="tags" multiline />
      </Grid>
    </>
  );
};

export const PersonSocialMediaForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <SocialDropdown
          refetch={({ id, name }) => {
            setFieldValue('social_media', { id, label: name });
            queryClient.invalidateQueries(['person-social-media']);
          }}
        >
          <AutocompleteFieldV2
            name="social_media"
            label="Social media"
            requestKey="person-social-media"
            fetchUrl={socialMediaTypesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </SocialDropdown>
      </Grid>
      <Grid item xs={2}>
        <TextField label="Username" name="username" required />
      </Grid>
      <Grid item xs={2}>
        <TextField label="Url" name="url" placeholder="https://" required />
      </Grid>
    </>
  );
};

export const HistoryForm = () => {
  return (
    <>
      <Grid item xs={12}>
        <TextField label="Notes" name="notes" multiline rows={4} />
      </Grid>
      <Grid item xs={4}>
        <UploadField name="history" />
      </Grid>
    </>
  );
};

export const PersonHistoryForm = () => {
  return (
    <>
      <Grid item xs={12}>
        <TextField label="Notes" name="notes" multiline rows={4} />
      </Grid>
      <Grid item xs={4}>
        <UploadField name="history" />
      </Grid>
    </>
  );
};

export const OpportunityHistoryForm = () => {
  return (
    <>
      <Grid item xs={12}>
        <TextField label="Notes" name="notes" multiline rows={4} />
      </Grid>
      <Grid item xs={6}>
        <UploadField name="url" />
      </Grid>
    </>
  );
};

export const ClientHistoryForm = () => {
  return (
    <>
      <Grid item xs={4}>
        <UploadField name="url" />
      </Grid>
    </>
  );
};

export const CompanyContactForm = ({
  setFieldValue,
  companyId,
  companyName,
} = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <PersonDropdown
          company={{ id: companyId, label: companyName }}
          refetch={({ id, email }) => {
            setFieldValue('person', {
              id: id,
              label: `${email || ''}`,
            });
            queryClient.invalidateQueries(['company-contact-persons']);
          }}
        >
          <AutocompleteFieldV2
            name="person"
            label="Person"
            requestKey="company-contact-persons"
            fetchUrl={personsUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.first_name || 'Unamed'} ${row?.last_name || ''}`,
            })}
            required
          />
        </PersonDropdown>
      </Grid>

      <Grid item xs={4}>
        <TextField label="Job Title" name="job_title" />
      </Grid>

      <Grid item xs={3}>
        <TextField label="Work Email" name="work_email" />
      </Grid>

      <Grid item xs={3}>
        <TextField label="Work Phone" name="work_phone" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Work Mobile" multiline name="work_mobile" />
      </Grid>

      <Grid item xs={2}>
        <DateTimePickerField label="Started" name="start_date" />
      </Grid>

      <Grid item xs={2}>
        <DateTimePickerField label="End Date" name="end_date" />
      </Grid>
    </>
  );
};

export const ClientsForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <OpportunityDropdown
          refetch={({ id, name, company_contact }) => {
            setFieldValue('opportunity', {
              id: id,
              label: `${name || ''}`,
              companyContact: company_contact,
            });
            queryClient.invalidateQueries(['client-company-contact']);
          }}
        >
          <AutocompleteFieldV2
            name="opportunity"
            label="Opportunity"
            requestKey="client-company-contact"
            fetchUrl={opportunitiesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
              companyContact: row?.company_contact,
            })}
            required
          />
        </OpportunityDropdown>
      </Grid>
      <Grid item xs={8}>
        <TextField label="Notes" name="notes" multiline rows={2} />
      </Grid>
    </>
  );
};

export const OpportunityForm = ({ values, setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={6}>
        <TextField label="Opportunity Title" name="name" multiline required />
      </Grid>

      <Grid item xs={3}>
        <CompanyDropdown
          refetch={({ id, name }) => {
            setFieldValue('companyId', { id, label: name });
            queryClient.invalidateQueries(['opportunity-companies']);
          }}
        >
          <AutocompleteFieldV2
            name="companyId"
            label="Company"
            requestKey="opportunity-companies"
            fetchUrl={companiesUrls.list}
            required
          />
        </CompanyDropdown>
      </Grid>

      <Grid item xs={3}>
        <CompanyContactDropdown
          company={values?.companyId}
          refetch={({ id, details }) => {
            setFieldValue('companyContact', {
              id,
              label: `${details?.person?.first_name ?? ''} ${
                details?.person?.last_name ?? ''
              }`,
            });
            queryClient.invalidateQueries(['opportunity-company-contacts']);
          }}
        >
          <AutocompleteFieldV2
            name="companyContact"
            label="Company Contact"
            requestKey="opportunity-company-contacts"
            fetchUrl={companyContactUrls.list}
            disabled={!values?.companyId}
            enabled={!!values?.companyId?.id}
            requestKeyOptions={[values?.companyId?.id]}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.details?.person?.first_name ?? ''} ${
                row?.details?.person?.last_name ?? ''
              }`,
            })}
            urlParams={
              values?.companyId ? `&company_id=${values.companyId?.id}` : ''
            }
            required
          />
        </CompanyContactDropdown>
      </Grid>

      <Grid item xs={3}>
        <PipelineDropdown
          refetch={({ id, name }) => {
            setFieldValue('pipeline', { id, label: name });
            queryClient.invalidateQueries(['opportunity-pipelines']);
          }}
        >
          <AutocompleteFieldV2
            name="pipeline"
            label="Pipelines"
            requestKey="opportunity-pipelines"
            fetchUrl={pipelinesUrls.list}
            required
          />
        </PipelineDropdown>
      </Grid>

      <Grid item xs={3}>
        <PipelineStagesDropdown
          pipelineId={values?.pipeline}
          refetch={({ id, stage }) => {
            setFieldValue('status', { id, label: stage });
            queryClient.invalidateQueries(['opportunity-pipeline-stages']);
          }}
        >
          <AutocompleteFieldV2
            name="status"
            label="Pipeline stage"
            requestKey="opportunity-pipeline-stages"
            fetchUrl={pipelineStagesUrls.list}
            disabled={!values?.pipeline}
            enabled={!!values?.pipeline?.id}
            requestKeyOptions={[values?.pipeline?.id]}
            renderRow={(row) => ({
              id: row?.id,
              label: `${Number(row?.order).toFixed(2)} ${row?.stage}`,
            })}
            urlParams={
              values?.pipeline
                ? `&pipeline=${values.pipeline?.id}&ordering=order`
                : ''
            }
            required
          />
        </PipelineStagesDropdown>
      </Grid>

      {/* <Grid item xs={2}>
        <TextField
          label="Probability %"
          placeholder="1-100"
          name="probability"
        />
      </Grid> */}

      <Grid item xs={3}>
        <SelectField label="Sentiment" name="sentiment">
          {opportunitySentiments.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item xs={3}>
        <SelectField label="Priority" name="customer_priority">
          {opportunityPriority.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Description"
          multiline
          minRows={2}
          name="description"
        />
      </Grid>

      <Grid item xs={2}>
        <DateTimePickerField
          label="Estimated Close Date"
          name="estimated_close_date"
        />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Estimated Value" name="estimated_value" />
      </Grid>
      <Grid item xs={2}>
        <TextField label="Actual Value" name="actual_value" />
      </Grid>
      <Grid item xs={3}>
        <TextField label="Data Source" multiline name="data_source" />
      </Grid>

      <Grid item xs={3}>
        <ChannelDropdown
          refetch={({ id, name }) => {
            setFieldValue('channel', { id, label: name });
            queryClient.invalidateQueries(['opportunity-channels-list']);
          }}
        >
          <AutocompleteFieldV2
            name="channel"
            label="Channel"
            requestKey="opportunity-channels-list"
            fetchUrl={channelsUrls.list}
          />
        </ChannelDropdown>
      </Grid>
      <Grid item xs={3}>
        <AutocompleteFieldV2
          name="owner"
          label="Owner"
          requestKey="opportunity-owner"
          fetchUrl={employeesUrls.list}
          urlParams={`&department_name=Sales&ordering=created_at`}
          required
          renderRow={(row) => ({
            id: row?.id,
            label: row?.work_email,
          })}
        />
      </Grid>
    </>
  );
};

export const OpportunityInfluencerForm = ({ setFieldValue, company }) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={3}>
        <CompanyContactDropdown
          refetch={({ id, details }) => {
            setFieldValue('company_contact', {
              id,
              label: `${details?.person?.first_name ?? ''} ${
                details?.person?.last_name ?? ''
              }`,
            });
            queryClient.invalidateQueries([
              'opportunity-influencer-company-contact',
            ]);
          }}
        >
          <AutocompleteFieldV2
            name="company_contact"
            label="Company Contact"
            requestKey="opportunity-influencer-company-contact"
            fetchUrl={companyContactUrls.list}
            urlParams={`&ordering=created_at${
              company ? `&company_id=${company}` : ''
            }`}
            enabled={!!company}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.details?.person?.first_name ?? ''} ${
                row?.details?.person?.last_name ?? ''
              }`,
            })}
            required
          />
        </CompanyContactDropdown>
      </Grid>

      <Grid item xs={2}>
        <TextField label="Role" multiline required name="role" />
      </Grid>

      <Grid item xs={2.5}>
        <TextField
          label="Desire for Company"
          multiline
          name="desire_for_company"
        />
      </Grid>

      <Grid item xs={2.5}>
        <TextField label="Desire for Self" multiline name="desire_for_self" />
      </Grid>

      <Grid item xs={1.5}>
        <SelectField label="Rating" name="rating">
          {opportunityInfluencerRatings.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
    </>
  );
};

export const SocialMediaTypeForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={2} sm={6}>
      <TextField label="Description" name="description" />
    </Grid>
  </>
);

export const OpportunityProductsForm = ({ setFieldValue, values } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={3}>
        <ProductDropdown
          refetch={({ id, name }) => {
            setFieldValue('product', {
              id,
              label: `${name ?? ''}`,
            });
            queryClient.invalidateQueries(['opportunity-product']);
          }}
        >
          <AutocompleteFieldV2
            name="product"
            label="Product"
            requestKey="opportunity-product"
            urlParams={`&ordering=created_at`}
            fetchUrl={productsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </ProductDropdown>
      </Grid>

      <Grid item xs={3}>
        <ProductVariantDropdown
          product={values?.product}
          refetch={({ id, name }) => {
            setFieldValue('product_variant', {
              id,
              label: `${name ?? ''}`,
            });
            queryClient.invalidateQueries(['opportunity-product-variant']);
          }}
        >
          <AutocompleteFieldV2
            name="product_variant"
            label="Product Variant"
            disabled={!values?.product}
            enabled={!!values?.product?.id}
            urlParams={
              values?.product
                ? `&product=${values.product?.id}&ordering=created_at`
                : ''
            }
            requestKey="opportunity-product-variant"
            fetchUrl={productVariantsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
              price: row?.price,
            })}
            required
            onChange={(v) =>
              !values.estimated_value
                ? setFieldValue('estimated_value', v?.price ?? 0)
                : null
            }
          />
        </ProductVariantDropdown>
      </Grid>
      <Grid item xs={2}>
        <TextField label="Amount" required name="amount" />
      </Grid>
      <Grid item xs={2.5}>
        <TextField label="Estimated Value" name="estimated_value" />
      </Grid>
    </>
  );
};

export const RelationshipForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={2} sm={6}>
      <TextField label="Description" name="description" />
    </Grid>
  </>
);

export const PipelineForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={2} sm={6}>
      <TextField label="Description" name="description" />
    </Grid>
  </>
);

export const PersonRelationshipHistoryForm = () => (
  <>
    <Grid item xs={12}>
      <TextField label="Notes" name="notes" minRows={3} multiline required />
    </Grid>
  </>
);

export const PipelineStageForm = () => (
  <>
    <Grid item xs={1.5}>
      <TextField label="Order" name="order" required />
    </Grid>

    <Grid item xs={2}>
      <TextField label="Name" name="stage" required multiline />
    </Grid>

    <Grid item xs={3.5}>
      <TextField label="Description" name="description" multiline />
    </Grid>

    <Grid item xs={1.5}>
      <TextField label="Confidence %" name="confidence" />
    </Grid>

    <Grid item xs={1.5}>
      <TextField label="Conversion %" name="conversion" />
    </Grid>

    <Grid item xs={3.5}>
      <TextField
        label="Immediate Next Action"
        name="immediate_next_action"
        multiline
        required
      />
    </Grid>

    <Grid item xs={1.5}>
      <TextField label="Rotting Days" name="rotting_days" />
    </Grid>
  </>
);

export const CompanySICForm = () => (
  <>
    <Grid item xs={6}>
      <TextField label="SIC Code" name="sic_code" />
    </Grid>
  </>
);

export const CompanySocialMediaForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2.5}>
        <SocialDropdown
          refetch={({ id, name }) => {
            setFieldValue('social_media', { id, label: name });
            queryClient.invalidateQueries(['company-social-media']);
          }}
        >
          <AutocompleteFieldV2
            name="social_media"
            label="Social media"
            requestKey="company-social-media"
            fetchUrl={socialMediaTypesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </SocialDropdown>
      </Grid>
      <Grid item xs={4}>
        <TextField label="Url" placeholder="https://" name="url" required />
      </Grid>
    </>
  );
};

export const DataNeededForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label="Info Needed" name="info_needed" multiline required />
    </Grid>
    <Grid item xs={4}>
      <TextField label="Who From" name="who_from" multiline required />
    </Grid>
    <Grid item xs={4}>
      <TextField label="Note" name="notes" multiline />
    </Grid>
  </>
);

export const ActionPlanForm = () => (
  <>
    <Grid item xs={5}>
      <TextField label="What" name="what" multiline required />
    </Grid>
    <Grid item xs={5}>
      <TextField label="Who" name="who" multiline required />
    </Grid>
    <Grid item xs={2}>
      <DateTimePickerField label="When" name="when" />
    </Grid>
  </>
);

export const CompanySpinsForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={12} sx={{ display: 'flex' }}>
        <Grid item xs={3}>
          <CompanyDropdown
            refetch={({ id, name }) => {
              setFieldValue('_company', { id, label: name });
              queryClient.invalidateQueries(['companyspins-company']);
            }}
          >
            <AutocompleteFieldV2
              name="_company"
              label="Company"
              requestKey="companyspins-company"
              fetchUrl={companiesUrls.list}
              required
            />
          </CompanyDropdown>
        </Grid>
        <Grid item xs={3}>
          <SelectField label="Buyer Influence" name="buyer_influence" required>
            {companySpinsBuyerInfluence.map((r) => (
              <MenuItem value={r.value} key={r.value}>
                {r.label}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <TextField
          label="Situation"
          name="situation"
          multiline
          minRows={3}
          required
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          label="Problem"
          name="problem"
          multiline
          minRows={3}
          required
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          label="Implication"
          name="implication"
          multiline
          minRows={3}
          required
        />
      </Grid>

      <Grid item xs={3}>
        <TextField label="Need" name="need" multiline minRows={3} required />
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex' }}>
        <Grid item xs={4}>
          <TextField label="Notes" name="notes" multiline />
        </Grid>
      </Grid>
    </>
  );
};
export const CustomerEnquiryStatusForm = () => {
  return (
    <>
      <Grid item xs={4}>
        <TextField label="Name" name="name" />
      </Grid>
      <Grid item xs={4}>
        <TextField label="Description" name="description" multiline />
      </Grid>
    </>
  );
};

export const CustomerEnquiryPurposeForm = () => {
  return (
    <>
      <Grid item xs={4}>
        <TextField label="Name" name="name" />
      </Grid>
      <Grid item xs={4}>
        <TextField label="Description" name="description" multiline />
      </Grid>
    </>
  );
};

export const CustomerEnquiryForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={4}>
        <PersonDropdown
          refetch={({ id, email }) => {
            if (id && email) {
              setFieldValue('person', {
                id: id,
                label: email,
              });
            }

            queryClient.invalidateQueries(['customer-enquiry-persons']);
          }}
        >
          <AutocompleteFieldV2
            name="person"
            label="Person"
            requestKey="customer-enquiry-persons"
            urlParams={`&ordering=created_at`}
            fetchUrl={personsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.email,
              email: row?.email,
              first_name: row?.first_name,
              last_name: row?.last_name,
              personal_mobile: row?.personal_mobile,
            })}
            onChange={(value) => {
              setFieldValue('first_name', value?.first_name ?? '');
              setFieldValue('last_name', value?.last_name ?? '');
              setFieldValue('phone', value?.personal_mobile ?? '');
              setFieldValue('email', value?.email ?? '');
            }}
            required
          />
        </PersonDropdown>
      </Grid>
      <Grid item xs={4}>
        <AutocompleteFieldV2
          name="status"
          label="Status"
          requestKey="customer-enquiry-statuses"
          urlParams={`&ordering=created_at`}
          fetchUrl={customerEnquiryStatusesUrls.list}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
        />
      </Grid>
      <Grid item xs={4}>
        <AutocompleteFieldV2
          name="purpose"
          label="Purpose"
          requestKey="customer-enquiry-purposes"
          urlParams={`&ordering=created_at`}
          fetchUrl={customerEnquiryPurposesUrls.list}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField label="Email" name="email" />
      </Grid>
      <Grid item xs={4}>
        <TextField label="First Name" name="first_name" />
      </Grid>
      <Grid item xs={4}>
        <TextField label="Last Name" name="last_name" />
      </Grid>
      <Grid item xs={4}>
        <TextField label="Phone" name="phone" />
      </Grid>
      <Grid item xs={4}>
        <TextField label="Message" name="message" multiline />
      </Grid>
      <Grid item xs={4}>
        <TextField label="Source" name="source" />
      </Grid>
      <Grid item xs={4}>
        <TextField label="Source Note" name="source_notes" />
      </Grid>
    </>
  );
};
