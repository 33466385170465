import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { activityNotesRowsMap } from '@config/handleRows/crm';
import { ActivityNotesForm } from '@config/forms/crm';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { activityNotesUrls } = crmUrls;

export default function ActivityNotes({ editMode, labels }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  return (
    <Box>
      <ListView
        label="Activity Notes"
        editMode={editMode}
        labels={labels}
        prefix="0i1"
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.activityNote}
        urls={activityNotesUrls}
        columnKey="activityNotesCRM"
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            ActivityNotesForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { kind, type, ...rest } = values;

              const response = await createRecord({
                values: {
                  ...rest,
                  kind: kind?.id,
                  type: type?.id,
                },
                url: activityNotesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={activityNotesRowsMap}
      />
    </Box>
  );
}
