import { Grid } from '@mui/material';
import { TextField, CheckBoxField, DateTimePickerField } from '@components/Inputs';
import AutocompleteField from '@components/Inputs/AutocompleteField';

export const DiscountCodeForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <TextField label='Amount' name='amount' />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <TextField label='Percent' name='percent' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <DateTimePickerField label='Start Code' name='start_code' showTime/>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <DateTimePickerField label='End Code' name='end_code' showTime/>
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <CheckBoxField label='Universal' name='universal' />
    </Grid>
  </>
);

export const PaymentStatusForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>
  </>
);

export const PaymentProviderForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>
  </>
);

export const PaymentMethodForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>
  </>
);

export const DeliveryMethodForm = ({
  paymentProviders,
  fetchingPaymentProviders,
  setPaymentProviderTerm,
}) => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        label='Payment Provider'
        options={paymentProviders}
        name='provider'
        optionLabel='label'
        isLoading={fetchingPaymentProviders}
        setSearchTerm={(term) => setPaymentProviderTerm(term)}
        required
      />
    </Grid>
  </>
);

export const DeliveryStatusForm = ({
  deliveryMethods,
  fetchingDeliveryMethods,
  setDeliveryMethodTerm,
}) => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Description' name='description' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        label='Delivery Method'
        options={deliveryMethods}
        name='delivery_method'
        optionLabel='label'
        isLoading={fetchingDeliveryMethods}
        setSearchTerm={(term) => setDeliveryMethodTerm(term)}
        required
      />
    </Grid>
  </>
);

export const CartForm = ({
  customers,
  fetchingCustomers,
  setCustomerTerm,
  deliveryStatuses,
  fetchingDeliveryStatuses,
  setDeliveryStatusTerm,
  paymentStatuses,
  fetchingPaymentStatuses,
  setPaymentStatusTerm,
  discountCodes,
  fetchingDiscountCodes,
  setDiscountCodeTerm,
}) => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        label='Customer'
        options={customers}
        name='customer'
        optionLabel='label'
        isLoading={fetchingCustomers}
        setSearchTerm={(term) => setCustomerTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Grand Total' name='grand_total' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='delivery_status'
        options={deliveryStatuses}
        label='Delivery Status'
        optionLabel='label'
        isLoading={fetchingDeliveryStatuses}
        setSearchTerm={(term) => setDeliveryStatusTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='payment_status'
        options={paymentStatuses}
        label='Payment Status'
        optionLabel='label'
        isLoading={fetchingPaymentStatuses}
        setSearchTerm={(term) => setPaymentStatusTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='discount_code'
        options={discountCodes}
        label='Discount Code'
        optionLabel='label'
        isLoading={fetchingDiscountCodes}
        setSearchTerm={(term) => setDiscountCodeTerm(term)}
      />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <CheckBoxField label='Same Delivery' name='same_delivery_for_all_items' />
    </Grid>
  </>
);

export const AddressForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='First Name' name='first_name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Last Name' name='last_name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Email' name='email' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Mobile' name='mobile' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Organisation' name='organisation' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Address1' name='address1' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Address2' name='address2' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='City' name='city' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Post Code' name='postcode' required />
    </Grid>
  </>
);

export const PaymentForm = ({
  carts,
  fetchingCarts,
  setCartTerm,
  addresses,
  fetchingAddresses,
  setAddressTerm,
  paymentProviders,
  fetchingPaymentProviders,
  setPaymentProviderTerm,
  paymentMethods,
  fetchingPaymentMethods,
  setPaymentMethodTerm,
  paymentStatuses,
  fetchingPaymentStatuses,
  setPaymentStatusTerm,
}) => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='cart'
        options={carts}
        label='Cart'
        optionLabel='label'
        isLoading={fetchingCarts}
        setSearchTerm={(term) => setCartTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='billing_address'
        options={addresses}
        label='Billing Address'
        optionLabel='label'
        isLoading={fetchingAddresses}
        setSearchTerm={(term) => setAddressTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='payment_provider'
        options={paymentProviders}
        label='Payment Provider'
        optionLabel='label'
        isLoading={fetchingPaymentProviders}
        setSearchTerm={(term) => setPaymentProviderTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='payment_method'
        options={paymentMethods}
        label='Payment Method'
        optionLabel='label'
        isLoading={fetchingPaymentMethods}
        setSearchTerm={(term) => setPaymentMethodTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='payment_status'
        options={paymentStatuses}
        label='Payment Status'
        optionLabel='label'
        isLoading={fetchingPaymentStatuses}
        setSearchTerm={(term) => setPaymentStatusTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <DateTimePickerField label='Payment Date' name='payment_date' showTime/>
    </Grid>
  </>
);

export const CartItemForm = ({
  productVariants,
  fetchingProductVariants,
  setProductVariantTerm,
  carts,
  fetchingCarts,
  setCartTerm,
  deliveryStatuses,
  fetchingDeliveryStatuses,
  setDeliveryStatusTerm,
}) => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='product_variant'
        options={productVariants}
        label='Product Variant'
        optionLabel='label'
        isLoading={fetchingProductVariants}
        setSearchTerm={(term) => setProductVariantTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='cart'
        options={carts}
        label='Cart'
        optionLabel='label'
        isLoading={fetchingCarts}
        setSearchTerm={(term) => setCartTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='delivery_status'
        options={deliveryStatuses}
        label='Delivery Status'
        optionLabel='label'
        isLoading={fetchingDeliveryStatuses}
        setSearchTerm={(term) => setDeliveryStatusTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Quanity' name='quanity' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='List Price' name='list_price' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Discount' name='discount' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Vat Percentage' name='vat_percentage' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Total' name='total' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Campaign Id' name='campaign_id' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Campaign Discount' name='campaign_discount' />
    </Grid>
  </>
);

export const ItemDeliveryForm = ({
  cartItems,
  fetchingCartItems,
  setCartItemTerm,
  deliveryMethods,
  fetchingDeliveryMethods,
  setDeliveryMethodTerm,
  deliveryStatuses,
  fetchingDeliveryStatuses,
  setDeliveryStatusTerm,
  addresses,
  fetchingAddresses,
  setAddressTerm,
}) => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='cart_item'
        options={cartItems}
        label='Cart Item'
        optionLabel='label'
        isLoading={fetchingCartItems}
        setSearchTerm={(term) => setCartItemTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='delivery_method'
        options={deliveryMethods}
        label='Delivery Method'
        optionLabel='label'
        isLoading={fetchingDeliveryMethods}
        setSearchTerm={(term) => setDeliveryMethodTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='delivery_status'
        options={deliveryStatuses}
        label='Delivery Status'
        optionLabel='label'
        isLoading={fetchingDeliveryStatuses}
        setSearchTerm={(term) => setDeliveryStatusTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        name='delivery_address'
        options={addresses}
        label='Delivery Address'
        optionLabel='label'
        isLoading={fetchingAddresses}
        setSearchTerm={(term) => setAddressTerm(term)}
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='External Reference' name='external_reference' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Delivery Cost' name='delivery_cost' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Vat Percent' name='vat_percent' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <DateTimePickerField
        label='Promised Delivery Date'
        name='promised_delivery_date'
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Campaign Id' name='campaign_id' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Campaign Discount' name='campaign_discount' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Tracking Url' name='tracking_url' />
    </Grid>
  </>
);
