import React from 'react';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';

export const SOPsForm = () => (
  <>
    <Grid item xs={3}>
      <TextField key='name' label='Name' name='name' required />
    </Grid>
    <Grid item xs={6}>
      <TextField
        multiline
        key='description'
        label='Description'
        name='description'
        rows={3}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField key='definitions' label='Definitions' name='definitions' />
    </Grid>

    <Grid item xs={3}>
      <TextField key='revision' label='Revision' name='revision' />
    </Grid>
    <Grid item xs={3}>
      <TextField key='purpose' label='Purpose' name='purpose' />
    </Grid>
    <Grid item xs={3}>
      <TextField key='scope' label='Scope' name='scope' />
    </Grid>
    <Grid item xs={3}>
      <TextField key='roles' label='Roles' name='roles' />
    </Grid>
  </>
);
export const SopTaskForm = () => (
  <>
    <Grid item xs={1}>
      <TextField label='Order' name='order' required />
    </Grid>

    <Grid item xs={3}>
      <TextField key='name' label='Name' name='name' required />
    </Grid>

    <Grid item xs={3}>
      <TextField
        key='conditional_requirements'
        label='Conditional Requirements'
        name='conditional_requirements'
      />
    </Grid>

    <Grid item xs={3}>
      <TextField key='actioned_by' label='Actioned By' name='actioned_by' />
    </Grid>
    <Grid item xs={3}>
      <TextField
        key='resources_required'
        label='Resources Required'
        name='resources_required'
      />
    </Grid>

    <Grid item xs={3}>
      <TextField
        key='potential_warning'
        label='Potential Warning'
        name='potential_warning'
      />
    </Grid>
    <Grid item xs={3}>
      <TextField key='notes' label='Notes' name='notes' />
    </Grid>
    <Grid item xs={6}>
      <TextField
        multiline
        key='explanation'
        label='Explanation'
        name='explanation'
      />
    </Grid>
  </>
);

export const SopTaskStepForm = () => (
  <>
    <Grid item xs={4}>
      <TextField key='name' label='Name' name='name' required />
    </Grid>
    <Grid item xs={6}>
      <TextField
        multiline
        key='description'
        label='Description'
        name='description'
        rows={3}
      />
    </Grid>
  </>
);
