import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import {
  communicationUrls,
  crmUrls,
  calendarUrls,
  nodeDriveUrls,
} from '@config/routes';
import { opportunitiesMeta } from '@config/meta/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import {
  opportunityInfluencerRowsMap,
  opportunityProductRowsMap,
  dataNeededRowsMap,
  actionPlanRowsMap,
  opportunityHistoryRowsMap,
  inasRowsMap,
} from '@config/handleRows/crm';
import { emailRowsMap } from '@config/handleRows/communication';
import {
  OpportunityInfluencerForm,
  OpportunityProductsForm,
  DataNeededForm,
  ActionPlanForm,
  OpportunityHistoryForm,
} from '@config/forms/crm';
import { useQueryClient } from 'react-query';
import Button from '@ui/Button';
import ReminderPopup from '@components/ReminderPopup';

const {
  opportunitiesUrls,
  opportunityInfluencersUrls,
  opportunityProductsUrls,
  dataNeedsUrls,
  actionPlansUrls,
  opportunityHistoryUrls,
} = crmUrls;
const { actionRemindersUrls } = calendarUrls;
const { filesUrls } = nodeDriveUrls;
const { notificationsUrls } = communicationUrls;

export default function OpportunityDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const [expand, setExpand] = React.useState(true);
  const [showAddReminder, setShowAddReminder] = React.useState(false);

  const getSchema = React.useCallback(
    (data) =>
      opportunitiesMeta({ data, queryClient, colorOnDetailCard: false }),
    []
  );

  const influencersColOptions = React.useMemo(() => {
    return {
      companyContact: {
        id: recordData?.company_contact,
        label: recordData?.person_detail?.person?.first_name,
      },
      role: recordData?.details?.company_contact?.job_title,
    };
  }, [
    recordData?.company_contact,
    recordData?.person_detail?.person?.first_name,
    recordData?.details?.company_contact?.job_title,
  ]);

  const emailHistoriesColOptions = React.useMemo(() => {
    return { dataPanel: true };
  }, []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/opportunities`, text: 'Opportunities' },
              { text: recordData?.name },
            ]}
          >
            <Button
              type='button'
              variant='outlined'
              label='Add INA'
              onClick={() => setShowAddReminder(true)}
            />
          </DetailPageHeader>
        </Box>
      ) : null}

      {showAddReminder ? (
        <ReminderPopup
          type='INA'
          microservice='CRM'
          model='Opportunity'
          open={showAddReminder}
          setOpen={setShowAddReminder}
          selectedRows={[recordId]}
          setSelectedRows={() => null}
          link={`${window.location.host}/opportunities/${recordId}`}
          company={recordData?.details?.company?.name}
          editMode={editMode}
          labels={labels}
          prefix='0bs'
        />
      ) : null}

      <DetailCard
        urls={opportunitiesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      {recordData ? (
        <Box sx={{ mt: 5 }}>
          <DetailAccordionView
            expand={expand}
            setExpand={setExpand}
            columnKey='inasCRM'
            label='INAs'
            editMode={editMode}
            labels={labels}
            prefix='0r4'
            urls={actionRemindersUrls}
            handleRowMap={inasRowsMap}
            urlParams={`&entity=Opportunity&entity_microservice=CRM&reminder_type=INA Reminder Event&entity_id=${recordId}`}
          />

          <DetailAccordionView
            microservice={microservices.CRM.name}
            model={microservices.CRM.models.opportunityHistory}
            columnKey='opportunityHistoryCRM'
            label='History'
            editMode={editMode}
            labels={labels}
            prefix='0r1'
            urls={opportunityHistoryUrls}
            addNewForm={{
              getFields: ({ values, errors }) =>
                OpportunityHistoryForm({ values, errors }),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError
              ) => {
                try {
                  let file = '';

                  const { notes, url, ...rest } = values;

                  if (url) {
                    const formData = new FormData();
                    formData.append('anonymous_can_see_it', true);
                    formData.append('file', url);

                    const { data: fileData } = await createRecord({
                      values: formData,
                      url: filesUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                    });

                    file = fileData.fileUrl;
                  }

                  const response = await createRecord({
                    values: {
                      url: file,
                      opportunity: recordId,
                      notes: notes,
                      ...rest,
                    },
                    url: opportunityHistoryUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  // notify('History Created', {
                  //   type: 'SUCCESS',
                  // });
                  refetch();
                  return response?.data?.id;
                } catch (err) {
                  console.log(err.response || err.message);
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={opportunityHistoryRowsMap}
            urlParams={`&opportunity=${recordId}`}
          />

          <DetailAccordionView
            microservice={microservices.CRM.name}
            model={microservices.CRM.models.actionPlan}
            columnKey='opportunityActionPlanCRM'
            label='Action Plan'
            editMode={editMode}
            labels={labels}
            prefix='084'
            urls={actionPlansUrls}
            addNewForm={{
              getFields: () => ActionPlanForm(),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError
              ) => {
                try {
                  const response = await createRecord({
                    values: { ...values, opportunity: recordId },
                    url: actionPlansUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  // notify('Action Plan Created', {
                  //   type: 'SUCCESS',
                  // });
                  refetch();
                  return response?.data?.id;
                } catch (err) {
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={actionPlanRowsMap}
            urlParams={`&opportunity=${recordId}`}
          />

          <DetailAccordionView
            microservice={microservices?.CRM?.name}
            model={microservices?.CRM?.models?.opportunityInfluencers}
            columnKey='opportunityInfluencerCRM'
            columnOptions={influencersColOptions}
            label='Influencers'
            editMode={editMode}
            labels={labels}
            prefix='0tk'
            urls={opportunityInfluencersUrls}
            addNewForm={{
              getFields: ({ setFieldValue }) =>
                OpportunityInfluencerForm({
                  setFieldValue,
                  company: recordData?.company,
                }),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError
              ) => {
                try {
                  const { company_contact, ...rest } = values;

                  const response = await createRecord({
                    values: {
                      ...rest,
                      opportunity: recordId,
                      company_contact: company_contact?.id,
                    },
                    url: opportunityInfluencersUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  // notify('Influencer Created', {
                  //   type: 'SUCCESS',
                  // });
                  refetch();
                  return response?.data?.id;
                } catch (err) {
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={opportunityInfluencerRowsMap}
            urlParams={`&opportunity=${recordId}`}
          />

          <DetailAccordionView
            microservice={microservices.CRM.name}
            model={microservices.CRM.models.opportunityProduct}
            columnKey='opportunityProductsCRM'
            label='Product Variants'
            editMode={editMode}
            labels={labels}
            prefix='0z5'
            urls={opportunityProductsUrls}
            addNewForm={{
              getFields: ({ setFieldValue, values }) =>
                OpportunityProductsForm({
                  setFieldValue,
                  values,
                }),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError,
                setFieldError
              ) => {
                try {
                  const { product_variant, ...rest } = values;

                  const errors = [];

                  if (!product_variant?.id) {
                    errors.push('product_variant');
                  }

                  if (errors.length) {
                    errors.forEach((name) => {
                      setFieldError(name, 'This field is required');
                    });

                    return;
                  }

                  const response = await createRecord({
                    values: {
                      ...rest,
                      opportunity: recordId,
                      product_variant: product_variant?.id,
                    },
                    url: opportunityProductsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  refetch();
                  return response?.data?.id;
                } catch (err) {
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={opportunityProductRowsMap}
            urlParams={`&opportunity=${recordId}`}
          />

          <DetailAccordionView
            microservice={microservices.CRM.name}
            model={microservices.CRM.models.dataNeeded}
            columnKey='opportunityDataNeedCRM'
            label='Data Needed'
            editMode={editMode}
            labels={labels}
            prefix='0ul'
            urls={dataNeedsUrls}
            addNewForm={{
              getFields: () => DataNeededForm(),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError
              ) => {
                try {
                  const response = await createRecord({
                    values: { ...values, opportunity: recordId },
                    url: dataNeedsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  // notify('Data Need Created', {
                  //   type: 'SUCCESS',
                  // });
                  refetch();
                  return response?.data?.id;
                } catch (err) {
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={dataNeededRowsMap}
            urlParams={`&opportunity=${recordId}`}
          />

          <DetailAccordionView
            columnKey='emailCommunication'
            columnOptions={emailHistoriesColOptions}
            handleRowsMap={emailRowsMap}
            label='Email History'
            urls={notificationsUrls}
            urlParams={`&all_inbox=true&history_email=${(
              recordData?.person_detail?.person?.all_emails ?? []
            ).toString()}&ordering=-created_at`}
          />
        </Box>
      ) : null}
    </Box>
  );
}
