import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import { drawerWidth } from '@config/constants';
import { List } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function LeftDrawerBase({
  onDrawerToggle,
  mobileOpen,
  children,
  laptopOpen,
}) {
  const isMobile = useMediaQuery('(max-width:600px)');

  const container =
    typeof window !== 'undefined' ? () => window.document.body : undefined;

  const drawer = (
    <div>
      <Toolbar variant='dense' />
      <Divider />
      <List>{children}</List>
    </div>
  );

  return (
    <Box
      component='nav'
      sx={{
        display: laptopOpen ? '' : 'none',
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
    >
      {isMobile ? (
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={onDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      ) : null}

      {isMobile ? null : (
        <Drawer
          key='open'
          variant='persistent'
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          open={laptopOpen}
        >
          {drawer}
        </Drawer>
      )}
    </Box>
  );
}
