import React from 'react';
import * as Yup from 'yup';
import { lmsUrls, crmUrls } from '@config/routes';
import { studentMeta } from '@config/meta/lms';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';
import { useQueryClient } from 'react-query';

const { studentsUrls } = lmsUrls;
const { personsUrls } = crmUrls;

export default function StudentDropdownForm({ title, children, refetch }) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  const renderPerson = (row) => {
    const { id, email } = row;
    return {
      id,
      label: email,
    };
  };

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title={title || 'Students'}
        initialValues={{
          ...getAddFormInitialValues(studentMeta({ queryClient }), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            studentMeta({ queryClient }),
            'listView'
          ),
        })}
        getPostUrl={() => studentsUrls.list()}
        getValues={(values) => ({
          ...values,
          personId: values?.personId?.id,
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3} sx={{ pb: 1 }}>
            <Grid item xs={12}>
              <PersonDropdown
                refetch={({
                  id,
                  email,
                }) => {
                  setFieldValue('personId', {
                    id: id,
                    label: email,
                  });
                  queryClient.invalidateQueries(['student-person'])
                }}
              >
                <AutocompleteFieldV2
                  name='personId'
                  label='Person'
                  requestKey='student-person'
                  fetchUrl={personsUrls.list}
                  renderRow={renderPerson}
                  required
                />
              </PersonDropdown>
            </Grid>
            <Grid item xs={12}>
              <TextField label='Alias' name='alias' required />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
