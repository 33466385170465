import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import {
  TextField,
  CheckBoxField,
  AutocompleteFieldV2,
  UploadField,
  SelectField,
} from '@components/Inputs';
import { cmsV2Urls, marketingV2Urls, bpaUrls } from '@config/routes';
import PageDropdown from '@dropdown-forms/cms/PageDropdown.jsx';
import PageGroupDropdown from '@dropdown-forms/cms/PageGroupDropdown.jsx';
import MarketSegmentsDropdown from '@dropdown-forms/marketing/MarketSegmentsDropdown.jsx';
import { useQueryClient } from 'react-query';

const {
  pageTemplatesUrls,
  pagesUrls,
  pageGroupsUrls,
  domainsUrls,
  menuOptionsUrls,
  menusUrls,
} = cmsV2Urls;
const { marketSegmentsUrls } = marketingV2Urls;
const { workflowsUrls } = bpaUrls;

export const PageTemplateForm = () => (
  <>
    <Grid item xs={6}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={6}>
      <TextField label='Tags' name='tags' />
    </Grid>
  </>
);

export const PageTemplateOptionForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label='Key' name='key' required />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Value' name='value' multiline required />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Tags' name='tags' />
    </Grid>
  </>
);

export const PageForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={4}>
        <TextField label='Name' name='name' required multiline />
      </Grid>

      <Grid item xs={4}>
        <PageGroupDropdown
          refetch={({ id, name }) => {
            setFieldValue('pageGroupId', { id, label: name });
            queryClient.invalidateQueries(['cms-page-group-page']);
          }}
        >
          <AutocompleteFieldV2
            name='pageGroupId'
            label='Page Group'
            requestKey='page-group'
            fetchUrl={pageGroupsUrls.list}
            required
          />
        </PageGroupDropdown>
      </Grid>

      <Grid item xs={4}>
        <AutocompleteFieldV2
          name='pageTemplateId'
          label='Page Template'
          requestKey='page-template'
          fetchUrl={pageTemplatesUrls.list}
          required
        />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Description' name='description' multiline />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Meta Title' name='metaTitle' />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Meta Description' name='metaDescription' multiline />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Canonical URL' name='canonicalUrl' multiline />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Tags' name='tags' />
      </Grid>

      <Grid item xs={2}>
        <CheckBoxField label='Published' name='isPublished' />
      </Grid>
    </>
  );
};

export const PageMarketSegmentForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={6}>
        <MarketSegmentsDropdown
          refetch={({ id, name }) => {
            setFieldValue('marketSegmentId', { id, label: name });
            queryClient.invalidateQueries(['page-market-segment']);
          }}
        >
          <AutocompleteFieldV2
            name='marketSegmentId'
            label='Market Segment'
            requestKey='market-segment'
            fetchUrl={marketSegmentsUrls.list}
            required
          />
        </MarketSegmentsDropdown>
      </Grid>
    </>
  );
};

export const PageGroup = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={4}>
        <TextField label='Name' name='name' required multiline />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteFieldV2
          name='domainId'
          label='Subdomain'
          requestKey='domainId'
          fetchUrl={domainsUrls.list}
          required
          renderRow={(row) => ({
            id: row?.id,
            label: row?.domain,
          })}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Slug' name='slug' required multiline />
      </Grid>

      <Grid item xs={4}>
        <PageDropdown
          refetch={({ id, name }) => {
            setFieldValue('defaultPageId', { id, label: name });
            queryClient.invalidateQueries(['page-group-domain']);
          }}
        >
          <AutocompleteFieldV2
            name='defaultPageId'
            label='Default Page'
            requestKey='page'
            fetchUrl={pagesUrls.list}
          />
        </PageDropdown>
      </Grid>

      <Grid item xs={4}>
        <AutocompleteFieldV2
          name='menuId'
          label='Menu'
          requestKey='menuId'
          fetchUrl={menusUrls.list}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
        />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteFieldV2
          name='automataId'
          label='Automata'
          requestKey='automata'
          fetchUrl={workflowsUrls.list}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Description' name='description' />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Tags' name='tags' />
      </Grid>

      <Grid item xs={2}>
        <CheckBoxField label='Published' name='isPublished' />
      </Grid>
    </>
  );
};

export const PageToPageGroupForm = ({ hidePage, setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      {hidePage ? (
        <Grid item xs={4}>
          <PageGroupDropdown
            refetch={({ id, name }) => {
              setFieldValue('pageGroupId', { id, label: name });
              queryClient.invalidateQueries(['page-group-page']);
            }}
          >
            <AutocompleteFieldV2
              name='pageGroupId'
              label='Page Group'
              requestKey='page-group'
              fetchUrl={pageGroupsUrls.list}
              required
            />
          </PageGroupDropdown>
        </Grid>
      ) : (
        <Grid item xs={4}>
          <PageDropdown
            refetch={({ id, name }) => {
              setFieldValue('pageId', { id, label: name });
              queryClient.invalidateQueries(['page-pageId']);
            }}
          >
            <AutocompleteFieldV2
              name='pageId'
              label='Page'
              requestKey='page'
              fetchUrl={pagesUrls.list}
              required
            />
          </PageDropdown>
        </Grid>
      )}
    </>
  );
};

export const DomainForm = () => (
  <>
    <Grid item xs={12}>
      <TextField label='Subdomain' name='domain' required />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Google Analytics Id' name='googleAnalyticsId' />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Hotjar Code' name='hotjarCode' />
    </Grid>
    <Grid item xs={4}>
      <TextField
        label='Google Search Console Code'
        name='googleSearchConsoleCode'
      />
    </Grid>
    <Grid item xs={6}>
      <UploadField name='favicon' />
    </Grid>
  </>
);

export const MenuForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label='Name' name='name' required multiline />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Tags' name='tags' />
    </Grid>
  </>
);

export const MenuOptionForm = ({ menuId, setFieldValue, values } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item sm={1}>
        <TextField label='Order' name='order' />
      </Grid>

      <Grid item xs={3}>
        <TextField label='Alias' name='alias' required />
      </Grid>

      <Grid item xs={3}>
        <PageGroupDropdown
          refetch={({ id, name }) => {
            setFieldValue('pageGroupId', { id, label: name });
            queryClient.invalidateQueries(['page-group-page']);
          }}
        >
          <AutocompleteFieldV2
            name='pageGroupId'
            label='Page Group'
            requestKey='page-group'
            fetchUrl={pageGroupsUrls.list}
            required
            onChange={() => {
              setFieldValue('pageOptionId', undefined);
            }}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
              defaultPage: row?.defaultPage,
            })}
          />
        </PageGroupDropdown>
      </Grid>

      {Array.isArray(values?.pageGroupId?.defaultPage?.pageOptions) ? (
        <Grid item xs={3}>
          <SelectField label='Page Section' name='pageOptionId' required>
            {values?.pageGroupId?.defaultPage?.pageOptions.map((r) => (
              <MenuItem value={r.id} key={r.id}>
                {r.key}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
      ) : null}

      <Grid item xs={1.5}>
        <CheckBoxField label='Add Parent' name='add_parent' />
      </Grid>

      {values?.add_parent ? (
        <Grid item xs={3}>
          <AutocompleteFieldV2
            name='parentId'
            label='Parent'
            required
            requestKey='parentId'
            fetchUrl={menuOptionsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.alias ?? row?.pageGroup?.name,
            })}
            urlParams={`&menuId=${menuId}`}
          />
        </Grid>
      ) : null}

      <Grid item xs={5}>
        <TextField label='Description' name='description' />
      </Grid>

      <Grid item xs={3}>
        <TextField label='Tags' name='tags' />
      </Grid>
    </>
  );
};

export const PageOptionForm = () => {
  return (
    <>
      <Grid item xs={4}>
        <TextField label='Key' name='key' required />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Value' name='value' required />
      </Grid>
    </>
  );
};
