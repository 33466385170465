import React from 'react';
import { Box, Typography, Button, Stack, Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import { TextField } from '@components/Inputs';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { accountUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import PageWrapper from '@ui/Auth/PageWrapper';
import { getUserStorageKey } from '@config/constants';

const { forgotPwdUrls } = accountUrls;

export default function Forgot() {
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  React.useEffect(() => {
    if (localStorage.getItem(getUserStorageKey())) {
      window.location.href = '/';
    }
  }, []);

  return (
    <PageWrapper>
      <Stack spacing={3} sx={{ width: '400px', mx: 'auto' }}>
        <Box>
          <Typography
            variant='h2'
            sx={{ fontSize: '28px', fontWeight: '500', mb: 0.5 }}
          >
            Forgot password?
          </Typography>
          {success ? null : (
            <Typography variant='p' sx={{ color: 'gray' }}>
              No worries, we'll send you reset instructions
            </Typography>
          )}
        </Box>

        <Box>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={Yup.object({
              email: Yup.string().email().required(),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                setError('');
                setSuccess('');
                await createRecord({
                  values,
                  url: forgotPwdUrls.list(),
                });
                setSuccess(
                  'We have sent an email with instructions on how to change your password to the email address you provided.'
                );
                setTimeout(() => {
                  setSuccess('');
                }, 5000);
                resetForm();
              } catch (err) {
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.email
                ) {
                  setError(err.response.data.email);
                } else {
                  setError('Something went wrong, try reloading');
                }
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form noValidate autoComplete='off'>
                <Stack spacing={3}>
                  {error ? (
                    <Alert icon={false} severity='error'>
                      {error}
                    </Alert>
                  ) : null}

                  {success ? (
                    <Alert icon={false} severity='success'>
                      {success}
                    </Alert>
                  ) : null}

                  <Box>
                    <TextField name='email' label='Email' />
                  </Box>

                  <Box>
                    <Button
                      fullWidth
                      type='submit'
                      disabled={isSubmitting}
                      variant='contained'
                    >
                      Reset password
                    </Button>
                  </Box>

                  <Box sx={{ textAlign: 'center' }}>
                    <Link
                      to='/auth/login'
                      style={{ textDecoration: 'none', color: '#444' }}
                    >
                      Back to log in
                    </Link>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </PageWrapper>
  );
}
