import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import DiscountCodes from '@pages/E-Commerce/DiscountCodes';
import DiscountCodeDetail from '@pages/E-Commerce/DiscountCodes/detail';
import PaymentStatuses from '@pages/E-Commerce/PaymentStatuses';
import PaymentStatusDetail from '@pages/E-Commerce/PaymentStatuses/detail';
import PaymentProviders from '@pages/E-Commerce/PaymentProviders';
import PaymentProviderDetail from '@pages/E-Commerce/PaymentProviders/detail';
import PaymentMethods from '@pages/E-Commerce/PaymentMethods';
import PaymentMethodDetail from '@pages/E-Commerce/PaymentMethods/detail';
import DeliveryMethods from '@pages/E-Commerce/DeliveryMethods';
import DeliveryMethodDetail from '@pages/E-Commerce/DeliveryMethods/detail';
import DeliveryStatuses from '@pages/E-Commerce/DeliveryStatuses';
import DeliveryStatusDetail from '@pages/E-Commerce/DeliveryStatuses/detail';
import Carts from '@pages/E-Commerce/Carts';
import CartDetail from '@pages/E-Commerce/Carts/detail';
import Addresses from '@pages/E-Commerce/Addresses';
import AddressDetail from '@pages/E-Commerce/Addresses/detail';
import Payments from '@pages/E-Commerce/Payments';
import CartItems from '@pages/E-Commerce/CartItems';
import CartItemDetail from '@pages/E-Commerce/CartItems/detail';
import ItemDeliveries from '@pages/E-Commerce/ItemDeliveries';

export default function () {
  return (
    <>
      <Route
        path='/discount-codes'
        element={<WithLayout comp={DiscountCodes} />}
      />
      <Route
        path='/discount-codes/:id'
        element={<WithLayout comp={DiscountCodeDetail} />}
      />
      <Route
        path='/payment-statuses'
        element={<WithLayout comp={PaymentStatuses} />}
      />
      <Route
        path='/payment-statuses/:id'
        element={<WithLayout comp={PaymentStatusDetail} />}
      />
      <Route
        path='/payment-providers'
        element={<WithLayout comp={PaymentProviders} />}
      />
      <Route
        path='/payment-providers/:id'
        element={<WithLayout comp={PaymentProviderDetail} />}
      />
      <Route
        path='/payment-methods'
        element={<WithLayout comp={PaymentMethods} />}
      />
      <Route
        path='/payment-methods/:id'
        element={<WithLayout comp={PaymentMethodDetail} />}
      />
      <Route
        path='/delivery-methods'
        element={<WithLayout comp={DeliveryMethods} />}
      />
      <Route
        path='/delivery-methods/:id'
        element={<WithLayout comp={DeliveryMethodDetail} />}
      />
      <Route
        path='/delivery-statuses'
        element={<WithLayout comp={DeliveryStatuses} />}
      />
      <Route
        path='/delivery-statuses/:id'
        element={<WithLayout comp={DeliveryStatusDetail} />}
      />
      <Route path='/carts' element={<WithLayout comp={Carts} />} />
      <Route path='/carts/:id' element={<WithLayout comp={CartDetail} />} />
      <Route path='/addresses' element={<WithLayout comp={Addresses} />} />
      <Route
        path='/addresses/:id'
        element={<WithLayout comp={AddressDetail} />}
      />
      <Route path='/payments' element={<WithLayout comp={Payments} />} />
      <Route path='/cart-items' element={<WithLayout comp={CartItems} />} />
      <Route
        path='/cart-items/:id'
        element={<WithLayout comp={CartItemDetail} />}
      />
      <Route
        path='/item-deliveries'
        element={<WithLayout comp={ItemDeliveries} />}
      />
    </>
  );
}
