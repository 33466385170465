import React from 'react';
import {
  Box,
  Divider,
  Tab,
  Tabs,
  Grid,
  InputLabel,
  NativeSelect,
  FormControl,
  Typography,
  Button,
} from '@mui/material';
import { bpaUrls } from '@config/routes';
import {
  TextField,
  AutocompleteFieldV2,
  CheckBoxField,
  DateTimePickerField,
} from '@components/Inputs';
import useToast from '@hooks/useToast';
import { deleteRecord } from '@config/functions/requests';

const {
  getPayloadActionsUrls,
  shapeActionParamValuesUrls,
  modelAssetsUrls,
  // prettier
} = bpaUrls;

const parameterTypes = [
  'String',
  'Number',
  'Decimal',
  'Boolean',
  'List',
  'Dict',
  'Date',
  'DateTime',
  'Other',
];

export default function FieldMappingPopupItem({
  index,
  isCPDPopup,
  workflowId,
  renderPayloadSections,
  setPayloadSectionKeys,
  mappedField,
  sectionKeysInit,
  setSectionKeysInit,
  parameter,
  renderPayloadKeyOptions,
  payloadSectionKeys,
  remove,
  user,
  msAndModelExist,
  setFieldValue,
}) {
  const hasCustomValueWithoutPayload = React.useMemo(() => {
    const hasNoPayloadSection = !mappedField?.payloadSection;
    const hasNoPayloadSectionKey = !mappedField?.payloadSectionKey;
    const hasCustomValue = !!mappedField?.customValue;

    return hasNoPayloadSection && hasNoPayloadSectionKey && hasCustomValue;
  }, [mappedField]);

  const [value, setValue] = React.useState(
    hasCustomValueWithoutPayload ? 1 : 0
  );
  const [notify] = useToast();

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', position: 'relative' }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="field mapping tabs"
        >
          <Tab label="Select from Payload" {...a11yProps(0)} />
          <Tab label="Custom Value" {...a11yProps(1)} />
        </Tabs>

        <Box sx={{ position: 'absolute', top: '16%', right: 0 }}>
          <Button
            variant="text"
            color="error"
            size="small"
            onClick={async () => {
              try {
                remove(index);
                await deleteRecord({
                  url: shapeActionParamValuesUrls.detail(mappedField?.id),
                  token: user.token,
                  actAs: user?.actAs,
                });
              } catch (error) {
                console.log(error?.response?.data ?? error?.message);
                notify('There was an error, please try again', {
                  type: 'ERROR',
                });
              }
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <PayloadTabContent
          isCPDPopup={isCPDPopup}
          renderPayloadSections={renderPayloadSections}
          index={index}
          workflowId={workflowId}
          setPayloadSectionKeys={setPayloadSectionKeys}
          mappedField={mappedField}
          sectionKeysInit={sectionKeysInit}
          setSectionKeysInit={setSectionKeysInit}
          parameter={parameter}
          renderPayloadKeyOptions={renderPayloadKeyOptions}
          payloadSectionKeys={payloadSectionKeys}
          remove={remove}
          user={user}
          setFieldValue={setFieldValue}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <CustomValueTabContent
          index={index}
          parameter={parameter}
          msAndModelExist={msAndModelExist}
          mappedField={mappedField}
          workflowId={workflowId}
          remove={remove}
          user={user}
          setFieldValue={setFieldValue}
        />
      </CustomTabPanel>

      <Divider sx={{ mb: '22px' }} />
    </Box>
  );
}

function ParameterTypeSelect({ index, defaultValue, setFieldValue }) {
  return (
    <FormControl fullWidth size="small">
      <InputLabel variant="standard" htmlFor="uncontrolled-native">
        Type
      </InputLabel>
      <NativeSelect
        defaultValue={defaultValue ?? parameterTypes[0]}
        onChange={(e) => {
          setFieldValue(`mappedFields.${index}.type`, e.target.value);
        }}
        inputProps={{
          name: `mappedFields.${index}.type`,
          id: `mappedFields-${index}-uncontrolled-native`,
        }}
      >
        {parameterTypes.map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}

function PayloadTabContent({
  isCPDPopup,
  renderPayloadSections,
  index,
  workflowId,
  setPayloadSectionKeys,
  mappedField,
  sectionKeysInit,
  setSectionKeysInit,
  parameter,
  renderPayloadKeyOptions,
  payloadSectionKeys,
  setFieldValue,
}) {
  return (
    <Grid sx={{ pb: '22px' }} container spacing={3}>
      {isCPDPopup ? null : (
        <Grid item sm={12}>
          <AutocompleteFieldV2
            label="Payload section"
            name={`mappedFields.${index}.payloadSection`}
            requestKey={`${parameter.id}-map-payload-section`}
            fetchUrl={getPayloadActionsUrls.list}
            urlParams={`&workflow_id=${workflowId}&exclude_draft_stage=true`}
            renderRow={renderPayloadSections}
            onChange={({ sectionKeys }) => {
              if (Array.isArray(sectionKeys)) {
                setPayloadSectionKeys((prevState) => ({
                  ...prevState,
                  [`mappedFields.${index}.payloadSection`]: sectionKeys,
                }));
              }
            }}
            getData={(data) => {
              const found = data.find(
                (row) => row.id === mappedField?.payloadSection?.id
              );

              if (!found && !sectionKeysInit) {
                setSectionKeysInit(true);
              } else if (
                found?.sectionKeys &&
                Array.isArray(found.sectionKeys) &&
                !sectionKeysInit
              ) {
                setPayloadSectionKeys((prevState) => ({
                  ...prevState,
                  [`mappedFields.${index}.payloadSection`]: found.sectionKeys,
                }));

                setSectionKeysInit(true);
              }
            }}
          />
        </Grid>
      )}

      {parameter.create_payload ? (
        <Grid item sm={6}>
          <TextField
            label="Label"
            name={`mappedFields.${index}.label`}
            required
          />
        </Grid>
      ) : null}

      <Grid item sm={6}>
        <ParameterTypeSelect
          index={index}
          defaultValue={mappedField?.type}
          setFieldValue={setFieldValue}
        />
      </Grid>

      {isCPDPopup ? null : (
        <Grid item sm={6}>
          {sectionKeysInit ? (
            <FormControl fullWidth size="small">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Payload section field
              </InputLabel>
              <NativeSelect
                defaultValue={mappedField?.payloadSectionKey}
                onChange={(e) => {
                  setFieldValue(
                    `mappedFields.${index}.payloadSectionKey`,
                    e.target.value
                  );
                }}
                inputProps={{
                  name: `mappedFields.${index}.payloadSectionKey`,
                  id: `mappedFields-${index}-uncontrolled-native`,
                }}
              >
                <option value={null}>None</option>
                {renderPayloadKeyOptions(index, payloadSectionKeys)}
              </NativeSelect>
            </FormControl>
          ) : (
            <Typography>loading...</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}

function CustomValueTabContent({
  index,
  parameter,
  msAndModelExist,
  mappedField,
  workflowId,
  setFieldValue,
}) {
  return (
    <Grid sx={{ pb: '22px' }} container spacing={3}>
      <Grid item sm={6}>
        <ParameterTypeSelect
          index={index}
          defaultValue={mappedField?.type}
          setFieldValue={setFieldValue}
        />
      </Grid>

      <Grid item sm={12}>
        {parameter.list_url ? (
          <AutocompleteFieldV2
            label={parameter.name}
            name={`mappedFields.${index}.customValue`}
            requestKey={`${parameter.id}-map-customValue`}
            urlParams={
              msAndModelExist
                ? `&microservice=${parameter?.microservice}&model=${parameter?.model}&workflow=${workflowId}`
                : ''
            }
            fetchUrl={
              msAndModelExist
                ? modelAssetsUrls.list
                : (query) => `${parameter.list_url}${query}`
            }
            renderRow={
              msAndModelExist
                ? (row) => {
                    const { name } = row?.details?.asset;

                    return {
                      id: row?.id,
                      label: name,
                    };
                  }
                : undefined
            }
            required
          />
        ) : mappedField.type === 'Date' || mappedField.type === 'DateTime' ? (
          <DateTimePickerField
            showTime={mappedField.type === 'DateTime'}
            label="Default Value"
            name={`mappedFields.${index}.customValue`}
          />
        ) : mappedField.type === 'Boolean' ? (
          <CheckBoxField
            label="Default Value"
            name={`mappedFields.${index}.customValue`}
          />
        ) : (
          <TextField
            type={
              mappedField.type === 'Number' || mappedField.type === 'Decimal'
                ? 'number'
                : 'text'
            }
            label="Default value"
            name={`mappedFields.${index}.customValue`}
          />
        )}
      </Grid>
    </Grid>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
