// handleRows

export const pagesRowsMap = (r) => ({
  id: r.id,
  page_name: r.page_name,
  page_description: r.page_description,
  page_status: r.page_status,
  page_title: r.page_title,
  slug: r.slug,
  order: r.order,
  show_on_menu: r.show_on_menu,
  type: r.type,
  publish_schedule:r.publish_schedule,
  created_at: r.created_at,
});

export const categoryRowsMap = (r) => ({
  id: r.id,
  category_name: r.category_name,
  category_description: r.category_description,
  created_at: r.created_at,
});

export const pageCategoryRowsMap = (r) => ({
  id: r.id,
  pages:
    r.details && r.details.pages
      ? { id: r.pages, label: r.details.pages.page_name }
      : r.pages,
  category:
    r.details && r.details.category
      ? { id: r.category, label: r.details.category.category_name }
      : r.category,
  created_at: r.created_at,
});

export const contentsRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  status: r.status,
  microservice: r.microservice,
  url: r.url,
  content: r.content,
  created_at: r.created_at,
});

export const pageMetaRowsMap = (r) => ({
  id: r.id,
  key: r.key,
  value: r.value,
  pages:
    r.details && r.details.pages
      ? { id: r.pages, label: r.details.pages.name }
      : null,
  created_at: r.created_at,
});
