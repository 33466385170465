import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextField, AutocompleteField } from '@components/Inputs';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { createRecord } from '@config/functions/requests';
import { accountUrls, communicationUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import useToast from '@hooks/useToast';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const { usersUrls } = accountUrls;
const { notificationsUrls } = communicationUrls;

export default function SendEmailModal({
  open,
  setOpen,
  onSendButtonClick,
  data,
  labels,
  editMode,
  prefix,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const user = useSelector(selectProfile);
  const [users, setUsers] = React.useState([]);
  const [userTerm, setUserTerm] = React.useState('');
  const [notify] = useToast();

  const { data: usersList, isFetching: userIsFetching } = useRQuery({
    key: ['users-data', userTerm, user.token, user?.actAs],
    url: usersUrls.list(`?search=${userTerm}&page_size=100&queryCode=70c2`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  React.useEffect(() => {
    if (!usersList) return;
    if (!usersList.results) return;

    setUsers(
      usersList.results.map((item) => ({
        id: item.id,
        label: item.email,
      }))
    );
  }, [usersList]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {prefix ? (
        <RenderUiPrefix
          editMode={editMode}
          labels={labels}
          code={prefix}
          defaultValue="Email guests"
        >
          {(text) => {
            return (
              <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
            );
          }}
        </RenderUiPrefix>
      ) : (
        <DialogTitle id="responsive-dialog-title">Email guests</DialogTitle>
      )}
      <DialogContent>
        <Formik
          key="email-model-formik"
          initialValues={{
            email: data && data.guests,
            subject: data && data.event && data.event.title,
            message: '',
          }}
          validationSchema={Yup.object({
            email: Yup.array().required(),
            subject: Yup.string(),
            message: Yup.string().max(2500).required(),
          })}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            const emails = Array.from(
              new Set(values.email.map((e) => e.label))
            );
            try {
              emails.map(async (email) => {
                const value = {
                  to_emails: [email],
                  subject: values.subject,
                  message: values.message,
                  from_email: 'hamza@pullstream.com',
                  type: 'email',
                  context: {},
                };
                // console.log(value);

                await createRecord({
                  values: value,
                  url: notificationsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
              });

              onSendButtonClick(emails);
              // notify(`Email Send!`, {
              //   type: 'SUCCESS',
              // });
              resetForm();
              setSubmitting(false);
            } catch (err) {
              console.log(err);
              notify(`There was an error, please try again.`, {
                type: 'ERROR',
              });
            }
          }}
        >
          <Form noValidate autoComplete="off">
            <Grid
              key="email-grid-container"
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{ mb: 2 }}
            >
              <Grid item xs={12} sx={{ mt: 2 }}>
                <AutocompleteField
                  key="email"
                  multiple
                  setSearchTerm={setUserTerm}
                  isLoading={userIsFetching}
                  options={users}
                  defaultValue={data && data.guests}
                  label="Add Email"
                  name="email"
                  filterSelectedOptions
                />
              </Grid>

              <Grid item xs={12}>
                <TextField label="Subject" name="subject" />
              </Grid>

              <Grid item xs={12}>
                <TextField label="Message" name="message" multiline rows={4} />
              </Grid>
            </Grid>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" autoFocus>
                Save
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
