import React from 'react';
import * as Yup from 'yup';
import useToast from '@hooks/useToast';
import { lmsUrls, nodeDriveUrls, wikiUrls } from '@config/routes';
import { courseMeta } from '@config/meta/lms';
import { Grid, Typography, Box } from '@mui/material';
import {
  TextField,
  AutocompleteFieldV2,
  UploadField,
} from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { useQueryClient } from 'react-query';
import HomeworkDropdown from '@dropdown-forms/lms/HomeworkDropdown';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import BookDropdown from '@dropdown-forms/wiki/BookDropdown';

const { coursesUrls, homeworksUrls } = lmsUrls;
const { filesUrls } = nodeDriveUrls;
const { booksUrls } = wikiUrls;

export default function CourseDropdownForm({ title, children, refetch }) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title={title || 'Course'}
        initialValues={{
          ...getAddFormInitialValues(courseMeta({ queryClient }), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            courseMeta({ queryClient }),
            'listView'
          ),
        })}
        getPostUrl={() => coursesUrls.list()}
        handleSubmit={async (
          values,
          setSubmitting,
          resetForm,
          setOpen,
          setError,
          setFieldError
        ) => {
          try {
            const { homeworkId, thumbnail, wikiBookId, ...rest } = values;

            if (!wikiBookId) {
              setFieldError('wikiBookId', 'Please select a wikibook');
              return;
            }

            let fileUrl;

            try {
              if (thumbnail) {
                const formData = new FormData();
                formData.append(
                  'client',
                  user?.actAs?.id || user?.details?.client
                );
                formData.append('created_by', user?.details?.id);
                formData.append('anonymous_can_see_it', true);
                formData.append('file', thumbnail);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                fileUrl = fileData.fileUrl;
              }
            } catch {}

            if (thumbnail && !fileUrl) {
              setFieldError('thumbnail', 'Failed to upload thumbnail');
              return;
            }

            const { data } = await createRecord({
              values: {
                ...rest,
                thumbnail: fileUrl || undefined,
                homeworkId: homeworkId?.id,
                wikiBookId: wikiBookId?.id,
              },
              url: coursesUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });

            resetForm();
            setOpen(false);
            refetch(data);
            return data?.id;
          } catch (err) {
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        }}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField label="Course Title" name="name" multiline required />
            </Grid>

            <Grid item xs={6}>
              <HomeworkDropdown
                refetch={({ id, name }) => {
                  setFieldValue('homework', { id, label: name });
                  queryClient.invalidateQueries(['course-homework']);
                }}
              >
                <AutocompleteFieldV2
                  name="homework"
                  label="Course Task"
                  requestKey="course-homework"
                  fetchUrl={homeworksUrls.list}
                />
              </HomeworkDropdown>
            </Grid>
            <Grid item xs={6}>
              <BookDropdown
                refetch={({ id, name }) => {
                  setFieldValue('wikiBookId', { id, label: name });
                  queryClient.invalidateQueries(['course-wiki-book']);
                }}
              >
                <AutocompleteFieldV2
                  name="wikiBookId"
                  label="Wiki Book"
                  requestKey="wiki-book"
                  fetchUrl={booksUrls.list}
                  required
                />
              </BookDropdown>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Course Description"
                name="description"
                minRows={4}
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ mb: 1, fontWeight: '500' }}>
                Thumbnail
              </Typography>
              <Box sx={{ maxWidth: '490px' }}>
                <UploadField name="thumbnail" accept=".png, .jpg, .jpeg" />
              </Box>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
