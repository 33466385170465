import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PublicForm from '@components/Forms/PublicForm';
import { formsUrls } from '@config/routes';
import useRQuery from '@hooks/useRQuery';
import PublicFormErrorScreen from '@components/Forms/PublicFormErrorScreen';
import RequireAuth from '@components/core/RequireAuth';
import Layout from '@components/core/Layout';
import { formBgStyles } from '@config/constants';
import Loader from '@components/Loader';
import SEOHeader from '@components/SEOHeader';
import Box from '@mui/material/Box';

const { formAccessLevelUrls } = formsUrls;

export default function ViewForm() {
  const { id: recordId } = useParams();
  const [queryParams] = useSearchParams();
  const initialFirstName = queryParams.get('first_name');
  const initialLastName = queryParams.get('last_name');
  const initialEmail = queryParams.get('email');
  const initialUTMContent = queryParams.get('utm_content');
  const initialUTMCampaign = queryParams.get('utm_campaign');
  const initialUTMTerm = queryParams.get('utm_term');
  const initialUTMMedium = queryParams.get('utm_medium');
  const initialUTMSource = queryParams.get('utm_source');
  const initialTags = queryParams.get('t');

  const {
    data: form,
    isLoading,
    isError,
  } = useRQuery({
    key: [`${recordId}-view-form`, recordId],
    url: formAccessLevelUrls.detail(recordId),
    options: { enabled: !!recordId },
  });

  if (isError) {
    return <PublicFormErrorScreen />;
  }

  if (isLoading || !form) {
    return (
      <Box flex justifyContent='center' alignContent='center' sx={formBgStyles}>
        <SEOHeader title='View Form' />
        <Loader />
      </Box>
    );
  }

  const formProps = {
    formId: recordId,
    initialFirstName,
    initialLastName,
    initialEmail,
    initialTags,
    initialUTMContent,
    initialUTMCampaign,
    initialUTMTerm,
    initialUTMMedium,
    initialUTMSource,
  };

  if (form?.accessLevel === 'PRIVATE') {
    return (
      <RequireAuth>
        {(userInfo) => (
          <Layout user={userInfo}>
            <PublicForm {...formProps} />
          </Layout>
        )}
      </RequireAuth>
    );
  }

  return <PublicForm {...formProps} />;
}
