import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { cmsV2Urls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { pageTemplateOptionRowsMap } from '@config/handleRows/cms-v2';
import { PageTemplateOptionForm } from '@config/forms/cms-v2';
import { pageTemplateMeta } from '@config/meta/cms-v2';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { pageTemplatesUrls, pageTemplateOptionsUrls } = cmsV2Urls;

export default function PageTemplateDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => pageTemplateMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/page-templates', text: 'Page Templates' },
              { text: recordData?.name ?? 'Unnamed' },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={pageTemplatesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          prefix='0t7'
          label='Page Template Option'
          labels={labels}
          editMode={editMode}
          columnKey='pageTemplateOption'
          urls={pageTemplateOptionsUrls}
          microservice={microservices.CMS.name}
          model={microservices.CMS.models.pageTemplateOption}
          addNewForm={{
            getFields: () => PageTemplateOptionForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { data } = await createRecord({
                  values: { ...values, pageTemplateId: recordId },
                  url: pageTemplateOptionsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={pageTemplateOptionRowsMap}
          urlParams={`&pageTemplateId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
