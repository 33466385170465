import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useRQuery from '@hooks/useRQuery';
import { computeUrls } from '@config/routes';
import { getAuthHeader } from '@config/functions/helperFunctions';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';

const { instanceLogsUrls } = computeUrls;

const stepContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  mt: 1,
};

const stepContentLabelStyles = { ml: 1, fontSize: '14px', color: '#616161' };

export default function BlockStepsList({
  user,
  microserviceId,
  headingStyles,
  instanceId,
  isError,
  blockGroups,
  statusUpdates,
  setStatusUpdates,
}) {
  const { data: instanceLogs, isError: instanceLogError } = useRQuery({
    key: ['block-instance-logs', microserviceId, user.token, user?.actAs],
    url: instanceLogsUrls.list(`?instanceId=${instanceId}&pageSize=100`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user.token && instanceId), refetchInterval: 500 },
    renderRow: (row) => ({
      id: row?.id,
      label: row?.status,
      message: row?.message,
      block: row?.block,
      blockId: row?.blockId,
      createdAt: row?.createdAt,
    }),
  });

  const getBlockStatus = (blockCode) => {
    return statusUpdates?.[blockCode] ?? 'Processing';
  };

  React.useEffect(() => {
    if (Array.isArray(instanceLogs)) {
      // Sort logs by createdAt in ascending order
      const sortedLogs = [...instanceLogs].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );

      sortedLogs.forEach((log, index) => {
        if (!statusUpdates[log.block.code]) {
          // Calculate the delay for each block.
          // For example, if index is 0, delay is 1000ms (1s delay + processing time).
          // If index is 1, delay is 3000ms (1s after the first one has been processed), and so on.
          const delay = (index + 1) * 1000; // Adjust the multiplier based on the desired delay between steps

          setTimeout(() => {
            setStatusUpdates((prevStatus) => ({
              ...prevStatus,
              [log.block.code]: log.label,
            }));
          }, delay);
        }
      });
    }
  }, [instanceLogs, statusUpdates]);

  if (isError || instanceLogError) {
    return (
      <Box sx={{ my: 3 }}>
        <Typography sx={headingStyles}>Failed to fetch steps.</Typography>
      </Box>
    );
  }

  if (!Array.isArray(blockGroups)) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      {blockGroups
        .sort((a, b) => a?.order - b?.order)
        .map((group, index) => (
          <Grid key={group?.id} item xs={3}>
            <Stepper activeStep={0} orientation="vertical">
              <Step completed={false}>
                <StepLabel StepIconProps={{ icon: index + 1 }}>
                  {group?.label}
                </StepLabel>

                {group?.blocks?.map((block) => {
                  const status = getBlockStatus(block?.code);

                  return (
                    <StepContent key={block?.id}>
                      <Box sx={stepContainerStyles}>
                        {status === 'Completed' ? (
                          <CheckCircleIcon
                            fontSize="small"
                            sx={{ color: 'rgb(0, 112, 243)' }}
                          />
                        ) : status === 'Failed' ? (
                          <ErrorOutlineIcon
                            fontSize="small"
                            sx={{ color: 'rgb(255, 0, 0)' }}
                          />
                        ) : (
                          <Box>
                            <CircularProgress size="18px" />
                          </Box>
                        )}
                        <Typography sx={stepContentLabelStyles}>
                          {block?.name}
                        </Typography>
                      </Box>
                    </StepContent>
                  );
                })}
              </Step>
            </Stepper>
          </Grid>
        ))}
    </Grid>
  );
}
