import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editMode: false,
  action: 'idle', // idle, busy, done, or failed
};

export const operationsSlice = createSlice({
  name: 'operationsSlice',
  initialState,
  reducers: {
    setAction: (state, action) => {
      state.action = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
  },
});

export const { setAction, setEditMode } = operationsSlice.actions;

export const selectOperations = (state) => state.operations;

export default operationsSlice.reducer;
