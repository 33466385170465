import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import UserRoles from '@pages/Account/UserRoles';
import UserRoleDetail from '@pages/Account/UserRoles/detail';
import Microservices from '@pages/Account/Microservices';
import Models from '@pages/Account/Models';
import Users from '@pages/Account/Users';
import UserDetail from '@pages/Account/Users/detail';
import Profiles from '@pages/Account/Profiles';

export default function () {
  return (
    <>
      <Route path="/user-roles" element={<WithLayout comp={UserRoles} />} />
      <Route
        path="/user-roles/:id"
        element={<WithLayout comp={UserRoleDetail} />}
      />
      <Route
        path="/microservices"
        element={<WithLayout comp={Microservices} />}
      />
      <Route path="/models" element={<WithLayout comp={Models} />} />
      <Route path="/users" element={<WithLayout comp={Users} />} />
      <Route path="/users/:id" element={<WithLayout comp={UserDetail} />} />
      <Route path="/profiles" element={<WithLayout comp={Profiles} />} />
    </>
  );
}
