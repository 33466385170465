import * as Yup from 'yup';
import { fieldType } from '@config/constants';

export const linkMeta = ({ data } = {}) => [
  {
    field: 'title',
    headerName: 'Name',
    onForm: true,
    editable: true,
    value: data ? data.title : null,
    initialValue: '',
    width: 250,
    type: fieldType.TEXT,
    validate: Yup.string().required(),
    hideColumn: false,
    getHref: (r) => `/links/${r.id}`,
  },
  {
    field: 'destination_url',
    headerName: 'Destination Url',
    onForm: true,
    width: 300,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.destination_url : null,
    initialValue: '',
    validate: Yup.string().url().required(),
    linkInNewTab: true,
    getHref: (r) => `${r.destination_url}`,
  },
  {
    field: 'custom_back_half',
    headerName: 'Custom Back Half',
    onForm: true,
    editable: true,
    value: data ? data.custom_back_half : null,
    initialValue: '',
    width: 250,
    type: fieldType.TEXT,
    validate: Yup.string().min(7, 'Must be exactly 7 digits'),
    hideColumn: true,
  },
  {
    field: 'qr_image',
    headerName: 'QR Code',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.qr_image : null,
    initialValue: '',
    validate: Yup.string().url(),
    getHref: (r) => `${r.qr_image}`,
    hideColumn: true,
  },
  {
    field: 'domain',
    headerName: 'Domain',
    onForm: true,
    editable: false,
    onDetailCard: false,
    value: data ? data.domain : null,
    initialValue: '',
    width: 250,
    type: fieldType.TEXT,
    validate: Yup.string(),
    hideColumn: true,
  },

  {
    field: 'link',
    headerName: 'Link',
    onForm: false,
    editable: false,
    value: data ? `${data.domain}/${data.custom_back_half}` : null,
    initialValue: '',
    width: 300,
    type: fieldType.TEXT,
    validate: Yup.string(),
    linkInNewTab: true,
    getHref: (r) =>
      r.domain && r.custom_back_half
        ? `${r.domain}/${r.custom_back_half}`
        : '#',
  },
  {
    field: 'retire',
    headerName: 'Expiry Date',
    width: 180,
    editable: true,
    initialValue: new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ),
    onForm: true,
    type: fieldType.DATE_TIME,
    hideColumn: true,
    value: data && data.retire ? new Date(data.retire) : null,
    validate: Yup.string(),
  },
  {
    field: 'source',
    headerName: 'Source',
    onForm: false,
    editable: true,
    value: data ? data.source : null,
    initialValue: '',
    hideColumn: true,
    width: 300,
    type: fieldType.TEXT,
    validate: Yup.string().required(),
  },
  {
    field: 'medium',
    headerName: 'Medium',
    onForm: false,
    editable: true,
    value: data ? data.medium : null,
    initialValue: '',
    width: 300,
    hideColumn: true,
    type: fieldType.TEXT,
    validate: Yup.string().required(),
  },
  {
    field: 'campaign',
    headerName: 'Campaign',
    onForm: false,
    editable: true,
    value: data ? data.campaign : null,
    initialValue: '',
    width: 300,
    type: fieldType.TEXT,
    hideColumn: true,
    validate: Yup.string().required(),
  },
  {
    field: 'term',
    headerName: 'Term',
    onForm: false,
    editable: true,
    value: data ? data.term : null,
    initialValue: '',
    hideColumn: true,
    width: 300,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'content',
    headerName: 'Content',
    onForm: false,
    editable: true,
    value: data ? data.content : null,
    initialValue: '',
    hideColumn: true,
    width: 300,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'text',
    headerName: 'Text',
    onForm: true,
    width: 200,
    type: fieldType.MULTILINE,
    editable: true,
    value: data ? data.text : null,
    initialValue: '',
    validate: Yup.string(),
    gridStyle: 12,
    hideColumn: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
