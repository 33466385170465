import React from 'react';
import { Box, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { cmsV2Urls, marketingV2Urls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import {
  pageMarketSegmentRowsMap,
  pageTopageGroupRowsMap,
  pageOptionRowsMap,
} from '@config/handleRows/cms-v2';
import {
  PageMarketSegmentForm,
  PageToPageGroupForm,
  PageOptionForm,
} from '@config/forms/cms-v2';
import { pageMeta } from '@config/meta/cms-v2';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { pagesUrls, pageToPageGroupsUrls, pageOptionsUrls } = cmsV2Urls;
const { landingPageMarketSegmentsUrls } = marketingV2Urls;

export default function PageTemplateDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const queryClient = useQueryClient();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => pageMeta({ data, queryClient }),
    []
  );

  const pageToPageGroupColOptions = React.useMemo(
    () => ({
      hidePage: true,
      hidePageGroups: false,
    }),
    []
  );

  const getEditPageLink = () => {
    const [connectedPageGroup] = recordData?.connectedPageGroups ?? [];
    const domain = connectedPageGroup?.pageGroup?.domain?.domain;
    return `${domain}/_edit/${recordId}`;
  };

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/cms-pages', text: 'Pages' },
              { text: recordData?.name ?? 'Unnamed' },
            ]}
          >
            <a href={getEditPageLink()} target='_blank' rel='noreferrer'>
              <Button
                variant='outlined'
                sx={{ mr: 2 }}
                disabled={!recordData?.connectedPageGroups?.length}
              >
                Edit Page
              </Button>
            </a>
            <a
              href={`${getEditPageLink()}?preview=true`}
              target='_blank'
              rel='noreferrer'
            >
              <Button variant='outlined' sx={{ mr: 2 }}>
                Preview
              </Button>
            </a>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={pagesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        {Array.isArray(user?.details?.roles_names) &&
        (user?.details?.roles_names.includes('Superusers') ||
          user?.details?.roles_names.includes('Company Administrator') ||
          user?.details?.is_superuser ||
          user?.details?.roles_names.includes('Template Admins')) ? (
          <DetailAccordionView
            nodeAPI
            label='Page Options'
            prefix='0m0'
            labels={labels}
            editMode={editMode}
            columnKey='pageOption'
            urls={pageOptionsUrls}
            microservice={microservices.CMS.name}
            model={microservices.CMS.models.pageOptions}
            addNewForm={{
              getFields: () => PageOptionForm(),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError,
                setFieldError
              ) => {
                try {
                  const { data } = await createRecord({
                    values: {
                      pageId: recordId,
                      ...values,
                    },
                    url: pageOptionsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  refetch();
                  resetForm();
                  setAddNewForm(false);
                  return data?.id;
                } catch (err) {
                  console.log('error', err?.response?.data ?? err?.message);
                  handleCreateFormErrors({ err, setError, notify, values: {} });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={pageOptionRowsMap}
            urlParams={`&pageId=${recordId}`}
          />
        ) : null}

        <DetailAccordionView
          nodeAPI
          label='Page Groups'
          prefix='0ug'
          labels={labels}
          editMode={editMode}
          columnKey='pageToPageGroup'
          columnOptions={pageToPageGroupColOptions}
          urls={pageToPageGroupsUrls}
          microservice={microservices.CMS.name}
          model={microservices.CMS.models.pageToPageGroups}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              PageToPageGroupForm({
                hidePage: true,
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { pageGroupId, ...rest } = values;

                if (!pageGroupId?.id) {
                  setFieldError('pageGroupId', 'This field is required');
                  return;
                }

                const { data } = await createRecord({
                  values: {
                    pageGroupId: pageGroupId?.id,
                    pageId: recordId,
                    ...rest,
                  },
                  url: pageToPageGroupsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                refetch();
                resetForm();
                setAddNewForm(false);
                return data?.id;
              } catch (err) {
                console.log('error', err?.response?.data ?? err?.message);
                notify(`One page can only be part of one group.`, {
                  type: 'ERROR',
                });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={pageTopageGroupRowsMap}
          urlParams={`&pageId=${recordId}`}
        />
        <DetailAccordionView
          nodeAPI
          prefix='024'
          label='Market Segment'
          labels={labels}
          editMode={editMode}
          columnKey='pageMarketSegment'
          urls={landingPageMarketSegmentsUrls}
          microservice={microservices.Marketing.name}
          model={microservices.Marketing.models.lpaudiences}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              PageMarketSegmentForm({ setFieldValue }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { marketSegmentId, ...rest } = values;

                if (!marketSegmentId?.id) {
                  setFieldError('marketSegmentId', 'Please select an option.');
                  return;
                }

                const { data } = await createRecord({
                  values: {
                    ...rest,
                    marketSegmentId: marketSegmentId?.id,
                    landingPageId: recordId,
                  },
                  url: landingPageMarketSegmentsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                refetch();
                resetForm();
                setAddNewForm(false);
                return data?.id;
              } catch (err) {
                // handleCreateFormErrors({ err, setError, notify, values });
                console.log('error', err?.response?.data ?? err?.message);
                // handleCreateFormErrors({ err, setError, notify, values:{} });
                notify(`Market segment already exists.`, { type: 'ERROR' });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={pageMarketSegmentRowsMap}
          urlParams={`&landingPageId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
