import React from 'react';
import { formCardStyles } from '@config/constants';
import {
  Box,
  Divider,
  Stack,
  Typography,
  Rating,
  TextField,
} from '@mui/material';
import DateTime from '@components/DateTime';
import MultipleChoiceField from '@components/Forms/MultipleChoiceField';
import MultipleSelectField from '@components/Forms/MultipleSelectField';
import DualButtonSelectField from '@components/Forms/DualButtonSelectField';
import LikertField from '@components/Forms/LikertField';
import AudioRecorder from '@components/Forms/AudioRecorder';
import UploadField from '@components/Forms/UploadField';
import { formatToUTCDate, detectLink } from '@config/functions/helperFunctions';

export default function DisplayQuestionByType({
  currentQuestion,
  getValue,
  handleChange,
  isSubmitting,
  success,
  otherValues,
  setOtherValues,
}) {
  return (
    <Stack
      sx={{
        ...formCardStyles,
        pt: 0,
        px: '24px',
        position: 'relative',
      }}
    >
      <Typography
        sx={{
          color: '#012970',
          fontSize: '18px',
          lineHeight: '24px',
          position: 'sticky',
          top: '0',
          zIndex: '99',
          background: '#fff',
          fontWeight: '600',
          pt: '18px',
          pb: '6px',
        }}
      >
        {currentQuestion?.question}{' '}
        <span style={{ color: '#b50001', fontWeight: '400' }}>
          {currentQuestion?.isRequired ? '*' : ''}
        </span>
      </Typography>
      {currentQuestion?.description ? (
        <Typography
          sx={{
            color: '#012970',
            fontSize: '14px',
            lineHeight: '18px',
            position: 'sticky',
            top: '0',
            zIndex: '99',
            background: '#fff',
            pb: '18px',
            whiteSpace: 'pre-line',
          }}
        >
          {detectLink(currentQuestion?.description ?? '')}
        </Typography>
      ) : null}

      <Divider sx={{ mb: 2 }} />

      {currentQuestion?.type === 'Text' ? (
        <TextField
          label='Your answer'
          size='small'
          variant='outlined'
          fullWidth
          multiline
          rows={2}
          value={getValue(currentQuestion?.id)}
          onChange={(e) => {
            handleChange(currentQuestion?.id, {
              type: currentQuestion?.type,
              value: e.target.value,
            });
          }}
        />
      ) : currentQuestion?.type === 'Audio' ? (
        <AudioRecorder
          isSubmitting={isSubmitting}
          submitted={success}
          setAudio={(file) => {
            handleChange(currentQuestion?.id, {
              type: currentQuestion?.type,
              value: file,
            });
          }}
        />
      ) : currentQuestion?.type === 'MultipleChoice' ? (
        <MultipleChoiceField
          value={getValue(currentQuestion?.id)}
          setValue={(value) => {
            handleChange(currentQuestion?.id, {
              type: currentQuestion?.type,
              value,
            });
          }}
          options={currentQuestion?.options}
          otherValue={otherValues[currentQuestion?.id] || ''}
          setOtherValue={(value) => {
            setOtherValues((state) => ({
              ...state,
              [currentQuestion?.id]: value,
            }));
          }}
          includeOthersField={currentQuestion?.includeOthersField}
        />
      ) : currentQuestion?.type === 'ButtonSelect' ? (
        <DualButtonSelectField
          value={getValue(currentQuestion?.id)}
          setValue={(value) => {
            handleChange(currentQuestion?.id, {
              type: currentQuestion?.type,
              value,
            });
          }}
          options={currentQuestion?.options}
        />
      ) : currentQuestion?.type === 'MultipleSelect' ? (
        <MultipleSelectField
          options={currentQuestion?.options}
          value={getValue(currentQuestion?.id) || []}
          setValue={(value) => {
            handleChange(currentQuestion?.id, {
              type: currentQuestion?.type,
              value,
            });
          }}
        />
      ) : currentQuestion?.type === 'Date' ? (
        <Box sx={{ pt: '6px', maxWidth: '120px' }}>
          <DateTime
            label='Select date'
            data={getValue(currentQuestion?.id) || new Date()}
            handleSubmit={async ({ dateObject }) => {
              handleChange(currentQuestion?.id, {
                type: currentQuestion?.type,
                value: formatToUTCDate(dateObject),
              });
            }}
          />
        </Box>
      ) : currentQuestion?.type === 'Rating' ? (
        <Rating
          name='size-medium'
          value={getValue(currentQuestion?.id)}
          onChange={(e) => {
            handleChange(currentQuestion?.id, {
              type: currentQuestion?.type,
              value: +e.target.value,
            });
          }}
        />
      ) : currentQuestion?.type === 'FileUpload' ? (
        <UploadField
          file={getValue(currentQuestion?.id) || null}
          setFile={(file) => {
            handleChange(currentQuestion?.id, {
              type: currentQuestion?.type,
              value: file,
            });
          }}
        />
      ) : currentQuestion?.type === 'Likert' ? (
        <LikertField
          randomizeLikertOptions={currentQuestion?.randomizeLikertOptions}
          labels={currentQuestion?.likertLabels}
          options={currentQuestion?.options}
          value={getValue(currentQuestion?.id)}
          setValue={(value) => {
            handleChange(currentQuestion?.id, {
              type: currentQuestion?.type,
              value: { ...getValue(currentQuestion?.id), ...value },
            });
          }}
        />
      ) : null}
    </Stack>
  );
}
