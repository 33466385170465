import React from 'react';
import Typography from '@mui/material/Typography';

export default function ErrorScreen({
  text = 'Sorry, something went wrong',
  img = '/img/warning-custom.png',
  imgW = 160,
  imgH = 160,
}) {
  return (
    <>
      {img ? <img src={img} width={imgW} height={imgH} alt='error' /> : null}
      <Typography component='div' sx={{ mt: 2 }} variant='h5'>
        {text}
      </Typography>
    </>
  );
}
