import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { systemUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { templatesMap } from '@config/handleRows/system';
import { TemplatesForm } from '@config/forms/system';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import PublicUserErrorScreen from '@components/CMS-V2/PublicUserErrorScreen';

const { templatesUrls } = systemUrls;

export default function Templates({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  if (
    Array.isArray(user?.details?.roles_names) &&
    !(
      user?.details?.roles_names.includes('Superusers') ||
      user?.details?.is_superuser
    )
  ) {
    return <PublicUserErrorScreen />;
  }

  return (
    <Box>
      <ListView
        label='Templates'
        prefix='0st'
        labels={labels}
        editMode={editMode}
        microservice={microservices.System.name}
        model={microservices.System.models.templates}
        importExport={false}
        urls={templatesUrls}
        columnKey='templateSystem'
        includeClient={true}
        addNewForm={{
          getFields: ({ values }) => TemplatesForm({ values }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values: {
                  ...values,
                  model: values?.model?.id,
                  clone_from: values?.clone_from?.id,
                  custom_params: {},
                },
                url: templatesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={templatesMap}
        urlParams={`&parent_template__isnull=true`}
      />
    </Box>
  );
}
