import React from 'react';
import { Box, Button, Grid, MenuItem } from '@mui/material';
import {
  TextField,
  SelectField,
  DateTimePickerField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import {
  intentChoicesOptions,
  typeChoicesOptions,
  staggerUnitsChoices,
  CRITERIA_CHOICES,
  CRITERIA_OPERATOR_CHOICES,
  CRITERIA_CONNECTOR_CHOICES,
} from '@config/constants';
import AudienceDropdown from '@dropdown-forms/marketing/AudienceDropdown';
import KeywordDropdown from '@dropdown-forms/marketing/KeywordDropdown';
import { FieldArray } from 'formik';
import UploadFilesPopup from '@components/UploadFilesPopup';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { formatToUTCDateTime } from '@config/functions/helperFunctions';
import { marketingUrls } from '@config/routes';
import { useQueryClient } from 'react-query';

const { landingUrls, keywordUrls, audiencesUrls } = marketingUrls;

export const MarketSegmentForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label='Name' name='name' multiline required />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Description' name='description' multiline />
    </Grid>
  </>
);

export const CriteriaForm = () => (
  <>
    <Grid item xs={1}>
      <TextField label="Order" name="order" required />
    </Grid>
    <Grid item md={2}>
      <SelectField label='Connector' name='connector'>
        {CRITERIA_CONNECTOR_CHOICES.map((row) => (
          <MenuItem value={row.value} key={row.value}>
            {row.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>
    <Grid item md={2}>
      <SelectField label='Criteria' name='criteria' required>
        {CRITERIA_CHOICES.map((row) => (
          <MenuItem value={row.value} key={row.value}>
            {row.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>

    <Grid item md={1.5}>
      <SelectField label='Operator' name='operator' required>
        {CRITERIA_OPERATOR_CHOICES.map((row) => (
          <MenuItem value={row.value} key={row.value}>
            {row.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>

    <Grid item xs={5.5}>
      <TextField label='Value' name='value' multiline required />
    </Grid>

    
  </>
);

export const CreatePostForm = ({
  values,
  setValues,
  selectedImages,
  setSelectedImages,
  files,
  setFiles,
}) => {
  const [uploadModal, setUploadModal] = React.useState(false);

  const addNewPost = (values, setValues) => {
    const posts = values.posts.slice();
    posts.push({
      text: '',
      medias: [],
      timing: formatToUTCDateTime(new Date()),
    });
    setValues({ ...values, posts });
  };

  return (
    <>
      <FieldArray name='posts'>
        {({ remove }) =>
          values.posts.map((post, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <TextField
                  label='What would you like to share?'
                  name={`posts.${index}.text`}
                  rows={6}
                  multiline
                  required
                />
              </Grid>

              <Grid
                item
                xs={1.5}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={() => setUploadModal(true)}
                  startIcon={<AddAPhotoOutlinedIcon />}
                >
                  Photo/video
                </Button>

                <UploadFilesPopup
                  postId={index}
                  open={uploadModal}
                  setOpen={setUploadModal}
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>

              <Grid item xs={1.5}>
                <DateTimePickerField
                  label='Schedule Date'
                  name={`posts.${index}.timing`}
                  rruleName={`posts.${index}.rrule`}
                  showTime
                  recurring
                />
              </Grid>

              {index !== 0 && (
                <Grid
                  item
                  xs={1}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box sx={{ m: 'auto', textAlign: 'center' }}>
                    <Button color='error' onClick={() => remove(index)}>
                      Remove
                    </Button>
                  </Box>
                </Grid>
              )}

              {index === values.posts.length - 1 && (
                <Grid item xs={12}>
                  <Box sx={{ m: 'auto', textAlign: 'center' }}>
                    <Button onClick={() => addNewPost(values, setValues)}>
                      + New Post
                    </Button>
                  </Box>
                </Grid>
              )}
            </React.Fragment>
          ))
        }
      </FieldArray>
    </>
  );
};

export const CampaignForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label='Name' name='name' multiline required />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={1.5}>
      <TextField label='Stagger Amount' name='stagger_between_smaccounts' />
    </Grid>

    <Grid item md={1.5}>
      <SelectField label='Stagger Unit' name='stagger_unit'>
        {staggerUnitsChoices.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>
  </>
);

export const LandingForms = () => (
  <>
    <Grid item xs={3}>
      <TextField key='name' label='Name' name='name' required />
    </Grid>
    <Grid item xs={3}>
      <TextField key='slug' label='Slug' name='slug' required />
    </Grid>
    <Grid item xs={3}>
      <TextField key='version' label='Version' name='version' required />
    </Grid>
    <Grid item sm={3}>
      <AutocompleteFieldV2
        name='template'
        label='Template'
        requestKey='landing-page-template'
        fetchUrl={landingUrls.list}
        urlParams={`&ordering=created_at&template=true`}
        required
      />
    </Grid>
    <Grid item xs={6}>
      <TextField key='seo_title' label='SEO Title' name='seo_title' />
    </Grid>
  </>
);

export const GenerateForms = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField key='keyword' label='Keyword' name='keyword' required />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField key='slug' label='Slug' name='slug' required />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField key='title' label='Title' name='title' required />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField
        key='city_criteria'
        label='City Criteria'
        name='city_criteria'
        required
      />
    </Grid>
    <Grid item xs={2} sm={4} md={6}>
      <TextField
        multiline
        key='description'
        label='Description'
        name='description'
        rows={3}
      />
    </Grid>
  </>
);

export const keywordForms = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField key='keyword' label='Keyword' name='keyword' required />
    </Grid>
    <Grid item sm={3}>
      <SelectField label='Intent' name='intent'>
        {intentChoicesOptions.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>
    <Grid item sm={3}>
      <SelectField label='Type' name='type'>
        {typeChoicesOptions.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>
  </>
);
export const LpKeywordForms = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item sm={4}>
        <KeywordDropdown
          refetch={({ id, keyword }) => {
            setFieldValue('keyword', { id, label: keyword });
            queryClient.invalidateQueries(['lp-keyword']);
          }}
        >
          <AutocompleteFieldV2
            name='keyword'
            label='Keyword'
            requestKey='lp-keyword'
            fetchUrl={keywordUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.keyword,
            })}
            required
          />
        </KeywordDropdown>
      </Grid>
    </>
  );
};

export const AudienceForms = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField key='name' label='Name' name='name' required />
    </Grid>
    <Grid item xs={2} sm={4} md={6}>
      <TextField
        multiline
        key='description'
        label='Description'
        name='description'
      />
    </Grid>
  </>
);

export const LpAudienceForms = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item sm={4}>
        <AudienceDropdown
          refetch={({ id, name }) => {
            setFieldValue('audience', { id, label: name });
            queryClient.invalidateQueries(['lp-audience']);
          }}
        >
          <AutocompleteFieldV2
            name='audience'
            label='Audience'
            requestKey='lp-audience'
            fetchUrl={audiencesUrls.list}
            urlParams={`&ordering=created_at`}
            required
          />
        </AudienceDropdown>
      </Grid>
    </>
  );
};

export const SocialMediaForm = () => (
  <>
    <Grid item xs={3}>
      <TextField key='name' label='Name' name='name' required />
    </Grid>
    <Grid item xs={3}>
      <TextField
        key='max_post_length'
        label='Max Post Length'
        name='max_post_length'
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        multiline
        key='description'
        label='Description'
        name='description'
      />
    </Grid>
  </>
);

export const clientSMAccessForm = () => (
  <>
    <Grid item sm={3}>
      <AutocompleteFieldV2
        name='client'
        label='Client'
        requestKey='Client-sm'
        fetchUrl={audiencesUrls.list}
        urlParams={`&ordering=created_at`}
        required
      />
    </Grid>
    <Grid item xs={3}>
      <TextField key='api' label='Api' name='api' />
    </Grid>
    <Grid item xs={6}>
      <TextField multiline key='secret' label='Secret' name='secret' />
    </Grid>
  </>
);

export const userSMAccessForm = () => (
  <>
    <Grid item sm={3}>
      <AutocompleteFieldV2
        name='user'
        label='User'
        requestKey='Client-sm'
        fetchUrl={audiencesUrls.list}
        urlParams={`&ordering=created_at`}
        required
      />
    </Grid>
    <Grid item xs={3}>
      <TextField key='access_token' label='Access Token' name='access_token' />
    </Grid>
    <Grid item xs={3}>
      <TextField
        multiline
        key='token_expiry'
        label='Token Expiry'
        name='token_expiry'
      />
    </Grid>
    <Grid item xs={3}>
      <TextField multiline key='page_id' label='Page' name='page_id' />
    </Grid>
    <Grid item xs={3}>
      <TextField
        multiline
        key='refresh_token'
        label='Refresh Token'
        name='refresh_token'
      />
    </Grid>
  </>
);

export const smPostForm = () => (
  <>
    <Grid item xs={3}>
      <TextField multiline key='post' label='Post' name='post' />
    </Grid>
  </>
);

export const postViaForm = ({ userSM, setUserSMTerm, fetchingUserSM }) => (
  <>
    <Grid item sm={3}>
      <AutocompleteFieldV2
        name='user_token'
        label='user_token'
        requestKey='Client-sm'
        fetchUrl={audiencesUrls.list}
        urlParams={`&ordering=created_at`}
        required
      />
    </Grid>
  </>
);

export const postViaScheduleForm = () => (
  <>
    <Grid item sm={6}>
      <DateTimePickerField label='Timing' name='timing' showTime />
    </Grid>
  </>
);
