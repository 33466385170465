// handleRows

export const chapterHandleRow = (c) => ({
  id: c.id,
  name: c.name,
  order: c.order,
  description: c.description,
  parent_chapter:
    c.parent_chapter && c.details && c.details.parent_chapter
      ? { id: c.parent_chapter, label: c.details.parent_chapter.name }
      : c.parent_chapter,
  created_at: c.created_at,
});

export const bookCategoryHandleRow = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  created_at: c.created_at,
});

export const bookHandleRow = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  category:
    c.category && c.details && c.details.category
      ? { id: c.category, label: c.details.category.name }
      : c.category,
  published: c.published,
  created_at: c.created_at,
});

export const tagHandleRow = (c) => ({
  id: c.id,
  name: c.name,
  created_at: c.created_at,
});

export const sectionHandleRow = (c) => ({
  id: c.id,
  title: c.title,
  order: c.order,
  description: c.description,
  created_at: c.created_at,
});

export const sectionTagHandleRow = (c) => ({
  id: c.id,
  order: c.order,
  tag:
    c.tag && c.details && c.details.tag
      ? { id: c.tag, label: c.details.tag.name }
      : c.tag,
  created_at: c.created_at,
});
