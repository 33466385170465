import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ecommerceUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import { getAuthHeader } from '@config/functions/helperFunctions';
import DetailAccordianArrayView from '@components/DetailAccordionArrayView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import useRQuery from '@hooks/useRQuery';
import ecommerceHandleRows from '@config/handleRows/ecommerce';
import { ItemDeliveryForm, DeliveryStatusForm } from '@config/forms/ecommerce';
import {
  deliveryMethodMeta,
} from '@config/meta/ecommerce';
import DetailPageHeader from '@components/DetailPageHeader';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  deliveryMethodsUrls,
  deliveryStatusesUrls,
  itemDeliveriesUrls,
  cartItemsUrls,
  addressesUrls,
  paymentProvidersUrls,
} = ecommerceUrls;
const { itemDeliveryRowMap, deliveryStatusRowMap } = ecommerceHandleRows;

export default function DeliveryMethodDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [addressTerm, setAddressTerm] = useState('');
  const [cartItemTerm, setCartItemTerm] = useState('');
  const [deliveryMethodTerm, setDeliveryMethodTerm] = useState('');
  const [deliveryStatusTerm, setDeliveryStatusTerm] = useState('');
  const [paymentProviderTerm, setPaymentProviderTerm] = useState('');
  const [notify] = useToast();

  const defaultOptions = {
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
    renderRow: (r) => ({
      id: r.id,
      label: r.name,
    }),
  };

  const { data: paymentProviders, isFetching: fetchingPaymentProviders } =
    useRQuery({
      key: ['payment-providers', paymentProviderTerm, user.token, user?.actAs],
      url: paymentProvidersUrls.list(`?search=${paymentProviderTerm}`),
      ...defaultOptions,
    });

  const { data: addresses, isFetching: fetchingAddresses } = useAutocomplete({
    key: 'addresses',
    getUrl: addressesUrls.list,
    inputValue: addressTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.address1,
      };
    },
  });

  const { data: cartItems, isFetching: fetchingCartItems } = useAutocomplete({
    key: 'cartItems',
    getUrl: cartItemsUrls.list,
    inputValue: cartItemTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.product_variant && r.details && r.details.product_variant
            ? r.details.product_variant.name
            : null,
      };
    },
  });

  const {
    data: deliveryMethods,
    isFetching: fetchingDeliveryMethods,
    refetch: refetchDelivery,
  } = useAutocomplete({
    key: 'deliveryMethods',
    getUrl: deliveryMethodsUrls.list,
    inputValue: deliveryMethodTerm,
  });

  const { data: deliveryStatuses, isFetching: fetchingDeliveryStatuses } =
    useAutocomplete({
      key: 'deliveryStatuses',
      getUrl: deliveryStatusesUrls.list,
      inputValue: deliveryStatusTerm,
    });

  const deliveryStatusesColOptions = React.useMemo(() => {
    return {
      deliveryMethods,
      fetchingDeliveryMethods,
      setDeliveryMethodTerm,
    };
  }, [deliveryMethods, fetchingDeliveryMethods, setDeliveryMethodTerm]);

  const itemDeliveriesColOptions = React.useMemo(() => {
    return {
      cartItems,
      fetchingCartItems,
      setCartItemTerm,
      deliveryMethods,
      fetchingDeliveryMethods,
      setDeliveryMethodTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
      addresses,
      fetchingAddresses,
      setAddressTerm,
    };
  }, [
    cartItems,
    fetchingCartItems,
    setCartItemTerm,
    deliveryMethods,
    fetchingDeliveryMethods,
    setDeliveryMethodTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
    addresses,
    fetchingAddresses,
    setAddressTerm,
  ]);

  const accordData = {
    deliveryStatuses: {
      columnKey: "deliveryStatusEcommerce",
      columnOptions: deliveryStatusesColOptions,
      microservice: 'E-Commerce',
      model: 'DeliveryStatus',

      label: 'Delivery Statuses',
      prefix: '0yz',
      urls: deliveryStatusesUrls,
      addNewForm: {
        getFields: () =>
          DeliveryStatusForm({
            deliveryMethods,
            fetchingDeliveryMethods,
            setDeliveryMethodTerm,
            refetchDelivery,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                delivery_method: recordId,
              },
              url: deliveryStatusesUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Delivery Status Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: deliveryStatusRowMap,
      urlParams: `&delivery_method=${recordId}`,
    },
    itemDeliveries: {
      columnKey: "itemDeliveryEcommerce",
      columnOptions: itemDeliveriesColOptions,
      microservice: 'E-Commerce',
      model: 'itemDelivery',

      label: 'Item Deliveries',
      prefix: '0mc',
      urls: itemDeliveriesUrls,
      addNewForm: {
        getFields: () =>
          ItemDeliveryForm({
            cartItems,
            fetchingCartItems,
            setCartItemTerm,
            deliveryMethods,
            fetchingDeliveryMethods,
            setDeliveryMethodTerm,
            deliveryStatuses,
            fetchingDeliveryStatuses,
            setDeliveryStatusTerm,
            addresses,
            fetchingAddresses,
            setAddressTerm,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                delivery_method: recordId,
              },
              url: itemDeliveriesUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Item Delivery Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: itemDeliveryRowMap,
      urlParams: `&delivery_method=${recordId}`,
    },
  };

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      deliveryMethodMeta({
        data,
        paymentProviders,
        fetchingPaymentProviders,
        setPaymentProviderTerm,
      }),
    [paymentProviders, fetchingPaymentProviders, setPaymentProviderTerm]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/delivery-methods`, text: 'Delivery Methods' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={deliveryMethodsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordianArrayView
          value={accordData}
          labels={labels}
          editMode={editMode}
        />
      </Box>
    </Box>
  );
}
