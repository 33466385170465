import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { companySpinsRowsMap } from '@config/handleRows/crm';
import { CompanySpinsForm } from '@config/forms/crm';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { companySpinsUrls } = crmUrls;

export default function CompanySpins({ editMode, labels }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label="Company Spins"
        editMode={editMode}
        labels={labels}
        prefix="07o"
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.companySPIN}
        urls={companySpinsUrls}
        columnKey="companySpinsCRM"
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            CompanySpinsForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { _company, ...rest } = values;
              const response = await createRecord({
                values: {
                  ...rest,
                  _company: _company?.id,
                },
                url: companySpinsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={companySpinsRowsMap}
      />
    </Box>
  );
}
