import * as Yup from 'yup';
import {
  fieldType,
  dataTypeOptions,
  computeProcessStatuses,
  computeOnDeleteOptions,
  computeModelFormTypes,
  computeForeignKeyTargets,
} from '@config/constants';
import { computeUrls, accountUrls } from '@config/routes';

const {
  modelsUrls,
  modelFieldsUrls,
  enumsUrls,
  microservicesUrls,
  menuDefnsUrls,
} = computeUrls;
const {
  microservicesUrls: accountsMicroservicesUrls,
  modelsUrls: accountsModelsUrls,
  modelFieldsUrls: accountsModelFieldsUrls,
} = accountUrls;

export const microservicesMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 300,
    type: fieldType.LINK,
    to: '/microservice/',
    editable: true,
    value: data?.name ?? null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'version',
    headerName: 'Version',
    onForm: true,
    width: 100,
    editable: true,
    value: data?.version ?? null,
    initialValue: '1.0.0',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data?.description ?? null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    width: 300,
    editable: true,
    value: data?.tags ?? null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const enumsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/enums/',
    width: 300,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data?.tags,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const enumValuesMeta = ({ data } = {}) => [
  {
    field: 'value',
    headerName: 'Value',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data?.value ?? null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data?.description ?? null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data?.tags,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const modelsMeta = ({ data, modelId } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/models/',
    width: 300,
    editable: true,
    value: data?.name ?? null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'label',
    headerName: 'Label',
    onAccordionForm: true,
    editable: true,
    value: data?.label ?? null,
    initialValue: '',
    width: 150,
    validate: Yup.string().required(),
  },
  {
    width: 336,
    editable: true,
    field: 'displayValueId',
    headerName: 'Display Value',
    hideColumn: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: modelFieldsUrls.list,
    requestKey: `${data?.id}-model-fields`,
    validate: Yup.mixed(),
    initialValue: data?.displayValue
      ? {
          id: data?.displayValueId,
          label: data?.displayValue?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: `&modelId=${modelId}`,
  },
  {
    field: 'useFormFlow',
    headerName: 'Use Form Flow?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data?.useFormFlow ?? null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'helpfulHint',
    headerName: 'Helpful Hint',
    onAccordionForm: true,
    editable: true,
    value: data?.helpfulHint ?? null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data?.description ?? null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data?.tags ?? null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const formFlowsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/form-flows/',
    width: 300,
    editable: true,
    value: data?.name ?? null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    width: 180,
    field: 'type',
    headerName: 'Form Type',
    type: fieldType.SELECT,
    editable: true,
    options: computeModelFormTypes,
    initialValue: computeModelFormTypes[0]?.value,
    value: data?.type ?? null,
    validate: Yup.string(),
    onForm: true,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const formFlowStepsMeta = ({ data } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 80,
    onAccordionForm: true,
    editable: true,
    value: data?.order ?? null,
    initialValue: '',
    validate: Yup.number().required(),
    onDetailCard: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/form-flow-steps/',
    width: 300,
    editable: true,
    value: data?.name ?? null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const menusMeta = ({
  data,
  hideParentGroupColumn,
  microserviceId,
  nextOrder,
} = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 80,
    onAccordionForm: true,
    editable: true,
    value: data?.order ?? null,
    initialValue: nextOrder ? nextOrder + 1 : 1,
    validate: Yup.number().required(),
  },
  {
    width: 336,
    editable: true,
    field: 'modelId',
    headerName: 'Model',
    to: '/menus/',
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: modelsUrls.list,
    requestKey: `${data?.id}-microservice-menu-models`,
    runEffectOnInit: true,
    validate: Yup.mixed(),
    initialValue: data?.model
      ? {
          id: data?.modelId,
          label: data?.model?.name ?? 'No name',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: microserviceId ? `&microserviceId=${microserviceId}` : '',
  },
  {
    field: 'parentMenuId',
    headerName: 'Parent Menu',
    width: 280,
    editable: true,
    onAccordionForm: true,
    runEffectOnInit: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: menuDefnsUrls.list,
    requestKey: `${data?.id}-question-parent-menus`,
    validate: Yup.mixed().nullable(),
    initialValue: data?.parentMenuId
      ? {
          id: data?.parentMenuId,
          label: `${data?.parentMenu?.order}. ${
            data?.parentMenu?.model?.name ?? 'No name'
          }`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.order}. ${row?.model?.name}`,
    }),
    urlParams: microserviceId
      ? `&microserviceId=${microserviceId}&parentMenuId=null`
      : '&parentMenuId=null',
    orderingCol: 'order',
    hideColumn: hideParentGroupColumn,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const formFieldLayoutMeta = ({ data, modelId } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 80,
    onAccordionForm: true,
    editable: true,
    value: data?.order ?? null,
    initialValue: '',
    validate: Yup.number().required(),
  },
  {
    width: 336,
    editable: true,
    field: 'fieldDefnId',
    headerName: 'Field',
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: modelFieldsUrls.list,
    requestKey: `${data?.id}-form-fields`,
    validate: Yup.mixed(),
    initialValue: data?.fieldDefn
      ? {
          id: data?.fieldDefnId,
          label: data?.fieldDefn?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: `&modelId=${modelId}`,
  },
  {
    field: 'colSpan',
    headerName: 'Col Span',
    width: 136,
    onAccordionForm: true,
    editable: true,
    value: data?.colSpan ?? null,
    initialValue: '',
    validate: Yup.number().required(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const computeLogsMeta = ({ microserviceId, data } = {}) => [
  {
    field: 'createdAt',
    headerName: 'Date Initiated',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
  {
    field: 'duration',
    headerName: 'Duration (MS)',
    onAccordionForm: true,
    editable: true,
    value: data ? data.duration : null,
    initialValue: '',
    width: 136,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: fieldType.SELECT,
    editable: true,
    width: 140,
    onAccordionForm: true,
    options: computeProcessStatuses,
    initialValue: computeProcessStatuses[0].value,
    value: data ? data.status : null,
    validate: Yup.string().required(),
  },
  {
    field: 'apiGitRepoUrl',
    headerName: 'API Git Repo URL',
    onAccordionForm: true,
    editable: true,
    value: data ? data.apiGitRepoUrl : null,
    initialValue: '',
    width: 330,
    getHref: (r) => r.apiGitRepoUrl,
    validate: Yup.string(),
    linkInNewTab: true,
  },
  {
    field: 'feGitRepoUrl',
    headerName: 'FE Git Repo URL',
    onAccordionForm: true,
    editable: true,
    value: data ? data.feGitRepoUrl : null,
    initialValue: '',
    width: 330,
    getHref: (r) => r.feGitRepoUrl,
    validate: Yup.string(),
    linkInNewTab: true,
  },
  {
    field: 'devopsGitRepoUrl',
    headerName: 'DevOps Git Repo URL',
    onAccordionForm: true,
    editable: true,
    value: data ? data.devopsGitRepoUrl : null,
    initialValue: '',
    width: 330,
    getHref: (r) => r.devopsGitRepoUrl,
    validate: Yup.string(),
    linkInNewTab: true,
  },
  {
    field: 'failureReason',
    headerName: 'Failure Reason',
    onAccordionForm: true,
    editable: true,
    value: data ? data.failureReason : null,
    initialValue: '',
    width: 690,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
];

export const fieldsMeta = ({ data, microserviceId, microserviceName } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 90,
    editable: true,
    value: data?.order ?? null,
    initialValue: 1,
    validate: Yup.number().nullable(),
    onAccordionForm: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    to: '/fields/',
    onAccordionForm: true,
    width: 150,
    editable: true,
    value: data?.name ?? null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'label',
    headerName: 'Label',
    onAccordionForm: true,
    editable: true,
    value: data?.label ?? null,
    initialValue: '',
    width: 150,
    validate: Yup.string().required(),
  },
  {
    field: 'dataType',
    headerName: 'Data Type',
    type: fieldType.SELECT,
    editable: true,
    width: 100,
    onAccordionForm: true,
    options: dataTypeOptions,
    initialValue: dataTypeOptions[0].value,
    value: data?.dataType ?? null,
    validate: Yup.string().required(),
  },
  {
    field: 'isForeignKey',
    headerName: 'Foreign Key?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data?.isForeignKey ?? null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'foreignKeyTarget',
    headerName: 'Foreign Key Target',
    type: fieldType.SELECT,
    editable: true,
    width: 160,
    onAccordionForm: true,
    options: computeForeignKeyTargets,
    initialValue: computeForeignKeyTargets[0].value,
    value: data?.foreignKeyTarget ?? null,
    validate: Yup.string().default('Internal').required(),
  },
  {
    field: 'foreignKeyMicroserviceId',
    headerName: 'Foreign Key Microservice',
    width: 200,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: microservicesUrls.list,
    requestKey: `${data?.id}-microservice-field`,
    validate: Yup.mixed(),
    initialValue:
      data?.foreignKeyMicroserviceId || microserviceId
        ? {
            id: data?.foreignKeyMicroserviceId ?? microserviceId,
            label:
              data?.foreignKeyMicroserviceId?.name ?? microserviceName ?? '',
          }
        : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    onDetailCard: false,
  },
  {
    field: 'foreignKeyModelId',
    headerName: 'Foreign Key Model',
    width: 180,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: modelsUrls.list,
    requestKey: `${data?.id}-model-field`,
    validate: Yup.mixed(),
    initialValue: data?.foreignKeyModelId
      ? {
          id: data?.foreignKeyModelId,
          label: data?.foreignKeyModelId?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: `&microserviceId=${
      data?.foreignKeyMicroserviceId ?? microserviceId
    }`,
    onDetailCard: false,
  },
  {
    field: 'externalMicroserviceId',
    headerName: 'External Microservice',
    width: 200,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: accountsMicroservicesUrls.list,
    requestKey: `${data?.id}-external-microservice-field`,
    validate: Yup.mixed(),
    initialValue: data?.externalMicroserviceId
      ? {
          id: data?.externalMicroserviceId,
          label: data?.externalMicroserviceId?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    onDetailCard: false,
  },
  {
    field: 'externalModelId',
    headerName: 'External Model',
    width: 180,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: accountsModelsUrls.list,
    requestKey: `${data?.id}-external-model-field`,
    validate: Yup.mixed(),
    initialValue: data?.externalModelId
      ? {
          id: data?.externalModelId,
          label: data?.externalModelId?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: `&microservice=${data?.externalMicroserviceId}`,
    onDetailCard: false,
  },
  {
    field: 'externalDisplayValueId',
    headerName: 'External Display Value',
    width: 280,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: accountsModelFieldsUrls.list,
    requestKey: `${data?.id}-external-display-value-field`,
    validate: Yup.mixed(),
    initialValue: data?.externalDisplayValueId
      ? {
          id: data?.externalDisplayValueId,
          label: data?.externalDisplayValueId?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: `&model=${data?.externalModelId}`,
    onDetailCard: false,
  },
  {
    field: 'isClickableLink',
    headerName: 'Is Clickable Link?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data?.isClickableLink ?? null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'showInTable',
    headerName: 'Show In Table?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data?.showInTable ?? null,
    initialValue: true,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'showInDetailCard',
    headerName: 'Show In Detail Card?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 190,
    editable: true,
    value: data?.showInDetailCard ?? null,
    initialValue: true,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'isEditable',
    headerName: 'Is Editable?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data?.isEditable ?? null,
    initialValue: true,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'isMultiline',
    headerName: 'Is Multiline?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data?.isMultiline ?? null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'isOptional',
    headerName: 'Optional?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 100,
    editable: true,
    value: data?.isOptional ?? null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'isUnique',
    headerName: 'Unique?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 100,
    editable: true,
    value: data?.isUnique ?? null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'isIndex',
    headerName: 'Index?',
    type: fieldType.BOOLEAN,
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data?.isIndex ?? null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'onDelete',
    headerName: 'On Delete',
    type: fieldType.SELECT,
    editable: true,
    width: 100,
    onAccordionForm: true,
    options: computeOnDeleteOptions,
    initialValue: computeOnDeleteOptions[0].value,
    value: data?.onDelete ?? null,
    validate: Yup.string().required(),
  },
  {
    width: 200,
    editable: true,
    field: 'enumDefnId',
    headerName: 'Enum',
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: enumsUrls.list,
    requestKey: `${data?.id}-microservice-enums`,
    validate: Yup.mixed(),
    initialValue: data?.enumDefn
      ? {
          id: data?.enumDefnId,
          label: data?.enumDefn?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: `&microserviceId=${microserviceId}`,
  },
  {
    field: 'helpfulHint',
    headerName: 'Helpful Hint',
    onAccordionForm: true,
    editable: true,
    value: data?.helpfulHint ?? null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data?.description ?? null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'minLength',
    headerName: 'Min Length',
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data?.minLength ?? null,
    initialValue: '',
    validate: Yup.number().positive().nullable(),
  },
  {
    field: 'maxLength',
    headerName: 'Max Length',
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data?.maxLength ?? null,
    initialValue: '',
    validate: Yup.number().positive().nullable(),
  },
  {
    field: 'defaultValue',
    headerName: 'Default Value',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data?.defaultValue ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    width: 300,
    editable: true,
    // value: data.tags ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const blockGroupsMeta = ({ data, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 65,
    onForm: true,
    editable: true,
    value: data?.order ?? null,
    initialValue: nextOrder + 1,
    validate: Yup.number(),
    onDetailCard: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    to: '/block-groups/',
    width: 200,
    editable: true,
    value: data?.name ?? null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 300,
    editable: true,
    value: data?.description ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data?.tags ?? null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const blocksMeta = ({ data, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 65,
    onAccordionForm: true,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder + 1,
    validate: Yup.number(),
  },
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 100,
    onAccordionForm: true,
    editable: true,
    value: data && data.code,
    initialValue: 1,
    validate: Yup.number(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
