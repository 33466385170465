import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { listsUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { currencyMeta } from '@config/meta/list';
import { countryCurrencyHandleRow } from '@config/handleRows/list';
import { CountryCurrencyForm } from '@config/forms/list';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { currenciesUrls, countryCurrenciesUrls } = listsUrls;

export default function CurrencyDetails({
  performingAction,
  setPerformingAction,
  labels,
  editMode,
}) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  const getSchema = React.useCallback((data) => currencyMeta({ data }), []);

  const countryCurrencyColOptions = React.useMemo(
    () => ({
      hideCountry: false,
      hideCurrency: true,
    }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/currency', text: 'Currency' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={currenciesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.LIST.name}
          model={microservices.LIST.models.countryCurrency}
          performingAction={performingAction}
          setPerformingAction={setPerformingAction}
          columnKey='countryCurrencyLists'
          columnOptions={countryCurrencyColOptions}
          label='Country Currency'
          prefix='0ql'
          labels={labels}
          editMode={editMode}
          urls={countryCurrenciesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              CountryCurrencyForm({
                setFieldValue,
                hideCountry: true,
                hideCurrency: false,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { country, ...rest } = values;
                const { data } = await createRecord({
                  values: {
                    ...rest,
                    currency: recordId,
                    country: country?.id,
                  },
                  url: countryCurrenciesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return data.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={countryCurrencyHandleRow}
          urlParams={`&currency=${recordId}`}
        />
      </Box>
    </Box>
  );
}
