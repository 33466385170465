import React from 'react';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { pmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { sprintsMeta } from '@config/meta/pm/pmMeta';
import { sprintTasksRowsMap } from '@config/handleRows/pm';
import { SprintTasksForm } from '@config/forms/pm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { sprintTasksUrls, sprintsUrls } = pmUrls;

export default function SprintsDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => sprintsMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/sprint-metas/${recordData?.sprint_meta}`,
                text: 'Sprints',
              },
              {
                to: `/sprint-metas/${recordData?.sprint_meta}`,
                text: recordData?.start_date ?? '',
              },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={sprintsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.PM.name}
          model={microservices.PM.models.sprintTasks}
          columnKey='sprintTasksPM'
          label='Sprint Backlogs'
          prefix='0nl'
          labels={labels}
          editMode={editMode}
          urls={sprintTasksUrls}
          addNewForm={{
            getFields: ({ setFieldValue, values }) =>
              SprintTasksForm({
                setFieldValue,
                values,
                sprintMeta: recordData?.sprint_meta,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { projects, programs, task, ...rest } = values;

                const response = await createRecord({
                  values: {
                    ...rest,
                    task: task?.id,
                    sprint: recordId,
                  },
                  url: sprintTasksUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                // notify('Sprint Backlog Created', {
                //   type: 'SUCCESS',
                // });
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={sprintTasksRowsMap}
          urlParams={`&sprint=${recordId}`}
        />
      </Box>
    </Box>
  );
}
