import React from 'react';
import {
  Box,
  Rating,
  Stack,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import useRQuery from '@hooks/useRQuery';
import { formsUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { getAuthHeader } from '@config/functions/helperFunctions';
import FormHeader from '@components/Forms/FormHeader';
import UserFields from '@components/Forms/UserFields';
import MultipleChoiceField from '@components/Forms/MultipleChoiceField';
import MultipleSelectField from '@components/Forms/MultipleSelectField';
import LikertField from '@components/Forms/LikertField';
import DateTime from '@components/DateTime';
import DetailPageHeader from '@components/DetailPageHeader';
import DualButtonSelectField from '@components/Forms/DualButtonSelectField';
import GenerateReportPopup from '@components/Forms/GenerateReportPopup';

const bgStyles = { background: '#f6f9ff', width: '90%', height: '100%' };

const cardStyles = {
  width: '840px',
  mx: 'auto',
  my: '22px',
  py: '18px',
  background: 'white',
  border: '1px solid #dadce0',
  borderRadius: '8px',
};

const { responsesUrls } = formsUrls;

export default function FormResponse() {
  const { id: recordId } = useParams();
  const [questions, setQuestions] = React.useState([]);
  const user = useSelector(selectProfile);
  const [formData, setFormData] = React.useState({});
  const [copied, setCopied] = React.useState(false);
  const [openGenReportPopup, setOpenGenReportPopup] = React.useState(false);

  const { data, isLoading, isError } = useRQuery({
    key: [`${recordId}-form-response`, recordId, user.token, user?.actAs],
    url: responsesUrls.detail(recordId),
    options: { enabled: !!recordId },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const getValue = (id) => (formData[id] ? formData[id].value : '');

  React.useEffect(() => {
    if (!data && !Array.isArray(data?.form?.questions)) return;

    const questionsSorted = [...data?.form?.questions];
    questionsSorted.sort((a, b) => a.order - b.order);
    setQuestions(
      questionsSorted.filter(({ createdAt }) => createdAt < data.createdAt)
    );

    const answers = {};

    questionsSorted.forEach(({ id, type, options }) => {
      const { answers: questionAnswers } = data;
      const matchingAnswers = questionAnswers.filter(
        ({ questionId }) => questionId === id
      );

      switch (type) {
        case 'Likert': {
          const likertValue = {};

          matchingAnswers.forEach(({ questionOptionId, answer }) => {
            likertValue[questionOptionId] = answer;
          });

          answers[id] = {
            type,
            value: likertValue,
          };
          break;
        }
        case 'MultipleSelect': {
          const selectedOptions = matchingAnswers.map(
            ({ questionOptionId }) => questionOptionId
          );
          const isCorrect = matchingAnswers.find(
            ({ questionOption }) => questionOption?.isCorrect
          );

          answers[id] = {
            type,
            value: selectedOptions,
            isCorrect: !!isCorrect,
          };
          break;
        }
        case 'MultipleChoice': {
          const value =
            matchingAnswers.length > 0 ? matchingAnswers[0].answer : undefined;
          const found = options.find(({ id: optionId }) => optionId === value);

          if (!found) {
            answers[id] = {
              type,
              value,
              other: true,
            };
          } else {
            answers[id] = {
              type,
              value,
              isCorrect: !!found?.isCorrect,
            };
          }
          break;
        }
        default: {
          const value =
            matchingAnswers.length > 0 ? matchingAnswers[0].answer : undefined;

          answers[id] = {
            type,
            value,
          };
          break;
        }
      }
    });

    setFormData(answers);
  }, [data]);

  if (isError) {
    return <p>Failed to fetch the response</p>;
  }

  if (isLoading || !data) {
    return <p>loading...</p>;
  }

  if (data?.status === 'IN_PROGRESS' || data?.status === 'DRAFT') {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <DetailPageHeader
          items={[
            { to: `/forms/${data.formId}`, text: 'Responses' },
            {
              text: `${data?.firstName || ''} ${data?.lastName || ''} ${
                data?.email ? `(${data?.email})` : ''
              }`,
            },
          ]}
        >
          <Button
            type='button'
            variant='outlined'
            disabled={
              data?.status === 'IN_PROGRESS' && data?.recipientId ? false : true
            }
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.host}/recipient-form/${data?.recipientId}`
              );
              setCopied(true);
              setTimeout(() => setCopied(false), 1000);
            }}
          >
            {copied ? 'Copied' : 'Copy Link'}
          </Button>
        </DetailPageHeader>
        <Typography
          sx={{ my: '18px', fontWeight: '500', fontSize: '28px' }}
          variant='h4'
        >
          Response Currently Unavailable.
        </Typography>
        <Typography sx={{ fontSize: '18px', color: 'rgb(19, 23, 34)' }}>
          Response is in progress, not submitted. It will be available for
          review once submitted.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box>
        <DetailPageHeader
          items={[
            { to: `/forms/${data.formId}`, text: 'Responses' },
            {
              text: `${data?.firstName || ''} ${data?.lastName || ''} ${
                data?.email ? `(${data?.email})` : ''
              }`,
            },
          ]}
        >
          {data?.form?.category === 'PersonalityTest' ? (
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                setOpenGenReportPopup(true);
              }}
            >
              Generate Report
            </Button>
          ) : null}
        </DetailPageHeader>
      </Box>

      {openGenReportPopup ? (
        <GenerateReportPopup
          open={openGenReportPopup}
          setOpen={setOpenGenReportPopup}
          responseId={recordId}
        />
      ) : null}

      <Box sx={bgStyles}>
        <Box sx={cardStyles}>
          <FormHeader form={data?.form} />
          <UserFields
            disabled
            firstName={data?.firstName}
            lastName={data?.lastName}
            email={data?.email}
            form={data?.form}
          />
        </Box>

        {questions.map(
          ({
            id,
            order,
            question,
            type,
            options,
            likertLabels,
            includeOthersField,
            questionOptionGroups,
            description,
          }) => {
            return (
              <Stack
                spacing={1}
                sx={{
                  ...cardStyles,
                  px: '24px',
                }}
              >
                <Typography
                  variant='body1'
                  sx={{
                    color: '#202124',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '500',
                  }}
                >
                  {order}. {question}
                </Typography>
                {description ? (
                  <Typography
                    variant='body1'
                    sx={{
                      fontSize: '14px',
                      lineHeight: '18px',
                      top: '0',
                      zIndex: '99',
                      background: '#fff',
                      pb: '18px',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {description}
                  </Typography>
                ) : null}

                {type === 'Text' ? (
                  <TextField
                    label='Your answer'
                    size='small'
                    variant='standard'
                    fullWidth
                    multiline
                    value={getValue(id)}
                    onChange={() => null}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : type === 'MultipleChoice' ? (
                  <MultipleChoiceField
                    value={getValue(id)}
                    setValue={() => null}
                    options={options}
                    otherChecked={formData[id].other && getValue(id)}
                    otherValue={formData[id].other ? getValue(id) : ''}
                    includeOthersField={includeOthersField}
                    isCorrect={!!formData[id]?.isCorrect}
                    category={data?.form?.category}
                    isResponsePage
                  />
                ) : type === 'ButtonSelect' ? (
                  <DualButtonSelectField
                    value={getValue(id)}
                    setValue={() => null}
                    options={options}
                  />
                ) : type === 'MultipleSelect' ? (
                  <MultipleSelectField
                    options={options}
                    value={getValue(id) || []}
                    isCorrect={!!formData[id]?.isCorrect}
                    category={data?.form?.category}
                    setValue={() => null}
                  />
                ) : type === 'Date' ? (
                  <Box
                    sx={{
                      pt: '6px',
                      maxWidth: '120px',
                      pointerEvents: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <DateTime
                      label='Select date'
                      data={getValue(id) || new Date()}
                      handleSubmit={() => {}}
                    />
                  </Box>
                ) : type === 'Rating' ? (
                  <Rating
                    name='size-medium'
                    value={getValue(id)}
                    onChange={() => null}
                    readOnly
                  />
                ) : type === 'FileUpload' ? (
                  <Box>
                    <a href={getValue(id)} target='_blank' rel='noreferrer'>
                      {getValue(id)}
                    </a>
                  </Box>
                ) : type === 'Audio' ? (
                  <Box>
                    <audio controls>
                      <source src={getValue(id)} type='audio/wav' />
                      Your browser does not support the audio element.
                    </audio>
                  </Box>
                ) : type === 'Likert' ? (
                  <LikertField
                    showScores
                    labels={likertLabels}
                    options={options}
                    value={getValue(id)}
                    questionOptionGroups={questionOptionGroups}
                    setValue={() => null}
                  />
                ) : null}
              </Stack>
            );
          }
        )}
      </Box>
    </>
  );
}
