import React from 'react';
import * as Yup from 'yup';
import { wikiUrls } from '@config/routes';
import { chaptersMeta } from '@config/meta/wiki';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import BookDropdown from '@dropdown-forms/wiki/BookDropdown';
import { useQueryClient } from 'react-query';

const { chaptersUrls, booksUrls } = wikiUrls;

export default function CategoryDropdown({ children, refetch, book: initialBook }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Chapter"
        initialValues={{
          ...getAddFormInitialValues(chaptersMeta(), 'listView'),
          book:initialBook
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(chaptersMeta(), 'listView'),
        })}
        getPostUrl={() => chaptersUrls.list()}
        getValues={({book, ...values}) => ({
          ...values,
          book: book?.id
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key="name" label="Name" name="name" required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
            <Grid item xs={12}>
                <BookDropdown
                  refetch={({ id, name }) => {
                    setFieldValue('book', {
                      id,
                      label: `${name ?? ''}`,
                    });
                    queryClient.invalidateQueries(['chapter-dropdown-book']);
                  }}
                >
                  <AutocompleteFieldV2
                    name='book'
                    label='Books'
                    urlParams={`&ordering=created_at`}
                    requestKey='chapter-dropdown-book'
                    fetchUrl={booksUrls.list}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.name,
                    })}
                    required
                  />
                </BookDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
