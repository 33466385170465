import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import { MuiTelInput } from 'mui-tel-input';

export default function PhoneField({ label, defaultCountry, ...rest }) {
  const [field, meta] = useField(rest);
  const isError = Boolean(meta.error);
  const { setFieldValue } = useFormikContext();

  const handleChange = (newValue) => {
    setFieldValue(field.name, `${newValue}`);
  };

  return (
    <MuiTelInput
      name={field.name}
      value={field.value || '+44'}
      onChange={handleChange}
      size='small'
      fullWidth
      variant='outlined'
      defaultCountry='gb'
      error={isError}
      onBlur={field.onBlur}
      helperText={!isError ? null : meta.error}
    />
  );
}
