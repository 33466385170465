import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { wikiUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import { SectionTagsForm } from '@config/forms/wiki';
import { sectionTagHandleRow } from '@config/handleRows/wiki';
import { sectionsMeta } from '@config/meta/wiki';
import DetailPageHeader from '@components/DetailPageHeader';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { sectionsUrls, sectionTagsUrls } = wikiUrls;

export default function SectionsDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [sectionTagesListData, setSectionTagesListData] = React.useState({});
  const [notify] = useToast();
  const queryClient = useQueryClient();

  const getSchema = React.useCallback(
    (data) => sectionsMeta({ data, queryClient }),
    []
  );

  const sectionTagsColOptions = React.useMemo(
    () => ({
      nextOrder: sectionTagesListData?.totalCount ?? 1,
    }),
    [sectionTagesListData?.totalCount]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key='detail-view-header'
            items={[
              {
                to: `/books/${
                  recordData &&
                  recordData.details &&
                  recordData.details.chapter.book
                }`,
                text: 'Chapters',
              },
              {
                to: `/chapters/${recordData && recordData.chapter}`,
                text: `${
                  recordData &&
                  recordData.details &&
                  recordData.details.chapter.name
                }`,
              },
              {
                to: `/chapters/${recordData && recordData.chapter}`,
                text: 'Section',
              },
              { text: recordData && recordData.title },
            ]}
          ></DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={sectionsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.WIKI.name}
          model={microservices.WIKI.models.sectionTags}
          columnKey='sectionTagsWiki'
          columnOptions={sectionTagsColOptions}
          label='Section Tag'
          prefix='0fa'
          labels={labels}
          editMode={editMode}
          urls={sectionTagsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              SectionTagsForm({ setFieldValue }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { tag, ...rest } = values;

                const response = await createRecord({
                  values: {
                    ...rest,
                    section: recordId,
                    tag: tag?.id,
                  },
                  url: sectionTagsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={sectionTagHandleRow}
          urlParams={`&section=${recordId}`}
          customSortColumnName='order'
          sortColumnBy='asc'
          getListData={(data) => setSectionTagesListData(data)}
        />
      </Box>
    </Box>
  );
}
