import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import useRQuery from '@hooks/useRQuery';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { communicationUrls, accountUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { emailRowsMap } from '@config/handleRows/communication';
import { EmailForm } from '@config/forms/communication';
import { microservices } from '@config/constants';
import {
  getAuthHeader,
  uploadFilesToDrive,
} from '@config/functions/helperFunctions';
import Button from '@ui/Button';
import AddIcon from '@mui/icons-material/Add';
import ContextFieldDialog from '@components/Communication/ContextFieldDialog';
import AuthWrapper from '@components/Communication/AuthWrapper';
import { useParams } from 'react-router-dom';

const { usersUrls } = accountUrls;
const {
  notificationsUrls,
  imapAccountsUrls,
  sendEmailFromMailTemplatesUrls,
  setOrGetSignatureUrls,
} = communicationUrls;

function InboxEmails({ labels, editMode }) {
  const [notify] = useToast();
  const { id: routerId } = useParams();
  const user = useSelector(selectProfile);
  const [mailTemplateForm, setMailTemplateForm] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedMailTemplate, setSelectedMailTemplate] = useState(null);
  const [contextFieldDialogOpen, setContextFieldDialogOpen] = useState(false);
  const [contextFieldValues, setContextFieldValues] = useState({});
  const [signature, setSignature] = useState('');

  const defaultOptions = {
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
    renderRow: (r) => r.email,
  };

  const { data: users } = useRQuery({
    key: ['users', user.token, user?.actAs],
    url: usersUrls.list(`?page_size=100`),
    ...defaultOptions,
  });

  const { data } = useRQuery({
    key: ['imap-account', routerId, user.token, user?.actAs],
    url: imapAccountsUrls.detail(routerId),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs, user?.encryptionToken),
  });

  const { data: signatureData } = useRQuery({
    key: ['signature-inbox-detail', routerId, user.token, user?.actAs],
    url: setOrGetSignatureUrls.detail(routerId),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs, user?.encryptionToken),
  });

  useEffect(() => {
    if (!signatureData) return;
    setSignature(
      signatureData && signatureData.sending_an_email
        ? signatureData.sending_an_email.signature
        : ''
    );
  }, [signatureData]);

  const inboxEmailsColOptions = React.useMemo(() => {
    return { signature };
  }, [signature]);

  return (
    <>
      <Box>
        <ListView
          label="Inbox Emails"
          prefix="06n"
          labels={labels}
          editMode={editMode}
          showBulkDeleteButton={false}
          pageHeaderFormHeaderText="Send Email"
          pageHeaderFormButtonSuccessText="Send Email"
          pageHeaderbuttonText="Compose"
          customCancelButton={() => setMailTemplateForm(false)}
          getCustomHeaderContent={
            data?.connected &&
            data?.smtp_connected &&
            data?.send_emails_from_this_account
              ? (_, setAddNewForm) => (
                  <Button
                    type="button"
                    variant="outlined"
                    sx={{ ml: 2 }}
                    label={'Compose Mail Template'}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setMailTemplateForm(true);
                      setAddNewForm(true);
                    }}
                  />
                )
              : null
          }
          customInitialValues={{
            everyone_in_object_company_can_see_it: false,
          }}
          showVisibilityInPageHeader={false}
          showVisibility={true}
          showDeleteBtn={false}
          microservice={microservices.NOTIFICATION.name}
          model={microservices.NOTIFICATION.models.notifications}
          urls={notificationsUrls}
          columnKey="emailCommunication"
          columnOptions={inboxEmailsColOptions}
          hideActions
          addNewForm={
            data?.connected &&
            data?.smtp_connected &&
            data?.send_emails_from_this_account
              ? {
                  getFields: ({ values, setFieldValue, errors, touched }) =>
                    EmailForm({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      users,
                      setAttachments,
                      mailTemplateForm,
                      setSelectedMailTemplate,
                      contextFieldValues,
                      setContextFieldValues,
                      selectedMailTemplate,
                      signature,
                    }),
                  handleSubmit: async (
                    values,
                    setSubmitting,
                    resetForm,
                    setAddNewForm,
                    refetch,
                    setError
                  ) => {
                    try {
                      values.type = 'email';

                      if (
                        !mailTemplateForm &&
                        (!values.subject || !values.subject.length)
                      ) {
                        setError({ subject: 'Subject is required field!' });
                        return;
                      }

                      setSelectedMailTemplate(values?.template);

                      if (
                        values?.template &&
                        values?.template?.contextFields?.length &&
                        (!Object.keys(contextFieldValues).length ||
                          values?.template?.contextFields
                            .map(
                              (contextField) =>
                                contextField.name in contextFieldValues
                            )
                            .includes(false))
                      ) {
                        setContextFieldDialogOpen(true);
                        return;
                      }

                      const newAttachments = [];

                      if (attachments.length) {
                        const { urls } = await uploadFilesToDrive({
                          files: attachments,
                          userToken: user.token,
                          actAs: user?.actAs,
                          encryptionToken: user.encryptionToken,
                        });

                        if (urls.filter((url) => url.error).length) {
                          notify(`There was an error, please try again.`, {
                            type: 'ERROR',
                          });
                          return;
                        }

                        await Promise.allSettled(
                          urls.map(async (url) => {
                            newAttachments.push({
                              name: url.name,
                              url: url.url,
                            });
                          })
                        );
                      }
                      const { template, ...rest } = values;

                      const { data } = await createRecord({
                        values: {
                          ...rest,
                          attachments: newAttachments,
                          everyone_in_object_company_can_see_it: false,
                          imap_account: routerId,
                          context: contextFieldValues,
                          template: template?.id,
                        },
                        url: mailTemplateForm
                          ? sendEmailFromMailTemplatesUrls.list()
                          : notificationsUrls.list(),
                        token: user.token,
                        actAs: user?.actAs,
                        encryptionToken: user.encryptionToken,
                      });

                      resetForm();
                      setMailTemplateForm(false);
                      setSelectedMailTemplate(null);
                      setContextFieldDialogOpen(false);
                      setContextFieldValues({});

                      setAddNewForm(false);
                      notify(
                        `Email ${values.scheduled ? 'Scheduled' : 'Sent'}`,
                        {
                          type: 'SUCCESS',
                        }
                      );
                      refetch();
                      return data.id;
                    } catch (err) {
                      console.log(err);
                      notify(`There was an error, please try again.`, {
                        type: 'ERROR',
                      });
                    } finally {
                      setSubmitting(false);
                    }
                    return null;
                  },
                }
              : null
          }
          handleRowsMap={emailRowsMap}
          customSortColumnName={'sent_date_time'}
          urlParams={`&inbox=true&imap_account=${routerId}`}
        />

        {contextFieldDialogOpen ? (
          <ContextFieldDialog
            contextFields={selectedMailTemplate?.contextFields || []}
            open={contextFieldDialogOpen}
            setOpen={setContextFieldDialogOpen}
            contextFieldValues={contextFieldValues}
            setContextFieldValues={setContextFieldValues}
            getValues={(values) => {
              setContextFieldValues(values);
              setContextFieldDialogOpen(false);
            }}
          />
        ) : null}
      </Box>
    </>
  );
}

export default function AuthenticatedInboxEmails(props) {
  return (
    <AuthWrapper>
      <InboxEmails {...props} />
    </AuthWrapper>
  );
}
