import { Grid, Typography, MenuItem, Box } from '@mui/material';
import {
  TextField,
  SelectField,
  UploadField,
  CheckBoxField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import { lmsHomeworkTypes, lmsCourseAccessLevel } from '@config/constants';
import { useQueryClient } from 'react-query';
import { lmsUrls, wikiUrls, crmUrls, formsUrls } from '@config/routes';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';
import BookDropdown from '@dropdown-forms/wiki/BookDropdown';
import InstructorDropdown from '@dropdown-forms/lms/InstructorDropdown';
import HomeworkDropdown from '@dropdown-forms/lms/HomeworkDropdown';
import CourseDropdown from '@dropdown-forms/lms/CourseDropdown';
import StudentDropdown from '@dropdown-forms/lms/StudentDropdown';
import MentorDropdown from '@dropdown-forms/lms/MentorDropdown';
import CoachDropdown from '@dropdown-forms/lms/CoachDropdown';
import ChapterDropdown from '@dropdown-forms/wiki/ChapterDropdown';
import FormDropdown from '@dropdown-forms/form/FormDropdown';
import SectionDropdown from '@dropdown-forms/wiki/SectionDropdown';
import ModuleDropdown from '@dropdown-forms/lms/ModuleDropdown';
import LessonDropdown from '@dropdown-forms/lms/LessonDropdown';
import TeamDropdown from '@dropdown-forms/lms/TeamDropdown';
import CategoryDropdown from '@dropdown-forms/lms/CategoryDropdown';

const { booksUrls, sectionsUrls, chaptersUrls } = wikiUrls;
const { personsUrls } = crmUrls;
const { formsListUrls } = formsUrls;
const {
  homeworksUrls,
  coursesUrls,
  instructorsUrls,
  modulesUrls,
  studentsUrls,
  lessonsUrls,
  coachesUrls,
  mentorsUrls,
  teamsUrls,
  categoriesUrls,
} = lmsUrls;

const renderPerson = (row) => {
  const { id, email } = row;
  return {
    id,
    label: email,
  };
};

export const UniversityForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label='Name' name='name' required multiline />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Tags' name='tags' multiline />
    </Grid>
  </>
);

export const FacultyForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label='Name' name='name' required multiline />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Tags' name='tags' multiline />
    </Grid>
  </>
);

export const FacultyCourseForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={6}>
        <CourseDropdown
          refetch={({ id, name }) => {
            setFieldValue('courseId', { id, label: name });
            queryClient.invalidateQueries(['faculty-course']);
          }}
        >
          <AutocompleteFieldV2
            name='courseId'
            label='Course'
            requestKey='faculty-course'
            fetchUrl={coursesUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </CourseDropdown>
      </Grid>
    </>
  );
};

export const FacultyInstructorForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={6}>
        <InstructorDropdown
          refetch={({ id, alias }) => {
            setFieldValue('instructorId', { id, label: alias });
            queryClient.invalidateQueries(['faculty-instructor-person']);
          }}
        >
          <AutocompleteFieldV2
            name='instructorId'
            label='Instructor'
            requestKey='faculty-instructor'
            fetchUrl={instructorsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.alias,
            })}
            required
          />
        </InstructorDropdown>
      </Grid>
    </>
  );
};

export const HomeworkForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label='Task Title' name='name' required multiline />
    </Grid>

    <Grid item xs={2}>
      <SelectField label='Type' name='type' required>
        {lmsHomeworkTypes.map(({ label, value }) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>

    <Grid item xs={6}>
      <TextField label='URL' name='url' multiline />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Description' name='description' minRows={2} multiline />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Tags' name='tags' minRows={2} multiline />
    </Grid>
  </>
);

export const TeamForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label='Name' name='name' required multiline />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Tags' name='tags' multiline />
    </Grid>
  </>
);

export const CourseForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={4}>
        <TextField label='Course Title' name='name' multiline required />
      </Grid>

      <Grid item xs={4}>
        <HomeworkDropdown
          refetch={({ id, name }) => {
            setFieldValue('homeworkId', { id, label: name });
            queryClient.invalidateQueries(['course-homework']);
          }}
        >
          <AutocompleteFieldV2
            name='homeworkId'
            label='Course Task'
            requestKey='course-homework'
            fetchUrl={homeworksUrls.list}
          />
        </HomeworkDropdown>
      </Grid>

      <Grid item xs={2}>
        <SelectField label='Access level' name='accessLevel'>
          {lmsCourseAccessLevel.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item sm={2}>
        <CheckBoxField label='Publish' name='isPublished' />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Course Description'
          name='description'
          minRows={4}
          multiline
        />
      </Grid>

      <Grid item xs={4}>
        <Typography sx={{ mb: 1, fontWeight: '500' }}>Thumbnail *</Typography>
        <Box sx={{ maxWidth: '490px' }}>
          <UploadField name='thumbnail' accept='.png, .jpg, .jpeg' />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <BookDropdown
          refetch={({ id, name }) => {
            setFieldValue('wikiBookId', { id, label: name });
            queryClient.invalidateQueries(['course-wiki-book']);
          }}
        >
          <AutocompleteFieldV2
            name='wikiBookId'
            label='Wiki Book'
            requestKey='wiki-book'
            fetchUrl={booksUrls.list}
            required
          />
        </BookDropdown>
      </Grid>
      <Grid item xs={4}>
        <CategoryDropdown
          refetch={({ id, name }) => {
            setFieldValue('categoryId', { id, label: name });
            queryClient.invalidateQueries(['course-category']);
          }}
        >
          <AutocompleteFieldV2
            name='categoryId'
            label='Course Category'
            requestKey='course-category'
            fetchUrl={categoriesUrls.list}
          />
        </CategoryDropdown>
      </Grid>
    </>
  );
};

export const LessonForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={6}>
        <TextField label='Name' name='name' multiline required />
      </Grid>

      <Grid item xs={3}>
        <HomeworkDropdown
          refetch={({ id, name }) => {
            setFieldValue('homeworkId', { id, label: name });
            queryClient.invalidateQueries(['lesson-homework']);
          }}
        >
          <AutocompleteFieldV2
            name='homeworkId'
            label='Task'
            requestKey='lesson-homework'
            fetchUrl={homeworksUrls.list}
          />
        </HomeworkDropdown>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label='Description'
          name='description'
          minRows={4}
          multiline
        />
      </Grid>

      <Grid item xs={6}>
        <Typography sx={{ mb: 1, fontWeight: '500' }}>Video *</Typography>
        <Box sx={{ maxWidth: '490px' }}>
          <UploadField name='video' accept='.mp4' />
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Typography sx={{ mb: 1, fontWeight: '500' }}>
          Presentation *
        </Typography>
        <Box sx={{ maxWidth: '490px' }}>
          <UploadField name='presentation' accept='.pdf' />
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Typography sx={{ mb: 1, fontWeight: '500' }}>Thumbnail</Typography>
        <Box sx={{ maxWidth: '490px' }}>
          <UploadField name='thumbnail' accept='.png, .jpg, .jpeg' />
        </Box>
      </Grid>
    </>
  );
};

export const ModuleForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={6}>
        <TextField label='Module Title' name='name' required multiline />
      </Grid>

      <Grid item xs={3}>
        <HomeworkDropdown
          refetch={({ id, name }) => {
            setFieldValue('homeworkId', { id, label: name });
            queryClient.invalidateQueries(['module-homework']);
          }}
        >
          <AutocompleteFieldV2
            name='homeworkId'
            label='Task'
            requestKey='module-homework'
            fetchUrl={homeworksUrls.list}
          />
        </HomeworkDropdown>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label='Description'
          name='description'
          minRows={4}
          multiline
        />
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{ mb: 1, fontWeight: '500' }}>Thumbnail</Typography>
        <Box sx={{ maxWidth: '490px' }}>
          <UploadField name='thumbnail' accept='.png, .jpg, .jpeg' />
        </Box>
      </Grid>
    </>
  );
};

export const SkillForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label='Name' name='name' required multiline />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Tags' name='tags' multiline />
    </Grid>
  </>
);

export const StudentForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <TextField label='Alias' name='alias' multiline required />
      </Grid>

      <Grid item xs={6}>
        <PersonDropdown
          refetch={({ id, email }) => {
            setFieldValue('personId', {
              id: id,
              label: email,
            });
            queryClient.invalidateQueries(['student-person']);
          }}
        >
          <AutocompleteFieldV2
            name='personId'
            label='Person'
            requestKey='coach-person'
            fetchUrl={personsUrls.list}
            renderRow={renderPerson}
            required
          />
        </PersonDropdown>
      </Grid>
    </>
  );
};

export const InstructorForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <TextField label='Alias' name='alias' multiline required />
      </Grid>

      <Grid item xs={6}>
        <PersonDropdown
          refetch={({ id, email }) => {
            setFieldValue('personId', { id, label: email });
            queryClient.invalidateQueries(['instructor-person']);
          }}
        >
          <AutocompleteFieldV2
            name='personId'
            label='Person'
            requestKey='instructor-person'
            fetchUrl={personsUrls.list}
            renderRow={renderPerson}
            required
          />
        </PersonDropdown>
      </Grid>
    </>
  );
};

export const CoachForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={6}>
        <PersonDropdown
          refetch={({ id, email }) => {
            setFieldValue('personId', {
              id: id,
              label: email,
            });
            queryClient.invalidateQueries(['coach-person']);
          }}
        >
          <AutocompleteFieldV2
            name='personId'
            label='Person'
            requestKey='coach-person'
            fetchUrl={personsUrls.list}
            renderRow={renderPerson}
            required
          />
        </PersonDropdown>
      </Grid>
    </>
  );
};

export const MentorForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={6}>
        <PersonDropdown
          refetch={({ id, email }) => {
            setFieldValue('personId', { id, label: email });
            queryClient.invalidateQueries(['mentor-persons']);
          }}
        >
          <AutocompleteFieldV2
            name='personId'
            label='Person'
            requestKey='mentor-persons'
            fetchUrl={personsUrls.list}
            renderRow={renderPerson}
            required
          />
        </PersonDropdown>
      </Grid>
    </>
  );
};

export const CourseModuleForm = ({
  setFieldValue,
  wikiBookId,
  wikiBookName,
} = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2}>
        <TextField label='Sequence' name='sequence' required type='number' />
      </Grid>

      <Grid item xs={4}>
        <ModuleDropdown
          refetch={({ id, name }) => {
            setFieldValue('moduleId', { id, label: name });
            queryClient.invalidateQueries(['course-module']);
          }}
        >
          <AutocompleteFieldV2
            name='moduleId'
            label='Module'
            requestKey='course-module'
            fetchUrl={modulesUrls.list}
            required
          />
        </ModuleDropdown>
      </Grid>
      <Grid item xs={3}>
        <ChapterDropdown
          book={{ id: wikiBookId, label: wikiBookName }}
          refetch={({ id, name }) => {
            setFieldValue('wikiChapterId', { id, label: name });
            queryClient.invalidateQueries(['lesson-wiki-chapter']);
          }}
        >
          <AutocompleteFieldV2
            name='wikiChapterId'
            label='Wiki Chapter'
            requestKey='module-wiki-chapter'
            fetchUrl={chaptersUrls.list}
            urlParams={wikiBookId ? `&book=${wikiBookId}` : ''}
          />
        </ChapterDropdown>
      </Grid>
      <Grid item sm={2}>
        <CheckBoxField label='Required' name='isRequired' />
      </Grid>
    </>
  );
};

export const CourseTeamForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <TeamDropdown
          refetch={({ id, name }) => {
            setFieldValue('teamId', { id, label: name });
            queryClient.invalidateQueries(['course-team']);
          }}
        >
          <AutocompleteFieldV2
            name='teamId'
            label='Team'
            requestKey='course-team'
            fetchUrl={teamsUrls.list}
            required
          />
        </TeamDropdown>
      </Grid>
    </>
  );
};

export const ModuleLessonForm = ({
  setFieldValue,
  wikiChapterId,
  wikiChapterName,
} = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={2}>
        <TextField label='Sequence' name='sequence' required type='number' />
      </Grid>

      <Grid item xs={6}>
        <LessonDropdown
          refetch={({ id, name }) => {
            setFieldValue('lessonId', { id, label: name });
            queryClient.invalidateQueries(['module-lesson']);
          }}
        >
          <AutocompleteFieldV2
            name='lessonId'
            label='Lesson'
            requestKey='module-lesson'
            fetchUrl={lessonsUrls.list}
            required
          />
        </LessonDropdown>
      </Grid>
      <Grid item xs={4}>
        <SectionDropdown
          chapter={{ id: wikiChapterId, label: wikiChapterName }}
          refetch={({ id, title }) => {
            setFieldValue('wikiSectionId', { id, label: title });
            queryClient.invalidateQueries(['lesson-section']);
          }}
        >
          <AutocompleteFieldV2
            name='wikiSectionId'
            label='Wiki Section'
            requestKey='lesson-section'
            fetchUrl={sectionsUrls.list}
            urlParams={wikiChapterId ? `&chapter=${wikiChapterId}` : ''}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.title,
            })}
          />
        </SectionDropdown>
      </Grid>
      <Grid item sm={3}>
        <CheckBoxField label='Required' name='isRequired' />
      </Grid>
    </>
  );
};

export const LessonProgressForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={3}>
        <TextField
          label='Presentation Progress %'
          name='presentationProgress'
          type='number'
          required
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          label='Video Progress %'
          name='videoProgress'
          type='number'
          required
        />
      </Grid>

      <Grid item xs={4}>
        <StudentDropdown
          refetch={({ id, alias }) => {
            setFieldValue('studentId', { id, label: alias });
            queryClient.invalidateQueries(['lesson-progress-student']);
          }}
        >
          <AutocompleteFieldV2
            name='studentId'
            label='Student'
            requestKey='lesson-progress-student'
            fetchUrl={studentsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.alias,
            })}
            required
          />
        </StudentDropdown>
      </Grid>

      <Grid item xs={3}>
        <SectionDropdown
          refetch={({ id, title }) => {
            setFieldValue('wikiSectionId', { id, label: title });
            queryClient.invalidateQueries(['lesson-progress-section']);
          }}
        >
          <AutocompleteFieldV2
            name='wikiSectionId'
            label='Wiki Section'
            requestKey='lesson-progress-section'
            fetchUrl={sectionsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.title,
            })}
          />
        </SectionDropdown>
      </Grid>
    </>
  );
};

export const LessonInstructorForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={6}>
        <InstructorDropdown
          refetch={({ id, alias }) => {
            setFieldValue('instructorId', { id, label: alias });
            queryClient.invalidateQueries(['lesson-instructor']);
          }}
        >
          <AutocompleteFieldV2
            name='instructorId'
            label='Instructor'
            requestKey='lesson-instructor'
            fetchUrl={instructorsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.alias,
            })}
            required
          />
        </InstructorDropdown>
      </Grid>
    </>
  );
};

export const LessonCoachForm = ({ setFieldValue }) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={6}>
        <CoachDropdown
          refetch={({ id, details }) => {
            console.log(details);
            setFieldValue('coachId', {
              id,
              label: details?.personId?.email ?? '',
            });
            queryClient.invalidateQueries(['lesson-coach']);
          }}
        >
          <AutocompleteFieldV2
            name='coachId'
            label='Coach'
            requestKey='lesson-coach'
            fetchUrl={coachesUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.details?.personId?.email,
            })}
            required
          />
        </CoachDropdown>
      </Grid>
    </>
  );
};

export const HomeworkProgressForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <FormDropdown
          refetch={({ id, name }) => {
            console.log(id, name);
            setFieldValue('homeworkProgress', { id, label: name });
            queryClient.invalidateQueries(['homework-progress-form']);
          }}
        >
          <AutocompleteFieldV2
            name='homeworkProgress'
            label='Task Progress'
            requestKey='homework-progress-form'
            fetchUrl={formsListUrls.list}
            required
          />
        </FormDropdown>
      </Grid>
      <Grid item xs={4}>
        <StudentDropdown
          refetch={({ id, alias }) => {
            setFieldValue('studentId', { id, label: alias });
            queryClient.invalidateQueries(['homework-progress-student']);
          }}
        >
          <AutocompleteFieldV2
            name='studentId'
            label='Student'
            requestKey='homework-progress-student'
            fetchUrl={studentsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.alias,
            })}
            required
          />
        </StudentDropdown>
      </Grid>
    </>
  );
};

export const CourseMentorForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <MentorDropdown
          refetch={({ id, details }) => {
            setFieldValue('mentorId', {
              id,
              label: details?.personId?.email ?? '',
            });
            queryClient.invalidateQueries(['course-mentor']);
          }}
        >
          <AutocompleteFieldV2
            name='mentorId'
            label='Mentor'
            requestKey='course-mentor'
            fetchUrl={mentorsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.details?.personId?.email,
            })}
            required
          />
        </MentorDropdown>
      </Grid>
    </>
  );
};

// export const StudentMentorForm = ({ setFieldValue } = {}) => {
//   const queryClient = useQueryClient();

//   return (
//     <>
//       <Grid item xs={4}>
//         <MentorDropdown
//           refetch={({ id, details }) => {
//             setFieldValue('mentorId', {
//               id,
//               label: details?.personId?.email ?? '',
//             });
//             queryClient.invalidateQueries(['student-mentor']);
//           }}
//         >
//           <AutocompleteFieldV2
//             name='mentorId'
//             label='Mentor'
//             requestKey='student-mentor'
//             fetchUrl={mentorsUrls.list}
//             renderRow={(row) => ({
//               id: row?.id,
//               label: row?.details?.personId?.email,
//             })}
//             required
//           />
//         </MentorDropdown>
//       </Grid>
//     </>
//   );
// };

export const TeamMemberForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={4}>
        <StudentDropdown
          refetch={({ id, alias }) => {
            setFieldValue('studentId', { id, label: alias });
            queryClient.invalidateQueries(['team-member-student']);
          }}
        >
          <AutocompleteFieldV2
            name='studentId'
            label='Student'
            requestKey='team-member-student'
            fetchUrl={studentsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.alias,
            })}
            required
          />
        </StudentDropdown>
      </Grid>
    </>
  );
};

export const LessonReferencesForm = () => {
  return (
    <>
      <Grid item xs={12}>
        <TextField label='Notes' name='notes' multiline rows={4} required />
      </Grid>
      <Grid item xs={4}>
        <UploadField name='attachmentURL' />
      </Grid>
    </>
  );
};

export const CategoryForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label='Name' name='name' required multiline />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Description' name='description' multiline />
    </Grid>
  </>
);
