import React from 'react';
import { microservices } from '@config/constants';
import { PerformanceReviewsForm } from '@config/forms/hr';
import { createRecord } from '@config/functions/requests';
import { performanceReviewRowsMap } from '@config/handleRows/hr';
import { hrUrls } from '@config/routes';
import DetailAccordionView from '../DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { performanceReviewsUrls } = hrUrls;

export default function PerformanceReviewsAccordion({
  recordId,
  notify,
  user,
  prefix,
  labels,
  editMode,
}) {
  return (
    <DetailAccordionView
      microservice={microservices.HR.name}
      model={microservices.HR.models.performanceReview}
      columnKey="performanceReviewHR"
      label="Performance Reviews"
      prefix={prefix}
      labels={labels}
      editMode={editMode}
      urls={performanceReviewsUrls}
      addNewForm={{
        getFields: ({ setFieldValue }) =>
          PerformanceReviewsForm({
            setFieldValue,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            const { reviewed_by, ...rest } = values;

            const response = await createRecord({
              values: {
                ...rest,
                reviewed_by: reviewed_by?.id,
                employee: recordId,
              },
              url: performanceReviewsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            refetch();
            return response?.data?.id;
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      }}
      handleRowMap={performanceReviewRowsMap}
      urlParams={`&employee=${recordId}`}
    />
  );
}
