import * as React from 'react';
import { useGridApiRef, GridOverlay } from '@mui/x-data-grid-pro';
import UIEditableTable from '@ui/EditableTable';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import VisibilityPromptModel from './VisibilityPromptModelEditabletable';
import VisibilityPopupNode from '@components/Visibility/VisibilityPopupNode';

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: 'column',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

// const getColumnByFieldName = (columns, field) =>
//   columns.find((column) => column.field === field);

export default function EditableTable({
  columnKey,
  columnOptions,
  rows,
  actions,
  hideActions,
  rowActions = [],
  pageSize,
  setPageSize,
  rowsPerPageOptions = [10, 25, 50, 100],
  currentPage,
  setCurrentPage,
  totalItems,
  selectedRows,
  setSelectedRows,
  user,
  isCellEditable = () => true,
  loading = false,
  sortingMode = 'server',
  microservice = '',
  model = '',
  sortColumn,
  onSortColumnChange = () => true,
  visibility = true,
  clients = [],
  roles = [],
  users = [],
  clientsIsFetching = false,
  rolesIsFetching = false,
  usersIsFetching = false,
  setClientTerm = () => true,
  setRoleTerm = () => true,
  setUserTerm = () => true,
  refetch = () => null,
  dynamicActions = (params) => [],
  getModel,
  getDetailPanelContent,
  autoHeight,
  showDeleteBtn = true,
  includeClient = false,
  deleteBtnLabel,
  prefix,
  editMode,
  labels,
  nodeAPI,
  ...rest
}) {
  const [isLoading, setIsLoading] = React.useState(null);
  const [showVisibilityModal, setShowVisibilityModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const apiRef = useGridApiRef();

  const { row } = selectedRow ?? {};
  const {
    created_by,
    everyone_can_see_it,
    anonymous_can_see_it,
    everyone_in_object_company_can_see_it,
    only_these_roles_can_see_it,
    only_these_users_can_see_it,
  } = row ?? {};

  const ensureArray = (value) => (Array.isArray(value) ? value : []);

  // Helper function to transform roles or users array
  const transform = (arr) => arr.map(({ id, label }) => `${id}|${label}`);

  const reverseTransform = (arr) =>
    arr.map((item) => {
      const [id, label] = item.split('|');
      return { id, label };
    });

  const handleUpdate2 = (params, event, refetch) => {
    if (params.row) {
      if (
        params !== null &&
        params.value !== null &&
        params.formattedValue !== null &&
        params.value.toString() === params.formattedValue.toString()
      ) {
        return;
      }

      if (
        params !== null &&
        params.value !== null &&
        params.formattedValue !== null &&
        params.colDef.type === 'date' &&
        params.value.toDateString() ===
          new Date(params.formattedValue).toDateString()
      ) {
        return;
      }

      if (
        params !== null &&
        params.value !== null &&
        params.formattedValue !== null &&
        params.colDef.type === 'dateTime' &&
        Math.floor(params.value.getTime() / 1000) ===
          Math.floor(new Date(params.formattedValue).getTime() / 1000)
      ) {
        return;
      }

      if (params.value === null && params.value === params.formattedValue) {
        return;
      }
    }

    let newParams = params;

    // if ('id' in params && 'field' in params && 'value' in params) {
    //   newParams = Object.keys(params.value).map((key) => ({
    //     id: key,
    //     label: params.value[key],
    //   }));
    // }

    actions.handleUpdate(newParams, event, refetch);
  };

  React.useEffect(() => {
    if (isLoading === null && loading) {
      setIsLoading(true);
    } else if (isLoading === true && !loading) {
      setIsLoading(false);
    }
  }, [isLoading, loading]);

  return (
    <>
      <UIEditableTable
        autoHeight={autoHeight}
        rows={rows}
        columnKey={columnKey}
        columnOptions={columnOptions}
        hideActions={hideActions}
        actions={actions}
        rowActions={rowActions}
        visibility={visibility}
        setShowVisibilityModal={setShowVisibilityModal}
        setSelectedRow={setSelectedRow}
        user={user}
        showDeleteBtn={showDeleteBtn}
        deleteBtnLabel={deleteBtnLabel}
        dynamicActions={dynamicActions}
        apiRef={apiRef}
        handleUpdate={handleUpdate2}
        refetch={refetch}
        pageSize={pageSize}
        setPageSize={setPageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        currentPage={currentPage}
        onPageChange={(newPage) => setCurrentPage(newPage + 1)}
        rowCount={totalItems}
        pagination
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isCellEditable={isCellEditable}
        loading={isLoading}
        sortingMode={sortingMode}
        sortModel={sortColumn}
        onSortModelChange={onSortColumnChange}
        components={{
          // LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        getDetailPanelContent={getDetailPanelContent}
        {...rest}
      />

      {nodeAPI && showVisibilityModal ? (
        <VisibilityPopupNode
          user={user}
          prefix='0i3'
          editMode={editMode}
          labels={labels}
          open={showVisibilityModal}
          setOpen={setShowVisibilityModal}
          createdBy={created_by}
          initialValues={{
            everyone_can_see_it,
            anonymous_can_see_it,
            everyone_in_object_company_can_see_it,
            only_these_roles_can_see_it: reverseTransform(
              ensureArray(only_these_roles_can_see_it)
            ),
            only_these_users_can_see_it: reverseTransform(
              ensureArray(only_these_users_can_see_it)
            ),
          }}
          handleSave={async ({
            only_these_roles_can_see_it,
            only_these_users_can_see_it,
            ...values
          }) => {
            await actions.visibilityUpdate(
              {
                ...values,
                only_these_roles_can_see_it: transform(
                  only_these_roles_can_see_it
                ),
                only_these_users_can_see_it: transform(
                  only_these_users_can_see_it
                ),
                id: selectedRow.id,
              },
              selectedRow
            );
          }}
        />
      ) : null}

      {!nodeAPI && showVisibilityModal ? (
        <VisibilityPromptModel
          includeClient={includeClient}
          open={showVisibilityModal}
          row={selectedRow}
          setShowVisibilityModal={setShowVisibilityModal}
          clients={clients}
          setClientTerm={setClientTerm}
          clientsIsFetching={clientsIsFetching}
          roles={roles}
          setRoleTerm={setRoleTerm}
          rolesIsFetching={rolesIsFetching}
          users={users}
          setUserTerm={setUserTerm}
          usersIsFetching={usersIsFetching}
          actions={actions}
          user={user}
          editMode={editMode}
          labels={labels}
          prefix='0i3'
        />
      ) : null}
    </>
  );
}

EditableTable.defaultProps = {
  actions: {
    handleDelete: () => {},
    handleUpdate: () => {},
  },
};

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay key='StyledGridOverlay'>
      <svg
        key='svg'
        width='120'
        height='100'
        viewBox='0 0 184 152'
        aria-hidden
        focusable='false'
      >
        <g key='evenodd' fill='none' fillRule='evenodd'>
          <g key='transform' transform='translate(24 31.67)'>
            <ellipse
              key='ellipse'
              className='ant-empty-img-5'
              cx='67.797'
              cy='106.89'
              rx='67.797'
              ry='12.668'
            />
            <path
              key='ant-empty-img-1'
              className='ant-empty-img-1'
              d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
            />
            <path
              key='ant-empty-img-2'
              className='ant-empty-img-2'
              d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'
            />
            <path
              key='ant-empty-img-3'
              className='ant-empty-img-3'
              d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'
            />
          </g>
          <path
            key='ant-img-3'
            className='ant-empty-img-3'
            d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
          />
          <g
            key='ant-empty-img-4'
            className='ant-empty-img-4'
            transform='translate(149.65 15.383)'
          >
            <ellipse
              key='ellipse-4'
              cx='20.654'
              cy='3.167'
              rx='2.849'
              ry='2.815'
            />
            <path
              key='empty-img-4'
              d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z'
            />
          </g>
        </g>
      </svg>
      <Box key='NoRows' sx={{ mt: 1 }}>
        No Rows
      </Box>
    </StyledGridOverlay>
  );
}

// function CustomLoadingOverlay() {
//   return (
//     <GridOverlay>
//       <div style={{ position: 'absolute', top: 0, width: '100%' }}>
//         <LinearProgress />
//       </div>
//     </GridOverlay>
//   );
// }
