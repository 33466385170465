import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '@components/Loader';
import { Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function CreateShortLinksPopup({
  open, 
  setOpen, 
  status, 
  labels,
  editMode,
  prefix 
}) {
  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >

        {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="Creating short links"
          >
            {(text) => {
              return <DialogTitle
              sx={{
                display: status === 'loading' ? '' : 'none',
                pointerEvents: 'none',
              }}
            >{text}</DialogTitle>;
            }}
          </RenderUiPrefix>
        ) : (
          <DialogTitle
          sx={{
            display: status === 'loading' ? '' : 'none',
            pointerEvents: 'none',
          }}
        >
          Creating short links
        </DialogTitle>
        )}

        <IconButton
          onClick={handleClose}
          sx={{ ml: 'auto', pt: status === 'loading' ? 0 : 2, pr: 3 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Box sx={{ pb: 4 }}>
          {status === 'loading' ? (
            <Loader />
          ) : status === 'success' ? (
            <>
              <CheckCircleOutlineIcon
                sx={{
                  color: 'green',
                  textAlign: 'center',
                  display: 'block',
                  mx: 'auto',
                  mb: 2,
                  fontSize: '48px',
                }}
              />
              <Typography
                variant='h3'
                sx={{
                  fontSize: '26px',
                  textAlign: 'center',
                }}
              >
                Operation completed
              </Typography>
            </>
          ) : status === 'error' ? (
            <>
              <ErrorOutlineIcon
                sx={{
                  mb: 2,
                  mx: 'auto',
                  color: '#de0000',
                  textAlign: 'center',
                  display: 'block',
                  fontSize: '48px',
                }}
              />
              <Typography
                variant='h3'
                sx={{
                  fontSize: '26px',
                  textAlign: 'center',
                }}
              >
                Failed to create short links
              </Typography>
            </>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
