import { Box, Typography } from '@mui/material';

export default function UnexpectedError({
  headline = 'Oops!',
  body = 'An unexpected error occurred. Please try again later!',
}) {
  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          mb: 2,
          textAlign: 'center',
        }}
      >
        {headline}
      </Typography>
      <Typography variant="subtitle1">{body}</Typography>
    </Box>
  );
}
