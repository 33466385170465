import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import * as Yup from 'yup';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import TerritoryDropdown from '@dropdown-forms/crm/TerritoryDropdown';
import { DateTimePickerField, AutocompleteFieldV2 } from '@components/Inputs';
import { formatToUTCDate } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const { territoriesUrls, createBulkCompanyInTerritoriesUrls } = crmUrls;

export default function CompanyTerritoryPopup({
  open,
  setOpen,
  selectedRows,
  selectAll,
  setSelectedRows,
  searchTerm,
  labels,
  editMode,
  prefix,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue='Add company to a territory'
          >
            {(text) => {
              return (
                <DialogTitle sx={{ pointerEvents: 'none' }}>{text}</DialogTitle>
              );
            }}
          </RenderUiPrefix>
        ) : (
          <DialogTitle sx={{ pointerEvents: 'none' }}>
            Add company to a territory
          </DialogTitle>
        )}

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Formik
          initialValues={{
            territory: '',
            expiryDate: new Date(
              new Date().setFullYear(new Date().getFullYear() + 4)
            ),
          }}
          validationSchema={Yup.object({
            territory: Yup.mixed(),
            expiryDate: Yup.string().required(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              const territoryObject = {
                territory: values?.territory?.id,
                expiry_date: formatToUTCDate(values.expiryDate),
              };

              await createRecord({
                values: {
                  all: selectAll,
                  ids: selectAll ? [] : selectedRows,
                  territory: territoryObject,
                  search_query: searchTerm,
                },
                url: createBulkCompanyInTerritoriesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setOpen(false);
              setSelectedRows([]);
              queryClient.invalidateQueries();
              notify('Operation completed', {
                type: 'SUCCESS',
              });
            } catch (error) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form noValidate autoComplete='off' style={{ paddingTop: '6px' }}>
              <Grid sx={{ paddingBottom: '22px' }} container spacing={3}>
                <Grid item sm={12}>
                  <TerritoryDropdown
                    refetch={({ id, name }) => {
                      setFieldValue('territory', { id, label: name });
                      queryClient.invalidateQueries(['company-territory']);
                    }}
                  >
                    <AutocompleteFieldV2
                      name='territory'
                      label='Territory'
                      requestKey='company-territory'
                      fetchUrl={territoriesUrls.list}
                      urlParams={`&ordering=created_at`}
                      renderRow={(row) => ({
                        id: row?.id,
                        label: row?.name,
                      })}
                      required
                    />
                  </TerritoryDropdown>
                </Grid>
                <Grid item sm={12}>
                  <DateTimePickerField label='Expiry date' name='expiryDate' />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type='submit' disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
