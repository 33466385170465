import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Button,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WysiwygEditor from '@components/wiki/WysiwygEditor';

export default function ManualInstructionsPopup({
  open,
  setOpen,
  value,
  setFieldValue,
}) {
  const handleClose = () => setOpen(false);

  const handleHtml = (e) => {
    if (e.editor.getData() === '') {
      setFieldValue('manual_task_instructions', '<p></p>');
      return;
    }
    setFieldValue('manual_task_instructions', e.editor.getData());
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>
          Manual Instructions
        </DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <WysiwygEditor initData={value ?? '<p></p>'} onChange={handleHtml} />

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Save</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
