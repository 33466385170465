import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: null,
  vacancies: [],
};

export const vacancyBoardSlice = createSlice({
  name: 'vacancyBoard',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setVacancies: (state, action) => {
      state.vacancies = action.payload;
    },
  },
});

export const { setModal, setVacancies } = vacancyBoardSlice.actions;

export const selectVacanciesBoard = (state) => state.vacancyBoard;

export default vacancyBoardSlice.reducer;
