import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import ProductTypes from '@pages/Inventory/ProductTypes';
import ProductTypeDetail from '@pages/Inventory/ProductTypes/detail';
import ManufacturerContacts from '@pages/Inventory/ManufacturerContacts';
import Manufacturers from '@pages/Inventory/Manufacturers';
import ManufacturerDetail from '@pages/Inventory/Manufacturers/detail';
import Products from '@pages/Inventory/Products';
import ProductDetail from '@pages/Inventory/Products/detail';
import ProductVariantDetail from '@pages/Inventory/ProductVariants/detail';
import InventoryDetail from '@pages/Inventory/Inventorys/detail';
import SupplierContacts from '@pages/Inventory/SupplierContacts';
import Suppliers from '@pages/Inventory/Suppliers';
import SupplierDetail from '@pages/Inventory/Suppliers/detail';
import Warehouses from '@pages/Inventory/Warehouses';
import WarehouseDetail from '@pages/Inventory/Warehouses/detail';
import DeliveryDetail from '@pages/Inventory/Deliveries/detail';
import ProductAdditionalInfos from '@pages/Inventory/ProductAdditionalInfos';
import VariantKeys from '@pages/Inventory/VariantKeys';


export default function () {
  return (
    <>
      <Route
        path='/product-types'
        element={<WithLayout comp={ProductTypes} />}
      />
      <Route
        path='/product-types/:id'
        element={<WithLayout comp={ProductTypeDetail} />}
      />
      <Route
        path='/manufacturer-contacts'
        element={<WithLayout comp={ManufacturerContacts} />}
      />
      <Route
        path='/manufacturers'
        element={<WithLayout comp={Manufacturers} />}
      />
      <Route
        path='/manufacturers/:id'
        element={<WithLayout comp={ManufacturerDetail} />}
      />
      <Route path='/products' element={<WithLayout comp={Products} />} />
      <Route
        path='/products/:id'
        element={<WithLayout comp={ProductDetail} />}
      />
      <Route
        path='/product-variants/:id'
        element={<WithLayout comp={ProductVariantDetail} />}
      />
      <Route
        path='/inventorys/:id'
        element={<WithLayout comp={InventoryDetail} />}
      />
      <Route
        path='/supplier-contacts'
        element={<WithLayout comp={SupplierContacts} />}
      />

      <Route path='/suppliers' element={<WithLayout comp={Suppliers} />} />
      <Route
        path='/suppliers/:id'
        element={<WithLayout comp={SupplierDetail} />}
      />
      <Route path='/warehouses' element={<WithLayout comp={Warehouses} />} />
      <Route
        path='/warehouses/:id'
        element={<WithLayout comp={WarehouseDetail} />}
      />
      <Route
        path='/deliveries/:id'
        element={<WithLayout comp={DeliveryDetail} />}
      />
      <Route
        path='/product-additional-infos'
        element={<WithLayout comp={ProductAdditionalInfos} />}
      />
      <Route
        path='/variant-keys'
        element={<WithLayout comp={VariantKeys} />}
      />
    </>
  );
}
