import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { marketingUrls } from '@config/routes';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import SocialMediaAccountVisibility from '@ui/SocialMedia/SocialMediaAccountVisibility';

const { twitterOAuthUrls } = marketingUrls;

export default function ConnectTwitter() {
  const [notify] = useToast();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const oauthToken = queryParams.get('oauth_token');
  const oauthVerifier = queryParams.get('oauth_verifier');
  const denied = queryParams.get('denied');
  const user = useSelector(selectProfile);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const saveUserToken = async ({ token, tokenVerifier, accountPrivate }) => {
    try {
      setSubmitting(true);
      await createRecord({
        values: {
          private: accountPrivate,
          oauth_token: token,
          oauth_verifier: tokenVerifier,
          ConsumerKey: process.env.REACT_APP_TWITTER_CONSUMER_KEY,
          ConsumerSecret: process.env.REACT_APP_TWITTER_CONSUMER_SECRET,
        },
        url: twitterOAuthUrls.list(),
        token: user?.token,
        actAs: user?.actAs,
      });

      navigate('/social-media-campaigns');
    } catch (err) {
      console.log('obi-err', err.message);
      notify(
        'Looks like we hit a tiny bump while updating your account. Please try again',
        {
          type: 'ERROR',
        }
      );
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (!oauthToken && !oauthVerifier && !denied) {
      navigate('/connect-platform');
    }

    if (denied) {
      navigate('/connect-platform');
      return;
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
      }}
    >
      <SocialMediaAccountVisibility
        isSubmitting={isSubmitting}
        handleClick={({ accountPrivate }) => {
          saveUserToken({
            token: oauthToken,
            tokenVerifier: oauthVerifier,
            accountPrivate,
          });
        }}
      />
    </Box>
  );
}
