import * as React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { bpaUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { assetMeta } from '@config/meta/bpa';
import DetailAccordionView from '@components/DetailAccordionView';
import { modelAssetStageRowsMap } from '@config/handleRows/bpa';
import { microservices } from '@config/constants';
import { ModelAssetStageForm } from '@config/forms/bpa';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { createRecord } from '@config/functions/requests';
import { useParams, useSearchParams } from 'react-router-dom';

const { modelAssetsUrls, modelAssetStagesUrls } = bpaUrls;

export default function AssetDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  /*eslint no-unused-vars: ["error", { "varsIgnorePattern": "setSearchParams" }]*/
  let [searchParams, setSearchParams] = useSearchParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);

  const workflowParams = {
    id: searchParams.get('workflowId'),
    name: searchParams.get('workflowName'),
  };

  const stagesColOptions = React.useMemo(() => {
    return {
      workflow: workflowParams,
    };
  }, [workflowParams]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/workflows/${workflowParams.id}`,
                text: `${workflowParams.name}`,
              },
              {
                text:
                  recordData?.details?.asset[
                    recordData?.details?.asset_model?.microservice_label
                  ] ?? null,
              },
            ]}
          ></DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={modelAssetsUrls}
        recordId={recordId}
        getSchema={(data) => assetMeta({ data })}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          microservice={microservices.BPA.name}
          model={microservices.BPA.models.modelAssetStage}
          columnKey="modelAssetStage"
          columnOptions={stagesColOptions}
          label="Stages"
          prefix="0mm"
          urls={modelAssetStagesUrls}
          addNewForm={{
            getFields: ({ values }) => ModelAssetStageForm({ values }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: {
                    ...values,
                    stage: values?.stage?.id,
                    asset: recordId,
                  },
                  url: modelAssetStagesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={modelAssetStageRowsMap}
          urlParams={`&asset=${recordId}`}
          customSortColumnName="order"
          sortColumnBy="asc"
        />
      </Box>
    </Box>
  );
}
