import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { accountUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { MicroservicesForms } from '@config/forms/accounts';
import { microserviceHandleRow } from '@config/handleRows/accounts';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { microservicesUrls } = accountUrls;

export default function Microservices({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  const isSuperUserPermission =
    user?.details?.roles_names.includes('Superusers') ||
    user?.details?.roles_names.includes('Company Administrator') ||
    user?.details?.is_superuser;

  const microserviceColOptions = React.useMemo(
    () => ({
      isSuperUserPermission: isSuperUserPermission,
    }),
    [isSuperUserPermission]
  );

  return (
    <Box>
      <ListView
        label='Microservices'
        prefix='0p9'
        labels={labels}
        showDeleteBtn={isSuperUserPermission}
        showBulkDeleteButton={isSuperUserPermission}
        editMode={editMode}
        microservice={microservices.ACCOUNTS.name}
        model={microservices.ACCOUNTS.models.microservices}
        urls={microservicesUrls}
        columnKey='microserviceAccounts'
        columnOptions={microserviceColOptions}
        addNewForm={
          isSuperUserPermission
            ? {
                getFields: () => MicroservicesForms(),
                handleSubmit: async (
                  values,
                  setSubmitting,
                  resetForm,
                  setAddNewForm,
                  refetch,
                  setError
                ) => {
                  try {
                    const response = await createRecord({
                      values,
                      url: microservicesUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                    });
                    resetForm();
                    setAddNewForm(false);
                    // notify(`Microservice created`, {
                    //   type: 'SUCCESS',
                    // });
                    refetch();
                    return response?.data?.id;
                  } catch (err) {
                    console.log(err);
                    handleCreateFormErrors({ err, setError, notify, values });
                  } finally {
                    setSubmitting(false);
                  }
                  return null;
                },
              }
            : null
        }
        handleRowsMap={microserviceHandleRow}
      />
    </Box>
  );
}
