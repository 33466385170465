import React from 'react';
import { Box } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { selectRemindersBoard, setReminders } from '@redux/reminderSlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls, calendarUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { updateRecord } from '@config/functions/requests';
import {
  handleDragEnd,
  getAuthHeader,
} from '@config/functions/helperFunctions';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';
import TextField from '@mui/material/TextField';
import ErrorScreen from '@components/ErrorScreen';

const { reminderStagesUrls, pipelinesUrls } = crmUrls;
const { actionRemindersUrls } = calendarUrls;

export default function VacanciesBoard({ labels, editMode }) {
  const { reminders: remindersState } = useSelector(selectRemindersBoard);
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [notify, update] = useToast();
  const [selectedPipeline, setSelectedPipeline] = React.useState(null);
  const [width, setWidth] = React.useState(290);

  const { data, isLoading, isError } = useRQuery({
    key: ['reminders-board', selectedPipeline, user.token, user?.actAs],
    url: reminderStagesUrls.list(
      `?pipeline=${
        selectedPipeline && selectedPipeline.id
      }&order=order&page_size=200`
    ),
    options: { enabled: !!(user.token && selectedPipeline) },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleSaveCardDrag = async ({ newList, removedItem, destId }) => {
    const tId = notify('Saving....', { loading: true });
    dispatch(setReminders(newList));
    try {
      await updateRecord({
        url: actionRemindersUrls.detail(removedItem.id),
        values: { status: destId },
        token: user.token,
        actAs: user?.actAs,
      });
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    if (data) {
      dispatch(setReminders(data));
    }
  }, [data, dispatch]);

  React.useEffect(() => {
    const w = localStorage.getItem('ps-opp-width');
    if (w) setWidth(+w);
  }, []);

  if (isError) {
    return (
      <ErrorScreen text='Failed to fetch the resource from server' img='' />
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title='Reminders'
        prefix='0y6'
        labels={labels}
        editMode={editMode}
        content2={
          <Box>
            <TextField
              sx={{ width: '80px', mr: 2, ml: 2 }}
              size='small'
              label='Width'
              variant='outlined'
              type='number'
              value={width}
              onChange={(e) => {
                setWidth(e.target.value);
                localStorage.setItem('ps-bugs-width', e.target.value);
              }}
              autoComplete='off'
            />
          </Box>
        }
        filterOptions={[
          {
            label: 'Select Pipeline',
            name: 'selectedPipeline',
            requestKey: 'reminders-board-pipelines',
            fetchUrl: pipelinesUrls.list,
            handleChange: (newValue) => {
              setSelectedPipeline(newValue);
            },
          },
        ]}
      />

      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            <DragDropContext
              onDragEnd={(res) =>
                handleDragEnd(res, remindersState, handleSaveCardDrag)
              }
            >
              <KanbanBoardList
                detailLink='reminders'
                data={remindersState}
                width={+width}
                labels={labels}
                editMode={editMode}
              />
            </DragDropContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}
