import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function StartAutomationPrompt({
  recordData,
  open,
  disabledStartAutomationButton,
  onCancelClick,
  onStartAutomationClick,
  labels,
  editMode,
  prefix,
  prefix2 
}) {
  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      fullWidth
      maxWidth="sm"
      aria-labelledby="start-automation"
    >
      {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="Start Automation?"
          >
            {(text) => {
              return <DialogTitle sx={{ pointerEvents: 'none' }}>{text}</DialogTitle>;
            }}
          </RenderUiPrefix>
        ) : (
          <DialogTitle id="delete-dialog-title">Start Automation?</DialogTitle>

        )}
      <DialogContent>
        {prefix2 ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix2}
            defaultValue="Start Automation?"
          >
            {(text) => {
              return <DialogContentText
              id="delete-dialog-description"
              sx={{
                mb: '10px',
              }}
            >{text}</DialogContentText>;
            }}
          </RenderUiPrefix>
        ) : (
          <DialogContentText
          id="delete-dialog-description"
          sx={{
            mb: '10px',
          }}
        >
          Are you sure you want to start the automation? after you have started
          the automation you can't <strong>undo</strong> this action.
        </DialogContentText>

        )}
        
        <Formik
          initialValues={{
            automata_workflow_name: recordData?.name,
          }}
          validationSchema={Yup.object({
            automata_workflow_name: Yup.string().required(),
          })}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setFieldError }
          ) => {
            const setError = (error) => {
              Object.entries(error).map((err) => setFieldError(err[0], err[1]));
            };

            await onStartAutomationClick(
              values,
              setSubmitting,
              resetForm,
              setError,
              setFieldError
            );
          }}
        >
          {() => (
            <Form noValidate autoComplete="off" style={{ paddingTop: '8px' }}>
              <Grid item xs={2} sm={4} md={2}>
                <TextField
                  label="Automata Workflow Name"
                  name="automata_workflow_name"
                  required
                />
              </Grid>
              <DialogActions>
                <Button onClick={onCancelClick}>Cancel</Button>
                <Button
                  color="error"
                  type="submit"
                  disabled={disabledStartAutomationButton}
                >
                  Start Automation
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
