import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { communicationUrls } from '@config/routes';
import Button from '@ui/Button';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import Loader from '@components/Loader';
import ErrorScreen from '@components/ErrorScreen';
import DetailPageHeader from '@components/DetailPageHeader';
import { updateRecord } from '@config/functions/requests';
import { microservices } from '@config/constants';
import AuthWrapper from '@components/Communication/AuthWrapper';
import useRQuery from '@hooks/useRQuery';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const { setOrGetSignatureUrls } = communicationUrls;

function SignatureEditor({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const { id: imapAccountId } = useParams();
  const [data, setData] = useState(null);
  const [sendingAnEmail, setSendingAnEmail] = useState('');
  const [replyingAnEmail, setReplyingAnEmail] = useState('');
  const [updating, setUpdating] = useState(false);
  const [notify] = useToast();

  const {
    data: signatureData,
    isFetching,
    refetch,
    isError,
  } = useRQuery({
    key: ['signature-detail', imapAccountId, user],
    url: setOrGetSignatureUrls.detail(imapAccountId),
    config: getAuthHeader(user.token, user?.actAs, user.encryptionToken),
    options: { enabled: !!user.token },
  });

  useEffect(() => {
    if (!signatureData) return;

    setSendingAnEmail(signatureData.sending_an_email.signature || '');
    setReplyingAnEmail(signatureData.replying_an_email.signature || '');
    setData(signatureData);
  }, [signatureData]);

  const handleSubmit = async () => {
    try {
      await updateRecord({
        values: {
          sending_an_email: sendingAnEmail,
          replying_an_email: replyingAnEmail,
        },
        url: setOrGetSignatureUrls.detail(imapAccountId),
        token: user.token,
        actAs: user?.actAs,
        encryptionToken: user.encryptionToken,
      });
      notify(`Signature Is Updated!`, {
        type: 'SUCCESS',
      });
      refetch();
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data.name) {
        notify(`${err.response.data.name}`, {
          type: 'ERROR',
        });
      } else if (err.response && err.response.data.description) {
        notify(`${err.response.data.description}`, {
          type: 'ERROR',
        });
      } else if (err.response && err.response.data.subject) {
        notify(`${err.response.data.subject}`, {
          type: 'ERROR',
        });
      } else if (err.response && err.response.data.html) {
        notify(`${err.response.data.html}`, {
          type: 'ERROR',
        });
      } else {
        notify(`There was an error, please try again.`, {
          type: 'ERROR',
        });
      }
    }
  };

  return (
    <>
      <Box key="detail-view-container">
        <Box>
          <DetailPageHeader
            key="detail-view-header"
            items={[
              { to: `/imap-accounts`, text: 'Imap Account' },
              { text: 'Signature' },
            ]}
          />
        </Box>

        {isFetching || !data ? <Loader /> : null}

        {isError ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ErrorScreen />
          </Box>
        ) : null}

        {!isFetching && data ? (
          <Box key="detail-view-data-container">
            <Box>
              <RenderUiPrefix
                editMode={editMode}
                labels={labels}
                code="pc"
                defaultValue="Email Signature"
              >
                {(text) => {
                  return (
                    <Typography
                      component="div"
                      key="Html"
                      variant="h5"
                      sx={{ fontWeight: '500' }}
                    >
                      {text}
                    </Typography>
                  );
                }}
              </RenderUiPrefix>
              <Box key="WysiwygEditor-container" sx={{ mt: 2 }}>
                <WysiwygEditor
                  initData={sendingAnEmail}
                  msName={microservices.NOTIFICATION.name}
                  onChange={(evt) => setSendingAnEmail(evt.editor.getData())}
                />
              </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
              <RenderUiPrefix
                editMode={editMode}
                labels={labels}
                code="4q"
                defaultValue="Reply Signature"
              >
                {(text) => {
                  return (
                    <Typography
                      component="div"
                      key="Html"
                      variant="h5"
                      sx={{ fontWeight: '500' }}
                    >
                      {text}
                    </Typography>
                  );
                }}
              </RenderUiPrefix>
              <Box key="WysiwygEditor-container" sx={{ mt: 2 }}>
                <WysiwygEditor
                  initData={replyingAnEmail}
                  msName={microservices.NOTIFICATION.name}
                  onChange={(evt) => setReplyingAnEmail(evt.editor.getData())}
                />
              </Box>
            </Box>

            <Box key="button-container" sx={{ mt: 2, textAlign: 'center' }}>
              <Button
                variant="contained"
                type="button"
                disabled={updating}
                onClick={async () => {
                  setUpdating(true);
                  await handleSubmit();
                  setUpdating(false);
                }}
                label="Save Signature"
              />
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
}

export default function AuthenticatedImapAccounts(props) {
  return (
    <AuthWrapper>
      <SignatureEditor {...props} />
    </AuthWrapper>
  );
}
