import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { crmUrls } from '@config/routes';
import { opportunityHistoryMeta } from '@config/meta/crm';

const { opportunityHistoryUrls } = crmUrls;

export default function OpportunitiesHistoryDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => opportunityHistoryMeta({ data }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/opportunities/${recordData.opportunity}`, text: 'Opportunities History' },
              { text: recordData?.notes },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={opportunityHistoryUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
    </Box>
  );
}
