import { chatHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  rooms: 'rooms',
  roomsByPerson: 'rooms/by-person',
  roomsBulk: 'rooms/with-members/bulk',
  roomMembers: 'room-members',
  roomMembersBulk: 'room-members/bulk',
};

const roomsUrls = getModelUrls(chatHost, models.rooms, false, 'v1');

const roomsBulkUrls = getModelUrls(chatHost, models.roomsBulk, false, 'v1');

const roomMembersUrls = getModelUrls(chatHost, models.roomMembers, false, 'v1');

const roomMembersBulkUrls = getModelUrls(
  chatHost,
  models.roomMembersBulk,
  false,
  'v1'
);

const roomsByPersonUrls = getModelUrls(
  chatHost,
  models.roomsByPerson,
  false,
  'v1'
);

const urls = {
  roomsUrls,
  roomsBulkUrls,
  roomsByPersonUrls,
  roomMembersBulkUrls,
  roomMembersUrls,
};

export default urls;
