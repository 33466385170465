// handleRows

export const productTypeRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  parent_product_type:
    c.parent_product_type && c.details && c.details.parent_product_type
      ? { id: c.parent_product_type, label: c.details.parent_product_type.name }
      : c.parent_product_type,
  created_at: new Date(c.created_at),
});

export const manufacturerContactRowMap = (c) => ({
  id: c.id,
  title: c.title,
  person:
    c.person && c.details && c.details.person
      ? { id: c.person, label: c.details.person.email }
      : c.person,
  status: c.status,
  created_at: new Date(c.created_at),
});

export const manufacturerRowMap = (c) => ({
  id: c.id,
  manufacturer_company:
    c.manufacturer_company && c.details && c.details.manufacturer_company
      ? {
          id: c.manufacturer_company,
          label: c.details.manufacturer_company.name,
        }
      : c.manufacturer_company,
  phone:
    c.manufacturer_company && c.details && c.details.manufacturer_company
      ? c.details.manufacturer_company.phone
      : c.manufacturer_company,
  website:
    c.manufacturer_company && c.details && c.details.manufacturer_company
      ? c.details.manufacturer_company.website
      : c.manufacturer_company,
  created_at: new Date(c.created_at),
});

export const productRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  slug: c.slug,
  product_type:
    c.product_type && c.details && c.details.product_type
      ? { id: c.product_type, label: c.details.product_type.name }
      : c.product_type,
  manufacturer:
    c.manufacturer &&
    c.details &&
    c.details.manufacturer &&
    c.details.manufacturer.details &&
    c.details.manufacturer.details.manufacturer_company
      ? {
          id: c.manufacturer,
          label: c.details.manufacturer.details.manufacturer_company.name,
        }
      : c.manufacturer,
  min_price: c.min_price,
  max_price: c.max_price,
  manufacturer_code: c.manufacturer_code,
  model: c.model,
  discontinued: c.discontinued,
  in_eshop: c.in_eshop,
  created_at: new Date(c.created_at),
});

export const productVariantRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  slug: c.slug,
  product:
    c.product && c.details && c.details.product
      ? { id: c.product, label: c.details.product.name }
      : c.product,
  manufacturer_code: c.manufacturer_code,
  our_sku_code: c.our_sku_code,
  vat_percentage: c.vat_percentage,
  price: c.price,
  created_at: new Date(c.created_at),
});

export const variantKeyRowMap = (c) => ({
  id: c.id,
  key: c.key,
  show_in_filters: c.show_in_filters,
  description: c.description,
  created_at: new Date(c.created_at),
});

export const variantValueRowMap = (c) => ({
  id: c.id,
  value: c.value,
  product_variant:
    c.product_variant && c.details && c.details.product_variant
      ? { id: c.product_variant, label: c.details.product_variant.name }
      : c.product_variant,
  key:
    c.key && c.details && c.details.key
      ? {
          id: c.key,
          label: c.details.key.key,
        }
      : c.key,
  created_at: new Date(c.created_at),
});

export const supplierContactRowMap = (c) => ({
  id: c.id,
  title: c.title,
  person:
    c.person && c.details && c.details.person
      ? { id: c.person, label: c.details.person.first_name }
      : c.person,
  status: c.status,
  created_at: new Date(c.created_at),
});

export const supplierRowMap = (c) => ({
  id: c.id,
  supplier_company:
    c.supplier_company && c.details && c.details.supplier_company
      ? { id: c.supplier_company, label: c.details.supplier_company.name }
      : c.supplier_company,
  phone:
    c.supplier_company && c.details && c.details.supplier_company
      ? c.details.supplier_company.phone
      : c.supplier_company,
  website:
    c.supplier_company && c.details && c.details.supplier_company
      ? c.details.supplier_company.website
      : c.supplier_company,
  created_at: new Date(c.created_at),
});

export const warehouseRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  address1: c.address1,
  city:
    c.city && c.details && c.details.city
      ? { id: c.city, label: c.details.city.name }
      : c.city,
  postcode:
    c.postcode && c.details && c.details.postcode
      ? { id: c.postcode, label: c.details.postcode.code }
      : c.postcode,
  keyholder_name: c.keyholder_name,
  keyholder_addr1: c.keyholder_addr1,
  keyholder_city:
    c.keyholder_city && c.details && c.details.keyholder_city
      ? { id: c.keyholder_city, label: c.details.keyholder_city.name }
      : c.keyholder_city,
  keyholder_postcode:
    c.keyholder_postcode && c.details && c.details.keyholder_postcode
      ? { id: c.keyholder_postcode, label: c.details.keyholder_postcode.code }
      : c.keyholder_postcode,
  keyholder_mobile: c.keyholder_mobile,
  keyholder_email: c.keyholder_email,
  keycode: c.keycode,
  created_at: new Date(c.created_at),
});

export const warehouseInventoryRowMap = (c) => ({
  id: c.id,
  name: c.name,
  product_variant:
    c.product_variant && c.details && c.details.product_variant
      ? { id: c.product_variant, label: c.details.product_variant.name }
      : c.product_variant,
  warehouse:
    c.warehouse && c.details && c.details.warehouse
      ? { id: c.warehouse, label: c.details.warehouse.name }
      : c.warehouse,
  location_in_warehouse: c.location_in_warehouse,
  quantity: c.quantity,
  warehouse_address1:
    c.warehouse && c.details && c.details.warehouse
      ? { id: c.warehouse, label: c.details.warehouse.address1 }
      : c.warehouse,
  warehouse_city:
    c.warehouse &&
    c.details &&
    c.details.warehouse &&
    c.details.warehouse.city &&
    c.details.warehouse.details &&
    c.details.warehouse.details.city
      ? // ? { id: c.warehouse, label: c.details.warehouse.details.city.name }
        c.details.warehouse.details.city.name
      : c.warehouse,
  warehouse_keyholder_name:
    c.warehouse && c.details && c.details.warehouse
      ? // ? { id: c.warehouse, label: c.details.warehouse.keyholder_name }
        c.details.warehouse.keyholder_name
      : c.warehouse,
  warehouse_keyholder_addr1:
    c.warehouse && c.details && c.details.warehouse
      ? // ? { id: c.warehouse, label: c.details.warehouse.keyholder_addr1 }
        c.details.warehouse.keyholder_addr1
      : c.warehouse,
  warehouse_keyholder_city:
    c.warehouse &&
    c.details &&
    c.details.warehouse &&
    c.details.warehouse.keyholder_city &&
    c.details.warehouse.details &&
    c.details.warehouse.details.keyholder_city
      ? // ? { id: c.warehouse, label: c.details.warehouse.details.keyholder_city.name }
        c.details.warehouse.details.keyholder_city.name
      : c.warehouse,
  warehouse_keyholder_mobile:
    c.warehouse && c.details && c.details.warehouse
      ? // ? { id: c.warehouse, label: c.details.warehouse.keyholder_mobile }
        c.details.warehouse.keyholder_mobile
      : c.warehouse,
  warehouse_keyholder_email:
    c.warehouse && c.details && c.details.warehouse
      ? // ? { id: c.warehouse, label: c.details.warehouse.keyholder_email }
        c.details.warehouse.keyholder_email
      : c.warehouse,
  created_at: new Date(c.created_at),
});

export const productSupplierRowMap = (c) => ({
  id: c.id,
  name: c.name,
  product_variant:
    c.product_variant && c.details && c.details.product_variant
      ? { id: c.product_variant, label: c.details.product_variant.name }
      : c.product_variant,
  supplier:
    c.supplier &&
    c.details &&
    c.details.supplier &&
    c.details.supplier.supplier_company &&
    c.details.supplier.details &&
    c.details.supplier.details.supplier_company
      ? {
          id: c.supplier,
          label: c.details.supplier.details.supplier_company.name,
        }
      : c.supplier,
  currency:
    c.currency && c.details && c.details.currency
      ? { id: c.currency, label: c.details.currency.name }
      : c.currency,
  cost: c.cost,
  quantity: c.quantity,
  supplier_code: c.supplier_code,
  created_at: new Date(c.created_at),
});

export const deliveryRowMap = (c) => ({
  id: c.id,
  warehouse:
    c.warehouse && c.details && c.details.warehouse
      ? { id: c.warehouse, label: c.details.warehouse.name }
      : c.warehouse,
  delivered_by: c.delivered_by,
  received_by: c.received_by,
  delivery_note_ref: c.delivery_note_ref,
  quantity: c.quantity,
  created_at: new Date(c.created_at),
});

export const deliveryItemRowMap = (c) => ({
  id: c.id,
  delivery:
    c.delivery && c.details && c.details.delivery
      ? { id: c.delivery, label: c.details.delivery.delivered_by }
      : c.delivery,
  product_variant:
    c.product_variant && c.details && c.details.product_variant
      ? { id: c.product_variant, label: c.details.product_variant.name }
      : c.product_variant,
  in_order: c.in_order,
  notes: c.notes,
  quantity: c.quantity,
  created_at: new Date(c.created_at),
});

export const deliveryArtifactRowMap = (c) => ({
  id: c.id,
  delivery:
    c.delivery && c.details && c.details.delivery
      ? { id: c.delivery, label: c.details.delivery.delivered_by }
      : c.delivery,
  artifact: c.artifact,
  created_at: new Date(c.created_at),
});

export const productArtifactRowMap = (c) => ({
  id: c.id,
  artifact: c.artifact,
  product:
    c.product && c.details && c.details.product
      ? { id: c.product, label: c.details.product.name }
      : c.product,
  created_at: new Date(c.created_at),
});

export const productAdditionalInfoRowMap = (c) => ({
  id: c.id,
  key: c.key,
  value: c.value,
  created_at: new Date(c.created_at),
});

export const inventoryRowMap = (c) => ({
  id: c.id,
  status: c.status,
  location_in_warehouse: c.location_in_warehouse,
  stock_level: c.stock_level,
  reorder_level: c.reorder_level,
  max_stock_level: c.max_stock_level,
  notes: c.notes,
  product:
    c.product && c.details && c.details.product
      ? { id: c.product, label: c.details.product.name }
      : c.product,
  warehouse:
    c.warehouse && c.details && c.details.warehouse
      ? { id: c.warehouse, label: c.details.warehouse.name }
      : c.warehouse,
  created_at: new Date(c.created_at),
});

export const inventoryTransactionRowMap = (c) => ({
  id: c.id,
  transaction_type: c.transaction_type,
  quantity: c.quantity,
  transaction_date: c.transaction_date,
  po_number: c.po_number,
  notes: c.notes,
  inventory:
    c.inventory && c.details && c.details.inventory
      ? { id: c.inventory, label: c.details.inventory.location_in_warehouse }
      : c.inventory,
  created_at: new Date(c.created_at),
});

export const mediaRowMap = (c) => ({
  id: c.id,
  url: c.url,
  type: c.type,
  product:
    c.product && c.details && c.details.product
      ? { id: c.product, label: c.details.product.name }
      : c.product,
  product_variant:
    c.product_variant && c.details && c.details.product_variant
      ? { id: c.product_variant, label: c.details.product_variant.name }
      : c.product_variant,
  created_at: new Date(c.created_at),
});

// export const inventoryHandleRow = {
//   productTypeRowMap,
//   manufacturerContactRowMap,
//   manufacturerRowMap,
//   productRowMap,
//   productVariantRowMap,
//   variantKeyRowMap,
//   variantValueRowMap,
//   supplierContactRowMap,
//   supplierRowMap,
//   warehouseRowMap,
//   warehouseInventoryRowMap,
//   productSupplierRowMap,
//   deliveryRowMap,
//   deliveryItemRowMap,
//   deliveryArtifactRowMap,
//   productArtifactRowMap,
//   productAdditionalInfoRowMap,
//   inventoryRowMap,
//   inventoryTransactionRowMap,
//   mediaRowMap,
// };

// export default inventoryHandleRow;
