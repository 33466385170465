import React from 'react';
import { Box } from '@mui/material';
import { calendarUrls } from '@config/routes';
import ListView from '@components/ListView';
import { reminderRowsMap } from '@config/handleRows/crm';
import { microservices } from '@config/constants';

const { actionRemindersUrls } = calendarUrls;

export default function Reminders({ labels, editMode }) {
  return (
    <Box>
      <ListView
        label="Reminders"
        prefix="0z8"
        labels={labels}
        editMode={editMode}
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.actionReminder}
        urls={actionRemindersUrls}
        columnKey="remindersCRM"
        handleRowsMap={reminderRowsMap}
      />
    </Box>
  );
}
