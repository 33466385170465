import { formatBytes } from '@config/functions/helperFunctions';

export const fileFolderRowsMap = (row) => ({
  id: row.id,
  name: row.name,
  kind: row.kind,
  fileUrl: row.fileUrl,
  anonymousCanSeeIt: row.anonymousCanSeeIt,
  size: formatBytes(row.size),
  createdAt: row.createdAt,
});
