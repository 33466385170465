import React from 'react';
import * as Yup from 'yup';
import { hrUrls } from '@config/routes';
import { recruitmentPipelineStageMeta } from '@config/meta/hr';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import RecruitmentPipelineDropdown from '@dropdown-forms/hr/RecruitmentPipelineDropdown';
import { useQueryClient } from 'react-query';

const { recruitmentPipelineStagesUrls, recruitmentPipelineUrls } = hrUrls;

export default function RecruitmentStagesDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

 

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Recruitment Pipeline Stage"
        initialValues={{
          ...getAddFormInitialValues(
            recruitmentPipelineStageMeta(),
            'listView',
          ),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            recruitmentPipelineStageMeta(),
            'listView',
          ),
        })}
        getPostUrl={() => recruitmentPipelineStagesUrls.list()}
        getValues={(values, recruitment_pipeline) => ({
          ...values,
          recruitment_pipeline: recruitment_pipeline?.id
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <TextField label="Name" name="name" required />
            </Grid>

            <Grid item xs={6}>
              <TextField label="Description" name="description" />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Order" name="order" />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Rotting Days" name="rotting_days" />
            </Grid>
            <Grid item xs={6}>
              <RecruitmentPipelineDropdown 
                refetch={({ id, name }) => {
                  setFieldValue('recruitment_pipeline', { id, label: name });
                  queryClient.invalidateQueries(['pipeline-stage']);
                }}
              >
                <AutocompleteFieldV2
                  name='recruitment_pipeline'
                  label='Recruitment Pipeline'
                  requestKey='pipeline-stage'
                  fetchUrl={recruitmentPipelineUrls.list}
                  urlParams={`&ordering=created_at`}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                  required
                />
                </RecruitmentPipelineDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
