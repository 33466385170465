import * as Yup from 'yup';
import {
  contextFieldTypeChoices,
  fieldType,
  connectionTypeChoices,
  smtpConnectionTypeChoices,
  microservices,
} from '@config/constants';
import { formatMultipleAutocompleteValue } from '@config/functions/helperFunctions';
import getDropdownForm from '@dropdown-forms/getDropdownForm';
import { communicationUrls } from '@config/routes';

const { labelsUrls, mailTemplatesUrls, imapAccountsUrls, imapProvidersUrls } =
  communicationUrls;

export const labelColumns = ({
  roles,
  rolesIsFetching,
  setRoleTerm,
  users,
  usersIsFetching,
  setUserTerm,
  data,
  queryClient,
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/labels/',
    width: 180,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.TEXT,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'parent_label',
    headerName: 'Parent Label',
    width: 170,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: labelsUrls.list,
    requestKey: `${data?.id}-parent-label`,
    validate: Yup.mixed(),
    initialValue: data?.parent_label
      ? {
          id: data?.parent_label,
          label: data?.details?.parent_label?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-parent-label`]);
      },
      Form: getDropdownForm({
        microservice: microservices.NOTIFICATION.name,
        model: microservices.NOTIFICATION.models.label,
      }),
    }),
  },
  {
    field: 'member_everyone_can_see_it',
    headerName: 'Everyone can see it',
    type: fieldType.BOOLEAN,
    width: 100,
    editable: true,
    value: data ? data.member_everyone_can_see_it : null,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'member_anonymous_can_see_it',
    headerName: 'Anonymous can see it',
    type: fieldType.BOOLEAN,
    width: 100,
    editable: true,
    value: data ? data.member_anonymous_can_see_it : null,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'member_everyone_in_object_company_can_see_it',
    headerName: 'Everyone in Obj Company can see it',
    type: fieldType.BOOLEAN,
    width: 100,
    editable: true,
    value: data ? data.member_everyone_in_object_company_can_see_it : null,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'member_only_these_roles_can_see_it',
    headerName: 'Everyone in These Roles can see it',
    onForm: true,
    onAccordionForm: true,
    initialValue: [],
    type: fieldType.AUTOCOMPLETE,
    multiple: true,
    hideColumn: true,
    setSearchTerm: (value) => setRoleTerm(value),
    options: roles,
    value: data
      ? Object.entries(data.member_only_these_roles_can_see_it || {}).map(
          (roleObj) => ({ id: roleObj[0], label: roleObj[1] })
        )
      : [],
    isLoading: rolesIsFetching,
    validate: Yup.array().default([]),
    formatValue: formatMultipleAutocompleteValue,
    width: 180,
    editable: true,
  },
  {
    field: 'member_only_these_users_can_see_it',
    headerName: 'Everyone in These Users can see it',
    onForm: true,
    onAccordionForm: true,
    initialValue: [],
    type: fieldType.AUTOCOMPLETE,
    multiple: true,
    hideColumn: true,
    setSearchTerm: (value) => setUserTerm(value),
    options: users,
    value: data
      ? Object.entries(data.member_only_these_users_can_see_it || {}).map(
          (userObj) => ({ id: userObj[0], label: userObj[1] })
        )
      : [],
    isLoading: usersIsFetching,
    validate: Yup.array().default([]),
    formatValue: formatMultipleAutocompleteValue,
    width: 180,
    editable: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
  },
];

export const notificationLabelMeta = ({ queryClient, data } = {}) => [
  {
    field: 'label',
    headerName: 'Label',
    width: 170,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: labelsUrls.list,
    requestKey: `${data?.id}-notification-label`,
    validate: Yup.mixed(),
    getHref: (r) => `/labels/${r.labelId}`,
    initialValue: data?.label
      ? {
          id: data?.label,
          label: data?.details?.label?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-notification-label`]);
      },
      Form: getDropdownForm({
        microservice: microservices.NOTIFICATION.name,
        model: microservices.NOTIFICATION.models.label,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
  },
];

export const emailMeta = ({
  data,
  signature,
  allEmails = false,
  dataPanel = false,
} = {}) => [
  {
    field: 'subject',
    headerName: 'Subject',
    width: 180,
    editable: false,
    value: data && data.subject,
    type: fieldType.TEXT,
    initialValue: '',
    getHref: (r) =>
      dataPanel
        ? `/all-emails/${r.id}/?history=true`
        : allEmails
        ? `/all-emails/${r.id}`
        : `detail/${r.id}`,
    onForm: true,
    linkInNewTab: dataPanel,
    openBrowserPopup: dataPanel,
    validate: Yup.string().max(150),
  },
  {
    field: 'to_emails',
    headerName: 'To Emails',
    width: 180,
    editable: false,
    value: data && data.email,
    initialValue: [],
    onForm: true,
    hideColumn: true,
    onDetailCard: false,
    validate: Yup.array()
      .of(Yup.string().email('Enter a valid email').required())
      .min(1, 'To Email is required field'),
  },
  {
    field: 'bcc_emails',
    headerName: 'Bcc Emails',
    width: 180,
    editable: false,
    value: data && data.bcc,
    initialValue: [],
    onForm: true,
    hideColumn: true,
    onDetailCard: false,
    validate: Yup.array().of(
      Yup.string().email('Enter a valid email').required()
    ),
    // validate: Yup.string().max(254).email().required(),
  },
  {
    field: 'cc_emails',
    headerName: 'Cc Emails',
    width: 180,
    editable: false,
    value: data && data.cc,
    initialValue: [],
    onForm: true,
    hideColumn: true,
    onDetailCard: false,
    validate: Yup.array().of(
      Yup.string().email('Enter a valid email').required()
    ),
    // validate: Yup.string().max(254).email().required(),
  },
  {
    field: 'from_email',
    headerName: 'From Email',
    width: 230,
    editable: false,
    value:
      data && data.from_email && data.details && data.details.from_email
        ? data.details.from_email.email
        : data && data.from_email,
    initialValue: '',
    onForm: false,
    validate: Yup.string().max(254).email(),
  },
  {
    field: 'all_to_recipient_emails',
    headerName: 'To Emails',
    width: 230,
    editable: false,
    value: data?.details?.all_to_recipient_emails ?? '',
    initialValue: '',
    onForm: false,
    onDetailCard: false,
    validate: Yup.string().max(254).email(),
  },
  {
    field: 'imap_account',
    headerName: 'Imap Account',
    width: 230,
    editable: false,
    value:
      data && data.imap_account && data.details && data.details.imap_account
        ? data.details.imap_account.display_name ||
          data.details.imap_account.username
        : data && data.imap_account,
    initialValue: '',
    onForm: false,
    getHref: (r) => `/imap-accounts/${r.imap_account_id}`,
    validate: Yup.string().max(254).email(),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: fieldType.TEXT,
    width: 180,
    editable: false,
    value: data && data.status,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'template',
    headerName: 'Mail Template',
    width: 170,
    editable: true,
    hideColumn: true,
    onAccordionForm: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: mailTemplatesUrls.list,
    requestKey: `${data?.id}-email-template`,
    validate: Yup.mixed(),
    initialValue: data?.template
      ? {
          id: data?.template,
          label: data?.details?.template?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'scheduled',
    headerName: 'Scheduled',
    width: 180,
    value: data && data.scheduled ? `${new Date(data.scheduled)}` : null,
    onForm: true,
    editable: false,
    hideColumn: true,
    type: 'dateTime',
  },
  {
    field: 'attachments',
    headerName: 'Attachments',
    onForm: true,
    type: fieldType.UPLOAD,
    width: 180,
    editable: true,
    hide: true,
    onDetailCard: false,
    value: data && data.attachments,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
  {
    field: 'html_body',
    headerName: 'Message',
    width: 200,
    editable: false,
    type: fieldType.HTML,
    value: (data && data.html_body) || '',
    initialValue: signature || '',
    onForm: true,
    hideColumn: true,
    gridStyle: 12,
    validate: Yup.string().nullable(),
  },
];

export const notificationEmailAddressMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 230,
    editable: false,
    value:
      data && data.email_address && data.details && data.details.email_address
        ? data.details.email_address.name || '...'
        : '...',
    initialValue: '',
    onForm: false,
    validate: Yup.string().max(200).required(),
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 230,
    editable: false,
    value:
      data && data.email_address && data.details && data.details.email_address
        ? data.details.email_address.email
        : data && data.email_address,
    initialValue: '',
    onForm: false,
    validate: Yup.string().email().required(),
  },
  {
    field: 'method',
    headerName: 'Method',
    width: 180,
    editable: false,
    value: data && data.method,
    initialValue: '',
    onForm: false,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const emailAttachmentMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    editable: false,
    value: data && data.name,
    initialValue: '',
    onForm: false,
    validate: Yup.string().max(200).required(),
  },
  {
    field: 'url',
    headerName: 'File',
    width: 300,
    editable: false,
    type: fieldType.UPLOAD,
    value: data && data.url,
    getHref: (r) => `${r.url}`,
    initialValue: '',
    onForm: false,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const emailTemplateMeta = ({ data, onListView = false } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    getHref: (r) => `/email-templates/${r.id}`,
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(200).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 200,
    editable: true,
    value: data && data.description,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'subject',
    headerName: 'Subject',
    width: 300,
    editable: !onListView,
    value: data?.subject ?? '',
    initialValue: '',
    onForm: true,
    validate: onListView
      ? Yup.string().max(150).nullable()
      : Yup.string().max(150).required(),
  },
  {
    field: 'from_email',
    headerName: 'From Email',
    width: 170,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: imapAccountsUrls.list,
    requestKey: `${data?.id}-email-template-from-email`,
    validate: Yup.mixed(),
    initialValue: data?.from_email
      ? {
          id: data?.from_email,
          label: data?.details?.from_email?.username,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.username,
    }),
    urlParams:
      '&connected=true&smtp_connected=true&send_emails_from_this_account=true',
  },
  {
    field: 'from_email_alias',
    headerName: 'From Email Alias',
    width: 300,
    editable: true,
    value: data && data.from_email_alias,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(300),
  },
  {
    field: '_tags',
    headerName: 'Tags',
    width: 300,
    editable: true,
    value: data && data._tags,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    type: fieldType.AUTOCOMPLETE,
    setSearchTerm: (value) => null,
    options: [],
    isLoading: false,
    width: 230,
    editable: false,
    value:
      data && data.details && data.details.created_by
        ? { id: data.created_by, label: data.details.created_by.email }
        : data && data.created_by,
    validate: Yup.string().nullable(),
    initialValue: null,
    onForm: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const contextFieldMeta = ({ data } = {}) => [
  {
    field: 'label',
    headerName: 'Name',
    type: fieldType.TEXT,
    width: 180,
    editable: true,
    value: data && data.label,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(400).required(),
  },
  {
    field: 'name',
    headerName: 'Key',
    type: fieldType.TEXT,
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(400).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.description,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'mandatory',
    headerName: 'Mandatory',
    type: fieldType.BOOLEAN,
    width: 100,
    editable: true,
    value: data ? data.mandatory : null,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'type',
    headerName: 'Type',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    options: contextFieldTypeChoices,
    initialValue: 'string',
    value: data ? data.type : null,
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const imapProviderMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    type: fieldType.TEXT,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required().max(700),
  },
  {
    field: 'host',
    headerName: 'Host',
    width: 200,
    editable: true,
    value: data && data.host,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required().max(700),
  },
  {
    field: 'port',
    headerName: 'Port',
    width: 180,
    type: fieldType.NUMBER,
    editable: true,
    value: data && data.port,
    initialValue: '',
    onForm: true,
    validate: Yup.number().required(),
  },
  {
    field: 'connection_type',
    headerName: 'Connection Type',
    type: fieldType.SELECT,
    width: 180,
    editable: true,
    options: connectionTypeChoices,
    initialValue: 'TLS',
    value: data && data.connection_type,
    onAccordionForm: true,
    validate: Yup.mixed()
      .oneOf(
        connectionTypeChoices && connectionTypeChoices.map((item) => item.value)
      )
      .required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];

export const imapAccountMeta = ({ data, queryClient } = {}) => [
  {
    field: 'username',
    headerName: 'Username',
    width: 200,
    type: fieldType.LINK,
    editable: false,
    value: data && data.username,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required().max(700),
    to: '/imap-accounts/',
  },
  {
    field: 'display_name',
    headerName: 'Display Name',
    width: 200,
    type: fieldType.STRING,
    editable: true,
    value: data && data.display_name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required().max(700),
  },
  {
    field: 'password',
    headerName: 'Password',
    width: 200,
    type: fieldType.PASSWORD,
    editable: false,
    initialValue: '',
    onForm: true,
    onDetailCard: false,
    hideColumn: true,
    validate: Yup.string().required().max(700),
  },
  {
    field: 'provider',
    headerName: 'Provider',
    width: 170,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: imapProvidersUrls.list,
    requestKey: `${data?.id}-imap-provider`,
    validate: Yup.mixed(),
    initialValue: data?.provider
      ? {
          id: data?.provider,
          label: data?.details?.provider?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-imap-provider`]);
      },
      Form: getDropdownForm({
        microservice: microservices.NOTIFICATION.name,
        model: microservices.NOTIFICATION.models.imapProvider,
      }),
    }),
  },
  {
    field: 'smtp_host',
    headerName: 'Smtp Host',
    width: 200,
    editable: false,
    value: data && data.smtp_host,
    initialValue: 'smtp.gmail.com',
    onForm: true,
    validate: Yup.string().max(700),
  },
  {
    field: 'smtp_username',
    headerName: 'Smtp Username',
    width: 200,
    type: fieldType.LINK,
    editable: false,
    value: data && data.username,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(700),
    to: '/imap-accounts/',
  },
  {
    field: 'encryption_enabled',
    headerName: 'Encryption Enabled',
    type: fieldType.BOOLEAN,
    width: 260,
    editable: false,
    value: data && data.encryption_enabled,
    initialValue: false,
    onForm: true,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'send_emails_from_this_account',
    headerName: 'Send Emails From This Account',
    type: fieldType.BOOLEAN,
    width: 260,
    editable: false,
    value: data && data.send_emails_from_this_account,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'connected',
    headerName: 'Connected',
    type: fieldType.BOOLEAN,
    width: 150,
    editable: false,
    value: data && data.connected,
    initialValue: true,
    validate: Yup.boolean().default(true),
  },
  {
    field: 'smtp_connected',
    headerName: 'Smtp Connected',
    type: fieldType.BOOLEAN,
    width: 150,
    editable: false,
    value: data && data.smtp_connected,
    initialValue: true,
    validate: Yup.boolean().default(true),
  },
  {
    field: 'smtp_port',
    headerName: 'Smtp Port',
    width: 180,
    type: fieldType.NUMBER,
    editable: false,
    value: data && data.smtp_port,
    initialValue: 587,
    onForm: true,
    hideColumn: true,
    validate: Yup.number().nullable(),
  },
  {
    field: 'smtp_connection_type',
    headerName: 'Smtp Connection Type',
    type: fieldType.SELECT,
    width: 180,
    editable: false,
    options: smtpConnectionTypeChoices,
    initialValue: 'TLS',
    value: data && data.smtp_connection_type,
    onForm: true,
    onAccordionForm: true,
    hideColumn: true,
    validate: Yup.mixed()
      .oneOf(
        smtpConnectionTypeChoices &&
          smtpConnectionTypeChoices.map((item) => item.value)
      )
      .default('TLS'),
  },
  {
    field: 'smtp_password',
    headerName: 'Smtp Password',
    width: 200,
    type: fieldType.PASSWORD,
    editable: false,
    initialValue: '',
    onForm: true,
    onDetailCard: false,
    hideColumn: true,
    validate: Yup.string().max(700),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: 'dateTime',
  },
];
