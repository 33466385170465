import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { listsUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { countryMeta } from '@config/meta/list';
import {
  cityRowsMap,
  countryCurrencyHandleRow,
  countryLanguageHandleRow,
} from '@config/handleRows/list';
import {
  CityForm,
  CountryLanguageForm,
  CountryCurrencyForm,
} from '@config/forms/list';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  countriesUrls,
  citiesUrls,
  countryLanguagesUrls,
  countryCurrenciesUrls,
} = listsUrls;

export default function CountryDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  const getSchema = React.useCallback((data) => countryMeta({ data }), []);

  const countryCurrencyColOptions = React.useMemo(
    () => ({
      hideCountry: true,
      hideCurrency: false,
    }),
    []
  );

  const countryLanguageColOptions = React.useMemo(
    () => ({
      hideCountry: true,
      hideLanguage: false,
    }),
    []
  );

  const cityColOptions = React.useMemo(
    () => ({
      hideCountry: true,
    }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/country', text: 'Country' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={countriesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.LIST.name}
          model={microservices.LIST.models.countryCurrency}
          columnKey='countryCurrencyLists'
          columnOptions={countryCurrencyColOptions}
          label='Country Currency'
          prefix='03y'
          labels={labels}
          editMode={editMode}
          urls={countryCurrenciesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              CountryCurrencyForm({
                hideCountry: false,
                hideCurrency: true,
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { currency, ...rest } = values;
                const { data } = await createRecord({
                  values: {
                    ...rest,
                    country: recordId,
                    currency: currency?.id,
                  },
                  url: countryCurrenciesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return data.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={countryCurrencyHandleRow}
          urlParams={`&country=${recordId}`}
        />
        <DetailAccordionView
          microservice={microservices.LIST.name}
          model={microservices.LIST.models.countryLanguages}
          columnKey='countryLanguageLists'
          columnOptions={countryLanguageColOptions}
          label='Country Language'
          prefix='0ji'
          labels={labels}
          editMode={editMode}
          urls={countryLanguagesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              CountryLanguageForm({
                hideCountry: false,
                hideLanguage: true,
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { language, ...rest } = values;
                const { data } = await createRecord({
                  values: {
                    ...rest,
                    country: recordId,
                    language: language?.id,
                  },
                  url: countryLanguagesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return data.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={countryLanguageHandleRow}
          urlParams={`&country=${recordId}`}
        />
        <DetailAccordionView
          microservice={microservices.LIST.name}
          model={microservices.LIST.models.cities}
          columnOptions={cityColOptions}
          columnKey='cityLists'
          label='City'
          prefix='09i'
          labels={labels}
          editMode={editMode}
          urls={citiesUrls}
          addNewForm={{
            getFields: () =>
              CityForm({
                hideCountry: false,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { data } = await createRecord({
                  values: { ...values, country: recordId },
                  url: citiesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return data.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={cityRowsMap}
          urlParams={`&country=${recordId}`}
        />
      </Box>
    </Box>
  );
}
