import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { inventoryUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { ProductForm } from '@config/forms/inventory/index';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { microservices } from '@config/constants';
import { productRowMap } from '@config/handleRows/inventory';

const { productsUrls } = inventoryUrls;

export default function Products({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label="Products"
        prefix="0sd"
        labels={labels}
        editMode={editMode}
        microservice={microservices.INVENTORY.name}
        model={microservices.INVENTORY.models.product}
        urls={productsUrls}
        columnKey="productInventory"
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            ProductForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { product_type, manufacturer, ...rest } = values;

              const response = await createRecord({
                values: {
                  ...rest,
                  product_type: product_type?.id,
                  manufacturer: manufacturer?.id,
                },
                url: productsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={productRowMap}
      />
    </Box>
  );
}
