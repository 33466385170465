import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useField } from 'formik';

export default function FormikTextField({
  name,
  label,
  multiline,
  rows,
  required,
  submitCount,
}) {
  const [field, meta] = useField(name);
  const isError = Boolean(meta.touched && meta.error);
  const submitError = Boolean(submitCount > 0 && meta.error);

  return (
    <Box>
      <Typography style={{ color: '#fff' }}>
        {label} {required ? <span style={{ color: 'red' }}>*</span> : null}
      </Typography>
      <TextField
        {...field}
        variant='outlined'
        margin='normal'
        size='small'
        multiline={multiline}
        rows={rows}
        value={field.value || ''}
        inputProps={{
          style: {
            backgroundColor: 'white',
            borderRadius: '6px',
            '&:focus': {
              borderColor: 'white',
            },
          },
        }}
        fullWidth
        style={{ marginTop: '4px', marginBottom: '4px' }}
      />
      {isError || submitError ? (
        <div style={{ marginTop: '0.5rem', fontSize: '0.85rem', color: 'red' }}>
          {meta.error}
        </div>
      ) : null}
    </Box>
  );
}
