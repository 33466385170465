import React from 'react';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { getAuthHeader } from '@config/functions/helperFunctions';
import useRQuery from '@hooks/useRQuery';
import { encryptionPassKey } from '@config/constants';

export default function useAutocomplete({
  key,
  getUrl,
  inputValue,
  renderRow,
  query,
  config,
  enabled,
  selected,
  orderingCol,
}) {
  const [term, setTerm] = React.useState('');
  const user = useSelector(selectProfile);

  const { data, isFetching, error, refetch } = useRQuery({
    key: Array.isArray(key)
      ? [...key, term, user.token, user?.actAs]
      : [key, term, user.token, user?.actAs],
    url: getUrl(
      `?search=${term}&autocomplete=true&page_size=500&ordering=${orderingCol || 'name'
      }${query || ''}`
    ),
    config:
      config ||
      getAuthHeader(
        user?.token,
        user?.actAs,
        user.encryptionToken || localStorage.getItem(encryptionPassKey)
      ),
    options: {
      enabled:
        typeof enabled !== 'undefined'
          ? !!(user.token && enabled)
          : !!user.token,
      refetchOnWindowFocus: true,
      retry: 0,
    },
    renderRow: renderRow
      ? (r) => renderRow(r)
      : (r) => ({
        id: r.id,
        label: r.name,
      }),
  });

  React.useEffect(() => {
    if (selected) return;
    const timeoutId = setTimeout(() => setTerm(inputValue), 500);
    return () => clearTimeout(timeoutId);
  }, [inputValue, selected]);

  return { data, isFetching, refetch, error };
}
