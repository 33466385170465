import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/system';

export default function MenuItems({ actions, context }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => {
    setAnchorEl(null);
    if (
      action &&
      action.handleClick &&
      typeof action.handleClick === 'function'
    ) {
      action.handleClick(context)();
    }
  };

  return (
    <Box key="main-container">
      <IconButton
        key="IconButton"
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon key="MoreVertIcon" />
      </IconButton>

      <Menu
        key="long-menu"
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actions &&
          actions.map((action) => (
            <MenuItem
              key={action.label}
              onClick={(e) => handleClose(action, e)}
              sx={{ color: action.color }}
            >
              {action.icon}
              {action.label}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
}
