import * as React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@components/LMS/Accordion';
import AccordionSummary from '@components/LMS/AccordionSummary';
import AccordionDetails from '@components/LMS/AccordionDetails';
import TopBar from '@components/LMS/TopBar';
import { useQueryClient } from 'react-query';
import { Box, Grid, Container, Button, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import { lmsUrls, nodeDriveUrls, wikiUrls } from '@config/routes';
import {
  TextField,
  UploadField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import HomeworkDropdown from '@dropdown-forms/lms/HomeworkDropdown';
import { createRecord, updateRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useRQuery from '@hooks/useRQuery';
import useToast from '@hooks/useToast';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuthHeader } from '@config/functions/helperFunctions';
import ErrorScreen from '@components/ErrorScreen';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import {
  moduleLessonHandleRow,
  courseModuleHandleRow,
} from '@config/handleRows/lms';
import { ModuleLessonForm, CourseModuleForm } from '@config/forms/lms';
import { moduleLessonMeta, courseModuleMeta } from '@config/meta/lms';
import BookDropdown from '@dropdown-forms/wiki/BookDropdown';
import CategoryDropdown from '@dropdown-forms/lms/CategoryDropdown';

const { booksUrls } = wikiUrls;
const { filesUrls } = nodeDriveUrls;
const {
  coursesUrls,
  homeworksUrls,
  moduleLessonsUrls,
  courseModulesUrls,
  updateCourseWithRelationsUrls,
  categoriesUrls,
} = lmsUrls;

function StepButtons({ backBtnText, nextBtnText, onBack, onNext, disabled }) {
  return (
    <>
      <Divider sx={{ mb: 2 }} />

      {onBack ? (
        <Button
          variant='outlined'
          disableElevation
          sx={{ px: 4, mr: 2 }}
          onClick={onBack}
          disabled={disabled}
        >
          {backBtnText || 'Back'}
        </Button>
      ) : null}

      {onNext ? (
        <Button
          variant='contained'
          disableElevation
          sx={{ px: 4 }}
          onClick={onNext}
          disabled={disabled}
        >
          {nextBtnText || 'Save and Continue'}
        </Button>
      ) : null}
    </>
  );
}

export default function CreateCourse() {
  const [notify] = useToast();
  const navigate = useNavigate();
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [expanded, setExpanded] = React.useState('course');
  // const [childExpanded, setChildExpanded] = React.useState(false);
  const [moduleLessonsListData, setModuleLessonsListData] = React.useState({});
  const [courseModulesListData, setCourseModulesListData] = React.useState({});

  const { data, isLoading, isError } = useRQuery({
    key: ['course-detail', recordId, user.token, user?.actAs],
    url: coursesUrls.detail(recordId),
    config: getAuthHeader(user.token, user?.actAs),
    options: {
      enabled: !!(user.token && recordId),
    },
  });

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // const handleChildChange = (panel) => (e, newExpanded) => {
  //   setChildExpanded(newExpanded ? panel : false);
  // };

  const validateCourseFields = ({ data, values, setFieldError }) => {
    const errors = {};

    if (!values?.courseTitle) {
      errors['courseTitle'] = 'Please enter a title';
    }

    if (!data?.thumbnail && !values?.courseThumbnail) {
      errors['courseThumbnail'] = 'Please upload a thumbnail';
    }

    if (!values?.courseWikiBook) {
      errors['courseWikiBook'] = 'Please select a wikibook';
    }

    Object.keys(errors).map((key) => {
      setFieldError(key, errors[key]);
    });

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  const validateModuleFields = ({ values, setFieldError }) => {
    const errors = [];

    values.modules.forEach((item, index) => {
      setFieldError(`modules.${index}`, null);

      if (!item?.id || !item?.label) {
        errors[index] = 'Please select an option';
      }
    });

    errors.forEach((message, index) => {
      setFieldError(`modules.${index}`, message);
    });

    if (errors.length) return false;

    return true;
  };

  const validateLessonFields = ({ values, setFieldError }) => {
    const errors = [];

    values.lessons.forEach((item, index) => {
      setFieldError(`lessons.${index}`, null);

      if (!item?.id || !item?.label) {
        errors[index] = 'Please select an option';
      }
    });

    errors.forEach((message, index) => {
      setFieldError(`lessons.${index}`, message);
    });

    if (errors.length) return false;

    return true;
  };

  const getInitialModules = ({ data }) => {
    if (!Array.isArray(data?.courseModules)) {
      return [{ id: null, label: '', sequence: 1 }];
    }

    return data.courseModules
      .filter(({ deleted }) => !deleted)
      .map(({ id, module, sequence, moduleLessons }) => ({
        id: module?.id,
        mId: id,
        label: module?.name,
        sequence,
        moduleLessons,
      }));
  };

  const getInitialLessons = ({ data }) => {
    if (!Array.isArray(data?.courseModules)) {
      return [];
    }

    const lessons = [];

    for (const courseModule of data.courseModules) {
      if (courseModule.deleted) {
        continue;
      }

      const module = courseModule.module;

      for (const moduleLesson of module.moduleLessons) {
        if (moduleLesson.deleted) {
          continue;
        }

        lessons.push({
          id: moduleLesson?.lesson?.id,
          mlId: moduleLesson?.id,
          sequence: moduleLesson?.sequence,
          label: moduleLesson?.lesson?.name,
          module: {
            id: module?.id,
            label: module?.name,
          },
        });
      }
    }

    return lessons;
  };

  if (isError) {
    return <ErrorScreen error='Failed to fetch the resource from server' />;
  }

  if (isLoading || !data) {
    return <p>loading...</p>;
  }

  return (
    <Box>
      <TopBar
        title='Edit Course'
        active={expanded}
        setActive={setExpanded}
        hideUni
      />

      <Formik
        initialValues={{
          courseTitle: data?.name ?? '',
          courseDescription: data?.description ?? '',
          courseHomework: { id: data?.homeworkId, label: data?.homework?.name },
          courseWikiBook: {
            id: data?.wikiBookId,
            label: data?.details?.wikiBookId?.name,
          },
          courseCategory: {
            id: data?.categoryId,
            label: data?.details?.categoryId?.name,
          },
          courseThumbnail: '',
          modules: getInitialModules({ data }),
          lessons: getInitialLessons({ data }),
        }}
        onSubmit={async (
          values,
          { setSubmitting, setFieldError, resetForm }
        ) => {
          try {
            setSubmitting(true);

            const courseValid = validateCourseFields({
              data,
              values,
              setFieldError,
            });

            if (!courseValid) {
              setExpanded('course');
              return;
            }

            const moduleValid = validateModuleFields({
              values,
              setFieldError,
            });

            if (!moduleValid) {
              setExpanded('module');
              return;
            }

            const lessonValid = validateLessonFields({
              values,
              setFieldError,
            });

            if (!lessonValid) {
              setExpanded('lesson');
              return;
            }

            let fileUrl;

            if (values?.courseThumbnail) {
              try {
                const formData = new FormData();
                formData.append(
                  'client',
                  user?.actAs?.id || user?.details?.client
                );
                formData.append('created_by', user?.details?.id);
                formData.append('anonymous_can_see_it', true);
                formData.append('file', values.courseThumbnail);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                fileUrl = fileData.fileUrl;
              } catch {}

              if (!fileUrl) {
                setExpanded('course');
                setFieldError('courseThumbnail', 'Failed to upload thumbnail');
                return;
              }
            }
            console.log(data?.wikiBookId, 'init');
            console.log(values?.courseWikiBook?.id, 'vvv');

            const payload = {
              name: values.courseTitle,
              description: values.courseDescription,
              homeworkId: values.courseHomework?.id ?? undefined,
              wikiBookId: values?.courseWikiBook?.id,
              categoryId: values?.courseCategory?.id ?? undefined,
              thumbnail: fileUrl ?? data?.thumbnail,
              modules: values.modules,
              lessons: values.lessons.map(({ id, sequence, module }) => ({
                id,
                sequence,
                moduleId: module?.id,
              })),
            };

            await updateRecord({
              url: updateCourseWithRelationsUrls.detail(recordId),
              values: payload,
              token: user.token,
              actAs: user?.actAs,
            });

            setExpanded(null);
            resetForm();
            navigate(`/courses/${recordId}`);
            notify('Operation completed', {
              type: 'SUCCESS',
            });
          } catch (error) {
            notify('There was an error, please try again', {
              type: 'ERROR',
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          isSubmitting,
          submitForm,
          setFieldValue,
          setFieldError,
          values,
        }) => (
          <>
            <Form>
              <Container maxWidth='md'>
                <Accordion
                  expanded={expanded === 'course'}
                  onChange={handleChange('course')}
                >
                  <AccordionSummary
                    aria-controls='course-content'
                    id='course-header'
                  >
                    <Typography>Course</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} sx={{ py: 2 }}>
                      <Grid item xs={6}>
                        <TextField
                          label='Course Title'
                          name='courseTitle'
                          multiline
                          required
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <HomeworkDropdown
                          refetch={({ id, name }) => {
                            setFieldValue('courseHomework', {
                              id,
                              label: name,
                            });
                            queryClient.invalidateQueries(['course-homework']);
                          }}
                        >
                          <AutocompleteFieldV2
                            name='courseHomework'
                            label='Course Task'
                            requestKey='course-homework'
                            fetchUrl={homeworksUrls.list}
                          />
                        </HomeworkDropdown>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label='Course Description'
                          name='courseDescription'
                          minRows={4}
                          multiline
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Box sx={{ maxWidth: '490px' }}>
                          <UploadField
                            name='courseThumbnail'
                            label='thumbnail'
                            accept='.png, .jpg, .jpeg'
                          />
                        </Box>
                      </Grid>
                      {data?.thumbnail ? (
                        <Grid item xs={6}>
                          <Box sx={{ maxWidth: '490px' }}>
                            <a href={data?.thumbnail}>{data?.thumbnail}</a>
                          </Box>
                        </Grid>
                      ) : null}
                      <Grid item xs={6}>
                        <BookDropdown
                          refetch={({ id, name }) => {
                            setFieldValue('courseWikiBook', {
                              id,
                              label: name,
                            });
                            queryClient.invalidateQueries(['course-wiki-book']);
                          }}
                        >
                          <AutocompleteFieldV2
                            name='courseWikiBook'
                            label='Course Wikibook'
                            requestKey='wiki-book'
                            fetchUrl={booksUrls.list}
                            required
                          />
                        </BookDropdown>
                      </Grid>
                      <Grid item xs={6}>
                        <CategoryDropdown
                          refetch={({ id, name }) => {
                            setFieldValue('courseCategory', {
                              id,
                              label: name,
                            });
                            queryClient.invalidateQueries(['course-category']);
                          }}
                        >
                          <AutocompleteFieldV2
                            name='courseCategory'
                            label='Course Category'
                            requestKey='course-category'
                            fetchUrl={categoriesUrls.list}
                          />
                        </CategoryDropdown>
                      </Grid>
                    </Grid>

                    <StepButtons
                      onNext={() => {
                        const isValid = validateCourseFields({
                          data,
                          values,
                          setFieldError,
                        });
                        if (isValid) setExpanded('module');
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Container>
            </Form>
            <Container maxWidth='md'>
              <DetailAccordionView
                expand={expanded === 'module'}
                onChange={handleChange('module')}
                nodeAPI
                hideSearch
                label='Module'
                columns={courseModuleMeta({
                  nextOrder: courseModulesListData?.totalCount ?? 1,
                  wikiBook: data?.wikiBookId,
                })}
                urls={courseModulesUrls}
                addNewForm={{
                  getFields: ({ setFieldValue }) =>
                    CourseModuleForm({
                      setFieldValue,
                      wikiBookId: data?.wikiBookId,
                      wikiBookName: data?.details?.wikiBookId?.name,
                    }),
                  handleSubmit: async (
                    values,
                    setSubmitting,
                    resetForm,
                    setAddNewForm,
                    refetch,
                    setError,
                    setFieldError
                  ) => {
                    try {
                      const { moduleId, wikiChapterId, ...rest } = values;

                      if (!moduleId?.id) {
                        setFieldError('moduleId', 'This field is required');
                        return;
                      }

                      await createRecord({
                        values: {
                          ...rest,
                          courseId: recordId,
                          moduleId: moduleId?.id,
                          wikiChapterId: wikiChapterId?.id,
                        },
                        url: courseModulesUrls.list(),
                        token: user.token,
                        actAs: user?.actAs,
                      });
                      refetch();
                      resetForm();
                      setAddNewForm(false);
                    } catch (err) {
                      handleCreateFormErrors({ err, setError, notify, values });
                    } finally {
                      setSubmitting(false);
                    }
                  },
                }}
                handleRowMap={courseModuleHandleRow}
                urlParams={`&courseId=${recordId}`}
                customSortColumnName='sequence'
                sortColumnBy='asc'
                getListData={(data) => setCourseModulesListData(data)}
              />
            </Container>
            <Container maxWidth='md'>
              <Accordion
                expanded={expanded === 'lesson'}
                onChange={handleChange('lesson')}
              >
                <AccordionSummary
                  aria-controls='lesson-content'
                  id='lesson-header'
                >
                  <Typography>Lesson</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ py: 2 }}>
                    <>
                      <Typography sx={{ fontWeight: '500', mb: 1 }}>
                        Modules
                      </Typography>
                      {values.modules
                        .filter((r) => r.id && r.label)
                        .map(({ id, label }) => (
                          <DetailAccordionView
                            nodeAPI
                            hideSearch
                            label={label}
                            columns={moduleLessonMeta({
                              queryClient,
                              nextOrder: moduleLessonsListData?.totalCount ?? 1,
                            })}
                            urls={moduleLessonsUrls}
                            addNewForm={{
                              getFields: ({ setFieldValue }) =>
                                ModuleLessonForm({ setFieldValue }),
                              handleSubmit: async (
                                value,
                                setSubmitting,
                                resetForm,
                                setAddNewForm,
                                refetch,
                                setError
                              ) => {
                                try {
                                  const { lessonId, wikiSectionId, ...rest } =
                                    value;

                                  console.log(value);

                                  await createRecord({
                                    values: {
                                      ...rest,
                                      moduleId: id,
                                      lessonId: lessonId?.id,
                                      wikiSectionId: wikiSectionId?.id,
                                    },
                                    url: moduleLessonsUrls.list(),
                                    token: user.token,
                                    actAs: user?.actAs,
                                  });
                                  refetch();
                                  resetForm();
                                  setAddNewForm(false);
                                } catch (err) {
                                  console.log(err);
                                  handleCreateFormErrors({
                                    err,
                                    setError,
                                    notify,
                                    values,
                                  });
                                } finally {
                                  setSubmitting(false);
                                }
                              },
                            }}
                            handleRowMap={moduleLessonHandleRow}
                            urlParams={`&moduleId=${id}`}
                            customSortColumnName='sequence'
                            sortColumnBy='asc'
                            getListData={(data) =>
                              setModuleLessonsListData(data)
                            }
                          />
                        ))}
                    </>
                  </Box>

                  <StepButtons
                    onNext={submitForm}
                    disabled={isSubmitting}
                    onBack={() => setExpanded('module')}
                  />
                </AccordionDetails>
              </Accordion>
            </Container>
          </>
        )}
      </Formik>
    </Box>
  );
}
