import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { Box, Typography, Grid, Card } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  getAuthHeader,
  formatDateTime,
} from '@config/functions/helperFunctions';
import useRQuery from '@hooks/useRQuery';
import { selectProfile } from '@redux/profileSlice';
import { calendarUrls } from '@config/routes';
import Button from '@mui/material/Button';
import { updateRecord, deleteRecord } from '@config/functions/requests';
import { useQueryClient } from 'react-query';
import useToast from '@hooks/useToast';
import CheckIcon from '@mui/icons-material/Check';

const { actionRemindersUrls } = calendarUrls;

const drawerWidth = 369;

function INAText({ label, value }) {
  return (
    <>
      <Typography sx={{ fontSize: '12px', color: 'rgb(95,99,104)' }}>
        {label}
      </Typography>
      <Typography sx={{ fontSize: '1rem', color: 'rgb(32,33,36)' }}>
        {value}
      </Typography>
    </>
  );
}

export default function DrawerItem({
  card,
  drawerOpen,
  setDrawerOpen,
  anchor,
  url,
}) {
  const user = useSelector(selectProfile);
  const [item, setItem] = React.useState([]);
  const [notify] = useToast();
  const queryClient = useQueryClient();

  const container =
    typeof window !== 'undefined' ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const {
    data,
    isLoading,
    refetch: refetchInaData,
  } = useRQuery({
    key: [
      `${card}-manual-workflow-instance-inas`,
      card,
      user.token,
      user?.actAs,
    ],
    url: actionRemindersUrls.list(
      `?entity=ManualWorkflowInstance&entity_microservice=BPA&reminder_type=INA Reminder Event&entity_id=${card}&ordering=-created_at`
    ),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!drawerOpen },
  });

  React.useEffect(() => {
    if (!data) return;
    setItem(data.results);
  }, [data]);

  const onINAUpdate = async (id) => {
    try {
      await updateRecord({
        url: actionRemindersUrls.detail(id),
        values: { progress: 'Completed' },
        token: user.token,
        actAs: user?.actAs,
      });
      refetchInaData();
      queryClient.invalidateQueries(['inas-board']);
      notify('INA Updated', {
        type: 'SUCCESS',
      });
    } catch (error) {
      console.log(error?.response?.data ?? error?.message);
      notify('Something went wrong, try refreshing', {
        type: 'ERROR',
      });
    }
  };

  const onINADelete = async (id) => {
    try {
      await deleteRecord({
        url: actionRemindersUrls.detail(id),
        token: user.token,
        actAs: user?.actAs,
      });
      refetchInaData();
      queryClient.invalidateQueries(['inas-board']);
      notify('INA Deleted', {
        type: 'SUCCESS',
      });
    } catch (error) {
      console.log(error?.response?.data ?? error?.message);
      notify('Something went wrong, try refreshing', {
        type: 'ERROR',
      });
    }
  };

  return (
    <Box>
      <Drawer
        container={container}
        variant="temporary"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
          '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.06)' },
        }}
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        anchor={anchor}
      >
        {isLoading ? (
          <Box sx={{ pl: 3 }}>
            <Toolbar />
            <p>loading...</p>
          </Box>
        ) : (
          <Box>
            <Toolbar />
            {item.map((data) => (
              <List>
                <Card sx={{ pt: 2 }} variant="outlined">
                  <Box sx={{ pl: 2, flexGrow: 1 }}>
                    <Grid container spacing={1} sx={{ mb: 1 }}>
                      <Grid item xs={12}>
                        <INAText label="NAME" value={data?.name ?? ''} />
                      </Grid>
                      <Grid item xs={12}>
                        <INAText label="NOTES" value={data?.ina_notes ?? ''} />
                      </Grid>
                      <Grid item xs={12}>
                        <INAText
                          label="DATE TIME"
                          value={
                            formatDateTime(data.reminder_start_datetime) ?? ''
                          }
                        />
                      </Grid>
                      <Grid item>
                        {data.progress === 'Completed' ? (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: 'green',
                            }}
                          >
                            <CheckIcon
                              fontSize="small"
                              color="primary"
                              sx={{ color: 'green', mr: 1 }}
                            />
                            <Typography sx={{ fontSize: '12px' }}>
                              Completed
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex' }}>
                            <Button
                              onClick={() => onINAUpdate(data.id)}
                              sx={{ mr: 2 }}
                            >
                              Mark as done
                            </Button>
                            <Button
                              color="error"
                              onClick={() => onINADelete(data.id)}
                            >
                              Delete
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </List>
            ))}
          </Box>
        )}
      </Drawer>
    </Box>
  );
}
