import * as React from 'react';
import Flowchart from '@components/BPA-V2/Flowchart';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import { bpaUrls } from '@config/routes';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { Box, Typography } from '@mui/material';
import Loader from '@components/Loader';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';

const { workflowsUrls, shapesUrls, shapeTransitionsUrls, getCWFShapesUrls } =
  bpaUrls;

export default function BPM({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const { id: workflowId } = useParams();
  const [isOrderingShapes, setOrderingShapes] = React.useState(false);

  const { data: workflowDetails, isError: workflowError } = useRQuery({
    key: [`${workflowId}-detail`, workflowId, user.token, user?.actAs],
    url: workflowsUrls.detail(workflowId),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user.token && !!workflowId), cacheTime: 0 },
  });

  const { data: shapes, isLoading: loadingShapes } = useRQuery({
    key: [`${workflowId}-shapes`, workflowId, user.token, user?.actAs],
    url: shapesUrls.list(`?workflow=${workflowId}`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user.token && !!workflowId), cacheTime: 0 },
  });

  const { data: transitions, isLoading: loadingTransitions } = useRQuery({
    key: [`${workflowId}-transitions`, workflowId, user.token, user?.actAs],
    url: shapeTransitionsUrls.list(`?workflow=${workflowId}`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user.token && !!workflowId), cacheTime: 0 },
  });

  const { data: childShapes, isLoading: loadingCWFShapes } = useRQuery({
    key: [`${workflowId}-cwf-shapes`, workflowId, user.token, user?.actAs],
    url: getCWFShapesUrls.detail(workflowId),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user.token && !!workflowId), cacheTime: 0 },
  });

  if (workflowError) {
    return (
      <Typography sx={{ fontSize: 20, fontWeight: 500, mb: 3 }} component="h3">
        Failed to fetch data from the server
      </Typography>
    );
  }

  if (
    !workflowDetails ||
    loadingShapes ||
    !shapes ||
    loadingTransitions ||
    !transitions ||
    loadingCWFShapes ||
    !childShapes
  ) {
    return (
      <Box sx={{ py: 16 }}>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      {workflowDetails ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/workflows/${workflowDetails?.id}`,
                text: 'Workflows',
              },
              { text: workflowDetails?.name },
            ]}
          ></DetailPageHeader>
        </Box>
      ) : null}

      {workflowDetails?.name ? (
        <Typography
          sx={{ fontSize: 20, fontWeight: 500, mb: 3 }}
          component="h3"
        >
          {workflowDetails?.name}
        </Typography>
      ) : null}

      {loadingShapes ||
      loadingTransitions ||
      loadingCWFShapes ||
      isOrderingShapes ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : workflowId ? (
        <Flowchart
          user={user}
          workflowId={workflowId}
          shapes={shapes}
          transitions={transitions}
          cwfShapes={childShapes}
          setOrderingShapes={setOrderingShapes}
          labels={labels}
          editMode={editMode}
          workflowName={workflowDetails?.name}
          draftStage={workflowDetails?.draft_stage}
        />
      ) : null}
    </>
  );
}
