import * as Yup from 'yup';
import { fieldType } from '@config/constants';
import {
  formatToUTCDate,
  formatToUTCDateTime,
} from '@config/functions/helperFunctions';
import getDropdownForm from '@dropdown-forms/getDropdownForm';
import {
  microservices,
  vacanciesStatusChoices,
  dayoffStatusType,
  reapplyDurationUnitsChoices,
  assessmentRating,
} from '@config/constants';
import { hrUrls, bpaUrls, communicationUrls, crmUrls } from '@config/routes';

const { workflowsUrls } = bpaUrls;
const {
  recruitmentPipelineStagesUrls,
  departmentsUrls,
  dayOffTypesUrls,
  employeesUrls,
  recruitmentPipelineUrls,
  jobLevelsUrls,
  skillLevelUrls,
  employeeStatusUrls,
} = hrUrls;
const { mailTemplatesUrls } = communicationUrls;
const { personsUrls, companiesUrls } = crmUrls;

export const interviewNoteMeta = ({ data } = {}) => [
  {
    field: 'interview_name',
    headerName: 'Interview name',
    onAccordionForm: true,
    editable: true,
    value: data ? data.interview_name : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string().required(),
  },
  {
    field: 'rating',
    headerName: 'Rating',
    onAccordionForm: true,
    width: 250,
    editable: true,
    value: data ? data.rating : null,
    initialValue: '',
    validate: Yup.number('Only 1-10 are allowed').positive(
      'Only 1-10 are allowed'
    ),
  },
  {
    field: 'current_salary',
    headerName: 'Current salary',
    onAccordionForm: true,
    editable: true,
    value: data ? data.current_salary : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'expected_salary',
    headerName: 'Expected salary',
    onAccordionForm: true,
    editable: true,
    value: data ? data.expected_salary : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'current_location',
    headerName: 'Current location',
    onAccordionForm: true,
    editable: true,
    value: data ? data.current_location : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'relevant_skills',
    headerName: 'Relevant skills',
    onAccordionForm: true,
    editable: true,
    value: data ? data.relevant_skills : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'initial_skills',
    headerName: 'Initial skills',
    onAccordionForm: true,
    editable: true,
    value: data ? data.initial_skills : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'initial_impression',
    headerName: 'Initial impression',
    onAccordionForm: true,
    editable: true,
    value: data ? data.initial_impression : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'motivations',
    headerName: 'Motivations',
    onAccordionForm: true,
    editable: true,
    value: data ? data.motivations : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'recommendations',
    headerName: 'Recommendations',
    onAccordionForm: true,
    editable: true,
    value: data ? data.recommendations : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'reason_for_leaving_current_job',
    headerName: 'Reason for leaving current job',
    onAccordionForm: true,
    editable: true,
    value: data ? data.reason_for_leaving_current_job : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const assessmentMeta = ({ data, includeVacancy = false } = {}) => [
  {
    field: 'rating',
    headerName: 'Rating',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onAccordionForm: true,
    options: assessmentRating,
    initialValue: assessmentRating[0].label,
    value: data ? data.rating : null,
    validate: Yup.string().required(),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 1080,
    type: fieldType.TEXT,
    editable: true,
    value: data?.notes,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    width: 280,
    editable: true,
    value: data?.attachment ?? '',
    initialValue: '',
    validate: Yup.mixed(),
    onAccordionForm: true,
    type: fieldType.UPLOAD,
    required: true,
  },
  {
    field: 'vacancy',
    headerName: 'Vacancy',
    hideColumn: !includeVacancy,
    width: 220,
    type: fieldType.TEXT,
    editable: false,
    value: data?.vacancy,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    width: 180,
    type: fieldType.TEXT,
    editable: false,
    value: data?.details?.created_by?.email,
    onAccordionForm: false,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const recruitmentPipelineMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.LINK,
    to: '/recruitment-pipelines/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 890,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const recruitmentPipelineStageMeta = ({ data, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 90,
    editable: true,
    value: data ? data.order : null,
    initialValue: nextOrder ? nextOrder + 1 : 1,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'email_template',
    headerName: 'Email template',
    width: 250,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: mailTemplatesUrls.list,
    requestKey: `${data?.id}-recruitment-pipeline-stage-email-template`,
    validate: Yup.mixed(),
    initialValue: data?.email_template
      ? {
        id: data?.email_template,
        label: data?.details?.email_template?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'description',
    headerName: 'Description',
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 340,
    onAccordionForm: true,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'rotting_days',
    headerName: 'Rotting Days',
    width: 150,
    editable: true,
    value: data ? data.rotting_days : null,
    initialValue: 0,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const keywordMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    editable: true,
    value: data?.name,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 900,
    editable: true,
    type: fieldType.MULTILINE,
    value: data?.description,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const vacancyStatusMeta = ({ data } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 90,
    editable: true,
    value: data ? data.order : null,
    initialValue: 0,
    validate: Yup.number(),
    onAccordionForm: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 540,
    onAccordionForm: true,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'rotting_days',
    headerName: 'Rotting Days',
    width: 120,
    editable: true,
    value: data ? data.rotting_days : null,
    initialValue: 0,
    validate: Yup.number(),
    onAccordionForm: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const coursesMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 240,
    type: fieldType.LINK,
    to: '/employee-courses/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(50).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 800,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const skillLevelMeta = ({ data } = {}) => [
  {
    field: 'level',
    headerName: 'Level',
    width: 180,
    type: fieldType.TEXT,
    editable: true,
    value: data?.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    type: fieldType.TEXT,
    editable: true,
    value: data?.description,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const rolesMeta = (data) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    type: fieldType.LINK,
    to: '/employee-roles/',
    editable: true,
    value: data?.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    type: fieldType.TEXT,
    editable: true,
    value: data?.description,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'min_salary_pm',
    headerName: 'Min Salary Pm',
    width: 180,
    type: fieldType.NUMBER,
    editable: true,
    value: data?.min_salary_pm,
    initialValue: '',
    onForm: true,
    validate: Yup.number().required(),
  },
  {
    field: 'max_salary_pm',
    headerName: 'Max Salary Pm',
    width: 180,
    type: fieldType.NUMBER,
    editable: true,
    value: data?.max_salary_pm,
    initialValue: '',
    onForm: true,
    validate: Yup.number().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const employeesMeta = ({ data, queryClient } = {}) => [
  {
    field: 'person',
    headerName: 'Person',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-employee-person`,
    validate: Yup.mixed().required('person is a required field'),
    initialValue: data?.person
      ? {
        id: data?.person,
        label: data?.details?.person?.email,
      }
      : null,
    renderRow: (row) => ({
      id: row.id,
      label: `${row?.first_name || 'Unamed'} ${row?.last_name || ''} (${row?.email || ''
        })`,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-employee-person`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.persons,
      }),
    }),
  },
  {
    field: 'work_email',
    headerName: 'Work Email',
    width: 180,
    editable: true,
    value: data?.work_email,
    initialValue: '',
    onForm: true,
    validate: Yup.string().email().required(),
    to: '/employees/',
  },
  {
    field: 'work_phone',
    headerName: 'Work Phone',
    width: 180,
    type: fieldType.TEXT,
    editable: true,
    value: data?.work_phone,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(20),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeeStatusUrls.list,
    requestKey: `${data?.id}-employee-status`,
    validate: Yup.mixed(),
    initialValue: data?.status
      ? {
        id: data?.status,
        label: data?.details?.status?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row.id,
      label: row.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-employee-status`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employeeStatus,
      }),
    }),
  },
  {
    field: 'cv',
    headerName: 'CV',
    width: 180,
    type: fieldType.UPLOAD,
    editable: true,
    value: data?.cv,
    initialValue: '',
    onForm: true,
    validate: Yup.string().url(),
  },
  {
    field: 'next_of_kin_email',
    headerName: 'Next Of Kin Email',
    width: 180,
    type: fieldType.TEXT,
    editable: true,
    value: data?.next_of_kin_email,
    initialValue: '',
    onForm: true,
    validate: Yup.string().email(),
  },
  {
    field: 'next_of_kin_phone',
    headerName: 'Next Of Kin Phone',
    width: 180,
    type: fieldType.TEXT,
    editable: true,
    value: data?.next_of_kin_phone,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(20),
  },
  {
    field: 'charge_rate',
    headerName: 'Charge Rate',
    width: 180,
    type: fieldType.NUMBER,
    editable: true,
    value: data?.charge_rate,
    initialValue: 0,
    onForm: true,
    validate: Yup.string().nullable(),
    hideColumn: true,
  },
  {
    field: 'date_joined',
    headerName: 'Joined',
    width: 180,
    type: fieldType.DATE,
    editable: true,
    value: data?.date_joined
      ? formatToUTCDate(new Date(data.date_joined))
      : null,
    initialValue: undefined,
    onForm: true,
    onAccordionForm: true,
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'date_left',
    headerName: 'Date Left',
    width: 180,
    type: fieldType.DATE,
    editable: true,
    value: data?.date_left ? formatToUTCDate(new Date(data.date_left)) : null,
    initialValue: undefined,
    onForm: true,
    onAccordionForm: true,
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const vacancyMeta = ({ data, queryClient } = {}) => [
  {
    field: 'position_title',
    headerName: 'Position Title',
    width: 200,
    type: fieldType.LINK,
    editable: true,
    value: data?.position_title,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
    to: '/vacancies/',
  },
  {
    field: 'candidate_count',
    headerName: 'Candidates #',
    width: 200,
    editable: false,
    value: data?.candidate_count,
    initialValue: '',
    onForm: false,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date Applied',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onForm: true,
    options: vacanciesStatusChoices,
    initialValue: vacanciesStatusChoices[0].label,
    value: data ? data.status : null,
    validate: Yup.string().required(),
  },
  {
    field: 'recruitment_pipeline',
    headerName: 'Recruitment Pipeline',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: recruitmentPipelineUrls.list,
    requestKey: `${data?.id}-vacancies-recruitment-pipeline`,
    validate: Yup.mixed(),
    initialValue: data?.recruitment_pipeline
      ? {
        id: data?.recruitment_pipeline,
        label: data?.details?.recruitment_pipeline?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([
          `${data?.id}-vacancies-recruitment-pipeline`,
        ]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.recruitmentPipeline,
      }),
    }),
  },
  {
    field: 'recruit_for',
    headerName: 'Recruit For',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: companiesUrls.list,
    requestKey: `${data?.id}-vacancies-recruit-for`,
    validate: Yup.mixed(),
    initialValue: data?.recruit_for
      ? {
        id: data?.recruit_for,
        label: data?.details?.recruit_for?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-vacancies-recruit-for`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.companies,
      }),
    }),
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 200,
    editable: true,
    value: data?.location,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'min_pay',
    headerName: 'Min Pay',
    width: 100,
    type: fieldType.NUMBER,
    editable: true,
    value: data?.min_pay,
    initialValue: '',
    gridStyle: 2.6,
    onForm: true,
    validate: Yup.number().required(),
  },
  {
    field: 'max_pay',
    headerName: 'Max Pay',
    width: 100,
    type: fieldType.NUMBER,
    editable: true,
    value: data?.max_pay,
    initialValue: '',
    gridStyle: 2.6,
    onForm: true,
    validate: Yup.number().required(),
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 180,
    editable: true,
    value: data?.currency,
    initialValue: '',
    onForm: true,
    gridStyle: 2.6,
    validate: Yup.string(),
  },
  {
    field: 'travel_required',
    headerName: 'Travel Required',
    width: 180,
    type: fieldType.MULTILINE,
    editable: true,
    value: data?.travel_required,
    initialValue: '',
    onForm: true,
    hideColumn: true,
    validate: Yup.string().nullable(),
  },
  {
    field: 'hiring_manager',
    headerName: 'Hiring Manager',
    width: 300,
    editable: true,
    onForm: true,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-vacancies-hiring-manager`,
    validate: Yup.mixed(),
    initialValue: data?.hiring_manager
      ? {
        id: data?.hiring_manager,
        label: data?.details?.hiring_manager?.work_email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([
          `${data?.id}-vacancies-hiring-managere`,
        ]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'internal_responsible',
    headerName: 'Internal Responsible',
    width: 300,
    editable: true,
    onForm: true,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-vacancies-internal-responsible`,
    validate: Yup.mixed(),
    initialValue: data?.internal_responsible
      ? {
        id: data?.internal_responsible,
        label: data?.details?.internal_responsible?.work_email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([
          `${data?.id}-vacancies-internal-responsible`,
        ]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'job_level',
    headerName: 'Job Level',
    width: 300,
    editable: true,
    onForm: true,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: jobLevelsUrls.list,
    requestKey: `${data?.id}-vacancies-job-level`,
    validate: Yup.mixed(),
    initialValue: data?.job_level
      ? {
        id: data?.job_level,
        label: data?.details?.job_level?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-vacancies-job-level`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.jobLevels,
      }),
    }),
  },
  {
    field: 'educational_requirements',
    headerName: 'Educational Requirements',
    width: 180,
    type: fieldType.MULTILINE,
    editable: true,
    value: data?.educational_requirements,
    initialValue: '',
    onForm: true,
    gridStyle: 6,
    hideColumn: true,
    validate: Yup.string().nullable(),
  },
  {
    field: 'skills_required',
    headerName: 'Skills Required',
    width: 180,
    type: fieldType.MULTILINE,
    editable: true,
    value: data?.skills_required,
    initialValue: '',
    onForm: true,
    gridStyle: 6,
    hideColumn: true,
    validate: Yup.string().nullable(),
  },
  {
    field: 'functional_area',
    headerName: 'Functional Area',
    width: 180,
    type: fieldType.MULTILINE,
    editable: true,
    value: data?.functional_area,
    initialValue: '',
    onForm: true,
    gridStyle: 6,
    hideColumn: true,
    validate: Yup.string().nullable(),
  },
  {
    field: 'responsibilities',
    headerName: 'Responsibilities',
    width: 180,
    type: fieldType.MULTILINE,
    editable: true,
    value: data?.responsibilities,
    initialValue: '',
    onForm: true,
    gridStyle: 6,
    hideColumn: true,
    validate: Yup.string().nullable(),
  },
  {
    field: 'job_description',
    headerName: 'Job Description',
    width: 180,
    type: fieldType.MULTILINE,
    editable: true,
    value: data?.job_description,
    initialValue: '',
    onForm: true,
    validate: Yup.string().nullable(),
    gridStyle: 12,
    hideColumn: true,
  },
  {
    field: 'test',
    headerName: 'Test',
    width: 180,
    type: fieldType.MULTILINE,
    editable: true,
    value: data?.test,
    initialValue: '',
    onForm: true,
    hideColumn: true,
    onDetailCard: false,
    gridStyle: 12,
    validate: Yup.string().nullable(),
  },
  {
    field: 'reapply_duration_amount',
    headerName: 'Duration Amount',
    width: 180,
    editable: true,
    value: data?.reapply_duration_amount,
    initialValue: 3,
    onForm: true,
    gridStyle: 2.6,
    validate: Yup.number(),
    hideColumn: true,
  },
  {
    field: 'reapply_duration_unit',
    headerName: 'Duration Unit',
    type: fieldType.SELECT,
    editable: true,
    onForm: true,
    options: reapplyDurationUnitsChoices,
    initialValue: reapplyDurationUnitsChoices[0].label,
    value: data ? data.reapply_duration_unit : null,
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'workflow',
    headerName: 'BPA Workflow',
    width: 300,
    editable: true,
    onForm: true,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: workflowsUrls.list,
    requestKey: `${data?.id}-vacancies-bpa-workflow`,
    validate: Yup.mixed(),
    initialValue: data?.workflow
      ? {
        id: data.workflow,
        label: data?.details?.workflow?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
];

export const departmentEmployeeMeta = ({ queryClient, data } = {}) => [
  {
    field: 'employee',
    headerName: 'Employee',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-department-employee`,
    validate: Yup.mixed(),
    initialValue: data?.employee
      ? {
        id: data?.employee,
        label: data?.details?.employee?.work_email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-department-employee`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const departmentMeta = ({ queryClient, data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    type: fieldType.LINK,
    to: '/departments/',
    editable: true,
    value: data?.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    type: fieldType.TEXT,
    editable: true,
    value: data?.description,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'parent_department',
    headerName: 'Department',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: departmentsUrls.list,
    requestKey: `${data?.id}-parent-department`,
    validate: Yup.mixed(),
    initialValue: data?.parent_department
      ? {
        id: data?.parent_department,
        label: data?.details?.parent_department?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-parent-department`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.department,
      }),
    }),
  },
  {
    field: 'department_manager',
    headerName: 'Department Manager',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-department-manager-employee`,
    validate: Yup.mixed(),
    initialValue: data?.department_manager
      ? {
        id: data?.department_manager,
        label: data?.details?.department_manager?.work_email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([
          `${data?.id}-department-manager-employee`,
        ]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const skillMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    type: fieldType.LINK,
    to: '/employee-skills/',
    editable: true,
    value: data?.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    type: fieldType.TEXT,
    editable: true,
    value: data?.description,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const roleHistoryMeta = ({ queryClient, data } = {}) => [
  {
    field: 'start_date',
    headerName: 'Start Date',
    type: fieldType.DATE,
    editable: true,
    width: 200,
    initialValue: formatToUTCDate(new Date()),
    onForm: true,
    onAccordionForm: true,
    value: data?.start_date ? formatToUTCDate(new Date(data.start_date)) : null,
    validate: Yup.date()
      .default(() => new Date())
      .required(),
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    width: 180,
    type: fieldType.DATE,
    editable: true,
    value: data?.end_date ? formatToUTCDate(new Date(data.end_date)) : null,
    initialValue: formatToUTCDate(new Date()),
    onForm: true,
    onAccordionForm: true,
    validate: Yup.date()
      .default(() => new Date())
      .required(),
  },
  {
    field: 'employee',
    headerName: 'Employee',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-role-employee`,
    validate: Yup.mixed(),
    initialValue: data?.employee
      ? {
        id: data?.employee,
        label: data?.details?.employee?.work_email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-role-employee`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'supervisor_emp',
    headerName: 'Supervisor Employee',
    width: 300,
    editable: true,
    onForm: true,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-role-supervisor_emp`,
    validate: Yup.mixed(),
    initialValue: data?.supervisor_emp
      ? {
        id: data?.supervisor_emp,
        label: data?.details?.supervisor_emp?.work_email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-role-supervisor_emp`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const employeeDepartmentMeta = ({ queryClient, data } = {}) => [
  {
    field: 'start_date',
    headerName: 'Start Date',
    width: 180,
    type: fieldType.DATE,
    editable: true,
    value: data?.start_date ? formatToUTCDate(new Date(data.start_date)) : null,
    initialValue: formatToUTCDate(new Date()),
    onForm: true,
    onAccordionForm: true,
    validate: Yup.date()
      .default(() => new Date())
      .required(),
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    width: 180,
    type: fieldType.DATE,
    editable: true,
    value: data?.end_date ? formatToUTCDate(new Date(data.end_date)) : null,
    initialValue: formatToUTCDate(new Date()),
    onForm: true,
    onAccordionForm: true,
    validate: Yup.date()
      .default(() => new Date())
      .required(),
  },
  {
    field: 'department',
    headerName: 'Department',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: departmentsUrls.list,
    requestKey: `${data?.id}-employee-department`,
    validate: Yup.mixed(),
    initialValue: data?.department
      ? {
        id: data?.department,
        label: data?.details?.department?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-employee-department`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.department,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const employeeSkillMeta = ({ queryClient, data } = {}) => [
  {
    field: 'date_acquired',
    headerName: 'Date Acquired',
    width: 180,
    type: fieldType.DATE,
    editable: true,
    value: data?.date_acquired
      ? formatToUTCDate(new Date(data.date_acquired))
      : null,
    initialValue: formatToUTCDate(new Date()),
    onForm: true,
    onAccordionForm: true,
    validate: Yup.date()
      .default(() => new Date())
      .required(),
  },
  {
    field: 'employee',
    headerName: 'Employee',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-skill-employee`,
    validate: Yup.mixed().required('employee is a required field'),
    initialValue: data?.employee
      ? {
        id: data?.employee,
        label: data?.details?.employee?.work_email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-skill-employee`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'skill_level',
    headerName: 'Skill Level',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: skillLevelUrls.list,
    requestKey: `${data?.id}-skill-skill-level`,
    validate: Yup.mixed().required('skill_level is a required field'),
    initialValue: data?.skill_level
      ? {
        id: data?.skill_level,
        label: data?.details?.skill_level?.level,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.level,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-skill-skill-level`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.skillLevel,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const candidateMeta = ({
  data,
  queryClient,
  firstNameGridStyle,
  lastNameGridStyle,
  statusGridStyle,
  personalEmailGridStyle,
  createdAtGridStyle,
  personalMobileGridStyle,
  dobGridStyle,
  cityGridStyle,
  countryGridStyle,
  address1GridStyle,
  address2GridStyle,
  visaRequiredGridStyle,
  currentlyEmployedGridStyle,
  currentEmployerGridStyle,
  cvGridStyle,
  yearsOfExperienceGridStyle,
  coverLetterGridStyle,
  linkedinGridStyle,
  utmCampaignGridStyle,
  utmMediumGridStyle,
  utmSourceGridStyle,
  utmContentGridStyle,
  utmTermGridStyle,
} = {}) => [
    {
      field: 'first_name',
      headerName: 'First Name',
      type: fieldType.LINK,
      to: '/candidates/',
      width: 120,
      editable: true,
      value: data?.first_name,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().required('This field is required'),
      gridStyle: firstNameGridStyle ?? undefined,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      type: fieldType.TEXT,
      width: 120,
      editable: true,
      value: data?.last_name,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().required('This field is required'),
      gridStyle: lastNameGridStyle ?? undefined,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      editable: true,
      onDetailCard: true,
      onAccordionForm: true,
      type: fieldType.AUTOCOMPLETE_V2,
      gridStyle: statusGridStyle ?? undefined,
      fetchUrl: recruitmentPipelineStagesUrls.list,
      requestKey: `${data?.id}-candidate-statuses`,
      validate: Yup.string().nullable(),
      initialValue: data?.details?.status
        ? {
          id: data.status,
          label: `${data.details.status?.order} ${data.details.status?.name}`,
        }
        : null,
      urlParams: `${data?.details?.vacancy?.recruitment_pipeline
        ? `&recruitment_pipeline=${data?.details?.vacancy?.recruitment_pipeline}`
        : ''
        }&ordering=order`,
      renderRow: (row) => ({
        id: row?.id,
        label: `${row?.order} ${row?.name}`,
      }),
      getDropdownForm: () => ({
        refetch: () =>
          queryClient.invalidateQueries([`${data?.id}-candidate-statuses`]),
        Form: getDropdownForm({
          microservice: microservices.HR.name,
          model: microservices.HR.models.recruitmentPipelineStage,
        }),
      }),
    },
    {
      field: 'personal_email',
      headerName: 'Email',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.personal_email,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().email().required('This field is required'),
      gridStyle: personalEmailGridStyle ?? undefined,
    },
    {
      field: 'created_at',
      headerName: 'Date of application',
      width: 180,
      value: data?.created_at
        ? `${formatToUTCDateTime(new Date(data.created_at))}`
        : null,
      onForm: false,
      editable: false,
      type: fieldType.DATE_TIME,
      validate: Yup.string().required('This field is required'),
      gridStyle: createdAtGridStyle ?? undefined,
    },
    {
      field: 'personal_mobile',
      headerName: 'Phone',
      width: 180,
      editable: true,
      value: data?.personal_mobile,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().nullable(),
      gridStyle: personalMobileGridStyle ?? undefined,
    },
    {
      field: 'dob',
      headerName: 'Date of Birth',
      type: fieldType.DATE,
      width: 180,
      editable: true,
      value: data?.dob ? formatToUTCDate(new Date(data.dob)) : null,
      initialValue: formatToUTCDate(new Date()),
      onAccordionForm: true,
      onForm: true,
      validate: Yup.date()
        .default(() => formatToUTCDate(new Date()))
        .nullable(),
      hideColumn: true,
      gridStyle: dobGridStyle ?? undefined,
    },
    {
      field: 'city',
      headerName: 'City',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.city,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().required('This field is required'),
      gridStyle: cityGridStyle ?? undefined,
    },
    {
      field: 'country',
      headerName: 'Country',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.country,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().required('This field is required'),
      gridStyle: countryGridStyle ?? undefined,
    },
    {
      field: 'address1',
      headerName: 'Address1',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.address1,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().nullable(),
      hideColumn: true,
      gridStyle: address1GridStyle ?? undefined,
    },
    {
      field: 'address2',
      headerName: 'Address2',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.address2,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().nullable(),
      gridStyle: address2GridStyle ?? undefined,
    },
    {
      field: 'visa_required',
      headerName: 'Visa Required',
      type: fieldType.BOOLEAN,
      width: 180,
      editable: true,
      value: data?.visa_required,
      initialValue: false,
      hideColumn: true,
      onAccordionForm: true,
      validate: Yup.bool(),
      gridStyle: visaRequiredGridStyle ?? undefined,
    },
    {
      field: 'currently_employed',
      headerName: 'Currently Employed',
      type: fieldType.BOOLEAN,
      width: 180,
      editable: true,
      value: data?.currently_employed,
      initialValue: false,
      hideColumn: true,
      onAccordionForm: true,
      validate: Yup.bool().nullable(),
      gridStyle: currentlyEmployedGridStyle ?? undefined,
    },
    {
      field: 'current_employer',
      headerName: 'Current Employer',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.current_employer,
      initialValue: '',
      hideColumn: true,
      onAccordionForm: true,
      validate: Yup.string().nullable(),
      gridStyle: currentEmployerGridStyle ?? undefined,
    },
    {
      field: 'cv',
      headerName: 'CV',
      type: fieldType.UPLOAD,
      width: 180,
      editable: true,
      value: data?.cv,
      initialValue: '',
      hideColumn: true,
      onAccordionForm: true,
      validate: Yup.string().required('This field is required'),
      gridStyle: cvGridStyle ?? undefined,
    },
    {
      field: 'years_of_experience',
      headerName: 'Years Of Experience',
      type: fieldType.TEXT,
      width: 140,
      editable: true,
      value: data?.years_of_experience,
      initialValue: 0,
      onForm: true,
      onAccordionForm: true,
      hideColumn: true,
      validate: Yup.string().nullable(),
      gridStyle: yearsOfExperienceGridStyle ?? undefined,
    },
    {
      field: 'cover_letter',
      headerName: 'Cover Letter',
      type: fieldType.MULTILINE,
      width: 180,
      editable: true,
      value: data?.cover_letter,
      initialValue: '',
      hideColumn: true,
      onForm: true,
      onAccordionForm: true,
      gridStyle: coverLetterGridStyle ?? 12,
      validate: Yup.string().min(250).required('This field is required'),
    },
    {
      field: 'linkedin',
      headerName: 'Linkedin Profile Url',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.linkedin,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().url().nullable(),
      gridStyle: linkedinGridStyle ?? undefined,
    },
    {
      field: 'utm_campaign',
      headerName: 'UTM Campaign',
      type: fieldType.TEXT,
      width: 120,
      editable: true,
      value: data?.utm_campaign,
      initialValue: '',
      validate: Yup.string(),
      gridStyle: utmCampaignGridStyle ?? undefined,
    },
    {
      field: 'utm_medium',
      headerName: 'UTM Medium',
      type: fieldType.TEXT,
      width: 120,
      editable: true,
      value: data?.utm_medium,
      initialValue: '',
      validate: Yup.string(),
      gridStyle: utmMediumGridStyle ?? undefined,
    },
    {
      field: 'utm_source',
      headerName: 'UTM Source',
      type: fieldType.TEXT,
      width: 120,
      editable: true,
      value: data?.utm_source,
      initialValue: '',
      validate: Yup.string(),
      gridStyle: utmSourceGridStyle ?? undefined,
    },
    {
      field: 'utm_content',
      headerName: 'UTM Content',
      type: fieldType.TEXT,
      width: 120,
      editable: true,
      value: data?.utm_content,
      initialValue: '',
      validate: Yup.string(),
      gridStyle: utmContentGridStyle ?? undefined,
    },
    {
      field: 'utm_term',
      headerName: 'UTM Term',
      type: fieldType.TEXT,
      width: 120,
      editable: true,
      value: data?.utm_term,
      initialValue: '',
      validate: Yup.string(),
      gridStyle: utmTermGridStyle ?? undefined,
    },
  ];

export const referencesMeta = (queryClient, data) => [
  {
    field: 'first_name',
    headerName: 'First Name',
    type: fieldType.TEXT,
    width: 180,
    editable: true,
    value: data?.first_name,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    type: fieldType.TEXT,
    width: 180,
    editable: true,
    value: data?.last_name,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    type: fieldType.NUMBER,
    width: 180,
    editable: true,
    value: data?.phone,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.number().required(),
  },
  {
    field: 'email',
    headerName: 'Email',
    type: fieldType.TEXT,
    width: 180,
    editable: true,
    value: data?.email,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().email().required(),
  },
  {
    field: 'type',
    headerName: 'Types',
    type: fieldType.SELECT,
    width: 180,
    editable: true,
    value: data?.type,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'reference_result',
    headerName: 'Reference Result',
    type: fieldType.TEXT,
    width: 180,
    editable: true,
    value: data?.reference_result,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'checked_by',
    headerName: 'Checked By',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-reference-checked-by`,
    validate: Yup.mixed(),
    initialValue: data?.checked_by
      ? {
        id: data?.checked_by,
        label: data?.details?.checked_by?.work_email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-reference-checked-by`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const salaryHistoryMeta = ({ data } = {}) => [
  {
    field: 'salary_pm',
    headerName: 'Salary Per Month',
    width: 180,
    editable: true,
    value: data?.salary_pm,
    initialValue: 0,
    onAccordionForm: true,
    validate: Yup.number().required(),
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    width: 180,
    type: fieldType.DATE,
    editable: true,
    value: data?.start_date ? formatToUTCDate(new Date(data.start_date)) : null,
    initialValue: formatToUTCDate(new Date()),
    onAccordionForm: true,
    validate: Yup.date()
      .default(() => new Date())
      .required(),
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    width: 180,
    type: fieldType.DATE,
    editable: true,
    value: data?.end_date ? formatToUTCDate(new Date(data.end_date)) : null,
    initialValue: formatToUTCDate(new Date()),
    onAccordionForm: true,
    validate: Yup.date()
      .default(() => new Date())
      .required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const trainingHistoryMeta = ({ data } = {}) => [
  {
    field: 'result',
    headerName: 'Result',
    width: 680,
    type: fieldType.TEXT,
    editable: true,
    value: data?.result,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },

  {
    field: 'date_taken',
    headerName: 'Date Taken',
    width: 180,
    type: fieldType.DATE,
    editable: true,
    value: data?.date_taken ? formatToUTCDate(new Date(data.date_taken)) : null,
    initialValue: formatToUTCDate(new Date()),
    onAccordionForm: true,
    validate: Yup.date(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const holidayEntitlementMeta = ({ data } = {}) => [
  {
    field: 'max_holidays_pa',
    headerName: 'Max Holidays Per Year',
    width: 200,
    editable: true,
    value: data?.max_holidays_pa,
    initialValue: 0,
    onAccordionForm: true,
    validate: Yup.number().required(),
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    width: 150,
    type: fieldType.DATE,
    editable: true,
    value: data?.start_date ? formatToUTCDate(new Date(data.start_date)) : null,
    initialValue: formatToUTCDate(new Date()),
    onAccordionForm: true,
    validate: Yup.date()
      .default(() => new Date())
      .required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const dayOffMeta = ({ data, queryClient, initial_approved_by } = {}) => [
  {
    field: 'notes',
    headerName: 'Notes',
    width: 240,
    type: fieldType.TEXT,
    editable: true,
    value: data?.notes,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string(),
  },
  {
    field: 'days_off_from',
    headerName: 'DaysOff From',
    type: fieldType.DATE_TIME,
    width: 180,
    editable: true,
    value: data?.days_off_from ? new Date(data?.days_off_from) : null,
    initialValue: new Date(),
    onAccordionForm: true,
    validate: Yup.date().required(),
  },
  {
    field: 'days_requested',
    headerName: 'Days Requested',
    width: 180,
    editable: true,
    value: data?.days_requested,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.number().required(),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onAccordionForm: true,
    options: dayoffStatusType,
    initialValue: dayoffStatusType[0].label,
    value: data ? data.status : null,
    validate: Yup.string().required(),
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: dayOffTypesUrls.list,
    requestKey: `${data?.id}-employee-day-off`,
    validate: Yup.mixed(),
    initialValue: data?.type
      ? {
        id: data?.type,
        label: data?.details?.type?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'approved_by',
    headerName: 'Approved By',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-day-off-approved-by`,
    validate: Yup.mixed(),
    initialValue: data?.approved_by
      ? {
        id: data?.approved_by,
        label: data?.details?.approved_by?.work_email,
      }
      : initial_approved_by
        ? initial_approved_by
        : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-day-off-approved-by`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const performanceReviewMeta = ({ data, queryClient } = {}) => [
  {
    field: 'date_of_review',
    headerName: 'Date Of Review',
    width: 140,
    type: fieldType.DATE,
    editable: true,
    value: data?.date_of_review
      ? formatToUTCDate(new Date(data.date_of_review))
      : null,
    initialValue: formatToUTCDate(new Date()),
    onAccordionForm: true,
    validate: Yup.date(),
  },
  {
    field: 'comments_by_reviewer',
    headerName: 'Comments By Reviewer',
    width: 320,
    type: fieldType.TEXT,
    editable: true,
    value: data?.comments_by_reviewer,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string(),
  },
  {
    field: 'comments_by_employee',
    headerName: 'Comments By Employee',
    width: 320,
    type: fieldType.TEXT,
    editable: true,
    value: data?.comments_by_employee,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string(),
  },
  {
    field: 'review_form',
    headerName: 'Review Form',
    width: 180,
    type: fieldType.TEXT,
    editable: true,
    value: data?.review_form,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().url(),
  },
  {
    field: 'reviewed_by',
    headerName: 'Reviewed By',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-performance-review-reviewed-by`,
    validate: Yup.mixed(),
    initialValue: data?.reviewed_by
      ? {
        id: data?.reviewed_by,
        label: data?.details?.reviewed_by?.work_email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([
          `${data?.id}-performance-review-reviewed-by`,
        ]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const jobLevelMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 890,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const dayOffTypesMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 890,
    type: fieldType.MULTILINE,
    validate: Yup.string().nullable(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const candidateAdminMeta = ({
  data,
  queryClient,
  disableStatusEdit,
  recruitmentPipeline,
} = {}) => [
    {
      field: 'first_name',
      headerName: 'First Name',
      type: fieldType.LINK,
      to: '/candidates/',
      width: 120,
      editable: true,
      value: data?.first_name,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().required(),
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      type: fieldType.TEXT,
      width: 120,
      editable: true,
      value: data?.last_name,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().required(),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 300,
      onDetailCard: true,
      onAccordionForm: true,
      editable: !disableStatusEdit,
      type: fieldType.AUTOCOMPLETE_V2,
      fetchUrl: recruitmentPipelineStagesUrls.list,
      requestKey: `${data?.id}-candidate-statuses`,
      validate: Yup.mixed(),
      initialValue: data?.details?.status
        ? {
          id: data.status,
          label: `${data.details.status?.order} ${data.details.status?.name}`,
        }
        : null,
      urlParams: `${recruitmentPipeline ? `&recruitment_pipeline=${recruitmentPipeline}` : ''
        }&ordering=order`,
      renderRow: (row) => ({
        id: row?.id,
        label: `${row?.order} ${row?.name}`,
      }),
      getDropdownForm: () => ({
        refetch: () =>
          queryClient.invalidateQueries([`${data?.id}-candidate-statuses`]),
        Form: getDropdownForm({
          microservice: microservices.HR.name,
          model: microservices.HR.models.recruitmentPipelineStage,
        }),
      }),
    },
    {
      field: 'personal_email',
      headerName: 'Email',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.personal_email,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().email().required(),
    },
    {
      field: 'created_at',
      headerName: 'Date Applied',
      width: 180,
      value: data?.created_at
        ? `${formatToUTCDateTime(new Date(data.created_at))}`
        : null,
      onForm: false,
      editable: false,
      type: fieldType.DATE_TIME,
      validate: Yup.string(),
    },
    {
      field: 'personal_mobile',
      headerName: 'Phone',
      width: 180,
      editable: true,
      value: data?.personal_mobile,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().nullable(),
    },
    {
      field: 'dob',
      headerName: 'Date of Birth',
      type: fieldType.DATE,
      width: 180,
      editable: true,
      value: data?.dob ? formatToUTCDate(new Date(data.dob)) : null,
      initialValue: formatToUTCDate(new Date()),
      onAccordionForm: true,
      onForm: true,
      validate: Yup.date()
        .default(() => new Date())
        .required(),
      hideColumn: true,
    },
    {
      field: 'city',
      headerName: 'City',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.city,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().required(),
    },
    {
      field: 'country',
      headerName: 'Country',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.country,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().required(),
    },
    {
      field: '_tags',
      headerName: 'Tags',
      width: 300,
      editable: true,
      value: data?._tags,
      initialValue: '',
      onForm: true,
      validate: Yup.string(),
    },
    {
      field: 'address1',
      headerName: 'Address1',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.address1,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string(),
      hideColumn: true,
    },
    {
      field: 'address2',
      headerName: 'Address2',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.address2,
      initialValue: '',
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().nullable(),
    },
    {
      field: 'visa_required',
      headerName: 'Visa Required',
      type: fieldType.BOOLEAN,
      width: 180,
      editable: true,
      value: data?.visa_required,
      initialValue: false,
      hideColumn: true,
      onAccordionForm: true,
      validate: Yup.bool(),
    },
    {
      field: 'currently_employed',
      headerName: 'Currently Employed',
      type: fieldType.BOOLEAN,
      width: 180,
      editable: true,
      value: data?.currently_employed,
      initialValue: false,
      hideColumn: true,
      onAccordionForm: true,
      validate: Yup.bool(),
    },
    {
      field: 'current_employer',
      headerName: 'Current Employer',
      type: fieldType.TEXT,
      width: 180,
      editable: true,
      value: data?.current_employer,
      initialValue: '',
      hideColumn: true,
      onAccordionForm: true,
      validate: Yup.string().nullable(),
    },
    {
      field: 'cv',
      headerName: 'CV',
      type: fieldType.UPLOAD,
      onAccordionForm: true,
      width: 1075,
      editable: true,
      hideColumn: true,
      value: data ? data.cv : null,
      initialValue: '',
      validate: Yup.mixed(),
    },
    {
      field: 'years_of_experience',
      headerName: 'Years Of Experience',
      type: fieldType.TEXT,
      width: 140,
      editable: true,
      value: data?.years_of_experience,
      initialValue: 0,
      onForm: true,
      onAccordionForm: true,
      hideColumn: true,
      validate: Yup.string().nullable(),
    },
    {
      field: 'cover_letter',
      headerName: 'Cover Letter',
      type: fieldType.MULTILINE,
      width: 180,
      editable: true,
      value: data?.cover_letter,
      initialValue: '',
      hideColumn: true,
      onForm: true,
      onAccordionForm: true,
      validate: Yup.string().min(250),
    },
  ];

export const candidateAdminListMeta = ({ data, queryClient } = {}) => [
  {
    field: 'first_name',
    headerName: 'First Name',
    type: fieldType.LINK,
    to: '/candidates/',
    width: 120,
    editable: true,
    value: data?.first_name,
    initialValue: '',
    onForm: true,
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    type: fieldType.TEXT,
    width: 120,
    editable: true,
    value: data?.last_name,
    initialValue: '',
    onForm: true,
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    onDetailCard: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: recruitmentPipelineStagesUrls.list,
    requestKey: `${data?.id}-candidate-admin-list-statuses`,
    validate: Yup.mixed(),
    initialValue: data?.details?.status
      ? {
        id: data.status,
        label: `${data.details.status?.order} ${data.details.status?.name}`,
      }
      : null,
    urlParams: `${data?.details?.vacancy?.recruitment_pipeline
      ? `&recruitment_pipeline=${data?.details?.vacancy?.recruitment_pipeline}`
      : ''
      }&ordering=order`,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.order} ${row?.name}`,
    }),
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([
          `${data?.id}-candidate-admin-list-statuses`,
        ]),
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.recruitmentPipelineStage,
      }),
    }),
  },
  {
    field: 'personal_email',
    headerName: 'Email',
    type: fieldType.TEXT,
    width: 180,
    editable: true,
    value: data?.personal_email,
    initialValue: '',
    onForm: true,
    onAccordionForm: true,
    validate: Yup.string().email(),
  },
  {
    field: 'created_at',
    headerName: 'Date Applied',
    width: 180,
    value: data?.created_at
      ? `${formatToUTCDateTime(new Date(data.created_at))}`
      : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    validate: Yup.string(),
  },
];

export const employeeStatusMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    editable: true,
    value: data?.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    type: fieldType.TEXT,
    editable: true,
    value: data?.description,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
