import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { selectProfile } from '@redux/profileSlice';
import DetailCard from '@components/core/DetailCard';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { communicationUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { emailMeta } from '@config/meta/communication';
import {
  attachmentRowsMap,
  notificationEmailAddressRowsMap,
  notificationLabelRowMap,
} from '@config/handleRows/communication';
import { NotificationLabelForm } from '@config/forms/communication';
import AuthWrapper from '@components/Communication/AuthWrapper';

const {
  notificationsUrls,
  notificationAttachmentsUrls,
  notificationEmailAddressesUrls,
  notificationLabelsUrls,
} = communicationUrls;

function ImapAccountDetails({
  performingAction,
  setPerformingAction,
  labels,
  editMode,
}) {
  const { id: recordId, history } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState();
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => emailMeta({ data }), []);

  return (
    <>
      <Box>
        {recordData ? (
          <Box>
            <DetailPageHeader
              items={[
                {
                  to: `/inbox-emails/${recordData.imap_account}`,
                  text: 'Inbox',
                },
                { text: recordData && recordData.subject },
              ]}
            ></DetailPageHeader>
          </Box>
        ) : null}

        <DetailCard
          urls={notificationsUrls}
          recordId={recordId}
          getSchema={getSchema}
          setData={setRecordData}
          urlParams={`${history ? '?history=true' : ''}`}
        />

        <Box sx={{ mt: 5 }}>
          <DetailAccordionView
            microservice={microservices.NOTIFICATION.name}
            model={microservices.NOTIFICATION.models.notificationEmailAddresses}
            performingAction={performingAction}
            setPerformingAction={setPerformingAction}
            columnKey="notificationEmailAddressCommunication"
            label="Recipients"
            prefix="014"
            labels={labels}
            editMode={editMode}
            pageHeaderFormHeaderText="Recipients"
            addNewForm={null}
            urls={notificationEmailAddressesUrls}
            hideActions
            showBulkDeleteButton={false}
            handleRowMap={notificationEmailAddressRowsMap}
            urlParams={`&notification=${recordId}&ordering=-method${
              history ? '&history=true' : ''
            }`}
          />

          <DetailAccordionView
            microservice={microservices.NOTIFICATION.name}
            model={microservices.NOTIFICATION.models.attachments}
            performingAction={performingAction}
            setPerformingAction={setPerformingAction}
            columnKey="emailAttachmentCommunication"
            label="Attachments"
            prefix="0ne"
            labels={labels}
            editMode={editMode}
            pageHeaderFormHeaderText="Attachments"
            addNewForm={null}
            urls={notificationAttachmentsUrls}
            handleRowMap={attachmentRowsMap}
            hideActions
            showBulkDeleteButton={false}
            urlParams={`&notification_or=${recordId}&email_template_or=${
              (recordData && recordData.template) || ''
            }${history ? '&history=true' : ''}`}
          />

          <DetailAccordionView
            microservice={microservices.NOTIFICATION.name}
            model={microservices.NOTIFICATION.models.label}
            performingAction={performingAction}
            setPerformingAction={setPerformingAction}
            columnKey="notificationLabelCommunication"
            label="Labels"
            prefix="0pw"
            labels={labels}
            editMode={editMode}
            urls={notificationLabelsUrls}
            addNewForm={
              history
                ? null
                : {
                    getFields: ({ setFieldValue }) =>
                      NotificationLabelForm({
                        setFieldValue,
                      }),
                    handleSubmit: async (
                      values,
                      setSubmitting,
                      resetForm,
                      setAddNewForm,
                      refetch,
                      setError
                    ) => {
                      try {
                        const { label, ...rest } = values;

                        const response = await createRecord({
                          values: {
                            ...rest,
                            notification: recordId,
                            label: label?.id,
                          },
                          url: notificationLabelsUrls.list(),
                          token: user.token,
                          actAs: user?.actAs,
                          encryptionToken: user.encryptionToken,
                        });
                        resetForm();
                        setAddNewForm(false);
                        // notify(`Label Added!`, {
                        //   type: 'SUCCESS',
                        // });
                        refetch();
                        return response.data.id;
                      } catch (err) {
                        console.log(err);
                        if (
                          err.response &&
                          err.response.status === 400 &&
                          err.response.data
                        ) {
                          setError(err.response.data);
                        } else {
                          notify(`There was an error, please try again.`, {
                            type: 'ERROR',
                          });
                        }
                      } finally {
                        setSubmitting(false);
                      }
                    },
                  }
            }
            handleRowMap={notificationLabelRowMap}
            urlParams={`&notification=${recordId}${
              history ? '&history=true' : ''
            }`}
          />
        </Box>
      </Box>
    </>
  );
}

export default function AuthenticatedImapAccountDetails(props) {
  return (
    <AuthWrapper>
      <ImapAccountDetails {...props} />
    </AuthWrapper>
  );
}
