import * as React from 'react';
import TextField from '@mui/material/TextField';
import MuiAutocomplete from '@mui/material/Autocomplete';

export default function Autocomplete({
  label,
  options = [],
  isLoading,
  value,
  setValue = (v) => v,
  inputValue = '',
  setInputValue = '',
  getOptionLabel = (o) => o.label,
  error,
  helperText,
  customInput,
  ...rest
}) {
  const onValueChange = (e, newValue) => {
    setValue(newValue);
  };
  const onInputChange = (e, newInputValue) => setInputValue(newInputValue);
  const styles = customInput
    ? {
        display: 'inline-block',
        '& input': {
          width: 150,
          bgcolor: 'background.paper',
          color: (theme) =>
            theme.palette.getContrastText(theme.palette.background.paper),
        },
      }
    : { mb: 0 };

  return (
    <MuiAutocomplete
      {...rest}
      value={value}
      size='small'
      fullWidth
      disableClearable
      clearOnEscape
      loading={isLoading}
      onChange={onValueChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      options={options || []}
      includeInputInList
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(k, v) => k.id === v.id && k.label === v.label}
      sx={styles}
      renderInput={(params) => {
        const { className, ...rest } = params?.InputProps || {};

        return customInput ? (
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            ref={params.InputProps.ref}
          >
            <input
              type='text'
              style={{
                padding: '10px',
                borderRadius: '6px',
                border: 0,
                boxShadow: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.1)',
              }}
              autoComplete='off'
              className={`act-as-input ${className}`}
              {...rest}
            />
          </div>
        ) : (
          <TextField
            {...params}
            error={!!error}
            helperText={helperText}
            fullWidth
            sx={{ mb: 0 }}
            label={label}
            autoComplete='off'
          />
        );
      }}
    />
  );
}
