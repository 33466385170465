import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ecommerceUrls, crmUrls, inventoryUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordianArrayView from '@components/DetailAccordionArrayView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import ecommerceHandleRows from '@config/handleRows/ecommerce';
import { CartItemForm, PaymentForm } from '@config/forms/ecommerce';
import { cartMeta } from '@config/meta/ecommerce';
import DetailPageHeader from '@components/DetailPageHeader';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  paymentStatusesUrls,
  cartsUrls,
  deliveryStatusesUrls,
  paymentProvidersUrls,
  paymentMethodsUrls,
  cartItemsUrls,
  addressesUrls,
  discountCodesUrls,
  paymentsUrls,
} = ecommerceUrls;
const { personsUrls } = crmUrls;
const { productVariantsUrls } = inventoryUrls;
const { cartItemRowMap, paymentRowMap } = ecommerceHandleRows;

export default function DeliveryStatusDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [addressTerm, setAddressTerm] = useState('');
  const [deliveryStatusTerm, setDeliveryStatusTerm] = useState('');
  const [customerTerm, setCustomerTerm] = useState('');
  const [paymentStatusTerm, setPaymentStatusTerm] = useState('');
  const [discountCodeTerm, setDiscountCodeTerm] = useState('');
  const [productVariantTerm, setProductVariantTerm] = useState('');
  const [paymentProviderTerm, setPaymentProviderTerm] = useState('');
  const [paymentMethodTerm, setPaymentMethodTerm] = useState('');
  const [cartTerm, setCartTerm] = useState('');
  const [notify] = useToast();

  const { data: discountCodes, isFetching: fetchingDiscountCodes } =
    useAutocomplete({
      key: 'discountCodes',
      getUrl: discountCodesUrls.list,
      inputValue: discountCodeTerm,
    });

  const { data: customers, isFetching: fetchingCustomers } = useAutocomplete({
    key: 'customers',
    getUrl: personsUrls.list,
    inputValue: customerTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.first_name,
      };
    },
  });

  const {
    data: deliveryStatuses,
    isFetching: fetchingDeliveryStatuses,
    refetch: refetchDeliveryStatuse,
  } = useAutocomplete({
    key: 'deliveryStatuses',
    getUrl: deliveryStatusesUrls.list,
    inputValue: deliveryStatusTerm,
  });

  const {
    data: paymentStatuses,
    isFetching: fetchingPaymentStatuses,
    refetch: refetchPaymentStatus,
  } = useAutocomplete({
    key: 'paymentStatuses',
    getUrl: paymentStatusesUrls.list,
    inputValue: paymentStatusTerm,
  });

  const {
    data: addresses,
    isFetching: fetchingAddresses,
    refetch: refetchBilling,
  } = useAutocomplete({
    key: 'addresses',
    getUrl: addressesUrls.list,
    inputValue: addressTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.address1,
      };
    },
  });

  const {
    data: productVariants,
    isFetching: fetchingProductVariants,
    refetch: refetchProductVariant,
  } = useAutocomplete({
    key: 'productVariants',
    getUrl: productVariantsUrls.list,
    inputValue: productVariantTerm,
  });

  const {
    data: carts,
    isFetching: fetchingCarts,
    refetch: refetchCart,
  } = useAutocomplete({
    key: 'carts',
    getUrl: cartsUrls.list,
    inputValue: cartTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.customer && r.details && r.customer_details
            ? r.customer_details.first_name
            : r.customer,
      };
    },
  });

  const {
    data: paymentProviders,
    isFetching: fetchingPaymentProviders,
    refetch: refetchPaymentProvider,
  } = useAutocomplete({
    key: 'paymentProviders',
    getUrl: paymentProvidersUrls.list,
    inputValue: paymentProviderTerm,
  });

  const {
    data: paymentMethods,
    isFetching: fetchingPaymentMethods,
    refetch: refetchPaymentMethod,
  } = useAutocomplete({
    key: 'paymentMethods',
    getUrl: paymentMethodsUrls.list,
    inputValue: paymentMethodTerm,
  });

  const paymentsColOptions = React.useMemo(() => {
    return {
      carts,
      fetchingCarts,
      setCartTerm,
      addresses,
      fetchingAddresses,
      setAddressTerm,
      paymentProviders,
      fetchingPaymentProviders,
      setPaymentProviderTerm,
      paymentMethods,
      fetchingPaymentMethods,
      setPaymentMethodTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
    };
  }, [
    carts,
    fetchingCarts,
    setCartTerm,
    addresses,
    fetchingAddresses,
    setAddressTerm,
    paymentProviders,
    fetchingPaymentProviders,
    setPaymentProviderTerm,
    paymentMethods,
    fetchingPaymentMethods,
    setPaymentMethodTerm,
    paymentStatuses,
    fetchingPaymentStatuses,
    setPaymentStatusTerm,
  ]);

  const cartItemsColOptions = React.useMemo(() => {
    return {
      productVariants,
      fetchingProductVariants,
      setProductVariantTerm,
      carts,
      fetchingCarts,
      setCartTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
    };
  }, [
    productVariants,
    fetchingProductVariants,
    setProductVariantTerm,
    carts,
    fetchingCarts,
    setCartTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
  ]);

  const accordData = {
    cartItems: {
      columnKey: 'cartItemEcommerce',
      columnOptions: cartItemsColOptions,
      microservice: 'E-Commerce',
      model: 'CartItem',
      label: 'Cart Items',
      prefix: '0sa',
      urls: cartItemsUrls,
      addNewForm: {
        getFields: () =>
          CartItemForm({
            productVariants,
            fetchingProductVariants,
            setProductVariantTerm,
            carts,
            fetchingCarts,
            setCartTerm,
            deliveryStatuses,
            fetchingDeliveryStatuses,
            setDeliveryStatusTerm,
            refetchDeliveryStatuse,
            refetchCart,
            refetchProductVariant,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                cart: recordId,
              },
              url: cartItemsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Cart Item Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: cartItemRowMap,
      urlParams: `&cart=${recordId}`,
    },
    payments: {
      columnKey: 'paymentEcommerce',
      columnOptions: paymentsColOptions,
      microservice: 'E-Commerce',
      model: 'Payment',
      label: 'Payments',
      prefix: '0iy',
      urls: paymentsUrls,
      addNewForm: {
        getFields: () =>
          PaymentForm({
            carts,
            fetchingCarts,
            setCartTerm,
            addresses,
            fetchingAddresses,
            setAddressTerm,
            paymentProviders,
            fetchingPaymentProviders,
            setPaymentProviderTerm,
            paymentMethods,
            fetchingPaymentMethods,
            setPaymentMethodTerm,
            paymentStatuses,
            fetchingPaymentStatuses,
            setPaymentStatusTerm,
            refetchCart,
            refetchBilling,
            refetchPaymentProvider,
            refetchPaymentMethod,
            refetchPaymentStatus,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                cart: recordId,
              },
              url: paymentsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Payment Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: paymentRowMap,
      urlParams: `&cart=${recordId}`,
    },
  };

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      cartMeta({
        data,
        customers,
        fetchingCustomers,
        setCustomerTerm,
        deliveryStatuses,
        fetchingDeliveryStatuses,
        setDeliveryStatusTerm,
        paymentStatuses,
        fetchingPaymentStatuses,
        setPaymentStatusTerm,
        discountCodes,
        fetchingDiscountCodes,
        setDiscountCodeTerm,
      }),
    [
      customers,
      fetchingCustomers,
      setCustomerTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
      discountCodes,
      fetchingDiscountCodes,
      setDiscountCodeTerm,
    ]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/carts`, text: 'Carts' },
              {
                text: recordData?.customer_details?.first_name,
              },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={cartsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }} key='detail-view-childs'>
        <DetailAccordianArrayView
          key='detail-view=childs-list'
          value={accordData}
          labels={labels}
          editMode={editMode}
        />
      </Box>
    </Box>
  );
}
