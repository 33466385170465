import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ecommerceUrls, crmUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import ecommerceHandleRows from '@config/handleRows/ecommerce';
import { CartForm } from '@config/forms/ecommerce';
import { discountCodeMeta } from '@config/meta/ecommerce';
import DetailPageHeader from '@components/DetailPageHeader';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  cartsUrls,
  deliveryStatusesUrls,
  paymentStatusesUrls,
  discountCodesUrls,
} = ecommerceUrls;
const { personsUrls } = crmUrls;
const { cartRowMap } = ecommerceHandleRows;

export default function DiscountCodeDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [discountCodeTerm, setDiscountCodeTerm] = useState('');
  const [customerTerm, setCustomerTerm] = useState('');
  const [deliveryStatusTerm, setDeliveryStatusTerm] = useState('');
  const [paymentStatusTerm, setPaymentStatusTerm] = useState('');
  const [notify] = useToast();

  const {
    data: discountCodes,
    isFetching: fetchingDiscountCodes,
    refetch: refetchDiscountCode,
  } = useAutocomplete({
    key: 'discountCodes',
    getUrl: discountCodesUrls.list,
    inputValue: discountCodeTerm,
  });

  const {
    data: customers,
    isFetching: fetchingCustomers,
    refetch: refetchCustomer,
  } = useAutocomplete({
    key: 'customers',
    getUrl: personsUrls.list,
    inputValue: customerTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.first_name,
      };
    },
  });

  const {
    data: deliveryStatuses,
    isFetching: fetchingDeliveryStatuses,
    refetch: refetchDeliveryStatus,
  } = useAutocomplete({
    key: 'deliveryStatuses',
    getUrl: deliveryStatusesUrls.list,
    inputValue: deliveryStatusTerm,
  });

  const {
    data: paymentStatuses,
    isFetching: fetchingPaymentStatuses,
    refetch: refetchPayment,
  } = useAutocomplete({
    key: 'paymentStatuses',
    getUrl: paymentStatusesUrls.list,
    inputValue: paymentStatusTerm,
  });

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => discountCodeMeta({ data }), []);

  const cartsColOptions = React.useMemo(() => {
    return {
      customers,
      fetchingCustomers,
      setCustomerTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
      discountCodes,
      fetchingDiscountCodes,
      setDiscountCodeTerm,
    };
  }, [
    customers,
    fetchingCustomers,
    setCustomerTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
    paymentStatuses,
    fetchingPaymentStatuses,
    setPaymentStatusTerm,
    discountCodes,
    fetchingDiscountCodes,
    setDiscountCodeTerm,
  ]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/discount-codes`, text: 'Discount Codes' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={discountCodesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice="E-Commerce"
          model="Cart"
          columnKey="cartEcommerce"
          columnOptions={cartsColOptions}
          label="Carts"
          prefix="0l7"
          labels={labels}
          editMode={editMode}
          urls={cartsUrls}
          addNewForm={{
            getFields: () =>
              CartForm({
                customers,
                fetchingCustomers,
                setCustomerTerm,
                refetchCustomer,
                deliveryStatuses,
                fetchingDeliveryStatuses,
                setDeliveryStatusTerm,
                refetchDeliveryStatus,
                paymentStatuses,
                fetchingPaymentStatuses,
                setPaymentStatusTerm,
                refetchPayment,
                discountCodes,
                fetchingDiscountCodes,
                setDiscountCodeTerm,
                refetchDiscountCode,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: {
                    ...values,
                    discount_code: recordId,
                  },
                  url: cartsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                // notify(`Cart Created!`, {
                //   type: 'SUCCESS',
                // });
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={cartRowMap}
          urlParams={`&discount_code=${recordId}`}
        />
      </Box>
    </Box>
  );
}
