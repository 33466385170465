import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: null,
  tasks: [],
};

export const tasksBoardSlice = createSlice({
  name: 'tasksBoard',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
  },
});

export const { setModal, setTasks } = tasksBoardSlice.actions;

export const selectTasksBoard = (state) => state.tasksBoard;

export default tasksBoardSlice.reducer;
