import React from 'react';
import * as Yup from 'yup';
import { countryMeta } from '@config/meta/list/index';
import { listsUrls } from '@config/routes';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { countriesUrls } = listsUrls;

export default function CountryDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="Country"
        initialValues={{
          ...getAddFormInitialValues(countryMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(countryMeta(), 'listView'),
        })}
        getPostUrl={() => countriesUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <TextField key="name" label="Country Name" name="name" required />
            </Grid>
            <Grid item xs={6}>
              <TextField key="iso2" label="Iso2" name="iso2" />
            </Grid>
            <Grid item xs={6}>
              <TextField key="iso3" label="Iso3" name="iso3" />
            </Grid>
            <Grid item xs={6}>
              <TextField key="dialcode" label="Dialcode" name="dialcode" />
            </Grid>
            <Grid item xs={6}>
              <TextField key="continent" label="Continent" name="continent" />
            </Grid>
            <Grid item xs={6}>
              <TextField key="tld" label="TLD" name="tld" placeholder=".pk" />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
        
      />
    </>
  );
}
