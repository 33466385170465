import React from 'react';
import { Box, Typography } from '@mui/material';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: '20%',
          }}
        >
          <Typography
            variant='h1'
            sx={{
              mb: 2,
            }}
          >
            Oops!
          </Typography>
          <Typography variant='subtitle1'>
            An unexpected error occurred. Please try again later.
          </Typography>
        </Box>
      );
    }

    return this.props.children;
  }
}
