import * as React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function CheckboxField({
  label,
  color = 'primary',
  labelcolor,
  alignItems,
  ...rest
}) {
  return (
    <FormControlLabel
      style={{ alignItems }}
      control={
        <Checkbox
          {...rest}
          color={labelcolor ? labelcolor : color}
          style={{
            color: labelcolor,
          }}
        />
      }
      label={
        labelcolor ? (
          <Typography component='legend' color='#FFFFFF'>
            {label}
          </Typography>
        ) : (
          label
        )
      }
    />
  );
}
