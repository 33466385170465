import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function UpdateTypeModal({
  open,
  setOpen,
  disabled,
  showThisEventOption = true,
  showAllEventsOption = true,
  updateType,
  setUpdateType = () => null,
  onSaveClick = () => null,
  labels,
  editMode,
  prefix 
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='edit-recurring-title'
    >
      
      {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="Edit Recurring Event"
          >
            {(text) => {
              return <DialogTitle id='edit-recurring-title'>{text}</DialogTitle>;
            }}
          </RenderUiPrefix>
        ) : (
          <DialogTitle id='edit-recurring-title'>Edit Recurring Event</DialogTitle>
        )}

      <DialogContent sx={{ minWidth: '470px' }}>
        <Box>
          <RadioGroup
            aria-labelledby='recurring-event-update-type-label'
            name='recurring-event-update-type'
            value={updateType}
            onChange={(e) => setUpdateType(e.target.value)}
          >
            {showThisEventOption ? (
              <FormControlLabel
                value='this_event'
                control={<Radio />}
                label='This event'
              />
            ) : null}
            <FormControlLabel
              value='this_and_following_events'
              control={<Radio />}
              label='This and following events'
            />
            {showAllEventsOption ? (
              <FormControlLabel
                value='all_events'
                control={<Radio />}
                label='All events'
              />
            ) : null}
          </RadioGroup>
        </Box>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={disabled}
            autoFocus
            onClick={() => onSaveClick()}
          >
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
