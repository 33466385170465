import React from 'react';
import * as Yup from 'yup';
import { inventoryUrls } from '@config/routes';
import { listsUrls } from '@config/routes';
import { warehouseMeta } from '@config/meta/inventory/index';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { useQueryClient } from 'react-query';
import CityDropdown from '@dropdown-forms/list/CityDropdown';
import PostCodeDropdown from '@dropdown-forms/list/PostCodeDropdown';

const { warehousesUrls } = inventoryUrls;
const { citiesUrls, postCodesUrls } = listsUrls;

export default function WarehouseDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Warehouse"
        initialValues={{
          ...getAddFormInitialValues(warehouseMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(warehouseMeta(), 'listView'),
        })}
        getPostUrl={() => warehousesUrls.list()}
        getValues={({values, city, postcode, keyholder_postcode, keyholder_city}) => ({
          ...values,
          city: city?.id,
          postcode: postcode?.id,
          keyholder_postcode: keyholder_postcode?.id,
          keyholder_city: keyholder_city?.id,
        })}
        getFields={({setFieldValue, values}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key="name" label="Name" name="name" required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="address1"
                label="Address 1"
                name="address1"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <CityDropdown
                refetch={({ id, name }) => {
                  setFieldValue('city', { id, label: name });
                  queryClient.invalidateQueries(['warehouse-city']);
                }}
              >
                <AutocompleteFieldV2
                  name='city'
                  label='City'
                  requestKey='warehouse-city'
                  fetchUrl={citiesUrls.list}
                  urlParams={`&ordering=created_at`}
                  required
                />
              </CityDropdown>
            </Grid>
            <Grid item xs={6}>
              <PostCodeDropdown
                city = {values.city}
                refetch={({ id, code }) => {
                  setFieldValue('postcode', { id, label: code });
                  queryClient.invalidateQueries(['warehouse-postcode']);
                }}
              >
                <AutocompleteFieldV2
                  name='postcode'
                  label='Postcode'
                  requestKey='warehouse-postcode'
                  fetchUrl={postCodesUrls.list}
                  urlParams={`&ordering=created_at`}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.code,
                  })}
                  required
                />
              </PostCodeDropdown>
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="keyholder_name"
                label="Keyholder Name"
                name="keyholder_name"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="keyholder_addr1"
                label="Keyholder Address 1"
                name="keyholder_addr1"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <CityDropdown
                refetch={({ id, name }) => {
                  setFieldValue('keyholder_city', { id, label: name });
                  queryClient.invalidateQueries(['warehouse-keyholder-city']);
                }}
              >
                <AutocompleteFieldV2
                  name='keyholder_city'
                  label='Keyholder City'
                  requestKey='warehouse-keyholder-city'
                  fetchUrl={citiesUrls.list}
                  urlParams={`&ordering=created_at`}
                  required
                />
              </CityDropdown>
            </Grid>
            <Grid item xs={6}>
               <PostCodeDropdown
                  city = {values.keyholder_city}
                  refetch={({ id, code }) => {
                    setFieldValue('keyholder_postcode', { id, label: code });
                    queryClient.invalidateQueries(['warehouse-keyholder-postcode']);
                  }}
                >
                  <AutocompleteFieldV2
                    name='keyholder_postcode'
                    label='Keyholder Post Code'
                    requestKey='warehouse-keyholder-postcode'
                    fetchUrl={postCodesUrls.list}
                    urlParams={`&ordering=created_at`}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.code,
                    })}
                    required
                  />
                </PostCodeDropdown>
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="keyholder_mobile"
                label="Keyholder Mobile"
                name="keyholder_mobile"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="keyholder_email"
                label="Keyholder Email"
                name="keyholder_email"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="keycode"
                label="Key Code"
                name="keycode"
                required
              />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
