export const programRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  created_at: r.created_at,
});

export const resourceRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  email: r.email,
  mobile: r.mobile,
  description: r.description,
  cost: r.cost,
  disabled: r.disabled,
  user:
    r.details && r.details.user
      ? { id: r.user, label: r.details.user.email }
      : null,
  created_at: r.created_at,
});

export const projectRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  started: r.started ? new Date(r.started) : null,
  beta_partners: r.beta_partners,
  created_at: r.created_at,
});

export const timeSheetRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  hours: r.hours,
  date_time_started: r.date_time_started ? new Date(r.date_time_started) : null,
  resource:
    r.details && r.details.resource
      ? { id: r.resource, label: r.details.resource.name }
      : null,
  task:
    r.details && r.details.task
      ? { id: r.task, label: r.details.task.name }
      : null,
  workcode:
    r.details && r.details.workcode
      ? { id: r.workcode, label: r.details.workcode.name }
      : null,
  created_at: r.created_at,
});

export const workCodesRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  billable: r.billable,
  created_at: r.created_at,
});

export const projectTemplateRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  started: r.started ? new Date(r.started) : null,
  program:
    r.details && r.details.program
      ? { id: r.program, label: r.details.program.name }
      : null,
  created_at: r.created_at,
});

export const personaRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  created_at: r.created_at,
});

export const hlrRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  created_at: r.created_at,
});

export const backlogRowsMap = (r) => ({
  id: r.id,
  order: r.order,
  as_a:
    r.as_a && r.details && r.details.as_a
      ? { id: r.as_a, label: r.details.as_a.name }
      : r.as_a,
  i_want: r.i_want,
  so_that: r.so_that,
  story_points: r.story_points,
  impact: r.impact,
  created_at: r.created_at,
});

export const uatRowsMap = (r) => ({
  id: r.id,
  order: r.order,
  given: r.given,
  when: r.when,
  then: r.then,
  status: r.status,
  created_at: r.created_at,
});

export const backlogArtifactRowsMap = (r) => ({
  id: r.id,
  drive: r.drive,
  backlog: r.backlog,
  created_at: r.created_at,
});

export const testsConductedRowsMap = (r) => ({
  id: r.id,
  notes: r.notes,
  tested_by:
    r.tested_by && r.tested_by_details
      ? {
          id: r.tested_by,
          label: r.tested_by_details.name,
        }
      : null,
  date_tested: r.date_tested ? new Date(r.date_tested) : null,
  passed: r.passed,
  uatId: r.acceptance_criteria,
  uat:
    r.details && r.details.acceptance_criteria
      ? r.details.acceptance_criteria.given
      : null,
  created_at: r.created_at,
});

export const featureRequestRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  project:
    r.project && r.details && r.details.project
      ? { id: r.project, label: r.details.project.name }
      : r.project,
  created_at: r.created_at,
});

export const bugRowsMap = (r) => ({
  id: r.id,
  reported_by_name: r.reported_by_name,
  reported_by_email: r.reported_by_email,
  headline: r.headline,
  type: r.type,
  project:
    r.project && r.details && r.details.project
      ? { id: r.project, label: r.details.project.name }
      : r.project,
  priority: r.priority,
  status: r.status,
  resource:
    r.resource && r.details && r.details.resource
      ? { id: r.resource, label: r.details.resource.name }
      : r.resource,
  url: r.url,
  steps_to_reproduce: r.steps_to_reproduce,
  expected_result: r.expected_result,
  actual_result: r.actual_result,
  screenshot_drive_link: r.screenshot_drive_link,
  os: r.os,
  browser: r.browser,
  hours_estimate: r.hours_estimate,
  actual_hours: r.actual_hours,
  due_date: r.due_date ? new Date(r.due_date) : null,
  estimated_start_date_time: r.estimated_start_date_time
    ? new Date(r.estimated_start_date_time)
    : null,
  date_fixed: r.date_fixed ? new Date(r.date_fixed) : null,
  signed_off:
    r.signed_off && r.details && r.details.signed_off
      ? { id: r.signed_off, label: r.details.signed_off.name }
      : r.signed_off,
  resolution: r.resolution,
  keywords: r.keywords,
  notes: r.notes,
  created_at: r.created_at,
});

export const sprintMetaRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  started: r.started ? new Date(r.started) : null,
  days: r.days,
  sprint_to_generate: r.sprint_to_generate,
  goal: r.goal,
  program:
    r.details && r.details.program
      ? { id: r.program, label: r.details.program.name }
      : null,
  created_at: r.created_at,
});

export const sprintMetaProjectRowsMap = (r) => ({
  id: r.id,
  project:
    r.details && r.details.project
      ? { id: r.project, label: r.details.project.name }
      : null,
  created_at: r.created_at,
});

export const sprintRowsMap = (r) => ({
  id: r.id,
  start_date: r.start_date ? new Date(r.start_date) : null,
  end_date: r.end_date ? new Date(r.end_date) : null,
  created_at: r.created_at,
});

export const sprintTasksRowsMap = (r) => ({
  id: r.id,
  taskId: r.task,
  task: r.details && r.details.task ? r.details.task.name : null,
  status: r.task_status_details
    ? { id: r.task_status_details.id, label: r.task_status_details.name }
    : null,
  completion_percent:
    r.details && r.details.task ? r.details.task.completion_percent : null,
  deadline:
    r.details && r.details.task && r.details.task.deadline
      ? new Date(r.details.task.deadline)
      : null,
  owner: r.task_owner_details
    ? { id: r.task_owner_details.id, label: r.task_owner_details.name }
    : null,
  created_at: r.created_at,
});

export const taskStatusRowsMap = (r) => ({
  id: r.id,
  order: r.order,
  name: r.name,
  description: r.description,
  rotting_days: r.rotting_days,
  _color: r.colour,
  created_at: r.created_at,
});

export const stakeHoldersRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  email: r.email,
  mobile: r.mobile,
  landline: r.landline,
  role:
    r.role && r.details && r.details.role
      ? { id: r.role, label: r.details.role.name }
      : r.role,
  person:
    r.person && r.details && r.details.person
      ? { id: r.person, label: r.details.person.email }
      : r.person,
  created_at: r.created_at,
});

export const tasksRowsMap = (r) => ({
  id: r.id,
  order: r.order,
  task_level: r?.task_level,
  name: r.name,
  description: r.description,
  project: r.project,
  duration_estimate: r.sub_tasks?.has_sub_tasks
    ? r?.sub_tasks?.duration_estimate
    : r?.duration_estimate,
  duration_unit: r.sub_tasks?.has_sub_tasks
    ? r?.sub_tasks?.duration_unit
    : r?.duration_unit,
  duration_actual: r.sub_tasks?.has_sub_tasks
    ? r?.sub_tasks?.duration_actual
    : r?.duration_actual,
  started: r.started ? new Date(r.started) : null,
  deadline: r.deadline ? new Date(r.deadline) : null,
  task_type:
    r.task_type && r.details && r.details.task_type
      ? {
          id: r.task_type,
          label: r.details.task_type.name,
        }
      : null,
  owner:
    r.owner && r.details && r.details.owner
      ? { id: r.owner, label: r.details.owner.name }
      : r.owner,
  status:
    r.status && r.details && r.details.status
      ? { id: r.status, label: r.details.status.name }
      : r.status,
  predecessor:
    r.predecessor && r.details && r.details.predecessor
      ? { id: r.predecessor, label: r.details.predecessor.name }
      : null,
  dependency:
    r.dependency && r?.details?.dependency
      ? { id: r?.dependency, label: r?.details?.dependency?.name }
      : null,
  created_at: r.created_at,
});

export const bugCommentRowsMap = (r) => ({
  id: r.id,
  comment: r.comment,
  organization: r.organization,
  created_at: r.created_at,
});

export const taskBacklogRowsMap = (r) => {
  return {
    id: r.id,
    backlogId: r.backlog,
    as_a: r.backlog_as_a_details
      ? {
          id: r.details && r.details.backlog ? r.details.backlog.as_a : null,
          label: r.backlog_as_a_details.name,
        }
      : null,
    i_want:
      r.backlog && r.details && r.details.backlog && r.details.backlog.i_want,
    so_that:
      r.backlog && r.details && r.details.backlog && r.details.backlog.so_that,
  };
};

export const taskBugRowsMap = (r) => {
  return {
    id: r.id,
    bug:
      r.details && r.details.bug
        ? { id: r.bug, label: r.details.bug.headline }
        : null,
    task:
      r.details && r.details.task
        ? { id: r.task, label: r.details.task.name }
        : null,
    created_at: r.created_at,
  };
};

export const backlogTaskRowsMap = (r) => {
  return {
    id: r.id,
    task: r.task,
    name: r.details && r.details.task ? r.details.task.name : null,
    completion_percent:
      r.details && r.details.task ? r.details.task.completion_percent : null,
    deadline:
      r.details && r.details.task && r.details.task.deadline
        ? new Date(r.details.task.deadline)
        : null,
    owner: r.task_owner_details
      ? { id: r.task_owner_details.id, label: r.task_owner_details.name }
      : null,
  };
};

export const taskCommentRowsMap = (r) => ({
  id: r.id,
  comment: r.comment,
  attachment: r.attachment,
  created_at: r.created_at,
});

export const workingTimeRowsMap = (r) => ({
  id: r.id,
  project:
    r.project && r.details && r.details.project
      ? { id: r.project, label: r.details.project.name }
      : r.project,
  week_start: r.week_start,
  fiscal_year_start: r.fiscal_year_start,
  default_start_time: r.default_start_time,
  default_end_time: r.default_end_time,
  hours_per_week: r.hours_per_week,
  days_per_month: r.days_per_month,
  created_at: r.created_at,
});
