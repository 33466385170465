import { Box, Typography } from '@mui/material';
import React from 'react';

export default function DropTooltip() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '88%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: '999',
      }}
    >
      <Box
        sx={{
          borderRadius: '50%',
          textAlign: 'center',
          background: '#2059c9',
          width: '3rem',
          height: '3rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          style={{
            width: '1.5rem',
            height: '1.5rem',
            color: '#fff',
          }}
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5'
          />
        </svg>
      </Box>

      <Box
        sx={{
          background: '#2059c9',
          py: 2,
          px: 3,
          borderRadius: '30px',
          textAlign: 'center',
        }}
      >
        <Typography sx={{ color: '#fff' }}>
          Drop files to upload them to drive
        </Typography>
      </Box>
    </Box>
  );
}
