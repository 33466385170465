import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import {
  TextField,
  DateTimePickerField,
  AutocompleteFieldV2,
  SelectField,
  CheckBoxField,
  UploadField,
} from '@components/Inputs';
import {
  backlogStoryPoints,
  bugPriorityOptions,
  bugOsOptions,
  bugBrowserOptions,
  bugTypeOptions,
  // calendarRecurringChoicesOptions,
  // DAILY_RRULE,
  // WEEKLY_RRULE,
  // MONTHLY_RRULE,
  // ANNUALLY_RRULE,
  // WEEKDAYS_RRULE,
} from '@config/constants';
import ProjectDropdown from '@dropdown-forms/pm/ProjectDropdown';
import ProgramDropdown from '@dropdown-forms/pm/ProgramDropdown';
// import HLRDropdown from '@dropdown-forms/pm/HLRDropdown';
import TaskDropdown from '@dropdown-forms/pm/TaskDropdown';
import StatusDropdown from '@dropdown-forms/pm/StatusDropdown';
import ResourcesDropdown from '@dropdown-forms/pm/ResourceDropdown';
import PersonaDropdown from '@dropdown-forms/pm/PersonaDropdown';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';
import RoleDropdown from '@dropdown-forms/pm/RoleDropdown';
import WorkCodeDropdown from '@dropdown-forms/pm/WorkCodeDropdown';
// import TemplateDropdown from '@dropdown-forms/pm/TemplateDropdown';
// import DoWhatDropdown from '@dropdown-forms/pm/DoWhatDropdown';
// import CustomRecurrenceModal from '@components/Calendar/CustomRecurranceModal';
import { pmUrls, crmUrls, systemUrls } from '@config/routes';
import { useQueryClient } from 'react-query';

const {
  sprintMetaProjectsUrls,
  projectsUrls,
  taskTypesUrls,
  resourcesUrls,
  taskStatusUrls,
  tasksUrls,
  bugsUrls,
  rolesUrls,
  personasUrls,
  programsUrls,
  userAccountsUrls,
  workCodesUrls,
  // hlrsUrls,
} = pmUrls;
const { personsUrls } = crmUrls;
// const { companiesUrls, personsUrls, opportunitiesUrls } = crmUrls;
// const { doWhatUrls, calendarsListUrls } = calendarUrls;
const { templatesUrls } = systemUrls;

export const ProgramForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={2} sm={6}>
      <TextField label="Description" name="description" multiline />
    </Grid>
  </>
);

export const WorkCodesForm = () => (
  <>
    <Grid item xs={2}>
      <TextField multiline label="Name" name="name" required />
    </Grid>

    <Grid item xs={5}>
      <TextField multiline label="Description" name="description" />
    </Grid>

    <Grid item sm={1}>
      <CheckBoxField label="Billable" name="billable" />
    </Grid>
  </>
);

export const TimesheetsForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={3}>
        <TextField label="Name" name="name" required multiline />
      </Grid>

      <Grid item xs={4}>
        <TextField label="Description" name="description" multiline />
      </Grid>

      <Grid item xs={1}>
        <TextField label="Hours" name="hours" />
      </Grid>

      <Grid item xs={3}>
        <DateTimePickerField
          label="Started"
          name="date_time_started"
          showTime
        />
      </Grid>

      <Grid item sm={3}>
        <TaskDropdown
          refetch={({ id, name }) => {
            setFieldValue('task', { id, label: name });
            queryClient.invalidateQueries(['timesheet-task']);
          }}
        >
          <AutocompleteFieldV2
            name="task"
            label="Task"
            requestKey="timesheet-task"
            fetchUrl={tasksUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </TaskDropdown>
      </Grid>

      <Grid item sm={3}>
        <ResourcesDropdown
          title="Resource"
          refetch={({ id, name }) => {
            setFieldValue('resource', { id, label: name });
            queryClient.invalidateQueries(['timesheet-resource']);
          }}
        >
          <AutocompleteFieldV2
            name="resource"
            label="Resource"
            requestKey="timesheet-resource"
            fetchUrl={resourcesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </ResourcesDropdown>
      </Grid>

      <Grid item sm={3}>
        <WorkCodeDropdown
          refetch={({ id, name }) => {
            setFieldValue('workcode', { id, label: name });
            queryClient.invalidateQueries(['timesheet-workcode']);
          }}
        >
          <AutocompleteFieldV2
            name="workcode"
            label="Work Code"
            requestKey="timesheet-workcode"
            fetchUrl={workCodesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </WorkCodeDropdown>
      </Grid>
    </>
  );
};

export const ResourceForm = () => (
  <>
    <Grid item xs={3}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={3}>
      <TextField label="Email" name="email" required />
    </Grid>

    <Grid item xs={3}>
      <TextField label="Mobile" name="mobile" />
    </Grid>

    <Grid item xs={2}>
      <TextField label="Cost" name="cost" />
    </Grid>

    <Grid item xs={4}>
      <TextField label="Description" name="description" multiline />
    </Grid>

    <Grid item sm={3}>
      <AutocompleteFieldV2
        name="user"
        label="User"
        requestKey="resource-users"
        fetchUrl={userAccountsUrls.list}
        urlParams={`&ordering=created_at`}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.email,
        })}
        required
      />
    </Grid>
  </>
);

export const ProjectForm = ({
  microservice,
  model,
  setFieldValue,
  programId,
  programName,
} = {}) => {
  // const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={3}>
        <TextField label="Name" name="name" required />
      </Grid>

      <Grid item xs={4}>
        <TextField label="Description" name="description" multiline />
      </Grid>

      <Grid item xs={2} sm={4} md={2}>
        <DateTimePickerField label="Start Date" name="started" showTime />
      </Grid>

      <Grid item sm={3}>
        <AutocompleteFieldV2
          name="template"
          label="Template"
          requestKey="project-template"
          fetchUrl={templatesUrls.list}
          urlParams={`&ordering=created_at&microservice_name=${microservice}&model_name=${model}`}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
        />
      </Grid>
    </>
  );
};

export const ProjectTemplateForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2}>
        <TextField label="Name" name="name" required />
      </Grid>

      <Grid item xs={5}>
        <TextField label="Description" name="description" multiline />
      </Grid>

      <Grid item xs={2} sm={4} md={2}>
        <DateTimePickerField label="Start Date" name="started" showTime />
      </Grid>

      <Grid item sm={2}>
        <ProgramDropdown
          refetch={({ id, name }) => {
            setFieldValue('program', {
              id: id,
              label: `${name || ''}`,
            });
            queryClient.invalidateQueries(['project-template-program']);
          }}
        >
          <AutocompleteFieldV2
            name="program"
            label="Programs"
            requestKey="project-template-program"
            fetchUrl={programsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </ProgramDropdown>
      </Grid>
    </>
  );
};

export const PersonaForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Name" name="name" multiline required />
    </Grid>

    <Grid item xs={2} sm={4} md={6}>
      <TextField
        key="description"
        label="Description"
        name="description"
        multiline
      />
    </Grid>
  </>
);

export const HlrForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField key="name" label="Name" name="name" required />
    </Grid>

    <Grid item xs={2} sm={4} md={6}>
      <TextField
        key="description"
        label="Description"
        name="description"
        multiline
      />
    </Grid>
  </>
);

export const BacklogForm = ({ setFieldValue, project } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item sm={1}>
        <TextField key="order" label="Order" name="order" />
      </Grid>

      <Grid item sm={2}>
        <PersonaDropdown
          project={project}
          refetch={({ id, name }) => {
            setFieldValue('as_a', { id, label: name });
            queryClient.invalidateQueries(['backlog-as-a']);
          }}
        >
          <AutocompleteFieldV2
            name="as_a"
            label="As a"
            requestKey="backlog-as-a"
            fetchUrl={personasUrls.list}
            urlParams={`&ordering=created_at&project=${project?.id}`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </PersonaDropdown>
      </Grid>

      <Grid item xs={2} sm={5}>
        <TextField
          multiline
          key="i_want"
          label="I want"
          name="i_want"
          required
        />
      </Grid>

      <Grid item xs={2} sm={4}>
        <TextField
          multiline
          key="so_that"
          label="So that"
          name="so_that"
          required
        />
      </Grid>

      <Grid item md={3}>
        <SelectField label="Story Points" name="story_points">
          {backlogStoryPoints.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.key}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item sm={2}>
        <TextField label="Impact" name="impact" type="number" />
      </Grid>
    </>
  );
};

export const UatForm = () => (
  <>
    <Grid item xs={1}>
      <TextField label="Order" name="order" />
    </Grid>

    <Grid item xs={3}>
      <TextField multiline label="Given" name="given" required />
    </Grid>

    <Grid item xs={4}>
      <TextField multiline label="When" name="when" required />
    </Grid>

    <Grid item xs={4}>
      <TextField multiline label="Then" name="then" required />
    </Grid>
  </>
);

export const TestsConductedForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2} sm={3}>
        <TextField multiline key="notes" label="Notes" name="notes" />
      </Grid>

      <Grid item sm={2}>
        <ResourcesDropdown
          refetch={({ id, name }) => {
            setFieldValue('tested_by', { id, label: name });
            queryClient.invalidateQueries(['uat-tested-by']);
          }}
        >
          <AutocompleteFieldV2
            name="tested_by"
            label="Tested By"
            requestKey="uat-tested-by"
            fetchUrl={resourcesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </ResourcesDropdown>
      </Grid>

      <Grid item sm={2}>
        <DateTimePickerField label="Date Tested" name="date_tested" />
      </Grid>

      <Grid item sm={1}>
        <CheckBoxField label="Passed" name="passed" />
      </Grid>
    </>
  );
};

export const featureRequestsForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Name" name="name" />
    </Grid>

    <Grid item xs={2} sm={4}>
      <TextField label="Description" name="description" multiline />
    </Grid>

    <Grid item sm={2}>
      <AutocompleteFieldV2
        name="project"
        label="Project"
        requestKey="feature-requests-projects"
        fetchUrl={projectsUrls.list}
        urlParams={`&ordering=created_at`}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.name,
        })}
        required
      />
    </Grid>
  </>
);

export const BugsForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item md={2}>
        <SelectField label="Type" name="type">
          {bugTypeOptions.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item xs={2}>
        <TextField label="Reported by name" name="reported_by_name" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Reported by email" name="reported_by_email" />
      </Grid>

      <Grid item xs={6}>
        <TextField label="Headline" name="headline" multiline required />
      </Grid>

      <Grid item sm={2}>
        <ProjectDropdown
          refetch={({ id, name }) => {
            setFieldValue('project', { id, label: name });
            queryClient.invalidateQueries(['bug-projects']);
          }}
        >
          <AutocompleteFieldV2
            name="project"
            label="Project"
            requestKey="bug-projects"
            fetchUrl={projectsUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </ProjectDropdown>
      </Grid>

      <Grid item md={2}>
        <SelectField label="Priority" name="priority">
          {bugPriorityOptions.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item md={2}>
        <SelectField label="Os" name="os">
          {bugOsOptions.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item md={2}>
        <SelectField label="Browser" name="browser">
          {bugBrowserOptions.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item xs={4}>
        <TextField label="Url" name="url" placeholder="https://" />
      </Grid>

      <Grid item xs={4}>
        <TextField
          multiline
          label="Steps to reproduce"
          name="steps_to_reproduce"
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          multiline
          label="Expected result"
          name="expected_result"
          required
        />
      </Grid>

      <Grid item xs={4}>
        <TextField multiline label="Actual result" name="actual_result" />
      </Grid>
      <Grid item xs={4}>
        <UploadField name="screenshot_drive_link" />
      </Grid>
    </>
  );
};

export const SprintMetaForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={3}>
        <TextField label="Name" name="name" required />
      </Grid>

      <Grid item xs={2}>
        <DateTimePickerField label="Started" name="started" showTime />
      </Grid>

      <Grid item xs={1}>
        <TextField label="Days" name="days" />
      </Grid>

      <Grid item xs={1}>
        <TextField label="# Sprints" name="sprint_to_generate" />
      </Grid>

      <Grid item sm={2}>
        <ProgramDropdown
          refetch={({ id, name }) => {
            setFieldValue('program', {
              id: id,
              label: `${name || ''}`,
            });
            queryClient.invalidateQueries(['sprint-program']);
          }}
        >
          <AutocompleteFieldV2
            name="program"
            label="Programs"
            requestKey="sprint-program"
            fetchUrl={programsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </ProgramDropdown>
      </Grid>

      <Grid item xs={3}>
        <TextField label="Goal" name="goal" />
      </Grid>

      <Grid item xs={3}>
        <TextField label="Methods" name="method" />
      </Grid>

      <Grid item xs={3}>
        <TextField label="Metrics" name="metrics" />
      </Grid>
    </>
  );
};

export const SprintMetaProjectForm = ({ programId, setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item sm={6}>
        <ProjectDropdown
          refetch={({ id, name }) => {
            setFieldValue('project', {
              id: id,
              label: `${name || ''}`,
            });
            queryClient.invalidateQueries(['sprint-program-projects']);
          }}
        >
          <AutocompleteFieldV2
            name="project"
            label="Projects"
            requestKey="sprint-program-projects"
            fetchUrl={projectsUrls.list}
            urlParams={programId ? `&program=${programId}` : ''}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </ProjectDropdown>
      </Grid>
    </>
  );
};

export const SprintsForm = () => (
  <Grid item sm={2}>
    <DateTimePickerField label="Start Date" name="start_date" />
  </Grid>
);

export const SprintTasksForm = ({ setFieldValue, values, sprintMeta } = {}) => {
  // const queryClient = useQueryClient();

  return (
    <>
      <Grid item sm={4}>
        <AutocompleteFieldV2
          name="projects"
          label="Project"
          requestKey="sprint-task-projects"
          fetchUrl={sprintMetaProjectsUrls.list}
          urlParams={`&ordering=created_at&sprint_meta=${sprintMeta}`}
          renderRow={(row) => ({
            id: row?.project,
            label: row?.details?.project?.name || '',
            program: row?.details?.project?.program,
          })}
          required
        />
      </Grid>
      <Grid item sm={4}>
        <AutocompleteFieldV2
          name="task"
          label="Task"
          requestKey="sprint-task"
          fetchUrl={tasksUrls.list}
          disabled={!values?.projects}
          enabled={!!values?.projects?.id}
          urlParams={
            values?.projects
              ? `&project=${values.projects?.id}&ordering=created_at`
              : ''
          }
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
          required
        />
      </Grid>
    </>
  );
};

export const TaskStatusForm = ({ hideParent, projectId }) => (
  <>
    <Grid item xs={1}>
      <TextField label="Order" name="order" />
    </Grid>

    <Grid item xs={2}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={3.5}>
      <TextField label="Description" name="description" multiline />
    </Grid>

    {!hideParent ? (
      <Grid item xs={4}>
        <AutocompleteFieldV2
          name="parent"
          label="Parent Task Status"
          requestKey="task-status-parent-task-statuses"
          fetchUrl={taskStatusUrls.list}
          urlParams={`&parent=${projectId}&only_parent=true`}
          renderRow={(row) => ({
            id: row?.id,
            label: `${row?.full_order ?? ''}${`${row?.order}` ?? ''} ${
              row?.name ?? ''
            }`,
          })}
        />
      </Grid>
    ) : null}

    <Grid item xs={1.5}>
      <TextField label="Rotting Days" name="rotting_days" />
    </Grid>
  </>
);

export const TaskTypeForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={5}>
      <TextField label="Description" name="description" multiline />
    </Grid>
  </>
);

export const TaskBugsForm = ({
  values,
  errors,
  setFieldValue,
  showTask = true,
  showBug = true,
} = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item sm={4}>
        <AutocompleteFieldV2
          name="program"
          label="Program"
          requestKey="task-bugs-programs"
          fetchUrl={programsUrls.list}
          urlParams={`&ordering=created_at`}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
          required
        />
      </Grid>
      <Grid item sm={4}>
        <AutocompleteFieldV2
          name="project"
          label="Project"
          requestKey={['task-bugs-projects', values?.program?.id]}
          fetchUrl={projectsUrls.list}
          disabled={!values?.program}
          enabled={!!values?.program?.id}
          urlParams={`&ordering=created_at&program=${
            values?.program?.id ?? ''
          }`}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name || '',
            program: row?.program,
          })}
          required
        />
      </Grid>
      {showTask ? (
        <Grid item sm={4}>
          <TaskDropdown
            program={values?.program}
            project={values?.project}
            refetch={({ id, name }) => {
              setFieldValue('task', { id, label: name });
              queryClient.invalidateQueries(['task-bugs-tasks']);
            }}
          >
            <AutocompleteFieldV2
              name="task"
              label="Task"
              requestKey={['task-bugs-tasks', values?.project?.id]}
              fetchUrl={tasksUrls.list}
              disabled={!values?.project?.id}
              enabled={!!values?.project?.id}
              urlParams={`&ordering=created_at&project=${
                values?.project?.id ?? ''
              }`}
              renderRow={(row) => ({
                id: row?.id,
                label: row?.name,
              })}
              required
            />
          </TaskDropdown>
        </Grid>
      ) : null}

      {showBug ? (
        <Grid item sm={4}>
          <AutocompleteFieldV2
            name="bug"
            label="Bug"
            requestKey={['task-bugs-bugs', values?.project?.id]}
            fetchUrl={bugsUrls.list}
            disabled={!values?.project?.id}
            enabled={!!values?.project?.id}
            urlParams={`&ordering=created_at&project=${
              values?.project?.id ?? ''
            }`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.headline,
            })}
            required
          />
        </Grid>
      ) : null}
    </>
  );
};

export const TasksForm = ({
  // setRepeat,
  programId,
  projectName,
  values,
  setFieldValue,
  projectId,
  programName,
} = {}) => {
  const queryClient = useQueryClient();
  // const [rrule, setRRule] = React.useState('');
  // const [modal, setModal] = React.useState(false);

  return (
    <>
      {values?.task_type?.label !== 'Reminder' ? (
        <Grid item xs={1}>
          <TextField label="Order" name="order" />
        </Grid>
      ) : null}

      <Grid item sm={2}>
        <AutocompleteFieldV2
          name="task_type"
          label="Task Type"
          requestKey="project-task-type"
          fetchUrl={taskTypesUrls.list}
          urlParams={`&ordering=created_at&project=${projectId}`}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
          setCustomOptions={(data) =>
            data.filter((taskType) => taskType.label !== 'Reminder')
          }
          required
        />
      </Grid>

      {/* {values?.task_type?.label === 'Reminder' ? (
        <Grid item sm={2}>
          <DoWhatDropdown
            refetch={({ id, name }) => {
              setFieldValue('do_what', { id, label: name });
              queryClient.invalidateQueries(['task-do-what']);
            }}
          >
            <AutocompleteFieldV2
              name="do_what"
              label="Do what"
              requestKey="task-do-what"
              fetchUrl={doWhatUrls.list}
              urlParams={`&program=${programId}&ordering=created_at`}
              renderRow={(row) => ({
                id: row?.id,
                label: row?.name,
              })}
              required
            />
          </DoWhatDropdown>
        </Grid>
      ) : null} */}

      <Grid item xs={4}>
        <TextField label="Name" multiline name="name" required />
      </Grid>

      <Grid item xs={4}>
        <TextField label="Description" name="description" multiline />
      </Grid>

      {values?.task_type?.label !== 'Reminder' ? (
        <Grid item sm={2}>
          <ResourcesDropdown
            title="Owner"
            refetch={({ id, name }) => {
              setFieldValue('owner', { id, label: name });
              queryClient.invalidateQueries(['task-owner']);
            }}
          >
            <AutocompleteFieldV2
              name="owner"
              label="Owner"
              requestKey="task-owner"
              fetchUrl={resourcesUrls.list}
              urlParams={`&ordering=created_at`}
              renderRow={(row) => ({
                id: row?.id,
                label: row?.name,
              })}
            />
          </ResourcesDropdown>
        </Grid>
      ) : null}

      <Grid item sm={2}>
        <DateTimePickerField
          label={
            values?.task_type?.label === 'Reminder'
              ? 'Reminder date and time'
              : 'Started'
          }
          name="started"
          showTime
          required
        />
      </Grid>

      {/* {values?.task_type?.label === 'Reminder' ? (
        <>
          <Grid item sm={2}>
            <AutocompleteFieldV2
              name="calendar"
              label="Calendar"
              requestKey="task-calendar-reminder"
              fetchUrl={calendarsListUrls.list}
              urlParams={`&ordering=created_at`}
              getData={(data) => {
                if (data.length) {
                  setFieldValue('calendar', data[0]);
                }
              }}
              required
            />
          </Grid>

          <Grid item sm={2}>
            <SelectField
              label="Repeat"
              name="repeat"
              onClose={(e) => e?.target?.value === 'custom' && setModal(true)}
              customSetValue={(e) => {
                const value = e?.target?.value;
                if (value === 'custom') {
                  setRepeat(null);
                } else if (value === 'daily') {
                  setRepeat(DAILY_RRULE);
                } else if (value === 'weekly') {
                  setRepeat(WEEKLY_RRULE);
                } else if (value === 'monthly') {
                  setRepeat(MONTHLY_RRULE);
                } else if (value === 'annually') {
                  setRepeat(ANNUALLY_RRULE);
                } else if (value === 'weekdays') {
                  setRepeat(WEEKDAYS_RRULE);
                }
              }}
            >
              {calendarRecurringChoicesOptions.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item sm={2}>
            <AutocompleteFieldV2
              name="_company"
              label="Company"
              requestKey="task-company"
              fetchUrl={companiesUrls.list}
              urlParams={`&ordering=created_at`}
            />
          </Grid>

          <Grid item sm={2}>
            <AutocompleteFieldV2
              name="person"
              label="Person"
              requestKey="task-reminder-person"
              fetchUrl={personsUrls.list}
              urlParams={`&ordering=created_at`}
              renderRow={(row) => ({
                id: row?.id,
                label: row?.email,
              })}
            />
          </Grid>

          <Grid item sm={2}>
            <AutocompleteFieldV2
              name="opportunity"
              label="Opportunity"
              requestKey="task-reminder-opportunity"
              fetchUrl={opportunitiesUrls.list}
              urlParams={`&ordering=created_at`}
              renderRow={(row) => ({
                id: row?.id,
                label: row?.name,
              })}
            />
          </Grid>

          <CustomRecurrenceModal
            open={modal}
            setOpen={setModal}
            oSave={(rrule) => {
              setRRule(rrule);
              setRepeat(rrule);
              setModal(false);
            }}
            rrule={rrule}
          />
        </>
      ) : null} */}

      {values?.task_type?.label !== 'Reminder' ? (
        <>
          <Grid item sm={2}>
            <DateTimePickerField
              label="Deadline"
              name="deadline"
              showTime
              required
            />
          </Grid>
          <Grid item sm={2}>
            <StatusDropdown
              project={{ id: projectId, label: projectName }}
              refetch={({ id, name }) => {
                setFieldValue('status', { id, label: name });
                queryClient.invalidateQueries(['task-status']);
              }}
            >
              <AutocompleteFieldV2
                name="status"
                label="Status"
                requestKey="task-status"
                fetchUrl={taskStatusUrls.list}
                urlParams={`&project=${projectId}&ordering=created_at`}
                renderRow={(row) => ({
                  id: row?.id,
                  label: row?.name,
                })}
              />
            </StatusDropdown>
          </Grid>
          <Grid item sm={2}>
            <TaskDropdown
              programId={programId}
              project={{ id: projectId, label: projectName }}
              status={values?.status}
              owner={values?.owner}
              task_type={values?.task_type}
              refetch={({ id, name }) => {
                setFieldValue('predecessor', { id, label: name });
                queryClient.invalidateQueries(['dependency-tasks-predecessor']);
              }}
            >
              <AutocompleteFieldV2
                name="predecessor"
                label="Predecessor"
                requestKey="dependency-tasks-predecessor"
                fetchUrl={tasksUrls.list}
                urlParams={`&project=${projectId}&ordering=created_at`}
                renderRow={(row) => ({
                  id: row?.id,
                  label: row?.name,
                })}
              />
            </TaskDropdown>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export const BugsCommentForm = () => (
  <>
    <Grid item xs={12}>
      <TextField label="Comment" name="comment" multiline rows={3} required />
    </Grid>
  </>
);

export const BacklogArtifactForm = ({ values = {}, errors = {} }) => {
  return (
    <Grid item xs={4}>
      <UploadField name="drive" />
    </Grid>
  );
};

export const TaskCommentForm = () => {
  return (
    <>
      <Grid item xs={12}>
        <TextField label="Comment" name="comment" multiline rows={3} required />
      </Grid>
      <Grid item xs={12}>
        <UploadField name="attachment" />
      </Grid>
    </>
  );
};

export const StakeholderForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item sm={2}>
        <PersonDropdown
          refetch={({ id, email }) => {
            setFieldValue('person', {
              id: id,
              label: `${email || ''}`,
            });
            queryClient.invalidateQueries(['stakeholder-persons']);
          }}
        >
          <AutocompleteFieldV2
            name="person"
            label="Person"
            requestKey="stakeholder-persons"
            fetchUrl={personsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.email}`,
              email: row?.email,
              first_name: row?.first_name,
              last_name: row?.last_name,
              personal_mobile: row?.personal_mobile,
              home_phone: row?.home_phone,
            })}
            onChange={(value) => {
              setFieldValue('name', `${value?.first_name}${value?.last_name}`);
              setFieldValue('email', value?.email);
              setFieldValue('mobile', value?.personal_mobile);
              setFieldValue('landline', value?.home_phone);
            }}
          />
        </PersonDropdown>
      </Grid>

      <Grid item xs={2}>
        <TextField label="Name" name="name" required />
      </Grid>

      <Grid item xs={3}>
        <TextField label="Description" name="description" multiline />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Email" name="email" required />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Mobile" name="mobile" />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Landline" name="landline" />
      </Grid>

      <Grid item sm={2}>
        <RoleDropdown
          refetch={({ id, name }) => {
            setFieldValue('role', {
              id: id,
              label: `${name || ''}`,
            });
            queryClient.invalidateQueries(['stakeholder-role']);
          }}
        >
          <AutocompleteFieldV2
            name="role"
            label="Role"
            requestKey="stakeholder-Role"
            fetchUrl={rolesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.name}`,
            })}
            required
          />
        </RoleDropdown>
      </Grid>
    </>
  );
};

export const WorkingTimeForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item sm={3}>
        <ProjectDropdown
          refetch={({ id, name }) => {
            setFieldValue('project', { id, label: name });
            queryClient.invalidateQueries(['working-time-projects']);
          }}
        >
          <AutocompleteFieldV2
            name="project"
            label="Project"
            requestKey="working-time-projects"
            fetchUrl={projectsUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </ProjectDropdown>
      </Grid>
      <Grid item xs={2} sm={3}>
        <TextField label="Week Start" name="week_start" />
      </Grid>
      <Grid item sm={2}>
        <DateTimePickerField
          label="Fiscal Year Start"
          name="fiscal_year_start"
        />
      </Grid>
      <Grid item sm={2}>
        <DateTimePickerField
          label="Default Start Time"
          name="default_start_time"
          required
          showTime
        />
      </Grid>
      <Grid item sm={2}>
        <DateTimePickerField
          label="Default End Time"
          name="default_end_time"
          required
          showTime
        />
      </Grid>
      <Grid item sm={3}>
        <TextField
          label="Hours Per Week"
          name="hours_per_week"
          type="number"
          required
        />
      </Grid>
      <Grid item sm={3}>
        <TextField
          label="Days Per Month"
          name="days_per_month"
          type="number"
          required
        />
      </Grid>
    </>
  );
};
