import React from 'react';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { setToken } from '@redux/profileSlice';
import { refreshToken } from '@hooks/useAuthToken';
import { getUserStorageKey } from '@config/constants';
import { parseJSON } from '@config/functions/helperFunctions';

function handleError({ key, dispatch, error, queryClient }) {
  console.log('error?.response', error?.response);
  if (
    error?.response?.status === 403 &&
    error?.response?.data?.code === 'token_not_valid'
  ) {
    // Refresh the token and try the query again
    return refreshToken().then((data) => {
      const { data: user } = parseJSON(
        localStorage.getItem(getUserStorageKey())
      );

      localStorage.setItem(
        getUserStorageKey(),
        JSON.stringify({
          ...user,
          token: data?.access,
        })
      );

      // Save the new JWT token to Redux state
      dispatch(setToken(data?.access));

      // Call the refetch function to retry the query with the new token
      queryClient.invalidateQueries([key]);

      // Return null to indicate that the error has been handled
      return null;
    });
  }

  return error;
}

export default function useRQuery({
  key,
  url,
  options = {},
  config = {},
  renderRow,
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [records, setRecords] = React.useState(null);
  const [dataUpToDate, setDataUpToDate] = React.useState(false);

  const { data, isLoading, isError, error, isRefetching, ...rest } = useQuery(
    key,
    () => axios.get(url, config).then((res) => res.data),
    {
      retry: 0,
      onError: (error) => handleError({ key, dispatch, error, queryClient }),
      ...options,
    }
  );

  React.useEffect(() => {
    if (!data) return;

    if (!renderRow) return setRecords(data);

    if (Array.isArray(data.results)) {
      setRecords(data.results.map(renderRow));
    } else if (Array.isArray(data)) {
      setRecords(data.map(renderRow));
    } else {
      setRecords(renderRow(data));
    }
  }, [data]);

  React.useEffect(() => {
    if (isLoading || isRefetching) {
      setDataUpToDate(false);
    } else {
      setTimeout(() => {
        setDataUpToDate(true);
      }, 500);
    }
  }, [isLoading, isRefetching]);

  return {
    data: records,
    isLoading,
    isError,
    error,
    isRefetching,
    dataUpToDate,
    ...rest,
  };
}
