import {
  updateRecord,
  deleteRecord,
  createRecord,
} from '@config/functions/requests';
import {
  calendarRecurringChoicesOptions,
  DAILY_RRULE,
  DAILY_RRULE_WITHOUT_INTERVAL,
  WEEKLY_RRULE,
  WEEKLY_RRULE_WITHOUT_INTERVAL,
  MONTHLY_RRULE,
  MONTHLY_RRULE_WITHOUT_INTERVAL,
  ANNUALLY_RRULE,
  ANNUALLY_RRULE_WITHOUT_INTERVAL,
  WEEKDAYS_RRULE,
  WEEKDAYS_RRULE_WITHOUT_INTERVAL,
} from '@config/constants';
import { calendarUrls } from '@config/routes';
import { formatToUTCDateTime } from './helperFunctions';

const { eventsUrls, deleteSlotEventsUrls, deleteRecurringEventsUrls, guestsUrls } = calendarUrls;


export const detectRrule = (rrule) => {
  if (!rrule || !rrule.length) {
    return calendarRecurringChoicesOptions[0] // DOES NOT REPEAT
  } else if (
    rrule === DAILY_RRULE ||
    rrule === DAILY_RRULE_WITHOUT_INTERVAL
  ) {
    return calendarRecurringChoicesOptions[1] // DOES NOT REPEAT
  } else if (
    rrule === WEEKLY_RRULE ||
    rrule === WEEKLY_RRULE_WITHOUT_INTERVAL
  ) {
    return calendarRecurringChoicesOptions[2] // WEEKLY
  } else if (
    rrule === MONTHLY_RRULE ||
    rrule === MONTHLY_RRULE_WITHOUT_INTERVAL
  ) {
    return calendarRecurringChoicesOptions[3] // MONTHLY
  } else if (
    rrule === ANNUALLY_RRULE ||
    rrule === ANNUALLY_RRULE_WITHOUT_INTERVAL
  ) {
    return calendarRecurringChoicesOptions[4] // ANNUALLY
  } else if (
    rrule === WEEKDAYS_RRULE ||
    rrule === WEEKDAYS_RRULE_WITHOUT_INTERVAL
  ) {
    return calendarRecurringChoicesOptions[5] // WEEKDAYS
  }
  return calendarRecurringChoicesOptions[6] // CUSTOM
};


//Pad given value to the left with '0'
export const AddZero = (num) => (num >= 0 && num < 10 ? '0' + num : num + '');

export const onEventDrop = async ({
  event,
  start,
  end,
  isAllDay = false,
  setEvents,
  userToken,
  refetchEvents,
  notify,
}) => {
  const { allDay } = event;
  if (!allDay && isAllDay) event.allDay = true;

  try {
    setEvents((state) => {
      const existing = state.find((ev) => ev.id === event.id) ?? {};
      const filtered = state.filter((ev) => ev.id !== event.id);
      return [...filtered, { ...existing, start, end, allDay }];
    });

    await updateRecord({
      values: {
        start_date_time: formatToUTCDateTime(start),
        end_date_time: formatToUTCDateTime(end),
      },
      url: eventsUrls.detail(event.id),
      token: userToken,
    });

    refetchEvents();
    // notify('Event Updated', {
    //   type: 'SUCCESS',
    // });
  } catch (err) {
    notify('Something went wrong, try refreshing', {
      type: 'ERROR',
    });
  }
};

export const onEventResize = async ({
  event,
  start,
  end,
  notify,
  setEvents,
  userToken,
  refetchEvents,
}) => {
  try {
    setEvents((state) => {
      const existing = state.find((ev) => ev.id === event.id) ?? {};
      const filtered = state.filter((ev) => ev.id !== event.id);
      return [...filtered, { ...existing, start, end }];
    });

    await updateRecord({
      values: {
        start_date_time: formatToUTCDateTime(start),
        end_date_time: formatToUTCDateTime(end),
      },
      url: eventsUrls.detail(event.id),
      token: userToken,
    });
    refetchEvents();
    // notify('Event Updated', {
    //   type: 'SUCCESS',
    // });
  } catch (err) {
    notify('Something went wrong, try refreshing', {
      type: 'ERROR',
    });
  }
};

export const getRuleFreq = (unit) => {
  let rule = '';
  switch (unit) {
    case 'days':
      rule = 'DAILY;';
      break;
    case 'weeks':
      rule = 'WEEKLY';
      break;
    case 'months':
      rule = 'MONTHLY';
      break;
    case 'years':
      rule = 'YEARLY';
      break;
    default:
      rule = '';
  }
  return rule;
};

export const getByWeekDay = (day) => {
  let rule = '';
  const dayParsed = parseInt(day);
  switch (day) {
    case dayParsed === 0:
      rule = 'MO';
      break;
    case dayParsed === 1:
      rule = 'TU';
      break;
    case dayParsed === 2:
      rule = 'WE';
      break;
    case dayParsed === 3:
      rule = 'TH';
      break;
    case dayParsed === 4:
      rule = 'SA';
      break;
    case dayParsed === 5:
      rule = 'SU';
      break;
    default:
      rule = '';
  }
  return rule;
};

export const onCreateEventClick = async ({
  values,
  guests,
  user,
  setShowCreateEventModal,
  refetchEvents,
  notify,
}) => {
  try {
    setShowCreateEventModal(false);
    const response = await createRecord({
      values: {
        ...values,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      url: eventsUrls.list(),
      token: user.token,
      actAs: user?.actAs,
    });

    if (guests) {
      const guestPromises = guests
        .filter((r) => r !== user.details.email)
        .map(async (guest) =>
          createRecord({
            values: {
              email: guest,
              event: response?.data?.id,
            },
            url: guestsUrls.list(),
            token: user.token,
            actAs: user?.actAs,
          })
        );

      await Promise.allSettled(guestPromises);
    }

    await updateRecord({
      values: {},
      url: eventsUrls.detail(response?.data?.id),
      token: user.token,
      actAs: user?.actAs,
    });

    refetchEvents();
    // notify('Event Created', {
    //   type: 'SUCCESS',
    // });
  } catch (err) {
    console.log(err);
    notify('Something went wrong, try refreshing', {
      type: 'ERROR',
    });
  }
};

export const onSlotConfirmDelete = async ({
  modalData,
  userToken,
  setShowViewEventModal,
  refetchEvents,
  notify,
}) => {
  try {
    setShowViewEventModal(false);
    await deleteRecord({
      url: deleteSlotEventsUrls.detail(
        modalData && modalData.event_type === "SlotEvent" && !modalData.parent_event ? modalData.id : modalData.parent_event
      ),
      token: userToken,
    });
    refetchEvents();
    // notify('Event Deleted', {
    //   type: 'SUCCESS',
    // });
  } catch (err) {
    console.log(err)
    notify('Something went wrong, try refreshing', {
      type: 'ERROR',
    });
  }
};

export const onConfirmDelete = async ({
  deleteType,
  modalData,
  userToken,
  setShowViewEventModal,
  refetchEvents,
  notify,
}) => {
  try {
    setShowViewEventModal(false);

    if (deleteType) {
      await updateRecord({
        values: {
          delete_type: deleteType
        },
        url: deleteRecurringEventsUrls.detail(modalData.id),
        token: userToken,
      });
    } else {
      await deleteRecord({
        url: eventsUrls.detail(modalData.id),
        token: userToken,
      });
    }
    refetchEvents();
    // notify('Event Deleted', {
    //   type: 'SUCCESS',
    // });
  } catch (err) {
    notify('Something went wrong, try refreshing', {
      type: 'ERROR',
    });
  }
};

export const formatDateToYYYMMDD = (date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return [year, month, day].join('-');
};

export const getRange = () => {
  const fromDate = new Date();
  const toDate = new Date();
  return [
    `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-1`,
    `${toDate.getFullYear()}-${toDate.getMonth() + 2}-4`,
  ];
};
