import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import { createRecord, updateRecord } from '@config/functions/requests';
import { opportunitiesRowsMap } from '@config/handleRows/crm';
import { OpportunityForm } from '@config/forms/crm';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import ColorPicker from '@components/EditableTableComps/ColorPicker';
import { useQueryClient } from 'react-query';

const { opportunitiesUrls } = crmUrls;

export default function Opportunities({ editMode, labels }) {
  const user = useSelector(selectProfile);
  const [notify, update] = useToast();
  const queryClient = useQueryClient();

  const handleColorSelect = async ({ rows, setSelectedRows, color } = {}) => {
    const tId = notify('Saving....', { loading: true });
    try {
      await Promise.allSettled(
        rows.map((row) =>
          updateRecord({
            url: opportunitiesUrls.detail(row),
            values: {
              _color: color,
            },
            token: user.token,
            actAs: user?.actAs,
          })
        )
      );
      setSelectedRows([]);
      queryClient.invalidateQueries(['Opportunities-listData']);
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  return (
    <Box>
      <ListView
        label="Opportunities"
        editMode={editMode}
        labels={labels}
        prefix="0yq"
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.opportunities}
        urls={opportunitiesUrls}
        reminderBulkActionType="opportunity"
        tableBulkOptions={[{ id: 'addReminder', text: 'Add reminder' }]}
        customBulkHeaderButton={(selectedRows, data, setSelectedRows) => {
          return (
            <>
              <ColorPicker
                onChange={(color) =>
                  handleColorSelect({
                    rows: selectedRows,
                    setSelectedRows,
                    color,
                  })
                }
              />
            </>
          );
        }}
        columnKey="opportunitiesCRM"
        addNewForm={{
          getFields: ({ values, setFieldValue }) =>
            OpportunityForm({
              values,
              setFieldValue,
            }),
          handleSubmit: async (
            {
              channel,
              companyId,
              companyContact,
              pipeline,
              status,
              owner,
              ...values
            },
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError,
            setFieldError
          ) => {
            try {
              const errors = [];

              if (!companyContact?.id) {
                errors.push('companyContact');
              }

              if (errors.length) {
                errors.forEach((name) => {
                  setFieldError(name, 'This field is required');
                });

                return;
              }

              const response = await createRecord({
                values: {
                  ...values,
                  company: companyId?.id,
                  channel: channel?.id,
                  company_contact: companyContact?.id,
                  pipeline: pipeline?.id,
                  status: status?.id,
                  owner: owner?.id,
                },
                url: opportunitiesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              refetch();
              resetForm();
              setAddNewForm(false);
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={opportunitiesRowsMap}
      />
    </Box>
  );
}
