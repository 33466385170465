import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, FormHelperText, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@components/CandidateForm/TextField';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  DateTimePickerField,
  CheckBoxField,
  SwitchField,
  FileInput,
} from '@components/Inputs';
import SelectField from '@components/CandidateForm/SelectField';
import useRQuery from '@hooks/useRQuery';
import { hrUrls, nodeDriveUrls, systemUrls, logsUrls } from '@config/routes';
import { candidateMeta } from '@config/meta/hr';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import { createRecord } from '@config/functions/requests';
import Alert from '@mui/material/Alert';
import { useParams, useSearchParams } from 'react-router-dom';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { countriesList } from '@config/constants';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { formatToUTCDate } from '@config/functions/helperFunctions';

const { filesUrls } = nodeDriveUrls;
const { vacanciesUrls, candidatesUrls } = hrUrls;
const { ipDetailUrls } = systemUrls;
const { visitorLogUrls } = logsUrls;

export default function CandidateForm() {
  const { id: vacancyId } = useParams();
  const [queryParams] = useSearchParams();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(null);
  const utmSource = queryParams.get('utm_source') ?? '';
  const utmMedium = queryParams.get('utm_medium') ?? '';
  const utmCampaign = queryParams.get('utm_campaign') ?? '';
  const utmTerm = queryParams.get('utm_term') ?? '';
  const utmContent = queryParams.get('utm_content') ?? '';

  const initialFirstName = queryParams.get('firstName') ?? '';
  const initialLastName = queryParams.get('lastName') ?? '';
  const initialEmail = queryParams.get('email') ?? '';
  const initialPhone = queryParams.get('phone') ?? '';
  const initialAddress1 = queryParams.get('address1') ?? '';
  const initialAddress2 = queryParams.get('address2') ?? '';
  const initialLinkedinProfile = queryParams.get('linkedinProfile') ?? '';

  const { data: userIP } = useRQuery({
    key: ['candidate-vacancy-user-ip', vacancyId],
    url: ipDetailUrls.list(),
  });

  const {
    data: vacancyData,
    isLoading,
    isError,
  } = useRQuery({
    key: ['candidate-vacancy', vacancyId],
    url: vacanciesUrls.detail(vacancyId),
    options: { enabled: !!vacancyId },
  });

  const getDefaultCountry = () => {
    if (typeof window === 'undefined') return '';

    if (window.location.href.includes('mazards')) {
      return userIP?.country ?? countriesList[185];
    }

    return userIP?.country ?? countriesList[108];
  };

  const initialValues = {
    ...getAddFormInitialValues(candidateMeta(), 'listView'),
    agreeTos: false,
    country: getDefaultCountry(),
    first_name: initialFirstName ?? '',
    last_name: initialLastName ?? '',
    personal_email: initialEmail ?? '',
    personal_mobile: initialPhone ?? '',
    address1: initialAddress1 ?? '',
    address2: initialAddress2 ?? '',
    linkedin: initialLinkedinProfile ?? '',
  };

  const getFavicon = () => {
    if (typeof window === 'undefined') return '';

    if (window.location.href.includes('mazards')) {
      return 'https://drive.pullstream.com/file/634e8c8f-810a-4149-b3af-ba750abca696.ico?one=true';
    }

    if (window.location.href.includes('evcharging')) {
      return 'https://drive.pullstream.com/file/e241d62a-ffe6-47fb-a91b-e59798743e73.ico?one=true';
    }

    if (window.location.href.includes('supersymmetrysoftware')) {
      return 'https://drive.pullstream.com/file/43b778ed-115b-4bb3-8c59-a9096a29abdf.png';
    }

    return '/img/ps-favicon.png';
  };

  const getFormColor = () => {
    if (typeof window === 'undefined') return '#224074';
    if (window.location.href.includes('mazards')) return '#5a345a';
    if (window.location.href.includes('evcharging')) return '#3e5c08';
    if (window.location.href.includes('supersymmetrysoftware')) {
      return '#0A0242';
    }
    return '#224074';
  };

  const getTosPage = () => {
    if (typeof window === 'undefined') {
      return '#';
    }

    if (window.location.href.includes('evcharging')) {
      if (window.location.href.includes('sandbox')) {
        return 'https://alpha.evchargingsolutions.co.uk/privacy-policy/';
      } else {
        return 'https://evchargingsolutions.co.uk/privacy-policy/';
      }
    }

    if (window.location.href.includes('mazards')) {
      if (window.location.href.includes('sandbox')) {
        return 'https://sandbox.mazards.com/privacypolicy/';
      } else {
        return 'https://mazards.com/privacypolicy/';
      }
    }

    if (window.location.href.includes('pullstream')) {
      if (window.location.href.includes('sandbox')) {
        return 'https://sandbox.pullstream.com/terms-of-service';
      } else {
        return 'https://pullstream.com/terms-of-service';
      }
    }

    if (window.location.href.includes('supersymmetrysoftware')) {
      if (window.location.href.includes('sandbox')) {
        return 'https://sandbox.supersymmetrysoftware.com/terms-of-service';
      } else {
        return 'https://supersymmetrysoftware.com/terms-of-service';
      }
    }

    return '#';
  };

  return (
    <>
      <Helmet>
        <title>Apply for the job</title>
        <link rel='icon' href={getFavicon()} />
      </Helmet>

      <Box sx={{ height: '100vh', backgroundColor: '#fff' }}>
        <Grid sx={{ height: '100%', overflowY: 'auto' }} container>
          <Grid
            item
            xs={12}
            lg={7}
            sx={{ py: '44px', height: '100vh', overflowY: 'auto' }}
          >
            {isError ? (
              <Box className='pb-lg-200'>
                <Typography
                  sx={{
                    fontWeight: '700',
                    fontSize: '22px',
                    mb: '16px',
                    textAlign: 'center',
                  }}
                >
                  Error Loading Data
                </Typography>
              </Box>
            ) : vacancyData && !isLoading ? (
              <Box className='pb-lg-200'>
                <Typography
                  sx={{
                    fontWeight: '700',
                    fontSize: '22px',
                    mb: '16px',
                    textAlign: 'center',
                  }}
                >
                  {vacancyData.position_title}
                </Typography>
                <Box>{ReactHtmlParser(vacancyData.ad_text)}</Box>
              </Box>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            className='px-lg-48'
            sx={{
              height: '100%',
              pt: '28px',
              background: getFormColor(),
              overflowY: 'auto',
            }}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '22px',
                mb: '24px',
                color: '#fff',
              }}
            >
              Apply for this role
            </Typography>

            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={Yup.object({
                ...getSchemaFromColumnsForForm(candidateMeta(), 'listView'),
                cv: Yup.mixed().required('CV is required'),
                agreeTos: Yup.bool()
                  .test(
                    'is-true',
                    'you must accept the TOS',
                    (value) => value === true
                  )
                  .required('you must accept the TOS'),
                gdpr_consent: window.location.href.includes('mazards')
                  ? Yup.bool()
                      .test(
                        'is-true',
                        'you must accept',
                        (value) => value === true
                      )
                      .required('you must accept')
                  : Yup.bool().nullable(),
              })}
              onSubmit={async (
                values,
                { setSubmitting, resetForm, setFieldError }
              ) => {
                setSuccess(false);
                setError('');

                const selected = !!values.cv;

                const errs = [];

                if (!selected) {
                  errs.push(1);
                  setFieldError('cv', 'please upload your cv');
                }

                if (!values.agreeTos) {
                  errs.push(1);
                  setFieldError('agreeTos', 'you must accept the tos');
                }

                if (
                  !values.gdpr_consent &&
                  window.location.href.includes('mazards')
                ) {
                  errs.push(1);
                  setFieldError('gdpr_consent', 'you must accept');
                }

                if (errs.length) return;

                try {
                  let resumeUrl = '';

                  const formData = new FormData();
                  formData.append('anonymous_can_see_it', true);
                  formData.append('client', vacancyData?.client);
                  formData.append('created_by', vacancyData?.created_by);
                  formData.append('file', values.cv);

                  try {
                    const { data: fileData } = await createRecord({
                      values: formData,
                      url: filesUrls.list(),
                    });
                    resumeUrl = fileData.fileUrl;
                  } catch (err) {
                    console.log('err 230:', err.response || err.message);
                  }

                  if (!resumeUrl) {
                    setError('Please upload your CV again');
                    return;
                  }

                  const response = await createRecord({
                    values: {
                      ...values,
                      dob: formatToUTCDate(values.dob),
                      workflow: vacancyData?.workflow,
                      initiate_workflow: true,
                      cv: resumeUrl,
                      vacancy: vacancyId,
                      utm_campaign: utmCampaign,
                      utm_medium: utmMedium,
                      utm_source: utmSource,
                      utm_term: utmTerm,
                      utm_content: utmContent,
                      source: `${window.location.origin}/vacancies/${vacancyId}`,
                    },
                    url: candidatesUrls.list(),
                  });

                  if (response?.data) {
                    try {
                      await createRecord({
                        values: {
                          microservice: 'HR',
                          model: 'Candidate',
                          object_id: response?.data?.id,
                          ip: userIP.query,
                          city: userIP.city,
                          country: userIP.country,
                          country_code: userIP.countryCode,
                          postal_code: userIP.zip,
                          longitude: userIP.lon,
                          latitude: userIP.lat,
                          timezone: userIP.timezone,
                          utm_campaign: utmCampaign,
                          utm_medium: utmMedium,
                          utm_source: utmSource,
                        },
                        url: visitorLogUrls.list(),
                      });
                    } catch (err) {
                      console.log(
                        'Error (code 268):',
                        err.response || err.message
                      );
                    }
                  }

                  resetForm({ values: initialValues });
                  setSuccess(true);
                  setError(null);
                } catch (err) {
                  console.log(
                    'Error (code 275):',
                    err?.response?.data ?? err?.message
                  );
                  handleCreateFormErrors({
                    err,
                    setError: (error) =>
                      Object.entries(error).map((err) =>
                        setFieldError(err[0], err[1])
                      ),
                    notify: () => null,
                  });

                  const hrEmail = window.location.href.includes('mazards')
                    ? 'hr@mazards.com'
                    : window.location.href.includes('supersymmetrysoftware')
                    ? 'hr@supersymmetrysoftware.com'
                    : 'hr@pullstream.com';

                  setError(
                    `It seems you have already submitted your details in consideration for this position.  If this is not the case or there is some other mistake, please contact us at ${hrEmail} and we will be in touch.`
                  );
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({
                values,
                setFieldValue,
                handleBlur,
                handleChange,
                touched,
                errors,
                isSubmitting,
                submitCount,
              }) => (
                <Form noValidate autoComplete='off'>
                  <Grid container rowSpacing={2.5} columnSpacing={{ xs: 4 }}>
                    <Grid item xs={6}>
                      <TextField
                        submitCount={submitCount}
                        required
                        label='First name'
                        name='first_name'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        submitCount={submitCount}
                        required
                        label='Last name'
                        name='last_name'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        submitCount={submitCount}
                        required
                        label='Email'
                        name='personal_email'
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        submitCount={submitCount}
                        label='Phone'
                        name='personal_mobile'
                      />
                    </Grid>

                    {window.location.href.includes('mazards') ? null : (
                      <>
                        <Grid item xs={6}>
                          <DateTimePickerField
                            label='Date Of Birth'
                            color='white'
                            name='dob'
                            value={values.dob}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <SwitchField
                            label='Currently Employed'
                            name='currently_employed'
                            color='success'
                            value={values.currently_employed}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    )}

                    {window.location.href.includes('mazards') ? null : (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            submitCount={submitCount}
                            required
                            label='Address1'
                            name='address1'
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            submitCount={submitCount}
                            label='Address2'
                            name='address2'
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={6}>
                      <TextField
                        submitCount={submitCount}
                        required
                        label='City'
                        name='city'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectField
                        label='Country'
                        name='country'
                        value={values.country}
                        sx={{ height: '33px' }}
                        setValue={(e) =>
                          setFieldValue('country', e.target.value)
                        }
                        options={countriesList.map((country) => ({
                          id: country,
                          value: country,
                        }))}
                        required
                        onBlur={handleBlur}
                        error={
                          touched.country || errors.country
                            ? errors.country
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        submitCount={submitCount}
                        label='Linkedin Profile URL'
                        name='linkedin'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        submitCount={submitCount}
                        required
                        multiline
                        rows={5}
                        label='Please describe why you are right for this role (min 250 characters)'
                        name='cover_letter'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FileInput
                        label='Attach your CV (pdf, doc, docx accepted)'
                        color='white'
                        name='cv'
                        required
                        accept='.doc, .docx, .pdf'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {success ? (
                        <Alert severity='success'>
                          Your application has been submitted
                        </Alert>
                      ) : null}
                      {!!error ? <Alert severity='error'>{error}</Alert> : null}
                    </Grid>

                    {window.location.href.includes('mazards') ? (
                      <Grid item xs={12}>
                        <CheckBoxField
                          name='gdpr_consent'
                          alignItems='flex-start'
                          labelcolor='white'
                          label={`I agree to use my personal information for job opportunities, event invitations, networking, and tailored communication. Mazards will maintain and update my data, and I will be contacted biennially for consent review. Any third-party disclosure requires my written approval.
                        `}
                        />
                        {errors.gdpr_consent ? (
                          <FormHelperText
                            sx={{ mt: 1, color: 'red', fontWeight: '500' }}
                          >
                            {errors.gdpr_consent}
                          </FormHelperText>
                        ) : null}
                      </Grid>
                    ) : null}

                    <Grid item sx={{ pb: '100px' }} xs={9}>
                      <CheckBoxField
                        name='agreeTos'
                        labelcolor='white'
                        label={
                          <>
                            I understand and agree to the{' '}
                            <Link to={getTosPage()}>
                              <span style={{ color: 'rgb(169 201 255)' }}>
                                Terms of Service
                              </span>
                            </Link>
                          </>
                        }
                      />
                      {errors.agreeTos ? (
                        <FormHelperText
                          sx={{ mt: 1, color: 'red', fontWeight: '500' }}
                        >
                          {errors.agreeTos}
                        </FormHelperText>
                      ) : null}
                    </Grid>

                    <Grid item sx={{ pb: '100px' }} xs={3}>
                      <Button
                        sx={{
                          backgroundColor: '#669EFF',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#669EFF',
                            color: '#fff',
                          },
                        }}
                        fullWidth
                        type='submit'
                        disabled={isSubmitting}
                        variant='contained'
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
