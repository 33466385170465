import {
  formBranchingConditionTypes,
  formAccessLevels,
  formRecipientStatus,
  formDisplayMode,
  formCategoryOptions,
} from '@config/constants';

export const formRowsMap = (row) => ({
  id: row.id,
  name: row.name,
  title: row.title,
  description: row.description,
  closingMessage: row.closingMessage,
  isPublished: row.isPublished,
  showFirstName: row.showFirstName,
  showLastName: row.showLastName,
  showEmail: row.showEmail,
  allowMultipleSubmissions: row.allowMultipleSubmissions,
  displayMode: formDisplayMode.find(({ value }) => value === row?.displayMode)
    ?.label,
  category: formCategoryOptions.find(({ value }) => value === row?.category)
    ?.label,
  accessLevel: formAccessLevels.find(({ value }) => value === row?.accessLevel)
    ?.label,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const questionRowsMap = (row) => ({
  id: row?.id,
  order: row?.order,
  groupNames: row?.groupNames,
  question: row?.question,
  description: row?.description,
  type: row?.type,
  formId: row?.formId,
  includeOthersField: row?.includeOthersField,
  isRequired: row?.isRequired,
  randomizeLikertOptions: row?.randomizeLikertOptions,
  createdAt: row?.createdAt,
});

export const questionOptionGroupRowsMap = (row) => ({
  id: row.id,
  name: row.name,
  description: row.description,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const questionOptionRowsMap = (row) => ({
  id: row.id,
  order: row.order,
  option: row.option,
  isCorrect: row.isCorrect,
  questionId: row.questionId,
  questionOptionGroupId:
    row?.questionOptionGroup && !row?.questionOptionGroup?.deleted
      ? {
          id: row.questionOptionGroup.id,
          label: row.questionOptionGroup.name,
        }
      : null,
  createdAt: row.createdAt,
});

export const responseRowsMap = (row) => ({
  id: row.id,
  firstName: row?.firstName === '' ? '...' : row?.firstName,
  lastName: row.lastName,
  email: row.email,
  status: formRecipientStatus.find(({ value }) => value === row?.status)?.label,
  expiryDate: row?.recipient?.expiryDate,
  recipientId: row.recipientId,
  formId: row.formId,
  utmContent: row.utmContent,
  utmTerm: row.utmTerm,
  utmCampaign: row.utmCampaign,
  utmMedium: row.utmMedium,
  utmSource: row.utmSource,
  tags: row.tags,
  createdAt: row.createdAt,
  updatedAt: row.updatedAt,
});

export const personalityRowsMap = (row) => ({
  id: row.id,
  personalityCode: row.personalityCode,
  textToDisplay: row.textToDisplay,
  textToImprove: row.textToImprove,
  createdAt: row.createdAt,
});

export const groupRowsMap = (row) => ({
  id: row.id,
  order: row.order,
  name: row.name,
  parentGroupId:
    row?.parentGroup && !row?.parentGroup?.deleted
      ? {
          id: row.parentGroup.id,
          label: `${row.parentGroup.order}. ${row.parentGroup.name}`,
        }
      : null,
  description: row.description,
  createdAt: row.createdAt,
});

export const recommendedCourseRowsMap = (row) => ({
  id: row.id,
  courseId: row?.details?.courseId
    ? {
        id: row?.courseId,
        label: row?.details?.courseId?.name,
      }
    : null,
  createdAt: row?.createdAt,
});

export const branchingConditionRowsMap = (row) => ({
  id: row.id,
  order: row.order,
  currentGroupId:
    row?.currentGroup && !row?.currentGroup?.deleted
      ? {
          id: row.currentGroup.id,
          label: `${row.currentGroup.order}. ${row.currentGroup.name}`,
        }
      : null,
  sourceGroupId:
    row?.sourceGroup && !row?.sourceGroup?.deleted
      ? {
          id: row.sourceGroup.id,
          label: `${row.sourceGroup.order}. ${row.sourceGroup.name}`,
        }
      : null,
  targetGroupId:
    row?.targetGroup && !row?.targetGroup?.deleted
      ? {
          id: row.targetGroup.id,
          label: `${row.targetGroup.order}. ${row.targetGroup.name}`,
        }
      : null,
  questionId:
    row?.question && !row?.question?.deleted
      ? {
          id: row.question.id,
          label: `${row.question.order}. ${row.question.question}`,
        }
      : null,
  questionOptionId:
    row?.questionOption && !row?.questionOption?.deleted
      ? {
          id: row.questionOption.id,
          label: `${row.questionOption.order}. ${row.questionOption.option}`,
        }
      : null,
  questionOptionGroupId:
    row?.questionOptionGroup && !row?.questionOptionGroup?.deleted
      ? {
          id: row.questionOptionGroup.id,
          label: row.questionOptionGroup.name,
        }
      : null,

  staticValue: row.staticValue,
  type: formBranchingConditionTypes.find(({ value }) => value === row.type)
    ?.label,
  concatenator: row.concatenator,
  createdAt: row.createdAt,
});

export const questionGroupRowsMap = (row) => ({
  id: row.id,
  questionId:
    row?.question && !row?.question?.deleted
      ? {
          id: row.question.id,
          label: `${row.question.order}. ${row.question.question}`,
        }
      : null,
  groupId:
    row?.group && !row?.group?.deleted
      ? {
          id: row.group.id,
          label: `${row.group.order}. ${row.group.name}`,
        }
      : null,
  createdAt: row.createdAt,
});

export const formRecipientsRowMap = (row) => ({
  id: row.id,
  firstName: row?.response?.firstName,
  lastName: row?.response?.lastName,
  email: row?.response?.email,
  expiryDate: row.expiryDate,
  response: row?.response,
  createdAt: row.createdAt,
  status: formRecipientStatus.find(
    ({ value }) => value === row?.response?.status
  )?.label,
});

export const questionOptionGroupScoreRowMap = (row) => ({
  id: row.id,
  minScore: String(row?.minScore),
  maxScore: String(row?.maxScore),
  createdAt: row.createdAt,
});
