import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useToast from '@hooks/useToast';
import { updateRecord } from '@config/functions/requests';
import { crmUrls } from '@config/routes';

const { marketingListsUrls } = crmUrls;

export default function MarketingListConfirmDialog({
  open,
  setOpen,
  user,
  marketingListId,
}) {
  const [notify] = useToast();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setOpen(false);

      await updateRecord({
        url: marketingListsUrls.detail(marketingListId),
        values: { trigger: true },
        token: user?.token,
        actAs: user?.actAs,
      });

      notify('The records will be processed shortly.', {
        type: 'SUCCESS',
      });
    } catch (error) {
      console.log('error', error?.response?.data ?? error?.message);
      notify('Failed to process records.', {
        type: 'ERROR',
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will trigger the automata for every record in the marketing list.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
