import React from 'react';
import Button from '@ui/Button';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import MarketingListConfirmDialog from '@components/CRM/MarketingListConfirmDialog';
import { crmUrls } from '@config/routes';
import { marketingListMeta } from '@config/meta/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { marketingListPersonsRowsMap } from '@config/handleRows/crm';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';

const { marketingListsUrls, personMarketingListsUrls } = crmUrls;

export default function MarketingListDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [confirmPopup, setConfirmPopup] = React.useState(false);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => marketingListMeta({ data }),
    []
  );

  const personsColOptions = React.useMemo(() => {
    return {
      inMarketingList: true,
    };
  }, []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/marketing-lists', text: 'Marketing Lists' },
              { text: recordData?.name },
            ]}
          >
            <Button
              type="button"
              variant="outlined"
              label="Process Records"
              onClick={() => setConfirmPopup(true)}
            />
          </DetailPageHeader>
        </Box>
      ) : null}

      <MarketingListConfirmDialog
        user={user}
        open={confirmPopup}
        setOpen={setConfirmPopup}
        marketingListId={recordId}
      />

      <DetailCard
        urls={marketingListsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.persons}
          columnKey="personsCRM"
          columnOptions={personsColOptions}
          label="Persons"
          editMode={editMode}
          labels={labels}
          prefix="01s"
          tableBulkOptions={[{ id: 'addToCallList', text: 'Add to call list' }]}
          urls={personMarketingListsUrls}
          getCustomUrl={({ row }) => personMarketingListsUrls.detail(row.id)}
          handleRowMap={marketingListPersonsRowsMap}
          urlParams={`&marketing_list=${recordId}`}
        />
      </Box>
    </Box>
  );
}
