import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { FieldArray } from 'formik';
import ContextFieldAccordion from '@components/BPA-V2/Popups/ChatGPTTextPopup/ContextFieldAccordion';
import ContextFieldCard from '@components/BPA-V2/Popups/ChatGPTTextPopup/ContextFieldCard';
import {
  hasKeyIncludingSubstring,
  getContextFields,
} from '@config/functions/chatGPTTextPopupFunctions';

export default function ContextFieldsList({
  shape,
  shapeAction,
  values,
  setValues,
  errors,
  setFieldValue,
  workflowId,
  workflowName,
  showAddReminder,
  setShowAddReminder,
}) {
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const deleteContextField = (idx) => {
    const newValues = { ...values };
    const keysThatNeedsToBeModified = [];
    const keysThatNeedsToBeDeleted = [];

    const beforeHandContextFields = values.context_fields.slice(0, idx);
    const oldFields = getContextFields(idx);

    Object.entries(oldFields).map(([key, value]) => {
      keysThatNeedsToBeDeleted.push(value);
    });

    const newContextFields = [
      ...beforeHandContextFields,
      ...values.context_fields.slice(idx + 1).map((context_field, eidx) => {
        const oldFields = getContextFields(idx + eidx);
        const fields = getContextFields(idx + eidx + 1);
        const response = {
          payload_section_key_reference:
            context_field.payload_section_key_reference,
        };

        Object.entries(fields).map(([key, value]) => {
          response[oldFields[key]] = context_field[value];
          keysThatNeedsToBeModified.push([oldFields[key], values[value]]);
        });

        return response;
      }),
    ];

    const lastFields = getContextFields(newContextFields.length);

    Object.entries(lastFields).map(([key, value]) => {
      keysThatNeedsToBeDeleted.push(value);
    });

    newValues.context_fields = newContextFields;

    keysThatNeedsToBeDeleted.map((key) => {
      delete newValues[key];
    });

    keysThatNeedsToBeModified.map(([key, value]) => {
      newValues[key] = value;
    });

    setValues(newValues);
  };

  return (
    <Box>
      <ContextFieldAccordion
        open={open}
        setOpen={handleToggle}
        label="Context Fields"
        isError={hasKeyIncludingSubstring(errors, 'context_fields')}
      >
        <FieldArray name="context_fields">
          {({ push }) => (
            <Stack spacing={2}>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const fields = getContextFields(
                      values?.context_fields.length
                    );
                    const uniqueKey = uuidv4();

                    push({
                      [fields.name]: '',
                      [fields.key]: '',
                      [fields.value]: '',
                      payload_section_key_reference: `payload_section_${uniqueKey}`,
                    });
                  }}
                >
                  Add New
                </Button>
              </Box>

              <Box>
                {values?.context_fields?.map((contextField, index) => (
                  <ContextFieldCard
                    index={index}
                    setFieldValue={setFieldValue}
                    remove={deleteContextField}
                    workflowId={workflowId}
                    workflowName={workflowName}
                    showAddReminder={showAddReminder}
                    setShowAddReminder={setShowAddReminder}
                    shape={shape}
                    shapeAction={shapeAction}
                    values={values}
                    contextField={contextField}
                    errors={errors}
                  />
                ))}
              </Box>
            </Stack>
          )}
        </FieldArray>
      </ContextFieldAccordion>
    </Box>
  );
}
