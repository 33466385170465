import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import EmailTemplateDropdown from '@dropdown-forms/communication/EmailTemplateDropdown';
import { AutocompleteFieldV2 } from '@components/Inputs';
import { bpaUrls } from '@config/routes';

const { modelAssetsUrls } = bpaUrls;

/**
 * EmailTemplateField provides a dropdown for selecting an email template
 * and updates the context fields based on the selected template.
 */
export default function EmailTemplateField({
  isKanbanView,
  workflowId,
  shapeActionId,
  initialStage,
  setContextFields,
  setFieldValue,
  selected,
}) {
  const queryClient = useQueryClient();

  // Handles refetching of templates and updates the selected template value
  const handleRefetch = ({ id, doNotAssign, details, contextFields = [] }) => {
    if (!doNotAssign) {
      setFieldValue('template', {
        id: id ?? null,
        label: details?.asset?.name ?? 'None',
        contextFields,
      });

      setContextFields(contextFields);
    }

    queryClient.invalidateQueries([
      `${shapeActionId}-popup-email-template-${workflowId}`,
    ]);
  };

  // Renders email template options
  const renderEmailTemplate = (row) => {
    const { name, details } = row?.details?.asset ?? {};
    const contextFields = Array.isArray(details?.context_fields)
      ? details.context_fields
      : [];

    return {
      id: row?.id,
      label: name,
      contextFields,
    };
  };

  return (
    <EmailTemplateDropdown
      workflow={workflowId}
      stage={initialStage}
      selectedModelAsset={selected?.id}
      refetch={handleRefetch}
      actionsDisabled={isKanbanView}
    >
      <AutocompleteFieldV2
        name="template"
        label="Template"
        urlParams={`&microservice=Notification&model=MailTemplate&workflow=${workflowId}`}
        requestKey={`${shapeActionId}-popup-email-template-${workflowId}`}
        fetchUrl={modelAssetsUrls.list}
        renderRow={renderEmailTemplate}
        onChange={({ contextFields } = {}) => {
          setContextFields(contextFields);
          setFieldValue('contextFields', {});
        }}
        required
      />
    </EmailTemplateDropdown>
  );
}

EmailTemplateField.propTypes = {
  workflowId: PropTypes.string.isRequired,
  contextFieldInit: PropTypes.bool.isRequired,
  shapeActionId: PropTypes.string.isRequired,
  initialStage: PropTypes.object.isRequired,
  setContextFields: PropTypes.func.isRequired,
  setContextFieldInit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  actionParams: PropTypes.array.isRequired,
};
