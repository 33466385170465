import { Box } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import ObjectLevelPermissionForm from './ObjectLevelPermissionForm';
import { useState } from 'react';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function VisibilityPromptModal({
  open,
  onCancelClick,
  customInitialValues,
  onUpdateClick,
  includeClient = false,
  clients,
  setClientTerm,
  fetchingClients,
  roles,
  setRoleTerm,
  rolesIsFetching,
  users,
  setUserTerm,
  usersIsFetching,
  setFieldValue,
  values,
  disabled = false,
  labels,
  editMode,
  prefix,
}) {
  const [customValues, setCustomValues] = useState({ ...values });

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open}>
      {prefix ? (
        <RenderUiPrefix
          editMode={editMode}
          labels={labels}
          code={prefix}
          defaultValue='Visibility'
        >
          {(text) => {
            return <DialogTitle>{text}</DialogTitle>;
          }}
        </RenderUiPrefix>
      ) : (
        <DialogTitle>Visibility</DialogTitle>
      )}

      <DialogContent>
        <ObjectLevelPermissionForm
          includeClient={includeClient}
          clients={clients}
          setClientTerm={setClientTerm}
          fetchingClients={fetchingClients}
          roles={roles}
          setRoleTerm={setRoleTerm}
          rolesIsFetching={rolesIsFetching}
          users={users}
          setUserTerm={setUserTerm}
          usersIsFetching={usersIsFetching}
          setCustomValues={setCustomValues}
          values={customValues}
          disabled={disabled}
        />
        <Box sx={{ display: 'flex' }}>
          {!disabled ? (
            <Box sx={{ mr: 'auto' }}>
              <DialogActions>
                <Button
                  onClick={() => {
                    setCustomValues({
                      everyone_can_see_it: Boolean(
                        customInitialValues.everyone_can_see_it
                      ),
                      anonymous_can_see_it: Boolean(
                        customInitialValues.anonymous_can_see_it
                      ),
                      everyone_in_object_company_can_see_it: true,
                      only_these_roles_can_see_it:
                        customInitialValues.only_these_roles_can_see_it || {},
                      only_these_users_can_see_it:
                        customInitialValues.only_these_users_can_see_it || {},
                      only_these_clients_can_see_it:
                        customInitialValues.only_these_clients_can_see_it || {},
                    });
                  }}
                >
                  Reset To Defaults
                </Button>
              </DialogActions>
            </Box>
          ) : null}

          <Box sx={{ ml: 'auto' }}>
            <DialogActions>
              <Button
                onClick={() => {
                  setCustomValues({ ...values });
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              {!disabled ? (
                <Button
                  onClick={() => {
                    setFieldValue(
                      'everyone_can_see_it',
                      customValues.everyone_can_see_it
                    );
                    setFieldValue(
                      'anonymous_can_see_it',
                      customValues.anonymous_can_see_it
                    );
                    setFieldValue(
                      'everyone_in_object_company_can_see_it',
                      customValues.everyone_in_object_company_can_see_it
                    );
                    setFieldValue(
                      'only_these_roles_can_see_it',
                      customValues.only_these_roles_can_see_it
                    );
                    setFieldValue(
                      'only_these_users_can_see_it',
                      customValues.only_these_users_can_see_it
                    );
                    setFieldValue(
                      'only_these_clients_can_see_it',
                      customValues.only_these_clients_can_see_it
                    );
                    onUpdateClick();
                  }}
                >
                  Save
                </Button>
              ) : null}
            </DialogActions>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
