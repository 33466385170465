import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { pmUrls, nodeDriveUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { backlogMeta } from '@config/meta/pm/pmMeta';
import {
  uatRowsMap,
  backlogArtifactRowsMap,
  backlogTaskRowsMap,
} from '@config/handleRows/pm';
import { UatForm, BacklogArtifactForm } from '@config/forms/pm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { backlogsUrls, uatsUrls, backlogArtifactsUrls, taskBacklogsUrls } =
  pmUrls;
const { filesUrls } = nodeDriveUrls;

export default function BacklogDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const [uatsListData, setUatsListData] = React.useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      backlogMeta({
        data,
        queryClient,
      }),
    []
  );

  const acceptanceTestsColOptions = React.useMemo(() => {
    return {
      nextOrder: uatsListData?.totalCount ?? 1,
    };
  }, [uatsListData?.totalCount]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/hlrs/${recordData.hlr}`,
                text: 'HLRs',
              },
              {
                to: `/hlrs/${recordData.hlr}`,
                text: recordData.details.hlr.name,
              },

              { to: `/hlrs/${recordData.hlr}`, text: 'Backlogs' },
              { text: recordData && recordData.i_want },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={backlogsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          prefix="0sk"
          labels={labels}
          editMode={editMode}
          microservice={microservices.PM.name}
          model={microservices.PM.models.backlogArtifact}
          columnKey="backlogArtifactPM"
          label="Artifacts"
          urls={backlogArtifactsUrls}
          addNewForm={{
            getFields: ({ values, errors }) =>
              BacklogArtifactForm({ values, errors }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                let file = values.drive;

                const formData = new FormData();
                formData.append('anonymous_can_see_it', true);
                formData.append('file', file);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                file = fileData.fileUrl;

                const response = await createRecord({
                  values: { ...values, drive: file, backlog: recordId },
                  url: backlogArtifactsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={backlogArtifactRowsMap}
          urlParams={`&backlog=${recordId}`}
        />
        <DetailAccordionView
          columnKey="backlogTasksPM"
          label="Tasks"
          prefix="07x"
          labels={labels}
          editMode={editMode}
          urls={taskBacklogsUrls}
          handleRowMap={backlogTaskRowsMap}
          urlParams={`&backlog=${recordId}`}
        />
        <DetailAccordionView
          label="Acceptance Tests"
          prefix="08u"
          labels={labels}
          editMode={editMode}
          microservice={microservices.PM.name}
          model={microservices.PM.models.uats}
          columnKey="uatPM"
          columnOptions={acceptanceTestsColOptions}
          urls={uatsUrls}
          addNewForm={{
            getFields: () => UatForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, name: '...', backlog: recordId },
                  url: uatsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={uatRowsMap}
          urlParams={`&backlog=${recordId}`}
          customSortColumnName="order"
          sortColumnBy="asc"
          getListData={(data) => setUatsListData(data)}
        />
      </Box>
    </Box>
  );
}
