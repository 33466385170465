import React from 'react';
import { Box, Typography } from '@mui/material';

export default function FormSubmitError({ heading, error }) {
  return (
    <Box
      sx={{
        minWidth: '320px',
        maxWidth: '790px',
        mx: 'auto',
        my: '22px',
        py: '18px',
        px: '24px',
        background: 'rgb(253, 237, 237)',
        border: '1px solid #ef5350',
        borderRadius: '8px',
        color: 'rgb(95, 33, 32)',
      }}
    >
      <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
        {heading ?? 'Failed to submit the form'}
      </Typography>

      <ul
        style={{
          margin: '0',
          mt: '22px',
          fontSize: '14px',
          paddingLeft: '12px',
        }}
      >
        {error.map((e) => (
          <li key={e}>{e}</li>
        ))}
      </ul>
    </Box>
  );
}
