import * as React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { nodeDriveUrls } from '@config/routes';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import StyledMenu from '@ui/Drive/StyledMenu';
import useToast from '@hooks/useToast';
import { useQueryClient } from 'react-query';
import Dropzone from 'react-dropzone';

const { foldersUrls, filesUrls } = nodeDriveUrls;

export default function UploadBtn({ folderId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const fileRef = React.useRef(null);
  const folderRef = React.useRef(null);
  const user = useSelector(selectProfile);
  const [notify, update] = useToast();
  const queryClient = useQueryClient();

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleFileClick = () => fileRef.current?.click();

  const handleFolderClick = () => folderRef.current?.click();

  const handleFileUpload = async (file, folderId, silentToast) => {
    if (!file) return;

    let tId;

    if (!silentToast) {
      tId = notify('Uploading....', {
        loading: true,
      });
    }

    try {
      const payload = new FormData();
      payload.append('everyone_can_see_it', false);
      payload.append('anonymous_can_see_it', false);
      payload.append('everyone_in_object_company_can_see_it', true);
      if (folderId) {
        payload.append('folderId', folderId);
      }
      payload.append('file', file);

      await createRecord({
        values: payload,
        url: filesUrls.list(),
        token: user.token,
        company: user?.details?.company,
      });

      update(tId, 'Upload complete');
      queryClient.invalidateQueries(['My-Drive-listData']);
    } catch (err) {
      update(tId, 'Upload failed', { type: 'ERROR' });
    }
  };
  const handleFolderUpload = async (selectedFiles, folderId) => {
    if (!selectedFiles) return; // Check if selectedFiles is null or undefined, if so, return and exit the function

    const tId = notify('Uploading....', {
      loading: true,
    });

    try {
      const folders = {}; // Object to store information about folders

      for (const selectedFile of selectedFiles) {
        // Loop through each selected file
        // Skip .DS_Store files
        if (selectedFile.name === '.DS_Store') continue; // If the file name is '.DS_Store', skip to the next iteration of the loop

        const filePath = selectedFile.path.split('/').slice(0, -1); // Get the path of the selected file and remove the last element (file name)
        let currentFolder = { data: { id: folderId } }; // Set the currentFolder variable to an object with a data property containing the folderId

        for (const [index, folderName] of filePath.entries()) {
          // Loop through each folder in the file path
          if (folders[index]?.[folderName]) {
            // If the folder at the current index and with the current folderName exists in the folders object
            currentFolder = folders[index][folderName]; // Update the currentFolder variable to the existing folder
          } else {
            const values = currentFolder?.data?.id
              ? {
                  name: folderName,
                  parentId: currentFolder.data.id,
                }
              : {
                  name: folderName,
                }; // Set the values for creating a new folder, including the name and optional parentId

            currentFolder = await createRecord({
              // Create a new folder using the createRecord function
              url: foldersUrls.list(),
              values: {
                ...values,
                everyone_can_see_it: false,
                anonymous_can_see_it: false,
                everyone_in_object_company_can_see_it: true,
                only_these_roles_can_see_it: {},
                only_these_users_can_see_it: {},
              },
              token: user.token,
              company: user?.details?.company,
            });

            folders[index] = { ...folders[index], [folderName]: currentFolder }; // Update the folders object with the new folder
          }

          if (index === filePath.length - 1) {
            // If it's the last folder in the file path
            handleFileUpload(selectedFile, currentFolder?.data?.id, true); // Call the handleFileUpload function with the selected file, current folder ID, and a flag indicating it's the last folder
          }
        }
      }

      update(tId, 'Upload complete');
      queryClient.invalidateQueries(['My-Drive-listData']);
    } catch (err) {
      console.log(err.message);
      update(tId, 'Upload failed', { type: 'ERROR' });
    }
  };

  return (
    <Box>
      <Button
        id='upload-button'
        aria-controls={open ? 'upload-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        startIcon={<FileUploadOutlinedIcon />}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Upload
      </Button>

      <input
        type='file'
        ref={fileRef}
        onChange={(e) => {
          const [file] = e.target.files;
          handleFileUpload(file, folderId);
        }}
        style={{ display: 'none' }}
      />

      <Dropzone onDrop={(files) => handleFolderUpload(files, folderId)}>
        {({ getRootProps, getInputProps }) => (
          <Box {...getRootProps({ className: 'dropzone' })}>
            <input
              {...getInputProps()}
              directory=''
              webkitdirectory=''
              type='file'
              ref={folderRef}
            />
          </Box>
        )}
      </Dropzone>

      <StyledMenu
        id='upload-menu'
        MenuListProps={{
          'aria-labelledby': 'upload-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleFileClick} disableRipple>
          <UploadFileOutlinedIcon />
          Files
        </MenuItem>
        <MenuItem onClick={handleFolderClick} disableRipple>
          <DriveFolderUploadOutlinedIcon />
          Folder
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}
