import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { inventoryUrls, nodeDriveUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import {
  ProductVariantForm,
  ProductArtifactForm,
  InventoryForm,
} from '@config/forms/inventory';
import {
  productMeta,
} from '@config/meta/inventory';
import DetailPageHeader from '@components/DetailPageHeader';
import { microservices } from '@config/constants';
import {
  productVariantRowMap,
  productArtifactRowMap,
  inventoryRowMap,
} from '@config/handleRows/inventory';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const {
  productsUrls,
  productVariantsUrls,
  productArtifactsUrls,
  inventorysUrls,
} = inventoryUrls;
const { filesUrls } = nodeDriveUrls;

export default function ManufacturerDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();

  const getSchema = React.useCallback(
    (data) => productMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key="detail-view-header"
            items={[
              { to: `/products`, text: 'Products' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={productsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix="0za"
          microservice={microservices.INVENTORY.name}
          model={microservices.INVENTORY.models.productVariant}
          columnKey="productVariantInventory"
          label="Product Variants"
          urls={productVariantsUrls}
          addNewForm={{
            getFields: () => ProductVariantForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                await createRecord({
                  values: {
                    ...values,
                    product: recordId,
                  },
                  url: productVariantsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={productVariantRowMap}
          urlParams={`&product=${recordId}`}
        />
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix="0hv"
          microservice={microservices.INVENTORY.name}
          model={microservices.INVENTORY.models.productArtifact}
          columnKey="productArtifactInventory"
          label="Product Artifacts"
          urls={productArtifactsUrls}
          addNewForm={{
            getFields: ({ values, errors }) =>
              ProductArtifactForm({ values, errors }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                let file = values.artifact;

                const formData = new FormData();
                formData.append('anonymous_can_see_it', true);
                formData.append('file', file);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                file = fileData.fileUrl;

                const response = await createRecord({
                  values: { ...values, artifact: file, product: recordId },
                  url: productArtifactsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                // notify(`Product Artifact Is Created!`, {
                //   type: 'SUCCESS',
                // });
                refetch();
                return response?.data?.id;
              } catch (err) {
                if (
                  err.response &&
                  err.response.status === 400 &&
                  err.response.data
                ) {
                  setError(err.response.data);
                } else {
                  notify(`There was an error, please try again.`, {
                    type: 'ERROR',
                  });
                }
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={productArtifactRowMap}
          urlParams={`&product=${recordId}`}
        />
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix="016"
          microservice={microservices.INVENTORY.name}
          model={microservices.INVENTORY.models.inventory}
          columnKey="inventoryInventory"
          label="Inventorys"
          urls={inventorysUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              InventoryForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { warehouse, ...rest } = values;

                await createRecord({
                  values: {
                    ...rest,
                    warehouse: warehouse?.id,
                    product: recordId,
                  },
                  url: inventorysUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                if (
                  err.response &&
                  err.response.status === 400 &&
                  err.response.data
                ) {
                  setError(err.response.data);
                } else {
                  notify(`There was an error, please try again.`, {
                    type: 'ERROR',
                  });
                }
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={inventoryRowMap}
          urlParams={`&product=${recordId}`}
        />
      </Box>
    </Box>
  );
}
