import axios from 'axios';
import { useDispatch } from 'react-redux';
// import jwtDecode from 'jwt-decode';
import { getUserStorageKey } from '@config/constants';
import { parseJSON, getAuthHeader } from '@config/functions/helperFunctions';
import { accountUrls } from '@config/routes';
import { setProfile } from '@redux/profileSlice';
import { useNavigate } from 'react-router-dom';

const { tokenRefreshUrls } = accountUrls;

export default function useAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userKey = getUserStorageKey();

  const getToken = async ({ callback = () => null } = {}) => {
    // get the JWT token and refresh token from local storage
    const { data: localStorageData } = parseJSON(localStorage.getItem(userKey));

    if (!localStorageData) {
      localStorage.removeItem(userKey);
      console.log('obi-useAuth-redirect');
      navigate('/auth/login');
      return;
    }

    const localStorageToken = localStorageData.token;
    const refreshToken = localStorageData.refreshToken;
    const detailsExist = 'details' in localStorageData;
    const clientId = detailsExist && localStorageData.details.client;

    // if the token or refresh token doesn't exist in local storage, redirect to login page
    if (!localStorageToken || !refreshToken) {
      localStorage.removeItem(userKey);
      console.log('obi-useAuth-redirect');
      navigate('/auth/login');
      return;
    }

    // if the token exists in local storage, check if it is expired
    try {
      // const decodedToken = jwtDecode(localStorageToken);
      // const currentTime = Date.now() / 1000;

      // // if the token exists and is not expired, update redux state
      // if (decodedToken.exp > currentTime) {
      //   dispatch(setProfile(localStorageData));
      //   callback();
      //   return;
      // }

      // console.log('obi-token-expired');

      // if the token is expired, refresh the token using the refresh token
      const refreshResponse = await axios.post(
        tokenRefreshUrls.list(),
        {
          refresh: refreshToken,
        },
        getAuthHeader(localStorageToken, clientId)
      );

      // if there is an error refreshing the token or the token is invalid, clear local storage and redux state and redirect to login page
      if (refreshResponse?.error || !refreshResponse?.data?.access) {
        localStorage.removeItem(userKey);
        console.log('obi-useAuth-redirect');
        navigate('/auth/login');
        return;
      }

      const profile = {
        ...localStorageData,
        token: refreshResponse?.data?.access,
      };

      // if the token is successfully refreshed, update the redux state and local storage
      localStorage.setItem(userKey, JSON.stringify(profile));
      dispatch(setProfile(profile));
      callback();
    } catch (error) {
      // if there is an error with the token, clear local storage and redux state and redirect to login page
      console.log(error.response || error.message);
      localStorage.removeItem(userKey);
      console.log('obi-useAuth-redirect');
      navigate('/auth/login');
    }
  };

  return { getToken };
}
