import React from 'react';
import Typography from '@mui/material/Typography';

export default function PlatformHeader({ text }) {
  return (
    <Typography
      sx={{
        fontSize: '15px',
        fontWeight: '600',
        color: 'rgb(61, 61, 61)',
        mb: 0.2,
      }}
    >
      {text}
    </Typography>
  );
}
