import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { communicationUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { imapProviderRowsMap } from '@config/handleRows/communication';
import { ImapProviderForm } from '@config/forms/communication';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { imapProvidersUrls } = communicationUrls;

export default function ImapProviders({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label="Imap Providers"
        prefix="0sf"
        labels={labels}
        editMode={editMode}
        microservice={microservices.NOTIFICATION.name}
        model={microservices.NOTIFICATION.models.imapProvider}
        urls={imapProvidersUrls}
        columnKey="imapProviderCommunication"
        addNewForm={{
          getFields: () => ImapProviderForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: imapProvidersUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify('Imap Provider Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={imapProviderRowsMap}
      />
    </Box>
  );
}
