import React from 'react';
import * as Yup from 'yup';
import { pmUrls } from '@config/routes';
import { projectMeta } from '@config/meta/pm/pmMeta';
import { Grid } from '@mui/material';
import {
  TextField,
  DateTimePickerField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import ProgramDropdown from '@dropdown-forms/pm/ProgramDropdown';
import { useQueryClient } from 'react-query';

const { projectsUrls, programsUrls } = pmUrls;

export default function TemplateDropdown({
  children,
  refetch,
  program: initialProgram,
}) {
  const [open, setOpen] = React.useState(false);
  const [hidePrograms, setHidePrograms] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        hidden={hidePrograms}
        title="Template"
        initialValues={{
          ...getAddFormInitialValues(projectMeta(), 'listView'),
          program: initialProgram,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(projectMeta(), 'listView'),
        })}
        getPostUrl={() => projectsUrls.list()}
        getValues={({ program, ...values }) => ({
          ...values,
          template: true,
          program: program?.id,
        })}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <TextField key="name" label="Name" name="name" required />
            </Grid>
            <Grid item xs={6}>
              <TextField
                multiline
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePickerField label="Start Date" name="started" showTime />
            </Grid>
            <Grid item xs={6}>
              <ProgramDropdown
                hideParent={setHidePrograms}
                refetch={({ id, name }) => {
                  setFieldValue('program', {
                    id: id,
                    label: `${name || ''}`,
                  });
                  queryClient.invalidateQueries(['template-dropdown-program']);
                }}
              >
                <AutocompleteFieldV2
                  name="program"
                  label="Programs"
                  requestKey="template-dropdown-program"
                  fetchUrl={programsUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                  required
                />
              </ProgramDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
