import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useAuth from '@hooks/useAuth';

const styles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export default function RequireAuth({ children }) {
  const [isLoading, setLoading] = React.useState(true);
  const userInfo = useSelector(selectProfile);
  const { getToken } = useAuth();

  React.useEffect(() => {
    getToken({
      callback: () => {
        setLoading(false);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Box sx={styles}>
        <CircularProgress />
      </Box>
    );
  }

  return children(userInfo);
}
