import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import DetailCard from '@components/core/DetailCard';
import DetailPageHeader from '@components/DetailPageHeader';
import Button from '@ui/Button';
import { calendarUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import ContextFieldDialog from '@components/Communication/ContextFieldDialog';
import { linkMeta } from '@config/meta/calendar/calendarMeta';
import {
  linkSlotHandleRow,
  linkRecipientsRowMap,
} from '@config/handleRows/calendar';
import { LinkSlotForms, LinkRecipientForm } from '@config/forms/calendar';
import { microservices } from '@config/constants';
import { appHost } from '@config/msHosts';
// import useAutocomplete from '@hooks/useAutocomplete';
import { useParams } from 'react-router-dom';

const { linksUrls, linkRecipientsUrls, linkSlotsUrls } = calendarUrls;

export default function LinkSlotDetails({
  performingAction,
  setPerformingAction,
  labels,
  editMode,
}) {
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = useState(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const [notify] = useToast();

  const [selectedMailTemplate, setSelectedMailTemplate] = useState(null);
  const [contextFieldDialogOpen, setContextFieldDialogOpen] = useState(false);
  const [contextFieldValues, setContextFieldValues] = useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => linkMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/slot-links`, text: 'Slot Links' },
              { text: recordData?.name },
            ]}
          >
            <Button
              type='button'
              variant='outlined'
              label={linkCopied ? 'Link Copied' : 'Copy Link'}
              onClick={() => {
                const url = `${appHost}/slots/${recordId}`;
                navigator.clipboard.writeText(recordData?.shortened_url || url);
                setLinkCopied(true);
                setTimeout(() => setLinkCopied(false), 1700);
              }}
            />
            <a
              key='slot-link'
              href={recordData?.shortened_url || `/slots/${recordId}`}
              target='_blank'
              rel='noreferrer'
            >
              <Button
                key='copy-Preview'
                type='button'
                variant='outlined'
                label='Preview'
                sx={{ ml: 1 }}
              />
            </a>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={linksUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          columnKey='linkSlotCalendar'
          label='Slots'
          prefix='00r'
          labels={labels}
          editMode={editMode}
          microservice={microservices.CALENDAR.name}
          model={microservices.CALENDAR.models.slotLink}
          performingAction={performingAction}
          setPerformingAction={setPerformingAction}
          urls={linkSlotsUrls}
          addNewForm={{
            getFields: () => LinkSlotForms(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { slot, ...rest } = values;

                const response = await createRecord({
                  values: {
                    ...rest,
                    link: recordId,
                    slot: slot?.id,
                  },
                  url: linkSlotsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                notify(`Operation completed`, {
                  type: 'SUCCESS',
                });
                refetch();
                return response.data.id;
              } catch (err) {
                if (
                  err.response &&
                  err.response.status === 400 &&
                  err.response.data
                ) {
                  setError(err.response.data);
                } else {
                  notify(`There was an error, please try again.`, {
                    type: 'ERROR',
                  });
                }
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={linkSlotHandleRow}
          urlParams={`&link=${recordId}`}
        />
        <DetailAccordionView
          columnKey='linkRecipientCalendar'
          label='Recipients'
          prefix='0tl'
          labels={labels}
          editMode={editMode}
          microservice={microservices.CALENDAR.name}
          model={microservices.CALENDAR.models.linkRecipient}
          performingAction={performingAction}
          setPerformingAction={setPerformingAction}
          urls={linkRecipientsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              LinkRecipientForm({
                setFieldValue,
                setSelectedMailTemplate,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { person, mail_template, ...rest } = values;

                if (
                  selectedMailTemplate &&
                  selectedMailTemplate?.contextFields?.length &&
                  (!Object.keys(contextFieldValues).length ||
                    selectedMailTemplate.contextFields
                      .map(
                        (contextField) =>
                          contextField.name in contextFieldValues
                      )
                      .includes(false))
                ) {
                  console.log('HERE!!!!');
                  setContextFieldDialogOpen(true);
                  return;
                }

                const response = await createRecord({
                  values: {
                    ...rest,
                    custom_context: contextFieldValues,
                    mail_template: mail_template?.id,
                    person: person?.id,
                    link: recordId,
                  },
                  url: linkRecipientsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                notify(`Operation completed`, {
                  type: 'SUCCESS',
                });
                refetch();
                return response.data.id;
              } catch (err) {
                if (
                  err.response &&
                  err.response.status === 400 &&
                  err.response.data
                ) {
                  setError(err.response.data);
                } else {
                  notify(`There was an error, please try again.`, {
                    type: 'ERROR',
                  });
                }
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={linkRecipientsRowMap}
          urlParams={`&link=${recordId}`}
        />

        <ContextFieldDialog
          contextFields={selectedMailTemplate?.contextFields || []}
          open={contextFieldDialogOpen}
          setOpen={setContextFieldDialogOpen}
          contextFieldValues={contextFieldValues}
          getValues={(values) => {
            setContextFieldValues(values);
            setContextFieldDialogOpen(false);
          }}
        />
      </Box>
    </Box>
  );
}
