import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { webChatUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { agentRowsMap } from '@config/handleRows/webChat';
import { AgentForm } from '@config/forms/webChat';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { agentsUrls } = webChatUrls;

export default function Agents({ editMode, labels }) {
  const userId = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label='Agents'
        prefix='0ai'
        labels={labels}
        editMode={editMode}
        microservice={microservices.WebChat.name}
        model={microservices.WebChat.models.agents}
        urls={agentsUrls}
        columnKey='agentsWebChat'
        addNewForm={{
          getFields: ({ setFieldValue }) => AgentForm({ setFieldValue }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { user, ...rest } = values;
              const response = await createRecord({
                values: {
                  user: user?.id,
                  ...rest,
                },
                url: agentsUrls.list(),
                token: userId.token,
                client: userId?.details?.client,
              });
              resetForm();
              setAddNewForm(false);
              // notify('Agent Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={agentRowsMap}
      />
    </Box>
  );
}
