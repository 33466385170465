import moments from 'moment-timezone';

export const computeForeignKeyTargets = [
  { label: 'Internal', value: 'Internal' },
  { label: 'External', value: 'External' },
];

export const formAccessLevels = [
  { label: 'Private', value: 'PRIVATE' },
  { label: 'Public', value: 'PUBLIC' },
];

export const AUTOMATA_ASSET_TYPES = [
  { label: 'Mail Template', value: 'MailTemplate' },
  { label: 'Form', value: 'Forms' },
  { label: 'eDoc', value: 'eDoc' },
];

export const CRITERIA_CHOICES = [
  { label: 'IP', value: 'IP' },
  { label: 'Browser', value: 'Browser' },
  { label: 'OS', value: 'OS' },
  { label: 'City', value: 'City' },
  { label: 'Country', value: 'Country' },
  { label: 'Postal Code', value: 'PostalCode' },
  { label: 'UTM Source', value: 'UTMSource' },
  { label: 'UTM Medium', value: 'UTMMedium' },
  { label: 'UTM Campaign', value: 'UTMCampaign' },
  { label: 'UTM Content', value: 'UTMContent' },
  { label: 'UTM Term', value: 'UTMTerm' },
];

export const CRITERIA_OPERATOR_CHOICES = [
  { label: '===', value: 'Equals' },
  { label: '!=', value: 'NotEquals' },
  { label: '>=', value: 'GreaterThanOrEqual' },
  { label: '<=', value: 'LessThanOrEqual' },
];

export const CRITERIA_CONNECTOR_CHOICES = [
  { label: 'None', value: null },
  { label: 'AND', value: 'And' },
  { label: 'OR', value: 'Or' },
];

export const formBgStyles = {
  background: '#f6f9ff',
  width: '100vw',
  height: '100vh',
  overflowX: 'hidden',
};

export const formHeaderContainerStyles = {
  minWidth: '320px',
  maxWidth: '790px',
  mx: 'auto',
  my: '22px',
  py: '18px',
  background: '#FFFFFF',
  borderRadius: '8px',
  boxShadow: '2',
  borderTop: '5px solid #0D6EFD',
};

export const formCardStyles = {
  minWidth: '320px',
  maxWidth: '790px',
  mx: 'auto',
  my: '22px',
  py: '18px',
  background: 'white',
  border: '1px solid #dadce0',
  borderRadius: '8px',
};

export const EMAIL_CONFIRMATION_STATES = {
  CONFIRMING: 'CONFIRMING',
  CONFIRMED: 'CONFIRMED',
  EXPIRED: 'EXPIRED',
  ERROR: 'ERROR',
};

export const drawerWidth = 240;

export const bpmWFKey = 'PS-BPM-WF';

export const encryptionPassKey = 'PS-ENCR-KEY';

export const getUserStorageKey = () => `${process.env.REACT_APP_NONCE}-ps-user`;

export const formQuestionInitialValues = {
  likertLabel1: 'Strongly disagree',
  likertLabel2: 'Disagree',
  likertLabel3: 'Neutral',
  likertLabel4: 'Agree',
  likertLabel5: 'Strongly agree',
};

export const bpaDryRunDurationUnits = [
  { label: 'minutes', value: 'minutes' },
  { label: 'hours', value: 'hours' },
  { label: 'days', value: 'days' },
  { label: 'months', value: 'months' },
];

export const bpaFromTOUnits = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
];

export const bpaTimezoneUnits = [{ label: 'UTC', value: 'UTC' }];

export const lmsHomeworkTypes = [
  { label: 'Quiz', value: 'Quiz' },
  { label: 'Exam', value: 'Exam' },
  { label: 'Assignment', value: 'Assignment' },
];

export const lmsCourseAccessLevel = [
  { label: 'Private', value: 'PRIVATE' },
  { label: 'Public', value: 'PUBLIC' },
];

export const formBranchingConditionTypes = [
  { label: '===', value: 'EQUALS' },
  { label: '>=', value: 'GREATER_THAN_OR_EQUAL_TO' },
  { label: '<=', value: 'LESS_THAN_OR_EQUAL_TO' },
];

export const formBranchingConcatenators = [
  { value: 'AND', label: 'AND' },
  { value: 'OR', label: 'OR' },
  { value: null, label: 'None' },
];

export const formRecipientStatus = [
  { value: 'IN_PROGRESS', label: 'In Progress' },
  { value: 'DRAFT', label: 'Saved as Draft' },
  { value: 'SUBMITTED', label: 'Submitted' },
];

export const formDisplayMode = [
  { value: 'ONE_QUESTION', label: 'One Question' },
  { value: 'ALL_GROUPS', label: 'All Groups' },
  { value: 'ONE_GROUP', label: 'One Group' },
];

export const colorPickerColors = {
  defaultWhite: {
    main: '#fff',
    kanban: '#fff',
  },
  lightRedBerry: {
    main: '#d08270',
    kanban: '#f0d9d4',
  },
  lightRed: {
    main: '#d16d6a',
    kanban: '#ecc4c3',
  },
  lightOrange: {
    main: '#ecb476',
    kanban: '#f9e8d5',
  },
  lightYellow: {
    main: '#fad978',
    kanban: '#fdf3d6',
  },
  lightGreen: {
    main: '#9dc284',
    kanban: '#e1ecda',
  },
  lightCyan: {
    main: '#80a4ae',
    kanban: '#d8e3e6',
  },
  lightCornflower: {
    main: '#779fe5',
    kanban: '#d6e2f7',
  },
  lightBlue: {
    main: '#7ba7d7',
    kanban: '#cadbef',
  },
  lightPurple: {
    main: '#8a7fbe',
    kanban: '#dbd8eb',
  },
  lightMagenta: {
    main: '#b87f9e',
    kanban: '#e9d8e1',
  },
};

export const colors = {
  primary: '#3367D6',
  light: '#f7f7f8',
  background: '#f6f9ff',
  dark: '#1B1F33',
  drawerLight: '#999999',
  linkActive: '##EEEEEE',
};

export const fieldType = {
  REPEAT: 'repeat',
  DATE_TIME: 'dateTime',
  DATE: 'date',
  TEXT: 'string',
  LINK: 'link',
  DETAIL_LINK: 'detailLink',
  PASSWORD: 'password',
  AUTOCOMPLETE: 'autocomplete',
  AUTOCOMPLETE_V2: 'autocompleteV2',
  SELECT: 'singleSelect',
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  MULTILINE: 'multiline',
  MULTILINE_CONDENSED: 'multiline_condensed',
  HTML: 'html',
  AUDIO: 'audio',
  ORDER: 'order',
  UPLOAD: 'UPLOAD',
};

export const jobLevels = [
  { value: 'Junior', label: 'Junior' },
  { value: 'Senior', label: 'Senior' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Senior Manager', label: 'Senior Manager' },
  { value: 'VP', label: 'VP' },
  { value: 'SVP', label: 'SVP' },
  { value: 'Board', label: 'Board' },
];

export const daysOffTypes = [
  { value: 'Holiday', label: 'Holiday' },
  { value: 'Sick Leave', label: 'Sick Leave' },
  { value: 'Compassionate Leave', label: 'Compassionate Leave' },
];

export const countriesList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo (Brazzaville)',
  'Congo (Kinshasa)',
  'Costa Rica',
  "Côte d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const microservices = {
  WebChat: {
    name: 'WebChat',
    models: { agents: 'Agent', customers: 'Customer' },
  },
  System: {
    name: 'System',
    models: {
      relationShip: 'RelationShip',
      clientRelation: 'ClientRelation',
      client: 'Client',
      automataConnectionWithAModel: 'AutomataConnectionWithAModel',
      templates: 'Template',
      uiPrefixes: 'UIPrefix',
      microservices: 'Microservice',
      models: 'models',
      fields: 'field',
    },
  },
  Compute: {
    name: 'Compute',
    models: {
      microservice: 'microservice',
      modelDefn: 'modelDefn',
      fieldDefn: 'fieldDefn',
      formDefn: 'formDefn',
      stepDefn: 'stepDefn',
      menuDefn: 'menuDefn',
      fieldLayoutDefn: 'fieldLayoutDefn',
      blockGroup: 'blockGroup',
      block: 'block',
      instance: 'instance',
      enumDefn: 'enumDefn',
      enumValue: 'enumValue',
    },
  },
  INVENTORY: {
    name: 'Inventory',
    models: {
      manufacturerContact: 'ManufacturerContact',
      supplierContact: 'SupplierContact',
      media: 'Media',
      productAdditionalInfo: 'ProductAdditionalInfo',
      productType: 'ProductType',
      product: 'Product',
      manufacturer: 'Manufacturer',
      productVariant: 'ProductVariant',
      productArtifact: 'ProductArtifact',
      inventory: 'Inventory',
      productSupplier: 'ProductSupplier',
      warehouseInventory: 'WarehouseInventory',
      deliveryItem: 'DeliveryItem',
      variantValues: 'VariantValues',
      variantKey: 'VariantKey',
      warehouse: 'Warehouse',
      delivery: 'Delivery',
      deliveryArtifact: 'DeliveryArtifact',
      inventoryTransaction: 'InventoryTransaction',
      supplier: 'Supplier',
    },
  },
  LIST: {
    name: 'List',
    models: {
      country: 'Country',
      currency: 'Curency',
      socialMedia: 'SocialMedia',
      sicCodes: 'SicCodes',
      timeZones: 'TimeZones',
      countryCurrency: 'CountryCurrency',
      countryLanguages: 'CountryLanguages',
      languages: 'Languages',
      cities: 'Cities',
      postCodes: 'PostCodes',
    },
  },
  NOTIFICATION: {
    name: 'Notification',
    models: {
      imapProvider: 'ImapProvider',
      label: 'Label',
      notificationLabel: 'NotificationLabel',
      mailTemplates: 'MailTemplate',
      notifications: 'Notification',
      attachments: 'NotificationAttachment',
      notificationEmailAddresses: 'NotificationEmailAddress ',
      contextField: 'ContextField',
    },
  },
  PM: {
    name: 'PM',
    models: {
      programs: 'Program',
      projects: 'Project',
      personas: 'Persona',
      hlrs: 'HLR',
      backlogs: 'Backlog',
      uats: 'AcceptanceTest',
      testsConducted: 'TestsConducted',
      featureRequests: 'FeatureRequest',
      bugs: 'Bug',
      sprintMeta: 'SprintMeta',
      sprintMetaProject: 'SprintMetaProject',
      sprint: 'Sprint',
      sprintTasks: 'SprintTask',
      taskStatus: 'TaskStatus',
      task: 'Task',
      bugComment: 'BugComment',
      taskComment: 'Comment',
      resources: 'Resource',
      taskTypes: 'TaskType',
      taskbugs: 'TaskBug',
      backlogArtifact: 'BacklogArtifact',
      stakeholder: 'Stakeholder',
      role: 'Role',
      workCode: 'WorkCode',
      workingTime: 'WorkingTime',
    },
  },
  CMS: {
    name: 'CMS',
    models: {
      pages: 'Pages',
      category: 'Category',
      contents: 'Content',
      pageCategory: 'PageCategory',
      posts: 'Post',
      pageTemplate: 'PageTemplate',
      pageTemplateOption: 'PageTemplateOption',
      pageGroup: 'PageGroup',
      pageToPageGroups: 'PageToPageGroups',
      domain: 'Domain',
      menus: 'menus',
      menuOptions: 'menuOptions',
      pageOptions: 'pageOptions',
    },
  },

  SOP: {
    name: 'SOP',
    models: {
      sop: 'SOP',
      sopTask: 'SOPTask',
      sopTaskStep: 'SOPTaskStep',
    },
  },
  Marketing: {
    name: 'Marketing',
    models: {
      landingPage: 'LandingPage',
      autoGenLandingPage: 'AutoGenLandingPage',
      audiences: 'Audiences',
      lpaudiences: 'LPAudience',
      keyword: 'Keyword',
      lpkeyword: 'LPKeywords',
      autoGenLandingPageStatus: 'AutoGenLandingPageStatus',
      smmCampaign: 'SmmCampaign',
      smPost: 'SMPost',
      criteria: 'Criteria',
    },
  },
  CRM: {
    name: 'CRM',
    models: {
      channel: 'Channel',
      callSchedule: 'CallSchedule',
      callHistory: 'CallHistory',
      callList: 'CallList',
      callListPipeline: 'CallListPipeline',
      callListPipelineStage: 'CallListPipelineStage',
      companies: 'Company',
      persons: 'Person',
      companyHistory: 'Company_Artifact',
      companyContacts: 'Company_Contact',
      personHistory: 'Person_Artifact',
      personSocialMedia: 'Person_SocialMedia',
      importLog: 'ImportLog',
      account: 'Account',
      opportunities: 'Opportunity',
      opportunityInfluencers: 'Opportunity_Influencer',
      socialMediaTypes: 'Social_media_type',
      opportunityProduct: 'Opportunity_Product',
      opportunityHistory: 'Opportunity_History',
      clientHistory: 'Client_History',
      pipeline: 'Pipeline',
      pipelineStage: 'Pipeline_Stage',
      companySIC: 'Company_SIC',
      companySocialMedia: 'Company_SocialMedia',
      dataNeeded: 'Data_Needed',
      actionPlan: 'Action_Plan',
      territory: 'Territory',
      accountManager: 'AccountManagerInCompany',
      companyTerritory: 'CompanyInTerritory',
      territoryOwner: 'TerritoryOwner',
      salesPersonTarget: 'SalesPersonTarget',
      activityNote: 'ActivityNote',
      activityKind: 'ActivityKind',
      activityType: 'ActivityType',
      companySPIN: 'CompanySPIN',
      actionReminder: 'Action_Reminder',
      customerEnquiry: 'CustomerEnquiry',
      customerEnquiryStatus: 'CustomerEnquiryStatus',
      customerEnquiryPurpose: 'CustomerEnquiryPurpose',
      relationship: 'Relationship',
      relationshipHistory: 'PersonRelationshipHistory',
    },
  },
  WIKI: {
    name: 'Wiki',
    models: {
      books: 'Book',
      bookCategory: 'BookCategory',
      chapter: 'Chapter',
      section: 'Section',
      tags: 'Tag',
      sectionTags: 'SectionTags',
      readingProgress: 'ReadingProgress',
    },
  },
  ACCOUNTS: {
    name: 'Accounts',
    models: {
      microservices: 'Microservice',
      permissions: 'Permission',
      roles: 'Role',
      users: 'User',
    },
  },
  LOGS: {
    name: 'Logs',
    models: {
      logs: 'Log',
      importLogs: 'ImportLog',
      systemLogs: 'SystemLog',
      shapeTransitionLog: 'ShapeTransitionLog',
      visitorLog: 'VisitorLog',
    },
  },
  HR: {
    name: 'HR',
    models: {
      course: 'Course',
      skillLevel: 'SkillLevel',
      role: 'Role',
      employee: 'Employee',
      vacancy: 'Vacancy',
      department: 'Department',
      skill: 'Skill',
      roleHistory: 'RoleHistory',
      salaryHistory: 'SalaryHistory',
      trainingHistory: 'TrainingHistory',
      holidayEntitlement: 'HolidayEntitlement',
      dayOff: 'DayOff',
      performanceReview: 'PerformanceReview',
      candidate: 'Candidate',
      Reference: 'Reference',
      employeeDepartment: 'EmployeeDepartment',
      employeeSkill: 'EmployeeSkill',
      vacancyStatus: 'VacancyStatus',
      keywords: 'Keyword',
      recruitmentPipeline: 'RecruitmentPipeline',
      recruitmentPipelineStage: 'RecruitmentPipelineStages',
      jobLevels: 'JobLevel',
      dayOffTypes: 'DayOffType',
      employeeStatus: 'EmployeeStatus',
    },
  },
  eDoc: {
    name: 'eDoc',
    models: {
      document: 'document',
      recipient: 'recipient',
    },
  },
  CALENDAR: {
    name: 'Calendar',
    models: {
      calendar: 'Calendar',
      event: 'Event',
      slotLink: 'LinkSlot',
      linkRecipient: 'LinkRecipient',
      link: 'Link',
      doWhat: 'DoWhat',
      batchReminder: 'BatchReminder',
    },
  },
  BPA: {
    name: 'BPA',
    models: {
      workflow: 'WorkflowDefn',
      stage: 'StageDefn',
      modelAsset: 'ModelAsset',
      modelAssetStage: 'ModelAssetStage',
      manualWorkflowInstance: 'ManualWorkflowInstance',
    },
  },
  Forms: {
    name: 'Forms',
    models: {
      personality: 'personality',
      recommendedCourse: 'recommendedCourse',
      form: 'form',
      question: 'question',
      questionOption: 'questionOption',
      questionOptionGroup: 'questionOptionGroup',
      response: 'response',
      answer: 'answer',
      group: 'group',
      branchingCondition: 'branchingCondition',
      recipient: 'recipient',
      questionOptionGroupScoreRange: 'questionOptionGroupScoreRange',
    },
  },
  UrlShortner: {
    name: 'UrlShortner',
    models: {
      link: 'Link',
      utm: 'UTM',
    },
  },
  QrCode: {
    name: 'QrCode',
    models: {
      vcard: 'Vcard',
      email: 'Email',
      sms: 'SMS',
      wifi: 'WiFi',
      twitter: 'Twitter',
      pdf: 'PDF',
      facebook: 'Facebook',
      mp3: 'Mp3',
      appStore: 'AppStore',
      image: 'Image',
    },
  },
  LMS: {
    name: 'LMS',
    models: {
      university: 'University',
      faculty: 'Faculty',
      facultyCourse: 'FacultyCourse',
      courseModule: 'CourseModule',
      courseStudent: 'CourseStudent',
      moduleLesson: 'ModuleLesson',
      lessonCoach: 'LessonCoach',
      homeworkProgress: 'HomeworkProgress',
      instructor: 'Instructor',
      student: 'Student',
      studentMentor: 'StudentMentor',
      mentor: 'Mentor',
      mentorSkill: 'MentorSkill',
      coach: 'Coach',
      skills: 'Skills',
      lessonInstructor: 'LessonInstructor',
      homework: 'Homework',
      course: 'Course',
      module: 'Module',
      lesson: 'Lesson',
      team: 'Team',
      teamMember: 'TeamMember',
      lessonProgress: 'LessonProgress',
      category: 'Category',
    },
  },
};

export const referenceTypeOptions = ['Personal', 'Academic', 'Work'];

export const adminLogo = 'Pullstream admin';

export const formCategoryOptions = [
  { label: 'Questionnaire', value: 'Questionnaire' },
  { label: 'Quiz', value: 'Quiz' },
  { label: 'Personality Test', value: 'PersonalityTest' },
];

export const dataTypeOptions = [
  { label: 'String', value: 'String' },
  { label: 'Email', value: 'Email' },
  { label: 'Int', value: 'Int' },
  { label: 'Boolean', value: 'Boolean' },
  { label: 'Json', value: 'Json' },
  { label: 'DateTime', value: 'DateTime' },
  { label: 'Date', value: 'Date' },
  { label: 'UUID', value: 'UUID' },
  { label: 'Float', value: 'Float' },
  { label: 'Decimal', value: 'Decimal' },
  { label: 'URL', value: 'URL' },
  { label: 'IPAddress', value: 'IPAddress' },
  { label: 'Enum', value: 'Enum' },
  { label: 'StringArray', value: 'StringArray' },
  { label: 'IntArray', value: 'IntArray' },
];

export const computeOnDeleteOptions = [
  { label: 'Cascade', value: 'Cascade' },
  { label: 'Restrict', value: 'Restrict' },
];

export const computeProcessStatuses = [
  { label: 'Processing', value: 'Processing' },
  { label: 'Failed', value: 'Failed' },
  { label: 'Completed', value: 'Completed' },
];

export const computeModelFormTypes = [{ label: 'Create', value: 'Create' }];

export const formQuestionTypeOptions = [
  {
    label: 'Text',
    value: 'Text',
  },
  {
    label: 'Multiple choice',
    value: 'MultipleChoice',
  },
  {
    label: 'Button select',
    value: 'ButtonSelect',
  },
  {
    label: 'Rating',
    value: 'Rating',
  },
  {
    label: 'Date',
    value: 'Date',
  },
  {
    label: 'Likert',
    value: 'Likert',
  },
  {
    label: 'File upload',
    value: 'FileUpload',
  },
  {
    label: 'Multiple select',
    value: 'MultipleSelect',
  },
  {
    label: 'Audio',
    value: 'Audio',
  },
];

export const backlogStoryPoints = [...Array(20).keys()].map((num) => ({
  key: (num + 1).toString(),
  label: (num + 1).toString(),
  value: (num + 1).toString(),
}));

export const assessmentRating = [...Array(10).keys()].map((num) => ({
  key: (num + 1).toString(),
  label: (num + 1).toString(),
  value: (num + 1).toString(),
}));

export const bugPriorityOptions = [
  { label: 'Work to fix', value: 'Work to fix' },
  { label: 'Immediate', value: 'Immediate' },
  { label: 'High', value: 'High' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Work it in', value: 'Work it in' },
];

export const bugOsOptions = [
  { label: 'MacOS', value: 'MacOS' },
  { label: 'iOS', value: 'iOS' },
  { label: 'Windows', value: 'Windows' },
  { label: 'Android', value: 'Android' },
];

export const bugBrowserOptions = [
  { label: 'Safari', value: 'Safari' },
  { label: 'Chrome', value: 'Chrome' },
  { label: 'Firefox', value: 'Firefox' },
  { label: 'Brave', value: 'Brave' },
];

export const bugTypeOptions = [
  { label: 'Bug Report', value: 'Bug Report' },
  {
    label: 'Feature Request',
    value: 'Feature Request',
  },
];

export const bugStatusOptions = [
  { label: 'Unassigned', value: 'Unassigned' },
  { label: 'Clarify', value: 'Clarify' },
  { label: 'WIP', value: 'WIP' },
  { label: 'Ready for Testing', value: 'Ready for Testing' },
  { label: 'Testing', value: 'Testing' },
  {
    label: 'Awaiting Staging Deployment',
    value: 'Awaiting Staging Deployment',
  },
  { label: 'Staging Review', value: 'Staging Review' },
  {
    label: 'Awaiting Production Deployment',
    value: 'Awaiting Production Deployment',
  },
  { label: 'Production Review', value: 'Production Review' },
  { label: 'Failed Testing', value: 'Failed Testing' },
  { label: 'Fixed', value: 'Fixed' },
  { label: 'Archived', value: 'Archived' },
];

export const taskStatusOptions = [
  'To do',
  'In Progress',
  'Testing',
  'Approved',
  'Done',
  'Deployed',
];

// Notification Ms
export const notificationTypeOptions = [
  { label: 'email', value: 'Email' },
  { label: 'sms', value: 'Sms' },
  // { label: 'simple', value: 'Simple' },
  // { label: 'in_app', value: 'In App' },
];

export const dataFieldType = [
  { label: 'Text', value: 'Text' },
  { label: 'Date', value: 'Date' },
  { label: 'Sign', value: 'Sign' },
];

export const connectionTypeChoices = [
  { label: 'SSL', value: 'SSL' },
  { label: 'TLS', value: 'TLS' },
  { label: 'Unencrypted', value: 'Unencrypted' },
];

export const smtpConnectionTypeChoices = [
  { label: 'SSL', value: 'SSL' },
  { label: 'TLS', value: 'TLS' },
];

// Inventory Ms
export const mediaTypeOptions = [
  { label: 'Image', value: 'Image' },
  { label: 'Video', value: 'Video' },
];

export const transactionTypeOptions = [
  { label: 'Addition', value: 'Addition' },
  { label: 'Removal', value: 'Removal' },
  { label: 'Shrinkage', value: 'Shrinkage' },
];

export const statusOptions = [
  { label: 'Reserved', value: 'Reserved' },
  { label: 'Not for Sale', value: 'Not for Sale' },
  { label: 'For Sale', value: 'For Sale' },
];

// CRM Opportunity Start
export const opportunitySentiments = [
  { label: 'Ecstatic', value: 'Ecstatic' },
  { label: 'Secure', value: 'Secure' },
  { label: 'Good', value: 'Good' },
  { label: 'OK', value: 'OK' },
  { label: 'Concerned', value: 'Concerned' },
  { label: 'Distressed', value: 'Distressed' },
  { label: 'Fearful', value: 'Fearful' },
];

export const opportunityPriority = [
  { label: 'Urgent', value: 'Urgent' },
  { label: 'ASAP', value: 'ASAP' },
  { label: 'Later', value: 'Later' },
];

export const companySpinsBuyerInfluence = [
  { label: 'User', value: 'User' },
  { label: 'Economic', value: 'Economic' },
  { label: 'Technical', value: 'Technical' },
];

export const opportunityInfluencerRatings = [
  { label: -3, value: -3 },
  { label: -2, value: -2 },
  { label: -1, value: -1 },
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
];
// CRM Opportunity End

// Learn homework
export const homeworkTypesOptions = [
  { label: 'Quiz', value: 'Quiz' },
  { label: 'Assignment', value: 'Assignment' },
  { label: 'Exam', value: 'Exam' },
];

// Calendar Ms
export const calendarNotificationTypeOptions = [
  { label: 'email', value: 'email' },
  { label: 'sms', value: 'sms' },
  { label: 'os', value: 'os' },
  { label: 'app', value: 'app' },
];

export const calendarNotificationDayChoicesOptions = [
  { label: 'days', value: 'days' },
  { label: 'hours', value: 'hours' },
  { label: 'minutes', value: 'minutes' },
];

export const calendarRecurringChoicesOptions = [
  { value: 'does_not_repeat', label: 'Does Not Repeat' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'annually', label: 'Annually' },
  { value: 'weekdays', label: 'Every Weekdays' },
  { value: 'custom', label: 'Custom' },
];

export const calendarRepeatOnChoicesOptions = [
  { value: 'MO', label: 'Monday' },
  { value: 'TU', label: 'Tuesday' },
  { value: 'WE', label: 'Wednesday' },
  { value: 'TH', label: 'Thursday' },
  { value: 'FR', label: 'Friday' },
  { value: 'SA', label: 'Saturday' },
  { value: 'SU', label: 'Sunday' },
];

export const calendarRepeatEveryChoicesOptions = [
  { label: 'days', value: 'DAILY' },
  { label: 'weeks', value: 'WEEKLY' },
  { label: 'months', value: 'MONTHLY' },
  { label: 'years', value: 'YEARLY' },
];

export const calendarReversedRepeatEveryChoicesOptions = [
  ...calendarRepeatEveryChoicesOptions,
].reverse();

const allTimeZones = moments.tz.names();

export const timeZones = allTimeZones.map((timeZone) => ({
  value: timeZone,
  label: timeZone,
}));

export const msWithExport = [
  { name: 'PM' },
  { name: 'Inventory' },
  { name: 'CRM' },
  { name: 'System' },
  { name: 'UrlShortner' },
  { name: 'Forms' },
  { name: 'Compute' },
];

export const durationUnitsChoices = [
  { label: 'None', value: null },
  { label: 'Minutes', value: 'Minutes' },
  { label: 'Hours', value: 'Hours' },
  { label: 'Days', value: 'Days' },
  { label: 'Weeks', value: 'Weeks' },
  { label: 'Months', value: 'Months' },
];

export const staggerUnitsChoices = [
  { label: 'Minute', value: 'Minute' },
  { label: 'Hour', value: 'Hour' },
];

export const targetUnitsChoices = [
  { label: 'Daily', value: 'Daily' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Monthly', value: 'Monthly' },
];

export const statusChoicesOptions = [
  { label: 'Published', value: 'Published' },
  { label: 'Draft', value: 'Draft' },
];

export const intentChoicesOptions = [
  { label: 'Informational', value: 'Informational' },
  { label: 'Transactional', value: 'Transactional' },
  { label: 'Comparison', value: 'Comparison' },
  { label: 'Navigational', value: 'Navigational' },
];

export const typeChoicesOptions = [
  { label: 'Generic', value: 'Generic' },
  { label: 'Broad Match', value: 'Broad Match' },
  { label: 'Long Tail', value: 'Long Tail' },
  { label: 'Golden', value: 'Golden' },
];

export const staggerUnitType = [
  { label: 'Minute', value: 'Minute' },
  { label: 'Hour', value: 'Hour' },
  { label: 'Days', value: 'Days' },
];

export const vacanciesStatusChoices = [
  { label: 'Live', value: 'Live' },
  { label: 'Draft', value: 'Draft' },
  { label: 'Ended', value: 'Ended' },
];

export const contextFieldTypeChoices = [
  {
    label: 'String',
    value: 'String',
  },
  {
    label: 'Number',
    value: 'Number',
  },
  {
    label: 'Decimal',
    value: 'Decimal',
  },
  {
    label: 'List',
    value: 'List',
  },
  {
    label: 'Dict',
    value: 'Dict',
  },
  {
    label: 'Date',
    value: 'Date',
  },
  {
    label: 'DateTime',
    value: 'DateTime',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const eventTypeChoices = [
  {
    label: 'Event',
    value: 'Event',
  },
  {
    label: 'Slot Event',
    value: 'SlotEvent',
  },
  {
    label: 'Reminder',
    value: 'Reminder',
  },
];

export const DAILY_RRULE = 'RRULE:FREQ=DAILY;INTERVAL=1';
export const DAILY_RRULE_WITHOUT_INTERVAL = 'RRULE:FREQ=DAILY';
export const WEEKLY_RRULE = 'RRULE:FREQ=WEEKLY;INTERVAL=1';
export const WEEKLY_RRULE_WITHOUT_INTERVAL = 'RRULE:FREQ=WEEKLY';
export const MONTHLY_RRULE = 'RRULE:FREQ=MONTHLY;INTERVAL=1';
export const MONTHLY_RRULE_WITHOUT_INTERVAL = 'RRULE:FREQ=MONTHLY';
export const ANNUALLY_RRULE = 'RRULE:FREQ=YEARLY;INTERVAL=1';
export const ANNUALLY_RRULE_WITHOUT_INTERVAL = 'RRULE:FREQ=YEARLY';
export const WEEKDAYS_RRULE =
  'RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR';
export const WEEKDAYS_RRULE_WITHOUT_INTERVAL =
  'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR';

export const REACT_FLOW_CHART = 'react-flow-chart';

export const bpmDefaultPorts = {
  port1: {
    id: 'port1',
    type: 'left',
    properties: { linkColor: '#222' },
  },
  port2: {
    id: 'port2',
    type: 'right',
    properties: { linkColor: '#222' },
  },
  port3: {
    id: 'port3',
    type: 'top',
    properties: { linkColor: '#222' },
  },
  port4: {
    id: 'port4',
    type: 'bottom',
    properties: { linkColor: '#222' },
  },
};

export const bpmFlowInitialValues = {
  offset: {
    x: 0,
    y: 0,
  },
  scale: 1,
  nodes: {},
  links: {},
  selected: {},
  hovered: {},
};

export const acpTermDef = { id: null, label: '' };

export const wifiTypeOptions = [
  { label: 'None', value: 'None' },
  { label: 'WPA', value: 'WPA' },
  { label: 'WEP', value: 'WEP' },
];

export const twitterTypeOptions = [
  { label: 'Link To Your Profile', value: 'Link To Your Profile' },
  { label: 'Post A Tweet', value: 'Post A Tweet' },
];

export const qrShape = [
  { label: 'Squares', value: 'squares' },
  { label: 'Dots', value: 'dots' },
];

export const logAction = [
  { label: 'Update', value: 'Change' },
  { label: 'Create', value: 'Addition' },
  { label: 'Delete', value: 'Deletion' },
];

export const shapeTransitionLogsType = [
  { label: 'Failure', value: 'Failure' },
  { label: 'Success', value: 'Success' },
];

export const progressType = [
  { label: 'In Progress', value: 'In Progress' },
  { label: 'Completed', value: 'Completed' },
];

export const dayoffStatusType = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Approved', value: 'Approved' },
  { label: 'Denied', value: 'Denied' },
];

export const reapplyDurationUnitsChoices = [
  { label: 'months', value: 'months' },
  { label: 'minutes', value: 'minutes' },
  { label: 'hours', value: 'hours' },
  { label: 'days', value: 'days' },
];

export const pullstreamClientId = '88a11494-2185-4338-b1d1-aec4b11537c0';
