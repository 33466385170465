import React from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { systemUrls } from '@config/routes';
import { logMap } from '@config/handleRows/system';
import { microservices } from '@config/constants';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import PublicUserErrorScreen from '@components/CMS-V2/PublicUserErrorScreen';

const { logUrls } = systemUrls;

export default function Logs({ labels, editMode }) {
  const user = useSelector(selectProfile);

  if (
    Array.isArray(user?.details?.roles_names) &&
    !(
      user?.details?.roles_names.includes('Superusers') ||
      user?.details?.is_superuser ||
      user?.details?.roles_names.includes('Can See Object Logs')
    )
  ) {
    return <PublicUserErrorScreen />;
  }

  return (
    <Box>
      <ListView
        label='Logs'
        prefix='0bw'
        labels={labels}
        editMode={editMode}
        microservice={microservices.LOGS.name}
        model={microservices.LOGS.models.logs}
        urls={logUrls}
        columnKey='logsSystem'
        handleRowsMap={logMap}
        customSortColumnName='date_created'
        hideActions
      />
    </Box>
  );
}
