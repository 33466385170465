import React from 'react';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { marketingUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { campaignsMeta } from '@config/meta/marketing';
import { smPostRowsMap } from '@config/handleRows/marketing';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import {
  uploadFilesToDrive,
  formatToUTCDateTime,
} from '@config/functions/helperFunctions';
import { useParams } from 'react-router-dom';
import { createRecord, updateRecord } from '@config/functions/requests';
import { CreatePostForm } from '@config/forms/marketing';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import Accordion from '@ui/Accordion';
import RenderPlatforms from '@components/SocialMedia/RenderPlatforms';
import { useQueryClient } from 'react-query';

const {
  smmCampaignsUrls,
  smPostsUrls,
  bulkCreateSmPostsUrls,
  userSmAccessUrls,
  campaignAccountsUrls,
  postViasUrls,
} = marketingUrls;

export default function CampaignDetail({ editMode, labels }) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [selectedAccounts, setSelectedAccounts] = React.useState([]);
  const [selectedImages, setSelectedImages] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [accountsInit, setAccountsInit] = React.useState(false);
  const [accountsSubmitting, setAccountsSubmitting] = React.useState(false);
  const [accountsError, setAccountsError] = React.useState([]);

  const { data: userAccounts, isFetching: fetchingAccounts } = useRQuery({
    key: [`user-sm-accounts`, user?.token, user?.actAs],
    url: userSmAccessUrls.list(),
    config: getAuthHeader(user?.token, user?.actAs),
    options: { enabled: !!user?.token },
  });

  const { data: postVias } = useRQuery({
    key: [`${recordId}-sm-post-vias`, recordId, user?.token, user?.actAs],
    url: postViasUrls.list(`?smm_campaign=${recordId}`),
    config: getAuthHeader(user?.token, user?.actAs),
    options: { enabled: !!recordId },
  });

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => campaignsMeta({ data }), []);

  React.useEffect(() => {
    if (!postVias || !Array.isArray(postVias.results) || accountsInit) return;

    if (!accountsInit) {
      setSelectedAccounts(
        postVias.results.map((row) => ({
          id: row.user_token,
          ...row?.details?.user_token,
        }))
      );
      setAccountsInit(true);
    }
  }, [postVias]);

  const updateAccounts = async () => {
    try {
      setAccountsSubmitting(true);
      setAccountsError([]);

      await createRecord({
        values: {
          smm_campaign: recordId,
          user_sm_access: selectedAccounts.map((acct) => acct.id),
        },
        url: campaignAccountsUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });
    } catch (err) {
      setAccountsError(err?.response?.data?.errors || []);
    } finally {
      setAccountsSubmitting(false);
    }
  };

  const handlePause = async () => {
    try {
      await updateRecord({
        values: {
          paused: !recordData.paused,
        },
        url: smmCampaignsUrls.detail(recordId),
        token: user.token,
        actAs: user?.actAs,
      });
      queryClient.invalidateQueries([`${recordId}-record-details`]);
    } catch (err) {
      notify(`Failed to pause the campaign`, {
        type: 'ERROR',
      });
    }
  };

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/social-media-campaigns', text: 'Campaigns' },
              { text: recordData?.name },
            ]}
          >
            <Button size='small' variant='outlined' onClick={handlePause}>
              {recordData.paused ? 'Unpause' : 'Pause'}
            </Button>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={smmCampaignsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <Accordion
          editMode={editMode}
          labels={labels}
          prefix='0s9'
          label='Accounts'
          expanded={expanded}
          onChange={() => setExpanded((prevState) => !prevState)}
        >
          <Box sx={{ py: 2 }}>
            {Array.isArray(accountsError) && accountsError.length ? (
              <Box sx={{ mb: 1.5, color: 'red', fontSize: '14px' }}>
                <p>Failed to add platform</p>
                <ul style={{ margin: '0', padding: '0', paddingLeft: '14px' }}>
                  {accountsError.map((error, index) => (
                    <li key={index}>
                      {error.field}: {error.messages[0].message}
                    </li>
                  ))}
                </ul>
              </Box>
            ) : null}

            {fetchingAccounts ? (
              <p>loading...</p>
            ) : userAccounts?.results &&
              userAccounts?.results.length &&
              accountsInit ? (
              <RenderPlatforms
                userAccounts={userAccounts}
                selectedAccounts={selectedAccounts}
                handleAccounts={setSelectedAccounts}
              />
            ) : (
              <p>No platforms connected</p>
            )}

            <Box sx={{ mt: 1.5 }}>
              <Button
                variant='outlined'
                size='small'
                onClick={updateAccounts}
                disabled={accountsSubmitting}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Accordion>

        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix='065'
          microservice={microservices.Marketing.name}
          model={microservices.Marketing.models.smPost}
          columnKey='postsCampaigns'
          label='Posts'
          urls={smPostsUrls}
          addNewForm={{
            initialValues: {
              posts: [
                {
                  text: '',
                  medias: [],
                  timing: formatToUTCDateTime(new Date()),
                },
              ],
            },
            getFields: ({ values, setValues }) =>
              CreatePostForm({
                values,
                setValues,
                selectedImages,
                setSelectedImages,
                files,
                setFiles,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const getMedias = async (index, postFiles, selectedImages) => {
                  const medias = [];

                  if (postFiles.length) {
                    const { urls } = await uploadFilesToDrive({
                      files: postFiles,
                      userToken: user.token,
                    });

                    if (urls.some(({ error }) => error)) {
                      throw new Error('Drive upload failed');
                    }

                    urls.forEach(({ url }) => {
                      const extension = url.split('.').pop().toLowerCase();
                      medias.push({
                        media_type: extension === 'mp4' ? 'Video' : 'Image',
                        url,
                      });
                    });
                  }

                  selectedImages.forEach(({ postId, file_url }) => {
                    if (postId === index) {
                      medias.push({
                        media_type: 'Image',
                        url: file_url,
                      });
                    }
                  });

                  return medias;
                };

                const payload = [];

                for (const [index, v] of values.posts.entries()) {
                  const postFiles = files.filter((r) => r.postId === index);
                  const medias = await getMedias(
                    index,
                    postFiles.map((r) => r.file),
                    selectedImages
                  );

                  const account = {
                    post: v.text,
                    timing: v.timing,
                    smm_campaign: recordId,
                    medias,
                  };

                  if (v.rrule) {
                    account.rrule = v.rrule;
                  }

                  payload.push(account);
                }

                const flattenedPayload = payload.flat();

                await createRecord({
                  values: flattenedPayload,
                  url: bulkCreateSmPostsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log('obi-error', err.message);
                notify(`Failed to schedule post(s)`, {
                  type: 'ERROR',
                });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={smPostRowsMap}
          urlParams={`&smm_campaign=${recordId}`}
        />
      </Box>
    </Box>
  );
}
