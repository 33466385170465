import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Typography,
  MenuItem,
} from '@mui/material';
import useAutocomplete from '@hooks/useAutocomplete';
import {
  hrUrls,
  communicationUrls,
  calendarUrls,
  crmUrls,
} from '@config/routes';
import { formatDate } from '@config/functions/helperFunctions';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  CheckBoxField,
  AutocompleteField,
  AutocompleteFieldV2,
  DateTimePickerField,
  SelectField,
} from '@components/Inputs';
import { AutocompleteField as AutocompleteFieldUi } from '@ui/Inputs';
import useToast from '@hooks/useToast';
import { createRecord } from '@config/functions/requests';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const { mailTemplatesUrls } = communicationUrls;
const { calendarsListUrls } = calendarUrls;
const { sendEmailsToCandidatesUrls } = hrUrls;
const { sendEmailToPersonsUrls } = crmUrls;

export default function SendEmailDialog({
  open,
  setOpen,
  multiple,
  meta,
  vacancy,
  callback,
  kanbanStatus,
  selectedRows,
  initialTemplate,
  isPerson,
  labels,
  editMode,
  prefix,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [calendarTerm, setCalendarTerm] = React.useState('');
  const [contextFields, setContextFields] = React.useState([]);
  const [contextFieldValues, setContextFieldValues] = React.useState({});
  const [contextFieldInputs, setContextFieldInputs] = React.useState({});
  const [contextFieldInit, setContextFieldInit] = React.useState(false);

  const firstNameMeta = meta.find((r) =>
    r.headerName === isPerson ? 'First name' : 'First Name'
  );
  const lastNameMeta = meta.find((r) =>
    r.headerName === isPerson ? 'Last name' : 'Last Name'
  );
  const emailMeta = meta.find((r) => r.headerName === 'Email');

  const { data: calendars, isFetching: fetchingCalendars } = useAutocomplete({
    key: 'ina-calendars',
    getUrl: calendarsListUrls.list,
    inputValue: calendarTerm,
  });

  const handleClose = () => setOpen(false);

  const renderEmailTemplate = (row) => {
    const { id, name, details } = row;
    const contextFields = Array.isArray(details?.context_fields)
      ? details.context_fields
      : [];

    return {
      id,
      label: name,
      contextFields,
    };
  };

  const getAutocompleteOptions = () => {
    const options = meta?.map((obj) => {
      if (obj?.headerName === 'Date of application') {
        return {
          ...obj,
          value: formatDate(obj?.created_at),
        };
      }
      return obj;
    });

    options.push(
      {
        headerName: 'Vacancy',
        value: vacancy?.position_title || '',
      },
      {
        headerName: 'Test',
        value: vacancy?.test || '',
      }
    );

    return options.map((option) => ({
      id: option?.value,
      label: option?.headerName,
    }));
  };

  const handlePrefillContextFields = (
    { contextFields } = { contextFields: [] }
  ) => {
    const labelsOfInterest = ['First Name', 'Last Name', 'Email', 'Test'];

    const includes = contextFields.some((item) =>
      labelsOfInterest.includes(item.label)
    );

    if (includes) {
      const { value: firstNameValue, headerName: firstNameHeader } =
        firstNameMeta ?? {};
      const { value: lastNameValue, headerName: lastNameHeader } =
        lastNameMeta ?? {};
      const { value: emailValue, headerName: emailHeader } = emailMeta ?? {};
      const { position_title: vacancyPositionTitle, test: vacancyTest } =
        vacancy ?? {};

      const values = {};

      if (firstNameValue && firstNameHeader) {
        values.first_name = {
          id: firstNameValue,
          label: firstNameHeader,
        };
      }

      if (lastNameValue && lastNameHeader) {
        values.last_name = {
          id: lastNameValue,
          label: lastNameHeader,
        };
      }

      if (emailValue && emailHeader) {
        values.email = {
          id: emailValue,
          label: emailHeader,
        };
      }

      if (vacancyPositionTitle) {
        values.Vacancy = {
          id: vacancyPositionTitle,
          label: 'Vacancy',
        };
      }

      if (vacancyTest) {
        values.Test = {
          id: vacancyTest,
          label: 'Test',
        };
      }

      setContextFieldValues(values);
    }

    if (isPerson) {
      if (contextFields?.Test) delete contextFields.Test;
      if (contextFields?.Vacancy) delete contextFields.Vacancy;
    }

    setContextFields(contextFields);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent maxWidth="sm" fullWidth>
        {/* <Typography variant='h6' sx={{ mb: 2 }}>
          Send email
        </Typography> */}
        {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="Send email"
          >
            {(text) => {
              return (
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {text}
                </Typography>
              );
            }}
          </RenderUiPrefix>
        ) : (
          <Typography variant="h6" sx={{ mb: 2 }}>
            Send email
          </Typography>
        )}

        <Formik
          initialValues={{
            template: initialTemplate || null,
            email: emailMeta.value,
            cc: '',
            bcc: '',
            form: '',
            startDateTime: new Date(),
          }}
          validationSchema={Yup.object({
            template: Yup.object().nullable(),
            email: Yup.string().email().required(),
            cc: Yup.string().email(),
            bcc: Yup.string().email(),
            form: Yup.string(),
          })}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              if (values.addReminder) {
                if (!values.calendar) {
                  setFieldError('calendar', 'Please select a calendar');
                  return;
                }
                if (!values.startDateTime) {
                  setFieldError('startDateTime', 'Please select date and time');
                  return;
                }
                if (!values.reminderName) {
                  setFieldError('reminderName', 'Please enter a name');
                  return;
                }
              }

              const payload = {
                vacancy: vacancy?.id,
                timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
                move_to_next_stage: values?.advanceStage,
                email: {
                  type: 'email',
                  template: values?.template?.id,
                  use_template: true,
                  cc_emails: values?.cc?.length ? [values.cc] : [],
                  bcc_emails: values?.bcc?.length ? [values.bcc] : [],
                  attachments: [],
                  to_emails: [],
                  context: {},
                },
              };

              if (kanbanStatus) {
                payload.status = kanbanStatus;
              }

              if (selectedRows) {
                payload.ids = selectedRows;
              }

              if (values.addReminder) {
                payload.reminder_name = values?.reminderName;
                payload.start_date_time = values?.startDateTime;
                payload.calendar = values?.calendar;
              }

              if (values.addSlots) {
                payload.slot_title = values?.slotName;
                payload.slot_calendar = values?.slotCalendar;
                payload.slot_timezone = values?.slotTimezone;
                payload.slot_duration = values?.slotDuration;
                payload.slot_buffer_between_slots = values?.slotBuffer;
                payload.slot_link = values?.slotMeetingLink;
                payload.slot_start_date_time = values?.slotBegin;
                payload.slot_end_date_time = values?.slotEnd;
                payload.slot_link_description = values?.slotDescription;
              }

              if (isPerson) {
                payload.reminder_description =
                  values.reminderDescription || undefined;
              }

              const { data: results } = await createRecord({
                values: payload,
                url: isPerson
                  ? sendEmailToPersonsUrls.list()
                  : sendEmailsToCandidatesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              callback(results);
              setOpen(false);
              notify('Email Sent', {
                type: 'SUCCESS',
              });
            } catch (err) {
              console.log(err?.response?.data || err.message);
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form
              noValidate
              autoComplete="off"
              style={{ paddingTop: multiple ? '' : '22px' }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AutocompleteFieldV2
                    name="template"
                    label="Template"
                    requestKey="send-email-templates"
                    fetchUrl={mailTemplatesUrls.list}
                    renderRow={renderEmailTemplate}
                    onChange={handlePrefillContextFields}
                    getData={(data) => {
                      if (initialTemplate?.id && !contextFieldInit) {
                        const found = data.find(
                          (r) => r.id === initialTemplate.id
                        );
                        handlePrefillContextFields(found);
                        setContextFieldInit(true);
                      }
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectField name="email" label="Email" value={values.email}>
                    {meta.map((row) => (
                      <MenuItem
                        value={row.value}
                        key={row.headerName + row.value}
                      >
                        {row.headerName}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Cc" name="cc" />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Bcc" name="bcc" />
                </Grid>

                {/* {isPerson ? null : (
                  <Grid item xs={12}>
                    <CheckBoxField
                      label='Advance to next stage'
                      name='advanceStage'
                    />
                  </Grid>
                )} */}

                <Grid item xs={12}>
                  <CheckBoxField label="Add reminder" name="addReminder" />
                </Grid>

                {values.addReminder ? (
                  <>
                    <Grid item xs={6}>
                      <AutocompleteField
                        label="Calendar"
                        options={calendars}
                        name="calendar"
                        isLoading={fetchingCalendars}
                        setSearchTerm={(v) => setCalendarTerm(v)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DateTimePickerField
                        label="Date time"
                        name="startDateTime"
                        showTime
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Reminder name"
                        name="reminderName"
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Reminder description"
                        name="reminderDescription"
                        multiline
                        minRows={2}
                      />
                    </Grid>
                  </>
                ) : null}

                {isPerson ? null : (
                  <Grid item xs={12}>
                    <CheckBoxField label="Add slots" name="addSlots" />
                  </Grid>
                )}

                {values.addSlots ? (
                  <>
                    <Grid item xs={6}>
                      <TextField label="Name" name="slotName" multiline />
                    </Grid>
                    <Grid item xs={6}>
                      <AutocompleteField
                        label="Calendar"
                        options={calendars}
                        name="slotCalendar"
                        isLoading={fetchingCalendars}
                        setSearchTerm={(v) => setCalendarTerm(v)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Description"
                        name="slotDescription"
                        minRows={2}
                        multiline
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DateTimePickerField
                        label="Begin"
                        name="slotBegin"
                        showTime
                        showTimezone
                        setTimezone={(v) => setFieldValue('slotTimezone', v)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DateTimePickerField
                        label="End"
                        name="slotEnd"
                        showTime
                        showTimezone
                        setTimezone={(v) => setFieldValue('slotTimezone', v)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="slotDuration"
                        label="Duration (Minutes)"
                        required
                        number
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="slotBuffer"
                        label="Buffer (Minutes)"
                        required
                        number
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name="slotMeetingLink"
                        label="Conference Link"
                      />
                    </Grid>
                  </>
                ) : null}

                {values.template &&
                Array.isArray(contextFields) &&
                contextFields.length ? (
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: '500', mb: 2 }}>
                      Field mappings
                    </Typography>
                    <Grid container spacing={2}>
                      {contextFields.map((field) => (
                        <React.Fragment key={field.name}>
                          <Grid item xs={4}>
                            <Typography>{field.label}</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <AutocompleteFieldUi
                              label={field.headerName}
                              value={contextFieldValues[field.name] || null}
                              setValue={(value) =>
                                setContextFieldValues((state) => ({
                                  ...state,
                                  [field.name]: value,
                                }))
                              }
                              inputValue={contextFieldInputs[field.name] || ''}
                              setInputValue={(value) =>
                                setContextFieldInputs((state) => ({
                                  ...state,
                                  [field.name]: value,
                                }))
                              }
                              options={getAutocompleteOptions()}
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Send
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
