import React from 'react';
import * as Yup from 'yup';
import { listsUrls } from '@config/routes';
import { cityMeta } from '@config/meta/list/index';
import { Grid } from '@mui/material';
import { TextField, CheckBoxField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import CountyDropdown from './CountryDropdown';
import { useQueryClient } from 'react-query';

const { citiesUrls, countriesUrls } = listsUrls;

export default function CityDropdown({ children, refetch, country: initialCountry, }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

 
  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="City"
        initialValues={{
          ...getAddFormInitialValues(cityMeta({}), 'listView'),
          country: initialCountry
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(cityMeta({}), 'listView'),
        })}
        getPostUrl={() => citiesUrls.list()}
        getValues={({values, country}) => ({
          ...values,
          country: country?.id
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <TextField label="Name" name="name" required />
            </Grid>
            <Grid item xs={6}>
              <CheckBoxField label="Capital" name="capital" />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Latitude" name="latitude" />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Longitude" name="longitude" />
            </Grid>
            <Grid item xs={6}>
              <CountyDropdown
                refetch={({ id, name }) => {
                  setFieldValue('country', { id, label: name });
                  queryClient.invalidateQueries(['country-postcode']);
                }}
              >
                <AutocompleteFieldV2
                  name='country'
                  label='Country'
                  requestKey='country-postcode'
                  fetchUrl={countriesUrls.list}
                  required
                />
              </CountyDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
