export const documentRowMap = (row) => ({
  id: row.id,
  name: row.name,
  description: row.description,
  tags: row.tags,
  isPublished: row.isPublished,
  documentUrl: row.documentUrl,
  documentFormUrl: row.documentFormUrl,
  expiryDate: row?.expiryDate ? new Date(row.expiryDate) : null,
  createdAt: row.createdAt,
});

export const documentRecipientsRowMap = (row) => ({
  id: row.id,
  firstName: row.firstName,
  lastName: row.lastName,
  email: row.email,
  personId: row?.details?.personId
    ? {
        id: row?.personId,
        label: `${row?.details.personId?.email ?? ''}`,
      }
    : null,
  expiryDate: row.expiryDate,
  tags: row.tags,
  documentFilledUrl: row.documentFilledUrl,
  isEditable: row.isEditable,
  createdAt: row.createdAt,
});
