import * as React from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function SwitchField({ label, labelColor="#FFFFFF", ...rest }) {
  return (
    <FormControlLabel
      sx={{ ml: 0.1, width: '100%' }}
      control={<Switch {...rest} />}
      label={
        <Typography component="legend" color={labelColor}>
          {label}
        </Typography>
      }
    />
  );
}
