import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: null,
  reminders: [],
};

export const reminderBoardSlice = createSlice({
  name: 'reminderBoard',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setReminders: (state, action) => {
      state.reminders = action.payload;
    },
  },
});

export const { setModal, setReminders } = reminderBoardSlice.actions;

export const selectRemindersBoard = (state) => state.reminders;

export default reminderBoardSlice.reducer;
