import React from 'react';
import useToast from '@hooks/useToast';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import { bpaUrls } from '@config/routes';
import { getActionParamAndValue } from '@config/functions/bpaFunctions';
import { createRecord } from '@config/functions/requests';
import { useQueryClient } from 'react-query';

const {
  getPayloadActionsUrls,
  payloadSectionKeysUrls,
  bulkCreateShapeActionParamsUrls,
  modelAssetsUrls,
} = bpaUrls;

export default function FormFilledInPopup({
  open,
  setOpen,
  actionParams,
  workflowId,
  shapeAction,
  user,
}) {
  const [notify] = useToast();
  const queryClient = useQueryClient();

  const initialForm = getActionParamAndValue({
    key: 'form_id',
    actionParams,
  });
  const initialEmail = getActionParamAndValue({
    key: 'email',
    actionParams,
  });
  const initialVacancy = getActionParamAndValue({
    key: 'vacancy_id',
    actionParams,
  });

  function getFormValues(initialForm) {
    const { value } = initialForm || {};

    if (!value || !value.model_asset) {
      return null;
    }

    return {
      id: value.model_asset,
      label: value.label,
    };
  }

  function getPayloadSectionValues(initialInput) {
    const { value } = initialInput || {};

    if (!value || !value.details || !value.details.mapping_field_name) {
      return null;
    }

    return {
      id: value.details.mapping_field_name.payload_section,
      label: value.details.mapping_field_name.details?.payload_section?.name,
    };
  }

  function getPayloadSectionKeyValues(initialInput) {
    const { value } = initialInput || {};

    if (!value || !value.mapping_field_name) {
      return null;
    }

    return {
      id: value.mapping_field_name,
      label: value?.details?.mapping_field_name?.label,
    };
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open}
      onKeyDown={(e) => e.stopPropagation()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent maxWidth="sm" fullWidth>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Map Fields
        </Typography>

        <Formik
          enableReinitialize
          initialValues={{
            formId: getFormValues(initialForm),
            payloadSection: getPayloadSectionValues(initialEmail),
            payloadSectionKey: getPayloadSectionKeyValues(initialEmail),
            vacancyPayloadSection: getPayloadSectionValues(initialVacancy),
            vacancyPayloadSectionKey:
              getPayloadSectionKeyValues(initialVacancy),
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true);

              const payload = [];

              // Add form
              payload.push({
                id: initialForm?.value?.id,
                type: 'String',
                action_param: initialForm?.param?.id,
                shape_action: shapeAction?.id,
                label: values?.formId?.label,
                model_asset: values?.formId?.id,
              });

              // Add email
              payload.push({
                id: initialEmail?.value?.id,
                type: 'String',
                action_param: initialEmail?.param?.id,
                shape_action: shapeAction?.id,
                label: values?.payloadSectionKey?.label,
                mapping_field_name: values?.payloadSectionKey?.id,
              });

              // Add vacancy
              payload.push({
                id: initialVacancy?.value?.id,
                type: 'String',
                action_param: initialVacancy?.param?.id,
                shape_action: shapeAction?.id,
                label: values?.vacancyPayloadSectionKey?.label,
                mapping_field_name: values?.vacancyPayloadSectionKey?.id,
              });

              await createRecord({
                values: {
                  shape_action_parameters: payload,
                },
                url: bulkCreateShapeActionParamsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              queryClient.invalidateQueries(['bpa-board']);
              notify('Operation completed', {
                type: 'SUCCESS',
              });
              setOpen(false);
            } catch (error) {
              console.log(error?.response?.data ?? error?.message);
              notify('There was an error, please refresh the page', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting }) => (
            <Form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AutocompleteFieldV2
                    name="formId"
                    label="Form"
                    requestKey={`${shapeAction?.id}-popup-forms`}
                    fetchUrl={modelAssetsUrls.list}
                    urlParams={`&microservice=Forms&model=form&workflow=${workflowId}`}
                    renderRow={({ id, details }) => ({
                      id,
                      label: details?.asset?.name,
                    })}
                    required
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography>Email</Typography>
                </Grid>

                <Grid item xs={3}>
                  <AutocompleteFieldV2
                    label="Payload section"
                    name="payloadSection"
                    requestKey={`formId-payload-section`}
                    fetchUrl={getPayloadActionsUrls.list}
                    urlParams={`&workflow_id=${workflowId}&exclude_draft_stage=true`}
                    renderRow={({ id, name, keys }) => ({
                      id,
                      label: name,
                      sectionKeys: keys,
                    })}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteFieldV2
                    label="Payload section keys"
                    name="payloadSectionKey"
                    requestKey={`to-email-map-payload-section-keys`}
                    fetchUrl={payloadSectionKeysUrls.list}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.label,
                    })}
                    enabled={!!values?.payloadSection?.id}
                    requestKeyOptions={[values?.payloadSection?.id]}
                    urlParams={
                      values?.payloadSection?.id
                        ? `&payload_section=${values?.payloadSection?.id}`
                        : ''
                    }
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Static value"
                    name="staticValue"
                    multiline
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography>Vacancy</Typography>
                </Grid>

                <Grid item xs={3}>
                  <AutocompleteFieldV2
                    label="Payload section"
                    name="vacancyPayloadSection"
                    requestKey={`vacancy-payload-section`}
                    fetchUrl={getPayloadActionsUrls.list}
                    urlParams={`&workflow_id=${workflowId}&exclude_draft_stage=true`}
                    renderRow={({ id, name, keys }) => ({
                      id,
                      label: name,
                      sectionKeys: keys,
                    })}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteFieldV2
                    label="Payload section keys"
                    name="vacancyPayloadSectionKey"
                    requestKey={`vacancy-map-payload-section-keys`}
                    fetchUrl={payloadSectionKeysUrls.list}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.label,
                    })}
                    enabled={!!values?.vacancyPayloadSection?.id}
                    requestKeyOptions={[values?.vacancyPayloadSection?.id]}
                    urlParams={
                      values?.vacancyPayloadSection?.id
                        ? `&payload_section=${values?.vacancyPayloadSection?.id}`
                        : ''
                    }
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Static value"
                    name="staticValue"
                    multiline
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
