import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: null,
  opportunities: [],
};

export const opportunityBoardSlice = createSlice({
  name: 'opportunityBoard',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setOpportunities: (state, action) => {
      state.opportunities = action.payload;
    },
  },
});

export const { setModal, setOpportunities } = opportunityBoardSlice.actions;

export const selectOpportunitiesBoard = (state) => state.opportunityBoard;

export default opportunityBoardSlice.reducer;
