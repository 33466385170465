import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import useToast from '@hooks/useToast';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { ecommerceUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { PaymentForm } from '@config/forms/ecommerce';
import ecommerceHandleRow from '@config/handleRows/ecommerce';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  paymentsUrls,
  cartsUrls,
  addressesUrls,
  paymentProvidersUrls,
  paymentMethodsUrls,
  paymentStatusesUrls,
} = ecommerceUrls;
const { paymentRowMap } = ecommerceHandleRow;

export default function Payments({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const [cartTerm, setCartTerm] = useState([]);
  const [addressTerm, setAddressTerm] = useState([]);
  const [paymentProviderTerm, setPaymentProviderTerm] = useState([]);
  const [paymentMethodTerm, setPaymentMethodTerm] = useState([]);
  const [paymentStatusTerm, setPaymentStatusTerm] = useState([]);

  const { data: carts, isFetching: fetchingCarts } = useAutocomplete({
    key: 'carts',
    getUrl: cartsUrls.list,
    inputValue: cartTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.customer && r.details && r.customer_details
            ? r.customer_details.first_name
            : r.customer,
      };
    },
  });

  const { data: addresses, isFetching: fetchingAddresses } = useAutocomplete({
    key: 'addresses',
    getUrl: addressesUrls.list,
    inputValue: addressTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.address1,
      };
    },
  });

  const { data: paymentProviders, isFetching: fetchingPaymentProviders } =
    useAutocomplete({
      key: 'paymentProviders',
      getUrl: paymentProvidersUrls.list,
      inputValue: paymentProviderTerm,
    });

  const { data: paymentMethods, isFetching: fetchingPaymentMethods } =
    useAutocomplete({
      key: 'paymentMethods',
      getUrl: paymentMethodsUrls.list,
      inputValue: paymentMethodTerm,
    });

  const { data: paymentStatuses, isFetching: fetchingPaymentStatuses } =
    useAutocomplete({
      key: 'paymentStatuses',
      getUrl: paymentStatusesUrls.list,
      inputValue: paymentStatusTerm,
    });

  const paymentsColOptions = React.useMemo(() => {
    return {
      carts,
      fetchingCarts,
      setCartTerm,
      addresses,
      fetchingAddresses,
      setAddressTerm,
      paymentProviders,
      fetchingPaymentProviders,
      setPaymentProviderTerm,
      paymentMethods,
      fetchingPaymentMethods,
      setPaymentMethodTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
    };
  }, [
    carts,
    fetchingCarts,
    setCartTerm,
    addresses,
    fetchingAddresses,
    setAddressTerm,
    paymentProviders,
    fetchingPaymentProviders,
    setPaymentProviderTerm,
    paymentMethods,
    fetchingPaymentMethods,
    setPaymentMethodTerm,
    paymentStatuses,
    fetchingPaymentStatuses,
    setPaymentStatusTerm,
  ]);

  return (
    <Box>
      <ListView
        label="Payments"
        prefix="0kb"
        labels={labels}
        editMode={editMode}
        microservice="E-Commerce"
        model="Payment"
        urls={paymentsUrls}
        columnKey="paymentEcommerce"
        columnOptions={paymentsColOptions}
        addNewForm={{
          getFields: () =>
            PaymentForm({
              carts,
              fetchingCarts,
              setCartTerm,
              addresses,
              fetchingAddresses,
              setAddressTerm,
              paymentProviders,
              fetchingPaymentProviders,
              setPaymentProviderTerm,
              paymentMethods,
              fetchingPaymentMethods,
              setPaymentMethodTerm,
              paymentStatuses,
              fetchingPaymentStatuses,
              setPaymentStatusTerm,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: paymentsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify(`Payment Created!`, {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={paymentRowMap}
      />
    </Box>
  );
}
