import React from 'react';
import * as Yup from 'yup';
import { lmsUrls, crmUrls } from '@config/routes';
import { mentorMeta } from '@config/meta/lms';
import { Grid } from '@mui/material';
import { AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';
import { useQueryClient } from 'react-query';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import useToast from '@hooks/useToast';

const { mentorsUrls } = lmsUrls;
const { personsUrls } = crmUrls;

export default function MentorDropdownForm({ title, children, refetch }) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  const renderPerson = (row) => {
    const { id, email } = row;
    return {
      id,
      label: email,
    };
  };

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title={title || 'Mentor'}
        initialValues={{
          ...getAddFormInitialValues(mentorMeta({ queryClient }), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            mentorMeta({ queryClient }),
            'listView'
          ),
        })}
        getPostUrl={() => mentorsUrls.list()}
        handleSubmit={async (
          values,
          setSubmitting,
          resetForm,
          setOpen,
          setError,
          setFieldError
        ) => {
          try {
            const { person, ...rest } = values;

            if (!person) {
              setFieldError('person', 'Please select a person');
              return;
            }

            const { data } = await createRecord({
              values: {
                ...rest,
                personId: person?.id,
              },
              url: mentorsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setOpen(false);
            refetch(data[0]);
            return data[0]?.id;
          } catch (err) {
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        }}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PersonDropdown
                refetch={({ id, email }) => {
                  setFieldValue('personId', {
                    id: id,
                    label: email,
                  });
                  queryClient.invalidateQueries(['mentor-person']);
                }}
              >
                <AutocompleteFieldV2
                  name="person"
                  label="Person"
                  requestKey="mentor-person"
                  fetchUrl={personsUrls.list}
                  renderRow={renderPerson}
                  required
                />
              </PersonDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
