// handleRows

export const agentRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  user: r?.details?.user
      ? { id: r?.user, label: r.details.user?.email }
      : null,
  created_at: r.created_at,
});

export const customerRowsMap = (r) => ({
  id: r.id,
  first_name: r.first_name,
  last_name: r.last_name,
  email: r.email,
  question: r.question,
  phone: r.phone,
  created_at: r.created_at,
});

export const ticketRowsMap = (r) => ({
  id: r.id,
  headline: r.headline,
  customer: r.details && r.details.customer && r.details.customer.email,
  // ? { id: r.customer, label: r.details.customer.email }
  // : null,
  customer2: r.details && r.details.customer && r.details.customer.phone,
  // ? { id: r.customer, label: r.details.customer.phone }
  // : null,
  closed: r.closed,
  created_at: r.created_at ? new Date(r.created_at) : null,
});
