import React from 'react';
import * as Yup from 'yup';
import { hrUrls } from '@config/routes';
import { crmUrls } from '@config/routes';
import { employeesMeta } from '@config/meta/hr';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';
import { useQueryClient } from 'react-query';


const { employeesUrls, createSalesPeoplesUrls } = hrUrls;
const { personsUrls } = crmUrls;

export default function EmployeeDropdownForm({
  title,
  children,
  refetch,
  isSalesPerson,
}) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const [hideEmployee, setHideEmployee] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        hidden={hideEmployee}
        title={title || 'Employee'}
        initialValues={{
          ...getAddFormInitialValues(employeesMeta({}), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(employeesMeta({}), 'listView'),
        })}
        getPostUrl={() =>
          isSalesPerson ? createSalesPeoplesUrls.list() : employeesUrls.list()
        }
        getValues={({ person,...values }) => ({
          ...values,
          person: person?.id
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <PersonDropdown
                hideParent={setHideEmployee}
                refetch={({
                  id,
                  email,
                }) => {
                  setFieldValue('person', {
                    id: id,
                    label: `${email || ''}`,
                  });
                  queryClient.invalidateQueries([
                    'employee-dropdown-persons',
                  ]);
                }}
              >
                <AutocompleteFieldV2
                  name='person'
                  label='Person'
                  requestKey='employee-dropdown-persons'
                  fetchUrl={personsUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.email,
                  })}
                  required
                />
              </PersonDropdown>
            </Grid>
            <Grid item xs={6}>
              <TextField
                key='Work_email'
                label='Work Email'
                name='work_email'
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key='Work_Phone'
                label='Work Phone'
                name='work_phone'
              />
            </Grid>
            {/* <Grid item xs={4}>
              <TextField
                key='next_of_kin_email'
                label='Next Of Kin Email'
                name='next_of_kin_email'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                key='next_of_kin_phone'
                label='Next Of Kin Phone'
                name='next_of_kin_phone'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                key='Charge_Rate'
                label='Charge Rate'
                name='charge_rate'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField key='cv' label='CV' name='cv' placeholder='https://' />
            </Grid>
            <Grid item xs={6}>
              <DateTimePickerField label='Joined' name='start_date' />
            </Grid>
            <Grid item xs={6}>
              <DateTimePickerField label='Left Date' name='end_date' />
            </Grid> */}
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
