import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { computeUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { blockGroupRowsMap } from '@config/handleRows/compute';
import { BlockGroupForm } from '@config/forms/compute';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { microservices } from '@config/constants';
import PublicUserErrorScreen from '@components/CMS-V2/PublicUserErrorScreen';

const { blockGroupsUrls } = computeUrls;

export default function BlockGroup({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [blockGroupListData, setBlockGroupListData] = React.useState({});

  const blockGroupColOptions = React.useMemo(
    () => ({
      nextOrder: blockGroupListData?.totalCount ?? 1,
    }),
    [blockGroupListData?.totalCount]
  );

  if (
    Array.isArray(user?.details?.roles_names) &&
    !(
      user?.details?.roles_names.includes('Superusers') ||
      user?.details?.is_superuser
    )
  ) {
    return <PublicUserErrorScreen />;
  }

  return (
    <Box>
      <ListView
        nodeAPI
        label='Block Group'
        prefix='05d'
        microservice={microservices.Compute.name}
        model={microservices.Compute.models.blockGroup}
        labels={labels}
        editMode={editMode}
        urls={blockGroupsUrls}
        columnKey='blockGroupsCompute'
        columnOptions={blockGroupColOptions}
        addNewForm={{
          getFields: () => BlockGroupForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { data } = await createRecord({
                values,
                url: blockGroupsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={blockGroupRowsMap}
        getListData={(data) => setBlockGroupListData(data)}
        customSortColumnName='order'
        sortColumnBy='asc'
      />
    </Box>
  );
}
