import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export default function DualButtonSelectField({
  options,
  value,
  setValue,
  isResponsePage,
} = {}) {
  return (
    <ButtonGroup
      variant='outlined'
      aria-label='PS button group'
      style={{ pointerEvents: isResponsePage ? 'none' : '' }}
    >
      {options
        .sort((a, b) => a?.order - b?.order)
        .map(({ id, option }) => (
          <Button
            key={id}
            variant={value === id ? 'contained' : 'outlined'}
            onClick={() => setValue(id)}
          >
            {option}
          </Button>
        ))}
    </ButtonGroup>
  );
}
