import React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EmailTemplateAccordion from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateAccordion';
import PayloadFieldSelector from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/PayloadFieldSelector';

export default function EmailTemplateContextFieldList({
  contextFields,
  workflowId,
  setFieldValue,
  values,
  isKanbanView,
}) {
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const getPayloadSectionContextKey = (key) => {
    return _.camelCase(`${key}PayloadSectionContext`);
  };

  const getSectionFieldContextKey = (key) => {
    return _.camelCase(`${key}SectionKeyContext`);
  };

  if (!(Array.isArray(contextFields) && contextFields.length)) {
    return null;
  }

  return (
    <Box>
      <Typography sx={{ fontWeight: '500', fontSize: '18px', mb: 2 }}>
        Field Mapping
      </Typography>

      <EmailTemplateAccordion
        open={open}
        setOpen={handleToggle}
        label="Context Fields"
      >
        <Stack
          spacing={2}
          sx={{
            pointerEvents: isKanbanView ? 'none' : undefined,
            userSelect: 'none',
          }}
        >
          {contextFields.map(({ id, name, label }) => (
            <PayloadFieldSelector
              key={id}
              label={label}
              values={values}
              workflowId={workflowId}
              initialPayloadSection={
                values?.contextFields[getPayloadSectionContextKey(name)]
              }
              initialPayloadSectionField={
                values?.contextFields[getSectionFieldContextKey(name)]
              }
              payloadSectionName={`contextFields.${getPayloadSectionContextKey(
                name
              )}`}
              payloadSectionFieldName={`contextFields.${getSectionFieldContextKey(
                name
              )}`}
              setFieldValue={setFieldValue}
            />
          ))}
        </Stack>
      </EmailTemplateAccordion>
    </Box>
  );
}
