import * as React from 'react';
import { Box, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export default function Breadcrumb({ items, children }) {
  const truncateText = (text) => {
    return typeof text === 'string' && text.length > 38
      ? `${text.substring(0, 38)}...`
      : text;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          {items.map((item) => {
            const key = uuidv4();
            return item.to ? (
              <Link key={key} to={item.to}>
                <Typography
                  component='div'
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                  {truncateText(item.text)}
                </Typography>
              </Link>
            ) : (
              <Typography key={key} component='div'>
                {truncateText(item.text)}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Box>
      <Box key='children' sx={{ ml: 4, mt: '-5px' }}>
        {children}
      </Box>
    </Box>
  );
}
