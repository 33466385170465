import { Box, Typography, Divider } from '@mui/material';
import React from 'react';
import { detectLink } from '@config/functions/helperFunctions';

const headingStyles = {
  color: '#012970',
  fontSize: '28px',
  lineHeight: '43px',
  fontWeight: '600',
};

export default function FormHeader({ form, isSuccess }) {
  return (
    <>
      <Box sx={{ px: '24px' }}>
        <Typography sx={headingStyles}>{form?.title}</Typography>
        {form?.description ? (
          <Typography sx={{ mt: '12px', color: '#202124' }}>
            {form?.description}
          </Typography>
        ) : null}

        {isSuccess ? (
          <Typography sx={{ mt: '12px', width: '75%' }}>
            <Divider sx={{ mb: '12px' }} />

            {detectLink(
              form?.closingMessage || 'Your response has been recorded'
            )}
          </Typography>
        ) : (
          <Box></Box>
        )}
      </Box>

      {!form?.showFirstName &&
      !form?.showLastName &&
      !form?.showEmail ? null : (
        <Box
          sx={{ borderTop: '1px solid #dadce0', mt: '12px', width: '100%' }}
        />
      )}
    </>
  );
}
