import { Box } from '@mui/system';
import Breadcrumb from '@components/core/Breadcrumbs';

export default function DetailPageHeader({ items, children }) {
  return (
    <Box sx={{ mb: 3 }}>
      <Breadcrumb items={items}>{children}</Breadcrumb>
    </Box>
  );
}
