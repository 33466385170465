import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { communicationUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { emailTemplateMeta } from '@config/meta/communication';
import { contextFieldRowsMap } from '@config/handleRows/communication';
import { ContextFieldForm } from '@config/forms/communication';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord, updateRecord } from '@config/functions/requests';
import AuthWrapper from '@components/Communication/AuthWrapper';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import Accordion from '@ui/Accordion';
import WysiwygEditor from '@components/wiki/WysiwygEditor';

const { mailTemplatesUrls, contextFieldUrls } = communicationUrls;

function EmailTemplateDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const [dirtyFields, setDirtyFields] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [htmlText, setHtmlText] = React.useState('');
  const [init, setInit] = React.useState(false);

  const { data: contextFieldsList } = useRQuery({
    key: ['contextFields', recordId, user.token, user?.actAs],
    url: contextFieldUrls.list(
      `?connected=true&smtp_connected=true&send_emails_from_this_account=true&template=${recordId}`
    ),
    renderRow: (r) => ({
      id: r.id,
      type: r.type,
      label: r.name,
    }),
    config: getAuthHeader(user.token, user?.actAs),
  });

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => emailTemplateMeta({ data }),
    []
  );

  const handleAccordionValueChange = async (values) => {
    try {
      await updateRecord({
        url: mailTemplatesUrls.detail(recordId),
        values,
        token: user.token,
        actAs: user?.actAs,
      });
    } catch (err) {
      notify('Failed to save', {
        type: 'ERROR',
      });
    }
  };

  React.useEffect(() => {
    let timeoutId = setTimeout(() => {
      handleAccordionValueChange(dirtyFields);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [dirtyFields]);

  React.useEffect(() => {
    if (!recordData || init) return;
    setHtmlText(recordData?.html || '');
    setInit(true);
  }, [recordData]);

  React.useEffect(() => {
    if (!contextFieldsList) return;
    console.log('here again & again', contextFieldsList.length);

    localStorage.setItem(
      'contextFields',
      JSON.stringify(
        contextFieldsList.map((contextField) => [
          `${contextField.label} // ${contextField.type}`,
          `{{ context.${contextField.label} }}`,
        ])
      )
    );
  }, [contextFieldsList]);

  return (
    <>
      <Box>
        {recordData ? (
          <Box>
            <DetailPageHeader
              items={[
                { to: `/email-templates`, text: 'Email Templates' },
                { text: recordData?.name },
              ]}
            />
          </Box>
        ) : null}

        <DetailCard
          urls={mailTemplatesUrls}
          recordId={recordId}
          getSchema={getSchema}
          setData={setRecordData}
          microservice={microservices.NOTIFICATION.name}
        />

        <Box sx={{ mt: 5 }}>
          <Accordion
            expanded={expanded}
            onChange={() => setExpanded((s) => !s)}
            label="Body"
            editMode={editMode}
            labels={labels}
            prefix="021"
          >
            {init ? (
              <WysiwygEditor
                initData={htmlText}
                msName={microservices.NOTIFICATION.name}
                onChange={(e) =>
                  setDirtyFields((s) => ({ ...s, html: e.editor.getData() }))
                }
              />
            ) : (
              <p>loading...</p>
            )}
          </Accordion>

          <DetailAccordionView
            microservice={microservices.NOTIFICATION.name}
            model={microservices.NOTIFICATION.models.contextField}
            columnKey="contextFieldCommunication"
            label="Context Fields"
            prefix="0ln"
            labels={labels}
            editMode={editMode}
            urls={contextFieldUrls}
            addNewForm={{
              getFields: () => ContextFieldForm(),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError
              ) => {
                try {
                  const response = await createRecord({
                    values: { ...values, template: recordId },
                    url: contextFieldUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                    encryptionToken: user.encryptionToken,
                  });

                  resetForm();
                  setAddNewForm(false);
                  // notify(`Context Field Created`, {
                  //   type: 'SUCCESS',
                  // });
                  refetch();
                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 1000);

                  return response?.data?.id;
                } catch (err) {
                  console.log(err);
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={contextFieldRowsMap}
            urlParams={`&template=${recordId}`}
          />
        </Box>
      </Box>
    </>
  );
}

export default function AuthenticatedEmailTemplateDetail(props) {
  return (
    <AuthWrapper>
      <EmailTemplateDetail {...props} />
    </AuthWrapper>
  );
}
