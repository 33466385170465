import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ecommerceUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordianArrayView from '@components/DetailAccordionArrayView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import ecommerceHandleRows from '@config/handleRows/ecommerce';
import { DeliveryMethodForm, PaymentForm } from '@config/forms/ecommerce';
import {
  paymentProviderMeta,
} from '@config/meta/ecommerce';
import DetailPageHeader from '@components/DetailPageHeader';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  deliveryMethodsUrls,
  paymentProvidersUrls,
  paymentsUrls,
  cartsUrls,
  addressesUrls,
  paymentMethodsUrls,
  paymentStatusesUrls,
} = ecommerceUrls;
const { deliveryMethodRowMap, paymentRowMap } = ecommerceHandleRows;

export default function PaymentProviderDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [cartTerm, setCartTerm] = useState('');
  const [addressTerm, setAddressTerm] = useState('');
  const [paymentMethodTerm, setPaymentMethodTerm] = useState('');
  const [paymentStatusTerm, setPaymentStatusTerm] = useState('');
  const [paymentProviderTerm, setPaymentProviderTerm] = useState('');
  const [notify] = useToast();

  const {
    data: carts,
    isFetching: fetchingCarts,
    refetch: refetchCart,
  } = useAutocomplete({
    key: 'carts',
    getUrl: cartsUrls.list,
    inputValue: cartTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.customer && r.details && r.customer_details
            ? r.customer_details.first_name
            : r.customer,
      };
    },
  });

  const {
    data: addresses,
    isFetching: fetchingAddresses,
    refetch: refetchBilling,
  } = useAutocomplete({
    key: 'addresses',
    getUrl: addressesUrls.list,
    inputValue: addressTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.address1,
      };
    },
  });

  const {
    data: paymentMethods,
    isFetching: fetchingPaymentMethods,
    refetch: refetchPaymentMethod,
  } = useAutocomplete({
    key: 'paymentMethods',
    getUrl: paymentMethodsUrls.list,
    inputValue: paymentMethodTerm,
  });

  const {
    data: paymentStatuses,
    isFetching: fetchingPaymentStatuses,
    refetch: refetchPaymentStatus,
  } = useAutocomplete({
    key: 'paymentStatuses',
    getUrl: paymentStatusesUrls.list,
    inputValue: paymentStatusTerm,
  });

  const {
    data: paymentProviders,
    isFetching: fetchingPaymentProviders,
    refetch: refetchPaymentProvider,
  } = useAutocomplete({
    key: 'paymentProviders',
    getUrl: paymentProvidersUrls.list,
    inputValue: paymentProviderTerm,
  });

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => paymentProviderMeta({ data }),
    []
  );

  const deliveryMethodsColOptions = React.useMemo(() => {
    return {
      paymentProviders,
      fetchingPaymentProviders,
      setPaymentProviderTerm,
    };
  }, [paymentProviders, fetchingPaymentProviders, setPaymentProviderTerm]);

  const paymentsColOptions = React.useMemo(() => {
    return {
      carts,
      fetchingCarts,
      setCartTerm,
      addresses,
      fetchingAddresses,
      setAddressTerm,
      paymentProviders,
      fetchingPaymentProviders,
      setPaymentProviderTerm,
      paymentMethods,
      fetchingPaymentMethods,
      setPaymentMethodTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
    };
  }, [
    carts,
    fetchingCarts,
    setCartTerm,
    addresses,
    fetchingAddresses,
    setAddressTerm,
    paymentProviders,
    fetchingPaymentProviders,
    setPaymentProviderTerm,
    paymentMethods,
    fetchingPaymentMethods,
    setPaymentMethodTerm,
    paymentStatuses,
    fetchingPaymentStatuses,
    setPaymentStatusTerm,
  ]);

  const accordData = {
    deliveryMethods: {
      columnKey: "deliveryMethodEcommerce",
      columnOptions: deliveryMethodsColOptions,
      microservice: 'E-Commerce',
      model: 'DeliveryMethod',

      label: 'Delivery Methods',
      prefix: '0ld',
      urls: deliveryMethodsUrls,
      addNewForm: {
        getFields: () =>
          DeliveryMethodForm({
            paymentProviders,
            fetchingPaymentProviders,
            setPaymentProviderTerm,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                provider: recordId,
              },
              url: deliveryMethodsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Delivery Method Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: deliveryMethodRowMap,
      urlParams: `&provider=${recordId}`,
    },
    payments: {
      columnKey: "paymentEcommerce",
      columnOptions: paymentsColOptions,
      microservice: 'E-Commerce',
      model: 'Payment',

      label: 'Payments',
      prefix: '0bt',
      urls: paymentsUrls,
      addNewForm: {
        getFields: () =>
          PaymentForm({
            carts,
            fetchingCarts,
            setCartTerm,
            addresses,
            fetchingAddresses,
            setAddressTerm,
            paymentProviders,
            fetchingPaymentProviders,
            setPaymentProviderTerm,
            paymentMethods,
            fetchingPaymentMethods,
            setPaymentMethodTerm,
            paymentStatuses,
            fetchingPaymentStatuses,
            setPaymentStatusTerm,
            refetchCart,
            refetchBilling,
            refetchPaymentProvider,
            refetchPaymentMethod,
            refetchPaymentStatus,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                // payment_provider: recordId,
              },
              url: paymentsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Payment Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: paymentRowMap,
      urlParams: `&payment_providers=${recordId}`,
    },
  };

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/payment-providers`, text: 'Payment Providers' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={paymentProvidersUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordianArrayView
          value={accordData}
          labels={labels}
          editMode={editMode}
        />
      </Box>
    </Box>
  );
}
