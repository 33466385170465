import * as Yup from 'yup';
import {
  fieldType,
  logAction,
  shapeTransitionLogsType,
} from '@config/constants';
import { pmUrls, systemUrls, accountUrls, bpaUrls } from '@config/routes';
import {
  formatToUTCDate,
  formatToUTCDateTime,
} from '@config/functions/helperFunctions';

const { userAccountsUrls } = pmUrls;
const { workflowsUrls } = bpaUrls;
const { clientsUrls, relationShipsUrls, metaModelsUrls } = systemUrls;
const { modelsUrls: accountsModelsUrls } = accountUrls;

export const automataConnectionWithAModelMeta = ({ data } = {}) => [
  {
    field: 'model',
    headerName: 'Model',
    width: 350,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: accountsModelsUrls.list,
    urlParams: `&workflowable_model=true`,
    requestKey: `${data?.id}-models`,
    validate: Yup.mixed().required(),
    initialValue: data?.model
      ? {
        id: data?.model,
        label: data?.details?.model?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'automata',
    headerName: 'Automata',
    width: 350,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: workflowsUrls.list,
    requestKey: `${data?.id}-automatas`,
    validate: Yup.mixed().required(),
    getHref: (r) => `/workflows/${r?.automata?.id}`,
    initialValue: data?.model
      ? {
        id: data?.model,
        label: data?.details?.model?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 200,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const templateMeta = ({ data } = {}) => [
  {
    field: 'model',
    headerName: 'Type',
    width: 200,
    editable: false,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: metaModelsUrls.list,
    requestKey: `${data?.id}-meta-models`,
    validate: Yup.mixed().required(),
    initialValue: data?.model
      ? {
        id: data?.model,
        label: data?.details?.model?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.LINK,
    to: '/templates/',
    onForm: true,
    width: 700,
    editable: false,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 700,
    editable: false,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'clone_from',
    headerName: 'Clone From',
    onForm: true,
    width: 300,
    hideColumn: true,
    onDetailCard: false,
    editable: false,
    initialValue: null,
    validate: Yup.mixed().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const uiLabelMeta = ({ data } = {}) => [
  {
    field: 'code',
    headerName: 'Code',
    onForm: false,
    width: 75,
    editable: true,
    value: data ? data.code : null,
    initialValue: Math.random().toString(36).slice(2, 5),
    validate: Yup.string().max(3).required(),
  },
  {
    field: 'label_en',
    headerName: 'Label EN',
    onForm: true,
    width: 200,
    editable: true,
    value: data ? data.label_en.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'label_fr',
    headerName: 'Label FR',
    onForm: true,
    width: 200,
    editable: true,
    value: data ? data.label_fr : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'label_de',
    headerName: 'Label DE',
    onForm: true,
    width: 200,
    editable: true,
    value: data ? data.label_de : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'label_ur',
    headerName: 'Label UR',
    onForm: true,
    width: 200,
    editable: true,
    value: data ? data.label_ur : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const logsMeta = ({ data } = {}) => [
  {
    field: 'user',
    headerName: 'User',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: userAccountsUrls.list,
    requestKey: `${data?.id}-log-users`,
    validate: Yup.mixed(),
    hideColumn: true,
    initialValue: data?.user
      ? {
        id: data?.user,
        label: data?.details?.user?.email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.email,
    }),
  },
  {
    field: 'microservice',
    headerName: 'Microservice',
    onForm: true,
    width: 300,
    editable: false,
    value: data ? data.microservice : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'model',
    headerName: 'Model',
    onForm: true,
    width: 300,
    editable: false,
    value: data ? data.model : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'object_id',
    headerName: 'Object',
    onForm: true,
    width: 300,
    editable: false,
    value: data ? data.object_id : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'action',
    headerName: 'Action',
    onForm: true,
    width: 180,
    editable: false,
    type: fieldType.SELECT,
    options: logAction,
    initialValue: '',
    value: data ? data.action : null,
    validate: Yup.string(),
  },
  {
    field: 'before_action_content',
    headerName: 'Before Action Content',
    onForm: true,
    width: 300,
    editable: false,
    value: data ? data.before_action_content : null,
    initialValue: '',
    validate: Yup.string(),
    hideColumn: true,
    onDetailCard: false,
  },
  {
    field: 'after_action_content',
    headerName: 'After Action Content',
    onForm: true,
    width: 300,
    editable: false,
    value: data ? data.after_action_content : null,
    initialValue: '',
    validate: Yup.string(),
    hideColumn: true,
    onDetailCard: false,
  },
  {
    field: 'action_datetime',
    headerName: 'Action Datetime',
    width: 180,
    value: data ? formatToUTCDateTime(new Date(data.action_datetime)) : null,
    onForm: true,
    initialValue: formatToUTCDateTime(new Date()),
    editable: false,
    type: fieldType.DATE_TIME,
  },
  {
    field: 'date_created',
    headerName: 'Date created',
    width: 180,
    value: data && data.date_created ? new Date(data.date_created) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const systemLogsMeta = ({ data } = {}) => [
  {
    field: 'file_name',
    headerName: 'File Name',
    onForm: true,
    width: 300,
    editable: false,
    value: data ? data.file_name : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'microservice',
    headerName: 'Microservice',
    onForm: true,
    width: 300,
    editable: false,
    value: data ? data.microservice : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'logs_date',
    headerName: 'Logs date',
    width: 180,
    value: data ? formatToUTCDate(new Date(data.logs_date)) : null,
    initialValue: formatToUTCDate(new Date()),
    validate: Yup.string(),
    onForm: true,
    editable: false,
    type: fieldType.DATE,
  },
  {
    field: 'date_created',
    headerName: 'Date created',
    width: 180,
    value: data && data.date_created ? new Date(data.date_created) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const importLogMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'microservice',
    headerName: 'Microservice',
    onForm: false,
    editable: false,
    value: data ? data.microservice : null,
    width: 170,
    validate: Yup.string().required(),
  },
  {
    field: 'model',
    headerName: 'Model',
    onForm: false,
    editable: false,
    value: data ? data.model : null,
    width: 170,
    validate: Yup.string().required(),
  },
  {
    field: 'total_rows',
    headerName: 'Total Rows',
    onForm: true,
    editable: false,
    value: data ? data.total_rows : null,
    width: 120,
    validate: Yup.number(),
    initialValue: '',
  },
  {
    field: 'failed_count',
    headerName: 'Failed',
    onForm: true,
    editable: false,
    value: data ? data.failed_count : null,
    width: 120,
    validate: Yup.number(),
    initialValue: '',
  },
  {
    field: 'imported_count',
    headerName: 'Imported Count',
    onForm: true,
    editable: false,
    value: data ? data.imported_count : null,
    width: 120,
    validate: Yup.number(),
    initialValue: '',
  },
  {
    field: 'failed',
    headerName: 'Failed',
    onForm: true,
    editable: false,
    value: data ? data.failed : null,
    width: 120,
    validate: Yup.string(),
    initialValue: '',
  },
  {
    field: 'imported',
    headerName: 'Imported',
    onForm: true,
    editable: false,
    value: data ? data.imported : null,
    width: 120,
    validate: Yup.string(),
    initialValue: '',
  },
  {
    field: 'finished_at',
    headerName: 'Finished At',
    type: fieldType.DATE_TIME,
    editable: true,
    onForm: false,
    width: 170,
    initialValue: formatToUTCDateTime(new Date()),
    value: data ? formatToUTCDateTime(new Date(data.finished_at)) : null,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const visitorLogMeta = ({ data } = {}) => [
  {
    field: 'microservice',
    headerName: 'Microservice',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.microservice : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'model',
    headerName: 'Model',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.model : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'ip',
    headerName: 'Ip',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.ip : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'os',
    headerName: 'Os',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.os : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'browser',
    headerName: 'Browser',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.browser : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'location',
    headerName: 'Location',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.location : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'city',
    headerName: 'City',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.city : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'country',
    headerName: 'Country',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.country : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'country_code',
    headerName: 'Country Code',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.country_code : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'postal_code',
    headerName: 'Postal Code',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.postal_code : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'longitude',
    headerName: 'Longitude',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.longitude : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'latitude',
    headerName: 'Latitude',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.latitude : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'timezone',
    headerName: 'Timezone',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.timezone : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'utm_campaign',
    headerName: 'UTM Campaign',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.utm_campaign : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'utm_medium',
    headerName: 'UTM Medium',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.utm_medium : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'utm_source',
    headerName: 'UTM Source',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.utm_source : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const shapeTransitionMeta = ({ data } = {}) => [
  {
    field: 'instance',
    headerName: 'Instance',
    onForm: true,
    width: 300,
    editable: false,
    value: data?.details?.instance?.label,
    initialValue: '',
    validate: Yup.string(),
    type: fieldType.LINK,
    to: '/shape-transition-logs/',
  },

  {
    field: 'transition',
    headerName: 'Transition',
    onForm: true,
    width: 500,
    editable: false,
    value:
      data?.transition !== null
        ? `${data?.details?.transition?.details?.shape?.name} -> ${data?.details?.transition?.details?.successor?.name}`
        : '',
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'current_shape',
    headerName: 'Current Shape',
    onForm: true,
    width: 180,
    editable: false,
    value: data?.details?.current_shape?.name,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'type',
    headerName: 'Type',
    onForm: true,
    width: 180,
    editable: false,
    type: fieldType.SELECT,
    options: shapeTransitionLogsType,
    initialValue: '',
    value: data ? data.type : null,
    validate: Yup.string(),
  },
  {
    field: 'failure_reason',
    headerName: 'Failure Reason',
    onForm: true,
    width: 300,
    editable: false,
    value: data ? data.failure_reason : null,
    initialValue: '',
    validate: Yup.string(),
    gridStyle: 8,
    type: fieldType.MULTILINE,
    hideColumn: true,
  },
  {
    field: 'shape_action',
    headerName: 'Shape Action',
    onForm: true,
    width: 300,
    editable: false,
    value: data ? data.shape_action : null,
    initialValue: '',
    validate: Yup.string(),
    hideColumn: true,
    onDetailCard: false,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const relationShipMeta = ({ data, pullstreamCompanyAdministrator } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 300,
    editable: pullstreamCompanyAdministrator,
    value: data?.name,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 500,
    editable: pullstreamCompanyAdministrator,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];


export const clientMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 300,
    editable: false,
    value: data?.name,
    initialValue: '',
    validate: Yup.string(),
    type: fieldType.LINK,
    to: '/admin-clients/',
  },
  {
    field: 'domain',
    headerName: 'Domain',
    onForm: true,
    editable: false,
    value: data ? data.domain : null,
    initialValue: '',
    width: 400,
    validate: Yup.string().url(),
    getHref: (r) => r.domain,
    linkInNewTab: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const clientRelationsMeta = ({ data } = {}) => [
  {
    field: 'relation',
    headerName: 'Relation',
    width: 200,
    editable: false,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: relationShipsUrls.list,
    getHref: (r) => `/relation-ships/${r.id}`,
    requestKey: `${data?.id}-relation-ships`,
    validate: Yup.mixed().required(),
    initialValue: data?.relation
      ? {
        id: data?.relation,
        label: data?.details?.relation?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'child_client',
    headerName: 'Child Client',
    width: 200,
    editable: false,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: clientsUrls.list,
    getHref: (r) => `/admin-clients/${r.id}`,
    requestKey: `${data?.id}-clients`,
    validate: Yup.mixed().required(),
    initialValue: data?.child_client
      ? {
        id: data?.child_client,
        label: data?.details?.child_client?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];