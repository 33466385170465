import React from 'react';
import {
  Box,
  // Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Stack,
  Alert,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { TextField, PhoneField } from '@components/Inputs';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { accountUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { getUserStorageKey } from '@config/constants';
import PageWrapper from '@ui/Auth/PageWrapper';
import { matchIsValidTel } from 'mui-tel-input';
import { appHost } from '@config/msHosts';

const { registerUrls } = accountUrls;

export default function Register() {
  const [showPWD, setShowPWD] = React.useState(false);
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  React.useEffect(() => {
    if (localStorage.getItem(getUserStorageKey())) {
      window.location.href = '/';
    }
  }, []);

  return (
    <PageWrapper>
      <Stack spacing={3} sx={{ width: '400px', mx: 'auto' }}>
        <Box>
          <Typography
            variant="h2"
            sx={{ fontSize: '28px', fontWeight: '500', mb: 0.5 }}
          >
            Create an account
          </Typography>
        </Box>

        <Box>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '+44',
              password: '',
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required(),
              lastName: Yup.string().required(),
              email: Yup.string().email().required(),
              phone: Yup.string()
                .required()
                .test('phone-validation', 'Invalid Phone Number', (value) =>
                  matchIsValidTel(value)
                ),
              password: Yup.string().min(8).required(),
            })}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setFieldError }
            ) => {
              try {
                setError('');
                const host = window.location.host;

                await createRecord({
                  values: {
                    ...values,
                    first_name: values.firstName,
                    last_name: values.lastName,
                    domain: host.includes('localhost') ? appHost : host,
                  },
                  url: registerUrls.list(),
                });

                setSuccess(
                  'Your account has been created. Please verify your account by click on the link that was sent to your email. Thank you for signing up!'
                );
                resetForm();
              } catch (error) {
                const errorResponse = error.response.data;
                const fieldErrors = {
                  firstName: errorResponse.firstName,
                  lastName: errorResponse.lastName,
                  email: errorResponse.email,
                  phone: errorResponse.phone,
                  password: errorResponse.password,
                };

                for (const field in fieldErrors) {
                  if (fieldErrors[field]) {
                    setFieldError(field, fieldErrors[field]);
                    break;
                  }
                }

                if (!Object.values(fieldErrors).some(Boolean)) {
                  setError('Something went wrong, try reloading');
                }
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form noValidate autoComplete="off">
                {error ? (
                  <Alert sx={{ mb: 4 }} icon={false} severity="error">
                    {error}
                  </Alert>
                ) : null}

                {success ? (
                  <Alert sx={{ mb: 4 }} icon={false} severity="success">
                    {success}
                  </Alert>
                ) : null}

                <Stack spacing={3}>
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Box sx={{ mr: '5px' }}>
                      <TextField name="firstName" label="First name" />
                    </Box>

                    <Box sx={{ ml: '5px' }}>
                      <TextField name="lastName" label="Last name" />
                    </Box>
                  </Box>

                  <Box>
                    <TextField name="email" label="Email" required />
                  </Box>

                  <Box>
                    <PhoneField
                      label="Phone"
                      name="phone"
                      defaultCountry="gb"
                    />
                    {/* <TextField name="phone" label="Phone" /> */}
                  </Box>

                  <Box>
                    <TextField
                      type={showPWD ? 'text' : 'password'}
                      name="password"
                      label="Password"
                      required
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={(e) => setShowPWD(e.target.checked)}
                      sx={{ userSelect: 'none' }}
                      label="Show password"
                    />
                  </Box>

                  <Box>
                    <Button
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                    >
                      Create account
                    </Button>
                  </Box>

                  <Box sx={{ textAlign: 'center' }}>
                    <Link
                      to="/auth/login"
                      style={{ textDecoration: 'none', color: '#444' }}
                    >
                      Already have an account?
                    </Link>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </PageWrapper>
  );
}
