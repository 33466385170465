import * as React from 'react';
import { Menu, IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ITEM_HEIGHT = 48;

export default function BugsKanbanCardActions({
  card,
  setShowAddBugToSprintPopup,
  setShowSidePeek,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        sx={{
          position: 'absolute',
          right: '4px',
          top: '6px',
          opacity: '0.8',
          '&:hover': {
            opacity: '1',
            color: '#fff',
          },
        }}
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        size='small'
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon
          fontSize='default'
          sx={{
            color: card.rotting ? '#fff' : '#000',
            '&:hover': {
              color: '#fff',
            },
          }}
        />
      </IconButton>

      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem
          size='small'
          onClick={() => {
            setShowAddBugToSprintPopup(true);
          }}
        >
          Add To Sprint
        </MenuItem>
        <MenuItem
          size='small'
          onClick={() => {
            setShowSidePeek(true);
            setAnchorEl(null);
          }}
        >
          Data
        </MenuItem>
      </Menu>
    </>
  );
}
