import * as React from 'react';
import UIAutocompleteField from '@ui/Inputs/AutocompleteboxField';
import { useField, useFormikContext } from 'formik';

export default function AutocompleteField({
  label,
  options,
  isLoading,
  value,
  setValue = (v) => v,
  setSearchTerm = (v) => v,
  customSetValue = (v) => v,
  customOnValueChange = null,
  multiple = false,
  ...rest
}) {
  const [inputValue, setInputValue] = React.useState('');
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(rest);
  const [loading, setLoading] = React.useState(false);
  const isError = Boolean(meta.error);

  const onValueChange = (e, newValue) => {
    setValue(newValue);

    if (multiple && newValue.length) {
      customSetValue(newValue);
      setFieldValue(field.name, newValue);
    } else {
      customSetValue(newValue ? newValue : {});
      setFieldValue(field.name, newValue ? newValue.id : '');
    }
  };
  const onInputChange = (e, newInputValue) => setInputValue(newInputValue);

  React.useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    const timeoutId = setTimeout(() => {
      setSearchTerm(inputValue);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  React.useEffect(() => {
    if (loading) setLoading(false);
  }, [loading, options]);

  return (
    <UIAutocompleteField
      {...rest}
      multiple={multiple}
      name={field.name}
      label={label}
      value={value}
      setValue={setValue}
      onChange={customOnValueChange || onValueChange}
      onInputChange={onInputChange}
      inputValue={inputValue}
      options={options || []}
      isLoading={loading || isLoading}
      error={isError}
      helperText={meta.error}
      setInputValue={(term) =>
        term !== 'undefined' ? setInputValue(term) : setInputValue('')
      }
    />
  );
}
