import React from 'react';
import { Box, Typography } from '@mui/material';

export default function PublicUserErrorScreen() {
  return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          sx={{ my: '18px', fontWeight: '500', fontSize: '28px' }}
          variant='h4'
        >
          Unable to open the page for you.
        </Typography>
        <Typography sx={{ fontSize: '18px', color: 'rgb(19, 23, 34)' }}>
          Either you do not have permissions or the page doesn’t exist.
        </Typography>
      </Box>
  );
}
