import React from 'react';
import { Box, Typography } from '@mui/material';
import Switch from '@ui/Inputs/SwitchField';

export default function Header({
  selectedCal = {},
  selectedCalendarAccount = {},
  disableDefaultButton,
  setDisableDefaultButton,
  setCalendarAsDefault,
  handleSync,
}) {

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
      <Typography sx={{ fontSize: 24, fontWeight: 500 }} component='h3'>
        {!Object.keys(selectedCalendarAccount).length &&
          !Object.keys(selectedCal).length
          ? 'All Calendars'
          : Object.keys(selectedCalendarAccount).length
            ? `All Calendars of ${selectedCalendarAccount.email}`
            : `Calendar (${selectedCal.name})`}
      </Typography>
    {/* 
      <Box sx={{ ml: 2, mr: 2 }}>
        <Button
          variant='contained'
          endIcon={<SyncIcon />}
          disabled={syncButtonDisabled}
          onClick={async () => await newHandleSync()}
        >
          Sync
        </Button>

      </Box> */}
      {selectedCal && Object.keys(selectedCal).length ? (
        <Switch
          checked={selectedCal.default}
          disabled={disableDefaultButton}
          onChange={async (e) => {
            setDisableDefaultButton(true);
            await setCalendarAsDefault(selectedCal.id, e.target.checked);
            setDisableDefaultButton(false);
          }}
          name={"default"}
          label={"Default Calendar"}
          color="primary"
          labelColor="dark"
        />
      ) : null}

    </Box>
  );
}
