import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { crmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { pipelineMeta } from '@config/meta/crm';
import { pipelineStagesRowsMap } from '@config/handleRows/crm';
import { PipelineStageForm } from '@config/forms/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { pipelinesUrls, pipelineStagesUrls } = crmUrls;

export default function PipelineDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const [pipelineStagesListData, setPipelineStagesListData] = React.useState(
    {}
  );
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => pipelineMeta({ data }), []);

  const stagesColOptions = React.useMemo(() => {
    return {
      nextOrder: pipelineStagesListData?.totalCount ?? 1,
    };
  }, [pipelineStagesListData?.totalCount]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/pipelines`, text: 'Pipelines' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={pipelinesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.pipelineStage}
          columnKey="pipelineStageCRM"
          columnOptions={stagesColOptions}
          label="Stages"
          editMode={editMode}
          labels={labels}
          prefix="0kf"
          urls={pipelineStagesUrls}
          addNewForm={{
            getFields: () => PipelineStageForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, pipeline: recordId },
                  url: pipelineStagesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={pipelineStagesRowsMap}
          urlParams={`&pipeline=${recordId}`}
          customSortColumnName="order"
          sortColumnBy="asc"
          getListData={(data) => setPipelineStagesListData(data)}
        />
      </Box>
    </Box>
  );
}
