import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEOHeader({ title }) {
  const getFavicon = () => {
    if (typeof window === 'undefined') return '';

    if (window.location.href.includes('mazards')) {
      return 'https://drive.pullstream.com/file/634e8c8f-810a-4149-b3af-ba750abca696.ico?one=true';
    }

    if (window.location.href.includes('evcharging')) {
      return 'https://drive.pullstream.com/file/e241d62a-ffe6-47fb-a91b-e59798743e73.ico?one=true';
    }

    if (window.location.href.includes('dastrum')) {
      return 'https://drive.pullstream.com/file/2cab0cb8-a12e-4e95-bab3-df166be3d969.png?f=1';
    }

    return '/img/ps-favicon.png';
  };

  return (
    <Helmet>
      <title>{title}</title>
      <link rel='icon' href={getFavicon()} />
    </Helmet>
  );
}
