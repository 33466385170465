import * as React from 'react';
import { Menu, IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { updateRecord } from '@config/functions/requests';
import ReminderPopup from '@components/ReminderPopup';
import ManualBPADrawer from '@components/ManualBPADrawer';
import { bpaUrls } from '@config/routes';
import { formatToUTCDateTime } from '@config/functions/helperFunctions';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import RottingPopup from './RottingPopup';
import useToast from '@hooks/useToast';
import { useQueryClient } from 'react-query';

const ITEM_HEIGHT = 48;

const { manualWorkflowInstancesUrls } = bpaUrls;

const getINAMicroservice = (detailLink) => {
  if (detailLink === 'manual-bpa-board') {
    return 'BPA';
  }
  return 'BPA';
};

const getINAModelName = (detailLink) => {
  if (detailLink === 'manual-bpa-board') {
    return 'ManualWorkflowInstance';
  }
  return 'ManualWorkflowInstance';
};

const getINALink = (detailLink) => {
  if (detailLink === 'manual-bpa-board') {
    return `${window.location.host}/manual-workflows/`;
  }
};

export default function ManualKanbanCardItem({ detailLink, card, user }) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showAddReminder, setShowAddReminder] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [showRotting, setShowRotting] = React.useState(false);

  const onRotUpdate = async (id, status) => {
    if (status) {
      try {
        await updateRecord({
          url: manualWorkflowInstancesUrls.detail(id),
          values: { stage_assigned_date: null },
          token: user.token,
          actAs: user?.actAs,
        });
        setAnchorEl(null);
        queryClient.invalidateQueries(['manual-bpa-board']);
        notify('Operating completed', {
          type: 'SUCCESS',
        });
      } catch (error) {
        console.log(error?.response?.data ?? error?.message);
        notify('Something went wrong, try refreshing', {
          type: 'ERROR',
        });
      }
    } else {
      try {
        await updateRecord({
          url: manualWorkflowInstancesUrls.detail(id),
          values: { stage_assigned_date: formatToUTCDateTime(new Date()) },
          token: user.token,
          actAs: user?.actAs,
        });
        setAnchorEl(null);
        queryClient.invalidateQueries(['manual-bpa-board']);
        notify('Operating completed', {
          type: 'SUCCESS',
        });
      } catch (error) {
        console.log(error?.response?.data ?? error?.message);
        notify('Something went wrong, try refreshing', {
          type: 'ERROR',
        });
      }
    }
  };

  return (
    <>
      <IconButton
        sx={{
          position: 'absolute',
          right: '4px',
          top: '6px',
          opacity: '0.8',
          '&:hover': {
            opacity: '1',
          },
        }}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        size="small"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon
          fontSize="default"
          sx={{ color: card.rotting ? '#fff' : '#000' }}
        />
      </IconButton>
      {detailLink === 'manual-bpa-board' && card.ina_count ? (
        <IconButton
          sx={{
            position: 'absolute',
            right: '22px',
            top: '6px',
            opacity: '0.8',
            '&:hover': {
              opacity: '1',
            },
          }}
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          size="small"
          onClick={() => {
            setDrawerOpen(true);
            setAnchorEl(null);
          }}
        >
          <LayersOutlinedIcon
            fontSize="inherit"
            sx={{ color: card.rotting ? '#fff' : '#000' }}
          />
        </IconButton>
      ) : null}
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem size="small" onClick={() => setShowAddReminder(true)}>
          Add INA
        </MenuItem>
        {detailLink === 'manual-bpa-board' ? (
          <>
            <MenuItem
              size="small"
              onClick={() => {
                setDrawerOpen(true);
                setAnchorEl(null);
              }}
            >
              Show INAs
            </MenuItem>
            <MenuItem
              size="small"
              onClick={() =>
                onRotUpdate(card.id, card.stage_assigned_date, setAnchorEl)
              }
            >
              {card.stage_assigned_date ? 'Do Not Rot' : 'Enable Rotting'}
            </MenuItem>
            <MenuItem
              size="small"
              onClick={() => onRotUpdate(card.id, null, setAnchorEl)}
            >
              Reset Rotting Days
            </MenuItem>
            <MenuItem size="small" onClick={() => setShowRotting(true)}>
              Start Rotting From
            </MenuItem>
          </>
        ) : null}
      </Menu>

      <RottingPopup
        open={showRotting}
        setOpen={setShowRotting}
        id={card.id}
        initialValue={card.status_assigned_date}
        url={manualWorkflowInstancesUrls}
        detailLink={detailLink}
      />

      <ReminderPopup
        type="INA"
        microservice={getINAMicroservice(detailLink)}
        model={getINAModelName(detailLink)}
        open={showAddReminder}
        setOpen={setShowAddReminder}
        selectedRows={[card.id]}
        setSelectedRows={() => null}
        link={`${getINALink(detailLink)}${card.id}`}
        company={card?.details?.entity_id?.name}
      />

      <ManualBPADrawer
        card={card.id}
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
        anchor="right"
      />
    </>
  );
}
