import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Labels from '@pages/Communication/Labels';
import LabelDetail from '@pages/Communication/Labels/detail';
import SharedLabels from '@pages/Communication/SharedLabels';
import ImapAccounts from '@pages/Communication/ImapAccounts';
import ImapAccountDetail from '@pages/Communication/ImapAccounts/detail';
import ImapProviders from '@pages/Communication/ImapProviders';
import EmailTemplates from '@pages/Communication/EmailTemplates';
import EmailTemplateDetail from '@pages/Communication/EmailTemplates/detail';
import HtmlEditorDetail from '@pages/Communication/EmailTemplates/HtmlEditor/detail';
import AllEmails from '@pages/Communication/AllEmails';
import AllEmailDetail from '@pages/Communication/AllEmails/detail';
import SentEmailDetail from '@pages/Communication/SentEmails/detail';
import SignatureDetail from '@pages/Communication/Signature/detail';
import InboxEmailDetail from '@pages/Communication/InboxEmails/detail';
import InboxEmailDetailDetail from '@pages/Communication/InboxEmails/Detail/detail';
import SentEmailDetailDetail from '@pages/Communication/SentEmails/Detail/detail';


export default function () {
  return (
    <>
      <Route path='/labels' element={<WithLayout comp={Labels} />} />
      <Route path='/labels/:id' element={<WithLayout comp={LabelDetail} />} />
      <Route
        path='/shared-labels'
        element={<WithLayout comp={SharedLabels} />}
      />
      <Route
        path='/imap-accounts'
        element={<WithLayout comp={ImapAccounts} />}
      />
      <Route
        path='/imap-accounts/:id'
        element={<WithLayout comp={ImapAccountDetail} />}
      />
      <Route
        path='/imap-providers'
        element={<WithLayout comp={ImapProviders} />}
      />
      <Route
        path='/email-templates'
        element={<WithLayout comp={EmailTemplates} />}
      />
      <Route
        path='/email-templates/:id'
        element={<WithLayout comp={EmailTemplateDetail} />}
      />
      <Route
        path='/inbox-emails/:id'
        element={<WithLayout comp={InboxEmailDetail} />}
      />
      <Route
        path='/email-templates/html-editor/:id'
        element={<WithLayout comp={HtmlEditorDetail} />}
      />
      <Route path='/all-emails' element={<WithLayout comp={AllEmails} />} />
      <Route
        path='/all-emails/:id'
        element={<WithLayout comp={AllEmailDetail} />}
      />
      <Route
        path='/sent-emails/:id'
        element={<WithLayout comp={SentEmailDetail} />}
      />
      <Route
        path='/signature/:id'
        element={<WithLayout comp={SignatureDetail} />}
      />
      <Route
        path='/inbox-emails/:id/detail/:id'
        element={<WithLayout comp={InboxEmailDetailDetail} />}
      />
      <Route
        path='/sent-emails/:id/detail/:id'
        element={<WithLayout comp={SentEmailDetailDetail} />}
      />
    </>
  );
}
