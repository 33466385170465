import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls, nodeDriveUrls } from '@config/routes';
import { clientsMeta } from '@config/meta/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { opportunityHistoryRowsMap } from '@config/handleRows/crm';
import { ClientHistoryForm } from '@config/forms/crm';
import { useQueryClient } from 'react-query';

const { clientsUrls, clientHistoryUrls } = crmUrls;
const { filesUrls } = nodeDriveUrls;

export default function ClientDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();

  const getSchema = React.useCallback(
    (data) => clientsMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/clients', text: 'Clients' },
              {
                text:
                  recordData &&
                  recordData.person_detail &&
                  recordData.person_detail.person
                    ? recordData.person_detail.person.email
                    : '',
              },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={clientsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.clientHistory}
          columnKey="clientHistoryCRM"
          label="History"
          editMode={editMode}
          labels={labels}
          prefix="037"
          urls={clientHistoryUrls}
          addNewForm={{
            getFields: ({ values, errors }) =>
              ClientHistoryForm({ values, errors }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                if (!values?.url?.name) {
                  setFieldError('url', 'Please select a history url');
                  return;
                }

                const { url, ...rest } = values;
                let file = url;

                const formData = new FormData();
                formData.append('anonymous_can_see_it', true);
                formData.append('file', file);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                file = fileData.fileUrl;

                const response = await createRecord({
                  values: { url: file, _client: recordId, ...rest },
                  url: clientHistoryUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err.response || err.message);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={opportunityHistoryRowsMap}
          urlParams={`&_client=${recordId}`}
        />
      </Box>
    </Box>
  );
}
