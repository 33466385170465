import React from 'react';
import MUILink from '@mui/material/Link';
import { voipUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { v4 as uuid } from 'uuid';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import IconButton from '@mui/material/IconButton';

const formatPhone = (phone = '') =>
  phone
    .replace(/\+/g, '')
    .replace(/[{()}]/g, '')
    .replace(/ /g, '');

const { createCallLogsUrls } = voipUrls;

export default function ClickToDial({
  callListKanban,
  phone,
  microservice,
  model,
  recordId,
}) {
  const formattedPhone = formatPhone(phone || '');
  const user = useSelector(selectProfile);

  const handleClick = async (e) => {
    e.preventDefault();

    if (!phone) {
      alert('No phone found');
      return;
    }

    const clickId = uuid();

    try {
      await createRecord({
        values: {
          id: clickId,
          microservice,
          model,
          record_id: recordId,
          call_to: phone,
        },
        url: createCallLogsUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });
    } catch {}

    window.location.href = `zoiper:-${formattedPhone} uuid:${clickId}`;
  };

  return (
    <>
      {callListKanban ? (
        <IconButton
          color="primary"
          size="small"
          onClick={handleClick}
          sx={{
            position: 'absolute',
            right: '4px',
            top: '6px',
            opacity: '0.5',
            '&:hover': {
              opacity: '1',
            },
          }}
        >
          <LocalPhoneRoundedIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <MUILink
          onClick={handleClick}
          sx={{ cursor: 'pointer' }}
          underline="hover"
        >
          {phone}
        </MUILink>
      )}
    </>
  );
}
