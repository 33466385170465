import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { CheckBoxField } from '@components/Inputs';
import { bpaUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { useQueryClient } from 'react-query';

const { bulkTriggerUrls } = bpaUrls;

export default function DiamondPopup({
  open,
  setOpen,
  payloadSection,
  workflowId,
  user,
  workflowInstances = [],
  manualTaskInstructions,
}) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const questions = [...(payloadSection?.payload_section_keys ?? [])];

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      onKeyDown={(e) => e.stopPropagation()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent maxWidth="sm" fullWidth>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Perform Manual Task
        </Typography>

        <Typography
          variant="p"
          dangerouslySetInnerHTML={{
            __html: manualTaskInstructions,
          }}
        ></Typography>

        <Formik
          initialValues={{}}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              await createRecord({
                values: {
                  workflow: workflowId,
                  manual: true,
                  payload: values,
                  workflow_instances: [...workflowInstances],
                },
                url: bulkTriggerUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              notify('Operation completed', {
                type: 'SUCCESS',
              });
              queryClient.invalidateQueries(['bpa-board']);
              resetForm();
              setOpen(false);
            } catch (error) {
              console.log(error?.response?.data ?? error?.message);
              notify('There was an error, please refresh the page', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Grid container spacing={2}>
                {questions
                  .sort((a, b) => a?.order - b?.order)
                  .map(({ key, label }) => (
                    <Grid item xs={12}>
                      <CheckBoxField name={key} label={label} />
                    </Grid>
                  ))}
              </Grid>

              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
