import { bpaHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  workflows: 'workflows',
  shapes: 'shapes',
  stages: 'stages',
  shapeTransitions: 'shape-transitions',
  actions: 'actions',
  actionParams: 'action-parameters',
  shapeActions: 'shape-actions',
  manualChatgptTextAction: 'manual-actions/chatgpt-text',
  chatgptTextAction: 'actions/chatgpt-text',
  createShortLinksAction: 'actions/create-short-links',
  sendEmailV2Action: 'actions/send-email-v2',
  shapesPaginated: 'shapes-paginated',
  modelAssetsFromStage: 'model-assets-from-stage',
  shapeActionsPaginated: 'shape-actions-paginated',
  shapeActionParamValues: 'shape-action-parameters-values',
  shapeActionParamValuesBulk: 'bulk-create-or-update-shape-action-parameters',
  workflowAccessBulk: 'bulk-create-or-overwrite-workflow-accesses',
  getCWFShapes: 'get-cwf-shapes-and-shapes-transitions',
  payloadSections: 'payload-sections',
  payloadSectionKeys: 'payload-section-keys',
  bulkPayloadSectionKeys: 'bulk-payload-section-keys',
  getPayloadActions: 'get-payload-sections',
  bulkMoveToLastManualStage: 'bulk-move-to-last-manual-stage',
  bulkDeleteWorkflowInstances: 'bulk-delete-workflow-instances',
  bulkCreateShapeActionParams:
    'bulk-create-or-update-shape-action-parameters-values',
  bulkGetShapeActionParamValues: 'bulk-get-shape-action-parameters-values',
  bulkCreatePayloadSectionKeys: 'bulk-create-or-update-payload-section-keys',
  workflowStages: 'workflow-stages',
  trigger: 'trigger',
  bulkTrigger: 'bulk-trigger',
  getShapeWithShapeActions: 'get-shape-with-shape-actions',
  manualSendEmail: 'manual-actions/send-email',
  createUpdateDiamondActions: 'create-update-diamond-actions',
  moveToNextStage: 'manual-actions/move-forward-from-delay',
  metaModels: 'meta-models',
  modelAssets: 'model-assets',
  modelAssetStages: 'model-asset-stages',
  assetModels: 'asset-models',
  bulkCreateStageShapes: 'bulk-create-stage-shapes',
  manualWorkflowInstances: 'manual-workflow-instances',
  manualWorkflowStages: 'manual-workflow-stages',
  startAutomation: 'start-automation',
  resetRottingDaysManualWorkflow:
    'reset-rotting-days-manual-workflow-instances',
  cloneSystemTemplates: 'clone-system-templates',
  getOrderedStages: 'get-ordered-stages',
  bulkCreateOrUpdateShapes: 'bulk-create-or-update-shapes',
  dataPopup: 'data-popup',
  manualSendEmailV2: 'manual-actions/send-email-v2',
};

const manualSendEmailV2Urls = getModelUrls(bpaHost, models.manualSendEmailV2);
const createShortLinksActionUrls = getModelUrls(bpaHost, models.createShortLinksAction);
const manualChatgptTextActionUrls = getModelUrls(bpaHost, models.manualChatgptTextAction);
const chatgptTextActionUrls = getModelUrls(bpaHost, models.chatgptTextAction);
const sendEmailV2ActionUrls = getModelUrls(bpaHost, models.sendEmailV2Action);


const dataPopupUrls = getModelUrls(bpaHost, models.dataPopup);

const bulkCreateOrUpdateShapesUrls = getModelUrls(
  bpaHost,
  models.bulkCreateOrUpdateShapes
);

const cloneSystemTemplatesUrls = getModelUrls(
  bpaHost,
  models.cloneSystemTemplates
);

const resetRottingDaysManualWorkflowUrls = getModelUrls(
  bpaHost,
  models.resetRottingDaysManualWorkflow
);
const bulkCreateStageShapesUrls = getModelUrls(
  bpaHost,
  models.bulkCreateStageShapes
);

const modelAssetsUrls = getModelUrls(bpaHost, models.modelAssets);
const metaModelsUrls = getModelUrls(bpaHost, models.metaModels);
const modelAssetStagesUrls = getModelUrls(bpaHost, models.modelAssetStages);
const manualWorkflowInstancesUrls = getModelUrls(
  bpaHost,
  models.manualWorkflowInstances
);

const assetModelsUrls = getModelUrls(bpaHost, models.assetModels);

const moveToNextStageUrls = getModelUrls(bpaHost, models.moveToNextStage);

const bulkTriggerUrls = getModelUrls(bpaHost, models.bulkTrigger);

const createUpdateDiamondActionsUrls = getModelUrls(
  bpaHost,
  models.createUpdateDiamondActions
);

const manualSendEmailUrls = getModelUrls(bpaHost, models.manualSendEmail);

const getShapeWithShapeActionsUrls = getModelUrls(
  bpaHost,
  models.getShapeWithShapeActions
);

const bulkCreatePayloadSectionKeysUrls = getModelUrls(
  bpaHost,
  models.bulkCreatePayloadSectionKeys
);

const bulkGetShapeActionParamValuesUrls = getModelUrls(
  bpaHost,
  models.bulkGetShapeActionParamValues
);

const bulkCreateShapeActionParamsUrls = getModelUrls(
  bpaHost,
  models.bulkCreateShapeActionParams
);

const bulkDeleteWorkflowInstancesUrls = getModelUrls(
  bpaHost,
  models.bulkDeleteWorkflowInstances
);

const bulkMoveToLastManualStageUrls = getModelUrls(
  bpaHost,
  models.bulkMoveToLastManualStage
);

const getPayloadActionsUrls = getModelUrls(bpaHost, models.getPayloadActions);

const bulkPayloadSectionKeysUrls = getModelUrls(
  bpaHost,
  models.bulkPayloadSectionKeys
);

const payloadSectionKeysUrls = getModelUrls(bpaHost, models.payloadSectionKeys);

const payloadSectionsUrls = getModelUrls(bpaHost, models.payloadSections);
const getCWFShapesUrls = getModelUrls(bpaHost, models.getCWFShapes);
const shapesPaginatedUrls = getModelUrls(bpaHost, models.shapesPaginated);
const shapeActionsPaginatedUrls = getModelUrls(
  bpaHost,
  models.shapeActionsPaginated
);
const modelAssetsFromStageUrls = getModelUrls(
  bpaHost,
  models.modelAssetsFromStage
);
const workflowAccessBulkUrls = getModelUrls(bpaHost, models.workflowAccessBulk);
const workflowsUrls = getModelUrls(bpaHost, models.workflows);
const shapesUrls = getModelUrls(bpaHost, models.shapes);
const stagesUrls = getModelUrls(bpaHost, models.stages);
const shapeTransitionsUrls = getModelUrls(bpaHost, models.shapeTransitions);
const actionsUrls = getModelUrls(bpaHost, models.actions);
const actionParamsUrls = getModelUrls(bpaHost, models.actionParams);
const shapeActionsUrls = getModelUrls(bpaHost, models.shapeActions);
const shapeActionParamValuesUrls = getModelUrls(
  bpaHost,
  models.shapeActionParamValues
);
const shapeActionParamValuesBulkUrls = getModelUrls(
  bpaHost,
  models.shapeActionParamValuesBulk
);

const workflowStagesUrls = getModelUrls(bpaHost, models.workflowStages);
const manualWorkflowStagesUrls = getModelUrls(
  bpaHost,
  models.manualWorkflowStages
);
const startAutomationUrls = getModelUrls(bpaHost, models.startAutomation);

const triggerUrls = getModelUrls(bpaHost, models.trigger);
const getOrderedStageUrls = getModelUrls(bpaHost, models.getOrderedStages);

const urls = {
  manualChatgptTextActionUrls,
  chatgptTextActionUrls,
  createShortLinksActionUrls,
  sendEmailV2ActionUrls,
  manualSendEmailV2Urls,
  bulkMoveToLastManualStageUrls,
  bulkDeleteWorkflowInstancesUrls,
  cloneSystemTemplatesUrls,
  metaModelsUrls,
  startAutomationUrls,
  bulkCreatePayloadSectionKeysUrls,
  shapesPaginatedUrls,
  modelAssetsFromStageUrls,
  shapeActionsPaginatedUrls,
  workflowsUrls,
  shapesUrls,
  shapeTransitionsUrls,
  actionsUrls,
  actionParamsUrls,
  shapeActionsUrls,
  shapeActionParamValuesUrls,
  shapeActionParamValuesBulkUrls,
  workflowAccessBulkUrls,
  stagesUrls,
  getCWFShapesUrls,
  payloadSectionsUrls,
  payloadSectionKeysUrls,
  bulkPayloadSectionKeysUrls,
  getPayloadActionsUrls,
  bulkCreateShapeActionParamsUrls,
  bulkGetShapeActionParamValuesUrls,
  workflowStagesUrls,
  manualWorkflowStagesUrls,
  triggerUrls,
  getShapeWithShapeActionsUrls,
  manualSendEmailUrls,
  createUpdateDiamondActionsUrls,
  bulkTriggerUrls,
  moveToNextStageUrls,
  modelAssetsUrls,
  modelAssetStagesUrls,
  assetModelsUrls,
  bulkCreateStageShapesUrls,
  manualWorkflowInstancesUrls,
  resetRottingDaysManualWorkflowUrls,
  getOrderedStageUrls,
  bulkCreateOrUpdateShapesUrls,
  dataPopupUrls,
};

export default urls;
