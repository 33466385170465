import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { webChatUrls } from '@config/routes';
import { ticketsMeta } from '@config/meta/webChat';

const { ticketsUrls } = webChatUrls;

export default function TicketDetail() {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => ticketsMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/tickets', text: 'Tickets' },
              { text: recordData && recordData.headline },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={ticketsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
    </Box>
  );
}
