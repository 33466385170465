import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { qrCodeUrls, nodeDriveUrls } from '@config/routes';
import { createRecord, updateRecord } from '@config/functions/requests';
import { QrForm } from '@config/forms/qrCode';
import { qrRowsMap } from '@config/handleRows/qrCode';
import { microservices } from '@config/constants';
import { QRCode } from 'react-qrcode-logo';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  vcardUrls,
  emailUrls,
  smsUrls,
  wifiUrls,
  twitterUrls,
  facebookUrls,
  appStoresUrls,
  imageUrls,
  mp3Urls,
  allDataUrls,
} = qrCodeUrls;
const { filesUrls } = nodeDriveUrls;

export default function QrCode({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const [value, setValue] = useState('vcard');
  const [mp3, setMp3] = useState('');
  const [image, setImage] = useState('');
  const [url, setUrl] = useState([]);
  const [options, setOptions] = useState();
  const [attachments, setAttachments] = useState([]);

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const generateQRCode = async (value, type) => {
    let logo = value.logo;
    const [screenshot] = attachments;

    if (screenshot) {
      const formData = new FormData();
      formData.append('anonymous_can_see_it', true);
      formData.append('file', screenshot);
      try {
        const response = await createRecord({
          values: formData,
          url: filesUrls.list(),
          token: user.token,
          actAs: user?.actAs,
        });
        logo = response?.data?.fileUrl;
      } catch (err) {
        // error
        console.log('err', err.response || err.message);
      }
    }
    if (value.name_of_qrcode) {
      setOptions({
        ecLevel: 'M',
        enableCORS: false,
        size: `${value.size ? value.size : '150'}`,
        quietZone: 5,
        bgColor: `${value.bgColor ? value.bgColor : '#FFFFFF'}`,
        fgColor: `${value.fgColor ? value.fgColor : '#000000'}`,
        logoImage: `${logo}`,
        logoWidth: `${value.logoWidth ? value.logoWidth : '100'}`,
        logoHeight: `${value.logoHeight ? value.logoHeight : '100'}`,
        logoOpacity: `${value.logoOpacity ? value.logoOpacity : '0.3'}`,
        removeQrCodeBehindLogo: false,
        qrStyle: `${value.shape ? value.shape : 'squares'}`,
      });
      if (type === 'vcard') {
        let vCardText = `BEGIN:VCARD\n`;
        vCardText += `N:${value?.first_name};${value?.last_name};\n`;
        vCardText += `FN:${value?.first_name} ${value?.last_name}\n`;
        vCardText += `TITLE:${value?.first_name}\n`;
        if (value?.mobile) {
          vCardText += `TEL;TYPE#WORK,VOICE:${value?.mobile}\n`;
        }
        if (value?.email) {
          vCardText += `EMAIL;TYPE#WORK:${value?.email}\n`;
        }
        if (value?.website) {
          vCardText += `URL:${value?.website}\n`;
        }
        // if (value.street && value.city && value.zip &&value.country ){
        //   vCardText += `ADR;TYPE#HOME:;;${value.street};${value.city};${value.state};${value.zip};${value.country}\n`
        // }
        vCardText += `VERSION:3.0\n`;
        vCardText += `END:VCARD\n`;
        setUrl(vCardText);
      }
      if (type === 'email') {
        setUrl(
          `MATMSG:TO:${value?.email};SUB:${value?.subject};BODY:${value?.message};;`
        );
      }
      if (type === 'sms') {
        if (value.message) {
          setUrl(`smsto:${value?.number}:${value?.message}`);
        } else {
          setUrl(`TEL:${value?.number}`);
        }
      }
      if (type === 'wifi') {
        const text = `WIFI:S:${value?.network_name};T:${value?.encryption};P:${value?.password};H:${value?.hidden};`;
        setUrl(text);
      }
      if (type === 'twitter') {
        if (value?.choose_an_option === 'Link To Your Profile') {
          setUrl(`https://twitter.com/${value?.your_username}`);
        } else {
          setUrl(
            `https://twitter.com/intent/tweet?text=${value?.your_username}`
          );
        }
      }
      if (type === 'facebook') {
        setUrl(`${value?.facebook_URL}`);
      }
      if (type === 'appstore') {
        if (value?.app_store) {
          setUrl(`${value?.app_store}`);
        }
        if (value?.google_play) {
          setUrl(`${value?.google_play}`);
        }
      }
      if (type === 'mp3') {
        let mp3Url = '';
        const formDataMp3 = new FormData();
        formDataMp3.append('file', value?.upload_mp3);
        formDataMp3.append('anonymous_can_see_it', true);
        try {
          const response = await createRecord({
            values: formDataMp3,
            url: filesUrls.list(),
            token: user.token,
            actAs: user?.actAs,
          });
          mp3Url = response?.data?.fileUrl;
          setMp3(mp3Url);
        } catch (err) {
          console.log('Update err', err.response || err.message);
        }
        setUrl(`${mp3Url}`);
      }
      if (type === 'image') {
        let imageUrl = '';
        const formDataMp3 = new FormData();
        formDataMp3.append('file', value?.upload_image);
        formDataMp3.append('anonymous_can_see_it', true);
        try {
          const response = await createRecord({
            values: formDataMp3,
            url: filesUrls.list(),
            token: user.token,
            actAs: user?.actAs,
          });
          imageUrl = response?.data?.fileUrl;
          setImage(imageUrl);
        } catch (err) {
          console.log('Update err', err.response || err.message);
        }
        setUrl(`${imageUrl}`);
      }

      //   document.getElementById('qrcode-container').style.display = 'block';
    }
  };
  console.log(allDataUrls.list());
  return (
    <Box>
      <ListView
        key='list-view'
        label='Qr Codes'
        prefix='04a'
        labels={labels}
        editMode={editMode}
        microservice={microservices.QrCode.name}
        model={microservices.QrCode.models.twitter}
        urls={allDataUrls}
        getCustomUrl={({ row }) =>
          `${allDataUrls.list()}${row.type}/${row.id}/`
        }
        columnKey='qrCode'
        addNewForm={{
          getFields: ({ setFieldValue, values }) =>
            QrForm(
              value,
              setValue,
              url,
              setAttachments,
              options,
              setFieldValue,
              values
            ),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              let listViewUrl;
              await generateQRCode(values, value);

              if (value === 'vcard') {
                listViewUrl = vcardUrls.list();
              }
              if (value === 'email') {
                listViewUrl = emailUrls.list();
              }
              if (value === 'sms') {
                listViewUrl = smsUrls.list();
              }
              if (value === 'wifi') {
                listViewUrl = wifiUrls.list();
              }
              if (value === 'twitter') {
                listViewUrl = twitterUrls.list();
              }
              if (value === 'facebook') {
                listViewUrl = facebookUrls.list();
              }
              if (value === 'appstore') {
                listViewUrl = appStoresUrls.list();
              }
              if (value === 'mp3') {
                listViewUrl = mp3Urls.list();
              }
              if (value === 'image') {
                listViewUrl = imageUrls.list();
              }
              console.log(values);
              try {
                const response = await createRecord({
                  values: { ...values, upload_mp3: mp3, upload_image: image },
                  url: listViewUrl,
                  token: user.token,
                  actAs: user?.actAs,
                });
                const vcardId = response?.data?.id;
                const canvas = document.getElementById('QR');
                const file = dataURLtoFile(
                  canvas
                    .toDataURL('image/png')
                    .replace('image/png', 'image/octet-stream'),
                  `${values.name_of_qrcode}.png`
                );

                if (file) {
                  const formData = new FormData();
                  formData.append('file', file);
                  formData.append('anonymous_can_see_it', true);
                  try {
                    const response = await createRecord({
                      values: formData,
                      url: filesUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                    });
                    const qr_code_url = response?.data?.fileUrl;
                    console.log(qr_code_url);
                    try {
                      await updateRecord({
                        values: {
                          qr_code_url: qr_code_url,
                        },
                        url: `${listViewUrl}${vcardId}/`,
                        token: user.token,
                        actAs: user?.actAs,
                      });
                    } catch (err) {
                      handleCreateFormErrors({ err, setError, notify, values });
                    }
                  } catch (err) {
                    handleCreateFormErrors({ err, setError, notify, values });
                  }
                }
                resetForm();
                setAddNewForm(false);
                // notify('QR Code Created', {
                //   type: 'SUCCESS',
                // });
                refetch();
                return response?.data?.id;
              } catch (err) {
                if (
                  err.response &&
                  err.response.status === 400 &&
                  err.response.data
                ) {
                  setError(err.response.data);
                } else {
                  notify(`There was an error, please try again.`, {
                    type: 'ERROR',
                  });
                }
              } finally {
                setSubmitting(false);
                setOptions();
                setUrl([]);
              }
              return null;
            } catch (err) {
              if (
                err.response &&
                err.response.status === 400 &&
                err.response.data
              ) {
                setError(err.response.data);
              } else {
                notify(`There was an error, please try again.`, {
                  type: 'ERROR',
                });
              }
            } finally {
              setSubmitting(false);
              setOptions();
              setUrl([]);
            }
          },
        }}
        handleRowsMap={qrRowsMap}
      />
      <Box style={{ display: 'none' }}>
        {url ? <QRCode value={url} {...options} id={'QR'} /> : null}
      </Box>
    </Box>
  );
}
