import React from 'react';
import { Box, Typography } from '@mui/material';

export default function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: '20%',
      }}
    >
      <Typography
        variant='h1'
        sx={{
          mb: 2,
        }}
      >
        404
      </Typography>
      <Typography variant='subtitle1'>
        Sorry, the page you were looking for was not found.
      </Typography>
    </Box>
  );
}
