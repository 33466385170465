import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from 'formik';
import useToast from '@hooks/useToast';
import * as Yup from 'yup';
import {
  TextField,
  DateTimePickerField,
  CheckBoxField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import { pmUrls, calendarUrls, hrUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { inasUrls } = pmUrls;
const { employeesUrls } = hrUrls;
const { calendarsListUrls } = calendarUrls;

export default function INADialog({
  open,
  setOpen,
  msName,
  modelName,
  link,
  recordId,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  const today = React.useMemo(() => {
    return new Date().setHours(8, 0, 0, 0);
  }, []);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='alert-dialog-title'>Add INA</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            next_action: '',
            datetime: today,
            responsible: null,
            notes: '',
            calendar: null,
            create_calendar_entry: false,
            invitee: [],
            reminder_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }}
          validationSchema={Yup.object({
            next_action: Yup.string().required(),
            reminder_name: Yup.string(),
            notes: Yup.string(),
            datetime: Yup.date()
              .default(() => today)
              .required(),
            invitee: Yup.array().of(
              Yup.string().email('Enter a valid email').required()
            ),
            responsible: Yup.mixed(),
            calendar: Yup.mixed(),
            create_calendar_entry: Yup.boolean().nullable(),
          })}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setFieldError }
          ) => {
            try {
              console.log('datetime', values);
              console.log('recordId', recordId);
              await createRecord({
                values: {
                  ...values,
                  calendar: values?.calendar?.id,
                  responsible: values?.responsible?.id,
                  record_id: recordId,
                  model_name: modelName,
                  microservice_name: msName,
                  link: link,
                  everyone_in_object_company_can_see_it: true,
                },
                url: inasUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setOpen(false);
              notify('INA Created', {
                type: 'SUCCESS',
              });
            } catch (err) {
              console.log(err.response || err.message);
              handleCreateFormErrors({
                err,
                notify,
                setError: (error) => {
                  Object.entries(error).map((err) =>
                    setFieldError(err[0], err[1])
                  );
                },
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form noValidate autoComplete='off' style={{ paddingTop: '8px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label='Next Action'
                    name='next_action'
                    minRows={2}
                    multiline
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField label='Notes' name='notes' minRows={2} multiline />
                </Grid>
                <Grid item xs={6}>
                  <DateTimePickerField
                    label='Date time'
                    name='datetime'
                    showTime
                    showTimezone
                    setTimezone={(v) => setFieldValue('reminder_timezone', v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AutocompleteFieldV2
                    name='responsible'
                    label='Responsible'
                    requestKey='ina-responsible'
                    fetchUrl={employeesUrls.list}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.work_email,
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckBoxField
                    label='Add Calendar Entry'
                    name='create_calendar_entry'
                  />
                </Grid>
                {values.create_calendar_entry ? (
                  <>
                    <Grid item xs={6}>
                      <AutocompleteFieldV2
                        name='calendar'
                        label='Calendar'
                        requestKey='ina-calendar'
                        fetchUrl={calendarsListUrls.list}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        label='Reminder name'
                        name='reminder_name'
                        multiline
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label='Reminder description'
                        name='reminder_description'
                        minRows={2}
                        multiline
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type='submit' disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
