import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { cmsV2Urls, nodeDriveUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { domainRowsMap } from '@config/handleRows/cms-v2';
import { DomainForm } from '@config/forms/cms-v2';
import { microservices } from '@config/constants';
// import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import PublicUserErrorScreen from '@components/CMS-V2/PublicUserErrorScreen';

const { domainsUrls } = cmsV2Urls;
const { filesUrls } = nodeDriveUrls;

export default function Domains({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  if (
    Array.isArray(user?.details?.roles_names) &&
    !(
      user?.details?.roles_names.includes('Superusers') ||
      user?.details?.roles_names.includes('Company Administrator') ||
      user?.details?.is_superuser ||
      user?.details?.roles_names.includes('Template Admins')
    )
  ) {
    return <PublicUserErrorScreen />;
  }

  return (
    <Box>
      <ListView
        nodeAPI
        label='Subdomains'
        prefix='0t6'
        labels={labels}
        editMode={editMode}
        microservice={microservices.CMS.name}
        model={microservices.CMS.models.domain}
        urls={domainsUrls}
        columnKey='domain'
        addNewForm={{
          getFields: () => DomainForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              let faviconUrl = '';

              const { favicon, ...rest } = values;

              if (favicon) {
                const formData = new FormData();
                formData.append('anonymous_can_see_it', true);
                formData.append('file', favicon);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                faviconUrl = fileData.fileUrl;
              }
              const response = await createRecord({
                values: {
                  ...rest,
                  favicon: faviconUrl,
                },
                url: domainsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err.response.data.message);
              if (err.response.data.statusCode === 401) {
                notify(`${err.response.data.message}`, { type: 'ERROR' });
              }
              notify(`Subdomains already exists.`, { type: 'ERROR' });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={domainRowsMap}
      />
    </Box>
  );
}
