import React from 'react';
import { microservices } from '@config/constants';
import { SalaryHistoryForm } from '@config/forms/hr';
import { createRecord } from '@config/functions/requests';
import { salaryHistoryRowsMap } from '@config/handleRows/hr';
import { hrUrls } from '@config/routes';
import DetailAccordionView from '../DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { salaryHistoryUrls } = hrUrls;

export default function SalaryHistoryAccordion({
  recordId,
  notify,
  user,
  prefix,
  labels,
  editMode,
}) {
  return (
    <DetailAccordionView
      microservice={microservices.HR.name}
      model={microservices.HR.models.salaryHistory}
      columnKey="salaryHistoryHR"
      label="Salary History"
      prefix={prefix}
      labels={labels}
      editMode={editMode}
      urls={salaryHistoryUrls}
      addNewForm={{
        getFields: () => SalaryHistoryForm(),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            const response = await createRecord({
              values: { ...values, employee: recordId },
              url: salaryHistoryUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Salary History Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
            return response?.data?.id;
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      }}
      handleRowMap={salaryHistoryRowsMap}
      urlParams={`&employee=${recordId}`}
    />
  );
}
