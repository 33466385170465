import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { getAuthHeader } from '@config/functions/helperFunctions';
import useToast from '@hooks/useToast';
import { createRecord } from '@config/functions/requests';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ImportExportBtn({ urls, refetch, model, formFields }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector(selectProfile);
  const [importing, setImporting] = React.useState(false);
  const [exporting, setExporting] = React.useState(false);
  const [notify] = useToast();
  const { import: importUrl, export: exportUrl } = urls;

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setImporting(false);
    setAnchorEl(null);
  };

  const notifyError = (msg) =>
    notify(msg, {
      type: 'ERROR',
    });

  const handleImport = async (e) => {
    try {
      console.log('call2 call2 call2');

      const [file] = e.target.files;
      console.log(importUrl, file);
      if (!importUrl || !file) {
        notifyError('Failed to import data');
        return;
      }
      setImporting(true);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('model', model);

      await createRecord({
        values: formData,
        url: importUrl,
        token: user.token,
        actAs: user?.actAs,
      });

      refetch();
    } catch (err) {
      console.log((err.response && err.response.data.error) || err.message);

      if (err.response && err.response.data.error) {
        notify(err.response.data.error, {
          type: 'ERROR',
        });
      } else {
        notify('There was an error, please try again', {
          type: 'ERROR',
        });
      }
    } finally {
      handleClose();
      setImporting(false);
    }
  };

  const handleExport = async () => {
    try {
      if (!exportUrl) {
        notifyError('Failed to export data');
        return;
      }
      setExporting(true);
      const { data } = await axios.get(`${exportUrl}${formFields || ''}`, {
        responseType: 'arraybuffer',
        ...getAuthHeader(user.token, user?.actAs),
      });
      const blob = new Blob([data], { type: 'application/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${new Date().getTime()}.csv`;
      link.click();
    } catch (err) {
      console.log(err.response || err.message);
      notifyError('Failed to export data');
    } finally {
      setExporting(false);
      handleClose();
    }
  };

  return (
    <div>
      <Button
        id='import-export-button'
        aria-controls={open ? 'import-export-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Import / Export
      </Button>

      <StyledMenu
        id='import-export-menu'
        MenuListProps={{
          'aria-labelledby': 'import-export-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disableRipple>
          <label
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input
              type='file'
              accept='text/csv'
              onChange={handleImport}
              style={{ display: 'none' }}
            />
            <UploadFileIcon />
            <p style={{ margin: 0 }}>
              {importing ? 'Importing...' : 'Import from CSV'}
            </p>
          </label>
        </MenuItem>
        <MenuItem onClick={handleExport} disableRipple>
          <SystemUpdateAltIcon />
          {exporting ? 'Exporting...' : 'Export as CSV'}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
