import React from 'react';
import useToast from '@hooks/useToast';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import DeletePromptModal from '@components/DeletePromptModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { bpaUrls } from '@config/routes';
import {
  createRecord,
  updateRecord,
  deleteRecord,
} from '@config/functions/requests';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import FieldMappingPopup from '@components/BPA-V2/Popups/FieldMappingPopup';
import SendEmailPopup from '@components/BPA-V2/ManualActions/SendEmailPopup';
import EmailTemplateConfigurationPopup from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateConfigurationPopup';
import CreateShortLinksPopup from '@components/BPA-V2/Popups/CreateShortLinksPopup/CreateShortLinksPopup';
import ChatGPTTextPopup from '@components/BPA-V2/Popups/ChatGPTTextPopup/ChatGPTTextPopup';
import FormFilledInPopup from '@components/BPA-V2/ManualActions/FormFilledInPopup';
import { useQueryClient } from 'react-query';
import { setEmailTemplatePopupConfig, selectBPaState } from '@redux/bpaSlice';
import { mxConstants } from 'mxgraph-js';

const { shapeActionsUrls, createUpdateDiamondActionsUrls } = bpaUrls;

export default function ShapeAction({
  shape,
  workflowId,
  actionsList,
  shapeAction,
  setShapeActions,
  workflowName,
  graph,
}) {
  const [notify] = useToast();
  const dispatch = useDispatch();
  const user = useSelector(selectProfile);
  const { emailTemplatePopupConfig, shapeCells } = useSelector(selectBPaState);
  const [shapeActionName, setShapeActionName] = React.useState(
    shapeAction?.name ?? ''
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [selectedActionId, setSelectedActionId] = React.useState(
    shapeAction?.action ?? ''
  );
  const [showFieldMapping, setShowFieldMapping] = React.useState({
    open: false,
    data: {},
  });
  const [showSendEmail, setShowSendEmail] = React.useState({
    open: false,
    data: {},
  });
  const [showFormFilledIn, setShowFormFilledIn] = React.useState({
    open: false,
    data: {},
  });
  const [showCreateShortLinks, setShowCreateShortLinks] = React.useState({
    open: false,
    data: {},
  });
  const [showChatGPTText, setShowChatGPTText] = React.useState({
    open: false,
    data: {},
  });
  const [errors, setErrors] = React.useState({});
  const queryClient = useQueryClient();

  async function onDeleteConfirmation() {
    try {
      setSubmitting(true);
      if (shapeAction?.created_at) {
        await deleteRecord({
          url: shapeActionsUrls.detail(shapeAction.id),
          token: user.token,
          actAs: user?.actAs,
        });
      }
    } catch (err) {
      console.log(
        'onDeleteConfirmation error:',
        err?.response?.data ?? err.message
      );
    } finally {
      setShapeActions((prevState) => {
        return prevState.filter((record) => record.id !== shapeAction.id);
      });
      setShowDeleteConfirmation(false);
      setSubmitting(false);
    }
  }

  function onSelectAction({
    shapeAction,
    selectedActionId,
    shapeActionName,
  } = {}) {
    setSelectedActionId(selectedActionId);
    setShapeActions((prevState) => {
      // First, find the index of the action we want to replace
      const shapeActionIndex = prevState.findIndex(
        (row) => row.id === shapeAction.id
      );

      if (shapeActionIndex === -1) {
        console.error('Shape action with provided id not found');
        return prevState; // return previous state if action was not found
      }

      // Prepare the new action
      const newShapeAction = {
        ...shapeAction,
        action: selectedActionId,
        name: shapeActionName,
      };

      // Create a new array and replace the existing action with the new one
      const newShapeActions = [...prevState];
      newShapeActions[shapeActionIndex] = newShapeAction;

      return newShapeActions;
    });
  }

  async function onSaveShapeAction() {
    const newShapeAction = {
      name: shapeActionName,
      action: selectedActionId,
      id: shapeAction?.id,
      when: shapeAction?.when,
      shape: shapeAction?.shape,
      created_at: shapeAction?.created_at,
    };

    if (!shapeActionName) {
      setErrors((prevState) => ({
        ...prevState,
        name: 'Please enter a name.',
      }));
      return;
    } else {
      delete errors.name;
    }

    if (!shapeAction?.created_at && !selectedActionId) {
      setErrors((prevState) => ({
        ...prevState,
        action: 'Please select an action.',
      }));
      return;
    } else {
      delete errors['action'];
    }

    setErrors({});

    const isProcess = shape.type === 'process';
    const isDiamond = shape.type === 'decision';
    const isManual = !!shape.manual_transition && (isProcess || isDiamond);

    try {
      setSubmitting(true);

      if (shape?.type === 'decision' && isManual) {
        await createShapeActionWithPayload({
          shape: shapeAction?.shape,
          action: selectedActionId,
          label: shapeActionName,
          key: shapeActionName
            .toLowerCase() // Convert string to lowercase
            .replace(/[^a-z0-9\s]/g, '') // Remove all special characters
            .replace(/\s+/g, '_'), // Replace spaces with underscores;,
        });
      } else {
        await saveShapeAction(newShapeAction);
      }

      if (graph && !isManual) {
        const cell = graph.getModel().getCell(shape.id) ?? shapeCells[shape.id];

        if (cell) {
          // Adds cells to the model in a single step
          graph.getModel().beginUpdate();
          graph.setCellStyles(mxConstants.STYLE_FILLCOLOR, '#FFF', [cell]);
          graph.getModel().endUpdate();
          graph.refresh();
        } else {
          console.log('cell not found', shape.id, cell);
        }
      } else {
        console.log('Graph or shape actions not found');
      }

      queryClient.invalidateQueries([
        `${shapeAction?.shape}-shape-and-actions`,
      ]);
      notify('Operation completed', { type: 'SUCCESS' });
    } catch (err) {
      console.log(err.response || err.message);
    } finally {
      updateShapeActions({ ...shapeAction, ...newShapeAction });
      setSubmitting(false);
    }
  }

  async function createShapeActionWithPayload(shapeAction) {
    return createRecord({
      url: createUpdateDiamondActionsUrls.list(),
      values: shapeAction,
      token: user.token,
      actAs: user?.actAs,
    });
  }

  async function saveShapeAction(shapeAction) {
    const saveMethod = shapeAction?.created_at ? updateRecord : createRecord;
    const url = shapeAction?.created_at
      ? shapeActionsUrls.detail(shapeAction.id)
      : shapeActionsUrls.list();

    return saveMethod({
      url,
      values: shapeAction,
      token: user.token,
      actAs: user?.actAs,
    });
  }

  function updateShapeActions(newShapeAction) {
    setShapeActions((prevState) => {
      const shapeActionIndex = prevState.findIndex(
        (row) => row.id === shapeAction.id
      );
      if (shapeActionIndex === -1) {
        console.error('Shape action with provided id not found');
        return prevState;
      }
      const newShapeActions = [...prevState];
      newShapeActions[shapeActionIndex] = newShapeAction;
      return newShapeActions;
    });
  }

  return (
    <Box
      sx={{
        my: 2,
        p: 1.5,
        border: '1px solid #cecece',
        borderRadius: '4px',
        background: '#efefff',
      }}
    >
      <Stack spacing={2.5}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 1.5,
          }}
        >
          <TextField
            label={shape?.type === 'decision' ? 'Question' : 'Name'}
            variant='outlined'
            size='small'
            multiline
            fullWidth
            value={shapeActionName}
            onChange={(e) => setShapeActionName(e.target.value)}
            autoComplete='off'
            error={'name' in errors}
            required
            helperText={'name' in errors ? errors['name'] : null}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <IconButton
              aria-label='delete'
              size='small'
              disabled={isSubmitting}
              onClick={() => setShowDeleteConfirmation(true)}
            >
              <DeleteIcon fontSize='inherit' />
            </IconButton>
          </Box>
        </Box>

        <Box>
          <FormControl
            size='small'
            error={'action' in errors}
            fullWidth
            required
          >
            <InputLabel>Action</InputLabel>
            <Select
              label='Action'
              value={selectedActionId}
              disabled={shapeAction?.created_at}
              onChange={(e) => {
                onSelectAction({
                  selectedActionId: e.target.value,
                  shapeAction,
                  shapeActionName,
                });
              }}
            >
              {actionsList.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
            {'action' in errors ? (
              <FormHelperText>{errors['action']}</FormHelperText>
            ) : null}
          </FormControl>
        </Box>

        <Box>
          <Box sx={{ mb: 1.5 }}>
            <Button
              size='small'
              onClick={onSaveShapeAction}
              disabled={isSubmitting}
              variant='contained'
              disableElevation
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
          </Box>

          {/* {shapeAction?.details?.action?.function_name} */}

          <Typography sx={{ fontSize: '14px', mb: 1.5 }}>Parameters</Typography>

          {shapeAction?.details?.action?.function_name === 'send_email' ||
          shapeAction?.details?.action?.function_name === 'send_email_v2' ||
          shapeAction?.details?.action?.function_name ===
            'create_short_links' ||
          shapeAction?.details?.action?.function_name === 'chatgpt_text' ? (
            <Button
              variant='outlined'
              size='small'
              onClick={() => {
                const functionName =
                  shapeAction?.details?.action?.function_name;

                if (functionName === 'send_email') {
                  setShowSendEmail({
                    open: true,
                    data: shapeAction?.action_params,
                  });
                }

                if (functionName === 'send_email_v2') {
                  dispatch(
                    setEmailTemplatePopupConfig({
                      id: shapeAction?.id,
                      value: {
                        open: true,
                        data: shapeAction?.action_params ?? [],
                      },
                    })
                  );
                }

                if (functionName === 'create_short_links') {
                  setShowCreateShortLinks({
                    open: true,
                    data: shapeAction?.action_params,
                  });
                }

                if (functionName === 'chatgpt_text') {
                  setShowChatGPTText({
                    open: true,
                    data: shapeAction?.action_params,
                  });
                }
              }}
              startIcon={<CreateOutlinedIcon fontSize='small' />}
            >
              Map Fields
            </Button>
          ) : Array.isArray(shapeAction?.action_params) ? (
            <Stack spacing={2} sx={{ maxHeight: '270px', overflowY: 'auto' }}>
              {shapeAction?.action_params.map((parameter) => {
                const { id, name } = parameter;

                return (
                  <Box>
                    <Button
                      key={id}
                      variant='outlined'
                      size='small'
                      onClick={() =>
                        setShowFieldMapping({ open: true, data: parameter })
                      }
                      startIcon={<CreateOutlinedIcon fontSize='small' />}
                    >
                      {name}
                    </Button>
                  </Box>
                );
              })}
            </Stack>
          ) : null}

          {showSendEmail?.open ? (
            <SendEmailPopup
              open={!!showSendEmail?.open}
              setOpen={(open) => setShowSendEmail({ open, data: {} })}
              actionParams={showSendEmail?.data}
              workflowId={workflowId}
              shape={shape}
              shapeAction={shapeAction}
              user={user}
              workflowName={workflowName}
            />
          ) : null}

          {emailTemplatePopupConfig[shapeAction?.id]?.open ? (
            <EmailTemplateConfigurationPopup
              open={!!emailTemplatePopupConfig[shapeAction?.id]?.open}
              setOpen={(open) => {
                dispatch(
                  setEmailTemplatePopupConfig({
                    id: shapeAction?.id,
                    value: { open, data: [] },
                  })
                );
              }}
              actionParams={
                emailTemplatePopupConfig[shapeAction?.id]?.data ?? []
              }
              workflowName={workflowName}
              workflowId={workflowId}
              shape={shape}
              shapeAction={shapeAction}
              user={user}
            />
          ) : null}

          {showFormFilledIn?.open ? (
            <FormFilledInPopup
              open={!!showFormFilledIn?.open}
              setOpen={(open) => setShowFormFilledIn({ open, data: {} })}
              actionParams={showFormFilledIn?.data}
              workflowId={workflowId}
              shapeAction={shapeAction}
              user={user}
            />
          ) : null}

          {showCreateShortLinks?.open ? (
            <CreateShortLinksPopup
              open={!!showCreateShortLinks?.open}
              setOpen={(open) => setShowCreateShortLinks({ open, data: {} })}
              actionParams={showCreateShortLinks?.data}
              workflowName={workflowName}
              workflowId={workflowId}
              shape={shape}
              shapeAction={shapeAction}
              user={user}
            />
          ) : null}

          {showChatGPTText?.open ? (
            <ChatGPTTextPopup
              open={!!showChatGPTText?.open}
              setOpen={(open) => setShowChatGPTText({ open, data: {} })}
              actionParams={showChatGPTText?.data}
              workflowName={workflowName}
              workflowId={workflowId}
              shape={shape}
              shapeAction={shapeAction}
              user={user}
            />
          ) : null}

          {showFieldMapping?.open ? (
            <FieldMappingPopup
              workflowId={workflowId}
              open={!!showFieldMapping?.open}
              parameter={showFieldMapping?.data}
              shapeAction={shapeAction}
              setOpen={(open) => setShowFieldMapping({ open, data: {} })}
            />
          ) : null}
        </Box>
      </Stack>

      {showDeleteConfirmation ? (
        <DeletePromptModal
          open={showDeleteConfirmation}
          disabledDeleteButton={isSubmitting}
          onDeleteClick={onDeleteConfirmation}
          onCancelClick={() => setShowDeleteConfirmation(false)}
        />
      ) : null}
    </Box>
  );
}
