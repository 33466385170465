import React from 'react';
import { Box, Typography } from '@mui/material';
import useRQuery from '@hooks/useRQuery';
import { formsUrls } from '@config/routes';
import Loader from '@components/Loader';
import UserFields from '@components/Forms/UserFields';
import FormHeader from '@components/Forms/FormHeader';
import axios from 'axios';
import { uploadFilesToDrive } from '@config/functions/helperFunctions';
import * as Yup from 'yup';
import SEOHeader from '@components/SEOHeader';
import FormSubmitError from '@components/Forms/FormSubmitError';
import { formBgStyles, formHeaderContainerStyles } from '@config/constants';
import PublicFormErrorScreen from '@components/Forms/PublicFormErrorScreen';
import RenderFormQuestions from '@components/Forms/RenderFormQuestions';
import ViewFormActions from '@components/Forms/ViewFormActions';

const { formsListUrls, recipientsUrls, responseWithAnswersUrls } = formsUrls;

export default function PublicForm({
  initialFirstName,
  initialLastName,
  initialEmail,
  initialTags,
  formId: recordId,
  recipient,
  formDraft,
  initialUTMContent,
  initialUTMCampaign,
  initialUTMTerm,
  initialUTMMedium,
  initialUTMSource,
}) {
  const [firstName, setFirstName] = React.useState(initialFirstName ?? '');
  const [lastName, setLastName] = React.useState(initialLastName ?? '');
  const [email, setEmail] = React.useState(initialEmail ?? '');
  const [formData, setFormData] = React.useState(formDraft?.formData ?? {});
  const [errorHeading, setErrorHeading] = React.useState(
    'Failed to submit the form'
  );
  const [error, setError] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [draftSuccess, setDraftSuccess] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isSavingDraft, setSavingDraft] = React.useState(false);
  const [otherValues, setOtherValues] = React.useState(
    formDraft?.otherValues ?? {}
  );
  const [currentGroup, setCurrentGroup] = React.useState(
    formDraft?.currentGroup ?? null
  );
  const [currentQuestion, setCurrentQuestion] = React.useState(
    formDraft?.currentQuestion ?? null
  );
  const [lastGroups, setLastGroups] = React.useState(
    formDraft?.lastGroups ?? []
  );
  const [lastQuestions, setLastQuestions] = React.useState(
    formDraft?.lastQuestions ?? []
  );
  const [orderedQuestionsByGroup, setOrderedQuestionsByGroup] = React.useState(
    formDraft?.orderedQuestionsByGroup ?? []
  );

  const showSaveDraft = !!recipient?.id;
  const isSuccess = success && !error.length;

  const {
    data: formDetails,
    isLoading,
    isError,
  } = useRQuery({
    key: [`${recordId}-view-form`, recordId],
    url: formsListUrls.detail(recordId),
    options: { enabled: !!recordId },
  });

  React.useEffect(() => {
    console.log('formDetails', formDetails);
  }, [formDetails]);

  const handleChange = (questionId, value) => {
    setFormData({ ...formData, [questionId]: value });
  };

  const getValue = (id) => (formData[id] ? formData[id].value : '');

  const handleSaveDraft = async () => {
    try {
      setSavingDraft(true);
      setDraftSuccess(false);
      setError([]);

      await axios.patch(
        recipientsUrls.detail(recipient?.id, `draft/${formDetails?.client}`),
        {
          formState: JSON.stringify({
            formData,
            otherValues,
            currentGroup,
            currentQuestion,
            lastGroups,
            lastQuestions,
            orderedQuestionsByGroup,
          }),
        }
      );

      setDraftSuccess(true);

      setTimeout(() => {
        setDraftSuccess(false);
      }, 3000);
    } catch (error) {
      setError(['Oops! Failed to save draft.']);
    } finally {
      setSavingDraft(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setError([]);
      setSuccess(false);
      setSubmitting(true);

      const answers = [];

      for (const [question, { type, value }] of Object.entries(formData)) {
        if (type === 'MultipleSelect') {
          for (const optionId of value) {
            answers.push({
              questionOptionId: optionId,
              questionId: question,
            });
          }
        } else if (type === 'FileUpload') {
          if (value) {
            const { urls } = await uploadFilesToDrive({
              files: [value],
              client: formDetails?.client,
              createdBy: formDetails?.createdBy,
            });
            answers.push({
              answer: urls[0]?.url,
              questionId: question,
            });
          }
        } else if (type === 'Audio') {
          if (value) {
            const { urls } = await uploadFilesToDrive({
              files: [value],
              fileName: 'recording.wav',
              client: formDetails?.client,
              createdBy: formDetails?.createdBy,
            });
            answers.push({
              answer: urls[0]?.url,
              questionId: question,
            });
          }
        } else if (type === 'Likert') {
          for (const [option, answer] of Object.entries(value)) {
            answers.push({
              answer,
              questionOptionId: option,
              questionId: question,
            });
          }
        } else if (type === 'MultipleChoice') {
          answers.push({
            answer:
              value === 'other'
                ? otherValues[question] || String(value)
                : String(value),
            questionId: question,
          });
        } else {
          answers.push({
            answer: String(value),
            questionId: question,
          });
        }
      }

      const schema = Yup.object({
        email: formDetails.showEmail
          ? Yup.string().email().required()
          : Yup.string().email(),
        lastName: formDetails.showLastName
          ? Yup.string().required()
          : Yup.string(),
        firstName: formDetails.showFirstName
          ? Yup.string().required()
          : Yup.string(),
      });

      try {
        await schema.validate({ firstName, lastName, email });
      } catch (err) {
        setError([err.message]);
        return;
      }

      await axios.post(responseWithAnswersUrls.list(), {
        firstName,
        lastName,
        email,
        formId: recordId,
        answers,
        responseId: recipient?.response?.id,
        tags: initialTags,
        utmContent: initialUTMContent,
        utmCampaign: initialUTMCampaign,
        utmTerm: initialUTMTerm,
        utmMedium: initialUTMMedium,
        utmSource: initialUTMSource,
      });

      setFormData({});
      setFirstName('');
      setLastName('');
      setEmail('');
      setOtherValues({});
      setSuccess(true);
    } catch (error) {
      console.log(error?.message);

      const statusCode = error?.response?.status;
      const message = error?.response?.data?.message;

      if (statusCode === 422) {
        setError(message);
        return;
      }

      const uniqueFailed = message?.includes(
        'already exists for this submission'
      );

      if (uniqueFailed) {
        setError([
          'It seems you have already filled in the form. Please email us if this is not so.',
        ]);
        return;
      }

      setError(['An unexpected error has occurred, please retry later']);
    } finally {
      setSubmitting(false);
    }
  };

  const sortRecordsByOrder = (records) =>
    records.sort((a, b) => a?.order - b?.order);

  const findQuestionsByGroupId = (groupId) => {
    if (!formDetails || !Array.isArray(formDetails.questions)) return [];

    const questionIdsInGroup = new Set(
      formDetails.questionGroups
        .filter((group) => group.groupId === groupId && group.deleted === null)
        .map((group) => group.questionId)
    );

    return formDetails.questions.filter((question) =>
      questionIdsInGroup.has(question.id)
    );
  };

  const isDate = (string) => {
    return !isNaN(Date.parse(string));
  };

  const convertStaticValue = (value) => {
    if (typeof value === 'string') {
      if (!isNaN(+value)) return Number(value);
      if (isDate(value)) return new Date(value);
    }
    return value;
  };

  const compare = (a, b, type) => {
    switch (type) {
      case 'EQUALS':
        return a === b;
      case 'GREATER_THAN_OR_EQUAL_TO':
        return a >= b;
      case 'LESS_THAN_OR_EQUAL_TO':
        return a <= b;
      default:
        return false;
    }
  };

  /**
   * Determines the next group of questions the user should navigate to based on
   * the current state of the form, user responses, and branching conditions.
   *
   * @param {Object} currentGroup - The group object representing the user's current position in the form.
   * @param {Object} currentQuestion - The question object representing the user's current question within the group.
   * @param {Array} sortedGroups - An array of all the groups in the form, sorted in the order they should be presented.
   * @param {Object} formData - An object containing the responses the user has provided so far.
   * @param {Array} branchingConditions - An array of conditions that determine how to branch to different groups based on user responses.
   * @returns {String|null} The ID of the next group the user should navigate to, or null if there is no next group.
   */
  const findNextGroup = (
    currentGroup,
    currentQuestion,
    sortedGroups,
    formData,
    branchingConditions,
    formDetails
  ) => {
    if (!currentGroup) {
      console.log('no current group');
      return null;
    }

    const currentGroupId = currentGroup.id;
    const currentQuestionId = currentQuestion?.id;

    const currentIndex = sortedGroups.findIndex(
      (group) => group.id === currentGroupId
    );

    console.log('currentIndex', currentIndex);

    const sortedQuestions = sortRecordsByOrder(
      findQuestionsByGroupId(currentGroupId)
    );
    const currentQuestionIndex = sortedQuestions.findIndex(
      (question) => question.id === currentQuestionId
    );

    // Check if there are more questions in the current group
    const nextQuestionIndex = currentQuestionIndex + 1;

    if (
      formDetails?.displayMode !== 'ONE_GROUP' &&
      nextQuestionIndex < sortedQuestions.length
    ) {
      console.log('Return the same group');
      return currentGroupId; // Return the same group
    }

    const findMatchingCondition = () => {
      // Start by filtering out the deleted branchingConditions and sorting them by the 'order' property.
      const sortedConditions = branchingConditions
        .filter(
          ({ currentGroupId, deleted }) =>
            currentGroupId === currentGroup?.id && deleted === null
        ) // Filter out condition with deleted property set to null.
        .sort((a, b) => a.order - b.order); // Sort the remaining condition by their 'order' property.

      // Initialize an empty array called resultStack to keep track of intermediate results.
      let resultStack = [];

      for (const condition of sortedConditions) {
        const {
          currentGroupId: conditionCurrentGroupId,
          questionId,
          questionOptionGroupId,
          questionOptionId,
          staticValue,
          type,
          questionOptionGroup,
          concatenator,
        } = condition;

        let conditionResult; // Initialize a variable to store the result of the current condition evaluation.

        // Retrieve the relevant question based on questionId
        const question = formDetails.questions.find(
          (q) => q?.id === questionId
        );
        const formDataAnswer = formData[questionId]?.value;
        const isMatchingGroupId = conditionCurrentGroupId === currentGroupId;
        const effectiveValue =
          questionOptionId || convertStaticValue(staticValue);

        // Special handling for Likert type questions
        if (question?.type === 'Likert') {
          let groupScore = 0;
          const options = questionOptionGroupId
            ? questionOptionGroup.questionOptions.filter(
                ({ deleted }) => deleted === null
              )
            : question.options;

          // Calculate the Likert score based on answers
          options.forEach(({ id, likertScores }) => {
            const label = formDataAnswer ? formDataAnswer[id] : null;

            groupScore +=
              likertScores[
                question.likertLabels.findIndex((value) => value === label)
              ];
          });

          console.log('score', groupScore, effectiveValue);

          conditionResult =
            isMatchingGroupId && compare(groupScore, effectiveValue, type);
        } else {
          // Default handling for non Likert questions
          const isMatchingFormData = compare(
            formDataAnswer,
            effectiveValue,
            type
          );

          conditionResult = isMatchingGroupId && isMatchingFormData;
        }

        // Following BODMAS/PEMDAS, we evaluate ANDs first, and if the connector is 'And'...
        if (concatenator === 'AND') {
          // If the last item in the resultStack is a boolean, pop it, perform the AND operation,
          // and push the result back to the resultStack.
          if (
            resultStack.length &&
            typeof resultStack[resultStack.length - 1]?.result === 'boolean'
          ) {
            let last = resultStack.pop();
            resultStack.push({
              condition,
              result: last.result && conditionResult,
            });
          } else {
            // If there's no previous boolean value, push the conditionResult to the resultStack.
            resultStack.push({ condition, result: conditionResult });
          }
        } else {
          // If the connector is 'Or', simply push the conditionResult to the resultStack.
          resultStack.push({ condition, result: conditionResult });
        }
      }

      // Log the intermediate result stack to the console for debugging.
      console.log('resultStack', resultStack);

      // At the end, any remaining 'Or' conditions in the stack can be combined using 'some'.
      // 'some' returns true if at least one element in the array is true.
      let finalResult = resultStack.find(({ result }) => result);

      // Log the final result to the console for debugging.
      console.log('Result:', finalResult);

      // Return the final boolean result.
      return finalResult?.condition;
    };

    const findGroupByParent = (
      parentGroupId,
      startIndex = 0,
      endIndex = sortedGroups.length
    ) =>
      sortedGroups
        .slice(startIndex, endIndex)
        .find((group) => group?.parentGroupId === parentGroupId);

    // Check for a branching condition match
    const matchingCondition = findMatchingCondition();
    if (matchingCondition) {
      console.log('matchingCondition', matchingCondition);
      return matchingCondition.targetGroupId;
    }

    // Check for the next or previous child group
    const nextChildGroup = findGroupByParent(currentGroupId, currentIndex + 1);
    console.log('nextChildGroup', nextChildGroup);
    if (nextChildGroup) return nextChildGroup.id;

    console.log('sortedGroups', currentIndex, sortedGroups);

    // Check for the next or previous sibling or parent's sibling group
    const parentGroup = sortedGroups[currentIndex]?.parentGroupId;
    const nextSiblingOrParentSiblingGroup = findGroupByParent(
      parentGroup,
      currentIndex + 1
    );

    console.log(
      'nextSiblingOrParentSiblingGroup',
      nextSiblingOrParentSiblingGroup
    );

    if (nextSiblingOrParentSiblingGroup) {
      return nextSiblingOrParentSiblingGroup.id;
    }

    // Default to the next or previous global group
    const nextGlobal = sortedGroups[currentIndex + 1];

    console.log('nextGlobal', nextGlobal);

    return nextGlobal?.id || null;
  };

  function findNextQuestion(currentQuestion, sortedQuestions) {
    // If there is no current question, just return the first question in the sorted list
    if (!currentQuestion) {
      return sortedQuestions[0];
    }

    // Find the next question in order
    for (let i = 0; i < sortedQuestions.length; i++) {
      if (sortedQuestions[i].id === currentQuestion.id) {
        // If we find the current question and there's another question after it, return that
        if (i < sortedQuestions.length - 1) {
          return sortedQuestions[i + 1];
        } else {
          // If it's the last question, end the list
          return null;
        }
      }
    }

    // If the currentQuestion ID is not found, return the first question
    return sortedQuestions[0];
  }

  const shouldDisplayAllQuestions = (branchingConditions, formDetails) => {
    const nonDeletedBranchingConditions = (branchingConditions ?? []).filter(
      ({ deleted }) => deleted === null
    );
    const displayAllQuestions = formDetails?.displayMode === 'ALL_GROUPS';

    return displayAllQuestions && !nonDeletedBranchingConditions.length;
  };

  const isLikertTypeValid = (question, formData) => {
    const optionCount = question?.options?.length;
    const filledAnswersCount = Object.keys(
      formData[question?.id]?.value ?? {}
    ).length;
    return filledAnswersCount === optionCount;
  };

  function scrollToBottom() {
    // Use a timeout to allow any dynamically loaded content to render
    setTimeout(() => {
      // Calculate the maximum scrollable height
      const scrollableHeight = Math.max(
        document.documentElement.scrollHeight,
        document.body.scrollHeight,
        document.documentElement.clientHeight,
        document.body.clientHeight,
        document.documentElement.offsetHeight,
        document.body.offsetHeight
      );

      // Scroll to the bottom of the page
      window.scrollTo({ top: scrollableHeight, behavior: 'smooth' });
    }, 100);
  }

  const isQuestionAnswered = (q) => {
    if (q?.type === 'Likert') {
      return isLikertTypeValid(q, formData);
    }

    return formData[q?.id]?.value;
  };

  const handleUnansweredQuestions = (questions) => {
    const unansweredQuestions = questions
      .filter((q) => !!q?.isRequired)
      .filter((q) => !isQuestionAnswered(q, formData));

    if (unansweredQuestions.length) {
      const errorMessage = [...unansweredQuestions.map((q) => q.question)];
      setErrorHeading(
        'Oops! It looks like you missed some required questions.'
      );
      setError(errorMessage);
      scrollToBottom();

      console.log('return true');
      return true;
    }

    console.log('return false');
    setErrorHeading(null);

    return false;
  };

  const onContinue = () => {
    // Default to an empty array if formDetails?.groups is undefined
    const { branchingConditions = [], groups = [] } = formDetails ?? {};
    const displayMode = formDetails?.displayMode;

    setError([]);

    switch (displayMode) {
      case 'ONE_QUESTION':
        // Check if the current question is required and has been answered
        if (handleUnansweredQuestions([currentQuestion])) return;
        break;

      case 'ONE_GROUP':
        // Get questions by group and check if they are answered
        const groupQuestions = findQuestionsByGroupId(currentGroup?.id);
        if (handleUnansweredQuestions(groupQuestions)) return;
        break;

      case 'ALL_GROUPS':
        // Check if questions in all groups are answered
        if (handleUnansweredQuestions(orderedQuestionsByGroup)) return;
        break;

      default:
        break;
    }

    console.log('groups', groups);

    // Sort only once to improve efficiency
    const sortedGroups = sortRecordsByOrder(groups);

    console.log('612 sortedGroups', sortedGroups);

    // Find the next group
    const nextGroupId = findNextGroup(
      currentGroup,
      currentQuestion,
      sortedGroups,
      formData,
      branchingConditions,
      formDetails
    );

    // Declare a variable to hold the next question object. It will be determined based on the current group or the next group.
    let nextQuestion;

    // Define a helper function to get the next question in the form flow.
    // It takes a groupId as an argument to identify which group's questions we're interested in.
    const getNextQuestion = (groupId) => {
      // Call sortRecordsByOrder with the result of findQuestionsByGroupId to get a sorted array of questions for the specified group.
      const sortedQuestions = sortRecordsByOrder(
        findQuestionsByGroupId(groupId)
      );

      // Call findNextQuestion with the current question and the sorted questions to find the next question in sequence.
      // It returns the next question object or null if there are no more questions in the group.
      return findNextQuestion(currentQuestion, sortedQuestions);
    };

    // If there is no nextGroupId, it means the user should continue within the current group.
    if (!nextGroupId) {
      // Use the getNextQuestion helper function to get the next question from the current group.
      nextQuestion = getNextQuestion(currentGroup?.id);

      // Check if a next question was successfully retrieved within the current group.
      if (nextQuestion) {
        // If there is a next question within the current group:

        // Update the lastQuestions state to include the current question. This keeps a history of questions the user has answered.
        setLastQuestions([...lastQuestions, currentQuestion]);

        // Update the currentQuestion state to the next question. This changes the user's current position to the next question within the current group.
        setCurrentQuestion(nextQuestion);
      } else {
        // If there isn't a next question within the current group (perhaps it's the last question of the group or form), it's assumed that the form is complete.

        // Call handleSubmit to handle form completion. This might involve submitting the data, navigating to a confirmation page, etc.
        handleSubmit();
      }
    }

    // Check if there is a nextGroupId available. If nextGroupId is available, it means there is a next group the user should navigate to.
    if (nextGroupId) {
      if (formDetails?.displayMode === 'ONE_GROUP') {
        // Update the lastGroups state to include the current group. This keeps a history of groups the user has visited.
        setLastGroups([...lastGroups, currentGroup]);

        // Update the currentGroup state to the next group. This changes the user's current position to the next group.
        setCurrentGroup(
          sortedGroups.find((group) => group?.id === nextGroupId)
        );
      } else {
        // Use the getNextQuestion helper function to get the next question from the next group.
        nextQuestion = getNextQuestion(nextGroupId);

        // Check if a next question was successfully retrieved.
        if (nextQuestion) {
          // If there is a next question, update the application state to reflect the user's new position in the form.

          // Update the lastGroups state to include the current group. This keeps a history of groups the user has visited.
          setLastGroups([...lastGroups, currentGroup]);

          // Update the lastQuestions state to include the current question. This keeps a history of questions the user has answered.
          setLastQuestions([...lastQuestions, currentQuestion]);

          // Update the currentGroup state to the next group. This changes the user's current position to the next group.
          setCurrentGroup(
            sortedGroups.find((group) => group?.id === nextGroupId)
          );

          // Update the currentQuestion state to the next question. This changes the user's current position to the next question within the new group.
          setCurrentQuestion(nextQuestion);
        } else {
          // If there isn't a next question (perhaps the next group is empty or doesn't exist), it's assumed that the form is complete.

          // Call handleSubmit to handle form completion. This might involve submitting the data, navigating to a confirmation page, etc.
          handleSubmit();
        }
      }
    }
  };

  React.useEffect(() => {
    if (currentGroup?.id && formDetails?.displayMode === 'ONE_GROUP') {
      // Call sortRecordsByOrder with the result of findQuestionsByGroupId to get a sorted array of questions for the specified group.
      const sortedQuestions = sortRecordsByOrder(
        findQuestionsByGroupId(currentGroup?.id)
      );
      setOrderedQuestionsByGroup(sortedQuestions);
    }
  }, [currentGroup?.id]);

  React.useEffect(() => {
    if (formDraft) return;

    const { groups, branchingConditions } = formDetails ?? {};

    // Guard clause to check if groups is an array
    if (!Array.isArray(groups)) return;

    const sortedGroups = sortRecordsByOrder(groups);

    for (let group of sortedGroups) {
      if (group.deleted) continue; // Skip deleted groups

      const questions = sortRecordsByOrder(findQuestionsByGroupId(group.id));

      // Check if the group has questions
      if (questions?.length) {
        // If displayAllQuestions is true and no branchingConditions exist, append questions
        if (shouldDisplayAllQuestions(branchingConditions, formDetails)) {
          setOrderedQuestionsByGroup((prevState) => [
            ...prevState,
            ...questions,
          ]);
        } else if (formDetails?.displayMode === 'ONE_GROUP') {
          setCurrentGroup(group);
          setOrderedQuestionsByGroup(questions);
          break;
        } else {
          setCurrentGroup(group);
          setCurrentQuestion(questions[0]);
          break; // Exit the loop once you find a group with questions
        }
      }
    }
  }, [formDetails]);

  if (isError) {
    return <PublicFormErrorScreen />;
  }

  if (isLoading || !formDetails) {
    return (
      <Box flex justifyContent='center' alignContent='center' sx={formBgStyles}>
        <SEOHeader title='View Form' />
        <Loader />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ p: 1, position: 'relative' }}>
        {window?.location?.href?.includes('mazards') ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: '1rem' }}>
            <img
              src='https://drive.pullstream.com/file/e21961bc-7e1c-4395-8018-2a9e5403135d.png'
              width='180'
              alt=''
            />
          </Box>
        ) : null}

        <Box sx={formHeaderContainerStyles}>
          <SEOHeader title={formDetails.title} />

          <FormHeader form={formDetails} isSuccess={isSuccess} />

          {isSuccess ? null : (
            <UserFields
              firstName={firstName}
              lastName={lastName}
              email={email}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              form={formDetails}
              initialFirstName={initialFirstName}
              initialLastName={initialLastName}
              initialEmail={initialEmail}
            />
          )}
        </Box>

        <RenderFormQuestions
          displayAll={
            shouldDisplayAllQuestions(
              formDetails?.branchingConditions,
              formDetails
            ) || formDetails?.displayMode === 'ONE_GROUP'
          }
          currentQuestion={currentQuestion}
          orderedQuestionsByGroup={orderedQuestionsByGroup}
          getValue={getValue}
          handleChange={handleChange}
          isSubmitting={isSubmitting}
          success={success}
          otherValues={otherValues}
          setOtherValues={setOtherValues}
          isSuccess={isSuccess}
        />

        {Array.isArray(error) && error.length ? (
          <FormSubmitError heading={errorHeading} error={error} />
        ) : null}

        {!error?.length && draftSuccess ? (
          <Box
            sx={{
              minWidth: '320px',
              maxWidth: '790px',
              mx: 'auto',
              my: '22px',
              py: '12px',
              px: '24px',
              background: 'rgb(238 253 237)',
              border: '1px solid #1fa200c7',
              borderRadius: '8px',
              color: 'rgb(32 95 49)',
            }}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
              Draft Saved
            </Typography>
          </Box>
        ) : null}

        {isSuccess ? null : (
          <ViewFormActions
            lastGroups={lastGroups}
            setLastGroups={setLastGroups}
            setCurrentGroup={setCurrentGroup}
            lastQuestions={lastQuestions}
            setLastQuestions={setLastQuestions}
            setCurrentQuestion={setCurrentQuestion}
            isSubmitting={isSubmitting}
            onContinue={onContinue}
            totalQuestionsCount={formDetails.questions?.length + 1}
            currentQuestionIndex={lastQuestions?.length + 1}
            showSaveDraft={showSaveDraft}
            handleSaveDraft={handleSaveDraft}
            isSavingDraft={isSavingDraft}
          />
        )}
      </Box>
    </Box>
  );
}
