import React from 'react';
import { Route } from 'react-router-dom';
import Login from '@pages/Auth/login';
import Register from '@pages/Auth/register';
import Forgot from '@pages/Auth/forgot';
import Reset from '@pages/Auth/reset';
import EmailConfirmation from '@pages/Auth/email-confirmation';

export default function () {
  return (
    <>
      <Route path="/auth">
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="reset/:id" element={<Reset />} />
        <Route path="email-confirmation/:id" element={<EmailConfirmation />} />
      </Route>
    </>
  );
}
