import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { hrUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { recruitmentPipelineRowsMap } from '@config/handleRows/hr';
import { RecruitmentPipelinesForm } from '@config/forms/hr';
import { microservices } from '@config/constants';

const { recruitmentPipelineUrls } = hrUrls;

export default function RecruitmentPipelines({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  return (
    <Box>
      <ListView
        label="Recruitment Pipelines"
        prefix="0j2"
        labels={labels}
        editMode={editMode}
        microservice={microservices.HR.name}
        model={microservices.HR.models.recruitmentPipeline}
        urls={recruitmentPipelineUrls}
        columnKey="recruitmentPipelineHR"
        tableBulkOptions={[{ id: 'makeACopy', text: 'Make a copy' }]}
        addNewForm={{
          getFields: () => RecruitmentPipelinesForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: recruitmentPipelineUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify('Pipeline Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={recruitmentPipelineRowsMap}
      />
    </Box>
  );
}
