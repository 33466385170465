import * as Yup from 'yup';
import { fieldType, statusChoicesOptions } from '@config/constants';

import { cmsUrls } from '@config/routes';

const { pagesUrls, pageCategoryUrls } = cmsUrls;

// remove this line :P

export const pagesMeta = ({
  data,
  users,
  fetchingUsers,
  setUserTerm,
  user,
  domain,
} = {}) => [
  {
    field: 'page_name',
    headerName: 'Page Name',
    width: 140,
    editable: true,
    type: fieldType.LINK,
    getHref: (r) => {
      if (r.type === 'Blog') {
        return `${domain}/content/edit?p=${r.slug}&t=${user.token}&r=${user.refreshToken}`;
      }

      return `${domain}/edit?p=${r.slug}&t=${user.token}`;
    },
    value: data?.page_name,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'page_title',
    headerName: 'Page Title',
    width: 140,
    editable: true,
    value: data?.page_title,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'page_description',
    headerName: 'Page Description',
    width: 250,
    editable: true,
    value: data ? data.page_description : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'slug',
    headerName: 'Slug',
    width: 250,
    editable: true,
    value: data?.slug,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'publish_schedule',
    headerName: 'Publish Schedule',
    width: 180,
    value: data?.publish_schedule ? new Date(data.publish_schedule) : null,
    onForm: true,
    editable: true,
    type: fieldType.DATE_TIME,
  },
  {
    field: 'page_status',
    headerName: 'Status',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onAccordionForm: true,
    options: [
      {
        key: 'Published',
        label: 'Published',
        value: 'Published',
      },
      {
        key: 'Draft',
        label: 'Draft',
        value: 'Draft',
      },
      {
        key: 'Trash',
        label: 'Trash',
        value: 'Trash',
      },
    ],
    initialValue: '',
    value: data ? data.page_status : null,
    validate: Yup.string().required(),
  },
  {
    field: 'order',
    headerName: 'Order',
    width: 180,
    editable: true,
    value: data?.order,
    type: 'number',
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
    hideColumn: true,
  },
  {
    field: 'user',
    headerName: 'User',
    type: fieldType.AUTOCOMPLETE,
    onForm: false,
    setSearchTerm: (value) => setUserTerm(value),
    options: users,
    isLoading: fetchingUsers,
    validate: Yup.string().required(),
    width: 240,
    editable: true,
    hideColumn: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 140,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const categoryMeta = (data) => [
  {
    field: 'category_name',
    headerName: 'Category Name',
    width: 180,
    editable: true,
    value: data && data.category_name,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'category_description',
    headerName: 'Category Description',
    width: 285,
    editable: true,
    value: data ? data.category_description : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const pageCategoryMeta = ({ data }) => [
  {
    field: 'page_id',
    headerName: 'Pages',
    width: 300,
    editable: true,
    onForm: true,
    onAccordionForm: true,
    getHref: (r) => `/pages/${r.pages ? r.pages.id : ''}`,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pagesUrls.list,
    requestKey: `${data?.id}-page-category`,
    validate: Yup.mixed(),
    initialValue: data?.page_id
      ? {
          id: data?.page_id,
          label: data?.page_id?.page_name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.page_name,
    }),
  },
  {
    field: 'category_id',
    headerName: 'Category',
    width: 300,
    editable: true,
    onForm: true,
    onAccordionForm: true,
    getHref: (r) => `/category/${r.category ? r.category.id : ''}`,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pageCategoryUrls.list,
    requestKey: `${data?.id}-page-category`,
    validate: Yup.mixed(),
    initialValue: data?.category_id
      ? {
          id: data?.category_id,
          label: data?.category_id?.category_name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.category_name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const contentMeta = (data) => [
  {
    field: 'name',
    headerName: ' Name',
    type: fieldType.LINK,
    to: '/edit-content/',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onAccordionForm: true,
    gridStyle: 3,
    validate: Yup.string().required(),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onForm: true,
    options: statusChoicesOptions,
    initialValue: '',
    gridStyle: 3,
    value: data ? data.status : null,
    validate: Yup.string().required(),
  },
  {
    field: 'microservice',
    headerName: 'Microservice',
    width: 285,
    editable: true,
    value: data ? data.microservice : null,
    initialValue: '',
    gridStyle: 3,
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'url',
    headerName: 'URL',
    width: 285,
    editable: true,
    value: data ? data.url : null,
    initialValue: '',
    gridStyle: 3,
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'content',
    headerName: 'Content',
    width: 285,
    type: fieldType.MULTILINE,
    editable: true,
    value: data ? data.content : null,
    initialValue: '',
    gridStyle: 12,
    hideColumn: true,
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const pageMetaMeta = ({ data } = {}) => [
  {
    field: 'key',
    headerName: 'Key ',
    width: 180,
    editable: true,
    value: data && data.key,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'value',
    headerName: 'Value ',
    width: 180,
    editable: true,
    value: data && data.value,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'page_id',
    headerName: 'Pages',
    width: 300,
    editable: true,
    onForm: true,
    onAccordionForm: true,
    getHref: (r) => `/pages/${r.pages ? r.pages.id : ''}`,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pagesUrls.list,
    requestKey: `${data?.id}-pages`,
    validate: Yup.mixed(),
    initialValue: data?.page_id
      ? {
          id: data?.page_id,
          label: data?.page_id?.page_name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.page_name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
