import React from 'react';
import { Box } from '@mui/material';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { computeUrls } from '@config/routes';
import { fieldsMeta } from '@config/meta/compute';
import { useParams } from 'react-router-dom';

const { modelFieldsUrls } = computeUrls;

export default function FieldDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      fieldsMeta({
        data,
        microserviceId: recordData?.model?.microserviceId,
      }),
    [recordData]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/models/${recordData.modelId}`,
                text: 'Model',
              },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={modelFieldsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
    </Box>
  );
}
