// handleRows

export const systemLogsHandleRow = (c) => ({
  id: c.id,
  file_name: c.file_name,
  microservice: c.microservice,
  logs_date: new Date(c.logs_date),
});

export const logHandleRow = (c) => ({
  id: c.id,
  user: c.user,
  microservice: c.microservice,
  model: c.model,
  action: c.action,
  action_datetime: new Date(c.action_datetime),
  before_action_content: c.before_action_content,
  after_action_content: c.after_action_content,
});

export const importLogsRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  microservice: r.microservice,
  model: r.model,
  total_rows: r.total_rows,
  imported: r.imported,
  failed_count: r.failed_count,
  started_at: r.created_at ? new Date(r.created_at) : null,
  finished_at: r.finished_at ? new Date(r.finished_at) : null,
});

export const importErrorLogsRowsMap = (r) => ({
  id: r.id,
  row: r.row,
  field: r.field,
  error: r.error,
});

export const visitorLogsRowsMap = (r) => ({
  id: r.id,
  ip: r.ip,
  location: r.location,
  city: r.city,
  country: r.country,
  postal_code: r.postal_code,
  longitude: r.longitude,
  latitude: r.latitude,
  timezone: r.timezone,
  utm_source: r.utm_source,
  utm_medium: r.utm_medium,
  utm_campaign: r.utm_campaign,
  utm_term: r.utm_term,
  utm_content: r.utm_content,
  created_at: r.created_at,
});
