import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import {
  formatToUTCDateTime,
  formatToUTCDate,
} from '@config/functions/helperFunctions';
import DateTime from '@components/DateTime';

export default function DateTimePickerField({
  label,
  showTime,
  recurring,
  color,
  getRRule,
  rruleName,
  noInfiniteRecurring,
  showTimezone,
  setTimezone,
  disablePast,
  disabled,
  required,
  afterDefaultOnChange = (v) => v,
  ...rest
}) {
  const [field, meta] = useField(rest);
  const { setFieldValue } = useFormikContext();
  const isError = Boolean(meta?.error);

  return (
    <DateTime
      color={color}
      label={label}
      disabled={disabled}
      showTime={showTime}
      recurring={recurring}
      disablePast={disablePast}
      noInfiniteRecurring={noInfiniteRecurring}
      data={field.value}
      required={required}
      showTimezone={showTimezone}
      handleSubmit={(value) => {
        if (getRRule) getRRule(value.rrule);

        if (recurring) {
          setFieldValue(rruleName, value.rrule);
        }

        if (showTimezone && setTimezone) {
          setTimezone(value.timezone);
        }

        let newDate = null;

        if (showTime) {
          newDate = formatToUTCDateTime(value.dateObject);
        } else {
          newDate = formatToUTCDate(value.dateObject);
        }

        setFieldValue(field.name, newDate);
        afterDefaultOnChange(newDate);
      }}
      isError={isError}
      error={meta.error}
    />
  );
}
