import React from 'react';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
} from '@mui/material';
import useToast from '@hooks/useToast';
import { systemUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { updateRecord } from '@config/functions/requests';
import { useQueryClient } from 'react-query';

const { uiPrefixesUrls } = systemUrls;

export default function RenderUiPrefix({
  editMode,
  labels,
  code,
  language,
  children,
  defaultValue = '',
}) {
  const [show, setShow] = React.useState(false);
  const [text, setText] = React.useState(defaultValue ?? '');
  const [prefixId, setPrefixId] = React.useState('');
  const [inputText, setInputText] = React.useState('');
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const handleClose = () => setShow(false);

  const handleSubmit = async () => {
    try {
      await updateRecord({
        url: uiPrefixesUrls.detail(prefixId),
        values: { label_en: inputText },
        token: user.token,
        actAs: user?.actAs,
      });
      queryClient.invalidateQueries('ui-labels-text');
      setText(inputText);
      setShow(false);
    } catch (err) {
      console.log(err.response || err.message);
      notify('There was an error, please try again', {
        type: 'ERROR',
      });
    }
  };

  React.useEffect(() => {
    if (!Array.isArray(labels)) return;
    const label = labels.find((r) => r.code === code);
    if (!label) return;

    const prop =
      language === 'French'
        ? 'label_fr'
        : language === 'German'
        ? 'label_de'
        : 'label_en';

    setText(label[prop] || label.label_en);
    setInputText(label[prop] || label.label_en);
    setPrefixId(label.id);
  }, [code, editMode, labels, language]);

  if (!text) return null;

  if (!editMode) return children(text);

  return (
    <>
      <Box
        className="editable-ui-label"
        sx={{ userSelect: 'none', cursor: 'pointer' }}
        onClick={() => setShow(true)}
      >
        <Box sx={{ pointerEvents: 'none' }}>{children(`${code} ${text}`)}</Box>
      </Box>

      <Dialog
        open={show}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Text</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={code}
                  fullWidth
                  disabled
                  label="Code"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="Text"
                  variant="outlined"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  multiline
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
