import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { bpaUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { workflowRowsMap } from '@config/handleRows/bpa';
import { WorkflowForm } from '@config/forms/bpa';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { workflowsUrls } = bpaUrls;

export default function ManualWorkflows({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  console.log('editMode', editMode);

  const workflowsColOptions = React.useMemo(() => {
    return {
      manualWorkflow: false,
    };
  }, []);

  return (
    <Box>
      <ListView
        label="Workflows"
        prefix="fp"
        labels={labels}
        editMode={editMode}
        microservice={microservices.BPA.name}
        model={microservices.BPA.models.workflow}
        urls={workflowsUrls}
        columnKey="workflow"
        columnOptions={workflowsColOptions}
        addNewForm={{
          getFields: () => WorkflowForm({ manualWorkflow: true }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values: {
                  ...values,
                  meta_model: values?.meta_model?.id,
                  workflow_type: 'Manual',
                },
                url: workflowsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify('Workflow Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={workflowRowsMap}
        urlParams={`&workflow_type=Manual`}
      />
    </Box>
  );
}
