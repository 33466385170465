import React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import { colorPickerColors } from '@config/constants';

export default function ColorPicker({ onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleOpen}>
        <FormatColorFillIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', px: 1 }}>
          {Object.keys(colorPickerColors).map((key) => (
            <Box
              key={key}
              sx={{
                cursor: 'pointer',
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                bgcolor: colorPickerColors[key]?.main,
                mx: 0.5,
                my: 1,
                border:
                  colorPickerColors[key]?.main === '#fff'
                    ? '1px solid #d3d3d3'
                    : '',
              }}
              onClick={() => {
                if (onChange) onChange(key);
                setAnchorEl(null);
              }}
            />
          ))}
        </Box>
      </Popover>
    </>
  );
}
