import React from 'react';
import { Box } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { selectTasksBoard, setTasks } from '@redux/tasksBoardSlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { pmUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { updateRecord } from '@config/functions/requests';
import { handleDragEnd } from '@config/functions/helperFunctions';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';
import {
  getAuthHeader,
  getLastSelectedDropdownOption,
} from '@config/functions/helperFunctions';
import PipelineStagesList from '@components/PipelineStagesList';

const {
  taskStagesUrls,
  taskStatusUrls,
  tasksUrls,
  projectsUrls,
  resourcesUrls,
  programsUrls,
} = pmUrls;

export default function TasksBoard({ labels, editMode }) {
  const { tasks } = useSelector(selectTasksBoard);
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [notify, update] = useToast();
  const initialProgram = getLastSelectedDropdownOption(
    'lastSelectedTaskProgram'
  );
  const initialProject = getLastSelectedDropdownOption(
    'lastSelectedTaskProject'
  );
  const initialResource = getLastSelectedDropdownOption(
    'lastSelectedTaskResource'
  );
  const [program, setProgram] = React.useState(initialProgram);
  const [project, setProject] = React.useState(initialProject);
  const [resource, setResource] = React.useState(initialResource);
  const [stages, setStages] = React.useState([]);
  const [editPipeline, setEditPipeline] = React.useState(false);

  const { data, isLoading, refetch } = useRQuery({
    key: ['tasks-board', project, resource, user.token, user?.actAs],
    program,
    url: taskStagesUrls.list(
      `?project=${project?.id ?? ''}&&owner=${
        resource?.id ?? ''
      }&order=order&page_size=200&item_order=-created_at`
    ),
    options: { enabled: !!(user.token && project) },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleSaveCardDrag = async ({ newList, removedItem, destId }) => {
    const tId = notify('Saving....', { loading: true });
    dispatch(setTasks(newList));
    try {
      await updateRecord({
        url: tasksUrls.detail(removedItem.id),
        values: { status: destId },
        token: user.token,
        actAs: user?.actAs,
      });
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(
        err.response && err.response.data ? err.response.data : err.message
      );
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  const savePipeline = async () => {
    const tId = notify('Saving....', { loading: true });
    try {
      await Promise.all(
        stages.map((s) =>
          updateRecord({
            url: taskStatusUrls.detail(s.id),
            values: {
              order: s.order,
              name: s.name,
              rotting_days: s.rotting_days,
            },
            token: user.token,
            actAs: user?.actAs,
          })
        )
      );
      update(tId, 'Your changes were saved');
      setEditPipeline(false);
      refetch();
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    if (data) {
      setStages(data);
      dispatch(setTasks(data));
    }
  }, [data, dispatch]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title='Tasks'
        prefix='020'
        labels={labels}
        editMode={editMode}
        setEditPipeline={setEditPipeline}
        showEditPipeline={!!project}
        editPipeline={editPipeline}
        savePipeline={savePipeline}
        filterOptions={[
          {
            label: 'Program',
            name: 'program',
            requestKey: 'tasks-board-program',
            fetchUrl: programsUrls.list,
            handleChange: (newValue) => {
              setProgram(newValue);
              localStorage.setItem(
                'lastSelectedTaskProgram',
                JSON.stringify(newValue)
              );
              setProject(null);
            },
            initialValue: initialProgram,
          },
          {
            label: 'Project',
            name: 'project',
            requestKey: 'tasks-board-projects',
            fetchUrl: projectsUrls.list,
            handleChange: (newValue) => {
              setProject(newValue);
              localStorage.setItem(
                'lastSelectedTaskProject',
                JSON.stringify(newValue)
              );
              setResource(null);
            },
            requestKeyOptions: [program?.id],
            urlParams: program ? `&program=${program.id}` : '',
            initialValue: initialProject,
          },
          {
            label: 'Resource',
            name: 'resource',
            requestKey: 'tasks-board-resource',
            fetchUrl: resourcesUrls.list,
            handleChange: (newValue) => {
              setResource(newValue);
              localStorage.setItem(
                'lastSelectedTaskResource',
                JSON.stringify(newValue)
              );
            },
            renderRow: (r) => ({
              id: r.id,
              label: r.email,
            }),
            orderingCol: 'email',
            initialValue: initialResource,
          },
        ]}
      />

      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            {editPipeline ? (
              <PipelineStagesList
                detailLink='tasks'
                stages={stages}
                setStages={setStages}
              />
            ) : (
              <DragDropContext
                onDragEnd={(res) => {
                  handleDragEnd(res, tasks, handleSaveCardDrag);
                }}
              >
                <KanbanBoardList
                  detailLink='tasks'
                  data={tasks}
                  labels={labels}
                  editMode={editMode}
                />
              </DragDropContext>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
