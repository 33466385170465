import * as React from 'react';
import useToast from '@hooks/useToast';
import { Formik, Form } from 'formik';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ContextFieldsList from '@components/BPA-V2/Popups/ChatGPTTextPopup/ContextFieldsList';
import { getInitialParamValue } from '@config/functions/bpaFunctions';
import { useQueryClient } from 'react-query';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  handleClose,
  handlePopupSubmit,
  getInitialValues,
} from '@config/functions/chatGPTTextPopupFunctions';
import ReminderPopup from '@components/ReminderPopup';
import { TextField, SwitchField } from '@components/Inputs';

export default function ChatGPTTextPopup({
  open,
  setOpen,
  workflowId,
  workflowName,
  actionParams,
  shapeAction,
  shape,
  user,
  isKanbanView,
  workflowInstanceId,
  isFromKanbanHeader,
}) {
  const [notify] = useToast();
  const contextFieldGroupRef = React.useRef(null);
  const [showAddReminder, setShowAddReminder] = React.useState({
    initialNotes: '',
    open: false,
  });
  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = React.useState('setup');

  const {
    initialName,
    initialPersona,
    initialContext,
    initialTask,
    initialFormat,
    initialExemplar,
    initialTone,
    initialHighIntelligence,
    initialContextFields,
  } = React.useMemo(
    () => ({
      initialName: getInitialParamValue('name', actionParams),
      initialPersona: getInitialParamValue('persona', actionParams),
      initialContext: getInitialParamValue('context', actionParams),
      initialTask: getInitialParamValue('task', actionParams),
      initialFormat: getInitialParamValue('format', actionParams),
      initialExemplar: getInitialParamValue('exemplar', actionParams),
      initialTone: getInitialParamValue('tone', actionParams),
      initialHighIntelligence: getInitialParamValue(
        'high_intelligence',
        actionParams
      ),
      initialContextFields: getInitialParamValue(
        'context_fields',
        actionParams,
        true
      ),
    }),
    [actionParams]
  );

  const initialValues = React.useMemo(() => {
    return getInitialValues({
      initialName,
      initialPersona,
      initialContext,
      initialTask,
      initialFormat,
      initialExemplar,
      initialTone,
      initialHighIntelligence,
      initialContextFields,
    });
  }, [
    initialName,
    initialPersona,
    initialContext,
    initialTask,
    initialFormat,
    initialExemplar,
    initialTone,
    initialHighIntelligence,
    initialContextFields,
  ]);

  React.useEffect(() => {
    console.log('initialValues', initialValues);
  }, [initialValues]);

  const handleCloseWrapped = () => {
    if (currentStep === 'preview') return setCurrentStep('setup');
    handleClose({ setOpen, shape, queryClient });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => null}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <DialogTitle>
        {currentStep === 'setup' ? `AI Text Setup` : 'Preview of AI Text'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseWrapped}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Formik
          enableReinitialize={false}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={initialValues}
          onSubmit={async (
            values,
            { setSubmitting, setFieldValue, setFieldError }
          ) => {
            await handlePopupSubmit({
              values,
              setSubmitting,
              setFieldValue,
              setFieldError,
              shapeAction,
              user,
              initialContextFields,
              notify,
              contextFieldGroupRef,
              isKanbanView,
              isFromKanbanHeader,
              workflowInstanceId,
              currentStep,
              setCurrentStep,
              setOpen,
            });
          }}
        >
          {({ values, errors, isSubmitting, setValues, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              {currentStep === 'setup' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField label="Name" name="name" multiline required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Persona"
                      name="persona"
                      multiline
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label="Context" name="context" multiline />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label="Task" name="task" multiline required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label="Format" name="format" multiline />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label="Exemplar" name="exemplar" multiline />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label="Tone" name="tone" multiline />
                  </Grid>
                  <Grid item xs={12}>
                    <SwitchField
                      label="High Intelligence (High Cost)"
                      name="high_intelligence"
                      labelColor="#000000de"
                    />
                  </Grid>
                </Grid>
              ) : (
                <TextField label="Answer" name="answer" multiline />
              )}

              {currentStep === 'setup' ? (
                <Stack spacing={3} sx={{ pt: 3 }}>
                  <Box ref={contextFieldGroupRef}>
                    <ContextFieldsList
                      shape={shape}
                      shapeAction={shapeAction}
                      values={values}
                      setValues={setValues}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      workflowId={workflowId}
                      workflowName={workflowName}
                      showAddReminder={showAddReminder}
                      setShowAddReminder={setShowAddReminder}
                    />
                  </Box>
                </Stack>
              ) : null}

              <DialogActions sx={{ pt: 3 }}>
                <Button
                  onClick={() => {
                    setFieldValue('answer', '');
                    handleCloseWrapped();
                  }}
                >
                  {currentStep === 'preview' ? `Back` : `Cancel`}
                </Button>
                <Button disabled={isSubmitting} type="submit">
                  {currentStep === 'setup' &&
                  isKanbanView &&
                  !isFromKanbanHeader
                    ? `Preview`
                    : 'Save'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
        {showAddReminder ? (
          <ReminderPopup
            type="INA"
            microservice="BPA"
            model="WorkflowDefn"
            workflow={workflowName}
            initialNotes={showAddReminder?.initialNotes}
            open={showAddReminder?.open}
            setOpen={(value) =>
              setShowAddReminder({
                ...showAddReminder,
                open: value,
              })
            }
            selectedRows={[workflowId]}
            setSelectedRows={() => null}
            link={`${window.location.host}/bpa/${workflowId}`}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
