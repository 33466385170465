import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import PlatformHeader from '@ui/SocialMedia/PlatformHeader';

const flexAndAlignCenter = { display: 'flex', alignItems: 'center' };

export default function PlatformAccountsList({
  name,
  selectedAccounts = [],
  accounts = [],
  handleClick = () => null,
}) {
  const [selectedAccountIds, setSelectedAccountIds] = React.useState(
    selectedAccounts.map((r) => r.id)
  );

  const handleCheckboxChange = (e, account) => {
    const checked = e.target.checked;
    let updatedSelectedAccountIds;
    if (checked) {
      updatedSelectedAccountIds = [...selectedAccountIds, account.id];
    } else {
      updatedSelectedAccountIds = selectedAccountIds.filter(
        (id) => id !== account.id
      );
    }
    setSelectedAccountIds(updatedSelectedAccountIds);
    handleClick({ checked: e.target.checked, account });
  };

  return (
    <Box sx={flexAndAlignCenter}>
      <PlatformHeader text={name} />
      <Stack direction='row' spacing={1.5} sx={{ ml: 1.5 }}>
        {Array.isArray(accounts) &&
          accounts.map((acct) => (
            <Box key={acct.id} sx={flexAndAlignCenter}>
              <Checkbox
                sx={{ pl: 0 }}
                size='small'
                defaultChecked={selectedAccountIds.includes(acct.id)}
                onClick={(e) => {
                  console.log('obi-click');
                  handleCheckboxChange(e, acct);
                }}
              />
              <Typography sx={{ fontSize: '13px', color: 'rgb(61, 61, 61)' }}>
                {acct.page_name}
              </Typography>
            </Box>
          ))}
      </Stack>
    </Box>
  );
}
