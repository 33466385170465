import * as Yup from 'yup';
import {
  fieldType,
  opportunityPriority,
  opportunitySentiments,
  opportunityInfluencerRatings,
  microservices,
  targetUnitsChoices,
  companySpinsBuyerInfluence,
  progressType,
} from '@config/constants';
import {
  formatToUTCDate,
  formatToUTCDateTime,
} from '@config/functions/helperFunctions';
import getDropdownForm from '@dropdown-forms/getDropdownForm';
import {
  crmUrls,
  calendarUrls,
  bpaUrls,
  listsUrls,
  hrUrls,
  inventoryUrls,
} from '@config/routes';

const { countriesUrls } = listsUrls;
const {
  relationshipsUrls,
  customerEnquiryStatusesUrls,
  customerEnquiryPurposesUrls,
  companiesUrls,
  channelsUrls,
  companyContactUrls,
  pipelinesUrls,
  pipelineStagesUrls,
  callListPipelineStagesUrls,
  personsUrls,
  callListPipelinesUrls,
  activityTypesUrls,
  activityKindsUrls,
  territoriesUrls,
  socialMediaTypesUrls,
  opportunitiesUrls,
} = crmUrls;
const { actionsUrls } = calendarUrls;
const { employeesUrls } = hrUrls;
const { productVariantsUrls } = inventoryUrls;
// const { usersUrls } = accountUrls;
const { workflowsUrls } = bpaUrls;

export const channelMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 350,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 790,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const personRelationshipHistoryMeta = ({ data } = {}) => [
  {
    field: 'notes',
    headerName: 'Notes',
    onAccordionForm: true,
    editable: true,
    value: data?.notes ?? null,
    initialValue: '',
    width: 590,
    type: fieldType.MULTILINE,
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const callHistoryMeta = ({
  data,
  callListPipelineId,
  queryClient,
} = {}) => [
  {
    field: 'call_list_pipeline_stage',
    headerName: 'Call List Stage',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: callListPipelineStagesUrls.list,
    requestKey: `${data?.id}-call-list-pipeline-stage`,
    validate: Yup.mixed(),
    initialValue: data?.call_list_pipeline_stage
      ? {
          id: data?.call_list_pipeline_stage,
          label: data?.call_list_pipeline_stage?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: data?.details?.call_list
      ? `&call_list_pipeline=${data?.details?.call_list?.call_list_pipeline}`
      : `&call_list_pipeline=${callListPipelineId}`,
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-call-list-pipeline-stage`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.callListPipelineStage,
      }),
    }),
  },
  {
    field: 'outcome',
    headerName: 'Outcome',
    onAccordionForm: true,
    width: 350,
    editable: true,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const callScheduleMeta = ({
  data,
  callListPipelineId,
  queryClient,
} = {}) => [
  {
    field: 'person',
    headerName: 'Person',
    to: '/call-schedules/',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-call-schedule-person`,
    validate: Yup.mixed(),
    initialValue: data?.person
      ? {
          id: data?.person,
          label: data?.details?.person?.email,
        }
      : null,
    renderRow: (row) => ({
      id: row.id,
      label: row?.email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-call-schedule-person`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.persons,
      }),
    }),
  },
  {
    field: 'schedule_datetime',
    headerName: 'Schedule Time',
    width: 180,
    value: data?.schedule_datetime ? new Date(data.schedule_datetime) : null,
    editable: true,
    onAccordionForm: true,
    initialValue: formatToUTCDateTime(new Date()),
    type: fieldType.DATE_TIME,
    validate: Yup.string(),
  },
  {
    field: 'call_list_pipeline_stage',
    headerName: 'Call List Stage',
    width: 180,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: callListPipelineStagesUrls.list,
    requestKey: `${data?.id}-call-list-pipeline-stage`,
    validate: Yup.mixed(),
    initialValue: data?.call_list_pipeline_stage
      ? {
          id: data?.call_list_pipeline_stage,
          label: data?.details?.call_list_pipeline_stage?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: data?.details?.call_list
      ? `&call_list_pipeline=${data?.details?.call_list?.call_list_pipeline}`
      : `&call_list_pipeline=${callListPipelineId}`,
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-call-list-pipeline-stage`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.callListPipelineStage,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const callListPipelineStageMeta = ({ data, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 90,
    editable: true,
    value: data ? data.order : null,
    initialValue: nextOrder ? nextOrder + 1 : 0,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 290,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'rotting_days',
    headerName: 'Rotting Days',
    width: 150,
    editable: true,
    value: data ? data.rotting_days : null,
    initialValue: 0,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const callListPipelineMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 170,
    editable: true,
    to: '/call-list-pipelines/',
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 285,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const callListMeta = ({ data, queryClient } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 170,
    editable: true,
    to: '/call-lists/',
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'call_list_pipeline',
    headerName: 'Call List Pipeline',
    width: 180,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: callListPipelinesUrls.list,
    requestKey: `${data?.id}-call-list-pipeline`,
    validate: Yup.mixed(),
    initialValue: data?.call_list_pipeline
      ? {
          id: data?.call_list_pipeline,
          label: data?.details?.call_list_pipeline?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-call-list-pipeline`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.callListPipeline,
      }),
    }),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 285,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const personCompanyMeta = ({ data, queryClient } = {}) => [
  // Why are there two company columns and one hidden? — Umer
  {
    field: 'company_id',
    headerName: 'Company',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: companiesUrls.list,
    hideColumn: true,
    requestKey: `${data?.id}-person-company`,
    validate: Yup.mixed().required('company is a required field'),
    initialValue: data?.company_id
      ? {
          id: data?.company_id,
          label: data?.details?.company_id?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-companyspins-company`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.companies,
      }),
    }),
  },
  {
    type: fieldType.LINK,
    getHref: (r) => `/companies/${r?.company_id}`,
    field: 'company_name',
    headerName: 'Company',
    onForm: true,
    width: 170,
    editable: false,
    value: data ? data.company_name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'company_phone',
    headerName: 'Company Phone',
    onForm: true,
    editable: false,
    value: data ? data.company_phone : null,
    initialValue: '',
    width: 150,
    validate: Yup.string(),
    type: 'company_phone',
    microservice: microservices.CRM.name,
    model: microservices.CRM.models.companies,
  },
  {
    field: 'work_email',
    headerName: 'Work Email',
    onAccordionForm: true,
    editable: true,
    value: data ? data.work_email : null,
    initialValue: '',
    width: 200,
    validate: Yup.string().email(),
  },
  {
    field: 'work_phone',
    headerName: 'Work Phone',
    type: 'phone',
    onAccordionForm: true,
    width: 140,
    editable: true,
    value: data ? data.work_phone : null,
    initialValue: '',
    validate: Yup.string().max(25).nullable(),
  },
  {
    field: 'work_mobile',
    headerName: 'Work Mobile',
    onAccordionForm: true,
    editable: true,
    value: data ? data.work_mobile : null,
    initialValue: '',
    width: 140,
    validate: Yup.string().max(25).nullable(),
  },
  {
    field: 'job_title',
    headerName: 'Job Title',
    onAccordionForm: true,
    editable: true,
    value: data ? data.job_title : null,
    initialValue: '',
    width: 140,
    validate: Yup.string(),
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    type: fieldType.DATE,
    width: 120,
    editable: true,
    onAccordionForm: true,
    value: data ? formatToUTCDate(new Date(data.start_date)) : null,
    initialValue: formatToUTCDate(new Date()),
    validate: Yup.string().default(formatToUTCDate(new Date())),
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    type: fieldType.DATE,
    width: 120,
    editable: true,
    onAccordionForm: true,
    value: data ? formatToUTCDate(new Date(data.end_date)) : null,
    initialValue: null,
    validate: Yup.string().nullable(),
  },
  {
    field: 'company_website',
    headerName: 'Company Website',
    onForm: true,
    editable: false,
    value: data ? data.company_website : null,
    initialValue: '',
    width: 200,
    validate: Yup.string().url(),
    getHref: (r) => r.company_website,
    linkInNewTab: true,
  },
  {
    field: 'company_news_url',
    headerName: 'Company News Url',
    onForm: true,
    editable: false,
    value: data ? data.company_news_url : null,
    initialValue: '',
    width: 200,
    getHref: (r) => r.company_news_url,
    linkInNewTab: true,
    validate: Yup.string().url(),
  },
  {
    field: 'company_staff_url',
    headerName: 'Company Staff Url',
    onForm: true,
    editable: false,
    value: data ? data.company_staff_url : null,
    initialValue: '',
    width: 200,
    getHref: (r) => r.company_staff_url,
    linkInNewTab: true,
    validate: Yup.string().url(),
  },
];

export const callRecordingsMeta = ({ data } = {}) => [
  {
    field: 'person',
    headerName: 'Person',
    width: 390,
    onForm: false,
    editable: false,
    value: data ? data.person : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'call_from',
    headerName: 'From',
    width: 120,
    onForm: false,
    editable: false,
    value: data ? data.call_from : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'call_to',
    headerName: 'To',
    width: 220,
    onForm: false,
    editable: false,
    value: data ? data.call_to : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'recorded_file_url',
    type: fieldType.AUDIO,
    headerName: 'Recording',
    width: 400,
    onForm: false,
    editable: false,
    value: data ? data.recorded_file_url : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'called_at',
    headerName: 'Called At',
    width: 180,
    value: data && data.called_at ? new Date(data.called_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const remindersMeta = ({ data, queryClient } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'responsible',
    headerName: 'Responsible',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-reminder-responsible`,
    validate: Yup.mixed(),
    initialValue: data?.responsible
      ? {
          id: data?.responsible,
          label: data?.details?.responsible?.work_email,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
  },
  {
    field: 'entity_id',
    headerName: data?.entity || 'Unnamed',
    type: fieldType.AUTOCOMPLETE,
    setSearchTerm: (value) => value,
    options: [],
    isLoading: false,
    width: 180,
    editable: false,
    value: data?.details?.entity_id
      ? {
          id: data.entity_id,
          label:
            data.details.entity_id[
              data?.entity === 'person' ? 'email' : 'name'
            ],
        }
      : null,
    validate: Yup.string().required(),
    initialValue: '',
  },
  {
    field: 'task_id',
    headerName: 'Task',
    type: fieldType.AUTOCOMPLETE,
    setSearchTerm: (value) => value,
    options: [],
    isLoading: false,
    width: 180,
    editable: false,
    value: data?.details?.task_id
      ? {
          id: data.task_id,
          label: data.details.task_id.name,
        }
      : null,
    validate: Yup.string().required(),
    initialValue: '',
  },
  {
    field: 'event_id',
    headerName: 'Event',
    type: fieldType.AUTOCOMPLETE,
    setSearchTerm: (value) => value,
    options: [],
    isLoading: false,
    width: 180,
    editable: false,
    value: data?.details?.event_id
      ? {
          id: data.event_id,
          label: data.details.event_id.name,
        }
      : null,
    validate: Yup.string().required(),
    initialValue: '',
  },
  {
    field: 'pipeline',
    headerName: 'Pipeline',
    type: fieldType.AUTOCOMPLETE,
    setSearchTerm: (value) => value,
    options: [],
    isLoading: false,
    width: 180,
    editable: false,
    value: data?.details?.pipeline
      ? {
          id: data.pipeline,
          label: data.details.pipeline.name,
        }
      : null,
    validate: Yup.string().required(),
    initialValue: '',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 300,
    editable: true,
    hideColumn: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    validate: Yup.mixed(),
    initialValue: data?.responsible
      ? {
          id: data?.status,
          label: data?.details?.status?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const actionMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const activityTypesMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const activityKindsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const activityNotesMeta = ({ data } = {}) => [
  {
    field: 'kind',
    headerName: 'Kind',
    width: 180,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: activityKindsUrls.list,
    requestKey: `${data?.id}-activity-kind`,
    validate: Yup.mixed(),
    initialValue: data?.kind
      ? {
          id: data?.kind,
          label: data?.details?.kind?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 180,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: activityTypesUrls.list,
    requestKey: `${data?.id}-activity-type`,
    validate: Yup.mixed(),
    initialValue: data?.type
      ? {
          id: data?.type,
          label: data?.details?.type?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'ina',
    headerName: 'INA',
    width: 285,
    editable: true,
    value: data ? data.ina : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 220,
    editable: true,
    value: data ? data.notes : null,
    initialValue: '',
    hideColumn: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const companySpinsMeta = ({ data, queryClient } = {}) => [
  {
    field: '_company',
    headerName: 'Company',
    width: 190,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: companiesUrls.list,
    requestKey: `${data?.id}-companyspins-company`,
    validate: Yup.mixed(),
    initialValue: data?._company
      ? {
          id: data?._company,
          label: data?.details?._company?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-companyspins-company`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.companies,
      }),
    }),
  },
  {
    width: 180,
    field: 'buyer_influence',
    headerName: 'Buyer Influence',
    type: fieldType.SELECT,
    editable: true,
    options: companySpinsBuyerInfluence,
    initialValue: '',
    value: data ? data.buyer_influence : null,
    validate: Yup.string().required(),
    onForm: true,
  },
  {
    field: 'situation',
    headerName: 'Situation',
    type: fieldType.MULTILINE,
    width: 240,
    editable: true,
    value: data ? data.situation : null,
    initialValue: '',
    onForm: true,
    getHref: (r) => `/company-spins/${r.id}`,
    validate: Yup.string().required(),
  },
  {
    field: 'problem',
    headerName: 'Problem',
    type: fieldType.MULTILINE,
    width: 140,
    editable: true,
    onForm: true,
    value: data ? data.problem : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'implication',
    headerName: 'Implication',
    type: fieldType.MULTILINE,
    width: 240,
    editable: true,
    value: data ? data.implication : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(100).required(),
  },
  {
    field: 'need',
    headerName: 'Need',
    type: fieldType.MULTILINE,
    width: 240,
    editable: true,
    value: data ? data.need : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(100).required(),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 400,
    editable: true,
    type: fieldType.TEXT,
    value: data && data.notes,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const salesPeopleTargetMeta = ({ data, queryClient } = {}) => [
  {
    field: 'sales_person',
    headerName: 'Sales Person',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-sales-people-target-person`,
    validate: Yup.mixed(),
    initialValue: data?.sales_person
      ? {
          id: data?.sales_person,
          label: data?.details?.sales_person?.work_email,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    urlParams: `&department_name=Sales&ordering=created_at`,
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([
          `${data?.id}-sales-people-target-person`,
        ]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'pipeline',
    headerName: 'Pipeline',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pipelinesUrls.list,
    requestKey: `${data?.id}-sales-people-target-pipeline`,
    validate: Yup.mixed(),
    initialValue: data?.pipeline
      ? {
          id: data?.pipeline,
          label: data?.details?.pipeline?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'pipeline_stage',
    headerName: 'Pipeline Stage',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pipelineStagesUrls.list,
    requestKey: `${data?.id}-sales-people-target-pipeline-stage`,
    validate: Yup.mixed(),
    initialValue: data?.pipeline_stage
      ? {
          id: data?.pipeline_stage,
          label: data?.details?.pipeline_stage?.stage,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row.order} ${row.stage}`,
    }),
  },
  {
    field: 'target',
    headerName: 'Target',
    width: 180,
    editable: true,
    value: data && data.target,
    initialValue: 0,
    onForm: true,
    validate: Yup.number().required(),
  },
  {
    width: 180,
    field: 'target_unit',
    headerName: 'Target Unit',
    type: fieldType.SELECT,
    editable: true,
    options: targetUnitsChoices,
    initialValue: targetUnitsChoices[0].label,
    value: data ? data.target_unit : null,
    validate: Yup.string(),
    onForm: true,
  },
  {
    field: 'expiry_date',
    headerName: 'Expiry date',
    type: fieldType.DATE,
    width: 140,
    editable: true,
    value: data?.expiry_date
      ? formatToUTCDate(new Date(data.expiry_date))
      : null,
    initialValue: formatToUTCDate(
      new Date(new Date().setFullYear(new Date().getFullYear() + 4))
    ),
    validate: Yup.string().default(
      formatToUTCDate(
        new Date(new Date().setFullYear(new Date().getFullYear() + 4))
      )
    ),
    onForm: true,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 400,
    editable: true,
    type: fieldType.TEXT,
    value: data && data.notes,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const territoryOwnerMeta = ({ data, queryClient } = {}) => [
  {
    field: 'sales_person',
    headerName: 'Sales Person',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-territory-owner-person`,
    validate: Yup.mixed(),
    initialValue: data?.sales_person
      ? {
          id: data?.sales_person,
          label: data?.details?.sales_person?.work_email,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-territory-owner-person`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'expiry_date',
    headerName: 'Expiry date',
    type: fieldType.DATE,
    width: 240,
    editable: true,
    onAccordionForm: true,
    value: data?.expiry_date
      ? formatToUTCDate(new Date(data.expiry_date))
      : null,
    initialValue: formatToUTCDate(
      new Date(new Date().setFullYear(new Date().getFullYear() + 4))
    ),
    validate: Yup.string().default(
      formatToUTCDate(
        new Date(new Date().setFullYear(new Date().getFullYear() + 4))
      )
    ),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const companyTerritoriesMeta = ({ data } = {}) => [
  {
    field: 'territory',
    headerName: 'Territory',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: territoriesUrls.list,
    requestKey: `${data?.id}-company-territory`,
    validate: Yup.mixed(),
    initialValue: data?.territory
      ? {
          id: data?.territory,
          label: data?.details?.territory?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'expiry_date',
    headerName: 'Expiry date',
    type: fieldType.DATE,
    width: 240,
    editable: true,
    onAccordionForm: true,
    value: data?.expiry_date
      ? formatToUTCDate(new Date(data.expiry_date))
      : null,
    initialValue: formatToUTCDate(
      new Date(new Date().setFullYear(new Date().getFullYear() + 4))
    ),
    validate: Yup.string().default(
      formatToUTCDate(
        new Date(new Date().setFullYear(new Date().getFullYear() + 4))
      )
    ),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const accountManagersMeta = ({ data, queryClient } = {}) => [
  {
    field: 'account_manager',
    headerName: 'Account manager',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-account-manager-person`,
    validate: Yup.mixed(),
    initialValue: data?.account_manager
      ? {
          id: data?.account_manager,
          label: data?.details?.account_manager?.work_email,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-account-manager-person`]);
      },
      Form: getDropdownForm({
        microservice: microservices.HR.name,
        model: microservices.HR.models.employee,
      }),
    }),
  },
  {
    field: 'expiry_date',
    headerName: 'Expiry date',
    type: fieldType.DATE,
    width: 240,
    editable: true,
    onAccordionForm: true,
    value: data?.expiry_date
      ? formatToUTCDate(new Date(data.expiry_date))
      : null,
    initialValue: formatToUTCDate(
      new Date(new Date().setFullYear(new Date().getFullYear() + 4))
    ),
    validate: Yup.string().default(
      formatToUTCDate(
        new Date(new Date().setFullYear(new Date().getFullYear() + 4))
      )
    ),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const marketingListMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 170,
    editable: true,
    to: '/marketing-lists/',
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 285,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'addExpiryDate',
    headerName: 'Add expiry date',
    type: fieldType.BOOLEAN,
    width: 120,
    editable: true,
    value: data ? data.beta_partners : null,
    initialValue: false,
    onForm: true,
    validate: Yup.boolean().nullable(),
    hideColumn: true,
    onDetailCard: false,
  },
  {
    field: 'expiry_date',
    headerName: 'Expiry date',
    type: fieldType.DATE,
    width: 240,
    editable: true,
    onForm: true,
    value: data?.expiry_date
      ? formatToUTCDate(new Date(data.expiry_date))
      : null,
    initialValue: formatToUTCDate(
      new Date(new Date().setFullYear(new Date().getFullYear() + 4))
    ),
    validate: Yup.string().default(
      formatToUTCDate(
        new Date(new Date().setFullYear(new Date().getFullYear() + 4))
      )
    ),
  },
  {
    field: 'workflow',
    headerName: 'Automata',
    width: 300,
    editable: true,
    onForm: false,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: workflowsUrls.list,
    requestKey: `${data?.id}-workflow`,
    validate: Yup.mixed(),
    initialValue: data?.workflow
      ? {
          id: data?.workflow,
          label: data?.details?.workflow?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const territoriesMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 170,
    editable: true,
    to: '/territories/',
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 285,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'expiry_date',
    headerName: 'Expiry date',
    type: fieldType.DATE,
    width: 240,
    editable: true,
    onForm: true,
    value: data?.expiry_date
      ? formatToUTCDate(new Date(data.expiry_date))
      : null,
    initialValue: formatToUTCDate(
      new Date(new Date().setFullYear(new Date().getFullYear() + 4))
    ),
    validate: Yup.string().default(
      formatToUTCDate(
        new Date(new Date().setFullYear(new Date().getFullYear() + 4))
      )
    ),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const companiesMeta = ({
  data,
  queryClient,
  nameGridStyle,
  descriptionGridStyle,
  phoneGridStyle,
  websiteGridStyle,
  colorGridStyle,
  newsUrlGridStyle,
  staffUrlGridStyle,
  betaPartnersGridStyle,
  keywordsGridStyle,
  branchOfGridStyle,
  address1GridStyle,
  address2GridStyle,
  cityGridStyle,
  countryGridStyle,
  notesGridStyle,
  tagsGridStyle,
  createdAtGridStyle,
  stateGridStyle,
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 170,
    type: fieldType.LINK,
    to: '/companies/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
    gridStyle: nameGridStyle ?? undefined,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 285,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
    gridStyle: descriptionGridStyle ?? undefined,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    onForm: true,
    editable: true,
    value: data ? data.phone : null,
    initialValue: '',
    width: 150,
    validate: Yup.string(),
    type: 'phone',
    microservice: microservices.CRM.name,
    model: microservices.CRM.models.companies,
    gridStyle: phoneGridStyle ?? undefined,
  },
  {
    field: 'website',
    headerName: 'Website',
    onForm: true,
    editable: true,
    value: data ? data.website : null,
    initialValue: '',
    width: 200,
    validate: Yup.string().url(),
    getHref: (r) => r.website,
    linkInNewTab: true,
    gridStyle: websiteGridStyle ?? undefined,
  },
  {
    field: '_color',
    headerName: 'Colour',
    width: 120,
    editable: false,
    value: data ? data._color : null,
    initialValue: '',
    onForm: false,
    validate: Yup.string(),
    onDetailCard: false,
    gridStyle: colorGridStyle ?? undefined,
  },
  {
    field: 'news_url',
    headerName: 'News Url',
    onForm: true,
    editable: true,
    value: data ? data.news_url : null,
    initialValue: '',
    width: 200,
    getHref: (r) => r.news_url,
    linkInNewTab: true,
    validate: Yup.string().url(),
    gridStyle: newsUrlGridStyle ?? undefined,
  },
  {
    field: 'staff_url',
    headerName: 'Staff Url',
    onForm: true,
    editable: true,
    value: data ? data.staff_url : null,
    initialValue: '',
    width: 200,
    getHref: (r) => r.staff_url,
    linkInNewTab: true,
    validate: Yup.string().url(),
    gridStyle: staffUrlGridStyle ?? undefined,
  },
  {
    field: 'beta_partners',
    headerName: 'Beta partner',
    type: fieldType.BOOLEAN,
    width: 120,
    editable: true,
    value: data ? data.beta_partners : null,
    initialValue: false,
    onForm: true,
    validate: Yup.boolean().nullable(),
    gridStyle: betaPartnersGridStyle ?? undefined,
  },
  {
    field: 'keywords',
    headerName: 'Keywords',
    width: 220,
    editable: true,
    value: data ? data.keywords : null,
    initialValue: '',
    hideColumn: true,
    validate: Yup.string(),
    gridStyle: keywordsGridStyle ?? undefined,
  },
  {
    field: 'branch_of',
    headerName: 'Branch Of',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: companiesUrls.list,
    requestKey: `${data?.id}-branch-of-company`,
    validate: Yup.mixed(),
    initialValue: data?.branch_of
      ? {
          id: data?.branch_of,
          label: data?.details?.branch_of?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-branch-of-company`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.companies,
      }),
    }),
    gridStyle: branchOfGridStyle ?? undefined,
  },
  {
    field: 'address1',
    headerName: 'Address 1',
    width: 220,
    editable: true,
    value: data ? data.address1 : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
    gridStyle: address1GridStyle ?? undefined,
  },
  {
    field: 'address2',
    headerName: 'Address 2',
    width: 220,
    editable: true,
    value: data ? data.address2 : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
    gridStyle: address2GridStyle ?? undefined,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 220,
    editable: true,
    value: data ? data.city : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
    gridStyle: cityGridStyle ?? undefined,
  },
  {
    field: 'state',
    headerName: 'State/County',
    width: 220,
    editable: true,
    value: data ? data.state : null,
    initialValue: '',
    onForm: false,
    validate: Yup.string(),
    gridStyle: stateGridStyle ?? undefined,
  },
  {
    field: 'zip',
    headerName: 'ZIP/Post Code',
    width: 220,
    editable: true,
    value: data ? data.zip : null,
    initialValue: '',
    onForm: false,
    validate: Yup.string(),
    gridStyle: stateGridStyle ?? undefined,
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: countriesUrls.list,
    requestKey: `${data?.id}-country-company`,
    validate: Yup.mixed(),
    initialValue: data?.country
      ? {
          id: data?.country,
          label: data?.details?.country?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-country-company`]);
      },
      Form: getDropdownForm({
        microservice: microservices.LIST.name,
        model: microservices.LIST.models.country,
      }),
    }),
    gridStyle: countryGridStyle ?? undefined,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 220,
    editable: true,
    value: data ? data.notes : null,
    initialValue: '',
    hideColumn: true,
    validate: Yup.string(),
    gridStyle: notesGridStyle ?? undefined,
  },
  {
    field: '_tags',
    headerName: 'Tags',
    width: 220,
    editable: true,
    value: data ? data._tags : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
    gridStyle: tagsGridStyle ?? undefined,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    gridStyle: createdAtGridStyle ?? undefined,
  },
];

export const personsMeta = ({
  data,
  queryClient,
  inMarketingList,
  firstNameGridStyle,
  middleNameGridStyle,
  lastNameGridStyle,
  preferredNameGridStyle,
  usernameGridStyle,
  emailGridStyle,
  dobGridStyle,
  homePhoneGridStyle,
  personalMobileGridStyle,
  address1GridStyle,
  address2GridStyle,
  cityGridStyle,
  countryGridStyle,
  companyOwnerGridStyle,
  sourceGridStyle,
  sourceNotesGridStyle,
  tagsGridStyle,
  colorGridStyle,
  notesGridStyle,
  createdAtGridStyle,
  stateGridStyle,
  notesOnDetailCard,
  notesCondensed,
} = {}) => [
  {
    field: 'first_name',
    headerName: 'First name',
    onForm: true,
    width: 140,
    type: fieldType.LINK,
    getHref: (r) => `/persons/${inMarketingList ? r?.personId : r?.id ?? ''}`,
    editable: true,
    value: data ? data.first_name : null,
    initialValue: '',
    validate: Yup.string().max(100).required(),
    gridStyle: firstNameGridStyle ?? undefined,
  },
  {
    field: 'middle_name',
    headerName: 'Middle name',
    hideColumn: true,
    width: 240,
    editable: true,
    value: data ? data.middle_name : null,
    initialValue: '',
    validate: Yup.string().max(100),
    gridStyle: middleNameGridStyle ?? undefined,
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    width: 140,
    editable: true,
    onForm: true,
    value: data ? data.last_name : null,
    initialValue: '',
    validate: Yup.string().max(100),
    gridStyle: lastNameGridStyle ?? undefined,
  },
  {
    field: 'preferred_name',
    headerName: 'Preferred name',
    hideColumn: true,
    width: 240,
    editable: true,
    value: data ? data.preferred_name : null,
    initialValue: '',
    validate: Yup.string().max(100),
    gridStyle: preferredNameGridStyle ?? undefined,
  },
  {
    field: 'username',
    headerName: 'Username',
    hideColumn: true,
    width: 240,
    editable: true,
    value: data ? data.username : null,
    initialValue: '',
    validate: Yup.string().max(100),
    gridStyle: usernameGridStyle ?? undefined,
  },
  {
    field: 'email',
    headerName: 'Email',
    onForm: true,
    editable: true,
    value: data ? data.email : null,
    initialValue: '',
    width: 200,
    validate: Yup.string().email().required(),
    gridStyle: emailGridStyle ?? undefined,
  },
  {
    field: 'processed',
    headerName: 'Processed?',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: false,
    value: data ? data.processed : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
    hideColumn: !inMarketingList,
    onDetailCard: !!inMarketingList,
  },
  {
    field: 'dob',
    headerName: 'DOB',
    type: fieldType.DATE,
    hideColumn: true,
    width: 240,
    editable: true,
    value: data && data.dob ? formatToUTCDate(new Date(data.dob)) : null,
    initialValue: formatToUTCDate(new Date()),
    validate: Yup.string().default(formatToUTCDate(new Date())),
    gridStyle: dobGridStyle ?? undefined,
  },
  {
    field: 'home_phone',
    headerName: 'Home Phone',
    type: 'phone',
    hideColumn: true,
    width: 240,
    editable: true,
    value: data ? data.home_phone : null,
    initialValue: '',
    validate: Yup.string(),
    gridStyle: homePhoneGridStyle ?? undefined,
  },
  {
    field: 'personal_mobile',
    headerName: 'Personal Mobile',
    onForm: true,
    editable: true,
    value: data ? data.personal_mobile : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
    type: 'phone',
    microservice: microservices.CRM.name,
    model: microservices.CRM.models.persons,
    gridStyle: personalMobileGridStyle ?? undefined,
  },
  {
    field: 'address1',
    headerName: 'Address1',
    hideColumn: true,
    width: 240,
    editable: true,
    value: data ? data.address1 : null,
    initialValue: '',
    validate: Yup.string(),
    gridStyle: address1GridStyle ?? undefined,
  },
  {
    field: 'address2',
    headerName: 'Address2',
    hideColumn: true,
    width: 240,
    editable: true,
    value: data ? data.address2 : null,
    initialValue: '',
    validate: Yup.string(),
    gridStyle: address2GridStyle ?? undefined,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 140,
    editable: true,
    onForm: true,
    value: data ? data.city : null,
    initialValue: '',
    validate: Yup.string(),
    gridStyle: cityGridStyle ?? undefined,
  },
  {
    field: 'state',
    headerName: 'State/County',
    width: 220,
    editable: true,
    value: data ? data.state : null,
    initialValue: '',
    onForm: false,
    validate: Yup.string(),
    gridStyle: stateGridStyle ?? undefined,
  },
  {
    field: 'zip',
    headerName: 'ZIP/Post Code',
    width: 220,
    editable: true,
    value: data ? data.zip : null,
    initialValue: '',
    onForm: false,
    validate: Yup.string(),
    gridStyle: stateGridStyle ?? undefined,
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 180,
    editable: true,
    onDetailCard: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: countriesUrls.list,
    requestKey: `${data?.id}-countries`,
    validate: Yup.mixed(),
    initialValue: data?.country
      ? {
          id: data?.country,
          label: data?.details?.country?.name,
        }
      : null,
    getDropdownForm: () => ({
      refetch: () => queryClient.invalidateQueries([`${data?.id}-countries`]),
      Form: getDropdownForm({
        microservice: microservices.LIST.name,
        model: microservices.LIST.models.country,
      }),
    }),
    gridStyle: countryGridStyle ?? undefined,
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 140,
    editable: true,
    onForm: true,
    value: data ? data.source : null,
    initialValue: '',
    validate: Yup.string(),
    gridStyle: sourceGridStyle ?? 8,
  },
  {
    field: 'source_notes',
    headerName: 'Source Note',
    width: 140,
    editable: true,
    onForm: true,
    value: data ? data.source_notes : null,
    initialValue: '',
    validate: Yup.string(),
    gridStyle: sourceNotesGridStyle ?? 6,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
    gridStyle: tagsGridStyle ?? 6,
  },
  {
    field: '_color',
    headerName: 'Colour',
    width: 120,
    editable: false,
    value: data ? data._color : null,
    initialValue: '',
    onForm: false,
    validate: Yup.string(),
    onDetailCard: false,
    gridStyle: colorGridStyle ?? undefined,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    type: notesCondensed ? fieldType.MULTILINE_CONDENSED : fieldType.MULTILINE,
    hideColumn: true,
    editable: true,
    value: data ? data.notes : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
    gridStyle: notesGridStyle ?? 12,
    rows: 12,
    onDetailCard: notesOnDetailCard,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    gridStyle: createdAtGridStyle ?? undefined,
  },
];

export const opportunitiesMeta = ({
  data,
  queryClient,
  nameGridStyle,
  descriptionGridStyle,
  companyGridStyle,
  companyContactGridStyle,
  pipelineGridStyle,
  statusGridStyle,
  sentimentGridStyle,
  customerPriorityGridStyle,
  estimatedValueGridStyle,
  estimatedCloseDateGridStyle,
  colorGridStyle,
  probabilityGridStyle,
  dataSourceGridStyle,
  channelGridStyle,
  tagsGridStyle,
  notesGridStyle,
  ownerGridStyle,
  actualValueGridStyle,
  createdAtGridStyle,
  colorOnDetailCard,
  statusOnDetailCard,
} = {}) => [
  {
    field: 'name',
    gridStyle: nameGridStyle ?? undefined,
    headerName: 'Name',
    type: fieldType.TEXT,
    onForm: true,
    width: 200,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    to: '/opportunities/',
    validate: Yup.string().max(50).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    type: fieldType.MULTILINE,
    onForm: true,
    width: 200,
    editable: true,
    gridStyle: descriptionGridStyle ?? 8,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 190,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: companiesUrls.list,
    requestKey: `${data?.id}-opportunity-company`,
    validate: Yup.mixed(),
    gridStyle: companyGridStyle ?? undefined,
    initialValue: data?.company
      ? {
          id: data?.company,
          label: data?.details?.company?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-opportunity-company`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.companies,
      }),
    }),
  },
  {
    field: 'company_contact',
    headerName: 'Company Contact',
    width: 220,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: companyContactUrls.list,
    requestKey: `${data?.id}-opportunity-company-contacts`,
    validate: Yup.mixed(),
    gridStyle: companyContactGridStyle ?? undefined,
    initialValue: data?.person_detail?.person
      ? {
          id: data?.company_contact,
          label: `${data?.person_detail?.person?.first_name ?? ''} ${
            data?.person_detail?.person?.last_name ?? ''
          }`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.details?.person?.first_name ?? ''} ${
        row?.details?.person?.last_name ?? ''
      }`,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([
          `${data?.id}-opportunity-company-contacts`,
        ]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.companyContacts,
      }),
    }),
    urlParams: `&company_id=${data?.company}`,
  },
  {
    field: 'pipeline',
    headerName: 'Pipeline',
    width: 170,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pipelinesUrls.list,
    requestKey: `${data?.id}-opportunity-pipelines`,
    validate: Yup.mixed(),
    gridStyle: pipelineGridStyle ?? undefined,
    customOnChange: () =>
      queryClient.invalidateQueries([
        `${data?.id}-opportunity-pipeline-stages`,
      ]),
    initialValue: data?.details?.pipeline
      ? {
          id: data?.pipeline,
          label: data?.details?.pipeline?.name ?? '',
        }
      : null,
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-opportunity-pipelines`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.pipeline,
      }),
    }),
  },
  {
    field: 'status',
    headerName: 'Pipeline Stage',
    width: 280,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pipelineStagesUrls.list,
    requestKey: `${data?.id}-opportunity-pipeline-stages`,
    // requestKeyOptions: [`opportunity-detailCard-pipeline-stages`],
    validate: Yup.mixed(),
    gridStyle: statusGridStyle ?? undefined,
    onDetailCard: statusOnDetailCard ?? true,
    initialValue: data?.details?.status
      ? {
          id: data?.status,
          label: `${Number(data?.details?.status?.order).toFixed(2)} ${
            data?.details?.status?.stage ?? ''
          }`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${Number(row?.order).toFixed(2)} ${row?.stage}`,
    }),
    urlParams: data?.pipeline
      ? `&pipeline=${data?.pipeline}&ordering=order`
      : '',
    getUrlParams: (data) =>
      data?.pipeline ? `&pipeline=${data?.pipeline?.id}&ordering=order` : '',
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([
          `${data?.id}-opportunity-pipelines-stage`,
        ]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.pipelineStage,
      }),
    }),
  },
  {
    field: 'sentiment',
    gridStyle: sentimentGridStyle ?? undefined,
    headerName: 'Sentiment',
    type: fieldType.SELECT,
    editable: true,
    width: 120,
    onForm: true,
    options: opportunitySentiments,
    initialValue: opportunitySentiments[0].label,
    value: data ? data.sentiment : null,
    validate: Yup.string().required(),
  },
  {
    field: 'customer_priority',
    gridStyle: customerPriorityGridStyle ?? undefined,
    headerName: `Customer's priority`,
    type: fieldType.SELECT,
    editable: true,
    width: 120,
    onForm: true,
    options: opportunityPriority,
    initialValue: opportunityPriority[0].label,
    value: data ? data.customer_priority : null,
    validate: Yup.string().required(),
  },
  {
    field: 'estimated_value',
    gridStyle: estimatedValueGridStyle ?? 2,
    headerName: 'Estimated Value',
    type: fieldType.NUMBER,
    onForm: true,
    editable: true,
    value: data?.estimated_value ?? 0,
    initialValue: 0,
    width: 140,
    validate: Yup.number(),
  },
  {
    field: 'estimated_close_date',
    gridStyle: estimatedCloseDateGridStyle ?? 2,
    headerName: 'Estimated Close Date',
    type: fieldType.DATE,
    width: 180,
    editable: true,
    onForm: true,
    value: data ? formatToUTCDate(new Date(data.estimated_close_date)) : null,
    initialValue: formatToUTCDate(new Date()),
    validate: Yup.string().default(formatToUTCDate(new Date())),
  },
  {
    field: '_color',
    gridStyle: colorGridStyle ?? undefined,
    headerName: 'Colour',
    width: 120,
    editable: false,
    hideColumn: true,
    value: data ? data._color : null,
    initialValue: '',
    onForm: false,
    validate: Yup.string(),
    onDetailCard: colorOnDetailCard ?? true,
  },
  {
    field: 'probability',
    gridStyle: probabilityGridStyle ?? 2,
    headerName: 'Probability %',
    onForm: false,
    editable: false,
    value: data?.details?.status?.confidence ?? 0,
    initialValue: 100,
    width: 120,
    // hideColumn: true,
    validate: Yup.number()
      .integer('must be a number')
      .positive('must be positive'),
  },
  {
    field: 'data_source',
    gridStyle: dataSourceGridStyle ?? 6,
    headerName: 'Data source',
    onForm: true,
    editable: true,
    value: data ? data.data_source : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
    hideColumn: true,
    type: fieldType.MULTILINE,
  },
  {
    field: 'channel',
    gridStyle: channelGridStyle ?? undefined,
    headerName: 'Channel',
    width: 390,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: channelsUrls.list,
    requestKey: `${data?.id}-opportunity-channel`,
    validate: Yup.mixed(),
    initialValue:
      data?.channel && data?.details?.channel
        ? {
            id: data?.channel,
            label: data.details.channel?.name ?? '',
          }
        : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
  },
  {
    field: 'tags',
    gridStyle: tagsGridStyle ?? undefined,
    headerName: 'Tags',
    type: fieldType.MULTILINE,
    onForm: true,
    width: 200,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'notes',
    gridStyle: notesGridStyle ?? undefined,
    headerName: 'Notes',
    type: fieldType.MULTILINE,
    onForm: true,
    width: 200,
    editable: true,
    value: data ? data.notes : null,
    initialValue: '',
    validate: Yup.string(),
    hideColumn: true,
    onDetailCard: false,
  },
  {
    field: 'owner',
    gridStyle: ownerGridStyle ?? undefined,
    headerName: 'Owner',
    width: 170,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    urlParams: `&department_name=Sales&ordering=created_at`,
    requestKey: `${data?.id}-opportunity-owner`,
    validate: Yup.mixed(),
    initialValue: data?.owner
      ? {
          id: data?.owner,
          label: data?.details?.owner?.work_email ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
  },
  {
    field: 'actual_value',
    gridStyle: actualValueGridStyle ?? undefined,
    headerName: 'Actual Value',
    type: fieldType.MULTILINE,
    onForm: true,
    width: 200,
    editable: true,
    value: data ? data.actual_value : null,
    initialValue: 0,
    validate: Yup.number(),
  },
  {
    field: 'created_at',
    gridStyle: createdAtGridStyle ?? undefined,
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const companyOfficeMeta = ({
  data,
  countries = [],
  fetchingCountries,
  setCountryTerm,
  refetchCountry,
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    width: 180,
    type: fieldType.LINK,
    to: '/company-offices/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(50).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 340,
    validate: Yup.string(),
  },
  {
    field: 'business_phone',
    headerName: 'Phone',
    type: 'phone',
    onAccordionForm: true,
    editable: true,
    value: data ? data.business_phone : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
  },
  {
    field: 'url',
    headerName: 'Url',
    editable: true,
    value: data ? data.url : null,
    initialValue: '',
    width: 200,
    hideColumn: true,
    validate: Yup.string().url(),
  },
  {
    field: 'country',
    headerName: 'Country',
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE,
    setSearchTerm: (value) => setCountryTerm(value),
    options: countries,
    value:
      data && data.country && data.details && data.details.country
        ? {
            id: data.country,
            label: data.details.country.name,
          }
        : null,
    isLoading: fetchingCountries,
    validate: Yup.string(),
    width: 140,
    getDropdownForm: () => ({
      refetch: refetchCountry,
      Form: getDropdownForm({
        microservice: microservices.LIST.name,
        model: microservices.LIST.models.country,
      }),
    }),
    editable: true,
  },
  {
    field: 'city',
    headerName: 'City',
    editable: true,
    onAccordionForm: true,
    value: data ? data.city : null,
    initialValue: '',
    width: 260,
    validate: Yup.string(),
  },
  {
    field: 'address1',
    headerName: 'Address1',
    editable: true,
    value: data ? data.address1 : null,
    initialValue: '',
    width: 200,
    hideColumn: true,
    validate: Yup.string(),
  },
  {
    field: 'address2',
    headerName: 'Address2',
    editable: true,
    value: data ? data.address2 : null,
    initialValue: '',
    width: 200,
    hideColumn: true,
    validate: Yup.string(),
  },
  {
    field: 'fax',
    headerName: 'Fax',
    editable: true,
    value: data ? data.fax : null,
    initialValue: '',
    width: 200,
    hideColumn: true,
    validate: Yup.string().max(30),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    editable: true,
    onAccordionForm: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    editable: true,
    value: data ? data.notes : null,
    initialValue: '',
    width: 200,
    hideColumn: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const historyMeta = ({ data } = {}) => [
  {
    field: 'notes',
    headerName: 'Notes',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 390,
    type: fieldType.MULTILINE,
    validate: Yup.string().required(),
  },
  {
    field: 'history',
    headerName: 'History',
    width: 1075,
    editable: true,
    type: fieldType.UPLOAD,
    value: data && data.history,
    initialValue: '',
    validate: Yup.string().url(),
    getHref: (r) => r.history,
    linkInNewTab: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const companyContactMeta = ({ data, showCompany, queryClient } = {}) => [
  {
    field: 'person',
    headerName: 'Person',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    getHref: (r) => `/persons/${r?.personId}`,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-company-contact-person`,
    validate: Yup.mixed(),
    initialValue: data?.person
      ? {
          id: data?.person,
          label: data?.details?.person?.email,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.first_name || 'Unamed'} ${row?.last_name || ''}`,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-company-contact-person`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.persons,
      }),
    }),
  },
  {
    field: 'companyId',
    headerName: 'Company',
    type: fieldType.AUTOCOMPLETE,
    setSearchTerm: () => null,
    options: [],
    value:
      data?.company_id && data?.details?.company_id
        ? {
            id: data.company_id,
            label: data.details.company_id.name,
          }
        : null,
    isLoading: false,
    validate: Yup.mixed(),
    width: 240,
    editable: false,
    hideColumn: !showCompany,
    onDetailCard: !showCompany,
  },
  {
    field: 'work_email',
    headerName: 'Work Email',
    onAccordionForm: true,
    editable: true,
    value: data ? data.work_email : null,
    initialValue: '',
    width: 200,
    validate: Yup.string().email(),
  },
  {
    field: 'work_phone',
    headerName: 'Work Phone',
    type: 'phone',
    onAccordionForm: true,
    width: 140,
    editable: true,
    value: data ? data.work_phone : null,
    initialValue: '',
    validate: Yup.string().max(25).nullable(),
  },
  {
    field: 'work_mobile',
    headerName: 'Work Mobile',
    onAccordionForm: true,
    editable: true,
    value: data ? data.work_mobile : null,
    initialValue: '',
    width: 140,
    validate: Yup.string().max(25).nullable(),
  },
  {
    field: 'job_title',
    headerName: 'Job Title',
    onAccordionForm: true,
    editable: true,
    value: data ? data.job_title : null,
    initialValue: '',
    width: 260,
    validate: Yup.string(),
  },
  {
    field: 'start_date',
    headerName: 'Started',
    type: fieldType.DATE,
    width: 120,
    editable: true,
    onAccordionForm: true,
    value: data ? formatToUTCDate(new Date(data.start_date)) : null,
    initialValue: formatToUTCDate(new Date()),
    validate: Yup.string().default(formatToUTCDate(new Date())),
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    type: fieldType.DATE,
    width: 120,
    editable: true,
    onAccordionForm: true,
    value: data ? formatToUTCDate(new Date(data.end_date)) : null,
    initialValue: null,
    validate: Yup.string().nullable(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const importLogMeta = (data) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: false,
    width: 170,
    type: fieldType.LINK,
    to: 'crm-import-error-logs/',
    editable: false,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'model',
    headerName: 'Model',
    onForm: false,
    editable: false,
    value: data ? data.model : null,
    width: 170,
    validate: Yup.string().required(),
  },
  {
    field: 'imported',
    headerName: 'Imported',
    onForm: false,
    editable: false,
    value: data ? data.imported : null,
    width: 120,
    validate: Yup.number().required(),
  },
  {
    field: 'failed',
    headerName: 'Failed',
    onForm: false,
    editable: false,
    value: data ? data.failed : null,
    width: 120,
    validate: Yup.number().required(),
  },
  {
    field: 'finished',
    headerName: 'Finished',
    onForm: false,
    editable: false,
    value: data ? data.finished : null,
    width: 120,
    validate: Yup.number().required(),
  },
  {
    field: 'started_at',
    headerName: 'Started At',
    type: fieldType.DATE_TIME,
    editable: false,
    onForm: false,
    width: 170,
    value: data ? formatToUTCDateTime(new Date(data.started_at)) : null,
    validate: Yup.string().required(),
  },
  {
    field: 'finished_at',
    headerName: 'Finished At',
    type: fieldType.DATE_TIME,
    editable: false,
    onForm: false,
    width: 170,
    value: data ? formatToUTCDateTime(new Date(data.finished_at)) : null,
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const importErrorLogMeta = (data) => [
  {
    field: 'row',
    headerName: 'Row',
    onForm: false,
    width: 100,
    editable: false,
    value: data ? data.row : null,
    validate: Yup.string().required(),
  },
  {
    field: 'field',
    headerName: 'Field',
    onForm: false,
    width: 200,
    editable: false,
    value: data ? data.field : null,
    validate: Yup.string().required(),
  },
  {
    field: 'error',
    headerName: 'Error',
    onForm: false,
    width: 600,
    editable: false,
    value: data ? data.error : null,
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const personSocialMediaMeta = ({ data, queryClient } = {}) => [
  {
    field: 'social_media',
    headerName: 'Social Media',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: socialMediaTypesUrls.list,
    requestKey: `${data?.id}-person-social-media`,
    validate: Yup.mixed(),
    initialValue: data?.social_media
      ? {
          id: data?.social_media,
          label: data?.details?.social_media?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-person-social-media`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.socialMediaTypes,
      }),
    }),
  },
  {
    field: 'username',
    headerName: 'Username',
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.username : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'url',
    headerName: 'Url',
    onAccordionForm: true,
    width: 560,
    editable: true,
    value: data ? data.url : null,
    initialValue: '',
    validate: Yup.string().url().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const clientsMeta = ({ data, queryClient } = {}) => [
  {
    field: 'company_contact',
    headerName: 'Company Contact',
    width: 300,
    editable: true,
    onForm: true,
    to: '/clients/',
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: companyContactUrls.list,
    requestKey: `${data?.id}-client-company-contact`,
    validate: Yup.mixed(),
    initialValue: data?.company_contact
      ? {
          id: data?.company_contact,
          label: data?.person_detail?.person?.email,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.details?.person?.email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-client-company-contact`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.companyContacts,
      }),
    }),
  },
  {
    field: 'opportunity',
    headerName: 'Opportunity',
    width: 300,
    editable: true,
    onForm: true,
    to: '/clients/',
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: opportunitiesUrls.list,
    requestKey: `${data?.id}-client-opportunity`,
    validate: Yup.mixed(),
    initialValue: data?.opportunity
      ? {
          id: data?.opportunity,
          label: data?.details?.opportunity?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-client-opportunity`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.opportunities,
      }),
    }),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 220,
    editable: true,
    value: data ? data.notes : '',
    initialValue: '',
    hideColumn: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const opportunityInfluencerMeta = ({
  data,
  queryClient,
  companyContact,
  role,
} = {}) => [
  {
    field: 'company_contact',
    headerName: 'Company Contact',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: companyContactUrls.list,
    requestKey: `${data?.id}-opportunity-influencer-company-contact`,
    validate: Yup.mixed(),
    initialValue: companyContact
      ? companyContact
      : data?.company_contact
      ? {
          id: data?.company_contact,
          label: data?.person_detail?.person?.first_name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.details?.person?.email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([
          `${data?.id}-opportunity-influencer-company-contact`,
        ]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.companyContacts,
      }),
    }),
  },
  {
    field: 'role',
    headerName: 'Role',
    type: fieldType.TEXT,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.role : null,
    initialValue: role ? role : '',
    validate: Yup.string().max(10).required(),
  },
  {
    field: 'desire_for_company',
    headerName: 'Desire for Company',
    type: fieldType.TEXT,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.desire_for_company : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'desire_for_self',
    headerName: 'Desire for Self',
    type: fieldType.TEXT,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.desire_for_self : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'rating',
    headerName: 'Rating',
    type: fieldType.SELECT,
    editable: true,
    width: 120,
    onAccordionForm: true,
    options: opportunityInfluencerRatings,
    initialValue: opportunityInfluencerRatings[3].label,
    value: data ? data.rating : null,
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const socialMediaTypeMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 890,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const opportunityProductsMeta = ({ data } = {}) => [
  {
    field: 'product_variant',
    headerName: 'Product Variant',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: productVariantsUrls.list,
    requestKey: `${data?.id}-opportunity-product-variant`,
    validate: Yup.mixed(),
    initialValue: data?.product_variant
      ? {
          id: data?.product_variant,
          label: data?.details?.product_variant?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    onAccordionForm: true,
    width: 250,
    editable: true,
    initialValue: 1,
    validate: Yup.number().integer().required(),
  },
  {
    field: 'estimated_value',
    headerName: 'Estimated Value',
    onAccordionForm: true,
    editable: true,
    initialValue: 0,
    width: 250,
    validate: Yup.number().integer().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const relationshipMeta = ({ data, inPersonDetail } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: !inPersonDetail,
    width: 350,
    getHref: inPersonDetail
      ? (row) => `/person-relationships/${row?.id}`
      : undefined,
    editable: !inPersonDetail,
    value: data?.name ?? data?.details?.relationship?.name ?? null,
    initialValue: '',
    validate: inPersonDetail
      ? Yup.string().nullable()
      : Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: !inPersonDetail,
    editable: true,
    value: data?.description ?? null,
    initialValue: '',
    width: 790,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    hideColumn: inPersonDetail,
    onDetailCard: !inPersonDetail,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const personRelationshipMeta = ({ data } = {}) => [
  {
    field: 'relationship',
    headerName: 'Relationship',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: relationshipsUrls.list,
    requestKey: `${data?.id}-relationship`,
    validate: Yup.mixed(),
    initialValue: data?.relationship
      ? {
          id: data?.relationship,
          label: data?.details?.relationship?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const pipelineMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.LINK,
    to: '/pipelines/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 890,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const pipelineStageMeta = ({ data, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 90,
    editable: true,
    value: data ? data.order : null,
    initialValue: nextOrder ? nextOrder + 1 : 0,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'stage',
    headerName: 'Name',
    onAccordionForm: true,
    width: 250,
    editable: true,
    value: data ? data.stage : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 290,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'conversion',
    headerName: 'Conversion %',
    width: 150,
    editable: true,
    value: data ? data.conversion : null,
    initialValue: 0,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'confidence',
    headerName: 'Confidence %',
    width: 150,
    editable: true,
    value: data ? data.confidence : null,
    initialValue: 0,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'immediate_next_action',
    headerName: 'Immediate Next Action',
    onAccordionForm: true,
    editable: true,
    value: data ? data.immediate_next_action : null,
    initialValue: '',
    width: 290,
    type: fieldType.MULTILINE,
    validate: Yup.string().max(200),
  },
  {
    field: 'rotting_days',
    headerName: 'Rotting Days',
    width: 150,
    editable: true,
    value: data ? data.rotting_days : null,
    initialValue: 0,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const companySICMeta = ({ data } = {}) => [
  {
    field: 'sic_code',
    headerName: 'SIC Code',
    onAccordionForm: true,
    width: 590,
    editable: true,
    value: data ? data.sic_code : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const companySocialMediaMeta = ({ queryClient, data } = {}) => [
  {
    field: 'social_media',
    headerName: 'Social Media',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: socialMediaTypesUrls.list,
    requestKey: `${data?.id}-company-social-media`,
    validate: Yup.mixed(),
    initialValue: data?.social_media
      ? {
          id: data?.social_media,
          label: data?.details?.social_media?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-company-social-media`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.socialMediaTypes,
      }),
    }),
  },
  {
    field: 'url',
    headerName: 'Url',
    onAccordionForm: true,
    width: 350,
    editable: true,
    initialValue: '',
    validate: Yup.string().url().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const opportunityDataNeedMeta = () => [
  {
    field: 'info_needed',
    headerName: 'Info Needed',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 390,
    type: fieldType.MULTILINE,
    validate: Yup.string().required(),
  },
  {
    field: 'who_from',
    headerName: 'Who From',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 390,
    type: fieldType.MULTILINE,
    validate: Yup.string().required(),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 390,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const opportunityActionPlanMeta = () => [
  {
    field: 'what',
    headerName: 'What',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 390,
    type: fieldType.MULTILINE,
    validate: Yup.string().required(),
  },
  {
    field: 'who',
    headerName: 'Who',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 390,
    type: fieldType.MULTILINE,
    validate: Yup.string().required(),
  },
  {
    field: 'when',
    headerName: 'When',
    type: fieldType.DATE,
    width: 240,
    editable: true,
    onAccordionForm: true,
    initialValue: formatToUTCDate(new Date()),
    validate: Yup.string().default(formatToUTCDate(new Date())),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const personHistoryMeta = ({ data } = {}) => [
  {
    field: 'notes',
    headerName: 'Notes',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 390,
    type: fieldType.MULTILINE,
    validate: Yup.string().required(),
  },
  {
    field: 'history',
    headerName: 'History',
    width: 1075,
    editable: true,
    type: fieldType.UPLOAD,
    value: data && data.history,
    initialValue: '',
    validate: Yup.string().url(),
    getHref: (r) => r.history,
    linkInNewTab: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const opportunityHistoryMeta = ({ data } = {}) => [
  {
    field: 'notes',
    headerName: 'Notes',
    to: '/opportunity-histories/',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 550,
    gridStyle: 12,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    value: data && data.notes,
  },
  {
    field: 'url',
    headerName: 'Url',
    width: 220,
    editable: true,
    type: fieldType.UPLOAD,
    value: data && data.url,
    initialValue: '',
    validate: Yup.string().url(),
    getHref: (r) => r.url,
    linkInNewTab: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const clientHistoryMeta = ({ data } = {}) => [
  {
    field: 'url',
    headerName: 'Url',
    width: 500,
    editable: true,
    type: fieldType.UPLOAD,
    value: data && data.url,
    initialValue: '',
    validate: Yup.string().url(),
    getHref: (r) => r.url,
    linkInNewTab: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const digitalDocColumns = () => [
  {
    field: 'signed_pdf',
    headerName: 'Signed Pdf',
    width: 600,
    // getHref: (r) => `/recipient/${r.id}`,
    editable: false,
    validate: Yup.string(),
  },
  {
    field: 'datetime_sign',
    headerName: 'Signature Time',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
];

export const formColumns = () => [
  {
    field: 'response',
    headerName: 'Response',
    width: 200,
    editable: false,
    // getHref: (r) => `/form-answers/${r.id}`,
    validate: Yup.string(),
  },
  {
    field: 'form',
    headerName: 'Form',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
  {
    field: 'responseTime',
    headerName: 'Response Time',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
];

export const opportunitiesColumns = () => [
  {
    field: 'opportunity',
    headerName: 'Opportunity',
    width: 200,
    editable: false,
    getHref: (r) => `/opportunities/${r.id}`,
    validate: Yup.string(),
  },
  {
    field: 'companyContact',
    headerName: 'Company contact',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
  {
    field: 'stage',
    headerName: 'Stage',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
  {
    field: 'EstimateValue',
    headerName: 'Estimate value',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
  {
    type: fieldType.MULTILINE,
    field: 'EstimateCloseDate',
    headerName: 'Estimate close date',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
];

export const calendarColumns = () => [
  {
    field: 'event',
    headerName: 'Event',
    width: 300,
    editable: false,
    // getHref: (r) => `/form-answers/${r.id}`,
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    editable: false,
    validate: Yup.string(),
  },
  {
    field: 'start_date_time',
    headerName: 'Start time',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
  {
    field: 'end_date_time',
    headerName: 'End time',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
];

export const notificationsColumns = () => [
  {
    field: 'subject',
    headerName: 'Subject',
    width: 400,
    editable: false,
    // getHref: (r) => `/all-emails/${r.id}?history=true`,
    validate: Yup.string(),
  },
  {
    field: 'method',
    headerName: 'Method',
    width: 100,
    editable: false,
    validate: Yup.string(),
  },
  {
    field: 'sent_date_time',
    headerName: 'Sent Date Time',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
  {
    field: 'from_email',
    headerName: 'From Email',
    width: 200,
    editable: false,
    validate: Yup.string(),
  },
];

export const inasMeta = ({ data, dataPanel = false } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    to: '/inas/',
    linkInNewTab: dataPanel,
    width: 180,
    validate: Yup.string(),
    value: data && data.name,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 390,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    value: data && data.ina_notes,
  },
  {
    field: 'reminder_start_datetime',
    headerName: 'Reminder Time',
    width: 180,
    value: data?.details
      ? formatToUTCDateTime(new Date(data?.details?.event?.start_date_time))
      : null,
    onAccordionForm: true,
    editable: true,
    type: fieldType.DATE_TIME,
  },
  {
    field: 'do_what',
    headerName: 'Do What',
    width: 180,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: actionsUrls.list,
    requestKey: `${data?.id}-inas-do-what`,
    validate: Yup.mixed(),
    initialValue: data?.do_what
      ? {
          id: data?.do_what,
          label: data?.details?.do_what?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    width: 180,
    field: 'progress',
    headerName: 'Progress',
    type: fieldType.SELECT,
    editable: true,
    options: progressType,
    initialValue: '',
    value: data ? data.progress : null,
    validate: Yup.string(),
    onAccordionForm: true,
  },

  {
    field: 'responsible',
    headerName: 'Responsible',
    width: 180,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-inas-responsible`,
    validate: Yup.mixed(),
    initialValue: data?.responsible
      ? {
          id: data?.responsible,
          label: data?.details?.responsible?.work_email,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const customerEnquiryStatusMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: true,
    value: data?.description ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const customerEnquiryPurposeMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: true,
    value: data?.description ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const customerEnquiriesMeta = ({ data } = {}) => [
  {
    field: 'first_name',
    headerName: 'First Name',
    to: '/customer-enquiries/',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.first_name : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: false,
    value: data?.last_name ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.phone : null,
    initialValue: '',
    validate: Yup.string().max(30),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: customerEnquiryStatusesUrls.list,
    requestKey: `${data?.id}-customer-enquiries-statuses`,
    validate: Yup.mixed(),
    initialValue: data?.status
      ? {
          id: data?.status,
          label: data?.details?.status?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row.id,
      label: row?.name || 'Unamed',
    }),
  },
  {
    field: 'purpose',
    headerName: 'Purpose',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: customerEnquiryPurposesUrls.list,
    requestKey: `${data?.id}-customer-enquiries-purpose`,
    validate: Yup.mixed(),
    initialValue: data?.purpose
      ? {
          id: data?.purpose,
          label: data?.details?.purpose?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row.id,
      label: row?.name || 'Unamed',
    }),
  },
  {
    field: 'person',
    headerName: 'Person',
    width: 300,
    editable: false,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-customer-enquiries-person`,
    validate: Yup.mixed(),
    initialValue: data?.person
      ? {
          id: data?.person,
          label: data?.details?.person?.email,
        }
      : null,
    renderRow: (row) => ({
      id: row.id,
      label: `${row?.first_name || 'Unamed'} ${row?.last_name || ''} (${
        row?.email || ''
      })`,
    }),
  },
  {
    field: 'email',
    headerName: 'Email',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.email : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'message',
    headerName: 'Message',
    onForm: true,
    editable: true,
    value: data ? data.message : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    gridStyle: 12,
  },
  {
    field: 'source',
    headerName: 'Source',
    onForm: true,
    editable: true,
    value: data ? data.source : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'source_notes',
    headerName: 'Source Note',
    onForm: true,
    editable: true,
    value: data ? data.source_notes : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
