import React from 'react';
import { Box } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { selectUatsBoard, setUats } from '@redux/uatsBoardSlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { selectProfile } from '@redux/profileSlice';
import { pmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { updateRecord } from '@config/functions/requests';
import {
  handleDragEnd,
  getAuthHeader,
  getLastSelectedDropdownOption,
} from '@config/functions/helperFunctions';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';

const {
  uatStagesUrls,
  uatsUrls,
  programsUrls,
  projectsUrls,
  hlrsUrls,
  backlogsUrls,
} = pmUrls;

export default function UATsBoard({ labels, editMode }) {
  const { uats } = useSelector(selectUatsBoard);
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [notify, update] = useToast();
  const initialProgram = getLastSelectedDropdownOption(
    'lastSelectedUATProgram'
  );
  const [program, setProgram] = React.useState(initialProgram);
  const initialProject = getLastSelectedDropdownOption(
    'lastSelectedUATProject'
  );
  const [project, setProject] = React.useState(initialProject);
  const [hlr, setHlr] = React.useState(null);
  const [backlog, setBacklog] = React.useState(null);

  const { data, isLoading } = useRQuery({
    key: ['uats-board', project, hlr, backlog, user.token, user?.actAs],
    url: uatStagesUrls.list(
      `?project=${project && project.id}&hlr=${hlr?.id || ''}&backlog=${
        backlog?.id || ''
      }&order=order&page_size=200`
    ),
    options: { enabled: !!(user.token && project) },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleSaveCardDrag = async ({ newList, removedItem, destId }) => {
    const tId = notify('Saving....', { loading: true });
    dispatch(setUats(newList));
    try {
      await updateRecord({
        url: uatsUrls.detail(removedItem.id),
        values: { status: destId },
        token: user.token,
        actAs: user?.actAs,
      });
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(
        err.response && err.response.data ? err.response.data : err.message
      );
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    if (data) dispatch(setUats(data));
  }, [data, dispatch]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title='UATs'
        prefix='0h2'
        labels={labels}
        editMode={editMode}
        filterOptions={[
          {
            label: 'Program',
            name: 'program',
            requestKey: 'uat-board-programs',
            fetchUrl: programsUrls.list,
            handleChange: (newValue) => {
              setProgram(newValue);
              localStorage.setItem(
                'lastSelectedUATProgram',
                JSON.stringify(newValue)
              );
            },
            initialValue: initialProgram,
          },
          {
            label: 'Project',
            name: 'project',
            requestKey: 'uat-board-projects',
            fetchUrl: projectsUrls.list,
            urlParams: `&program=${program?.id}`,
            handleChange: (newValue) => {
              setProject(newValue);
              localStorage.setItem(
                'lastSelectedUATProject',
                JSON.stringify(newValue)
              );
            },
            show: program ? true : false,
            initialValue: initialProject,
          },
          {
            label: 'Hlr',
            name: 'hlr',
            requestKey: 'uat-board-hlrs',
            fetchUrl: hlrsUrls.list,
            urlParams: `&project=${project?.id}`,
            handleChange: (newValue) => {
              setHlr(newValue);
            },
            show: project ? true : false,
          },
          {
            label: 'Backlog',
            name: 'backlog',
            requestKey: 'uat-board-backlogs',
            fetchUrl: backlogsUrls.list,
            urlParams: `&hlr=${hlr?.id}`,
            handleChange: (newValue) => {
              setBacklog(newValue);
            },
            renderRow: (r) => ({
              id: r.id,
              label: r.i_want,
            }),
            show: hlr ? true : false,
          },
        ]}
      />

      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            <DragDropContext
              onDragEnd={(res) => handleDragEnd(res, uats, handleSaveCardDrag)}
            >
              <KanbanBoardList
                detailLink='acceptance-tests'
                data={uats}
                labels={labels}
                editMode={editMode}
              />
            </DragDropContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}
