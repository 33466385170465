import * as React from 'react';
import { useField } from 'formik';
import TextBox from '@ui/Inputs/TextField';

export default function TextField({
  label,
  type = 'text',
  afterDefaultOnChange = (v) => v,
  multiline,
  customOnChange,
  customOnBlur,
  hideError,
  helperText,
  ...rest
}) {
  const [field, meta] = useField(rest);
  const isError = Boolean(meta.error);

  return (
    <TextBox
      {...rest}
      multiline={multiline}
      type={type}
      size='small'
      label={label}
      error={isError}
      name={field.name}
      value={field.value}
      onBlur={customOnBlur || field.onBlur}
      onChange={(params) => {
        if (customOnChange) {
          customOnChange(params);
        } else {
          field.onChange(params);
        }

        afterDefaultOnChange(params);
      }}
      helperText={hideError || !isError ? helperText ?? null : meta.error}
    />
  );
}
