import {
  faBullhorn,
  faTasks,
  faTable,
  faShoppingCart,
  faKeyboard,
  faUserLock,
  faWarehouse,
  faChalkboardTeacher,
  faHdd,
  faCommentAlt,
  faCalendar,
  faCodeBranch,
  faBell,
  faFileWord,
  faList,
  faComments,
  faPencilAlt,
  faProjectDiagram,
  faThList,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';

export default function getLinks({ roles = [] }) {
  const links = [
    {
      icon: faThList,
      text: 'Kanbans',
      role: 'MS_KANBAN',
      prefix: '0#e',
      dropdown: [
        {
          text: 'Automata',
          href: '/bpa-board',
        },
        {
          text: 'Bugs and Features',
          href: '/bugs-board',
        },
        {
          text: 'Call Lists',
          href: '/call-lists-board',
        },
        {
          text: 'INAs',
          href: '/inas-board',
        },
        {
          text: 'Opportunities',
          href: '/opportunities-board',
        },
        {
          text: 'Projects',
          href: '/tasks-board',
        },
        {
          text: 'Sprints',
          href: '/sprint-tasks-board',
        },
        {
          text: 'UATs',
          href: '/uats-board',
        },
        {
          text: 'Vacancies',
          href: '/vacancies-board',
        },
        {
          text: 'WebChat',
          href: '/rooms-board',
        },
      ],
    },
    {
      icon: faProjectDiagram,
      text: 'Automata',
      role: 'MS_BPA',
      prefix: '06t',
      href: '/workflows',
    },
    {
      icon: faTasks,
      text: 'Project Management',
      role: 'MS_PM',
      prefix: '03a',
      dropdown: [
        {
          text: 'Programs',
          href: '/programs',
        },
        {
          text: 'Resources',
          href: '/resources',
        },
        {
          text: 'Roles',
          href: '/stakeholder-roles',
        },
        {
          text: 'Work Codes',
          href: '/work-codes',
        },
        {
          text: 'Timesheets',
          href: '/time-sheets',
        },
        {
          text: 'Working Time',
          href: '/working-times',
        },
        {
          text: 'Sprint Metas',
          href: '/sprint-metas',
        },
        {
          text: 'Bugs and Features',
          href: '/bugs',
        },
      ],
    },
    {
      icon: faTable,
      text: 'CRM',
      role: 'MS_CRM',
      prefix: '03k',
      dropdown: [
        {
          text: 'Company',
          href: '/companies',
          dropdown: [
            {
              text: 'Company Spin',
              href: '/company-spins',
            },
            {
              text: 'Company History',
              href: '/company-history',
            },
          ],
        },
        {
          text: 'Person',
          href: '/persons',
          dropdown: [
            {
              text: 'Relationships',
              href: '/person-relationships',
            },
            {
              text: 'Person History',
              href: '/person-history',
            },
          ],
        },
        {
          text: 'Opportunity',
          href: '/opportunities',
        },
        {
          text: 'Client',
          href: '/clients',
        },
        {
          text: 'Sales Person',
          href: '/sales-people',
          dropdown: [
            {
              text: 'Call List',
              href: '/call-lists',
            },
            {
              text: 'Sales Person Target',
              href: '/sales-people-targets',
            },
          ],
        },
        {
          text: 'Territory',
          href: '/territories',
        },
        {
          text: 'Customer Enquiry',
          href: '/customer-enquiries',
          dropdown: [
            {
              text: 'Customer Enquiry Status',
              href: '/customer-enquiries-statuses',
            },
            {
              text: 'Customer Enquiry Purpose',
              href: '/customer-enquiries-purposes',
            },
          ],
        },
        {
          text: 'Marketing List',
          href: '/marketing-lists',
        },
        {
          text: 'Admin',
          dropdown: [
            {
              text: 'Action',
              href: '/actions',
            },
            {
              text: 'Activity Kind',
              href: '/activity-kinds',
            },
            {
              text: 'Activity Note',
              href: '/activity-notes',
            },
            {
              text: 'Activity Type',
              href: '/activity-types',
            },
            {
              text: 'Call List Pipeline',
              href: '/call-list-pipelines',
            },
            {
              text: 'Pipeline',
              href: '/pipelines',
            },
            {
              text: 'Opportunity Channel',
              href: '/channels',
            },
            {
              text: 'Social Media Type',
              href: '/social-media-types',
            },
          ],
        },
      ],
    },

    {
      icon: faPencilAlt,
      text: 'CMS',
      role: 'MS_CMS',
      prefix: '0mv',
      unstable: true,
      dropdown: [
        {
          text: 'Subdomains',
          href: '/subdomains',
        },
        {
          text: 'Page Groups',
          href: '/page-groups',
        },
        {
          text: 'Pages',
          href: '/cms-pages',
        },
        {
          text: 'Page Templates',
          href: '/page-templates',
        },
        {
          text: 'Menus',
          href: '/menus',
        },
        {
          text: 'Legacy',
          dropdown: [
            {
              text: 'Pages',
              href: '/cms-page',
            },
          ],
        },
      ],
    },
    {
      icon: faBullhorn,
      text: 'Marketing',
      role: 'MS_MARKETING',
      prefix: '0r3',
      unstable: true,
      dropdown: [
        {
          text: 'Market Segments',
          href: '/market-segments',
        },
        {
          text: 'Social Media',
          dropdown: [
            {
              text: 'Campaigns',
              href: '/social-media-campaigns',
            },
            {
              text: 'Connect Platform',
              href: '/connect-platform',
            },
          ],
        },
        {
          text: 'URL Shortener',
          href: '/links',
        },
        {
          text: 'QRCode',
          href: '/qr-code',
        },
      ],
    },
    {
      icon: faCalendar,
      text: 'Calendar',
      role: 'MS_CALENDAR',
      prefix: '0nw',
      dropdown: [
        {
          text: 'Calendars',
          dropdown: [],
        },
        {
          text: 'Connect Calendar',
          href: '/connect-calendar',
        },
        {
          text: 'Slot Links',
          href: '/slot-links',
        },
        {
          text: 'Batch Reminders',
          href: '/batch-reminders',
        },
      ],
    },
    {
      icon: faUserLock,
      text: 'HR',
      role: 'MS_HR',
      prefix: '0l5',
      dropdown: [
        {
          text: 'Departments',
          href: '/departments',
        },
        {
          text: 'Employees',
          href: '/employees',
        },

        {
          text: 'Recruitment Pipelines',
          href: '/recruitment-pipelines',
        },
        {
          text: 'Job Level',
          href: '/job-levels',
        },
        {
          text: 'Vacancies',
          href: '/vacancies',
        },
        {
          text: 'Vacancy Page',
          href: 'https://pullstream.com/careers/job-openings',
        },
        {
          text: 'Course',
          href: '/employee-courses',
        },
        {
          text: 'Skills',
          href: '/employee-skills',
        },
        {
          text: 'Skill Level',
          href: '/skill-levels',
        },
        {
          text: 'Day Off Types',
          href: '/day-off-types',
        },
        {
          text: 'Employee Status',
          href: '/employee-status',
        },
      ],
    },
    {
      icon: faComments,
      text: 'Chat',
      role: 'MS_WEBCHAT',
      prefix: '0y7',
      href: '/company-chat',
    },
    {
      icon: faWarehouse,
      text: 'Inventory',
      role: 'MS_INVENTORY',
      prefix: '0lk',
      dropdown: [
        {
          text: 'Product Types',
          href: '/product-types',
        },
        {
          text: 'Manufacturer Contacts',
          href: '/manufacturer-contacts',
        },
        {
          text: 'Manufacturers',
          href: '/manufacturers',
        },
        {
          text: 'Products',
          href: '/products',
        },
        {
          text: 'Supplier Contacts',
          href: '/supplier-contacts',
        },
        {
          text: 'Suppliers',
          href: '/suppliers',
        },
        {
          text: 'Warehouses',
          href: '/warehouses',
        },
        {
          text: 'Product Additional Infos',
          href: '/product-additional-infos',
        },
        {
          text: 'Variant Keys',
          href: '/variant-keys',
        },
      ],
    },
    {
      icon: faShoppingCart,
      text: 'E-commerce',
      role: 'MS_E-COMMERCE',
      prefix: '09c',
      dropdown: [
        {
          text: 'Discount Codes',
          href: '/discount-codes',
        },
        {
          text: 'Payment Statuses',
          href: '/payment-statuses',
        },
        {
          text: 'Payment Providers',
          href: '/payment-providers',
        },
        {
          text: 'Payment Methods',
          href: '/payment-methods',
        },
        {
          text: 'Delivery Methods',
          href: '/delivery-methods',
        },
        {
          text: 'Delivery Statuses',
          href: '/delivery-statuses',
        },
        {
          text: 'Carts',
          href: '/carts',
        },
        {
          text: 'Addresses',
          href: '/addresses',
        },
        {
          text: 'Payments',
          href: '/payments',
        },
        {
          text: 'Cart Items',
          href: '/cart-items',
        },
        {
          text: 'Item Deliveries',
          href: '/item-deliveries',
        },
      ],
    },
    {
      icon: faBell,
      text: 'Communication',
      role: 'MS_COMMUNICATION',
      prefix: '0ps',
      dropdown: [
        {
          text: 'Emails',
          dropdown: [
            {
              text: 'Disconnected Accounts',
              dropdown: [],
            },
          ],
        },
        {
          text: 'Labels',
          href: '/labels',
        },
        {
          text: 'Shared With Me',
          href: '/shared-labels',
        },
        {
          text: 'Imap Accounts',
          href: '/imap-accounts',
        },
        {
          text: 'Imap Providers',
          href: '/imap-providers',
        },
        {
          text: 'Email Templates',
          href: '/email-templates',
        },
      ],
    },
    {
      icon: faCommentAlt,
      text: 'Forms',
      role: 'MS_FORMS',
      prefix: '0z1',
      href: '/forms',
    },
    {
      icon: faFileWord,
      text: 'eDoc',
      href: '/documents',
      role: 'MS_EDOC',
      prefix: '0hj',
      unstable: true,
    },
    {
      icon: faChalkboardTeacher,
      text: 'LMS',
      role: 'MS_LMS',
      unstable: true,
      prefix: '01x',
      dropdown: [
        {
          text: 'Create Course',
          href: '/create-course',
        },
        {
          text: 'University',
          href: '/universities',
        },
        {
          text: 'Course',
          href: '/courses',
        },
        {
          text: 'Module',
          href: '/modules',
        },
        {
          text: 'Lesson',
          href: '/lessons',
        },
        {
          text: 'Homework',
          href: '/homeworks',
        },
        {
          text: 'Instructor',
          href: '/instructors',
        },
        {
          text: 'Coach',
          href: '/coaches',
        },
        {
          text: 'Team',
          href: '/teams',
        },
        {
          text: 'Student',
          href: '/students',
        },
        {
          text: 'Mentor',
          href: '/mentors',
        },
        {
          text: 'Skill',
          href: '/skills',
        },
        {
          text: 'Course Category',
          href: '/categories',
        },
      ],
    },
    {
      icon: faKeyboard,
      text: 'Wiki',
      role: 'MS_WIKI',
      prefix: '0no',
      dropdown: [
        {
          text: 'Author',
          dropdown: [
            {
              text: 'Books',
              href: '/books',
            },
            {
              text: 'Manage Categories',
              href: '/book-categories',
            },
            {
              text: 'Manage Tags',
              href: '/tags',
            },
          ],
        },
        {
          text: 'Books',
          dropdown: [],
        },
      ],
    },
    {
      icon: faTasks,
      text: 'SOPs',
      role: 'MS_SOPS',
      prefix: '0nt',
      dropdown: [
        {
          text: 'Define',
          href: '/sops',
        },
        {
          text: 'Standard Procedures',
          dropdown: [
            {
              text: 'Request Days Off',
              href: '/iso-9001',
            },
          ],
        },
        {
          text: 'GDPR',
          href: '/gdpr-sops',
        },
        {
          text: 'ISO 9001',
          href: '/iso-9001',
        },
      ],
    },
    {
      icon: faHdd,
      text: 'Drive',
      role: 'MS_DRIVE',
      prefix: '0ik',
      href: '/drive',
    },
    {
      icon: faUserShield,
      text: 'Admin',
      role: 'Company Administrator',
      prefix: 'fAJ',
      unstable: false,
      dropdown: [
        // {
        //   text: 'Dashboard',
        //   href: '/system-dashboard',
        // },
        {
          text: 'RelationShip',
          href: '/relation-ships',
        },
        {
          text: 'Clients',
          href: '/admin-clients',
        },
      ],
    },
    {
      icon: faCodeBranch,
      text: 'System',
      role: 'MS_SYSTEM',
      prefix: '09m',
      unstable: false,
      dropdown: [
        // {
        //   text: 'Dashboard',
        //   href: '/system-dashboard',
        // },
        {
          text: 'Automata Connection With Models',
          href: '/automata-connection-with-models',
        },
        {
          text: 'Templates',
          href: '/templates',
        },
        {
          text: 'UI Prefixes',
          href: '/ui-prefixes',
        },
        // {
        //   text: 'ERDs',
        //   dropdown: [],
        // },
        {
          text: 'Compute',
          dropdown: [
            {
              text: 'Microservice',
              href: '/microservice',
            },
            {
              text: 'Block Group',
              href: '/block-groups',
            },
          ],
        },
        {
          text: 'Roles',
          href: '/user-roles',
        },
        {
          text: 'Logs',
          href: '/logs',
        },
        {
          text: 'System Logs',
          href: '/system-logs',
        },
        {
          text: 'Import Logs',
          href: '/import-logs',
        },
        {
          text: 'Visitor Logs',
          href: '/visitor-logs',
        },
        {
          text: 'Shape Transition Logs',
          href: '/shape-transition-logs',
        },
      ],
    },
    {
      icon: faList,
      text: 'List',
      role: 'MS_LIST',
      prefix: '042',
      unstable: true,
      dropdown: [
        {
          text: 'Countries',
          href: '/countries',
        },
        {
          text: 'Currencies',
          href: '/currencies',
        },
        {
          text: 'Social Medias',
          href: '/social-medias',
        },
        {
          text: 'Sic Codes',
          href: '/sic-codes',
        },
        {
          text: 'TimeZones',
          href: '/timezones',
        },
        {
          text: 'Languages',
          href: '/languages',
        },
        {
          text: 'Cities',
          href: '/cities',
        },
        {
          text: 'Post Codes',
          href: '/postcodes',
        },
      ],
    },
    {
      icon: faUserLock,
      text: 'Accounts',
      role: 'MS_ACCOUNTS',
      prefix: '0pd',
      unstable: true,
      dropdown: [
        {
          text: 'Roles',
          href: '/user-roles',
        },
        {
          text: 'Microservices',
          href: '/microservices',
        },
        {
          text: 'Models',
          href: '/models',
        },
        {
          text: 'Users',
          href: '/users',
        },
      ],
    },
  ];

  const { live, unstable } = roles.includes('LMS Student')
    ? { live: [], unstable: [] }
    : links.reduce(
        (acc, curr) => {
          if (
            roles.some((role) => role?.startsWith('MS_')) &&
            roles.includes(curr?.role)
          ) {
            if (curr?.unstable) {
              acc.unstable.push(curr);
            } else {
              acc.live.push(curr);
            }
          } else if (curr?.text === 'Kanbans') {
            acc.live.push(curr);
          }

          return acc;
        },
        { live: [], unstable: [] }
      );

  return { live, unstable };
}
