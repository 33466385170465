import React from 'react';
import * as Yup from 'yup';
import { inventoryUrls } from '@config/routes';
import { productVariantMeta } from '@config/meta/inventory/index';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import FormDialog from '@components/FormDialog';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import DropdownAddHOC from '@components/DropdownAddHOC';
import ProductDropdowm from '@dropdown-forms/inventory/ProductDropdown';
import { useQueryClient } from 'react-query';

const { productVariantsUrls, productsUrls } = inventoryUrls;

export default function ProductVarientDropdown({ children, refetch, product: initialProduct}) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const [hideProductVarient, setHideProductVarient] = React.useState(false);


  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        hidden={hideProductVarient}
        title="Product Variant"
        initialValues={{
          ...getAddFormInitialValues(productVariantMeta({}), 'listView'),
          product: initialProduct
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(productVariantMeta({}), 'listView'),
        })}
        getPostUrl={() => productVariantsUrls.list()}
        getValues={({ product,...values}) => ({
          product: product?.id,
          ...values,
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key="name" label="Name" name="name" required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
            <Grid item xs={6}>
              <ProductDropdowm
                hideParent={setHideProductVarient}
                refetch={({ id, name }) => {
                  setFieldValue('product', {
                    id,
                    label: `${name ?? ''}`,
                  });
                  queryClient.invalidateQueries(['opportunity-product']);
                }}
              >
              <AutocompleteFieldV2
                name='product'
                label='Product'
                requestKey='opportunity-product'
                urlParams={`&ordering=created_at`}
                fetchUrl={productsUrls.list}
                renderRow={(row) => ({
                  id: row?.id,
                  label: row?.name,
                })}
                required
              />
            </ProductDropdowm>
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="manufacturer_code"
                label="Manufacturer Code"
                name="manufacturer_code"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="our_sku_code"
                label="Our Sku Code"
                name="our_sku_code"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="vat_percentage"
                label="VAT Percentage"
                name="vat_percentage"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField key="price" label="Price" name="price" />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
