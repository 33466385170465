import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { accountUrls, nodeDriveUrls } from '@config/routes';
import { useState, useEffect } from 'react';
import useRQuery from '@hooks/useRQuery';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import Loader from '@components/Loader';
// import Button from '@ui/Button';

const { microservicesUrls } = accountUrls;
const { filesUrls } = nodeDriveUrls;

export default function Erd() {
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [microservicesName, setMicroservicesName] = useState('');
  const [fileName, setFileName] = useState('');

  const { data: microservicesList } = useRQuery({
    key: [`microservices-erd-list-${recordId}`, user.token, user?.actAs],
    url: microservicesUrls.list(`?id=${recordId}`),
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: fileList, isLoading: loadingfiles } = useRQuery({
    key: [
      `file-erd-list-${recordId}`,
      microservicesName,
      user.token,
      user?.actAs,
    ],
    url: filesUrls.list(`?name=${microservicesName}.png`),
    config: getAuthHeader(user.token, user?.actAs),
  });

  // const onReload = async (value) => {
  // setUploading(true);
  // const micro = axios
  //   .get(microservicesUrls.list(`?id=${value}`))
  //   .then((res) => res.data);
  // const object = await micro;
  // let url = '';
  // object.results.map((item) => {
  //   if (process.env.REACT_APP_APP_ENV === 'sandbox') {
  //     url = item.devDomainUrl;
  //   } else {
  //     url = item.domain_url;
  //   }
  // });
  // const domainUrl = `${url}/api/erd/`;
  // const response = await createRecord({
  //   url: domainUrl,
  //   token: user.token,
  //   actAs: user?.actAs,
  // });
  // if (response.status === 200) {
  //   setUploading(false);
  //   router.reload(window.location.pathname);
  // } else {
  //   setUploading(false);
  //   notify(`There was an error, please try again.`, {
  //     type: 'ERROR',
  //   });
  // }
  // };

  useEffect(() => {
    if (!microservicesList || !microservicesList.results) return;
    setMicroservicesName(microservicesList.results.map((item) => item.name));
  }, [microservicesList]);

  useEffect(() => {
    if (!fileList || !fileList.results) return;
    setFileName(fileList.results.map((item) => item.fileUrl));
  }, [fileList]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            mb: 2,
          }}
        >
          <Typography variant='h6'>{microservicesName} ERD</Typography>
          {/* {fileName.length !== 0 ? (
            <Button
              type='button'
              variant='contained'
              label='Reload'
              sx={{ ml: 3 }}
              onClick={() => onReload(recordId)}
            />
          ) : null} */}
        </Box>
        {/* {uploading && (
          <Box
            sx={{
              display: 'flex',
              ml: 5,
              mt: 1,
            }}
          >
            <Box
              sx={{
                mr: 1,
              }}
            >
              <CircularProgress size='1.5rem' />
            </Box>
            <Box>
              <Typography variant='h6'>Reloading...</Typography>
            </Box>
          </Box>
        )} */}
      </Box>
      {loadingfiles ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : fileName && fileName.length !== 0 ? (
        <Box>
          <img
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            src={fileName}
            alt='description '
          />
        </Box>
      ) : (
        <Box>
          <Typography variant='h6' sx={{ mb: 1 }}>
            No models are available.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
