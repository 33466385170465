export const domainRowsMap = (row) => ({
  id: row?.id,
  domain: row?.domain,
  googleAnalyticsId: row?.googleAnalyticsId,
  hotjarCode: row?.hotjarCode,
  googleSearchConsoleCode: row?.googleSearchConsoleCode,
  favicon: row?.favicon,
  createdAt: row?.createdAt,
});
export const pageTemplateRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  code: row?.code,
  tags: row?.tags,
  createdAt: row?.createdAt,
});

export const pageTemplateOptionRowsMap = (row) => ({
  id: row?.id,
  key: row?.key,
  value: row?.value,
  tags: row?.tags,
  createdAt: row?.createdAt,
});

export const pageMarketSegmentRowsMap = (row) => ({
  id: row?.id,
  landingPageId: row?.landingPageId,
  marketSegmentId: row?.marketSegmentId
    ? {
        id: row?.marketSegmentId,
        label: row?.marketSegment?.name,
      }
    : null,
  createdAt: row?.createdAt,
});

export const pageRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  pageGroupId: row?.connectedPageGroups.map((r) => r?.pageGroup?.name),
  description: row?.description,
  metaTitle: row?.metaTitle,
  metaDescription: row?.metaDescription,
  canonicalUrl: row?.canonicalUrl,
  tags: row?.tags,
  isPublished: row?.isPublished,
  createdAt: row?.createdAt,
});

export const pageGroupRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  slug: row?.slug,
  isPublished: row?.isPublished,
  domainId: row?.domain
    ? {
        id: row?.domainId,
        label: row?.domain?.domain ?? '',
      }
    : null,
  defaultPageId: row?.defaultPageId
    ? {
        id: row?.defaultPageId,
        label: row?.defaultPage?.name ?? '',
      }
    : null,
  menuId: row?.menu
    ? {
        id: row?.menuId,
        label: row?.menu?.name ?? '',
      }
    : null,
  automataId: row?.automataId
    ? {
        id: row?.automataId,
        label: row?.automata?.name ?? '',
      }
    : null,
  description: row?.description,
  tags: row?.tags,
  createdAt: row?.createdAt,
});

export const pageTopageGroupRowsMap = (row) => ({
  id: row?.id,
  pageId: row?.pageId
    ? {
        id: row?.pageId,
        label: row?.page?.name,
      }
    : null,
  pageGroupId: row?.pageGroupId
    ? {
        id: row?.pageGroupId,
        label: row?.pageGroup?.name,
      }
    : null,
  createdAt: row?.createdAt,
});

export const menuRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  tags: row?.tags,
  createdAt: row?.createdAt,
});

export const menuOptionRowsMap = (row) => ({
  id: row?.id,
  order: row?.order,
  alias: row?.alias,
  parent: row?.parent,
  pageGroup: row?.pageGroup,
  pageOption: row?.pageOption,
  pageOptionId: row?.pageOptionId
    ? {
        id: row?.pageOptionId,
        label: row?.pageOption?.key ?? '',
      }
    : null,
  pageGroupId: row?.pageGroupId
    ? {
        id: row?.pageGroupId,
        label: row?.pageGroup?.name ?? '',
      }
    : null,
  parentId: row?.parentId
    ? {
        id: row?.parentId,
        label: row?.parent?.alias ?? row?.parent?.pageGroup?.name ?? 'Unamed',
      }
    : null,
  description: row?.description,
  tags: row?.tags,
  createdAt: row?.createdAt,
});

export const pageOptionRowsMap = (row) => ({
  id: row?.id,
  key: row?.key,
  value: row?.value,
  createdAt: row?.createdAt,
});
