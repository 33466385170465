// handleRows

export const labelRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  parent_label:
    c.parent_label && c.details && c.details.parent_label
      ? { id: c.parent_label, label: c.details.parent_label.name }
      : c.parent_label,
  member_everyone_can_see_it: c.member_everyone_can_see_it,
  member_anonymous_can_see_it: c.member_anonymous_can_see_it,
  member_everyone_in_object_company_can_see_it:
    c.member_everyone_in_object_company_can_see_it,
  member_only_these_roles_can_see_it: Object.entries(
    c.member_only_these_roles_can_see_it || {}
  ).map((roleObj) => ({ id: roleObj[0], label: roleObj[1] })),
  member_only_these_users_can_see_it: Object.entries(
    c.member_only_these_users_can_see_it || {}
  ).map((userObj) => ({ id: userObj[0], label: userObj[1] })),
  created_at: new Date(c.created_at),
});

export const notificationLabelRowMap = (c) => ({
  id: c.id,
  labelId: c.label,
  label:
    c.label && c.details && c.details.label
      ? { id: c.label, label: c.details.label.name }
      : c.label,
  created_at: new Date(c.created_at),
});

export const emailRowsMap = (r) => ({
  id: r.id,
  subject: r.subject || '...',
  html_body: r.html_body,
  // type: r.type,
  imap_account_id: r.imap_account,
  status: r.status,
  imap_account:
    r.imap_account && r.details && r.details.imap_account
      ? r.details.imap_account.display_name || r.details.imap_account.username
      : r.imap_account,
  from_email:
    r.from_email && r.details && r.details.from_email
      ? r.details.from_email.email
      : r.from_email,
  all_to_recipient_emails: r?.details?.all_to_recipient_emails ?? '',
  template:
    r.template && r.details && r.details.template
      ? r.details.template.name
      : r.template,
  scheduled: r.scheduled ? new Date(r.scheduled) : null,
  created_at: r.created_at ? new Date(r.created_at) : null,
});

export const attachmentRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  url: r.url,
  created_at: r.created_at ? new Date(r.created_at) : null,
});

export const notificationEmailAddressRowsMap = (r) => ({
  id: r.id,
  name:
    r.email_address && r.details && r.details.email_address
      ? r.details.email_address.name
      : r.email_address,
  email:
    r.email_address && r.details && r.details.email_address
      ? r.details.email_address.email
      : r.email_address,
  method: r.method,
  created_at: r.created_at ? new Date(r.created_at) : null,
});

export const emailTemplateRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  html: r.html,
  description: r.description,
  subject: r.subject,
  from_email:
    r.from_email && r.details && r.details.from_email
      ? { id: r.from_email, label: r.details.from_email.username }
      : r.from_email,
  from_email_alias: r.from_email_alias,
  _tags: r._tags,
  created_at: r.created_at ? new Date(r.created_at) : null,
});

export const contextFieldRowsMap = (r) => ({
  id: r.id,
  label: r.label,
  name: r.name,
  description: r.description,
  type: r.type,
  mandatory: r.mandatory,
  created_at: r.created_at ? new Date(r.created_at) : null,
});

export const imapProviderRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  host: r.host,
  port: r.port,
  connection_type: r.connection_type,
  created_at: r.created_at ? new Date(r.created_at) : null,
});

export const imapAccountRowsMap = (r) => ({
  id: r.id,
  display_name: r.display_name,
  username: r.username,
  provider:
    r.provider && r.details && r.details.provider
      ? { id: r.provider, label: r.details.provider.name }
      : r.provider,
  smtp_host: r.smtp_host,
  smtp_port: r.smtp_port,
  smtp_connection_type: r.smtp_connection_type,
  smtp_username: r.smtp_username,
  connected: r.connected,
  smtp_connected: r.smtp_connected,
  encryption_enabled: r.encryption_enabled,
  send_emails_from_this_account: r.send_emails_from_this_account,
  created_at: r.created_at ? new Date(r.created_at) : null,
});
