import * as Yup from 'yup';
import { fieldType, microservices } from '@config/constants';
import { cmsV2Urls, marketingV2Urls, bpaUrls } from '@config/routes';
import getDropdownForm from '@dropdown-forms/getDropdownForm';

const {
  pagesUrls,
  pageOptionsUrls,
  pageGroupsUrls,
  domainsUrls,
  menuOptionsUrls,
  menusUrls,
} = cmsV2Urls;
const { marketSegmentsUrls } = marketingV2Urls;
const { workflowsUrls } = bpaUrls;

export const pageTemplateMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    to: '/page-templates/',
    onForm: true,
    width: 300,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'code',
    headerName: 'Code',
    onForm: false,
    editable: true,
    value: data ? data.code : null,
    initialValue: '',
    width: 300,
    type: fieldType.TEXT,
    validate: Yup.string().required(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const pageTemplateOptionMeta = ({ data } = {}) => [
  {
    field: 'key',
    headerName: 'Key',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data ? data.key : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'value',
    headerName: 'Value',
    onAccordionForm: true,
    editable: true,
    value: data ? data.value : null,
    initialValue: '',
    width: 300,
    type: fieldType.TEXT,
    validate: Yup.string().required(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const pageMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    to: '/cms-pages/',
    onForm: true,
    width: 300,
    editable: true,
    value: data ? data.name : null,
    type: fieldType.MULTILINE,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'pageGroupId',
    headerName: 'Page Group',
    onForm: true,
    width: 300,
    editable: false,
    value: data ? data.pageGroupId : null,
    type: fieldType.MULTILINE,
    initialValue: '',
    validate: Yup.mixed(),
    onDetailCard: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 180,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'isPublished',
    headerName: 'Published',
    type: fieldType.BOOLEAN,
    width: 120,
    editable: true,
    value: data?.isPublished ?? null,
    initialValue: false,
    onForm: true,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'metaTitle',
    headerName: 'Meta Title',
    onForm: true,
    editable: true,
    value: data ? data.metaTitle : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string().max(60),
  },
  {
    field: 'metaDescription',
    headerName: 'Meta Description',
    onForm: true,
    editable: true,
    value: data ? data.metaDescription : null,
    initialValue: '',
    width: 180,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'canonicalUrl',
    headerName: 'Canonical URL',
    type: fieldType.Text,
    width: 180,
    editable: true,
    value: data?.canonicalUrl,
    initialValue: '',
    onForm: true,
    validate: Yup.string().url().nullable(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const pageMarketSegmentMeta = ({ data } = {}) => [
  {
    field: 'marketSegmentId',
    headerName: 'Market Segment',
    getHref: (r) => `/market-segments/${r?.marketSegmentId?.id}`,
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: marketSegmentsUrls.list,
    requestKey: `${data?.id}-market-segment`,
    validate: Yup.mixed(),
    initialValue: data?.marketSegmentId
      ? {
          id: data?.marketSegmentId,
          label: data?.marketSegment?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const pageGroupMeta = ({ data, queryClient } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    to: '/page-groups/',
    onForm: true,
    width: 300,
    editable: true,
    value: data ? data.name : null,
    type: fieldType.MULTILINE,
    initialValue: '',
    validate: Yup.string().required(),
    rows: 2,
  },
  {
    field: 'domainId',
    headerName: 'Subdomain',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: domainsUrls.list,
    requestKey: `${data?.id}-domain`,
    validate: Yup.mixed(),
    initialValue: data?.domainId
      ? {
          id: data?.domainId,
          label: data?.domain?.domain ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.domain,
    }),
  },
  {
    field: 'slug',
    headerName: 'Slug',
    onForm: true,
    editable: true,
    value: data ? data.slug : null,
    type: fieldType.MULTILINE,
    initialValue: '',
    width: 300,
    validate: Yup.string().required(),
    rows: 2,
  },
  {
    field: 'defaultPageId',
    headerName: 'Default Page',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pagesUrls.list,
    requestKey: `${data?.id}-default-Page`,
    validate: Yup.mixed(),
    initialValue: data?.defaultPageId
      ? {
          id: data?.defaultPageId,
          label: data?.defaultPage?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-default-pages`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CMS.name,
        model: microservices.CMS.models.pages,
      }),
    }),
  },
  {
    field: 'menuId',
    headerName: 'Menu',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: menusUrls.list,
    requestKey: `${data?.id}-menu`,
    validate: Yup.mixed(),
    initialValue: data?.menuId
      ? {
          id: data?.menuId,
          label: data?.menu?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'isPublished',
    headerName: 'Published',
    type: fieldType.BOOLEAN,
    width: 120,
    editable: true,
    value: data ? data.isPublished : null,
    initialValue: false,
    onForm: true,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'automataId',
    headerName: 'Automata',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: workflowsUrls.list,
    requestKey: `${data?.id}-workflow`,
    validate: Yup.mixed(),
    initialValue: data?.automata
      ? {
          id: data?.automata,
          label: data?.automata?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const pageToPageGroupMeta = ({
  data,
  hidePage,
  hidePageGroups,
} = {}) => [
  {
    field: 'pageGroupId',
    headerName: 'Page Group',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pageGroupsUrls.list,
    requestKey: `${data?.id}-pages`,
    validate: Yup.mixed(),
    initialValue: data?.pageGroupId
      ? {
          id: data?.pageGroupId,
          label: data?.pageGroup?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    hideColumn: hidePageGroups,
    getHref: (r) => `/page-groups/${r?.pageGroupId?.id}`,
  },
  {
    field: 'pageId',
    headerName: 'Page',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pagesUrls.list,
    requestKey: `${data?.id}-pages`,
    validate: Yup.mixed(),
    initialValue: data?.pageId
      ? {
          id: data?.pageId,
          label: data?.page?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    hideColumn: hidePage,
    getHref: (r) => `/cms-pages/${r?.pageId?.id}`,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const domainMeta = ({ data } = {}) => [
  {
    field: 'domain',
    headerName: 'Subdomain',
    onForm: true,
    width: 450,
    editable: true,
    value: data ? data.domain : null,
    initialValue: '',
    validate: Yup.string()
      .test('is-url', 'Invalid Domain', (value) => {
        if (!value) return true;
        // Regular expression to match URLs without trailing slashes
        const urlPattern = /^(https?:\/\/\S+[^/])$/;
        return urlPattern.test(value);
      })
      .required(),
  },
  {
    field: 'googleAnalyticsId',
    headerName: 'Google Analytics Id',
    onForm: true,
    width: 450,
    editable: true,
    value: data?.googleAnalyticsId ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'hotjarCode',
    headerName: 'Hotjar Code',
    onForm: true,
    width: 450,
    editable: true,
    value: data?.hotjarCode ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'googleSearchConsoleCode',
    headerName: 'Google Search Console Code',
    onForm: true,
    width: 450,
    editable: true,
    value: data?.googleSearchConsoleCode ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'favicon',
    headerName: 'Favicon',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.favicon : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const menuMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    to: '/menus/',
    onForm: true,
    width: 180,
    editable: true,
    value: data ? data.name : null,
    type: fieldType.MULTILINE,
    initialValue: '',
    validate: Yup.string().required(),
    rows: 2,
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 180,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const menuOptionMeta = ({ data, menuId, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 65,
    onAccordionForm: true,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder ? nextOrder + 1 : 1,
    validate: Yup.number(),
  },
  {
    field: 'alias',
    headerName: 'Alias',
    onAccordionForm: true,
    editable: true,
    value: data ? data.alias : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string().required(),
  },
  {
    field: 'parentId',
    headerName: 'Parent',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: menuOptionsUrls.list,
    requestKey: `${data?.id}-menu-options`,
    validate: Yup.mixed(),
    urlParams: `&menuId=${menuId}`,
    initialValue: data?.parentId
      ? {
          id: data?.parentId,
          label:
            data?.parent?.alias ?? data?.parent?.pageGroup?.name ?? 'Unamed',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.alias ?? row?.pageGroup?.name ?? 'Unamed',
    }),
  },
  {
    field: 'pageGroupId',
    headerName: 'Page Group',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pageGroupsUrls.list,
    requestKey: `${data?.id}-pages`,
    validate: Yup.mixed(),
    initialValue: data?.pageGroupId
      ? {
          id: data?.pageGroupId,
          label: data.pageGroup.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'pageOptionId',
    headerName: 'Page Section',
    width: 300,
    editable: false,
    onForm: true,
    validate: Yup.mixed(),
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: pageOptionsUrls.list,
    requestKey: `${data?.id}-page-group-sections`,
    initialValue: data?.pageOptionId
      ? {
          id: data?.pageOptionId,
          label: data?.pageOption?.key,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.key,
    }),
    urlParams: data?.pageGroup?.defaultPageId
      ? `&pageId=${data.pageGroup.defaultPageId}`
      : '',
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const pageOptionMeta = ({ data } = {}) => [
  {
    field: 'key',
    headerName: 'Key',
    onAccordionForm: true,
    editable: false,
    value: data ? data.key : null,
    initialValue: '',
    width: 300,
    type: fieldType.TEXT,
    validate: Yup.string().required(),
  },
  {
    field: 'value',
    headerName: 'Value',
    onAccordionForm: true,
    editable: true,
    value: data ? data.value : null,
    initialValue: '',
    width: 400,
    type: fieldType.TEXT,
    validate: Yup.string().required(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
