import { useState } from 'react';
import { Box } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import ObjectLevelPermissionForm from '../Visibility/ObjectLevelPermissionForm';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useEffect } from 'react';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function VisibilityPromptModal({
  open,
  setShowVisibilityModal,
  row,
  clients,
  setClientTerm,
  clientsIsFetching,
  roles,
  setRoleTerm,
  rolesIsFetching,
  users,
  setUserTerm,
  usersIsFetching,
  actions,
  user,
  labels,
  editMode,
  includeClient = false,
  prefix,
}) {
  const [initialValues, setInitialValues] = useState({});
  const [customValues, setCustomValues] = useState({});

  useEffect(() => {
    if (!row || !open || Object.keys(initialValues).length) return;

    const only_these_clients_can_see_it =
      row?.row?.only_these_clients_can_see_it;
    const only_these_roles_can_see_it = row?.row?.only_these_roles_can_see_it;
    const only_these_users_can_see_it = row?.row?.only_these_users_can_see_it;

    const values = {
      everyone_can_see_it: row?.row?.everyone_can_see_it,
      anonymous_can_see_it: row?.row?.anonymous_can_see_it,
      everyone_in_object_company_can_see_it:
        row?.row?.everyone_in_object_company_can_see_it,
      only_these_clients_can_see_it:
        Array.isArray(only_these_clients_can_see_it) &&
        !only_these_clients_can_see_it.length
          ? {}
          : only_these_clients_can_see_it,
      only_these_roles_can_see_it:
        Array.isArray(only_these_roles_can_see_it) &&
        !only_these_roles_can_see_it.length
          ? {}
          : only_these_roles_can_see_it,
      only_these_users_can_see_it:
        Array.isArray(only_these_users_can_see_it) &&
        !only_these_users_can_see_it.length
          ? {}
          : only_these_users_can_see_it,
    };

    setCustomValues(values);
    setInitialValues(values);
  }, [row, open]);

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open}>
      {prefix ? (
        <RenderUiPrefix
          editMode={editMode}
          labels={labels}
          code={prefix}
          defaultValue='Visibility'
        >
          {(text) => {
            return <DialogTitle>{text}</DialogTitle>;
          }}
        </RenderUiPrefix>
      ) : (
        <DialogTitle>Visibility</DialogTitle>
      )}
      <DialogContent>
        {row && open && Object.keys(initialValues).length ? (
          <Formik
            initialValues={{
              ...initialValues,
            }}
            validationSchema={Yup.object({
              everyone_can_see_it: Yup.bool(),
              anonymous_can_see_it: Yup.bool(),
              everyone_in_object_company_can_see_it: Yup.bool(),
              only_these_clients_can_see_it: Yup.object()
                .default({})
                .nullable(),
              only_these_roles_can_see_it: Yup.object().default({}).nullable(),
              only_these_users_can_see_it: Yup.object().default({}).nullable(),
            })}
            onSubmit={async (values, { resetForm }) => {
              const payload = {
                ...values,
                id: row.id,
              };

              await actions.visibilityUpdate(payload, row);

              resetForm();
              setShowVisibilityModal(false);
              setInitialValues({});
              setCustomValues({});
            }}
          >
            {({ isSubmitting, submitForm, setFieldValue }) => (
              <Form noValidate autoComplete='off'>
                <ObjectLevelPermissionForm
                  includeClient={includeClient}
                  clients={clients}
                  setClientTerm={setClientTerm}
                  clientsIsFetching={clientsIsFetching}
                  roles={roles}
                  setRoleTerm={setRoleTerm}
                  rolesIsFetching={rolesIsFetching}
                  users={users}
                  setUserTerm={setUserTerm}
                  usersIsFetching={usersIsFetching}
                  values={customValues}
                  setCustomValues={setCustomValues}
                  disabled={row.row.created_by !== user?.details?.id}
                />
                <Box sx={{ display: 'flex' }}>
                  {row.row.created_by === user?.details?.id ? (
                    <Box sx={{ mr: 'auto' }}>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setCustomValues({
                              everyone_can_see_it: Boolean(
                                initialValues.everyone_can_see_it
                              ),
                              anonymous_can_see_it: Boolean(
                                initialValues.anonymous_can_see_it
                              ),
                              everyone_in_object_company_can_see_it: Boolean(
                                initialValues.everyone_in_object_company_can_see_it
                              ),
                              only_these_clients_can_see_it:
                                initialValues.only_these_clients_can_see_it ||
                                {},
                              only_these_roles_can_see_it:
                                initialValues.only_these_roles_can_see_it || {},
                              only_these_users_can_see_it:
                                initialValues.only_these_users_can_see_it || {},
                            });
                          }}
                        >
                          Reset To Defaults
                        </Button>
                      </DialogActions>
                    </Box>
                  ) : null}

                  <Box sx={{ ml: 'auto' }}>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setShowVisibilityModal(false);
                          setInitialValues({});
                          setCustomValues({});
                        }}
                      >
                        Cancel
                      </Button>
                      {row.row.created_by === user?.details?.id ? (
                        <Button
                          disabled={isSubmitting}
                          onClick={() => {
                            setFieldValue(
                              'everyone_can_see_it',
                              customValues.everyone_can_see_it
                            );
                            setFieldValue(
                              'anonymous_can_see_it',
                              customValues.anonymous_can_see_it
                            );
                            setFieldValue(
                              'everyone_in_object_company_can_see_it',
                              customValues.everyone_in_object_company_can_see_it
                            );
                            setFieldValue(
                              'only_these_clients_can_see_it',
                              customValues.only_these_clients_can_see_it
                            );
                            setFieldValue(
                              'only_these_roles_can_see_it',
                              customValues.only_these_roles_can_see_it
                            );
                            setFieldValue(
                              'only_these_users_can_see_it',
                              customValues.only_these_users_can_see_it
                            );
                            submitForm();
                          }}
                        >
                          Save
                        </Button>
                      ) : null}
                    </DialogActions>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
