import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { accountUrls } from '@config/routes';
import { userHandleRow } from '@config/handleRows/accounts';
import { microservices } from '@config/constants';

const { adminUsersUrls } = accountUrls;

export default function Users({ labels, editMode }) {
  return (
    <Box>
      <ListView
        label='Users'
        prefix='0t4'
        labels={labels}
        editMode={editMode}
        microservice={microservices.ACCOUNTS.name}
        model={microservices.ACCOUNTS.models.users}
        urls={adminUsersUrls}
        columnKey='usersAccounts'
        handleRowsMap={userHandleRow}
      />
    </Box>
  );
}
