import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { urlShortenerUrls, logsUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import { linkMeta } from '@config/meta/urlShortener';
import Button from '@ui/Button';
import DetailAccordionView from '@components/DetailAccordionView';
import { visitorLogsRowsMap } from '@config/handleRows/logs/index';

const { combinedDataUrls } = urlShortenerUrls;
const { visitorLogUrls } = logsUrls;

export default function LinkDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = useState(null);
  const user = useSelector(selectProfile);
  const [copied, setCopied] = useState(false);
  const [copyText, setCopyText] = useState(false);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => linkMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/links`, text: 'Link' },
              {
                text:
                  recordData &&
                  `${recordData.domain}/${recordData.custom_back_half}`,
              },
            ]}
          >
            <Button
              type='button'
              variant='outlined'
              label={copied ? 'Copied' : 'Copy Link'}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${recordData.domain}/${recordData.custom_back_half}`
                );
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              }}
            />
            <Button
              type='button'
              variant='outlined'
              label={copyText ? 'Copied Text' : 'Copy Text'}
              onClick={() => {
                const placeholder = '{ShortURL}';
                const string = recordData && recordData.text;
                const replacement =
                  recordData &&
                  `${recordData.domain}/${recordData.custom_back_half}`;

                const replacedString = string.replace(
                  new RegExp(placeholder, 'g'),
                  replacement
                );
                navigator.clipboard.writeText(replacedString);
                setCopyText(true);
                setTimeout(() => setCopyText(false), 1000);
              }}
              sx={{ ml: 2 }}
            />
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={combinedDataUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      {(Array.isArray(user?.details?.roles_names) &&
        user.details.roles_names.includes('Marketing')) ||
      user?.details?.is_superuser ? (
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix='0f4'
          columnKey='visitorLog'
          label='User Logs'
          urls={visitorLogUrls}
          handleRowMap={visitorLogsRowsMap}
          urlParams={`&microservice=URL Shortener&model=Link&object_id=${recordId}`}
        />
      ) : null}
    </Box>
  );
}
