import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { pmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { hlrMeta } from '@config/meta/pm/pmMeta';
import { backlogRowsMap } from '@config/handleRows/pm';
import { BacklogForm } from '@config/forms/pm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { hlrsUrls, backlogsUrls, tasksUrls, taskBacklogsUrls } = pmUrls;

export default function HLRDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const [backlogsListData, setBacklogsListData] = React.useState({});
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => hlrMeta({ data }), []);

  const backlogsColOptions = React.useMemo(() => {
    return {
      nextOrder: backlogsListData?.totalCount ?? 1,
      project: recordData?.project,
    };
  }, [backlogsListData?.totalCount, recordData?.project]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/programs/${recordData?.project_detail.program}`,
                text: 'Projects',
              },
              {
                to: `/projects/${recordData?.project}`,
                text: recordData?.project_detail.name,
              },

              { to: `/projects/${recordData?.project}`, text: 'HLRs' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={hlrsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      {console.log(recordData?.project)}

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          prefix="0jy"
          labels={labels}
          editMode={editMode}
          microservice={microservices.PM.name}
          model={microservices.PM.models.backlogs}
          columnKey="backlogPM"
          columnOptions={backlogsColOptions}
          customValues={{
            rebase_backlog_order: true,
          }}
          label="Backlogs"
          urls={backlogsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              BacklogForm({
                setFieldValue,
                project: {
                  id: recordData?.project,
                  label: recordData?.details?.project?.name,
                },
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { as_a, ...rest } = values;
                const response = await createRecord({
                  values: {
                    ...rest,
                    hlr: recordId,
                    as_a: as_a?.id,
                    rebase_backlog_order: true,
                  },
                  url: backlogsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                const createTaskBacklog = async (taskId) =>
                  createRecord({
                    values: {
                      task: taskId,
                      backlog: response?.data?.id,
                    },
                    url: taskBacklogsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                try {
                  const { data: task } = await createRecord({
                    values: {
                      name: values.i_want,
                      description: values.so_that,
                      project: recordData?.project,
                      hlr: response?.data?.hlr,
                    },
                    url: tasksUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  await createTaskBacklog(task.id);
                } catch (err) {
                  console.log(err.response || err.message);
                  if (
                    err.response &&
                    err.response.status === 400 &&
                    err.response.data &&
                    err.response.data.name
                  ) {
                    try {
                      const { data: task } = await createRecord({
                        values: {
                          name: `${values.i_want} ${new Date().getTime()}`,
                          description: values.so_that,
                          project: recordData?.project,
                        },
                        url: tasksUrls.list(),
                        token: user.token,
                        actAs: user?.actAs,
                      });
                      await createTaskBacklog(task.id);
                    } catch (err) {
                      console.log(err);
                      handleCreateFormErrors({ err, setError, notify, values });
                    }
                  }
                }

                resetForm();
                setAddNewForm(false);
                refetch();

                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          customSortColumnName="order"
          sortColumnBy="asc"
          handleRowMap={backlogRowsMap}
          urlParams={`&hlr=${recordId}`}
          getListData={(data) => setBacklogsListData(data)}
        />
      </Box>
    </Box>
  );
}
