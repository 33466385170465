import Modal from '../ui/Modal';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '../ui/Button';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '45%',
};

export default function PromptModal({
  open,
  headline = '',
  primaryLabel = 'Done',
  primaryColor = 'primary',
  secondaryLabel = 'Cancel',
  secondaryColor = 'primary',
  message,
  disablePrimaryButton = false,
  onSecondaryClick,
  onPrimaryClick,
}) {
  return (
    <Modal
      open={open}
    >
      <Box key='main-container' sx={style}>
        <Box key='action-container' sx={{ display: 'inline-flex' }}>
          <FontAwesomeIcon
            key='icon'
            icon={faExclamationTriangle}
            size='3x'
            color='rgb(244, 24, 66)'
          />
          <Typography
            id='modal-modal-title'
            variant='h4'
            component='h2'
            sx={{ ml: '20px', my: 'auto' }}
          >
            {headline}
          </Typography>
        </Box>
        <Typography component='div' id='modal-modal-description' sx={{ mt: 2 }}>
          {message}
        </Typography>
        <Box
          key='button-container'
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '20px',
          }}
        >
          <Button
            variant='outlined'
            color={secondaryColor}
            label={secondaryLabel}
            size='large'
            onClick={onSecondaryClick}
          />
          <Button
            variant='contained'
            color={primaryColor}
            label={primaryLabel}
            size='large'
            disabled={disablePrimaryButton}
            onClick={onPrimaryClick}
            sx={{ marginLeft: '9px' }}
          />
        </Box>
      </Box>
    </Modal>
  );
}
