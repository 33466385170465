import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { AutocompleteFieldV2, SelectField } from '@components/Inputs';
import { bpaUrls } from '@config/routes';
import { useFormikContext } from 'formik';

const { getPayloadActionsUrls } = bpaUrls;

const headingContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mb: 2,
};

const headingStyles = { fontSize: '15px', fontWeight: '500', color: '#121826' };

export default function PayloadSelector({
  isKanbanView,
  label,
  workflowId,
  payloadSectionName,
  payloadSectionFieldName,
}) {
  const { values, setFieldValue } = useFormikContext();

  const onPayloadSectionChange = () => {
    setFieldValue(payloadSectionFieldName, null);
  };

  const renderPayloadSections = (row) => {
    const { id, name, keys } = row;

    return {
      id,
      label: name,
      sectionKeys: keys.map((key) => ({
        ...key,
        fullLabel: `${name} / ${key.label}`,
      })),
    };
  };

  return (
    <Box>
      <Box sx={headingContainerStyles}>
        <Typography sx={headingStyles}>{label}</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AutocompleteFieldV2
            label="Payload Section"
            disabled={isKanbanView}
            name={payloadSectionName}
            onChange={onPayloadSectionChange}
            requestKey={`${payloadSectionName}-map-payload-section`}
            fetchUrl={getPayloadActionsUrls.list}
            urlParams={`&workflow_id=${workflowId}&exclude_draft_stage=true`}
            renderRow={renderPayloadSections}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectField
            required
            disabled={!!!values[payloadSectionName]?.id}
            label="Section Field"
            labelId="payload-section-keys"
            name={payloadSectionFieldName}
          >
            {(values[payloadSectionName]?.sectionKeys ?? []).map(
              (sectionKey) => (
                <MenuItem value={sectionKey} key={sectionKey?.key}>
                  {sectionKey?.label}
                </MenuItem>
              )
            )}
          </SelectField>
        </Grid>
      </Grid>
    </Box>
  );
}
