import React from 'react';
import * as Yup from 'yup';
import { pmUrls } from '@config/routes';
import { tasksMeta } from '@config/meta/pm/pmMeta';
import { Grid } from '@mui/material';
import {
  TextField,
  AutocompleteFieldV2,
  SelectField,
} from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import ProjectDropdown from '@dropdown-forms/pm/ProjectDropdown';
import ProgramDropdown from '@dropdown-forms/pm/ProgramDropdown';
import StatusDropdown from '@dropdown-forms/pm/StatusDropdown';
import { useQueryClient } from 'react-query';
import ResourcesDropdown from '@dropdown-forms/pm/ResourceDropdown';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import useToast from '@hooks/useToast';
import MenuItem from '@mui/material/MenuItem';
import { durationUnitsChoices } from '@config/constants';

const {
  tasksUrls,
  taskStatusUrls,
  taskTypesUrls,
  resourcesUrls,
  projectsUrls,
  programsUrls,
} = pmUrls;

export default function TaskDropdown({
  children,
  customOpen,
  setCustomOpen,
  handleCustom = async () => null,
  showChildren = true,
  refetch = () => null,
  parent_task: initialParentTask,
  program: initialProgram,
  project: initialProject,
  status: initialStatus,
  owner: initialOwner,
  task_type: initialTaskType,
  hideProgram,
  hideProject,
  hideParent,
  title,
}) {
  const [open, setOpen] = React.useState(false);
  const [hideTask, setHideTask] = React.useState(false);
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <>
      {showChildren ? (
        <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      ) : null}

      <FormDialog
        hidden={hideTask}
        title={title ?? 'Task'}
        initialValues={{
          ...getAddFormInitialValues(tasksMeta(), 'listView'),
          parent_task: initialParentTask,
          program: initialProgram,
          projects: initialProject,
          status: initialStatus,
          owner: initialOwner,
          task_type: initialTaskType,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(tasksMeta(), 'listView'),
        })}
        getPostUrl={() => tasksUrls.list()}
        handleSubmit={async (
          values,
          setSubmitting,
          resetForm,
          setOpen,
          setError,
          setFieldError
        ) => {
          try {
            const {
              status,
              task_type,
              owner,
              projects,
              program,
              parent_task,
              ...rest
            } = values;

            if (!program?.id) {
              setFieldError('program', 'This field is required');
              return;
            }

            if (!projects?.id) {
              setFieldError('projects', 'This field is required');
              return;
            }

            if (!status?.id) {
              setFieldError('status', 'This field is required');
              return;
            }

            if (!task_type?.id) {
              setFieldError('task_type', 'This field is required');
              return;
            }

            const { data } = await createRecord({
              values: {
                ...rest,
                status: status?.id,
                task_type: task_type?.id,
                owner: owner?.id,
                project: projects?.id,
                parent_task: parent_task?.id,
                rebase_task_order: true,
              },
              url: tasksUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });

            await handleCustom(data);

            resetForm();
            setOpen(false);
            if (setCustomOpen) setCustomOpen(false);
            refetch(data);
            return data?.id;
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        }}
        getFields={({ setFieldValue, values }) => (
          <Grid container spacing={2.5}>
            <Grid item xs={3}>
              <TextField type="number" label="Order" name="order" />
            </Grid>
            <Grid item xs={9}>
              <AutocompleteFieldV2
                name="task_type"
                label="Task Type"
                disabled={!values?.projects}
                enabled={!!values?.projects?.id}
                requestKey="task-project-task-types"
                fetchUrl={taskTypesUrls.list}
                requestKeyOptions={[values?.projects?.id]}
                urlParams={`&ordering=created_at&project=${values?.projects?.id}`}
                renderRow={(row) => ({
                  id: row?.id,
                  label: row?.name,
                })}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField label="Name" name="name" required multiline />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                label="Description"
                name="description"
                minRows={2}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField label="Duration Estimate" name="duration_estimate" />
            </Grid>
            <Grid item xs={6}>
              <SelectField label="Duration unit" name="duration_unit">
                {durationUnitsChoices.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>

            {hideProgram ? null : (
              <Grid item xs={6}>
                <ProgramDropdown
                  hideParent={setHideTask}
                  refetch={({ id, name }) => {
                    setFieldValue('program', { id, label: name });
                    queryClient.invalidateQueries(['task-projects']);
                  }}
                >
                  <AutocompleteFieldV2
                    name="program"
                    label="Program"
                    requestKey="task-programs"
                    initi
                    fetchUrl={programsUrls.list}
                    urlParams={`&ordering=created_at`}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.name,
                    })}
                    required
                  />
                </ProgramDropdown>
              </Grid>
            )}

            {hideProject ? null : (
              <Grid item xs={6}>
                <ProjectDropdown
                  hideParent={setHideTask}
                  refetch={({ id, name }) => {
                    setFieldValue('projects', { id, label: name });
                    queryClient.invalidateQueries(['task-project-task-types']);
                  }}
                >
                  <AutocompleteFieldV2
                    name="projects"
                    label="Project"
                    requestKey="task-projects"
                    fetchUrl={projectsUrls.list}
                    disabled={!values?.program}
                    enabled={!!values?.program?.id}
                    requestKeyOptions={[values?.program?.id]}
                    urlParams={`&program=${values?.program?.id}&ordering=created_at`}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.name,
                      program: row?.program,
                    })}
                    required
                  />
                </ProjectDropdown>
              </Grid>
            )}

            {hideParent ? null : (
              <Grid item xs={12}>
                <TaskDropdown
                  refetch={({ id, name }) => {
                    setFieldValue('parent_task', { id, label: name });
                    queryClient.invalidateQueries(['task-parent-tasks']);
                  }}
                >
                  <AutocompleteFieldV2
                    name="parent_task"
                    label="Parent Task"
                    requestKey="task-parent-tasks"
                    fetchUrl={tasksUrls.list}
                    urlParams={`&ordering=created_at`}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.name,
                    })}
                  />
                </TaskDropdown>
              </Grid>
            )}

            <Grid item xs={6}>
              <StatusDropdown
                refetch={({ id, name }) => {
                  setFieldValue('status', { id, label: name });
                }}
              >
                <AutocompleteFieldV2
                  name="status"
                  label="Status"
                  requestKey="task-project-statuses"
                  fetchUrl={taskStatusUrls.list}
                  disabled={!values?.projects}
                  enabled={!!values?.projects?.id}
                  requestKeyOptions={[values?.projects?.id]}
                  urlParams={`&ordering=order&project=${values?.projects?.id}`}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                  required
                />
              </StatusDropdown>
            </Grid>

            <Grid item xs={6}>
              <ResourcesDropdown
                title="Owner"
                refetch={({ id, name }) => {
                  setFieldValue('owner', { id, label: name });
                  queryClient.invalidateQueries(['task-owner']);
                }}
              >
                <AutocompleteFieldV2
                  name="owner"
                  label="Owner"
                  requestKey="task-owner"
                  fetchUrl={resourcesUrls.list}
                  urlParams={`&ordering=created_at`}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                />
              </ResourcesDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={customOpen || open}
        setOpen={setCustomOpen ? setCustomOpen : setOpen}
      />
    </>
  );
}
