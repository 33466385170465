import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { marketingListsRowsMap } from '@config/handleRows/crm';
import { MarketingListForm } from '@config/forms/crm';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { marketingListsUrls } = crmUrls;

export default function MarketingLists({ editMode, labels }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  return (
    <Box>
      <ListView
        label="Marketing Lists"
        editMode={editMode}
        labels={labels}
        prefix="0x4"
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.marketingList}
        urls={marketingListsUrls}
        columnKey="marketingListCRM"
        addNewForm={{
          getFields: ({ values }) => MarketingListForm({ values }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { workflow, ...rest } = values;

              const { data } = await createRecord({
                token: user?.token,
                actAs: user?.actAs,
                url: marketingListsUrls.list(),
                values: { ...rest, workflow: workflow?.id },
              });

              resetForm();
              setAddNewForm(false);
              refetch();

              return data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={marketingListsRowsMap}
      />
    </Box>
  );
}
