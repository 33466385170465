export default function getSchemaFromColumnsForForm(
  columns,
  view = 'listView'
) {
  const schema = {};
  const formView = view === 'listView' ? 'onForm' : 'onAccordionForm';

  const values = columns.filter((column) => column[formView]);
  values.forEach((column) => {
    schema[column.field] = column.validate;
  });

  return schema;
}
