import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { pmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { sprintMetaRowsMap } from '@config/handleRows/pm';
import { SprintMetaForm } from '@config/forms/pm';
import { microservices } from '@config/constants';

const { sprintMetaUrls } = pmUrls;

export default function SprintMetas({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        prefix='028'
        labels={labels}
        editMode={editMode}
        label='Sprint Metas'
        microservice={microservices.PM.name}
        model={microservices.PM.models.sprintMeta}
        urls={sprintMetaUrls}
        columnKey='sprintMetasPM'
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            SprintMetaForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { program, ...rest } = values;
              const response = await createRecord({
                values: {
                  program: program?.id,
                  ...rest,
                },
                url: sprintMetaUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify('Sprint Meta Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={sprintMetaRowsMap}
      />
    </Box>
  );
}
