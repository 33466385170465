import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { colors } from '@config/constants';

export default createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: 'rgb(55, 53, 47)',
    },
    gray: {
      main: 'gray',
    },
    success: { main: '#42a347' },
    white: { main: '#fff' },
    label: { main: 'rgba(255,255,255,0.8)' },
    error: {
      main: red.A400,
    },
    background: {
      default: colors.background,
    },
    // row colors
    lightRedBerry: {
      main: '#d08270',
    },
    lightRed: {
      main: '#d16d6a',
    },
    lightOrange: {
      main: '#ecb476',
    },
    lightYellow: {
      main: '#fad978',
    },
    lightGreen: {
      main: '#9dc284',
    },
    lightCyan: {
      main: '#80a4ae',
    },
    lightCornflower: {
      main: '#779fe5',
    },
    lightBlue: {
      main: '#7ba7d7',
    },
    lightPurple: {
      main: '#8a7fbe',
    },
    lightMagenta: {
      main: '#b87f9e',
    },
    defaultWhite: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
});
