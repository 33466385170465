import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { lmsUrls } from '@config/routes';
import { teamMeta } from '@config/meta/lms';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { teamMemberRowsMap } from '@config/handleRows/lms';
import { TeamMemberForm } from '@config/forms/lms';
import { useQueryClient } from 'react-query';

const { teamsUrls, teamMembersUrls } = lmsUrls;

export default function TeamDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => teamMeta({ data, queryClient }),
    []
  );

  const teamMemberColOptions = React.useMemo(() => {
    return {
      queryClient,
    };
  }, [queryClient]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/teams', text: 'Teams' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={teamsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Team Members'
          prefix='085'
          columnKey='teamMember'
          columnOptions={teamMemberColOptions}
          urls={teamMembersUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              TeamMemberForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { studentId, ...rest } = values;
                await createRecord({
                  values: {
                    ...rest,
                    teamId: recordId,
                    studentId: studentId?.id,
                  },
                  url: teamMembersUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={teamMemberRowsMap}
          urlParams={`&teamId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
