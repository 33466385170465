import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { hrUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { VacancyForm } from '@config/forms/hr';
import { vacancyRowsMap, candidateRowsMap } from '@config/handleRows/hr';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import DetailAccordionView from '@components/DetailAccordionView';
import { useQueryClient } from 'react-query';

const { vacanciesUrls, candidatesUrls } = hrUrls;

export default function Vacancies({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const candidatesColOptions = React.useMemo(() => {
    return {
      queryClient,
      disableStatusEdit: true,
    };
  }, [queryClient]);

  return (
    <Box>
      <ListView
        label="Vacancies"
        prefix="0ni"
        labels={labels}
        editMode={editMode}
        microservice={microservices.HR.name}
        model={microservices.HR.models.vacancy}
        urls={vacanciesUrls}
        tableBulkOptions={[
          {
            id: 'createShortLinks',
            text: 'Create short links',
          },
        ]}
        columnKey="vacancyHR"
        addNewForm={{
          getFields: ({ setFieldValue }) => {
            return VacancyForm({
              setFieldValue,
            });
          },
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const {
                workflow,
                recruitment_pipeline,
                recruit_for,
                hiring_manager,
                internal_responsible,
                job_level,
                ...rest
              } = values;

              const response = await createRecord({
                values: {
                  ...rest,
                  workflow: workflow?.id,
                  recruitment_pipeline: recruitment_pipeline?.id,
                  recruit_for: recruit_for?.id,
                  hiring_manager: hiring_manager?.id,
                  internal_responsible: internal_responsible?.id,
                  job_level: job_level?.id,
                  anonymous_can_see_it: true,
                },
                url: vacanciesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return { id: response?.data?.id, values };
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={vacancyRowsMap}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.HR.name}
          model={microservices.HR.models.candidate}
          columnKey="candidateAdminHR"
          columnOptions={candidatesColOptions}
          label="Candidates"
          prefix="0io"
          labels={labels}
          editMode={editMode}
          urls={candidatesUrls}
          handleRowMap={candidateRowsMap}
        />
      </Box>
    </Box>
  );
}
