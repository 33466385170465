import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Grid,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { TextField, DateTimePickerField } from '@components/Inputs';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';

export default function MapFieldsPopup({
  open,
  setOpen,
  documentFields = [],
  handleSave = () => null,
}) {
  const user = useSelector(selectProfile);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent maxWidth="sm" fullWidth>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Map fields
        </Typography>

        <Formik
          initialValues={{}}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);
              console.log('handleSave', handleSave);
              handleSave(
                Object.keys(values).map((key) => {
                  const [, id] = key.split('|');

                  return {
                    id,
                    value: values[key],
                    createdBy: user?.details?.id,
                    actAs: user?.actAs,
                  };
                })
              );
              resetForm();
              setOpen(false);
            } catch (error) {
              console.log('error', error);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                {Array.isArray(documentFields) &&
                  documentFields
                    .filter(({ type }) => type !== 'Signature')
                    .map((field) => (
                      <React.Fragment key={field?.id}>
                        <Grid item xs={4}>
                          <Typography>{field?.name}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          {field?.type === 'Date' ? (
                            <DateTimePickerField
                              label="Initial Date"
                              name={`docField|${field?.id}`}
                            />
                          ) : (
                            <TextField
                              label="Initial Value"
                              name={`docField|${field?.id}`}
                              multiline
                            />
                          )}
                        </Grid>
                      </React.Fragment>
                    ))}
              </Grid>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
