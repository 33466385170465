import { notificationHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  notifications: 'notifications',
  notificationTrigger: 'notification-trigger',
  notificationAttachments: 'notification-attachments',
  notificationEmailAddresses: 'notification-email-addresses',
  mailTemplates: 'email-templates',
  contextField: 'context-fields',
  imapProviders: 'imap-providers',
  sendEmailFromMailTemplate: 'send-template-emails',
  setOrGetSignature: 'set-signature',
  imapAccounts: 'imap-accounts',
  labels: 'labels',
  notificationLabels: 'notification-labels',
  encryptionEnabled: 'encryption-enabled',
  updateBulkEmailTemplates: 'update-bulk-email-templates',
  cloneSystemTemplates: 'clone-system-templates',
  bulkConnectFromEmailToMailTemplates: 'bulk-connect-from-email-to-mail-templates',
};

const cloneSystemTemplatesUrls = getModelUrls(
  notificationHost,
  models.cloneSystemTemplates
);

const bulkConnectFromEmailToMailTemplatesUrls = getModelUrls(
  notificationHost,
  models.bulkConnectFromEmailToMailTemplates
);

const updateBulkEmailTemplatesUrls = getModelUrls(
  notificationHost,
  models.updateBulkEmailTemplates
);
const encryptionEnabledUrls = getModelUrls(
  notificationHost,
  models.encryptionEnabled
);
const notificationsUrls = getModelUrls(notificationHost, models.notifications);
const notificationTriggerUrls = getModelUrls(
  notificationHost,
  models.notificationTrigger
);
const notificationAttachmentsUrls = getModelUrls(
  notificationHost,
  models.notificationAttachments
);
const notificationEmailAddressesUrls = getModelUrls(
  notificationHost,
  models.notificationEmailAddresses
);
const mailTemplatesUrls = getModelUrls(notificationHost, models.mailTemplates);
const contextFieldUrls = getModelUrls(notificationHost, models.contextField);
const imapProvidersUrls = getModelUrls(notificationHost, models.imapProviders);
const sendEmailFromMailTemplatesUrls = getModelUrls(
  notificationHost,
  models.sendEmailFromMailTemplate
);
const imapAccountsUrls = getModelUrls(notificationHost, models.imapAccounts);

const labelsUrls = getModelUrls(notificationHost, models.labels);
const notificationLabelsUrls = getModelUrls(
  notificationHost,
  models.notificationLabels
);

const setOrGetSignatureUrls = getModelUrls(
  notificationHost,
  models.setOrGetSignature
);

const urls = {
  notificationsUrls,
  bulkConnectFromEmailToMailTemplatesUrls,
  cloneSystemTemplatesUrls,
  notificationTriggerUrls,
  notificationAttachmentsUrls,
  notificationEmailAddressesUrls,
  mailTemplatesUrls,
  contextFieldUrls,
  imapProvidersUrls,
  sendEmailFromMailTemplatesUrls,
  imapAccountsUrls,
  labelsUrls,
  notificationLabelsUrls,
  setOrGetSignatureUrls,
  encryptionEnabledUrls,
  updateBulkEmailTemplatesUrls,
};

export default urls;
