import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import FormHelperText from '@mui/material/FormHelperText';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '4px 10px',
    width: '100%',
    height: '33px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      backgroundColor: '#fff',
    },
  },
}));

const errorStyles = { mt: 1, color: 'red', fontWeight: '500' };

export default function SelectField({
  value,
  setValue,
  label,
  required,
  options = [],
  error,
}) {
  return (
    <>
      <FormControl sx={{ width: '100%' }} variant='standard'>
        <InputLabel
          color='label'
          shrink
          sx={{ color: 'rgba(255,255,255,0.8)', fontSize: '18px' }}
        >
          {label} <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
        </InputLabel>
        <NativeSelect
          value={value}
          onChange={setValue}
          input={<BootstrapInput />}
        >
          {options.map((r) => (
            <option key={r.id} value={r.id}>
              {r.value}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      {error ? <FormHelperText sx={errorStyles}>{error}</FormHelperText> : null}
    </>
  );
}
