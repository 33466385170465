import React from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { systemUrls } from '@config/routes';
import { importLogMap } from '@config/handleRows/system';
import { microservices } from '@config/constants';
import PublicUserErrorScreen from '@components/CMS-V2/PublicUserErrorScreen';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';

const { importLogsUrls } = systemUrls;

export default function ImportLogs({ labels, editMode }) {
  const user = useSelector(selectProfile);

  if (
    Array.isArray(user?.details?.roles_names) &&
    !(
      user?.details?.roles_names.includes('Superusers') ||
      user?.details?.is_superuser
    )
  ) {
    return <PublicUserErrorScreen />;
  }

  return (
    <Box>
      <ListView
        label='Import Logs'
        prefix='0rw'
        labels={labels}
        editMode={editMode}
        microservice={microservices.LOGS.name}
        model={microservices.LOGS.models.importLogs}
        urls={importLogsUrls}
        columnKey='importLogSystem'
        handleRowsMap={importLogMap}
        customSortColumnName='created_at'
        hideActions
      />
    </Box>
  );
}
