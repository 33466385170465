import React from 'react';
import { Box, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import DetailAccordionView from '@components/DetailAccordionView';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { microservices } from '@config/constants';
import useRQuery from '@hooks/useRQuery';

import { accountUrls } from '@config/routes';
import { usersMeta } from '@config/meta/accounts/accountMeta';
import { profileRoleHandleRow } from '@config/handleRows/accounts';
import { ProfileRolesForms } from '@config/forms/accounts';
import { useQueryClient } from 'react-query';
import {
  getAuthHeader,
  handleCreateFormErrors,
} from '@config/functions/helperFunctions';

const {
  adminUsersUrls,
  rolesUrls,
  approveOrRejectUsersUrls,
  profileRolesUrls,
  bulkProfileRolesUrls,
} = accountUrls;

export default function UserDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const [roleTerm, setRoleTerm] = React.useState('');
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();

  const defaultOptions = {
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
    renderRow: (r) => ({ id: r.id, label: r.name }),
  };

  const { data: roles, isFetching: fetchingRoles } = useRQuery({
    key: ['profile-roles', roleTerm, user.token, user?.actAs],
    url: rolesUrls.list(`?search=${roleTerm}&page_size=500`),
    ...defaultOptions,
  });

  const getSchema = React.useCallback((data) => usersMeta({ data }), []);

  const approveOrRejectUser = async (id, action) => {
    try {
      await createRecord({
        url: approveOrRejectUsersUrls.detail(id),
        values: { action: action },
        token: user.token,
        actAs: user?.actAs,
      });
      queryClient.invalidateQueries([`${recordId}-record-details`]);
      notify('Operation Completed', {
        type: 'SUCCESS',
      });
    } catch (error) {
      console.log(error?.response?.data ?? error?.message);
      notify('Something went wrong, try refreshing', {
        type: 'ERROR',
      });
    }
  };

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/users', text: 'Users' },
              {
                text: recordData && recordData.email,
              },
            ]}
          >
            <Button
              variant='outlined'
              sx={{ mr: 2 }}
              onClick={() => approveOrRejectUser(recordData.id, 'accept')}
            >
              Approve
            </Button>
            <Button
              variant='outlined'
              sx={{ mr: 2 }}
              onClick={() => approveOrRejectUser(recordData.id, 'reject')}
            >
              Reject
            </Button>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={adminUsersUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.clientHistory}
          columnKey='profileRolesAccounts'
          label='Roles'
          editMode={editMode}
          labels={labels}
          urls={profileRolesUrls}
          addNewForm={{
            getFields: ({ values, errors }) =>
              ProfileRolesForms({
                values,
                errors,
                roles,
                setRoleTerm,
                fetchingRoles,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const newValues = {
                  profile_roles: [],
                };

                (values.add_roles || []).map((role) => {
                  newValues.profile_roles.push({
                    profile: recordData?.profile,
                    role: role?.id,
                  });
                });

                const response = await createRecord({
                  values: newValues,
                  url: bulkProfileRolesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                console.log(err.response || err.message);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={profileRoleHandleRow}
          urlParams={`&profile=${recordData?.profile}`}
        />
      </Box>
    </Box>
  );
}
