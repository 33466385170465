import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Formik, Form } from 'formik';
import { DateTimePickerField } from '@components/Inputs';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { formatToUTCDateTime } from '@config/functions/helperFunctions';
import * as Yup from 'yup';
import { updateRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';

export default function RottingPopup({
  open,
  setOpen,
  id,
  initialValue,
  url,
  detailLink,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>
          Start Rotting From
        </DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      {detailLink === 'manual-bpa-board' ? (
        <DialogContent>
          <Formik
            initialValues={{
              stage_assigned_date: initialValue
                ? formatToUTCDateTime(initialValue)
                : formatToUTCDateTime(new Date()),
            }}
            validationSchema={Yup.object({
              stage_assigned_date: Yup.string().required(),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                setSubmitting(true);

                await updateRecord({
                  url: url.detail(id),
                  values: values,
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setOpen(false);
                notify('Operation completed', {
                  type: 'SUCCESS',
                });
              } catch (error) {
                notify('There was an error, please try again', {
                  type: 'ERROR',
                });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form noValidate autoComplete="off" style={{ paddingTop: '6px' }}>
                <Grid sx={{ paddingBottom: '22px' }} container spacing={3}>
                  <Grid item sm={12}>
                    <DateTimePickerField
                      label="Date and time"
                      name="stage_assigned_date"
                      showTime
                      showTimezone
                      disablePast
                      required
                    />
                  </Grid>
                </Grid>

                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit" disabled={isSubmitting}>
                    Save
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      ) : (
        <DialogContent>
          <Formik
            initialValues={{
              status_assigned_date: initialValue
                ? formatToUTCDateTime(initialValue)
                : formatToUTCDateTime(new Date()),
            }}
            validationSchema={Yup.object({
              status_assigned_date: Yup.string().required(),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                setSubmitting(true);

                await updateRecord({
                  url: url.detail(id),
                  values: values,
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setOpen(false);
                notify('Operation completed', {
                  type: 'SUCCESS',
                });
              } catch (error) {
                notify('There was an error, please try again', {
                  type: 'ERROR',
                });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form noValidate autoComplete="off" style={{ paddingTop: '6px' }}>
                <Grid sx={{ paddingBottom: '22px' }} container spacing={3}>
                  <Grid item sm={12}>
                    <DateTimePickerField
                      label="Date and time"
                      name="status_assigned_date"
                      showTime
                      showTimezone
                      disablePast
                      required
                    />
                  </Grid>
                </Grid>

                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit" disabled={isSubmitting}>
                    Save
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      )}
    </Dialog>
  );
}
