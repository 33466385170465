import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { lmsUrls, nodeDriveUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { lessonMeta } from '@config/meta/lms';
import {
  lessonProgressHandleRow,
  lessonInstructorRowsMap,
  lessonCoachRowsMap,
  lessonReferencesRowsMap,
} from '@config/handleRows/lms';
import {
  LessonProgressForm,
  LessonInstructorForm,
  LessonCoachForm,
  LessonReferencesForm,
} from '@config/forms/lms';
import { useQueryClient } from 'react-query';

const {
  lessonsUrls,
  lessonProgressesUrls,
  lessonInstructorsUrls,
  lessonCoachesUrls,
  lessonReferencesUrls,
} = lmsUrls;
const { filesUrls } = nodeDriveUrls;

export default function LessonDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => lessonMeta({ data, queryClient }),
    []
  );

  const lessonInstructorColOptions = React.useMemo(() => {
    return {
      queryClient,
    };
  }, [queryClient]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/lessons', text: 'Lessons' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={lessonsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Lesson References'
          prefix='ler'
          columnKey='lessonReferences'
          urls={lessonReferencesUrls}
          addNewForm={{
            getFields: () => LessonReferencesForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                let file = values.attachmentURL;
                if (file) {
                  const formData = new FormData();
                  formData.append('anonymous_can_see_it', true);
                  formData.append('file', file);

                  const { data: fileData } = await createRecord({
                    values: formData,
                    url: filesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  file = fileData.fileUrl;
                }
                const response = await createRecord({
                  values: {
                    attachmentURL: file,
                    lessonId: recordId,
                    notes: values.notes,
                  },
                  url: lessonReferencesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={lessonReferencesRowsMap}
          urlParams={`&lessonId=${recordId}`}
        />

        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Lesson Progress'
          prefix='0kd'
          columnKey='lessonProgress'
          columnOptions={lessonInstructorColOptions}
          urls={lessonProgressesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              LessonProgressForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { studentId, wikiSectionId, ...rest } = values;
                await createRecord({
                  values: {
                    ...rest,
                    lessonId: recordId,
                    studentId: studentId?.id,
                    wikiSectionId: wikiSectionId?.id,
                  },
                  url: lessonProgressesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={lessonProgressHandleRow}
          urlParams={`&lessonId=${recordId}`}
        />
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Lesson Instructor'
          prefix='0wt'
          columnKey='lessonInstructor'
          columnOptions={lessonInstructorColOptions}
          urls={lessonInstructorsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              LessonInstructorForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { instructorId, ...rest } = values;
                await createRecord({
                  values: {
                    ...rest,
                    instructorId: instructorId?.id,
                    lessonId: recordId,
                  },
                  url: lessonInstructorsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={lessonInstructorRowsMap}
          urlParams={`&lessonId=${recordId}`}
        />
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Lesson Coach'
          prefix='0pz'
          columnKey='lessonCoach'
          urls={lessonCoachesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              LessonCoachForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { coachId, ...rest } = values;

                console.log(coachId);

                if (!coachId) {
                  setFieldError('coachId', 'Please select a coach');
                  return;
                }

                await createRecord({
                  values: {
                    ...rest,
                    coachId: coachId?.id,
                    lessonId: recordId,
                  },
                  url: lessonCoachesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err.response.data.message);
                if (err.response.data.statusCode === 401) {
                  notify(`${err.response.data.message}`, { type: 'ERROR' });
                }
                notify(`Subdomains already exists.`, { type: 'ERROR' });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={lessonCoachRowsMap}
          urlParams={`&lessonId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
