import React from 'react';
import * as Yup from 'yup';
import { communicationUrls } from '@config/routes';
import { imapProviderMeta } from '@config/meta/communication';
import { Grid, MenuItem } from '@mui/material';
import { connectionTypeChoices } from '@config/constants';
import { TextField, SelectField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { imapProvidersUrls } = communicationUrls;

export default function ImapProviderDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Imap Provider"
        initialValues={{
          ...getAddFormInitialValues(imapProviderMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(imapProviderMeta(), 'listView'),
        })}
        getPostUrl={() => imapProvidersUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <TextField label="Name" name="name" required />
            </Grid>

            <Grid item xs={6}>
              <TextField label="Host" name="host" required />
            </Grid>

            <Grid item xs={6}>
              <TextField required label="Port" name="port" type="number" />
            </Grid>

            <Grid item md={6}>
              <SelectField
                label="Connection Type"
                name="connection_type"
                required
                defaultValue="TLS"
              >
                {connectionTypeChoices.map((r) => (
                  <MenuItem value={r.value} key={r.value}>
                    {r.label}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
