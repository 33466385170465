import React from 'react';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { computeUrls } from '@config/routes';
import { microservicesMeta } from '@config/meta/compute';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import {
  modelsRowsMap,
  enumsRowsMap,
  computeLogsRowsMap,
  menuDefnRowsMap,
} from '@config/handleRows/compute';
import { EnumsForm, ModelsForm, MenusForm } from '@config/forms/compute';
import { microservices } from '@config/constants';
import GeneratePopup from '@components/Compute/GeneratePopup';
import GenerateErdPopup from '@components/Compute/GenerateErdPopup';

const {
  microservicesUrls,
  enumsUrls,
  modelsUrls,
  instanceUrls,
  menuDefnsUrls,
  // prettier
} = computeUrls;

export default function MicroserviceDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [showGeneratePopup, setShowGeneratePopup] = React.useState(false);
  const [showGenerateErdPopup, setShowGenerateErdPopup] = React.useState(false);
  const [menuListData, setMenuListData] = React.useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => microservicesMeta({ data }),
    []
  );

  const menuColOptions = React.useMemo(
    () => ({
      microserviceId: recordData?.microserviceId,
      nextOrder: menuListData?.totalCount ?? 1,
    }),
    [menuListData?.totalCount, recordData?.microserviceId]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/microservice', text: 'Microservice' },
              { text: recordData?.name },
            ]}
          >
            <Button
              variant='outlined'
              sx={{ mr: 2 }}
              onClick={() => setShowGeneratePopup(true)}
            >
              Generate
            </Button>

            <Button
              variant='outlined'
              onClick={() => setShowGenerateErdPopup(true)}
            >
              ERD
            </Button>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={microservicesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      {showGenerateErdPopup ? (
        <GenerateErdPopup
          user={user}
          open={showGenerateErdPopup}
          setOpen={setShowGenerateErdPopup}
          microserviceId={recordId}
          initialErdUrl={recordData?.erdUrl}
          microserviceName={recordData?.name}
        />
      ) : null}

      {showGeneratePopup ? (
        <GeneratePopup
          user={user}
          open={showGeneratePopup}
          setOpen={setShowGeneratePopup}
          microserviceId={recordId}
          microserviceName={recordData?.name}
        />
      ) : null}

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Enums'
          prefix='fMK'
          microservice={microservices.Compute.name}
          model={microservices.Compute.models.enumDefn}
          importExportFields={`?microserviceId=${recordId}`}
          columnKey='enumsCompute'
          urls={enumsUrls}
          addNewForm={{
            getFields: () => EnumsForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                await createRecord({
                  values: {
                    ...values,
                    microserviceId: recordId,
                  },
                  url: enumsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={enumsRowsMap}
          urlParams={`&microserviceId=${recordId}`}
        />

        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Model'
          prefix='0ra'
          microservice={microservices.Compute.name}
          model={microservices.Compute.models.modelDefn}
          importExportFields={`?microserviceId=${recordId}`}
          columnKey='modelsCompute'
          urls={modelsUrls}
          addNewForm={{
            getFields: () => ModelsForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { ...rest } = values;

                await createRecord({
                  values: {
                    ...rest,
                    microserviceId: recordId,
                  },
                  url: modelsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={modelsRowsMap}
          urlParams={`&microserviceId=${recordId}`}
        />

        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Menu'
          prefix='gNz'
          columnOptions={menuColOptions}
          microservice={microservices.Compute.name}
          model={microservices.Compute.models.menuDefn}
          importExportFields={`?microserviceId=${recordId}`}
          columnKey='menusCompute'
          urls={menuDefnsUrls}
          addNewForm={{
            getFields: () => MenusForm({ microserviceId: recordId }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { modelId, parentMenuId, ...rest } = values;

                if (!modelId?.id) {
                  setFieldError('modelId', 'Please select a model.');
                  return;
                }

                await createRecord({
                  values: {
                    ...rest,
                    microserviceId: recordId,
                    modelId: modelId?.id,
                    parentMenuId: parentMenuId?.id,
                  },
                  url: menuDefnsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          hideSearch
          handleRowMap={menuDefnRowsMap}
          customSortColumnName='order'
          sortColumnBy='asc'
          urlParams={`&microserviceId=${recordId}&parentMenuId=null`}
          getListData={(data) => setMenuListData(data)}
        />

        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Logs'
          prefix='tOn'
          microservice={microservices.Compute.name}
          model={microservices.Compute.models.instance}
          importExportFields={`?microserviceId=${recordId}`}
          columnKey='computeLogs'
          urls={instanceUrls}
          handleRowMap={computeLogsRowsMap}
          urlParams={`&microserviceId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
