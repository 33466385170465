import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { inventoryUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import { DeliveryForm } from '@config/forms/inventory';
import { warehouseMeta } from '@config/meta/inventory';
import DetailPageHeader from '@components/DetailPageHeader';
import { microservices } from '@config/constants';
import { deliveryRowMap } from '@config/handleRows/inventory';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { deliveriesUrls, warehousesUrls } = inventoryUrls;

export default function WarehousesDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function

  const getSchema = React.useCallback(
    (data) => warehouseMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key="detail-view-header"
            items={[
              { to: `/warehouses`, text: 'Warehouses' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={warehousesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix="062"
          microservice={microservices.INVENTORY.name}
          model={microservices.INVENTORY.models.delivery}
          columnKey="deliveryInventory"
          label="Deliveries"
          urls={deliveriesUrls}
          addNewForm={{
            getFields: () => DeliveryForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                await createRecord({
                  values: {
                    ...values,
                    warehouse: recordId,
                  },
                  url: deliveriesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={deliveryRowMap}
          urlParams={`&warehouse=${recordId}`}
        />
      </Box>
    </Box>
  );
}
