import React from 'react';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import { lmsUrls, nodeDriveUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { moduleHandleRow } from '@config/handleRows/lms';
import { ModuleForm } from '@config/forms/lms';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { modulesUrls } = lmsUrls;
const { filesUrls } = nodeDriveUrls;

export default function Modules({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const moduleColOptions = React.useMemo(() => {
    return {
      queryClient,
    };
  }, [queryClient]);

  return (
    <Box>
      <ListView
        nodeAPI
        label='Modules'
        prefix='0qw'
        labels={labels}
        editMode={editMode}
        urls={modulesUrls}
        columnKey='module'
        columnOptions={moduleColOptions}
        addNewForm={{
          getFields: ({ setFieldValue }) => ModuleForm({ setFieldValue }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError,
            setFieldError
          ) => {
            try {
              const { homeworkId, thumbnail, ...rest } = values;

              let fileUrl;

              try {
                if (thumbnail) {
                  const formData = new FormData();
                  formData.append(
                    'client',
                    user?.actAs?.id || user?.details?.client
                  );
                  formData.append('created_by', user?.details?.id);
                  formData.append('anonymous_can_see_it', true);
                  formData.append('file', thumbnail);

                  const { data: fileData } = await createRecord({
                    values: formData,
                    url: filesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  fileUrl = fileData.fileUrl;
                }
              } catch {}

              if (thumbnail && !fileUrl) {
                setFieldError('thumbnail', 'Failed to upload thumbnail');
                return;
              }

              const { data } = await createRecord({
                values: {
                  ...rest,
                  thumbnail: fileUrl || undefined,
                  homeworkId: homeworkId?.id,
                },
                url: modulesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={moduleHandleRow}
      />
    </Box>
  );
}
