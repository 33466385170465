import { wikiHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  books: 'books',
  bookCategory: 'book-categories',
  tags: 'tags',
  chapters: 'chapters',
  sections: 'sections',
  sectionTags: 'section-tags',
  readingProgress: 'reading-progress',
};

const booksUrls = getModelUrls(wikiHost, models.books);
const bookCategoryUrls = getModelUrls(wikiHost, models.bookCategory);
const tagsUrls = getModelUrls(wikiHost, models.tags);
const chaptersUrls = getModelUrls(wikiHost, models.chapters);
const sectionsUrls = getModelUrls(wikiHost, models.sections);
const sectionTagsUrls = getModelUrls(wikiHost, models.sectionTags);
const readingProgressUrls = getModelUrls(wikiHost, models.readingProgress);

const urls = {
  booksUrls,
  bookCategoryUrls,
  tagsUrls,
  chaptersUrls,
  sectionsUrls,
  sectionTagsUrls,
  readingProgressUrls,
};

export default urls;
