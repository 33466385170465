import React from 'react';
import { useQueryClient } from 'react-query';
import { Box, Button, Stack } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  selectOpportunitiesBoard,
  setOpportunities,
} from '@redux/opportunitySlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls, hrUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { updateRecord } from '@config/functions/requests';
import {
  handleDragEnd,
  getAuthHeader,
} from '@config/functions/helperFunctions';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';
import DateTime from '@components/DateTime';
import {
  formatToUTCDate,
  getLastSelectedDropdownOption,
} from '@config/functions/helperFunctions';
import SearchField from '@ui/Inputs/SearchField';
import ErrorScreen from '@components/ErrorScreen';

const { opportunityStagesUrls, pipelinesUrls, opportunitiesUrls } = crmUrls;
const { employeesUrls } = hrUrls;

export default function VacanciesBoard({ labels, editMode }) {
  const { opportunities: opportunitiesState } = useSelector(
    selectOpportunitiesBoard
  );
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [notify, update] = useToast();
  const queryClient = useQueryClient();
  const initialPipeline = getLastSelectedDropdownOption('lastSelectedPipeline');
  const initialSalesPerson = getLastSelectedDropdownOption(
    'lastSelectedSalesPersons'
  );
  const [selectedPipeline, setSelectedPipeline] =
    React.useState(initialPipeline);
  const [selectedSalesPerson, setSelectedSalesPerson] =
    React.useState(initialSalesPerson);

  const [startDate, setStartDate] = React.useState(formatToUTCDate(new Date()));
  const [endDate, setEndDate] = React.useState(formatToUTCDate(new Date()));
  const [filter, setFilter] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [width, setWidth] = React.useState(290);

  const { data, isLoading, isError } = useRQuery({
    key: [
      'opportunities-board',
      selectedPipeline,
      selectedSalesPerson,
      searchValue,
      filter,
      user.token,
      user?.actAs,
    ],
    url: opportunityStagesUrls.list(
      `?pipeline=${selectedPipeline && selectedPipeline.id}&owner=${
        (selectedSalesPerson && selectedSalesPerson.id) || ''
      }${
        filter ? `&start_date=${startDate}&end_date=${endDate}` : ''
      }&search=${searchValue}&search_fields=name&order=order&page_size=1000`
    ),
    options: { enabled: !!(user.token && selectedPipeline) },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleSaveCardDrag = async ({ newList, removedItem, destId }) => {
    const tId = notify('Saving....', { loading: true });
    dispatch(setOpportunities(newList));
    try {
      await updateRecord({
        url: opportunitiesUrls.detail(removedItem.id),
        values: { status: destId },
        token: user.token,
        actAs: user?.actAs,
      });
      queryClient.invalidateQueries(['opportunities-board']);
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    if (data)
      dispatch(setOpportunities(data.map((r) => ({ ...r, name: r.stage }))));
  }, [data, dispatch]);

  React.useEffect(() => {
    const w = localStorage.getItem('ps-opp-width');
    if (w) setWidth(+w);
  }, []);

  if (isError) {
    return (
      <ErrorScreen text='Failed to fetch the resource from server' img='' />
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Header
          title='Opportunities'
          prefix='0n9'
          labels={labels}
          editMode={editMode}
          filterOptions={[
            {
              label: 'Select Pipeline',
              name: 'selectedPipeline',
              requestKey: 'opportunities-kanban-selected-Pipeline',
              fetchUrl: pipelinesUrls.list,
              handleChange: (newValue) => {
                setSelectedPipeline(newValue);
                localStorage.setItem(
                  'lastSelectedPipeline',
                  JSON.stringify(newValue)
                );
              },
              initialValue: initialPipeline,
            },
            {
              label: 'Select Sales Person',
              name: 'selectedSalesPerson',
              requestKey: 'opportunities-kanban-selected-Sales-Person',
              fetchUrl: employeesUrls.list,
              handleChange: (newValue) => {
                setSelectedSalesPerson(newValue);
                localStorage.setItem(
                  'lastSelectedSalesPersons',
                  JSON.stringify(newValue)
                );
              },
              urlParams: `&department_name=Sales`,
              renderRow: (row) => ({
                id: row.id,
                label: row.work_email,
              }),
              initialValue: initialSalesPerson,
            },
          ]}
        />

        <Stack direction='row' spacing={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '90px', mr: 2, ml: 2 }}>
              <DateTime
                label='Start date'
                data={startDate}
                handleSubmit={({ dateObject }) => {
                  setStartDate(formatToUTCDate(dateObject));
                }}
              />
            </Box>
            <Box sx={{ width: '90px', mr: 2 }}>
              <DateTime
                label='End date'
                data={endDate}
                handleSubmit={({ dateObject }) => {
                  setEndDate(formatToUTCDate(dateObject));
                }}
              />
            </Box>

            <Box>
              <Button
                variant='contained'
                disableElevation
                onClick={() => setFilter(Date.now())}
              >
                Filter
              </Button>
            </Box>
          </Box>
          <SearchField setSearch={(term) => setSearchValue(term)} />
          {/* <Box>
            <TextField
              sx={{ width: '80px', mr: 1, ml: 1 }}
              size="small"
              label="Width"
              variant="outlined"
              type="number"
              value={width}
              onChange={(e) => {
                setWidth(e.target.value);
                localStorage.setItem('ps-opp-width', e.target.value);
              }}
              autoComplete="off"
            />
          </Box> */}
        </Stack>
      </Box>

      {!selectedPipeline ? null : isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            <DragDropContext
              onDragEnd={(res) => {
                handleDragEnd(res, opportunitiesState, handleSaveCardDrag);
              }}
            >
              <KanbanBoardList
                detailLink='opportunities'
                data={opportunitiesState}
                width={+width}
                labels={labels}
                editMode={editMode}
              />
            </DragDropContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}
