import React from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import { FreeSoloAutocompleteField } from '@components/Inputs';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { crmUrls } from '@config/routes';
import useRQuery from '@hooks/useRQuery';
import {
  getAuthHeader,
  validateEmail,
} from '@config/functions/helperFunctions';

const { personsUrls } = crmUrls;

export default function EmailTemplateRecipientFields({
  values,
  setFieldValue,
  user,
}) {
  const [personTerm, setPersonTerm] = React.useState('');
  const [persons, setPersons] = React.useState([]);

  const { data: personsList, isFetching: fetchingPersons } = useRQuery({
    key: ['persons-data', personTerm, user.token, user?.actAs],
    url: personsUrls.list(`?search=${personTerm}&page_size=100`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  React.useEffect(() => {
    if (!personsList || !personsList.results) return;

    setPersons(
      personsList.results.map((item) => ({
        label: item?.email,
        static_value: item?.email,
        mapping_field_name: null,
      }))
    );
  }, [personsList]);

  const fields = [
    {
      label: 'To Emails',
      name: 'toEmails',
    },
    {
      label: 'Cc Emails',
      name: 'ccEmails',
    },
    {
      label: 'Bcc Emails',
      name: 'bccEmails',
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex' }}>
              <IconButton
                aria-label="add"
                color="primary"
                sx={{ pl: '1px' }}
                onClick={() =>
                  (values[field?.name] ?? []).find(
                    (v) =>
                      v?.mapping_field_name ===
                      values?.recipientPayloadSectionKey?.key
                  )
                    ? null
                    : setFieldValue(field?.name, [
                        ...(values[field?.name] ?? []),
                        {
                          label: values?.recipientPayloadSectionKey?.fullLabel,
                          static_value: '',
                          mapping_field_name:
                            values?.recipientPayloadSectionKey?.key,
                        },
                      ])
                }
                disabled={!values?.recipientPayloadSectionKey}
              >
                <SubdirectoryArrowRightIcon fontSize="inherit" />
              </IconButton>
              <Box sx={{ width: '100%' }}>
                <FreeSoloAutocompleteField
                  label={field?.label}
                  name={field?.name}
                  loading={fetchingPersons}
                  setSearchTerm={setPersonTerm}
                  options={persons}
                  isOptionEqualToValue={(option, value) =>
                    option?.static_value === value?.static_value
                  }
                  onChange={({ newValue }) => {
                    if (
                      !newValue ||
                      newValue.find(
                        (v) => typeof v !== 'object' && !validateEmail(v)
                      ) ||
                      (typeof newValue[newValue.length - 1] === 'string' &&
                        newValue.find(
                          (v) =>
                            v?.static_value === newValue[newValue.length - 1]
                        ))
                    )
                      return;
                    setFieldValue(
                      field?.name,
                      typeof newValue[newValue.length - 1] === 'string'
                        ? [
                            ...newValue.slice(0, -1),
                            {
                              label: newValue[newValue.length - 1],
                              static_value: newValue[newValue.length - 1],
                              mapping_field_name: null,
                            },
                          ]
                        : [...newValue]
                    );
                  }}
                />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
