import { Grid, TextField } from '@mui/material';
import React from 'react';

export default function UserFields({
  firstName,
  lastName,
  email,
  disabled,
  setFirstName = () => null,
  setLastName = () => null,
  setEmail = () => null,
  form,
  errors,
  initialFirstName,
  initialLastName,
  initialEmail,
}) {
  if (!form?.showFirstName && !form?.showLastName && !form?.showEmail) {
    return null;
  }

  return (
    <Grid container spacing={2.5} sx={{ px: '24px', my: '6px' }}>
      {form?.showFirstName ? (
        <Grid item xs={6}>
          <TextField
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            label='First Name'
            size='small'
            variant='outlined'
            fullWidth
            helperText={errors}
            error={errors}
            required
            InputProps={{
              readOnly: initialFirstName || disabled,
            }}
          />
        </Grid>
      ) : null}

      {form?.showLastName ? (
        <Grid item xs={6}>
          <TextField
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            label='Last Name'
            size='small'
            variant='outlined'
            fullWidth
            required
            InputProps={{
              readOnly: initialLastName || disabled,
            }}
          />
        </Grid>
      ) : null}

      {form?.showEmail ? (
        <Grid item xs={12}>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label='Email Address'
            size='small'
            variant='outlined'
            fullWidth
            required
            InputProps={{
              readOnly: initialEmail || disabled,
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
