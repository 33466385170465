import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Programs from '@pages/PM/Programs';
import ProgramDetail from '@pages/PM/Programs/detail';
import ProjectDetail from '@pages/PM/Projects/detail';
import HlrDetail from '@pages/PM/Hlrs/detail';
import BacklogDetail from '@pages/PM/Backlogs/detail';
import AcceptanceTestDetail from '@pages/PM/AcceptanceTests/detail';
import TaskDetail from '@pages/PM/Tasks/detail';
import TaskStatusDetail from '@pages/PM/TaskStatuses/detail';
import ProjectTemplates from '@pages/PM/ProjectTemplates';
import Resources from '@pages/PM/Resources';
import StakeholderRoles from '@pages/PM/StakeholderRoles';
import WorkCodes from '@pages/PM/WorkCodes';
import TimeSheets from '@pages/PM/TimeSheets';
import WorkingTimes from '@pages/PM/WorkingTimes';
import SprintMetas from '@pages/PM/SprintMetas';
import SprintMetaDetail from '@pages/PM/SprintMetas/detail';
import SprintDetail from '@pages/PM/Sprints/detail';
import Bugs from '@pages/PM/Bugs';
import BugDetail from '@pages/PM/Bugs/detail';
import FeatureRequests from '@pages/PM/FeatureRequests';
import PmDashboard from '@pages/PM/PmDashboard';

export default function () {
  return (
    <>
      <Route path="/programs" element={<WithLayout comp={Programs} />} />
      <Route
        path="/programs/:id"
        element={<WithLayout comp={ProgramDetail} />}
      />
      <Route
        path="/projects/:id"
        element={<WithLayout comp={ProjectDetail} />}
      />
      <Route path="/hlrs/:id" element={<WithLayout comp={HlrDetail} />} />
      <Route
        path="/backlogs/:id"
        element={<WithLayout comp={BacklogDetail} />}
      />
      <Route
        path="/acceptance-tests/:id"
        element={<WithLayout comp={AcceptanceTestDetail} />}
      />

      <Route path="/tasks/:id" element={<WithLayout comp={TaskDetail} />} />

      <Route
        path="/task-statuses/:id"
        element={<WithLayout comp={TaskStatusDetail} />}
      />

      <Route
        path="/project-templates"
        element={<WithLayout comp={ProjectTemplates} />}
      />
      <Route path="/resources" element={<WithLayout comp={Resources} />} />
      <Route
        path="/stakeholder-roles"
        element={<WithLayout comp={StakeholderRoles} />}
      />
      <Route path="/work-codes" element={<WithLayout comp={WorkCodes} />} />
      <Route
        path="/working-times"
        element={<WithLayout comp={WorkingTimes} />}
      />
      <Route path="/time-sheets" element={<WithLayout comp={TimeSheets} />} />
      <Route path="/sprint-metas" element={<WithLayout comp={SprintMetas} />} />
      <Route
        path="/sprint-metas/:id"
        element={<WithLayout comp={SprintMetaDetail} />}
      />
      <Route path="/bugs" element={<WithLayout comp={Bugs} />} />
      <Route path="/bugs/:id" element={<WithLayout comp={BugDetail} />} />
      <Route path="/sprints/:id" element={<WithLayout comp={SprintDetail} />} />
      <Route
        path="/feature-requests"
        element={<WithLayout comp={FeatureRequests} />}
      />
      <Route path="/pm-dashboard" element={<WithLayout comp={PmDashboard} />} />
    </>
  );
}
