import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { urlShortenerUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { LinkForm } from '@config/forms/urlShortener';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { microservices } from '@config/constants';
import { linkRowsMap } from '@config/handleRows/urlShortener';
import { formatToUTCDateTime } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { linkUrls, bulkCreateOrUpdateLinksUrls } = urlShortenerUrls;

export default function Links({ labels, editMode }) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);

  return (
    <Box>
      <ListView
        label='Links'
        prefix='09e'
        labels={labels}
        editMode={editMode}
        microservice={microservices.UrlShortner.name}
        model={microservices.UrlShortner.models.link}
        urls={linkUrls}
        columnKey='linkURLShorteners'
        tableBulkOptions={[
          {
            id: 'updateShortLinks',
            text: 'Update short links',
          },
        ]}
        addNewForm={{
          getFields: ({ values }) =>
            LinkForm({
              values,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { source, medium, campaign, term, content } = values;
              const utmsHaveValue =
                source || medium || campaign || term || content;
              const linksToCreate = [];
              const date = new Date();
              const twoYearsFromNow = date.setFullYear(date.getFullYear() + 1);
              const expiryDate =
                values.retire || formatToUTCDateTime(twoYearsFromNow);

              const linkMeta = {
                domain: process.env.REACT_APP_URL_SHORTENER_HOST,
                retire: expiryDate,
                title: values.title,
                text: values.text,
                destination_url: values.destination_url,
                everyone_can_see_it: values.everyone_can_see_it,
                anonymous_can_see_it: values.anonymous_can_see_it,
                everyone_in_object_company_can_see_it:
                  values.everyone_in_object_company_can_see_it,
                only_these_roles_can_see_it: values.only_these_roles_can_see_it,
                only_these_users_can_see_it: values.only_these_users_can_see_it,
              };

              if (values.addUTMs && utmsHaveValue) {
                const utmInputs = [
                  source || '',
                  medium || '',
                  campaign || '',
                  term || '',
                  content || '',
                ];
                const hasCommas = utmInputs.some((input) =>
                  input.includes(',')
                );

                if (hasCommas) {
                  const maxLength = Math.max(
                    ...utmInputs.map((input) => input.split(',').length)
                  );

                  for (let i = 0; i < maxLength; i++) {
                    linksToCreate.push({
                      ...linkMeta,
                      utm: {
                        source: source.split(',')[i] || source,
                        medium: medium.split(',')[i] || medium,
                        campaign: campaign.split(',')[i] || campaign,
                        term: term.split(',')[i] || term,
                        content: content.split(',')[i] || content,
                        everyone_in_object_company_can_see_it: true,
                      },
                    });
                  }
                } else {
                  linksToCreate.push({
                    ...linkMeta,
                    utm: {
                      source: source || '',
                      medium: medium || '',
                      campaign: campaign || '',
                      term: term || '',
                      content: content || '',
                      everyone_in_object_company_can_see_it: true,
                    },
                  });
                }
              }

              await createRecord({
                values: linksToCreate.length ? linksToCreate : [linkMeta],
                url: bulkCreateOrUpdateLinksUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              queryClient.invalidateQueries();
            } catch (err) {
              console.log(err.message);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={linkRowsMap}
      />
    </Box>
  );
}
