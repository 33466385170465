import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { Formik, Form } from 'formik';
import { TextField } from '@components/Inputs';
import { eDocUrls } from '@config/routes';
import { updateRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';

const { documentFieldInstancesUrls } = eDocUrls;

export default function DocFieldInstanceForm({
  instance,
  user,
  onDelete,
  setDocumentFieldInstances,
}) {
  const [notify] = useToast();

  const updateInstanceState = (key, value) => {
    setDocumentFieldInstances((prevState) =>
      prevState.map((field) =>
        field?.id === instance?.id ? { ...field, [key]: value } : field
      )
    );
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{
          imageX: instance?.imageX ?? 0,
          imageY: instance?.imageY ?? 0,
          width: instance?.width ?? 78,
          height: instance?.height ?? 18,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);

            const page = document.querySelector(
              `[data-page-number="${instance?.pageNumber}"]`
            );

            if (!page?.clientHeight) throw new Error('No page');

            await updateRecord({
              values: {
                ...values,
                pdfX: +instance?.pdfX || undefined,
                pdfY: parseInt(+page?.clientHeight - +values?.imageY),
              },
              url: documentFieldInstancesUrls.detail(instance?.id),
              token: user.token,
              actAs: user?.actAs,
            });
            notify('Operation completed', {
              type: 'SUCCESS',
            });
          } catch (error) {
            notify('There was an error, please try again later.', {
              type: 'ERROR',
            });
            console.log(error?.response?.data ?? error?.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack spacing={2}>
              <TextField
                type="number"
                label="X Position"
                name="imageX"
                afterDefaultOnChange={(e) =>
                  updateInstanceState('imageX', e.target.value)
                }
              />

              <TextField
                type="number"
                label="Y Position"
                name="imageY"
                afterDefaultOnChange={(e) =>
                  updateInstanceState('imageY', e.target.value)
                }
              />

              <TextField
                type="number"
                label="Width"
                name="width"
                afterDefaultOnChange={(e) =>
                  updateInstanceState('width', e.target.value)
                }
              />

              <TextField
                type="number"
                label="Height"
                name="height"
                afterDefaultOnChange={(e) =>
                  updateInstanceState('height', e.target.value)
                }
              />

              <Stack direction="row" spacing={2}>
                <Button color="error" onClick={onDelete}>
                  Delete
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
