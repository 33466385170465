import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import {
  crmUrls,
  calendarUrls,
  nodeDriveUrls,
  communicationUrls,
} from '@config/routes';
import { companiesMeta } from '@config/meta/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import {
  historyRowsMap,
  companySocialMediaRowsMap,
  companyContactRowsMap,
  accountManagersRowsMap,
  companyTerritoriesRowsMap,
  inasRowsMap,
  opportunitiesRowsMap,
} from '@config/handleRows/crm';
import { emailRowsMap } from '@config/handleRows/communication';
import {
  HistoryForm,
  CompanySocialMediaForm,
  CompanyContactForm,
  AccountManagersForm,
  CompanyTerritoriesForm,
  OpportunityForm,
} from '@config/forms/crm';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';
import Button from '@ui/Button';
import ReminderPopup from '@components/ReminderPopup';

const {
  companiesUrls,
  companyHistoryUrls,
  companySocialMediasUrls,
  companyContactUrls,
  companyAccountManagersUrls,
  companyTerritoriesUrls,
  opportunitiesUrls,
} = crmUrls;
const { actionRemindersUrls } = calendarUrls;
const { filesUrls } = nodeDriveUrls;
const { notificationsUrls } = communicationUrls;

export default function CompanyDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const [showAddReminder, setShowAddReminder] = React.useState(false);
  const [expand, setExpand] = React.useState(true);

  const getSchema = React.useCallback(
    (data) => companiesMeta({ data, queryClient }),
    []
  );

  const emailHistoriesColOptions = React.useMemo(() => {
    return { dataPanel: true };
  }, []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/companies', text: 'Companies' },
              { text: recordData?.name },
            ]}
          >
            <Button
              type='button'
              variant='outlined'
              label='Add INA'
              onClick={() => setShowAddReminder(true)}
            />
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={companiesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          columnKey='inasCRM'
          label='INAs'
          editMode={editMode}
          labels={labels}
          prefix='0r4'
          urls={actionRemindersUrls}
          handleRowMap={inasRowsMap}
          urlParams={`&entity=Company&entity_microservice=CRM&reminder_type=INA Reminder Event&entity_id=${recordId}`}
          expand={expand}
          setExpand={setExpand}
        />

        {showAddReminder ? (
          <ReminderPopup
            type='INA'
            microservice='CRM'
            model='Company'
            open={showAddReminder}
            setOpen={setShowAddReminder}
            selectedRows={[recordId]}
            setSelectedRows={() => null}
            link={`${window.location.host}/companies/${recordId}`}
            company={recordData?.name}
            editMode={editMode}
            labels={labels}
            prefix='0bs'
          />
        ) : null}

        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.opportunities}
          columnKey='opportunitiesCRM'
          label='Opportunities'
          editMode={editMode}
          labels={labels}
          prefix='0yq'
          urls={opportunitiesUrls}
          addNewForm={{
            getFields: ({ setFieldValue, values }) =>
              OpportunityForm({
                values,
                setFieldValue,
              }),
            handleSubmit: async (
              {
                channel,
                companyId,
                companyContact,
                pipeline,
                status,
                owner,
                ...values
              },
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const errors = [];

                if (!companyContact?.id) {
                  errors.push('companyContact');
                }

                if (errors.length) {
                  errors.forEach((name) => {
                    setFieldError(name, 'This field is required');
                  });
                  return;
                }

                const response = await createRecord({
                  values: {
                    ...values,
                    company: companyId?.id,
                    channel: channel?.id,
                    company_contact: companyContact?.id,
                    pipeline: pipeline?.id,
                    status: status?.id,
                    owner: owner?.id,
                  },
                  url: opportunitiesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                refetch();
                resetForm();
                setAddNewForm(false);
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={opportunitiesRowsMap}
          urlParams={`&company=${recordId}`}
        />

        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.accountManager}
          columnKey='accountManagersCRM'
          label='Account Managers'
          editMode={editMode}
          labels={labels}
          prefix='0f8'
          urls={companyAccountManagersUrls}
          addNewForm={{
            getFields: ({ setFieldValue, values }) =>
              AccountManagersForm({
                setFieldValue,
                values,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { account_manager, ...rest } = values;

                const response = await createRecord({
                  values: {
                    ...rest,
                    _company: recordId,
                    account_manager: account_manager?.id,
                  },
                  url: companyAccountManagersUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={accountManagersRowsMap}
          urlParams={`&_company=${recordId}`}
        />

        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.companyTerritory}
          columnKey='companyTerritoriesCRM'
          label='Company Territory'
          editMode={editMode}
          labels={labels}
          prefix='0df'
          urls={companyTerritoriesUrls}
          addNewForm={{
            getFields: ({ setFieldValue, values }) =>
              CompanyTerritoriesForm({
                setFieldValue,
                values,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { territory, ...rest } = values;

                const response = await createRecord({
                  values: {
                    ...rest,
                    _company: recordId,
                    territory: territory?.id,
                  },
                  url: companyTerritoriesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={companyTerritoriesRowsMap}
          urlParams={`&_company=${recordId}`}
        />
        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.companySocialMedia}
          columnKey='companySocialMediaCRM'
          label='Company Social Media'
          editMode={editMode}
          labels={labels}
          prefix='0k6'
          urls={companySocialMediasUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              CompanySocialMediaForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { social_media, ...rest } = values;

                if (!social_media?.id) {
                  setFieldError('social_media', 'This field is required');
                  return;
                }

                const response = await createRecord({
                  values: {
                    ...rest,
                    company_id: recordId,
                    social_media: social_media?.id,
                  },
                  url: companySocialMediasUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={companySocialMediaRowsMap}
          urlParams={`&company_id=${recordId}`}
        />
        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.companyHistory}
          columnKey='historyCRM'
          label='Company Notes'
          editMode={editMode}
          labels={labels}
          prefix='02k'
          urls={companyHistoryUrls}
          addNewForm={{
            getFields: ({ values, errors }) => HistoryForm({ errors, values }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                let file = values?.history;

                if (file) {
                  const formData = new FormData();
                  formData.append('anonymous_can_see_it', true);
                  formData.append('file', file);

                  const { data: fileData } = await createRecord({
                    values: formData,
                    url: filesUrls.list(),
                    token: user?.token,
                    actAs: user?.actAs,
                  });

                  file = fileData.fileUrl;
                }

                const { data } = await createRecord({
                  values: {
                    history: file,
                    company_id: recordId,
                    notes: values?.notes,
                  },
                  url: companyHistoryUrls.list(),
                  token: user?.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={historyRowsMap}
          urlParams={`&company_id=${recordId}`}
        />
        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.companyContacts}
          columnKey='companyContactCRM'
          label='Company Contact'
          editMode={editMode}
          labels={labels}
          prefix='02c'
          urls={companyContactUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              CompanyContactForm({
                companyId: recordId,
                companyName: recordData?.name,
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { person, ...rest } = values;
                const errors = [];

                if (!person?.id) {
                  errors.push('person');
                }

                if (errors.length) {
                  errors.forEach((name) => {
                    setFieldError(name, 'This field is required');
                  });

                  return;
                }

                const { data } = await createRecord({
                  values: {
                    ...rest,
                    company_id: recordId,
                    person: person?.id,
                  },
                  url: companyContactUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={companyContactRowsMap}
          urlParams={`&company_id=${recordId}`}
        />

        <DetailAccordionView
          columnKey='emailCommunication'
          columnOptions={emailHistoriesColOptions}
          handleRowsMap={emailRowsMap}
          label='Email History'
          urls={notificationsUrls}
          startFetching={
            Array.isArray(recordData?.all_emails) &&
            recordData?.all_emails.length !== 0
          }
          urlParams={`&all_inbox=true&history_email=${(
            recordData?.all_emails ?? []
          ).toString()}&ordering=-created_at`}
        />
      </Box>
    </Box>
  );
}
