import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { pmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { featureRequestRowsMap } from '@config/handleRows/pm';
import { featureRequestsForm } from '@config/forms/pm';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import useAutocomplete from '@hooks/useAutocomplete';

const { featureRequestsUrls, projectsUrls } = pmUrls;

export default function Programs({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [projectsTerm, setProjectsTerm] = React.useState('');
  const [notify] = useToast();

  const { data: projects, isFetching: fetchingProjects } = useAutocomplete({
    key: 'projects',
    getUrl: projectsUrls.list,
    inputValue: projectsTerm,
  });

  const featureRequestsColOptions = React.useMemo(() => {
    return {
      projects,
      fetchingProjects,
      setProjectsTerm,
    };
  }, [projects, fetchingProjects, setProjectsTerm]);

  return (
    <Box>
      <ListView
        label="Feature Requests"
        microservice={microservices.PM.name}
        model={microservices.PM.models.featureRequests}
        prefix="0zu"
        labels={labels}
        editMode={editMode}
        urls={featureRequestsUrls}
        columnKey="featureRequestPM"
        columnOptions={featureRequestsColOptions}
        addNewForm={{
          getFields: () =>
            featureRequestsForm({
              projects,
              fetchingProjects,
              setProjectsTerm,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { data } = await createRecord({
                values,
                url: featureRequestsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return data.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={featureRequestRowsMap}
      />
    </Box>
  );
}
