import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editMode: false,
  labels: [],
};

export const uiPrefixesBoardSlice = createSlice({
  name: 'uiPrefixesBoard',
  initialState,
  reducers: {
    setEditMode: (state, action) => {
      console.log(action.payload);
      state.editMode = action.payload;
    },
    setLabels: (state, action) => {
      state.labels = action.payload;
    },
  },
});

export const { setEditMode, setLabels } = uiPrefixesBoardSlice.actions;

export const selectUiPrefixesBoard = (state) => state.uiPrefixesBoard;

export default uiPrefixesBoardSlice.reducer;
