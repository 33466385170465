import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import useRQuery from '@hooks/useRQuery';
import ProfileForm from '@components/accounts/ProfileForm';
import { TextField } from '@components/Inputs';
import { selectProfile, setProfile } from '@redux/profileSlice';
import { accountUrls, crmUrls } from '@config/routes';
import { getAuthHeader } from '@config/functions/helperFunctions';

const { editProfileUrls, passwordChangeUrls } = accountUrls;
const { companiesUrls } = crmUrls;

export default function Profile() {
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState('');
  const user = useSelector(selectProfile);

  const handleUpdate = (newProfile) => {
    dispatch(
      setProfile({
        token: user.token,
        actAs: user?.actAs,
        details: newProfile,
      })
    );
  };

  const { data: setCompaniesList } = useRQuery({
    key: ['company', user.token, user?.actAs],
    url: companiesUrls.detail(user?.details?.client),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  useEffect(() => {
    if (!setCompaniesList) return;

    setCompanyName(setCompaniesList.name);
  }, [setCompaniesList]);

  return (
    <Box>
      <Grid
        key='grid-header-container'
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid key='grid-profile-header-container' item xs={4} sm={8} md={6}>
          <ProfileForm
            key='profile-form'
            title='Profile'
            token={user.token}
            urls={editProfileUrls.list()}
            isEditable
            fields={[
              {
                label: 'First Name',
                key: 'first_name',
                value: user.details.first_name || '...',
              },
              {
                label: 'Last Name',
                key: 'last_name',
                value: user.details.last_name || '...',
              },
              {
                label: 'Username',
                key: 'username',
                value: user.details.username || '...',
              },
              {
                label: 'Email',
                key: 'email',
                value: user.details.email || '...',
              },
              {
                label: 'Company',
                key: 'company',
                value:
                  (user?.details?.client && companyName.toUpperCase()) || '...',
              },
              {
                label: 'Is Superuser',
                key: 'is_superuser',
                exclude:
                  !user.details && user.details.is_superuser ? true : false,
                value: user.details && user.details.is_superuser ? 'YES' : 'NO',
              },
              {
                label: 'Profile Picture',
                key: 'profile_picture',
                value: user.details.avatar.profile_picture ? (
                  <img
                    src={`${process.env.REACT_APP_ACCOUNTS_HOST}${user.details.avatar.profile_picture}`}
                    alt={user.details.avatar.profile_picture}
                    width='100'
                    height='100'
                  />
                ) : (
                  '...'
                ),
              },
            ]}
            formData={{
              initialValues: {
                first_name: user.details.first_name || '',
                last_name: user.details.last_name || '',
                username: user.details.username || '',
              },
              validationSchema: Yup.object({
                first_name: Yup.string().max(100),
                last_name: Yup.string().max(100),
                username: Yup.string().max(100).required(),
              }),
              handleUpdate,
            }}
            formFields={
              <>
                <Grid item xs={4} sm={8} md={12}>
                  <TextField
                    key='first_name'
                    variant='outlined'
                    label='First Name'
                    name='first_name'
                  />
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                  <TextField
                    key='last_name'
                    variant='outlined'
                    label='Last Name'
                    name='last_name'
                  />
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                  <TextField
                    key='username'
                    variant='outlined'
                    label='Username'
                    name='username'
                  />
                </Grid>
              </>
            }
          />
        </Grid>

        <Grid key='grid-password-header-container' item xs={4} sm={8} md={6}>
          <ProfileForm
            key='password-profile-form'
            title='Password & Security'
            token={user.token}
            isEditable
            urls={passwordChangeUrls.list()}
            fields={[
              {
                label: 'Password has been set',
                key: 'password_set',
                value:
                  'Choose a strong, unique password that’s at least 8 characters long.',
                md: 12,
              },
            ]}
            formData={{
              initialValues: {
                old_password: '',
                new_password: '',
                confirm_password: '',
              },
              validationSchema: Yup.object({
                old_password: Yup.string().max(100).required(),
                new_password: Yup.string().min(8).max(100).required(),
                confirm_password: Yup.string().when('new_password', {
                  is: (val) => val && val.length,
                  then: Yup.string()
                    .oneOf(
                      [Yup.ref('new_password')],
                      'Both password need to be the same'
                    )
                    .required(),
                }),
              }),
              handleUpdate,
            }}
            formFields={
              <>
                <Grid item xs={4} sm={8} md={12}>
                  <TextField
                    key='old_password'
                    variant='outlined'
                    label='Old Password'
                    name='old_password'
                    type='password'
                  />
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                  <TextField
                    key='new_password'
                    variant='outlined'
                    label='New Password'
                    name='new_password'
                    type='password'
                  />
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                  <TextField
                    key='confirm_password'
                    variant='outlined'
                    label='Confirm Password'
                    name='confirm_password'
                    type='password'
                  />
                </Grid>
              </>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}
