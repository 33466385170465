import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import {
  communicationUrls,
  crmUrls,
  calendarUrls,
  nodeDriveUrls,
} from '@config/routes';
import { personsMeta } from '@config/meta/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import {
  personHistoryRowsMap,
  personCompanyRowsMap,
  personSocialMediaRowsMap,
  inasRowsMap,
  opportunitiesRowsMap,
  personRelationshipsRowsMap,
} from '@config/handleRows/crm';
import { emailRowsMap } from '@config/handleRows/communication';
import {
  PersonHistoryForm,
  PersonCompanyForm,
  PersonSocialMediaForm,
  OpportunityForm,
  PersonRelationshipForm,
} from '@config/forms/crm';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';
import Button from '@ui/Button';
import ReminderPopup from '@components/ReminderPopup';

const {
  personsUrls,
  personHistoryUrls,
  personSocialMediaUrls,
  companyContactUrls,
  opportunitiesUrls,
  personRelationshipsUrls,
} = crmUrls;
const { filesUrls } = nodeDriveUrls;
const { actionRemindersUrls } = calendarUrls;
const { notificationsUrls } = communicationUrls;

export default function PersonDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [expand, setExpand] = React.useState(true);
  const [showAddReminder, setShowAddReminder] = React.useState(false);
  const [notesCount, setNotesCount] = React.useState(1);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      personsMeta({
        data,
        queryClient,
      }),
    []
  );

  const emailHistoriesColOptions = React.useMemo(() => {
    return { dataPanel: true };
  }, []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/persons`, text: 'Persons' },
              { text: recordData && recordData.first_name },
            ]}
          >
            <Button
              type='button'
              variant='outlined'
              label='Add INA'
              onClick={() => setShowAddReminder(true)}
            />
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={personsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          columnKey='inasCRM'
          label='INAs'
          editMode={editMode}
          labels={labels}
          prefix='0r4'
          urls={actionRemindersUrls}
          handleRowMap={inasRowsMap}
          urlParams={`&entity=Person&entity_microservice=CRM&reminder_type=INA Reminder Event&entity_id=${recordId}`}
          expand={expand}
          setExpand={setExpand}
        />

        {showAddReminder ? (
          <ReminderPopup
            type='INA'
            microservice='CRM'
            model='Person'
            open={showAddReminder}
            setOpen={setShowAddReminder}
            selectedRows={[recordId]}
            setSelectedRows={() => null}
            link={`${window.location.host}/persons/${recordId}`}
            company={`${recordData?.first_name} ${recordData?.last_name}`}
            editMode={editMode}
            labels={labels}
            prefix='0bs'
          />
        ) : null}

        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.opportunities}
          columnKey='opportunitiesCRM'
          label='Opportunities'
          editMode={editMode}
          labels={labels}
          prefix='0yq'
          urls={opportunitiesUrls}
          addNewForm={{
            getFields: ({ setFieldValue, values }) =>
              OpportunityForm({
                values,
                setFieldValue,
              }),
            handleSubmit: async (
              {
                channel,
                companyId,
                companyContact,
                pipeline,
                status,
                owner,
                ...values
              },
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const errors = [];

                if (!companyContact?.id) {
                  errors.push('companyContact');
                }

                if (errors.length) {
                  errors.forEach((name) => {
                    setFieldError(name, 'This field is required');
                  });
                  return;
                }

                const response = await createRecord({
                  values: {
                    ...values,
                    company: companyId?.id,
                    channel: channel?.id,
                    company_contact: companyContact?.id,
                    pipeline: pipeline?.id,
                    status: status?.id,
                    owner: owner?.id,
                  },
                  url: opportunitiesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                refetch();
                resetForm();
                setAddNewForm(false);
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={opportunitiesRowsMap}
          urlParams={`&person=${recordId}`}
        />

        <DetailAccordionView
          prefix='Xjm'
          editMode={editMode}
          labels={labels}
          label='Person Relationships'
          urls={personRelationshipsUrls}
          columnKey='personRelationshipCRM'
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              PersonRelationshipForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                if (!values?.relationship?.id) {
                  setFieldError(
                    'relationship',
                    'Please select a relationship.'
                  );
                  return;
                }

                const { data } = await createRecord({
                  values: {
                    ...values,
                    relationship: values?.relationship?.id,
                    person: recordId,
                  },
                  url: personRelationshipsUrls.list(),
                  token: user?.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={personRelationshipsRowsMap}
          urlParams={`&person=${recordId}`}
        />

        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.personHistory}
          columnKey='personHistoryCRM'
          label='Person History'
          editMode={editMode}
          labels={labels}
          prefix='0d0'
          autoHeight={`${notesCount * 36 + 130}px`}
          getListData={(data) => {
            if (Array.isArray(data?.results)) {
              setNotesCount(data?.results?.length);
            }
          }}
          urls={personHistoryUrls}
          addNewForm={{
            getFields: ({ values, errors }) =>
              PersonHistoryForm({ values, errors }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                let file = values.history;
                if (file) {
                  const formData = new FormData();
                  formData.append('anonymous_can_see_it', true);
                  formData.append('file', file);

                  const { data: fileData } = await createRecord({
                    values: formData,
                    url: filesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  file = fileData.fileUrl;
                }
                const response = await createRecord({
                  values: {
                    ...values,
                    history: file,
                    person: recordId,
                    notes: values.notes,
                  },
                  url: personHistoryUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={personHistoryRowsMap}
          urlParams={`&person=${recordId}`}
        />

        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.personSocialMedia}
          columnKey='personSocialMediaCRM'
          label='Person Social Media'
          editMode={editMode}
          labels={labels}
          prefix='08t'
          urls={personSocialMediaUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              PersonSocialMediaForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { social_media, ...rest } = values;

                const response = await createRecord({
                  values: {
                    ...rest,
                    person: recordId,
                    social_media: social_media?.id,
                  },
                  url: personSocialMediaUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                // notify(`Person Social Media Created`, {
                //   type: 'SUCCESS',
                // });
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={personSocialMediaRowsMap}
          urlParams={`&person=${recordId}`}
        />

        <DetailAccordionView
          prefix='0q0'
          editMode={editMode}
          labels={labels}
          label='Person Companies'
          urls={companyContactUrls}
          columnKey='personCompanyCRM'
          addNewForm={{
            getFields: ({ values, setFieldValue }) =>
              PersonCompanyForm({ values, setFieldValue }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { addEndDate, end_date, ...rest } = values;

                const getEndDate = () => {
                  if (!addEndDate) {
                    return null;
                  }

                  if (addEndDate && !end_date) {
                    const date = new Date();
                    const formattedDate = date.toISOString().split('T')[0];
                    console.log(formattedDate);
                    return formattedDate;
                  }

                  return end_date;
                };

                const { data } = await createRecord({
                  values: {
                    ...rest,
                    end_date: getEndDate(),
                    company_id: values?.company_id?.id,
                    person: recordId,
                  },
                  url: companyContactUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={personCompanyRowsMap}
          urlParams={`&person=${recordId}`}
        />

        <DetailAccordionView
          columnKey='emailCommunication'
          columnOptions={emailHistoriesColOptions}
          handleRowsMap={emailRowsMap}
          label='Email History'
          urls={notificationsUrls}
          urlParams={`&all_inbox=true&history_email=${(
            recordData?.all_emails ?? []
          ).toString()}&ordering=-created_at`}
        />
      </Box>
    </Box>
  );
}
