import React from 'react';
import useRQuery from '@hooks/useRQuery';
import { systemUrls } from '@config/routes';

const { uiPrefixesUrls } = systemUrls;

export default function useUiPrefixes() {
  const [labels, setLabels] = React.useState([]);
  const [language, setLanguage] = React.useState('English');

  const { data: uiPrefixes, isLoading: loadingUiPrefixes } = useRQuery({
    key: ['ui-labels-text'],
    url: uiPrefixesUrls.list(),
  });

  React.useEffect(() => {
    if (
      uiPrefixes &&
      Array.isArray(uiPrefixes?.results) &&
      !loadingUiPrefixes
    ) {
      setLabels(uiPrefixes.results);
    }
  }, [loadingUiPrefixes, uiPrefixes]);

  return { labels, loadingUiPrefixes, language, setLanguage };
}
