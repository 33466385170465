import React from 'react';
import { ColumnChart } from 'react-chartkick';
import 'chartkick/chart.js';
import { Box, Grid, Divider } from '@mui/material';
import { pmUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import Header from '@components/KanbanBoardHeader';
import useAutocomplete from '@hooks/useAutocomplete';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

const {
  programsUrls,
  projectsUrls,
  bugStagesUrls,
  taskStagesUrls,
  uatStagesUrls,
} = pmUrls;

export default function PmDashboard() {
  const user = useSelector(selectProfile);
  const [project, setProject] = React.useState(null);
  const [program, setProgram] = React.useState(null);
  const [bugs, setBugs] = React.useState([]);
  const [tasks, setTasks] = React.useState([]);
  const [uats, setUats] = React.useState([]);
  const [programTerm, setProgramTerm] = React.useState('');
  const [projectTerm, setProjectTerm] = React.useState('');

  const { data: programs, isFetching: fetchingPrograms } = useAutocomplete({
    key: 'pm-programs',
    getUrl: programsUrls.list,
    inputValue: programTerm,
  });

  const { data: projects, isFetching: fetchingProjects } = useAutocomplete({
    key: 'pm-projects',
    getUrl: projectsUrls.list,
    inputValue: projectTerm,
  });

  const { data: bugsStatusList } = useRQuery({
    key: ['bugs-board', project, program, user.token, user?.actAs],
    url: bugStagesUrls.list(
      `?order=order${project ? `&project=${project.id}` : ''}${
        program ? `&program=${program.id}` : ''
      }&page_size=200`
    ),
    options: { enabled: !!(user.token && (program || project)) },
    config: getAuthHeader(user.token, user?.actAs),
  });
  const { data: uatStagesList } = useRQuery({
    key: ['uats-board', project, user.token, user?.actAs],
    url: uatStagesUrls.list(
      `?project=${project && project.id}&order=order&page_size=200`
    ),
    options: { enabled: !!(user.token && project) },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: taskStatusList } = useRQuery({
    key: ['tasks-board', project, user.token, user?.actAs],
    program,
    url: taskStagesUrls.list(
      `?project=${project && project.id}${
        program ? `&program=${program.id}` : ''
      }&order=order&page_size=200&item_order=-created_at`
    ),
    options: { enabled: !!(user.token && project) },
    config: getAuthHeader(user.token, user?.actAs),
  });

  React.useEffect(() => {
    if (uatStagesList);
    const uatStages =
      uatStagesList &&
      uatStagesList.map((item) => [item.name, item.items.length]);
    setUats(uatStages);
  }, [uatStagesList]);

  React.useEffect(() => {
    if (taskStatusList);
    const TaskStatus =
      taskStatusList &&
      taskStatusList.map((item) => [item.name, item.items.length]);
    setTasks(TaskStatus);
  }, [taskStatusList]);

  React.useEffect(() => {
    if (bugsStatusList);
    const bugStatus =
      bugsStatusList &&
      bugsStatusList.map((item) => [item.name, item.items.length]);
    setBugs(bugStatus);
  }, [bugsStatusList]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title="PM Dashboard"
        filterOptions={[
          {
            label: 'Program',
            value: program,
            setValue: setProgram,
            inputValue: programTerm,
            setInputValue: setProgramTerm,
            isLoading: fetchingPrograms,
            options: programs,
          },
          {
            label: 'Project',
            value: project,
            setValue: setProject,
            inputValue: projectTerm,
            setInputValue: setProjectTerm,
            isLoading: fetchingProjects,
            options: projects,
          },
        ]}
      />
      <Grid
        key="grid-header-container"
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid
          key="grid-profile-header-container"
          sx={{ mt: 2 }}
          item
          xs={4}
          sm={4}
          md={6}
        >
          <Card variant="outlined">
            <CardContent>
              <Typography
                sx={{
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
                variant="h6"
              >
                <GpsFixedIcon sx={{ mr: 1 }} /> Project Bugs Data
              </Typography>
              {/* <Typography
                sx={{ fontSize: 14, mb: 1 }}
                color='text.secondary'
                gutterBottom
              >
                THIS YEAR
              </Typography> */}
              <Divider key="Divider" sx={{ mb: 2, mt: 2 }} />
              <ColumnChart
                colors={[
                  '#FF0000',
                  '#00FF00',
                  '#00FFFF',
                  '#0000FF',
                  '#ADD8E6',
                  '#FFC0CB',
                  '#FF00FF',
                  '#00008B',
                ]}
                label="Bugs"
                xtitle="Bugs Status"
                ytitle="TotalCount"
                download={{ background: '#fff' }}
                data={bugs}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          key="grid-profile-header-container"
          sx={{ mt: 2 }}
          item
          xs={4}
          sm={4}
          md={6}
        >
          <Card variant="outlined">
            <CardContent>
              <Typography
                sx={{
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
                variant="h6"
              >
                <GpsFixedIcon sx={{ mr: 1 }} /> Task Data
              </Typography>
              {/* <Typography
                sx={{ fontSize: 14, mb: 1 }}
                color='text.secondary'
                gutterBottom
              >
                THIS YEAR
              </Typography> */}
              <Divider key="Divider" sx={{ mb: 2, mt: 2 }} />
              <ColumnChart
                colors={[
                  '#FF0000',
                  '#00FF00',
                  '#00FFFF',
                  '#0000FF',
                  '#ADD8E6',
                  '#FFC0CB',
                  '#FF00FF',
                  '#00008B',
                ]}
                label="Tasks"
                xtitle="Task Status"
                ytitle="TotalCount"
                download={{ background: '#fff' }}
                data={tasks}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          key="grid-profile-header-container"
          sx={{ mt: 2 }}
          item
          xs={4}
          sm={4}
          md={6}
        >
          <Card variant="outlined">
            <CardContent>
              <Typography
                sx={{
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
                variant="h6"
              >
                <GpsFixedIcon sx={{ mr: 1 }} /> Uats Data
              </Typography>
              {/* <Typography
                sx={{ fontSize: 14, mb: 1 }}
                color='text.secondary'
                gutterBottom
              >
                THIS YEAR
              </Typography> */}
              <Divider key="Divider" sx={{ mb: 2, mt: 2 }} />
              <ColumnChart
                colors={[
                  '#FF0000',
                  '#00FF00',
                  '#00FFFF',
                  // '#0000FF',
                  // '#ADD8E6',
                  // '#FFC0CB',
                  // '#FF00FF',
                  // '#00008B',
                ]}
                label="Uats"
                xtitle="Uats Stages"
                ytitle="TotalCount"
                download={{ background: '#fff' }}
                data={uats}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
