import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useToast from '@hooks/useToast';
import ShortLinkAccordion from '@components/BPA-V2/Popups/CreateShortLinksPopup/ShortLinkAccordion';
import PayloadSelector from '@components/BPA-V2/Popups/PayloadSelector';
import ShortLinkFields from '@components/BPA-V2/Popups/CreateShortLinksPopup/ShortLinkFields';
import { handleAccordionItemDelete } from '@config/functions/createShortLinksPopupFunctions';

const cardStyles = {
  p: 2,
  borderRadius: '4px',
  backgroundColor: '#f7f7f7',
  border: '1px solid #c8c8c8',
};

export default function ShortLinkCard({
  index,
  setFieldValue,
  remove,
  workflowId,
  workflowName,
  showAddReminder,
  setShowAddReminder,
  shape,
  shapeAction,
  values,
  shortLink,
  errors,
}) {
  const [notify] = useToast();
  const [isDeleting, setDeleting] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const accordionLabel = `Short Link ${index + 1}`;

  const handleToggle = () => setOpen(!open);
  const handleIna = (moreNotes = '') =>
    setShowAddReminder({
      ...showAddReminder,
      initialNotes: `Ref: Workflows > ${workflowName} > ${shape?.name} > ${
        shapeAction?.name
      } > Short Links > ${accordionLabel}${
        moreNotes ? ` > ${moreNotes}` : ''
      }\n\n`,
      open: true,
    });

  return (
    <ShortLinkAccordion
      open={open}
      setOpen={handleToggle}
      isError={errors?.short_links?.[index] ?? false}
      label={accordionLabel}
      endContent={() => (
        <Button
          variant="text"
          color="error"
          size="small"
          disabled={isDeleting}
          onClick={() => {
            handleAccordionItemDelete({
              setDeleting,
              notify,
              remove,
              index,
            });
          }}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </Button>
      )}
    >
      <Stack spacing={2} sx={cardStyles}>
        <Box>
          <PayloadSelector
            label="Payload Selector"
            workflowId={workflowId}
            payloadSectionName="shortLinkPayloadSection"
            payloadSectionFieldName="shortLinkPayloadSectionKey"
          />
        </Box>

        <ShortLinkFields
          values={values}
          shortLink={shortLink}
          shortLinkIdx={index}
          setFieldValue={setFieldValue}
          handleIna={handleIna}
        />
      </Stack>
    </ShortLinkAccordion>
  );
}
