import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { bpaUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { workflowRowsMap } from '@config/handleRows/bpa';
import { WorkflowForm } from '@config/forms/bpa';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { workflowsUrls, cloneSystemTemplatesUrls } = bpaUrls;

export default function Workflows({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  const workflowsColOptions = React.useMemo(() => {
    return {
      manualWorkflow: false,
    };
  }, []);

  return (
    <Box>
      <ListView
        label="Workflows"
        prefix="0fp"
        labels={labels}
        editMode={editMode}
        microservice={microservices.BPA.name}
        model={microservices.BPA.models.workflow}
        urls={workflowsUrls}
        columnKey="workflow"
        columnOptions={workflowsColOptions}
        addNewForm={{
          getFields: () =>
            WorkflowForm({
              manualWorkflow: false,
              microservice: microservices.BPA.name,
              model: microservices.BPA.models.workflow,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const doFinally = () => {
                resetForm();
                setAddNewForm(false);
                refetch();
              };

              const { template, ...restValues } = values;

              if (template) {
                const response = await createRecord({
                  values: {
                    template_id: template?.id,
                    custom_params: {
                      ...restValues,
                      workflow_type: 'Automata',
                      anonymous_can_see_it: true,
                    },
                  },
                  url: cloneSystemTemplatesUrls.detail(
                    microservices.BPA.models.workflow
                  ),
                  token: user.token,
                  actAs: user?.actAs,
                });
                doFinally();
                return response?.data?.id;
              } else {
                const response = await createRecord({
                  values: {
                    ...restValues,
                    workflow_type: 'Automata',
                    anonymous_can_see_it: true,
                  },
                  url: workflowsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                doFinally();
                return response?.data?.id;
              }
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={workflowRowsMap}
        urlParams={`&workflow_type=Automata`}
      />
    </Box>
  );
}
