import * as Yup from 'yup';
import {
  fieldType,
  wifiTypeOptions,
  twitterTypeOptions,
} from '@config/constants';

export const qrCodeMeta = ({ data } = {}) => [
  {
    field: 'name_of_qrcode',
    headerName: 'Qr Code Name',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.name_of_qrcode : null,
    initialValue: '',
    validate: Yup.string().required(),
    gridStyle: 3,
  },
  {
    field: 'type',
    headerName: 'Type',
    editable: false,
    value: data ? data.type : null,
    initialValue: '',
    width: 100,
    validate: Yup.string(),
    type: fieldType.TEXT,
    gridStyle: 1,
  },
  {
    field: 'qr_code_url',
    headerName: 'Qr Code Url',
    editable: false,
    value: data ? data.qr_code_url : null,
    initialValue: '',
    width: 600,
    validate: Yup.string(),
    type: fieldType.DETAIL_LINK,
    gridStyle: 8,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    onDetailCard: false,
  },
];

export const vcardMeta = (data) => [
  {
    field: 'name_of_qrcode',
    headerName: 'Qr Code Name',
    onForm: true,
    width: 140,
    type: fieldType.LINK,
    to: '/v-card/',
    editable: false,
    value: data ? data.name_of_qrcode : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'first_name',
    headerName: 'First name',
    onForm: true,
    width: 140,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string().required(),
    hideColumn: true,
    value: data ? data.first_name : null,
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    width: 140,
    editable: false,
    initialValue: '',
    validate: Yup.string().required(),
    type: fieldType.TEXT,
    hideColumn: true,
    value: data ? data.last_name : null,
  },
  {
    field: 'email',
    headerName: 'Email',
    onForm: true,
    editable: false,
    value: data ? data.email : null,
    initialValue: '',
    width: 140,
    validate: Yup.string().email(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 240,
    editable: false,
    value: data ? data.phone : null,
    initialValue: '',
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'mobile',
    headerName: 'Mobile',
    width: 240,
    editable: false,
    value: data ? data.mobile : null,
    initialValue: '',
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'fax',
    headerName: 'Fax',
    type: fieldType.TEXT,
    width: 240,
    editable: false,
    value: data && data.fax,
    initialValue: '',
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 140,
    editable: false,
    onForm: true,
    value: data ? data.city : null,
    initialValue: '',
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'street',
    headerName: 'Street',
    width: 240,
    editable: false,
    value: data ? data.street : null,
    initialValue: '',
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'state',
    headerName: 'State',
    width: 240,
    editable: false,
    value: data ? data.state : null,
    initialValue: '',
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },

  {
    field: 'zip',
    headerName: 'Zip',
    onForm: true,
    editable: false,
    value: data ? data.zip : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'country',
    headerName: 'Country',
    editable: false,
    value: data ? data.country : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'website',
    headerName: 'Website',
    editable: false,
    value: data ? data.website : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'summary',
    headerName: 'Summary',
    editable: false,
    value: data ? data.summary : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'qr_code_url',
    headerName: 'Qr Code Url',
    editable: false,
    value: data ? data.qr_code_url : null,
    initialValue: '',
    width: 700,
    validate: Yup.string(),
    type: fieldType.TEXT,
    getHref: (r) => `${r.qr_code_url}`,
  },
  {
    field: 'bgColor',
    headerName: 'Background Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
    onDetailCard: false,
  },
  {
    field: 'fgColor',
    headerName: 'Frontend Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
    onDetailCard: false,
  },
  {
    field: 'shape',
    headerName: 'Shape',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
    onDetailCard: false,
  },
  {
    field: 'logo',
    headerName: 'Logo',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
    onDetailCard: false,
  },
];

export const emailMeta = (data) => [
  {
    field: 'name_of_qrcode',
    headerName: 'QR Code Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.name_of_qrcode : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'email',
    headerName: 'Email',
    onForm: true,
    editable: false,
    value: data ? data.email : null,
    initialValue: '',
    width: 240,
    validate: Yup.string().email().required(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.subject : null,
    initialValue: '',
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'message',
    headerName: 'Message',
    width: 240,
    editable: false,
    value: data ? data.message : null,
    initialValue: '',
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'qr_code_url',
    headerName: 'Qr Code Url',
    editable: false,
    value: data ? data.qr_code_url : null,
    initialValue: '',
    width: 350,
    validate: Yup.string(),
    type: fieldType.TEXT,
    getHref: (r) => `${r.qr_code_url}`,
  },
  {
    field: 'bgColor',
    headerName: 'Background Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'fgColor',
    headerName: 'Frontend Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'shape',
    headerName: 'Shape',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'logo',
    headerName: 'Logo',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
];

export const smsMeta = (data) => [
  {
    field: 'name_of_qrcode',
    headerName: 'QR Code Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.name_of_qrcode : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'number',
    headerName: 'Number',
    onForm: true,
    editable: false,
    value: data ? data.number : null,
    initialValue: '',
    width: 240,
    validate: Yup.number().required(),
    type: fieldType.NUMBER,
    hideColumn: true,
  },

  {
    field: 'message',
    headerName: 'Message',
    width: 240,
    editable: false,
    value: data ? data.message : null,
    initialValue: '',
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'qr_code_url',
    headerName: 'Qr Code Url',
    editable: false,
    value: data ? data.qr_code_url : null,
    initialValue: '',
    width: 350,
    validate: Yup.string(),
    type: fieldType.TEXT,
    getHref: (r) => `${r.qr_code_url}`,
  },
  {
    field: 'bgColor',
    headerName: 'Background Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'fgColor',
    headerName: 'Frontend Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'shape',
    headerName: 'Shape',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'logo',
    headerName: 'Logo',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
];

export const wifiMeta = (data) => [
  {
    field: 'name_of_qrcode',
    headerName: 'QR Code Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.name_of_qrcode : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'network_name',
    headerName: 'Network Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.network_name : null,
    initialValue: '',
    validate: Yup.string().required(),
    hideColumn: true,
  },
  {
    field: 'password',
    headerName: 'Password',
    onForm: true,
    editable: false,
    value: data ? data.password : null,
    initialValue: '',
    width: 240,
    validate: Yup.string().required(),
    type: fieldType.TEXT,
    hideColumn: true,
  },

  {
    field: 'encryption',
    headerName: 'Encryption',
    type: fieldType.SELECT,
    editable: false,
    width: 110,
    onAccordionForm: true,
    options: wifiTypeOptions,
    initialValue: '',
    value: data ? data.encryption : null,
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'hidden',
    headerName: 'Hidden',
    width: 240,
    editable: false,
    value: data ? data.hidden : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
    type: fieldType.BOOLEAN,
    hideColumn: true,
  },
  {
    field: 'qr_code_url',
    headerName: 'Qr Code Url',
    editable: false,
    value: data ? data.qr_code_url : null,
    initialValue: '',
    width: 350,
    validate: Yup.string(),
    type: fieldType.TEXT,
    getHref: (r) => `${r.qr_code_url}`,
  },
  {
    field: 'bgColor',
    headerName: 'Background Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'fgColor',
    headerName: 'Frontend Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'shape',
    headerName: 'Shape',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'logo',
    headerName: 'Logo',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
];

export const twitterMeta = (data) => [
  {
    field: 'name_of_qrcode',
    headerName: 'Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.name_of_qrcode : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'your_username',
    headerName: 'Your Username',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.your_username : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'choose_an_option',
    headerName: 'Option',
    type: fieldType.SELECT,
    editable: false,
    width: 110,
    onAccordionForm: true,
    options: twitterTypeOptions,
    initialValue: '',
    value: data ? data.encryption : null,
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'qr_code_url',
    headerName: 'Qr Code Url',
    editable: false,
    value: data ? data.qr_code_url : null,
    initialValue: '',
    width: 350,
    validate: Yup.string(),
    type: fieldType.TEXT,
    getHref: (r) => `${r.qr_code_url}`,
  },
  {
    field: 'bgColor',
    headerName: 'Background Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'fgColor',
    headerName: 'Frontend Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'shape',
    headerName: 'Shape',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'logo',
    headerName: 'Logo',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
];

export const facebookMeta = () => [
  {
    field: 'name_of_qrcode',
    headerName: 'Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'facebook_URL',
    headerName: 'Facebook URL',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'title',
    headerName: 'Title',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'website',
    headerName: 'Website',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'name',
    headerName: 'name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'qr_code_url',
    headerName: 'Qr Code Url',
    editable: false,
    initialValue: '',
    width: 350,
    validate: Yup.string(),
    type: fieldType.TEXT,
    getHref: (r) => `${r.qr_code_url}`,
  },
  {
    field: 'bgColor',
    headerName: 'Background Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'fgColor',
    headerName: 'Frontend Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'shape',
    headerName: 'Shape',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'logo',
    headerName: 'Logo',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
];

export const appstoreMeta = () => [
  {
    field: 'name_of_qrcode',
    headerName: 'Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'app_name',
    headerName: 'App Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'developer',
    headerName: 'Developer',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'title',
    headerName: 'Title',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'website',
    headerName: 'Website',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'app_store',
    headerName: 'App Store',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'google_play',
    headerName: 'Google Play',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'qr_code_url',
    headerName: 'Qr Code Url',
    editable: false,
    initialValue: '',
    width: 350,
    validate: Yup.string(),
    type: fieldType.TEXT,
    getHref: (r) => `${r.qr_code_url}`,
  },
  {
    field: 'bgColor',
    headerName: 'Background Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'fgColor',
    headerName: 'Frontend Color',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'shape',
    headerName: 'Shape',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'logo',
    headerName: 'Logo',
    editable: false,
    initialValue: '',
    width: 500,
    validate: Yup.string(),
    type: fieldType.TEXT,
    hideColumn: true,
  },
];

export const mp3Meta = () => [
  {
    field: 'name_of_qrcode',
    headerName: 'Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'title',
    headerName: 'Title',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'website',
    headerName: 'Website',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'more_info',
    headerName: 'More Info',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },

  {
    field: 'upload_mp3',
    headerName: 'Upload Mp3',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    initialValue: '',
    validate: Yup.string(),
  },
];

export const pdfMeta = () => [
  {
    field: 'name_of_qrcode',
    headerName: 'Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.name_of_qrcode : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'title',
    headerName: 'Title',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.title : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'website',
    headerName: 'Website',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.website : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'upload_pdf',
    headerName: 'Upload Pdf',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
  },
];

export const imageMeta = () => [
  {
    field: 'name_of_qrcode',
    headerName: 'Name',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.name_of_qrcode : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'Image_upload',
    headerName: 'Upload Image',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'title',
    headerName: 'Title',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.title : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'website',
    headerName: 'Website',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.website : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 240,
    type: fieldType.TEXT,
    editable: false,
    // value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
  },
];
