import * as Yup from 'yup';
import {
  fieldType,
  mediaTypeOptions,
  transactionTypeOptions,
  microservices,
} from '@config/constants';
import getDropdownForm from '@dropdown-forms/getDropdownForm';
import { formatToUTCDate } from '@config/functions/helperFunctions';
import { inventoryUrls, crmUrls, listsUrls } from '@config/routes';


const { productTypesUrls, productsUrls, warehousesUrls, productVariantsUrls, suppliersUrls, deliveriesUrls, variantKeysUrls, manufacturersUrls } = inventoryUrls;
const { companiesUrls, personsUrls } = crmUrls;
const { currenciesUrls, citiesUrls } = listsUrls;



export const variantKeyMeta = ({ data } = {}) => [
  {
    field: 'key',
    headerName: 'Key',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.key : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'show_in_filters',
    headerName: 'Show in filters',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.show_in_filters : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const productTypeMeta = ({
  queryClient,
  data,
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/product-types/',
    width: 180,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.TEXT,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'parent_product_type',
    headerName: 'Parent Product Type',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: productTypesUrls.list,
    requestKey: `${data?.id}-parent-product-type`,
    validate: Yup.mixed(),
    initialValue: data?.parent_product_type
      ? {
          id: data?.parent_product_type,
          label: data?.details?.parent_product_type?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-parent-product-type`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.INVENTORY.name,
          model: microservices.INVENTORY.models.productType,
        }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
  },
];

export const manufacturerMeta = ({
  data,
  queryClient
} = {}) => [
  {
    field: 'manufacturer_company',
    headerName: 'Company',
    width: 190,
    editable: true,
    onForm: true,
    to: '/manufacturers/',
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: companiesUrls.list,
    requestKey: `${data?.id}-manufacturer-company`,
    validate: Yup.mixed(),
    initialValue: data?.manufacturer_company
      ? {
          id: data?.manufacturer_company,
          label: data?.details?.manufacturer_company?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-manufacturer-company`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.CRM.name,
          model: microservices.CRM.models.companies,
        }),
    }),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 180,
    value:
      data &&
      data.manufacturer_company &&
      data.details &&
      data.details.manufacturer_company
        ? data.details.manufacturer_company.phone
        : null,
    onForm: false,
    editable: false,
  },
  {
    field: 'website',
    headerName: 'Website',
    width: 180,
    value:
      data &&
      data.manufacturer_company &&
      data.details &&
      data.details.manufacturer_company
        ? data.details.manufacturer_company.website
        : null,
    onForm: false,
    editable: false,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const manufacturerContactMeta = ({
  data,
  queryClient
} = {}) => [
  {
    field: 'title',
    headerName: 'Title',
    onForm: true,
    onAccordionForm: true,
    initialValue: '',
    width: 180,
    editable: true,
    validate: Yup.string().max(200).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'person',
    headerName: 'Person',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-manufacturer-person`,
    validate: Yup.mixed(),
    initialValue: data?.person
      ? {
          id: data?.person,
          label: data?.details?.person?.email,
        }
      : null,
    renderRow: (row) => ({
        id: row.id,
        label: `${row?.first_name || 'Unamed'} ${row?.last_name || ''} (${
          row?.email || ''
        })`,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-manufacturer-person`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.CRM.name,
          model: microservices.CRM.models.persons,
        }),
    }),
  },
  {
    field: 'status',
    headerName: 'Status',
    onForm: true,
    onAccordionForm: true,
    width: 90,
    editable: true,
    initialValue: false,
    type: fieldType.BOOLEAN,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const supplierContactMeta = ({
  data,
  queryClient
} = {}) => [
  {
    field: 'title',
    headerName: 'Title',
    onForm: true,
    onAccordionForm: true,
    initialValue: '',
    width: 180,
    editable: true,
    validate: Yup.string().max(200).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'person',
    headerName: 'Person',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-supplier-contact-person`,
    validate: Yup.mixed().required("person is a required field"),
    initialValue: data?.person
      ? {
          id: data?.person,
          label: data?.details?.person?.email,
        }
      : null,
    renderRow: (row) => ({
        id: row.id,
        label: `${row?.first_name || 'Unamed'} ${row?.last_name || ''} (${
          row?.email || ''
        })`,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-supplier-contact-person`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.CRM.name,
          model: microservices.CRM.models.persons,
        }),
    }),
  },
  {
    field: 'status',
    headerName: 'Status',
    onForm: true,
    onAccordionForm: true,
    width: 90,
    editable: true,
    initialValue: false,
    type: fieldType.BOOLEAN,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const supplierMeta = ({
  data,
  queryClient
} = {}) => [
  {
    field: 'supplier_company',
    headerName: 'Supplier Company',
    width: 190,
    editable: true,
    onForm: true,
    to: '/suppliers/',
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: companiesUrls.list,
    requestKey: `${data?.id}-supplier-company`,
    validate: Yup.mixed(),
    initialValue: data?.supplier_company
      ? {
          id: data?.supplier_company,
          label: data?.details?.supplier_company?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-supplier-company`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.CRM.name,
          model: microservices.CRM.models.companies,
        }),
    }),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 180,
    value:
      data &&
      data.supplier_company &&
      data.details &&
      data.details.supplier_company
        ? data.details.supplier_company.phone
        : null,
    onForm: false,
    editable: false,
  },
  {
    field: 'website',
    headerName: 'Website',
    width: 180,
    value:
      data &&
      data.supplier_company &&
      data.details &&
      data.details.supplier_company
        ? data.details.supplier_company.website
        : null,
    onForm: false,
    editable: false,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const productMeta = ({
  data,
  queryClient
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/products/',
    width: 180,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(200).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
    type: fieldType.TEXT,
  },
  {
    field: 'slug',
    headerName: 'Slug',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.slug : null,
    initialValue: '',
    validate: Yup.string()
      .matches(/^[a-z](-?[a-z])*$/, 'Invalid slug')
      .required()
      .max(200),
  },
  {
    field: 'product_type',
    headerName: 'Product Type',
    width: 190,
    editable: true,
    onForm: true,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: productTypesUrls.list,
    requestKey: `${data?.id}-product-type`,
    validate: Yup.mixed(),
    initialValue: data?.product_type
      ? {
          id: data?.product_type,
          label: data?.details?.product_type?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-product-type`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.INVENTORY.name,
          model: microservices.INVENTORY.models.productType,
        }),
    }),
  },
  {
    field: 'manufacturer',
    headerName: 'Manufacturer',
    width: 190,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: manufacturersUrls.list,
    requestKey: `${data?.id}-product-manufacturer`,
    validate: Yup.mixed(),
    initialValue: data?.manufacturer
      ? {
          id: data?.manufacturer,
          label: data?.details?.manufacturer?.details?.manufacturer_company?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.details?.manufacturer_company?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-product-manufacturer`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.INVENTORY.name,
          model: microservices.INVENTORY.models.manufacturer,
        }),
    }),
  },
  {
    field: 'min_price',
    headerName: 'Min Price',
    type: fieldType.NUMBER,
    width: 100,
    value: data ? data.min_price : null,
    onForm: false,
    editable: false,
    hideColumn: true,
  },
  {
    field: 'max_price',
    headerName: 'Max Price',
    type: fieldType.NUMBER,
    width: 100,
    value: data ? data.max_price : null,
    onForm: false,
    editable: false,
    hideColumn: true,
  },
  {
    field: 'manufacturer_code',
    headerName: 'Manufacturer Code',
    onForm: true,
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.manufacturer_code : null,
    initialValue: '',
    validate: Yup.string().max(50),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'model',
    headerName: 'Model',
    onForm: true,
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.model : null,
    initialValue: '',
    validate: Yup.string().max(50),
    type: fieldType.TEXT,
    hideColumn: true,
  },
  {
    field: 'discontinued',
    headerName: 'Discontinued',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 90,
    editable: true,
    value: data ? data.discontinued : null,
    initialValue: false,
    validate: Yup.boolean().default(false),
    hideColumn: true,
  },
  {
    field: 'in_eshop',
    headerName: 'In Eshop',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 90,
    editable: true,
    value: data ? data.in_eshop : null,
    initialValue: true,
    validate: Yup.boolean().default(true),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? `${new Date(data.created_at)}` : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const productVariantMeta = ({
  data,
  queryClient
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/product-variants/',
    width: 200,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(200).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
    type: fieldType.Text,
    hideColumn: true,
  },
  {
    field: 'product',
    headerName: 'Product',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    hideColumn: true,
    fetchUrl: productsUrls.list,
    requestKey: `${data?.id}-product-varient-product`,
    validate: Yup.mixed(),
    initialValue: data?.product
      ? {
          id: data?.product,
          label: data?.details?.product?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-product-varient-product`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.INVENTORY.name,
          model: microservices.INVENTORY.models.product,
        }),
    }),
  },
  {
    field: 'manufacturer_code',
    headerName: 'Manufacturer Code',
    onForm: true,
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.manufacturer_code : null,
    initialValue: '',
    validate: Yup.string().max(50),
    type: fieldType.TEXT,
  },
  {
    field: 'our_sku_code',
    headerName: 'Sku Code',
    onForm: true,
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.our_sku_code : null,
    initialValue: '',
    validate: Yup.string().max(50),
    type: fieldType.TEXT,
  },
  {
    field: 'vat_percentage',
    headerName: 'VAT Percentage',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.vat_percentage : null,
    validate: Yup.number().min(0).max(32767),
  },
  {
    field: 'price',
    headerName: 'Price',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.price : null,
    validate: Yup.number().min(0),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const productArtifactMeta = ({
  data,
  queryClient
} = {}) => [
  {
    field: 'artifact',
    headerName: 'Artifact',
    onAccordionForm: true,
    width: 1075,
    editable: true,
    value: data ? data.artifact : null,
    initialValue: '',
    validate: Yup.mixed().required('Please select a file'),
    getHref: (r) => r.artifact,
    linkInNewTab: true,
    type: fieldType.UPLOAD,
  },
  {
    field: 'product',
    headerName: 'Product',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    hideColumn: true,
    fetchUrl: productsUrls.list,
    requestKey: `${data?.id}-product-artifact-product`,
    validate: Yup.mixed(),
    initialValue: data?.product
      ? {
          id: data?.product,
          label: data?.details?.product?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-product-artifact-product`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.INVENTORY.name,
          model: microservices.INVENTORY.models.product,
        }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const productSupplierMeta = ({
  data,
} = {}) => [
  {
    field: 'product_variant',
    headerName: 'Product Variant',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    hideColumn: true,
    fetchUrl: productVariantsUrls.list,
    requestKey: `${data?.id}-product-supplier-product-variant`,
    validate: Yup.mixed(),
    initialValue: data?.product_variant
      ? {
          id: data?.product_variant,
          label: data?.details?.product_variant?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'supplier',
    headerName: 'Supplier',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: suppliersUrls.list,
    requestKey: `${data?.id}-product-supplier-supplier`,
    validate: Yup.mixed(),
    initialValue: data?.supplier
      ? {
          id: data?.supplier,
          label: data?.details?.supplier?.details?.supplier_company?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.details?.supplier_company?.name,
    }),
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: currenciesUrls.list,
    requestKey: `${data?.id}-product-supplier-currency`,
    validate: Yup.mixed(),
    initialValue: data?.currency
      ? {
          id: data?.currency,
          label: data?.details?.currency?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'cost',
    headerName: 'Cost',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.cost : null,
    validate: Yup.number().min(0),
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.quantity : null,
    validate: Yup.number().min(0),
  },
  {
    field: 'supplier_code',
    headerName: 'Supplier Code',
    onForm: true,
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.supplier_code : null,
    initialValue: '',
    validate: Yup.string().max(50),
    type: fieldType.TEXT,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const warehouseMeta = ({
  data,
  queryClient
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/warehouses/',
    width: 180,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().max(200).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string().max(2500),
    type: fieldType.TEXT,
  },
  {
    field: 'address1',
    headerName: 'Address 1',
    onForm: true,
    onAccordionForm: true,
    editable: true,
    width: 200,
    initialValue: '',
    value: data ? data.address1 : null,
    validate: Yup.string().max(300).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 300,
    editable: true,
    onForm: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: citiesUrls.list,
    requestKey: `${data?.id}-warehouse-city`,
    validate: Yup.mixed().required("city is a required field"),
    initialValue: data?.city
      ? {
          id: data?.city,
          label: data?.details?.city?.name,
        }
      : null,
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-warehouse-city`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.LIST.name,
          model: microservices.LIST.models.cities,
        }),
    }),
  },
  {
    field: 'keyholder_name',
    headerName: 'Keyholder Name',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data ? data.keyholder_name : null,
    initialValue: '',
    validate: Yup.string().max(200).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'keyholder_addr1',
    headerName: 'Keyholder Address 1',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data && data.keyholder_addr1 ? data.keyholder_addr1 : null,
    initialValue: '',
    validate: Yup.string().max(300).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'keyholder_city',
    headerName: 'Keyholder City',
    width: 300,
    editable: true,
    onForm: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: citiesUrls.list,
    requestKey: `${data?.id}-warehouse-keyholder-city`,
    validate: Yup.mixed().required("keyholder_city is a required field"),
    initialValue: data?.keyholder_city
      ? {
          id: data?.keyholder_city,
          label: data?.details?.keyholder_city?.name,
        }
      : null,
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-warehouse-keyholder-city`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.LIST.name,
          model: microservices.LIST.models.cities,
        }),
    }),
  },
  {
    field: 'keyholder_mobile',
    headerName: 'Keyholder Mobile',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data && data.keyholder_mobile ? data.keyholder_mobile : null,
    initialValue: '',
    validate: Yup.string().max(25).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'keyholder_email',
    headerName: 'Keyholder Email',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data && data.keyholder_email ? data.keyholder_email : null,
    initialValue: '',
    validate: Yup.string().email().max(254).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'keycode',
    headerName: 'Key Code',
    onForm: true,
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.keycode : null,
    initialValue: '',
    validate: Yup.string().max(25).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const warehouseInventoryMeta = ({data}= {}) => [
  {
    field: 'product_variant',
    headerName: 'Product Variant',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    hideColumn: true,
    fetchUrl: productVariantsUrls.list,
    requestKey: `${data?.id}-warehouse-inventory-product-variant`,
    validate: Yup.mixed(),
    initialValue: data?.product_variant
      ? {
          id: data?.product_variant,
          label: data?.details?.product_variant?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'warehouse',
    headerName: 'Warehouse',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    hideColumn: true,
    fetchUrl: warehousesUrls.list,
    requestKey: `${data?.id}-warehouse-inventory-warehouse`,
    validate: Yup.mixed(),
    initialValue: data?.warehouse
      ? {
          id: data?.warehouse,
          label: data?.details?.warehouse?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'location_in_warehouse',
    headerName: 'Location In Warehouse',
    onForm: true,
    onAccordionForm: true,
    editable: true,
    width: 200,
    initialValue: '',
    value: data ? data.location_in_warehouse : null,
    validate: Yup.string().max(2500),
    type: fieldType.TEXT,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    initialValue: 1,
    value: data ? data.quantity : null,
    validate: Yup.number().min(0).default(1),
  },
  {
    field: 'warehouse_city',
    headerName: 'Warehouse City',
    width: 180,
    editable: false,
    value:
      data &&
      data.warehouse &&
      data.details &&
      data.details.warehouse &&
      data.details.warehouse.city &&
      data.details.warehouse.details &&
      data.details.warehouse.details.city
        ? data.details.warehouse.details.city.name
        : null,
    onForm: false,
    hideColumn: true,
    validate: Yup.string(),
    type: fieldType.TEXT,
  },
  {
    field: 'warehouse_keyholder_name',
    headerName: 'Warehouse Keyholder Name',
    width: 180,
    editable: false,
    value:
      data && data.warehouse && data.details && data.details.warehouse
        ? data.details.warehouse.keyholder_name
        : null,
    onForm: false,
    validate: Yup.string(),
    type: fieldType.TEXT,
  },
  {
    field: 'warehouse_keyholder_addr1',
    headerName: 'Warehouse Keyholder Address',
    width: 180,
    editable: false,
    value:
      data && data.warehouse && data.details && data.details.warehouse
        ? data.details.warehouse.keyholder_addr1
        : null,
    onForm: false,
    validate: Yup.string(),
    type: fieldType.TEXT,
  },
  {
    field: 'warehouse_keyholder_city',
    headerName: 'Warehouse Keyholder City',
    width: 180,
    editable: false,
    hideColumn: true,
    value:
      data &&
      data.warehouse &&
      data.details &&
      data.details.warehouse &&
      data.details.warehouse.keyholder_city &&
      data.details.warehouse.details &&
      data.details.warehouse.details.keyholder_city
        ? data.details.warehouse.details.keyholder_city.name
        : null,
    onForm: false,
    validate: Yup.string(),
    type: fieldType.TEXT,
  },
  {
    field: 'warehouse_keyholder_mobile',
    headerName: 'Warehouse Keyholder Mobile',
    width: 180,
    editable: false,
    value:
      data && data.warehouse && data.details && data.details.warehouse
        ? data.details.warehouse.keyholder_mobile
        : null,
    onForm: false,
    validate: Yup.string(),
    type: fieldType.TEXT,
  },
  {
    field: 'warehouse_keyholder_email',
    headerName: 'Warehouse Keyholder Email',
    width: 180,
    editable: false,
    value:
      data && data.warehouse && data.details && data.details.warehouse
        ? data.details.warehouse.keyholder_email
        : null,
    onForm: false,
    validate: Yup.string(),
    type: fieldType.TEXT,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const deliveryMeta = ({
  data,
} = {}) => [
  {
    field: 'delivered_by',
    to: '/deliveries/',
    headerName: 'Delivered By',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data ? data.delivered_by : null,
    initialValue: '',
    validate: Yup.string().max(300).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'received_by',
    headerName: 'Received By',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data ? data.received_by : null,
    initialValue: '',
    validate: Yup.string().max(300).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'delivery_note_ref',
    headerName: 'Delivery Note Ref',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.delivery_note_ref : null,
    initialValue: '',
    validate: Yup.string().max(2500),
    type: fieldType.TEXT,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.quantity : null,
    validate: Yup.number().min(0),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const deliveryItemMeta = ({
  data,
} = {}) => [
  {
    field: 'delivery',
    headerName: 'Delivery',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: deliveriesUrls.list,
    requestKey: `${data?.id}-delivery-item`,
    validate: Yup.mixed(),
    initialValue: data?.delivery
      ? {
          id: data?.delivery,
          label: data?.details?.delivery?.delivered_by,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.delivered_by,
    }),
  },
  {
    field: 'product_variant',
    headerName: 'Product Variant',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    hideColumn: true,
    fetchUrl: productVariantsUrls.list,
    requestKey: `${data?.id}-warehouse-inventory-product-variant`,
    validate: Yup.mixed(),
    initialValue: data?.product_variant
      ? {
          id: data?.product_variant,
          label: data?.details?.product_variant?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'in_order',
    headerName: 'In Order',
    onForm: true,
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data ? data.in_order : null,
    initialValue: '',
    validate: Yup.string().max(200),
    type: fieldType.TEXT,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.notes : null,
    initialValue: '',
    validate: Yup.string().max(2500),
    type: fieldType.TEXT,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.quantity : null,
    validate: Yup.number().min(0),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const deliveryArtifactMeta = ({
  data
} = {}) => [
  {
    field: 'artifact',
    headerName: 'Artifact',
    onAccordionForm: true,
    width: 1075,
    editable: true,
    value: data ? data.artifact : null,
    initialValue: '',
    validate: Yup.mixed().required('Please select a file'),
    getHref: (r) => r.artifact,
    linkInNewTab: true,
    type: fieldType.UPLOAD,

  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const variantValueMeta = ({
  data
} = {}) => [
  {
    field: 'product_variant',
    headerName: 'Product Variant',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    hideColumn: true,
    fetchUrl: productVariantsUrls.list,
    requestKey: `${data?.id}-warehouse-inventory-product-variant`,
    validate: Yup.mixed(),
    initialValue: data?.product_variant
      ? {
          id: data?.product_variant,
          label: data?.details?.product_variant?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'key',
    headerName: 'Variant Key',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    hideColumn: true,
    fetchUrl: variantKeysUrls.list,
    requestKey: `${data?.id}-variant-value-key`,
    validate: Yup.mixed(),
    initialValue: data?.key
      ? {
          id: data?.key,
          label: data?.details?.key?.key,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.key,
    }),
  },
  {
    field: 'value',
    headerName: 'Value',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.value : null,
    initialValue: '',
    validate: Yup.string().max(2500),
    type: fieldType.TEXT,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const productAdditionalInfoMeta = ({ data } = {}) => [
  {
    field: 'key',
    headerName: 'Key',
    onForm: true,
    onAccordionForm: true,
    initialValue: '',
    width: 180,
    editable: true,
    validate: Yup.string().max(200).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'value',
    headerName: 'Value',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.value : null,
    initialValue: '',
    validate: Yup.string().max(2500),
    type: fieldType.TEXT,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    editable: false,
    width: 180,
    type: fieldType.DATE_TIME,
  },
];

export const inventoryMeta = ({
  data,
  queryClient,
} = {}) => [
  {
    field: 'status',
    headerName: 'Status',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.SELECT,
    editable: true,
    width: 200,
    options: [
      { value: 'Reserved', label: 'Reserved' },
      { value: 'Not for Sale', label: 'Not for Sale' },
      { value: 'For Sale', label: 'For Sale' },
    ],
    to: '/inventorys/',
    initialValue: 'Reserved',
    value: data ? data.status : null,
  },
  {
    field: 'location_in_warehouse',
    headerName: 'Location In Warehouse',
    onForm: true,
    onAccordionForm: true,
    editable: true,
    width: 200,
    value: data ? data.location_in_warehouse : null,
    validate: Yup.string().max(2500).required(),
    type: fieldType.TEXT,
  },
  {
    field: 'stock_level',
    headerName: 'Stock Level',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.stock_level : null,
    validate: Yup.number().min(0).max(32767),
  },
  {
    field: 'reorder_level',
    headerName: 'Reorder Level',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.reorder_level : null,
    validate: Yup.number().min(0).max(32767),
  },
  {
    field: 'max_stock_level',
    headerName: 'Max Stock Level',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.max_stock_level : null,
    validate: Yup.number().min(0).max(32767),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.notes : null,
    initialValue: '',
    validate: Yup.string().max(2500),
    type: fieldType.TEXT,
  },
  {
    field: 'product',
    headerName: 'Product',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    hideColumn: true,
    fetchUrl: productsUrls.list,
    requestKey: `${data?.id}-inventory-product`,
    validate: Yup.mixed(),
    initialValue: data?.product
      ? {
          id: data?.product,
          label: data?.details?.product?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-inventory-product`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.INVENTORY.name,
          model: microservices.INVENTORY.models.product,
        }),
    }),
  },
  {
    field: 'warehouse',
    headerName: 'Warehouse',
    width: 190,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: warehousesUrls.list,
    requestKey: `${data?.id}-inventory-warehouse`,
    validate: Yup.mixed(),
    initialValue: data?.warehouse
      ? {
          id: data?.warehouse,
          label: data?.details?.warehouse?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-inventory-warehouse`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.INVENTORY.name,
          model: microservices.INVENTORY.models.warehouse,
        }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    editable: false,
    width: 180,
    value: data ? data.created_at : null,
    type: fieldType.DATE_TIME,
  },
];

export const inventoryTransactioMeta = ({
  data,
} = {}) => [
  {
    field: 'transaction_type',
    headerName: 'Transaction Type',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.SELECT,
    editable: true,
    width: 200,
    options: transactionTypeOptions,
    initialValue: '',
    value: data ? data.transaction_type : null,
    validate: Yup.string().required(),
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.quantity : null,
    validate: Yup.number().min(0),
  },
  {
    field: 'transaction_date',
    headerName: 'Transaction Date',
    type: fieldType.DATE,
    editable: true,
    width: 200,
    initialValue: formatToUTCDate(new Date()),
    onAccordionForm: true,
    onForm: true,
    value: data ? formatToUTCDate(new Date(data.transaction_date)) : null,
    validate: Yup.string(),
  },
  {
    field: 'po_number',
    headerName: 'Po Number',
    onForm: true,
    onAccordionForm: true,
    type: fieldType.NUMBER,
    width: 90,
    editable: true,
    value: data ? data.po_number : null,
    validate: Yup.number().min(0).required(),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    onForm: true,
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.notes : null,
    initialValue: '',
    validate: Yup.string().max(2500),
    type: fieldType.TEXT,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    editable: false,
    width: 180,
    type: fieldType.DATE_TIME,
  },
];

export const mediaMeta = ({
  data,
} = {}) => [
  {
    field: 'url',
    headerName: 'Url',
    onAccordionForm: true,
    width: 1075,
    editable: true,
    value: data ? data.url : null,
    initialValue: '',
    validate: Yup.mixed().required('Please select a file'),
    getHref: (r) => r.url,
    linkInNewTab: true,
    type: fieldType.UPLOAD,

  },
  {
    field: 'type',
    headerName: 'Type',
    onForm: false,
    type: fieldType.SELECT,
    editable: false,
    width: 200,
    options: [...mediaTypeOptions],
    initialValue: 'Image',
    value: data ? data.type : null,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    editable: false,
    width: 180,
    type: fieldType.DATE_TIME,
  },
];
