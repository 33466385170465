import React from 'react';
import { Box, Button } from '@mui/material';

export default function FlowchartHeader({ toolbarRef, undoManager, graph }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div className='ps-bpm-toolbar' ref={toolbarRef} />

      <Box sx={{ pr: 2, mb: '12px' }}>
        <Button
          onClick={() => {
            undoManager.undo();
          }}
        >
          Undo
        </Button>
        <Button
          onClick={() => {
            undoManager.redo();
          }}
        >
          Redo
        </Button>
        <Button
          onClick={() => {
            graph.zoomIn();
          }}
        >
          Zoom in
        </Button>
        <Button
          onClick={() => {
            graph.zoomOut();
          }}
        >
          Zoom out
        </Button>
      </Box>
    </Box>
  );
}
