import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function DeletePromptModal({
  open,
  disabledDeleteButton,
  onCancelClick,
  onDeleteClick,
  labels,
  editMode,
  prefix,
  prefix2
}) {
  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      aria-labelledby='delete-dialog-title'
      aria-describedby='delete-dialog-description'
    >
      {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="Move to trash?"
          >
            {(text) => {
              return <DialogTitle id='delete-dialog-title'>{text}</DialogTitle>;
            }}
          </RenderUiPrefix>
        ) : (
            <DialogTitle id='delete-dialog-title'>
              Move to trash?
            </DialogTitle>
        )}
      <DialogContent>
        
        {prefix2 ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix2}
            defaultValue="Are you sure you want to move this record to trash?"
          >
            {(text) => {
              return <DialogContentText id='delete-dialog-description'>{text}</DialogContentText>;
            }}
          </RenderUiPrefix>
        ) : (
          <DialogContentText id='delete-dialog-description'>
              Are you sure you want to move this record to trash?
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button
          color='error'
          onClick={onDeleteClick}
          disabled={disabledDeleteButton}
        >
          Trash
        </Button>
      </DialogActions>
    </Dialog>
  );
}
