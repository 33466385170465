import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import useToast from '@hooks/useToast';
import { bpaUrls } from '@config/routes';
import { AutocompleteFieldV2 } from '@components/Inputs';
import EmailTemplateAccordion from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateAccordion';
import PayloadSelector from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/PayloadSelector';
import EmailTemplateFormFields from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateFormFields';
import {
  getReadOnlyStyles,
  handleAccordionItemDelete,
} from '@config/functions/emailTemplateConfigurationPopupFunctions';

const cardStyles = {
  p: 2,
  borderRadius: '4px',
  backgroundColor: '#f7f7f7',
  border: '1px solid #c8c8c8',
};

const { modelAssetsUrls } = bpaUrls;

export default function EmailTemplateFormCard({
  index,
  setFieldValue,
  remove,
  workflowId,
  workflowName,
  showAddReminder,
  setShowAddReminder,
  shape,
  shapeAction,
  values,
  user,
  isKanbanView,
  form,
  errors,
}) {
  const [notify] = useToast();
  const [isDeleting, setDeleting] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const accordionLabel = `Form ${index + 1}`;
  const fieldName = `forms-${index}-form`;

  const handleToggle = () => setOpen(!open);
  const handleIna = (moreNotes = '') =>
    setShowAddReminder({
      ...showAddReminder,
      initialNotes: `Ref: Workflows > ${workflowName} > ${shape?.name} > ${
        shapeAction?.name
      } > Forms > ${accordionLabel}${moreNotes ? ` > ${moreNotes}` : ''}\n\n`,
      open: true,
    });

  // Renders form options
  const renderForm = (row) => {
    const { id, name } = row?.details?.asset ?? {};

    return {
      label: name,
      static_value: id,
      mapping_field_name: null,
    };
  };

  return (
    <EmailTemplateAccordion
      open={open}
      setOpen={handleToggle}
      isError={errors?.forms?.[index] ?? false}
      label={accordionLabel}
      endContent={() => (
        <Button
          variant="text"
          color="error"
          size="small"
          disabled={isDeleting || isKanbanView}
          onClick={() => {
            handleAccordionItemDelete({
              setDeleting,
              notify,
              remove,
              index,
            });
          }}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </Button>
      )}
    >
      <Stack
        spacing={2}
        sx={{
          ...cardStyles,
          ...getReadOnlyStyles(isKanbanView),
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <AutocompleteFieldV2
            idKey="static_value"
            label="Form"
            values={values}
            name={fieldName}
            fetchUrl={modelAssetsUrls.list}
            urlParams={`&microservice=Forms&model=form&workflow=${workflowId}`}
            requestKey={`email-template-setup-form-${index}-${workflowId}`}
            renderRow={renderForm}
            onChange={(newValue) => {
              if (!newValue) return;
              const newForms = [...values.forms];
              newForms[index][fieldName] = newValue;
              setFieldValue('forms', newForms);
            }}
            required
          />
          <IconButton
            aria-label="add-ina"
            color="primary"
            sx={{ pr: '1px' }}
            disabled={isKanbanView}
            onClick={() => handleIna('Form')}
          >
            <PlaylistAddIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box sx={getReadOnlyStyles(isKanbanView)}>
          <PayloadSelector
            isKanbanView={isKanbanView}
            label="Payload Selector"
            workflowId={workflowId}
            payloadSectionName="formPayloadSection"
            payloadSectionFieldName="formPayloadSectionKey"
          />
        </Box>

        <EmailTemplateFormFields
          values={values}
          form={form}
          formIdx={index}
          setFieldValue={setFieldValue}
          user={user}
          handleIna={handleIna}
          isKanbanView={isKanbanView}
        />
      </Stack>
    </EmailTemplateAccordion>
  );
}
