import React from 'react';
import * as Yup from 'yup';
import { marketingUrls } from '@config/routes';
import { keywordMeta } from '@config/meta/marketing';
import { Grid, MenuItem } from '@mui/material';
import { TextField, SelectField } from '@components/Inputs';
import { intentChoicesOptions, typeChoicesOptions } from '@config/constants';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { keywordUrls } = marketingUrls;

export default function KeywordDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="Keyword"
        initialValues={{
          ...getAddFormInitialValues(keywordMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(keywordMeta(), 'listView'),
        })}
        getPostUrl={() => keywordUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField
                key="keyword"
                label="Keyword"
                name="keyword"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <SelectField label="Intent" name="intent" required>
                {intentChoicesOptions.map((r) => (
                  <MenuItem value={r.value} key={r.value}>
                    {r.label}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={6}>
              <SelectField label="Type" name="type" required>
                {typeChoicesOptions.map((r) => (
                  <MenuItem value={r.value} key={r.value}>
                    {r.label}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
