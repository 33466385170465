import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { listsUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { timeZonesRowsMap } from '@config/handleRows/list';
import { TimeZonesForm } from '@config/forms/list';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { timeZonesUrls } = listsUrls;

export default function TimeZones({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label='TimeZone'
        prefix='0j5'
        labels={labels}
        editMode={editMode}
        microservice={microservices.LIST.name}
        model={microservices.LIST.models.timeZones}
        urls={timeZonesUrls}
        columnKey='timeZonesLists'
        addNewForm={{
          getFields: () => TimeZonesForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: timeZonesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify('TimeZone Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={timeZonesRowsMap}
        showBulkDeleteButton={false}
        hideActions
      />
    </Box>
  );
}
