import React from 'react';
import * as Yup from 'yup';
import { pmUrls } from '@config/routes';
import { taskTypeMeta } from '@config/meta/pm/pmMeta';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import ProjectDropdown from '@dropdown-forms/pm/ProjectDropdown';
import { useQueryClient } from 'react-query';

const { taskTypesUrls, projectsUrls } = pmUrls;

export default function TaskTypeDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const [hideProjects, setHideProjects] = React.useState(false);
  const queryClient = useQueryClient();


  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        hidden={hideProjects}
        title="Type"
        initialValues={{
          ...getAddFormInitialValues(taskTypeMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(taskTypeMeta(), 'listView'),
        })}
        getPostUrl={() => taskTypesUrls.list()}
        getValues={({values, project}) => ({
          ...values,
          project:project?.id
        })}
        getFields={(setFieldValue) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key="name" label="Name" name="name" required />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
            <Grid item xs={12}>
              <ProjectDropdown
                  hideParent={setHideProjects}
                  refetch={({ id, name }) => {
                    setFieldValue('project', { id, label: name });
                    queryClient.invalidateQueries(['hlr-projects']);
                  }}
                >
                  <AutocompleteFieldV2
                    name='project'
                    label='Project'
                    requestKey='hlr-projects'
                    fetchUrl={projectsUrls.list}
                    urlParams={`&ordering=created_at`}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.name,
                    })}
                    required
                  />
                </ProjectDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
