import * as React from 'react';
import MuiTextField from '@mui/material/TextField';

export default function TextField({ value, children, ...rest }) {
  return (
    <MuiTextField
      {...rest}
      value={value}
      variant={rest.variant ? rest.variant : 'outlined'}
      fullWidth
      size="small"
      autoComplete="off"
      InputLabelProps={{
        shrink: value !== null && value !== undefined && value !== '',
      }}
    >
      {children}
    </MuiTextField>
  );
}
