import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useAutocomplete from '@hooks/useAutocomplete';

/**
 * Validates and returns the input value.
 *
 * @param {object} value - The value object.
 * @param {string} inputValue - The input value to validate.
 * @param {object} customValue - The custom value object.
 * @param {boolean} customOnChange - Flag for custom change behavior.
 * @param {boolean} disableSearch - Flag to disable search.
 * @returns {string} - The validated input value or an empty string.
 */
function getValidatedInput(
  value,
  inputValue,
  customValue,
  customOnChange,
  disableSearch
) {
  if (disableSearch) {
    return '';
  }

  if (
    (customOnChange && customValue && customValue?.label === inputValue) ||
    (value && value?.label === inputValue)
  ) {
    return '';
  }

  return inputValue ?? '';
}

function getInitialValue(initialValue, multiple) {
  if (!initialValue) {
    return multiple ? [] : null;
  }
  return initialValue;
}

export default function AutocompleteFieldV2({
  label,
  fetchUrl,
  error,
  renderRow,
  helperText,
  requestKey,
  requestKeyOptions = [],
  initialValue,
  customValue,
  customOnChange,
  disableSearch,
  getOptionLabel = (option) => option?.label,
  required,
  getData,
  urlParams,
  disabled,
  enabled,
  orderingCol,
  setCustomOptions = (data) => data,
  multiple,
  idKey = 'id',
  runEffectOnInit,
}) {
  const [value, setValue] = React.useState(
    getInitialValue(initialValue, multiple)
  );
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const { data, isFetching } = useAutocomplete({
    key: [requestKey, ...requestKeyOptions],
    getUrl: fetchUrl,
    inputValue:
      getValidatedInput(
        value,
        inputValue,
        customValue,
        customOnChange,
        disableSearch
      ) ?? '',
    renderRow: renderRow,
    query: urlParams,
    orderingCol,
    enabled,
  });

  React.useEffect(() => {
    if (!runEffectOnInit) return;
    setValue(getInitialValue(initialValue, multiple));
  }, [initialValue, multiple, runEffectOnInit]);

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted && !isFetching && Array.isArray(data)) {
      setOptions(setCustomOptions(data));
      if (getData) getData(data);
    }

    return () => {
      isMounted = false;
    };
  }, [data, isFetching]);

  return (
    <>
      <Autocomplete
        size='small'
        fullWidth
        multiple={multiple}
        open={open}
        disabled={disabled}
        value={
          customOnChange && typeof customValue !== 'undefined'
            ? customValue
            : value
        }
        onChange={(e, newValue) => {
          setValue(newValue);
          if (customOnChange) customOnChange(newValue);
        }}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue ?? '');
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={getOptionLabel}
        options={[{ [idKey]: null, label: 'None' }, ...options]}
        loading={isFetching}
        autoComplete
        noOptionsText='No options'
        isOptionEqualToValue={(option, value) => {
          return option?.[idKey] === value?.[idKey];
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={params?.value ?? ''}
            fullWidth
            label={label}
            required={required}
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </>
  );
}
