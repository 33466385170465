import React from 'react';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import { lmsUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { teamRowsMap } from '@config/handleRows/lms';
import { TeamForm } from '@config/forms/lms';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { teamsUrls } = lmsUrls;

export default function Teams({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  return (
    <Box>
      <ListView
        nodeAPI
        label='Teams'
        prefix='0ag'
        labels={labels}
        editMode={editMode}
        urls={teamsUrls}
        columnKey='team'
        addNewForm={{
          getFields: () => TeamForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { data } = await createRecord({
                values,
                url: teamsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={teamRowsMap}
      />
    </Box>
  );
}
