import React from 'react';
import * as Yup from 'yup';
import { nodeDriveUrls } from '@config/routes';
import { Grid, Box } from '@mui/material';
import { UploadField } from '@components/Inputs';
import FormDialog from '@components/FormDialog';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import TextField from '@mui/material/TextField';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, IconButton, MenuItem } from '@mui/material';
import DeletePromptModal from '@components/DeletePromptModal';

const { filesUrls } = nodeDriveUrls;

export default function DetailCardUploadField({
  id,
  label,
  value,
  onSubmit,
  required,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [open, setOpen] = React.useState(false);
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAnchor = Boolean(anchorEl);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id={id}
          label={label}
          value={value}
          InputProps={{
            readOnly: true,
          }}
          onClick={() => setOpen(true)}
          size='small'
          variant='outlined'
          fullWidth
          required={required}
        />
        <IconButton
          color='primary'
          component='span'
          sx={{ p: 0, ml: 1 }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={openAnchor}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          <MenuItem
            size='small'
            onClick={() => {
              window.open(value, '_blank');
            }}
          >
            Preview
          </MenuItem>
          <MenuItem size='small' onClick={() => setOpen(true)}>
            Change {label}
          </MenuItem>
          <MenuItem
            size='small'
            disabled={required}
            onClick={() => setDeleteModalOpen(true)}
          >
            Remove {label}
          </MenuItem>
        </Menu>
      </Box>

      {open ? (
        <FormDialog
          label={`Upload ${label}`}
          initialValues={{}}
          validationSchema={Yup.object({})}
          getPostUrl={() => null}
          handleSubmit={async (
            values,
            setSubmitting,
            resetForm,
            setOpen,
            setError,
            setFieldError
          ) => {
            try {
              const { file } = values;

              let fileURL;

              if (!file) {
                setFieldError('file', 'Please select a file');
              }

              try {
                const formData = new FormData();
                formData.append(
                  'client',
                  user?.actAs?.id || user?.details?.client
                );
                formData.append('created_by', user?.details?.id);
                formData.append('anonymous_can_see_it', true);
                formData.append('file', file);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                fileURL = fileData.fileUrl;
              } catch {}

              if (file && !fileURL) {
                setFieldError('file', 'Failed to upload thumbnail');
                return;
              }
              resetForm();
              onSubmit(fileURL);
              setOpen(false);
              setAnchorEl(null);
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          }}
          getFields={() => (
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                  <UploadField name='file' />
                </Box>
              </Grid>
            </Grid>
          )}
          open={open}
          setOpen={setOpen}
          refetch={() => null}
        />
      ) : null}

      {!required && deleteModalOpen ? (
        <DeletePromptModal
          open={deleteModalOpen}
          onCancelClick={() => setDeleteModalOpen(false)}
          onDeleteClick={() => {
            onSubmit(null);
            setDeleteModalOpen(false);
            setAnchorEl(null);
          }}
        />
      ) : null}
    </>
  );
}
