import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { nodeDriveUrls, pmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { bugRowsMap } from '@config/handleRows/pm';
import { BugsForm } from '@config/forms/pm';
import { microservices } from '@config/constants';
import { useSearchParams } from 'react-router-dom';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { bugsUrls } = pmUrls;
const { filesUrls } = nodeDriveUrls;

export default function Bugs({ labels, editMode }) {
  const [queryParams] = useSearchParams();
  const create = queryParams.get('create');
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  const bugAndFeaturesColOptions = React.useMemo(() => {
    return {
      user,
    };
  }, [user]);

  return (
    <Box>
      <ListView
        showCreateForm={!!create}
        prefix="0co"
        labels={labels}
        editMode={editMode}
        label="Bugs and Features"
        microservice={microservices.PM.name}
        model={microservices.PM.models.bugs}
        urls={bugsUrls}
        columnKey="bugPM"
        columnOptions={bugAndFeaturesColOptions}
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            BugsForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              let file = values.screenshot_drive_link;

              if (file) {
                const formData = new FormData();
                formData.append('anonymous_can_see_it', true);
                formData.append('file', file);
                try {
                  const response = await createRecord({
                    values: formData,
                    url: filesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });
                  file = response?.data?.fileUrl;
                } catch (err) {
                  handleCreateFormErrors({ err, setError, notify, values });
                }
              }

              const { project, ...rest } = values;
              const response = await createRecord({
                values: {
                  ...rest,
                  project: project?.id,
                  screenshot_drive_link: file,
                },
                url: bugsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={bugRowsMap}
      />
    </Box>
  );
}
