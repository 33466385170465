import * as React from 'react';
import useToast from '@hooks/useToast';
import { Formik, Form } from 'formik';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ShortLinksList from '@components/BPA-V2/Popups/CreateShortLinksPopup/ShortLinksList';
import { getInitialParamValue } from '@config/functions/bpaFunctions';
import { useQueryClient } from 'react-query';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  handleClose,
  handlePopupSubmit,
  getInitialValues,
} from '@config/functions/createShortLinksPopupFunctions';
import ReminderPopup from '@components/ReminderPopup';

export default function CreateShortLinksPopup({
  open,
  setOpen,
  workflowId,
  workflowName,
  actionParams,
  shapeAction,
  shape,
  user,
}) {
  const [notify] = useToast();
  const slotLinkGroupRef = React.useRef(null);
  const [showAddReminder, setShowAddReminder] = React.useState({
    initialNotes: '',
    open: false,
  });
  const queryClient = useQueryClient();

  const { initialShortLinks } = React.useMemo(
    () => ({
      initialShortLinks: getInitialParamValue(
        'short_links',
        actionParams,
        true
      ),
    }),
    [actionParams]
  );

  const initialValues = React.useMemo(() => {
    return getInitialValues({
      initialShortLinks,
    });
  }, [initialShortLinks]);

  React.useEffect(() => {
    console.log('initialValues', initialValues);
  }, [initialValues]);

  const handleCloseWrapped = () => {
    handleClose({ setOpen, shape, queryClient });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => null}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <DialogTitle>Create Short Links Setup</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseWrapped}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Formik
          enableReinitialize={false}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            await handlePopupSubmit({
              values,
              setSubmitting,
              setFieldError,
              shapeAction,
              user,
              initialShortLinks,
              notify,
              slotLinkGroupRef,
            });
          }}
        >
          {({ values, errors, isSubmitting, setValues, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              {console.log(errors)}
              <Stack spacing={3} sx={{ pt: 1 }}>
                <Box ref={slotLinkGroupRef}>
                  <ShortLinksList
                    shape={shape}
                    shapeAction={shapeAction}
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    workflowId={workflowId}
                    workflowName={workflowName}
                    showAddReminder={showAddReminder}
                    setShowAddReminder={setShowAddReminder}
                  />
                </Box>
              </Stack>

              <DialogActions sx={{ pt: 3 }}>
                <Button onClick={handleCloseWrapped}>Cancel</Button>
                <Button disabled={isSubmitting} type="submit">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
        {showAddReminder ? (
          <ReminderPopup
            type="INA"
            microservice="BPA"
            model="WorkflowDefn"
            workflow={workflowName}
            initialNotes={showAddReminder?.initialNotes}
            open={showAddReminder?.open}
            setOpen={(value) =>
              setShowAddReminder({
                ...showAddReminder,
                open: value,
              })
            }
            selectedRows={[workflowId]}
            setSelectedRows={() => null}
            link={`${window.location.host}/bpa/${workflowId}`}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
