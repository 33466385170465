import { Box } from '@mui/material';
import React from 'react';
import KanbanListHeader from '@components/KanbanListHeader';

export default function List({
  list,
  headerLabel = 'name',
  workflowId,
  idx,
  width,
  detailLink,
  data,
  children,
  stageStates,
  setStageStates,
  sprintMeta,
  childStages,
}) {
  return (
    <Box
      key={list.id}
      sx={{
        minWidth: width || 290,
        maxWidth: width || 290,
        m: 1,
        bgcolor: '#EBECF0',
        borderRadius: '4px',
        boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.07)',
      }}
    >
      <KanbanListHeader
        {...list}
        childStages={list?.child_stages}
        sprintMeta={sprintMeta}
        idx={idx}
        id={list.id}
        data={data}
        customColor={list?.current ? '#94c6ff' : null}
        title={list[headerLabel]}
        order={`${list?.stage_full_order ?? ''}${
          list?.order ? `${list?.order}` : ''
        }`}
        count={list?.items?.length}
        detailLink={detailLink}
        workflowId={workflowId}
        manualColor={list.manual}
        stageStates={stageStates}
        setStageStates={setStageStates}
      />

      <Box sx={{ overflowY: 'auto', maxHeight: '65vh' }}>{children}</Box>
    </Box>
  );
}
