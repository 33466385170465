import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { eDocUrls, nodeDriveUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { documentRowMap } from '@config/handleRows/eDoc';
import { DocumentsForm } from '@config/forms/eDoc';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { filesUrls } = nodeDriveUrls;
const { documentsUrls, cloneSystemTemplatesUrls } = eDocUrls;

export default function Documents({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        nodeAPI
        label='Documents'
        prefix='0u6'
        labels={labels}
        editMode={editMode}
        microservice={microservices.eDoc.name}
        model={microservices.eDoc.models.document}
        urls={documentsUrls}
        columnKey='document'
        addNewForm={{
          getFields: () =>
            DocumentsForm({
              microservice: microservices.eDoc.name,
              model: microservices.eDoc.models.document,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError,
            setFieldError
          ) => {
            try {
              let documentUrl;
              const { template, document, ...rest } = values;

              const doFinally = () => {
                resetForm();
                setAddNewForm(false);
                refetch();
              };

              if (!document?.name && !template) {
                setFieldError('document', 'Please select a document');
                return;
              }

              if (document?.name) {
                const formData = new FormData();
                formData.append('anonymous_can_see_it', true);
                formData.append('file', values.document);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                documentUrl = fileData.fileUrl;

                if (!documentUrl) {
                  notify(
                    'Failed to upload the document. Please refresh the page to retry',
                    {
                      type: 'ERROR',
                    }
                  );
                  return;
                }
              }

              if (values.template) {
                const payload = {
                  template_id: template?.id,
                  custom_params: {
                    ...rest,
                    documentUrl,
                    anonymous_can_see_it: values.isPublished,
                  },
                };
                console.log(payload);
                if (documentUrl)
                  payload.custom_params.documentUrl = documentUrl;
                const { data } = await createRecord({
                  values: payload,
                  url: cloneSystemTemplatesUrls.detail(
                    microservices.eDoc.models.document
                  ),
                  token: user.token,
                  actAs: user?.actAs,
                });
                doFinally();
                return data?.id;
              } else {
                const { data } = await createRecord({
                  values: {
                    ...rest,
                    documentUrl,
                    anonymous_can_see_it: values.isPublished,
                  },
                  url: documentsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                doFinally();
                return data?.id;
              }
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={documentRowMap}
      />
    </Box>
  );
}
