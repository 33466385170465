import * as React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { pmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { taskStatusMeta } from '@config/meta/pm/pmMeta';
import DetailAccordionView from '@components/DetailAccordionView';
import { taskStatusRowsMap } from '@config/handleRows/pm';
import { microservices } from '@config/constants';
import { TaskStatusForm } from '@config/forms/pm';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { createRecord } from '@config/functions/requests';
import { useParams } from 'react-router-dom';

const { taskStatusUrls } = pmUrls;

export default function TaskStatusDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [taskStatusListData, setTaskStatusListData] = React.useState({});

  const getSchema = React.useCallback(
    (data) =>
      taskStatusMeta({
        data,
      }),
    []
  );

  const taskStagesColOptions = React.useMemo(() => {
    return {
      nextOrder: taskStatusListData?.totalCount ?? 1,
      onlyParent: true,
    };
  }, [taskStatusListData?.totalCount]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: recordData.parent
                  ? `/task-statuses/${recordData?.parent}`
                  : `/projects/${recordData?.project}`,
                text: recordData.parent
                  ? `${recordData?.details?.parent?.name}`
                  : `${recordData?.details?.project?.name}`,
              },
              { text: recordData?.name },
            ]}
          ></DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={taskStatusUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          microservice={microservices.PM.name}
          model={microservices.PM.models.taskStatus}
          columnKey='taskStatusPM'
          columnOptions={taskStagesColOptions}
          customValues={{
            rebase: true,
          }}
          label='Sub Task Statuses'
          // prefix="0rv"
          urls={taskStatusUrls}
          addNewForm={{
            getFields: () =>
              TaskStatusForm({
                hideParent: true,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: {
                    ...values,
                    project: recordData?.project,
                    parent: recordId,
                  },
                  url: taskStatusUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log('error 99', err.response || err.message);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={taskStatusRowsMap}
          urlParams={`&parent=${recordId}`}
          customSortColumnName='order'
          sortColumnBy='asc'
          getListData={(data) => setTaskStatusListData(data)}
        />
      </Box>
    </Box>
  );
}
