import React from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { communicationUrls } from '@config/routes';
import { emailRowsMap } from '@config/handleRows/communication';
import { microservices } from '@config/constants';
import AuthWrapper from '@components/Communication/AuthWrapper';

const { notificationsUrls } = communicationUrls;

function AllEmails({ labels, editMode }) {
  const allEmailsColOptions = React.useMemo(() => {
    return { allEmails: true };
  }, []);

  return (
    <>
      <Box>
        <ListView
          label="All Emails"
          prefix="0n7"
          labels={labels}
          editMode={editMode}
          showBulkDeleteButton={false}
          showVisibilityInPageHeader={false}
          showVisibility={true}
          showDeleteBtn={false}
          microservice={microservices.NOTIFICATION.name}
          model={microservices.NOTIFICATION.models.notifications}
          urls={notificationsUrls}
          hideActions
          columnKey="emailCommunication"
          columnOptions={allEmailsColOptions}
          handleRowsMap={emailRowsMap}
          urlParams={`&all_inbox=true`}
        />
      </Box>
    </>
  );
}

export default function AuthenticatedAllEmails(props) {
  return (
    <AuthWrapper>
      <AllEmails {...props} />
    </AuthWrapper>
  );
}
