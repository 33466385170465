import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: null,
  tasks: [],
};

export const meBoardSlice = createSlice({
  name: 'meBoard',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
  },
});

export const { setModal, setTasks } = meBoardSlice.actions;

export const selectMeBoard = (state) => state.meBoard;

export default meBoardSlice.reducer;
