import * as React from 'react';
import useToast from '@hooks/useToast';
import { Formik, Form } from 'formik';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import EmailTemplateField from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateField';
import PayloadSelector from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/PayloadSelector';
import EmailTemplateContextFieldList from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateContextFieldList';
import EmailTemplateSlotLinkList from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateSlotLinkList';
import EmailTemplateFormList from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateFormList';
import EmailTemplateEDocList from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateEDocList';
import EmailTemplateShortLinkList from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateShortLinkList';
import EmailTemplateRecipientFields from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateRecipientFields';
import { getActionParamAndValue } from '@config/functions/bpaFunctions';
import { useQueryClient } from 'react-query';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import {
  getReadOnlyStyles,
  handleClose,
  handlePopupSubmit,
  getInitialValues,
  contextMappingDefinition,
} from '@config/functions/emailTemplateConfigurationPopupFunctions';
import { bpaUrls } from '@config/routes';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { TextField } from '@components/Inputs';
import ReminderPopup from '@components/ReminderPopup';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';

const { modelAssetsUrls } = bpaUrls;

export default function EmailTemplateConfigurationPopup({
  open,
  setOpen,
  workflowId,
  workflowName,
  actionParams,
  shapeAction,
  shape,
  user,
  isKanbanView,
  workflowInstances,
  isFromKanbanHeader,
}) {
  const [notify] = useToast();
  const contentOptionsGroupRef = React.useRef(null);
  const [uniqueNameErrors, setUniqueNameErrors] = React.useState([]);
  const [contextFields, setContextFields] = React.useState([]);
  const [showAddReminder, setShowAddReminder] = React.useState({
    initialNotes: '',
    open: false,
  });

  const defaultStep =
    shapeAction?.payload?.actual_result &&
    shapeAction?.payload?.actual_result.length > 1 &&
    shapeAction?.payload?.actual_result[1]?.email?.status === 'Preview'
      ? 'preview'
      : 'setup';

  const [currentStep, setCurrentStep] = React.useState(defaultStep);
  const queryClient = useQueryClient();

  const shapeActionId = shapeAction?.id;

  const getInitialParamValue = (key, multipleValues = false) => {
    return getActionParamAndValue({ key, actionParams, multipleValues });
  };

  const {
    initialSlotLinks,
    initialForms,
    initialToEmail,
    initialBccEmails,
    initialCcEmails,
    initialTemplate,
    initialContext,
    initialEdocs,
    initialShortLinks,
  } = React.useMemo(
    () => ({
      initialTemplate: getInitialParamValue('template_id'),
      initialToEmail: getInitialParamValue('to_email', true),
      initialCcEmails: getInitialParamValue('cc_emails', true),
      initialBccEmails: getInitialParamValue('bcc_emails', true),
      initialContext: getInitialParamValue('context', true),
      initialSlotLinks: getInitialParamValue('calendar_slots', true),
      initialForms: getInitialParamValue('forms', true),
      initialEdocs: getInitialParamValue('edocs', true),
      initialShortLinks: getInitialParamValue('short_links', true),
    }),
    [actionParams]
  );

  // React.useEffect(() => {
  //   console.log('initialValues', initialValues);
  // }, [initialValues]);

  const { data, isError, isFetching } = useRQuery({
    key: [
      'mail-template-data',
      initialTemplate?.value?.model_asset,
      open,
      user?.token,
      user?.actAs,
    ],
    url: modelAssetsUrls.detail(initialTemplate?.value?.model_asset),
    config: getAuthHeader(user?.token, user?.actAs),
    options: {
      enabled: !!user?.token && !!initialTemplate?.value?.model_asset && open,
    },
  });

  React.useEffect(() => {
    if (!data) return;
    setContextFields(data?.details?.asset?.details?.context_fields ?? []);
  }, [data]);

  const initialValues = React.useMemo(() => {
    if (!defaultStep || (!data && initialTemplate?.value?.model_asset)) return;
    return getInitialValues({
      initialTemplate: {
        modelAssetId: initialTemplate?.value?.model_asset,
        modelAssetlabel: initialTemplate?.value?.label,
        ...(defaultStep === 'preview'
          ? {
              ...(data?.details?.asset ?? {}),
              subject: shapeAction?.payload?.actual_result[1]?.email?.subject,
              html: shapeAction?.payload?.actual_result[1]?.email?.html_body,
            }
          : { ...(data?.details?.asset ?? {}), subject: '', html: '' }),
      },
      initialToEmail,
      initialCcEmails,
      initialBccEmails,
      initialSlotLinks,
      initialForms,
      initialContext,
      initialEdocs,
      initialShortLinks,
      contextMappingDefinition,
    });
  }, [
    data,
    defaultStep,
    initialToEmail,
    initialCcEmails,
    initialBccEmails,
    initialSlotLinks,
    initialForms,
    initialContext,
    initialEdocs,
    initialShortLinks,
    contextMappingDefinition,
  ]);

  const handleCloseWrapped = ({ setFieldValue }) => {
    if (currentStep === 'preview' && setFieldValue) {
      setFieldValue('subject', '');
      setFieldValue('html', '');
      return setCurrentStep('setup');
    }

    handleClose({ setOpen, shape, queryClient });
  };

  const handlePreviewOrSave = async (values, formSubmit) => {
    if (currentStep === 'setup') {
      values.type = 'preview';
    } else {
      values.type = 'save';
    }

    await formSubmit();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => null}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <DialogTitle>
        {currentStep === 'setup'
          ? `Email Template Setup`
          : 'Preview of Email Template'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseWrapped}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {!isError &&
        (isFetching ||
          (!data && initialTemplate?.value?.model_asset) ||
          !initialValues) ? (
          <Loader />
        ) : isError ? (
          `There was an error, please try again.`
        ) : (
          <Formik
            enableReinitialize={!isKanbanView}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initialValues}
            onSubmit={async (
              values,
              { setSubmitting, setValues, setErrors, setFieldError }
            ) => {
              await handlePopupSubmit({
                values,
                setValues,
                setSubmitting,
                setErrors,
                setFieldError,
                setUniqueNameErrors,
                isKanbanView,
                workflowId,
                shapeAction,
                workflowInstances,
                user,
                contextFields,
                notify,
                queryClient,
                contentOptionsGroupRef,
                setOpen,
                shape,
                currentStep,
                setCurrentStep,
              });
            }}
          >
            {({
              values,
              errors,
              isSubmitting,
              setValues,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form noValidate autoComplete="off">
                {currentStep === 'setup' ? (
                  <Stack spacing={3} sx={{ pt: 1 }}>
                    <Box sx={getReadOnlyStyles(isKanbanView)}>
                      <EmailTemplateField
                        isKanbanView={isKanbanView}
                        workflowId={workflowId}
                        shapeActionId={shapeActionId}
                        initialStage={shape?.stage}
                        setContextFields={setContextFields}
                        setFieldValue={setFieldValue}
                        selected={values?.template}
                      />
                    </Box>

                    <Box sx={getReadOnlyStyles(isKanbanView)}>
                      <PayloadSelector
                        isKanbanView={isKanbanView}
                        label="Payload Selector"
                        workflowId={workflowId}
                        payloadSectionName="recipientPayloadSection"
                        payloadSectionFieldName="recipientPayloadSectionKey"
                      />
                    </Box>

                    <Box sx={getReadOnlyStyles(isKanbanView)}>
                      <EmailTemplateRecipientFields
                        values={values}
                        setFieldValue={setFieldValue}
                        user={user}
                      />
                    </Box>

                    <Typography
                      ref={contentOptionsGroupRef}
                      sx={{ fontWeight: '500', fontSize: '18px' }}
                    >
                      Content Options
                    </Typography>

                    {uniqueNameErrors?.length ? (
                      <Alert severity="error">
                        <AlertTitle>Duplicate names found:</AlertTitle>
                        {uniqueNameErrors.map((error, index) => (
                          <div key={index}>{error}</div>
                        ))}
                      </Alert>
                    ) : null}

                    <Box>
                      <EmailTemplateSlotLinkList
                        shape={shape}
                        shapeAction={shapeAction}
                        user={user}
                        errors={errors}
                        values={values}
                        setValues={setValues}
                        setFieldValue={setFieldValue}
                        workflowId={workflowId}
                        workflowName={workflowName}
                        showAddReminder={showAddReminder}
                        setShowAddReminder={setShowAddReminder}
                        isKanbanView={isKanbanView}
                      />

                      <EmailTemplateFormList
                        shape={shape}
                        shapeAction={shapeAction}
                        user={user}
                        errors={errors}
                        values={values}
                        setValues={setValues}
                        setFieldValue={setFieldValue}
                        workflowId={workflowId}
                        workflowName={workflowName}
                        showAddReminder={showAddReminder}
                        setShowAddReminder={setShowAddReminder}
                        isKanbanView={isKanbanView}
                      />

                      <EmailTemplateEDocList
                        shape={shape}
                        shapeAction={shapeAction}
                        values={values}
                        setValues={setValues}
                        errors={errors}
                        user={user}
                        setFieldValue={setFieldValue}
                        workflowId={workflowId}
                        workflowName={workflowName}
                        showAddReminder={showAddReminder}
                        setShowAddReminder={setShowAddReminder}
                        isKanbanView={isKanbanView}
                      />

                      <EmailTemplateShortLinkList
                        shape={shape}
                        shapeAction={shapeAction}
                        values={values}
                        setValues={setValues}
                        errors={errors}
                        user={user}
                        setFieldValue={setFieldValue}
                        workflowId={workflowId}
                        workflowName={workflowName}
                        showAddReminder={showAddReminder}
                        setShowAddReminder={setShowAddReminder}
                        isKanbanView={isKanbanView}
                      />
                    </Box>

                    {isKanbanView ? null : (
                      <Box>
                        <Button
                          variant="outlined"
                          disabled={isSubmitting}
                          onClick={() => handleSubmit()}
                        >
                          Update payload
                        </Button>
                      </Box>
                    )}

                    <EmailTemplateContextFieldList
                      contextFields={contextFields}
                      setFieldValue={setFieldValue}
                      workflowId={workflowId}
                      values={values}
                      isKanbanView={isKanbanView}
                    />
                  </Stack>
                ) : (
                  <Grid container spacing={3.5}>
                    <Grid item xs={12}>
                      <TextField label="Subject" name="subject" required />
                    </Grid>

                    <Grid item xs={12}>
                      <WysiwygEditor
                        initData={values?.html}
                        msName="Notification"
                        onChange={(e) => {
                          setFieldValue('html', e.editor.getData() ?? '');
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                <DialogActions sx={{ pt: 3 }}>
                  <Button
                    onClick={() => handleCloseWrapped({ setFieldValue })}
                    disabled={isSubmitting}
                  >
                    {currentStep === 'preview' ? `Back` : `Cancel`}
                  </Button>

                  {!isFromKanbanHeader && isKanbanView ? (
                    <Button
                      onClick={() => handlePreviewOrSave(values, handleSubmit)}
                      disabled={isSubmitting}
                    >
                      {currentStep === 'setup' ? 'Preview' : 'Save'}
                    </Button>
                  ) : null}

                  <Button
                    disabled={
                      isSubmitting || !contextFields || !contextFields?.length
                    }
                    onClick={async () => {
                      values.type = 'send';
                      await handleSubmit();
                    }}
                  >
                    {isKanbanView ? `Send` : `Save`}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}

        {showAddReminder ? (
          <ReminderPopup
            type="INA"
            microservice="BPA"
            model="WorkflowDefn"
            workflow={workflowName}
            initialNotes={showAddReminder?.initialNotes}
            open={showAddReminder?.open}
            setOpen={(value) =>
              setShowAddReminder({
                ...showAddReminder,
                open: value,
              })
            }
            selectedRows={[workflowId]}
            setSelectedRows={() => null}
            link={`${window.location.host}/bpa/${workflowId}`}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
