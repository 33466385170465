import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { ecommerceUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { DeliveryStatusForm } from '@config/forms/ecommerce';
import ecommerceHandleRow from '@config/handleRows/ecommerce';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { deliveryStatusesUrls, deliveryMethodsUrls } = ecommerceUrls;
const { deliveryStatusRowMap } = ecommerceHandleRow;

export default function DeliveryStatuses({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const [deliveryMethodTerm, setDeliveryMethodTerm] = useState([]);

  const { data: deliveryMethods, isFetching: fetchingDeliveryMethods } =
    useAutocomplete({
      key: 'deliveryMethods',
      getUrl: deliveryMethodsUrls.list,
      inputValue: deliveryMethodTerm,
    });

  const deliveryStatusesColOptions = React.useMemo(() => {
    return {
      deliveryMethods,
      fetchingDeliveryMethods,
      setDeliveryMethodTerm,
    };
  }, [deliveryMethods, fetchingDeliveryMethods, setDeliveryMethodTerm]);

  return (
    <Box>
      <ListView
        label="Delivery Statuses"
        prefix="0fl"
        labels={labels}
        editMode={editMode}
        microservice="E-Commerce"
        model="DeliveryStatus"
        urls={deliveryStatusesUrls}
        columnKey="deliveryStatusEcommerce"
        columnOptions={deliveryStatusesColOptions}
        addNewForm={{
          getFields: () =>
            DeliveryStatusForm({
              deliveryMethods,
              fetchingDeliveryMethods,
              setDeliveryMethodTerm,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: deliveryStatusesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify(`Delivery Status Created!`, {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={deliveryStatusRowMap}
      />
    </Box>
  );
}
