import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { hrUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { EmployeeStatusForm } from '@config/forms/hr';
import { employeeStatusRowsMap } from '@config/handleRows/hr';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { employeeStatusUrls } = hrUrls;

export default function EmployeeStatus({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label="Employee Status"
        prefix="09j"
        labels={labels}
        editMode={editMode}
        microservice={microservices.HR.name}
        model={microservices.HR.models.employeeStatus}
        urls={employeeStatusUrls}
        columnKey="employeeStatusHR"
        addNewForm={{
          getFields: () => EmployeeStatusForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: employeeStatusUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={employeeStatusRowsMap}
      />
    </Box>
  );
}
