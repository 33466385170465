import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<FontAwesomeIcon icon={faChevronRight} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomAccordion({
  label,
  children,
  prefix,
  labels,
  editMode,
  expanded,
  ...rest
}) {
  return (
    <Accordion {...rest} expanded={editMode ? false : expanded}>
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronRight} />}>
        {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue={label}
          >
            {(text) => {
              // console.log('text', text, prefix, 'label', label);
              return <Typography component='div'>{text}</Typography>;
            }}
          </RenderUiPrefix>
        ) : (
          <Typography component='div'>{label}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Typography component='div'>{children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
