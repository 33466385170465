import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField } from '@ui/Inputs';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import { selectProfile, setEncryptionToken } from '@redux/profileSlice';
import { createRecord, updateRecord } from '@config/functions/requests';
import { accountUrls, communicationUrls } from '@config/routes';
import { encryptionPassKey } from '@config/constants';
import { useQueryClient } from 'react-query';

const initialValue = {
  passphrase: '',
  passphrase2: '',
};

const { imapAccountsUrls } = communicationUrls;
const { setEncryptionAcctUrls } = accountUrls;

export default function SetPassphraseModal({ open, setOpen, cb }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [error, setError] = React.useState('');
  const [showPwd, setShowPwd] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [passphrase, setPassphrase] = React.useState(initialValue);

  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      setError('');

      if (!passphrase.passphrase.length) {
        setError(`Passphrases is required`);
        return;
      }

      if (passphrase.passphrase.length < 12) {
        setError(`Passphrases must at least 12 characters long`);
        return;
      }

      if (passphrase.passphrase !== passphrase.passphrase2) {
        setError(`Passphrases don't match`);
        return;
      }

      const response = await createRecord({
        values: { phrase: passphrase.passphrase },
        url: setEncryptionAcctUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });

      setPassphrase(initialValue);
      dispatch(setEncryptionToken(response?.data?.access));
      localStorage.setItem(encryptionPassKey, response?.data?.access);
      queryClient.invalidateQueries('Imap-Accounts-listData');
      queryClient.invalidateQueries(`drawer-imap-accts-${user.token}`);
      handleClose();

      await updateRecord({
        values: { encryption_enabled: true },
        url: imapAccountsUrls.detail(cb.id),
        token: user.token,
        actAs: user?.actAs,
        encryptionToken: user.encryptionToken,
      });

      cb.refetch();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.phrase) {
        setError(err.response.data.phrase);
      }
      console.log(err.response || err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={() => {}}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent maxWidth="xs" fullWidth>
        <Stack spacing={3}>
          <Typography variant="h6" component="h2" sx={{ textAlign: 'center' }}>
            Set Passphrase
          </Typography>

          {!!error.length ? (
            <Alert icon={false} severity="error">
              {error}
            </Alert>
          ) : null}

          <Stack
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
            spacing={3}
          >
            <TextField
              type={showPwd ? 'text' : 'password'}
              label="Enter Passphrase"
              value={passphrase.passphrase}
              onChange={(e) =>
                setPassphrase((s) => ({ ...s, passphrase: e.target.value }))
              }
            />

            <TextField
              type={showPwd ? 'text' : 'password'}
              label="Confirm Passphrase"
              value={passphrase.passphrase2}
              onChange={(e) =>
                setPassphrase((s) => ({
                  ...s,
                  passphrase2: e.target.value,
                }))
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={showPwd}
                  onChange={() => setShowPwd((v) => !v)}
                />
              }
              label="Show passphrase"
            />

            <Grid container sx={{ width: '100%' }}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={submitting}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
