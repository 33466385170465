import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, FormControl, FormControlLabel, Radio } from '@mui/material';

export default function RecurringDatePicker({
  never,
  setNever,
  endDate,
  setEndDate,
}) {
  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl>
        <FormControlLabel
          value='endsOnDate'
          control={
            <Radio
              checked={never === 'endsOnDate'}
              onChange={() => setNever('endsOnDate')}
            />
          }
          label='On'
        />
      </FormControl>
      <Box sx={{ mt: '5px', ml: 6, width: '160px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={endDate}
            onChange={(newValue) => {
              setEndDate(new Date(newValue));
            }}
            renderInput={(params) => <TextField size='small' {...params} />}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}
