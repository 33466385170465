import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Departments from '@pages/HR/Departments';
import DepartmentDetail from '@pages/HR/Departments/detail';
import Employees from '@pages/HR/Employees';
import EmployeeDetail from '@pages/HR/Employees/detail';
import RecruitmentPipelines from '@pages/HR/RecruitmentPipelines';
import RecruitmentPipelineDetail from '@pages/HR/RecruitmentPipelines/detail';
import JobLevels from '@pages/HR/JobLevels';
import Vacancies from '@pages/HR/Vacancies';
import VacancyDetail from '@pages/HR/Vacancies/detail';
import CandidateDetail from '@pages/HR/Candidates/detail';
import Courses from '@pages/HR/Courses';
import CourseDetail from '@pages/HR/Courses/detail';
import Skills from '@pages/HR/Skills';
import SkillDetail from '@pages/HR/Skills/detail';
import SkillLevels from '@pages/HR/SkillLevels';
import DayOffTypes from '@pages/HR/DayOffTypes';
import CandidateFormDetail from '@pages/HR/CandidateForms/detail';
import HrDashboard from '@pages/HR/HrDashboard';
import EmployeeStatus from '@pages/HR/EmployeeStatus';


export default function () {
  return (
    <>
      <Route path='/departments' element={<WithLayout comp={Departments} />} />
      <Route
        path='/departments/:id'
        element={<WithLayout comp={DepartmentDetail} />}
      />
      <Route path='/employees' element={<WithLayout comp={Employees} />} />
      <Route
        path='/employees/:id'
        element={<WithLayout comp={EmployeeDetail} />}
      />
      <Route
        path='/recruitment-pipelines'
        element={<WithLayout comp={RecruitmentPipelines} />}
      />
      <Route
        path='/recruitment-pipelines/:id'
        element={<WithLayout comp={RecruitmentPipelineDetail} />}
      />
      <Route path='/job-levels' element={<WithLayout comp={JobLevels} />} />
      <Route path='/vacancies' element={<WithLayout comp={Vacancies} />} />
      <Route
        path='/vacancies/:id'
        element={<WithLayout comp={VacancyDetail} />}
      />
      <Route
        path='/candidates/:id'
        element={<WithLayout comp={CandidateDetail} />}
      />
      <Route path='/employee-courses' element={<WithLayout comp={Courses} />} />
      <Route
        path='/employee-courses/:id'
        element={<WithLayout comp={CourseDetail} />}
      />
      <Route path='/employee-skills' element={<WithLayout comp={Skills} />} />
      <Route
        path='/employee-skills/:id'
        element={<WithLayout comp={SkillDetail} />}
      />
      <Route path='/skill-levels' element={<WithLayout comp={SkillLevels} />} />
      <Route
        path='/day-off-types'
        element={<WithLayout comp={DayOffTypes} />}
      />
      <Route path='/candidate-form/:id' element={<CandidateFormDetail />} />
      <Route path='/hr-dashboard' element={<WithLayout comp={HrDashboard} />} />
      <Route path='/employee-status' element={<WithLayout comp={EmployeeStatus} />} />
    </>
  );
}
