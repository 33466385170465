import React from 'react';
import * as Yup from 'yup';
import { crmUrls } from '@config/routes';
import { companiesMeta } from '@config/meta/crm/index';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { companiesUrls } = crmUrls;

export default function CompanyDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title='Company'
        initialValues={{
          ...getAddFormInitialValues(companiesMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(companiesMeta(), 'listView'),
        })}
        getPostUrl={() => companiesUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <TextField label='Name' name='name' required />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key='description'
                label='Description'
                name='description'
                rows={3}
                multiline
              />
            </Grid>
            <Grid item xs={6}>
              <TextField key='phone' label='Phone' name='phone' />
            </Grid>
            <Grid item xs={6}>
              <TextField key='website' label='Website' name='website' />
            </Grid>
            <Grid item xs={6}>
              <TextField key='news_url' label='News Url' name='news_url' />
            </Grid>
            <Grid item xs={6}>
              <TextField key='staff_url' label='Staff Url' name='staff_url' />
            </Grid>
            <Grid item xs={12}>
              <TextField key='_tag' label='Tag' name='_tag' />
            </Grid>
          </Grid>
        )}
        open={open}
        refetch={refetch}
        setOpen={setOpen}
      />
    </>
  );
}
