import axios from 'axios';
import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import ListView from '@components/ListView';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { logsUrls, accountUrls } from '@config/routes';
import { systemLogsMeta } from '@config/meta/logs/logsMeta';
import { systemLogsHandleRow } from '@config/handleRows/logs';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { microservices } from '@config/constants';

const { systemLogsUrls, downloadSystemLogsUrls } = logsUrls;
const { userDetailUrls } = accountUrls;

export default function SystemLogs({ labels, editMode }) {
  const navigate = useNavigate();
  const user = useSelector(selectProfile);
  const [notify, update] = useToast();

  const actions = useMemo(
    () => ({
      handleDownload: (values) => async () => {
        const toastId = notify(`Downloading...`, {
          type: 'INFO',
        });
        try {
          const resp = await axios.get(
            downloadSystemLogsUrls.detail(values.id),
            getAuthHeader(user.token, user.details && user?.details?.client),
            { responseType: 'blob' }
          );

          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', values.row && values.row.file_name);
          document.body.appendChild(link);
          link.click();

          update(toastId, `File Downloaded`, {
            type: 'SUCCESS',
          });
        } catch {
          update(toastId, `There was an error, please try again.`, {
            type: 'ERROR',
          });
        }
      },
    }),
    [user.token, notify, update]
  );

  useEffect(() => {
    axios
      .get(
        userDetailUrls.list(),
        getAuthHeader(user.token, user.details && user?.details?.client)
      )
      .then((res) => {
        if (!res.data.is_superuser) {
          navigate('/');
        }
      });
  }, [navigate, user.token]);

  return (
    <Box key='list-view-container'>
      <ListView
        key='system-log-list-view'
        label='System Logs'
        prefix='0ep'
        labels={labels}
        editMode={editMode}
        microservice={microservices.LOGS.name}
        model={microservices.LOGS.models.systemLogs}
        urls={systemLogsUrls}
        columns={systemLogsMeta()}
        handleRowsMap={systemLogsHandleRow}
        rowActions={[
          {
            icon: <DownloadIcon sx={{ mr: 1 }} />,
            label: 'Download',
            color: '#808080',
            handleClick: actions.handleDownload,
          },
        ]}
      />
    </Box>
  );
}
