import React from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { systemUrls } from '@config/routes';
import { shapeTransitionMeta } from '@config/meta/system';
import Accordion from '@ui/Accordion';
import { TextField } from '@ui/Inputs';

const { shapeTransitionLogUrls } = systemUrls;

export default function ShapeTransitionLogDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);

  const onChange = () => {
    setExpanded(!expanded);
  };

  const parametersData = JSON.parse(recordData && recordData?.parameters);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => shapeTransitionMeta({ data }),
    []
  );

  return (
    <>
      <Box>
        {recordData ? (
          <Box>
            <DetailPageHeader
              items={[
                {
                  to: `/shape-transition-logs`,
                  text: 'Shape Transition Logs',
                },
                { text: recordData?.details?.instance?.label },
              ]}
            />
          </Box>
        ) : null}

        <DetailCard
          urls={shapeTransitionLogUrls}
          recordId={recordId}
          getSchema={getSchema}
          setData={setRecordData}
        />
        <Box>
          {recordData && recordData?.parameters && parametersData?.payload ? (
            <Accordion
              label='Parameters'
              expanded={expanded}
              onChange={onChange}
            >
              {Object.entries(parametersData?.payload).map(([key, value]) => (
                <Box sx={{ display: 'flex', pb: '20px' }}>
                  <Box sx={{ pr: '20px', pt: '9px' }}>
                    <Typography variant='p'>{key}:</Typography>
                  </Box>

                  <Box sx={{ flexGrow: '100' }}>
                    <TextField
                      value={value || ''}
                      fullWidth
                      hiddenLabel
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Accordion>
          ) : null}
        </Box>
      </Box>
    </>
  );
}
