import React from 'react';
import { Box, Button, IconButton, Stack } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const mimeType = 'audio/wav';

const formatDuration = (duration) => {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

export default function AudioRecorder({ submitted, setAudio }) {
  const mediaStream = React.useRef(null);
  const mediaRecorder = React.useRef(null);
  const durationInterval = React.useRef(null);
  const [recordingStatus, setRecordingStatus] = React.useState('inactive');
  const [audioChunks, setAudioChunks] = React.useState([]);
  const [localAudioUrl, setLocalAudioUrl] = React.useState(null);
  const [duration, setDuration] = React.useState(0);

  React.useEffect(() => {
    if (submitted && localAudioUrl) {
      setLocalAudioUrl(null);
    }
  }, [submitted]);

  const getMicrophonePermission = async () => {
    try {
      const streamData = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      return { error: false, data: streamData };
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        alert('You need to grant microphone access to use this feature.');
      } else if (err.name === 'NotFoundError') {
        alert(
          'No microphone was found. Please connect a microphone and try again.'
        );
      } else if (err.name === 'NotReadableError') {
        alert(
          'Failed to access microphone. Please ensure that no other application is using it and try again.'
        );
      } else {
        alert('Failed to access microphone. Please try again later.');
      }
      return { error: false, data: 'error' };
    }
  };

  const startRecording = async () => {
    try {
      setRecordingStatus('recording');

      const { data, error } = await getMicrophonePermission();

      mediaStream.current = data;

      if (error) throw new Error(data);

      mediaRecorder.current = new MediaRecorder(data, { type: mimeType });
      mediaRecorder.current?.start();

      let localAudioChunks = [];

      mediaRecorder.current.ondataavailable = (e) => {
        if (typeof e.data === 'undefined') return;
        if (e.data.size === 0) return;
        localAudioChunks.push(e.data);
      };

      durationInterval.current = setInterval(() => {
        setDuration((prevDuration) => prevDuration + 1);
      }, 1000);

      setAudioChunks(localAudioChunks);
    } catch (err) {
      setRecordingStatus('error');
      console.log(err.message);
    }
  };

  const stopRecording = () => {
    try {
      setRecordingStatus('inactive');
      mediaRecorder.current?.stop();
      mediaRecorder.current.onstop = () => {
        clearInterval(durationInterval.current);
        setDuration(0);

        const audioBlob = new Blob(audioChunks, { type: mimeType });
        const audioUrl = URL.createObjectURL(audioBlob);
        setLocalAudioUrl(audioUrl);
        setAudio(audioBlob);

        // Release resources
        mediaRecorder.current = null;
        mediaStream.current?.getTracks().forEach((track) => track.stop());
        mediaStream.current = null;
      };
    } catch (err) {
      setRecordingStatus('error');
      console.log(err.message);
    }
  };

  return (
    <Box>
      {recordingStatus === 'inactive' && localAudioUrl ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: '390px',
            mb: 2,
          }}
        >
          <audio controls>
            <source src={localAudioUrl} type='audio/wav' />
            Your browser does not support the audio element.
          </audio>
          <IconButton
            onClick={() => {
              setAudio(null);
              setLocalAudioUrl(null);
            }}
          >
            <CloseOutlinedIcon fontSize='small' />
          </IconButton>
        </Box>
      ) : null}

      {recordingStatus === 'inactive' ? (
        <Button
          variant='contained'
          disableElevation
          startIcon={<MicIcon />}
          onClick={startRecording}
          sx={{ borderRadius:2}}
        >
          Record audio
        </Button>
      ) : recordingStatus === 'recording' ? (
        <Stack direction='row' alignItems='center' spacing={2}>
          <MicIcon color='error' className='pulse' />
          <p>{formatDuration(duration)}</p>
          <Button
            variant='outlined'
            disableElevation
            startIcon={<MicOffIcon />}
            onClick={stopRecording}
          >
            Stop
          </Button>
        </Stack>
      ) : null}
    </Box>
  );
}
