import React from 'react';
import * as Yup from 'yup';
import { listsUrls } from '@config/routes';
import { currencyMeta } from '@config/meta/list/index';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { currenciesUrls } = listsUrls;

export default function CurrencyDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Currency"
        initialValues={{
          ...getAddFormInitialValues(currencyMeta({}), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(currencyMeta({}), 'listView'),
        })}
        getPostUrl={() => currenciesUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField label="Name" name="name" required />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Code" name="code" />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
