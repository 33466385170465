import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Countries from '@pages/Lists/Countries';
import Currencies from '@pages/Lists/Currencies';
import SocialMedias from '@pages/Lists/SocialMedias';
import SICCodes from '@pages/Lists/SICCodes';
import Timezones from '@pages/Lists/Timezones';
import Languages from '@pages/Lists/Languages';
import Cities from '@pages/Lists/Cities';
import PostCodes from '@pages/Lists/PostCodes';
import CountriesDetail from '@pages/Lists/Countries/detail';
import CurrenciesDetail from '@pages/Lists/Currencies/detail';
import LanguageDetail from '@pages/Lists/Languages/detail';

export default function () {
  return (
    <>
      <Route path='/countries' element={<WithLayout comp={Countries} />} />
      <Route path='/currencies' element={<WithLayout comp={Currencies} />} />
      <Route
        path='/social-medias'
        element={<WithLayout comp={SocialMedias} />}
      />
      <Route path='/sic-codes' element={<WithLayout comp={SICCodes} />} />
      <Route path='/timezones' element={<WithLayout comp={Timezones} />} />
      <Route path='/languages' element={<WithLayout comp={Languages} />} />
      <Route path='/cities' element={<WithLayout comp={Cities} />} />
      <Route path='/postcodes' element={<WithLayout comp={PostCodes} />} />
      <Route path='/countries/:id' element={<WithLayout comp={CountriesDetail} />} />
      <Route path='/currencies/:id' element={<WithLayout comp={CurrenciesDetail} />} />
      <Route path='/languages/:id' element={<WithLayout comp={LanguageDetail} />} />

    </>
  );
}
