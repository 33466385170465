import React from 'react';
import { Box, Typography } from '@mui/material';

export default function ChatMessage({
  active,
  name,
  email,
  onClick = () => null,
  noHover,
  highlight,
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
        borderRadius: '8px',
        p: 1,
        pb: 1,
        bgcolor: active ? 'rgba(222,222,222,0.3)' : '',
        '&:hover': {
          bgcolor: noHover ? '' : 'rgba(222,222,222,0.3)',
        },
        position: 'relative',
      }}
    >
      <Box
        sx={{
          mr: 2,
          width: '38px',
          height: '38px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          bgcolor: '#D2D2DC',
          color: '#4F4F6D',
          fontWeight: '500',
        }}
      >
        {name.substring(0, 1).toUpperCase()}
      </Box>
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
          {name.length > 18 ? `${name.substring(0, 18)}...` : name}
        </Typography>
        {email ? (
          <Typography sx={{ fontSize: '14px' }}>
            {email.length > 18 ? `${email.substring(0, 18)}...` : email}
          </Typography>
        ) : null}
        {highlight ? (
          <Box
            sx={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              bgcolor: 'red',
              position: 'absolute',
              top: '12px',
              right: '12px',
              zIndex: '20',
            }}
          ></Box>
        ) : null}
      </Box>
    </Box>
  );
}
