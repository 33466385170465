import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { cmsV2Urls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { pageRowsMap } from '@config/handleRows/cms-v2';
import { PageForm } from '@config/forms/cms-v2';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';
import LinkIcon from '@mui/icons-material/Link';

const { pagesUrls, clonePageUrls } = cmsV2Urls;

export default function Pages({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify, update] = useToast();
  const queryClient = useQueryClient();

  const handleClonePage = async (row) => {
    const toastId = notify('Cloning....', { loading: true });
    try {
      await createRecord({
        values: {
          pageId: row?.id,
          pageGroupId: row?.pageGroupId,
        },
        url: clonePageUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });
      queryClient.invalidateQueries(['Pages-listData']);
      update(toastId, 'Cloned');
    } catch (err) {
      update(toastId, 'Failed to clone', { type: 'ERROR' });
    }
  };

  return (
    <Box>
      <ListView
        nodeAPI
        label='Pages'
        prefix='0gg'
        labels={labels}
        editMode={editMode}
        microservice={microservices.CMS.name}
        model={microservices.CMS.models.pages}
        urls={pagesUrls}
        columnKey='cmsV2Page'
        dynamicActions={(params) => [
          {
            icon: <LinkIcon sx={{ mr: 1 }} />,
            label: 'Clone',
            handleClick: (row) => () => {
              handleClonePage(row.row);
            },
          },
        ]}
        addNewForm={{
          getFields: ({ setFieldValue }) => PageForm({ setFieldValue }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError,
            setFieldError
          ) => {
            try {
              const { pageTemplateId, pageGroupId, ...rest } = values;

              if (!pageGroupId?.id) {
                setFieldError('pageGroupId', 'This field is required');
                return;
              }

              if (!pageTemplateId?.id) {
                setFieldError('pageTemplateId', 'This field is required');
                return;
              }

              const response = await createRecord({
                values: {
                  pageTemplateId: pageTemplateId?.id,
                  pageGroupId: pageGroupId?.id,
                  ...rest,
                },
                url: pagesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={pageRowsMap}
      />
    </Box>
  );
}
