import {
  domainMeta,
  menuMeta,
  menuOptionMeta,
  pageMeta,
  pageOptionMeta,
  pageMarketSegmentMeta,
  pageGroupMeta,
  pageTemplateMeta,
  pageTemplateOptionMeta,
  pageToPageGroupMeta,
} from '@config/meta/cms-v2';
import {
  universityMeta,
  teamMemberMeta,
  facultyMeta,
  teamMeta,
  categoryMeta,
  coachMeta,
  moduleLessonMeta,
  courseMeta,
  courseModuleMeta,
  courseMentorMeta,
  courseTeamMeta,
  facultyInstructorMeta,
  facultyCourseMeta,
  homeworkMeta,
  homeworkProgressMeta,
  instructorMeta,
  lessonMeta,
  lessonReferencesMeta,
  lessonCoachMeta,
  lessonProgressMeta,
  mentorMeta,
  moduleMeta,
  skillMeta,
  studentMeta,
  lessonInstructorMeta,
} from '@config/meta/lms';
import {
  workflowMeta,
  stageMeta,
  modelAssetStageMeta,
  manualWorkflowInstanceMeta,
  assetMeta,
  assetsPopupMeta,
} from '@config/meta/bpa';
import {
  formsMeta,
  questionGroupMeta,
  groupMeta,
  questionsMeta,
  branchingConditionMeta,
  responseMeta,
  questionOptionMeta,
  questionOptionGroupMeta,
  questionGroupScoreRangeMeta,
  personalityMeta,
  recommendedCourseMeta,
} from '@config/meta/forms/formsMeta';
import {
  programMeta,
  workCodesMeta,
  timesheetsMeta,
  roleMeta,
  resourceMeta,
  projectMeta,
  projectTemplateMeta,
  personaMeta,
  hlrMeta,
  backlogMeta,
  uatMeta,
  testsConductedMeta,
  featureRequestMeta,
  bugMeta,
  sprintMetasMeta,
  sprintMetaProjectsMeta,
  sprintsMeta,
  sprintTasksMeta,
  taskStatusMeta,
  taskTypeMeta,
  tasksMeta,
  bugCommentMeta,
  taskBacklogMeta,
  backlogTasksMeta,
  backlogArtifactMeta,
  taskBugMeta,
  taskCommentMeta,
  stakeholderMeta,
  workingTimeMeta,
} from '@config/meta/pm/pmMeta';
import { documentMeta, documentRecipientMeta } from '@config/meta/eDoc';
import {
  tagsMeta,
  sectionTagsMeta,
  sectionsMeta,
  chaptersMeta,
  booksMeta,
  bookCategoryMeta,
} from '@config/meta/wiki';
import {
  channelMeta,
  personRelationshipHistoryMeta,
  callHistoryMeta,
  callScheduleMeta,
  callListPipelineStageMeta,
  callListPipelineMeta,
  callListMeta,
  personCompanyMeta,
  callRecordingsMeta,
  remindersMeta,
  actionMeta,
  activityTypesMeta,
  activityKindsMeta,
  activityNotesMeta,
  companySpinsMeta,
  salesPeopleTargetMeta,
  territoryOwnerMeta,
  companyTerritoriesMeta,
  accountManagersMeta,
  marketingListMeta,
  territoriesMeta,
  companiesMeta,
  personsMeta,
  opportunitiesMeta,
  companyOfficeMeta,
  historyMeta,
  companyContactMeta,
  importLogMeta,
  importErrorLogMeta,
  personSocialMediaMeta,
  clientsMeta,
  opportunityInfluencerMeta,
  socialMediaTypeMeta,
  opportunityProductsMeta,
  relationshipMeta,
  personRelationshipMeta,
  pipelineMeta,
  pipelineStageMeta,
  companySICMeta,
  companySocialMediaMeta,
  opportunityDataNeedMeta,
  opportunityActionPlanMeta,
  personHistoryMeta,
  opportunityHistoryMeta,
  clientHistoryMeta,
  digitalDocColumns,
  formColumns,
  opportunitiesColumns,
  calendarColumns,
  notificationsColumns,
  inasMeta,
  customerEnquiryStatusMeta,
  customerEnquiryPurposeMeta,
  customerEnquiriesMeta,
} from '@config/meta/crm';
import {
  doWhatMeta,
  inasMeta as inasMetaCalendar,
  batchReminderMeta,
  calendarMeta,
  linkMeta,
  linkSlotMeta,
  linkRecipientMeta,
} from '@config/meta/calendar/calendarMeta';
import {
  interviewNoteMeta,
  assessmentMeta,
  recruitmentPipelineMeta,
  recruitmentPipelineStageMeta,
  keywordMeta,
  vacancyStatusMeta,
  coursesMeta,
  skillLevelMeta,
  rolesMeta,
  employeesMeta,
  vacancyMeta,
  departmentEmployeeMeta,
  departmentMeta,
  skillMeta as skillMetaHR,
  roleHistoryMeta,
  employeeDepartmentMeta,
  employeeSkillMeta,
  candidateMeta,
  referencesMeta,
  salaryHistoryMeta,
  trainingHistoryMeta,
  holidayEntitlementMeta,
  dayOffMeta,
  performanceReviewMeta,
  jobLevelMeta,
  dayOffTypesMeta,
  candidateAdminMeta,
  candidateAdminListMeta,
  employeeStatusMeta,
} from '@config/meta/hr';
import {
  variantKeyMeta,
  productTypeMeta,
  manufacturerMeta,
  manufacturerContactMeta,
  supplierContactMeta,
  supplierMeta,
  productMeta,
  productVariantMeta,
  productArtifactMeta,
  productSupplierMeta,
  warehouseMeta,
  warehouseInventoryMeta,
  deliveryMeta,
  deliveryItemMeta,
  deliveryArtifactMeta,
  variantValueMeta,
  productAdditionalInfoMeta,
  inventoryMeta,
  inventoryTransactioMeta,
  mediaMeta,
} from '@config/meta/inventory';
import {
  discountCodeMeta,
  paymentStatusMeta,
  paymentProviderMeta,
  paymentMethodMeta,
  deliveryMethodMeta,
  deliveryStatusMeta,
  cartMeta,
  addressMeta,
  paymentMeta,
  cartItemMeta,
  itemDeliveryMeta,
} from '@config/meta/ecommerce';
import {
  labelColumns,
  notificationLabelMeta,
  emailMeta,
  notificationEmailAddressMeta,
  emailAttachmentMeta,
  emailTemplateMeta,
  contextFieldMeta,
  imapProviderMeta,
  imapAccountMeta,
} from '@config/meta/communication';
import {
  SOPTaskStepMeta,
  SOPsTaskMeta,
  SOPsMeta,
} from '@config/meta/SOPs/SOPsMeta';
import { visitorLogMeta } from '@config/meta/logs/logsMeta';
import {
  criteriaMeta,
  marketSegmentsMeta,
  postsMeta,
  campaignsMeta,
} from '@config/meta/marketing';
import { qrCodeMeta } from '@config/meta/qrCode';
import { linkMeta as linkMetaURLShortener } from '@config/meta/urlShortener';
import {
  fieldsMeta,
  enumsMeta,
  modelsMeta,
  computeLogsMeta,
  enumValuesMeta,
  blockGroupsMeta,
  blocksMeta,
  microservicesMeta as microservicesMetaCompute,
  formFlowsMeta,
  formFlowStepsMeta,
  formFieldLayoutMeta,
  menusMeta,
} from '@config/meta/compute';
import {
  timeZonesMeta,
  socialMediaMeta,
  sicCodesMeta,
  postCodesMeta,
  languageMeta,
  countryCurrencyMeta,
  countryLanguageMeta,
  countryMeta,
  cityMeta,
  currencyMeta,
} from '@config/meta/list';
import { fileFolderMeta } from '@config/meta/drive';
import {
  profileRolesMeta,
  usersMeta,
  rolesMeta as rolesMetaAccounts,
  rolesUserMeta,
  rolesPermissionMeta,
  modelsMeta as modelsMetaAccounts,
  microservicesMeta,
} from '@config/meta/accounts/accountMeta';
import {
  uiLabelMeta,
  templateMeta,
  systemLogsMeta,
  shapeTransitionMeta,
  logsMeta,
  importLogMeta as importLogMetaSystem,
  automataConnectionWithAModelMeta,
  relationShipMeta,
  clientMeta,
  clientRelationsMeta,
} from '@config/meta/system';
import { agentsMeta, customersMeta, ticketsMeta } from '@config/meta/webChat';

export const META_MAPPING = {
  domain: domainMeta,
  menu: menuMeta,
  menuOption: menuOptionMeta,
  cmsV2Page: pageMeta,
  pageOption: pageOptionMeta,
  pageMarketSegment: pageMarketSegmentMeta,
  pageGroup: pageGroupMeta,
  pageTemplate: pageTemplateMeta,
  pageTemplateOption: pageTemplateOptionMeta,
  university: universityMeta,
  faculty: facultyMeta,
  team: teamMeta,
  teamMember: teamMemberMeta,
  categoryLMS: categoryMeta,
  coach: coachMeta,
  moduleLesson: moduleLessonMeta,
  course: courseMeta,
  courseModule: courseModuleMeta,
  courseMentor: courseMentorMeta,
  courseTeam: courseTeamMeta,
  facultyInstructor: facultyInstructorMeta,
  facultyCourse: facultyCourseMeta,
  homework: homeworkMeta,
  homeworkProgress: homeworkProgressMeta,
  instructor: instructorMeta,
  lesson: lessonMeta,
  lessonReferences: lessonReferencesMeta,
  lessonCoach: lessonCoachMeta,
  lessonProgress: lessonProgressMeta,
  mentor: mentorMeta,
  module: moduleMeta,
  skill: skillMeta,
  student: studentMeta,
  pageToPageGroup: pageToPageGroupMeta,
  lessonInstructor: lessonInstructorMeta,

  // Automata
  workflow: workflowMeta,
  stage: stageMeta,
  modelAssetStage: modelAssetStageMeta,
  manualWorkflowInstance: manualWorkflowInstanceMeta,
  asset: assetMeta,
  assetsPopup: assetsPopupMeta,

  // Forms
  forms: formsMeta,
  questionGroup: questionGroupMeta,
  groupForms: groupMeta,
  personalityForms: personalityMeta,
  recommendedCourseForms: recommendedCourseMeta,
  questions: questionsMeta,
  branchingCondition: branchingConditionMeta,
  responseForms: responseMeta,

  // PM
  programPM: programMeta,
  workCodesPM: workCodesMeta,
  timesheetsPM: timesheetsMeta,
  rolePM: roleMeta,
  resourcePM: resourceMeta,
  projectPM: projectMeta,
  projectTemplatePM: projectTemplateMeta,
  personaPM: personaMeta,
  hlrPM: hlrMeta,
  backlogPM: backlogMeta,
  uatPM: uatMeta,
  testsConductedPM: testsConductedMeta,
  featureRequestPM: featureRequestMeta,
  bugPM: bugMeta,
  sprintMetasPM: sprintMetasMeta,
  sprintMetaProjectsPM: sprintMetaProjectsMeta,
  sprintsPM: sprintsMeta,
  sprintTasksPM: sprintTasksMeta,
  taskStatusPM: taskStatusMeta,
  taskTypePM: taskTypeMeta,
  tasksPM: tasksMeta,
  bugCommentPM: bugCommentMeta,
  taskBacklogPM: taskBacklogMeta,
  backlogTasksPM: backlogTasksMeta,
  backlogArtifactPM: backlogArtifactMeta,
  taskBugPM: taskBugMeta,
  taskCommentPM: taskCommentMeta,
  stakeholderPM: stakeholderMeta,
  workingTimePM: workingTimeMeta,
  questionOptionGroup: questionOptionGroupMeta,
  questionOption: questionOptionMeta,
  questionGroupScoreRange: questionGroupScoreRangeMeta,

  // eDoc
  document: documentMeta,
  documentRecipient: documentRecipientMeta,

  // Wiki
  tagsWiki: tagsMeta,
  sectionTagsWiki: sectionTagsMeta,
  sectionsWiki: sectionsMeta,
  chaptersWiki: chaptersMeta,
  booksWiki: booksMeta,
  bookCategoryWiki: bookCategoryMeta,

  // CRM
  channelCRM: channelMeta,
  personRelationshipHistoryCRM: personRelationshipHistoryMeta,
  callHistoryCRM: callHistoryMeta,
  callScheduleCRM: callScheduleMeta,
  callListPipelineStageCRM: callListPipelineStageMeta,
  callListPipelineCRM: callListPipelineMeta,
  callListCRM: callListMeta,
  personCompanyCRM: personCompanyMeta,
  callRecordingsCRM: callRecordingsMeta,
  remindersCRM: remindersMeta,
  actionCRM: actionMeta,
  activityTypesCRM: activityTypesMeta,
  activityKindsCRM: activityKindsMeta,
  activityNotesCRM: activityNotesMeta,
  companySpinsCRM: companySpinsMeta,
  salesPeopleTargetCRM: salesPeopleTargetMeta,
  territoryOwnerCRM: territoryOwnerMeta,
  companyTerritoriesCRM: companyTerritoriesMeta,
  accountManagersCRM: accountManagersMeta,
  marketingListCRM: marketingListMeta,
  territoriesCRM: territoriesMeta,
  companiesCRM: companiesMeta,
  personsCRM: personsMeta,
  opportunitiesCRM: opportunitiesMeta,
  companyOfficeCRM: companyOfficeMeta,
  historyCRM: historyMeta,
  companyContactCRM: companyContactMeta,
  importLogCRM: importLogMeta,
  importErrorLogCRM: importErrorLogMeta,
  personSocialMediaCRM: personSocialMediaMeta,
  clientsCRM: clientsMeta,
  opportunityInfluencerCRM: opportunityInfluencerMeta,
  socialMediaTypeCRM: socialMediaTypeMeta,
  opportunityProductsCRM: opportunityProductsMeta,
  relationshipCRM: relationshipMeta,
  personRelationshipCRM: personRelationshipMeta,
  pipelineCRM: pipelineMeta,
  pipelineStageCRM: pipelineStageMeta,
  companySICCRM: companySICMeta,
  companySocialMediaCRM: companySocialMediaMeta,
  opportunityDataNeedCRM: opportunityDataNeedMeta,
  opportunityActionPlanCRM: opportunityActionPlanMeta,
  personHistoryCRM: personHistoryMeta,
  opportunityHistoryCRM: opportunityHistoryMeta,
  clientHistoryCRM: clientHistoryMeta,
  digitalDocCRM: digitalDocColumns,
  formCRM: formColumns,
  opportunitiesColumnsCRM: opportunitiesColumns,
  calendarCRM: calendarColumns,
  notificationsCRM: notificationsColumns,
  inasCRM: inasMeta,
  customerEnquiryStatusCRM: customerEnquiryStatusMeta,
  customerEnquiryPurposeCRM: customerEnquiryPurposeMeta,
  customerEnquiriesCRM: customerEnquiriesMeta,

  // Calendar
  doWhatCalendar: doWhatMeta,
  calendarInasCalendar: inasMetaCalendar,
  batchReminderCalendar: batchReminderMeta,
  calendarCalendar: calendarMeta,
  linkCalendar: linkMeta,
  linkSlotCalendar: linkSlotMeta,
  linkRecipientCalendar: linkRecipientMeta,

  // SOPs
  SOPTaskStep: SOPTaskStepMeta,
  SOPsTask: SOPsTaskMeta,
  SOPs: SOPsMeta,

  // HR
  interviewNoteHR: interviewNoteMeta,
  assessmentHR: assessmentMeta,
  recruitmentPipelineHR: recruitmentPipelineMeta,
  recruitmentPipelineStageHR: recruitmentPipelineStageMeta,
  keywordHR: keywordMeta,
  vacancyStatusHR: vacancyStatusMeta,
  coursesHR: coursesMeta,
  skillLevelHR: skillLevelMeta,
  rolesHR: rolesMeta,
  employeesHR: employeesMeta,
  vacancyHR: vacancyMeta,
  departmentEmployeeHR: departmentEmployeeMeta,
  departmentHR: departmentMeta,
  skillMetaHR: skillMetaHR,
  roleHistoryHR: roleHistoryMeta,
  employeeDepartmentHR: employeeDepartmentMeta,
  employeeSkillHR: employeeSkillMeta,
  candidateHR: candidateMeta,
  referencesHR: referencesMeta,
  salaryHistoryHR: salaryHistoryMeta,
  trainingHistoryHR: trainingHistoryMeta,
  holidayEntitlementHR: holidayEntitlementMeta,
  dayOffHR: dayOffMeta,
  performanceReviewHR: performanceReviewMeta,
  jobLevelHR: jobLevelMeta,
  dayOffTypesHR: dayOffTypesMeta,
  candidateAdminHR: candidateAdminMeta,
  candidateAdminListHR: candidateAdminListMeta,
  employeeStatusHR: employeeStatusMeta,

  // Inventory
  variantKeyInventory: variantKeyMeta,
  productTypeInventory: productTypeMeta,
  manufacturerInventory: manufacturerMeta,
  manufacturerContactInventory: manufacturerContactMeta,
  supplierContactInventory: supplierContactMeta,
  supplierInventory: supplierMeta,
  productInventory: productMeta,
  productVariantInventory: productVariantMeta,
  productArtifactInventory: productArtifactMeta,
  productSupplierInventory: productSupplierMeta,
  warehouseInventory: warehouseMeta,
  warehouseInventoryInventory: warehouseInventoryMeta,
  deliveryInventory: deliveryMeta,
  deliveryItemInventory: deliveryItemMeta,
  deliveryArtifactInventory: deliveryArtifactMeta,
  variantValueInventory: variantValueMeta,
  productAdditionalInfoInventory: productAdditionalInfoMeta,
  inventoryInventory: inventoryMeta,
  inventoryTransactioInventory: inventoryTransactioMeta,
  mediaInventory: mediaMeta,

  // e-commerce
  discountCodeEcommerce: discountCodeMeta,
  paymentStatusEcommerce: paymentStatusMeta,
  paymentProviderEcommerce: paymentProviderMeta,
  paymentMethodEcommerce: paymentMethodMeta,
  deliveryMethodEcommerce: deliveryMethodMeta,
  deliveryStatusEcommerce: deliveryStatusMeta,
  cartEcommerce: cartMeta,
  addressEcommerce: addressMeta,
  paymentEcommerce: paymentMeta,
  cartItemEcommerce: cartItemMeta,
  itemDeliveryEcommerce: itemDeliveryMeta,

  // Communication
  labelCommunication: labelColumns,
  notificationLabelCommunication: notificationLabelMeta,
  emailCommunication: emailMeta,
  notificationEmailAddressCommunication: notificationEmailAddressMeta,
  emailAttachmentCommunication: emailAttachmentMeta,
  emailTemplateCommunication: emailTemplateMeta,
  contextFieldCommunication: contextFieldMeta,
  imapProviderCommunication: imapProviderMeta,
  imapAccountCommunication: imapAccountMeta,

  // Marketing
  visitorLog: visitorLogMeta,
  linkURLShorteners: linkMetaURLShortener,
  qrCode: qrCodeMeta,
  criteriaMarketSegment: criteriaMeta,
  marketSegments: marketSegmentsMeta,
  postsCampaigns: postsMeta,
  campaignsMarketing: campaignsMeta,

  // Compute
  microservicesCompute: microservicesMetaCompute,
  fieldsCompute: fieldsMeta,
  enumsCompute: enumsMeta,
  modelsCompute: modelsMeta,
  menusCompute: menusMeta,
  computeLogs: computeLogsMeta,
  enumValuesCompute: enumValuesMeta,
  blockGroupsCompute: blockGroupsMeta,
  blocksCompute: blocksMeta,
  formFlowsCompute: formFlowsMeta,
  formFlowStepsCompute: formFlowStepsMeta,
  formFieldLayoutsCompute: formFieldLayoutMeta,

  // Lists
  timeZonesLists: timeZonesMeta,
  socialMediaLists: socialMediaMeta,
  sicCodesLists: sicCodesMeta,
  postCodesLists: postCodesMeta,
  languageLists: languageMeta,
  countryCurrencyLists: countryCurrencyMeta,
  countryLanguageLists: countryLanguageMeta,
  countryLists: countryMeta,
  cityLists: cityMeta,
  currencyLists: currencyMeta,

  // Drive
  fileFolderDrive: fileFolderMeta,

  // Accounts
  profileRolesAccounts: profileRolesMeta,
  usersAccounts: usersMeta,
  rolesAccounts: rolesMetaAccounts,
  rolesUserAccounts: rolesUserMeta,
  rolesPermissionAccounts: rolesPermissionMeta,
  modelsAccounts: modelsMetaAccounts,
  microserviceAccounts: microservicesMeta,

  // System
  uiLabelSystem: uiLabelMeta,
  templateSystem: templateMeta,
  systemLogs: systemLogsMeta,
  shapeTransitionSystem: shapeTransitionMeta,
  logsSystem: logsMeta,
  importLogSystem: importLogMetaSystem,
  automataConnectionWithAModel: automataConnectionWithAModelMeta,
  relationshipSystem: relationShipMeta,
  clientSystem: clientMeta,
  clientRelationsSystem: clientRelationsMeta,

  // WebChat
  agentsWebChat: agentsMeta,
  customersWebChat: customersMeta,
  ticketsWebChat: ticketsMeta,
};

export const getColumns = (columnKey, colOptions) => {
  const metaFunction = META_MAPPING[columnKey];
  if (metaFunction) {
    return colOptions ? metaFunction(colOptions) : metaFunction();
  }
  return [];
};
