import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import Header from '@components/KanbanBoardHeader';
import { Box } from '@mui/material';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { useEffect, useState } from 'react';
import EditableTable from '@components/EditableTableComps/EditableTable';
import useToast from '@hooks/useToast';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { formatDateTime } from '@config/functions/helperFunctions';

const { companiesUrls, opportunitiesUrls } = crmUrls;

export default function CompanyHistory({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [company, setCompany] = useState(null);
  const [opportunity, setOpportunity] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [notify] = useToast();
  const [value, setValue] = React.useState('1');
  const [listData, setListData] = useState({ results: [] });
  const [sortColumn, setSortColumn] = useState([
    { field: 'created_at', sort: 'desc' },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onBtnClick = async (company, btn) => {
    if (!company) {
      notify('Please select company', {
        type: 'ERROR',
      });
    } else if (btn === 'opportunity') {
      axios
        .get(
          opportunitiesUrls.list(
            `?page=${page}&page_size=${pageSize}&company=${
              company && company.id
            }&order=order
            `
          ),
          getAuthHeader(user.token, user?.actAs)
        )
        .then((res) => setOpportunity(res.data));
    }
  };

  useEffect(() => {
    if (Object.keys(opportunity).length === 0) return;
    opportunity.results = [
      ...listData.results.slice(0, (page - 1) * pageSize),
      ...opportunity.results.map((r) => ({
        id: r.id,
        opportunity: r.name,
        comapnyContact:
          r.person_detail &&
          r.person_detail.person &&
          r.person_detail.person.email,
        stage: r.details && r.details.status && r.details.status.stage,
        EstimateValue: r.estimated_value,
        EstimateCloseDate: formatDateTime(r.estimated_close_date),
      })),
    ];

    setListData(opportunity);
  }, [opportunity]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title="Company"
        prefix="0pg"
        labels={labels}
        editMode={editMode}
        filterOptions={[
          {
            label: 'Company',
            name: 'company',
            requestKey: 'company-history',
            fetchUrl: companiesUrls.list,
            handleChange: (newValue) => {
              setCompany(newValue);
            },
            renderRow: (r) => ({
              id: r.id,
              label: r.name,
            }),
          },
        ]}
      />

      <Box sx={{ mt: 2, bgcolor: 'rgba(0, 0, 0, .03)' }}>
        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                icon={<TextSnippetIcon />}
                label="Opportunity"
                iconPosition="start"
                onClick={() => onBtnClick(company, 'opportunity')}
                value="1"
              />
            </TabList>
          </Box>
          <TabPanel value={value} fullWidth>
            <EditableTable
              key="Editable"
              columnKey="opportunitiesColumnsCRM"
              rows={listData && listData.results}
              hideActions
              pageSize={pageSize}
              setPageSize={setPageSize}
              currentPage={page - 1}
              setCurrentPage={setPage}
              totalItems={listData && listData.totalCount}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              sortColumn={sortColumn}
              onSortColumnChange={(newSortColumn) =>
                setSortColumn(newSortColumn)
              }
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}
