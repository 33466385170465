import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ecommerceUrls, crmUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordianArrayView from '@components/DetailAccordionArrayView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import ecommerceHandleRows from '@config/handleRows/ecommerce';
import { CartForm, PaymentForm } from '@config/forms/ecommerce';
import {
  paymentStatusMeta,
} from '@config/meta/ecommerce';
import DetailPageHeader from '@components/DetailPageHeader';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  paymentStatusesUrls,
  cartsUrls,
  deliveryStatusesUrls,
  discountCodesUrls,
  paymentsUrls,
  addressesUrls,
  paymentProvidersUrls,
  paymentMethodsUrls,
} = ecommerceUrls;
const { personsUrls } = crmUrls;
const { cartRowMap, paymentRowMap } = ecommerceHandleRows;

export default function PaymentStatusDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [customerTerm, setCustomerTerm] = useState('');
  const [deliveryStatusTerm, setDeliveryStatusTerm] = useState('');
  const [paymentStatusTerm, setPaymentStatusTerm] = useState('');
  const [discountCodeTerm, setDiscountCodeTerm] = useState('');
  const [cartTerm, setCartTerm] = useState([]);
  const [addressTerm, setAddressTerm] = useState([]);
  const [paymentProviderTerm, setPaymentProviderTerm] = useState([]);
  const [paymentMethodTerm, setPaymentMethodTerm] = useState([]);
  const [notify] = useToast();

  const {
    data: carts,
    isFetching: fetchingCarts,
    refetch: refetchCart,
  } = useAutocomplete({
    key: 'carts',
    getUrl: cartsUrls.list,
    inputValue: cartTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.customer && r.details && r.customer_details
            ? r.customer_details.first_name
            : r.customer,
      };
    },
  });

  const {
    data: addresses,
    isFetching: fetchingAddresses,
    refetch: refetchBilling,
  } = useAutocomplete({
    key: 'addresses',
    getUrl: addressesUrls.list,
    inputValue: addressTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.address1,
      };
    },
  });

  const {
    data: paymentProviders,
    isFetching: fetchingPaymentProviders,
    refetch: refetchPaymentProvider,
  } = useAutocomplete({
    key: 'paymentProviders',
    getUrl: paymentProvidersUrls.list,
    inputValue: paymentProviderTerm,
  });

  const {
    data: paymentMethods,
    isFetching: fetchingPaymentMethods,
    refetch: refetchPaymentMethod,
  } = useAutocomplete({
    key: 'paymentMethods',
    getUrl: paymentMethodsUrls.list,
    inputValue: paymentMethodTerm,
  });

  const {
    data: paymentStatuses,
    isFetching: fetchingPaymentStatuses,
    refetch: refetchPaymentStatus,
    refetch: refetchPayment,
  } = useAutocomplete({
    key: 'paymentStatuses',
    getUrl: paymentStatusesUrls.list,
    inputValue: paymentStatusTerm,
  });

  const {
    data: customers,
    isFetching: fetchingCustomers,
    refetch: refetchCustomer,
  } = useAutocomplete({
    key: 'customers',
    getUrl: personsUrls.list,
    inputValue: customerTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.first_name,
      };
    },
  });

  const {
    data: deliveryStatuses,
    isFetching: fetchingDeliveryStatuses,
    refetch: refetchDeliveryStatus,
  } = useAutocomplete({
    key: 'deliveryStatuses',
    getUrl: deliveryStatusesUrls.list,
    inputValue: deliveryStatusTerm,
  });

  const {
    data: discountCodes,
    isFetching: fetchingDiscountCodes,
    refetch: refetchDiscountCode,
  } = useAutocomplete({
    key: 'discountCodes',
    getUrl: discountCodesUrls.list,
    inputValue: discountCodeTerm,
  });

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => paymentStatusMeta({ data }),
    []
  );

  const cartsColOptions = React.useMemo(() => {
    return {
      customers,
      fetchingCustomers,
      setCustomerTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
      discountCodes,
      fetchingDiscountCodes,
      setDiscountCodeTerm,
    };
  }, [
    customers,
    fetchingCustomers,
    setCustomerTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
    paymentStatuses,
    fetchingPaymentStatuses,
    setPaymentStatusTerm,
    discountCodes,
    fetchingDiscountCodes,
    setDiscountCodeTerm,
  ]);

  const paymentsColOptions = React.useMemo(() => {
    return {
      carts,
      fetchingCarts,
      setCartTerm,
      addresses,
      fetchingAddresses,
      setAddressTerm,
      paymentProviders,
      fetchingPaymentProviders,
      setPaymentProviderTerm,
      paymentMethods,
      fetchingPaymentMethods,
      setPaymentMethodTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
    };
  }, [
    carts,
    fetchingCarts,
    setCartTerm,
    addresses,
    fetchingAddresses,
    setAddressTerm,
    paymentProviders,
    fetchingPaymentProviders,
    setPaymentProviderTerm,
    paymentMethods,
    fetchingPaymentMethods,
    setPaymentMethodTerm,
    paymentStatuses,
    fetchingPaymentStatuses,
    setPaymentStatusTerm,
  ]);

  const accordData = {
    carts: {
      columnKey: "cartEcommerce",
      columnOptions: cartsColOptions,
      microservice: 'E-Commerce',
      model: 'Cart',

      label: 'Carts',
      prefix: '0ry',
      urls: cartsUrls,
      addNewForm: {
        getFields: () =>
          CartForm({
            customers,
            fetchingCustomers,
            setCustomerTerm,
            refetchCustomer,
            deliveryStatuses,
            fetchingDeliveryStatuses,
            setDeliveryStatusTerm,
            refetchDeliveryStatus,
            paymentStatuses,
            fetchingPaymentStatuses,
            setPaymentStatusTerm,
            refetchPayment,
            discountCodes,
            fetchingDiscountCodes,
            setDiscountCodeTerm,
            refetchDiscountCode,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                payment_status: recordId,
              },
              url: cartsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Cart Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: cartRowMap,
      urlParams: `&payment_status=${recordId}`,
    },
    payments: {
      columnKey: "paymentEcommerce",
      columnOptions: paymentsColOptions,
      microservice: 'E-Commerce',
      model: 'Payment',

      label: 'Payments',
      prefix: '05n',
      urls: paymentsUrls,
      addNewForm: {
        getFields: () =>
          PaymentForm({
            carts,
            fetchingCarts,
            setCartTerm,
            addresses,
            fetchingAddresses,
            setAddressTerm,
            paymentProviders,
            fetchingPaymentProviders,
            setPaymentProviderTerm,
            paymentMethods,
            fetchingPaymentMethods,
            setPaymentMethodTerm,
            paymentStatuses,
            fetchingPaymentStatuses,
            setPaymentStatusTerm,
            refetchCart,
            refetchBilling,
            refetchPaymentProvider,
            refetchPaymentMethod,
            refetchPaymentStatus,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                payment_status: recordId,
              },
              url: paymentsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Payment Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: paymentRowMap,
      urlParams: `&payment_status=${recordId}`,
    },
  };

  return (
    <Box key="detail-view-container">
      {recordData ? (
        <Box key="detail-view-header-container">
          <DetailPageHeader
            key="detail-view-header"
            items={[
              { to: `/payment-statuses`, text: 'Payment Statuses' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={paymentStatusesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordianArrayView
          value={accordData}
          labels={labels}
          editMode={editMode}
        />
      </Box>
    </Box>
  );
}
