import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { pmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { uatMeta } from '@config/meta/pm/pmMeta';
import { testsConductedRowsMap } from '@config/handleRows/pm';
import { TestsConductedForm } from '@config/forms/pm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { uatsUrls, testsConductedUrls } = pmUrls;

export default function UATDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => uatMeta({ data }), []);

  const testConductedColOptions = React.useMemo(
    () => ({
      hide: true,
    }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/hlrs/${recordData.details.backlog.hlr}`,
                text: 'Backlogs',
              },
              {
                to: `/backlogs/${recordData.backlog}`,
                text: recordData.details.backlog.i_want,
              },

              {
                to: `/backlogs/${recordData.backlog}`,
                text: 'Acceptance Tests',
              },
              { text: recordData && recordData.given },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={uatsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          prefix='02s'
          labels={labels}
          editMode={editMode}
          microservice={microservices.PM.name}
          model={microservices.PM.models.testsConducted}
          columnKey='testsConductedPM'
          columnOptions={testConductedColOptions}
          label='Tests Conducted'
          urls={testsConductedUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              TestsConductedForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { tested_by, ...rest } = values;
                const errors = [];

                if (!tested_by?.id) {
                  errors.push('tested_by');
                }

                if (errors.length) {
                  errors.forEach((name) => {
                    setFieldError(name, 'This field is required');
                  });

                  return;
                }

                const response = await createRecord({
                  values: {
                    ...rest,
                    acceptance_criteria: recordId,
                    tested_by: tested_by?.id,
                  },
                  url: testsConductedUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={testsConductedRowsMap}
          urlParams={`&acceptance_criteria=${recordId}`}
        />
      </Box>
    </Box>
  );
}
