import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Typography,
  Skeleton,
} from '@mui/material';
import { Formik, Form } from 'formik';
import {
  TextField,
  CheckBoxField,
  AutocompleteFieldV2,
  DateTimePickerField,
  AutocompleteField,
} from '@components/Inputs';
import { calendarUrls, accountUrls, bpaUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { getActionParamAndValue } from '@config/functions/bpaFunctions';
import {
  formatToUTCDateTime,
  getAuthHeader,
} from '@config/functions/helperFunctions';
import useRQuery from '@hooks/useRQuery';
import { useQueryClient } from 'react-query';
import EmailTemplateDropdown from '@dropdown-forms/communication/EmailTemplateDropdown';
import ReminderPopup from '@components/ReminderPopup';

const {
  getPayloadActionsUrls,
  payloadSectionKeysUrls,
  bulkCreateShapeActionParamsUrls,
  manualSendEmailUrls,
  modelAssetsUrls,
} = bpaUrls;
const { calendarsListUrls } = calendarUrls;
const { usersUrls } = accountUrls;

export default function SendEmailPopup({
  open,
  setOpen,
  actionParams,
  env = 'BPA',
  workflowId,
  shapeAction,
  user,
  shape,
  workflowInstances = [],
  workflowName,
}) {
  const [notify] = useToast();
  const fixedOptions = [
    { id: user?.details?.email, label: user?.details?.email },
  ];
  const [userTerm, setUserTerm] = React.useState('');
  const [contextFields, setContextFields] = React.useState([]);
  const [contextFieldInit, setContextFieldInit] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const queryClient = useQueryClient();
  const [showAddIna, setShowAddIna] = React.useState(false);

  const { data: usersList, isFetching: fetchingUsers } = useRQuery({
    key: ['users-data', userTerm, user.token, user?.actAs],
    url: usersUrls.list(`?search=${userTerm}&page_size=100&queryCode=cba2`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  const initialTemplate = getActionParamAndValue({
    key: 'template_id',
    actionParams,
  });
  const initialToEmail = getActionParamAndValue({
    key: 'to_email',
    actionParams,
  });
  const initialAddReminder = getActionParamAndValue({
    key: 'add_reminder',
    actionParams,
  });
  const initialAddSlots = getActionParamAndValue({
    key: 'add_slot',
    actionParams,
  });
  const initialContext = getActionParamAndValue({
    key: 'context',
    actionParams,
    multipleValues: true,
  });

  function renderEmailTemplate(row) {
    const { name, details } = row?.details?.asset;
    const contextFields = Array.isArray(details?.context_fields)
      ? details.context_fields
      : [];

    return {
      id: row?.id,
      label: name,
      contextFields,
    };
  }

  function renderPayloadSections(row) {
    const { id, name, keys } = row;

    return {
      id,
      label: name,
      sectionKeys: keys,
    };
  }

  function getTemplateValues(initialTemplate) {
    const { value } = initialTemplate || {};

    if (!value || !value.model_asset) {
      return null;
    }

    return {
      id: value.model_asset,
      label: value.label,
    };
  }

  function getEmailPayloadSectionValues(initialToEmail) {
    const { value } = initialToEmail || {};

    if (!value || !value.details || !value.details.mapping_field_name) {
      return null;
    }

    return {
      id: value.details.mapping_field_name.payload_section,
      label: value.details.mapping_field_name.details?.payload_section?.name,
    };
  }

  function getEmailPayloadSectionKeyValues(initialToEmail) {
    const { value } = initialToEmail || {};

    if (!value || !value.mapping_field_name) {
      return null;
    }

    return {
      id: value.mapping_field_name,
      label: value?.details?.mapping_field_name?.label,
    };
  }

  function getContextValues(initialContext) {
    const { values } = initialContext || {};

    if (!Array.isArray(values)) {
      return null;
    }

    const initialValues = {};

    values.forEach(({ key, mapping_field_name, details }) => {
      const sectionLabel =
        details?.mapping_field_name?.details?.payload_section?.name;
      const { label } = details?.mapping_field_name;

      initialValues[`context-${key}-payloadSection`] = {
        id: details?.mapping_field_name?.payload_section,
        label: sectionLabel,
      };
      initialValues[`context-${key}-payloadSectionKeys`] = {
        id: mapping_field_name,
        label,
      };
    });

    return initialValues;
  }

  function getInitialValues() {
    return {
      template: getTemplateValues(initialTemplate),
      toEmailPayloadSection: getEmailPayloadSectionValues(initialToEmail),
      toEmailPayloadSectionKey: getEmailPayloadSectionKeyValues(initialToEmail),
      addReminder: initialAddReminder?.value?.static_value === 'true',
      addSlots: initialAddSlots?.value?.static_value === 'true',
      slotBegin: formatToUTCDateTime(new Date()),
      slotEnd: formatToUTCDateTime(new Date()),
      reminderStartDateTime: formatToUTCDateTime(new Date()),
      slotName: '',
      ...getContextValues(initialContext),
    };
  }

  const contextFieldsInitialized =
    contextFieldInit || (Array.isArray(contextFields) && contextFields.length);

  const stopPropagationForTab = (event) => {
    event.stopPropagation();
  };

  React.useEffect(() => {
    if (!usersList) return;
    if (!usersList.results) return;

    setUsers(
      usersList.results.map((item) => ({
        id: item.email,
        label: item.email,
      }))
    );
  }, [usersList]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open}
      onKeyDown={stopPropagationForTab}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent maxWidth="sm" fullWidth>
        <Grid container>
          <Grid item sx={{ mb: 3 }} xs={10}>
            <Typography variant="h6">Map Fields</Typography>
          </Grid>
          <Grid item sx={{ mb: 3 }} xs={2} container justifyContent="flex-end">
            {env !== 'Kanban' ? (
              <Button onClick={() => setShowAddIna(true)} variant="outlined">
                INA +
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Formik
          initialValues={getInitialValues()}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              const timezone =
                Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

              if (env === 'Kanban') {
                const context = {};

                contextFields.forEach((field) => {
                  context[field?.name] =
                    values[`context-${field?.name}-payloadSectionKeys`]?.id;
                });

                await createRecord({
                  values: {
                    workflow: workflowId,
                    shape_action: shapeAction?.id,
                    mail_template: values?.template?.id,
                    to_email: values?.toEmailPayloadSectionKey?.id,
                    context,

                    add_slot: values?.addSlots,
                    slot_title: values?.slotName,
                    slot_link_description: values?.slotDescription,
                    slot_link: values?.slotMeetingLink,
                    slot_timezone: timezone,
                    slot_start_date_time: values?.slotBegin,
                    slot_end_date_time: values?.slotEnd,
                    slot_duration: values?.slotDuration,
                    slot_buffer: values?.slotBuffer,
                    slot_calendar: values?.slotCalendar?.id,
                    slot_guests: Array.isArray(values?.slotGuests)
                      ? values?.slotGuests.map((row) => row?.label)
                      : [user?.details?.email],

                    add_reminder: values?.addReminder,
                    reminder_name: values?.reminderName,
                    reminder_description: values?.reminderDescription,
                    reminder_timezone: timezone,
                    reminder_start_date_time: values?.reminderStartDateTime,
                    reminder_calendar: values?.reminderCalendar?.id,

                    workflow_instances: [...workflowInstances],
                  },
                  url: manualSendEmailUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
              } else {
                const payload = [];

                // Add email template
                payload.push({
                  id: initialTemplate?.value?.id,
                  type: 'String',
                  action_param: initialTemplate?.param?.id,
                  shape_action: shapeAction?.id,
                  label: values?.template?.label,
                  model_asset: values?.template?.id,
                });

                // Add to email
                payload.push({
                  id: initialToEmail?.value?.id,
                  type: 'String',
                  action_param: initialToEmail?.param?.id,
                  shape_action: shapeAction?.id,
                  label: values?.toEmailPayloadSectionKey?.label,
                  mapping_field_name: values?.toEmailPayloadSectionKey?.id,
                });

                // Add reminder
                payload.push({
                  id: initialAddReminder?.value?.id,
                  type: 'Boolean',
                  action_param: initialAddReminder?.param?.id,
                  shape_action: shapeAction?.id,
                  static_value: String(values?.addReminder),
                });

                // Add slot
                payload.push({
                  id: initialAddSlots?.value?.id,
                  type: 'Boolean',
                  action_param: initialAddSlots?.param?.id,
                  shape_action: shapeAction?.id,
                  static_value: String(values?.addSlots),
                });

                // Add context
                if (Array.isArray(contextFields)) {
                  contextFields.forEach((field) => {
                    const found = Array.isArray(initialContext?.values)
                      ? initialContext?.values.find(
                          (row) => row?.key === field?.name
                        )
                      : null;
                    const staticValue =
                      values[`context-${field?.name}-staticValue`];

                    payload.push({
                      id: found?.id,
                      type: 'String',
                      action_param: initialContext?.param?.id,
                      shape_action: shapeAction?.id,
                      key: field?.name,
                      label:
                        values[`context-${field?.name}-payloadSectionKeys`]
                          ?.label,
                      mapping_field_name: staticValue
                        ? undefined
                        : values[`context-${field?.name}-payloadSectionKeys`]
                            ?.id,
                      static_value: staticValue,
                    });
                  });
                }

                await createRecord({
                  values: {
                    shape_action_parameters: payload,
                  },
                  url: bulkCreateShapeActionParamsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
              }

              notify('Operation completed', {
                type: 'SUCCESS',
              });
              queryClient.invalidateQueries([
                'bpa-board',
                `${shape?.id}-shape-and-actions`,
              ]);
              resetForm();
              setOpen(false);
            } catch (error) {
              console.log(error?.response?.data ?? error?.message);
              notify('There was an error, please refresh the page', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form noValidate>
              <Grid container spacing={2}>
                {env !== 'Kanban' ? (
                  <Grid item xs={12}>
                    <EmailTemplateDropdown
                      workflow={workflowId}
                      stage={shape?.stage}
                      refetch={({ id, details }) => {
                        setFieldValue('template', {
                          id,
                          label: `${details?.asset?.name ?? ''}`,
                        });
                        queryClient.invalidateQueries([
                          `${shapeAction?.id}-popup-email-template-${workflowId}`,
                        ]);
                      }}
                    >
                      <AutocompleteFieldV2
                        name="template"
                        label="Template"
                        urlParams={`&microservice=Notification&model=MailTemplate&workflow=${workflowId}`}
                        requestKey={`${shapeAction?.id}-popup-email-template-${workflowId}`}
                        fetchUrl={modelAssetsUrls.list}
                        renderRow={renderEmailTemplate}
                        onChange={({ contextFields } = {}) =>
                          setContextFields(contextFields)
                        }
                        getData={(data) => {
                          if (
                            initialTemplate?.value?.model_asset &&
                            !contextFieldInit
                          ) {
                            const found = data.find(
                              (r) =>
                                r.id === initialTemplate?.value?.model_asset
                            );
                            setContextFields(found?.contextFields ?? []);
                          }

                          setContextFieldInit(true);
                        }}
                        required
                      />
                    </EmailTemplateDropdown>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <AutocompleteFieldV2
                      name="template"
                      label="Template"
                      urlParams={`&microservice=Notification&model=MailTemplate&workflow=${workflowId}`}
                      requestKey={`${shapeAction?.id}-popup-email-template`}
                      fetchUrl={modelAssetsUrls.list}
                      renderRow={renderEmailTemplate}
                      onChange={({ contextFields } = {}) =>
                        setContextFields(contextFields)
                      }
                      getData={(data) => {
                        if (
                          initialTemplate?.value?.model_asset &&
                          !contextFieldInit
                        ) {
                          const found = data.find(
                            (r) => r.id === initialTemplate?.value?.model_asset
                          );
                          setContextFields(found?.contextFields ?? []);
                        }

                        setContextFieldInit(true);
                      }}
                      required
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography>To Email</Typography>
                </Grid>

                <Grid item xs={3}>
                  <AutocompleteFieldV2
                    label="Payload section"
                    name="toEmailPayloadSection"
                    requestKey={`to-email-map-payload-section`}
                    fetchUrl={getPayloadActionsUrls.list}
                    urlParams={`&workflow_id=${workflowId}&exclude_draft_stage=true`}
                    renderRow={renderPayloadSections}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteFieldV2
                    label="Payload section keys"
                    name="toEmailPayloadSectionKey"
                    requestKey={`to-email-map-payload-section-keys`}
                    fetchUrl={payloadSectionKeysUrls.list}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.label,
                    })}
                    enabled={!!values?.toEmailPayloadSection?.id}
                    requestKeyOptions={[values?.toEmailPayloadSection?.id]}
                    urlParams={
                      values?.toEmailPayloadSection?.id
                        ? `&payload_section=${values?.toEmailPayloadSection?.id}`
                        : ''
                    }
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Static value"
                    name="toEmailStaticValue"
                    multiline
                  />
                </Grid>

                <Grid item xs={12}>
                  <CheckBoxField label="Add reminder" name="addReminder" />
                </Grid>

                {values.addReminder &&
                (env === 'Kanban' || !shape?.manual_transition) ? (
                  <>
                    <Grid item xs={6}>
                      <AutocompleteFieldV2
                        name="reminderCalendar"
                        label="Calendar"
                        requestKey={`${shapeAction?.id}-popup-reminder-calendars`}
                        fetchUrl={calendarsListUrls.list}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DateTimePickerField
                        label="Date time"
                        name="reminderStartDateTime"
                        showTime
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Reminder name"
                        name="reminderName"
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Reminder description"
                        name="reminderDescription"
                        multiline
                        minRows={2}
                      />
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12}>
                  <CheckBoxField label="Add slots" name="addSlots" />
                </Grid>

                {values.addSlots &&
                (env === 'Kanban' || !shape?.manual_transition) ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        label="Name"
                        name="slotName"
                        multiline
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AutocompleteFieldV2
                        name="slotCalendar"
                        label="Calendar"
                        requestKey={`${shapeAction?.id}-popup-slot-calendars`}
                        fetchUrl={calendarsListUrls.list}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Description"
                        name="slotDescription"
                        minRows={2}
                        multiline
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DateTimePickerField
                        label="Begin"
                        name="slotBegin"
                        showTime
                        showTimezone
                        setTimezone={(v) => setFieldValue('slotTimezone', v)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DateTimePickerField
                        label="End"
                        name="slotEnd"
                        showTime
                        showTimezone
                        setTimezone={(v) => setFieldValue('slotTimezone', v)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="slotDuration"
                        label="Duration (Minutes)"
                        required
                        number
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="slotBuffer"
                        label="Buffer (Minutes)"
                        required
                        number
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="slotMeetingLink"
                        label="Conference Link"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <AutocompleteField
                        key="guests"
                        multiple
                        setSearchTerm={setUserTerm}
                        isLoading={fetchingUsers}
                        options={users}
                        label="Add Guests"
                        defaultValue={fixedOptions}
                        name="slotGuests"
                        filterSelectedOptions
                      />
                    </Grid>
                  </>
                ) : null}

                {values.template && contextFieldsInitialized ? (
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: '500', mb: 2 }}>
                      Context Field mappings
                    </Typography>

                    <Grid container spacing={2}>
                      {contextFields.map((field) => (
                        <React.Fragment key={field.name}>
                          <Grid item xs={2}>
                            <Typography>{field.label}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <AutocompleteFieldV2
                              label="Payload section"
                              name={`context-${field.name}-payloadSection`}
                              requestKey={`${field.name}-map-payload-section`}
                              fetchUrl={getPayloadActionsUrls.list}
                              urlParams={`&workflow_id=${workflowId}&exclude_draft_stage=true`}
                              renderRow={renderPayloadSections}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <AutocompleteFieldV2
                              label="Payload section keys"
                              name={`context-${field.name}-payloadSectionKeys`}
                              requestKey={`${field.name}-map-payload-sectionKeys`}
                              fetchUrl={payloadSectionKeysUrls.list}
                              renderRow={(row) => ({
                                id: row?.id,
                                label: row?.label,
                              })}
                              enabled={
                                !!values[`context-${field.name}-payloadSection`]
                                  ?.id
                              }
                              requestKeyOptions={[
                                values[`context-${field.name}-payloadSection`]
                                  ?.id,
                              ]}
                              urlParams={
                                values[`context-${field.name}-payloadSection`]
                                  ?.id
                                  ? `&payload_section=${
                                      values[
                                        `context-${field.name}-payloadSection`
                                      ]?.id
                                    }`
                                  : ''
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              label="Static value"
                              name={`context-${field.name}-staticValue`}
                              multiline
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Skeleton variant="rounded" width="100%" height={120} />
                  </Grid>
                )}
              </Grid>

              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !(values.template && contextFieldsInitialized)
                  }
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>

      <ReminderPopup
        type="INA"
        microservice="BPA"
        model="WorkflowDefn"
        open={showAddIna}
        setOpen={setShowAddIna}
        selectedRows={[workflowId]}
        setSelectedRows={() => null}
        link={`${window.location.host}/workflows/${workflowId}`}
        company={workflowName}
      />
    </Dialog>
  );
}
