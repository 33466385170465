import React from 'react';
import * as Yup from 'yup';
import { formsUrls } from '@config/routes';
import {questionOptionMeta} from '@config/meta/forms/formsMeta';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2, CheckBoxField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { useQueryClient } from 'react-query';
import QuestionDropdown from '@dropdown-forms/form/QuestionDropdown';

const { questionOptionsUrls, questionsUrls } = formsUrls;

export default function QuestionOptionsDropdown({ children, refetch, question: initialQuestion, form, sourceGroup}) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title={'Question Options'}
        initialValues={{
          ...getAddFormInitialValues(
            questionOptionMeta(),
            'DetailAccordionView'
          ),
          questionId:initialQuestion
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            questionOptionMeta(),
            'DetailAccordionView'
          ),
        })}
        getPostUrl={() => questionOptionsUrls.list()}
        getValues={({ questionId,...values }) => ({
          ...values,
          questionId: questionId?.id,
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item sm={2}>
              <TextField label='Order' name='order' />
            </Grid>

            <Grid item xs={10}>
              <TextField label='Option' name='option' multiline required />
            </Grid>
            
            <Grid item xs={7}>
              <QuestionDropdown
                form={form}
                group={sourceGroup}
                refetch={({ id, question }) => {
                  setFieldValue('questionId', { id, label: question });
                  queryClient.invalidateQueries(['question-option-form'])

                }}
              >
              <AutocompleteFieldV2
                name='questionId'
                label='Question'
                requestKey='question-option'
                fetchUrl={questionsUrls.list}
                urlParams={initialQuestion ? `&questionId=${initialQuestion.id}` : ''}
                required
              />
              </QuestionDropdown>
            </Grid>
            <Grid item sm={4}>
              <CheckBoxField label='Is Correct?' name='isCorrect' />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
