import React from 'react';
import * as Yup from 'yup';
import { cmsV2Urls } from '@config/routes';
import { pageMeta } from '@config/meta/cms-v2';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2, CheckBoxField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import PageGroupDropdown from '@dropdown-forms/cms/PageGroupDropdown.jsx';
import PageTemplateDropdown from '@dropdown-forms/cms/PageTemplateDropdown.jsx';
import { useQueryClient } from 'react-query';

const { pagesUrls, pageTemplatesUrls, pageGroupsUrls } = cmsV2Urls;

export default function PagesDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title='Pages'
        initialValues={{
          ...getAddFormInitialValues(pageMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(pageMeta(), 'listView'),
        })}
        getPostUrl={() => pagesUrls.list()}
        getValues={({ pageGroupId, pageTemplateId, ...values }) => ({
          ...values,
          pageGroupId: pageGroupId?.id,
          pageTemplateId: pageTemplateId?.id,
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
                <TextField label='Name' name='name' required multiline />
            </Grid>
            <Grid item xs={6}>
            <PageGroupDropdown
              refetch={({ id, name }) => {
                setFieldValue('pageGroupId', { id, label: name });
                queryClient.invalidateQueries(['page-group-page']);
              }}
            >
                <AutocompleteFieldV2
                    name='pageGroupId'
                    label='Page Group'
                    requestKey='page-group'
                    fetchUrl={pageGroupsUrls.list}
                    required
                />
                </PageGroupDropdown>
            </Grid>
            <Grid item xs={6}>
            <PageTemplateDropdown
                refetch={({ id, name }) => {
                  setFieldValue('pageTemplateId', { id, label: name });
                  queryClient.invalidateQueries(['page-template']);
                }}
              >
                <AutocompleteFieldV2
                    name='pageTemplateId'
                    label='Page Template'
                    requestKey='page-template'
                    fetchUrl={pageTemplatesUrls.list}
                    required
                />
                </PageTemplateDropdown>
            </Grid>
            <Grid item xs={6}>
                <TextField label='Description' name='description' multiline/>
            </Grid>
            <Grid item xs={6}>
                <TextField label='Meta Title' name='metaTitle' />
            </Grid>
            <Grid item xs={6}>
                <TextField label='Meta Description' name='metaDescription' multiline/>
            </Grid>
            <Grid item xs={6}>
                <CheckBoxField label='Published' name='isPublished' />
            </Grid>
            <Grid item xs={6}>
                <TextField label='Tags' name='tags' />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
