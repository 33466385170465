import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

const subHeadingStyles = {
  fontSize: '18px',
  textAlign: 'left',
  fontWeight: 'bold',
  color: 'rgb(17, 24, 39)',
};

export default function PrivacyPolicy() {

  const getFavicon = () => {
    if (typeof window === 'undefined') return '';

    if (window.location.href.includes('mazards')) {
      return 'https://drive.pullstream.com/file/634e8c8f-810a-4149-b3af-ba750abca696.ico?one=true';
    }

    if (window.location.href.includes('evcharging')) {
      return 'https://drive.pullstream.com/file/e241d62a-ffe6-47fb-a91b-e59798743e73.ico?one=true';
    }

    return '/img/ps-favicon.png';
  };

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <link rel='icon' href={getFavicon()} />
      </Helmet>
      <Box sx={{ background: '#fff', py: 8 }}>
        <Container maxWidth='md'>
          <Stack spacing={2}>
            <Typography
              variant='h3'
              sx={{
                fontSize: '24px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: 'rgb(17, 24, 39)',
              }}
            >
              Privacy Policy
            </Typography>
            <Typography variant='h3' sx={subHeadingStyles}>
              Introduction
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              This Privacy Statement describes how and why Pull Stream Ltd (“we”
              or “us”) and our subsidiaries that: (1) are contracting parties in
              order to provide or receive services; (2) publicise a position for
              which you are applying; or (3) you have some other connection to,
              collect and use personal data (i.e. data relating to an identified
              or identifiable individual) in the course of business. It applies to
              personal data provided directly to us by the individuals concerned
              and to personal data provided to us by companies and other
              organisations.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              We are committed to the protection of personal data and to fair and
              transparent processing. If you have any questions about this Privacy
              Statement, you can contact our Data Protection Officer via email at
              dpo@pullstream.com.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              To find out more about how and why we process personal data, please
              refer to the relevant section of this Privacy Statement (using the
              tabs above).
            </Typography>
            <Typography variant='h3' sx={subHeadingStyles}>
              Data controller
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              Pull Stream Ltd (a limited company registered in England with number
              12701171. Registered address: 31 Trevelyan Court Green Lane,
              Windsor, England, SL4 3SE).
            </Typography>
            <Typography variant='h3' sx={subHeadingStyles}>
              Security of personal data
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              We have policies, procedures and training in place in respect of
              data protection, confidentiality and information security. We
              regularly review such measures with the objective of ensuring their
              continuing effectiveness. The Privacy Statement was last updated in
              June 2022.
            </Typography>
            <Typography variant='h3' sx={subHeadingStyles}>
              International transfers of personal data
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              In the course of running our business and providing services to
              clients we may transfer personal data to third parties located in
              other countries that have less stringent data protection laws. Where
              we transfer personal data to a country not determined by the
              relevant authority to provide an adequate level of protection for
              personal data, we will take steps to ensure that personal data will
              be adequately protected in accordance with applicable law, such as
              using the Information Commissioner’s Office approved standard
              contractual clauses.
            </Typography>
            <Typography variant='h3' sx={subHeadingStyles}>
              Provision of personal data to third parties
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              We will only share personal data with third parties where we are
              legally permitted to do so. We do not provide information to third
              parties for their own marketing purposes and we do not undertake
              mailings for third parties. Where we transfer personal data to third
              parties, we will put in place appropriate contractual arrangements
              and seek to ensure that there are appropriate technical and
              organisational measures in place to protect personal data.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              We may provide personal data to:
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              Other Pullstream Member Firms – we may share personal data with
              other members of the Pullstream International Network where required
              for the provision of services to our clients and/or for
              administrative purposes.
            </Typography>

            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              Third parties involved in the performance of services – we may also
              share personal data to third party organisations who assist us in
              providing services to clients or are otherwise involved in the
              services we provide to clients.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              Third parties who provide IT services, data processing or
              functionality – like many professional service providers, we use
              third party providers to support our business and the provision of
              services to our clients, such as cloud based software providers, web
              hosting/management providers, data analysis providers, and data
              back-up and security/storage providers. We may transfer personal
              data to such third parties.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              Auditors and advisers – we may transfer personal data to our
              auditors and advisers as required by law or as reasonably required
              in the management of our business.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              Third parties where required by applicable law and regulation – we
              may be requested or compelled to disclose personal data to third
              parties such as regulators and law enforcement agencies. We will
              only provide personal data to such parties where there is a legal
              requirement or permission to do so.
            </Typography>
            <Typography variant='h3' sx={subHeadingStyles}>
              Your Rights
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              You have rights in relation to any of your personal data held by us
              as a data controller. Should you wish to exercise your rights right,
              please contact our Data Protection Officer via email at
              dpo@pullstream.com. We will endeavour to respond to any request
              promptly and within any legally required time limit.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              You also have a right to update your personal data that we hold. To
              do so, please either update the personal data via the web page or
              applications open to you, contact your usual Pullstream contact or
              otherwise contact our Data Protection Officer via email at
              dpo@pullstream.com.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              Where we process your personal data based on your consent, you have
              a right to withdraw consent at any time. Should you wish to do so,
              please contact our Data Protection Officer via email at
              dpo@pullstream.com.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              Finally, in addition to the rights above, you may also have other
              rights in relation to personal data, including a right to
              erasure/deletion, the right to data portability and the right to
              restrict and/or object to our processing of personal data.
            </Typography>
            <Typography variant='h3' sx={subHeadingStyles}>
              Complaints
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              Should you wish to complain about our use of your personal data,
              please contact our Data Protection Officer via email at
              dpo@pullstream.com. We will investigate all complaints received and
              will endeavour to respond to complaints promptly.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              You may also complain about our use of personal data to the
              Information Commissioner’s Office. For further information on your
              rights and the complaints process, please visit the Information
              Commissioner’s Office website:
              https://ico.org.uk/your-data-matters/.
            </Typography>
            <Typography variant='h3' sx={subHeadingStyles}>
              Data Retention
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              We will only keep personal data for as long as necessary for the
              purposes for which it was collected, or as required by applicable
              law or regulation.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              Unless there are any overriding legal, regulatory or contractual
              requirements, we will retain records of services provided (which may
              include personal data) in accordance with our document retention
              policy.
            </Typography>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
