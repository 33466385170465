import { logsHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  logs: '',
  systemLogs: 'system-logs',
  import: 'import',
  export: 'export',
  importLogs: 'import-logs',
  importErrorLogs: 'import-error-logs',
  downloadSystemLogs: 'download-system-logs',
  visitorLogs: 'visitor-logs',
  notifications: 'notifications',
  bulkNotificationReadBy: 'bulk-notification-read-by',
};

const logUrls = getModelUrls(logsHost, models.logs);
const notificationUrls = getModelUrls(logsHost, models.notifications);
const bulkNotificationReadByUrls = getModelUrls(logsHost, models.bulkNotificationReadBy);
const visitorLogUrls = getModelUrls(logsHost, models.visitorLogs);
const systemLogsUrls = getModelUrls(logsHost, models.systemLogs);
const importUrls = getModelUrls(logsHost, models.import);
const exportUrls = getModelUrls(logsHost, models.export);
const importLogsUrls = getModelUrls(logsHost, models.importLogs);
const importErrorLogsUrls = getModelUrls(logsHost, models.importErrorLogs);
const downloadSystemLogsUrls = getModelUrls(
  logsHost,
  models.downloadSystemLogs
);

const urls = {
  logUrls,
  notificationUrls,
  bulkNotificationReadByUrls,
  systemLogsUrls,
  importUrls,
  exportUrls,
  importLogsUrls,
  importErrorLogsUrls,
  downloadSystemLogsUrls,
  visitorLogUrls,
};

export default urls;
