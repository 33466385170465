import React from 'react';
import * as Yup from 'yup';
import { inventoryUrls } from '@config/routes';
import { deliveryMeta } from '@config/meta/inventory/index';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import WarehouseDropdown from '@dropdown-forms/inventory/WarehouseDropdown';
import { useQueryClient } from 'react-query';

const { warehousesUrls } = inventoryUrls;
const { deliveriesUrls } = inventoryUrls;

export default function DeliveryDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

 
  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Delivery"
        initialValues={{
          ...getAddFormInitialValues(deliveryMeta({}), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(deliveryMeta({}), 'listView'),
        })}
        getPostUrl={() => deliveriesUrls.list()}
        getValues={({values, warehouse}) => ({
          ...values,
          warehouse: warehouse?.id
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <WarehouseDropdown
                refetch={({ id, name }) => {
                  setFieldValue('warehouse', { id, label: name });
                  queryClient.invalidateQueries(['delivery-warehouse']);
                }}
              >
                <AutocompleteFieldV2
                  name='warehouse'
                  label='Warehouse'
                  requestKey='delivery-warehouse'
                  fetchUrl={warehousesUrls.list}
                  urlParams={`&ordering=created_at`}
                  required
                />
              </WarehouseDropdown>
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="delivered_by"
                label="Delivered By"
                name="delivered_by"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="received_by"
                label="Received By"
                name="received_by"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField key="quantity" label="Quantity" name="quantity" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="delivert_note_ref"
                label="Delivert Note Ref"
                name="delivert_note_ref"
              />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
