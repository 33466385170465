import React from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { formsUrls } from '@config/routes';
import { questionsMeta } from '@config/meta/forms/formsMeta';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord, updateRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import {
  questionOptionRowsMap,
  questionOptionGroupRowsMap,
  questionGroupRowsMap,
} from '@config/handleRows/forms';
import {
  QuestionOptionsForm,
  QuestionOptionGroupsForm,
  QuestionGroupForm,
} from '@config/forms/forms';
import Accordion from '@ui/Accordion';
import * as Yup from 'yup';
import { microservices } from '@config/constants';
import { useQueryClient } from 'react-query';

const {
  questionsUrls,
  questionOptionsUrls,
  questionOptionGroupsUrls,
  questionGroupsUrls,
} = formsUrls;

const typesWithOptions = [
  'Likert',
  'MultipleChoice',
  'MultipleSelect',
  'ButtonSelect',
];

export default function QuestionDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const [expanded, setExpanded] = React.useState(false);
  const [likerts, setLikerts] = React.useState({
    label1: '',
    label2: '',
    label3: '',
    label4: '',
    label5: '',
  });
  const [likertsInit, setLikertsInit] = React.useState(false);
  const [likertsSubmitting, setLikertsSubmitting] = React.useState(false);
  const [questionOptionsListData, setQuestionOptionsListData] = React.useState(
    {}
  );

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => questionsMeta({ data, queryClient }),
    []
  );

  const handleLikertSave = async () => {
    try {
      setLikertsSubmitting(true);

      try {
        await Yup.object({
          label1: Yup.string().required(),
          label2: Yup.string().required(),
          label3: Yup.string().required(),
          label4: Yup.string().required(),
          label5: Yup.string().required(),
        }).validate(likerts);
      } catch (err) {
        notify('Leaving labels empty is not allowed', {
          type: 'ERROR',
        });
        return;
      }

      await updateRecord({
        values: {
          likertLabels: Object.values(likerts),
        },
        url: questionsUrls.detail(recordId),
        token: user.token,
        actAs: user?.actAs,
      });

      notify('Operation completed', {
        type: 'SUCCESS',
      });
    } catch (err) {
      notify('There was an error, please try again', {
        type: 'ERROR',
      });
    } finally {
      setLikertsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (!recordData || likertsInit) return;

    const [first, second, third, fourth, fifth] = recordData.likertLabels;

    setLikerts({
      label1: first,
      label2: second,
      label3: third,
      label4: fourth,
      label5: fifth,
    });

    setLikertsInit(true);
  }, [recordData]);

  const questionGroupColOptions = React.useMemo(
    () => ({
      hideGroup: false,
      hideQuestion: true,
      formId: recordData?.formId,
    }),
    [recordData?.formId]
  );

  const questionOptionColOptions = React.useMemo(
    () => ({
      nextOrder: questionOptionsListData?.totalCount ?? 1,
      type: recordData?.type,
      question: recordData?.id,
    }),
    [recordData, questionOptionsListData?.totalCount]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/forms/${recordData.formId}`, text: 'Questions' },
              { text: recordData && recordData.question },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={questionsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          hideSearch
          editMode={editMode}
          labels={labels}
          label='Groups'
          prefix='0ud'
          microservice={microservices.Forms.name}
          model={microservices.Forms.models.group}
          columnKey='questionGroup'
          columnOptions={questionGroupColOptions}
          urls={questionGroupsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              QuestionGroupForm({
                setFieldValue,
                formId: recordData?.formId,
                hideGroup: false,
                formName: recordData?.form?.name,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { groupId, ...rest } = values;

                if (!groupId?.id) {
                  setFieldError('groupId', 'This field is required');
                  return;
                }

                await createRecord({
                  values: {
                    ...rest,
                    questionId: recordId,
                    groupId: groupId?.id,
                    formId: recordData?.formId,
                  },
                  url: questionGroupsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={questionGroupRowsMap}
          urlParams={`&questionId=${recordId}`}
          sortColumnBy='asc'
        />
      </Box>

      {recordData && typesWithOptions.includes(recordData?.type) ? (
        <Box>
          {recordData.type === 'Likert' ? (
            <>
              <Accordion
                editMode={editMode}
                labels={labels}
                prefix='0cl'
                label='Likert Labels'
                expanded={expanded}
                onChange={() => setExpanded((prevState) => !prevState)}
              >
                <Box sx={{ py: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2.4}>
                      <TextField
                        fullWidth
                        required
                        size='small'
                        label='Likert label 1'
                        value={likerts.label1}
                        onChange={(e) =>
                          setLikerts((state) => ({
                            ...state,
                            label1: e.target.value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <TextField
                        fullWidth
                        required
                        size='small'
                        label='Likert label 2'
                        value={likerts.label2}
                        onChange={(e) =>
                          setLikerts((state) => ({
                            ...state,
                            label2: e.target.value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <TextField
                        fullWidth
                        required
                        size='small'
                        label='Likert label 3'
                        value={likerts.label3}
                        onChange={(e) =>
                          setLikerts((state) => ({
                            ...state,
                            label3: e.target.value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <TextField
                        fullWidth
                        required
                        size='small'
                        label='Likert label 4'
                        value={likerts.label4}
                        onChange={(e) =>
                          setLikerts((state) => ({
                            ...state,
                            label4: e.target.value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <TextField
                        fullWidth
                        required
                        size='small'
                        label='Likert label 5'
                        value={likerts.label5}
                        onChange={(e) =>
                          setLikerts((state) => ({
                            ...state,
                            label5: e.target.value,
                          }))
                        }
                      />
                    </Grid>

                    <Grid item xs={2.4}>
                      <Button
                        variant='outlined'
                        onClick={handleLikertSave}
                        disabled={likertsSubmitting}
                      >
                        Save Changes
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Accordion>

              <DetailAccordionView
                nodeAPI
                editMode={editMode}
                labels={labels}
                label='Question Option Groups'
                prefix='0tx'
                microservice={microservices.Forms.name}
                model={microservices.Forms.models.questionOptionGroup}
                columnKey='questionOptionGroup'
                urls={questionOptionGroupsUrls}
                importExportFields={`?questionId=${recordId}`}
                addNewForm={{
                  getFields: () => QuestionOptionGroupsForm(),
                  handleSubmit: async (
                    values,
                    setSubmitting,
                    resetForm,
                    setAddNewForm,
                    refetch,
                    setError
                  ) => {
                    try {
                      await createRecord({
                        values: {
                          ...values,
                          questionId: recordId,
                        },
                        url: questionOptionGroupsUrls.list(),
                        token: user.token,
                        actAs: user?.actAs,
                      });

                      resetForm();
                      setAddNewForm(false);
                      refetch();
                    } catch (err) {
                      console.log(err);
                      handleCreateFormErrors({ err, setError, notify, values });
                    } finally {
                      setSubmitting(false);
                    }
                  },
                }}
                handleRowMap={questionOptionGroupRowsMap}
                urlParams={`&questionId=${recordId}`}
                customSortColumnName='createdAt'
                sortColumnBy='asc'
              />
            </>
          ) : null}

          <DetailAccordionView
            nodeAPI
            editMode={editMode}
            labels={labels}
            label='Question Options'
            prefix='076'
            microservice={microservices.Forms.name}
            model={microservices.Forms.models.questionOption}
            columnKey='questionOption'
            columnOptions={questionOptionColOptions}
            urls={questionOptionsUrls}
            importExportFields={`?questionId=${recordId}`}
            addNewForm={{
              getFields: ({ setFieldValue }) =>
                QuestionOptionsForm({
                  setFieldValue,
                  questionId: recordId,
                  questionName: recordData?.question,
                  type: recordData?.type,
                }),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError
              ) => {
                try {
                  const { questionOptionGroupId, ...rest } = values;

                  await createRecord({
                    values: {
                      ...rest,
                      questionId: recordId,
                      questionOptionGroupId: questionOptionGroupId?.id,
                    },
                    url: questionOptionsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  refetch();
                } catch (err) {
                  console.log(err);
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={questionOptionRowsMap}
            urlParams={`&questionId=${recordId}`}
            customSortColumnName='order'
            sortColumnBy='asc'
            getListData={(data) => setQuestionOptionsListData(data)}
          />
        </Box>
      ) : null}
    </Box>
  );
}
