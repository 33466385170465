// handleRows

export const qrRowsMap = (r) => ({
  id: r.id,
  name_of_qrcode: r.name_of_qrcode,
  qr_code_url: r.qr_code_url,
  type: r.type,
  created_at: r.created_at,
});

export const vCardRowsMap = (r) => ({
  id: r.id,
  name_of_qrcode: r.name_of_qrcode,
  email: r.email,
  first_name: r.first_name,
  last_name: r.last_name,
  qr_code_url: r.qr_code_url,
});

export const emailRowsMap = (r) => ({
  id: r.id,
  name_of_qrcode: r.name_of_qrcode,
  email: r.email,
  subject: r.subject,
  message: r.message,
  qr_code_url: r.qr_code_url,
});

export const smsRowsMap = (r) => ({
  id: r.id,
  name_of_qrcode: r.name_of_qrcode,
  number: r.number,
  message: r.message,
  qr_code_url: r.qr_code_url,
});

export const wifiRowsMap = (r) => ({
  id: r.id,
  name_of_qrcode: r.name_of_qrcode,
  password: r.password,
  network_name: r.network_name,
  encryption: r.encryption,
  hidden: r.hidden,
  qr_code_url: r.qr_code_url,
});

export const twitterRowsMap = (r) => ({
  id: r.id,
  name_of_qrcode: r.name_of_qrcode,
  choose_an_option: r.choose_an_option,
  qr_code_url: r.qr_code_url,
  your_username: r.your_username,
});
