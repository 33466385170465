import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { sopsUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { sopRowsMap } from '@config/handleRows/sops';
import { SOPsForm } from '@config/forms/sops';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { sopUrls } = sopsUrls;

export default function SOPs({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label='SOPs'
        prefix='0gb'
        labels={labels}
        editMode={editMode}
        microservice={microservices.SOP.name}
        model={microservices.SOP.models.sop}
        urls={sopUrls}
        columnKey='SOPs'
        addNewForm={{
          getFields: () => SOPsForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: sopUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify('SOP Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={sopRowsMap}
      />
    </Box>
  );
}
