import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Formik, Form } from 'formik';
import {
  TextField,
  AutocompleteFieldV2,
  DateTimePickerField,
  CheckBoxField,
  MultipleAutocompleteField,
} from '@components/Inputs';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { calendarUrls, hrUrls, accountUrls } from '@config/routes';
import {
  formatToUTCDateTime,
  getAuthHeader,
  validateEmail,
} from '@config/functions/helperFunctions';
import * as Yup from 'yup';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import DoWhatDropdown from '@dropdown-forms/pm/DoWhatDropdown';
import { useQueryClient } from 'react-query';
import useRQuery from '@hooks/useRQuery';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const {
  batchRemindersUrls,
  bulkCreateActionReminderUrls,
  calendarsListUrls,
  actionsUrls,
} = calendarUrls;
const { employeesUrls } = hrUrls;
const { usersUrls } = accountUrls;

export default function ReminderPopup({
  open,
  setOpen,
  type,
  selectedRows,
  setSelectedRows,
  microservice,
  model,
  link,
  company,
  workflow,
  labels,
  editMode,
  prefix,
  initialNotes = '',
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const defaultOptions = {
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
    renderRow: (r) => r.email,
  };

  const today = React.useMemo(() => {
    return new Date();
  }, []);

  const { data: users } = useRQuery({
    key: ['users', user.token, user?.actAs],
    url: usersUrls.list(`?page_size=100`),
    ...defaultOptions,
  });

  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="Add INA Reminder"
          >
            {(text) => (
              <DialogTitle sx={{ pointerEvents: 'none' }}>{text}</DialogTitle>
            )}
          </RenderUiPrefix>
        ) : (
          <DialogTitle sx={{ pointerEvents: 'none' }}>
            Add INA Reminder
          </DialogTitle>
        )}

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Formik
          initialValues={{
            name: '',
            do_what: '',
            notes: initialNotes,
            calendar: '',
            invitee: [],
            batch_reminder: '',
            date_time: formatToUTCDateTime(today),
            prefix: true,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(),
            date_time: Yup.string(),
            invitee: Yup.array().of(
              Yup.string().email('Enter a valid email').required()
            ),
            batch_reminder: Yup.mixed(),
            do_what: Yup.mixed(),
            calendar: Yup.mixed(),
            responsible: Yup.mixed(),
            link: Yup.string(),
          })}
          onSubmit={async (
            values,
            { setFieldError, setSubmitting, resetForm }
          ) => {
            try {
              setSubmitting(true);

              const errors = [];

              console.log(values?.date_time);

              if (!values?.date_time && !values?.batch_reminder?.id) {
                errors.push('date_time');
              }

              if (!values?.do_what?.id) {
                errors.push('do_what');
              }

              if (!values?.calendar?.id) {
                errors.push('calendar');
              }

              if (!values?.responsible?.id) {
                errors.push('responsible');
              }

              if (errors.length) {
                errors.forEach((name) => {
                  setFieldError(name, 'This field is required');
                });

                return;
              }

              if (values?.date_time && new Date(values?.date_time) < today) {
                setFieldError(
                  'date_time',
                  'Date and time can not be in the past.'
                );
                return;
              }

              const getINAName = (prefix, company, name) => {
                if (prefix) {
                  return company
                    ? `${company} - ${name}`
                    : workflow
                    ? `${workflow} - ${name}`
                    : name;
                }
                return name;
              };

              const newValues = selectedRows.map((row) => ({
                entity_microservice: microservice,
                entity: model,
                entity_id: row,
                name: getINAName(values.prefix, company, values.name),
                batch_reminder: values?.batch_reminder?.id ?? null,
                date_time: values?.date_time,
                responsible: values?.responsible?.id,
                person: values?.responsible?.person,
                do_what: values?.do_what?.id,
                calendar: values?.calendar?.id,
                invitee: values?.invitee,
                reminder_type:
                  type === 'INA' ? 'INA Reminder Event' : 'Reminder Event',
                notes: values?.notes,
                link: link,
              }));

              await createRecord({
                values: {
                  data: newValues,
                },
                url: bulkCreateActionReminderUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setSelectedRows([]);
              setOpen(false);
              queryClient.invalidateQueries(['inas-board']);
              notify('Operation completed', {
                type: 'SUCCESS',
              });
            } catch (error) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue, errors, touched }) => (
            <Form noValidate autoComplete="off" style={{ paddingTop: '6px' }}>
              <Grid sx={{ paddingBottom: '22px' }} container spacing={3}>
                <Grid item sm={6}>
                  <TextField name="name" label="Name" multiline required />
                </Grid>

                <Grid item sm={6}>
                  <CheckBoxField label="Add Prefix" name="prefix" />
                </Grid>

                <Grid item sm={6}>
                  <DoWhatDropdown
                    refetch={({ id, name }) => {
                      setFieldValue('do_what', { id, label: name });
                      queryClient.invalidateQueries(['reminder-do-whats']);
                    }}
                  >
                    <AutocompleteFieldV2
                      name="do_what"
                      label="Do what"
                      requestKey="reminder-do-whats"
                      fetchUrl={actionsUrls.list}
                      required
                    />
                  </DoWhatDropdown>
                </Grid>

                <Grid item sm={6}>
                  <AutocompleteFieldV2
                    name="responsible"
                    label="Responsible"
                    requestKey="reminder-responsible-list"
                    fetchUrl={employeesUrls.list}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.work_email,
                      person: row?.person,
                    })}
                    required
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextField name="notes" label="Notes" multiline minRows={3} />
                </Grid>

                <Grid item sm={6}>
                  <AutocompleteFieldV2
                    name="calendar"
                    label="Calendar"
                    requestKey="reminder-calendars"
                    fetchUrl={calendarsListUrls.list}
                    getData={(data) => {
                      if (data.length) {
                        console.log('data', data[0]);
                        setFieldValue('calendar', data[0]);
                      }
                    }}
                    required
                  />
                </Grid>

                <Grid item sm={6}>
                  <AutocompleteFieldV2
                    name="batch_reminder"
                    label="Batch Reminder"
                    requestKey="batch-reminders"
                    fetchUrl={batchRemindersUrls.list}
                  />
                </Grid>

                {/* {values?.batch_reminder?.id ? null : ( */}
                <Grid item sm={12}>
                  <DateTimePickerField
                    label="Date and time"
                    name="date_time"
                    showTime
                    showTimezone
                    disablePast
                    required
                  />
                </Grid>
                {/* )} */}

                <Grid item sm={12}>
                  <MultipleAutocompleteField
                    label="Invitee"
                    options={users}
                    value={values.invitee}
                    onChange={(e, newValue) => {
                      if (
                        newValue
                          .map((email) => validateEmail(email))
                          .some((t) => !t)
                      )
                        return;
                      setFieldValue('invitee', newValue);
                    }}
                    isError={errors.invitee && touched.invitee}
                    error={errors.invitee}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
