import * as React from 'react';
import { Menu, IconButton, Typography, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useQueryClient } from 'react-query';
import { calendarUrls } from '@config/routes';
import { updateRecord, deleteRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import INASidePeek from './INASidePeek/INASidePeek';

const ITEM_HEIGHT = 48;
const { actionRemindersUrls } = calendarUrls;

export default function INAsKanbanCardActions({
  list,
  card,
  labels,
  editMode,
  user,
  showINASidePeek,
  setINAShowSidePeek,
}) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const onINAUpdate = async (id) => {
    try {
      await updateRecord({
        url: actionRemindersUrls.detail(id),
        values: { progress: 'Completed' },
        token: user.token,
        actAs: user?.actAs,
      });
      setAnchorEl(null);
      queryClient.invalidateQueries(['inas-board']);
      notify('INA Updated', {
        type: 'SUCCESS',
      });
    } catch (error) {
      console.log(error?.response?.data ?? error?.message);
      notify('Something went wrong, try refreshing', {
        type: 'ERROR',
      });
    }
  };

  const onINADelete = async (id) => {
    try {
      await deleteRecord({
        url: actionRemindersUrls.detail(id),
        token: user.token,
        actAs: user?.actAs,
      });
      setAnchorEl(null);
      queryClient.invalidateQueries(['inas-board']);
      notify('INA Deleted', {
        type: 'SUCCESS',
      });
    } catch (error) {
      console.log(error?.response?.data ?? error?.message);
      notify('Something went wrong, try refreshing', {
        type: 'ERROR',
      });
    }
  };

  return (
    <>
      <IconButton
        sx={{
          position: 'absolute',
          right: '4px',
          top: '6px',
          opacity: '0.8',
          '&:hover': {
            opacity: '1',
            color: '#fff',
          },
        }}
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        size='small'
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon
          fontSize='default'
          sx={{
            color: list.name === 'Zombies' || card.rotting ? '#fff' : '#000',
            '&:hover': {
              color: '#fff',
            },
          }}
        />
      </IconButton>

      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem
          size='small'
          onClick={() => {
            setINAShowSidePeek(true);
            setAnchorEl(null);
          }}
        >
          Data
        </MenuItem>
        <MenuItem
          size='small'
          onClick={() => onINAUpdate(card.id, setAnchorEl)}
        >
          Mark as done
        </MenuItem>
        <MenuItem
          size='small'
          onClick={() => onINADelete(card.id, setAnchorEl)}
        >
          <Typography sx={{ color: 'red' }}>Delete</Typography>
        </MenuItem>
      </Menu>

      {showINASidePeek ? (
        <INASidePeek
          inaId={card?.id}
          recordId={card?.entity_id}
          modelName={card?.model_name}
          open={showINASidePeek}
          setOpen={setINAShowSidePeek}
          editMode={editMode}
          labels={labels}
        />
      ) : null}
    </>
  );
}
