import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { wikiUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import { SectionsForm } from '@config/forms/wiki';
import { sectionHandleRow } from '@config/handleRows/wiki';
import { chaptersMeta } from '@config/meta/wiki';
import DetailPageHeader from '@components/DetailPageHeader';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { chaptersUrls, sectionsUrls } = wikiUrls;

export default function ChaptersDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const [sectionsListData, setSectionsListData] = React.useState({});
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();

  const getSchema = React.useCallback(
    (data) => chaptersMeta({ data, queryClient }),
    []
  );

  const sectionsColOptions = React.useMemo(
    () => ({
      nextOrder: sectionsListData?.totalCount ?? 1,
    }),
    [sectionsListData?.totalCount]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key='detail-view-header'
            items={[
              { to: `/books`, text: 'Books' },
              {
                to: `/books/${recordData && recordData.book}`,
                text: `${
                  recordData &&
                  recordData.details &&
                  recordData.details.book.name
                }`,
              },
              {
                to: `/books/${recordData && recordData.book}`,
                text: 'Chapters',
              },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}
      <DetailCard
        urls={chaptersUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.WIKI.name}
          model={microservices.WIKI.models.section}
          columnKey='sectionsWiki'
          columnOptions={sectionsColOptions}
          label='Section'
          prefix='0us'
          labels={labels}
          editMode={editMode}
          urls={sectionsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) => {
              return SectionsForm({ setFieldValue });
            },

            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: {
                    ...values,
                    chapter: recordId,
                  },
                  url: sectionsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                // notify(`Section Created!`, {
                //   type: 'SUCCESS',
                // });
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={sectionHandleRow}
          urlParams={`&chapter=${recordId}`}
          customSortColumnName='order'
          sortColumnBy='asc'
          getListData={(data) => setSectionsListData(data)}
        />
      </Box>
    </Box>
  );
}
