export function withOrWithoutQuery(query, url) {
  if (query) {
    return `${url}${query}`;
  }

  return url;
}

export function getModelUrls(host, model, noApi, version) {
  const list = (query) =>
    withOrWithoutQuery(
      query,
      `${host}${noApi ? '/' : `/api/${version ? `${version}/` : ''}`}${model}/`
    );

  const detail = (id, params) =>
    withOrWithoutQuery(
      params,
      `${host}${
        noApi ? '/' : `/api/${version ? `${version}/` : ''}`
      }${model}/${id}/`
    );

  const modelUrls = {
    list,
    detail,
  };

  return modelUrls;
}

export function getModelRelatedUrl(host, model, id, relatedModel, query) {
  const url = `${host}/api/${model}/${id}/${relatedModel}`;
  return withOrWithoutQuery(query, url);
}

export function getCompanyNameFromHost(hostName) {
  const isLocal = hostName === 'localhost' || hostName === '127.0.0.1';
  // const host = isLocal ? 'sandbox.me.pullstream.com' : hostName;
  // const host = isLocal ? 'me.pullstream.com' : hostName;
  const host = isLocal ? 'me.mazards.com' : hostName;
  // const host = isLocal ? 'sandbox.me.evchargingsolutions.co.uk' : hostName;

  if (host.includes('sandbox') || host.includes('staging')) {
    const [, , name] = host.split('.');
    return name;
  }

  const [, name] = host.split('.');
  return name;
}
