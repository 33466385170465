import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PublicForm from '@components/Forms/PublicForm';
import { formsUrls } from '@config/routes';
import useRQuery from '@hooks/useRQuery';
import PublicFormErrorScreen from '@components/Forms/PublicFormErrorScreen';
import { Box } from '@mui/material';
import { formBgStyles } from '@config/constants';
import Loader from '@components/Loader';
import SEOHeader from '@components/SEOHeader';
import { parseJSON } from '@config/functions/helperFunctions';
import RequireAuth from '@components/core/RequireAuth';
import Layout from '@components/core/Layout';

const { recipientsUrls } = formsUrls;

export default function RecipientForm() {
  const { id: recipientId } = useParams();
  const [queryParams] = useSearchParams();
  const initialTags = queryParams.get('t');
  const initialUTMContent = queryParams.get('utm_content');
  const initialUTMCampaign = queryParams.get('utm_campaign');
  const initialUTMTerm = queryParams.get('utm_term');
  const initialUTMMedium = queryParams.get('utm_medium');
  const initialUTMSource = queryParams.get('utm_source');

  const {
    data: recipient,
    isLoading,
    isError,
  } = useRQuery({
    key: [`${recipientId}-recipient-form`, recipientId],
    url: recipientsUrls.detail(recipientId),
    options: { enabled: !!recipientId },
  });

  if (isError) {
    return <PublicFormErrorScreen />;
  }

  if (isLoading || !recipient) {
    return (
      <Box flex justifyContent='center' alignContent='center' sx={formBgStyles}>
        <SEOHeader title='View Form' />
        <Loader />
      </Box>
    );
  }

  if (
    recipient?.response?.status === 'SUBMITTED' ||
    (recipient?.expiryDate && new Date(recipient?.expiryDate) < new Date())
  ) {
    return <PublicFormErrorScreen />;
  }

  const formProps = {
    recipient: recipient,
    formId: recipient?.formId,
    initialEmail: recipient?.response?.email,
    initialFirstName: recipient?.response?.firstName,
    initialLastName: recipient?.response?.lastName,
    formDraft: parseJSON(recipient?.formState)?.data,
    initialTags,
    initialUTMContent,
    initialUTMCampaign,
    initialUTMTerm,
    initialUTMMedium,
    initialUTMSource,
  };

  if (recipient?.form?.accessLevel === 'PRIVATE') {
    return (
      <RequireAuth>
        {(userInfo) => (
          <Layout user={userInfo}>
            <PublicForm {...formProps} />
          </Layout>
        )}
      </RequireAuth>
    );
  }

  return <PublicForm {...formProps} />;
}
