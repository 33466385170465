import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import SOPs from '@pages/SOPs/SOPs';
import SOPDetail from '@pages/SOPs/SOPs/detail';
import SOPTasks from '@pages/SOPs/SOPTasks/detail';
import DaysOff from '@pages/SOPs/DaysOff';
import GDPR from '@pages/SOPs/GDPR';
import ISO9001 from '@pages/SOPs/ISO9001';

export default function () {
  return (
    <>
      <Route path='/sops' element={<WithLayout comp={SOPs} />} />
      <Route path='/sops/:id' element={<WithLayout comp={SOPDetail} />} />
      <Route path='/sop-tasks/:id' element={<WithLayout comp={SOPTasks} />} />
      <Route path='/day-offs' element={<WithLayout comp={DaysOff} />} />
      <Route path='/gdpr-sops' element={<WithLayout comp={GDPR} />} />
      <Route path='/iso-9001' element={<WithLayout comp={ISO9001} />} />
    </>
  );
}
