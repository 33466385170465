import * as React from 'react';
import Typography from '@mui/material/Typography';

export default function Home() {

  console.log('process.env.REACT_APP_NOTIFICATION_HOST', process.env.REACT_APP_NOTIFICATION_HOST);

  return (
    <>
      <Typography component='div' variant='h5'>
        Dashboard
      </Typography>
    </>
  );
}
