// handleRows

const discountCodeRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  amount: c.amount,
  percent: c.percent,
  start_code: c.start_code ? new Date(c.start_code) : null,
  end_code: c.end_code ? new Date(c.end_code) : null,
  universal: c.universal,
  created_at: new Date(c.created_at),
});

const paymentStatusRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  created_at: new Date(c.created_at),
});

const paymentProviderRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  created_at: new Date(c.created_at),
});

const paymentMethodRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  created_at: new Date(c.created_at),
});

const deliveryMethodRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  provider:
    c.provider && c.details && c.details.provider
      ? { id: c.provider, label: c.details.provider.name }
      : c.provider,
  created_at: new Date(c.created_at),
});

const deliveryStatusRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  delivery_method:
    c.delivery_method && c.details && c.details.delivery_method
      ? { id: c.delivery_method, label: c.details.delivery_method.name }
      : c.delivery_method,
  created_at: new Date(c.created_at),
});

const cartRowMap = (c) => ({
  id: c.id,
  customer:
    c.customer && c.customer_details
      ? { id: c.customer, label: c.customer_details.first_name }
      : c.customer,
  grand_total: c.grand_total,
  delivery_status:
    c.delivery_status && c.details && c.details.delivery_status
      ? { id: c.delivery_status, label: c.details.delivery_status.name }
      : c.delivery_status,
  payment_status:
    c.payment_status && c.details && c.details.payment_status
      ? { id: c.payment_status, label: c.details.payment_status.name }
      : c.payment_status,
  discount_code:
    c.discount_code && c.details && c.details.discount_code
      ? { id: c.discount_code, label: c.details.discount_code.name }
      : c.discount_code,
  same_delivery_for_all_items: c.same_delivery_for_all_items,
  created_at: new Date(c.created_at),
});

const addressRowMap = (c) => ({
  id: c.id,
  first_name: c.first_name,
  last_name: c.last_name,
  email: c.email,
  mobile: c.mobile,
  organisation: c.organisation,
  address1: c.address1,
  address2: c.address2,
  city: c.city,
  postcode: c.postcode,
  created_at: new Date(c.created_at),
});

const paymentRowMap = (c) => ({
  id: c.id,
  cart:
    c && c.cart && c.customer_details
      ? { id: c.cart, label: c.customer_details.first_name }
      : c.cart,
  billing_address:
    c.billing_address && c.details && c.details.billing_address
      ? { id: c.billing_address, label: c.details.billing_address.address1 }
      : c.billing_address,
  payment_provider:
    c.payment_provider && c.details && c.details.payment_provider
      ? { id: c.payment_provider, label: c.details.payment_provider.name }
      : c.payment_provider,
  payment_method:
    c.payment_method && c.details && c.details.payment_method
      ? { id: c.payment_method, label: c.details.payment_method.name }
      : c.payment_method,
  payment_status:
    c.payment_status && c.details && c.details.payment_status
      ? { id: c.payment_status, label: c.details.payment_status.name }
      : c.payment_status,
  payment_date: c.payment_date ? new Date(c.payment_date) : c.payment_date,
  created_at: new Date(c.created_at),
});

const cartItemRowMap = (c) => ({
  id: c.id,
  product_variant:
    c.product_variant && c.details && c.details.product_variant
      ? { id: c.product_variant, label: c.details.product_variant.name }
      : c.product_variant,
  quantity: c.quantity,
  list_price: c.list_price,
  discount: c.discount,
  vat_percentage: c.vat_percentage,
  total: c.total,
  campaign_id: c.campaign_id,
  campaign_discount: c.campaign_discount,
  cart:
    c && c.cart && c.customer_details
      ? { id: c.cart, label: c.customer_details.first_name }
      : c.cart,
  delivery_status:
    c.delivery_status && c.details && c.details.delivery_status
      ? { id: c.delivery_status, label: c.details.delivery_status.name }
      : c.delivery_status,
  created_at: new Date(c.created_at),
});

const itemDeliveryRowMap = (c) => ({
  id: c.id,
  external_reference: c.external_reference,
  delivery_cost: c.delivery_cost,
  vat_percent: c.vat_percent,
  promised_delivery_date: c.promised_delivery_date
    ? new Date(c.promised_delivery_date)
    : c.promised_delivery_date,
  campaign_id: c.campaign_id,
  campaign_discount: c.campaign_discount,
  tracking_url: c.tracking_url,
  cart_item: c.cart_item && c.details && c.details.cart_item && c.details.cart_item.details && c.details.cart_item.details.product_variant
  ? { id: c.cart_item, label: c.details.cart_item.details.product_variant.name }
  : null,
  delivery_method:
    c.delivery_method && c.details && c.details.delivery_method
      ? { id: c.delivery_method, label: c.details.delivery_method.name }
      : c.delivery_method,
  delivery_status:
    c.delivery_status && c.details && c.details.delivery_status
      ? { id: c.delivery_status, label: c.details.delivery_status.name }
      : c.delivery_status,
  delivery_address:
    c.delivery_address && c.details && c.details.delivery_address
      ? { id: c.delivery_address, label: c.details.delivery_address.address1 }
      : c.delivery_address,
  created_at: new Date(c.created_at),
});

const handleRows = {
  discountCodeRowMap,
  paymentStatusRowMap,
  paymentProviderRowMap,
  paymentMethodRowMap,
  deliveryMethodRowMap,
  deliveryStatusRowMap,
  cartRowMap,
  addressRowMap,
  paymentRowMap,
  cartItemRowMap,
  itemDeliveryRowMap,
};

export default handleRows;
