import _ from "lodash";
import moment from "moment";
import { bpaUrls } from "@config/routes";
import { parseJSON } from "@config/functions/helperFunctions";
import { createRecord } from "@config/functions/requests";

const {
  manualSendEmailV2Urls,
  sendEmailV2ActionUrls,
} = bpaUrls;

export const getReadOnlyStyles = (isKanbanView) => ({
  pointerEvents: isKanbanView ? "none" : undefined,
  userSelect: isKanbanView ? "none" : undefined,
});

const adjustCalendarSlotDates = ({
  updatedAtString,
  beginDateString,
  endDateString,
} = {}) => {
  const createdAt = moment(updatedAtString.substring(0, 10));
  const beginDate = moment(beginDateString);
  const endDate = moment(endDateString);
  const currentDate = moment(moment().format("YYYY-MM-DD"));

  // Calculate differences in days
  const diffDays = currentDate.diff(createdAt, "days");

  // Adjust begin and end dates
  const adjustedBeginDate = beginDate
    .add(diffDays, "days")
  const adjustedEndDate = endDate
    .add(diffDays, "days")

  return [adjustedBeginDate.toDate(), adjustedEndDate.toDate()];
};

/**
 * Checks if any key in an object includes a specified substring.
 * @param {Object} obj - The object to search through.
 * @param {string} substring - The substring to look for in the keys.
 * @returns {boolean} - Returns true if any key includes the substring, false otherwise.
 */
export const hasKeyIncludingSubstring = (obj, substring) => {
  return Object.keys(obj).some((key) => key.includes(substring));
};

export const convertNoneToNull = (value) => {
  return value === "None" ? null : value;
};

export const handleAccordionItemDelete = async ({
  setDeleting,
  notify,
  remove,
  index,
} = {}) => {
  try {
    setDeleting(true);
    remove(index);
  } catch (error) {
    console.log(error);
    notify("Failed to delete the item.", {
      type: "ERROR",
    });
  } finally {
    setDeleting(false);
  }
};

export const handleClose = ({ setOpen, shape, queryClient } = {}) => {
  setOpen(false);
  queryClient.invalidateQueries([`${shape?.id}-shape-and-actions`]);
};

export const getTemplateValues = (initialTemplate = {}) => {
  return {
    template: initialTemplate?.modelAssetId ? {
      id: initialTemplate?.modelAssetId,
      label: initialTemplate?.name,
    } : null,
    subject: initialTemplate?.subject ?? '',
    html: initialTemplate?.html ?? '',
  };
};

export const getEmailPayloadSectionValues = (initialToEmail) => {
  const { value } = initialToEmail || {};

  if (!value || !value.details || !value.details.mapping_field_name) {
    return null;
  }

  return {
    id: value.details.mapping_field_name.payload_section,
    label: value.details.mapping_field_name.details?.payload_section?.name,
  };
};

export const getToEmailCustomValue = (initialToEmail) => {
  const { value } = initialToEmail || {};

  if (!value || !value.static_value) {
    return null;
  }

  return value.static_value;
};

export const getNestedPayloadSection = (section) => ({
  id: section?.details?.payload_section?.id,
  label: section?.details?.payload_section?.name,
  sectionKeys: section?.details?.payload_section?.keys,
});

export const validateEmailList = (emailList) => {
  // Regular expression for validating an individual email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Split the list into individual emails
  const emails = emailList.split(",").map((email) => email.trim());

  // Initialize a set to track unique emails
  const uniqueEmails = new Set();

  // Validate each email in the list
  for (const email of emails) {
    if (!emailRegex.test(email) || uniqueEmails.has(email)) {
      return false; // Invalid or duplicate email found
    }
    uniqueEmails.add(email);
  }

  // Ensure there are no empty strings (trailing commas)
  if (emails.includes("")) {
    return false;
  }

  return true; // All emails in the list are valid and unique
};

export const mergeArrayOfObjects = (arrayOfObjects) => {
  return arrayOfObjects.reduce((acc, obj) => {
    for (let key in obj) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

export const mapArrayItems = (array, mappingDefinition, noStaticValue) => {
  if (!Array.isArray(array)) {
    return [];
  }

  return array
    .map((item) => {
      const { data: parsedValues } = parseJSON(item.static_value);
      if (!parsedValues && !noStaticValue) return null;
      // Apply the mapping definition to the parsed values
      return mappingDefinition(item, parsedValues ?? {});
    })
    .filter(Boolean); // filter out any null entries if parsing failed
};

export const findArrayElementByReferenceKey = (
  arrayContainer,
  referenceObject
) => {
  if (!Array.isArray(arrayContainer?.values)) {
    return null;
  }

  return arrayContainer.values.find((element) => {
    const { data } = parseJSON(element?.static_value);
    return data?.payload_section_key_reference === referenceObject?.uniqueKey;
  });
};

export const groupByActionParam = (values, paramId) => {
  return values.filter(({ action_param }) => action_param === paramId);
};

export const getEmailPayloadSectionKeyValues = (initialToEmail) => {
  if (!initialToEmail?.value?.mapping_field_name) return null;
  return initialToEmail?.value?.mapping_field_name;
};

export const calendarSlotMappingDefinition = (item, values) => {
  let newBeginDate = values?.start_date_time;
  let newEndDate = values?.end_date_time;

  if (item?.updated_at && values?.relative) {
    [newBeginDate, newEndDate] = adjustCalendarSlotDates({
      updatedAtString: item.updated_at,
      beginDateString: values?.start_date_time ?? "",
      endDateString: values?.end_date_time ?? "",
    });
  }

  return {
    id: item?.id,
    slotName: values?.title,
    slotDescription: values?.description,
    slotMeetingLink: values?.link,
    timezone: values?.timezone,
    relative: values?.relative,
    slotBegin: newBeginDate,
    slotEnd: newEndDate,
    slotDuration: values?.slot_duration,
    slotBuffer: values?.buffer_between_slots,
    slotCalendar: {
      id: values?.calendar,
      label: values?.calendar_label,
    },
    uniqueKey: values?.payload_section_key_reference,
    guests: values?.guests,
  };
};

export const formMappingDefinition = (item, values) => {
  const details = item?.details?.static_value;

  return {
    id: item?.id,
    formName: values?.name,
    form: { id: values?.form, label: values?.form_label },
    personPayloadSection: getNestedPayloadSection(details?.person),
    personPayloadSectionKey: convertNoneToNull(details?.person?.id),
    uniqueKey: values?.payload_section_key_reference,
    recipientFirstNamePayloadSection: getNestedPayloadSection(
      details?.recipient_first_name
    ),
    recipientFirstNamePayloadSectionKey: convertNoneToNull(
      details?.recipient_first_name?.id
    ),
    recipientLastNamePayloadSection: getNestedPayloadSection(
      details?.recipient_last_name
    ),
    recipientLastNamePayloadSectionKey: convertNoneToNull(
      details?.recipient_last_name?.id
    ),
    recipientEmailPayloadSection: getNestedPayloadSection(
      details?.recipient_email
    ),
    recipientEmailPayloadSectionKey: convertNoneToNull(
      details?.recipient_email?.id
    ),
  };
};

export const documentMappingDefinition = (item, values) => {
  const details = item?.details?.static_value;
  const docFieldValues = details?.document_field_values ?? {};
  const docFieldValuesTransformed = {};

  Object.keys(docFieldValues).forEach((key) => {
    const item = docFieldValues[key];
    const itemName = _.camelCase(`${item?.details?.edoc?.name}PayloadSection`);

    docFieldValuesTransformed[key] = {
      [itemName]: {
        id: item?.payload_section,
        label: item?.details?.payload_section?.name,
        sectionKeys: item?.details?.payload_section?.keys ?? [],
      },
      [`${itemName}Key`]: item?.id,
    };
  });

  return {
    id: item?.id,
    docName: values?.name,
    docFieldValues: docFieldValuesTransformed,
    expiryDuration: values?.expiry_duration,
    document: { id: values?.document, label: values?.document_label },
    personPayloadSection: getNestedPayloadSection(details?.person),
    personPayloadSectionKey: convertNoneToNull(details?.person?.id),
    uniqueKey: values?.payload_section_key_reference,
    recipientFirstNamePayloadSection: getNestedPayloadSection(
      details?.recipient_first_name
    ),
    recipientFirstNamePayloadSectionKey: convertNoneToNull(
      details?.recipient_first_name?.id
    ),
    recipientLastNamePayloadSection: getNestedPayloadSection(
      details?.recipient_last_name
    ),
    recipientLastNamePayloadSectionKey: convertNoneToNull(
      details?.recipient_last_name?.id
    ),
    recipientEmailPayloadSection: getNestedPayloadSection(
      details?.recipient_email
    ),
    recipientEmailPayloadSectionKey: convertNoneToNull(
      details?.recipient_email?.id
    ),
  };
};

export const contextMappingDefinition = (item) => {
  const payloadSection =
    item?.details?.mapping_field_name?.details?.payload_section;
  const keyContext = _.camelCase(`${item?.key}PayloadSectionContext`);
  const sectionKeyContext = _.camelCase(`${item?.key}SectionKeyContext`);

  return {
    [keyContext]: {
      id: item?.details?.mapping_field_name?.payload_section,
      label: payloadSection?.name,
      sectionKeys: payloadSection?.keys,
    },
    [sectionKeyContext]: item?.mapping_field_name,
  };
};

export const initialEmailMappingDefinition = (initialEmails) =>
  initialEmails
    .filter((value) => value?.static_value || value?.mapping_field_name)
    .map((value) =>
      value?.mapping_field_name
        ? {
          label: `${value?.details?.mapping_field_name?.details?.payload_section?.name} / ${value?.details?.mapping_field_name?.label}`,
          static_value: "",
          mapping_field_name: value?.mapping_field_name,
        }
        : {
          label: value?.static_value,
          static_value: value?.static_value,
          mapping_field_name: null,
        }
    );

export const getSlotFields = (i) => ({
  name: `calendarSlots-${i}-name`,
  description: `calendarSlots-${i}-description`,
  link: `calendarSlots-${i}-link`,
  timezone: `calendarSlots-${i}-timezone`,
  relative: `calendarSlots-${i}-relative`,
  start_date_time: `calendarSlots-${i}-start_date_time`,
  end_date_time: `calendarSlots-${i}-end_date_time`,
  slot_duration: `calendarSlots-${i}-slot_duration`,
  buffer_between_slots: `calendarSlots-${i}-buffer_between_slots`,
  calendar: `calendarSlots-${i}-calendar`,
});

export const getFormFields = (i) => ({
  form: `forms-${i}-form`,
  person: `forms-${i}-person`,
  recipient_first_name: `forms-${i}-recipient_first_name`,
  recipient_last_name: `forms-${i}-recipient_last_name`,
  recipient_email: `forms-${i}-recipient_email`,
});

export const getEdocFields = (i) => ({
  document: `edocs-${i}-document`,
  person: `edocs-${i}-person`,
  recipient_first_name: `edocs-${i}-recipient_first_name`,
  recipient_last_name: `edocs-${i}-recipient_last_name`,
  recipient_email: `edocs-${i}-recipient_email`,
  expiry_duration: `edocs-${i}-expiry_duration`,
  document_field_values: `edocs-${i}-document_field_values`,
});

export const getShortLinkFields = (i) => ({
  short_link: `short_links-${i}-short_link`,
});

export const buildLabelForInitialValueFields = (
  fullValue,
  value,
  field,
  label_type = "static_value"
) => ({
  ...value,
  label: value?.mapping_field_name
    ? `${fullValue?.details?.[label_type]?.[field]?.details?.payload_section?.name} / ${fullValue?.details?.[label_type]?.[field]?.label}`
    : value?.label,
});

export const initialCalendarSlotMappingDefinition = (initialCalendarSlots) =>
  initialCalendarSlots
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getSlotFields(i);

      let newBeginDate = valueInObject?.start_date_time?.static_value;
      let newEndDate = valueInObject?.end_date_time?.static_value;

      if (value?.updated_at && valueInObject?.relative?.static_value && newBeginDate && newEndDate) {
        [newBeginDate, newEndDate] = adjustCalendarSlotDates({
          updatedAtString: value.updated_at,
          beginDateString: newBeginDate,
          endDateString: newEndDate,
        });
      }

      return {
        payload_section_key_reference: valueInObject?.payload_section_key_reference,
        [fields.name]: buildLabelForInitialValueFields(value, valueInObject?.name, "name"),
        [fields.description]: buildLabelForInitialValueFields(value, valueInObject?.description, "description"),
        [fields.link]: buildLabelForInitialValueFields(value, valueInObject?.link, "link"),
        [fields.timezone]: buildLabelForInitialValueFields(value, valueInObject?.timezone, "timezone"),
        [fields.relative]: buildLabelForInitialValueFields(value, valueInObject?.relative, "relative"),
        [fields.start_date_time]: buildLabelForInitialValueFields(
          value,
          newBeginDate
            ? {
              label: newBeginDate,
              static_value: newBeginDate,
              mapping_field_name: null,
            }
            : valueInObject?.start_date_time,
          "start_date_time"
        ),
        [fields.end_date_time]: buildLabelForInitialValueFields(
          value,
          newEndDate
            ? {
              label: newEndDate,
              static_value: newEndDate,
              mapping_field_name: null,
            }
            : valueInObject?.end_date_time,
          "end_date_time"
        ),
        [fields.slot_duration]: buildLabelForInitialValueFields(value, valueInObject?.slot_duration, "slot_duration"),
        [fields.buffer_between_slots]: buildLabelForInitialValueFields(value, valueInObject?.buffer_between_slots, "buffer_between_slots"),
        [fields.calendar]: buildLabelForInitialValueFields(value, valueInObject?.calendar, "calendar"),
      };
    });

export const initialCalendarSlotMappingDefinitionOnlyStuff = (
  initialCalendarSlots
) => {
  const response = {};
  initialCalendarSlots
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getSlotFields(i);

      let newBeginDate = valueInObject?.start_date_time?.static_value;
      let newEndDate = valueInObject?.end_date_time?.static_value;

      if (value?.updated_at && valueInObject?.relative?.static_value && newBeginDate && newEndDate) {
        [newBeginDate, newEndDate] = adjustCalendarSlotDates({
          updatedAtString: value.updated_at,
          beginDateString: newBeginDate,
          endDateString: newEndDate,
        });
      }

      response[fields.name] = valueInObject?.name?.static_value;
      response[fields.description] = valueInObject?.description?.static_value;
      response[fields.link] = valueInObject?.link?.static_value;
      response[fields.timezone] = valueInObject?.timezone?.static_value;
      response[fields.relative] = valueInObject?.relative?.static_value;
      response[fields.start_date_time] = newBeginDate;
      response[fields.end_date_time] = newEndDate;
      response[fields.slot_duration] = valueInObject?.slot_duration?.static_value;
      response[fields.buffer_between_slots] = valueInObject?.buffer_between_slots?.static_value;
      response[fields.calendar] = valueInObject?.calendar;
    });
  return response;
};

export const initialFormMappingDefinition = (initialForms) =>
  initialForms
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getFormFields(i);

      return {
        payload_section_key_reference: valueInObject?.payload_section_key_reference,
        [fields.form]: buildLabelForInitialValueFields(value, valueInObject?.form, "form"),
        [fields.person]: buildLabelForInitialValueFields(value, valueInObject?.person, "person"),
        [fields.recipient_first_name]: buildLabelForInitialValueFields(value, valueInObject?.recipient_first_name, "recipient_first_name"),
        [fields.recipient_last_name]: buildLabelForInitialValueFields(value, valueInObject?.recipient_last_name, "recipient_last_name"),
        [fields.recipient_email]: buildLabelForInitialValueFields(value, valueInObject?.recipient_email, "recipient_email"),
      };
    });

export const initialFormMappingDefinitionOnlyForms = (initialForms) => {
  const response = {};
  initialForms
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getFormFields(i);

      response[fields.form] = valueInObject?.form;
    });
  return response;
};

export const initialEdocMappingDefinition = (initialEdocs) =>
  initialEdocs
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getEdocFields(i);

      const newDocumentFieldValues = {};

      Object.entries(valueInObject?.document_field_values ?? {}).map(
        ([field, documentFieldValue]) => {
          newDocumentFieldValues[field] = documentFieldValue?.mapping_field_name
            ? {
              ...documentFieldValue,
              label: `${value?.details?.static_value?.document_field_values[field]?.details?.payload_section?.name} / ${value?.details?.static_value?.document_field_values[field]?.label}`,
            }
            : documentFieldValue;
        }
      );

      return {
        payload_section_key_reference: valueInObject?.payload_section_key_reference,
        [fields.document]: buildLabelForInitialValueFields(value, valueInObject?.document, "document"),
        [fields.person]: buildLabelForInitialValueFields(value, valueInObject?.person, "person"),
        [fields.recipient_first_name]: buildLabelForInitialValueFields(value, valueInObject?.recipient_first_name, "recipient_first_name"),
        [fields.recipient_last_name]: buildLabelForInitialValueFields(value, valueInObject?.recipient_last_name, "recipient_last_name"),
        [fields.recipient_email]: buildLabelForInitialValueFields(value, valueInObject?.recipient_email, "recipient_email"),
        [fields.expiry_duration]: buildLabelForInitialValueFields(value, valueInObject?.expiry_duration, "expiry_duration"),
        [fields.document_field_values]: newDocumentFieldValues,
      };
    });

export const initialEdocMappingDefinitionOnlyFields = (initialEdocs) => {
  const response = {};
  initialEdocs
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getEdocFields(i);

      response[fields.document] = valueInObject?.document;
      response[fields.expiry_duration] = `${valueInObject?.expiry_duration?.static_value}`;
    });
  return response;
};

export const initialShortLinkMappingDefinition = (initialShortLinks) =>
  initialShortLinks
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getShortLinkFields(i);

      return {
        payload_section_key_reference: valueInObject?.payload_section_key_reference,
        [fields.short_link]: buildLabelForInitialValueFields(value, valueInObject?.short_link, "short_link"),
      };
    });

export const initialShortLinkMappingDefinitionOnlyFields = (initialShortLinks) => {
  const response = {};
  initialShortLinks
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getShortLinkFields(i);

      response[fields.short_link] = valueInObject?.short_link;
    });
  return response;
};

export const getInitialValues = ({
  initialTemplate,
  initialToEmail,
  initialCcEmails,
  initialBccEmails,
  initialSlotLinks,
  initialForms,
  initialContext,
  initialEdocs,
  initialShortLinks,
  contextMappingDefinition,
} = {}) => {
  return {
    ...getTemplateValues(initialTemplate),
    recipientPayloadSection: null,
    recipientPayloadSectionKey: null,
    toEmails: initialEmailMappingDefinition(initialToEmail?.values),
    ccEmails: initialEmailMappingDefinition(initialCcEmails?.values),
    bccEmails: initialEmailMappingDefinition(initialBccEmails?.values),
    calendarSlots: initialCalendarSlotMappingDefinition(
      initialSlotLinks?.values
    ),
    ...initialCalendarSlotMappingDefinitionOnlyStuff(initialSlotLinks?.values),
    formPayloadSection: null,
    formPayloadSectionKey: null,
    forms: initialFormMappingDefinition(initialForms?.values),
    ...initialFormMappingDefinitionOnlyForms(initialForms?.values),
    edocPayloadSection: null,
    edocPayloadSectionKey: null,
    edocs: initialEdocMappingDefinition(initialEdocs?.values),
    ...initialEdocMappingDefinitionOnlyFields(initialEdocs?.values),
    short_links: initialShortLinkMappingDefinition(initialShortLinks?.values),
    ...initialShortLinkMappingDefinitionOnlyFields(initialShortLinks?.values),
    contextFields: {
      ...mergeArrayOfObjects(
        mapArrayItems(initialContext?.values, contextMappingDefinition, true)
      ),
    },
  };
};


const triggerKanbanSendEmail = async ({
  workflowId,
  shapeAction,
  workflowInstances,
  user,
  payload,
} = {}) => {
  return await createRecord({
    values: {
      workflow: workflowId,
      shape_action: shapeAction?.id,
      workflow_instances: workflowInstances ?? [],
      calendar_slots: payload?.calendar_slots,
      type: payload?.type,
      subject: payload?.subject,
      body: payload?.body,
    },
    url: manualSendEmailV2Urls.list(),
    token: user?.token,
    actAs: user?.actAs,
  });
};


/**
 * Validates email-related fields within the provided values object.
 * Checks if the template, toEmailPayloadSection, toEmailPayloadSectionKey are selected,
 * and if ccEmails and bccEmails have valid formats.
 * @param {Object} values - The values object containing the fields to be validated.
 * @returns {Object} An object containing any validation errors found.
 */
const validateEmailFields = ({ values } = {}) => {
  const errorsList = {};

  if (!values?.template?.id) {
    errorsList.template = "Please select a template.";
  }

  if (!values?.toEmails || !values.toEmails.length) {
    errorsList.toEmails = "Please select atleast one email.";
  }

  return errorsList;
};


const checkFormErrors = (form, index, setFieldError) => {
  let hasErrors = false;
  const fields = getFormFields(index);

  const addError = (fieldName, message) => {
    setFieldError(fieldName, message);
    hasErrors = true;
  };

  if (!form?.[fields?.form]?.static_value) {
    addError(fields?.form, "Please select a form.");
  }

  if (!form?.[fields?.person]) {
    addError(fields?.person, "Please select a person.");
  }

  if (!form?.[fields?.recipient_first_name]) {
    addError(fields?.recipient_first_name, "Please select a first name.");
  }

  if (!form?.[fields?.recipient_last_name]) {
    addError(fields?.recipient_last_name, "Please select a last name.");
  }

  if (!form?.[fields?.recipient_email]) {
    addError(fields?.recipient_email, "Please select a email.");
  }

  return hasErrors;
};

function isNumeric(value) {
  return /^\d+$/.test(value);
}

const checkEdocErrors = (edoc, index, setFieldError) => {
  let hasErrors = false;
  const fields = getEdocFields(index);

  const addError = (fieldName, message) => {
    setFieldError(fieldName, message);
    hasErrors = true;
  };

  if (!edoc?.[fields?.document]?.static_value) {
    addError(fields?.document, "Please select a document.");
  }

  if (!edoc?.[fields?.person]) {
    addError(fields?.person, "Please select a person.");
  }

  if (!edoc?.[fields?.recipient_first_name]) {
    addError(fields?.recipient_first_name, "Please select a first name.");
  }

  if (!edoc?.[fields?.recipient_last_name]) {
    addError(fields?.recipient_last_name, "Please select a last name.");
  }

  if (!edoc?.[fields?.recipient_email]) {
    addError(fields?.recipient_email, "Please select a email.");
  }

  if (
    edoc?.[fields?.expiry_duration]?.static_value === undefined ||
    edoc?.[fields?.expiry_duration]?.static_value === null ||
    !isNumeric(edoc?.[fields?.expiry_duration]?.static_value) ||
    edoc?.[fields?.expiry_duration]?.static_value < 0
  ) {
    addError(fields?.expiry_duration, "Must be zero or a positive number.");
  }

  return hasErrors;
};

const checkShortLinkErrors = (short_link, index, setFieldError) => {
  let hasErrors = false;
  const fields = getShortLinkFields(index);

  const addError = (fieldName, message) => {
    setFieldError(fieldName, message);
    hasErrors = true;
  };

  if (!short_link?.[fields?.short_link]?.static_value) {
    addError(fields?.short_link, "Please select a short link.");
  }

  return hasErrors;
};

const checkCalendarSlotErrors = (slotLink, index, setFieldError, namesSet) => {
  let hasErrors = false;
  const fields = getSlotFields(index);
  const name = slotLink?.[fields?.name]?.static_value?.length
    ? slotLink?.[fields?.name]?.static_value.trim()
    : "";

  const addError = (fieldName, message) => {
    setFieldError(fieldName, message);
    hasErrors = true;
  };

  if (!name) {
    addError(fields?.name, "Name is required.");
  }

  // Check if name is unique
  if (namesSet.has(name)) {
    addError(fields?.name, "Name must be unique.");
  }

  if (!slotLink?.[fields?.calendar]?.static_value) {
    addError(fields?.calendar, "Please select a calendar.");
  }

  if (!slotLink?.[fields?.start_date_time]?.static_value) {
    addError(fields?.start_date_time, "Please select a begin date time.");
  }

  if (
    new Date(slotLink?.[fields?.start_date_time]?.static_value) < new Date()
  ) {
    addError(fields?.start_date_time, "Date cannot be in the past.");
  }

  if (!slotLink?.[fields?.end_date_time]?.static_value) {
    addError(fields?.end_date_time, "Please select a end date time.");
  }

  if (
    new Date(slotLink?.[fields?.end_date_time]?.static_value) <
    new Date(slotLink?.[fields?.start_date_time]?.static_value)
  ) {
    addError(
      fields?.end_date_time,
      "End date cannot be earlier than begin date."
    );
  }

  if (!slotLink?.[fields?.link]?.static_value) {
    addError(fields?.link, "Please select a conference link.");
  }

  if (
    slotLink?.[fields?.buffer_between_slots]?.static_value === undefined ||
    slotLink?.[fields?.buffer_between_slots]?.static_value === null ||
    slotLink?.[fields?.buffer_between_slots]?.static_value < 1
  ) {
    addError(
      fields?.buffer_between_slots,
      "Buffer is required and must be a positive number."
    );
  }

  if (
    slotLink?.[fields?.slot_duration]?.static_value === undefined ||
    slotLink?.[fields?.slot_duration]?.static_value === null ||
    slotLink?.[fields?.slot_duration]?.static_value < 1
  ) {
    addError(
      fields?.slot_duration,
      "Duration is required and must be a positive number."
    );
  }

  namesSet.add(name);

  return hasErrors;
};

const checkPreviewEmailErrors = (values, setFieldError) => {
  let hasErrors = false;

  const addError = (fieldName, message) => {
    setFieldError(fieldName, message);
    hasErrors = true;
  };

  if (!values?.subject) {
    addError("subject", "Subject is required.");
  }

  return hasErrors;
};

/**
 * Adds email-related fields to the payload.
 * @param {Array} payload - The payload array to add email fields to.
 * @param {Object} initialTemplate - The initial template for reference.
 * @param {Object} shapeAction - The shape action object.
 * @param {Object} values - The values object containing email fields.
 * @param {Object} initialToEmail - Initial to email for reference.
 * @param {Object} initialCcEmails - Initial cc emails for reference.
 * @param {Object} initialBccEmails - Initial bcc emails for reference.
 */
const addEmailFieldsToPayload = ({
  payload,
  values,
} = {}) => {
  // Add email template
  payload.mail_template = {
    label: values?.template?.label,
    model_asset: values?.template?.id,
  };

  // Add to emails
  values?.toEmails.map((toEmail) =>
    payload.to_emails.push({
      ...toEmail,
    })
  );

  // Add cc emails
  values?.ccEmails.map((ccEmail) =>
    payload.cc_emails.push({
      ...ccEmail,
    })
  );

  // Add bcc emails
  values?.bccEmails.map((bccEmail) =>
    payload.bcc_emails.push({
      ...bccEmail,
    })
  );
};

const addContextToPayload = ({
  contextFields,
  values,
  payload,
} = {}) => {
  if (
    _.isObject(values?.contextFields) &&
    Object.keys(values?.contextFields)?.length
  ) {
    for (const { name: key } of contextFields) {
      const formikName = _.camelCase(`${key}SectionKeyContext`);
      const value = values?.contextFields[formikName];
      if (!value) continue;
      payload.context_fields.push({
        key,
        mapping_field_name: value ?? null,
      });
    }
  }
};

const addCalendarSlotsToPayload = ({
  values,
  user,
  payload,
  setFieldError,
} = {}) => {
  const namesSet = new Set();

  if (
    values?.calendarSlots
      .map((calendarSlot, index) =>
        checkCalendarSlotErrors(calendarSlot, index, setFieldError, namesSet)
      )
      .filter((e) => e).length
  )
    return true;

  values?.calendarSlots.map((calendarSlot, index) => {
    const fields = getSlotFields(index);
    payload.calendar_slots.push({
      payload_section_key_reference: calendarSlot?.payload_section_key_reference,
      name: calendarSlot[fields?.name],
      description: calendarSlot[fields?.description],
      link: calendarSlot[fields?.link],
      timezone: calendarSlot[fields?.timezone],
      relative: calendarSlot[fields?.relative],
      start_date_time: calendarSlot[fields?.start_date_time],
      end_date_time: calendarSlot[fields?.end_date_time],
      slot_duration: calendarSlot[fields?.slot_duration],
      buffer_between_slots: calendarSlot[fields?.buffer_between_slots],
      calendar: calendarSlot[fields?.calendar],
      guests: [
        {
          label: user?.details?.email,
          static_value: user?.details?.email,
          mapping_field_name: null,
        },
      ],
    });
  });
};

const addFormsToPayload = ({
  values,
  payload,
  setFieldError,
} = {}) => {
  if (
    values?.forms
      .map((form, index) => checkFormErrors(form, index, setFieldError))
      .filter((e) => e).length
  )
    return true;

  values?.forms.map((form, index) => {
    const fields = getFormFields(index);
    payload.forms.push({
      payload_section_key_reference: form?.payload_section_key_reference,
      form: form?.[fields?.form],
      person: form?.[fields?.person],
      recipient_first_name: form?.[fields?.recipient_first_name],
      recipient_last_name: form?.[fields?.recipient_last_name],
      recipient_email: form?.[fields?.recipient_email],
    });
  });
};

const addDocMappingsToPayload = ({
  values,
  payload,
  setFieldError,
} = {}) => {
  if (
    values?.edocs
      .map((edoc, index) => checkEdocErrors(edoc, index, setFieldError))
      .filter((e) => e).length
  )
    return true;

  values?.edocs.map((edoc, index) => {
    const fields = getEdocFields(index);
    const documentFieldValues = edoc?.[fields?.document_field_values];
    const newDocumentFieldValues = {};

    Object.entries(documentFieldValues).map(([field, documentFieldValue]) => {
      newDocumentFieldValues[field] = documentFieldValue;
    });

    payload.edocs.push({
      payload_section_key_reference: edoc?.payload_section_key_reference,
      document: edoc?.[fields?.document],
      person: edoc?.[fields?.person],
      recipient_first_name: edoc?.[fields?.recipient_first_name],
      recipient_last_name: edoc?.[fields?.recipient_last_name],
      recipient_email: edoc?.[fields?.recipient_email],
      expiry_duration: edoc?.[fields?.expiry_duration],
      expiry_duration_unit: {
        label: "days",
        static_value: "days",
        mapping_field_name: null
      },
      document_field_values: newDocumentFieldValues,
    });
  });
};


const addShortLinksMappingsToPayload = ({
  values,
  payload,
  setFieldError,
} = {}) => {
  if (
    values?.short_links
      .map((short_link, index) => checkShortLinkErrors(short_link, index, setFieldError))
      .filter((e) => e).length
  )
    return true;

  values?.short_links.map((short_link, index) => {
    const fields = getShortLinkFields(index);

    payload.short_links.push({
      payload_section_key_reference: short_link?.payload_section_key_reference,
      short_link: short_link?.[fields?.short_link],
    });
  });
};


/**
 * Main function to handle the popup submit action.
 * Includes processing of different fields and payload creation.
 * @param {Object} params - The parameters including values, state setters, and other dependencies.
 */
export const handlePopupSubmit = async ({
  values,
  setValues,
  setSubmitting,
  setErrors,
  setFieldError,
  setUniqueNameErrors,
  isKanbanView,
  workflowId,
  shapeAction,
  workflowInstances,
  user,
  contextFields,
  notify,
  queryClient,
  contentOptionsGroupRef,
  setOpen,
  shape,
  currentStep,
  setCurrentStep
} = {}) => {
  try {
    setSubmitting(true);
    setErrors({});

    setUniqueNameErrors([]);

    const vefErrorsList = validateEmailFields({ values });

    const payload = {
      mail_template: null,
      to_emails: [],
      cc_emails: [],
      bcc_emails: [],
      context_fields: [],
      calendar_slots: [],
      forms: [],
      edocs: [],
      short_links: [],
      type: values?.type,
      subject: values?.subject,
      body: values?.html,
    };

    // Create a set for tracking names

    const hasPreviewEmailErrors = isKanbanView && payload?.type !== "preview" && currentStep !== "setup" ? checkPreviewEmailErrors(values, setFieldError) : false;

    const hasSlotLinkErrors = addCalendarSlotsToPayload({
      values,
      user,
      payload,
      setFieldError,
    });

    const hasFormErrors = addFormsToPayload({
      values,
      payload,
      setFieldError,
    });

    const hasDocErrors = addDocMappingsToPayload({
      values,
      payload,
      setFieldError,
    });

    const hasShortLinkErrors = addShortLinksMappingsToPayload({
      values,
      payload,
      setFieldError,
    });

    const hasVefErrors = Object.keys(vefErrorsList)?.length;
    const hasAnyErrors = hasSlotLinkErrors || hasFormErrors || hasDocErrors || hasShortLinkErrors;

    if (hasVefErrors || hasAnyErrors) {
      if (hasVefErrors) {
        setErrors(vefErrorsList);
      } else {
        contentOptionsGroupRef.current?.scrollIntoView({
          behavior: "smooth",
        });
      }
      return; // Exit the function early if there are any errors
    }

    if (hasPreviewEmailErrors) return;

    addEmailFieldsToPayload({
      payload,
      values,
    });

    // Add context
    addContextToPayload({
      contextFields,
      values,
      payload,
    });

    console.log(payload);

    if (isKanbanView) {
      const resp = await triggerKanbanSendEmail({
        workflowId,
        shapeAction,
        workflowInstances,
        user,
        payload,
      });

      if (payload?.type === "preview") {
        setValues({
          ...values,
          subject: resp?.data?.subject,
          html: resp?.data?.html_body,
        })
        return setCurrentStep('preview');
      } else if (payload?.type !== "save" && payload?.type !== "preview") {
        handleClose({ setOpen, shape, queryClient });
      }
    } else {
      await createRecord({
        values: payload,
        url: sendEmailV2ActionUrls.detail(shapeAction?.id),
        token: user?.token,
        actAs: user?.actAs,
      });
    }

    queryClient.invalidateQueries(["payloadSectionName-map-payload-section"])

    notify("Operation completed", {
      type: "SUCCESS",
    });
  } catch (error) {
    console.log(error?.response?.data ?? error?.message);
    notify("There was an error, please refresh the page", {
      type: "ERROR",
    });
  } finally {
    setSubmitting(false);
  }
};
