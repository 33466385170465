import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { ecommerceUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import ecommerceHandleRow from '@config/handleRows/ecommerce';
import { PaymentStatusForm } from '@config/forms/ecommerce';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { paymentStatusesUrls } = ecommerceUrls;
const { paymentStatusRowMap } = ecommerceHandleRow;

export default function PaymentStatuses({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label="Payment Statuses"
        prefix="0u4"
        labels={labels}
        editMode={editMode}
        microservice="E-Commerce"
        model="PaymentStatus"
        urls={paymentStatusesUrls}
        columnKey="paymentStatusEcommerce"
        addNewForm={{
          getFields: () => PaymentStatusForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: paymentStatusesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify(`Payment Status Created!`, {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={paymentStatusRowMap}
      />
    </Box>
  );
}
