import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Universities from '@pages/LMS/Universities';
import UniversityDetail from '@pages/LMS/Universities/detail';
import Homeworks from '@pages/LMS/Homeworks';
import FacultyDetail from '@pages/LMS/Faculties/detail';
import Teams from '@pages/LMS/Teams';
import Courses from '@pages/LMS/Courses';
import Lessons from '@pages/LMS/Lessons';
import Modules from '@pages/LMS/Modules';
import Skill from '@pages/LMS/Skill';
import Students from '@pages/LMS/Students';
import Instructors from '@pages/LMS/Instructors';
import Coaches from '@pages/LMS/Coaches';
import Mentors from '@pages/LMS/Mentors';
import CourseDetail from '@pages/LMS/Courses/detail';
import ModuleDetail from '@pages/LMS/Modules/detail';
import LessonDetail from '@pages/LMS/Lessons/detail';
import HomeworkDetail from '@pages/LMS/Homeworks/detail';
import StudentDetail from '@pages/LMS/Students/detail';
import TeamDetail from '@pages/LMS/Teams/detail';
import CreateCourse from '@pages/LMS/CreateCourse';
import EditCourse from '@pages/LMS/EditCourse/detail';
import CourseModule from '@pages/LMS/CourseModule/detail';
import Category from '@pages/LMS/Category';
import CategoryDetail from '@pages/LMS/Category/detail';
import LessonReferenceDetail from '@pages/LMS/LessonReferences/detail';


export default function () {
  return (
    <>
      <Route
        path='/create-course'
        element={<WithLayout comp={CreateCourse} />}
      />
      <Route
        path='/edit-course/:id'
        element={<WithLayout comp={EditCourse} />}
      />
      <Route
        path='/course-module/:id'
        element={<WithLayout comp={CourseModule} />}
      />
      <Route
        path='/universities'
        element={<WithLayout comp={Universities} />}
      />
      <Route
        path='/universities/:id'
        element={<WithLayout comp={UniversityDetail} />}
      />
      <Route
        path='/faculties/:id'
        element={<WithLayout comp={FacultyDetail} />}
      />
      <Route path='/homeworks' element={<WithLayout comp={Homeworks} />} />
      <Route path='/teams' element={<WithLayout comp={Teams} />} />
      <Route path='/courses' element={<WithLayout comp={Courses} />} />
      <Route path='/lessons' element={<WithLayout comp={Lessons} />} />
      <Route path='/modules' element={<WithLayout comp={Modules} />} />
      <Route path='/skills' element={<WithLayout comp={Skill} />} />
      <Route path='/students' element={<WithLayout comp={Students} />} />
      <Route path='/instructors' element={<WithLayout comp={Instructors} />} />
      <Route path='/coaches' element={<WithLayout comp={Coaches} />} />
      <Route path='/mentors' element={<WithLayout comp={Mentors} />} />
      <Route path='/courses/:id' element={<WithLayout comp={CourseDetail} />} />
      <Route path='/modules/:id' element={<WithLayout comp={ModuleDetail} />} />
      <Route path='/lessons/:id' element={<WithLayout comp={LessonDetail} />} />
      <Route
        path='/homeworks/:id'
        element={<WithLayout comp={HomeworkDetail} />}
      />
      <Route
        path='/students/:id'
        element={<WithLayout comp={StudentDetail} />}
      />
      <Route path='/teams/:id' element={<WithLayout comp={TeamDetail} />} />
      <Route path='/categories' element={<WithLayout comp={Category} />} />
      <Route
        path='/categories/:id'
        element={<WithLayout comp={CategoryDetail} />}
      />
      <Route
        path='/lesson-references/:id'
        element={<WithLayout comp={LessonReferenceDetail} />}
      />

    </>
  );
}
