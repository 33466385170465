import * as React from 'react';
import { Autocomplete, Chip } from '@mui/material';
import MUITextField from '@mui/material/TextField';
import { useField, useFormikContext } from 'formik';

export default function FreeSoloAutocompleteField({
  customValue,
  label,
  name,
  options,
  onChange = null,
  setSearchTerm = (term) => term,
  isError,
  error,
  multiple = true,
  freeSolo = true,
  isOptionEqualToValue = (option, value) => false,
  ...rest
}) {
  const [field, meta] = useField(name);
  const [inputValue, setInputValue] = React.useState('');
  const { setFieldValue, setFieldError } = useFormikContext();

  const handleChange = (e, newValue) => {
    if (!onChange) {
      setFieldValue(name, newValue);
    } else {
      onChange({
        newValue,
        setFieldValue,
        setFieldError,
      });
    }
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchTerm(inputValue);
    }, 400);

    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  return (
    <Autocomplete
      {...rest}
      multiple={multiple}
      options={options || []}
      value={customValue ? customValue : field?.value ?? (multiple ? [] : null)}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
      onChange={handleChange}
      freeSolo={freeSolo}
      getOptionLabel={(option) => option?.label}
      isOptionEqualToValue={isOptionEqualToValue}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            size="small"
            label={option?.label}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <MUITextField
          {...params}
          onChange={handleChange}
          error={!!meta.error}
          helperText={meta.error}
          variant="outlined"
          fullWidth
          size="small"
          label={label}
          autoComplete="off"
        />
      )}
    />
  );
}
