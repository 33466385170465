import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { selectProfile } from '@redux/profileSlice';
import { pmUrls } from '@config/routes';
import BasicTable from './BasicTable';
import useRQuery from '@hooks/useRQuery';
import {
  formatDateTime,
  getAuthHeader,
} from '@config/functions/helperFunctions';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
const { tasksUrls } = pmUrls;

export default function SubtasksList({ taskId, setHeight }) {
  const user = useSelector(selectProfile);

  const { data, isFetching } = useRQuery({
    key: [`${taskId}-subtasks`, taskId, user.token, user?.actAs],
    url: tasksUrls.list(`?parent_task=${taskId}&ordering=order`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
    renderRow: (r) => ({
      id: r.id,
      order: r?.order,
      task_level: r?.task_level,
      name: r.name,
      description: r.description,
      duration_estimate: r.duration_estimate,
      duration_unit: r.duration_unit,
      duration_actual: r.duration_actual,
      started: r.started ? formatDateTime(new Date(r.started)) : null,
      deadline: r.deadline ? formatDateTime(new Date(r.deadline)) : null,
      owner: r.details && r.details.owner ? r.details.owner.name : null,
      status: r.details && r.details.status ? r.details.status.name : null,
    }),
  });

  React.useEffect(() => {
    if (!data) return;
    const height = 85 * data.lenght;
    setHeight(height > 338 ? 338 : height);
  }, [data, setHeight]);

  return (
    <Box sx={{ pl: 12, pr: 3, py: 2, overflow: 'hidden' }}>
      <BasicTable
        rows={data ? data : []}
        isFetching={isFetching}
        renderRow={(row) => (
          <TableRow
            key={row.name}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              display: 'block',
            }}
          >
            <TableCell style={{ width: '50px' }}>
              {row?.task_level}
              {row?.order}
            </TableCell>
            <TableCell style={{ width: '400px' }}>
              <Link to={`/tasks/${row.id}`}>{row?.name}</Link>
            </TableCell>
            <TableCell style={{ width: '140px' }}>{row?.started}</TableCell>
            <TableCell style={{ width: '200px' }}>
              {row?.duration_estimate}
            </TableCell>
            <TableCell style={{ width: '250px' }}>
              {row?.duration_unit}
            </TableCell>
            <TableCell style={{ width: '200px' }}>
              {row?.duration_actual}
            </TableCell>
            <TableCell style={{ width: '140px' }}>{row?.deadline}</TableCell>
            <TableCell style={{ width: '140px' }}>{row?.owner}</TableCell>
            <TableCell style={{ width: '130px' }}>{row?.status}</TableCell>
            <TableCell style={{ width: '390px' }}>{row?.description}</TableCell>
          </TableRow>
        )}
      />
    </Box>
  );
}
