import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import Header from '@components/Calendar/Header';
import { calendarUrls } from '@config/routes';
import ErrorScreen from '@components/ErrorScreen';
import Loader from '@components/Loader';
import CalendarDnD from '@components/Calendar/CalendarDnD';
import { createRecord, updateRecord } from '@config/functions/requests';
import { useParams } from 'react-router-dom';
import {
  onConfirmDelete,
  onCreateEventClick,
  onEventDrop,
  onEventResize,
  getRange,
} from '@config/functions/calendarFunctions';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import ViewEventModal from '@components/Calendar/ViewEventModal';
import CreateEventModal from '@components/Calendar/CreateEventModal';

const { calendarAccountsUrls, calendarsUrls, eventsUrls, syncUrls } =
  calendarUrls;

const CalendarAccountEventList = ({ labels, editMode }) => {
  const [notify] = useToast();
  const { id: calendarAccountId } = useParams();
  const user = useSelector(selectProfile);
  const [selectedCalendarAccount, setSelectedCalendarAccount] =
    React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [rangeDebounced, setRangeDebounced] = React.useState(getRange());
  const [range, setRange] = React.useState(getRange());
  const [modalData, setModalData] = React.useState(null);
  const [showCreateEventModal, setShowCreateEventModal] = React.useState(false);
  const [showViewEventModal, setShowViewEventModal] = React.useState(false);
  const [disableDefaultButton, setDisableDefaultButton] = React.useState(false);

  const { data: calendarAccountData, isError } = useRQuery({
    key: [
      'calendar-account-detail-data',
      calendarAccountId,
      user.token,
      user?.actAs,
    ],
    url: calendarAccountsUrls.detail(calendarAccountId),
    config: getAuthHeader(user.token, user?.actAs, user.encryptionToken),
    options: { enabled: !!user.token },
  });

  const { data: eventsList, refetch: refetchEvents } = useRQuery({
    key: [
      'calendar-events',
      selectedCalendarAccount,
      range,
      user.token,
      user?.actAs,
    ],
    url: eventsUrls.list(
      `?calendar_account=${
        selectedCalendarAccount && selectedCalendarAccount.id
      }&from=${range[0]}&to=${range[1]}`
    ),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user.token && !!selectedCalendarAccount) },
  });

  const getParams = (args) => ({
    ...args,
    notify,
    setEvents,
    userToken: user.token,
    refetchEvents,
  });

  const handleSync = async (calendarAccountId) => {
    try {
      await createRecord({
        values: {},
        url: syncUrls.list(`?calendar_account=${calendarAccountId}`),
        token: user.token,
        actAs: user?.actAs,
      });
      notify(`Syncing Started In Background.`, {
        type: 'SUCCESS',
      });
    } catch (err) {
      console.log(err);
      notify(`There was an error, please try again.`, {
        type: 'ERROR',
      });
    }
  };

  const setCalendarAsDefault = async (calendarId, _default) => {
    try {
      await updateRecord({
        values: {
          default: _default,
        },
        url: calendarsUrls.detail(calendarId),
        token: user.token,
        actAs: user?.actAs,
      });
      notify(`This calendar is now default calendar.`, {
        type: 'SUCCESS',
      });
    } catch (err) {
      console.log(err);
      notify(`There was an error, please try again.`, {
        type: 'ERROR',
      });
    }
  };

  /**
   * Set Calendar Detail
   */
  React.useEffect(() => {
    if (!calendarAccountData) return;

    setSelectedCalendarAccount(calendarAccountData);
  }, [calendarAccountData]);

  React.useEffect(() => {
    if (!eventsList) return;

    const events = eventsList.map((r) => ({
      ...r,
      start: new Date(r.start_date_time),
      end: new Date(r.end_date_time),
      background_color: r.details.booked
        ? 'green'
        : r.details.calendar.background_color,
      foreground_color: r.details.booked
        ? 'white'
        : r.details.calendar.foreground_color,
    }));

    setEvents([...events]);
  }, [eventsList]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRange(rangeDebounced);
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [rangeDebounced]);

  if (isError) {
    return <ErrorScreen error="Something went wrong, try reloading" />;
  }

  if (!selectedCalendarAccount) {
    return (
      <Box sx={{ py: 16 }}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box>
      <Header
        selectedCalendarAccount={selectedCalendarAccount}
        handleSync={handleSync}
        disableDefaultButton={disableDefaultButton}
        setDisableDefaultButton={setDisableDefaultButton}
        setCalendarAsDefault={setCalendarAsDefault}
      />

      <CalendarDnD
        events={events}
        onEventDrop={(args) => onEventDrop(getParams(args))}
        onEventResize={(args) => onEventResize(getParams(args))}
        onSelectEvent={(args) => {
          setModalData(args);
          setShowViewEventModal(true);
        }}
        onSelectSlot={({ start, end }) => {
          setModalData({
            start: start,
            end: end,
            email: user.details ? user.details.email : null,
          });
          setShowCreateEventModal(true);
        }}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: !event.background_color
              ? '#2336f1'
              : event.background_color,
            foregroundColor: !event.foreground_color
              ? '#000000'
              : event.foreground_color,
          },
        })}
        onRangeChange={(newRange) => {
          const startDate =
            Array.isArray(newRange) && newRange.length
              ? newRange[0]
              : newRange.start;
          const endDate =
            Array.isArray(newRange) && newRange.length
              ? newRange[0]
              : newRange.end;
          startDate.setMonth(startDate.getMonth() - 1);
          const range = [
            `${startDate.getFullYear()}-${
              startDate.getMonth() + 1
            }-${startDate.getDate()}`,
            `${endDate.getFullYear()}-${
              Array.isArray(newRange) && newRange.length
                ? endDate.getMonth() + 2
                : endDate.getMonth() + 1
            }-${new Date(
              endDate.getFullYear(),
              Array.isArray(newRange) && newRange.length
                ? endDate.getMonth() + 2
                : endDate.getMonth() + 1,
              0
            ).getDate()}`,
          ];
          setRangeDebounced(range);
        }}
      />

      <CreateEventModal
        open={showCreateEventModal}
        setOpen={setShowCreateEventModal}
        user={user}
        calendarAccount={selectedCalendarAccount}
        onCreateEventClick={(values, guests) =>
          onCreateEventClick({
            values,
            guests,
            user,
            setShowCreateEventModal,
            refetchEvents,
            notify,
          })
        }
        data={modalData}
        prefix="0po"
        labels={labels}
        editMode={editMode}
      />

      <ViewEventModal
        open={showViewEventModal}
        setOpen={setShowViewEventModal}
        onConfirmDelete={() =>
          onConfirmDelete({
            modalData,
            userToken: user.token,
            setShowViewEventModal,
            refetchEvents,
            notify,
          })
        }
        event={modalData}
        prefix="0h3"
        labels={labels}
        editMode={editMode}
      />
    </Box>
  );
};

export default CalendarAccountEventList;
