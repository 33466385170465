import { FormControl } from '@mui/material';
import * as React from 'react';
import FormHelperText from '@mui/material/FormHelperText';

export default function FileField({ error, value, color, ...rest }) {
  const [hide, setHide] = React.useState(false);

  React.useEffect(() => {
    setHide(true);

    let timeout = setTimeout(() => {
      setHide(false);
    }, 100);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <>
      <FormControl error={error}>
        {hide ? null : (
          <input
            type="file"
            {...rest}
            value={value}
            style={{ display: 'block', color: color }}
          />
        )}

        {error ? (
          <FormHelperText sx={{ mt: 1, color: 'red', fontWeight: '500' }}>
            *Please upload your CV
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
}
