import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { lmsUrls } from '@config/routes';
import { courseModuleMeta } from '@config/meta/lms';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { moduleLessonHandleRow } from '@config/handleRows/lms';
import { ModuleLessonForm } from '@config/forms/lms';

const { courseModulesUrls, moduleLessonsUrls } = lmsUrls;

export default function CourseModuleDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [moduleLessonsListData, setModuleLessonsListData] = React.useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => courseModuleMeta({ data, queryClient }),
    []
  );

  const moduleLessonColOptions = React.useMemo(() => {
    return {
      queryClient,
      nextOrder: moduleLessonsListData?.totalCount ?? 1,
      wikiChapterId: recordData?.wikiChapterId,
    };
  }, [
    queryClient,
    moduleLessonsListData?.totalCount,
    recordData?.wikiChapterId,
  ]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/courses/', text: 'Courses' },
              {
                to: `/courses/${recordData?.courseId}`,
                text: `${recordData?.course?.name}`,
              },
              { text: `${recordData?.module?.name}` },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={courseModulesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Module Lesson'
          prefix='0ig'
          columnKey='moduleLesson'
          columnOptions={moduleLessonColOptions}
          urls={moduleLessonsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              ModuleLessonForm({
                setFieldValue,
                wikiChapterId: recordData?.wikiChapterId,
                wikiChapterName: recordData?.wikiChapterId?.name,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { lessonId, wikiSectionId, ...rest } = values;

                await createRecord({
                  values: {
                    ...rest,
                    moduleId: recordData?.moduleId,
                    wikiSectionId: wikiSectionId?.id,
                    lessonId: lessonId?.id,
                  },
                  url: moduleLessonsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                refetch();
                resetForm();
                setAddNewForm(false);
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={moduleLessonHandleRow}
          urlParams={`&moduleId=${recordData?.moduleId}`}
          customSortColumnName='sequence'
          sortColumnBy='asc'
          getListData={(data) => setModuleLessonsListData(data)}
        />
      </Box>
    </Box>
  );
}
