import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { FieldArray } from 'formik';
import EmailTemplateAccordion from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateAccordion';
import EmailTemplateFormCard from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateFormCard';
import {
  hasKeyIncludingSubstring,
  getFormFields,
} from '@config/functions/emailTemplateConfigurationPopupFunctions';

export default function EmailTemplateFormList({
  shape,
  shapeAction,
  values,
  setValues,
  errors,
  setFieldValue,
  workflowId,
  workflowName,
  showAddReminder,
  setShowAddReminder,
  user,
  isKanbanView,
}) {
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const deleteForm = (idx) => {
    const keysThatNeedsToBeModified = [];
    const keysThatNeedsToBeDeleted = [];
    const beforeHandForms = values.forms.slice(0, idx);
    const oldFields = getFormFields(idx);

    Object.entries(oldFields).map(([key, value]) => {
      keysThatNeedsToBeDeleted.push(value);
    });

    const newForms = [
      ...beforeHandForms,
      ...values.forms.slice(idx + 1).map((form, fidx) => {
        const oldFields = getFormFields(idx + fidx);
        const fields = getFormFields(idx + fidx + 1);
        const response = {
          payload_section_key_reference: form.payload_section_key_reference,
        };

        Object.entries(fields).map(([key, value]) => {
          response[oldFields[key]] = form[value];
          keysThatNeedsToBeModified.push([oldFields[key], values[value]]);
        });

        return response;
      }),
    ];

    const lastFields = getFormFields(newForms.length);

    Object.entries(lastFields).map(([key, value]) => {
      keysThatNeedsToBeDeleted.push(value);
    });

    const newValues = { ...values, forms: newForms };

    keysThatNeedsToBeDeleted.map((key) => {
      delete newValues[key];
    });

    keysThatNeedsToBeModified.map(([key, value]) => {
      newValues[key] = value;
    });

    setValues(newValues);
  };

  return (
    <Box>
      <EmailTemplateAccordion
        open={open}
        setOpen={handleToggle}
        label="Forms"
        isError={hasKeyIncludingSubstring(errors, 'forms')}
      >
        <FieldArray name="forms">
          {({ push }) => (
            <Stack spacing={2}>
              {isKanbanView ? null : (
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      const fields = getFormFields(values?.forms.length);
                      const uniqueKey = uuidv4();

                      push({
                        [fields.form]: {
                          label: '',
                          static_value: '',
                          mapping_field_name: null,
                        },
                        [fields.person]: null,
                        [fields.recipient_first_name]: null,
                        [fields.recipient_last_name]: null,
                        [fields.recipient_email]: null,
                        payload_section_key_reference: `payload_section_${uniqueKey}`,
                      });
                    }}
                  >
                    Add New
                  </Button>
                </Box>
              )}

              <Box>
                {values?.forms?.map((form, index) => (
                  <EmailTemplateFormCard
                    shape={shape}
                    shapeAction={shapeAction}
                    key={index}
                    index={index}
                    remove={deleteForm}
                    form={form}
                    setFieldValue={setFieldValue}
                    workflowId={workflowId}
                    workflowName={workflowName}
                    showAddReminder={showAddReminder}
                    setShowAddReminder={setShowAddReminder}
                    values={values}
                    user={user}
                    errors={errors}
                    isKanbanView={isKanbanView}
                  />
                ))}
              </Box>
            </Stack>
          )}
        </FieldArray>
      </EmailTemplateAccordion>
    </Box>
  );
}
