import { nodeDriveHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  folders: 'folders',
  folderDownload: 'folders/download',
  files: 'files',
  fileDownload: 'files/download',
  filesAndFolders: 'files-and-folders',
};

const foldersUrls = getModelUrls(nodeDriveHost, models.folders, false, 'v1');
const folderDownloadUrls = getModelUrls(
  nodeDriveHost,
  models.folderDownload,
  false,
  'v1'
);
const filesUrls = getModelUrls(nodeDriveHost, models.files, false, 'v1');
const fileDownloadUrls = getModelUrls(
  nodeDriveHost,
  models.fileDownload,
  false,
  'v1'
);
const filesAndFoldersUrls = getModelUrls(
  nodeDriveHost,
  models.filesAndFolders,
  false,
  'v1'
);

const urls = {
  foldersUrls,
  filesUrls,
  fileDownloadUrls,
  filesAndFoldersUrls,
  folderDownloadUrls,
};

export default urls;
