import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { marketingUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { CampaignForm } from '@config/forms/marketing';
import { campaignRowsMap } from '@config/handleRows/marketing';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { smmCampaignsUrls } = marketingUrls;

export default function Campaigns({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label='Campaigns'
        prefix='0xt'
        labels={labels}
        editMode={editMode}
        microservice={microservices.Marketing.name}
        model={microservices.Marketing.models.smmCampaign}
        urls={smmCampaignsUrls}
        columnKey='campaignsMarketing'
        addNewForm={{
          getFields: () => CampaignForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values: {
                  ...values,
                  everyone_in_object_company_can_see_it: false,
                },
                url: smmCampaignsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              refetch();
              resetForm();
              setAddNewForm(false);
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={campaignRowsMap}
      />
    </Box>
  );
}
