import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { inventoryUrls } from '@config/routes';
import DetailCard from '@components/core/DetailCard';
import { supplierMeta } from '@config/meta/inventory';
import DetailPageHeader from '@components/DetailPageHeader';
import { useQueryClient } from 'react-query';


const { suppliersUrls } = inventoryUrls;

export default function SupplierDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const queryClient = useQueryClient();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => supplierMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key='detail-view-header'
            items={[
              { to: `/suppliers`, text: 'Suppliers' },
              {
                text:
                  recordData &&
                  recordData.supplier_company &&
                  recordData.details &&
                  recordData.details.supplier_company
                    ? recordData.details.supplier_company.name
                    : null,
              },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={suppliersUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
    </Box>
  );
}
