import React from 'react';
import * as Yup from 'yup';
import { wikiUrls } from '@config/routes';
import { Grid, Typography } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { sectionsMeta } from '@config/meta/wiki';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import ChapterDropdown from '@dropdown-forms/wiki/ChapterDropdown';
import { useQueryClient } from 'react-query';


const { sectionsUrls, chaptersUrls } = wikiUrls;

export default function SectionDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title='Forms'
        initialValues={{
            ...getAddFormInitialValues(sectionsMeta({}), 'listView'),
          }}
          validationSchema={Yup.object({
            ...getSchemaFromColumnsForForm(sectionsMeta({}), 'listView'),
          })}
        getPostUrl={() => sectionsUrls.list()}
        getValues={(values) => ({
          ...values,
          chapter: values?.chapter?.id,
        })}

        getFields={({ setFieldValue }) => {
            const handleHtml = (e) => {
                if (e.editor.getData() === '') {
                  setFieldValue('ad_text', '<p></p>');
                  return;
                }
                setFieldValue('html', e.editor.getData());
              };
        return(
          <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField key='order' label='Order' name='order' required />
            </Grid>
            <Grid item xs={6}>
                <TextField key='title' label='Title' name='title' required />
            </Grid>
            <Grid item xs={6}>
                <TextField key='description' label='Description' name='description' />
            </Grid>
            <Grid item xs={6}>
            <ChapterDropdown
                refetch={({ id, name }) => {
                  setFieldValue('chapter', { id, label: name });
                  queryClient.invalidateQueries(['book-chapter']);
                }}
              >
                <AutocompleteFieldV2
                  name='chapter'
                  label='Chapter'
                  requestKey='book-chapter'
                  fetchUrl={chaptersUrls.list}
                />
              </ChapterDropdown>
            </Grid>
            <Grid item xs={12}>
                <Typography
                variant='h6'
                sx={{
                    fontWeight: '500',
                    fontSize: '16px',
                    color: 'rgba(0, 0, 0, 0.87)',
                }}
                gutterBottom
                >
                Content
                </Typography>
                <WysiwygEditor initData={'<p></p>'} onChange={handleHtml} />
            </Grid>
          </Grid>
        )}}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
