import React from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import { FreeSoloAutocompleteField } from '@components/Inputs';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

export default function ShortLinkFields({
  values,
  shortLinkIdx,
  setFieldValue,
  handleIna,
}) {
  const fields = [
    {
      label: 'Name',
      name: `short_links-${shortLinkIdx}-name`,
    },
    {
      label: 'Destination Link',
      name: `short_links-${shortLinkIdx}-destination_url`,
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex' }}>
              <IconButton
                aria-label="add"
                color="primary"
                sx={{ pl: '1px' }}
                onClick={() => {
                  const newShortLinks = [...values.short_links];
                  newShortLinks[shortLinkIdx][field?.name] = {
                    label: values?.shortLinkPayloadSectionKey?.fullLabel,
                    static_value: '',
                    mapping_field_name: values?.shortLinkPayloadSectionKey?.key,
                  };
                  setFieldValue('short_links', newShortLinks);
                }}
                disabled={!values?.shortLinkPayloadSectionKey}
              >
                <SubdirectoryArrowRightIcon fontSize="inherit" />
              </IconButton>
              <Box sx={{ width: '100%' }}>
                <FreeSoloAutocompleteField
                  {...field}
                  customValue={
                    values?.short_links[shortLinkIdx]?.[field?.name]
                      ? [values?.short_links[shortLinkIdx]?.[field?.name]]
                      : []
                  }
                  label={field?.label}
                  options={[]}
                  onChange={({ newValue: newValues }) => {
                    if (!newValues) return;
                    const newValue = newValues[newValues.length - 1];
                    const newShortLinks = [...values.short_links];

                    newShortLinks[shortLinkIdx][field?.name] = newValue
                      ? {
                          label: newValue,
                          static_value: newValue,
                          mapping_field_name: null,
                        }
                      : null;
                    setFieldValue('short_links', newShortLinks);
                  }}
                />
              </Box>
              <IconButton
                aria-label="add-ina"
                color="primary"
                sx={{ pr: '1px' }}
                onClick={() => handleIna(field?.label)}
              >
                <PlaylistAddIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
