import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { formsUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { formRowsMap } from '@config/handleRows/forms';
import { FormsForm } from '@config/forms/forms';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { microservices } from '@config/constants';

const { formsListUrls, cloneSystemTemplatesUrls } = formsUrls;

export default function Forms({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  return (
    <Box>
      <ListView
        nodeAPI
        label='Forms'
        prefix='051'
        microservice={microservices.Forms.name}
        model={microservices.Forms.models.form}
        labels={labels}
        editMode={editMode}
        urls={formsListUrls}
        columnKey='forms'
        updateRecordCB={({ values }) => {
          return {
            ...values,
            anonymous_can_see_it: values.isPublished,
          };
        }}
        addNewForm={{
          getFields: () =>
            FormsForm({
              microservice: microservices.Forms.name,
              model: microservices.Forms.models.form,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const doFinally = () => {
                resetForm();
                setAddNewForm(false);
                refetch();
              };

              const {
                anonymous_can_see_it,
                isPublished,
                template,
                ...restValues
                // prettier :p
              } = values;

              if (template) {
                const payload = {
                  template_id: template?.id,
                  custom_params: {
                    ...restValues,
                    isPublished,
                    anonymous_can_see_it: isPublished || anonymous_can_see_it,
                  },
                };

                const response = await createRecord({
                  values: payload,
                  url: cloneSystemTemplatesUrls.detail(
                    microservices.Forms.models.form
                  ),
                  token: user.token,
                  actAs: user?.actAs,
                });

                doFinally();

                return response?.data?.id;
              } else {
                const response = await createRecord({
                  values: {
                    ...restValues,
                    isPublished,
                    anonymous_can_see_it: isPublished || anonymous_can_see_it,
                  },
                  url: formsListUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                doFinally();
                return response?.data?.id;
              }
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={formRowsMap}
      />
    </Box>
  );
}
