import React from 'react';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import { lmsUrls, nodeDriveUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { courseHandleRow } from '@config/handleRows/lms';
import { CourseForm } from '@config/forms/lms';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { coursesUrls } = lmsUrls;
const { filesUrls } = nodeDriveUrls;

export default function Courses({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const courseColOptions = React.useMemo(() => {
    return {
      queryClient,
    };
  }, [queryClient]);

  return (
    <Box>
      <ListView
        nodeAPI
        label='Courses'
        prefix='094'
        labels={labels}
        editMode={editMode}
        urls={coursesUrls}
        columnKey='course'
        columnOptions={courseColOptions}
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            CourseForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError,
            setFieldError
          ) => {
            try {
              const { homeworkId, thumbnail, wikiBookId, categoryId, ...rest } =
                values;

              if (!thumbnail) {
                setFieldError('thumbnail', 'Please select a thumbnail');
                return;
              }

              if (!wikiBookId) {
                setFieldError('wikiBookId', 'Please select a wikibook');
                return;
              }

              let fileUrl;

              try {
                const formData = new FormData();
                formData.append(
                  'client',
                  user?.actAs?.id || user?.details?.client
                );
                formData.append('created_by', user?.details?.id);
                formData.append('anonymous_can_see_it', true);
                formData.append('file', thumbnail);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                fileUrl = fileData.fileUrl;
              } catch {}

              if (!fileUrl) {
                setFieldError('thumbnail', 'Failed to upload thumbnail');
                return;
              }

              const { data } = await createRecord({
                values: {
                  ...rest,
                  thumbnail: fileUrl,
                  homeworkId: homeworkId?.id,
                  wikiBookId: wikiBookId?.id,
                  categoryId: categoryId?.id,
                },
                url: coursesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={courseHandleRow}
      />
    </Box>
  );
}
