import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { computeUrls } from '@config/routes';
import { formFlowStepsMeta } from '@config/meta/compute';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { formFieldLayoutsRowsMap } from '@config/handleRows/compute';
import { FormFieldLayoutStepsForm } from '@config/forms/compute';
import { microservices } from '@config/constants';

const { formFlowStepsUrls, formFlowFieldLayoutsUrls } = computeUrls;

export default function FormFlowStepDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => formFlowStepsMeta({ data }),
    []
  );

  const fieldLayoutOptions = React.useMemo(
    () => ({
      modelId: recordData?.formDefn?.modelId,
    }),
    [recordData]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/form-flows/${recordData?.formDefnId}`,
                text: 'Form Flow Steps',
              },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={formFlowStepsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Step Fields'
          prefix='Beo'
          microservice={microservices.Compute.name}
          model={microservices.Compute.models.fieldLayoutDefn}
          importExportFields={`?stepDefnId=${recordId}`}
          columnKey='formFieldLayoutsCompute'
          columnOptions={fieldLayoutOptions}
          urls={formFlowFieldLayoutsUrls}
          addNewForm={{
            getFields: () => FormFieldLayoutStepsForm(fieldLayoutOptions),
            handleSubmit: async (
              { fieldDefnId, ...values },
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                if (!fieldDefnId?.id) {
                  setFieldError('fieldDefnId', 'Please select a field');
                  return;
                }

                await createRecord({
                  values: {
                    ...values,
                    fieldDefnId: fieldDefnId?.id,
                    stepDefnId: recordId,
                  },
                  url: formFlowFieldLayoutsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={formFieldLayoutsRowsMap}
          customSortColumnName='order'
          sortColumnBy='asc'
          urlParams={`&stepDefnId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
