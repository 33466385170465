import React from 'react';
import * as Yup from 'yup';
import { pmUrls, accountUrls } from '@config/routes';
import { resourceMeta } from '@config/meta/pm/pmMeta';
import { Grid } from '@mui/material';
import useAutocomplete from '@hooks/useAutocomplete';
import { TextField, AutocompleteField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { resourcesUrls } = pmUrls;
const { usersUrls } = accountUrls;

export default function ResourcesDropdown({ title, children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const [userTerm, setUserTerm] = React.useState('');

  const { data: user, isFetching: fetchingUser } = useAutocomplete({
    key: 'user',
    getUrl: usersUrls.list,
    inputValue: userTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.email,
      };
    },
  });

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title={title || 'Tested By'}
        initialValues={{
          ...getAddFormInitialValues(resourceMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(resourceMeta(), 'listView'),
        })}
        getPostUrl={() => resourcesUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key="name" label="Name" name="name" required />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                multiline
                key="email"
                label="Email"
                name="email"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField multiline key="mobile" label="Mobile" name="mobile" />
            </Grid>
            <Grid item xs={6}>
              <TextField multiline key="cost" label="Cost" name="cost" />
            </Grid>

            <Grid item xs={6}>
              {/* <UserDropdown hideParent={setHideUser} refetch={refetchUser}> */}
              <AutocompleteField
                label="Users"
                options={user}
                name="user"
                isLoading={fetchingUser}
                required={true}
                setSearchTerm={(v) => setUserTerm(v)}
              />
              {/* </UserDropdown> */}
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
