import React from 'react';
import { useField, useFormikContext } from 'formik';

export default function FileInput({
  label,
  color,
  required,
  accept,
  ...props
}) {
  const [field, meta] = useField(props);
  const [value, setValue] = React.useState('');
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    setFieldValue(
      field.name,
      e.target.files && e.target.files.length ? e.target.files[0] : null
    );
    setValue(e.target.value);
  };

  return (
    <>
      <label htmlFor={props.id || props.name} style={{ color }}>
        {label} {required ? <span style={{ color: 'red' }}>*</span> : null}
      </label>
      <input
        type='file'
        {...props}
        value={value}
        onChange={handleChange}
        style={{ color }}
        accept={accept}
      />
      {meta.touched || meta.error ? (
        <div style={{ marginTop: '0.5rem', fontSize: '0.85rem', color: 'red' }}>
          {meta.error}
        </div>
      ) : null}
    </>
  );
}
