import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Companies from '@pages/CRM/Companies';
import CompanyDetail from '@pages/CRM/Companies/detail';
import Persons from '@pages/CRM/Persons';
import PersonDetail from '@pages/CRM/Persons/detail';
import SocialMediaTypes from '@pages/CRM/SocialMediaTypes';
import Clients from '@pages/CRM/Clients';
import ClientDetail from '@pages/CRM/Clients/detail';
import Pipelines from '@pages/CRM/Pipelines';
import PipelineDetail from '@pages/CRM/Pipelines/detail';
import Opportunities from '@pages/CRM/Opportunities';
import OpportunityDetail from '@pages/CRM/Opportunities/detail';
import PersonHistories from '@pages/CRM/PersonHistories';
import CompanyHistories from '@pages/CRM/CompanyHistories';
import Territories from '@pages/CRM/Territories';
import TerritoryDetail from '@pages/CRM/Territories/detail';
import SalesPeople from '@pages/CRM/SalesPeople';
import SalesPeopleTargets from '@pages/CRM/SalesPeopleTargets';
import CompanySpins from '@pages/CRM/CompanySpins';
import CompanySpinDetail from '@pages/CRM/CompanySpins/detail';
import ActivityNotes from '@pages/CRM/ActivityNotes';
import ActivityKinds from '@pages/CRM/ActivityKinds';
import ActivityTypes from '@pages/CRM/ActivityTypes';
import CrmImportErrorLogDetail from '@pages/CRM/CrmImportErrorLogs/detail';
import CrmImportLogs from '@pages/CRM/CrmImportLogs';
import CrmDashboard from '@pages/CRM/CrmDashboard';
import Actions from '@pages/CRM/Actions';
import Reminders from '@pages/CRM/Reminders';
import ReminderDetail from '@pages/CRM/Reminders/detail';
import MarketingLists from '@pages/CRM/MarketingLists';
import MarketingListDetail from '@pages/CRM/MarketingLists/detail';
import CallLists from '@pages/CRM/CallLists';
import CallListDetail from '@pages/CRM/CallLists/detail';
import CallSchedulesDetail from '@pages/CRM/CallSchedules/detail';
import CallListPipelines from '@pages/CRM/CallListPipelines';
import CallListPipelineDetail from '@pages/CRM/CallListPipelines/detail';
import Channels from '@pages/CRM/Channels';
import OpportunityHistoryDetail from '@pages/CRM/OpportunitiesHistory/detail';
import INADetail from '@pages/CRM/INA/detail';
import CustomerEnquiryStatuses from '@pages/CRM/CustomerEnquiryStatus';
import CustomerEnquiryPurposes from '@pages/CRM/CustomerEnquiryPurpose';
import CustomerEnquiries from '@pages/CRM/CustomerEnquiry';
import CustomerEnquiryDetail from '@pages/CRM/CustomerEnquiry/detail';
import PersonRelationships from '@pages/CRM/PersonRelationships';
import PersonRelationshipDetail from '@pages/CRM/PersonRelationships/detail';

export default function () {
  return (
    <>
      <Route path='/companies' element={<WithLayout comp={Companies} />} />
      <Route
        path='/companies/:id'
        element={<WithLayout comp={CompanyDetail} />}
      />
      <Route path='/persons' element={<WithLayout comp={Persons} />} />
      <Route path='/persons/:id' element={<WithLayout comp={PersonDetail} />} />
      <Route
        path='/social-media-types'
        element={<WithLayout comp={SocialMediaTypes} />}
      />
      <Route path='/clients' element={<WithLayout comp={Clients} />} />
      <Route path='/clients/:id' element={<WithLayout comp={ClientDetail} />} />
      <Route path='/pipelines' element={<WithLayout comp={Pipelines} />} />
      <Route
        path='/pipelines/:id'
        element={<WithLayout comp={PipelineDetail} />}
      />
      <Route
        path='/opportunities'
        element={<WithLayout comp={Opportunities} />}
      />
      <Route
        path='/opportunities/:id'
        element={<WithLayout comp={OpportunityDetail} />}
      />
      <Route path='/territories' element={<WithLayout comp={Territories} />} />
      <Route
        path='/territories/:id'
        element={<WithLayout comp={TerritoryDetail} />}
      />
      <Route path='/actions' element={<WithLayout comp={Actions} />} />
      <Route path='/sales-people' element={<WithLayout comp={SalesPeople} />} />
      <Route
        path='/sales-people-targets'
        element={<WithLayout comp={SalesPeopleTargets} />}
      />
      <Route
        path='/company-spins'
        element={<WithLayout comp={CompanySpins} />}
      />
      <Route
        path='/company-spins/:id'
        element={<WithLayout comp={CompanySpinDetail} />}
      />
      <Route
        path='/activity-notes'
        element={<WithLayout comp={ActivityNotes} />}
      />
      <Route
        path='/activity-kinds'
        element={<WithLayout comp={ActivityKinds} />}
      />
      <Route
        path='/activity-types'
        element={<WithLayout comp={ActivityTypes} />}
      />
      <Route
        path='/person-history'
        element={<WithLayout comp={PersonHistories} />}
      />
      <Route
        path='/company-history'
        element={<WithLayout comp={CompanyHistories} />}
      />
      <Route
        path='/crm-import-error-logs/:id'
        element={<WithLayout comp={CrmImportErrorLogDetail} />}
      />
      <Route
        path='/crm-import-logs'
        element={<WithLayout comp={CrmImportLogs} />}
      />

      <Route
        path='/crm-dashboard'
        element={<WithLayout comp={CrmDashboard} />}
      />

      <Route path='/reminders' element={<WithLayout comp={Reminders} />} />
      <Route
        path='/reminders/:id'
        element={<WithLayout comp={ReminderDetail} />}
      />

      <Route
        path='/marketing-lists'
        element={<WithLayout comp={MarketingLists} />}
      />
      <Route
        path='/marketing-lists/:id'
        element={<WithLayout comp={MarketingListDetail} />}
      />

      <Route path='/call-lists' element={<WithLayout comp={CallLists} />} />

      <Route
        path='/call-lists/:id'
        element={<WithLayout comp={CallListDetail} />}
      />

      <Route
        path='/call-schedules/:id'
        element={<WithLayout comp={CallSchedulesDetail} />}
      />

      <Route
        path='/call-list-pipelines'
        element={<WithLayout comp={CallListPipelines} />}
      />
      <Route
        path='/call-list-pipelines/:id'
        element={<WithLayout comp={CallListPipelineDetail} />}
      />

      <Route path='/channels' element={<WithLayout comp={Channels} />} />
      <Route
        path='/opportunity-histories/:id'
        element={<WithLayout comp={OpportunityHistoryDetail} />}
      />
      <Route path='/inas/:id' element={<WithLayout comp={INADetail} />} />
      <Route
        path='/customer-enquiries-statuses'
        element={<WithLayout comp={CustomerEnquiryStatuses} />}
      />
      <Route
        path='/customer-enquiries-purposes'
        element={<WithLayout comp={CustomerEnquiryPurposes} />}
      />
      <Route
        path='/customer-enquiries'
        element={<WithLayout comp={CustomerEnquiries} />}
      />
      <Route
        path='/customer-enquiries/:id'
        element={<WithLayout comp={CustomerEnquiryDetail} />}
      />
      <Route
        path='/person-relationships'
        element={<WithLayout comp={PersonRelationships} />}
      />
      <Route
        path='/person-relationships/:id'
        element={<WithLayout comp={PersonRelationshipDetail} />}
      />
    </>
  );
}
