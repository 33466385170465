import React from 'react';
import { Box, Menu, MenuItem, Button, IconButton, Stack } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { selectBpaBoard, setWorkflows } from '@redux/bpaBoardSlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { selectProfile } from '@redux/profileSlice';
import { bpaUrls, hrUrls } from '@config/routes';
import {
  handleDragEnd,
  getAuthHeader,
  parseJSON,
  getLastSelectedDropdownOption,
  formatToUTCDate,
  addDays,
} from '@config/functions/helperFunctions';
// import DateTime from '@components/DateTime';
import useToast from '@hooks/useToast';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';
import { updateRecord } from '@config/functions/requests';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShowHideStagesPopup from '@components/BPA-V2/Popups/ShowHideStagesPopup';
// import { useSearchParams } from 'react-router-dom';
import { toggleStageVisibility } from '@config/functions/bpaFunctions';
import SearchField from '@ui/Inputs/SearchField';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import WorkFlowFilterPopup from '@components/WorkFlowFilterPopup';

const ITEM_HEIGHT = 48;

const { workflowsUrls, workflowStagesUrls } = bpaUrls;
const { vacanciesUrls } = hrUrls;

export default function BpaBoard({ labels, editMode }) {
  const [notify] = useToast();
  const dispatch = useDispatch();
  const user = useSelector(selectProfile);
  const { workflows: workflowsState } = useSelector(selectBpaBoard);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const initialWorkflow = getLastSelectedDropdownOption('lastSelectedWorkflow');
  const [selectedWorkflow, setSelectedWorkflow] =
    React.useState(initialWorkflow);
  const [selectedWorkflowDebounced, setSelectedWorkflowDebounced] =
    React.useState(selectedWorkflow);
  const [selectedVacancy, setSelectedVacancy] = React.useState(null);

  const [startDate, setStartDate] = React.useState(formatToUTCDate(new Date()));
  const [endDate, setEndDate] = React.useState(
    formatToUTCDate(addDays(new Date(), 1))
  );
  const [filter, setFilter] = React.useState(false);
  const [filterPopup, setFilterPopup] = React.useState(false);
  const [stageStates, setStageStates] = React.useState([]);
  const [hideShowStages, setHideShowStages] = React.useState(false);
  // const [searchParams] = useSearchParams();

  const open = Boolean(anchorEl);
  const [searchValue, setSearchValue] = React.useState('');

  const {
    data: workflowData,
    refetch: refetchWorkflowData,
    isFetching: workflowDataIsFetching,
  } = useRQuery({
    key: [
      'bpa-board-workflow-details',
      selectedWorkflow?.id,
      user?.token,
      user?.actAs,
    ],
    url: workflowsUrls.detail(selectedWorkflow?.id),
    options: {
      enabled: !!(user.token && selectedWorkflow?.id),
      refetchOnWindowFocus: true,
    },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data, isLoading } = useRQuery({
    key: [
      'bpa-board',
      selectedWorkflow?.id,
      user?.token,
      user?.actAs,
      searchValue,
      selectedVacancy,
      filter,
    ],
    url: workflowStagesUrls.list(
      `?workflow=${selectedWorkflow?.id}&order=order&page_size=200${
        selectedVacancy?.id ? `&vacancy=${selectedVacancy?.id}` : ''
      }${
        filter ? `&start_date=${startDate}&end_date=${endDate}` : ''
      }&search=${searchValue}&include_send_form_action=true`
    ),
    options: {
      enabled: !!(user.token && selectedWorkflow?.id),
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
    },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleSaveCardDrag = async () => {};

  const onPauseClick = async () => {
    try {
      await updateRecord({
        url: workflowsUrls.detail(selectedWorkflow?.id),
        values: {
          paused: !workflowData?.paused,
        },
        token: user.token,
        actAs: user?.actAs,
      });
      refetchWorkflowData();
      notify('Operation completed', {
        type: 'SUCCESS',
      });
    } catch (error) {
      notify('There was an error, please try again later.', {
        type: 'ERROR',
      });
    }
  };

  React.useEffect(() => {
    if (workflowDataIsFetching || !workflowData) return;

    let data = null;

    if (workflowData) {
      data = {
        ...workflowData,
        id: workflowData?.id,
        label: workflowData?.name,
        paused: workflowData?.paused,
      };
    }

    if (
      data?.id === selectedWorkflowDebounced?.id &&
      selectedWorkflowDebounced?.id !== selectedWorkflow?.id
    )
      return;

    setSelectedWorkflowDebounced(data);
    setSelectedWorkflow(data);
  }, [workflowData, workflowDataIsFetching]);

  React.useEffect(() => {
    if (initialWorkflow?.id && !selectedWorkflow?.id) return;
    localStorage.setItem(
      'lastSelectedWorkflow',
      JSON.stringify(selectedWorkflow)
    );
  }, [selectedWorkflow]);

  // React.useEffect(() => {
  //   const workflowId = searchParams.get('workflowId');
  //   const workflowName = searchParams.get('workflowName');

  //   if (!workflowId || !workflowName) return;
  //   setSelectedWorkflow({
  //     id: workflowId,
  //     label: workflowName,
  //   });
  // }, [searchParams]);

  React.useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const storedStates =
        parseJSON(localStorage.getItem('automataStageStates'))?.data || {};

      const getVisibility = (stage, storedStates) => {
        if (storedStates[stage.id] !== undefined) {
          return storedStates[stage.id];
        }
        return stage.stage_full_order && !stage.manual ? false : true;
      };

      const newStageStates = data.map((stage) => ({
        id: stage.id,
        visible: getVisibility(stage, storedStates),
      }));

      setStageStates(newStageStates);

      dispatch(
        setWorkflows(
          data.map((stage) => ({
            ...stage,
            child_stages: data
              .filter((childStage) =>
                childStage?.stage_full_order.startsWith(`${stage?.order}.`)
              )
              .map((childStage) => childStage?.id),
          }))
        )
      );
    }
  }, [data, dispatch]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Header
          title="Workflows"
          prefix="0yb"
          labels={labels}
          editMode={editMode}
          content={
            <>
              {selectedWorkflow ? (
                <Button
                  variant="outlined"
                  sx={{ ml: 2 }}
                  onClick={onPauseClick}
                >
                  {selectedWorkflow?.paused ? 'Resume' : 'Pause'}
                </Button>
              ) : null}
            </>
          }
          content2={
            <Box sx={{ ml: 'auto' }}>
              {selectedWorkflow ? (
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  size="small"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <MoreVertIcon fontSize="inherit" />
                </IconButton>
              ) : null}

              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >
                <MenuItem
                  size="small"
                  onClick={() => {
                    setHideShowStages(true);
                  }}
                >
                  Show/Hide Stages
                </MenuItem>
                <MenuItem
                  size="small"
                  onClick={() => {
                    const substages = workflowsState.flatMap(
                      ({ child_stages }) =>
                        child_stages.length
                          ? child_stages.map((id) => id.toString())
                          : []
                    );

                    toggleStageVisibility(substages, true, setStageStates);

                    setAnchorEl(null);
                  }}
                >
                  Expand All Substages
                </MenuItem>
                <MenuItem
                  size="small"
                  onClick={() => {
                    const substages = workflowsState.flatMap(
                      ({ child_stages }) =>
                        child_stages.length
                          ? child_stages.map((id) => id.toString())
                          : []
                    );

                    toggleStageVisibility(substages, false, setStageStates);

                    setAnchorEl(null);
                  }}
                >
                  Collapse All Substages
                </MenuItem>
                <MenuItem
                  size="small"
                  onClick={() => {
                    window.open(`/bpa/${selectedWorkflow?.id}`, '_blank');
                  }}
                >
                  Open Diagram
                </MenuItem>
              </Menu>

              <ShowHideStagesPopup
                open={hideShowStages}
                setOpen={setHideShowStages}
                stageStates={stageStates}
                workflowsState={workflowsState}
                setStageStates={setStageStates}
              />
            </Box>
          }
          filterOptions={[
            {
              label: 'Workflow',
              name: 'selectedWorkflow',
              requestKey: 'bpa-board-workflows',
              fetchUrl: workflowsUrls.list,
              urlParams: '&workflow_type=Automata',
              handleChange: (newValue) => {
                setSelectedWorkflow(newValue);
                setSelectedVacancy(null);
              },
              renderRow: (r) => ({
                ...r,
                id: r.id,
                label: r.name,
                paused: r.paused,
              }),
              orderingCol: 'name',
              customValue: selectedWorkflow,
            },
            {
              show:
                selectedWorkflow &&
                selectedWorkflow?.label.toLowerCase().includes('recruitment'),
              label: 'Select Vacancy',
              name: 'selectedVacancy',
              requestKey: 'bpa-board-vacancy',
              fetchUrl: vacanciesUrls.list,
              handleChange: (newValue) => {
                setSelectedVacancy(newValue);
              },
              renderRow: (r) => ({
                id: r.id,
                label: r.position_title,
              }),
              orderingCol: 'position_title',
              initialValue: null,
            },
          ]}
        />

        <Stack direction="row" spacing={2}>
          <SearchField setSearch={(term) => setSearchValue(term)} />
          <IconButton onClick={() => setFilterPopup(true)}>
            <FilterAltIcon
              sx={{ cursor: 'pointer' }}
              color={filter ? 'primary' : undefined}
            />
          </IconButton>
        </Stack>
        <WorkFlowFilterPopup
          open={filterPopup}
          setOpen={setFilterPopup}
          setFilter={setFilter}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
        />
      </Box>

      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'scroll',
            }}
          >
            <DragDropContext
              onDragEnd={(res) =>
                handleDragEnd(res, workflowsState, handleSaveCardDrag)
              }
            >
              <KanbanBoardList
                detailLink="bpa-board"
                isDraggable={false}
                data={workflowsState}
                labelCol="id"
                workflowId={selectedWorkflow?.id}
                stageStates={stageStates}
                setStageStates={setStageStates}
                labels={labels}
                editMode={editMode}
              />
            </DragDropContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}
