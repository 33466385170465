import { cmsHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  pages: 'pages',
  category: 'categories',
  pageCategory: 'page-categories',
  pageMeta: 'page-metas',
  content: 'contents',
  posts: 'posts',
  import: 'import',
  export: 'export',
  duplicatePage: 'duplicate-page',
};

const pagesUrls = getModelUrls(cmsHost, models.pages);
const postsUrls = getModelUrls(cmsHost, models.posts);
const pageMetaUrls = getModelUrls(cmsHost, models.pageMeta);
const categoryUrls = getModelUrls(cmsHost, models.category);
const pageCategoryUrls = getModelUrls(cmsHost, models.pageCategory);
const contentUrls = getModelUrls(cmsHost, models.content);
const importUrls = getModelUrls(cmsHost, models.import);
const exportUrls = getModelUrls(cmsHost, models.export);
const duplicatePageUrls = getModelUrls(cmsHost, models.duplicatePage);

const urls = {
  pagesUrls,
  postsUrls,
  categoryUrls,
  pageCategoryUrls,
  pageMetaUrls,
  contentUrls,
  importUrls,
  exportUrls,
  duplicatePageUrls,
};

export default urls;
