import * as Yup from 'yup';
import { fieldType } from '@config/constants';

export const profileRolesMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.LINK,
    getHref: (r) => `/user-roles/${r?.role}`,
    width: 300,
    editable: true,
    value: data?.details?.role?.name,
  },
  {
    field: 'add_roles',
    headerName: 'Add Roles',
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE,
    width: 180,
    hideColumn: true,
    initialValue: [],
    validate: Yup.array().min(1, "Roles is required!"),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];


export const rolesMeta = ({ isSuperUserPermission, data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    type: fieldType.LINK,
    to: '/user-roles/',
    width: 300,
    editable: isSuperUserPermission,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const microservicesMeta = ({ isSuperUserPermission, data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    type: fieldType.TEXT,
    width: 180,
    editable: isSuperUserPermission,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'domain_url',
    headerName: 'Domain Url',
    onForm: true,
    type: fieldType.TEXT,
    width: 300,
    editable: isSuperUserPermission,
    value: data && data.domain_url,
    initialValue: '',
    validate: Yup.string().url(),
  },
  {
    field: 'devDomainUrl',
    headerName: 'Sandbox Url',
    onForm: true,
    type: fieldType.TEXT,
    width: 300,
    editable: isSuperUserPermission,
    value: data && data.devDomainUrl,
    initialValue: '',
    validate: Yup.string().url(),
  },
  {
    field: 'stagingDomainUrl',
    headerName: 'Staging Url',
    onForm: true,
    type: fieldType.TEXT,
    width: 300,
    editable: isSuperUserPermission,
    value: data && data.stagingDomainUrl,
    initialValue: '',
    validate: Yup.string().url(),
  },
  {
    field: 'brand_color',
    headerName: 'Brand Color',
    onForm: true,
    type: fieldType.TEXT,
    width: 300,
    editable: isSuperUserPermission,
    value: data && data.brand_color,
    initialValue: '',
    validate: Yup.string().max(150),
    hideColumn: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const modelsMeta = () => [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    editable: false,
  },
  {
    field: 'microservice',
    headerName: 'Microservices',
    width: 300,
    editable: false,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const usersMeta = ({ data } = {}) => [
  {
    field: 'username',
    headerName: 'Username',
    to: '/users/',
    onForm: true,
    type: fieldType.TEXT,
    width: 180,
    editable: false,
    value: data && data.username,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'email',
    headerName: 'Email',
    onForm: true,
    type: fieldType.TEXT,
    width: 300,
    editable: false,
    value: data && data.email,
    initialValue: '',
    validate: Yup.string().email().required(),
  },
  {
    field: 'first_name',
    headerName: 'First Name',
    onForm: true,
    type: fieldType.TEXT,
    width: 180,
    editable: false,
    value: data && data.first_name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    onForm: true,
    type: fieldType.TEXT,
    width: 180,
    editable: false,
    value: data && data.last_name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'is_active',
    headerName: 'Is Active',
    type: fieldType.BOOLEAN,
    width: 180,
    editable: false,
    value: data && data.is_active,
  },
  {
    field: 'company_joined',
    headerName: 'Company Joined',
    type: fieldType.BOOLEAN,
    width: 180,
    editable: false,
    value: data && data.company_joined,
  },
  {
    field: 'email_confirmed',
    headerName: 'Email Confirmed',
    type: fieldType.BOOLEAN,
    width: 180,
    editable: false,
    value: data && data.email_confirmed,
  },
  {
    field: 'date_joined',
    headerName: 'Created At',
    width: 180,
    value: data && data.date_joined ? new Date(data.date_joined) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const rolesUserMeta = () => [
  {
    field: 'username',
    headerName: 'Username',
    type: fieldType.LINK,
    to: 'users/',
    width: 180,
    editable: false,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'email',
    headerName: 'Email',
    type: fieldType.TEXT,
    width: 300,
    editable: false,
    initialValue: '',
    validate: Yup.string().email().required(),
  },
  {
    field: 'first_name',
    headerName: 'First Name',
    type: fieldType.TEXT,
    width: 180,
    editable: false,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    type: fieldType.TEXT,
    width: 180,
    editable: false,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'member',
    type: fieldType.BOOLEAN,
    headerName: 'Member',
    width: 150,
    editable: true,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const rolesPermissionMeta = () => [
  {
    field: 'model',
    headerName: 'Microservice / Model',
    type: fieldType.TEXT,
    width: 300,
    editable: false,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'post',
    type: fieldType.BOOLEAN,
    headerName: 'Create',
    width: 100,
    editable: true,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'get',
    type: fieldType.BOOLEAN,
    headerName: 'Read',
    width: 100,
    editable: true,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'put',
    type: fieldType.BOOLEAN,
    headerName: 'Overwrite',
    width: 100,
    editable: true,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'patch',
    type: fieldType.BOOLEAN,
    headerName: 'Update',
    width: 100,
    editable: true,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'delete',
    type: fieldType.BOOLEAN,
    headerName: 'Delete',
    width: 100,
    editable: true,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'options',
    type: fieldType.BOOLEAN,
    headerName: 'Options',
    width: 100,
    editable: true,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const permissionsMeta = (data) => [
  {
    field: 'models',
    headerName: 'Microservice / Model',
    onForm: true,
    type: fieldType.TEXT,
    width: 300,
    editable: false,
    value: data
      ? data.model &&
      data.model.microservice &&
      `${data.model.microservice.name} / ${data.model.name}`
      : null,
    initialValue: [],
    validate: Yup.array().min(1, 'Select atleast 1 Model').required(),
  },
  {
    field: 'users',
    headerName: 'Users',
    onForm: true,
    type: fieldType.TEXT,
    width: 200,
    editable: false,
    value: data ? data.user && data.user.email : null,
    initialValue: [],
    validate: Yup.array().min(1, 'Select atleast 1 User').required(),
  },
  {
    field: 'roles',
    headerName: 'Roles',
    onForm: true,
    type: fieldType.TEXT,
    width: 200,
    editable: false,
    value: data ? data.role && data.role.name : null,
    initialValue: [],
    validate: Yup.array(),
  },
  {
    field: 'methods',
    headerName: 'Methods',
    onForm: true,
    type: fieldType.TEXT,
    width: 150,
    editable: false,
    value: data ? data.methods : null,
    initialValue: [],
    validate: Yup.array().min(1, 'Select atleast 1 Method').required(),
  },
  {
    field: 'view',
    headerName: 'Views',
    type: fieldType.TEXT,
    width: 150,
    editable: false,
    value: data ? data.view : null,
    validate: Yup.string().required(),
    onForm: false,
  },
  {
    field: 'for_anonymous',
    type: fieldType.BOOLEAN,
    headerName: 'Anonymous',
    width: 100,
    editable: false,
    value: data ? data.for_anonymous : null,
    validate: Yup.boolean().default(false),
    onForm: false,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const manageUsersMeta = (data) => [
  {
    field: 'username',
    headerName: 'Username',
    type: fieldType.LINK,
    to: 'users/',
    width: 180,
    editable: false,
    value: data && data.user.username,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'email',
    headerName: 'Email',
    type: fieldType.TEXT,
    width: 300,
    editable: false,
    value: data && data.user.email,
    initialValue: '',
    validate: Yup.string().email().required(),
  },
  {
    field: 'first_name',
    headerName: 'First Name',
    type: fieldType.TEXT,
    width: 120,
    editable: false,
    value: data && data.user.first_name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    type: fieldType.TEXT,
    width: 120,
    editable: false,
    value: data && data.user.last_name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'is_active',
    type: fieldType.BOOLEAN,
    headerName: 'Status',
    width: 120,
    value: data && data.is_active,
    editable: true,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'client_joined',
    type: fieldType.BOOLEAN,
    headerName: 'Company Joined',
    width: 120,
    value: data?.client_joined,
    editable: false,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'email_confirmed',
    type: fieldType.BOOLEAN,
    headerName: 'Email Confirmed',
    width: 120,
    value: data && data.email_confirmed,
    editable: false,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
