import React from 'react';
import { accountUrls, communicationUrls } from '@config/routes';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { selectProfile, setEncryptionToken } from '@redux/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PassphraseLoginModal from '@components/Communication/PassphraseLoginModal';
import { createRecord } from '@config/functions/requests';
import { encryptionPassKey } from '@config/constants';

const { verifyEncryptionAcctUrls } = accountUrls;
const { encryptionEnabledUrls } = communicationUrls;

const styles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export default function AuthWrapper({ children }) {
  const [action, setAction] = React.useState('loading');
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();

  const { data } = useRQuery({
    key: ['user-encryption-enabled', user.token, user?.actAs],
    url: encryptionEnabledUrls.list(),
    config: getAuthHeader(user.token, user?.actAs),
  });

  React.useEffect(() => {
    if (!data) return;

    const token =
      user.encryptionToken || localStorage.getItem(encryptionPassKey);

    const verify = async () => {
      try {
        await createRecord({
          values: { token },
          url: verifyEncryptionAcctUrls.list(),
          token: user.token,
          actAs: user?.actAs,
        });

        dispatch(setEncryptionToken(token));
        setAction('authenticated');
      } catch (err) {
        console.log('err', err.response || err.message);
        setAction('login');
      }
    };

    if (data.encryption_enabled) {
      if (token) {
        verify();
      } else {
        setAction('login');
      }
    } else {
      setAction('authenticated');
    }
  }, [data, dispatch, user]);

  if (action === 'login') {
    return (
      <Box>
        <PassphraseLoginModal open={action === 'login'} setOpen={setAction} />
      </Box>
    );
  }

  if (action === 'authenticated') {
    return <Box>{children}</Box>;
  }

  return (
    <Box sx={styles}>
      <CircularProgress />
    </Box>
  );
}
