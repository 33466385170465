import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';

function NameColumn({ params, kindFolder }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      {kindFolder ? (
        <img
          src='/img/folder-new.png'
          style={{ width: '28px', height: '28px' }}
          alt='folder'
        />
      ) : (
        <img
          src='/img/file-3.png'
          style={{ width: '28px', height: '28px' }}
          alt='file'
        />
      )}
      <Typography sx={{ ml: 1, fontSize: '14px' }}>
        {params?.row?.name}
      </Typography>
    </Box>
  );
}

export default function LinkWrapper({ params }) {
  const user = useSelector(selectProfile);

  const kind = params?.row?.kind;
  const kindFolder = kind === 'Folder';
  const to = kindFolder
    ? `/drive/${params?.row?.id}`
    : `${params?.row?.fileUrl}${
        !params?.row?.anonymousCanSeeIt
          ? `?accessToken=Bearer ${user.token}`
          : ''
      }`;
  const styles = { textDecoration: 'none', color: 'rgb(30, 25, 25)' };

  if (kind === 'File') {
    return (
      <a href={to} target={'_blank'} rel={'noopener noreferrer'} style={styles}>
        <NameColumn params={params} kindFolder={kindFolder} />
      </a>
    );
  }

  return (
    <Link to={to} style={styles}>
      <NameColumn params={params} kindFolder={kindFolder} />
    </Link>
  );
}
