import axios from 'axios';
import { getAuthHeader, parseJSON } from '@config/functions/helperFunctions';
import { refreshToken } from '@hooks/useAuthToken';
import {
  getUserStorageKey,
  EMAIL_CONFIRMATION_STATES,
} from '@config/constants';
import { accountUrls } from '@config/routes';

const { emailConfirmationUrls } = accountUrls;

export async function handleEmailConfirmation({ token, setState }) {
  try {
    await axios.post(emailConfirmationUrls.detail(token), {}, {});
    setState(EMAIL_CONFIRMATION_STATES.CONFIRMED);
  } catch (error) {
    if (error?.response?.data?.code === 'link_expired') {
      setState(EMAIL_CONFIRMATION_STATES.EXPIRED);
    } else {
      setState(EMAIL_CONFIRMATION_STATES.ERROR);
    }
  }
}

async function handleAuthError(error, request) {
  if (
    error?.response?.status === 403 &&
    error?.response?.data?.code === 'token_not_valid'
  ) {
    console.log('obi-refresh');
    // Refresh the token
    const refreshResponse = await refreshToken();
    const { data: user } = parseJSON(localStorage.getItem(getUserStorageKey()));
    localStorage.setItem(
      getUserStorageKey(),
      JSON.stringify({
        ...user,
        token: refreshResponse.access,
      })
    );
    // Retry the original request with the new token
    return await request({ newToken: refreshResponse.access }); // return the result of the request callback function
  } else {
    console.log('obi-refresh-failed');

    // Rethrow the error if it's not a 403 "token_not_valid" error
    throw error;
  }
}

export async function createRecord({
  url,
  values,
  token,
  actAs,
  encryptionToken,
}) {
  try {
    return await axios.post(
      url,
      values,
      token ? getAuthHeader(token, actAs, encryptionToken) : {}
    );
  } catch (error) {
    return await handleAuthError(error, ({ newToken }) => {
      return axios.post(
        url,
        values,
        getAuthHeader(newToken, actAs, encryptionToken)
      );
    });
  }
}

export async function updateRecord({
  url,
  values,
  token,
  actAs,
  encryptionToken,
}) {
  try {
    values = values || {};
    const keys = Object.keys(values);
    if (keys.length) {
      const firstValue = values[keys[0]];
      if (typeof firstValue === 'object' && firstValue !== null) {
        if (
          firstValue.hasOwnProperty('id') &&
          firstValue.hasOwnProperty('label')
        ) {
          values[keys[0]] = firstValue.id;
        }
      }
    }

    const response = await axios.patch(
      url,
      values,
      getAuthHeader(token, actAs, encryptionToken)
    );
    return response;
  } catch (error) {
    return await handleAuthError(error, ({ newToken }) => {
      return axios.patch(
        url,
        values,
        getAuthHeader(newToken, actAs, encryptionToken)
      );
    });
  }
}

export async function deleteRecord({ url, token, actAs, encryptionToken }) {
  try {
    return await axios.delete(
      url,
      token ? getAuthHeader(token, actAs, encryptionToken) : {}
    );
  } catch (error) {
    return await handleAuthError(error, ({ newToken }) =>
      axios.delete(url, getAuthHeader(newToken, actAs, encryptionToken))
    );
  }
}

export function createLinkedinRecord({ url, values }) {
  return axios.post(url, values);
}
