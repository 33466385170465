import React from 'react';
import { Box } from '@mui/material';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { formsUrls } from '@config/routes';
import { questionGroupScoreRangeMeta } from '@config/meta/forms/formsMeta';
import { useParams } from 'react-router-dom';

const { questionGroupScoreRangesUrls } = formsUrls;

export default function QuestionGroupScoreRangesDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => questionGroupScoreRangeMeta({ data }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/question-option-groups/${recordData?.questionOptionGroupId}`,
                text: 'Question Option Groups',
              },
              { text: recordData?.minScore },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={questionGroupScoreRangesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
    </Box>
  );
}
