import React from 'react';
import * as Yup from 'yup';
import { inventoryUrls } from '@config/routes';
import { productTypeMeta } from '@config/meta/inventory/index';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { productTypesUrls } = inventoryUrls;

export default function ProductTypeDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="Product Type"
        initialValues={{
          ...getAddFormInitialValues(productTypeMeta({}), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(productTypeMeta({}), 'listView'),
        })}
        getPostUrl={() => productTypesUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key="name" label="Name" name="name" required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
