import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import AutocompleteFieldV2 from '@ui/Inputs/AutocompleteFieldV2';
import useAutocomplete from '@hooks/useAutocomplete';
import { bpaUrls, accountUrls } from '@config/routes';
import { createRecord, updateRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import Button from '@mui/material/Button';
import { getAuthHeader } from '@config/functions/helperFunctions';
import axios from 'axios';
import EditKanbanCardLabel from '@components/BPA-V2/Popups/EditKanbanCardLabel';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

const { microservicesUrls, modelsUrls, modelFieldsUrls } = accountUrls;
const { shapesUrls, bulkPayloadSectionKeysUrls } = bpaUrls;

export default function TerminatorActions({
  workflowId,
  shapeId,
  shapeDetail,
  loadingShapeDetail,
}) {
  const [model, setModel] = React.useState(null);
  const [microservice, setMicroservice] = React.useState(null);
  const [init, setInit] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const user = useSelector(selectProfile);
  const [labelOpen, setLabelOpen] = React.useState(false);
  const [errors, setErrors] = React.useState({
    microservice: '',
    model: '',
  });

  const { data: models, isFetching: fetchingModels } = useAutocomplete({
    key: ['workflowable-models', workflowId, microservice],
    getUrl: modelsUrls.list,
    query: `&workflowable_model=true${
      microservice?.id ? `&microservice=${microservice.id}` : ''
    }`,
    renderRow: (record) => ({
      id: record.id,
      label: record.label,
    }),
  });

  React.useEffect(() => {
    if (init || loadingShapeDetail || !shapeDetail) {
      return;
    }

    if (shapeDetail?.payload_model && shapeDetail?.model) {
      setModel({ id: shapeDetail?.payload_model, label: shapeDetail?.model });
    }

    if (shapeDetail?.payload_microservice && shapeDetail?.microservice) {
      setMicroservice({
        id: shapeDetail?.payload_microservice,
        label: shapeDetail?.microservice,
      });
    }

    setInit(true);
  }, [fetchingModels, init, loadingShapeDetail, models, shapeDetail]);

  const updateModel = async () => {
    try {
      setSubmitting(true);

      if (!microservice?.id) {
        setErrors({
          ...errors,
          microservice: 'Microservice is a required field.',
        });
        return;
      }

      if (!model?.id) {
        setErrors({
          ...errors,
          model: 'Model is a required field.',
        });
        return;
      }

      setErrors({
        microservice: '',
        model: '',
      });

      await updateRecord({
        url: shapesUrls.detail(shapeId),
        values: {
          payload_model: model?.id,
          model: model?.label,
          payload_microservice: microservice?.id,
          microservice: microservice?.label,
        },
        token: user.token,
        actAs: user?.actAs,
      });

      if (model) {
        const { data: fields } = await axios.get(
          modelFieldsUrls.list(
            `?model=${model.id}&show_in_bpa=true&page_size=1000`
          ),
          getAuthHeader(user.token, user?.actAs)
        );

        const fieldsPayload = fields.results.map((field) => ({
          payload_section: shapeDetail?.payload_section?.id,
          label: field?.label,
          key: field?.name,
        }));

        await createRecord({
          url: bulkPayloadSectionKeysUrls.list(),
          token: user.token,
          actAs: user?.actAs,
          values: { payload_template_fields: fieldsPayload },
        });
      }
    } catch (err) {
      console.log(err.response || err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Typography sx={{ mb: 1 }}>Kanban card label</Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setLabelOpen(true);
          }}
          startIcon={<CreateOutlinedIcon fontSize="small" />}
        >
          Edit label
        </Button>
      </Box>

      <Typography>Payload</Typography>
      <Box
        sx={{
          my: 2,
          p: 2,
          border: '1px solid #b0b0b0',
          borderRadius: '4px',
        }}
      >
        {init ? (
          <>
            <Box sx={{ mb: 2 }}>
              <AutocompleteFieldV2
                label="Microservice"
                name="microservice"
                requestKey="shape-terminator-action-microservice"
                error={!!errors?.microservice}
                helperText={errors?.microservice}
                fetchUrl={microservicesUrls.list}
                initialValue={microservice}
                customOnChange={(newValue) => {
                  setMicroservice(newValue);
                  setErrors({ ...errors, microservice: '' });
                }}
                renderRow={(r) => ({
                  id: r.id,
                  label: r.name,
                })}
                required
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <AutocompleteFieldV2
                label="Model"
                name="model"
                requestKey="shape-terminator-action-model"
                fetchUrl={modelsUrls.list}
                initialValue={model}
                disabled={!microservice}
                enabled={!!microservice?.id}
                requestKeyOptions={[microservice?.id]}
                error={!!errors?.model}
                helperText={errors?.model}
                customOnChange={(newValue) => {
                  setModel(newValue);
                  setErrors({ ...errors, model: '' });
                }}
                renderRow={(r) => ({
                  id: r.id,
                  label: r.label,
                })}
                urlParams={
                  microservice?.id
                    ? `&microservice=${microservice.id}&workflowable_model=true`
                    : ''
                }
              />
            </Box>
          </>
        ) : (
          <>
            <Skeleton variant="rectangular" width="100%" height={41} />
            <Skeleton variant="rectangular" width="100%" height={41} />
          </>
        )}
      </Box>

      <Button
        variant="outlined"
        size="small"
        disabled={isSubmitting}
        onClick={updateModel}
      >
        {isSubmitting ? 'Saving' : 'Save'}
      </Button>

      <EditKanbanCardLabel
        open={labelOpen}
        setOpen={setLabelOpen}
        shapeId={shapeId}
        shapeDetail={shapeDetail}
      />
    </Box>
  );
}
