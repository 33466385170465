import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Typography, CircularProgress } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { selectProfile } from '@redux/profileSlice';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { wikiUrls } from '@config/routes';
import theme from '@config/theme';
import { createRecord, updateRecord } from '@config/functions/requests';
import useRQuery from '@hooks/useRQuery';

const { sectionsUrls, chaptersUrls, readingProgressUrls } = wikiUrls;

export default function WikiDetails() {
  const { id: chapterId } = useParams();
  const [sections, setSections] = useState(null);
  const [bookId, setBookId] = useState('');
  const [page, setPage] = useState(1);
  const user = useSelector(selectProfile);

  const { data: chapterDetail } = useRQuery({
    key: ['chapter', chapterId, user.token, user?.actAs],
    url: chaptersUrls.detail(chapterId),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  const { data: readingProgressList } = useRQuery({
    key: ['readingProgress', bookId, chapterId, user.token, user?.actAs],
    url: readingProgressUrls.list(
      `?book=${bookId}&chapter=${chapterId}&user=${user.details.id}`
    ),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  const { data, isFetching } = useRQuery({
    key: ['pages', page, chapterId, user.token, user?.actAs],
    url: sectionsUrls.list(
      `?chapter=${chapterId}&page_size=1&page=${page}&ordering=order`
    ),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  const loadOneTime = useCallback(() => {
    setPage(
      readingProgressList && readingProgressList.results[0]
        ? readingProgressList.results[0].page_number
        : 1
    );
  }, [readingProgressList]);

  useEffect(() => {
    if (!readingProgressList) return;

    loadOneTime();
  }, [loadOneTime, readingProgressList]);

  useEffect(() => {
    if (!chapterDetail) return;

    setBookId(chapterDetail.book);

    if (!data) return;

    setSections(
      data.results.map((item) => ({
        id: item.id,
        fields: {
          title: item.title,
          description: item.description,
          html: item.html,
          chapter: item.chapter,
        },
      }))
    );
  }, [data, chapterDetail]);

  const handleChange = async (pages) => {
    setPage(pages);
    const resp = await axios
      .get(
        readingProgressUrls.list(
          `?book=${bookId}&chapter=${chapterId}&user=${user.details.id}`
        ),
        getAuthHeader(user.token, user?.actAs)
      )
      .then((res) => res.data);

    if (resp && resp.results.length === 0) {
      await createRecord({
        values: {
          user: user.details.id,
          progress_page: data.results[0].id,
          book: chapterDetail.book,
          chapter: chapterId,
          page_number: pages,
        },
        url: readingProgressUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });
    } else {
      const [reading] = resp && resp.results;

      await updateRecord({
        values: {
          progress_page: data.results[0].id,
          page_number: pages,
        },
        url: readingProgressUrls.detail(reading.id),
        token: user.token,
        actAs: user?.actAs,
      });
    }
  };

  return (
    <Box>
      {isFetching && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress key="circular-progress" />
        </Box>
      )}

      {data && !isFetching && sections ? (
        <Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Pagination
              count={data.pageCount}
              defaultPage={page}
              shape="rounded"
              onChange={(event, page) => handleChange(page)}
              showFirstButton
              showLastButton
            />
          </Box>
          <Box
            sx={{
              marginTop: 3,
              borderTop: 1,
              borderWidth: 5,
              borderColor: theme.palette.primary.main,
            }}
          />
        </Box>
      ) : null}

      {data &&
        !isFetching &&
        sections &&
        sections.map((p) => (
          <Box sx={{ m: 1 }}>
            <Typography
              component="div"
              variant="h5"
              sx={{ mt: 2, fontWeight: '500' }}
              key={`title-${p.id}`}
            >
              {p.fields.title}
            </Typography>
            <Box sx={{ borderTop: 1 }} />
            <Box
              sx={{ mb: 5 }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(p.fields.html),
              }}
            />
          </Box>
        ))}
    </Box>
  );
}
