import React from 'react';
import useToast from '@hooks/useToast';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import DetailCard from '@components/core/DetailCard';
import DetailPageHeader from '@components/DetailPageHeader';
import { hrUrls, nodeDriveUrls } from '@config/routes';
import { candidateMeta } from '@config/meta/hr';
import { assessmentRowsMap, interviewNoteRowsMap } from '@config/handleRows/hr';
import SendEmailDialog from '@components/hr/SendEmailDialog';
import PassphraseLoginModal from '@components/Communication/PassphraseLoginModal';
import { encryptionPassKey } from '@config/constants';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { AssessmentForm, InterviewNoteForm } from '@config/forms/hr';
import { useQueryClient } from 'react-query';

const { candidatesUrls, assessmentsUrls, interviewNotesUrls } = hrUrls;
const { filesUrls } = nodeDriveUrls;

export default function CandidateDetails({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const [recordData, setRecordData] = useState();
  const [showSendEmail, setShowSendEmail] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState('');

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      candidateMeta({
        data,
        queryClient,
      }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/vacancies/${recordData.vacancy}`, text: 'Candidates' },
              { text: recordData && recordData.first_name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={candidatesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix="0b1"
          microservice={microservices.HR.name}
          model={microservices.HR.models.candidate}
          columnKey="assessmentHR"
          label="Assessment"
          urls={assessmentsUrls}
          addNewForm={{
            getFields: () => AssessmentForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                let file = values.attachment;

                if (file) {
                  const formData = new FormData();
                  formData.append('anonymous_can_see_it', true);
                  formData.append('file', file);
                  try {
                    const response = await createRecord({
                      values: formData,
                      url: filesUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                    });
                    file = response?.data?.fileUrl;
                  } catch (err) {
                    handleCreateFormErrors({ err, setError, notify, values });
                  }
                }
                const response = await createRecord({
                  values: { ...values, candidate: recordId, attachment: file },
                  url: assessmentsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={assessmentRowsMap}
          urlParams={`&candidate=${recordId}`}
        />

        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix="0yg"
          microservice={microservices.HR.name}
          model={microservices.HR.models.candidate}
          columnKey="interviewNoteHR"
          label="Interview Notes"
          urls={interviewNotesUrls}
          addNewForm={{
            getFields: () => InterviewNoteForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, candidate: recordId },
                  url: interviewNotesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={interviewNoteRowsMap}
          urlParams={`&candidate=${recordId}`}
        />
      </Box>

      <SendEmailDialog
        open={showSendEmail}
        setOpen={setShowSendEmail}
        vacancy={recordData?.details?.vacancy}
        vacancyId={recordData?.vacancy}
        encryptToken={
          user.encryptionToken || localStorage.getItem(encryptionPassKey)
        }
        setShowLogin={setShowLogin}
        meta={candidateMeta({
          data: recordData,
        })}
        labels={labels}
        editMode={editMode}
        prefix="0lr"
      />

      <PassphraseLoginModal
        open={showLogin === 'login'}
        setOpen={setShowLogin}
      />
    </Box>
  );
}
