import { Grid, MenuItem } from '@mui/material';
import {
  TextField,
  SelectField,
  RepeatField,
  AutocompleteFieldV2,
  DateTimePickerField,
} from '@components/Inputs';
import ColorPicker from '@components/accounts/ColorPicker';
import { timeZones } from '@config/constants';
// import { useQueryClient } from 'react-query';
import { calendarUrls, crmUrls, communicationUrls } from '@config/routes';
import { useQueryClient } from 'react-query';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';

const { slotsUrls } = calendarUrls;
const { personsUrls } = crmUrls;
const { mailTemplatesUrls } = communicationUrls;

export const DoWhatForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Name" name="name" multiline required />
    </Grid>

    <Grid item xs={2} sm={4} md={6}>
      <TextField
        key="description"
        label="Description"
        name="description"
        multiline
      />
    </Grid>
  </>
);

export const BatchReminderForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label="Name" name="name" multiline required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField
        key="description"
        label="Description"
        name="description"
        multiline
      />
    </Grid>

    <Grid item xs={12} sm={4} md={3}>
      <DateTimePickerField
        label="Start Date Time"
        name="start_date_time"
        showTime
        showTimezone
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <SelectField key="timezone" label="Timezone" name="timezone">
        {timeZones &&
          timeZones.map((o, oIdx) => (
            <MenuItem value={o.value} key={oIdx.toString()}>
              {o.label}
            </MenuItem>
          ))}
      </SelectField>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <RepeatField
        key="rrule"
        label="Repeat"
        name="rrule"
        showDoesNotRepeat={false}
        showCancel={true}
      />
    </Grid>
  </>
);

export const CalendarForms = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField key="name" label="Name" name="name" />
    </Grid>

    <Grid item xs={2} sm={4} md={4}>
      <TextField
        multiline
        key="description"
        label="Description"
        name="description"
      />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <SelectField key="timezone" label="Timezone" name="timezone">
        {timeZones &&
          timeZones.map((o, oIdx) => (
            <MenuItem value={o.value} key={oIdx.toString()}>
              {o.label}
            </MenuItem>
          ))}
      </SelectField>
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <ColorPicker key="color" label="Color" name="color" />
    </Grid>
  </>
);

export const LinkForms = () => (
  <>
    <Grid item xs={3}>
      <TextField key="name" label="Name" name="name" />
    </Grid>

    <Grid item xs={4}>
      <TextField
        key="description"
        label="Description"
        name="description"
        multiline
      />
    </Grid>
  </>
);

export const LinkRecipientForm = ({
  setFieldValue,
  setSelectedMailTemplate,
} = {}) => {
  const queryClient = useQueryClient();
  const ignoreContextFields = ['first_name', 'last_name', 'email', 'slot_link'];

  return (
    <>
      <Grid item xs={4}>
        <AutocompleteFieldV2
          name="mail_template"
          label="Mail Template"
          requestKey="mail-templates"
          fetchUrl={mailTemplatesUrls.list}
          renderRow={(row) => ({
            id: row?.id,
            label: `${row?.name}`,
            contextFields: row?.details?.context_fields,
          })}
          onChange={(mail_template) => {
            setSelectedMailTemplate({
              ...mail_template,
              contextFields: mail_template.contextFields.filter(
                (contextField) =>
                  !ignoreContextFields.includes(contextField.name)
              ),
            });
          }}
          required
        />
      </Grid>

      <Grid item xs={4}>
        <PersonDropdown
          refetch={({ id, email }) => {
            setFieldValue('person', {
              id: id,
              label: `${email || ''}`,
            });
            queryClient.invalidateQueries(['recipients-persons']);
          }}
        >
          <AutocompleteFieldV2
            name="person"
            label="Person"
            requestKey="recipients-persons"
            fetchUrl={personsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.email}`,
              email: row?.email,
              first_name: row?.first_name,
              last_name: row?.last_name,
            })}
            onChange={(value) => {
              setFieldValue('first_name', value?.first_name);
              setFieldValue('last_name', value?.last_name);
              setFieldValue('email', value?.email);
            }}
            required
          />
        </PersonDropdown>
      </Grid>

      <Grid item xs={2}>
        <TextField label="First name" name="first_name" multiline />
      </Grid>

      <Grid item xs={2}>
        <TextField label="Last name" name="last_name" multiline />
      </Grid>

      <Grid item xs={3}>
        <TextField label="Email" name="email" multiline />
      </Grid>
    </>
  );
};

export const LinkSlotForms = () => {
  // const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2} sm={4} md={6}>
        <AutocompleteFieldV2
          name="slot"
          label="Slot Event"
          requestKey="slot-event"
          fetchUrl={slotsUrls.list}
          urlParams={`&parent_event__isnull=True&event_type=SlotEvent`}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.title,
          })}
          required
        />
      </Grid>
    </>
  );
};
