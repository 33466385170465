import { sopHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  sops: 'sops',
  sopTasks: 'sop-tasks',
  sopTaskStep: 'sop-task-steps',
};

const sopUrls = getModelUrls(sopHost, models.sops);
const sopTasksUrls = getModelUrls(sopHost, models.sopTasks);
const sopTaskStepUrls = getModelUrls(sopHost, models.sopTaskStep);

const urls = {
  sopUrls,
  sopTasksUrls,
  sopTaskStepUrls,
};

export default urls;
