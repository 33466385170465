import React from 'react';
import { Grid } from '@mui/material';
import {
  TextField,
  AutocompleteFieldV2,
  AutocompleteField,
} from '@components/Inputs';
import { bpaUrls, systemUrls } from '@config/routes';

const { workflowsUrls, stagesUrls, assetModelsUrls, metaModelsUrls } = bpaUrls;
const { templatesUrls } = systemUrls;

export const WorkflowForm = ({ manualWorkflow, microservice, model }) => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label='Name' name='name' multiline required />
    </Grid>

    <Grid item xs={2} sm={3}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    {manualWorkflow ? (
      <Grid item xs={3}>
        <AutocompleteFieldV2
          name='meta_model'
          label='Model'
          requestKey='bpa-meta-models'
          fetchUrl={metaModelsUrls.list}
          required
        />
      </Grid>
    ) : null}

    {!manualWorkflow ? (
      <Grid item sm={4}>
        <AutocompleteFieldV2
          name='template'
          label='Template To Copy From'
          requestKey='forms-templates'
          fetchUrl={templatesUrls.list}
          urlParams={`&ordering=created_at&microservice_name=${microservice}&model_name=${model}`}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
        />
      </Grid>
    ) : null}
  </>
);

export const StageForm = ({ hideParentStage, workflowId }) => (
  <>
    <Grid item xs={1}>
      <TextField label='Order' name='order' />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Name' name='name' multiline required />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Description' name='description' multiline />
    </Grid>
    {!hideParentStage ? (
      <Grid item xs={4}>
        <AutocompleteFieldV2
          name='parent_stage'
          label='Parent Stage'
          requestKey='stage-parentStage'
          fetchUrl={stagesUrls.list}
          urlParams={`&workflow=${workflowId}&only_parent_stages=true`}
          renderRow={(row) => ({
            id: row?.id,
            label: `${row?.stage_full_order ?? ''}${`${row?.order}` ?? ''} ${
              row?.name ?? ''
            }`,
          })}
        />
      </Grid>
    ) : null}

    <Grid item xs={1.5}>
      <TextField label='Rotting Days' name='rotting_days' />
    </Grid>

    <Grid item xs={1.5}>
      <TextField label='Probability' name='probability_percent' />
    </Grid>
  </>
);

export const ManualWorkflowInstanceForm = ({ workflow, metaModel } = {}) => (
  <>
    <Grid item xs={4}>
      <AutocompleteFieldV2
        name='entity_id'
        label={metaModel?.name}
        requestKey={`bpa-manual-workflow-instances-${metaModel?.name}`}
        requestKeyOptions={[metaModel?.route]}
        fetchUrl={(query) => {
          return `${metaModel?.route}${query ?? ''}`;
        }}
        renderRow={(row) => ({
          id: row?.id,
          label: row[metaModel?.label],
        })}
        required
      />
    </Grid>
    <Grid item xs={4}>
      <AutocompleteFieldV2
        name='stage'
        label='Stage'
        requestKey='bpa-stages'
        fetchUrl={stagesUrls.list}
        renderRow={({ id, name }) => ({
          id,
          label: name,
        })}
        required
        urlParams={`&workflow=${workflow.id}`}
      />
    </Grid>
  </>
);

export const AssetForm = ({
  values,
  stageDetail,
  stages,
  setStageTerm,
  fetchingStages,
} = {}) => (
  <>
    <Grid item xs={3}>
      <AutocompleteFieldV2
        name='assetModel'
        label='Type'
        requestKey='bpa-asset-models'
        fetchUrl={assetModelsUrls.list}
        renderRow={({
          id,
          name,
          microservice,
          model,
          microservice_route,
          microservice_label,
        }) => ({
          id,
          label: name,
          microservice,
          model,
          microservice_route,
          microservice_label,
        })}
        required
      />
    </Grid>

    {values?.assetModel?.microservice_route ? (
      <>
        <Grid item xs={4}>
          <AutocompleteFieldV2
            name='asset'
            label='Item'
            requestKey={`bpa-asset-model-items-${values?.assetModel?.microservice_route}`}
            requestKeyOptions={[values?.assetModel?.microservice_route]}
            fetchUrl={(query) => {
              return `${values?.assetModel?.microservice_route}${query ?? ''}`;
            }}
            renderRow={(row) => ({
              id: row?.id,
              label: row[values?.assetModel?.microservice_label],
            })}
            required
          />
        </Grid>
        {!stageDetail ? (
          <Grid item xs={5}>
            <AutocompleteField
              label='Stages'
              name='stages'
              options={stages || []}
              isLoading={fetchingStages}
              setSearchTerm={setStageTerm}
              value={values.stages || []}
              multiple
              required
            />
          </Grid>
        ) : null}
      </>
    ) : null}
  </>
);

export const ModelAssetStageForm = ({ values } = {}) => (
  <>
    <Grid item xs={3}>
      <AutocompleteFieldV2
        name='workflow'
        label='Workflow'
        requestKey='bpa-workflows'
        fetchUrl={workflowsUrls.list}
        renderRow={({ id, name }) => ({
          id,
          label: name,
        })}
        required
      />
    </Grid>

    {values?.workflow ? (
      <Grid item xs={6}>
        <AutocompleteFieldV2
          name='stage'
          label='Stage'
          fetchUrl={stagesUrls.list}
          requestKey={`bpa-workflow-stages-${values?.workflow?.id}`}
          requestKeyOptions={[values?.workflow?.id]}
          urlParams={`&workflow=${values?.workflow?.id}`}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
          required
        />
      </Grid>
    ) : null}
  </>
);
