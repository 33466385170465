import React from 'react';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import { lmsUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { studentRowsMap } from '@config/handleRows/lms';
import { StudentForm } from '@config/forms/lms';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { studentsUrls } = lmsUrls;

export default function Student({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const studentColOptions = React.useMemo(() => {
    return {
      queryClient,
    };
  }, [queryClient]);

  return (
    <Box>
      <ListView
        nodeAPI
        label='Students'
        prefix='0ro'
        labels={labels}
        editMode={editMode}
        urls={studentsUrls}
        columnKey='student'
        columnOptions={studentColOptions}
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            StudentForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { personId, ...rest } = values;
              const { data } = await createRecord({
                values: {
                  ...rest,
                  personId: personId?.id,
                },
                url: studentsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={studentRowsMap}
      />
    </Box>
  );
}
