import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import CmsPages from '@pages/CMS/CmsPages';
import EditContentDetail from '@pages/CMS/EditContent/detail';


export default function () {
  return (
    <>
      <Route path='/cms-page' element={<WithLayout comp={CmsPages} />} />
      <Route
        path='/edit-content/:id'
        element={<WithLayout comp={EditContentDetail} />}
      />
    </>
  );
}
