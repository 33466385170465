import React from 'react';
import { Box, Typography } from '@mui/material';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { webChatUrls } from '@config/routes';
import { notificationsSupported } from '@config/functions/helperFunctions';
import Loader from '@components/Loader';
import ErrorScreen from '@components/ErrorScreen';
import MessagesList from '@components/WebChat/MessagesList';
import ChatMessage from '@ui/WebChat/ChatMessage';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import CustomerMessage from '@ui/WebChat/CustomerMessage';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import useToast from '@hooks/useToast';

const { messagesUrls } = webChatUrls;

export default function RealtimeChat() {
  const [queryParams] = useSearchParams();
  const roomId = queryParams.get('room');
  const navigate = useNavigate();
  const user = useSelector(selectProfile);
  const [rooms, setRooms] = React.useState([]);
  const [withNotificationRooms, setWithNotificationRooms] = React.useState([]);
  const [selectedRoom, setSelectedRoom] = React.useState(null);
  const [openedRooms, inConversationRooms] = Array.isArray(rooms) ? rooms : [];
  const [message, setMessage] = React.useState('');
  const [showCloseRoomModal, setShowCloseRoomModal] = React.useState('');
  const [error, setError] = React.useState(null);
  const msgContainer = React.useRef(null);
  const [notify] = useToast();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    messagesUrls.list(`?access_token=${user?.token}`)
  );

  const sendMessageToCustomer = () => {
    if (!message.length || message === ' ' || message === '\n') return;
    sendMessage(
      JSON.stringify({
        room_id: roomId,
        message,
      })
    );
    setMessage('');
  };

  const handleCloseRoom = () => {
    if (!roomId) return;

    try {
      sendMessage(
        JSON.stringify({
          room_id: roomId,
          close_room: true,
        })
      );
      setSelectedRoom(null);
      setShowCloseRoomModal(false);
      navigate('/web-chat');
    } catch (error) {
      console.log(error.message);
    }
  };

  React.useEffect(() => {
    if (notificationsSupported() && Notification.permission !== 'granted') {
      const askForNotification = async () => Notification.requestPermission();
      askForNotification();
    }
  }, []);

  React.useEffect(() => {
    if (!roomId || !openedRooms || !inConversationRooms) return;
    const findFn = (room) => room.id === roomId;
    const room1 = openedRooms.items.find(findFn);
    const room2 = inConversationRooms.items.find(findFn);

    if (!room1 && !room2) {
      setSelectedRoom(null);
      navigate('/web-chat');
      return;
    }

    const accept = (room) => {
      if (!room.agent_details || !Object.keys(room.agent_details).length) {
        sendMessage(
          JSON.stringify({
            room_id: roomId,
            accept: true,
          })
        );
      }
    };

    if (room1 && !room1.closed) {
      accept(room1);
      setSelectedRoom(room1);
      return;
    }

    if (room2 && !room2.closed) {
      accept(room2);
      setSelectedRoom(room2);
      return;
    }
  }, [inConversationRooms, openedRooms, rooms, roomId, sendMessage]);

  React.useEffect(() => {
    if (!lastMessage) return;

    try {
      const data = JSON.parse(lastMessage.data);

      if (data.type === 'Error') {
        setRooms(data);
        return;
      }

      if (
        Array.isArray(data.notification_rooms) &&
        data.notification_rooms.length
      ) {
        const audio = new Audio(
          'https://drive.pullstream.com/file/eac6a641-a478-4e07-ba59-533414caea0d.wav'
        );
        audio.play();
        if (notificationsSupported() && Notification.permission === 'granted') {
          const msg = `${data.notification_rooms.length} New Customers Waiting`;
          new Notification(msg);
        }
      }

      setRooms(data.rooms);
      setWithNotificationRooms(data.notification_rooms);
    } catch (err) {
      console.log(err.message);
    }
  }, [lastMessage]);

  React.useEffect(() => {
    if (msgContainer.current) {
      msgContainer.current.scrollTop =
        msgContainer.current.scrollHeight - msgContainer.current.clientHeight;
    }
  }, [selectedRoom]);

  React.useEffect(() => {
    if (rooms && rooms.type === 'Error') {
      setError(rooms);
    }
  }, [rooms]);

  React.useEffect(() => {
    if (!error) return;

    if (error.error_code === 'room_doesnot_exist') {
      setError(null);
      navigate('/web-chat');
      return;
    }

    if (error.error_code === 'room_already_connected') {
      setError(null);
      setSelectedRoom(null);
      notify('Another agent just accepted this room', {
        type: 'INFO',
      });
      navigate('/web-chat');
    }
  }, [error, notify]);

  if (readyState === 3) {
    let errorMsg = null;
    try {
      const { type, agent } = JSON.parse(lastMessage.data);
      if (type === 'Error' && agent) {
        errorMsg = agent;
      }
    } catch (err) {}
    return (
      <ErrorScreen
        text={errorMsg || 'There was an error, please try again'}
        img=''
      />
    );
  }

  if (!readyState) {
    return (
      <Box sx={{ py: 16 }}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Box
        sx={{
          background: '#fff',
          minWidth: '250px',
          maxWidth: '250px',
          p: 1.5,
          borderRadius: '4px',
          height: '80vh',
        }}
      >
        <Typography sx={{ mb: 1.5 }} variant='h6'>
          Messages
        </Typography>
        <MessagesList
          title='Waiting'
          messages={openedRooms}
          onMessageClick={(v) => navigate(`/web-chat?room=${v}`)}
          withNotificationRooms={withNotificationRooms}
        />
        <MessagesList
          title='In Conversation'
          selectedRoom={roomId}
          messages={inConversationRooms}
          onMessageClick={(v) => navigate(`/web-chat?room=${v}`)}
          withNotificationRooms={withNotificationRooms}
        />
      </Box>
      {selectedRoom ? (
        <Box
          sx={{
            background: '#EBECF0',
            width: 'calc(100% - 250px)',
            height: '80vh',
          }}
        >
          <Box
            sx={{
              px: 1,
              display: 'flex',
              height: '56px',
              alignItems: 'center',
              borderBottom: '1px solid #cfcfcf',
            }}
          >
            <ChatMessage name={selectedRoom.customer_name} noHover />
            <Button
              startIcon={<CloseIcon />}
              sx={{ minWidth: '140px' }}
              color='error'
              onClick={() => setShowCloseRoomModal(true)}
            >
              Close Room
            </Button>
          </Box>
          <Box
            ref={msgContainer}
            sx={{ overflowY: 'auto', height: 'calc(100% - 136px)', p: 2 }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
              <CustomerMessage
                msg={selectedRoom.customer_question}
                date={selectedRoom.created_at}
                customer
              />
              {selectedRoom.messages.map((msg) => (
                <CustomerMessage
                  msg={msg.message}
                  date={msg.created_at}
                  customer={msg.message_from === 'Customer'}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              maxHeight: '80px',
              px: 2,
              py: 1,
              pb: 4,
            }}
          >
            <TextareaAutosize
              maxRows={1.5}
              minRows={1.5}
              placeholder='Send a message'
              className='web-chat-textarea'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  sendMessageToCustomer();
                }
              }}
              style={{
                width: 'calc(100% - 120px)',
                fontFamily: 'inherit',
                // border: '0',
                padding: '12px',
                borderRadius: '10px',
                resize: 'none',
                border: '1px solid #cfcfcf',
              }}
            />
            <Box
              sx={{ width: '120px', display: 'flex', justifyContent: 'center' }}
            >
              <Button
                variant='contained'
                onClick={sendMessageToCustomer}
                endIcon={<SendIcon />}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}

      <Dialog
        fullWidth
        maxWidth='sm'
        open={showCloseRoomModal}
        onClose={() => setShowCloseRoomModal(false)}
      >
        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontSize: '18px', mb: 1.5 }}>
            Are you absolutely sure?
          </Typography>
          <Typography sx={{ fontWeight: '500', mb: 1.5 }}>
            This action cannot be undone. This will close the current room and
            you will not be able to chat with current room customer.
          </Typography>

          <DialogActions>
            <Button onClick={() => setShowCloseRoomModal(false)}>Cancel</Button>
            <Button color='error' onClick={handleCloseRoom}>
              Close Room
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
