import React from 'react';
import { Box, Menu, MenuItem, IconButton } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { selectBpaBoard, setManual, setWorkflows } from '@redux/bpaBoardSlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { selectProfile } from '@redux/profileSlice';
import { bpaUrls } from '@config/routes';
import {
  handleDragEnd,
  getAuthHeader,
  parseJSON,
  getLastSelectedDropdownOption,
} from '@config/functions/helperFunctions';
import useToast from '@hooks/useToast';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';
import { updateRecord } from '@config/functions/requests';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShowHideStagesPopup from '@components/BPA-V2/Popups/ShowHideStagesPopup';
import { useSearchParams } from 'react-router-dom';

const ITEM_HEIGHT = 48;

const { workflowsUrls, manualWorkflowInstancesUrls, manualWorkflowStagesUrls } =
  bpaUrls;

export default function ManualBpaBoard({ labels, editMode }) {
  const [notify, update] = useToast();
  const dispatch = useDispatch();
  const user = useSelector(selectProfile);
  const { workflows: workflowsState } = useSelector(selectBpaBoard);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const initialManualWorkflow = getLastSelectedDropdownOption(
    'lastSelectedManualWorkflow'
  );
  const [selectedWorkflow, setSelectedWorkflow] = React.useState(
    initialManualWorkflow
  );
  const [stageStates, setStageStates] = React.useState([]);
  const [hideShowStages, setHideShowStages] = React.useState(false);

  /*eslint no-unused-vars: ["error", { "varsIgnorePattern": "setSearchParams" }]*/
  let [searchParams, setSearchParams] = useSearchParams();

  const open = Boolean(anchorEl);

  const { data, isLoading } = useRQuery({
    key: ['bpa-board', selectedWorkflow?.id, user.token, user?.actAs],
    url: manualWorkflowStagesUrls.list(
      `?workflow=${selectedWorkflow?.id}&order=order&page_size=200`
    ),
    options: {
      enabled: !!(user.token && selectedWorkflow?.id),
      refetchInterval: 1000 * 10 * 1,
    },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleSaveCardDrag = async ({ newList, removedItem, destId }) => {
    const tId = notify('Saving....', { loading: true });
    setStageStates(() => {
      const storedStates =
        parseJSON(localStorage.getItem('automataStageStates'))?.data || {};
      return newList.map((stage) => ({
        id: stage?.id,
        visible:
          storedStates[stage?.id] !== undefined
            ? storedStates[stage?.id]
            : true,
      }));
    });
    dispatch(setWorkflows(newList));
    try {
      await updateRecord({
        url: manualWorkflowInstancesUrls.detail(removedItem.id),
        values: { stage: destId },
        token: user.token,
        actAs: user?.actAs,
      });
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(err);
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    const workflowId = searchParams.get('workflowId');
    const workflowName = searchParams.get('workflowName');
    const metaModelLabel = searchParams.get('metaModelLabel');
    if (!workflowId || !workflowName || !metaModelLabel) return;
    setSelectedWorkflow({
      id: workflowId,
      label: workflowName,
      metaModel: {
        label: metaModelLabel,
      },
    });
  }, [searchParams]);

  React.useEffect(() => {
    if (data) {
      setStageStates(() => {
        const storedStates =
          parseJSON(localStorage.getItem('automataStageStates'))?.data || {};
        return data.map((stage) => ({
          id: stage?.id,
          visible:
            storedStates[stage?.id] !== undefined
              ? storedStates[stage?.id]
              : true,
        }));
      });
      dispatch(setManual(true));
      dispatch(setWorkflows(data));
    }
  }, [data, dispatch]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title='Workflows'
        prefix='06t'
        labels={labels}
        editMode={editMode}
        content2={
          <Box sx={{ ml: 'auto' }}>
            {selectedWorkflow ? (
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                size='small'
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <MoreVertIcon fontSize='inherit' />
              </IconButton>
            ) : null}

            <Menu
              id='long-menu'
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
              <MenuItem
                size='small'
                onClick={() => {
                  setHideShowStages(true);
                }}
              >
                Show/Hide Stages
              </MenuItem>
            </Menu>

            <ShowHideStagesPopup
              open={hideShowStages}
              setOpen={setHideShowStages}
              stageStates={stageStates}
              workflowsState={workflowsState}
              setStageStates={setStageStates}
            />
          </Box>
        }
        filterOptions={[
          {
            label: 'Workflow',
            name: 'selectedWorkflow',
            requestKey: 'bpa-board-workflows',
            fetchUrl: workflowsUrls.list,
            handleChange: (newValue) => {
              setSelectedWorkflow(newValue);
              localStorage.setItem(
                'lastSelectedManualWorkflow',
                JSON.stringify(newValue)
              );
            },
            renderRow: (r) => ({
              id: r.id,
              label: r.name,
              metaModel: r?.details?.meta_model,
            }),
            urlParams: `&workflow_type=Manual`,
            orderingCol: 'name',
            initialValue: initialManualWorkflow,
          },
        ]}
      />

      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            <DragDropContext
              onDragEnd={(res) =>
                handleDragEnd(res, workflowsState, handleSaveCardDrag)
              }
            >
              <KanbanBoardList
                detailLink='manual-bpa-board'
                isDraggable={false}
                data={workflowsState.filter((stage) => {
                  const found = stageStates.find(({ id }) => stage?.id === id);
                  return found?.visible === true;
                })}
                labelCol='id'
                workflowId={selectedWorkflow?.id}
                selectedWorkflow={selectedWorkflow}
                setStageStates={setStageStates}
                labels={labels}
                editMode={editMode}
              />
            </DragDropContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}
