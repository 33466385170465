import { hrHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  courses: 'courses',
  skillLevel: 'skill-levels',
  roles: 'roles',
  employees: 'employees',
  vacancies: 'vacancies',
  departments: 'departments',
  skills: 'skills',
  roleHistorys: 'role-historys',
  employeeDepartments: 'employee-departments',
  employeeSkills: 'employee-skills',
  candidates: 'candidates',
  references: 'references',
  salaryHistorys: 'salary-historys',
  trainingHistorys: 'training-historys',
  holidayEntitlements: 'holiday-entitlements',
  dayOffs: 'day-offs',
  performanceReviews: 'performance-reviews',
  vacancyStages: 'vacancy-stages',
  vacancyStatus: 'vacancy-statuses',
  keywords: 'keywords',
  jobLevels: 'job-levels',
  recruitmentPipeline: 'recruitment-pipelines',
  recruitmentPipelineStages: 'recruitment-pipeline-stages',
  dayOffTypes: 'day-off-types',
  inas: 'inas',
  inaStages: 'ina-stages',
  updateBulkCandidates: 'update-bulk-candidates',
  duplicateRecruitmentPipelines: 'duplicate-recruitment-pipelines',
  advanceCandidateToNextStage: 'advance-candidate-to-next-stage',
  assessments: 'assessments',
  interviewNotes: 'interview-notes',
  sendEmailsToCandidates: 'send-emails-to-candidates',
  createSalesPeoples: 'create-sales-peoples',
  employeeStatus: 'employee-statuses',
  candidateAssessments: 'candidate-assessments',
};

const createSalesPeoplesUrls = getModelUrls(hrHost, models.createSalesPeoples);
// const candidateAssessmentsUrls = getModelUrls(hrHost, models.candidateAssessments);

const sendEmailsToCandidatesUrls = getModelUrls(
  hrHost,
  models.sendEmailsToCandidates
);

const interviewNotesUrls = getModelUrls(hrHost, models.interviewNotes);

const advanceCandidateToNextStageUrls = getModelUrls(
  hrHost,
  models.advanceCandidateToNextStage
);

const assessmentsUrls = getModelUrls(hrHost, models.assessments);

const duplicateRecruitmentPipelinesUrls = getModelUrls(
  hrHost,
  models.duplicateRecruitmentPipelines
);

const updateBulkCandidatesUrls = getModelUrls(
  hrHost,
  models.updateBulkCandidates
);
const keywordsUrls = getModelUrls(hrHost, models.keywords);
const coursesUrls = getModelUrls(hrHost, models.courses);
const skillLevelUrls = getModelUrls(hrHost, models.skillLevel);
const rolesUrls = getModelUrls(hrHost, models.roles);
const employeesUrls = getModelUrls(hrHost, models.employees);
const vacanciesUrls = getModelUrls(hrHost, models.vacancies);
const departmentsUrls = getModelUrls(hrHost, models.departments);
const skillsUrls = getModelUrls(hrHost, models.skills);
const roleHistoryUrls = getModelUrls(hrHost, models.roleHistorys);
const employeeDepartmentsUrls = getModelUrls(
  hrHost,
  models.employeeDepartments
);
const employeeSkillsUrls = getModelUrls(hrHost, models.employeeSkills);
const candidatesUrls = getModelUrls(hrHost, models.candidates);
const referencesUrls = getModelUrls(hrHost, models.references);
const salaryHistoryUrls = getModelUrls(hrHost, models.salaryHistorys);
const trainingHistoryUrls = getModelUrls(hrHost, models.trainingHistorys);
const holidayEntitlementsUrls = getModelUrls(
  hrHost,
  models.holidayEntitlements
);
const daysOffUrls = getModelUrls(hrHost, models.dayOffs);
const vacancyStagesUrls = getModelUrls(hrHost, models.vacancyStages);
const performanceReviewsUrls = getModelUrls(hrHost, models.performanceReviews);
const vacancyStatusUrls = getModelUrls(hrHost, models.vacancyStatus);
const jobLevelsUrls = getModelUrls(hrHost, models.jobLevels);
const recruitmentPipelineUrls = getModelUrls(
  hrHost,
  models.recruitmentPipeline
);
const recruitmentPipelineStagesUrls = getModelUrls(
  hrHost,
  models.recruitmentPipelineStages
);
const dayOffTypesUrls = getModelUrls(hrHost, models.dayOffTypes);
const inasUrls = getModelUrls(hrHost, models.inas);
const inaStagesUrls = getModelUrls(hrHost, models.inaStages);
const employeeStatusUrls = getModelUrls(hrHost, models.employeeStatus);

const urls = {
  assessmentsUrls,
  updateBulkCandidatesUrls,
  inasUrls,
  inaStagesUrls,
  coursesUrls,
  skillLevelUrls,
  rolesUrls,
  employeesUrls,
  vacanciesUrls,
  departmentsUrls,
  skillsUrls,
  roleHistoryUrls,
  employeeDepartmentsUrls,
  employeeSkillsUrls,
  candidatesUrls,
  referencesUrls,
  salaryHistoryUrls,
  trainingHistoryUrls,
  holidayEntitlementsUrls,
  daysOffUrls,
  performanceReviewsUrls,
  vacancyStagesUrls,
  vacancyStatusUrls,
  keywordsUrls,
  recruitmentPipelineUrls,
  recruitmentPipelineStagesUrls,
  jobLevelsUrls,
  dayOffTypesUrls,
  duplicateRecruitmentPipelinesUrls,
  advanceCandidateToNextStageUrls,
  interviewNotesUrls,
  sendEmailsToCandidatesUrls,
  createSalesPeoplesUrls,
  employeeStatusUrls,
  // candidateAssessmentsUrls
};

export default urls;
