import React from 'react';
import _ from 'lodash';
import { Typography } from '@mui/material';
import EditableTableLink from './EditableTableLink';
import AutocompleteFieldV2 from '@ui/Inputs/AutocompleteFieldV2';

export function EditableAutocompleteV2ReadOnly({ params }) {
  const { colDef, value } = params;

  if (colDef.multiple) {
    return (
      <Typography component='div' variant='p'>
        {!value || value.length === 0
          ? '...'
          : value.map((obj) => obj.label).join(', ')}
      </Typography>
    );
  }

  if (colDef.to || colDef.getHref) {
    return <EditableTableLink params={params} />;
  }

  return (
    <Typography component='div' variant='p'>
      {!value || !value?.id ? '...' : value.label}
    </Typography>
  );
}

export function EditableAutocompleteV2({ params }) {
  const { id, value, api, field, colDef, row } = params;

  const handleChange = async (value) => {
    if (typeof value === 'undefined') return;

    if (colDef.formatValue) {
      value = colDef.formatValue(value);
    }

    api.setEditCellValue({
      id,
      field,
      value,
    });

    await api.commitCellChange({ id, field });
    api.setCellMode(id, field, 'view');
  };

  return (
    <AutocompleteFieldV2
      label={colDef?.headerName}
      urlParams={
        colDef.getUrlParams ? colDef.getUrlParams(row) : colDef?.urlParams
      }
      disableSearch={colDef?.disableSearch}
      requestKey={colDef?.requestKey}
      fetchUrl={colDef?.fetchUrl}
      renderRow={colDef?.renderRow}
      getOptionLabel={colDef?.getOptionLabel}
      initialValue={_.isObject(value) ? value : null}
      customOnChange={handleChange}
      orderingCol={colDef?.orderingCol}
    />
  );
}
