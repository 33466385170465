import React from 'react';
import { Box, Typography, Button, Stack, Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import { TextField } from '@components/Inputs';
import * as Yup from 'yup';
import { accountUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import PageWrapper from '@ui/Auth/PageWrapper';
import { Link, useParams, useNavigate } from 'react-router-dom';
import useToast from '@hooks/useToast';
import { getUserStorageKey } from '@config/constants';

const { resetPwdUrls } = accountUrls;

export default function Reset() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const [notify] = useToast();

  React.useEffect(() => {
    if (localStorage.getItem(getUserStorageKey())) {
      window.location.href = '/';
    }
  }, []);

  return (
    <PageWrapper>
      <Stack spacing={3} sx={{ width: '400px', mx: 'auto' }}>
        <Box>
          <Typography
            variant='h2'
            sx={{ fontSize: '28px', fontWeight: '500', mb: 0.5 }}
          >
            Reset Password
          </Typography>
        </Box>

        <Box>
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={Yup.object({
              password: Yup.string().min(8).required(),
              confirmPassword: Yup.string().test(
                'passwords-match',
                'Passwords must match',
                function (value) {
                  return this.parent.password === value;
                }
              ),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                setError('');

                await createRecord({
                  values: {
                    password: values.password,
                    token: id,
                  },
                  url: resetPwdUrls.list(),
                });

                resetForm();
                notify('Password changed', {
                  type: 'SUCCESS',
                });
                navigate('/');
              } catch (err) {
                console.log(err.message);
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.email
                ) {
                  setError(err.response.data.email);
                } else {
                  setError('Something went wrong, try reloading');
                }
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form noValidate autoComplete='off'>
                <Stack spacing={3}>
                  {error ? (
                    <Alert icon={false} severity='error'>
                      {error}
                    </Alert>
                  ) : null}

                  <Box>
                    <TextField
                      type='password'
                      name='password'
                      label='Password'
                    />
                  </Box>

                  <Box>
                    <TextField
                      type='password'
                      name='confirmPassword'
                      label='Confirm password'
                    />
                  </Box>

                  <Box>
                    <Button
                      fullWidth
                      type='submit'
                      disabled={isSubmitting}
                      variant='contained'
                    >
                      Reset password
                    </Button>
                  </Box>

                  <Box sx={{ textAlign: 'center' }}>
                    <Link
                      to='/auth/login'
                      style={{ textDecoration: 'none', color: '#444' }}
                    >
                      Back to log in
                    </Link>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </PageWrapper>
  );
}
