import * as Yup from 'yup';
import { fieldType } from '@config/constants';
import { formatToUTCDate } from '@config/functions/helperFunctions';
import { accountUrls } from '@config/routes';

const { usersUrls } = accountUrls;


// remove this line :P

export const agentsMeta = ({
  data
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'user',
    headerName: 'User',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: usersUrls.list,
    requestKey: `${data?.id}-agent-user`,
    validate: Yup.mixed(),
    initialValue: data?.user
      ? {
          id: data?.user,
          label: data?.details?.user?.email,
        }
      : null,
    renderRow: (row) => ({
        id: row?.id,
        label: row?.email,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const customersMeta = ({ data } = {}) => [
  {
    field: 'first_name',
    headerName: 'First name',
    onForm: true,
    width: 150,
    editable: true,
    value: data ? data.first_name : null,
    initialValue: '',
    validate: Yup.string().max(100).required(),
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    onForm: true,
    width: 150,
    editable: true,
    value: data ? data.last_name : null,
    initialValue: '',
    validate: Yup.string().max(100).required(),
  },
  {
    field: 'email',
    headerName: 'Email',
    onForm: true,
    width: 150,
    editable: true,
    value: data ? data.email : null,
    initialValue: '',
    validate: Yup.string().email().required(),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    onForm: true,
    width: 150,
    editable: true,
    value: data ? data.phone : null,
    initialValue: '',
    validate: Yup.string().max(20).required(),
  },
  {
    field: 'question',
    headerName: 'Question',
    onForm: true,
    width: 450,
    editable: true,
    value: data ? data.question : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const ticketsMeta = ({
  data,
} = {}) => [
  {
    field: 'headline',
    headerName: 'Headline',
    onForm: true,
    width: 400,
    editable: true,
    to: '/tickets/',
    value: data ? data.headline : null,
    initialValue: '',
    validate: Yup.string().max(800).required(),
  },
  {
    field: 'customer',
    headerName: 'Customer Email',
    onForm: true,
    width: 500,
    editable: false,
    value: data && data.details.customer ? data.details.customer.email : null,
    initialValue: '',
    validate: Yup.string().max(800).required(),
  },
  {
    field: 'customer2',
    headerName: 'Customer Phone',
    onForm: true,
    width: 500,
    editable: false,
    value: data && data.details.customer ? data.details.customer.phone : null,
    initialValue: '',
    validate: Yup.string().max(800).required(),
  },
  // {
  //   field: 'customer',
  //   headerName: 'Customer Email',
  //   type: fieldType.AUTOCOMPLETE,
  //   onForm: true,
  //   setSearchTerm: (value) => setCustomerTerm(value),
  //   options: customers,
  //   isLoading: fetchingCustomers,
  //   validate: Yup.string().required(),
  //   width: 240,
  //   editable: false,
  //   value: data && data.details.customer ? data.details.customer.email : null,
  // },
  // {
  //   field: 'customer2',
  //   headerName: 'Customer Phone',
  //   type: fieldType.AUTOCOMPLETE,
  //   onForm: true,
  //   setSearchTerm: (value) => setCustomerTerm(value),
  //   options: customers,
  //   isLoading: fetchingCustomers,
  //   validate: Yup.string().required(),
  //   width: 240,
  //   editable: false,
  //   value: data && data.details.customer ? data.details.customer.phone : null,
  // },
  {
    field: 'closed',
    headerName: 'Closed',
    type: fieldType.BOOLEAN,
    width: 100,
    editable: true,
    value: data ? data.closed : null,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    type: fieldType.DATE,
    editable: true,
    width: 150,
    initialValue: formatToUTCDate(new Date()),
    onAccordionForm: true,
    value: data ? formatToUTCDate(new Date(data.created_at)) : null,
    validate: Yup.string().required(),
  },
];
