import React from 'react';
import * as Yup from 'yup';
import { inventoryUrls } from '@config/routes';
import { supplierMeta } from '@config/meta/inventory';
import { Grid } from '@mui/material';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { crmUrls } from '@config/routes';
import CompanyDropdown from '@dropdown-forms/crm/CompanyDropdown';
import { AutocompleteFieldV2 } from '@components/Inputs';
import { useQueryClient } from 'react-query';


const { suppliersUrls } = inventoryUrls;
const { companiesUrls } = crmUrls;

export default function SupplierDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);  
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="Supplier Company"
        initialValues={{
          ...getAddFormInitialValues(supplierMeta({}), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(supplierMeta({}), 'listView'),
        })}
        getPostUrl={() => suppliersUrls.list()}
        getValues={({values, supplier_company}) => ({
          ...values,
          supplier_company: supplier_company?.id
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
            <CompanyDropdown
              refetch={({ id, name }) => {
                setFieldValue('supplier_company', { id, label: name });
                queryClient.invalidateQueries(['supplier-companies']);
              }}
            >
              <AutocompleteFieldV2
                name='supplier_company'
                label='Supplier Company'
                requestKey='supplier-companies'
                fetchUrl={companiesUrls.list}
                urlParams={`&ordering=created_at`}
                required
              />
            </CompanyDropdown>
            </Grid>
          </Grid>
        )}
        open={open}
        setOpen={setOpen}
        refetch={refetch}
      />
    </>
  );
}
