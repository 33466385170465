import React from 'react';
import * as Yup from 'yup';
import { accountUrls } from '@config/routes';
import { usersMeta } from '@config/meta/accounts/accountMeta';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';

const { usersUrls } = accountUrls;

export default function UserDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const user = useSelector(selectProfile);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      {user.details && user.details.is_superuser ? (
        <FormDialog
          title="User"
          initialValues={{
            ...getAddFormInitialValues(usersMeta(), 'listView'),
          }}
          validationSchema={Yup.object({
            ...getSchemaFromColumnsForForm(usersMeta(), 'listView'),
          })}
          getPostUrl={() => usersUrls.list()}
          getValues={(values) => ({
            ...values,
          })}
          getFields={() => (
            <Grid container spacing={3.5}>
              <Grid item xs={6}>
                <TextField
                  key="Username"
                  label="Username"
                  name="username"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField key="email" label="Email" name="email" required />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  key="firstname"
                  label="First Name"
                  name="first_name"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  multiline
                  key="lastname"
                  label="Last Name"
                  name="last_name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  key="password"
                  label="Password"
                  name="password"
                  type="password"
                />
              </Grid>
            </Grid>
          )}
          refetch={refetch}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
    </>
  );
}
