import * as React from 'react';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import StartAutomationPrompt from '@components/BPA-V2/StartAutomationPrompt';
import DetailCard from '@components/core/DetailCard';
import { bpaUrls, systemUrls, calendarUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { workflowMeta } from '@config/meta/bpa';
import DetailAccordionView from '@components/DetailAccordionView';
import ImportStagesModal from '@components/BPA-V2/ImportStagesModal';
import {
  stageRowsMap,
  assetRowsMap,
  manualWorkflowInstanceRowsMap,
} from '@config/handleRows/bpa';
import { microservices } from '@config/constants';
import {
  StageForm,
  AssetForm,
  ManualWorkflowInstanceForm,
} from '@config/forms/bpa';
import useRQuery from '@hooks/useRQuery';
import {
  handleCreateFormErrors,
  getAuthHeader,
} from '@config/functions/helperFunctions';
import { createRecord, updateRecord } from '@config/functions/requests';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { shapeTransitionMap } from '@config/handleRows/system';
import { Menu, IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { inasRowsMap } from '@config/handleRows/crm';
import UpdateWorkingTime from '@components/BPA-V2/UpdateWorkingTime';

const {
  workflowsUrls,
  stagesUrls,
  modelAssetsUrls,
  bulkCreateStageShapesUrls,
  manualWorkflowInstancesUrls,
  startAutomationUrls,
  getOrderedStageUrls,
} = bpaUrls;
const { shapeTransitionLogUrls } = systemUrls;
const { actionRemindersUrls } = calendarUrls;

export default function WorkflowDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const [importStageModalOpen, setImportStageModalOpen] = React.useState(false);
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const [stageListData, setStageListData] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [stageTerm, setStageTerm] = React.useState('');
  const ITEM_HEIGHT = 44;
  const defaultOptions = {
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
    renderRow: (r) => ({ id: r.id, label: r.name }),
  };

  const {
    data: stages,
    refetch: refetchStages,
    isFetching: fetchingStages,
  } = useRQuery({
    key: ['stages', recordId, stageTerm, user.token, user?.actAs],
    url: getOrderedStageUrls.detail(recordId),
    ...defaultOptions,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.stage_full_order ?? ''}${`${row?.order}` ?? ''} ${
        row?.name ?? ''
      }`,
    }),
  });

  const [startAutomationModalOpen, setStartAutomationModalOpen] =
    React.useState(false);

  const [workinTimeModalOpen, setWorkinTimeModalOpen] = React.useState(false);

  const [startAutomationModalDisable, setStartAutomationModalDisable] =
    React.useState(false);

  const onCreateShapes = async () => {
    try {
      await createRecord({
        url: bulkCreateStageShapesUrls.list(),
        values: {
          x: -100,
          y: 5240,
          width: 120,
          height: 60,
          type: 'process',
          workflow: recordId,
          style: 'strokeColor=black;fillColor=white;fontColor=black',
        },
        token: user.token,
        actAs: user?.actAs,
      });
      queryClient.invalidateQueries([`${recordId}-record-details`]);
      notify('Operation completed', {
        type: 'SUCCESS',
      });
    } catch (error) {
      notify('There was an error, please try again later.', {
        type: 'ERROR',
      });
    }
  };

  const onPauseClick = async () => {
    try {
      await updateRecord({
        url: workflowsUrls.detail(recordId),
        values: {
          paused: !recordData?.paused,
        },
        token: user.token,
        actAs: user?.actAs,
      });
      queryClient.invalidateQueries([`${recordId}-record-details`]);
      notify('Operation completed', {
        type: 'SUCCESS',
      });
    } catch (error) {
      notify('There was an error, please try again later.', {
        type: 'ERROR',
      });
    }
  };

  const onStartAutomationClick = async (
    values,
    setSubmitting,
    resetForm,
    setError,
    setFieldError
  ) => {
    try {
      setStartAutomationModalDisable(true);
      await createRecord({
        url: startAutomationUrls.detail(recordId),
        values,
        token: user.token,
        actAs: user?.actAs,
      });
      queryClient.invalidateQueries([`${recordId}-record-details`]);
      setStartAutomationModalOpen(false);
      notify('Operation completed', {
        type: 'SUCCESS',
      });
    } catch (error) {
      // console.log(error);
      handleCreateFormErrors({
        err: error,
        setError,
        values,
        notify,
      });
      // notify('There was an error, please try again later.', {
      //   type: 'ERROR',
      // });
    } finally {
      setStartAutomationModalDisable(false);
    }
  };

  const manualWorkflow = recordData?.workflow_type === 'Manual';
  const metaModelDetails = recordData?.details?.meta_model;

  const stagesColOptions = React.useMemo(() => {
    return {
      nextOrder: stageListData?.totalCount ?? 1,
      onlyParentStages: true,
      workflowId: recordId,
    };
  }, [stageListData?.totalCount, recordId]);

  const workflowInstancesColOptions = React.useMemo(() => {
    return {
      workflow: recordData,
      metaModel: metaModelDetails,
    };
  }, [recordData, metaModelDetails]);

  const assetColOptions = React.useMemo(() => {
    return {
      workflow: {
        id: recordData?.id,
        name: recordData?.name,
      },
    };
  }, [recordData?.id, recordData?.name]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: manualWorkflow ? '/manual-workflows' : '/workflows',
                text: 'Workflows',
              },
              { text: recordData?.name },
            ]}
          >
            {!manualWorkflow ? (
              <Button variant="outlined" onClick={onPauseClick}>
                {recordData?.paused ? 'Resume' : 'Pause'}
              </Button>
            ) : null}

            <IconButton
              sx={{
                ml: 1,
              }}
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              size="small"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVertIcon fontSize="default" sx={{ color: '#002884' }} />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
              {!manualWorkflow ? (
                <>
                  <MenuItem
                    size="small"
                    onClick={() => {
                      window.open(`/bpa/${recordId}`, '_blank');
                    }}
                  >
                    Open Diagram
                  </MenuItem>
                  <MenuItem size="small" onClick={onCreateShapes}>
                    Create Shapes
                  </MenuItem>
                </>
              ) : !recordData.automata_workflow ? (
                <>
                  <MenuItem
                    size="small"
                    onClick={() => {
                      setStartAutomationModalOpen(true);
                    }}
                  >
                    Start Automation
                  </MenuItem>
                </>
              ) : (
                <MenuItem
                  size="small"
                  onClick={() => {
                    window.location.href = `/workflows/${recordData?.automata_workflow}`;
                  }}
                >
                  Jump To Automata
                </MenuItem>
              )}
              <MenuItem
                size="small"
                onClick={() => {
                  if (manualWorkflow) {
                    localStorage.setItem(
                      'lastSelectedManualWorkflow',
                      JSON.stringify({
                        id: recordData.id,
                        label: recordData.name,
                        metaModel: recordData?.details?.meta_model,
                      })
                    );
                  } else {
                    localStorage.setItem(
                      'lastSelectedWorkflow',
                      JSON.stringify({
                        id: recordData.id,
                        label: recordData.name,
                        paused: recordData?.paused,
                      })
                    );
                  }

                  window.open(
                    `/${manualWorkflow ? 'manual-' : ''}bpa-board`,
                    '_blank'
                  );
                }}
              >
                Jump To Kanban
              </MenuItem>
              <MenuItem
                size="small"
                onClick={() => {
                  setWorkinTimeModalOpen(true);
                }}
              >
                Update Working Time
              </MenuItem>
            </Menu>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={workflowsUrls}
        recordId={recordId}
        getSchema={(data) => workflowMeta({ data, manualWorkflow })}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          microservice={microservices.BPA.name}
          model={microservices.BPA.models.stage}
          columnKey="stage"
          columnOptions={stagesColOptions}
          customValues={{
            rebase: true,
          }}
          label="Stages"
          prefix="0rv"
          urls={stagesUrls}
          customOptionsOnRight={() => (
            <Box sx={{ mr: 3 }}>
              <Button onClick={() => setImportStageModalOpen(true)}>
                Import Stages
              </Button>
            </Box>
          )}
          addNewForm={{
            getFields: () =>
              StageForm({
                hideParentStage: true,
                workflowId: recordId,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { parent_stage, ...rest } = values;

                const response = await createRecord({
                  values: {
                    ...rest,
                    width: 0,
                    workflow: recordId,
                    parent_stage: parent_stage?.id,
                    only_parent_stages: true,
                    rebase: true,
                  },
                  url: stagesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                refetchStages();
                return response?.data?.id;
              } catch (err) {
                console.log('error 99', err.response || err.message);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={stageRowsMap}
          urlParams={`&workflow=${recordId}&only_parent_stages=true`}
          customSortColumnName="order"
          sortColumnBy="asc"
          getListData={(data) => setStageListData(data)}
          refetchFunction={() => refetchStages()}
        />
        {manualWorkflow && metaModelDetails ? (
          <DetailAccordionView
            editMode={editMode}
            labels={labels}
            microservice={microservices.BPA.name}
            model={microservices.BPA.models.manualWorkflowInstance}
            columnKey="manualWorkflowInstance"
            columnOptions={workflowInstancesColOptions}
            label="Workflow Instances"
            prefix="0pe"
            urls={manualWorkflowInstancesUrls}
            addNewForm={{
              getFields: () =>
                ManualWorkflowInstanceForm({
                  workflow: recordData,
                  metaModel: metaModelDetails,
                }),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError
              ) => {
                try {
                  const response = await createRecord({
                    values: {
                      ...values,
                      stage: values?.stage?.id,
                      entity_id: values?.entity_id?.id,
                    },
                    url: manualWorkflowInstancesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });
                  resetForm();
                  setAddNewForm(false);
                  refetch();
                  return response?.data?.id;
                } catch (err) {
                  console.log('error 99', err.response || err.message);
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={manualWorkflowInstanceRowsMap}
            urlParams={`&workflow=${recordId}`}
            customSortColumnName="order"
            sortColumnBy="asc"
          />
        ) : null}

        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          microservice={microservices.BPA.name}
          model={microservices.BPA.models.modelAsset}
          columnKey="asset"
          columnOptions={assetColOptions}
          label="Asset"
          prefix="0ax"
          urls={modelAssetsUrls}
          deleteBtnLabel={'Remove From Automata'}
          addNewForm={{
            getFields: ({ values }) =>
              AssetForm({
                values,
                stages,
                setStageTerm,
                fetchingStages,
              }),
            handleSubmit: async (
              { assetModel, asset, stages },
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                await createRecord({
                  values: {
                    workflow: recordId,
                    stages: stages.map((stage) => stage.id),
                    asset_id: asset?.id,
                    microservice: assetModel?.microservice,
                    model: assetModel?.model,
                  },
                  url: modelAssetsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log('error', err?.response?.data ?? err?.message);
                handleCreateFormErrors({ err, setError, notify, values: {} });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={assetRowsMap}
          urlParams={`&workflow=${recordId}`}
        />

        {!manualWorkflow ? (
          <DetailAccordionView
            label="Logs"
            prefix="0x0"
            editMode={editMode}
            labels={labels}
            microservice={microservices.LOGS.name}
            model={microservices.LOGS.models.shapeTransitionLog}
            urls={shapeTransitionLogUrls}
            columnKey='shapeTransitionSystem'
            handleRowsMap={shapeTransitionMap}
            urlParams={`&workflow=${recordId}`}
            customSortColumnName="created_at"
            hideActions
          />
        ) : null}
        <DetailAccordionView
          columnKey="inasCRM"
          label="INAs"
          editMode={editMode}
          labels={labels}
          prefix="0r4"
          urls={actionRemindersUrls}
          handleRowMap={inasRowsMap}
          urlParams={`&entity=WorkflowDefn&entity_microservice=BPA&reminder_type=INA Reminder Event&entity_id=${recordId}`}
        />
      </Box>
      <ImportStagesModal
        open={importStageModalOpen}
        setOpen={setImportStageModalOpen}
        workflowId={recordId}
        prefix="0h9"
        editMode={editMode}
        labels={labels}
      />
      <StartAutomationPrompt
        recordData={recordData}
        open={startAutomationModalOpen}
        disabledStartAutomationButton={startAutomationModalDisable}
        onStartAutomationClick={onStartAutomationClick}
        onCancelClick={() => {
          setStartAutomationModalOpen(false);
          setStartAutomationModalDisable(false);
        }}
        prefix="0sq"
        prefix2="08q"
        editMode={editMode}
        labels={labels}
      />
      {recordData ? (
        <UpdateWorkingTime
          recordData={recordData}
          open={workinTimeModalOpen}
          onCancelClick={() => {
            setWorkinTimeModalOpen(false);
          }}
          setWorkinTimeModalOpen={setWorkinTimeModalOpen}
        />
      ) : null}
    </Box>
  );
}
