import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { accountUrls } from '@config/routes';
import { modelHandleRow } from '@config/handleRows/accounts';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';

const { modelsUrls } = accountUrls;

export default function Models({ labels, editMode }) {
  const user = useSelector(selectProfile);

  const isSuperUserPermission =
    user?.details?.roles_names.includes('Superusers') ||
    user?.details?.roles_names.includes('Company Administrator') ||
    user?.details?.is_superuser;

  return (
    <Box>
      <ListView
        label='Models'
        prefix='0gu'
        labels={labels}
        editMode={editMode}
        microservice='Accounts'
        model='Model'
        urls={modelsUrls}
        columnKey='modelsAccounts'
        handleRowsMap={modelHandleRow}
        showDeleteBtn={isSuperUserPermission}
        showBulkDeleteButton={isSuperUserPermission}
      />
    </Box>
  );
}
