import React from 'react';
import { Box, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { cmsV2Urls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { pageTopageGroupRowsMap } from '@config/handleRows/cms-v2';
import { PageToPageGroupForm } from '@config/forms/cms-v2';
import { pageGroupMeta } from '@config/meta/cms-v2';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
// import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { pageGroupsUrls, pageToPageGroupsUrls } = cmsV2Urls;

export default function PageGroupDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const queryClient = useQueryClient();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => pageGroupMeta({ data, queryClient }),
    []
  );

  const getPreviewLink = (domain, slug) => {
    return `${domain}${slug}`;
  };

  const pageToPageGroupColOptions = React.useMemo(
    () => ({
      hidePage: false,
      hidePageGroups: true,
    }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/page-groups', text: 'Page Group' },
              { text: recordData?.name ?? 'Unnamed' },
            ]}
          >
            <a
              href={getPreviewLink(
                recordData?.domain?.domain,
                recordData?.slug
              )}
              target='_blank'
              rel='noreferrer'
            >
              <Button variant='outlined' sx={{ mr: 2 }}>
                Preview
              </Button>
            </a>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={pageGroupsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          label='Pages'
          prefix='0gg'
          labels={labels}
          editMode={editMode}
          columnKey='pageToPageGroup'
          columnOptions={pageToPageGroupColOptions}
          urls={pageToPageGroupsUrls}
          microservice={microservices.CMS.name}
          model={microservices.CMS.models.pageToPageGroups}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              PageToPageGroupForm({
                hidePage: false,
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { pageId, ...rest } = values;

                if (!pageId?.id) {
                  setFieldError('pageId', 'This field is required');
                  return;
                }

                const { data } = await createRecord({
                  values: {
                    ...rest,
                    pageId: pageId?.id,
                    pageGroupId: recordId,
                  },
                  url: pageToPageGroupsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                refetch();
                resetForm();
                setAddNewForm(false);
                return data?.id;
              } catch (err) {
                // handleCreateFormErrors({ err, setError, notify, values });
                notify(`One page can only be part of one group.`, {
                  type: 'ERROR',
                });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={pageTopageGroupRowsMap}
          urlParams={`&pageGroupId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
