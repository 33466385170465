import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import LinkIcon from '@mui/icons-material/Link';
import ReconnectImapAccountDialog from '@components/Communication/ReconnectImapAccountDialog';
import DisconnectPromptModal from '@components/Communication/DisconnectPromptModal';
import PromptModal from '@components/PromptModal';
import getStatusRejectedPromises from '@config/functions/getStatusRejectedPromises';
import { selectProfile } from '@redux/profileSlice';
import { communicationUrls } from '@config/routes';
import { createRecord, updateRecord } from '@config/functions/requests';
import {
  ImapAccountForm,
  ImapAccountDialogForm,
} from '@config/forms/communication';
import { imapAccountRowsMap } from '@config/handleRows/communication';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import useAutocomplete from '@hooks/useAutocomplete';
import { useQueryClient } from 'react-query';
import AuthWrapper from '@components/Communication/AuthWrapper';
import SetPassphraseModal from '@components/Communication/SetPassphraseModal';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DisableEncryptionModal from '@components/Communication/DisableEncryptionModal';

const { imapAccountsUrls, imapProvidersUrls } = communicationUrls;

function ImapAccounts({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [cb, setCB] = React.useState({ id: null, refetch: () => null });
  const [imapProviderTerm, setImapProviderTerm] = React.useState('');
  const [openPassphraseModal, setOpenPassphraseModal] = React.useState(false);
  const [disconnectModalOpen, setDisconnectModalOpen] = React.useState(false);
  const [disconnectModalSubmitting, setDisconnectModalSubmitting] =
    React.useState(false);
  const [reconnectDialogOpen, setReconnectDialogOpen] = React.useState(false);

  const [selectedRow, setSelectedRow] = React.useState(null);
  const queryClient = useQueryClient();
  const [notify, update] = useToast();
  const [showDisableEncyptModal, setShowDisableEncyptModal] =
    React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalPrimaryBtnDisable, setModalPrimaryBtnDisable] =
    React.useState(false);
  const [selectedEncryption, setSelectedEncryption] = React.useState(null);

  const {
    data: providers,
    isFetching: fetchingProviders,
    refetch: refetchProviders,
  } = useAutocomplete({
    key: [
      'imap-providers-list',
      user.details.encryption_public_key,
      user.encryptionToken,
      user.token,
      user?.actAs,
    ],
    getUrl: imapProvidersUrls.list,
    inputValue: imapProviderTerm,
  });

  const handleFunctions = {
    disableImapAccounts: async (
      selectedRows,
      refetch,
      setSelectedRows,
      disableModalOpen,
      setDisableModalOpen,
      urls
    ) => {
      setDisableModalOpen(false);
      const rows = selectedRows;
      setSelectedRows([]);
      const toastId = notify(`Disconnecting Imap Accounts!`, {
        type: 'INFO',
      });

      const promises = rows.map((rowId) =>
        updateRecord({
          url: urls.detail(rowId),
          values: { disable: true },
          token: user.token,
          actAs: user?.actAs,
          encryptionToken: user.encryptionToken,
        })
      );

      const rejectedPromises = getStatusRejectedPromises(
        await Promise.allSettled(promises)
      );

      if (rejectedPromises.length) {
        update(
          toastId,
          `Sorry Something Went Wrong We Weren't Able To Disable Imap Accounts!`,
          {
            type: 'ERROR',
          }
        );
        return;
      }

      refetch();
      queryClient.invalidateQueries(`drawer-imap-accts-${user.token}`);
      queryClient.invalidateQueries('Imap-Accounts-listData');
      update(toastId, `Imap Accounts Disconnected!`, {
        type: 'SUCCESS',
      });
    },
    disableImapAccount: async () => {
      setDisconnectModalSubmitting(true);

      try {
        await updateRecord({
          url: imapAccountsUrls.detail(selectedRow.id),
          values: { disable: true },
          token: user.token,
          actAs: user?.actAs,
          encryptionToken: user.encryptionToken,
        });

        // notify('Imap Account Disconnected!', {
        //   type: 'SUCCESS',
        // });

        queryClient.invalidateQueries(`drawer-imap-accts-${user.token}`);
        queryClient.invalidateQueries('Imap-Accounts-listData');
        setSelectedRow({});
        setDisconnectModalOpen(false);
      } catch (err) {
        console.log(err);
        notify(`There was an error, please try again.`, {
          type: 'ERROR',
        });
      } finally {
        setDisconnectModalSubmitting(false);
      }
    },
    encryptionUpdate: async (values) => {
      try {
        const { data } = await updateRecord({
          values: { ...values },
          url: imapAccountsUrls.detail(values.id),
          token: user.token,
          actAs: user?.actAs,
          encryptionToken: user.encryptionToken,
        });
        // notify(
        //   `Encryption ${values.encryption_enabled ? 'Enabled' : 'Disabled'}!`,
        //   {
        //     type: 'SUCCESS',
        //   }
        // );
        queryClient.invalidateQueries(`drawer-imap-accts-${user.token}`);
        queryClient.invalidateQueries('Imap-Accounts-listData');
        return { id: data.id, values };
      } catch (err) {
        console.log(err);
        notify('Sorry, something went wrong!', {
          type: 'ERROR',
        });
      }
    },
  };

  const handleSubmit = async (
    values,
    setSubmitting,
    resetForm,
    setAddNewForm,
    refetch,
    setError,
    type = 'Add'
  ) => {
    try {
      let error = false;

      if (
        values.send_emails_from_this_account &&
        (!values.smtp_host || !values.smtp_host.length)
      ) {
        setError({ smtp_host: 'Smtp Host is required field!' });
        error = true;
      }

      if (values.send_emails_from_this_account && !values.smtp_port) {
        setError({ smtp_port: 'Smtp Port is required field!' });
        error = true;
      }

      if (
        values.send_emails_from_this_account &&
        (!values.smtp_username || !values.smtp_username.length)
      ) {
        setError({ smtp_username: 'Smtp Username is required field!' });
        error = true;
      }

      if (
        values.send_emails_from_this_account &&
        (!values.smtp_password || !values.smtp_password.length)
      ) {
        setError({ smtp_password: 'Smtp Password is required field!' });
        error = true;
      }

      if (error) {
        return;
      }

      const newValues = { ...values };

      if (!values.send_emails_from_this_account) {
        newValues.smtp_host = '';
        newValues.smtp_port = null;
        delete newValues.smtp_connection_type;
        newValues.smtp_username = '';
        newValues.smtp_password = '';
      }
      const { provider, ...rest } = newValues;
      const { data } = newValues.id
        ? await updateRecord({
            values: { ...newValues, everyone_in_obj_company_can_see_it: false },
            url: imapAccountsUrls.detail(newValues.id),
            token: user.token,
            actAs: user?.actAs,
            encryptionToken: user.encryptionToken,
          })
        : await createRecord({
            values: {
              ...rest,
              encryption_enabled: false,
              everyone_in_obj_company_can_see_it: false,
              provider: provider?.id,
            },
            url: imapAccountsUrls.list(),
            token: user.token,
            actAs: user?.actAs,
            encryptionToken: user.encryptionToken,
          });
      resetForm();
      setAddNewForm(false);
      // notify(
      //   `IMAP Account ${type === 'Reconnect' ? 'Reconnected' : 'Created'}`,
      //   {
      //     type: 'SUCCESS',
      //   }
      // );

      if (values.encryption_enabled && !user.details.encryption_public_key) {
        setOpenPassphraseModal(true);
        setCB({ id: data.id, refetch });
      } else {
        refetch();
        queryClient.invalidateQueries('Imap-Accounts-listData');
        queryClient.invalidateQueries(`drawer-imap-accts-${user.token}`);
      }
      return { id: data.id, values };
    } catch (err) {
      handleCreateFormErrors({ err, setError, notify, values });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {selectedRow && selectedRow.id ? (
        <DisableEncryptionModal
          rowId={selectedRow && selectedRow.id}
          row={selectedRow}
          open={showDisableEncyptModal}
          handleClose={() => setShowDisableEncyptModal(false)}
          editMode={editMode}
          labels={labels}
          prefix="0er"
          prefix2="0pn"
          prefix3="0c5"
        />
      ) : null}

      <SetPassphraseModal
        cb={cb}
        open={openPassphraseModal}
        setOpen={setOpenPassphraseModal}
      />

      <Box>
        <ListView
          label="Imap Accounts"
          prefix="0wx"
          labels={labels}
          editMode={editMode}
          microservice={microservices.NOTIFICATION.name}
          model={microservices.NOTIFICATION.models.imapAccounts}
          showBulkDeleteButton={false}
          showDeleteBtn={false}
          urls={imapAccountsUrls}
          dynamicActions={(params) => [
            {
              icon: <VpnKeyIcon sx={{ mr: 1 }} />,
              label: 'Passphrase',
              color: '#333',
              handleClick: (row) => () => {
                setSelectedRow(row);
                setShowDisableEncyptModal(true);
              },
            },
            {
              icon: <LinkIcon sx={{ mr: 1 }} />,
              label: params.row.connected ? 'Disconnect' : 'Reconnect',
              color: params.row.connected ? '#ff1a1a' : '#808080',
              handleClick: (row) => () => {
                setSelectedRow(row);
                params.row.connected
                  ? setDisconnectModalOpen(true)
                  : setReconnectDialogOpen(true);
              },
            },
          ]}
          getRowClassName={(params) =>
            !params.row.connected ? `disable-row` : null
          }
          addFunctions={handleFunctions}
          columnKey="imapAccountCommunication"
          customInitialValues={{
            everyone_in_object_company_can_see_it: false,
          }}
          addNewForm={{
            getFields: ({ values, setFieldValue }) => {
              return ImapAccountForm({
                values,
                setFieldValue,
              });
            },
            handleSubmit,
          }}
          handleRowsMap={imapAccountRowsMap}
        />
        {selectedRow ? (
          <>
            <DisconnectPromptModal
              key="disable-prompt-modal"
              open={disconnectModalOpen}
              disabledDisableButton={disconnectModalSubmitting}
              onCancelClick={() => {
                setSelectedRow({});
                setDisconnectModalOpen(false);
              }}
              onDisableClick={() => handleFunctions.disableImapAccount()}
              editMode={editMode}
              labels={labels}
              prefix="0yl"
              prefix2="0wy"
            />
            <ReconnectImapAccountDialog
              columnKey="imapAccountCommunication"
              row={selectedRow}
              refetch={() => {
                queryClient.invalidateQueries(
                  `drawer-imap-accts-${user.token}`
                );
                queryClient.invalidateQueries('Imap-Accounts-listData');
              }}
              open={reconnectDialogOpen}
              handleSubmit={async (
                values,
                setSubmitting,
                resetForm,
                setOpen,
                refetch,
                setError
              ) => {
                await handleSubmit(
                  values,
                  setSubmitting,
                  resetForm,
                  setOpen,
                  refetch,
                  setError,
                  'Reconnect'
                );
              }}
              setOpen={setReconnectDialogOpen}
              getFields={({ values }) => {
                return ImapAccountDialogForm({
                  values,
                  providers,
                  fetchingProviders,
                  setProviderTerm: setImapProviderTerm,
                  refetchProviders,
                  providerDefaultValue: selectedRow.row.provider,
                });
              }}
            />
          </>
        ) : null}
        {selectedEncryption ? (
          <PromptModal
            open={modalOpen}
            headline={'Are you Absolutely Sure?'}
            primaryLabel={
              selectedEncryption.row.encryption_enabled ? 'Disable' : 'Enable'
            }
            primaryColor={'error'}
            disablePrimaryButton={modalPrimaryBtnDisable}
            message={
              <>
                This action <strong className="text-gray-700">cannot</strong> be
                undone. This will permanently delete all the data associated
                with it!
                <br />
                This action may <strong className="text-gray-700">
                  take
                </strong>{' '}
                few minutes.
              </>
            }
            onSecondaryClick={() => {
              setModalPrimaryBtnDisable(false);
              setSelectedEncryption(null);
              setModalOpen(false);
            }}
            onPrimaryClick={async () => {
              setModalPrimaryBtnDisable(true);
              await handleFunctions.encryptionUpdate({
                id: selectedEncryption.id,
                encryption_enabled: !selectedEncryption.row.encryption_enabled,
              });
              setSelectedEncryption(null);
              setModalOpen(false);
              setModalPrimaryBtnDisable(false);
            }}
          />
        ) : null}
      </Box>
    </>
  );
}

export default function AuthenticatedImapAccounts(props) {
  return (
    <AuthWrapper>
      <ImapAccounts {...props} />
    </AuthWrapper>
  );
}
