import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUniversity,
  faChevronRight,
  faBookOpen,
  faList,
  faFile,
} from '@fortawesome/free-solid-svg-icons';

export default function TopBar({ title, active, setActive, hideUni }) {
  return (
    <Box sx={{ width: '850px', mx: 'auto', mb: 3 }}>
      <Typography
        sx={{
          fontSize: '1.65rem',
          mb: 1,
        }}
      >
        {title ?? ''}
      </Typography>

      <Stack
        direction='row'
        alignItems='center'
        spacing={2}
        sx={{
          height: '48px',
          background: '#fff',
          borderRadius: '2px',
          boxShadow: 'rgba(61, 66, 78, 0.3) 0px 1px 2px',
          px: '16px',
        }}
      >
        {hideUni ? null : (
          <>
            <Button onClick={() => setActive('university')}>
              <FontAwesomeIcon
                icon={faUniversity}
                color={active === 'university' ? '#203FE8' : '#A0A0A0'}
              />
              <Typography
                sx={{
                  color: active === 'university' ? '#203FE8' : '#A3A3A2',
                  fontWeight: '500',
                  ml: 1,
                }}
              >
                University
              </Typography>
            </Button>

            <Box>
              <FontAwesomeIcon icon={faChevronRight} color='#A0A0A0' />
            </Box>
          </>
        )}

        <Button onClick={() => setActive('course')}>
          <FontAwesomeIcon
            icon={faBookOpen}
            color={active === 'course' ? '#203FE8' : '#A0A0A0'}
          />
          <Typography
            sx={{
              color: active === 'course' ? '#203FE8' : '#A3A3A2',
              fontWeight: '500',
              ml: 1,
            }}
          >
            Course
          </Typography>
        </Button>

        <Box>
          <FontAwesomeIcon icon={faChevronRight} color='#A0A0A0' />
        </Box>

        <Button onClick={() => setActive('module')}>
          <FontAwesomeIcon
            icon={faList}
            color={active === 'module' ? '#203FE8' : '#A0A0A0'}
          />
          <Typography
            sx={{
              color: active === 'module' ? '#203FE8' : '#A3A3A2',
              fontWeight: '500',
              ml: 1,
            }}
          >
            Module
          </Typography>
        </Button>

        <Box>
          <FontAwesomeIcon icon={faChevronRight} color='#A0A0A0' />
        </Box>

        <Button onClick={() => setActive('lesson')}>
          <FontAwesomeIcon
            icon={faFile}
            color={active === 'lesson' ? '#203FE8' : '#A0A0A0'}
          />
          <Typography
            sx={{
              color: active === 'lesson' ? '#203FE8' : '#A3A3A2',
              fontWeight: '500',
              ml: 1,
            }}
          >
            Lesson
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
