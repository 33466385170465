import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import useToast from '@hooks/useToast';
import { systemUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { updateRecord } from '@config/functions/requests';
import { useQueryClient } from 'react-query';
const { uiPrefixesUrls } = systemUrls;

export default function RenderUiPrefix({
  editMode,
  labels,
  code,
  language,
  children,
  defaultValue,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [text, setText] = React.useState('');
  const [prefixId, setPrefixId] = React.useState('');
  const [inputText, setInputText] = React.useState('');
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = async () => {
    try {
      await updateRecord({
        url: uiPrefixesUrls.detail(prefixId),
        values: { label_en: inputText },
        token: user.token,
        actAs: user?.actAs,
      });
      queryClient.invalidateQueries('ui-labels-text');
      setText(inputText);
      setShowModal(false);
    } catch (err) {
      console.error(err.response || err.message);
      notify('There was an error, please try again', {
        type: 'ERROR',
      });
    }
  };

  // update text and inputText state when code, editMode, labels, or language change
  React.useEffect(() => {
    if (!Array.isArray(labels)) return;

    const label = labels.find((r) => r.code === code);
    if (!label) return;

    let prop = 'label_en';
    if (language === 'French') {
      prop = 'label_fr';
    } else if (language === 'German') {
      prop = 'label_de';
    }

    setText(label[prop] || label.label_en);
    setInputText(label[prop] || label.label_en);
    setPrefixId(label.id);
  }, [code, editMode, labels, language]);

  if (!text || !labels || !editMode) return children(text || defaultValue);

  return (
    <>
      <Box
        className="editable-ui-label"
        sx={{ userSelect: 'none', cursor: 'pointer' }}
        onClick={() => setShowModal(true)}
      >
        <Box sx={{ pointerEvents: 'none' }}>{children(`${code} ${text}`)}</Box>
      </Box>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Text</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={code}
                  fullWidth
                  disabled
                  label="Code"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="Text"
                  variant="outlined"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  autoComplete="off"
                  multiline
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
