import * as React from 'react';
import {
  DAILY_RRULE,
  WEEKLY_RRULE,
  MONTHLY_RRULE,
  ANNUALLY_RRULE,
  WEEKDAYS_RRULE,
  calendarRecurringChoicesOptions,
} from '@config/constants';
import { detectRrule } from '@config/functions/calendarFunctions';
import { MenuItem } from '@mui/material';
import Select from '@ui/Inputs/SelectField';
import CustomRecurranceModal from '@components/Calendar/CustomRecurranceModal';

export default function RepeatField({
  field,
  label,
  showDoesNotRepeat = true,
  rrule = '',
  setCustomValue = () => null,
  showCancel = false,
  ...rest
}) {
  const [rruleValue, setRruleValue] = React.useState(rrule);
  const [customModal, setCustomModal] = React.useState(false);
  const choiceValue = {
    rrule: rruleValue,
    value: detectRrule(rruleValue)?.value,
  };

  const setRrrule = (rrule) => {
    setRruleValue(rrule);
    setCustomValue(rrule);
  };

  const handleChange = (e) => {
    let rrule = '';

    if (e.target.value === 'daily') {
      rrule = DAILY_RRULE;
    } else if (e.target.value === 'weekly') {
      rrule = WEEKLY_RRULE;
    } else if (e.target.value === 'monthly') {
      rrule = MONTHLY_RRULE;
    } else if (e.target.value === 'annually') {
      rrule = ANNUALLY_RRULE;
    } else if (e.target.value === 'weekdays') {
      rrule = WEEKDAYS_RRULE;
    } else if (e.target.value === 'custom') {
      return;
    }

    setRrrule(rrule);
  };

  return (
    <>
      <Select
        {...rest}
        label={label}
        value={choiceValue?.value}
        name={field}
        onChange={handleChange}
        onClose={(e) =>
          e.target.dataset.value === 'custom' && setCustomModal(true)
        }
      >
        {calendarRecurringChoicesOptions &&
          calendarRecurringChoicesOptions
            .filter((o) =>
              o.value === 'does_not_repeat' ? showDoesNotRepeat : true
            )
            .map((o, oIdx) => (
              <MenuItem value={o.value} key={oIdx.toString()}>
                {o.label}
              </MenuItem>
            ))}
      </Select>
      <CustomRecurranceModal
        open={customModal}
        setOpen={setCustomModal}
        oSave={(rrule) => {
          setRrrule(rrule);
          setCustomModal(false);
        }}
        showCancel={showCancel}
        rrule={choiceValue?.rrule}
      />
    </>
  );
}
