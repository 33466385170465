import * as Yup from 'yup';

import { fieldType } from '@config/constants';
import { listsUrls } from '@config/routes';

const { countriesUrls, currenciesUrls, languagesUrls } = listsUrls;

export const countryMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    to: '/countries/',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'iso2',
    headerName: 'Iso2',
    width: 180,
    editable: true,
    value: data && data.iso2,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'iso3',
    headerName: 'Iso3',
    width: 180,
    editable: true,
    value: data && data.iso3,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'dialcode',
    headerName: 'Dialcode',
    width: 180,
    editable: true,
    value: data && data.dialcode,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'continent',
    headerName: 'Continent',
    width: 180,
    editable: true,
    value: data && data.continent,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'tld',
    headerName: 'Tld',
    width: 180,
    editable: true,
    value: data && data.tld,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const sicCodesMeta = ({ data } = {}) => [
  {
    field: 'code',
    headerName: 'Code',
    width: 180,
    editable: true,
    value: data && data.code,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 285,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const currencyMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    to: '/currencies/',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 180,
    editable: true,
    value: data && data.code,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const socialMediaMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'url',
    headerName: 'Url',
    width: 180,
    editable: true,
    value: data && data.url,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const timeZonesMeta = ({ data } = {}) => [
  {
    field: 'code',
    headerName: 'Code',
    width: 180,
    editable: true,
    value: data && data.code,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'utc_offset',
    headerName: 'Utc offset',
    width: 180,
    editable: true,
    value: data && data.utc_offset,
    initialValue: '',
    onForm: true,
    type: 'number',
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const countryCurrencyMeta = ({
  data,
  hideCountry,
  hideCurrency,
} = {}) => [
  {
    field: 'country',
    headerName: 'Country',
    width: 180,
    editable: true,
    onForm: true,
    hideColumn: hideCountry,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: countriesUrls.list,
    requestKey: `${data?.id}-country-currency`,
    validate: Yup.mixed(),
    initialValue: data?.country
      ? {
          id: data?.country,
          label: data?.details?.country?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 180,
    editable: true,
    onForm: true,
    hideColumn: hideCurrency,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: currenciesUrls.list,
    requestKey: `${data?.id}-currency-country`,
    validate: Yup.mixed(),
    initialValue: data?.currency
      ? {
          id: data?.currency,
          label: data?.details?.currency?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const languageMeta = ({ data } = {}) => [
  {
    field: 'iso_639_1',
    headerName: 'Iso_639_1',
    width: 180,
    editable: true,
    value: data && data.iso_639_1,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'iso_639_2',
    headerName: 'Iso_639_2',
    width: 180,
    editable: true,
    value: data && data.iso_639_2,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'english',
    headerName: 'English',
    to: '/languages/',
    width: 180,
    editable: true,
    value: data && data.english,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'french',
    headerName: 'French',
    width: 180,
    editable: true,
    value: data && data.french,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'german',
    headerName: 'German',
    width: 180,
    editable: true,
    value: data && data.german,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const countryLanguageMeta = ({
  data,
  hideCountry,
  hideLanguage,
} = {}) => [
  {
    field: 'country',
    headerName: 'Country',
    width: 180,
    editable: true,
    onForm: true,
    hideColumn: hideCountry,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: countriesUrls.list,
    requestKey: `${data?.id}-country-language`,
    validate: Yup.mixed(),
    initialValue: data?.country
      ? {
          id: data?.country,
          label: data?.details?.country?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'language',
    headerName: 'Language',
    width: 180,
    editable: true,
    onForm: true,
    hideColumn: hideLanguage,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: languagesUrls.list,
    requestKey: `${data?.id}-country-language`,
    validate: Yup.mixed(),
    initialValue: data?.language
      ? {
          id: data?.language,
          label: data?.details?.language?.english,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.english,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const cityMeta = ({
  data,
  hideCountry,
} = {}) => [
  {
    field: 'country',
    headerName: 'Country',
    width: 180,
    editable: true,
    onForm: true,
    hideColumn: hideCountry,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: countriesUrls.list,
    requestKey: `${data?.id}-country-city`,
    validate: Yup.mixed(),
    initialValue: data?.country
      ? {
          id: data?.country,
          label: data?.details?.country?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    editable: true,
    type: fieldType.TEXT,
    value: data && data.name,
    initialValue: '',
    onAccordionForm: true,
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'capital',
    headerName: 'Capital',
    onForm: true,
    type: 'boolean',
    width: 90,
    editable: true,
    value: data ? data.capital : null,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'latitude',
    headerName: 'Latitude',
    onForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.latitude : null,
    initialValue: 1,
    validate: Yup.number().min(1).max(32767),
  },
  {
    field: 'longitude',
    headerName: 'Longitude',
    onForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.longitude : null,
    initialValue: 1,
    validate: Yup.number().min(1).max(32767),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const postCodesMeta = ({
  data
}={}) => [
  {
    field: 'code',
    headerName: 'Code',
    width: 180,
    editable: true,
    value: data && data.code,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 180,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: countriesUrls.list,
    requestKey: `${data?.id}-country-postcode`,
    validate: Yup.mixed(),
    initialValue: data?.country
      ? {
          id: data?.country,
          label: data?.details?.country?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'city',
    headerName: 'City',
    width: 180,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: countriesUrls.list,
    requestKey: `${data?.id}-postcode-city`,
    validate: Yup.mixed(),
    initialValue: data?.city
      ? {
          id: data?.city,
          label: data?.details?.city?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'region',
    headerName: 'Region',
    width: 180,
    editable: true,
    value: data && data.region,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'uk_region',
    headerName: 'Uk Region',
    width: 180,
    editable: true,
    value: data && data.uk_region,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'latitude',
    headerName: 'Latitude',
    onForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.latitude : null,
    initialValue: '',
    validate: Yup.number().min(1).max(32767),
  },
  {
    field: 'longitude',
    headerName: 'Longitude',
    onForm: true,
    type: 'number',
    width: 90,
    editable: true,
    value: data ? data.longitude : null,
    initialValue: '',
    validate: Yup.number().min(1).max(32767),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
