import React from 'react';
import * as Yup from 'yup';
import { cmsV2Urls } from '@config/routes';
import { pageTemplateMeta } from '@config/meta/cms-v2';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { pageTemplatesUrls } = cmsV2Urls;

export default function PageTemplatesDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  
  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title='Page Templates'
        initialValues={{
          ...getAddFormInitialValues(pageTemplateMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(pageTemplateMeta(), 'listView'),
        })}
        getPostUrl={() => pageTemplatesUrls.list()}
        getValues={(values) => ({
            ...values,
          })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
           <Grid item xs={6}>
                <TextField label='Name' name='name' required />
            </Grid>

            <Grid item xs={6}>
                <TextField label='Tags' name='tags' />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
