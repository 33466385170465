import React from 'react';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import UserDropdown from '@dropdown-forms/pm/UserDropdown';
import { accountUrls } from '@config/routes';
import { useQueryClient } from 'react-query';

const { usersUrls } = accountUrls;

export const AgentForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient()
  return(
  <>
    <Grid item xs={3}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item sm={3}>
       <UserDropdown 
          refetch={({ id, email }) => {
            setFieldValue('user', { id, label: email });
            queryClient.invalidateQueries(['agent-user']);
          }}
        >
          <AutocompleteFieldV2
            name='user'
            label='User'
            requestKey='agent-user'
            fetchUrl={usersUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.email,
            })}
            required
          />
        </UserDropdown>
    </Grid>
  </>
)};
