import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailCard from '@components/core/DetailCard';
import DetailPageHeader from '@components/DetailPageHeader';
import { hrUrls } from '@config/routes';
import { departmentMeta } from '@config/meta/hr';
import DetailAccordionView from '@components/DetailAccordionView';
import { DepartmentEmployeeForm } from '@config/forms/hr';
import { microservices } from '@config/constants';
import { departmenEmployeeHandleRowMap } from '@config/handleRows/hr';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import useToast from '@hooks/useToast';
import { useQueryClient } from 'react-query';

const { departmentsUrls, employeeDepartmentsUrls } = hrUrls;

export default function DepartmentDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => departmentMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/departments`, text: 'Departments' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={departmentsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          columnKey="departmentEmployeeHR"
          label="Employees"
          prefix="0sr"
          labels={labels}
          editMode={editMode}
          microservice={microservices.HR.name}
          model={microservices.HR.models.employeeDepartment}
          urls={employeeDepartmentsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              DepartmentEmployeeForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { employee, ...rest } = values;

                if (!employee?.id) {
                  setFieldError('employee', 'This field is required');
                  return;
                }

                await createRecord({
                  values: {
                    employee: employee?.id,
                    department: recordId,
                    ...rest,
                  },
                  url: employeeDepartmentsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={departmenEmployeeHandleRowMap}
          urlParams={`&department=${recordId}`}
        />
      </Box>
    </Box>
  );
}
