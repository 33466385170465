import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { TextField, SelectField, AutocompleteFieldV2 } from '@components/Inputs';
import { statusChoicesOptions } from '@config/constants';
import { cmsUrls } from '@config/routes';

const { pagesUrls, pageCategoryUrls } = cmsUrls;


export const PageForm = () => (
  <>
    <Grid item sm={8}>
      <AutocompleteFieldV2
        name='pageId'
        label='Page Template'
        requestKey='cms-templates'
        fetchUrl={pagesUrls.list}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.page_name
        })}
        required
      />
    </Grid>
  </>
);

export const CategoryForms = () => (
  <>
    <Grid item sm={3}>
      <TextField key='category_name' label='Name' name='category_name' />
    </Grid>

    <Grid item sm={5}>
      <TextField
        key='category_description'
        label=' Description'
        name='category_description'
      />
    </Grid>
  </>
);

export const ContentForms = () => (
  <>
    <Grid item sm={4}>
      <TextField key='name' label='Name' name='name' />
    </Grid>
    <Grid item xs={4}>
      <SelectField label='Status' name='status'>
        {statusChoicesOptions.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>
    <Grid item xs={4}>
      <TextField label='URL' name='url' placeholder='/example/' required />
    </Grid>
    <Grid item sm={12}>
      <TextField
        multiline
        key='content'
        label=' Content'
        name='content'
        required
      />
    </Grid>
  </>
);
export const pageCategoryForms = () => (
  <>
    <Grid item xs={4}>
    <AutocompleteFieldV2
        name='page_id'
        label='Pages'
        requestKey='cms-page'
        fetchUrl={pagesUrls.list}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.page_name
        })}
        required
      />
    </Grid>
    <Grid item xs={4}>
    <AutocompleteFieldV2
        name='category_id'
        label='Category'
        requestKey='cms-page'
        fetchUrl={pageCategoryUrls.list}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.category_name
        })}
        required
      />
    </Grid>
  </>
);
export const pageMetaForm = () => (
  <>
    <Grid item xs={3}>
      <TextField label='key' name='key' />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Value' name='value' />
    </Grid>

    <Grid item xs={3}>
    <AutocompleteFieldV2
        name='page_id'
        label='Pages'
        requestKey='cms-page'
        fetchUrl={pagesUrls.list}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.page_name
        })}
        required
      />
    </Grid>
  </>
);
