import Accordion from '@ui/Accordion';
import ListView from './ListView';

export default function DetailAccordion({
  label,
  pageHeaderFormHeaderText,
  addNewForm,
  expanded,
  onChange,
  microservice = '',
  model = '',
  urls,
  columnKey,
  columnOptions,
  handleRowMap,
  urlParams,
  handleFunctions,
  rowActions,
  headerContent,
  startFetching,
  customSortColumnName,
  sortColumnBy,
  getDetailPanelContent,
  editMode,
  labels,
  prefix,
  onFormCancel,
  nodeAPI,
  hideSearch,
  autoHeight,
  dynamicActions,
  disabled,
  ...rest
}) {
  return (
    <Accordion
      disabled={disabled}
      editMode={editMode}
      labels={labels}
      prefix={prefix}
      label={label}
      expanded={expanded}
      onChange={onChange}
    >
      <ListView
        nodeAPI={nodeAPI}
        label={label}
        pageHeaderFormHeaderText={pageHeaderFormHeaderText}
        microservice={microservice}
        model={model}
        urls={urls}
        columnOptions={columnOptions}
        columnKey={columnKey}
        addNewForm={addNewForm}
        handleRowsMap={handleRowMap}
        urlParams={urlParams}
        handleFunctions={handleFunctions}
        rowActions={rowActions}
        view="DetailAccordionView"
        headerContent={headerContent}
        startFetching={startFetching}
        customSortColumnName={customSortColumnName}
        sortColumnBy={sortColumnBy}
        getDetailPanelContent={getDetailPanelContent}
        onFormCancel={onFormCancel}
        editMode={editMode}
        labels={labels}
        prefix={prefix}
        hideSearch={hideSearch}
        autoHeight={autoHeight}
        dynamicActions={dynamicActions}
        {...rest}
      />
    </Accordion>
  );
}
