import React from 'react';
import * as Yup from 'yup';
import { crmUrls } from '@config/routes';
import { pipelineStageMeta } from '@config/meta/crm/index';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import PipelineDropdown from '@dropdown-forms/crm/PipelineDropdown';
import { useQueryClient } from 'react-query';



const { pipelinesUrls, pipelineStagesUrls } = crmUrls;

export default function PipelineStagesDropdown({ children, refetch, pipelineId:initialPipeline }) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="Pipeline Stages"
        initialValues={{
          ...getAddFormInitialValues(pipelineStageMeta(), 'listView'),
          pipeline:initialPipeline
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(pipelineStageMeta(), 'listView'),
        })}
        getPostUrl={() => pipelineStagesUrls.list()}
        getValues={({ pipeline,...values }) => ({
          ...values,
          pipeline: pipeline?.id
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={3}>
              <TextField label='Order' name='order' required />
            </Grid>

            <Grid item xs={4}>
              <TextField label='Name' name='stage' required multiline />
            </Grid>

            <Grid item xs={5}>
              <TextField label='Description' name='description' multiline />
            </Grid>

            <Grid item xs={6}>
              <TextField label='Confidence %' name='confidence' />
            </Grid>
            <Grid item xs={6}>
              <TextField label='Rotting Days' name='rotting_days' />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label='Immediate Next Action'
                name='immediate_next_action'
                multiline
                required
              />
            </Grid>
            <Grid item xs={12}>
            <PipelineDropdown 
              refetch={({ id, name }) => {
                setFieldValue('pipeline', { id, label: name });
                queryClient.invalidateQueries(['pipeline-dropdown']);
              }}
            >
              <AutocompleteFieldV2
                name='pipeline'
                label='Pipelines'
                requestKey='pipeline-dropdown'
                fetchUrl={pipelinesUrls.list}
                required
              />
            </PipelineDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
