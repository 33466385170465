import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Typography,
} from '@mui/material';
import { bpaUrls } from '@config/routes';
import { getAuthHeader } from '@config/functions/helperFunctions';
import useRQuery from '@hooks/useRQuery';
import Loader from '@components/Loader';
import CaptureDataPopup from '@components/BPA-V2/ManualActions/CaptureDataPopup';
import DiamondPopup from '@components/BPA-V2/ManualActions/DiamondPopup';
import ChatGPTTextPopup from '@components/BPA-V2/Popups/ChatGPTTextPopup/ChatGPTTextPopup';
import EmailTemplateConfigurationPopup from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateConfigurationPopup';
import SendEmailPopup from '@components/BPA-V2/ManualActions/SendEmailPopup';
import { Box } from '@mui/material';

const { getShapeWithShapeActionsUrls } = bpaUrls;

export default function PerformManualTaskPopup({
  open,
  setOpen,
  stage,
  workflowId,
  workflowInstances,
  user,
  isFromKanbanHeader,
}) {
  const [showBPAChatgptText, setShowBPAChatgptText] = React.useState(false);
  const [showBPASendEmail, setShowBPASendEmail] = React.useState(false);
  const [showBPACaptureData, setShowBPACaptureData] = React.useState(false);
  const [showBPADiamondPopup, setShowBPADiamondPopup] = React.useState(false);
  const [showBPAEmailTemplateConfig, setShowBPAEmailTemplateConfig] =
    React.useState(false);

  const { data, isError, isFetching } = useRQuery({
    key: ['shape-data', stage?.shape_id, user?.token, user?.actAs],
    url: getShapeWithShapeActionsUrls.detail(
      stage?.shape_id,
      !isFromKanbanHeader ? `?workflow_instance=${workflowInstances[0]}` : ''
    ),
    config: getAuthHeader(user?.token, user?.actAs),
    options: { enabled: !!user?.token && !!stage?.shape_id },
  });

  React.useEffect(() => {
    if (!data) return;

    if (data?.shape?.type === 'decision') {
      setShowBPADiamondPopup(true);
      return;
    }

    const [action] = data?.Do;

    if (!action) return;

    const { function_name: functionName } = action?.details?.action;

    if (functionName === 'send_email') {
      setShowBPASendEmail(true);
    } else if (functionName === 'capture_data') {
      setShowBPACaptureData(true);
    } else if (functionName === 'send_email_v2') {
      setShowBPAEmailTemplateConfig(true);
    } else if (functionName === 'chatgpt_text') {
      setShowBPAChatgptText(true);
    }
  }, [data]);

  const closePopup = (manualTask) => {
    manualTask(false);
    setOpen(false);
  };

  return (
    <>
      {isFetching || isError ? (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onKeyDown={(e) => e.stopPropagation()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent maxWidth="sm" fullWidth>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Perform Manual Task
            </Typography>

            <Box sx={{ py: 1 }}>
              {isFetching ? (
                <Loader />
              ) : isError ? (
                `There was an error, please try again.`
              ) : null}
            </Box>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : (
        <>
          {showBPAChatgptText ? (
            <ChatGPTTextPopup
              open={showBPAChatgptText}
              setOpen={() => closePopup(setShowBPAChatgptText)}
              workflowId={workflowId}
              workflowInstanceId={workflowInstances[0]}
              manualTaskInstructions={data?.shape?.manual_task_instructions}
              actionParams={data?.Do[0]?.action_params}
              shapeAction={data?.Do[0]}
              user={user}
              isKanbanView
              isFromKanbanHeader={isFromKanbanHeader}
            />
          ) : null}

          {showBPAEmailTemplateConfig ? (
            <EmailTemplateConfigurationPopup
              open={showBPAEmailTemplateConfig}
              setOpen={() => closePopup(setShowBPAEmailTemplateConfig)}
              workflowId={workflowId}
              workflowInstances={workflowInstances}
              manualTaskInstructions={data?.shape?.manual_task_instructions}
              actionParams={data?.Do[0]?.action_params}
              shapeAction={data?.Do[0]}
              user={user}
              isKanbanView
              isFromKanbanHeader={isFromKanbanHeader}
            />
          ) : null}

          {showBPASendEmail ? (
            <SendEmailPopup
              open={!!showBPASendEmail}
              setOpen={() => closePopup(setShowBPASendEmail)}
              env="Kanban"
              actionParams={data?.Do[0]?.action_params}
              shapeAction={data?.Do[0]}
              workflowId={workflowId}
              workflowInstances={workflowInstances}
              manualTaskInstructions={data?.shape?.manual_task_instructions}
              user={user}
            />
          ) : null}

          {showBPACaptureData ? (
            <CaptureDataPopup
              open={!!showBPACaptureData}
              setOpen={() => closePopup(setShowBPACaptureData)}
              actionParams={data?.Do[0]?.action_params}
              shapeAction={data?.Do[0]}
              workflowId={workflowId}
              workflowInstances={workflowInstances}
              manualTaskInstructions={data?.shape?.manual_task_instructions}
              user={user}
            />
          ) : null}

          {showBPADiamondPopup ? (
            <DiamondPopup
              open={!!showBPADiamondPopup}
              setOpen={() => closePopup(setShowBPADiamondPopup)}
              payloadSection={data?.shape?.payload_section}
              workflowId={workflowId}
              workflowInstances={workflowInstances}
              manualTaskInstructions={data?.shape?.manual_task_instructions}
              user={user}
            />
          ) : null}
        </>
      )}
    </>
  );
}
