import { calendarHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  calendarAccounts: 'calendar-accounts',
  calendars: 'calendars',
  deleteSlotEvents: 'delete-slot-events',
  deleteRecurringEvents: 'delete-recurring-events',
  events: 'events',
  slots: 'slots',
  guests: 'guests',
  notifications: 'notifications',
  sync: 'sync',
  repeats: 'repeats',
  batchReminders: 'batch-reminders',
  links: 'links',
  attachments: 'attachments',
  linkSlots: 'link-slots',
  linkRecipients: 'link-recipients',
  googleConnect: 'google-connect',
  getSlotDatesFromParentEvent: 'get-dates-from-parent-event',
  getSlotsFromLink: 'get-slots-from-link',
  getSlotsFromRecipientLink: 'get-slots-from-recipient-link',
  calendarApps: 'calendar-apps',
  connectGoogleAccount: 'connect-google-account',
  doWhat: 'do-whats',
  bulkCreateActionReminder: 'bulk-create-action-reminders',
  actionReminders: 'action-reminders',
  actionReminderStage: 'action-reminder-status',
  actions: 'actions'
};

const actionReminderStageUrls = getModelUrls(calendarHost, models.actionReminderStage);
const actionRemindersUrls = getModelUrls(calendarHost, models.actionReminders);
const bulkCreateActionReminderUrls = getModelUrls(calendarHost, models.bulkCreateActionReminder);
const doWhatUrls = getModelUrls(calendarHost, models.doWhat);
const actionsUrls = getModelUrls(calendarHost, models.actions);
const calendarsListUrls = getModelUrls(calendarHost, models.calendars);
const connectGoogleAccountUrls = getModelUrls(
  calendarHost,
  models.connectGoogleAccount
);
const calendarAccountsUrls = getModelUrls(
  calendarHost,
  models.calendarAccounts
);
const calendarsUrls = getModelUrls(calendarHost, models.calendars);
const deleteSlotEventsUrls = getModelUrls(calendarHost, models.deleteSlotEvents);
const deleteRecurringEventsUrls = getModelUrls(calendarHost, models.deleteRecurringEvents);
const eventsUrls = getModelUrls(calendarHost, models.events);
const slotsUrls = getModelUrls(calendarHost, models.slots);
const guestsUrls = getModelUrls(calendarHost, models.guests);
const notificationsUrls = getModelUrls(calendarHost, models.notifications);
const syncUrls = getModelUrls(calendarHost, models.sync);
const repeatsUrls = getModelUrls(calendarHost, models.repeats);
const linksUrls = getModelUrls(calendarHost, models.links);
const batchRemindersUrls = getModelUrls(calendarHost, models.batchReminders);
const attachmentsUrls = getModelUrls(calendarHost, models.attachments);
const linkSlotsUrls = getModelUrls(calendarHost, models.linkSlots);
const linkRecipientsUrls = getModelUrls(calendarHost, models.linkRecipients);
const googleConnectUrls = getModelUrls(calendarHost, models.googleConnect);
const getSlotsFromLinkUrls = getModelUrls(
  calendarHost,
  models.getSlotsFromLink
);
const getSlotsFromRecipientLinkUrls = getModelUrls(
  calendarHost,
  models.getSlotsFromRecipientLink
);

const getSlotDatesFromParentEventUrls = getModelUrls(
  calendarHost,
  models.getSlotDatesFromParentEvent
);
const calendarAppsUrls = getModelUrls(calendarHost, models.calendarApps);

const getGoogleOAuthApiUrl = ({
  state,
  client_id,
  client_redirect_uri,
  client_scopes,
}) => {
  const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

  const params = {
    response_type: 'code',
    client_id: client_id,
    redirect_uri: client_redirect_uri,
    scope: client_scopes,
    access_type: 'offline',
    prompt: 'consent',
    state,
  };
  const urlParams = new URLSearchParams(params).toString();

  console.log(`${googleAuthUrl}?${urlParams}`);
  return `${googleAuthUrl}?${urlParams}`;
};

const urls = {
  batchRemindersUrls,
  actionReminderStageUrls,
  actionRemindersUrls,
  bulkCreateActionReminderUrls,
  calendarAccountsUrls,
  calendarsListUrls,
  calendarsUrls,
  deleteSlotEventsUrls,
  deleteRecurringEventsUrls,
  eventsUrls,
  slotsUrls,
  guestsUrls,
  notificationsUrls,
  syncUrls,
  repeatsUrls,
  linksUrls,
  attachmentsUrls,
  linkSlotsUrls,
  linkRecipientsUrls,
  googleConnectUrls,
  getGoogleOAuthApiUrl,
  getSlotsFromLinkUrls,
  getSlotsFromRecipientLinkUrls,
  getSlotDatesFromParentEventUrls,
  calendarAppsUrls,
  connectGoogleAccountUrls,
  doWhatUrls,
  actionsUrls
};

export default urls;
