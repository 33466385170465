import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Typography,
} from '@mui/material';
import { pmUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@components/Inputs';
import useToast from '@hooks/useToast';
import { createRecord } from '@config/functions/requests';
import { useQueryClient } from 'react-query';

const { duplicateTaskUrls } = pmUrls;

export default function AddTaskDialogBox({
  open,
  setOpen,
  taskId,
  initialOrder,
  initialName,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      onKeyDown={(e) => e.stopPropagation()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent maxWidth="sm" fullWidth>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Clone Task
        </Typography>

        <Formik
          initialValues={{
            order: initialOrder ?? 1,
            name: initialName ?? '',
          }}
          validationSchema={Yup.object({
            order: Yup.number().required(),
            name: Yup.string().required(),
          })}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              await createRecord({
                values: { ...values, rebase: true },
                url: duplicateTaskUrls.detail(taskId),
                token: user.token,
                actAs: user?.actAs,
              });
              queryClient.invalidateQueries(['tasks-board']);
              setOpen(false);
              notify('Task will be cloned shortly.', {
                type: 'SUCCESS',
              });
            } catch (err) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form noValidate autoComplete="off" sx={{ pt: '10px' }}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField label="Order" name="order" required />
                </Grid>

                <Grid item sm={12}>
                  <TextField label="Name" name="name" multiline required />
                </Grid>
              </Grid>

              <DialogActions sx={{ mt: 2 }}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
