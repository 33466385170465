import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { wikiUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import { ChaptersForm } from '@config/forms/wiki';
import { chapterHandleRow } from '@config/handleRows/wiki';
import { booksMeta } from '@config/meta/wiki';
import DetailPageHeader from '@components/DetailPageHeader';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { booksUrls, chaptersUrls } = wikiUrls;

export default function BooksDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const [chaptersListData, setChaptersListData] = React.useState({});
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();

  const getSchema = React.useCallback(
    (data) => booksMeta({ data, queryClient }),
    []
  );

  const chaptersColOptions = React.useMemo(
    () => ({
      nextOrder: chaptersListData?.totalCount ?? 1,
    }),
    [chaptersListData?.totalCount]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key='detail-view-header'
            items={[
              { to: `/books`, text: 'Books' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={booksUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.WIKI.name}
          model={microservices.WIKI.models.chapter}
          columnKey='chaptersWiki'
          columnOptions={chaptersColOptions}
          label='Chapters'
          prefix='0ky'
          labels={labels}
          editMode={editMode}
          urls={chaptersUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              ChaptersForm({
                setFieldValue,
                bookId: recordId,
                bookName: recordData?.name,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { parent_chapter, ...rest } = values;

                const response = await createRecord({
                  values: {
                    ...rest,
                    book: recordId,
                    parent_chapter: parent_chapter?.id,
                  },
                  url: chaptersUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={chapterHandleRow}
          urlParams={`&book=${recordId}`}
          customSortColumnName='order'
          sortColumnBy='asc'
          getListData={(data) => setChaptersListData(data)}
        />
      </Box>
    </Box>
  );
}
