import React from 'react';
import _ from 'lodash';
import { useField, useFormikContext } from 'formik';
import AutocompleteFieldV2 from '@ui/Inputs/AutocompleteFieldV2';

export default function FormikAutocompleteFieldV2({
  name,
  label,
  requestKey,
  fetchUrl,
  renderRow,
  onChange,
  disableDefaultSetValue,
  disableSearch,
  getData,
  urlParams,
  getOptionLabel = (option) => option.label,
  setCustomOptions = (data) => data,
  required,
  disabled,
  requestKeyOptions,
  enabled,
  orderingCol,
  multiple,
  idKey = 'id',
}) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (newValue) => {
    if (!disableDefaultSetValue) {
      setFieldValue(name, newValue);
    }
    if (onChange) {
      onChange(newValue ?? {});
    }
  };

  // Function to process field value based on given criteria.
  function processFieldValue(field, multiple) {
    // Ensure field and field.value exist and field.value is an object.
    if (field && _.isObject(field.value)) {
      // Check if both id and label are strings.
      const isValidObject =
        _.isString(field.value[idKey]) && _.isString(field.value.label);

      if (isValidObject) {
        return field.value; // Return the value if it matches the criteria.
      }
    }

    // Handle the 'multiple' scenario more cleanly.
    if (multiple) {
      // If field.value is an array, return it; otherwise, return [].
      return Array.isArray(field.value) ? field.value : [];
    }

    // If not handling a 'multiple' scenario or the criteria are not met, return null.
    return null;
  }

  return (
    <AutocompleteFieldV2
      idKey={idKey}
      label={label || ''}
      orderingCol={orderingCol}
      requestKeyOptions={requestKeyOptions || []}
      required={required}
      urlParams={urlParams}
      getData={getData}
      setCustomOptions={setCustomOptions}
      disableSearch={disableSearch}
      requestKey={requestKey}
      fetchUrl={fetchUrl}
      renderRow={renderRow}
      getOptionLabel={getOptionLabel}
      enabled={enabled}
      customValue={processFieldValue(field, multiple)}
      customOnChange={handleChange}
      error={!!meta.error}
      helperText={meta.error}
      disabled={disabled}
      multiple={multiple}
    />
  );
}
