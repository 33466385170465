import { createSlice } from '@reduxjs/toolkit';
import { colors, adminLogo } from '@config/constants';

const initialState = {
  details: null,
  token: null,
  refreshToken: null,
  encryptionToken: null,
  actAs: {},
  adminLogo,
  colors,
  favicon: '/favicon.png',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.encryptionToken = action.payload.encryptionToken;
      state.details = action.payload.details;
      state.actAs = action.payload.actAs;
      if (action.payload.adminLogo) state.adminLogo = action.payload.adminLogo;
      if (action.payload.favicon) state.favicon = action.payload.favicon;
      if (action.payload.colors && action.payload.colors.primary) {
        state.colors.primary = action.payload.colors.primary;
      }
    },
    setTokens: (state, action) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setEncryptionToken: (state, action) => {
      state.encryptionToken = action.payload;
    },
    setActAs: (state, action) => {
      state.actAs = action.payload;
    },
  },
});

export const { setProfile, setToken, setTokens, setEncryptionToken, setActAs } =
  profileSlice.actions;

export const selectProfile = (state) => state.profile;

export default profileSlice.reducer;
