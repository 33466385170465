import React from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import {
  TextField,
  DateTimePickerField,
  SelectField,
  CheckBoxField,
  UploadField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import {
  vacanciesStatusChoices,
  dayoffStatusType,
  assessmentRating,
} from '@config/constants';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import DepartmentDropdown from '@dropdown-forms/hr/DepartmentDropdown';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';
import RecruitForDropdown from '@dropdown-forms/hr/RecruitForDropdown';
import RecruitmentPipelineDropdown from '@dropdown-forms/hr/RecruitmentPipelineDropdown';
import SkillLevelDropdown from '@dropdown-forms/hr/SkillLevelDropdown';
import JobLevelDropdown from '@dropdown-forms/hr/JobLevelDropdown';
import EmployeeDropdownForm from '@dropdown-forms/hr/EmployeeDropdown';
import DayOffTypeDropdownForm from '@dropdown-forms/hr/DayOffTypeDropdown';
import EmployeeStatusDropdown from '@dropdown-forms/hr/EmployeeStatusDropdown';
import { bpaUrls, communicationUrls, crmUrls, hrUrls } from '@config/routes';
import { useQueryClient } from 'react-query';

const { workflowsUrls } = bpaUrls;
const { mailTemplatesUrls } = communicationUrls;
const { personsUrls, companiesUrls } = crmUrls;
const {
  departmentsUrls,
  dayOffTypesUrls,
  employeesUrls,
  jobLevelsUrls,
  recruitmentPipelineUrls,
  skillLevelUrls,
  employeeStatusUrls,
} = hrUrls;

export const InterviewNoteForm = () => (
  <>
    <Grid item xs={4}>
      <TextField
        label='Interview name'
        name='interview_name'
        multiline
        required
      />
    </Grid>
    <Grid item xs={1.5}>
      <TextField label='Rating' name='rating' type='number' />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Current salary' name='current_salary' multiline />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Expected salary' name='expected_salary' multiline />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Current location' name='current_location' multiline />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Relevant skills' name='relevant_skills' multiline />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Initial skills' name='initial_skills' multiline />
    </Grid>
    <Grid item xs={4}>
      <TextField
        label='Initial impression'
        name='initial_impression'
        multiline
      />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Motivations' name='motivations' multiline />
    </Grid>
    <Grid item xs={4}>
      <TextField
        label='Reason for leaving current job'
        name='reason_for_leaving_current_job'
        multiline
      />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Recommendations' name='recommendations' multiline />
    </Grid>
  </>
);

export const AssessmentForm = () => (
  <>
    <Grid item xs={2.5}>
      <SelectField label='Rating' name='rating'>
        {assessmentRating.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.key}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>
    <Grid item xs={5.5}>
      <TextField label='Notes' name='notes' multiline required />
    </Grid>
    <Grid item xs={6}>
      <UploadField name='attachment' />
    </Grid>
  </>
);

export const CandidatesForm = ({ values } = {}) => {
  return (
    <>
      <Grid item xs={1.5}>
        <TextField label='First name' name='first_name' required />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label='Last name' name='last_name' required />
      </Grid>

      <Grid item xs={2}>
        <TextField label='Email' name='personal_email' required />
      </Grid>

      <Grid item xs={2}>
        <TextField label='Phone' name='personal_mobile' />
      </Grid>

      <Grid item xs={1.5}>
        <DateTimePickerField label='Date Of Birth' name='dob' />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label='City' name='city' required />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label='Country' name='country' required />
      </Grid>

      <Grid item xs={2}>
        <TextField
          label='Years of Experience'
          name='years_of_experience'
          type='number'
        />
      </Grid>

      <Grid item xs={2}>
        <TextField label='Address 1' name='address1' multiline />
      </Grid>

      <Grid item xs={2}>
        <TextField label='Address 2' name='address2' multiline />
      </Grid>

      <Grid item xs={3}>
        <TextField label='Linkedin' name='linkedin' placeholder='https://' />
      </Grid>

      <Grid item xs={2}>
        <CheckBoxField label='Currently Employed' name='currently_employed' />
      </Grid>

      {values.currently_employed ? (
        <Grid item xs={2}>
          <TextField label='Current Employer' name='current_employer' />
        </Grid>
      ) : null}

      <Grid item xs={2}>
        <CheckBoxField label='Visa Required' name='visa_required' />
      </Grid>

      <Grid item xs={3}>
        <TextField label='Cover letter' name='cover_letter' multiline />
      </Grid>
      <Grid item xs={4}>
        <UploadField name='cv' />
      </Grid>
    </>
  );
};

export const KeywordsForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Description' name='description' multiline />
    </Grid>
  </>
);

export const VacancyStatusForm = () => (
  <>
    <Grid item xs={1}>
      <TextField label='Order' name='order' />
    </Grid>

    <Grid item xs={2}>
      <TextField label='Name' name='name' />
    </Grid>

    <Grid item xs={3.5}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={1.5}>
      <TextField label='Rotting Days' name='rotting_days' />
    </Grid>
  </>
);

export const CoursesForm = () => (
  <>
    <Grid item xs={3}>
      <TextField label='Name' name='name' required />
    </Grid>
    <Grid item xs={5}>
      <TextField label='Description' name='description' />
    </Grid>
  </>
);

export const SkillLevelForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Level' name='level' required />
    </Grid>
    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>
  </>
);

export const rolesForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Name' name='name' />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Description' name='description' />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Min Salary Pm' name='min_salary_pm' />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Max Salary Pm' name='max_salary_pm' />
    </Grid>
  </>
);

export const EmployeesForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <PersonDropdown
          refetch={({ id, email }) => {
            setFieldValue('person', {
              id: id,
              label: `${email || ''}`,
            });
            queryClient.invalidateQueries(['employee-persons']);
          }}
        >
          <AutocompleteFieldV2
            name='person'
            label='Person'
            requestKey='employee-persons'
            fetchUrl={personsUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.email}`,
            })}
            required
          />
        </PersonDropdown>
      </Grid>
      <Grid item xs={2}>
        <TextField label='Work Email' name='work_email' required />
      </Grid>
      <Grid item xs={2}>
        <TextField label='Work Phone' name='work_phone' />
      </Grid>
      <Grid item xs={2}>
        <EmployeeStatusDropdown
          refetch={({ id, name }) => {
            setFieldValue('status', { id, label: name });
            queryClient.invalidateQueries(['employee-status']);
          }}
        >
          <AutocompleteFieldV2
            name='status'
            label='Status'
            requestKey='employee-status'
            fetchUrl={employeeStatusUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.name}`,
            })}
          />
        </EmployeeStatusDropdown>
      </Grid>
      <Grid item xs={1.5}>
        <TextField label='Charge Rate' name='charge_rate' type='number' />
      </Grid>
      <Grid item xs={3}>
        <TextField label='CV' name='cv' placeholder='https://' />
      </Grid>
      <Grid item xs={1.5}>
        <DateTimePickerField label='Joined' name='date_joined' />
      </Grid>
      <Grid item xs={1.5}>
        <DateTimePickerField label='Date Left' name='date_left' />
      </Grid>
    </>
  );
};

export const VacancyForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  // const [, setWfVal] = React.useState('');
  // const [init, setInit] = React.useState(false);

  // React.useEffect(() => {
  //   if (init || fetchingWorkflows || !Array.isArray(workflows)) return;
  //   const [item] = workflows;
  //   console.log('Set item', item);
  //   setSelectedWorkflow(item);
  //   setWfVal(item);
  //   setInit(true);
  // }, [fetchingWorkflows, init, setSelectedWorkflow, workflows]);

  const handleHtml = (e) => {
    if (e.editor.getData() === '') {
      setFieldValue('ad_text', '<p></p>');
      return;
    }
    setFieldValue('ad_text', e.editor.getData());
  };

  return (
    <>
      <Grid item xs={3}>
        <TextField label='Position Title' name='position_title' required />
      </Grid>
      <Grid item xs={2}>
        <TextField label='Location' name='location' multiline />
      </Grid>

      <Grid item xs={2}>
        <JobLevelDropdown
          refetch={({ id, name }) => {
            setFieldValue('job_level', { id, label: name });
            queryClient.invalidateQueries(['vacancies-job-level']);
          }}
        >
          <AutocompleteFieldV2
            name='job_level'
            label='Job Level'
            requestKey='vacancies-job-level'
            fetchUrl={jobLevelsUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </JobLevelDropdown>
      </Grid>

      <Grid item xs={1.66}>
        <TextField required label='Min Pay' name='min_pay' type='number' />
      </Grid>

      <Grid item xs={1.66}>
        <TextField required label='Max Pay' name='max_pay' type='number' />
      </Grid>

      <Grid item xs={1.66}>
        <TextField label='Currency' name='currency' />
      </Grid>

      <Grid item xs={3}>
        <RecruitForDropdown
          refetch={({ id, name }) => {
            setFieldValue('recruit_for', { id, label: name });
            queryClient.invalidateQueries(['vacancies-recruit-for']);
          }}
        >
          <AutocompleteFieldV2
            name='recruit_for'
            label='Recruit For'
            requestKey='vacancies-recruit-for'
            fetchUrl={companiesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
          />
        </RecruitForDropdown>
      </Grid>

      <Grid item xs={3}>
        <EmployeeDropdownForm
          title='Hiring Manager'
          refetch={({ id, work_email }) => {
            setFieldValue('hiring_manager', { id, label: work_email });
            queryClient.invalidateQueries(['vacancies-hiring-manager']);
          }}
        >
          <AutocompleteFieldV2
            name='hiring_manager'
            label='Hiring Manager'
            requestKey='vacancies-hiring-manager'
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.work_email,
            })}
          />
        </EmployeeDropdownForm>
      </Grid>

      <Grid item xs={3}>
        <EmployeeDropdownForm
          title='Internal Responsible'
          refetch={({ id, work_email }) => {
            setFieldValue('internal_responsible', { id, label: work_email });
            queryClient.invalidateQueries(['vacancies-internal-responsible']);
          }}
        >
          <AutocompleteFieldV2
            name='internal_responsible'
            label='Internal Responsible'
            requestKey='vacancies-internal-responsible'
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.work_email,
            })}
          />
        </EmployeeDropdownForm>
      </Grid>
      <Grid item xs={3}>
        <RecruitmentPipelineDropdown
          refetch={({ id, name }) => {
            setFieldValue('recruitment_pipeline', { id, label: name });
            queryClient.invalidateQueries(['vacancies-recruitment-pipeline']);
          }}
        >
          <AutocompleteFieldV2
            name='recruitment_pipeline'
            label='Recruitment Pipeline'
            requestKey='vacancies-recruitment-pipeline'
            fetchUrl={recruitmentPipelineUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
          />
        </RecruitmentPipelineDropdown>
      </Grid>

      <Grid item xs={4}>
        <TextField label='Skills Required' name='skills_required' multiline />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label='Educational Requirements'
          name='educational_requirements'
          multiline
        />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Responsibilities' name='responsibilities' multiline />
      </Grid>
      <Grid item md={4}>
        <SelectField label='Status' name='status'>
          {vacanciesStatusChoices.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item xs={4}>
        <TextField label='Functional Area' name='functional_area' multiline />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Travel Required' name='travel_required' multiline />
      </Grid>
      <Grid item xs={4}>
        <AutocompleteFieldV2
          name='workflow'
          label='BPA Workflow'
          requestKey='vacancy-workflow'
          fetchUrl={workflowsUrls.list}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField label='Job Description' name='job_description' multiline />
      </Grid>

      <Grid item xs={12}>
        <TextField label='Test' name='test' multiline />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant='h6'
          sx={{
            fontWeight: '500',
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
          gutterBottom
        >
          Advert Text
        </Typography>
        <WysiwygEditor initData={'<p></p>'} onChange={handleHtml} />
      </Grid>
    </>
  );
};

export const DepartmentsForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={2} sm={4} md={3}>
        <TextField label='Name' name='name' required />
      </Grid>
      <Grid item xs={2} sm={4} md={5}>
        <TextField label='Description' name='description' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <DepartmentDropdown
          refetch={({ id, name }) => {
            setFieldValue('parent_department', {
              id: id,
              label: `${name || ''}`,
            });
            queryClient.invalidateQueries(['parent-department']);
          }}
        >
          <AutocompleteFieldV2
            name='parent_department'
            label='Department'
            requestKey='parent-department'
            fetchUrl={departmentsUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.name}`,
            })}
          />
        </DepartmentDropdown>
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <EmployeeDropdownForm
          refetch={({ id, work_email }) => {
            setFieldValue('department_manager', {
              id: id,
              label: `${work_email || ''}`,
            });
            queryClient.invalidateQueries(['department-manager-employee']);
          }}
        >
          <AutocompleteFieldV2
            name='department_manager'
            label='Department Manager'
            requestKey='department-manager-employee'
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.work_email}`,
            })}
          />
        </EmployeeDropdownForm>
      </Grid>
    </>
  );
};
export const DepartmentEmployeeForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={2} sm={4} md={3}>
        <EmployeeDropdownForm
          refetch={({ id, work_email }) => {
            setFieldValue('employee', {
              id: id,
              label: `${work_email || ''}`,
            });
            queryClient.invalidateQueries(['department-employee']);
          }}
        >
          <AutocompleteFieldV2
            name='employee'
            label='Employee'
            requestKey='department-employee'
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.work_email}`,
            })}
            required
          />
        </EmployeeDropdownForm>
      </Grid>
    </>
  );
};

export const SkillsForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Name' name='name' required />
    </Grid>
    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>
  </>
);

export const RoleHistoryForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2} sm={4} md={3}>
        <DateTimePickerField label='Start Date' name='start_date' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <DateTimePickerField label='End Date' name='end_date' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <EmployeeDropdownForm
          refetch={({ id, work_email }) => {
            setFieldValue('employee', {
              id: id,
              label: `${work_email || ''}`,
            });
            queryClient.invalidateQueries(['role-employee']);
          }}
        >
          <AutocompleteFieldV2
            name='employee'
            label='Employee'
            requestKey='role-employee'
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.work_email}`,
            })}
            required
          />
        </EmployeeDropdownForm>
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <EmployeeDropdownForm
          refetch={({ id, work_email }) => {
            setFieldValue('supervisor_emp', {
              id: id,
              label: `${work_email || ''}`,
            });
            queryClient.invalidateQueries(['role-supervisor-emp']);
          }}
        >
          <AutocompleteFieldV2
            name='supervisor_emp'
            label='Supervisor Employee'
            requestKey='role-supervisor-emp'
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.work_email}`,
            })}
            required
          />
        </EmployeeDropdownForm>
      </Grid>
    </>
  );
};

export const EmployeeDepartmentForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2} sm={4} md={3}>
        <DateTimePickerField label='Start Date' name='start_date' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <DateTimePickerField label='End Date' name='end_date' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <DepartmentDropdown
          refetch={({ id, name }) => {
            setFieldValue('department', {
              id: id,
              label: `${name || ''}`,
            });
            queryClient.invalidateQueries(['employee-department']);
          }}
        >
          <AutocompleteFieldV2
            name='department'
            label='Department'
            requestKey='employee-department'
            fetchUrl={departmentsUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.name}`,
            })}
            required
          />
        </DepartmentDropdown>
      </Grid>
    </>
  );
};

export const EmployeeSkillForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2} sm={4} md={3}>
        <DateTimePickerField label='Date Acquired' name='date_acquired' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <EmployeeDropdownForm
          refetch={({ id, work_email }) => {
            setFieldValue('employee', { id, label: work_email });
            queryClient.invalidateQueries(['skill-employee']);
          }}
        >
          <AutocompleteFieldV2
            name='employee'
            label='Employee'
            requestKey='skill-employee'
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.work_email,
            })}
            required
          />
        </EmployeeDropdownForm>
      </Grid>

      <Grid item xs={2} sm={4} md={3}>
        <SkillLevelDropdown
          refetch={({ id, level }) => {
            setFieldValue('skill_level', { id, label: level });
            queryClient.invalidateQueries(['skill-employee']);
          }}
        >
          <AutocompleteFieldV2
            name='skill_level'
            label='Skill Level'
            requestKey='skill-employee'
            fetchUrl={skillLevelUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.level,
            })}
            required
          />
        </SkillLevelDropdown>
      </Grid>
    </>
  );
};

export const ReferencesForm = ({
  referenceTypeOptions,
  setFieldValue,
} = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2} sm={4} md={3}>
        <TextField label='First Name' name='first_name' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <TextField label='Last Name' name='last_name' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <TextField label='Phone' name='phone' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <TextField label='Email' name='email' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <TextField label='Reference Result' name='reference_result' />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <SelectField label='Type' name='type'>
          {referenceTypeOptions.map((w) => (
            <MenuItem value={w} key={w}>
              {w}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <EmployeeDropdownForm
          refetch={({ id, work_email }) => {
            setFieldValue('checked_by', { id, label: work_email });
            queryClient.invalidateQueries(['reference-checked-by']);
          }}
        >
          <AutocompleteFieldV2
            name='checked_by'
            label='Checked By'
            requestKey='reference-checked-by'
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.work_email,
            })}
            required
          />
        </EmployeeDropdownForm>
      </Grid>
    </>
  );
};

export const SalaryHistoryForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label='Salary Per Month' name='salary_pm' />
    </Grid>
    <Grid item xs={1.5}>
      <DateTimePickerField label='Start Date' name='start_date' />
    </Grid>
    <Grid item xs={1.5}>
      <DateTimePickerField label='End Date' name='end_date' />
    </Grid>
  </>
);

export const TrainingHistoryForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label='Result' name='result' multiline />
    </Grid>
    <Grid item xs={1.5}>
      <DateTimePickerField label='Date Taken' name='date_taken' />
    </Grid>
  </>
);

export const HolidayEntitlementForm = () => (
  <>
    <Grid item xs={3}>
      <TextField label='Max Holidays Per Year' name='max_holidays_pa' />
    </Grid>
    <Grid item xs={1.5}>
      <DateTimePickerField label='Start Date' name='start_date' />
    </Grid>
  </>
);

export const DaysOffForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={4}>
        <TextField label='Notes' name='notes' multiline />
      </Grid>

      <Grid item xs={2}>
        <DateTimePickerField
          showTime
          label='DaysOff From'
          name='days_off_from'
        />
      </Grid>

      <Grid item xs={2}>
        <TextField
          label='Days Requested'
          type='number'
          name='days_requested'
          required
        />
      </Grid>
      <Grid item md={2}>
        <SelectField label='Status' name='status'>
          {dayoffStatusType.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item xs={2}>
        <DayOffTypeDropdownForm
          refetch={({ id, name }) => {
            setFieldValue('type', {
              id: id,
              label: `${name || ''}`,
            });
            queryClient.invalidateQueries(['day-off-type']);
          }}
        >
          <AutocompleteFieldV2
            name='type'
            label='Type'
            requestKey='day-off-type'
            fetchUrl={dayOffTypesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.name}`,
            })}
            required
          />
        </DayOffTypeDropdownForm>
      </Grid>

      <Grid item xs={3}>
        <EmployeeDropdownForm
          title='Approved By'
          refetch={({ id, work_email }) => {
            setFieldValue('approved_by', {
              id: id,
              label: `${work_email || ''}`,
            });
            queryClient.invalidateQueries(['day-off-approved-by']);
          }}
        >
          <AutocompleteFieldV2
            name='approved_by'
            label='Approved By'
            requestKey='day-off-approved_by'
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.work_email}`,
            })}
            required
          />
        </EmployeeDropdownForm>
      </Grid>
    </>
  );
};

export const PerformanceReviewsForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={1.5}>
        <DateTimePickerField label='Date Of Review' name='date_of_review' />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label='Comments By Reviewer'
          name='comments_by_reviewer'
          multiline
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label='Comments By Employee'
          name='comments_by_employee'
          multiline
        />
      </Grid>

      <Grid item xs={2}>
        <EmployeeDropdownForm
          title='Reviewed By'
          refetch={({ id, work_email }) => {
            setFieldValue('reviewed_by', {
              id: id,
              label: `${work_email || ''}`,
            });
            queryClient.invalidateQueries(['performance-review-reviewed-by']);
          }}
        >
          <AutocompleteFieldV2
            name='reviewed_by'
            label='Reviewed By'
            requestKey='performance-review-reviewed-by'
            fetchUrl={employeesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.work_email}`,
            })}
            required
          />
        </EmployeeDropdownForm>
      </Grid>
      <Grid item xs={3}>
        <TextField
          label='Review Form'
          placeholder='https://'
          name='review_form'
        />
      </Grid>
    </>
  );
};

export const vacancyStatusForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Name' name='name' />
    </Grid>
    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Order' name='order' />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Rotting Days' name='rotting_days' />
    </Grid>
  </>
);

export const RecruitmentPipelinesForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={6}>
      <TextField label='Description' name='description' />
    </Grid>
  </>
);

export const RecruitmentPipelineStageForm = () => {
  return (
    <>
      <Grid item xs={1}>
        <TextField label='Order' name='order' />
      </Grid>

      <Grid item xs={2}>
        <TextField label='Name' name='name' required />
      </Grid>

      <Grid item xs={3}>
        <AutocompleteFieldV2
          name='email_template'
          label='Template'
          requestKey='recruitment-pipeline-stage-email-template'
          fetchUrl={mailTemplatesUrls.list}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField label='Description' name='description' />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label='Rotting Days' name='rotting_days' />
      </Grid>
    </>
  );
};

export const DayOffTypesForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={6}>
      <TextField label='Description' name='description' />
    </Grid>
  </>
);

export const JobLevelForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={6}>
      <TextField label='Description' name='description' multiline />
    </Grid>
  </>
);

export const EmployeeStatusForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label='Name' name='name' required />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Description' name='description' />
    </Grid>
  </>
);
