import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import { createRecord, updateRecord } from '@config/functions/requests';
import { personsRowsMap } from '@config/handleRows/crm';
import { PersonsForm } from '@config/forms/crm';
import { microservices } from '@config/constants';
import useAutocomplete from '@hooks/useAutocomplete';
import {
  handleCreateFormErrors,
  parseJSON,
} from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';
import ColorPicker from '@components/EditableTableComps/ColorPicker';
import { AutocompleteField } from '@ui/Inputs';

const { personsUrls, marketingListsUrls } = crmUrls;

export default function Persons({ editMode, labels }) {
  const user = useSelector(selectProfile);
  const [notify, update] = useToast();
  const queryClient = useQueryClient();

  const [init, setInit] = React.useState(false);
  const [marketingList, setMarketingList] = React.useState(null);
  const [marketingListTerm, setMarketingListTerm] = React.useState('');
  const [marketingListSelected, setMarketingListSelected] =
    React.useState(false);

  const { data: marketingLists, isFetching: fetchingMarketingLists } =
    useAutocomplete({
      key: ['persons-pinned-marketingList'],
      getUrl: marketingListsUrls.list,
      inputValue:
        init && marketingList?.label === marketingListTerm
          ? ''
          : marketingListTerm,
      selected: marketingListSelected,
    });

  const marketingListsOrArray = marketingLists || [];
  const marketingListsWithNone = [
    { id: null, label: 'None' },
    ...marketingListsOrArray,
  ];

  function getMarketingListParam(marketingList) {
    if (marketingList?.id) {
      return `&marketing_list=${marketingList.id}`;
    } else {
      return '';
    }
  }

  React.useEffect(() => {
    if (!init && !marketingList && !fetchingMarketingLists && !marketingLists) {
      const { data } = parseJSON(
        localStorage.getItem(`${user.details.id}-pinned-marketingList`)
      );
      if (!data) return;
      setMarketingListSelected(true);
      setMarketingList(data);
      setMarketingListTerm('');
      setInit(true);
      queryClient.invalidateQueries('Persons-listData');
    }
  }, [init, marketingList, user, fetchingMarketingLists, marketingLists]);

  const handleColorSelect = async ({ rows, setSelectedRows, color } = {}) => {
    const tId = notify('Saving....', { loading: true });
    try {
      await Promise.allSettled(
        rows.map((row) =>
          updateRecord({
            url: personsUrls.detail(row),
            values: {
              _color: color,
            },
            token: user.token,
            actAs: user?.actAs,
          })
        )
      );
      setSelectedRows([]);
      queryClient.invalidateQueries(['Persons-listData']);
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  const personsColOptions = React.useMemo(() => {
    return {
      queryClient,
    };
  }, [queryClient]);

  return (
    <Box>
      <ListView
        label="Persons"
        editMode={editMode}
        labels={labels}
        prefix="01s"
        microservice={microservices?.CRM?.name}
        model={microservices?.CRM?.models?.persons}
        urls={personsUrls}
        columnKey="personsCRM"
        columnOptions={personsColOptions}
        reminderBulkActionType="person"
        tableBulkOptions={[
          { id: 'addReminder', text: 'Add reminder' },
          { id: 'addToPersonRelationship', text: 'Add relationship' },
          { id: 'addToMarketingList', text: 'Add to marketing list' },
          { id: 'sendEmail', text: 'Send email' },
        ]}
        customBulkHeaderButton={(selectedRows, data, setSelectedRows) => {
          return (
            <>
              <ColorPicker
                onChange={(color) =>
                  handleColorSelect({
                    rows: selectedRows,
                    setSelectedRows,
                    color,
                  })
                }
              />
            </>
          );
        }}
        customOptionsOnRight={() => (
          <Box sx={{ mr: 2, minWidth: '240px' }}>
            <AutocompleteField
              label="Marketing List"
              value={marketingList}
              inputValue={marketingListTerm}
              options={marketingListsWithNone}
              isLoading={fetchingMarketingLists}
              setValue={(val) => {
                setMarketingList(val);

                const storageKey = `${user.details.id}-pinned-marketingList`;

                if (val?.id) {
                  localStorage.setItem(storageKey, JSON.stringify(val));
                } else {
                  localStorage.removeItem(storageKey);
                }

                setMarketingListSelected(true);
                queryClient.invalidateQueries(['Persons-listData']);
              }}
              setInputValue={(val) => {
                setMarketingListTerm(val);
                setMarketingListSelected(false);
              }}
            />
          </Box>
        )}
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            PersonsForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { country, company_owner, ...rest } = values;
              const { data } = await createRecord({
                values: {
                  country: country?.id,
                  company_owner: company_owner?.id,
                  ...rest,
                },
                url: personsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={personsRowsMap}
        urlParams={getMarketingListParam(marketingList)}
      />
    </Box>
  );
}
