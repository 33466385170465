import { Formik, Form } from 'formik';
import { useState, useMemo } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '@mui/material';

import ProfileCard from './ProfileCard';
import ReadOnlyField from '@components/core/ReadOnlyField';
import { accountUrls } from '@config/routes';
import { getAuthHeader } from '@config/functions/helperFunctions';
import Button from '@ui/Button';
import useToast from '@hooks/useToast';

const { userProfileUrls } = accountUrls;

export default function ProfileForm({
  title,
  formData,
  formFields,
  fields,
  token,
  urls,
  isEditable,
}) {
  const [editing, setEditing] = useState(false);
  const [notify] = useToast();

  const pictureConfig = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      },
    }),
    [token]
  );

  const switchEditing = () => {
    setEditing((prevState) => !prevState);
  };

  return (
    <ProfileCard
      title={title}
      action={
        isEditable &&
        !editing && (
          <Button
            type='button'
            variant='text'
            label={
              <FontAwesomeIcon icon={faPencilAlt} onClick={switchEditing} />
            }
          />
        )
      }
    >
      {editing ? (
        <Formik
          initialValues={formData.initialValues}
          validationSchema={formData.validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            if (title === 'Profile') {
              try {
                await axios.patch(urls, values, getAuthHeader(token));

                // notify(`${title} Updated`, {
                //   type: 'SUCCESS',
                // });
                switchEditing();
              } catch (err) {
                if (
                  err.response &&
                  err.response.data &&
                  Object.keys(err.response.data).length &&
                  Object.keys(err.response.data).length &&
                  err.response.data[Object.keys(err.response.data)[0]].length
                ) {
                  notify(
                    `${
                      err.response.data[Object.keys(err.response.data)[0]][0]
                    }`,
                    { type: 'ERROR' }
                  );
                } else if (err.response && err.response.data.name) {
                  notify(`${err.response.data.name}`, {
                    type: 'ERROR',
                  });
                } else if (err.response && err.response.data.description) {
                  notify(`${err.response.data.description}`, {
                    type: 'ERROR',
                  });
                } else {
                  notify(`There was an error, please try again.`, {
                    type: 'ERROR',
                  });
                }
              } finally {
                setSubmitting(false);
              }
            }
            if (
              title === 'Profile Picture' ||
              title === 'Avatar' ||
              title === 'Picture'
            ) {
              try {
                const formdata = new FormData();
                formdata.append('profile_picture', values.profile_picture);

                await axios.post(urls, formdata, pictureConfig);
                // notify(`${title} Updated`, {
                //   type: 'SUCCESS',
                // });
                switchEditing();
              } catch (err) {
                if (
                  err.response &&
                  err.response.data &&
                  Object.keys(err.response.data).length &&
                  Object.keys(err.response.data).length &&
                  err.response.data[Object.keys(err.response.data)[0]].length
                ) {
                  notify(
                    `${
                      err.response.data[Object.keys(err.response.data)[0]][0]
                    }`,
                    { type: 'ERROR' }
                  );
                } else if (err.response && err.response.data.name) {
                  notify(`${err.response.data.name}`, {
                    type: 'ERROR',
                  });
                } else if (err.response && err.response.data.description) {
                  notify(`${err.response.data.description}`, {
                    type: 'ERROR',
                  });
                } else {
                  notify(`There was an error, please try again.`, {
                    type: 'ERROR',
                  });
                }
              } finally {
                setSubmitting(false);
              }
            }
            if (title === 'Password & Security') {
              try {
                delete values.confirm_password;
                await axios.put(urls, values, getAuthHeader(token));
                // notify(`${title} Updated`, {
                //   type: 'SUCCESS',
                // });
                switchEditing();
              } catch (err) {
                if (
                  err.response &&
                  err.response.data &&
                  Object.keys(err.response.data).length &&
                  Object.keys(err.response.data).length &&
                  err.response.data[Object.keys(err.response.data)[0]].length
                ) {
                  notify(
                    `${
                      err.response.data[Object.keys(err.response.data)[0]][0]
                    }`,
                    { type: 'ERROR' }
                  );
                } else if (err.response && err.response.data.name) {
                  notify(`${err.response.data.name}`, {
                    type: 'ERROR',
                  });
                } else if (err.response && err.response.data.description) {
                  notify(`${err.response.data.description}`, {
                    type: 'ERROR',
                  });
                } else {
                  notify(`There was an error, please try again.`, {
                    type: 'ERROR',
                  });
                }
              } finally {
                setSubmitting(false);
              }
            }
            const resp = await axios.get(
              userProfileUrls.list(),
              getAuthHeader(token)
            );
            formData.handleUpdate(resp.data);
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate autoComplete='off'>
              <Grid
                key='profile-form-grid-container'
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ mb: 2 }}
              >
                {formFields}

                <Grid
                  key='profile-item-grid-container'
                  item
                  xs={2}
                  sm={4}
                  md={12}
                >
                  <Button
                    key='profile-form-save-button'
                    type='submit'
                    variant='contained'
                    label='Save'
                    disabled={isSubmitting}
                    sx={{ ml: '5px' }}
                  />
                  <Button
                    key='profile-form-cancel-button'
                    type='button'
                    variant='outlined'
                    label='Cancel'
                    onClick={switchEditing}
                    sx={{ ml: '5px' }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          {fields
            .filter((field) => !field.exclude)
            .map((field) => (
              <Grid
                key={field.key}
                item
                xs={2}
                sm={4}
                md={field.md ? field.md : 6}
              >
                <ReadOnlyField
                  key={field.key}
                  label={field.label}
                  value={field.value}
                />
              </Grid>
            ))}
        </Grid>
      )}
    </ProfileCard>
  );
}
