import React from 'react';
import { Box } from '@mui/material';
// import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { lmsUrls } from '@config/routes';
// import useToast from '@hooks/useToast';
// import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
// import { createRecord } from '@config/functions/requests';
// import DetailAccordionView from '@components/DetailAccordionView';
import { studentMeta } from '@config/meta/lms';
// import { studentMentorRowsMap } from '@config/handleRows/lms';
// import { StudentMentorForm } from '@config/forms/lms';
import { useQueryClient } from 'react-query';

const { studentsUrls } = lmsUrls;

export default function StudentDetail({ editMode, labels }) {
  // const [notify] = useToast();
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  // const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => studentMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/students', text: 'Student' },
              { text: recordData?.alias },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={studentsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      {/* <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Student Mentor'
          prefix='0g8'
          columns={studentMentorMeta()}
          urls={studentMentorsUrls}
          addNewForm={{
            getFields: ({setFieldValue}) => StudentMentorForm({ setFieldValue }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setFieldError
            ) => {
              try {
                const { mentorId, ...rest } = values;

                if (!mentorId) {
                  setFieldError('mentorId', 'Please select a mentor');
                  return;
                }

                await createRecord({
                  values: {
                    ...rest,
                    studentId: recordId,
                    mentorId: mentorId?.id,
                  },
                  url: studentMentorsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err.response.data.message);
                if(err.response.data.statusCode === 401){
                  notify(`${err.response.data.message}`, { type: 'ERROR' });
                }
                notify(`Mentor already exists.`, { type: 'ERROR' });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={studentMentorRowsMap}
          urlParams={`&studentId=${recordId}`}
        />
      </Box> */}
    </Box>
  );
}
