import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { createRecord } from '@config/functions/requests';
import { computeUrls } from '@config/routes';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useQueryClient } from 'react-query';

const { generateMicroserviceErdUrls } = computeUrls;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const headingStyles = {
  fontSize: '1.3rem',
  lineHeight: '24px',
  color: '#424242',
  fontWeight: '500',
  mb: 2,
};

export default function GenerateErdPopup({
  open,
  setOpen,
  microserviceName,
  microserviceId,
  initialErdUrl,
  user,
}) {
  const queryClient = useQueryClient();
  const [state, setState] = React.useState({
    phase: 'confirm',
  });

  const handleClose = React.useCallback(() => setOpen(false), [setOpen]);

  const handleConfirm = React.useCallback(async () => {
    setState((s) => ({ ...s, phase: 'loading' }));

    try {
      const { data } = await createRecord({
        values: {},
        url: generateMicroserviceErdUrls.detail(microserviceId),
        token: user.token,
        actAs: user?.actAs,
      });

      queryClient.invalidateQueries([`${microserviceId}-record-details`]);
      setState({ phase: 'success', payload: data });
    } catch (error) {
      console.log(error?.response?.data ?? error?.message);
      setState((s) => ({ ...s, phase: 'error' }));
    }
  }, [microserviceId, user]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='sm'
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            ERD for {microserviceName}
          </Typography>
          <Button autoFocus color='inherit' onClick={handleClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 6, height: 'calc(100% - 64px)' }}>
        {state.phase === 'confirm' ? (
          <Box>
            {/* <Divider sx={{ my: 4 }}>OR</Divider> */}

            <Typography sx={headingStyles}>Generate a new ERD</Typography>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                color: '#616161',
                mb: 2,
              }}
            >
              This action will generate an ERD diagram for {microserviceName}
              {initialErdUrl ? (
                <>
                  {' '}
                  and its models. Or you can view the existing ERD using{' '}
                  <a
                    href={initialErdUrl}
                    style={{ textDecoration: 'none' }}
                    target='_blank'
                    rel='noreferrer'
                  >
                    this link
                  </a>
                </>
              ) : null}
              .
            </Typography>
            <Button variant='outlined' onClick={handleConfirm}>
              Confirm
            </Button>
          </Box>
        ) : null}

        {state?.phase === 'loading' ? (
          <>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '20px',
                color: '#616161',
                mb: 4,
              }}
            >
              Generating the ERD diagram for {microserviceName} and its models.
              Please wait...
            </Typography>

            <LinearProgress />
          </>
        ) : null}

        {state?.phase === 'success' ? (
          <>
            <Alert icon={<CheckIcon fontSize='inherit' />} severity='success'>
              The ERD diagram for {microserviceName} and its models has been
              successfully generated.
            </Alert>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
              <Typography
                sx={{
                  mr: 1,
                  color: '#424242',
                  fontWeight: '500',
                }}
              >
                ERD Link:
              </Typography>

              <a
                href={state?.payload?.url}
                style={{ textDecoration: 'none' }}
                target='_blank'
                rel='noreferrer'
              >
                {state?.payload?.url}
              </a>
            </Box>
          </>
        ) : null}

        {state?.phase === 'error' ? (
          <Alert severity='error'>
            Failed to generate the ERD diagram for {microserviceName} and its
            models. Please try again later.
          </Alert>
        ) : null}
      </Box>
    </Dialog>
  );
}
