import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import UiPreFixes from '@pages/System/UiPreFixes';
import ERDDetail from '@pages/System/ERDs/detail';
import Logs from '@pages/System/Logs';
import SystemLogs from '@pages/System/SystemLogs';
import ImportLogs from '@pages/System/ImportLogs';
import VisitorLogs from '@pages/System/VisitorLogs';
import ShapeTransitionLogs from '@pages/System/ShapeTransitionLogs';
import LogDetail from '@pages/System/Logs/detail';
import ShapeTransitionLogsDetail from '@pages/System/ShapeTransitionLogs/detail';
import Templates from '@pages/System/Templates';
import AutomataConnectionWithModels from '@pages/System/AutomataConnectionWithModels';
import TemplateDetail from '@pages/System/Templates/detail';

export default function () {
  return (
    <>
      <Route path='/ui-prefixes' element={<WithLayout comp={UiPreFixes} />} />
      <Route path='/erds/:id' element={<WithLayout comp={ERDDetail} />} />
      <Route path='/logs' element={<WithLayout comp={Logs} />} />
      <Route path='/system-logs' element={<WithLayout comp={SystemLogs} />} />
      <Route path='/import-logs' element={<WithLayout comp={ImportLogs} />} />
      <Route path='/visitor-logs' element={<WithLayout comp={VisitorLogs} />} />
      <Route path='/templates' element={<WithLayout comp={Templates} />} />
      <Route
        path='/automata-connection-with-models'
        element={<WithLayout comp={AutomataConnectionWithModels} />}
      />
      <Route
        path='/templates/:id'
        element={<WithLayout comp={TemplateDetail} />}
      />
      <Route
        path='/shape-transition-logs'
        element={<WithLayout comp={ShapeTransitionLogs} />}
      />
      <Route path='/logs/:id' element={<WithLayout comp={LogDetail} />} />
      <Route
        path='/shape-transition-logs/:id'
        element={<WithLayout comp={ShapeTransitionLogsDetail} />}
      />
    </>
  );
}
