import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { urlShortenerUrls } from '@config/routes';
import Button from '@mui/material/Button';
import { AutocompleteFieldV2 } from '@components/Inputs';
import EmailTemplateAccordion from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateAccordion';
import useToast from '@hooks/useToast';
import {
  getReadOnlyStyles,
  handleAccordionItemDelete,
} from '@config/functions/emailTemplateConfigurationPopupFunctions';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import IconButton from '@mui/material/IconButton';
import LinkDropdown from '@dropdown-forms/urlShortener/LinkDropdown';
import { useQueryClient } from 'react-query';

const cardStyles = {
  p: 2,
  borderRadius: '4px',
  backgroundColor: '#f7f7f7',
  border: '1px solid #c8c8c8',
};

const { linkUrls } = urlShortenerUrls;

export default function EmailTemplateShortLinkCard({
  shortLinkIndex,
  doc,
  setFieldValue,
  remove,
  workflowId,
  workflowName,
  showAddReminder,
  setShowAddReminder,
  shape,
  shapeAction,
  values,
  user,
  isKanbanView,
  errors,
}) {
  const [notify] = useToast();
  const [isDeleting, setDeleting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  const accordionLabel = `Short Link ${shortLinkIndex + 1}`;

  const fieldName = `short_links-${shortLinkIndex}-short_link`;

  const handleToggle = () => setOpen(!open);
  const handleIna = (moreNotes = '') =>
    setShowAddReminder({
      ...showAddReminder,
      initialNotes: `Ref: Workflows > ${workflowName} > ${shape?.name} > ${
        shapeAction?.name
      } > Short Links > ${accordionLabel}${
        moreNotes ? ` > ${moreNotes}` : ''
      }\n\n`,
      open: true,
    });

  const renderShortLinks = ({ id, title }) => ({
    label: title,
    static_value: id,
    mapping_field_name: null,
  });

  return (
    <EmailTemplateAccordion
      open={open}
      setOpen={handleToggle}
      label={accordionLabel}
      isError={errors?.short_links?.[shortLinkIndex] ?? false}
      endContent={() => (
        <Button
          variant="text"
          color="error"
          size="small"
          disabled={isDeleting || isKanbanView}
          onClick={() => {
            handleAccordionItemDelete({
              setDeleting,
              notify,
              remove,
              index: shortLinkIndex,
            });
          }}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </Button>
      )}
    >
      <Stack
        spacing={2}
        sx={{
          ...cardStyles,
        }}
      >
        <Box sx={{ ...getReadOnlyStyles(isKanbanView) }}>
          <LinkDropdown
            user={user}
            actionsDisabled={isKanbanView}
            initialId={
              values?.short_links[shortLinkIndex][fieldName]?.static_value
            }
            refetch={(newUrlShortener) => {
              const newShortLinks = [...values.short_links];
              newShortLinks[shortLinkIndex][fieldName] = {
                label: newUrlShortener?.title,
                static_value: newUrlShortener?.id,
                mapping_field_name: null,
              };
              setFieldValue('short_links', newShortLinks);
              setFieldValue(
                fieldName,
                newShortLinks[shortLinkIndex][fieldName]
              );
              queryClient.invalidateQueries([
                `email-template-setup-short_links-${shortLinkIndex}-${workflowId}`,
              ]);
            }}
            getCustomActions={() => (
              <IconButton
                aria-label="add-ina"
                color="primary"
                sx={{ pr: '1px' }}
                disabled={isKanbanView}
                onClick={() => handleIna('Short Link')}
              >
                <PlaylistAddIcon fontSize="inherit" />
              </IconButton>
            )}
          >
            <AutocompleteFieldV2
              idKey="static_value"
              label="Short Links"
              values={values}
              name={fieldName}
              fetchUrl={linkUrls.list}
              requestKey={`email-template-setup-short_links-${shortLinkIndex}-${workflowId}`}
              renderRow={renderShortLinks}
              onChange={(newValue) => {
                if (!newValue) return;
                const newShortLinks = [...values.short_links];
                newShortLinks[shortLinkIndex][fieldName] = newValue;
                setFieldValue('short_links', newShortLinks);
              }}
              required
            />
          </LinkDropdown>
        </Box>
      </Stack>
    </EmailTemplateAccordion>
  );
}
