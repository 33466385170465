import React from 'react';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

export default function DropdownAddHOCNew({
  children,
  setOpen,
  setAction,
  selected,
  getCustomActions = () => null,
  actionsDisabled = false,
}) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {children}
      <IconButton
        color="primary"
        sx={{ ml: 1, p: 0 }}
        disabled={actionsDisabled}
        component="span"
        onClick={() => {
          setOpen(true);
          setAction('Add');
        }}
      >
        <AddIcon />
      </IconButton>
      <IconButton
        color="primary"
        component="span"
        sx={{ ml: '3px', p: 0 }}
        disabled={!!!selected || actionsDisabled}
        onClick={() => {
          setOpen(true);
          setAction('Edit');
        }}
      >
        <EditIcon />
      </IconButton>
      {getCustomActions()}
    </Box>
  );
}
