import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { hrUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { recruitmentPipelineMeta } from '@config/meta/hr';
import { RecruitmentPipelineStageForm } from '@config/forms/hr';
import { recruitmentPipelineStagesRowsMap } from '@config/handleRows/hr';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { recruitmentPipelineUrls, recruitmentPipelineStagesUrls } = hrUrls;

export default function RecruitmentPipelineDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [recruitmentStagesListData, setRecruitmentStagesListData] =
    React.useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => recruitmentPipelineMeta({ data }),
    []
  );

  const pipelineStagesColOptions = React.useMemo(() => {
    return {
      nextOrder: recruitmentStagesListData?.totalCount ?? 1,
    };
  }, [recruitmentStagesListData?.totalCount]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/recruitment-pipelines`,
                text: 'Recruitment Pipelines',
              },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={recruitmentPipelineUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.HR.name}
          model={microservices.HR.models.recruitmentPipelineStage}
          columnKey="recruitmentPipelineStageHR"
          columnOptions={pipelineStagesColOptions}
          label="Pipeline Stages"
          prefix="0es"
          labels={labels}
          editMode={editMode}
          urls={recruitmentPipelineStagesUrls}
          addNewForm={{
            getFields: () => RecruitmentPipelineStageForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { email_template, ...rest } = values;

                const { data } = await createRecord({
                  values: {
                    ...rest,
                    email_template: email_template?.id,
                    recruitment_pipeline: recordId,
                  },
                  url: recruitmentPipelineStagesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);

                refetch();
                return data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          customSortColumnName="order"
          sortColumnBy="asc"
          handleRowMap={recruitmentPipelineStagesRowsMap}
          urlParams={`&recruitment_pipeline=${recordId}`}
          getListData={(data) => setRecruitmentStagesListData(data)}
        />
      </Box>
    </Box>
  );
}
