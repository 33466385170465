import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Typography,
  MenuItem,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextField, SelectField } from '@components/Inputs';
import { createRecord, updateRecord } from '@config/functions/requests';
import { eDocUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { useQueryClient } from 'react-query';
import { v4 as uuid } from 'uuid';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const documentFieldTypes = ['Text', 'Signature', 'Date'];

const { documentFieldDefnsUrls } = eDocUrls;

export default function DocumentFieldDefnPopup({
  open,
  setOpen,
  initialValues,
  documentId,
  action,
  labels,
  editMode,
  prefix,
}) {
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const isActionEdit = action === 'edit';

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent maxWidth="sm" fullWidth>
        {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="Document Field"
          >
            {(text) => {
              return (
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {text}
                </Typography>
              );
            }}
          </RenderUiPrefix>
        ) : (
          <Typography variant="h6" sx={{ mb: 2 }}>
            Document Field
          </Typography>
        )}
        <Formik
          initialValues={{
            name: isActionEdit ? initialValues?.name : '',
            type: isActionEdit ? initialValues?.type : documentFieldTypes[0],
            description: isActionEdit ? initialValues?.description : '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(),
            type: Yup.string().oneOf(documentFieldTypes),
            description: Yup.string().nullable(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              const payload = {
                ...values,
                id: isActionEdit ? initialValues?.id : uuid(),
                documentId,
              };

              const requestPayload = {
                values: payload,
                url: documentFieldDefnsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              };

              if (isActionEdit && initialValues?.id) {
                requestPayload.url = documentFieldDefnsUrls.detail(
                  initialValues?.id
                );
                await updateRecord(requestPayload);
              } else {
                await createRecord(requestPayload);
              }

              resetForm();
              queryClient.invalidateQueries([`${documentId}-edit-document`]);
              setOpen(false);
            } catch (error) {
              console.log(error?.response?.data ?? error?.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField label="Name" name="name" multiline required />
                </Grid>

                <Grid item xs={4}>
                  <SelectField name="type" label="Type" value={values.type}>
                    {documentFieldTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    name="description"
                    minRows={2}
                    multiline
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
