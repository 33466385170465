import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { setRebaseDetail } from '@redux/pmRebaseSlice';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function RebasePastErrorMessageDialogBox({
  open,
  task,
  labels,
  editMode,
  err,
}) {
  const dispatch = useDispatch();

  const details = {
    Project: {
      headline: {
        code: 'sAO',
        defaultValue: 'Started Date Change Failed',
      },
      body: {
        code: 'eHN',
        defaultValue:
          'The tasks dates have conflict with project started date, please update the conflicted tasks and try again.',
      },
    },
    Task: {
      headline: {
        code: err?.started ? '25f' : 'lsq',
        defaultValue: err?.started
          ? 'Started Date Change Failed'
          : 'Deadline Date Change Failed',
      },
      body: {
        code: err?.started ? 'I88' : 'TBN',
        defaultValue: err?.started
          ? 'The subtasks started dates have conflict with task started date, please update the conflicted tasks and try again.'
          : 'The subtasks deadline dates have conflict with task deadline date, please update the conflicted tasks and try again.',
      },
    },
  };

  console.log(err);
  const mode = task ? `Task` : `Project`;

  return (
    <Dialog
      open={open}
      onClose={() => null}
      fullWidth
      maxWidth="sm"
      aria-labelledby="start-automation"
    >
      <RenderUiPrefix
        editMode={editMode}
        labels={labels}
        code={details[mode]?.headline?.code}
        defaultValue={details[mode]?.headline?.defaultValue}
      >
        {(text) => <DialogTitle id="delete-dialog-title">{text}</DialogTitle>}
      </RenderUiPrefix>
      <DialogContent>
        <RenderUiPrefix
          editMode={editMode}
          labels={labels}
          code={details[mode]?.body?.code}
          defaultValue={details[mode]?.body?.defaultValue}
        >
          {(text) => (
            <DialogContentText
              id="delete-dialog-description"
              sx={{
                mb: '10px',
              }}
            >
              {text}
            </DialogContentText>
          )}
        </RenderUiPrefix>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(
                setRebaseDetail({
                  open: false,
                  error: null,
                })
              );
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              window.open(err?.conflicted_tasks_csv_file, '_blank');
              dispatch(
                setRebaseDetail({
                  open: false,
                  error: null,
                })
              );
            }}
          >
            Download Conflicted Tasks
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
