import React from 'react';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { TextField, SelectField, CheckBoxField } from '@components/Inputs';
import ColorPicker from '@components/accounts/ColorPicker';
import {
  wifiTypeOptions,
  twitterTypeOptions,
  qrShape,
} from '@config/constants';
import { useDropzone } from 'react-dropzone';
import { QRCode } from 'react-qrcode-logo';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FileField from '@components/accounts/FileField';

export const QrForm = (
  value,
  setValue,
  url,
  setAttachments,
  options,
  setFieldValue,
  values
) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={6}>
        <TextField label='QR Code Name' name='name_of_qrcode' required />
      </Grid>
      <TabContext value={value}>
        <Grid item xs={4} sm={8} md={12}>
          <TabList onChange={handleChange} aria-label='lab API tabs example'>
            <Tab label='Vcard' iconPosition='start' value='vcard' />
            <Tab label='Email' iconPosition='start' value='email' />
            <Tab label='SMS' iconPosition='start' value='sms' />
            <Tab label='WiFi' iconPosition='start' value='wifi' />
            <Tab label='Twitter' iconPosition='start' value='twitter' />
            <Tab label='Facebook' iconPosition='start' value='facebook' />
            <Tab label='App Store' iconPosition='start' value='appstore' />
            <Tab label='Mp3' iconPosition='start' value='mp3' />
            <Tab label='Image' iconPosition='start' value='image' />
          </TabList>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <TabPanel value='vcard'>
            {VCardField({ url, setAttachments, options })}
          </TabPanel>
          <TabPanel value='email'>
            {EmailField({ url, setAttachments, options })}
          </TabPanel>
          <TabPanel value='sms'>
            {SMSField({ url, setAttachments, options })}
          </TabPanel>
          <TabPanel value='wifi'>
            {WifiField({ url, setAttachments, options })}
          </TabPanel>
          <TabPanel value='twitter'>
            {TwitterField({ url, setAttachments, options, setFieldValue, values })}
          </TabPanel>
          <TabPanel value='facebook'>
            {FacebookField({ url, setAttachments, options })}
          </TabPanel>
          <TabPanel value='appstore'>
            {AppStoreField({ url, setAttachments, options })}
          </TabPanel>
          <TabPanel value='mp3'>
            {Mp3Field({ url, setAttachments, options })}
          </TabPanel>
          <TabPanel value='image'>
            {ImageField({ url, setAttachments, options })}
          </TabPanel>
        </Grid>
      </TabContext>
    </Grid>
  );
};

export const VCardField = ({ url, setAttachments, options }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField label='First name' name='first_name' required />
          </Grid>

          <Grid item xs={6}>
            <TextField label='Last name' name='last_name' required />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Mobile' name='mobile' />
          </Grid>
          <Grid item xs={6}>
            <TextField label='Phone' name='phone' />
          </Grid>

          <Grid item xs={6}>
            <TextField label='Fax' name='fax' />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Email' name='email' />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Street' name='street' />
          </Grid>

          <Grid item xs={8}>
            <TextField label='City' name='city' />
          </Grid>
          <Grid item xs={4}>
            <TextField label='Zip' name='zip' />
          </Grid>
          <Grid item xs={12}>
            <TextField label='State' name='state' />
          </Grid>

          <Grid item xs={12}>
            <TextField label='Country' name='country' />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Website' name='website' />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Summary' name='summary' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            {url ? <QRCode value={url} {...options} /> : null}
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColorPicker label='Background Color' name='bgColor' />
              </Grid>
              <Grid item xs={12}>
                <ColorPicker label='Foreground color' name='fgColor' />
              </Grid>
              <Grid item xs={12}>
                <SelectField label='Shape' name='shape'>
                  {qrShape.map((o, oIdx) => (
                    <MenuItem value={o.value} key={oIdx.toString()}>
                      {o.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed #c1c7d0',
                    p: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: '#7a869a',
                      }}
                    >
                      logo to attach, or{' '}
                      <Typography component='span' sx={{ color: 'blue' }}>
                        browse
                      </Typography>
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const EmailField = ({ url, setAttachments, options }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);
  return (
    <Grid container spacing={5}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label='Email' name='email' required />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Subject' name='subject' />
          </Grid>

          <Grid item xs={12}>
            <TextField label='Message' name='message' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            {url ? <QRCode value={url} {...options} /> : null}
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColorPicker label='Background Color' name='bgColor' />
              </Grid>
              <Grid item xs={12}>
                <ColorPicker label='Foreground color' name='fgColor' />
              </Grid>
              <Grid item xs={12}>
                <SelectField label='Shape' name='shape'>
                  {qrShape.map((o, oIdx) => (
                    <MenuItem value={o.value} key={oIdx.toString()}>
                      {o.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed #c1c7d0',
                    p: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: '#7a869a',
                      }}
                    >
                      logo to attach, or{' '}
                      <Typography component='span' sx={{ color: 'blue' }}>
                        browse
                      </Typography>
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const SMSField = ({ url, setAttachments, options }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);
  return (
    <Grid container spacing={5}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label='Number' name='number' required />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Message' name='message' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            {url ? <QRCode value={url} {...options} /> : null}
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColorPicker label='Background Color' name='bgColor' />
              </Grid>
              <Grid item xs={12}>
                <ColorPicker label='Foreground color' name='fgColor' />
              </Grid>
              <Grid item xs={12}>
                <SelectField label='Shape' name='shape'>
                  {qrShape.map((o, oIdx) => (
                    <MenuItem value={o.value} key={oIdx.toString()}>
                      {o.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed #c1c7d0',
                    p: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: '#7a869a',
                      }}
                    >
                      logo to attach, or{' '}
                      <Typography component='span' sx={{ color: 'blue' }}>
                        browse
                      </Typography>
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const WifiField = ({ url, setAttachments, options }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);
  return (
    <Grid container spacing={5}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label='Network Name' name='network_name' required />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Password' name='password' required />
          </Grid>
          <Grid item xs={12}>
            <SelectField label='Encryption' name='encryption'>
              {wifiTypeOptions.map((r) => (
                <MenuItem value={r.value} key={r.value}>
                  {r.label}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={12}>
            <CheckBoxField label='Hidden' name='hidden' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            {url ? <QRCode value={url} {...options} /> : null}
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColorPicker label='Background Color' name='bgColor' />
              </Grid>
              <Grid item xs={12}>
                <ColorPicker label='Foreground color' name='fgColor' />
              </Grid>
              <Grid item xs={12}>
                <SelectField label='Shape' name='shape'>
                  {qrShape.map((o, oIdx) => (
                    <MenuItem value={o.value} key={oIdx.toString()}>
                      {o.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed #c1c7d0',
                    p: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: '#7a869a',
                      }}
                    >
                      logo to attach, or{' '}
                      <Typography component='span' sx={{ color: 'blue' }}>
                        browse
                      </Typography>
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const TwitterField = ({
  url,
  setAttachments,
  options,
  setFieldValue,
  values,
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectField
              label='Choose an option'
              name='choose_an_option'
              onChange={(e) => {
                setFieldValue('choose_an_option', e.target.value || '');
              }}
            >
              {twitterTypeOptions.map((r) => (
                <MenuItem value={r.value} key={r.value}>
                  {r.label}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          {values.choose_an_option === 'Link To Your Profile' ? (
            <Grid item xs={12}>
              <TextField label='Your Username' name='your_username' required />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TextField label='Tweet' name='your_username' required />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            {url ? <QRCode value={url} {...options} /> : null}
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColorPicker label='Background Color' name='bgColor' />
              </Grid>
              <Grid item xs={12}>
                <ColorPicker label='Foreground color' name='fgColor' />
              </Grid>
              <Grid item xs={12}>
                <SelectField label='Shape' name='shape'>
                  {qrShape.map((o, oIdx) => (
                    <MenuItem value={o.value} key={oIdx.toString()}>
                      {o.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed #c1c7d0',
                    p: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: '#7a869a',
                      }}
                    >
                      logo to attach, or{' '}
                      <Typography component='span' sx={{ color: 'blue' }}>
                        browse
                      </Typography>
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const FacebookField = ({ url, setAttachments, options }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);
  return (
    <Grid container spacing={5}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label='Facebook URL' name='facebook_URL' required />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Name' name='name' required />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Title' name='title' />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField label='Website' name='website' />
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            {url ? <QRCode value={url} {...options} /> : null}
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColorPicker label='Background Color' name='bgColor' />
              </Grid>
              <Grid item xs={12}>
                <ColorPicker label='Foreground color' name='fgColor' />
              </Grid>
              <Grid item xs={12}>
                <SelectField label='Shape' name='shape'>
                  {qrShape.map((o, oIdx) => (
                    <MenuItem value={o.value} key={oIdx.toString()}>
                      {o.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed #c1c7d0',
                    p: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: '#7a869a',
                      }}
                    >
                      logo to attach, or{' '}
                      <Typography component='span' sx={{ color: 'blue' }}>
                        browse
                      </Typography>
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const AppStoreField = ({ url, setAttachments, options }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);
  return (
    <Grid container spacing={5}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label='App store'
              name='app_store'
              placeholder='https://'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Google Play'
              name='google_play'
              placeholder='https://'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField label='App Name' name='app_name' />
          </Grid>
          <Grid item xs={6}>
            <TextField label='Developer' name='developer' />
          </Grid>

          <Grid item xs={6}>
            <TextField label='Title' name='title' />
          </Grid>
          <Grid item xs={6}>
            <TextField label='Description' name='description' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            {url ? <QRCode value={url} {...options} /> : null}
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColorPicker label='Background Color' name='bgColor' />
              </Grid>
              <Grid item xs={12}>
                <ColorPicker label='Foreground color' name='fgColor' />
              </Grid>
              <Grid item xs={12}>
                <SelectField label='Shape' name='shape'>
                  {qrShape.map((o, oIdx) => (
                    <MenuItem value={o.value} key={oIdx.toString()}>
                      {o.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed #c1c7d0',
                    p: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: '#7a869a',
                      }}
                    >
                      logo to attach, or{' '}
                      <Typography component='span' sx={{ color: 'blue' }}>
                        browse
                      </Typography>
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Mp3Field = ({ url, setAttachments, options }) => {
  const fileRef = React.useRef(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);
  return (
    <Grid container spacing={5}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: '14px',
              }}
            >
              Upload Mp3
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <FileField label='Upload Mp3' name='upload_mp3' ref={fileRef} />
          </Grid>
          <Grid item xs={6}>
            <TextField label='Title' name='title' />
          </Grid>
          <Grid item xs={6}>
            <TextField label='Website' name='website' />
          </Grid>
          <Grid item xs={6}>
            <TextField label='More Info' name='more_info' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            {url ? <QRCode value={url} {...options} /> : null}
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColorPicker label='Background Color' name='bgColor' />
              </Grid>
              <Grid item xs={12}>
                <ColorPicker label='Foreground color' name='fgColor' />
              </Grid>
              <Grid item xs={12}>
                <SelectField label='Shape' name='shape'>
                  {qrShape.map((o, oIdx) => (
                    <MenuItem value={o.value} key={oIdx.toString()}>
                      {o.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed #c1c7d0',
                    p: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: '#7a869a',
                      }}
                    >
                      logo to attach, or{' '}
                      <Typography component='span' sx={{ color: 'blue' }}>
                        browse
                      </Typography>
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ImageField = ({ url, setAttachments, options }) => {
  const fileRef = React.useRef(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);
  return (
    <Grid container spacing={5}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: '14px',
              }}
            >
              Upload Image
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <FileField label='Upload Image' name='upload_image' ref={fileRef} />
          </Grid>
          <Grid item xs={6}>
            <TextField label='Title' name='title' />
          </Grid>
          <Grid item xs={6}>
            <TextField label='Website' name='website' />
          </Grid>
          <Grid item xs={6}>
            <TextField label='Description' name='description' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            {url ? <QRCode value={url} {...options} /> : null}
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColorPicker label='Background Color' name='bgColor' />
              </Grid>
              <Grid item xs={12}>
                <ColorPicker label='Foreground color' name='fgColor' />
              </Grid>
              <Grid item xs={12}>
                <SelectField label='Shape' name='shape'>
                  {qrShape.map((o, oIdx) => (
                    <MenuItem value={o.value} key={oIdx.toString()}>
                      {o.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed #c1c7d0',
                    p: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: '#7a869a',
                      }}
                    >
                      logo to attach, or{' '}
                      <Typography component='span' sx={{ color: 'blue' }}>
                        browse
                      </Typography>
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
