import React from 'react';
import { Box } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { selectVacanciesBoard, setVacancies } from '@redux/vacancySlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { selectProfile } from '@redux/profileSlice';
import { hrUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { updateRecord } from '@config/functions/requests';
import {
  handleDragEnd,
  getAuthHeader,
  getLastSelectedDropdownOption,
} from '@config/functions/helperFunctions';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';

const { vacancyStagesUrls, vacanciesUrls, candidatesUrls } = hrUrls;

export default function VacanciesBoard({ labels, editMode }) {
  const { vacancies: vacanciesState } = useSelector(selectVacanciesBoard);
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [notify, update] = useToast();
  const initialVacancy = getLastSelectedDropdownOption('lastSelectedVacancy');
  const [selectedVacancy, setSelectedVacancy] = React.useState(initialVacancy);

  const { data, isLoading } = useRQuery({
    key: ['vacancies-board', selectedVacancy, user.token, user?.actAs],
    url: vacancyStagesUrls.list(
      `?vacancy=${
        selectedVacancy && selectedVacancy.id
      }&order=order&page_size=200`
    ),
    options: { enabled: !!(user.token && selectedVacancy) },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleSaveCardDrag = async ({ newList, removedItem, destId }) => {
    const tId = notify('Saving....', { loading: true });
    dispatch(setVacancies(newList));
    try {
      await updateRecord({
        url: candidatesUrls.detail(removedItem.id),
        values: { status: destId },
        token: user.token,
        actAs: user?.actAs,
      });
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(
        err.response && err.response.data ? err.response.data : err.message
      );
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    if (data) dispatch(setVacancies(data));
  }, [data, dispatch]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title='Vacancies'
        prefix='0ns'
        labels={labels}
        editMode={editMode}
        filterOptions={[
          {
            label: 'Vacancy',
            name: 'selectedVacancy',
            requestKey: 'vacancies-board-vacancies',
            fetchUrl: vacanciesUrls.list,
            handleChange: (newValue) => {
              setSelectedVacancy(newValue);
              localStorage.setItem(
                'lastSelectedVacancy',
                JSON.stringify(newValue)
              );
            },
            renderRow: (r) => ({
              id: r.id,
              label: r.position_title,
            }),
            orderingCol: 'position_title',
            initialValue: initialVacancy,
          },
        ]}
      />

      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            <DragDropContext
              onDragEnd={(res) =>
                handleDragEnd(res, vacanciesState, handleSaveCardDrag)
              }
            >
              <KanbanBoardList
                detailLink='candidates'
                data={vacanciesState}
                labelCol='personal_email'
                labels={labels}
                editMode={editMode}
              />
            </DragDropContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}
