// handleRows

export const relationShipsRowsMap = (r) => ({
  id: r.id,
  name: r?.name,
  description: r?.description,
  created_at: r.created_at,
});


export const clientsRowsMap = (r) => ({
  id: r.id,
  name: r?.name,
  domain: r?.domain,
  created_at: r.created_at,
});

export const automataConnectionWithAModelsRowsMap = (r) => ({
  id: r.id,
  model: {
    id: r?.model,
    label: r?.details?.model?.name ?? '',
  },
  automata: {
    id: r?.automata,
    label: r?.details?.automata?.name ?? '',
  },
  created_at: r.created_at,
});

export const templatesMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  model: {
    id: r?.model,
    label: r?.details?.model?.name ?? '',
  },
  created_at: r.created_at,
});

export const childTemplatesMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  model: {
    id: r?.model,
    label: r?.details?.model?.name ?? '',
  },
  created_at: r.created_at,
});

export const uiPrefixesMap = (r) => ({
  id: r.id,
  code: r.code,
  label_en: r.label_en,
  label_fr: r.label_fr,
  label_de: r.label_de,
  label_ur: r.label_ur,
  created_at: r.created_at,
});

export const logMap = (row) => ({
  id: row?.id,
  user: row?.details?.user
    ? {
      id: row?.user,
      label: row?.details?.user?.email,
    }
    : null,
  microservice: row?.microservice,
  model: row?.model,
  object_id: row?.object_id,
  action: row?.action,
  before_action_content: row?.before_action_content,
  after_action_content: row?.after_action_content,
  action_datetime: row?.action_datetime,
  date_created: row?.date_created,
});

export const SystemLogMap = (row) => ({
  id: row?.id,
  microservice: row?.microservice,
  file_name: row?.file_name,
  logs_date: row?.logs_date,
  date_created: row?.date_created,
});

export const importLogMap = (row) => ({
  id: row?.id,
  name: row?.name,
  microservice_id: row.microservice_id,
  microservice: row?.microservice,
  model: row?.model,
  model_id: row?.model_id,
  total_rows: row?.total_rows,
  failed_count: row?.failed_count,
  imported_count: row?.imported_count,
  failed: row?.failed,
  imported: row?.imported,
  finished_at: row?.finished_at,
  created_at: row?.created_at,
});

export const visitorLogMap = (row) => ({
  id: row?.id,
  microservice: row?.microservice,
  model: row.model,
  ip: row?.ip,
  os: row?.os,
  browser: row?.browser,
  location: row?.location,
  city: row?.city,
  country: row?.country,
  country_code: row?.country_code,
  postal_code: row?.postal_code,
  longitude: row?.longitude,
  latitude: row?.latitude,
  timezone: row?.timezone,
  utm_campaign: row?.utm_campaign,
  utm_medium: row?.utm_medium,
  utm_source: row?.utm_source,
  created_at: row?.created_at,
});

export const shapeTransitionMap = (row) => ({
  id: row?.id,
  instance:
    row?.details && row?.details.instance
      ? { id: row?.instance, label: row?.details.instance.label }
      : null,
  current_shape: row?.details?.current_shape?.name,
  transition:
    row?.transition !== null
      ? `${row?.details?.transition?.details?.shape?.name} -> ${row?.details?.transition?.details?.successor?.name}`
      : '',
  shape_action: row?.shape_action,
  failure_reason: row?.failure_reason,
  parameters: row?.parameters,
  type: row?.type,
  created_at: row?.created_at,
});

export const clientRelationsRowsMap = (r) => ({
  id: r.id,
  child_client: {
    id: r?.child_client,
    label: r?.details?.child_client?.name ?? '',
  },
  relation: {
    id: r?.relation,
    label: r?.details?.relation?.name ?? '',
  },
  created_at: r.created_at,
});
