import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rooms: [],
};

export const roomsBoardSlice = createSlice({
  name: 'web-chat-rooms',
  initialState,
  reducers: {
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
  },
});

export const { setRooms } = roomsBoardSlice.actions;

export const selectRoomsBoard = (state) => state.roomsBoard;

export default roomsBoardSlice.reducer;
