import React from 'react';
import { Box, Alert, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import DetailCard from '@components/core/DetailCard';
import DetailPageHeader from '@components/DetailPageHeader';
import { hrUrls, nodeDriveUrls } from '@config/routes';
import { vacancyMeta, candidateAdminMeta } from '@config/meta/hr/';
import useToast from '@hooks/useToast';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { keywordRowsMap, candidateRowsMap } from '@config/handleRows/hr';
import { KeywordsForm, CandidatesForm } from '@config/forms/hr';
import { createRecord, updateRecord } from '@config/functions/requests';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import SendEmailDialog from '@components/hr/SendEmailDialog';
import PassphraseLoginModal from '@components/Communication/PassphraseLoginModal';
import Accordion from '@ui/Accordion';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import { AutocompleteField } from '@ui/Inputs';
import TextField from '@ui/Inputs/TextField';
import { useQueryClient } from 'react-query';
import { setAction } from '@redux/operationsSlice';

const {
  vacanciesUrls,
  keywordsUrls,
  candidatesUrls,
  recruitmentPipelineStagesUrls,
  updateBulkCandidatesUrls,
} = hrUrls;
const { filesUrls } = nodeDriveUrls;

export default function VacanciesDetails({ labels, editMode }) {
  const [notify] = useToast();
  const dispatch = useDispatch();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState();
  const [statusTerm, setStatusTerm] = React.useState('');
  const [copied, setCopied] = React.useState(false);
  const [showSendEmail, setShowSendEmail] = React.useState(false);
  const [statusSelected, setStatusSelected] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState('');
  const [expanded, setExpanded] = React.useState(false);
  const [testExpanded, setTestExpanded] = React.useState(false);
  const [secondTestExpanded, setSecondTestExpanded] = React.useState(false);
  const [init, setInit] = React.useState(false);
  const [html, setHtml] = React.useState('');
  const [test, setTest] = React.useState('');
  const [secondTest, setSecondTest] = React.useState('');
  const [tags, setTags] = React.useState('');
  const [status, setStatus] = React.useState(null);
  const [candidateError, setCandidateError] = React.useState('');
  const [dirtyFields, setDirtyFields] = React.useState({});
  const queryClient = useQueryClient();
  const [isUpdating, setUpdating] = React.useState('idle');

  const { data: statuses, isFetching: fetchingStatuses } = useAutocomplete({
    key: 'candidate-statuses',
    getUrl: recruitmentPipelineStagesUrls.list,
    inputValue: statusTerm,
    query:
      recordData && recordData.recruitment_pipeline
        ? `&recruitment_pipeline=${recordData.recruitment_pipeline}`
        : '',
    enabled: !!(recordData && recordData.recruitment_pipeline),
    selected: statusSelected,
  });

  const handleAccordionValueChange = async (values) => {
    try {
      setUpdating('busy');

      await updateRecord({
        url: vacanciesUrls.detail(recordId),
        values,
        token: user.token,
        actAs: user?.actAs,
      });

      setUpdating('done');
      setTimeout(() => {
        setUpdating('idle');
      }, 3000);
    } catch (err) {
      console.log('[Error]', err?.response?.data ?? err?.message);
      setUpdating('failed');
      notify('Failed to save changes.', {
        type: 'ERROR',
      });
    }
  };

  const handleCandidateUpdate = async ({ selectedRows, setSelectedRows }) => {
    try {
      await updateRecord({
        values: selectedRows.map((row) => ({
          id: row,
          status: status.id,
          _tags: tags,
        })),
        url: updateBulkCandidatesUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });
      setSelectedRows([]);
      queryClient.invalidateQueries('Candidates-listData');
      notify('Operation Completed', {
        type: 'SUCCESS',
      });
    } catch (err) {
      if (err.response && Object.keys(err.response.data).length) {
        const error = err.response.data[Object.keys(err.response.data)[0]];
        setCandidateError(typeof error === 'string' ? error : error[0]);
        setTimeout(() => {
          setCandidateError('');
        }, 3000);
      }
    }
  };

  const getSchema = React.useCallback(
    (data) => vacancyMeta({ data, queryClient }),
    []
  );

  React.useEffect(() => {
    let timeoutId = setTimeout(() => {
      handleAccordionValueChange(dirtyFields);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [dirtyFields]);

  React.useEffect(() => {
    if (showLogin === 'authenticated') {
      window.location.reload();
    }
  }, [showLogin]);

  React.useEffect(() => {
    if (!recordData || init) return;
    setTest(recordData?.test ?? '');
    setHtml(recordData?.ad_text ?? '');
    setSecondTest(recordData?.test2 ?? '');
    setInit(true);
  }, [recordData]);

  React.useEffect(() => {
    dispatch(setAction(isUpdating));
  }, [isUpdating]);

  const candidatesColOptions = React.useMemo(() => {
    return {
      queryClient,
      recruitmentPipeline: recordData?.recruitment_pipeline,
    };
  }, [queryClient, recordData?.recruitment_pipeline]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/vacancies`, text: 'Vacancies' },
              { text: recordData && recordData.position_title },
            ]}
          >
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.host}/candidate-form/${recordId}`
                );
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              }}
            >
              {copied ? 'Copied' : 'Copy Link'}
            </Button>
            <a
              href={`/candidate-form/${recordId}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button type="button" variant="outlined" sx={{ ml: 1 }}>
                Preview
              </Button>
            </a>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={vacanciesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      {!!candidateError ? (
        <Alert sx={{ mb: 2.5 }} severity="error">
          {candidateError}
        </Alert>
      ) : null}

      <Box sx={{ mt: 5 }}>
        <Accordion
          expanded={expanded}
          onChange={() => setExpanded((s) => !s)}
          label="Advert Text"
          editMode={editMode}
          labels={labels}
          prefix="0p3"
        >
          {init ? (
            <WysiwygEditor
              initData={html}
              onChange={(e) =>
                setDirtyFields((s) => ({ ...s, ad_text: e.editor.getData() }))
              }
            />
          ) : (
            <p>loading...</p>
          )}
        </Accordion>

        <Accordion
          expanded={testExpanded}
          onChange={() => setTestExpanded((s) => !s)}
          label="1st Test"
          editMode={editMode}
          labels={labels}
          prefix="0b9"
        >
          {init ? (
            <WysiwygEditor
              initData={test}
              onChange={(e) =>
                setDirtyFields((s) => ({ ...s, test: e.editor.getData() }))
              }
            />
          ) : (
            <p>loading...</p>
          )}
        </Accordion>

        <Accordion
          expanded={secondTestExpanded}
          onChange={() => setSecondTestExpanded((s) => !s)}
          label="2nd Test"
          editMode={editMode}
          labels={labels}
          prefix="0o8"
        >
          {init ? (
            <WysiwygEditor
              initData={secondTest}
              onChange={(e) =>
                setDirtyFields((s) => ({ ...s, test2: e.editor.getData() }))
              }
            />
          ) : (
            <p>loading...</p>
          )}
        </Accordion>

        <DetailAccordionView
          microservice={microservices.HR.name}
          model={microservices.HR.models.candidate}
          columnKey="candidateAdminHR"
          columnOptions={candidatesColOptions}
          label="Candidates"
          prefix="0io"
          labels={labels}
          editMode={editMode}
          urls={candidatesUrls}
          handleRowMap={candidateRowsMap}
          urlParams={`&vacancy=${recordId}`}
          customBulkHeaderButton={(selectedRows, data, setSelectedRows) => {
            const [candidateId] = selectedRows;
            const candidate = data.results.find((r) => r.id === candidateId);

            return (
              <>
                <Box sx={{ marginRight: 2, minWidth: '420px' }}>
                  <TextField
                    label="Tags"
                    onChange={(e) => setTags(e.target.value)}
                  />
                </Box>
                <Box sx={{ marginRight: 2, minWidth: '240px' }}>
                  <AutocompleteField
                    label="Status"
                    value={status}
                    setValue={(value) => {
                      setStatus(value);
                      setStatusSelected(true);
                    }}
                    inputValue={statusTerm}
                    options={statuses || []}
                    isLoading={fetchingStatuses}
                    setInputValue={(value) => {
                      setStatusTerm(value);
                      setStatusSelected(false);
                    }}
                  />
                </Box>

                <Box sx={{ marginRight: 2 }}>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() =>
                      handleCandidateUpdate({ selectedRows, setSelectedRows })
                    }
                    disabled={!status && !tags}
                  >
                    Update
                  </Button>
                </Box>

                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => setShowSendEmail(true)}
                >
                  Send Email
                </Button>

                <SendEmailDialog
                  multiple
                  open={showSendEmail}
                  setOpen={setShowSendEmail}
                  vacancy={recordData}
                  selectedRows={selectedRows}
                  meta={candidateAdminMeta({
                    data: candidate,
                    queryClient,
                  })}
                  callback={() => {
                    queryClient.invalidateQueries('Candidates-listData');
                  }}
                  labels={labels}
                  editMode={editMode}
                  prefix="0lr"
                />

                <PassphraseLoginModal
                  open={showLogin === 'login'}
                  setOpen={setShowLogin}
                />
              </>
            );
          }}
          addNewForm={{
            getFields: ({ errors, values }) =>
              CandidatesForm({ errors, values }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                let file = '';
                if (!values.cv === '') {
                  const formData = new FormData();
                  formData.append(
                    'client',
                    user?.actAs?.id || user?.details?.client
                  );
                  formData.append('created_by', recordData?.created_by);
                  formData.append('anonymous_can_see_it', true);
                  formData.append('file', file);

                  const { data: fileData } = await createRecord({
                    values: formData,
                    url: filesUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  file = fileData.fileUrl;
                }

                const response = await createRecord({
                  values: {
                    ...values,
                    cv: file,
                    vacancy: recordId,
                  },
                  url: candidatesUrls.list(),
                });

                resetForm();
                refetch();
                setAddNewForm(false);
                return response?.data?.id;
              } catch (err) {
                console.log(err.response || err.message);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
        />

        <DetailAccordionView
          microservice={microservices.HR.name}
          model={microservices.HR.models.keywords}
          columnKey="keywordHR"
          label="Keywords"
          prefix="0c2"
          labels={labels}
          editMode={editMode}
          urls={keywordsUrls}
          addNewForm={{
            getFields: () => KeywordsForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, vacancy: recordId },
                  url: keywordsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={keywordRowsMap}
          urlParams={`&vacancy=${recordId}`}
        />
      </Box>
    </Box>
  );
}
