import React from 'react';
import { Box } from '@mui/material';
import { colors } from '@config/constants';

export default function Card({ children }) {
  return (
    <Box
      sx={{
        display: 'block',
        mb: '0.75rem',
        padding: '0.5rem 0.8rem',
        width: '100%',
        background: '#fff',
        borderRadius: '4px',
        boxShadow:
          '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        textDecoration: 'none',
        color: colors.dark,
        position: 'relative',
      }}
    >
      {children}
    </Box>
  );
}
