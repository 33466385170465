import * as React from 'react';
import { Menu, IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useQueryClient } from 'react-query';
import { candidateAdminMeta } from '@config/meta/hr/';
import { updateRecord, deleteRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import SendEmailDialog from '@components/hr/SendEmailDialog';
import ReminderPopup from '@components/ReminderPopup';
import CloneTaskPopup from '@components/PM/CloneTaskPopup';
import OpportunitiesDrawer from '@components/OpportunitiesDrawer';
import { crmUrls, pmUrls } from '@config/routes';
import { formatToUTCDateTime } from '@config/functions/helperFunctions';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import RottingPopup from './RottingPopup';
import DeletePromptModal from '@components/DeletePromptModal';

const ITEM_HEIGHT = 48;
const { opportunitiesUrls } = crmUrls;
const { tasksUrls, sprintTasksUrls } = pmUrls;

const getINAMicroservice = (detailLink) => {
  if (detailLink === 'candidates') {
    return 'HR';
  }
  if (detailLink === 'opportunities') {
    return 'CRM';
  }
  return 'PM';
};

const getINAModelName = (detailLink) => {
  if (detailLink === 'candidates') {
    return 'Candidate';
  }
  if (detailLink === 'opportunities') {
    return 'Opportunity';
  }
  return 'Task';
};

const getINALink = (detailLink) => {
  if (detailLink === 'candidates') {
    return `${window.location.host}/candidates/`;
  }
  if (detailLink === 'opportunities') {
    return `${window.location.host}/opportunities/`;
  }
  if (detailLink === 'tasks') {
    return `${window.location.host}/tasks/`;
  }
};

export default function OpportunitiesKanbanCardItem({
  detailLink,
  card,
  list,
  user,
  setShowTasksSidePeek,
}) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showAddReminder, setShowAddReminder] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [showSendEmail, setShowSendEmail] = React.useState(false);
  const [showRotting, setShowRotting] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);
  const [showCloneTask, setShowCloneTask] = React.useState(false);

  const emailTemplate = list?.details?.email_template;

  const onRotUpdate = async (id, status) => {
    if (status) {
      try {
        await updateRecord({
          url: opportunitiesUrls.detail(id),
          values: { status_assigned_date: null },
          token: user.token,
          actAs: user?.actAs,
        });
        setAnchorEl(null);
        queryClient.invalidateQueries(['opportunities-board']);
        notify('Operating completed', {
          type: 'SUCCESS',
        });
      } catch (error) {
        console.log(error?.response?.data ?? error?.message);
        notify('Something went wrong, try refreshing', {
          type: 'ERROR',
        });
      }
    } else {
      try {
        await updateRecord({
          url: opportunitiesUrls.detail(id),
          values: { status_assigned_date: formatToUTCDateTime(new Date()) },
          token: user.token,
          actAs: user?.actAs,
        });
        setAnchorEl(null);
        queryClient.invalidateQueries(['opportunities-board']);
        notify('Operating completed', {
          type: 'SUCCESS',
        });
      } catch (error) {
        console.log(error?.response?.data ?? error?.message);
        notify('Something went wrong, try refreshing', {
          type: 'ERROR',
        });
      }
    }
  };

  const onTaskDelete = async (id, type) => {
    try {
      await deleteRecord({
        url:
          type === 'sprintTask'
            ? sprintTasksUrls.detail(id)
            : tasksUrls.detail(id),
        token: user?.token,
        actAs: user?.actAs,
      });

      setShowDeleteConfirmation(false);
      setAnchorEl(null);

      queryClient.invalidateQueries(['opportunities-board']);
      queryClient.invalidateQueries(['tasks-board']);

      notify('Operating completed', {
        type: 'SUCCESS',
      });
    } catch (error) {
      console.log(error?.response?.data ?? error?.message);
      notify('Something went wrong, try refreshing', {
        type: 'ERROR',
      });
    }
  };

  return (
    <>
      <IconButton
        sx={{
          position: 'absolute',
          right: '4px',
          top: '6px',
          opacity: '0.8',
          '&:hover': {
            opacity: '1',
          },
        }}
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        size='small'
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon
          fontSize='default'
          sx={{ color: card.rotting ? '#fff' : '#000' }}
        />
      </IconButton>
      {detailLink === 'opportunities' && card.ina_count ? (
        <IconButton
          sx={{
            position: 'absolute',
            right: '22px',
            top: '6px',
            opacity: '0.8',
            '&:hover': {
              opacity: '1',
            },
          }}
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          size='small'
          onClick={() => {
            setDrawerOpen(true);
            setAnchorEl(null);
          }}
        >
          <LayersOutlinedIcon
            fontSize='inherit'
            sx={{ color: card.rotting ? '#fff' : '#000' }}
          />
        </IconButton>
      ) : null}
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {detailLink === 'tasks' && setShowTasksSidePeek ? (
          <MenuItem
            size='small'
            onClick={() => {
              setShowTasksSidePeek(true);
              setAnchorEl(null);
            }}
          >
            Data
          </MenuItem>
        ) : null}

        <MenuItem size='small' onClick={() => setShowAddReminder(true)}>
          Add INA
        </MenuItem>

        {detailLink === 'candidates' ? (
          <MenuItem size='small' onClick={() => setShowSendEmail(true)}>
            Send email
          </MenuItem>
        ) : null}
        {detailLink === 'opportunities' ? (
          <>
            <MenuItem
              size='small'
              onClick={() => {
                setDrawerOpen(true);
                setAnchorEl(null);
              }}
            >
              Show INAs
            </MenuItem>
            <MenuItem
              size='small'
              onClick={() =>
                onRotUpdate(card.id, card.status_assigned_date, setAnchorEl)
              }
            >
              {card.status_assigned_date ? 'Do Not Rot' : 'Enable Rotting'}
            </MenuItem>
            <MenuItem
              size='small'
              onClick={() => onRotUpdate(card.id, null, setAnchorEl)}
            >
              Reset Rotting Days
            </MenuItem>
            <MenuItem size='small' onClick={() => setShowRotting(true)}>
              Start Rotting From
            </MenuItem>
          </>
        ) : null}
        {detailLink === 'tasks' ? (
          <>
            <MenuItem size='small' onClick={() => setShowCloneTask(true)}>
              Clone task
            </MenuItem>
            <MenuItem
              size='small'
              onClick={() => setShowDeleteConfirmation(true)}
            >
              Move to trash
            </MenuItem>
          </>
        ) : null}
      </Menu>

      {showSendEmail ? (
        <SendEmailDialog
          open={showSendEmail}
          setOpen={setShowSendEmail}
          vacancy={{
            ...(card?.details?.vacancy ?? {}),
            id: card?.vacancy,
          }}
          selectedRows={[card?.id]}
          meta={candidateAdminMeta({
            data: card,
            queryClient,
          })}
          callback={() => {
            queryClient.invalidateQueries(['vacancies-board']);
          }}
          initialTemplate={
            emailTemplate
              ? { id: emailTemplate?.id, label: emailTemplate?.name }
              : null
          }
        />
      ) : null}

      {showCloneTask ? (
        <CloneTaskPopup
          open={showCloneTask}
          setOpen={setShowCloneTask}
          taskId={card?.id}
          initialOrder={card?.order}
          initialName={card?.name}
        />
      ) : null}

      {showDeleteConfirmation ? (
        <DeletePromptModal
          open={showDeleteConfirmation}
          onDeleteClick={() => {
            if (card?.sprint_task_id) {
              onTaskDelete(card?.sprint_task_id, 'sprintTask');
            }

            if (card?.id) {
              onTaskDelete(card?.id);
            }
          }}
          onCancelClick={() => setShowDeleteConfirmation(false)}
        />
      ) : null}

      {showRotting ? (
        <RottingPopup
          open={showRotting}
          setOpen={setShowRotting}
          id={card?.id}
          initialValue={card?.status_assigned_date}
          url={opportunitiesUrls}
          detailLink={detailLink}
        />
      ) : null}

      {showAddReminder ? (
        <ReminderPopup
          type='INA'
          microservice={getINAMicroservice(detailLink)}
          model={getINAModelName(detailLink)}
          open={showAddReminder}
          setOpen={setShowAddReminder}
          selectedRows={[card.id]}
          setSelectedRows={() => null}
          link={`${getINALink(detailLink)}${card.id}`}
          company={card?.details?.company?.name}
        />
      ) : null}

      {drawerOpen ? (
        <OpportunitiesDrawer
          card={card?.id}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
          anchor='right'
        />
      ) : null}
    </>
  );
}
