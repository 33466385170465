import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  formatDateTime,
  openBrowserPopup,
} from '@config/functions/helperFunctions';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
// import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';

export default function DataPanelTimeline({ sentItems } = {}) {
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
        px: 0,
      }}
    >
      {sentItems.map(
        ({ asset_type, asset_id, sent_at, params, order, stage_name }) => (
          <TimelineItem>
            <TimelineOppositeContent color='textSecondary'>
              {formatDateTime(sent_at)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Button
                variant='text'
                endIcon={<LaunchIcon />}
                sx={{ py: 0, textAlign: 'left' }}
                startIcon={
                  asset_type === 'Mail Templates' ? (
                    <MailOutlineOutlinedIcon />
                  ) : (
                    <DescriptionOutlinedIcon />
                  )
                }
                onClick={() => {
                  if (asset_type === 'Mail Templates') {
                    openBrowserPopup(
                      `/all-emails/${params?.email_id}/?history=true&noDrawer=true`
                    );
                  } else if (asset_type === 'Documents') {
                    openBrowserPopup(
                      `/document-recipients/${params?.recipient_id}?noDrawer=true`
                    );
                  } else if (asset_type === 'Forms') {
                    openBrowserPopup(
                      `/form-responses/${params?.response_id}?noDrawer=true`
                    );
                  }
                }}
              >
                {order} {stage_name}
              </Button>
            </TimelineContent>
          </TimelineItem>
        )
      )}
    </Timeline>
  );
}
