import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Typography } from '@mui/material';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { communicationUrls } from '@config/routes';
import Button from '@ui/Button';
import TextField from '@ui/Inputs/TextField';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import Loader from '@components/Loader';
import ErrorScreen from '@components/ErrorScreen';
import DetailPageHeader from '@components/DetailPageHeader';
import { updateRecord } from '@config/functions/requests';
import { microservices } from '@config/constants';
import AuthWrapper from '@components/Communication/AuthWrapper';
import useAutocomplete from '@hooks/useAutocomplete';

const { mailTemplatesUrls, contextFieldUrls } = communicationUrls;

function EmailTemplateEditor() {
  const { id: emailTemplateId } = useParams();
  const [emailTemplate, setEmailTemplate] = useState({});
  const [subject, setSubject] = useState('');
  const [html, setHtml] = useState('');
  const [updating, setUpdating] = useState(false);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  const {
    data: contextFieldsList,
    isLoading: contextFieldsFetching,
    isError: contextFieldsError,
  } = useAutocomplete({
    key: ['contextFields', emailTemplateId],
    getUrl: contextFieldUrls.list,
    inputValue: '',
    query: `&connected=true&smtp_connected=true&send_emails_from_this_account=true&template=${emailTemplateId}`,
    renderRow: (r) => ({
      id: r.id,
      type: r.type,
      label: r.name,
    }),
  });

  const { data, isFetching, refetch, isError } = useQuery(
    ['email-template-detail', emailTemplateId, user],
    () =>
      axios
        .get(
          mailTemplatesUrls.detail(emailTemplateId),
          getAuthHeader(user.token, user?.actAs, user.encryptionToken)
        )
        .then((res) => res.data),
    { enabled: !!user.token }
  );

  useEffect(() => {
    if (!contextFieldsList) return;
    console.log('here again & again');

    localStorage.setItem(
      'contextFields',
      JSON.stringify(
        contextFieldsList.map((contextField) => [
          `${contextField.label} // ${contextField.type}`,
          `{{ context.${contextField.label} }}`,
        ])
      )
    );
  }, [contextFieldsList]);

  useEffect(() => {
    if (!data) return;

    setHtml(data.html);
    setSubject(data.subject);
    setEmailTemplate(data);
  }, [data]);

  const handleSubmit = async () => {
    try {
      await updateRecord({
        values: {
          html,
          subject,
          id: emailTemplateId,
        },
        url: mailTemplatesUrls.detail(emailTemplateId),
        token: user.token,
        actAs: user?.actAs,
        encryptionToken: user.encryptionToken,
      });
      // notify(`Mail Template Is Updated!`, {
      //   type: 'SUCCESS',
      // });
      refetch();
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data.name) {
        notify(`${err.response.data.name}`, {
          type: 'ERROR',
        });
      } else if (err.response && err.response.data.description) {
        notify(`${err.response.data.description}`, {
          type: 'ERROR',
        });
      } else if (err.response && err.response.data.subject) {
        notify(`${err.response.data.subject}`, {
          type: 'ERROR',
        });
      } else if (err.response && err.response.data.html) {
        notify(`${err.response.data.html}`, {
          type: 'ERROR',
        });
      } else {
        notify(`There was an error, please try again.`, {
          type: 'ERROR',
        });
      }
    }
  };

  return (
    <>
      <Box key="detail-view-container">
        <Box>
          <DetailPageHeader
            key="detail-view-header"
            items={[
              { to: `/email-templates`, text: 'Email Templates' },
              {
                text:
                  emailTemplate && emailTemplate.name ? emailTemplate.name : '',
              },
            ]}
          />
        </Box>

        {isFetching || contextFieldsFetching ? <Loader /> : null}

        {isError || contextFieldsError ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {console.log(contextFieldsError)}
            <ErrorScreen />
          </Box>
        ) : null}

        {!isFetching &&
        !contextFieldsFetching &&
        Object.keys(emailTemplate).length &&
        !isError &&
        !contextFieldsError ? (
          <Box key="detail-view-data-container">
            <Typography
              component="div"
              key="Html"
              variant="h5"
              sx={{ fontWeight: '500' }}
            >
              Subject
            </Typography>
            <Box sx={{ mb: '13px', mt: '13px', background: 'white' }}>
              <TextField
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Subject"
              />
            </Box>
            <Typography
              component="div"
              key="Html"
              variant="h5"
              sx={{ fontWeight: '500' }}
            >
              Message
            </Typography>
            <Box key="WysiwygEditor-container" sx={{ mt: 2 }}>
              <WysiwygEditor
                initData={html}
                msName={microservices.NOTIFICATION.name}
                onChange={(evt) => setHtml(evt.editor.getData())}
              />
            </Box>

            <Box key="button-container" sx={{ mt: 2, textAlign: 'center' }}>
              <Button
                key="SaveHtml"
                variant="contained"
                type="button"
                disabled={updating}
                onClick={async () => {
                  setUpdating(true);
                  await handleSubmit();
                  setUpdating(false);
                }}
                label="Save Html Email Template"
              />
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
}

export default function AuthenticatedEmailTemplateEditor(props) {
  return (
    <AuthWrapper>
      <EmailTemplateEditor {...props} />
    </AuthWrapper>
  );
}
