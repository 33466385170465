import React from 'react';
import * as Yup from 'yup';
import { crmUrls } from '@config/routes';
import { personsMeta } from '@config/meta/crm/index';
import { listsUrls } from '@config/routes';
import { Grid } from '@mui/material';
import {
  TextField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import { useQueryClient } from 'react-query';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import CountryDropdown from '@dropdown-forms/list/CountryDropdown';
import CompanyDropdown from '@dropdown-forms/crm/CompanyDropdown';

const { personsUrls, companiesUrls } = crmUrls;
const { countriesUrls } = listsUrls;

export default function CompanyPersonDropdown({ children, refetch, company }) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [hideModal, setHideModal] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        hidden={hideModal}
        title='Person'
        initialValues={{
          ...getAddFormInitialValues(personsMeta(), 'listView'),
          company_owner: company,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(personsMeta(), 'listView'),
        })}
        getPostUrl={() => personsUrls.list()}
        getValues={({ company_owner, country, ...values }) => ({
          ...values,
          company_owner: company_owner?.id,
          country: country?.id,
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <TextField label='First Name' name='first_name' required />
            </Grid>
            <Grid item xs={6}>
              <TextField label='Last Name' name='last_name' />
            </Grid>
            <Grid item xs={6}>
              <TextField label='Email' name='email' required />
            </Grid>
            <Grid item xs={6}>
              <TextField label='Mobile' name='personal_mobile' />
            </Grid>
            <Grid item xs={6}>
              <TextField label='Address1' name='address1' />
            </Grid>
            <Grid item xs={6}>
              <TextField label='Address2' name='address2' />
            </Grid>
            <Grid item xs={6}>
              <CountryDropdown
                hideParent={setHideModal}
                refetch={({ id, name }) => {
                  setFieldValue('country', { id, label: name });
                  queryClient.invalidateQueries(['person-country']);
                }}
              >
                <AutocompleteFieldV2
                  name='country'
                  label='Country'
                  requestKey='person-country'
                  fetchUrl={countriesUrls.list}
                />
              </CountryDropdown>
            </Grid>
            <Grid item xs={6}>
              <CompanyDropdown
                hideParent={setHideModal}
                refetch={({ id, name }) => {
                  setFieldValue('company_owner', { id, label: name });
                  queryClient.invalidateQueries(['person-dropdown-companies']);
                }}
              >
                <AutocompleteFieldV2
                  name='company_owner'
                  label='Company'
                  requestKey='person-dropdown-companies'
                  fetchUrl={companiesUrls.list}
                />
              </CompanyDropdown>
            </Grid>
            <Grid item xs={6}>
              <TextField label='City' name='city' />
            </Grid>
            <Grid item xs={6}>
              <TextField label='Tags' name='tag' />
            </Grid>
          </Grid>
        )}
        open={open}
        refetch={refetch}
        setOpen={setOpen}
      />
    </>
  );
}
