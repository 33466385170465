import { Box, Typography } from '@mui/material';

export default function LinkExpired() {
  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          mb: 2,
          textAlign: 'center',
        }}
      >
        Link Expired
      </Typography>
      <Typography variant="subtitle1">
        Your link has been expired. Please check your email for new email
        confirmation link.
      </Typography>
    </Box>
  );
}
