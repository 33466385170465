import React from 'react';
import * as Yup from 'yup';
import { labelColumns } from '@config/meta/communication';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { communicationUrls } from '@config/routes';

import axios from 'axios';
import { useQuery } from 'react-query';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';

const { labelsUrls } = communicationUrls;

export default function LabelDropdown({ children, refetch }) {
  const user = useSelector(selectProfile);
  const [open, setOpen] = React.useState(false);
  const [parentLabels, setParentLabels] = React.useState([]);
  const [parentLabelTerm, setParentLabelTerm] = React.useState('');

  const { data: parentLabelsList, isFetching: parentLabelsIsFetching } =
    useQuery(
      [
        'parent-labels',
        parentLabelTerm,
        user.token,
        user?.actAs,
        user.encryptionToken,
      ],
      () =>
        axios
          .get(
            labelsUrls.list(`?search=${parentLabelTerm}`),
            getAuthHeader(user.token, user?.actAs, user.encryptionToken)
          )
          .then((res) => res.data),
      {
        enabled: !!user.token,
      }
    );

  React.useEffect(() => {
    if (!parentLabelsList) return;

    setParentLabels([
      { id: null, label: '------' },
      ...parentLabelsList.results.map((item) => ({
        id: item.id,
        label: item.name,
      })),
    ]);
  }, [parentLabelsList]);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="Label"
        initialValues={{
          ...getAddFormInitialValues(
            labelColumns({
              parentLabels,
              parentLabelsIsFetching,
              setParentLabelTerm,
            }),
            'listView'
          ),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            labelColumns({
              parentLabels,
              parentLabelsIsFetching,
              setParentLabelTerm,
            }),
            'listView'
          ),
        })}
        getPostUrl={() => labelsUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key="name" label="Name" name="name" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
