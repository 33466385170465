import * as React from 'react';
import { drawerWidth } from '@config/constants';
import MenuAppBar from '@components/core/MenuAppBar';
import {
  CssBaseline,
  Box,
  Toolbar,
  CircularProgress,
  Typography,
  Button,
  Dialog,
} from '@mui/material';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import LeftDrawer from '@components/core/LeftDrawer';
import { ToastContainer } from 'react-toastify';
import useWebSocket from 'react-use-websocket';
import { logsUrls, webChatUrls } from '@config/routes';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { encryptionPassKey } from '@config/constants';
import { useDispatch } from 'react-redux';
import { setEncryptionToken } from '@redux/profileSlice';
import useAuthToken from '@hooks/useAuthToken';
import useRQuery from '@hooks/useRQuery';
import useUiPrefixes from '@hooks/useUiPrefixes';
import { Helmet } from 'react-helmet';
import { selectOperations, setEditMode } from '@redux/operationsSlice';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const { messagesUrls } = webChatUrls;
const { notificationUrls, bulkNotificationReadByUrls } = logsUrls;

export default function Layout({ children, user }) {
  const dispatch = useDispatch();
  const [notify] = useToast();
  const [queryParams] = useSearchParams();
  const noDrawer = queryParams.get('noDrawer');
  const { editMode } = useSelector(selectOperations);
  const [notificationsDisplayed, setNotificationsDisplayed] = React.useState(
    []
  );
  const [notifications, setNotifications] = React.useState([]);
  const [laptopOpen, setLaptopOpen] = React.useState(!noDrawer);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [oldUsersWaiting, setOldUserWaiting] = React.useState(0);
  const [usersWaiting, setUserWaiting] = React.useState(0);
  const [waitingRoomsLength, setWaitingRoomsLength] = React.useState(0);
  const { labels, language, setLanguage, loadingUiPrefixes } = useUiPrefixes();

  useAuthToken();

  const mainStyles = {
    flexGrow: 1,
    p: 3,
    width: { sm: laptopOpen ? `calc(100% - ${drawerWidth}px)` : '100%' },
  };

  const onDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setLaptopOpen((s) => !s);
  };

  const { lastMessage } = useWebSocket(
    messagesUrls.list(`?access_token=${user && user.token}`)
  );

  const { data: notificationsList } = useRQuery({
    key: ['notifications', user.token, user?.actAs],
    url: notificationUrls.list(),
    config: getAuthHeader(user.token, user?.actAs),
    options: {
      enabled: !!(user.token && !!user.details),
      refetchInterval: 10000,
    },
  });

  React.useEffect(() => {
    if (!notificationsList || !Array.isArray(notificationsList.results)) {
      return;
    }
    setNotifications(notificationsList.results);
  }, [notificationsList]);

  React.useEffect(() => {
    if (!notifications || !notifications.length) {
      return;
    }

    notifications.map((notification) => {
      if (!notificationsDisplayed.includes(notification.id)) {
        setNotificationsDisplayed([...notificationsDisplayed, notification.id]);
        notify(notification.message, {
          type:
            notification.notification_type === 'Success' ? 'SUCCESS' : 'ERROR',
        });
      }
    });

    try {
      createRecord({
        values: {
          notifications: notifications.map((notification) => notification.id),
        },
        url: bulkNotificationReadByUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });
    } catch (err) {
      console.log(err.message);
    }
  }, [notifications]);

  React.useEffect(() => {
    if (!lastMessage) return;

    try {
      const data = JSON.parse(lastMessage.data);
      if (data.type) return;
      const { rooms } = data.constructor.name === 'Object' ? data : {};
      const [waiting] = Array.isArray(rooms) ? rooms : [{ items: [] }];
      if (waiting && waiting.items.length !== waitingRoomsLength) {
        new Audio(
          'https://drive.pullstream.com/file/eac6a641-a478-4e07-ba59-533414caea0d.wav'
        ).play();
      }

      setWaitingRoomsLength(waiting.items.length);
      setUserWaiting(waiting.items.length);
      if (!waiting.items.length) setOldUserWaiting(0);
    } catch (err) {
      console.log(err.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  React.useEffect(() => {
    const token = localStorage.getItem(encryptionPassKey);
    if (token) dispatch(setEncryptionToken(token));
  }, [dispatch]);

  if (loadingUiPrefixes && !labels.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <link rel="icon" href={`${user.favicon}?obi=true`} />
        <meta name="description" content={user.adminLogo} />
        <title>{user.adminLogo}</title>
      </Helmet>

      <ToastContainer
        position="top-center"
        hideProgressBar
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MenuAppBar
          barColor={user.colors.primary}
          text={user.adminLogo}
          username={user.details && user.details.username}
          onDrawerToggle={onDrawerToggle}
          labels={labels}
          editMode={editMode}
          setEditMode={setEditMode}
          language={language}
          setLanguage={setLanguage}
        />
        <LeftDrawer
          labels={labels}
          editMode={editMode}
          language={language}
          mobileOpen={mobileOpen}
          onDrawerToggle={onDrawerToggle}
          laptopOpen={laptopOpen}
          setLaptopOpen={setLaptopOpen}
        />
        <Box component="main" sx={mainStyles}>
          <Toolbar />
          {React.cloneElement(children, {
            labels,
            editMode,
            language,
          })}
        </Box>
      </Box>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={
          oldUsersWaiting !== usersWaiting && oldUsersWaiting < usersWaiting
        }
        onClose={() => null}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>
            There are {usersWaiting} new user(s) waiting
          </Typography>
          <Button onClick={() => setOldUserWaiting(usersWaiting)}>
            Got it
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
