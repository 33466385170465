import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { calendarUrls } from '@config/routes';
import { remindersMeta } from '@config/meta/crm';
import { useQueryClient } from 'react-query';

const { actionRemindersUrls } = calendarUrls;

export default function ReminderDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const queryClient = useQueryClient();

  // Use the useCallback hook to optimize the getSchema function

  const getSchema = React.useCallback(
    (data) =>
      remindersMeta({
        data,
        queryClient,
      }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/reminders`, text: 'Reminders' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={actionRemindersUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}></Box>
    </Box>
  );
}
