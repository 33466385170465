import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: null,
  manual: false,
  workflows: [],
};

export const bpaBoardSlice = createSlice({
  name: 'bpaBoard',
  initialState,
  reducers: {
    setManual: (state, action) => {
      state.manual = action.payload;
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setWorkflows: (state, action) => {
      state.workflows = action.payload;
    },
  },
});

export const { setManual, setModal, setWorkflows } = bpaBoardSlice.actions;

export const selectBpaBoard = (state) => state.bpaBoard;

export default bpaBoardSlice.reducer;
