import * as Yup from 'yup';
import { fieldType, bpaDryRunDurationUnits } from '@config/constants';
import { bpaUrls } from '@config/routes';

const { metaModelsUrls, assetModelsUrls, stagesUrls } = bpaUrls;

export const assetsPopupMeta = ({ data } = {}) => [
  {
    field: 'type',
    headerName: 'Type',
    onForm: false,
    width: 140,
    editable: true,
    value: data?.type ?? null,
    initialValue: '',
    validate: Yup.string().nullable(),
  },
  {
    field: 'name',
    headerName: 'Name',
    onForm: false,
    width: 536,
    editable: true,
    value: data?.name ?? null,
    initialValue: '',
    validate: Yup.string().nullable(),
    getHref: (row) => `${row?.link}?noDrawer=true`,
    openBrowserPopup: true,
  },
];

export const workflowMeta = ({
  data, manualWorkflow,
  nameGridStyle,
  descriptionGridStyle,
  metaModelGridStyle,
  dryRunGridStyle,
  setDryValuesInAllShapesGridStyle,
  dryRunDurationGridStyle,
  dryRunDurationUnitGridStyle
} = {}) => [
    {
      field: 'name',
      headerName: 'Name',
      onForm: true,
      width: 340,
      type: fieldType.LINK,
      to: '/workflows/',
      editable: true,
      value: data ? data.name : null,
      initialValue: '',
      validate: Yup.string().required(),
      gridStyle: nameGridStyle ?? undefined,
    },
    {
      field: 'description',
      headerName: 'Description',
      onForm: true,
      editable: true,
      value: data ? data.description : null,
      initialValue: '',
      width: 600,
      type: fieldType.MULTILINE,
      validate: Yup.string(),
      gridStyle: descriptionGridStyle ?? undefined,
    },
    {
      field: 'meta_model',
      headerName: 'Model',
      width: 200,
      editable: true,
      onForm: manualWorkflow,
      hideColumn: manualWorkflow ? false : true,
      type: fieldType.AUTOCOMPLETE_V2,
      fetchUrl: metaModelsUrls.list,
      requestKey: `${data?.id}-bpa-asset-models`,
      validate: Yup.mixed().required(),
      initialValue: data
        ? {
          id: data?.meta_model,
          label: data?.details?.meta_model?.name ?? '',
        }
        : null,
      renderRow: (row) => ({
        id: row?.id,
        label: row?.name,
      }),
      gridStyle: metaModelGridStyle ?? undefined,
    },
    // {
    //   field: 'template',
    //   headerName: 'Template',
    //   type: fieldType.BOOLEAN,
    //   width: 140,
    //   hideColumn: manualWorkflow,
    //   onDetailCard: !manualWorkflow,
    //   editable: true,
    //   value: data ? data.template : null,
    //   initialValue: false,
    //   validate: Yup.boolean().default(false),
    // },
    {
      field: 'dry_run',
      headerName: 'Dry run?',
      type: fieldType.BOOLEAN,
      width: 140,
      editable: true,
      value: data ? data.dry_run : null,
      initialValue: false,
      validate: Yup.boolean().nullable(),
      gridStyle: dryRunGridStyle ?? undefined,
    },
    {
      field: 'set_dry_values_in_all_shapes',
      headerName: 'Set dry run values for all shapes?',
      type: fieldType.BOOLEAN,
      width: 140,
      editable: true,
      value: data ? data.set_dry_values_in_all_shapes : null,
      initialValue: false,
      validate: Yup.boolean().nullable(),
      gridStyle: setDryValuesInAllShapesGridStyle ?? undefined,
    },
    {
      field: 'dry_run_duration_amount',
      headerName: 'Dry run duration',
      type: fieldType.NUMBER,
      onForm: false,
      width: 340,
      editable: true,
      value: data ? data.dry_run_duration_amount : null,
      initialValue: data ? data.dry_run_duration_amount : null,
      validate: Yup.number().positive().nullable(),
      gridStyle: dryRunDurationGridStyle ?? undefined,
    },
    {
      field: 'dry_run_duration_unit',
      headerName: 'Dry run duration unit',
      type: fieldType.SELECT,
      onForm: false,
      width: 340,
      editable: true,
      options: bpaDryRunDurationUnits,
      value: data ? data.dry_run_duration_unit : null,
      initialValue: data ? data.dry_run_duration_unit : null,
      validate: Yup.string().nullable(),
      gridStyle: dryRunDurationUnitGridStyle ?? undefined,
    },
  ];

export const stageMeta = ({
  data,
  nextOrder,
  workflowId,
  onlyParentStages,
} = {}) => [
    {
      field: 'order',
      headerName: 'Order',
      width: 65,
      onAccordionForm: true,
      editable: true,
      value: data && `${data.stage_full_order}${data.order}`,
      initialValue: onlyParentStages
        ? nextOrder === 1
          ? 1
          : nextOrder
        : nextOrder + 1,
      validate: Yup.number().required().min(0),
      type: fieldType.ORDER,
    },
    {
      field: 'name',
      headerName: 'Name',
      onAccordionForm: true,
      width: 340,
      editable: true,
      value: data ? data.name : null,
      initialValue: '',
      to: `/stages/`,
      linkInNewTab: true,
      validate: Yup.string().required(),
    },
    {
      field: 'description',
      headerName: 'Description',
      onAccordionForm: true,
      editable: true,
      value: data ? data.description : null,
      initialValue: '',
      width: 450,
      type: fieldType.MULTILINE,
      validate: Yup.string(),
    },
    {
      field: 'parent_stage',
      headerName: 'Parent Stage',
      width: 200,
      editable: true,
      onAccordionForm: true,
      type: fieldType.AUTOCOMPLETE_V2,
      fetchUrl: stagesUrls.list,
      requestKey: `${data?.id}-parent-stage`,
      validate: Yup.mixed(),
      initialValue:
        data && data?.details?.parent_stage
          ? {
            id: data?.parent_stage,
            label: `${data?.stage_full_order ?? ''} ${data?.details?.parent_stage?.name
              }`,
          }
          : null,
      renderRow: (row) => ({
        id: row?.id,
        label: `${row?.stage_full_order}${row?.order} ${row?.name ?? ''}`,
      }),
      hideColumn: onlyParentStages,
      urlParams: `${workflowId
        ? `&workflow=${workflowId}`
        : data?.workflow
          ? `&workflow=${data.workflow}`
          : ''
        }&ordering=order`,
    },
    {
      field: 'rotting_days',
      headerName: 'Rotting days',
      width: 150,
      editable: true,
      value: data ? data.rotting_days : null,
      initialValue: 0,
      validate: Yup.number(),
      onAccordionForm: true,
    },
    {
      field: 'probability_percent',
      headerName: 'Probability',
      onAccordionForm: true,
      editable: true,
      value: data ? data.probability_percent : null,
      initialValue: 1,
      width: 120,
      validate: Yup.number()
        .integer('must be a number')
        .positive('must be positive')
        .max(100, 'should not be greater than 100'),
      gridStyle: 2,
    },
  ];

export const modelAssetStageMeta = ({ data, workflow } = {}) => [
  {
    field: 'workflow',
    headerName: 'Workflow',
    width: 340,
    editable: false,
    value: data?.workflow_details?.name ?? null,
    initialValue: {
      id: workflow?.id,
      label: workflow?.name ?? null,
    },
    onAccordionForm: true,
    getHref: (r) => `/workflows/${r.workflowId}`,
    to: '/workflows/',
    validate: Yup.mixed().required(),
  },
  {
    field: 'stage',
    headerName: 'Stage',
    width: 340,
    editable: false,
    value: data?.details?.stage?.name ?? null,
    initialValue: null,
    onAccordionForm: true,
    getHref: (r) => `/stages/${r.stageId}`,
    validate: Yup.mixed().required(),
  },
];

export const manualWorkflowInstanceMeta = ({
  data,
  workflow,
  metaModel,
} = {}) => [
    {
      field: 'entity_id',
      headerName: metaModel?.name,
      width: 340,
      editable: false,
      type: fieldType.AUTOCOMPLETE_V2,
      fetchUrl: () => metaModel?.route,
      requestKey: `${data?.id}-bpa-${metaModel?.name}`,
      initialValue: data
        ? {
          id: data?.entity_id,
          label: data?.details?.entity_id[metaModel?.label] ?? '',
        }
        : null,
      onAccordionForm: true,
      // getHref: (r) => `/workflows/${r.workflowId}`,
      validate: Yup.mixed().required(`${metaModel?.name} is required!`),
    },
    {
      field: 'stage',
      headerName: 'Stage',
      width: 340,
      editable: true,
      type: fieldType.AUTOCOMPLETE_V2,
      fetchUrl: stagesUrls.list,
      urlParams: `&workflow=${workflow.id}`,
      requestKey: `${data?.id}-bpa-${metaModel?.name}`,
      value: data?.details?.stage?.name ?? null,
      initialValue: null,
      onAccordionForm: true,
      getHref: (r) => `/stages/${r?.stage?.id}`,
      validate: Yup.mixed().required(),
    },
  ];

export const assetMeta = ({ data, workflow, stageDetail } = {}) => [
  {
    field: 'assetModel',
    headerName: 'Type',
    width: 180,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: assetModelsUrls.list,
    requestKey: `${data?.id}-bpa-asset-models`,
    validate: Yup.mixed().required('Type is a required field.'),
    initialValue: data
      ? {
        id: data?.asset_model,
        label: data?.details?.asset_model?.name ?? null,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'asset',
    headerName: 'Item',
    onAccordionForm: true,
    getHref: (r) => `${r?.assetModel?.link}${r.asset_id}`,
    width: 690,
    editable: false,
    value:
      data?.details?.asset[data?.details?.asset_model?.microservice_label] ??
      null,
    initialValue: '',
    validate: Yup.mixed(),
  },
  {
    field: 'stages',
    headerName: 'Stages',
    onAccordionForm: stageDetail ? false : true,
    type: fieldType.AUTOCOMPLETE,
    width: 180,
    hideColumn: true,
    onDetailCard: false,
    initialValue: [],
    validate: Yup.array().min(1, 'Stages is required!'),
  },
];
