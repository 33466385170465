import * as Yup from 'yup';
import {
  fieldType,
  intentChoicesOptions,
  typeChoicesOptions,
  staggerUnitsChoices,
  CRITERIA_CHOICES,
  CRITERIA_OPERATOR_CHOICES,
  CRITERIA_CONNECTOR_CHOICES,
} from '@config/constants';
import {
  formatToUTCDate,
  formatToUTCDateTime,
} from '@config/functions/helperFunctions';
import { marketingUrls } from '@config/routes';

const { landingUrls, keywordUrls, audiencesUrls } = marketingUrls;

export const marketSegmentsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 300,
    type: fieldType.LINK,
    to: '/market-segments/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const criteriaMeta = ({ data, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 90,
    editable: true,
    value: data ? data.order : null,
    initialValue: nextOrder ? nextOrder + 1 : 1,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'connector',
    headerName: 'Connector',
    type: fieldType.SELECT,
    editable: true,
    width: 140,
    onAccordionForm: true,
    options: CRITERIA_CONNECTOR_CHOICES,
    value: data?.connector ?? null,
    validate: Yup.string(),
  },
  {
    field: 'criteria',
    headerName: 'Criteria',
    type: fieldType.SELECT,
    editable: true,
    width: 140,
    onAccordionForm: true,
    options: CRITERIA_CHOICES,
    initialValue: CRITERIA_CHOICES[0].value,
    value: data?.criteria ?? null,
    validate: Yup.string(),
  },
  {
    field: 'operator',
    headerName: 'Operator',
    type: fieldType.SELECT,
    editable: true,
    width: 140,
    onAccordionForm: true,
    options: CRITERIA_OPERATOR_CHOICES,
    initialValue: CRITERIA_OPERATOR_CHOICES[0].value,
    value: data?.operator ?? null,
    validate: Yup.string(),
  },
  {
    field: 'value',
    headerName: 'Value',
    onAccordionForm: true,
    editable: true,
    value: data?.value ?? null,
    initialValue: '',
    width: 300,
    type: fieldType.TEXT,
    validate: Yup.string().max(250).required(),
  },
  
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const postsMeta = ({ data } = {}) => [
  {
    field: 'post',
    headerName: 'Post',
    onForm: true,
    width: 250,
    editable: false,
    value: data ? data.post : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'timing',
    headerName: 'Timing',
    type: fieldType.DATE_TIME,
    editable: false,
    onForm: true,
    width: 170,
    value: data ? formatToUTCDateTime(new Date(data.timing)) : null,
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const campaignsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.LINK,
    to: '/social-media-campaigns/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'stagger_between_smaccounts',
    headerName: 'Stagger Amount',
    width: 250,
    editable: true,
    value: data ? data.stagger_between_smaccounts : null,
    initialValue: 0,
    validate: Yup.string(),
    onForm: true,
  },
  {
    field: 'stagger_unit',
    headerName: 'Stagger Unit',
    type: fieldType.SELECT,
    editable: true,
    options: staggerUnitsChoices,
    initialValue: staggerUnitsChoices[0].label,
    value: data ? data.stagger_unit : null,
    validate: Yup.string(),
    onForm: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const landingMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.LINK,
    to: '/landing-page/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'slug',
    headerName: 'Slug',
    onForm: true,
    editable: true,
    onAccordionForm: true,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
  },
  {
    field: 'version',
    headerName: 'Version',
    onForm: true,
    editable: true,
    onAccordionForm: true,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
  },
  {
    field: 'seo_title',
    headerName: 'SEO Title',
    onForm: true,
    editable: true,
    onAccordionForm: true,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
  },
  {
    field: 'template',
    headerName: 'Template',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: landingUrls.list,
    requestKey: `${data?.id}-landing-page-template`,
    validate: Yup.mixed(),
    initialValue: data?.template
      ? {
          id: data?.template,
          label: data?.details?.template?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: `&template=true`,
  },
];

export const generateMeta = ({ data } = {}) => [
  {
    field: 'keyword',
    headerName: 'Keyword',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 140,
    validate: Yup.string().required(),
  },
  {
    field: 'slug',
    headerName: 'Slug',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 200,
    validate: Yup.string().required(),
  },
  {
    field: 'title',
    headerName: 'Title',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 140,
    validate: Yup.string(),
  },
  {
    field: 'city_criteria',
    headerName: 'City Criteria',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 80,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    type: fieldType.MULTILINE,
    width: 490,
    editable: true,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500),
  },
];

export const keywordMeta = (data) => [
  {
    field: 'keyword',
    headerName: 'Keyword',
    onForm: true,
    width: 250,
    type: fieldType.LINK,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'intent',
    headerName: 'Intent',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onForm: true,
    options: intentChoicesOptions,
    initialValue: '',
    value: data ? data.intent : null,
    validate: Yup.string().required(),
  },
  {
    field: 'type',
    headerName: 'Type',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onForm: true,
    options: typeChoicesOptions,
    initialValue: '',
    value: data ? data.type : null,
    validate: Yup.string().required(),
  },
];
export const lpKeywordMeta = ({ data } = {}) => [
  {
    field: 'keyword',
    headerName: 'Keyword',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: keywordUrls.list,
    requestKey: `${data?.id}-landing-page-keyword`,
    validate: Yup.mixed(),
    initialValue: data?.keyword
      ? {
          id: data?.keyword,
          label: data?.details?.keyword?.keyword,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.keyword,
    }),
  },
];

export const lpAudienceMeta = ({ data } = {}) => [
  {
    field: 'audience',
    headerName: 'Audience',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: audiencesUrls.list,
    requestKey: `${data?.id}-landing-page-audience`,
    validate: Yup.mixed(),
    initialValue: data?.audience
      ? {
          id: data?.audience,
          label: data?.details?.audience?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
];
export const AudienceMeta = (data) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    to: '/audience/',
    width: 250,
    type: fieldType.LINK,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    type: fieldType.MULTILINE,
    width: 490,
    editable: true,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500),
  },
];

export const statusMeta = ({ data }) => [
  {
    field: 'template',
    headerName: 'Template',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: landingUrls.list,
    requestKey: `${data?.id}-landing-page-template`,
    validate: Yup.mixed(),
    initialValue: data?.template
      ? {
          id: data?.template,
          label: data?.details?.template?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: `&template=true`,
  },
  {
    field: 'failed',
    headerName: 'Failed',
    onForm: true,
    editable: true,
    initialValue: '0',
    width: 200,
    value: data ? data.failed : null,
    validate: Yup.string(),
  },
  {
    field: 'total_records',
    headerName: 'Total Records',
    onForm: true,
    editable: true,
    initialValue: '0',
    width: 200,
    validate: Yup.string(),
    value: data ? data.total_records : null,
  },
  {
    field: 'successfully_generated',
    headerName: 'Successfully Generated',
    onForm: true,
    editable: true,
    initialValue: '0',
    width: 200,
    validate: Yup.string(),
    value: data ? data.successfully_generated : null,
  },
  {
    field: 'status',
    headerName: 'Status ',
    onForm: true,
    editable: true,
    initialValue: 'In Progress',
    width: 200,
    validate: Yup.string(),
    value: data ? data.status : null,
  },
];
export const socialMediaMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    editable: false,
    value: data && data.name,
    initialValue: '',
    onForm: true,
    type: fieldType.LINK,
    to: '/social-media/',
    validate: Yup.string().required(),
  },
  {
    field: 'max_post_length',
    headerName: 'Max Post Length',
    width: 180,
    editable: true,
    initialValue: '',
    onForm: true,
    value: data && data.max_post_length,
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    type: fieldType.MULTILINE,
    width: 240,
    editable: true,
    initialValue: '',
    onForm: true,
    value: data && data.description,
    validate: Yup.string().max(2500),
  },
];

export const clientSMAccessMeta = ({ data } = {}) => [
  {
    field: 'client',
    headerName: 'Client',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: landingUrls.list,
    requestKey: `${data?.id}-client-sm`,
    validate: Yup.mixed(),
    initialValue: data?.client
      ? {
          id: data?.client,
          label: data?.details?.client?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'api',
    headerName: 'Api ',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
    value: data ? data.api : null,
  },
  {
    field: 'secret',
    headerName: 'Secret',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
    value: data ? data.secret : null,
  },
];

export const userSMAccessMeta = ({ data } = {}) => [
  {
    field: 'user',
    headerName: 'User',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: landingUrls.list,
    requestKey: `${data?.id}-user-sm`,
    validate: Yup.mixed(),
    initialValue: data?.user
      ? {
          id: data?.user,
          label: data?.details?.user?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'access_token',
    headerName: 'Access Token',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 200,
    validate: Yup.string().required(),
    value: data ? data?.access_token : null,
  },
  {
    field: 'token_expiry',
    headerName: 'Token Expiry',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 200,
    hideColumn: true,
    validate: Yup.number().min(0),
    value: data ? data?.token_expiry : null,
  },
  {
    field: 'page_id',
    headerName: 'Page',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 200,
    hideColumn: true,
    validate: Yup.string(),
    value: data ? data.page_id : null,
  },
  {
    field: 'page_name',
    headerName: 'Page Name',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 200,
    hideColumn: false,
    validate: Yup.string(),
    value: data ? data.page_name : null,
  },
  {
    field: 'refresh_token',
    headerName: 'Refresh Token',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 200,
    hideColumn: true,
    validate: Yup.string(),
    value: data ? data.refresh_token : null,
  },
  {
    field: 'private',
    headerName: 'Private',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: false,
    value: data ? data.private : null,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
];

export const smPostMeta = (data) => [
  {
    field: 'post',
    headerName: 'Post',
    onForm: true,
    editable: false,
    initialValue: '',
    width: 200,
    type: fieldType.LINK,
    to: '/social-media-post/',
    validate: Yup.string(),
    value: data ? data.post : null,
  },
];

export const postViaMeta = ({ data } = {}) => [
  {
    field: 'user_token',
    headerName: 'User Token',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: landingUrls.list,
    requestKey: `${data?.id}-post-via`,
    validate: Yup.mixed(),
    initialValue: data?.user_token
      ? {
          id: data?.user_token,
          label: data?.details?.user_token?.access_token,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getHref: (r) => `/post-via/${r.id}/`,
  },
];

export const postViaScheduleMeta = (data) => [
  {
    field: 'timing',
    headerName: 'Timing',
    width: 180,
    onAccordionForm: true,
    value: data && data.timing ? new Date(data.timing) : null,
    editable: false,
    initialValue: formatToUTCDate(new Date()),
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
  },
];

export const managePostDataMeta = (edit, data) => [
  {
    field: 'post',
    headerName: 'Post',
    onForm: true,
    editable: edit ? edit : false,
    initialValue: '',
    width: 400,
    type: fieldType.TEXT,
    // getHref: (r) => `/social-media-post/${r.id}`,
    validate: Yup.string(),
    value:
      data && data.post_details && data.post_details.post
        ? data.post_details.post
        : null,
  },
  {
    field: 'socialMedia',
    headerName: 'Social Media',
    onForm: true,
    editable: false,
    initialValue: '',
    width: 180,
    validate: Yup.string(),
    value:
      data &&
      data.user_details &&
      data.user_details.details &&
      data.user_details.details.social_media
        ? data.user_details.details.social_media.name
        : null,
    hideColumn: true,
  },
  {
    field: 'timing',
    headerName: 'Timing',
    width: 200,
    onAccordionForm: true,
    editable: false,
    initialValue: formatToUTCDate(new Date()),
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
    value: data ? formatToUTCDateTime(data.timing) : null,
  },
  {
    field: 'user',
    headerName: 'User',
    onForm: false,
    editable: false,
    initialValue: '',
    width: 250,
    validate: Yup.string(),
    value:
      data &&
      data.user_details &&
      data.user_details.details &&
      data.user_details.details.user &&
      data.user_details.details.user.user
        ? data.user_details.details.user.user.email
        : null,
    hideColumn: true,
  },
];
