import React from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { formsUrls } from '@config/routes';
import { questionOptionMeta } from '@config/meta/forms/formsMeta';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { updateRecord } from '@config/functions/requests';
import Accordion from '@ui/Accordion';
// import useAutocomplete from '@hooks/useAutocomplete';
import { useQueryClient } from 'react-query';

const { questionOptionsUrls } = formsUrls;

export default function QuestionDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const [notify] = useToast();
  const [expanded, setExpanded] = React.useState(false);
  const [likertScores, setLikertScores] = React.useState({
    score1: '',
    score2: '',
    score3: '',
    score4: '',
    score5: '',
  });
  const [likertsInit, setLikertsInit] = React.useState(false);
  const [likertsSubmitting, setLikertsSubmitting] = React.useState(false);

  // Use the useCallback hook to optimize the getSchema function

  const getSchema = React.useCallback(
    (data) => questionOptionMeta({ data, queryClient }),
    []
  );

  const handleLikertSave = async () => {
    try {
      setLikertsSubmitting(true);

      const likertScoresModified = Object.values(likertScores)
        .filter((v) => !!v)
        .map((v) => +v);

      if (
        !Array.isArray(likertScoresModified) ||
        likertScoresModified.length > 5
      ) {
        notify('There was an error, please refresh the page', {
          type: 'ERROR',
        });
        return;
      }

      await updateRecord({
        values: {
          likertScores: likertScoresModified,
        },
        url: questionOptionsUrls.detail(recordId),
        token: user.token,
        actAs: user?.actAs,
      });

      notify('Operation completed', {
        type: 'SUCCESS',
      });
    } catch (err) {
      notify('There was an error, please try again', {
        type: 'ERROR',
      });
    } finally {
      setLikertsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (!recordData || likertsInit) return;

    if (!recordData?.likertScores?.length) {
      setLikertsInit(true);
      return;
    }

    const [
      scoreFirst,
      scoreSecond,
      scoreThird,
      scoreFourth,
      scoreFifth,
      // prettier xx
    ] = recordData.likertScores;

    setLikertScores({
      score1: scoreFirst,
      score2: scoreSecond,
      score3: scoreThird,
      score4: scoreFourth,
      score5: scoreFifth,
    });

    setLikertsInit(true);
  }, [recordData]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/questions/${recordData.questionId}`,
                text: 'Questions Options',
              },
              { text: recordData && recordData.option },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={questionOptionsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      {recordData?.question?.type === 'Likert' ? (
        <Box sx={{ mt: 5 }}>
          <Accordion
            editMode={editMode}
            labels={labels}
            prefix='0am'
            label='Likert Scores'
            expanded={expanded}
            onChange={() => setExpanded((prevState) => !prevState)}
          >
            <Box sx={{ py: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={2.4}>
                  <TextField
                    fullWidth
                    required
                    size='small'
                    label='Likert score 1'
                    value={likertScores.score1}
                    onChange={(e) =>
                      setLikertScores((state) => ({
                        ...state,
                        score1: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    fullWidth
                    required
                    size='small'
                    label='Likert score 2'
                    value={likertScores.score2}
                    onChange={(e) =>
                      setLikertScores((state) => ({
                        ...state,
                        score2: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    fullWidth
                    required
                    size='small'
                    label='Likert score 3'
                    value={likertScores.score3}
                    onChange={(e) =>
                      setLikertScores((state) => ({
                        ...state,
                        score3: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    fullWidth
                    required
                    size='small'
                    label='Likert score 4'
                    value={likertScores.score4}
                    onChange={(e) =>
                      setLikertScores((state) => ({
                        ...state,
                        score4: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    fullWidth
                    required
                    size='small'
                    label='Likert score 5'
                    value={likertScores.score5}
                    onChange={(e) =>
                      setLikertScores((state) => ({
                        ...state,
                        score5: e.target.value,
                      }))
                    }
                  />
                </Grid>

                <Grid item xs={2.4}>
                  <Button
                    variant='outlined'
                    onClick={handleLikertSave}
                    disabled={likertsSubmitting}
                  >
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Accordion>
        </Box>
      ) : null}
    </Box>
  );
}
