import { bpaUrls } from "@config/routes";
import { createRecord } from "@config/functions/requests";

const {
  createShortLinksActionUrls,
} = bpaUrls;

export const handleClose = ({ setOpen, shape, queryClient } = {}) => {
  setOpen(false);
  queryClient.invalidateQueries([`${shape?.id}-shape-and-actions`]);
};

/**
 * Checks if any key in an object includes a specified substring.
 * @param {Object} obj - The object to search through.
 * @param {string} substring - The substring to look for in the keys.
 * @returns {boolean} - Returns true if any key includes the substring, false otherwise.
 */
export const hasKeyIncludingSubstring = (obj, substring) => {
  return Object.keys(obj).some((key) => key.includes(substring));
};

export const handleAccordionItemDelete = async ({
  setDeleting,
  notify,
  remove,
  index,
} = {}) => {
  try {
    setDeleting(true);
    remove(index);
  } catch (error) {
    console.log(error);
    notify("Failed to delete the item.", {
      type: "ERROR",
    });
  } finally {
    setDeleting(false);
  }
};

export const getShortLinkFields = (i) => ({
  name: `short_links-${i}-name`,
  destination_url: `short_links-${i}-destination_url`,
});


export const buildLabelForInitialValueFields = (
  fullValue,
  value,
  field,
  label_type = "static_value"
) => ({
  ...value,
  label: value?.mapping_field_name
    ? `${fullValue?.details?.[label_type]?.[field]?.details?.payload_section?.name} / ${fullValue?.details?.[label_type]?.[field]?.label}`
    : value?.label,
});

export const initialShortLinkMappingDefinition = (initialShortLinks) =>
  initialShortLinks
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getShortLinkFields(i);

      return {
        payload_section_key_reference: valueInObject?.payload_section_key_reference,
        [fields.name]: buildLabelForInitialValueFields(value, valueInObject?.name, "name"),
        [fields.destination_url]: buildLabelForInitialValueFields(value, valueInObject?.destination_url, "destination_url"),
      };
    });

export const getInitialValues = ({
  initialShortLinks,
} = {}) => {
  return {
    shortLinkPayloadSection: null,
    shortLinkPayloadSectionKey: null,
    short_links: initialShortLinkMappingDefinition(initialShortLinks?.values),
  };
};

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

const checkShortLinkErrors = (short_link, index, setFieldError) => {
  let hasErrors = false;
  const fields = getShortLinkFields(index);

  console.log(short_link)

  const addError = (fieldName, message) => {
    setFieldError(fieldName, message);
    hasErrors = true;
  };

  if (!short_link?.[fields?.name]) {
    addError(fields?.name, "Please select a name.");
  }

  if (!short_link?.[fields?.destination_url]) {
    addError(fields?.destination_url, "Please select a destination link.");
  }

  if (short_link?.[fields?.destination_url]?.static_value && !validURL(short_link?.[fields?.destination_url]?.static_value)) {
    addError(fields?.destination_url, "Please select a valid destination link.");
  }

  return hasErrors;
};

const addShortLinksToPayload = ({
  values,
  payload,
  setFieldError,
} = {}) => {
  if (
    values?.short_links
      .map((short_link, index) => checkShortLinkErrors(short_link, index, setFieldError))
      .filter((e) => e).length
  )
    return true;

  values?.short_links.map((short_link, index) => {
    const fields = getShortLinkFields(index);
    payload.short_links.push({
      payload_section_key_reference: short_link?.payload_section_key_reference,
      name: short_link?.[fields?.name],
      destination_url: short_link?.[fields?.destination_url],
    });
  });
};

/**
 * Main function to handle the popup submit action.
 * Includes processing of different fields and payload creation.
 * @param {Object} params - The parameters including values, state setters, and other dependencies.
 */
export const handlePopupSubmit = async ({
  values,
  setSubmitting,
  setFieldError,
  shapeAction,
  user,
  initialShortLinks,
  notify,
  slotLinkGroupRef,
} = {}) => {
  try {
    setSubmitting(true);

    const payload = {
      short_links: [],
    };

    // Create a set for tracking names
    const hasShortLinkErrors = addShortLinksToPayload({
      values,
      initialShortLinks,
      shapeAction,
      payload,
      setFieldError,
    });

    const hasAnyErrors = hasShortLinkErrors;

    if (hasAnyErrors) {
      slotLinkGroupRef.current?.scrollIntoView({
        behavior: "smooth",
      });
      return; // Exit the function early if there are any errors
    }

    console.log(payload);


    await createRecord({
      values: payload,
      url: createShortLinksActionUrls.detail(shapeAction?.id),
      token: user?.token,
      actAs: user?.actAs,
    });

    notify("Operation completed", {
      type: "SUCCESS",
    });
  } catch (error) {
    console.log(error?.response?.data ?? error?.message);
    notify("There was an error, please refresh the page", {
      type: "ERROR",
    });
  } finally {
    setSubmitting(false);
  }
};
