import React from 'react';
import * as Yup from 'yup';
import { crmUrls } from '@config/routes';
import { companySpinsMeta } from '@config/meta/crm/index';
import { Grid, MenuItem } from '@mui/material';
import { TextField, AutocompleteFieldV2, SelectField } from '@components/Inputs';
import { companySpinsBuyerInfluence } from '@config/constants';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import CompanyDropdown from '@dropdown-forms/crm/CompanyDropdown';
import { useQueryClient } from 'react-query';

const { companySpinsUrls, companiesUrls } = crmUrls;

export default function BuyerInfluenceDropdown({
  children,
  refetch,
  buyerInfluence,
  company: initialCompany,
}) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title='Company Spins'
        initialValues={{
          ...getAddFormInitialValues(companySpinsMeta(), 'listView'),
          buyer_influence: buyerInfluence || '',
          _company: initialCompany,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(companySpinsMeta(), 'listView'),
        })}
        getPostUrl={() => companySpinsUrls.list()}
        getValues={({ _company, ...values}) => ({
          buyer_influence: buyerInfluence || '',
          _company: _company?.id,
          ...values,
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <CompanyDropdown
                refetch={({ id, name }) => {
                  setFieldValue('_company', {
                    id: id,
                    label: `${name || ''}`,
                  });
                  queryClient.invalidateQueries([
                    'company-spins-dropdown-companies',
                  ]);
                }}
              >
                <AutocompleteFieldV2
                  name='_company'
                  label='Company'
                  requestKey='company-spins-dropdown-companies'
                  fetchUrl={companiesUrls.list}
                  required
                />
              </CompanyDropdown>
            </Grid>
            {!buyerInfluence ? (
              <Grid item xs={6}>
                <SelectField
                  label='Buyer Influence'
                  name='buyer_influence'
                  required
                >
                  {companySpinsBuyerInfluence.map((r) => (
                    <MenuItem value={r.value} key={r.value}>
                      {r.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
            ) : null}

            <Grid item xs={6}>
              <TextField
                label='Situation'
                name='situation'
                multiline
                minRows={3}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Problem'
                name='problem'
                multiline
                minRows={3}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Implication'
                name='implication'
                multiline
                minRows={3}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Need'
                name='need'
                multiline
                minRows={3}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField label='Notes' name='notes' multiline />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
