import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Loader from '@components/Loader';
import { Formik, Form } from 'formik';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { createRecord, updateRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

import useToast from '@hooks/useToast';

export default function FormDialog({
  title,
  maxWidth,
  initialValues,
  validationSchema,
  getPostUrl,
  getValues,
  refetch,
  open,
  setOpen,
  getFields,
  hidden,
  label = null,
  handleSubmit = null,
  submitType = 'Add',
  showActionButtons = true,
  handleClose = () => setOpen(false),
  handleSave = null,
  isFetching = false,
  isError = false,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldError }
  ) => {
    try {
      const newValues = {
        ...values,
        ...getValues(values),
      };

      const commonPayload = {
        values: newValues,
        url: getPostUrl(),
        token: user.token,
        actAs: user?.actAs,
        encryptionToken: user.encryptionToken,
      };

      const performAction = async (action) => {
        const { data } = await action(commonPayload);
        if (refetch) refetch(data);
      };

      if (submitType === 'Add') {
        await performAction(createRecord);
        resetForm();
      } else if (submitType === 'Edit') {
        await performAction(updateRecord);
      }

      setOpen(false);
    } catch (err) {
      console.log(err.response || err.message);
      handleCreateFormErrors({
        err,
        notify,
        values: {
          ...values,
          ...getValues(values),
        },
        setError: (error) => {
          Object.entries(error).map((err) => setFieldError(err[0], err[1]));
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth || 'sm'}
      open={open}
      onClose={handleClose}
      style={{
        opacity: hidden ? '0' : '100',
        pointerEvents: hidden ? 'none' : '',
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <DialogTitle>{title ? `${submitType} ${title}` : label}</DialogTitle>
      <DialogContent>
        {isFetching ? (
          <Loader />
        ) : isError ? (
          `There was an error, please try again.`
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setFieldValue, setFieldError, errors }
            ) => {
              const setError = (error) => {
                Object.entries(error).map((err) =>
                  setFieldError(err[0], err[1])
                );
              };

              if (handleSubmit) {
                await handleSubmit(
                  values,
                  setSubmitting,
                  resetForm,
                  setOpen,
                  refetch,
                  setError,
                  setFieldError,
                  setFieldValue,
                  errors
                );
              } else {
                await onSubmit(values, {
                  setSubmitting,
                  resetForm,
                  setFieldError,
                });
              }
            }}
          >
            {({
              isSubmitting,
              values,
              errors,
              submitForm,
              setFieldValue,
              validateForm,
            }) => (
              <Form noValidate autoComplete="off" style={{ paddingTop: '8px' }}>
                {/* {console.log(values, errors)} */}
                {getFields({
                  isSubmitting,
                  values,
                  errors,
                  setFieldValue,
                  submitForm,
                  validateForm,
                })}
                {showActionButtons ? (
                  <DialogActions sx={{ pt: 3 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      onClick={() => {
                        if (errors && Object.keys(errors).length) return;
                        if (handleSave) return handleSave(submitForm, values);
                        return submitForm();
                      }}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </DialogActions>
                ) : null}
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
}
