import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { pmUrls } from '@config/routes';
import * as Yup from 'yup';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { AutocompleteFieldV2 } from '@components/Inputs';
import { useQueryClient } from 'react-query';
import { formatDate } from '@config/functions/helperFunctions';

const { sprintMetaUrls, sprintsUrls, bulkConnectTasksToSprintUrls } = pmUrls;

export default function PersonMarketingListPopup({
  open,
  setOpen,
  selectedRows,
  selectAll,
  setSelectedRows,
  searchTerm,
  rowsDetail,
  filterParams,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const [first] = rowsDetail?.results ?? [];

  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>
          Add tasks to sprint
        </DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Formik
          initialValues={{
            sprintMeta: '',
            sprint: '',
          }}
          validationSchema={Yup.object({
            sprintMeta: Yup.mixed(),
            sprint: Yup.mixed(),
          })}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setFieldError }
          ) => {
            try {
              setSubmitting(true);

              if (!values?.sprint?.id) {
                setFieldError('sprint', 'Please select a sprint');
                return;
              }

              await createRecord({
                values: {
                  all_: selectAll,
                  ids: selectAll ? [] : selectedRows,
                  project: first?.project,
                  sprint: values?.sprint?.id,
                  search_query: searchTerm,
                  filters: filterParams,
                },
                url: bulkConnectTasksToSprintUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setOpen(false);
              setSelectedRows([]);
              queryClient.invalidateQueries();
              notify('Operation completed', {
                type: 'SUCCESS',
              });
            } catch (error) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form noValidate autoComplete="off" style={{ paddingTop: '6px' }}>
              <Grid sx={{ paddingBottom: '22px' }} container spacing={3}>
                <Grid item sm={12}>
                  <AutocompleteFieldV2
                    name="sprintMeta"
                    label="Sprint Meta"
                    requestKey="tasks-sprint-meta-list"
                    fetchUrl={sprintMetaUrls.list}
                    urlParams={`&ordering=created_at&project=${
                      first?.project ?? ''
                    }`}
                    getData={(data) => {
                      if (data.length) {
                        console.log('data', data[0]);
                        setFieldValue('sprintMeta', data[0]);
                      }
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <AutocompleteFieldV2
                    name="sprint"
                    label="Sprint"
                    requestKey="tasks-sprint-list"
                    fetchUrl={sprintsUrls.list}
                    urlParams={`&ordering=start_date&sprint_meta=${
                      values?.sprintMeta?.id ?? ''
                    }`}
                    disabled={!values?.sprintMeta}
                    enabled={!!values?.sprintMeta?.id}
                    requestKeyOptions={[values?.sprintMeta?.id]}
                    renderRow={(r) => ({
                      id: r.id,
                      label: formatDate(new Date(r.start_date)),
                    })}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
