import * as React from 'react';
import TextField from '@mui/material/TextField';
import MuiAutocomplete from '@mui/material/Autocomplete';

export default function Autocomplete({
  label,
  options = [],
  isLoading,
  value,
  inputValue = '',
  getOptionLabel = (o) => o.label,
  error,
  helperText,
  required,
  disabled,
  ...rest
}) {
  return (
    <MuiAutocomplete
      {...rest}
      value={value}
      disabled={disabled}
      size='small'
      fullWidth
      disableClearable
      clearOnEscape
      loading={isLoading}
      inputValue={inputValue}
      options={options}
      includeInputInList
      sx={{ mb: 0 }}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(k, v) => k.id === v.id && k.label === v.label}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={helperText}
          fullWidth
          sx={{ mb: 0 }}
          label={label}
          required={required}
          variant={rest?.variant ?? 'outlined'}
          autoComplete='off'
        />
      )}
    />
  );
}
