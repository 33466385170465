import { Box } from '@mui/system';
import DetailAccordionView from '@components/DetailAccordionView';

export default function DetailAccordianArrayView({ value, labels, editMode }) {
  return (
    <Box>
      {Object.keys(value).map((key) => (
        <DetailAccordionView
          key={key.toString()}
          label={value[key].label}
          columnKey={value[key].columnKey}
          columnOptions={value[key].columnOptions}
          urls={value[key].urls}
          addNewForm={value[key].addNewForm}
          handleRowMap={value[key].handleRowMap}
          urlParams={value[key].urlParams}
          microservice={value[key].microservice}
          model={value[key].model}
          handleFunctions={value[key].handleFunctions}
          rowActions={value[key].rowActions}
          customSortColumnName={value[key].customSortColumnName}
          sortColumnBy={value[key].sortColumnBy}
          startFetching={value[key].startFetching}
          prefix={value[key].prefix}
          labels={labels}
          editMode={editMode}
        />
      ))}
    </Box>
  );
}
