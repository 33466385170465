import React from 'react';
import * as Yup from 'yup';
import { pmUrls } from '@config/routes';
import { taskStatusMeta } from '@config/meta/pm/pmMeta';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import ProjectDropdown from '@dropdown-forms/pm/ProjectDropdown';
import { useQueryClient } from 'react-query';

const { taskStatusUrls, projectsUrls } = pmUrls;

export default function StatusDropdown({
  children,
  refetch,
  project: initialProject,
}) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Status"
        initialValues={{
          ...getAddFormInitialValues(taskStatusMeta(), 'DetailAccordionView'),
          project: initialProject,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(taskStatusMeta(), 'DetailAccordionView'),
          project: Yup.mixed().required('This field is required')
        })}
        getPostUrl={() => taskStatusUrls.list()}
        getValues={({ values, project }) => ({
          ...values,
          project: project?.id,
        })}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField label="Name" name="name" required />
            </Grid>
            <Grid item xs={6}>
              <TextField type="number" key="order" label="Order" name="order" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                key="rotting_days"
                label="Rotting Days"
                name="rotting_days"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
            <Grid item xs={12}>
              <ProjectDropdown
                refetch={({ id, name }) => {
                  setFieldValue('project', {
                    id: id,
                    label: `${name || ''}`,
                  });
                  queryClient.invalidateQueries(['project']);
                }}
              >
                <AutocompleteFieldV2
                  name="project"
                  label="Projects"
                  requestKey="project"
                  fetchUrl={projectsUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                  required
                />
              </ProjectDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
