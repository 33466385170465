import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ecommerceUrls, crmUrls, inventoryUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordianArrayView from '@components/DetailAccordionArrayView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import ecommerceHandleRows from '@config/handleRows/ecommerce';
import {
  ItemDeliveryForm,
  CartForm,
  CartItemForm,
} from '@config/forms/ecommerce';
import {
  deliveryStatusMeta,
} from '@config/meta/ecommerce';
import DetailPageHeader from '@components/DetailPageHeader';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  deliveryMethodsUrls,
  cartsUrls,
  deliveryStatusesUrls,
  itemDeliveriesUrls,
  cartItemsUrls,
  addressesUrls,
  paymentStatusesUrls,
  discountCodesUrls,
} = ecommerceUrls;
const { personsUrls } = crmUrls;
const { productVariantsUrls } = inventoryUrls;
const { itemDeliveryRowMap, cartRowMap, cartItemRowMap } = ecommerceHandleRows;

export default function DeliveryStatusDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [addressTerm, setAddressTerm] = useState('');
  const [cartItemTerm, setCartItemTerm] = useState('');
  const [deliveryMethodTerm, setDeliveryMethodTerm] = useState('');
  const [deliveryStatusTerm, setDeliveryStatusTerm] = useState('');
  const [customerTerm, setCustomerTerm] = useState('');
  const [paymentStatusTerm, setPaymentStatusTerm] = useState('');
  const [discountCodeTerm, setDiscountCodeTerm] = useState('');
  const [productVariantTerm, setProductVariantTerm] = useState('');
  const [cartTerm, setCartTerm] = useState('');
  const [notify] = useToast();

  //for carts

  const { data: addresses, isFetching: fetchingAddresses } = useAutocomplete({
    key: 'addresses',
    getUrl: addressesUrls.list,
    inputValue: addressTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.address1,
      };
    },
  });

  const { data: cartItems, isFetching: fetchingCartItems } = useAutocomplete({
    key: 'cartItems',
    getUrl: cartItemsUrls.list,
    inputValue: cartItemTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.product_variant && r.details && r.details.product_variant
            ? r.details.product_variant.name
            : null,
      };
    },
  });

  const { data: deliveryMethods, isFetching: fetchingDeliveryMethods } =
    useAutocomplete({
      key: 'deliveryMethods',
      getUrl: deliveryMethodsUrls.list,
      inputValue: deliveryMethodTerm,
    });

  const {
    data: deliveryStatuses,
    isFetching: fetchingDeliveryStatuses,
    refetch: refetchDeliveryStatus,
  } = useAutocomplete({
    key: 'deliveryStatuses',
    getUrl: deliveryStatusesUrls.list,
    inputValue: deliveryStatusTerm,
  });

  const {
    data: customers,
    isFetching: fetchingCustomers,
    refetch: refetchCustomer,
  } = useAutocomplete({
    key: 'customers',
    getUrl: personsUrls.list,
    inputValue: customerTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.first_name,
      };
    },
  });

  const {
    data: paymentStatuses,
    isFetching: fetchingPaymentStatuses,
    refetch: refetchPayment,
  } = useAutocomplete({
    key: 'paymentStatuses',
    getUrl: paymentStatusesUrls.list,
    inputValue: paymentStatusTerm,
  });

  const {
    data: discountCodes,
    isFetching: fetchingDiscountCodes,
    refetch: refetchDiscountCode,
  } = useAutocomplete({
    key: 'discountCodes',
    getUrl: discountCodesUrls.list,
    inputValue: discountCodeTerm,
  });

  const { data: productVariants, isFetching: fetchingProductVariants } =
    useAutocomplete({
      key: 'productVariants',
      getUrl: productVariantsUrls.list,
      inputValue: productVariantTerm,
    });

  const { data: carts, isFetching: fetchingCarts } = useAutocomplete({
    key: 'carts',
    getUrl: cartsUrls.list,
    inputValue: cartTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.customer && r.details && r.customer_details
            ? r.customer_details.first_name
            : r.customer,
      };
    },
  });

  const cartsColOptions = React.useMemo(() => {
    return {
      customers,
      fetchingCustomers,
      setCustomerTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
      discountCodes,
      fetchingDiscountCodes,
      setDiscountCodeTerm,
    };
  }, [
    customers,
    fetchingCustomers,
    setCustomerTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
    paymentStatuses,
    fetchingPaymentStatuses,
    setPaymentStatusTerm,
    discountCodes,
    fetchingDiscountCodes,
    setDiscountCodeTerm,
  ]);

  const cartItemsColOptions = React.useMemo(() => {
    return {
      productVariants,
      fetchingProductVariants,
      setProductVariantTerm,
      carts,
      fetchingCarts,
      setCartTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
    };
  }, [
    productVariants,
    fetchingProductVariants,
    setProductVariantTerm,
    carts,
    fetchingCarts,
    setCartTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
  ]);

  const itemDeliveriesColOptions = React.useMemo(() => {
    return {
      cartItems,
      fetchingCartItems,
      setCartItemTerm,
      deliveryMethods,
      fetchingDeliveryMethods,
      setDeliveryMethodTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
      addresses,
      fetchingAddresses,
      setAddressTerm,
    };
  }, [
    cartItems,
    fetchingCartItems,
    setCartItemTerm,
    deliveryMethods,
    fetchingDeliveryMethods,
    setDeliveryMethodTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
    addresses,
    fetchingAddresses,
    setAddressTerm,
  ]);

  const accordData = {
    carts: {
      columnKey: "cartEcommerce",
      columnOptions: cartsColOptions,
      microservice: 'E-Commerce',
      model: 'Cart',

      label: 'Carts',
      prefix: '0uv',
      urls: cartsUrls,
      addNewForm: {
        getFields: () =>
          CartForm({
            customers,
            fetchingCustomers,
            setCustomerTerm,
            refetchCustomer,
            deliveryStatuses,
            fetchingDeliveryStatuses,
            setDeliveryStatusTerm,
            refetchDeliveryStatus,
            paymentStatuses,
            fetchingPaymentStatuses,
            setPaymentStatusTerm,
            refetchPayment,
            discountCodes,
            fetchingDiscountCodes,
            setDiscountCodeTerm,
            refetchDiscountCode,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                delivery_status: recordId,
              },
              url: cartsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Cart Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: cartRowMap,
      urlParams: `&delivery_status=${recordId}`,
    },
    cartItems: {
      columnKey: "cartItemEcommerce",
      columnOptions: cartItemsColOptions,
      microservice: 'E-Commerce',
      model: 'CartItem',

      label: 'Cart Items',
      prefix: '0c6',
      urls: cartItemsUrls,
      addNewForm: {
        getFields: () =>
          CartItemForm({
            productVariants,
            fetchingProductVariants,
            setProductVariantTerm,
            carts,
            fetchingCarts,
            setCartTerm,
            deliveryStatuses,
            fetchingDeliveryStatuses,
            setDeliveryStatusTerm,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                delivery_status: recordId,
              },
              url: cartItemsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Cart Item Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: cartItemRowMap,
      urlParams: `&delivery_status=${recordId}`,
    },
    itemDeliveries: {
      columnKey: "itemDeliveryEcommerce",
      columnOptions: itemDeliveriesColOptions,
      microservice: 'E-Commerce',
      model: 'itemDelivery',

      label: 'Item Deliveries',
      prefix: '0jh',
      urls: itemDeliveriesUrls,
      addNewForm: {
        getFields: () =>
          ItemDeliveryForm({
            cartItems,
            fetchingCartItems,
            setCartItemTerm,
            deliveryMethods,
            fetchingDeliveryMethods,
            setDeliveryMethodTerm,
            deliveryStatuses,
            fetchingDeliveryStatuses,
            setDeliveryStatusTerm,
            addresses,
            fetchingAddresses,
            setAddressTerm,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                delivery_status: recordId,
              },
              url: itemDeliveriesUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Item Delivery Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: itemDeliveryRowMap,
      urlParams: `&delivery_status=${recordId}`,
    },
  };

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key="detail-view-header"
            items={[
              { to: `/delivery-statuses`, text: 'Delivery Statuses' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={deliveryStatusesUrls}
        recordId={recordId}
        getSchema={(data) =>
          deliveryStatusMeta({
            data,
            deliveryMethods,
            fetchingDeliveryMethods,
            setDeliveryMethodTerm,
          })
        }
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }} key="detail-view-childs">
        <DetailAccordianArrayView
          value={accordData}
          labels={labels}
          editMode={editMode}
        />
      </Box>
    </Box>
  );
}
