import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { Box, Typography } from '@mui/material';

export default function EmailConfirmed() {
  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          mb: 2,
          textAlign: 'center',
        }}
      >
        <MarkEmailReadIcon sx={{ fontSize: 120 }} />
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="subtitle1">
          Your email has been confirmed.
        </Typography>
        <Typography variant="subtitle1">
          you will be able to login after a company administrator has approved
          you.
        </Typography>
      </Box>
    </Box>
  );
}
