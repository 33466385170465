import React from 'react';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import { accountUrls, bpaUrls, systemUrls } from '@config/routes';

const {
  modelsUrls: accountsModelsUrls,
  microservicesUrls: accountsMicroservicesUrls,
} = accountUrls;
const { workflowsUrls } = bpaUrls;
const { relationShipsUrls, clientsUrls, metaModelsUrls } = systemUrls;

export const automataConnectionWithAModelForm = ({ values }) => (
  <>
    <Grid item xs={3}>
      <AutocompleteFieldV2
        name="microservice"
        label="Microservice"
        requestKey="microservices"
        fetchUrl={accountsMicroservicesUrls.list}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.name,
        })}
      />
    </Grid>

    {values?.microservice ? (
      <>
        <Grid item xs={3}>
          <AutocompleteFieldV2
            name="model"
            label="Model"
            requestKey="models"
            fetchUrl={(query) =>
              accountsModelsUrls.list(
                `?workflowable_model=true&microservice=${
                  values?.microservice?.id
                }&${query ?? ''}`
              )
            }
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteFieldV2
            name="automata"
            label="Automata"
            requestKey="automatas"
            fetchUrl={workflowsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
          />
        </Grid>
      </>
    ) : null}
  </>
);

export const TemplatesForm = ({ values }) => (
  <>
    <Grid item xs={3}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={3}>
      <TextField label="Description" name="description" />
    </Grid>

    <Grid item xs={3}>
      <AutocompleteFieldV2
        name="model"
        label="Type"
        requestKey="meta-models"
        fetchUrl={metaModelsUrls.list}
        renderRow={(row) => ({
          id: row?.id,
          route: row?.route,
          params: row?.params,
          label: row?.name,
          itemLabel: row?.label,
        })}
      />
    </Grid>

    {values?.model ? (
      <Grid item xs={3}>
        <AutocompleteFieldV2
          name="clone_from"
          label={values?.model?.label}
          requestKey={`system-model-clone-from`}
          requestKeyOptions={[values?.model?.id]}
          fetchUrl={(query) => {
            return `${values?.model?.route}${query ?? ''}${
              values?.model?.params ? '&' + values?.model?.params : ''
            }`;
          }}
          renderRow={(row) => ({
            id: row?.id,
            label: row[values?.model?.itemLabel],
          })}
          required
        />
      </Grid>
    ) : null}
  </>
);

export const UIPrefixesForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label="Code" name="code" required />
    </Grid>

    <Grid item xs={3}>
      <TextField label="Label EN" name="label_en" required />
    </Grid>

    <Grid item xs={3}>
      <TextField label="Label FR" name="label_fr" />
    </Grid>

    <Grid item xs={3}>
      <TextField label="Label DE" name="label_de" />
    </Grid>

    <Grid item xs={3}>
      <TextField label="Label UR" name="label_ur" />
    </Grid>
  </>
);

export const RelationShipsForm = () => (
  <>
    <Grid item xs={5}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={6}>
      <TextField label="Description" name="description" required />
    </Grid>
  </>
);

export const ClientRelationForm = ({ values }) => (
  <>
    <Grid item xs={3}>
      <AutocompleteFieldV2
        name="relation"
        label="Relation"
        requestKey="client-relations-relation"
        fetchUrl={relationShipsUrls.list}
        required
        renderRow={(row) => ({
          id: row?.id,
          label: row?.name,
        })}
      />
    </Grid>

    <Grid item xs={3}>
      <AutocompleteFieldV2
        name="child_client"
        label="Child Client"
        requestKey="client-relations-client"
        fetchUrl={clientsUrls.list}
        required
        renderRow={(row) => ({
          id: row?.id,
          label: row?.name,
        })}
      />
    </Grid>
  </>
);
