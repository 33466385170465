import * as Yup from 'yup';
import {
  backlogStoryPoints,
  fieldType,
  bugPriorityOptions,
  bugOsOptions,
  bugBrowserOptions,
  bugStatusOptions,
  bugTypeOptions,
  durationUnitsChoices,
  microservices,
} from '@config/constants';
import {
  formatToUTCDate,
  formatDate,
  formatToUTCDateTime,
} from '@config/functions/helperFunctions';
import getDropdownForm from '@dropdown-forms/getDropdownForm';
import { Link } from 'react-router-dom';
import { pmUrls, crmUrls } from '@config/routes';

const {
  programsUrls,
  resourcesUrls,
  tasksUrls,
  bugsUrls,
  taskStatusUrls,
  taskTypesUrls,
  rolesUrls,
  personasUrls,
  projectsUrls,
  userAccountsUrls,
  workCodesUrls,
  hlrsUrls,
} = pmUrls;
const { personsUrls } = crmUrls;

export const programMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.LINK,
    to: '/programs/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const workCodesMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 490,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'billable',
    headerName: 'Billable',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.billable : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const timesheetsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 290,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'date_time_started',
    headerName: 'Start Date',
    width: 180,
    value:
      data && data.date_time_started ? new Date(data.date_time_started) : null,
    editable: true,
    initialValue: formatToUTCDateTime(new Date()),
    onForm: true,
    type: fieldType.DATE_TIME,
    validate: Yup.string(),
  },
  {
    field: 'hours',
    headerName: 'Hours',
    width: 65,
    onForm: true,
    editable: true,
    value: data && data.order,
    initialValue: 0,
    validate: Yup.number().required(),
  },
  {
    field: 'task',
    headerName: 'Task',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: tasksUrls.list,
    requestKey: `${data?.id}-timesheet-task`,
    validate: Yup.mixed(),
    initialValue: data?.task
      ? {
          id: data?.task,
          label: data?.details?.task?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'resource',
    headerName: 'Resource',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: resourcesUrls.list,
    requestKey: `${data?.id}-timesheet-resource`,
    validate: Yup.mixed(),
    initialValue: data?.resource
      ? {
          id: data?.resource,
          label: data?.details?.resource?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'workcode',
    headerName: 'Work Code',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: workCodesUrls.list,
    requestKey: `${data?.id}-timesheet-workcode`,
    validate: Yup.mixed(),
    initialValue: data?.workcode
      ? {
          id: data?.workcode,
          label: data?.details?.workcode?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const roleMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 890,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const resourceMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 140,
    validate: Yup.string().required(),
  },
  {
    field: 'email',
    headerName: 'Email',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 200,
    validate: Yup.string().email().required(),
  },
  {
    field: 'mobile',
    headerName: 'Mobile',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 140,
    validate: Yup.string(),
  },
  {
    field: 'user',
    headerName: 'User',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: userAccountsUrls.list,
    requestKey: `${data?.id}-resource-users`,
    validate: Yup.mixed().required(),
    initialValue: data?.user
      ? {
          id: data?.user,
          label: data?.details?.user?.email,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.email,
    }),
  },
  {
    field: 'cost',
    headerName: 'Cost',
    onForm: true,
    editable: true,
    initialValue: 0,
    width: 80,
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    initialValue: '',
    width: 290,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'disabled',
    headerName: 'Disabled',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.disabled : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const projectMeta = ({ data, queryClient } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.LINK,
    to: '/projects/',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onAccordionForm: true,
    onForm: true,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    type: fieldType.MULTILINE,
    width: 580,
    editable: true,
    value: data && data.description,
    initialValue: '',
    onAccordionForm: true,
    onForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'started',
    headerName: 'Started',
    width: 180,
    value: data && data.started ? new Date(data.started) : null,
    editable: true,
    hideColumn: true,
    initialValue: formatToUTCDateTime(new Date()),
    onAccordionForm: true,
    onForm: true,
    type: fieldType.DATE_TIME,
    validate: Yup.string(),
  },
  {
    field: 'beta_partners',
    headerName: 'Beta Partner',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.beta_partners : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  // {
  //   field: 'template',
  //   headerName: 'Template',
  //   type: fieldType.BOOLEAN,
  //   width: 90,
  //   editable: true,
  //   value: data ? data.template : null,
  //   initialValue: false,
  //   hideColumn: true,
  //   validate: Yup.boolean().default(false),
  // },
  {
    field: 'program',
    headerName: 'Program',
    width: 300,
    editable: true,
    onAccordionForm: true,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: programsUrls.list,
    requestKey: `${data?.id}-project-program`,
    validate: Yup.mixed(),
    initialValue: data?.program
      ? {
          id: data?.program,
          label: data?.details?.program?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-project-program`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.programs,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const projectTemplateMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.LINK,
    to: '/projects/',
    width: 180,
    editable: true,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    type: fieldType.MULTILINE,
    width: 490,
    editable: true,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'started',
    headerName: 'Start Date',
    width: 180,
    editable: true,
    initialValue: formatToUTCDateTime(new Date()),
    onForm: true,
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
  },
  {
    field: 'program',
    headerName: 'Program',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: programsUrls.list,
    requestKey: `${data?.id}-project-temlate-program`,
    validate: Yup.mixed(),
    initialValue: data?.program
      ? {
          id: data?.program,
          label: data?.details?.program?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const personaMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.TEXT,
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 900,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.description,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const hlrMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.LINK,
    to: '/hlrs/',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 900,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.description,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const backlogMeta = ({ data, nextOrder, project, queryClient } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 65,
    onAccordionForm: true,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder + 1,
    validate: Yup.number().required(),
    onDetailCard: false,
  },
  {
    field: 'as_a',
    headerName: 'As a',
    width: 120,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personasUrls.list,
    requestKey: `${data?.id}-backlog-as-a`,
    validate: Yup.mixed(),
    urlParams: project
      ? `&project=${project}`
      : `&project=${data?.project}&ordering=created_at`,
    initialValue: data?.as_a
      ? {
          id: data?.as_a,
          label: data?.details?.as_a?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-backlog-as-a`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.personas,
      }),
    }),
  },
  {
    field: 'i_want',
    headerName: 'I want',
    to: '/backlogs/',
    onAccordionForm: true,
    width: 390,
    editable: true,
    value: data && data.i_want,
    initialValue: '',
    validate: Yup.string().required(),
    type: fieldType.MULTILINE,
  },
  {
    field: 'so_that',
    headerName: 'So that',
    onAccordionForm: true,
    width: 420,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.so_that,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'story_points',
    headerName: 'Story Points',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onAccordionForm: true,
    options: backlogStoryPoints,
    initialValue: backlogStoryPoints[0].label,
    value: data ? data.story_points : null,
    validate: Yup.string().required(),
  },
  {
    field: 'impact',
    headerName: 'Impact',
    type: fieldType.NUMBER,
    width: 150,
    editable: true,
    value: data ? data.impact : null,
    initialValue: '',
    validate: Yup.number(),
    onForm: true,
  },
  {
    field: 'hlr',
    headerName: 'HLR',
    editable: true,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: hlrsUrls.list,
    requestKey: `${data?.id}-backlog-hlrs`,
    validate: Yup.mixed(),
    initialValue: data?.hlr
      ? {
          id: data?.hlr,
          label: data?.details?.hlr?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: data?.project
      ? `&project=${data?.project}&ordering=order`
      : null,
    onDetailCard: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const uatMeta = ({ data, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 75,
    onAccordionForm: true,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder + 1,
    validate: Yup.number().required(),
  },
  {
    field: 'given',
    headerName: 'Given',
    type: fieldType.MULTILINE,
    width: 280,
    getHref: (r) => `/acceptance-tests/${r.id}`,
    editable: true,
    value: data && data.given,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'when',
    headerName: 'When',
    width: 350,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.when,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'then',
    headerName: 'Then',
    width: 290,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.then,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: fieldType.SELECT,
    editable: true,
    width: 85,
    options: [
      {
        label: 'To Test',
        value: 'To Test',
      },
      {
        label: 'Passed',
        value: 'Passed',
      },
      {
        label: 'Failed',
        value: 'Failed',
      },
    ],
    initialValue: 'To Test',
    value: data ? data.status : null,
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const testsConductedMeta = ({ data, hide } = {}) => [
  {
    field: 'uat',
    headerName: 'UAT',
    type: fieldType.LINK,
    getHref: (r) => `/acceptance-tests/${r.uatId}`,
    width: 300,
    editable: false,
    value: null,
    initialValue: '',
    hideColumn: hide ? hide : null,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 400,
    editable: true,
    type: fieldType.TEXT,
    value: data && data.notes,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string(),
  },
  {
    field: 'passed',
    headerName: 'Passed',
    type: fieldType.BOOLEAN,
    width: 90,
    editable: true,
    value: data ? data.passed : null,
    initialValue: false,
    onAccordionForm: true,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'tested_by',
    headerName: 'Tested By',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: resourcesUrls.list,
    requestKey: `${data?.id}-project-task-tested-by`,
    validate: Yup.mixed(),
    initialValue: data?.tested_by
      ? {
          id: data?.tested_by,
          label: data?.details?.tested_by?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'date_tested',
    headerName: 'Date tested',
    type: fieldType.DATE,
    editable: true,
    width: 200,
    initialValue: formatToUTCDate(new Date()),
    onAccordionForm: true,
    value: data ? formatToUTCDate(new Date(data.date_tested)) : null,
    validate: Yup.string().default(formatToUTCDate(new Date())),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const featureRequestMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 700,
    onForm: true,
    type: fieldType.MULTILINE,
    validate: Yup.string().required(),
  },
  {
    field: 'project',
    headerName: 'Project',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: projectsUrls.list,
    requestKey: `${data?.id}-feature-request-project`,
    validate: Yup.mixed(),
    initialValue: data?.project
      ? {
          id: data?.project,
          label: data?.details?.project?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const bugMeta = ({
  data,
  queryClient,
  user,
  typeGridStyle,
  reportedByNameGridStyle,
  reportedByEmailGridStyle,
  headlineGridStyle,
  projectGridStyle,
  priorityGridStyle,
  osGridStyle,
  browserGridStyle,
  urlGridStyle,
  screenshotDriveLinkGridStyle,
  stepsToReproduceGridStyle,
  expectedResultGridStyle,
  actualResultGridStyle,
  statusGridStyle,
  resourceGridStyle,
  signedOffGridStyle,
  durationEstimateGridStyle,
  durationUnitGridStyle,
  durationActualGridStyle,
  dueDateGridStyle,
  estimatedStartDateTimeGridStyle,
  dateFixedGridStyle,
  resolutionGridStyle,
  keywordsGridStyle,
  notesGridStyle,
  createdAtGridStyle,
  reportedByNameOnDetailCard,
} = {}) => [
  {
    field: 'type',
    headerName: 'Type',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onForm: true,
    options: bugTypeOptions,
    initialValue: bugTypeOptions[0].label,
    value: data ? data.type : null,
    validate: Yup.string().required(),
    gridStyle: typeGridStyle ?? undefined,
  },
  {
    field: 'reported_by_name',
    headerName: 'Reported By',
    width: 180,
    editable: true,
    type: fieldType.TEXT,
    value: data?.reported_by_name,
    initialValue: user?.details?.username || '',
    hideColumn: false,
    onForm: true,
    validate: Yup.string().max(150),
    gridStyle: reportedByNameGridStyle ?? undefined,
    onDetailCard: reportedByNameOnDetailCard ?? true,
  },
  {
    field: 'reported_by_email',
    headerName: 'Reported by email',
    width: 400,
    editable: true,
    type: fieldType.TEXT,
    value: data?.reported_by_email,
    initialValue: user?.details?.email || '',
    hideColumn: true,
    onForm: true,
    validate: Yup.string().email(),
    gridStyle: reportedByEmailGridStyle ?? undefined,
  },
  {
    field: 'headline',
    headerName: 'Headline',
    type: fieldType.MULTILINE,
    to: '/bugs/',
    width: 500,
    editable: true,
    value: data && data.headline,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(150).required(),
    gridStyle: headlineGridStyle ?? undefined,
  },
  {
    field: 'project',
    headerName: 'Project',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: projectsUrls.list,
    requestKey: `${data?.id}-bug-project`,
    validate: Yup.mixed().required('project is a required field'),
    initialValue: data?.project
      ? {
          id: data?.project,
          label: data?.details?.project?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-sprint-program`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.projects,
      }),
    }),
    gridStyle: projectGridStyle ?? undefined,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onForm: true,
    options: bugPriorityOptions,
    initialValue: bugPriorityOptions[0].label,
    value: data ? data.priority : null,
    validate: Yup.string(),
    gridStyle: priorityGridStyle ?? undefined,
  },
  {
    field: 'os',
    headerName: 'Os',
    type: fieldType.SELECT,
    onForm: true,
    editable: true,
    width: 110,
    options: bugOsOptions,
    initialValue: bugOsOptions[0].label,
    hideColumn: true,
    value: data ? data.os : null,
    validate: Yup.string(),
    gridStyle: osGridStyle ?? 2,
  },
  {
    field: 'browser',
    headerName: 'Browser',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    onForm: true,
    options: bugBrowserOptions,
    initialValue: bugBrowserOptions[0].label,
    hideColumn: true,
    value: data ? data.browser : null,
    validate: Yup.string(),
    gridStyle: browserGridStyle ?? 2,
  },
  {
    field: 'url',
    headerName: 'Url',
    width: 400,
    editable: true,
    type: fieldType.TEXT,
    value: data && data.url,
    initialValue: '',
    hideColumn: true,
    validate: Yup.string().url(),
    gridStyle: urlGridStyle ?? undefined,
  },
  {
    field: 'screenshot_drive_link',
    headerName: 'Screenshot link',
    onAccordionForm: true,
    width: 1075,
    editable: true,
    value: data ? data.screenshot_drive_link : null,
    initialValue: '',
    validate: Yup.mixed(),
    getHref: (r) => r.screenshot_drive_link,
    linkInNewTab: true,
    hideColumn: true,
    onForm: true,
    type: fieldType.UPLOAD,
    gridStyle: screenshotDriveLinkGridStyle ?? undefined,
  },
  {
    field: 'steps_to_reproduce',
    headerName: 'Steps to reproduce',
    width: 400,
    editable: true,
    type: fieldType.MULTILINE,
    onForm: true,
    value: data && data.steps_to_reproduce,
    initialValue: '',
    hideColumn: true,
    validate: Yup.string().max(2500),
    gridStyle: stepsToReproduceGridStyle ?? 4,
  },
  {
    field: 'expected_result',
    headerName: 'Expected result',
    width: 400,
    editable: true,
    type: fieldType.MULTILINE,
    onForm: true,
    value: data && data.expected_result,
    initialValue: '',
    hideColumn: true,
    validate: Yup.string().max(2500).required(),
    gridStyle: expectedResultGridStyle ?? 4,
  },
  {
    field: 'actual_result',
    headerName: 'Actual result',
    onForm: true,
    width: 400,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.actual_result,
    initialValue: '',
    hideColumn: true,
    validate: Yup.string().max(2500),
    gridStyle: actualResultGridStyle ?? 4,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: fieldType.SELECT,
    editable: true,
    width: 110,
    options: bugStatusOptions,
    initialValue: '',
    onForm: false,
    value: data ? data.status : null,
    validate: Yup.string(),
    gridStyle: statusGridStyle ?? undefined,
  },
  {
    field: 'resource',
    headerName: 'Resource',
    width: 300,
    editable: true,
    onForm: false,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: resourcesUrls.list,
    requestKey: `${data?.id}-bug-resource`,
    validate: Yup.mixed(),
    initialValue: data?.resource
      ? {
          id: data?.resource,
          label: data?.details?.resource?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-sprint-program`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.resources,
      }),
    }),
    gridStyle: resourceGridStyle ?? undefined,
  },
  {
    field: 'signed_off',
    headerName: 'Signed off',
    width: 300,
    editable: true,
    onForm: false,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: resourcesUrls.list,
    requestKey: `${data?.id}-bug-signed-off`,
    validate: Yup.mixed(),
    initialValue: data?.signed_off
      ? {
          id: data?.signed_off,
          label: data?.details?.signed_off?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-bugs-signed-off`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.resources,
      }),
    }),
    gridStyle: signedOffGridStyle ?? undefined,
  },
  {
    field: 'duration_estimate',
    headerName: 'Duration Estimate',
    width: 250,
    editable: true,
    value: data ? data.duration_estimate : null,
    initialValue: '',
    validate: Yup.string(),
    onDetailAccordion: false,
    hideColumn: true,
    gridStyle: durationEstimateGridStyle ?? undefined,
  },
  {
    field: 'duration_unit',
    headerName: 'Duration Unit',
    type: fieldType.SELECT,
    editable: true,
    options: durationUnitsChoices,
    initialValue: durationUnitsChoices[1].label,
    value: data ? data.duration_unit : null,
    validate: Yup.string(),
    hideColumn: true,
    gridStyle: durationUnitGridStyle ?? undefined,
  },
  {
    field: 'duration_actual',
    headerName: 'Duration Actual',
    width: 250,
    editable: true,
    value: data ? data.duration_actual : null,
    initialValue: '',
    validate: Yup.string(),
    onDetailAccordion: false,
    hideColumn: true,
    gridStyle: durationActualGridStyle ?? undefined,
  },
  {
    field: 'due_date',
    headerName: 'Due date',
    type: fieldType.DATE,
    editable: true,
    width: 200,
    hideColumn: true,
    onForm: false,
    initialValue: formatToUTCDate(new Date()),
    value:
      data && data.due_date ? formatToUTCDate(new Date(data.due_date)) : null,
    validate: Yup.string(),
    gridStyle: dueDateGridStyle ?? undefined,
  },
  {
    field: 'estimated_start_date_time',
    headerName: 'Estimated start date time',
    width: 180,
    value:
      data && data.estimated_start_date_time
        ? new Date(data.estimated_start_date_time)
        : null,
    editable: true,
    hideColumn: true,
    onForm: false,
    initialValue: formatToUTCDate(new Date()),
    type: fieldType.DATE_TIME,
    validate: Yup.string(),
    gridStyle: estimatedStartDateTimeGridStyle ?? undefined,
  },
  {
    field: 'date_fixed',
    headerName: 'Date fixed',
    type: fieldType.DATE,
    editable: true,
    hideColumn: true,
    onForm: false,
    width: 200,
    initialValue: formatToUTCDate(new Date()),
    value:
      data && data.date_fixed
        ? formatToUTCDate(new Date(data.date_fixed))
        : null,
    validate: Yup.string(),
    gridStyle: dateFixedGridStyle ?? undefined,
  },

  {
    field: 'resolution',
    headerName: 'Resolution',
    width: 400,
    editable: true,
    type: fieldType.TEXT,
    value: data && data.resolution,
    initialValue: '',
    onForm: false,
    hideColumn: true,
    validate: Yup.string(),
    gridStyle: resolutionGridStyle ?? 6,
  },
  {
    field: 'keywords',
    headerName: 'Keywords',
    width: 400,
    editable: true,
    type: fieldType.TEXT,
    value: data && data.keywords,
    initialValue: '',
    onForm: false,
    hideColumn: true,
    validate: Yup.string(),
    gridStyle: keywordsGridStyle ?? 6,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 400,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.notes,
    initialValue: '',
    onForm: false,
    hideColumn: true,
    validate: Yup.string(),
    gridStyle: notesGridStyle ?? 12,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    gridStyle: createdAtGridStyle ?? undefined,
  },
];

export const sprintMetasMeta = ({ data, queryClient } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.LINK,
    to: '/sprint-metas/',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'started',
    headerName: 'Started',
    width: 180,
    value: data && data.started ? new Date(data.started) : null,
    editable: true,
    initialValue: formatToUTCDateTime(new Date()),
    onForm: true,
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
  },
  {
    field: 'days',
    headerName: 'Days',
    width: 180,
    value: data && data.days,
    editable: true,
    onForm: true,
    initialValue: 7,
    validate: Yup.number().positive().integer().required(),
  },
  {
    field: 'sprint_to_generate',
    headerName: '# Sprints',
    width: 180,
    value: data && data.sprint_to_generate,
    editable: true,
    onForm: true,
    initialValue: 1,
    validate: Yup.number().positive().integer().required(),
  },
  {
    field: 'goal',
    headerName: 'Goal',
    width: 180,
    value: data && data.goal,
    editable: true,
    onForm: true,
    initialValue: '',
    type: fieldType.TEXT,
    validate: Yup.string().max(2500),
  },
  {
    field: 'method',
    headerName: 'Methods',
    width: 180,
    value: data && data.method,
    editable: true,
    initialValue: '',
    onForm: true,
    hideColumn: true,
    type: fieldType.TEXT,
    validate: Yup.string().max(2500),
  },
  {
    field: 'metrics',
    headerName: 'Metrics',
    width: 180,
    value: data && data.metrics,
    editable: true,
    initialValue: '',
    type: fieldType.TEXT,
    hideColumn: true,
    onForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'program',
    headerName: 'Program',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: programsUrls.list,
    requestKey: `${data?.id}-sprint-program`,
    validate: Yup.mixed(),
    initialValue: data?.program
      ? {
          id: data?.program,
          label: data?.details?.program?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-sprint-program`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.programs,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const sprintMetaProjectsMeta = ({
  data,
  programId,
  queryClient,
} = {}) => [
  {
    field: 'project',
    headerName: 'Project',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: projectsUrls.list,
    getHref: (r) => `/projects/${r.project?.id}`,
    urlParams: programId ? `&program=${programId}` : '',
    requestKey: `${data?.id}-sprint-program-projects`,
    validate: Yup.mixed().required(),
    initialValue: data?.project
      ? {
          id: data?.project,
          label: data?.details?.project?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-sprint-program-projects`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.projects,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const sprintsMeta = ({ data } = {}) => [
  {
    field: 'start_date',
    headerName: 'Start Date',
    type: fieldType.DATE,
    editable: true,
    width: 200,
    initialValue: formatToUTCDate(new Date()),
    onAccordionForm: true,
    value:
      data && data.start_date
        ? formatToUTCDate(new Date(data.start_date))
        : null,
    validate: Yup.date()
      .default(() => new Date())
      .required(),
    renderCell: ({ id, value }) => (
      <Link to={`/sprints/${id}`}>{formatDate(new Date(value))}</Link>
    ),
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    type: fieldType.DATE,
    editable: true,
    width: 200,
    initialValue: formatToUTCDate(new Date()),
    onForm: false,
    onAccordionForm: false,
    value:
      data && data.end_date ? formatToUTCDate(new Date(data.end_date)) : null,
    validate: Yup.date().default(() => new Date()),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const sprintTasksMeta = ({ data } = {}) => [
  {
    field: 'task',
    headerName: 'Task',
    width: 300,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: tasksUrls.list,
    requestKey: `${data?.id}-sprint-task`,
    validate: Yup.mixed(),
    getHref: (r) => `/tasks/${r.taskId}`,
    initialValue: data?.task
      ? {
          id: data?.task,
          label: data?.details?.task?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 300,
    editable: false,
    onForm: false,
    onAccordionForm: false,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: taskStatusUrls.list,
    requestKey: `${data?.id}-sprint-task-status`,
    validate: Yup.mixed(),
    initialValue: data?.status
      ? {
          id: data?.status,
          label: data?.task_status_details?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'completion_percent',
    headerName: 'Percentage completed',
    width: 190,
    editable: false,
    onForm: false,
    onAccordionForm: false,
    value: data ? data.completion_percent : null,
    initialValue: '',
    validate: Yup.number().max(100).min(0),
    onDetailAccordion: false,
  },
  {
    field: 'deadline',
    headerName: 'Deadline',
    width: 180,
    editable: false,
    onForm: false,
    onAccordionForm: false,
    value: data && data.deadline ? new Date(data.deadline) : null,
    initialValue: formatToUTCDate(new Date()),
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const taskStatusMeta = ({
  data,
  nextOrder,
  projectId,
  onlyParent,
} = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 65,
    onAccordionForm: true,
    editable: true,
    value: data && `${data.full_order}${data.order}`,
    initialValue: nextOrder + 1,
    validate: Yup.number().required().min(0),
    type: fieldType.ORDER,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    to: `/task-statuses/`,
    onAccordionForm: true,
    onForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 590,
    onAccordionForm: true,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'parent',
    headerName: 'Parent Task Status',
    width: 200,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: taskStatusUrls.list,
    requestKey: `${data?.id}-parent-task-status`,
    validate: Yup.mixed(),
    initialValue:
      data && data?.details?.parent
        ? {
            id: data?.parent,
            label: `${data?.full_order ?? ''}${data?.full_order ? ' ' : ''}${
              data?.details?.parent?.name ?? ''
            }`,
          }
        : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.full_order}${row?.order} ${row?.name ?? ''}`,
    }),
    hideColumn: onlyParent,
    urlParams: `${
      projectId
        ? `&project=${projectId}`
        : data?.project
        ? `&project=${data.project}`
        : ''
    }&ordering=order`,
  },
  {
    field: 'rotting_days',
    headerName: 'Rotting days',
    width: 150,
    editable: true,
    value: data ? data.rotting_days : null,
    initialValue: 0,
    validate: Yup.number(),
    onAccordionForm: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const taskTypeMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 890,
    onAccordionForm: true,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const tasksMeta = ({
  data,
  queryClient,
  subTasks,
  ganttChart,
  nextOrder,
  projectId,
  orderGridStyle,
  nameGridStyle,
  startedGridStyle,
  durationEstimateGridStyle,
  durationUnitGridStyle,
  durationActualGridStyle,
  completionPercentGridStyle,
  deadlineGridStyle,
  predecessorGridStyle,
  dependencyGridStyle,
  ownerGridStyle,
  statusGridStyle,
  descriptionGridStyle,
  taskTypeGridStyle,
  programGridStyle,
  projectsGridStyle,
  createdAtGridStyle,
  statusOnDetailCard,
} = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 50,
    editable: true,
    onAccordionForm: true,
    onForm: true,
    value: data && data.order,
    initialValue: nextOrder ? nextOrder + 1 : 1,
    onDetailCard: false,
    validate: Yup.number().required(),
    type: fieldType.ORDER,
    gridStyle: orderGridStyle ?? undefined,
  },

  {
    field: 'owner',
    headerName: 'Owner',
    onForm: true,
    width: 140,
    editable: true,
    onAccordionForm: true,
    hideColumn: ganttChart,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: resourcesUrls.list,
    requestKey: `${data?.id}-project-task-owner`,
    validate: Yup.mixed(),
    initialValue: data?.owner
      ? {
          id: data?.owner,
          label: data?.details?.owner?.email,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.email,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-project-task-owner`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.resources,
      }),
    }),
    gridStyle: ownerGridStyle ?? undefined,
  },
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    type: fieldType.MULTILINE,
    to: '/tasks/',
    width: 400,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
    gridStyle: nameGridStyle ?? undefined,
  },
  {
    field: 'started',
    headerName: 'Started',
    width: 140,
    onAccordionForm: false,
    value: data && data.started ? new Date(data.started) : null,
    editable: true,
    initialValue: formatToUTCDateTime(new Date()),
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
    gridStyle: startedGridStyle ?? undefined,
  },
  {
    field: 'duration_estimate',
    headerName: 'Duration Est',
    onForm: true,
    width: 120,
    editable: !subTasks?.has_sub_tasks,
    value: subTasks?.has_sub_tasks
      ? `${subTasks.duration_estimate}`
      : data
      ? `${data.duration_estimate || ''}`
      : null,
    initialValue: 0,
    validate: Yup.string()
      .nullable()
      .matches(/^(?:\d+)?$/, 'Must be >= 0')
      .test('is-positive-integer-or-empty', 'Must be >= 0', (value) => {
        // Allow empty or null values
        if (value === null || value === '') return true;

        const number = parseInt(value, 10);
        return !isNaN(number) && number >= 0;
      }),
    onDetailAccordion: false,
    gridStyle: durationEstimateGridStyle ?? undefined,
  },
  {
    field: 'duration_unit',
    headerName: 'Duration Unit',
    width: 110,
    type: fieldType.SELECT,
    editable: subTasks?.has_sub_tasks ? false : true,
    options: durationUnitsChoices,
    initialValue: durationUnitsChoices[1].label,
    value: subTasks?.has_sub_tasks
      ? `${subTasks.duration_unit}`
      : data
      ? `${data.duration_unit || ''}`
      : null,
    validate: Yup.string(),
    onDetailAccordion: false,
    gridStyle: durationUnitGridStyle ?? undefined,
  },
  {
    field: 'duration_actual',
    headerName: 'Duration Actual',
    onForm: true,
    width: 130,
    editable: subTasks?.has_sub_tasks ? false : true,
    value: subTasks?.has_sub_tasks
      ? `${subTasks.duration_actual}`
      : data
      ? `${data.duration_actual || ''}`
      : null,
    initialValue: 0,
    validate: Yup.string()
      .nullable()
      .matches(/^(?:\d+)?$/, 'Must be >= 0')
      .test('is-positive-integer-or-empty', 'Must be >= 0', (value) => {
        // Allow empty or null values
        if (value === null || value === '') return true;

        const number = parseInt(value, 10);
        return !isNaN(number) && number >= 0;
      }),
    onDetailAccordion: false,
    gridStyle: durationEstimateGridStyle ?? undefined,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    editable: true,
    onForm: true,
    onAccordionForm: true,
    hideColumn: ganttChart,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: taskStatusUrls.list,
    requestKey: `${data?.id}-project-task-status`,
    validate: Yup.mixed().test(
      'is-object-with-id-label-or-empty',
      'Please select an option',
      (value) => {
        // Check if value is an object with 'id' and 'label'
        return (
          typeof value === 'object' &&
          value !== null &&
          'id' in value &&
          'label' in value
        );
      }
    ),
    initialValue: data?.status
      ? {
          id: data?.status,
          label: data?.details?.status?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: data?.project
      ? `&project=${data?.project}&ordering=order`
      : `&project=${projectId}`,
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-project-task-status`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.taskStatus,
      }),
    }),
    gridStyle: statusGridStyle ?? undefined,
    onDetailCard: statusOnDetailCard ?? true,
  },
  {
    field: 'completion_percent',
    headerName: 'Percentage completed',
    width: 250,
    editable: true,
    value: data ? data.completion_percent : null,
    initialValue: '',
    validate: Yup.number().max(100).min(0),
    onDetailAccordion: false,
    hideColumn: true,
    gridStyle: completionPercentGridStyle ?? undefined,
  },
  {
    field: 'deadline',
    headerName: 'Deadline',
    width: 140,
    onAccordionForm: false,
    value: data && data.deadline ? new Date(data.deadline) : null,
    editable: true,
    initialValue: formatToUTCDateTime(new Date()),
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
    gridStyle: deadlineGridStyle ?? undefined,
    hideColumn: true,
  },
  {
    field: 'predecessor',
    headerName: 'Predecessor',
    width: 300,
    onForm: true,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: tasksUrls.list,
    requestKey: `${data?.id}-project-task-predecessor`,
    validate: Yup.mixed(),
    hideColumn: true,
    initialValue: data?.predecessor
      ? {
          id: data?.predecessor,
          label: data?.details?.predecessor?.name,
        }
      : null,
    urlParams: data?.project ? `&project=${data?.project}&ordering=order` : '',
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    gridStyle: predecessorGridStyle ?? undefined,
  },
  {
    field: 'dependency',
    headerName: 'Dependency',
    width: 300,
    onForm: true,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: tasksUrls.list,
    requestKey: `${data?.id}-project-task-dependency`,
    validate: Yup.mixed(),
    initialValue: data?.dependency
      ? {
          id: data?.dependency,
          label: data?.details?.dependency?.name,
        }
      : null,
    urlParams: data?.project ? `&project=${data?.project}&ordering=order` : '',
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    gridStyle: dependencyGridStyle ?? undefined,
    hideColumn: true,
  },
  {
    field: 'task_type',
    headerName: 'Type',
    onForm: true,
    width: 300,
    editable: true,
    onAccordionForm: true,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: taskTypesUrls.list,
    requestKey: `${data?.id}-project-task-type`,
    validate: Yup.mixed().required('This field is required'),
    initialValue: data?.task_type
      ? {
          id: data?.task_type,
          label: data?.details?.task_type?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: data?.project
      ? `&project=${data?.project}&ordering=-created_at`
      : `&project=${projectId}`,
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-project-task-type`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.taskTypes,
      }),
    }),
    gridStyle: taskTypeGridStyle ?? undefined,
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 800,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
    onAccordionForm: true,
    type: fieldType.MULTILINE,
    onDetailAccordion: false,
    gridStyle: descriptionGridStyle ?? undefined,
  },
  {
    field: 'program',
    headerName: 'Program',
    width: 300,
    editable: true,
    onForm: true,
    hideColumn: true,
    onDetailCard: false,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: programsUrls.list,
    requestKey: `${data?.id}-task-program`,
    validate: Yup.mixed().required('This field is required'),
    initialValue: data?.program
      ? {
          id: data?.program,
          label: data?.details?.program?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    gridStyle: programGridStyle ?? undefined,
  },
  {
    field: 'parent_task',
    headerName: 'Parent Task',
    width: 300,
    editable: true,
    onForm: true,
    hideColumn: true,
    onDetailCard: false,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: tasksUrls.list,
    requestKey: `${data?.id}-parent-task`,
    validate: Yup.mixed(),
    initialValue: data?.parent_task
      ? {
          id: data?.parent_task,
          label: data?.details?.parent_task?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    gridStyle: programGridStyle ?? undefined,
  },
  {
    field: 'projects',
    headerName: 'Project',
    width: 300,
    editable: true,
    onForm: true,
    hideColumn: true,
    onDetailCard: false,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: projectsUrls.list,
    requestKey: `${data?.id}-task-project`,
    validate: Yup.mixed().required('This field is required'),
    initialValue: data?.project
      ? {
          id: data?.project,
          label: data?.details?.project?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    gridStyle: projectsGridStyle ?? undefined,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    gridStyle: createdAtGridStyle ?? undefined,
  },
];

export const bugCommentMeta = ({ data } = {}) => [
  {
    field: 'comment',
    headerName: 'Comment',
    width: 650,
    editable: true,
    value: data ? data.comment : null,
    initialValue: '',
    onForm: true,
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'organization',
    headerName: 'Organization',
    width: 250,
    editable: true,
    value: data ? data.organization : null,
    initialValue: '',
    onForm: true,
    onAccordionForm: true,
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const taskBacklogMeta = ({ data } = {}) => [
  {
    field: 'as_a',
    headerName: 'As a',
    width: 300,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personasUrls.list,
    requestKey: `${data?.id}-task-backlog-as-a`,
    validate: Yup.mixed().required(),
    initialValue: data?.as_a
      ? {
          id: data?.as_a,
          label: data?.details?.as_a?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'i_want',
    headerName: 'I want',
    width: 500,
    editable: false,
    value: data && data.i_want,
    initialValue: '',
    type: fieldType.LINK,
    getHref: (r) => `/backlogs/${r.backlogId || r.id}/`,
    validate: Yup.string().required(),
  },
  {
    field: 'so_that',
    headerName: 'So that',
    width: 400,
    editable: false,
    type: fieldType.TEXT,
    value: data && data.so_that,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const backlogTasksMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    getHref: (r) => `/tasks/${r.task}`,
    width: 200,
    editable: false,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'completion_percent',
    headerName: 'Completion Percentage',
    width: 250,
    editable: false,
    value: data ? data.completion_percent : null,
    initialValue: '',
    validate: Yup.number().max(100).min(0),
    onDetailAccordion: false,
  },
  {
    field: 'deadline',
    headerName: 'Deadline',
    width: 180,
    onAccordionForm: true,
    value: data && data.deadline ? new Date(data.deadline) : null,
    editable: false,
    initialValue: formatToUTCDateTime(new Date()),
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
  },
  {
    field: 'owner',
    headerName: 'Owner',
    width: 300,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personasUrls.list,
    requestKey: `${data?.id}-backlog-owner`,
    validate: Yup.mixed(),
    initialValue: data?.owner
      ? {
          id: data?.owner,
          label: data?.details?.owner?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const backlogArtifactMeta = ({ data } = {}) => [
  {
    field: 'drive',
    headerName: 'Artifact',
    onAccordionForm: true,
    width: 1075,
    editable: true,
    value: data ? data.drive : null,
    initialValue: '',
    validate: Yup.mixed().required('Please select a file'),
    getHref: (r) => r.drive,
    linkInNewTab: true,
    type: fieldType.UPLOAD,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const taskBugMeta = ({
  data,
  queryClient,
  projectId,
  showTask = true,
  showBug = true,
  includeFilters = false,
} = {}) => [
  {
    field: 'program',
    headerName: 'Program',
    width: 300,
    editable: false,
    onForm: includeFilters,
    onAccordionForm: includeFilters,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: programsUrls.list,
    requestKey: `${data?.id}-task-bug-programs`,
    validate: Yup.mixed().required(),
    initialValue: null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'project',
    headerName: 'Project',
    width: 300,
    editable: false,
    onForm: includeFilters,
    onAccordionForm: includeFilters,
    hideColumn: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: projectsUrls.list,
    requestKey: `${data?.id}-task-bug-projects`,
    validate: Yup.mixed().required(),
    initialValue: null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'task',
    headerName: 'Task',
    width: 300,
    hideColumn: !showTask,
    editable: false,
    onForm: showTask,
    onAccordionForm: showTask,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: tasksUrls.list,
    getHref: (r) => `/tasks/${r?.task?.id}`,
    urlParams: projectId ? `&project=${projectId}` : '',
    requestKey: `${data?.id}-task-bug-tasks`,
    validate: Yup.mixed().required(),
    initialValue: data?.task
      ? {
          id: data?.task,
          label: data?.details?.task?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-task-bug-tasks`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.task,
      }),
    }),
  },
  {
    field: 'bug',
    headerName: 'Bug',
    width: 300,
    editable: false,
    onForm: showBug,
    onAccordionForm: showBug,
    hideColumn: !showBug,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: bugsUrls.list,
    getHref: (r) => `/bugs/${r?.bug}`,
    urlParams: projectId ? `&project=${projectId}` : '',
    requestKey: `${data?.id}-task-bug-bugs`,
    validate: Yup.mixed().required(),
    initialValue: data?.bug
      ? {
          id: data?.bug,
          label: data?.details?.bug?.headline,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.headline,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const taskCommentMeta = ({ data } = {}) => [
  {
    field: 'comment',
    headerName: 'Comment',
    width: 750,
    editable: true,
    value: data ? data.comment : null,
    initialValue: '',
    onForm: true,
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    onAccordionForm: true,
    width: 350,
    editable: true,
    value: data ? data.attachment : null,
    initialValue: '',
    validate: Yup.mixed(),
    getHref: (r) => r.attachment,
    linkInNewTab: true,
    type: fieldType.UPLOAD,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const stakeholderMeta = ({ data, queryClient } = {}) => [
  {
    field: 'person',
    headerName: 'Person',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-stakeholder-person`,
    validate: Yup.mixed(),
    initialValue: data?.person
      ? {
          id: data?.person,
          label: data?.details?.personId?.email,
        }
      : null,
    renderRow: (row) => ({
      id: row.id,
      label: `${row?.first_name || 'Unamed'} ${row?.last_name || ''} (${
        row?.email || ''
      })`,
    }),
    hideColumn: true,
    onDetailCard: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(400).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 250,
    onAccordionForm: true,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'email',
    headerName: 'Email',
    editable: true,
    value: data ? data.email : null,
    initialValue: '',
    width: 250,
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'mobile',
    headerName: 'Mobile',
    editable: true,
    value: data ? data.mobile : null,
    initialValue: '',
    width: 250,
    onAccordionForm: true,
    validate: Yup.string(),
  },
  {
    field: 'landline',
    headerName: 'Landline',
    editable: true,
    value: data ? data.landline : null,
    initialValue: '',
    width: 250,
    onAccordionForm: true,
    validate: Yup.string().nullable(),
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: rolesUrls.list,
    requestKey: `${data?.id}-stakeholder-role`,
    validate: Yup.mixed(),
    initialValue: data?.role
      ? {
          id: data?.role,
          label: data?.details?.role?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'person',
    headerName: 'Person',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-stakeholder-person`,
    validate: Yup.mixed(),
    initialValue: data?.person
      ? {
          id: data?.person,
          label: data?.details?.person?.email,
        }
      : null,
    renderRow: (row) => ({
      id: row.id,
      label: `${row?.first_name || 'Unamed'} ${row?.last_name || ''} (${
        row?.email || ''
      })`,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const workingTimeMeta = ({ data, queryClient } = {}) => [
  {
    field: 'project',
    headerName: 'Project',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: projectsUrls.list,
    requestKey: `${data?.id}-working-time-project`,
    validate: Yup.mixed(),
    initialValue: data?.project
      ? {
          id: data?.project,
          label: data?.details?.project?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-working-time-project`]);
      },
      Form: getDropdownForm({
        microservice: microservices.PM.name,
        model: microservices.PM.models.projects,
      }),
    }),
  },
  {
    field: 'week_start',
    headerName: 'Week Start',
    width: 400,
    editable: true,
    type: fieldType.TEXT,
    onForm: true,
    value: data && data.week_start,
    initialValue: '',
    hideColumn: true,
    validate: Yup.string(),
  },
  {
    field: 'fiscal_year_start',
    headerName: 'Fiscal Year Start',
    type: fieldType.DATE,
    editable: true,
    width: 200,
    onForm: true,
    initialValue: formatToUTCDate(new Date()),
    value:
      data && data.due_date
        ? formatToUTCDate(new Date(data.fiscal_year_start))
        : null,
    validate: Yup.string(),
  },
  {
    field: 'default_start_time',
    headerName: 'Default Start Time',
    type: fieldType.DATE_TIME,
    editable: true,
    width: 200,
    onForm: true,
    initialValue: formatToUTCDateTime(new Date()),
    value:
      data && data.default_start_time
        ? formatToUTCDate(new Date(data.default_start_time))
        : null,
    validate: Yup.string().required(),
  },
  {
    field: 'default_end_time',
    headerName: 'Default End Time',
    type: fieldType.DATE_TIME,
    editable: true,
    width: 200,
    onForm: true,
    initialValue: formatToUTCDateTime(new Date()),
    value:
      data && data.default_end_time
        ? formatToUTCDate(new Date(data.default_end_time))
        : null,
    validate: Yup.string().required(),
  },
  {
    field: 'hours_per_week',
    headerName: 'Hours Per Week',
    type: fieldType.NUMBER,
    width: 150,
    editable: true,
    value: data ? data.hours_per_week : null,
    initialValue: '',
    validate: Yup.number().required(),
    onForm: true,
  },
  {
    field: 'days_per_month',
    headerName: 'Days Per Month',
    type: fieldType.NUMBER,
    width: 150,
    editable: true,
    value: data ? data.days_per_month : null,
    initialValue: '',
    validate: Yup.number().required(),
    onForm: true,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
