import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { ecommerceUrls, crmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { CartForm } from '@config/forms/ecommerce';
import ecommerceHandleRow from '@config/handleRows/ecommerce';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  cartsUrls,
  deliveryStatusesUrls,
  paymentStatusesUrls,
  discountCodesUrls,
} = ecommerceUrls;
const { personsUrls } = crmUrls;
const { cartRowMap } = ecommerceHandleRow;

export default function Carts({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const [customerTerm, setCustomerTerm] = useState('');
  const [deliveryStatusTerm, setDeliveryStatusTerm] = useState('');
  const [paymentStatusTerm, setPaymentStatusTerm] = useState('');
  const [discountCodeTerm, setDiscountCodeTerm] = useState('');

  const {
    data: customers,
    isFetching: fetchingCustomers,
    refetch: refetchCustomer,
  } = useAutocomplete({
    key: 'customers',
    getUrl: personsUrls.list,
    inputValue: customerTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.first_name,
      };
    },
  });

  const {
    data: deliveryStatuses,
    isFetching: deliveryStatusesIsFetching,
    refetch: refetchDeliveryStatus,
  } = useAutocomplete({
    key: 'deliveryStatuses',
    getUrl: deliveryStatusesUrls.list,
    inputValue: deliveryStatusTerm,
  });

  const {
    data: paymentStatuses,
    isFetching: fetchingPaymentStatuses,
    refetch: refetchPayment,
  } = useAutocomplete({
    key: 'paymentStatuses',
    getUrl: paymentStatusesUrls.list,
    inputValue: paymentStatusTerm,
  });

  const {
    data: discountCodes,
    isFetching: fetchingDiscountCodes,
    refetch: refetchDiscountCode,
  } = useAutocomplete({
    key: 'discountCodes',
    getUrl: discountCodesUrls.list,
    inputValue: discountCodeTerm,
  });

  const cartsColOptions = React.useMemo(() => {
    return {
      customers,
      fetchingCustomers,
      setCustomerTerm,
      deliveryStatuses,
      deliveryStatusesIsFetching,
      setDeliveryStatusTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
      discountCodes,
      fetchingDiscountCodes,
      setDiscountCodeTerm,
    };
  }, [
    customers,
    fetchingCustomers,
    setCustomerTerm,
    deliveryStatuses,
    deliveryStatusesIsFetching,
    setDeliveryStatusTerm,
    paymentStatuses,
    fetchingPaymentStatuses,
    setPaymentStatusTerm,
    discountCodes,
    fetchingDiscountCodes,
    setDiscountCodeTerm,
  ]);

  return (
    <Box>
      <ListView
        label="Carts"
        prefix="0tp"
        labels={labels}
        editMode={editMode}
        microservice="E-Commerce"
        model="Cart"
        urls={cartsUrls}
        columnKey="cartEcommerce"
        columnOptions={cartsColOptions}
        addNewForm={{
          getFields: () =>
            CartForm({
              customers,
              fetchingCustomers,
              setCustomerTerm,
              deliveryStatuses,
              deliveryStatusesIsFetching,
              setDeliveryStatusTerm,
              paymentStatuses,
              fetchingPaymentStatuses,
              setPaymentStatusTerm,
              discountCodes,
              fetchingDiscountCodes,
              setDiscountCodeTerm,
              refetchCustomer,
              refetchPayment,
              refetchDeliveryStatus,
              refetchDiscountCode,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: cartsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify(`Cart Created!`, {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={cartRowMap}
      />
    </Box>
  );
}
