import React from 'react';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import { lmsUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { mentorRowsMap } from '@config/handleRows/lms';
import { MentorForm } from '@config/forms/lms';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { mentorsUrls } = lmsUrls;

export default function Mentors({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const mentorColOptions = React.useMemo(() => {
    return {
      queryClient,
    };
  }, [queryClient]);

  return (
    <Box>
      <ListView
        nodeAPI
        label='Mentors'
        prefix='00i'
        labels={labels}
        editMode={editMode}
        urls={mentorsUrls}
        columnKey='mentor'
        columnOptions={mentorColOptions}
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            MentorForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { personId, ...rest } = values;

              const { data } = await createRecord({
                values: {
                  ...rest,
                  personId: personId?.id,
                },
                url: mentorsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              refetch();
              return data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={mentorRowsMap}
      />
    </Box>
  );
}
