import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { colors } from '@config/constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function NavLink({ icon, text, expandable, open, padLeft, ...rest }) {
  const chevronStyles = { fontSize: 18, color: colors.drawerLight };
  const textColor = open ? '#111' : '#888';

  return (
    <ListItemButton
      dense
      {...rest}
      selected={open}
      sx={{
        pl: padLeft,
        color: textColor,
        fontWeight: '500',
        mt: '4px',
      }}
    >
      {icon ? (
        <ListItemIcon sx={{ color: textColor, minWidth: 32 }}>
          <FontAwesomeIcon icon={icon} />
        </ListItemIcon>
      ) : null}
      <ListItemText primary={text} />
      {expandable ? (
        open ? (
          <ExpandLessOutlinedIcon sx={chevronStyles} />
        ) : (
          <ChevronRightIcon sx={chevronStyles} />
        )
      ) : null}
    </ListItemButton>
  );
}

export default function DrawerItem({
  active,
  icon,
  href = '',
  dropdown,
  expandable,
  text,
  padLeft,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(!!active);
  const isExternal = typeof href === 'string' && href.startsWith('http');

  const hrefLinkStyles = { textDecoration: 'none' };
  const linkProps = {
    icon,
    text,
    padLeft,
  };

  const handleClick = (e) => {
    if (dropdown) {
      e.preventDefault();
      setOpen((prevState) => !prevState);
      if (href) {
        navigate(href);
      }
    }
  };

  return (
    <>
      {isExternal ? (
        <a
          style={hrefLinkStyles}
          href={href}
          rel='noopener noreferrer'
          target='_blank'
        >
          <NavLink {...linkProps} open={location.pathname === href} />
        </a>
      ) : (
        <Link style={hrefLinkStyles} to={href}>
          <NavLink
            {...linkProps}
            onClick={handleClick}
            expandable={expandable}
            open={open}
          />
        </Link>
      )}

      {expandable ? (
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List
            dense
            component='div'
            sx={{
              '&::after': {
                content: '""',
                position: 'absolute',
                left: '32px',
                top: '8px',
                height: '90%',
                width: '2px',
                opacity: '1',
                background: '#ddd',
              },
            }}
          >
            {dropdown}
          </List>
        </Collapse>
      ) : null}
    </>
  );
}
