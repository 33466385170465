import React from 'react';
import * as Yup from 'yup';
import { crmUrls } from '@config/routes';
import { socialMediaTypeMeta } from '@config/meta/crm/index';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { socialMediaTypesUrls } = crmUrls;

export default function SocialMediaDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Social Media"
        initialValues={{
          ...getAddFormInitialValues(socialMediaTypeMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(socialMediaTypeMeta(), 'listView'),
        })}
        getPostUrl={() => socialMediaTypesUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key="name" label="Name" name="name" required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                key="description"
                label="Description"
                name="description"
                rows={3}

              />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
