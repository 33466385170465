import React from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { systemUrls } from '@config/routes';
import { SystemLogMap } from '@config/handleRows/system';
import { microservices } from '@config/constants';
import PublicUserErrorScreen from '@components/CMS-V2/PublicUserErrorScreen';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';

const { systemLogsUrls } = systemUrls;

export default function SystemLogs({ labels, editMode }) {
  const user = useSelector(selectProfile);

  if (
    Array.isArray(user?.details?.roles_names) &&
    !(
      user?.details?.roles_names.includes('Superusers') ||
      user?.details?.is_superuser ||
      user?.details?.roles_names.includes('Can See System Logs')
    )
  ) {
    return <PublicUserErrorScreen />;
  }

  return (
    <Box>
      <ListView
        label='System Logs'
        prefix='0ep'
        labels={labels}
        editMode={editMode}
        microservice={microservices.LOGS.name}
        model={microservices.LOGS.models.systemLogs}
        urls={systemLogsUrls}
        columnKey='systemLogs'
        handleRowsMap={SystemLogMap}
        customSortColumnName='logs_date'
        hideActions
      />
    </Box>
  );
}
