import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DetailCard from '@components/core/DetailCard';
import { useQueryClient } from 'react-query';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  openBrowserPopup,
  handleCreateFormErrors,
} from '@config/functions/helperFunctions';
import { tasksMeta } from '@config/meta/pm/pmMeta';
import { pmUrls, nodeDriveUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import RebaseDialogBox from '@components/PM/RebaseDialogBox';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { TaskCommentForm } from '@config/forms/pm';
import { createRecord } from '@config/functions/requests';
import { taskCommentRowsMap } from '@config/handleRows/pm';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import TaskDropdown from '@dropdown-forms/pm/TaskDropdown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const { sprintTasksUrls, tasksUrls, taskCommentsUrls } = pmUrls;
const { filesUrls } = nodeDriveUrls;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function TasksSidePeek({
  labels,
  editMode,
  sprintId,
  recordId,
  open,
  setOpen,
  statusOnDetailCard,
}) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addTaskPopupOpen, setAddTaskPopupOpen] = React.useState(false);
  const [addTaskPopupParams, setAddTaskPopupParams] = React.useState({});
  const addTaskOpen = Boolean(anchorEl);
  const [recordData, setRecordData] = React.useState(null);
  const [rebasePopup, setRebasePopup] = React.useState(false);
  const [resolveValues, setResolveValues] = React.useState(null);

  const container =
    typeof window !== 'undefined' ? () => window.document.body : undefined;

  const getSchema = React.useCallback((data) => {
    return tasksMeta({
      data,
      queryClient,
      subTasks: data?.sub_tasks,
      projectId: recordData?.project,
      orderGridStyle: 6,
      nameGridStyle: 12,
      startedGridStyle: 6,
      durationEstimateGridStyle: 6,
      durationUnitGridStyle: 6,
      durationActualGridStyle: 6,
      completionPercentGridStyle: 6,
      deadlineGridStyle: 6,
      predecessorGridStyle: 6,
      dependencyGridStyle: 6,
      ownerGridStyle: 6,
      statusGridStyle: 6,
      descriptionGridStyle: 12,
      taskTypeGridStyle: 6,
      programGridStyle: 6,
      projectsGridStyle: 6,
      createdAtGridStyle: 6,
      statusOnDetailCard,
    });
  }, []);

  const getValues = (values) => {
    if (
      (values?.started &&
        values?.started < (values?.deadline || recordData?.deadline)) ||
      (values?.deadline &&
        values?.deadline > (values?.started || recordData?.started))
    ) {
      return new Promise((resolve) => {
        setResolveValues(() => resolve);
        setRebasePopup(true);
      });
    }

    return Promise.resolve(values);
  };

  const onPopupSubmit = (valuesFromPopup) => {
    if (resolveValues) {
      resolveValues(valuesFromPopup);
      setResolveValues(null);
      setRebasePopup(false);
    }
  };

  const handleMenuItemClick = (isChild) => {
    const commonData = {
      title: isChild ? 'Child Task' : 'Sibling Task',
      program: {
        id: recordData?.details?.project?.program,
        label: recordData?.program_details?.name,
      },
      project: {
        id: recordData?.project,
        label: recordData?.details?.project?.name,
      },
    };

    const taskData = isChild
      ? { parent_task: { id: recordData?.id, label: recordData?.name } }
      : {
          parent_task: {
            id: recordData?.parent_task,
            label: recordData?.details?.parent_task?.name,
          },
        };

    setAddTaskPopupOpen(true);
    setAddTaskPopupParams({ ...commonData, ...taskData });
  };

  const handleAddTaskClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddTaskClose = () => {
    setAnchorEl(null);
  };

  const handleTaskDropdownCustom = async (data) => {
    if (!sprintId) return;

    await createRecord({
      values: {
        sprint: sprintId,
        task: data?.id,
      },
      url: sprintTasksUrls.list(),
      token: user.token,
      actAs: user?.actAs,
    });
  };

  return (
    <Drawer
      container={container}
      variant='temporary'
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 699,
        },
        '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.06)' },
      }}
      open={open}
      onClose={() => {
        setOpen(false);
        queryClient.invalidateQueries(['tasks-board']);
      }}
      ModalProps={{
        keepMounted: true,
      }}
      anchor='right'
    >
      <Toolbar />
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Typography sx={{ fontWeight: '500', fontSize: '22px' }}>
            Data
          </Typography>

          <Box sx={{ ml: 'auto' }}>
            <Button
              id='add-task-button'
              disabled={!recordData}
              aria-controls={addTaskOpen ? 'add-task-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={addTaskOpen ? 'true' : undefined}
              variant='text'
              disableElevation
              onClick={handleAddTaskClick}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ mr: 2 }}
            >
              Add Task
            </Button>

            <Button
              variant='text'
              endIcon={<LaunchIcon />}
              sx={{ mr: 1 }}
              onClick={() => {
                openBrowserPopup(`/tasks/${recordId}?noDrawer=true`);
              }}
            >
              Detail
            </Button>

            <StyledMenu
              id='add-task-menu'
              MenuListProps={{ 'aria-labelledby': 'add-task-button' }}
              anchorEl={anchorEl}
              open={addTaskOpen}
              onClose={handleAddTaskClose}
            >
              <TaskDropdown
                hideProgram
                hideProject
                hideParent
                showChildren={false}
                customOpen={addTaskPopupOpen}
                setCustomOpen={setAddTaskPopupOpen}
                handleCustom={handleTaskDropdownCustom}
                refetch={() => null}
                {...addTaskPopupParams}
              />
              <MenuItem
                onClick={() => handleMenuItemClick(false)}
                disableRipple
              >
                Sibling
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick(true)} disableRipple>
                Child
              </MenuItem>
            </StyledMenu>
          </Box>
        </Box>

        <DetailCard
          urls={tasksUrls}
          recordId={recordId}
          getSchema={getSchema}
          setData={setRecordData}
          getValues={getValues}
          onError={(err) => {
            if (err?.response?.data?.started) {
              notify(err?.response?.data?.started, { type: 'ERROR' });
            } else if (err?.response?.data?.deadline) {
              notify(err?.response?.data?.deadline, { type: 'ERROR' });
            } else {
              notify('There was an error, please try again.', {
                type: 'ERROR',
              });
            }
          }}
          catchRebasingError={true}
          debounceDelay={0}
        />

        {rebasePopup ? (
          <RebaseDialogBox
            task
            open={rebasePopup}
            onPopupSubmit={onPopupSubmit}
          />
        ) : null}

        <Box sx={{ mt: 4 }}>
          <DetailAccordionView
            microservice={microservices.PM.name}
            model={microservices.PM.models.taskComment}
            columnKey='taskCommentPM'
            label='Task Comments'
            prefix='0e0'
            labels={labels}
            editMode={editMode}
            urls={taskCommentsUrls}
            addNewForm={{
              getFields: ({ values, errors }) =>
                TaskCommentForm({ values, errors }),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError
              ) => {
                try {
                  let file = values.attachment;
                  if (file) {
                    const formData = new FormData();
                    formData.append('anonymous_can_see_it', true);
                    formData.append('file', file);

                    const { data: fileData } = await createRecord({
                      values: formData,
                      url: filesUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                    });

                    file = fileData.fileUrl;
                  }

                  const response = await createRecord({
                    values: {
                      ...values,
                      attachment: file,
                      task: recordId,
                    },
                    url: taskCommentsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  refetch();
                  return response?.data?.id;
                } catch (err) {
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={taskCommentRowsMap}
            urlParams={`&task=${recordId}`}
          />
        </Box>
      </Box>
    </Drawer>
  );
}
