import React from 'react';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import FormDialog from '@components/FormDialog';

export default function ContextFieldDialog({
  contextFields,
  contextFieldValues,
  open,
  setOpen,
  getValues,
  setContextFieldValues,
}) {
  const initialValues = { ...contextFieldValues };
  const meta = {};

  contextFields.map((contextField) => {
    if (!Object.keys(contextFieldValues).length) {
      initialValues[contextField.name] = '';
    }

    if (contextField.type === 'String') {
      if (contextField.mandatory) {
        meta[contextField.name] = Yup.string().required();
      } else {
        meta[contextField.name] = Yup.string().nullable();
      }
    } else if (
      contextField.type === 'Number' ||
      contextField.type === 'Decimal'
    ) {
      if (contextField.mandatory) {
        meta[contextField.name] = Yup.number().required();
      } else {
        meta[contextField.name] = Yup.number().nullable();
      }
    }
  });

  return (
    <>
      <FormDialog
        title='Context Fields'
        handleSubmit={async (values, setSubmitting, resetForm, setOpen) => {
          setContextFieldValues(values);
          setSubmitting(false);
          resetForm();
          setOpen(false);
        }}
        initialValues={initialValues}
        validationSchema={Yup.object(meta)}
        getValues={(values) => getValues(values)}
        getPostUrl={() => null}
        getFields={() => (
          <Grid container spacing={3.5}>
            {contextFields.map((contextField) => {
              return (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label={contextField.label || contextField.name}
                      name={contextField.name}
                      required={contextField.mandatory}
                      type={contextField.type.toLowerCase()}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        )}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
