import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { communicationUrls, systemUrls, bpaUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { emailTemplateMeta } from '@config/meta/communication';
import { Box, Grid, Button, Divider } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOCNew from '@components/DropdownAddHOCNew';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import {
  createRecord,
  updateRecord,
  deleteRecord,
} from '@config/functions/requests';
import {
  handleCreateFormErrors,
  getAuthHeader,
} from '@config/functions/helperFunctions';
import Accordion from '@ui/Accordion';
import { ContextFieldForm } from '@config/forms/communication';
import { contextFieldRowsMap } from '@config/handleRows/communication';
import useToast from '@hooks/useToast';
import useRQuery from '@hooks/useRQuery';

const {
  mailTemplatesUrls,
  contextFieldUrls,
  imapAccountsUrls,
  cloneSystemTemplatesUrls,
} = communicationUrls;
const { templatesUrls } = systemUrls;
const { modelAssetsUrls } = bpaUrls;

const defaultText = '<h1>An Example Email</h1>';

function StepButtons({ backBtnText, nextBtnText, onBack, onNext, disabled }) {
  return (
    <>
      <Divider sx={{ mb: 2 }} />

      <Button
        variant="outlined"
        disableElevation
        sx={{ px: 4, mr: 2 }}
        onClick={onBack}
        disabled={disabled}
      >
        {backBtnText || 'Back'}
      </Button>
      <Button
        variant="contained"
        disableElevation
        sx={{ px: 4 }}
        onClick={onNext}
        disabled={disabled}
      >
        {nextBtnText || 'Next'}
      </Button>
    </>
  );
}

export default function EmailTemplateDropdown({
  children,
  refetch,
  workflow,
  stage,
  selectedModelAsset = null,
  actionsDisabled = false,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [action, setAction] = React.useState('Add');
  const [stepActionsDisabled, setStepActionsDisabled] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [htmlText, setHtmlText] = React.useState(defaultText);
  const [expanded, setExpanded] = React.useState('mailTemplate');
  const [initialFromEmail, setInitialFromEmail] = React.useState(null);
  const [mailTemplate, setMailTemplate] = React.useState(null);
  const [modelAsset, setModelAsset] = React.useState({});
  const [contextFields, setContextFields] = React.useState([]);

  const { data, isError, isFetching } = useRQuery({
    key: ['asset-data', selectedModelAsset, open, user?.token, user?.actAs],
    url: modelAssetsUrls.detail(selectedModelAsset),
    config: getAuthHeader(user?.token, user?.actAs),
    options: {
      enabled:
        !!user?.token && !!selectedModelAsset && action === 'Edit' && open,
    },
  });

  useEffect(() => {
    if (!data) return;

    if (!initialFromEmail && data?.details?.asset?.from_email)
      setInitialFromEmail({
        id: data?.details?.asset?.from_email,
        label: data?.details?.asset?.details?.from_email?.username,
      });
    setMailTemplate(data?.details?.asset);
    setContextFields(data?.details?.asset?.details?.context_fields ?? []);
    setModelAsset(data);
    setHtmlText(data?.details?.asset?.html);
  }, [data]);

  const emailTemplatesColOptions = React.useMemo(() => {
    return { onListView: true };
  }, []);

  const customInitialValues =
    action === 'Edit' && mailTemplate
      ? {
          name: mailTemplate?.name,
          description: mailTemplate?.description,
          subject: mailTemplate?.subject,
          from_email: mailTemplate?.from_email
            ? {
                id: mailTemplate?.from_email,
                label: mailTemplate?.details?.from_email?.username,
              }
            : null,
          from_email_alias: mailTemplate?.from_email_alias,
        }
      : {};

  const closePopup = () => {
    setContextFields([]);
    setInitialFromEmail(null);
    setMailTemplate(null);
    setModelAsset({});
    setExpanded('mailTemplate');
    setHtmlText(defaultText);
    setStepActionsDisabled('');
    setOpen(false);
  };

  const handleCancel = async (disable = '') => {
    if (disable) {
      setStepActionsDisabled(disable);
    }

    if (action !== 'Edit' && mailTemplate?.id) {
      await deleteRecord({
        url: mailTemplatesUrls.detail(mailTemplate?.id),
        token: user.token,
        actAs: user?.actAs,
      });
    }

    if (action !== 'Edit' && modelAsset?.id) {
      await deleteRecord({
        url: modelAssetsUrls.detail(modelAsset?.id),
        token: user.token,
        actAs: user?.actAs,
      });
    }

    refetch({ doNotAssign: true });
    closePopup();
  };

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <DropdownAddHOCNew
        setOpen={setOpen}
        setAction={setAction}
        selected={selectedModelAsset}
        actionsDisabled={actionsDisabled}
      >
        {children}
      </DropdownAddHOCNew>

      <FormDialog
        maxWidth="md"
        title="Email Template"
        isFetching={
          isFetching ||
          (selectedModelAsset &&
            action === 'Edit' &&
            (!mailTemplate || !Object.keys(customInitialValues).length) &&
            !isError)
        }
        isError={isError}
        submitType={action}
        initialValues={{
          ...getAddFormInitialValues(
            emailTemplateMeta(emailTemplatesColOptions),
            'listView'
          ),
          ...customInitialValues,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            emailTemplateMeta(emailTemplatesColOptions),
            'listView'
          ),
        })}
        handleClose={handleCancel}
        showActionButtons={!!mailTemplate?.id}
        getPostUrl={() => mailTemplatesUrls.list()}
        handleSave={async (submitForm, values) => {
          values.clickedFromSaveBtn = true;
          await submitForm();
        }}
        handleSubmit={async (
          values,
          setSubmitting,
          resetForm,
          setOpen,
          refetch,
          setError,
          setFieldError,
          setFieldValue,
          errors
        ) => {
          try {
            const {
              template,
              from_email,
              subject,
              clickedFromSaveBtn,
              ...rest
            } = values;
            let response = {};

            if (template && !mailTemplate?.id) {
              const payload = {
                template_id: template?.id,
                custom_params: {
                  ...rest,
                  from_email: from_email?.id,
                  anonymous_can_see_it: true,
                },
              };

              if (subject && subject.length)
                payload.custom_params.subject = subject;
              response = await createRecord({
                values: payload,
                url: cloneSystemTemplatesUrls.detail(
                  microservices.NOTIFICATION.models.mailTemplates
                ),
                token: user.token,
                actAs: user?.actAs,
              });
            } else {
              if (!values?.subject || !values?.subject.length) {
                return setFieldError('subject', 'Subject is a required field!');
              }

              if (mailTemplate?.id) {
                const payload = {
                  ...rest,
                  subject,
                  html: htmlText,
                };

                if (
                  !initialFromEmail ||
                  (initialFromEmail && from_email?.id !== initialFromEmail?.id)
                )
                  payload.from_email = from_email?.id;

                response = await updateRecord({
                  values: payload,
                  url: mailTemplatesUrls.detail(mailTemplate?.id),
                  token: user.token,
                  actAs: user?.actAs,
                  encryptionToken: user?.encryptionToken,
                });
              } else {
                response = await createRecord({
                  values: {
                    ...rest,
                    subject,
                    html: defaultText,
                    from_email: from_email?.id,
                    anonymous_can_see_it: true,
                  },
                  url: mailTemplatesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                  encryptionToken: user?.encryptionToken,
                });
              }
            }

            if (!mailTemplate?.id) {
              const { data: modelAssetResponse } = await createRecord({
                values: {
                  workflow: workflow,
                  asset_id: response?.data?.id,
                  stages: [stage],
                  microservice: 'Notification',
                  model: 'MailTemplate',
                },
                url: modelAssetsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              setModelAsset(modelAssetResponse);
            }

            if (clickedFromSaveBtn) {
              const newModelAsset = { ...modelAsset };
              newModelAsset.details.asset.name = response?.data?.name;
              refetch({
                ...newModelAsset,
                contextFields,
              });
              closePopup();
              return;
            }
            setMailTemplate(response?.data);
            setHtmlText(response?.data?.html);
            setFieldValue('name', response?.data?.name);
            setFieldValue('description', response?.data?.description);
            setFieldValue('subject', response?.data?.subject);
            setFieldValue(
              'from_email',
              response?.data?.from_email
                ? {
                    id: response?.data?.from_email,
                    label: response?.data?.details?.from_email?.username,
                  }
                : null
            );
            setFieldValue('from_email_alias', response?.data?.from_email_alias);
            setExpanded('body');
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        }}
        getFields={({
          isSubmitting,
          submitForm,
          errors,
          setFieldValue,
          setFieldError,
          values,
          validateForm,
        }) => (
          <Box>
            <Accordion
              label="Mail Template"
              expanded={expanded === 'mailTemplate'}
              onChange={handleChange('mailTemplate')}
            >
              <Grid container spacing={3.5}>
                <Grid item xs={6}>
                  <TextField label="Name" name="name" required />
                </Grid>

                <Grid item xs={6}>
                  <TextField label="Description" name="description" multiline />
                </Grid>

                <Grid item xs={6}>
                  <TextField label="Subject" name="subject" required />
                </Grid>

                {!mailTemplate?.id ? (
                  <Grid item xs={6}>
                    <AutocompleteFieldV2
                      name="template"
                      label="Template To Copy From"
                      requestKey="mail-template-templates"
                      fetchUrl={templatesUrls.list}
                      urlParams={`&ordering=created_at&microservice_name=Notification&model_name=MailTemplate`}
                      renderRow={(row) => ({
                        id: row?.id,
                        label: row?.name,
                      })}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={6}>
                  <AutocompleteFieldV2
                    name="from_email"
                    label="From Email"
                    requestKey="mail-template-from-emails"
                    fetchUrl={imapAccountsUrls.list}
                    urlParams={`&connected=true&smtp_connected=true&send_emails_from_this_account=true&ordering=created_at`}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.username,
                    })}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField label="From Email Alias" name="from_email_alias" />
                </Grid>
              </Grid>

              <StepButtons
                backBtnText="Cancel"
                disabled={stepActionsDisabled === 'mailTemplate'}
                onNext={async () => {
                  if (Object.keys(await validateForm()).length !== 0) return;
                  setStepActionsDisabled('mailTemplate');
                  await submitForm();
                  setStepActionsDisabled('');
                }}
                onBack={() => handleCancel('mailTemplate')}
              />
            </Accordion>
            <Accordion
              label="Body"
              expanded={expanded === 'body'}
              onChange={handleChange('body')}
              disabled={!!!mailTemplate?.id}
            >
              {expanded === 'body' ? (
                <>
                  <WysiwygEditor
                    initData={htmlText}
                    msName="Notification"
                    onChange={(e) => {
                      setHtmlText(e.editor.getData() ?? '');
                    }}
                  />

                  <StepButtons
                    disabled={stepActionsDisabled === 'body'}
                    onNext={async () => {
                      if (Object.keys(await validateForm()).length !== 0)
                        return;
                      setStepActionsDisabled('body');
                      await submitForm();
                      setStepActionsDisabled('');
                      setExpanded('contextFields');
                    }}
                    onBack={() => setExpanded('mailTemplate')}
                  />
                </>
              ) : null}
            </Accordion>
            <DetailAccordionView
              expanded={expanded === 'contextFields'}
              onChange={handleChange('contextFields')}
              disabled={!!!mailTemplate?.id}
              microservice={microservices.NOTIFICATION.name}
              model={microservices.NOTIFICATION.models.contextField}
              columnKey="contextFieldCommunication"
              label="Context Fields"
              prefix="0ln"
              urls={contextFieldUrls}
              addNewForm={{
                getFields: () => ContextFieldForm(),
                handleSubmit: async (
                  values,
                  setSubmitting,
                  resetForm,
                  setAddNewForm,
                  refetch,
                  setError
                ) => {
                  try {
                    const response = await createRecord({
                      values: { ...values, template: mailTemplate?.id },
                      url: contextFieldUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                      encryptionToken: user?.encryptionToken,
                    });

                    resetForm();
                    setAddNewForm(false);
                    refetch();

                    return response?.data?.id;
                  } catch (err) {
                    console.log(err);
                    handleCreateFormErrors({
                      err,
                      setError,
                      notify,
                      values,
                    });
                  } finally {
                    setSubmitting(false);
                  }
                },
              }}
              handleRowMap={contextFieldRowsMap}
              startFetching={!!mailTemplate?.id}
              urlParams={`&template=${mailTemplate?.id}`}
              getListData={(data) =>
                setContextFields(
                  data?.results ??
                    [].map((contextField) => ({
                      id: contextField.id,
                      label: contextField.label,
                      name: contextField.name,
                      mandatory: contextField.mandatory,
                      type: contextField.type,
                    }))
                )
              }
            />
          </Box>
        )}
        open={open}
        refetch={refetch}
        setOpen={setOpen}
      />
    </>
  );
}
