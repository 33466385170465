import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { logsUrls } from '@config/routes';
import { importErrorLogsRowsMap } from '@config/handleRows/logs';
import { importErrorLogMeta } from '@config/meta/logs/logsMeta';
import { useParams } from 'react-router-dom';


const { importErrorLogsUrls } = logsUrls;

export default function ImportLogs() {
  const { id: routerId } = useParams();

  return (
    <Box>
      <ListView
        label='Import Error Logs'
        urls={importErrorLogsUrls}
        getListViewURL= {(urls, page, searchTerm, pageSize, urlParams, sortColumn) => urls.detail(routerId,
            `?page=${page}&search=${searchTerm}&page_size=${pageSize}${urlParams}&ordering=${
                sortColumn.length
                ? sortColumn[0].sort === 'asc'
                    ? sortColumn[0].field
                    : `-${sortColumn[0].field}`
                : ''
            }`
        )}
        columns={importErrorLogMeta()}
        handleRowsMap={importErrorLogsRowsMap}
        importExport={false}
        showSearch={false}
        hideActions={true}
      />
    </Box>
  );
}
