import * as React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { sopsUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { SOPsTaskMeta } from '@config/meta/SOPs/SOPsMeta';
import { sopTaskStepRowsMap } from '@config/handleRows/sops';
import { SopTaskStepForm } from '@config/forms/sops';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useParams } from 'react-router-dom';

const { sopTasksUrls, sopTaskStepUrls } = sopsUrls;

export default function SopTaskDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => SOPsTaskMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/sop`, text: 'SOP' },

              {
                to: `/sops/${recordData.SOP}`,
                text: recordData.details.SOP && recordData.details.SOP.name,
              },
              { to: `/sops/${recordData.SOP}`, text: 'SOPTasks' },
              { text: recordData?.name },
            ]}
          ></DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={sopTasksUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.SOP.name}
          model={microservices.SOP.models.sopTaskStep}
          columnKey='SOPTaskStep'
          label='SOPTaskSteps'
          prefix='0hf'
          labels={labels}
          editMode={editMode}
          urls={sopTaskStepUrls}
          addNewForm={{
            getFields: () => SopTaskStepForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, SOPTask: recordId },
                  url: sopTaskStepUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                // notify(`SOP Task Step Created`, {
                //   type: 'SUCCESS',
                // });
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={sopTaskStepRowsMap}
          urlParams={`&SOPTask=${recordId}`}
        />
      </Box>
    </Box>
  );
}
