import * as React from 'react';
import DeleteRecurringEventModal from '@components/Calendar/RecurringEventDeletePrompt';
import DeletePromptModal from '@components/DeletePromptModal';
import SlotEventDeletePrompt from '@components/Calendar/SlotEventDeletePrompt';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

import {
  AccessTime,
  Assignment,
  CalendarToday,
  Description,
} from '@mui/icons-material';
import PeopleOutlineSharpIcon from '@mui/icons-material/PeopleOutlineSharp';
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function ViewEventModal({
  open,
  setOpen,
  event,
  onConfirmDelete,
  onConfirmSlotDelete,
  labels,
  editMode,
  prefix,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [deletePromptModal, setDeletePromptModal] = React.useState(false);
  const [disableDeleteBtn, setDisableDeleteBtn] = React.useState(false);

  const [recurringDeletePromptModal, setRecurringDeletePromptModal] =
    React.useState(false);
  const [disableRecurringDeleteBtn, setDisableRecurringDeleteBtn] =
    React.useState(false);

  const [slotDeletePromptModal, setSlotDeletePromptModal] =
    React.useState(false);
  const [slotDisableDeleteBtn, setSlotDisableDeleteBtn] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const editButtonClick = () => {
    navigate(`/event/${event.id}`);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="event-view-page"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {prefix ? (
            <RenderUiPrefix
              editMode={editMode}
              labels={labels}
              code={prefix}
              defaultValue="View Event"
            >
              {(text) => {
                return <DialogTitle>{text}</DialogTitle>;
              }}
            </RenderUiPrefix>
          ) : (
            <DialogTitle>View Event</DialogTitle>
          )}
          <IconButton
            aria-label="close"
            onClick={editButtonClick}
            sx={{
              position: 'absolute',
              right: 80,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="close"
            onClick={() => {
              (event &&
                event.parent_event &&
                event.details &&
                event.details.parent_event &&
                event.details.parent_event.event_type === 'SlotEvent' &&
                !event?.details?.booked) ||
              (event && !event.parent_event && event.event_type === 'SlotEvent')
                ? setSlotDeletePromptModal(true)
                : (event.rrule && event.rrule.length) ||
                  (event.parent_event && event.details.parent_event?.rrule)
                ? setRecurringDeletePromptModal(true)
                : setDeletePromptModal(true);
            }}
            sx={{
              position: 'absolute',
              right: 40,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* <DialogTitle id='event-view-page'>
          View Event
          <IconButton
            aria-label='close'
            onClick={editButtonClick}
            sx={{
              position: 'absolute',
              right: 80,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label='close'
            onClick={() => {
              (
                event && event.parent_event && event.details && event.details.parent_event && event.details.parent_event.event_type === "SlotEvent"
              ) || (
                event && !event.parent_event && event.event_type === "SlotEvent"
              ) ? setSlotDeletePromptModal(true) : (event.rrule && event.rrule.length) || (event.parent_event) ? setRecurringDeletePromptModal(true) : setDeletePromptModal(true);
            }}
            sx={{
              position: 'absolute',
              right: 40,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
        <DialogContent>
          {event && (
            <Grid key="event-grid-container" container>
              <Grid
                item
                xs={2}
                sx={{
                  color: (theme) => theme.palette.grey[700],
                }}
              >
                <Assignment />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h5" component="div" gutterBottom>
                  {event.title}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  color: (theme) => theme.palette.grey[700],
                }}
              >
                <AccessTime />
              </Grid>
              <Grid item xs={10} sx={{ mb: 4 }}>
                <Typography variant="p" component="div" gutterBottom>
                  {`${new Date(event.start).toDateString()}, ${new Date(
                    event.start
                  ).getHours()}:${
                    new Date(event.start).getMinutes() < 10 ? '0' : ''
                  }${new Date(event.start).getMinutes()}${
                    new Date(event.start).getHours() >= 12 ? 'pm' : 'am'
                  }  `}
                  -
                  {new Date(event.end).toDateString()
                    ? `  ${new Date(event.end).getHours()}:${
                        new Date(event.end).getMinutes() < 10 ? '0' : ''
                      }${new Date(event.end).getMinutes()}${
                        new Date(event.end).getHours() >= 12 ? 'pm' : 'am'
                      }`
                    : `  ${new Date(event.end).toDateString()}, ${new Date(
                        event.end
                      ).getHours()}:${
                        new Date(event.end).getMinutes() < 10 ? '0' : ''
                      }${new Date(event.end).getMinutes()}${
                        new Date(event.end).getHours() >= 12 ? 'pm' : 'am'
                      }`}
                </Typography>
              </Grid>

              {event.description && (
                <>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      color: (theme) => theme.palette.grey[700],
                    }}
                  >
                    <Description />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="p" component="div" gutterBottom>
                      {event.description}
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={2}
                sx={{
                  color: (theme) => theme.palette.grey[700],
                }}
              >
                <CalendarToday />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="p" component="div" gutterBottom>
                  {event.calendar_name}
                </Typography>
              </Grid>

              <Grid
                item
                xs={2}
                sx={{
                  mt: 1,
                  color: (theme) => theme.palette.grey[700],
                }}
              >
                <PeopleOutlineSharpIcon />
              </Grid>
              <Grid item xs={10} sx={{ mt: 1 }}>
                <Typography variant="p" component="div" gutterBottom>
                  {Array.isArray(event.guests) && event.guests.length}{' '}
                  {Array.isArray(event.guests) && event.guests.length > 1
                    ? 'guests'
                    : 'guest'}
                </Typography>

                {Array.isArray(event.guests)
                  ? event.guests.map((guest) => (
                      <Typography variant="p" component="div" gutterBottom>
                        {guest.first_name || guest.last_name
                          ? `${guest.first_name || ''} ${
                              guest.last_name || ''
                            } -- `
                          : ''}
                        {guest.email}
                      </Typography>
                    ))
                  : null}
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>

      <DeleteRecurringEventModal
        open={recurringDeletePromptModal}
        disabled={disableRecurringDeleteBtn}
        onCancelClick={() => {
          setDisableRecurringDeleteBtn(false);
          setRecurringDeletePromptModal(false);
        }}
        onDeleteClick={async (deleteType) => {
          setDisableRecurringDeleteBtn(true);
          await onConfirmDelete(deleteType);
          setRecurringDeletePromptModal(false);
          setDisableRecurringDeleteBtn(false);
        }}
        editMode={editMode}
        labels={labels}
        prefix="0f7"
      />

      <DeletePromptModal
        open={deletePromptModal}
        disabledDeleteButton={disableDeleteBtn}
        onCancelClick={() => {
          setDisableDeleteBtn(false);
          setDeletePromptModal(false);
        }}
        onDeleteClick={async () => {
          setDisableDeleteBtn(true);
          await onConfirmDelete();
          setDeletePromptModal(false);
          setDisableDeleteBtn(false);
        }}
        editMode={editMode}
        labels={labels}
        prefix="cv"
        prefix2="kp"
      />

      <SlotEventDeletePrompt
        event={event}
        open={slotDeletePromptModal}
        disabledDeleteButton={slotDisableDeleteBtn}
        onCancelClick={() => {
          setSlotDisableDeleteBtn(false);
          setSlotDeletePromptModal(false);
        }}
        onDeleteAllClick={async () => {
          setSlotDisableDeleteBtn(true);
          await onConfirmSlotDelete();
          setSlotDeletePromptModal(false);
          setSlotDisableDeleteBtn(false);
        }}
        onDeleteClick={async () => {
          setSlotDisableDeleteBtn(true);
          await onConfirmDelete();
          setSlotDeletePromptModal(false);
          setSlotDisableDeleteBtn(false);
        }}
      />
    </>
  );
}
