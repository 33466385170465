import * as Yup from 'yup';
import { fieldType, microservices } from '@config/constants';
import {
  formatToUTCDateTime,
  addYearsToDate,
} from '@config/functions/helperFunctions';
import getDropdownForm from '@dropdown-forms/getDropdownForm';
import { crmUrls } from '@config/routes';

const { personsUrls } = crmUrls;

export const documentMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
    to: '/documents/',
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    width: 220,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'isPublished',
    headerName: 'Published',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.isPublished : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const documentRecipientMeta = ({ data, queryClient } = {}) => [
  {
    field: 'firstName',
    headerName: 'First name',
    onAccordionForm: true,
    width: 250,
    editable: true,
    value: data ? data.firstName : null,
    initialValue: '',
    validate: Yup.string().required(),
    to: '/document-recipients/',
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    onAccordionForm: true,
    width: 250,
    editable: true,
    value: data ? data.lastName : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'email',
    headerName: 'Email',
    onAccordionForm: true,
    editable: true,
    value: data ? data.email : null,
    initialValue: '',
    width: 200,
    validate: Yup.string().email().required(),
  },
  {
    field: 'personId',
    headerName: 'Person',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-recipients-person`,
    validate: Yup.mixed(),
    initialValue: data?.personId
      ? {
          id: data?.personId,
          label: data?.details?.personId?.email,
        }
      : null,
    renderRow: (row) => ({
      id: row.id,
      label: `${row?.first_name || 'Unamed'} ${row?.last_name || ''} (${
        row?.email || ''
      })`,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-recipients-person`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.persons,
      }),
    }),
  },
  {
    field: 'isEditable',
    headerName: 'Editable?',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.isEditable : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'expiryDate',
    headerName: 'Expiry date',
    type: fieldType.DATE_TIME,
    width: 240,
    editable: true,
    value: data?.expiryDate
      ? formatToUTCDateTime(new Date(data.expiryDate))
      : null,
    initialValue: formatToUTCDateTime(addYearsToDate(new Date(), 4)),
    validate: Yup.string().default(formatToUTCDateTime(new Date())),
    onAccordionForm: true,
  },
  {
    field: 'documentFilledUrl',
    headerName: 'Filled Document',
    onAccordionForm: true,
    width: 850,
    editable: true,
    value: data ? data.documentFilledUrl : 'Not signed yet',
    initialValue: '',
    validate: Yup.string().url(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
