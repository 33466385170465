import React from 'react';
import * as Yup from 'yup';
import { crmUrls } from '@config/routes';
import { useQueryClient } from 'react-query';
import { companyContactMeta } from '@config/meta/crm/index';
import { Grid } from '@mui/material';
import {
  TextField,
  DateTimePickerField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';
import CompanyDropdown from '@dropdown-forms/crm/CompanyDropdown';

const {
  companyContactUrls,
  personsUrls,
  companiesUrls, // prettier
} = crmUrls;

export default function CompanyContactDropdown({
  children,
  refetch,
  company: initialCompany,
}) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [hideCompanyContact, setHideCompanyContact] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        hidden={hideCompanyContact}
        title="Company Contact"
        initialValues={{
          ...getAddFormInitialValues(
            companyContactMeta(),
            'DetailAccordionView'
          ),
          company: initialCompany,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            companyContactMeta(),
            'DetailAccordionView'
          ),
        })}
        getPostUrl={() => companyContactUrls.list()}
        getValues={({ company, person, ...values }) => ({
          ...values,
          person: person?.id,
          company_id: company?.id,
        })}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <PersonDropdown
                company={initialCompany}
                hideParent={setHideCompanyContact}
                refetch={({
                  id,
                  first_name,
                  last_name,
                  email,
                  personal_mobile,
                }) => {
                  setFieldValue('person', {
                    id: id,
                    label: `${first_name || ''} ${last_name || ''}`,
                  });
                  setFieldValue('work_email', email);
                  setFieldValue('work_phone', personal_mobile);
                  setFieldValue('work_mobile', personal_mobile);
                  queryClient.invalidateQueries([
                    'company-contact-dropdown-persons',
                  ]);
                }}
              >
                <AutocompleteFieldV2
                  name="person"
                  label="Person"
                  requestKey="company-contact-dropdown-persons"
                  fetchUrl={personsUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: `${row?.first_name} ${row?.last_name || ''}`,
                    email: row?.email,
                    personal_mobile: row?.personal_mobile,
                  })}
                  onChange={(value) => {
                    setFieldValue('work_email', value?.email);
                    setFieldValue('work_phone', value?.personal_mobile);
                    setFieldValue('work_mobile', value?.personal_mobile);
                  }}
                  required
                />
              </PersonDropdown>
            </Grid>

            <Grid item xs={6}>
              <CompanyDropdown
                hideParent={setHideCompanyContact}
                refetch={({ id, name }) => {
                  setFieldValue('company', { id, label: name });
                  queryClient.invalidateQueries([
                    'company-contact-dropdown-companies',
                  ]);
                }}
              >
                <AutocompleteFieldV2
                  name="company"
                  label="Company"
                  requestKey="company-contact-dropdown-companies"
                  fetchUrl={companiesUrls.list}
                  required
                />
              </CompanyDropdown>
            </Grid>

            <Grid item xs={6}>
              <TextField key="job_title" label="Job Title" name="job_title" />
            </Grid>

            <Grid item xs={6}>
              <TextField label="Work Email" name="work_email" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                multiline
                key="work_phone"
                label="Work Phone"
                name="work_phone"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                multiline
                key="work_mobile"
                label="Work Mobile"
                name="work_mobile"
              />
            </Grid>

            <Grid item xs={6}>
              <DateTimePickerField label="Start Date" name="start_date" />
            </Grid>
          </Grid>
        )}
        open={open}
        refetch={refetch}
        setOpen={setOpen}
      />
    </>
  );
}
