import { systemHost, logsHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  relationships: 'relationships',
  clientRelations: 'client-relations',
  clients: 'clients',
  automataConnectionWithAModels: 'automata-connection-with-a-models',
  clientOptions: 'client-meta',
  uiPrefixes: 'ui-prefixes',
  metaModels: 'meta-models',
  templates: 'templates',
  uiPrefixesPagn: 'ui-prefixes-paginated',
  importLogs: 'import-logs',
  importErrorLogs: 'import-error-logs',
  import: 'import',
  export: 'export',
  ipDetails: 'ip-details',
  logs: '',
  systemLogs: 'system-logs',
  visitorLogs: 'visitor-logs',
  shapeTransitionLog: 'shape-transition-logs',
};

const relationShipsUrls = getModelUrls(systemHost, models.relationships);
const clientRelationsUrls = getModelUrls(systemHost, models.clientRelations);
const clientsUrls = getModelUrls(systemHost, models.clients);
const automataConnectionWithAModelsUrls = getModelUrls(systemHost, models.automataConnectionWithAModels);
const clientOptionsUrls = getModelUrls(systemHost, models.clientOptions);
const uiPrefixesUrls = getModelUrls(systemHost, models.uiPrefixes);
const metaModelsUrls = getModelUrls(systemHost, models.metaModels);
const templatesUrls = getModelUrls(systemHost, models.templates);
const uiPrefixesPagnUrls = getModelUrls(systemHost, models.uiPrefixesPagn);
const importUrls = getModelUrls(systemHost, models.import);
const exportUrls = getModelUrls(systemHost, models.export);
const ipDetailUrls = getModelUrls(systemHost, models.ipDetails);
const logUrls = getModelUrls(logsHost, models.logs);
const systemLogsUrls = getModelUrls(logsHost, models.systemLogs);
const importLogsUrls = getModelUrls(logsHost, models.importLogs);
const visitorLogUrls = getModelUrls(logsHost, models.visitorLogs);
const shapeTransitionLogUrls = getModelUrls(
  logsHost,
  models.shapeTransitionLog
);

const urls = {
  automataConnectionWithAModelsUrls,
  relationShipsUrls,
  clientRelationsUrls,
  clientsUrls,
  clientOptionsUrls,
  uiPrefixesUrls,
  metaModelsUrls,
  templatesUrls,
  importUrls,
  exportUrls,
  uiPrefixesPagnUrls,
  ipDetailUrls,
  logUrls,
  systemLogsUrls,
  importLogsUrls,
  visitorLogUrls,
  shapeTransitionLogUrls,
};

export default urls;
