import React from 'react';
import useToast from '@hooks/useToast';
import { Box, Typography } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { colors } from '@config/constants';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import ClickToDial from '@components/CRM/ClickToDial';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import PerformManualTaskPopup from '@components/BPA-V2/ManualActions/PerformManualTaskPopup';
import MoveToLastManualStagePopup from '@components/BPA-V2/Popups/MoveToLastManualStagePopup';
import DeleteWorkflowInstancePopup from '@components/BPA-V2/Popups/DeleteWorkflowInstancePopup';
import AddBugToSprintDialogBox from '@components/PM/AddBugToSprintDialogBox';
import { createRecord } from '@config/functions/requests';
import { bpaUrls } from '@config/routes';
import OpportunitiesKanbanCardItem from '@components/OpportunitiesKanbanCardItem';
import INAsKanbanCardActions from '@components/INAsKanbanCardActions';
import {
  formatToUTCDate,
  isSuperUserOrAutomataAdmin,
  stripHTML,
} from '@config/functions/helperFunctions';
import ManualBPAKanbanCardItem from '@components/ManualBPAKanbanCardItem';
import { colorPickerColors } from '@config/constants';
import AutomataSidePeek from '@components/BPA-V2/ManualActions/AutomataSidePeek';
import BugsKanbanCardActions from './BugsKanbanCardActions';
import BugsSidePeek from './BugsSidePeek/BugsSidePeek';
import TasksSidePeek from './TasksSidePeek/TasksSidePeek';

const ITEM_HEIGHT = 48;

const {
  moveToNextStageUrls,
  bulkDeleteWorkflowInstancesUrls,
  bulkMoveToLastManualStageUrls,
} = bpaUrls;

// const getTypographyStyle = (card) => ({
//   fontSize: '0.85rem',
//   lineHeight: '1.25rem',
//   color: card.rotting ? '#fff' : colors.dark,
//   textDecoration: 'none',
//   '&:hover': {
//     color: '#fff',
//   }
// });

const TaskInfo = ({ card, labelCol }) => (
  <>
    {card?.order ? `${card?.task_level ?? ''}${card?.order}. ` : null}{' '}
    <span>{card[labelCol]}</span>{' '}
    {card?.duration_estimate
      ? `| ${card?.duration_estimate} ${card?.duration_unit ?? ''}`
      : ''}
  </>
);

const CandidateInfo = ({ card }) => (
  <>
    <span>
      {card.first_name} {card.last_name}
    </span>
    <br />
    <span>{card.personal_email}</span>
  </>
);

const CallScheduleInfo = ({ card }) => (
  <>
    <span>
      {card?.details?.person?.first_name || ''}{' '}
      {card?.details?.person?.last_name || ''}
    </span>
    <br />
    <span>{card?.details?.person?.personal_mobile || 'No phone'}</span>
  </>
);

const OpportunityInfo = ({ list, card, labelCol }) => {
  const opportunityCompany = card?.details?.company?.name;

  return (
    <>
      <span>
        {!!opportunityCompany ? `${opportunityCompany} -` : ''} {card[labelCol]}
      </span>
      <br />
      <span>
        Discounted Value: £
        {Number((list.confidence / 100) * card.estimated_value).toLocaleString(
          'en-GB',
          {
            useGrouping: true,
            maximumFractionDigits: 0,
            maximumSignificantDigits: 3,
          }
        )}
      </span>
      <br />
      <span>
        Estimated Close Date: {formatToUTCDate(card.estimated_close_date)}
      </span>
    </>
  );
};

const BugInfo = ({ card }) => (
  <>
    <span>
      {card.project} - {card.name}
    </span>
  </>
);

const ReminderInfo = ({ card }) => (
  <>
    <span>{card.name}</span>
  </>
);

const UATInfo = ({ card }) => (
  <>
    <span>{card.name}</span>
  </>
);

const INAInfo = ({ card }) => (
  <>
    <span>{card.name}</span>
  </>
);

export default function KanbanBoardCard({
  card,
  list,
  detailLink,
  labelCol,
  index,
  isDraggable = true,
  workflowId,
  selectedWorkflow,
  labels,
  editMode,
}) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [
    disableBPAMoveToLastManualStageBtn,
    setDisableBPAMoveToLastManualStageBtn,
  ] = React.useState(false);
  const [showBPAMoveToLastManualStage, setShowBPAMoveToLastManualStage] =
    React.useState(false);
  const [
    disableBPADeleteWorkflowInstancesBtn,
    setDisableBPADeleteWorkflowInstancesBtn,
  ] = React.useState(false);
  const [showBPADeleteWorkflowInstances, setShowBPADeleteWorkflowInstances] =
    React.useState(false);
  const [showBPAPerformManualTaskPopup, setShowBPAPerformManualTaskPopup] =
    React.useState(false);
  const [showAddBugToSprintPopup, setShowAddBugToSprintPopup] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showManualTaskData, setShowManualTaskData] = React.useState(false);
  const [showBugsSidePeek, setShowBugsSidePeek] = React.useState(false);
  const [showTasksSidePeek, setShowTasksSidePeek] = React.useState(false);
  const [showINASidePeek, setINAShowSidePeek] = React.useState(false);

  const open = Boolean(anchorEl);

  // const MANUAL_ACTIONS = [
  //   'send_email',
  //   'capture_data',
  //   'send_email_v2',
  //   'is_true',
  // ];

  function determineBackgroundColor({
    card,
    list,
    colorPickerColors,
    showManualTaskData,
  }) {
    if (showManualTaskData) return '#3367D6ED';
    if (list?.name === 'Zombies') return '#eb453f';
    if (card.colour) return colorPickerColors[card.colour]?.kanban;
    if (list?.manual) return '#FFBF00';
    if (card.rotting) return '#eb453f';
    return '#efefff';
  }

  const isBoxNeeded =
    detailLink === 'inas' || detailLink === 'tasks' || detailLink === 'bugs';

  function renderDetailComponent() {
    return (
      <Typography
        sx={{
          fontSize: '0.85rem',
          lineHeight: '1.25rem',
          color:
            showManualTaskData ||
            showTasksSidePeek ||
            card.rotting ||
            list?.name === 'Zombies'
              ? '#fff'
              : colors.dark,
          textDecoration: 'none',
          cursor: 'pointer',
          '&:hover': {
            color: '#fff',
          },
        }}
      >
        {
          {
            tasks: <TaskInfo card={card} labelCol={labelCol} />,
            candidates: <CandidateInfo card={card} />,
            'call-schedules': <CallScheduleInfo card={card} />,
            opportunities: (
              <OpportunityInfo list={list} labelCol={labelCol} card={card} />
            ),
            bugs: <BugInfo card={card} />,
            inas: <INAInfo card={card} />,
            reminders: <ReminderInfo card={card} />,
            'acceptance-tests': <UATInfo card={card} />,
          }[detailLink]
        }
      </Typography>
    );
  }

  return (
    <Draggable
      key={card.id}
      draggableId={card.id}
      index={index}
      isDragDisabled={!isDraggable}
      sx={{
        '&:hover': {
          background: '#3367D6ED',
          color: '#fff',
        },
      }}
    >
      {(provided2) => (
        <Box
          component="div"
          ref={provided2.innerRef}
          {...provided2.draggableProps}
          {...provided2.dragHandleProps}
          sx={{
            display: 'block',
            mb: '0.75rem',
            padding: '0.5rem 0.8rem',
            width: '100%',
            background: determineBackgroundColor({
              card,
              list,
              showManualTaskData: showManualTaskData || showTasksSidePeek,
              colorPickerColors,
            }),
            borderRadius: '4px',
            boxShadow:
              '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
            textDecoration: 'none',
            color:
              showManualTaskData || showTasksSidePeek || card.rotting
                ? '#fff'
                : colors.dark,
            position: 'relative',
            border: '1px solid #b9b9b9',
            ...provided2.draggableProps.style,
            '&:hover': {
              background: '#3367D6ED',
              color: '#fff',
            },
            '&:hover .MuiTypography-root': {
              color: '#fff',
            },
          }}
        >
          {detailLink !== 'bpa-board' && isBoxNeeded ? (
            <Box
              onClick={() => {
                if (detailLink === 'tasks') {
                  setShowTasksSidePeek(true);
                } else if (detailLink === 'bugs') {
                  setShowBugsSidePeek(true);
                } else if (detailLink === 'inas') {
                  setINAShowSidePeek(true);
                }
              }}
            >
              {renderDetailComponent()}
            </Box>
          ) : (
            <Link
              style={{ textDecoration: 'none' }}
              to={`/${detailLink}/${card.id}`}
            >
              {renderDetailComponent()}
            </Link>
          )}

          {detailLink === 'bpa-board' ? (
            <>
              <Typography
                sx={{
                  fontSize: '0.85rem',
                  lineHeight: '1.25rem',
                  color: card?.rotting ? '#fff' : undefined,
                  textDecoration: 'none',
                  wordBreak: 'break-word',
                  cursor: 'pointer',
                }}
                onClick={() => setShowManualTaskData(true)}
              >
                <span>{stripHTML(card?.label ?? 'Untitled')}</span>
              </Typography>

              <Box>
                <IconButton
                  sx={{
                    position: 'absolute',
                    right: '4px',
                    top: '6px',
                    opacity: '0.5',
                    '&:hover': {
                      opacity: '1',
                    },
                  }}
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  size="small"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <MoreVertIcon fontSize="inherit" />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '22ch',
                    },
                  }}
                >
                  {isSuperUserOrAutomataAdmin(user) &&
                  list?.shape_type === 'delay' ? (
                    <MenuItem
                      size="small"
                      sx={{ whiteSpace: 'normal' }}
                      onClick={async () => {
                        try {
                          await createRecord({
                            values: {
                              workflow: workflowId,
                              delay_shape: list?.shape_id,
                              workflow_instances: [card?.id],
                            },
                            url: moveToNextStageUrls.list(),
                            token: user.token,
                            actAs: user?.actAs,
                          });
                          queryClient.invalidateQueries(['bpa-board']);
                          notify('The card will be moved shortly.', {
                            type: 'SUCCESS',
                          });
                        } catch (error) {
                          notify('There was an error, please try again', {
                            type: 'ERROR',
                          });
                        } finally {
                          setAnchorEl(null);
                        }
                      }}
                    >
                      Move Forward From Delay
                    </MenuItem>
                  ) : null}

                  {list?.manual ? (
                    <MenuItem
                      size="small"
                      onClick={() => {
                        setShowBPAPerformManualTaskPopup(true);
                      }}
                    >
                      Perform Manual Task
                    </MenuItem>
                  ) : null}

                  <MenuItem
                    size="small"
                    onClick={() => {
                      setShowManualTaskData(true);
                      setAnchorEl(null);
                    }}
                  >
                    Data
                  </MenuItem>

                  {isSuperUserOrAutomataAdmin(user) ? (
                    <MenuItem
                      size="small"
                      onClick={() => {
                        setShowBPAMoveToLastManualStage(true);
                      }}
                    >
                      Move to Previous Stage
                    </MenuItem>
                  ) : null}

                  <MenuItem
                    size="small"
                    onClick={() => {
                      setShowBPADeleteWorkflowInstances(true);
                    }}
                  >
                    Move to Trash
                  </MenuItem>

                  {showBPAMoveToLastManualStage ? (
                    <MoveToLastManualStagePopup
                      open={!!showBPAMoveToLastManualStage}
                      setOpen={setShowBPAMoveToLastManualStage}
                      env="Kanban"
                      disableBPAMoveToLastManualStageBtn={
                        disableBPAMoveToLastManualStageBtn
                      }
                      setDisableBPAMoveToLastManualStageBtn={
                        setDisableBPAMoveToLastManualStageBtn
                      }
                      onMoveToLastManualStageClick={async () => {
                        try {
                          setDisableBPAMoveToLastManualStageBtn(true);
                          await createRecord({
                            values: {
                              workflow: workflowId,
                              shape: list?.shape_id,
                              workflow_instances: [card?.id],
                            },
                            url: bulkMoveToLastManualStageUrls.list(),
                            token: user.token,
                            actAs: user?.actAs,
                          });
                          queryClient.invalidateQueries(['bpa-board']);
                          notify('Operation completed', {
                            type: 'SUCCESS',
                          });
                          setShowBPAMoveToLastManualStage(false);
                        } catch (error) {
                          notify('There was an error, please try again', {
                            type: 'ERROR',
                          });
                        } finally {
                          setDisableBPAMoveToLastManualStageBtn(false);
                        }
                      }}
                    />
                  ) : null}

                  {showBPADeleteWorkflowInstances ? (
                    <DeleteWorkflowInstancePopup
                      open={!!showBPADeleteWorkflowInstances}
                      setOpen={setShowBPADeleteWorkflowInstances}
                      env="Kanban"
                      disableBPADeleteWorkflowInstancesBtn={
                        disableBPADeleteWorkflowInstancesBtn
                      }
                      setDisableBPADeleteWorkflowInstancesBtn={
                        setDisableBPADeleteWorkflowInstancesBtn
                      }
                      onDeleteWorkflowInstancesClick={async () => {
                        try {
                          setDisableBPADeleteWorkflowInstancesBtn(true);
                          await createRecord({
                            values: {
                              workflow: workflowId,
                              workflow_instances: [card?.id],
                            },
                            url: bulkDeleteWorkflowInstancesUrls.list(),
                            token: user.token,
                            actAs: user?.actAs,
                          });
                          queryClient.invalidateQueries(['bpa-board']);
                          notify('Operation completed', {
                            type: 'SUCCESS',
                          });
                          setShowBPADeleteWorkflowInstances(false);
                        } catch (error) {
                          notify('There was an error, please try again', {
                            type: 'ERROR',
                          });
                        } finally {
                          setDisableBPADeleteWorkflowInstancesBtn(false);
                        }
                      }}
                    />
                  ) : null}

                  {showBPAPerformManualTaskPopup ? (
                    <PerformManualTaskPopup
                      open={!!showBPAPerformManualTaskPopup}
                      setOpen={setShowBPAPerformManualTaskPopup}
                      workflowId={workflowId}
                      workflowInstances={[card.id]}
                      stage={list}
                      user={user}
                    />
                  ) : null}
                </Menu>
              </Box>
            </>
          ) : null}

          {showManualTaskData ? (
            <AutomataSidePeek
              instanceId={card?.id}
              showManualTaskData={showManualTaskData}
              setShowManualTaskData={setShowManualTaskData}
            />
          ) : null}

          {detailLink === 'manual-bpa-board' ? (
            <>
              <Link
                style={{ textDecoration: 'none' }}
                to={`${selectedWorkflow?.metaModel?.frontend_route}${card.entity_id}`}
              >
                <Typography
                  sx={{
                    fontSize: '0.85rem',
                    lineHeight: '1.25rem',
                    color: card?.rotting ? '#fff' : colors?.dark,
                    textDecoration: 'none',
                    wordBreak: 'break-word',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#fff',
                    },
                  }}
                >
                  <span>
                    {card?.details?.entity_id[
                      selectedWorkflow?.metaModel?.label
                    ] ?? 'Untitled'}
                  </span>
                </Typography>
              </Link>
              <ManualBPAKanbanCardItem
                detailLink={detailLink}
                card={card}
                list={list}
                user={user}
              />
            </>
          ) : null}

          {detailLink === 'call-schedules' ? (
            <ClickToDial
              callListKanban
              recordId={card.id}
              microservice="CRM"
              model="CallSchedule"
              phone={card?.details?.person?.personal_mobile}
            />
          ) : null}

          {detailLink === 'tasks' ||
          detailLink === 'candidates' ||
          detailLink === 'opportunities' ? (
            <OpportunitiesKanbanCardItem
              detailLink={detailLink}
              card={card}
              list={list}
              user={user}
              setShowTasksSidePeek={setShowTasksSidePeek}
            />
          ) : null}

          {detailLink === 'bugs' ? (
            <BugsKanbanCardActions
              card={card}
              setShowSidePeek={setShowBugsSidePeek}
              setShowAddBugToSprintPopup={setShowAddBugToSprintPopup}
            />
          ) : null}

          {detailLink === 'inas' ? (
            <INAsKanbanCardActions
              list={list}
              card={card}
              labels={labels}
              editMode={editMode}
              user={user}
              showINASidePeek={showINASidePeek}
              setINAShowSidePeek={setINAShowSidePeek}
            />
          ) : null}

          {showBugsSidePeek ? (
            <BugsSidePeek
              recordId={card?.id}
              open={showBugsSidePeek}
              setOpen={setShowBugsSidePeek}
            />
          ) : null}

          {showTasksSidePeek ? (
            <TasksSidePeek
              sprintId={card?.sprint_task_id ? list?.id : null}
              recordId={card?.id}
              open={showTasksSidePeek}
              setOpen={setShowTasksSidePeek}
              statusOnDetailCard={!!card?.sprint_task_id}
            />
          ) : null}

          {showAddBugToSprintPopup ? (
            <AddBugToSprintDialogBox
              open={showAddBugToSprintPopup}
              setOpen={setShowAddBugToSprintPopup}
              bugs={[card]}
            />
          ) : null}
        </Box>
      )}
    </Draggable>
  );
}
