import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { calendarUrls } from '@config/routes';
import ErrorScreen from '@components/ErrorScreen';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';
import useRQuery from '@hooks/useRQuery';

const styles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const { connectGoogleAccountUrls } = calendarUrls;

export default function GoogleCallback() {
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const codeAndState = code && state;

  const { data, isError } = useRQuery({
    key: ['connect-google-account', user.token, user?.actAs],
    url: connectGoogleAccountUrls.list(`?code=${code}&state=${state}`),
    config: getAuthHeader(user.token, user?.actAs),
    enabled: !!codeAndState,
  });

  React.useEffect(() => {
    if (!data || isError) return;
    queryClient.invalidateQueries(`drawer-calendar-accounts`);
    navigate(`/all-calendars/${data.id}`);
  }, [data, isError, navigate]);

  console.log(data);

  return (
    <Box sx={styles}>{isError ? <ErrorScreen /> : <CircularProgress />}</Box>
  );
}
