import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { truncateText } from '@config/functions/helperFunctions';

export default function DriveBreadcrumbs({ folderId, currentFolder }) {
  const shouldRenderBreadcrumbs = folderId && currentFolder;

  if (!shouldRenderBreadcrumbs) {
    return null;
  }

  const parentFolderId = currentFolder?.parent?.id;
  const parentFolderName = currentFolder?.parent?.name;
  const isParentFolderUser = parentFolderName?.startsWith('u-');

  const driveLink = isParentFolderUser ? '/drive' : `/drive/${parentFolderId}`;

  return (
    <Breadcrumbs separator='›' sx={{ mb: 3 }} aria-label='breadcrumb'>
      <Link to={driveLink}>
        <Typography
          component='div'
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          {isParentFolderUser ? 'Drive' : truncateText(parentFolderName)}
        </Typography>
      </Link>

      <Typography component='div'>
        {truncateText(currentFolder?.name)}
      </Typography>
    </Breadcrumbs>
  );
}
