import * as React from 'react';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import ShapeSidebar from './ShapeSidebar';
import TransitionSidebar from './TransitionSidebar';

export default function Sidebar({ selected, graph, workflowId, workflowName }) {
  const user = useSelector(selectProfile);
  const isSelectedArrow = selected?.vertex === false;

  if (!selected) return null;

  if (isSelectedArrow) {
    return <TransitionSidebar user={user} selectedTransition={selected} />;
  }

  return (
    <ShapeSidebar
      user={user}
      graph={graph}
      selectedShape={selected}
      workflowId={workflowId}
      workflowName={workflowName}
    />
  );
}
