import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ecommerceUrls, inventoryUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import ecommerceHandleRows from '@config/handleRows/ecommerce';
import { ItemDeliveryForm } from '@config/forms/ecommerce';
import { cartItemMeta } from '@config/meta/ecommerce';
import DetailPageHeader from '@components/DetailPageHeader';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  deliveryMethodsUrls,
  cartsUrls,
  deliveryStatusesUrls,
  itemDeliveriesUrls,
  cartItemsUrls,
  addressesUrls,
} = ecommerceUrls;
const { productVariantsUrls } = inventoryUrls;
const { itemDeliveryRowMap } = ecommerceHandleRows;

export default function CartItemDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [addressTerm, setAddressTerm] = useState('');
  const [cartItemTerm, setCartItemTerm] = useState('');
  const [deliveryMethodTerm, setDeliveryMethodTerm] = useState('');
  const [deliveryStatusTerm, setDeliveryStatusTerm] = useState('');
  const [productVariantTerm, setProductVariantTerm] = useState('');
  const [cartTerm, setCartTerm] = useState('');
  const [notify] = useToast();

  const {
    data: addresses,
    isFetching: fetchingAddresses,
    refetch: refetchDeliveryAddress,
  } = useAutocomplete({
    key: 'addresses',
    getUrl: addressesUrls.list,
    inputValue: addressTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.address1,
      };
    },
  });

  const {
    data: cartItems,
    isFetching: fetchingCartItems,
    refetch: refetchItemCart,
  } = useAutocomplete({
    key: 'cartItems',
    getUrl: cartItemsUrls.list,
    inputValue: cartItemTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.product_variant && r.details && r.details.product_variant
            ? r.details.product_variant.name
            : null,
      };
    },
  });

  const {
    data: deliveryMethods,
    isFetching: fetchingDeliveryMethods,
    refetch: refetchDelivery,
  } = useAutocomplete({
    key: 'deliveryMethods',
    getUrl: deliveryMethodsUrls.list,
    inputValue: deliveryMethodTerm,
  });

  const {
    data: deliveryStatuses,
    isFetching: fetchingDeliveryStatuses,
    refetch: refetchDeliveryStatuse,
  } = useAutocomplete({
    key: 'deliveryStatuses',
    getUrl: deliveryStatusesUrls.list,
    inputValue: deliveryStatusTerm,
  });

  const { data: productVariants, isFetching: productVariantsIsFetching } =
    useAutocomplete({
      key: 'productVariants',
      getUrl: productVariantsUrls.list,
      inputValue: productVariantTerm,
    });

  const { data: carts, isFetching: cartsIsFetching } = useAutocomplete({
    key: 'carts',
    getUrl: cartsUrls.list,
    inputValue: cartTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.customer && r.details && r.customer_details
            ? r.customer_details.first_name
            : r.customer,
      };
    },
  });

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      cartItemMeta({
        data,
        productVariants,
        productVariantsIsFetching,
        setProductVariantTerm,
        carts,
        cartsIsFetching,
        setCartTerm,
        deliveryStatuses,
        fetchingDeliveryStatuses,
        setDeliveryStatusTerm,
      }),
    [
      productVariants,
      productVariantsIsFetching,
      setProductVariantTerm,
      carts,
      cartsIsFetching,
      setCartTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
    ]
  );

  const itemDeliveriesColOptions = React.useMemo(() => {
    return {
      cartItems,
      fetchingCartItems,
      setCartItemTerm,
      deliveryMethods,
      fetchingDeliveryMethods,
      setDeliveryMethodTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
      addresses,
      fetchingAddresses,
      setAddressTerm,
    };
  }, [
    cartItems,
    fetchingCartItems,
    setCartItemTerm,
    deliveryMethods,
    fetchingDeliveryMethods,
    setDeliveryMethodTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
    addresses,
    fetchingAddresses,
    setAddressTerm,
  ]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/cart-items`, text: 'Cart Items' },
              {
                text: recordData?.details?.product_variant?.name,
              },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={cartItemsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice="E-Commerce"
          model="ItemDelivery"
          columnKey="itemDeliveryEcommerce"
          columnOptions={itemDeliveriesColOptions}
          label="Item Deliveries"
          editMode={editMode}
          labels={labels}
          prefix="0f2"
          urls={itemDeliveriesUrls}
          addNewForm={{
            getFields: () =>
              ItemDeliveryForm({
                cartItems,
                fetchingCartItems,
                setCartItemTerm,
                refetchItemCart,
                deliveryMethods,
                fetchingDeliveryMethods,
                setDeliveryMethodTerm,
                refetchDelivery,
                deliveryStatuses,
                fetchingDeliveryStatuses,
                setDeliveryStatusTerm,
                refetchDeliveryStatuse,
                addresses,
                fetchingAddresses,
                setAddressTerm,
                refetchDeliveryAddress,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: {
                    ...values,
                    cart_item: recordId,
                  },
                  url: itemDeliveriesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                // notify(`Item Delivery Created!`, {
                //   type: 'SUCCESS',
                // });
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={itemDeliveryRowMap}
          urlParams={`&cart_item=${recordId}`}
        />
      </Box>
    </Box>
  );
}
