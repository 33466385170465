import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import useToast from '@hooks/useToast';
import ContextFieldAccordion from '@components/BPA-V2/Popups/ChatGPTTextPopup/ContextFieldAccordion';
import PayloadSelector from '@components/BPA-V2/Popups/PayloadSelector';
import ContextFieldValue from '@components/BPA-V2/Popups/ChatGPTTextPopup/ContextFieldValue';
import {
  handleAccordionItemDelete,
  getContextFields,
} from '@config/functions/chatGPTTextPopupFunctions';
import { TextField } from '@components/Inputs';
import _ from 'lodash';

const cardStyles = {
  p: 2,
  borderRadius: '4px',
  backgroundColor: '#f7f7f7',
  border: '1px solid #c8c8c8',
};

export default function ContextFieldCard({
  index,
  setFieldValue,
  remove,
  workflowId,
  workflowName,
  showAddReminder,
  setShowAddReminder,
  shape,
  shapeAction,
  values,
  contextField,
  errors,
}) {
  const [notify] = useToast();
  const [isDeleting, setDeleting] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => setOpen(!open);
  const fields = getContextFields(index);
  const accordionLabel = values?.[fields?.name] || `Context Field ${index + 1}`;

  const handleIna = (moreNotes = '') =>
    setShowAddReminder({
      ...showAddReminder,
      initialNotes: `Ref: Workflows > ${workflowName} > ${shape?.name} > ${
        shapeAction?.name
      } > Context Fields > ${accordionLabel}${
        moreNotes ? ` > ${moreNotes}` : ''
      }\n\n`,
      open: true,
    });

  return (
    <ContextFieldAccordion
      open={open}
      setOpen={handleToggle}
      isError={errors?.context_fields?.[index] ?? false}
      label={accordionLabel}
      endContent={() => (
        <Button
          variant="text"
          color="error"
          size="small"
          disabled={isDeleting}
          onClick={() => {
            handleAccordionItemDelete({
              setDeleting,
              notify,
              remove,
              index,
            });
          }}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </Button>
      )}
    >
      <Stack spacing={2} sx={cardStyles}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Name"
                name={fields?.name}
                multiline
                required
                afterDefaultOnChange={(e) => {
                  const newContextFields = [...values.context_fields];
                  newContextFields[index][fields?.name] = e.target.value ?? '';
                  newContextFields[index][fields?.key] = _.camelCase(
                    e.target.value ?? ''
                  );
                  setFieldValue(fields?.key, _.camelCase(e.target.value ?? ''));
                  setFieldValue('context_fields', newContextFields);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Key"
                name={fields?.key}
                multiline
                required
                disabled
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <PayloadSelector
            label="Payload Selector"
            workflowId={workflowId}
            payloadSectionName="contextFieldPayloadSection"
            payloadSectionFieldName="contextFieldPayloadSectionKey"
          />
        </Box>

        <ContextFieldValue
          values={values}
          contextField={contextField}
          contextFieldIdx={index}
          setFieldValue={setFieldValue}
          handleIna={handleIna}
        />
      </Stack>
    </ContextFieldAccordion>
  );
}
