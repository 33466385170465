import React from 'react';
import * as Yup from 'yup';
import { inventoryUrls } from '@config/routes';
import { manufacturerMeta } from '@config/meta/inventory';
import { Grid } from '@mui/material';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { crmUrls } from '@config/routes';
import CompanyDropdown from '@dropdown-forms/crm/CompanyDropdown';
import { AutocompleteFieldV2 } from '@components/Inputs';
import { useQueryClient } from 'react-query';

const { manufacturersUrls } = inventoryUrls;
const { companiesUrls } = crmUrls;

export default function ManufacturerDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="Manufacturer"
        initialValues={{
          ...getAddFormInitialValues(manufacturerMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(manufacturerMeta(), 'listView'),
        })}
        getPostUrl={() => manufacturersUrls.list()}
        getValues={({values, manufacturer_company}) => ({
          ...values,
          manufacturer_company: manufacturer_company?.id,
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <CompanyDropdown
                refetch={({ id, name }) => {
                  setFieldValue('manufacturer_company', { id, label: name });
                  queryClient.invalidateQueries(['manufacturer-companies']);
                }}
              >
                <AutocompleteFieldV2
                  name='manufacturer_company'
                  label='Company'
                  requestKey='manufacturer-companies'
                  fetchUrl={companiesUrls.list}
                  urlParams={`&ordering=created_at`}
                  required
                />
              </CompanyDropdown>
            </Grid>
          </Grid>
        )}
        open={open}
        setOpen={setOpen}
        refetch={refetch}
      />
    </>
  );
}
