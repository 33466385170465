import { microservices } from '@config/constants';

import PersonDropdown from './crm/PersonDropdown';
import CompanyDropdown from './crm/CompanyDropdown';
import CompanyContactDropdown from './crm/CompanyContactDropdown';
import SocialDropdown from './crm/SocialDropdown';
import PipelineDropdown from './crm/PipelineDropdown';
import OpportunityDropdown from './crm/OpportunityDropdown';
import BuyerInfluenceDropdown from './crm/BuyerInfluenceDropdown';
import CallListPipelineStageDropdown from './crm/CallListPipelineStageDropdown';
import CallListPipelinesDropdown from './crm/CallListPipelinesDropdown';
import PipelineStagesDropdown from './crm/PipelineStagesDropdown';
import ChannelDropdown from './crm/ChannelDropdown';


import DepartmentDropdown from './hr/DepartmentDropdown';
import EmployeeDropdown from './hr/EmployeeDropdown';
import JobLevelDropdown from './hr/JobLevelDropdown';
import RecruitmentPipelineDropdown from './hr/RecruitmentPipelineDropdown';
import RecruitmentPipelineStageDropdown from './hr/RecruitmentPipelineStageDropdown';
import SkillLevelDropdown from './hr/SkillLevelDropdown';
import EmployeeStatusDropdown from './hr/EmployeeStatusDropdown';

import HLRDropdown from './pm/HLRDropdown';
import PersonaDropdown from './pm/PersonaDropdown';
import ProgramDropdown from './pm/ProgramDropdown';
import ProjectDropdown from './pm/ProjectDropdown';
import StatusDropdown from './pm/StatusDropdown';
import TaskDropdown from './pm/TaskDropdown';
import TaskTypeDropdown from './pm/TaskTypeDropdown';
import TemplateDropdown from './pm/TemplateDropdown';
import ResourcesDropdown from './pm/ResourceDropdown';
import ProductTypeDropdown from './inventory/ProductTypeDropdown';
import ManufacturerDropdown from './inventory/ManufacturerDropdown';
import ProductDropdown from './inventory/ProductDropdown';
import WarehouseDropdown from './inventory/WarehouseDropdown';
import CityDropdown from './list/CityDropdown';
import CurrencyDropdown from './list/CurrencyDropdown';

import ImapProviderDropdown from './communication/ImapProviderDropdown';
import LabelDropdown from './communication/LabelDropdown';

import CountryDropdown from './list/CountryDropdown';

import CategoryDropdown from './wiki/CategoryDropdown';
import ChapterDropdown from './wiki/ChapterDropdown';
import BookDropdown from './wiki/BookDropdown';

import InstructorDropdown from './lms/InstructorDropdown';
import HomeworkDropdown from './lms/HomeworkDropdown';
import CourseDropdown from './lms/CourseDropdown';
import CourseCategoryDropdown from './lms/CategoryDropdown';

import QuestionOptionGroupDropdown from './form/QuestionOptionGroupDropdown';
import QuestionDropdown from './form/QuestionDropdown';
import GroupDropdown from './form/GroupDropdown';
import DomainDropdown from './cms/DomainDropdown';
import PageDropdown from './cms/PageDropdown';
import PageTemplateDropdown from './cms/PageTemplateDropdown';


export default function getDropdownForm({ model, microservice }) {
  const msCRM = microservice === microservices?.CRM?.name;
  const msHR = microservice === microservices?.HR?.name;
  const msPM = microservice === microservices?.PM?.name;
  const msNOTIFICATION = microservice === microservices?.NOTIFICATION?.name;
  const msList = microservice === microservices?.LIST?.name;
  const msInventory = microservice === microservices?.INVENTORY?.name;
  const msWIKI = microservice === microservices?.WIKI?.name;
  const msLMS = microservice === microservices?.LMS?.name;
  const msForms = microservice === microservices?.Forms?.name;
  const msCMS = microservice === microservices?.CMS?.name;
  const isModal = (name) => model === name;

  // CRM
  if (msCRM && isModal(microservices?.CRM?.models?.persons))
    return PersonDropdown;
  if (msCRM && isModal(microservices?.CRM?.models?.companies))
    return CompanyDropdown;
  if (msCRM && isModal(microservices?.CRM?.models?.companyContacts))
    return CompanyContactDropdown;
  if (msCRM && isModal(microservices?.CRM?.models?.socialMediaTypes))
    return SocialDropdown;
  if (msCRM && isModal(microservices?.CRM?.models?.pipeline))
    return PipelineDropdown;
  if (msCRM && isModal(microservices?.CRM?.models?.opportunities))
    return OpportunityDropdown;
  if (msCRM && isModal(microservices?.CRM?.models?.companySPIN))
    return BuyerInfluenceDropdown;
  if (msCRM && isModal(microservices?.CRM?.models?.callListPipelineStage))
    return CallListPipelineStageDropdown;
  if (msCRM && isModal(microservices?.CRM?.models?.callListPipeline))
    return CallListPipelinesDropdown;
  if (msCRM && isModal(microservices?.CRM?.models?.pipelineStage))
    return PipelineStagesDropdown;
  if (msCRM && isModal(microservices?.CRM?.models?.channel))
    return ChannelDropdown;

  // HR
  if (msHR && isModal(microservices?.HR?.models?.department))
    return DepartmentDropdown;
  if (msHR && isModal(microservices?.HR?.models?.employee))
    return EmployeeDropdown;
  if (msHR && isModal(microservices?.HR?.models?.jobLevels))
    return JobLevelDropdown;
  if (msHR && isModal(microservices?.HR?.models?.recruitmentPipeline))
    return RecruitmentPipelineDropdown;
  if (msHR && isModal(microservices?.HR?.models?.skillLevel))
    return SkillLevelDropdown;
  if (msHR && isModal(microservices?.HR?.models?.recruitmentPipelineStage))
    return RecruitmentPipelineStageDropdown;
  if (msHR && isModal(microservices?.HR?.models?.employeeStatus))
    return EmployeeStatusDropdown;

  // PM
  if (msPM && isModal(microservices?.PM?.models?.hlrs)) return HLRDropdown;
  if (msPM && isModal(microservices?.PM?.models?.personas))
    return PersonaDropdown;
  if (msPM && isModal(microservices?.PM?.models?.programs))
    return ProgramDropdown;
  if (msPM && isModal(microservices?.PM?.models?.projects))
    return ProjectDropdown;
  if (msPM && isModal(microservices?.PM?.models?.taskStatus))
    return StatusDropdown;
  if (msPM && isModal(microservices?.PM?.models?.task)) return TaskDropdown;
  if (msPM && isModal(microservices?.PM?.models?.taskTypes))
    return TaskTypeDropdown;
  if (msPM && isModal('Template')) return TemplateDropdown;
  if (msPM && isModal(microservices?.PM?.models?.resources))
    return ResourcesDropdown;

  // List
  if (msList && isModal(microservices?.LIST?.models?.country))
    return CountryDropdown;
  if (msList && isModal(microservices?.LIST?.models?.cities))
    return CityDropdown;
  if (msList && isModal(microservices?.LIST?.models?.currency))
    return CurrencyDropdown;

  // NOTIFICATION
  if (
    msNOTIFICATION &&
    isModal(microservices?.NOTIFICATION?.models?.imapProvider)
  )
    return ImapProviderDropdown;
  if (msNOTIFICATION && isModal(microservices?.NOTIFICATION?.models?.label))
    return LabelDropdown;

  // INVENTORY
  if (msInventory && isModal(microservices?.INVENTORY?.models?.productType))
    return ProductTypeDropdown;
  if (msInventory && isModal(microservices?.INVENTORY?.models?.manufacturer))
    return ManufacturerDropdown;
  if (msInventory && isModal(microservices?.INVENTORY?.models?.product))
    return ProductDropdown;
  if (msInventory && isModal(microservices?.INVENTORY?.models?.warehouse))
    return WarehouseDropdown;

  // WIKI

  if (msWIKI && isModal(microservices?.WIKI?.models?.bookCategory))
    return CategoryDropdown;
  if (msWIKI && isModal(microservices?.WIKI?.models?.chapter))
    return ChapterDropdown;
  if (msWIKI && isModal(microservices?.WIKI?.models?.books))
    return BookDropdown;


  // LMS
  if (msLMS && isModal(microservices?.LMS?.models?.instructor))
  return InstructorDropdown;
  if (msLMS && isModal(microservices?.LMS?.models?.homework))
  return HomeworkDropdown;
  if (msLMS && isModal(microservices?.LMS?.models?.course))
  return CourseDropdown;
  if (msLMS && isModal(microservices?.LMS?.models?.category))
  return CourseCategoryDropdown;


  // Forms
  if (msForms && isModal(microservices?.Forms?.models?.questionOptionGroup))
  return QuestionOptionGroupDropdown;
  if (msForms && isModal(microservices?.Forms?.models?.question))
  return QuestionDropdown;
  if (msForms && isModal(microservices?.Forms?.models?.group))
  return GroupDropdown;

  // CMS
  if (msCMS && isModal(microservices?.CMS?.models?.domain))
  return DomainDropdown;
  if (msCMS && isModal(microservices?.CMS?.models?.pages))
  return PageDropdown;
  if (msCMS && isModal(microservices?.CMS?.models?.pageTemplate))
  return PageTemplateDropdown;

}
