import React from 'react';
import * as Yup from 'yup';
import { formsUrls } from '@config/routes';
import { groupMeta } from '@config/meta/forms/formsMeta';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { useQueryClient } from 'react-query';
import FormDropdown from '@dropdown-forms/form/FormDropdown';

const { groupsUrls, formsListUrls } = formsUrls;

export default function GroupDropdown({
  children,
  refetch,
  form: initialForm,
  title,
}) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title={title || 'Group'}
        initialValues={{
          ...getAddFormInitialValues(groupMeta(), 'DetailAccordionView'),
          formId: initialForm,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(groupMeta(), 'DetailAccordionView'),
        })}
        getPostUrl={() => groupsUrls.list()}
        getValues={({ formId, parentGroup, ...values }) => ({
          ...values,
          parentGroupId: parentGroup?.id,
          formId: formId?.id,
        })}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField label='Order' name='order' required />
            </Grid>
            <Grid item xs={12}>
              <TextField key='name' label='Name' name='name' required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                key='description'
                label='Description'
                name='description'
              />
            </Grid>
            <Grid item xs={12}>
              <GroupDropdown
                title='Parent Group'
                form={initialForm}
                refetch={({ id, name }) => {
                  setFieldValue('parentGroup', { id, label: name });
                  queryClient.invalidateQueries(['parentGroup-form']);
                }}
              >
                <AutocompleteFieldV2
                  name='parentGroup'
                  label='Parent Group'
                  requestKey='question-parent-groups'
                  fetchUrl={groupsUrls.list}
                  urlParams={initialForm ? `&formId=${initialForm.id}` : ''}
                />
              </GroupDropdown>
            </Grid>

            <Grid item xs={12}>
              <FormDropdown
                refetch={({ id, name }) => {
                  setFieldValue('formId', { id, label: name });
                  queryClient.invalidateQueries(['group-form']);
                }}
              >
                <AutocompleteFieldV2
                  name='formId'
                  label='Forms'
                  requestKey='group-progress-form'
                  fetchUrl={formsListUrls.list}
                  required
                />
              </FormDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
