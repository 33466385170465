import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function ConfgureAppDialog({ 
  open, 
  handleCancel, 
  handleConfigure, 
  labels,
  editMode,
  prefix 
}) {

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby='Connnect-App'
      >
        <DialogTitle id='Connnect-App'>
          {'Confgure Calendar App?'}
        </DialogTitle>
        <DialogContent>
          
          {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="You don't have a calendar app configured. Configure?"
          >
            {(text) => {
              return <DialogContentText id='connect-app-description'>{text}</DialogContentText>;
            }}
          </RenderUiPrefix>
        ) : (
          <DialogContentText id='connect-app-description'>
            You don't have a calendar app configured. Configure?
          </DialogContentText>
        )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfigure} autoFocus>
            Configure
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
