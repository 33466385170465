import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Drive from '@pages/Drive/MyDrive';

export default function () {
  return (
    <>
      <Route path='/drive' element={<WithLayout comp={Drive} />} />
      <Route path='/drive/:id' element={<WithLayout comp={Drive} />} />
    </>
  );
}
