import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import UIRepeatField from '@ui/Inputs/RepeatField';

export default function RepeatField({
  label,
  showDoesNotRepeat = true,
  ...rest
}) {
  const [field, meta] = useField(rest);
  const isError = Boolean(meta.error);
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <UIRepeatField
        field={field.name}
        label={label}
        showDoesNotRepeat={showDoesNotRepeat}
        rrule={field?.value}
        setCustomValue={(newRrule) => setFieldValue(field.name, newRrule)}
        error={isError}
        errorMessage={meta.error}
        {...rest}
      />
    </>
  );
}
