import React from 'react';
import * as Yup from 'yup';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Button,
  Grid,
  MenuItem,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  bpaUrls,
  systemUrls,
  communicationUrls,
  formsUrls,
  nodeDriveUrls,
  eDocUrls,
} from '@config/routes';
import ListView from '@components/ListView';
import { assetsPopupRowsMap } from '@config/handleRows/bpa';
import AddIcon from '@mui/icons-material/Add';
import { Formik, Form } from 'formik';
import {
  SelectField,
  TextField,
  AutocompleteFieldV2,
  CheckBoxField,
  UploadField,
} from '@components/Inputs';
import {
  AUTOMATA_ASSET_TYPES,
  formDisplayMode,
  formCategoryOptions,
  formAccessLevels,
} from '@config/constants';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import { formsMeta } from '@config/meta/forms/formsMeta';
import { emailTemplateMeta } from '@config/meta/communication';
import { documentMeta } from '@config/meta/eDoc';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';

const { modelAssetsUrls } = bpaUrls;
const { imapAccountsUrls, mailTemplatesUrls } = communicationUrls;
const { templatesUrls } = systemUrls;
const { formsListUrls } = formsUrls;
const { filesUrls } = nodeDriveUrls;
const { documentsUrls } = eDocUrls;

const getSchema = (type) => {
  if (type === 'MailTemplate') {
    return emailTemplateMeta({ onListView: true });
  }

  if (type === 'Forms') {
    return formsMeta();
  }

  if (type === 'eDoc') {
    return documentMeta();
  }
};

const getMicroservice = (type) => {
  return type === 'MailTemplate' ? 'Notification' : type;
};

const getModel = (type) => {
  switch (type) {
    case 'MailTemplate':
      return 'MailTemplate';
    case 'Forms':
      return 'form';
    case 'eDoc':
      return 'document';
    default:
      return '';
  }
};

export default function ShapeDocsPopup({ open, setOpen, shapeId, workflowId }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [showCreateForm, setShowCreateForm] = React.useState(false);

  const handleClose = () => setOpen(false);

  const handleFormsSubmission = React.useCallback(async (data) => {
    const { isPublished, anonymous_can_see_it } = data;

    try {
      const response = await createRecord({
        values: {
          ...data,
          anonymous_can_see_it: isPublished || anonymous_can_see_it,
        },
        url: formsListUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });

      return response?.data?.id;
    } catch (error) {
      console.log(error?.response?.data ?? error?.message);
    }
  }, []);

  const uploadDocument = React.useCallback(async (document) => {
    const formData = new FormData();
    formData.append('anonymous_can_see_it', 'true');
    formData.append('file', document);

    const response = await createRecord({
      values: formData,
      url: filesUrls.list(),
      token: user.token,
      actAs: user?.actAs,
    });

    return response?.data?.fileUrl;
  }, []);

  const handleEDocSubmission = React.useCallback(
    async (data) => {
      const { document, ...remainingValues } = data;

      let documentUrl = null;

      console.log('document', document);

      if (document?.name) {
        documentUrl = await uploadDocument(document);
      }

      if (!documentUrl) {
        notify(
          'Failed to upload the document. Please refresh the page to retry',
          { type: 'ERROR' }
        );
        return null;
      }

      const response = await createRecord({
        values: {
          ...remainingValues,
          documentUrl,
          anonymous_can_see_it: data.isPublished,
        },
        url: documentsUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });

      return response?.data?.id;
    },
    [uploadDocument]
  );

  const handleMailTemplateSubmission = React.useCallback(
    async (data, setFieldError) => {
      const { from_email, ...mailTemplateValues } = data;

      try {
        const response = await createRecord({
          values: {
            ...mailTemplateValues,
            html: '<h1>An Example Email</h1>',
            from_email: from_email?.id,
          },
          url: mailTemplatesUrls.list(),
          token: user.token,
          actAs: user?.actAs,
          encryptionToken: user.encryptionToken,
        });

        return response?.data?.id;
      } catch (error) {
        if (error.response?.data) {
          Object.keys(error.response.data).forEach((key) => {
            setFieldError(key, error.response.data[key]);
          });
        }

        return null;
      }
    },
    []
  );

  return (
    <Dialog fullWidth maxWidth='md' open={open}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>
          {showCreateForm ? 'Add Assets' : 'Assets'}
        </DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ pt: '4px' }}>
        {showCreateForm ? (
          <Formik
            initialValues={{
              type: 'MailTemplate',
            }}
            onSubmit={async (formData, { setSubmitting, setFieldError }) => {
              setSubmitting(true);
              const { type, ...otherValues } = formData;
              const { document } = otherValues;

              const isNodeAPI = ['Forms', 'eDoc'].includes(type);
              const columns = getSchema(type);

              const validationSchema = Yup.object({
                ...getSchemaFromColumnsForForm(columns),
                everyone_can_see_it: Yup.bool().nullable(),
                anonymous_can_see_it: Yup.bool().nullable(),
                everyone_in_object_company_can_see_it: Yup.bool().nullable(),
                only_these_roles_can_see_it: isNodeAPI
                  ? Yup.array().default([]).nullable()
                  : Yup.object().nullable(),
                only_these_users_can_see_it: isNodeAPI
                  ? Yup.array().default([]).nullable()
                  : Yup.object().nullable(),
                only_these_clients_can_see_it: isNodeAPI
                  ? Yup.array().default([]).nullable()
                  : Yup.object().nullable(),
              });

              if (
                type === 'MailTemplate' &&
                (!otherValues.subject?.length ||
                  otherValues.subject?.length > 150)
              ) {
                setFieldError(
                  'subject',
                  'Subject is required and must be 150 characters long at most'
                );
                return;
              }

              if (type === 'eDoc' && !document?.name) {
                setFieldError('document', 'Please select a document');
                return;
              }

              try {
                const validatedData = await validationSchema.validate(
                  otherValues
                );

                let recordId;

                switch (type) {
                  case 'Forms':
                    recordId = await handleFormsSubmission(validatedData);
                    break;

                  case 'eDoc':
                    recordId = await handleEDocSubmission(validatedData);
                    break;

                  case 'MailTemplate':
                    recordId = await handleMailTemplateSubmission(
                      validatedData,
                      setFieldError
                    );
                    break;
                  default:
                    throw new Error('Unknown type');
                }

                if (!recordId) {
                  return;
                }

                await createRecord({
                  values: {
                    workflow: workflowId,
                    stages: [],
                    shapes: [shapeId],
                    asset_id: recordId,
                    microservice: getMicroservice(type),
                    model: getModel(type),
                  },
                  url: modelAssetsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                setShowCreateForm(false);
              } catch (error) {
                if (error?.path) {
                  setFieldError(error.path, error.message);
                }
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ values, errors, isSubmitting, setValues }) => (
              <Form noValidate>
                {console.log('errors', errors)}
                <Grid container sx={{ py: 1 }} spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ width: '360px' }}>
                      <SelectField
                        label='Type'
                        name='type'
                        required
                        customSetValue={(e) => {
                          setValues({ type: e.target.value.trim() });
                        }}
                      >
                        {AUTOMATA_ASSET_TYPES.map((r) => (
                          <MenuItem value={r.value} key={r.value}>
                            {r.label}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Box>
                  </Grid>

                  {values?.type === 'eDoc' ? (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          label='Name'
                          name='name'
                          multiline
                          required
                        />
                      </Grid>

                      <Grid item sm={6}>
                        <AutocompleteFieldV2
                          name='template'
                          label='Template To Copy From'
                          requestKey='forms-templates'
                          fetchUrl={templatesUrls.list}
                          urlParams={`&ordering=created_at&microservice_name=eDoc&model_name=document`}
                          renderRow={(row) => ({
                            id: row?.id,
                            label: row?.name,
                          })}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <UploadField
                          name='document'
                          required
                          accept={{ 'application/pdf': ['.pdf', '.PDF'] }}
                        />
                      </Grid>

                      <Grid item sm={2}>
                        <CheckBoxField label='Publish' name='isPublished' />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label='Description'
                          name='description'
                          multiline
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField label='Tags' name='tags' multiline />
                      </Grid>
                    </>
                  ) : null}

                  {values?.type === 'Forms' ? (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          label='Name'
                          name='name'
                          multiline
                          required
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label='Title'
                          name='title'
                          multiline
                          required
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <SelectField label='Category' name='category'>
                          {formCategoryOptions.map((r) => (
                            <MenuItem value={r.value} key={r.value}>
                              {r.label}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>

                      <Grid item xs={6}>
                        <SelectField label='Display mode' name='displayMode'>
                          {formDisplayMode.map((r) => (
                            <MenuItem value={r.value} key={r.value}>
                              {r.label}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>

                      <Grid item sm={6}>
                        <AutocompleteFieldV2
                          name='template'
                          label='Template To Copy From'
                          requestKey='forms-templates'
                          fetchUrl={templatesUrls.list}
                          urlParams='&ordering=created_at&microservice_name=Forms&model_name=form'
                          renderRow={(row) => ({
                            id: row?.id,
                            label: row?.name,
                          })}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label='Closing message'
                          name='closingMessage'
                          multiline
                        />
                      </Grid>

                      <Grid item sm={2}>
                        <CheckBoxField label='Publish' name='isPublished' />
                      </Grid>

                      <Grid item xs={4}>
                        <SelectField label='Access level' name='accessLevel'>
                          {formAccessLevels.map((r) => (
                            <MenuItem value={r.value} key={r.value}>
                              {r.label}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField label='Tags' name='tags' multiline />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label='Description'
                          name='description'
                          multiline
                          minRows={2}
                        />
                      </Grid>
                    </>
                  ) : null}

                  {values?.type === 'MailTemplate' ? (
                    <>
                      <Grid item xs={6}>
                        <TextField label='Name' name='name' required />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField label='Subject' name='subject' required />
                      </Grid>
                      <Grid item sm={6}>
                        <AutocompleteFieldV2
                          name='template'
                          label='Template To Copy From'
                          requestKey='mail-template-template'
                          fetchUrl={templatesUrls.list}
                          urlParams='&ordering=created_at&microservice_name=Notification&model_name=MailTemplate'
                          renderRow={(row) => ({
                            id: row?.id,
                            label: row?.name,
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AutocompleteFieldV2
                          name='from_email'
                          label='From Email'
                          requestKey='comms-imap-accounts'
                          fetchUrl={imapAccountsUrls.list}
                          urlParams={`&connected=true&smtp_connected=true&send_emails_from_this_account=true&ordering=created_at`}
                          renderRow={(row) => ({
                            id: row?.id,
                            label: row?.username,
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label='From Email Alias'
                          name='from_email_alias'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField label='Tags' name='_tags' />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label='Description'
                          name='description'
                          minRows={2}
                          multiline
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>

                <DialogActions>
                  <Button onClick={() => setShowCreateForm(false)}>
                    Cancel
                  </Button>
                  <Button type='submit' disabled={isSubmitting}>
                    {isSubmitting ? 'Saving...' : 'Save'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        ) : (
          <Box sx={{ pb: 5 }}>
            <Button
              startIcon={<AddIcon />}
              variant='outlined'
              sx={{ mb: 3 }}
              onClick={() => setShowCreateForm(true)}
            >
              Create
            </Button>

            <ListView
              noHeader
              urls={modelAssetsUrls}
              columnKey='assetsPopup'
              autoHeight='390px'
              handleRowsMap={assetsPopupRowsMap}
              customPageSize={8}
              urlParams={`&ordering=-created_at&shape=${shapeId}`}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
