import axios from 'axios';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { setToken } from '@redux/profileSlice';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { accountUrls } from '@config/routes';
import { getUserStorageKey } from '@config/constants';
import { parseJSON } from '@config/functions/helperFunctions';

const { tokenRefreshUrls } = accountUrls;
const userKey = getUserStorageKey();

export function refreshToken() {
  const { data: user } = parseJSON(localStorage.getItem(userKey));

  const decodedToken = jwtDecode(user.token);
  const currentTime = Date.now() / 1000;

  // if the token exists and is not expired
  if (decodedToken.exp > currentTime) return;

  console.log('obi-expired');

  return axios
    .post(
      tokenRefreshUrls.list(),
      {
        refresh: user.refreshToken,
      },
      getAuthHeader(user.token, user?.actAs)
    )
    .then((res) => res.data)
    .catch((error) => {
      console.log(error.response || error.message);
      localStorage.removeItem(userKey);
      console.log('obi-useAuthToken-redirect');
      window.location.href = '/auth/login';
    });
}

export default function useAuthToken() {
  const dispatch = useDispatch();
  const { data: user } = parseJSON(localStorage.getItem(userKey));

  return useQuery(['auth-token'], refreshToken, {
    enabled: !!(user && 'refreshToken' in user),
    // Refresh the token every 14 minutes
    refreshInterval: 14 * 60 * 1000,
    retry: 0,
    onSuccess: (data) => {
      if (!data?.access) return;

      // Save the new token
      localStorage.setItem(
        getUserStorageKey(),
        JSON.stringify({
          ...user,
          token: data.access,
        })
      );
      dispatch(setToken(data.access));
    },
    onError: (error) => {
      // Handle token refresh errors
      console.error(error);
    },
  });
}
