import { lmsCourseAccessLevel } from '@config/constants';

export const universityRowsMap = (row) => ({
  id: row.id,
  name: row.name,
  description: row.description,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const facultyRowsMap = (row) => ({
  id: row.id,
  name: row.name,
  description: row.description,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const facultyCourseRowsMap = (row) => ({
  id: row.id,
  facultyId: row.facultyId,
  courseId:
    row?.course && !row?.course?.deleted
      ? {
          id: row?.courseId,
          label: row.course?.name,
        }
      : null,
  createdAt: row.createdAt,
});

export const facultyInstructorRowsMap = (row) => ({
  id: row.id,
  facultyId: row.facultyId,
  instructorId:
    row?.instructor && !row?.instructor?.deleted
      ? {
          id: row?.instructorId,
          label: row?.instructor?.alias,
        }
      : null,
  createdAt: row.createdAt,
});

export const homeworkRowsMap = (row) => ({
  id: row.id,
  name: row.name,
  description: row.description,
  type: row.type,
  url: row.url,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const teamRowsMap = (row) => ({
  id: row.id,
  name: row.name,
  description: row.description,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const courseHandleRow = (row) => ({
  id: row.id,
  name: row.name,
  description: row.description,
  wikiBookId: row?.details?.wikiBookId
    ? {
        id: row?.wikiBookId,
        label: `${row?.details.wikiBookId?.name || ''}`,
      }
    : null,
  homeworkId:
    row?.homework && !row?.homework?.deleted
      ? {
          id: row.homeworkId,
          label: row.homework.name,
        }
      : null,
  categoryId:
    row?.category && !row?.category?.deleted
      ? {
          id: row.categoryId,
          label: row?.category?.name,
        }
      : null,
  thumbnail: row.thumbnail,
  isPublished: row.isPublished,
  accessLevel: lmsCourseAccessLevel.find(
    ({ value }) => value === row?.accessLevel
  )?.label,
  createdAt: row.createdAt,
});

export const lessonHandleRow = (row) => ({
  id: row.id,
  order: row.order,
  name: row.name,
  description: row.description,
  thumbnail: row.thumbnail,
  presentation: row.presentation,
  video: row.video,
  wikiSectionId: row?.details?.wikiSectionId
    ? {
        id: row.wikiSectionId,
        label: row?.details?.wikiSectionId?.title,
      }
    : null,
  homeworkId:
    row?.homework && !row?.homework?.deleted
      ? {
          id: row.homework.id,
          label: row.homework.name,
        }
      : null,
  createdAt: row.createdAt,
});

export const moduleHandleRow = (row) => ({
  id: row.id,
  order: row.order,
  name: row.name,
  description: row.description,
  thumbnail: row.thumbnail,
  homeworkId:
    row?.homework && !row?.homework?.deleted
      ? {
          id: row.homework.id,
          label: row.homework.name,
        }
      : null,
  createdAt: row.createdAt,
});

export const skillRowsMap = (row) => ({
  id: row.id,
  name: row.name,
  description: row.description,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const studentRowsMap = (row) => ({
  id: row.id,
  personId: row?.details?.personId
    ? {
        id: row?.personId,
        label: `${row?.details.personId?.email || ''}`,
      }
    : null,
  alias: row.alias,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const instructorRowsMap = (row) => ({
  id: row.id,
  personId: row?.details?.personId
    ? {
        id: row?.personId,
        label: `${row?.details.personId?.email || ''}`,
      }
    : null,
  alias: row.alias,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const coachRowsMap = (row) => ({
  id: row.id,
  personId: row?.details?.personId
    ? {
        id: row?.personId,
        label: `${row?.details.personId?.email || ''}`,
      }
    : null,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const mentorRowsMap = (row) => ({
  id: row.id,
  personId: row?.details?.personId
    ? {
        id: row?.personId,
        label: `${row?.details.personId?.email || ''}`,
      }
    : null,
  tags: row.tags,
  createdAt: row.createdAt,
});

export const courseModuleHandleRow = (row) => ({
  id: row.id,
  sequence: row.sequence,
  moduleId:
    row?.module && !row?.module?.deleted
      ? {
          id: row.module?.id,
          label: row.module?.name,
        }
      : null,
  wikiChapterId: row?.details?.wikiChapterId
    ? {
        id: row?.wikiChapterId,
        label: `${row?.details.wikiChapterId?.name || ''}`,
      }
    : null,
  isRequired: row.isRequired,
  createdAt: row.createdAt,
});

export const courseTeamHandleRow = (row) => ({
  id: row.id,
  teamId:
    row?.team && !row?.team?.deleted
      ? {
          id: row?.teamId,
          label: row.team?.name,
        }
      : null,
  createdAt: row.createdAt,
});

export const moduleLessonHandleRow = (row) => ({
  id: row.id,
  sequence: row.sequence,
  lessonId:
    row?.lesson && !row?.lesson?.deleted
      ? {
          id: row.lessonId,
          label: row.lesson.name,
        }
      : null,
  wikiSectionId:
    row?.wikiSectionId && !row?.wikiSectionId?.deleted
      ? {
          id: row.wikiSectionId,
          label: row?.details?.wikiSectionId?.title,
        }
      : null,
  isRequired: row.isRequired,
  createdAt: row.createdAt,
});

export const lessonProgressHandleRow = (row) => ({
  id: row.id,
  studentId:
    row?.student && !row?.student?.deleted
      ? {
          id: row.studentId,
          label: row.student?.alias,
        }
      : null,
  wikiSectionId: row?.details?.wikiSectionId
    ? {
        id: row.wikiSectionId,
        label: row?.details?.wikiSectionId?.title,
      }
    : null,
  presentationProgress: row.presentationProgress,
  videoProgress: row.videoProgress,
  createdAt: row.createdAt,
});

export const lessonInstructorRowsMap = (row) => ({
  id: row.id,
  instructorId:
    row?.instructor && !row?.instructor?.deleted
      ? {
          id: row.instructor?.id,
          label: row.instructor?.alias,
        }
      : null,
  createdAt: row.createdAt,
});

export const lessonCoachRowsMap = (row) => ({
  id: row.id,
  lessonId: row.lessonId,
  coachId:
    row?.coach && !row?.coach?.deleted
      ? {
          id: row?.coachId,
          label: row?.coach?.details?.personId?.email,
        }
      : null,
  createdAt: row.createdAt,
});

export const homeworkProgressRowsMap = (row) => ({
  id: row.id,
  homeworkProgress: row.homeworkProgress,
  studentId:
    row?.student && !row?.student?.deleted
      ? {
          id: row.studentId,
          label: row.student?.alias,
        }
      : null,
  createdAt: row.createdAt,
});

export const courseMentorRowsMap = (row) => ({
  id: row.id,
  mentorId:
    row?.mentor && !row?.mentor?.deleted
      ? {
          id: row?.mentorId,
          label: row?.mentor?.details?.personId?.email,
        }
      : null,
  createdAt: row.createdAt,
});

// export const studentMentorRowsMap = (row) => ({
//   id: row.id,
//   mentorId:
//     row?.mentor && !row?.mentor?.deleted
//       ? {
//           id: row?.mentorId,
//           label: row?.mentor?.details?.personId?.email,
//         }
//       : null,
//   createdAt: row.createdAt,
// });

export const teamMemberRowsMap = (row) => ({
  id: row.id,
  teamId: row.teamId,
  studentId:
    row?.student && !row?.student?.deleted
      ? {
          id: row.studentId,
          label: row.student?.alias,
        }
      : null,
  createdAt: row.createdAt,
});

export const lessonReferencesRowsMap = (row) => ({
  id: row?.id,
  attachmentURL: row?.attachmentURL,
  notes: row?.notes,
  createdAt: row?.createdAt,
});

export const categoryRowsMap = (row) => ({
  id: row.id,
  name: row.name,
  description: row.description,
  tags: row.tags,
  createdAt: row.createdAt,
});
