import React from 'react';
import _ from 'lodash';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Button,
  DialogActions,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form, FieldArray } from 'formik';
import { bpaUrls } from '@config/routes';

import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import axios from 'axios';
import { getAuthHeader } from '@config/functions/helperFunctions';
import FieldMappingPopupItem from '../FieldMappingPopupItem';

const { bulkCreateShapeActionParamsUrls, bulkGetShapeActionParamValuesUrls } =
  bpaUrls;

const parameterTypes = [
  'String',
  'Number',
  'Decimal',
  'Boolean',
  'List',
  'Dict',
  'Date',
  'DateTime',
  'Other',
];

export default function FieldMappingPopup({
  open,
  setOpen,
  parameter,
  workflowId,
  shapeAction,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [payloadSectionKeys, setPayloadSectionKeys] = React.useState({});
  const [sectionKeysInit, setSectionKeysInit] = React.useState(false);
  const [paramValues, setParamValues] = React.useState(null);
  const [fetchingParamValues, setFetchingParamValues] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  // CPD = Capture data
  const isCPDPopup =
    shapeAction?.details?.action?.function_name === 'capture_data';

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const url = bulkGetShapeActionParamValuesUrls.list(
          `?shape_action=${shapeAction?.id}&action_param=${parameter?.id}`
        );
        const config = {
          ...getAuthHeader(user.token, user?.actAs),
        };

        setFetchingParamValues(true);
        const response = await axios.get(url, config);
        setParamValues(response.data);
        setFetchingParamValues(false);
        setIsError(false);
      } catch (error) {
        setFetchingParamValues(false);
        setIsError(true);
      }
    };

    if (user.token && open && parameter?.id && shapeAction?.id) {
      fetchData();
    }

    return () => {
      source.cancel('Request canceled'); // Cleanup: Cancel ongoing request when component unmounts
    };
  }, [user, open, parameter?.id]);

  const renderPayloadSections = (row) => {
    const { id, name, keys } = row;

    return {
      id,
      label: name,
      sectionKeys: keys,
    };
  };

  const mapFields = (paramValues, parameter) => {
    if (paramValues && paramValues.length) {
      return paramValues.map((value) => {
        const payloadSection = value?.mapping_field_name_details
          ?.payload_section_name
          ? {
              id: value.mapping_field_name_details.payload_section,
              label: value.mapping_field_name_details.payload_section_name,
            }
          : value.mapping_field_name_details.payload_section || null;

        const payloadSectionKey = value.static_value
          ? null
          : value.mapping_field_name;

        const key = value.key || '';
        const label = value.label || '';
        const type = value.type || parameter.type;

        function extractCustomValue(value) {
          const msAndModelExist = value?.microservice && value?.model;
          const labelExist = value?.label;

          if (msAndModelExist && labelExist) {
            return { id: value?.model_asset, label: value.label };
          }

          if (value?.list_url && labelExist) {
            return { id: value?.static_value, label: value.label };
          }

          if (value?.static_value === 'false') {
            return false;
          }

          return value?.static_value ?? '';
        }

        const customValue = extractCustomValue(value);

        return {
          type,
          key,
          label,
          id: value?.id,
          payloadSection,
          payloadSectionKey,
          customValue,
        };
      });
    } else {
      return [
        {
          payloadSection: null,
          payloadSectionKey: null,
          type: parameterTypes[0],
          key: '',
          label: '',
          customValue: '',
        },
      ];
    }
  };

  const handleClose = () => {
    setPayloadSectionKeys({});
    setOpen(false);
  };

  const renderPayloadKeyOptions = (index, payloadSectionKeys) => {
    const sectionKey = `mappedFields.${index}.payloadSection`;
    if (sectionKey in payloadSectionKeys) {
      return payloadSectionKeys[sectionKey].map(({ key, label }) => (
        <option key={key} value={key}>
          {label}
        </option>
      ));
    }
    return null;
  };

  const formatLabel = (label) => {
    // Check if the label is in camelCase. We consider a label in camelCase if it starts with a lowercase
    // letter and has no spaces or special characters, but may have uppercase letters for subsequent words.
    const isCamelCase = /^[a-z][a-zA-Z0-9]*$/.test(label);

    if (isCamelCase) {
      // If it's already in camelCase, return it as is.
      return label;
    } else {
      // If it's not in camelCase, remove all special characters and replace spaces with underscores.
      return label
        .replace(/[^a-z0-9\s]/gi, '') // Remove all special characters, making it case-insensitive
        .replace(/\s+/g, '_'); // Replace one or more spaces with a single underscore
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => null}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>
          Map {parameter.name} fields
        </DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        {isError ? (
          <Typography>Failed to fetch data...</Typography>
        ) : fetchingParamValues || !paramValues ? (
          <Typography>loading...</Typography>
        ) : (
          <Formik
            initialValues={{
              mappedFields: mapFields(paramValues, parameter),
            }}
            validationSchema={Yup.object().shape({
              mappedFields: Yup.array().of(
                Yup.object().shape({
                  payloadSection: Yup.mixed().nullable(),
                  payloadSectionKey: Yup.mixed().nullable(),
                  type: Yup.string().required('Type is required'),
                  label: Yup.string(),
                  customValue: Yup.mixed().nullable(),
                })
              ),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setSubmitting(true);

                await createRecord({
                  values: {
                    shape_action_parameters: values.mappedFields.map(
                      ({ id, label, type, customValue, payloadSectionKey }) => {
                        const customValueCombined = _.isObject(customValue)
                          ? customValue?.id
                          : customValue ?? undefined;

                        return {
                          id: id || undefined,
                          type,
                          key: formatLabel(label),
                          label: _.isObject(customValue)
                            ? customValue.label
                            : label,
                          model_asset:
                            parameter?.microservice && parameter?.model
                              ? customValueCombined
                              : null, // custom value
                          static_value: String(customValueCombined), // custom value
                          action_param: parameter.id, // shape action param that we are editing ex (Mail Template)
                          shape_action: shapeAction?.id, // current shape action
                          mapping_field_name: customValue
                            ? null
                            : payloadSectionKey, // payload section key if custom value isn't set
                        };
                      }
                    ),
                  },
                  url: bulkCreateShapeActionParamsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                notify('Operation completed', {
                  type: 'SUCCESS',
                });
                setOpen(false);
              } catch (err) {
                console.log(err?.response?.data ?? err?.message);
                notify('There was an error, please try again', {
                  type: 'ERROR',
                });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form noValidate>
                <FieldArray name="mappedFields">
                  {({ remove, push }) => (
                    <Box>
                      <Button
                        variant="outlined"
                        sx={{ mb: '22px' }}
                        onClick={() =>
                          push({
                            payloadSection: null,
                            payloadSectionKey: null,
                            type: parameterTypes[0],
                            customValue: '',
                            label: '',
                          })
                        }
                      >
                        Add a new 1:1
                      </Button>

                      {values.mappedFields.map((mappedField, index) => {
                        const msAndModelExist = !!(
                          parameter?.microservice && parameter?.model
                        );

                        return (
                          <FieldMappingPopupItem
                            key={index}
                            index={index}
                            isCPDPopup={isCPDPopup}
                            workflowId={workflowId}
                            renderPayloadSections={renderPayloadSections}
                            setPayloadSectionKeys={setPayloadSectionKeys}
                            mappedField={mappedField}
                            sectionKeysInit={sectionKeysInit}
                            setSectionKeysInit={setSectionKeysInit}
                            parameter={parameter}
                            renderPayloadKeyOptions={renderPayloadKeyOptions}
                            payloadSectionKeys={payloadSectionKeys}
                            remove={remove}
                            user={user}
                            msAndModelExist={msAndModelExist}
                            setFieldValue={setFieldValue}
                          />
                        );
                      })}
                    </Box>
                  )}
                </FieldArray>

                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit" disabled={isSubmitting}>
                    Save
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
}
