import { CRITERIA_OPERATOR_CHOICES } from '@config/constants';

export const smPostRowsMap = (r) => ({
  ...r,
});

export const marketSegmentRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  createdAt: row?.createdAt,
});

export const criteriaRowsMap = (row) => ({
  id: row?.id,
  criteria: row?.criteria,
  order: row?.order,
  operator: CRITERIA_OPERATOR_CHOICES.find(
    ({ value }) => value === row?.operator
  )?.label,
  connector: row?.connector,
  value: row?.value,
  createdAt: row?.createdAt,
});

export const campaignRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  stagger_between_smaccounts: r.stagger_between_smaccounts,
  stagger_unit: r.stagger_unit,
  created_at: r.created_at,
});

export const landingRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  slug: r.slug,
  version: r.version,
  seo_title: r.seo_title,
  template:
    r.details && r.details.template
      ? { id: r.template, label: r.details.template.name }
      : null,
});

export const generateRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  keyword: r.keyword,
  slug: r.slug,
  description: r.description,
  title: r.title,
  city_criteria: r.city_criteria,
});

export const audienceRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
});

export const lpAudienceRowsMap = (r) => ({
  id: r.id,
  audience:
    r.audience_details && r.audience_details.name
      ? { id: r.audience, label: r.audience_details.name }
      : null,
});

export const keywordRowsMap = (r) => ({
  id: r.id,
  keyword: r.keyword,
  intent: r.intent,
  type: r.type,
});

export const lpKeywordRowsMap = (r) => ({
  id: r.id,
  keyword:
    r.keyword_details && r.keyword_details.keyword
      ? { id: r.keyword, label: r.keyword_details.keyword }
      : null,
});

export const socialMediaRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  max_post_length: r.max_post_length,
});

export const clientSMAccessRowsMap = (r) => ({
  id: r.id,
  client:
    r.client && r.details && r.details.client
      ? { id: r.client, label: r.details.client.name }
      : r.client,
  api: r.api,
  secret: r.secret,
});

export const userSMAccessRowsMap = (r) => ({
  id: r.id,
  user:
    r.details && r.details.user && r.details.user.user
      ? { id: r.user, label: r.details.user.user.email }
      : r.user,
  access_token: r.access_token,
  token_expiry: r.token_expiry,
  page_id: r.page_id,
  page_name: r.page_name,
  refresh_token: r.refresh_token,
  private: r.private,
});

export const postViaRowsMap = (r) => ({
  id: r.id,
  user_token:
    r.details && r.details.user_token && r.details.user_token
      ? { id: r.user_token, label: r.details.user_token.access_token }
      : r.user_token,
});

export const postViaScheduleRowsMap = (r) => ({
  id: r.id,
  timing: r.timing ? new Date(r.timing) : null,
});
