import { inventoryHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  productTypes: 'product-types',
  manufacturerContacts: 'manufacturer-contacts',
  manufacturers: 'manufacturers',
  products: 'products',
  productVariants: 'product-variants',
  supplierContacts: 'supplier-contacts',
  suppliers: 'suppliers',
  variantKeys: 'variant-keys',
  variantValues: 'variant-values',
  productSuppliers: 'product-suppliers',
  warehouses: 'warehouses',
  warehouseInventorys: 'warehouse-inventorys',
  deliveries: 'deliverys',
  deliveryItems: 'delivery-items',
  deliveryArtifacts: 'delivery-artifacts',
  productArtifacts: 'product-artifacts',
  productAdditionalInfos: 'product-additional-infos',
  inventorys: 'inventorys',
  inventoryTransactions: 'inventory-transactions',
  medias: 'medias',
  import: 'import',
  export: 'export',
};

const importUrls = getModelUrls(inventoryHost, models.import);
const exportUrls = getModelUrls(inventoryHost, models.export);

const productTypesUrls = getModelUrls(inventoryHost, models.productTypes);

const manufacturerContactsUrls = getModelUrls(
  inventoryHost,
  models.manufacturerContacts
);
const manufacturersUrls = getModelUrls(inventoryHost, models.manufacturers);
const productsUrls = getModelUrls(inventoryHost, models.products);
const productVariantsUrls = getModelUrls(inventoryHost, models.productVariants);

const supplierContactsUrls = getModelUrls(
  inventoryHost,
  models.supplierContacts
);
const suppliersUrls = getModelUrls(inventoryHost, models.suppliers);
const variantKeysUrls = getModelUrls(inventoryHost, models.variantKeys);
const variantValuesUrls = getModelUrls(inventoryHost, models.variantValues);
const productSuppliersUrls = getModelUrls(
  inventoryHost,
  models.productSuppliers
);
const warehousesUrls = getModelUrls(inventoryHost, models.warehouses);
const warehouseInventorysUrls = getModelUrls(
  inventoryHost,
  models.warehouseInventorys
);
const deliveriesUrls = getModelUrls(inventoryHost, models.deliveries);
const deliveryItemsUrls = getModelUrls(inventoryHost, models.deliveryItems);
const deliveryArtifactsUrls = getModelUrls(
  inventoryHost,
  models.deliveryArtifacts
);
const productArtifactsUrls = getModelUrls(
  inventoryHost,
  models.productArtifacts
);
const productAdditionalInfosUrls = getModelUrls(
  inventoryHost,
  models.productAdditionalInfos
);
const inventorysUrls = getModelUrls(inventoryHost, models.inventorys);
const inventoryTransactionsUrls = getModelUrls(
  inventoryHost,
  models.inventoryTransactions
);
const mediasUrls = getModelUrls(inventoryHost, models.medias);

const urls = {
  importUrls,
  exportUrls,
  productTypesUrls,
  manufacturerContactsUrls,
  manufacturersUrls,
  productsUrls,
  productVariantsUrls,
  supplierContactsUrls,
  suppliersUrls,
  variantKeysUrls,
  variantValuesUrls,
  productSuppliersUrls,
  warehousesUrls,
  warehouseInventorysUrls,
  deliveriesUrls,
  deliveryItemsUrls,
  deliveryArtifactsUrls,
  productArtifactsUrls,
  productAdditionalInfosUrls,
  inventorysUrls,
  inventoryTransactionsUrls,
  mediasUrls,
};

export default urls;
