import React from 'react';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { lmsUrls } from '@config/routes';
import { courseMeta } from '@config/meta/lms';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import {
  courseModuleHandleRow,
  courseTeamHandleRow,
  courseMentorRowsMap,
} from '@config/handleRows/lms';
import {
  CourseModuleForm,
  CourseMentorForm,
  CourseTeamForm,
} from '@config/forms/lms';
import { useQueryClient } from 'react-query';

const {
  coursesUrls,
  courseModulesUrls,
  courseMentorsUrls,
  courseTeamsUrls,
  // Prettier :p
} = lmsUrls;

export default function CourseDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [courseModulesListData, setCourseModulesListData] = React.useState({});
  const [copied, setCopied] = React.useState(false);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => courseMeta({ data, queryClient }),
    []
  );

  const courseModuleColOptions = React.useMemo(() => {
    return {
      nextOrder: courseModulesListData?.totalCount ?? 1,
      wikiBook: recordData?.wikiBookId,
    };
  }, [courseModulesListData?.totalCount, recordData?.wikiBookId]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/courses', text: 'Courses' },
              { text: recordData?.name },
            ]}
          >
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_LMS_HOST}`
                );
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              }}
            >
              {copied ? 'Copied' : 'Copy Link'}
            </Button>
            <a
              href={`${process.env.REACT_APP_LMS_HOST}`}
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none' }}
            >
              <Button type='button' variant='outlined' sx={{ ml: 1 }}>
                Preview
              </Button>
            </a>
            <a
              href={`/edit-course/${recordId}`}
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none' }}
            >
              <Button type='button' variant='outlined' sx={{ ml: 1 }}>
                Edit Course
              </Button>
            </a>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={coursesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Course Module'
          prefix='0cc'
          columnKey='courseModule'
          columnOptions={courseModuleColOptions}
          urls={courseModulesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              CourseModuleForm({
                setFieldValue,
                wikiBookId: recordData?.wikiBookId,
                wikiBookName: recordData?.details?.wikiBookId?.name,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { moduleId, wikiChapterId, ...rest } = values;

                if (!moduleId?.id) {
                  setFieldError('moduleId', 'This field is required');
                  return;
                }

                if (!wikiChapterId?.id) {
                  setFieldError('wikiChapterId', 'This field is required');
                  return;
                }

                await createRecord({
                  values: {
                    ...rest,
                    courseId: recordId,
                    moduleId: moduleId?.id,
                    wikiChapterId: wikiChapterId?.id,
                  },
                  url: courseModulesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={courseModuleHandleRow}
          urlParams={`&courseId=${recordId}`}
          customSortColumnName='sequence'
          sortColumnBy='asc'
          getListData={(data) => setCourseModulesListData(data)}
        />

        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Course Mentor'
          prefix='daP'
          columnKey='courseMentor'
          urls={courseMentorsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              CourseMentorForm({ setFieldValue }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setFieldError
            ) => {
              try {
                const { mentorId, ...rest } = values;

                if (!mentorId) {
                  setFieldError('mentorId', 'Please select a mentor');
                  return;
                }

                await createRecord({
                  values: {
                    ...rest,
                    courseId: recordId,
                    mentorId: mentorId?.id,
                  },
                  url: courseMentorsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                const errorMsg = err?.response?.data?.message;
                notify(errorMsg ?? 'Something went wrong. Try refreshing.', {
                  type: 'ERROR',
                });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={courseMentorRowsMap}
          urlParams={`&courseId=${recordId}`}
        />
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Course Team'
          prefix='0q4'
          columnKey='courseTeam'
          urls={courseTeamsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              CourseTeamForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { teamId, ...rest } = values;

                if (!teamId?.id) {
                  setFieldError('teamId', 'This field is required');
                  return;
                }

                await createRecord({
                  values: {
                    ...rest,
                    courseId: recordId,
                    teamId: teamId?.id,
                  },
                  url: courseTeamsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={courseTeamHandleRow}
          urlParams={`&courseId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
