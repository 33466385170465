import React from 'react';
import { Grid } from '@mui/material';

export default function PageWrapper({ children }) {
  return (
    <Grid sx={{ height: '100vh' }} container>
      <Grid item sx={{ height: '100%', overflow: 'hidden' }} xs={5}>
        <img
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src="/img/ps-login.png"
          alt=""
        />
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          bgcolor: '#fff',
          p: 8,
        }}
        xs={7}
      >
        {children}
      </Grid>
    </Grid>
  );
}
