import * as Yup from 'yup';
import { fieldType } from '@config/constants';
import NameColumn from '@components/Drive/NameColumn';

export const fileFolderMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 900,
    editable: false,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
    renderCell: (params) => <NameColumn params={params} />,
  },
  {
    field: 'size',
    headerName: 'Size',
    onForm: false,
    editable: false,
    value: data ? data.size : null,
    width: 120,
  },
  {
    field: 'createdAt',
    headerName: 'Date added',
    width: 120,
    value: data?.createdAt ? new Date(data?.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
