import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { formsUrls, systemUrls } from '@config/routes';
import { Grid } from '@mui/material';
import {
  TextField,
  CheckBoxField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { formsMeta } from '@config/meta/forms/formsMeta';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import { createRecord } from '@config/functions/requests';
import { microservices } from '@config/constants';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { templatesUrls } = systemUrls;
const { formsListUrls, cloneSystemTemplatesUrls } = formsUrls;

export default function FormDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="Forms"
        initialValues={{
          ...getAddFormInitialValues(formsMeta({}), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(formsMeta({}), 'listView'),
        })}
        getPostUrl={() => formsListUrls.list()}
        handleSubmit={async (values, setSubmitting, resetForm, setError) => {
          try {
            const {
              anonymous_can_see_it,
              isPublished,
              template,
              ...restValues
            } = values;

            if (template) {
              const payload = {
                template_id: template?.id,
                custom_params: {
                  ...restValues,
                  isPublished,
                  anonymous_can_see_it: isPublished || anonymous_can_see_it,
                },
              };
              const { data } = await createRecord({
                values: payload,
                url: cloneSystemTemplatesUrls.detail(
                  microservices.Forms.models.form
                ),
                token: user.token,
                actAs: user?.actAs,
              });
              refetch(data);
              resetForm();
              setOpen(false);
            } else {
              const { data } = await createRecord({
                values: {
                  ...restValues,
                  isPublished,
                  anonymous_can_see_it: isPublished || anonymous_can_see_it,
                  category: 'Quiz',
                },
                url: formsListUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              refetch(data);
              resetForm();
              setOpen(false);
            }
          } catch (err) {
            console.log(err);
            console.log(err.message);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        }}
        getFields={() => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField label="Name" name="name" multiline required />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Title" name="title" multiline required />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Description" name="description" multiline />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Closing message"
                name="closingMessage"
                multiline
              />
            </Grid>
            <Grid item sm={6}>
              <CheckBoxField label="Publish" name="isPublished" />
            </Grid>
            <Grid item sm={6}>
              <AutocompleteFieldV2
                name="template"
                label="Template To Copy From"
                requestKey="forms-templates"
                fetchUrl={templatesUrls.list}
                urlParams={`&ordering=created_at&microservice_name=${microservices.Forms.name}&model_name=${microservices.Forms.models.form}`}
                renderRow={(row) => ({
                  id: row?.id,
                  label: row?.name,
                })}
              />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
