import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Box } from '@mui/system';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { ecommerceUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { ItemDeliveryForm } from '@config/forms/ecommerce';
import ecommerceHandleRow from '@config/handleRows/ecommerce';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  itemDeliveriesUrls,
  cartItemsUrls,
  deliveryMethodsUrls,
  deliveryStatusesUrls,
  addressesUrls,
} = ecommerceUrls;
const { itemDeliveryRowMap } = ecommerceHandleRow;

export default function ItemDeliveries({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const [addressTerm, setAddressTerm] = useState('');
  const [cartItemTerm, setCartItemTerm] = useState('');
  const [deliveryMethodTerm, setDeliveryMethodTerm] = useState('');
  const [deliveryStatusTerm, setDeliveryStatusTerm] = useState('');

  const { data: addresses, isFetching: addressesIsFetching } = useAutocomplete({
    key: 'addresses',
    getUrl: addressesUrls.list,
    inputValue: addressTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.address1,
      };
    },
  });

  const { data: cartItems, isFetching: fetchingCartItems } = useAutocomplete({
    key: 'cartItems',
    getUrl: cartItemsUrls.list,
    inputValue: cartItemTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.product_variant && r.details && r.details.product_variant
            ? r.details.product_variant.name
            : null,
      };
    },
  });

  const { data: deliveryMethods, isFetching: fetchingDeliveryMethods } =
    useAutocomplete({
      key: 'deliveryMethods',
      getUrl: deliveryMethodsUrls.list,
      inputValue: deliveryMethodTerm,
    });

  const { data: deliveryStatuses, isFetching: fetchingDeliveryStatuses } =
    useAutocomplete({
      key: 'deliveryStatuses',
      getUrl: deliveryStatusesUrls.list,
      inputValue: deliveryStatusTerm,
    });

  const itemDeliveriesColOptions = React.useMemo(() => {
    return {
      cartItems,
      fetchingCartItems,
      setCartItemTerm,
      deliveryMethods,
      fetchingDeliveryMethods,
      setDeliveryMethodTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
      addresses,
      addressesIsFetching,
      setAddressTerm,
    };
  }, [
    cartItems,
    fetchingCartItems,
    setCartItemTerm,
    deliveryMethods,
    fetchingDeliveryMethods,
    setDeliveryMethodTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
    addresses,
    addressesIsFetching,
    setAddressTerm,
  ]);

  return (
    <Box>
      <ListView
        label="Item Deliveries"
        prefix="0e4"
        labels={labels}
        editMode={editMode}
        urls={itemDeliveriesUrls}
        microservice="E-Commerce"
        model="ItemDelivery"
        columnKey="itemDeliveryEcommerce"
        columnOptions={itemDeliveriesColOptions}
        addNewForm={{
          getFields: () =>
            ItemDeliveryForm({
              cartItems,
              fetchingCartItems,
              setCartItemTerm,
              deliveryMethods,
              fetchingDeliveryMethods,
              setDeliveryMethodTerm,
              deliveryStatuses,
              fetchingDeliveryStatuses,
              setDeliveryStatusTerm,
              addresses,
              addressesIsFetching,
              setAddressTerm,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: itemDeliveriesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify(`Item Delivery Created!`, {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={itemDeliveryRowMap}
      />
    </Box>
  );
}
