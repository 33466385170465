import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React from 'react';

export default function MultipleChoiceField({
  options,
  value,
  setValue,
  includeOthersField,
  otherValue,
  setOtherValue,
  otherChecked,
  isCorrect,
  category,
  isResponsePage,
}) {
  const handleOtherChange = (e) => {
    setOtherValue(e.target.value);
  };

  return (
    <Box>
      {Array.isArray(options) && options.length ? (
        <FormControl style={{ pointerEvents: isResponsePage ? 'none' : '' }}>
          <RadioGroup
            defaultValue={isResponsePage ? null : options[0].id}
            value={value}
            onChange={(e) => {
              setOtherValue('');
              setValue(e.target.value);
            }}
            name={`${options[0].id}-radio`}
          >
            {options
              .sort((a, b) => a?.order - b?.order)
              .map(({ id, option }) => (
                <FormControlLabel
                  key={id}
                  value={id}
                  control={<Radio size='small' />}
                  label={option}
                  sx={{ color: '#012970' }} // add color
                />
              ))}
            {includeOthersField && (
              <>
                <FormControlLabel
                  value='other'
                  control={
                    <Radio size='small' checked={otherChecked || undefined} />
                  }
                  label='Other'
                />
                {value === 'other' || otherValue ? (
                  <Box sx={{ pt: '12px', width: '390px' }}>
                    <TextField
                      fullWidth
                      multiline
                      label='Other'
                      size='small'
                      value={otherValue}
                      onChange={handleOtherChange}
                      InputProps={{
                        readOnly: isResponsePage,
                      }}
                    />
                  </Box>
                ) : null}
              </>
            )}
          </RadioGroup>
        </FormControl>
      ) : null}

      {category === 'Quiz' && isCorrect ? (
        <Alert variant='filled' sx={{ mt: '12px' }} severity='success'>
          The selected option(s) are correct
        </Alert>
      ) : category === 'Quiz' && isCorrect === false ? (
        <Alert variant='filled' sx={{ mt: '12px' }} severity='error'>
          The selected option(s) are incorrect
        </Alert>
      ) : null}
    </Box>
  );
}
