import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import {
  pmUrls,
  //  calendarUrls
} from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { programMeta } from '@config/meta/pm/pmMeta';
import { projectRowsMap } from '@config/handleRows/pm';
// import { doWhatRowsMap } from '@config/handleRows/calendar';
import { ProjectForm } from '@config/forms/pm';
// import { DoWhatForm } from '@config/forms/calendar';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useParams } from 'react-router-dom';
// import { useQueryClient } from 'react-query';

// const { doWhatUrls } = calendarUrls;
const { programsUrls, projectsUrls, cloneSystemTemplatesUrls } = pmUrls;

export default function ProgramDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => programMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/programs', text: 'Programs' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={programsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix='0cy'
          microservice={microservices.PM.name}
          model={microservices.PM.models.projects}
          columnKey='projectPM'
          label='Projects'
          urls={projectsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              ProjectForm({
                setFieldValue,
                microservice: microservices.PM.name,
                model: microservices.PM.models.projects,
                programId: recordId,
                programName: recordData?.name,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const doFinally = () => {
                  resetForm();
                  setAddNewForm(false);
                  refetch();
                };
                const { template, ...rest } = values;
                if (template) {
                  try {
                    const response = await createRecord({
                      values: {
                        template_id: template?.id,
                        custom_params: {
                          ...rest,
                          program: recordId,
                        },
                      },
                      url: cloneSystemTemplatesUrls.detail(
                        microservices.PM.models.projects
                      ),
                      token: user.token,
                      actAs: user?.actAs,
                    });
                    doFinally();
                    return response?.data?.id;
                  } catch (err) {
                    handleCreateFormErrors({ err, setError, notify, values });
                  } finally {
                    setSubmitting(false);
                  }
                } else {
                  const response = await createRecord({
                    values: {
                      ...rest,
                      program: recordId,
                      template: template?.id,
                    },
                    url: projectsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });
                  doFinally();
                  return response?.data?.id;
                }
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={projectRowsMap}
          urlParams={`&program=${recordId}&template=false`}
        />

        {/* <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix='0p1'
          microservice={microservices.CALENDAR.name}
          model={microservices.CALENDAR.models.doWhat}
          columnKey="doWhatCalendar"
          label='Do What'
          urls={doWhatUrls}
          addNewForm={{
            getFields: () => DoWhatForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, program: recordId },
                  url: doWhatUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={doWhatRowsMap}
          urlParams={`&program=${recordId}`}
        /> */}
      </Box>
    </Box>
  );
}
