import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { pmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { timeSheetRowsMap } from '@config/handleRows/pm';
import { TimesheetsForm } from '@config/forms/pm';
import { microservices } from '@config/constants';

const { timeSheetsUrls } = pmUrls;

export default function SprintMetas({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        labels={labels}
        editMode={editMode}
        label='Timesheets'
        microservice={microservices.PM.name}
        model={microservices.PM.models.sprintMeta}
        urls={timeSheetsUrls}
        columnKey='timesheetsPM'
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            TimesheetsForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { task, resource, workcode, ...rest } = values;
              const response = await createRecord({
                values: {
                  task: task?.id,
                  resource: resource?.id,
                  workcode: workcode?.id,
                  ...rest,
                },
                url: timeSheetsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify('Timesheet Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={timeSheetRowsMap}
      />
    </Box>
  );
}
