import axios from 'axios';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { logsUrls, accountUrls } from '@config/routes';
import { logMeta } from '@config/meta/logs/logsMeta';
import { logHandleRow } from '@config/handleRows/logs';
import { microservices } from '@config/constants';
import useRQuery from '@hooks/useRQuery';

const { logUrls } = logsUrls;
const { usersUrls, userDetailUrls } = accountUrls;

export default function Logs() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [userTerm, setUserTerm] = useState();
  const user = useSelector(selectProfile);

  const { data: usersList, isFetching: usersIsFetching } = useRQuery({
    key: ['users', userTerm, user.token, user?.actAs],
    url: usersUrls.list(`?search=${userTerm}`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  useEffect(() => {
    if (!usersList) return;

    setUsers(
      usersList.results.map((item) => ({
        id: item.id,
        label: item.username,
      }))
    );
  }, [usersList]);

  useEffect(() => {
    axios
      .get(
        userDetailUrls.list(),
        getAuthHeader(user.token, user.details && user?.details?.client)
      )
      .then((res) => {
        if (!res.data.is_superuser) {
          navigate('/');
        }
      });
  }, [navigate, user.token]);

  return (
    <Box key="list-view-container">
      <ListView
        key="log-list-view"
        label="Logs"
        urls={logUrls}
        microservice={microservices.LOGS.name}
        model={microservices.LOGS.models.logs}
        columns={logMeta(users, setUserTerm, usersIsFetching)}
        handleRowsMap={logHandleRow}
      />
    </Box>
  );
}
