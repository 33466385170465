import React from 'react';
import * as Yup from 'yup';
import { crmUrls, hrUrls } from '@config/routes';
import { opportunitiesMeta } from '@config/meta/crm/index';
import { Grid, MenuItem } from '@mui/material';
import {
  TextField,
  DateTimePickerField,
  AutocompleteFieldV2,
  SelectField,
} from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { opportunitySentiments, opportunityPriority } from '@config/constants';
import CompanyContactDropdown from '@dropdown-forms/crm/CompanyContactDropdown';
import PipelineDropdown from '@dropdown-forms/crm/PipelineDropdown';
import PipelineStagesDropdown from '@dropdown-forms/crm/PipelineStagesDropdown';
import { useQueryClient } from 'react-query';
import CompanyDropdown from '@dropdown-forms/crm/CompanyDropdown';
import EmployeeDropdownForm from '@dropdown-forms/hr/EmployeeDropdown';

const {
  opportunitiesUrls,
  companyContactUrls,
  pipelinesUrls,
  companiesUrls,
  pipelineStagesUrls,
} = crmUrls;
const { employeesUrls } = hrUrls;

export default function OpportunityDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title='Opportunity'
        initialValues={{
          ...getAddFormInitialValues(opportunitiesMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(opportunitiesMeta(), 'listView'),
        })}
        getPostUrl={() => opportunitiesUrls.list()}
        getValues={({
          companyId,
          companyContact,
          pipeline,
          status,
          owner,
          ...values
        }) => ({
          ...values,
          company: companyId?.id,
          company_contact: companyContact?.id,
          pipeline: pipeline?.id,
          status: status?.id,
          owner: owner?.id,
        })}
        getFields={({ setFieldValue, values }) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key='name' label='Name' name='name' required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                key='description'
                label='Description'
                name='description'
                rows={3}
              />
            </Grid>
            <Grid item xs={6}>
              <CompanyDropdown
                refetch={({ id, name }) => {
                  setFieldValue('companyId', { id, label: name });
                  queryClient.invalidateQueries(['opportunity-companies']);
                }}
              >
                <AutocompleteFieldV2
                  name='companyId'
                  label='Company'
                  requestKey='opportunity-companies'
                  fetchUrl={companiesUrls.list}
                  required
                />
              </CompanyDropdown>
            </Grid>
            <Grid item xs={6}>
              <CompanyContactDropdown
                company={values?.companyId}
                refetch={({ id, details }) => {
                  setFieldValue('companyContact', {
                    id,
                    label: `${details?.person?.first_name ?? ''} ${
                      details?.person?.last_name ?? ''
                    }`,
                  });
                  queryClient.invalidateQueries([
                    'opportunity-company-contacts',
                  ]);
                }}
              >
                <AutocompleteFieldV2
                  name='companyContact'
                  label='Company Contact'
                  requestKey='opportunity-company-contacts'
                  fetchUrl={companyContactUrls.list}
                  disabled={!values?.companyId}
                  enabled={!!values?.companyId?.id}
                  requestKeyOptions={[values?.companyId?.id]}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: `${row?.details?.person?.first_name ?? ''} ${
                      row?.details?.person?.last_name ?? ''
                    }`,
                  })}
                  urlParams={
                    values?.companyId
                      ? `&company_id=${values.companyId?.id}`
                      : ''
                  }
                  required
                />
              </CompanyContactDropdown>
            </Grid>
            <Grid item xs={6}>
              <PipelineDropdown
                refetch={({ id, name }) => {
                  setFieldValue('pipeline', { id, label: name });
                  queryClient.invalidateQueries(['opportunity-pipelines']);
                }}
              >
                <AutocompleteFieldV2
                  name='pipeline'
                  label='Pipelines'
                  requestKey='opportunity-pipelines'
                  fetchUrl={pipelinesUrls.list}
                  required
                />
              </PipelineDropdown>
            </Grid>
            <Grid item xs={6}>
              <PipelineStagesDropdown
                pipelineId={values?.pipeline}
                refetch={({ id, stage }) => {
                  setFieldValue('status', { id, label: stage });
                  queryClient.invalidateQueries([
                    'opportunity-pipeline-stages',
                  ]);
                }}
              >
                <AutocompleteFieldV2
                  name='status'
                  label='Pipeline stage'
                  requestKey='opportunity-pipeline-stages'
                  fetchUrl={pipelineStagesUrls.list}
                  disabled={!values?.pipeline}
                  enabled={!!values?.pipeline?.id}
                  requestKeyOptions={[values?.pipeline?.id]}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: `${Number(row?.order).toFixed(2)} ${row?.stage}`,
                  })}
                  urlParams={
                    values?.pipeline
                      ? `&pipeline=${values.pipeline?.id}&ordering=order`
                      : ''
                  }
                  required
                />
              </PipelineStagesDropdown>
            </Grid>
            <Grid item xs={6}>
              <EmployeeDropdownForm
                title='Owner'
                isSalesPerson
                refetch={({ id, work_email }) => {
                  setFieldValue('owner', { id, label: work_email });
                  queryClient.invalidateQueries(['opportunity-owner']);
                }}
              >
                <AutocompleteFieldV2
                  name='owner'
                  label='Owner'
                  requestKey='opportunity-owner'
                  fetchUrl={employeesUrls.list}
                  urlParams={`&department_name=Sales&ordering=created_at`}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.work_email,
                  })}
                />
              </EmployeeDropdownForm>
            </Grid>
            <Grid item xs={6}>
              <SelectField label='Sentiment' name='sentiment'>
                {opportunitySentiments &&
                  opportunitySentiments.map((o) => (
                    <MenuItem value={o.value}>{o.label}</MenuItem>
                  ))}
              </SelectField>
            </Grid>
            <Grid item xs={6}>
              <SelectField label='Customer Priority' name='customer_priority'>
                {opportunityPriority &&
                  opportunityPriority.map((o) => (
                    <MenuItem value={o.value}>{o.label}</MenuItem>
                  ))}
              </SelectField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                key='data_source'
                label='How Found'
                name='data_source'
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePickerField
                label='Estimated Close Date'
                name='estimated_close_date'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key='estimated_value'
                label='Estimated Value'
                name='estimated_value'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key='probability'
                label='Probability'
                name='probability'
              />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
