import React from 'react';
import * as Yup from 'yup';
import useToast from '@hooks/useToast';
import { lmsUrls, nodeDriveUrls } from '@config/routes';
import { moduleMeta } from '@config/meta/lms';
import { Grid, Typography, Box } from '@mui/material';
import {
  TextField,
  AutocompleteFieldV2,
  UploadField,
} from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { useQueryClient } from 'react-query';
import HomeworkDropdown from '@dropdown-forms/lms/HomeworkDropdown';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { modulesUrls, homeworksUrls } = lmsUrls;
const { filesUrls } = nodeDriveUrls;

export default function ModuleDropdownForm({ title, children, refetch }) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title={title || 'Module'}
        initialValues={{
          ...getAddFormInitialValues(moduleMeta({ queryClient }), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            moduleMeta({ queryClient }),
            'listView'
          ),
        })}
        getPostUrl={() => modulesUrls.list()}
        handleSubmit={async (
          values,
          setSubmitting,
          resetForm,
          setOpen,
          setError,
          setFieldError
        ) => {
          try {
            const { homeworkId, thumbnail, ...rest } = values;

            let fileUrl;

            try {
              if (thumbnail) {
                const formData = new FormData();
                formData.append(
                  'client',
                  user?.actAs?.id || user?.details?.client
                );
                formData.append('created_by', user?.details?.id);
                formData.append('anonymous_can_see_it', true);
                formData.append('file', thumbnail);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                fileUrl = fileData.fileUrl;
              }
            } catch {}

            if (thumbnail && !fileUrl) {
              setFieldError('thumbnail', 'Failed to upload thumbnail');
              return;
            }

            const { data } = await createRecord({
              values: {
                ...rest,
                thumbnail: fileUrl || undefined,
                homeworkId: homeworkId?.id,
              },
              url: modulesUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });

            resetForm();
            setOpen(false);
            refetch(data);
            return data?.id;
          } catch (err) {
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        }}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField label="Module Title" name="name" required multiline />
            </Grid>

            <Grid item xs={6}>
              <HomeworkDropdown
                refetch={({ id, name }) => {
                  setFieldValue('homeworkId', { id, label: name });
                  queryClient.invalidateQueries(['module-homework']);
                }}
              >
                <AutocompleteFieldV2
                  name="homeworkId"
                  label="Task"
                  requestKey="module-homework"
                  fetchUrl={homeworksUrls.list}
                />
              </HomeworkDropdown>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                minRows={2}
                multiline
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ mb: 1, fontWeight: '500' }}>
                Thumbnail
              </Typography>
              <Box>
                <UploadField name="thumbnail" accept=".png, .jpg, .jpeg" />
              </Box>
            </Grid>
          </Grid>
        )}
        open={open}
        setOpen={setOpen}
        refetch={refetch}
      />
    </>
  );
}
