import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Box, Button, Stack } from '@mui/material';
import DateTime from '@components/DateTime';
import { formatToUTCDate } from '@config/functions/helperFunctions';

export default function FilterPopup({
  open,
  setOpen,
  setStartDate,
  setEndDate,
  endDate,
  startDate,
  setFilter,
}) {
  const handleClose = () => setOpen(false);

  const handleSave = () => {
    setFilter(true);
    handleClose();
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>Filter</DialogTitle>
      </Box>

      <DialogContent>
        <Stack direction='row' spacing={3}>
          <Box>
            <DateTime
              label='Start date'
              data={startDate}
              handleSubmit={({ dateObject }) => {
                setStartDate(formatToUTCDate(dateObject));
              }}
            />
          </Box>
          <Box>
            <DateTime
              label='End date'
              data={endDate}
              handleSubmit={({ dateObject }) => {
                setEndDate(formatToUTCDate(dateObject));
              }}
            />
          </Box>
        </Stack>

        <DialogActions sx={{ pt: 3 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
