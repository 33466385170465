import { voipHOST } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  callLogs: 'call-logs',
  createCallLogs: 'create-call-logs',
};

const callLogsUrls = getModelUrls(voipHOST, models.callLogs);
const createCallLogsUrls = getModelUrls(voipHOST, models.createCallLogs);

const urls = {
  callLogsUrls,
  createCallLogsUrls,
};

export default urls;
