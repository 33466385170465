import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import Checkbox from '@ui/Inputs/CheckBoxField';

export default function CheckboxField({
  label,
  color,
  labelcolor,
  customSetValue = () => null,
  customOnChange = null,
  ...rest
}) {
  const [field] = useField(rest);
  const { setFieldValue } = useFormikContext();

  const onChange = (e) => {
    field.onChange(e);
    setFieldValue(field.name, e.target.checked);
    customSetValue(e.target.checked);
  }

  return (
    <Checkbox
      checked={field.value}
      onChange={customOnChange || onChange}
      name={field.name}
      label={label}
      color={color}
      labelcolor={labelcolor}
      {...rest}
    />
  );
}
