import React from 'react';
import { Typography } from '@mui/material';
import UIRepeatField from '@ui/Inputs/RepeatField';
import { detectRrule } from '@config/functions/calendarFunctions';

export function renderRepeat(params) {
  const { value } = params;
  return (
    <Typography component="div" variant="p">
      {detectRrule(value)?.label}
    </Typography>
  );
}

export function RepeatEditInputCell(params) {
  const { id, value, api, field, colDef } = params;

  const handleChange = async (value) => {
    if (typeof value === 'undefined') return;

    if (colDef.formatValue) {
      value = colDef.formatValue(value);
    }

    api.setEditCellValue({
      id,
      field,
      value,
    });

    await api.commitCellChange({ id, field });
    api.setCellMode(id, field, 'view');
  };

  return (
    <UIRepeatField
      field={colDef?.field}
      label={colDef?.headerName}
      showCancel={colDef?.showCancel ?? false}
      showDoesNotRepeat={colDef?.showDoesNotRepeat ?? true}
      rrule={value}
      setCustomValue={handleChange}
    />
  );
}
