import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { crmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { relationshipMeta } from '@config/meta/crm';
import { personRelationshipHistoriesRowsMap } from '@config/handleRows/crm';
import { PersonRelationshipHistoryForm } from '@config/forms/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { personRelationshipsUrls, personRelationshipHistoriesUrls } = crmUrls;

export default function PersonRelationshipDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => relationshipMeta({ data, inPersonDetail: true }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/persons/${recordData?.person}`,
                text: 'Person Relationships',
              },
              { text: recordData?.details?.relationship?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={personRelationshipsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.relationshipHistory}
          columnKey="personRelationshipHistoryCRM"
          label="Notes"
          editMode={editMode}
          labels={labels}
          prefix="dc2"
          urls={personRelationshipHistoriesUrls}
          addNewForm={{
            getFields: () => PersonRelationshipHistoryForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { data } = await createRecord({
                  values: { ...values, person_relationship: recordId },
                  url: personRelationshipHistoriesUrls.list(),
                  token: user?.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={personRelationshipHistoriesRowsMap}
          urlParams={`&person_relationship=${recordId}`}
        />
      </Box>
    </Box>
  );
}
