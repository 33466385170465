import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export default function UploadField({ file, setFile }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  const handleRemove = () => {
    setFile(null);
  };

  return (
    <Box sx={{ pt: '6px' }}>
      <Box
        sx={{
          p: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px dashed #c1c7d0',
          borderRadius: '4px',
        }}
      >
        <div {...getRootProps({ className: 'dropzone' })}>
          <input
            {...getInputProps()}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
          <Typography
            sx={{
              cursor: 'pointer',
              userSelect: 'none',
              color: '#7a869a',
            }}
          >
            {isDragActive ? (
              <span>Drop the file here...</span>
            ) : (
              <>
                <span>Drop file to attach, or</span>{' '}
                <span style={{ color: 'blue' }}>browse</span>
              </>
            )}
          </Typography>
        </div>
      </Box>
      {file && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1,
            }}
          >
            <Typography>{file?.name}</Typography>
            <IconButton onClick={handleRemove}>
              <CloseOutlinedIcon fontSize='small' />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
}
