import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { microservices } from '@config/constants';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { listsUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { CityForm } from '@config/forms/list';
import { cityRowsMap } from '@config/handleRows/list';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { citiesUrls } = listsUrls;

export default function Cities({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        microservice={microservices.LIST.name}
        model={microservices.LIST.models.cities}
        label='City'
        prefix='07s'
        labels={labels}
        editMode={editMode}
        urls={citiesUrls}
        columnKey='cityLists'
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            CityForm({
              setFieldValue,
              hideCountry: true,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { country, ...rest } = values;
              const response = await createRecord({
                values: {
                  ...rest,
                  country: country?.id,
                },
                url: citiesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify(`City created`, {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={cityRowsMap}
        showBulkDeleteButton={false}
        hideActions
      />
    </Box>
  );
}
