import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import Button from '@ui/Button';
import Loader from '@components/Loader.jsx';
import useToast from '@hooks/useToast';
import { createRecord } from '@config/functions/requests';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { calendarUrls } from '@config/routes';
import { TextField } from '@components/Inputs';
import { Formik, Form } from 'formik';
import useRQuery from '@hooks/useRQuery';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const { calendarAppsUrls } = calendarUrls;

export default function ConfigureCalendar() {
  const user = useSelector(selectProfile);
  const [value, setValue] = React.useState(0);
  const [notify] = useToast();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    data: calendarApps,
    isFetching: fetchingCalendarApps,
    refetch: refetchCalendarApps,
  } = useRQuery({
    key: ['calendarApps', user.token, user?.actAs],
    url: calendarAppsUrls.list(),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user && user.token) },
  });

  if (fetchingCalendarApps || !calendarApps) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Google Calendar" {...a11yProps(0)} />
          <Tab label="Microsoft Calendar" {...a11yProps(1)} />
          <Tab label="Apple Calendar" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Formik
          initialValues={{
            client_id: calendarApps.google.client_id || '',
            client_secret: calendarApps.google.client_secret || '',
            client_redirect_uri: `https://${window.location.hostname}/google-callback`,
            scopes:
              'https://www.googleapis.com/auth/userinfo.email\nhttps://www.googleapis.com/auth/userinfo.profile\nhttps://www.googleapis.com/auth/calendar',
          }}
          validationSchema={Yup.object({
            client_id: Yup.string().required(),
            client_secret: Yup.string().required(),
            client_redirect_uri: Yup.string(),
            scopes: Yup.string(),
          })}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            const setError = (error) => {
              Object.entries(error).map((err) => setFieldError(err[0], err[1]));
            };

            try {
              const newValues = { ...values };

              const response = await createRecord({
                values: {
                  ...newValues,
                  type: 'Google',
                },
                url: calendarAppsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              console.log(response);
              notify(`Your Changes Are Saved!`, {
                type: 'SUCCESS',
              });
              refetchCalendarApps();
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate autoComplete="off">
              <Grid item xs={2} sm={4} md={4}>
                <Box sx={{ mb: 2 }}>
                  <TextField label="Client Public Key" name="client_id" />
                </Box>
              </Grid>

              <Grid item xs={2} sm={4} md={5}>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    label="Client Secret Key"
                    name="client_secret"
                    type="password"
                  />
                </Box>
              </Grid>

              <Grid item xs={2} sm={4} md={5}>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    label="Redirect URI"
                    name="client_redirect_uri"
                    disabled={true}
                  />
                </Box>
              </Grid>

              <Grid item xs={2} sm={4} md={5}>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    label="Scopes"
                    name="scopes"
                    multiline
                    rows={4}
                    disabled
                  />
                </Box>
              </Grid>

              <Box sx={{ display: 'flex' }}>
                <Button
                  sx={{ ml: 'auto' }}
                  type="submit"
                  variant="contained"
                  label={'Save'}
                  disabled={isSubmitting}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Comming Very Soon!
      </TabPanel>
      <TabPanel value={value} index={2}>
        Comming Very Soon!
      </TabPanel>
    </Box>
  );
}
