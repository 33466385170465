import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { bpaUrls } from '@config/routes';
import Button from '@mui/material/Button';
import { AutocompleteFieldV2 } from '@components/Inputs';
import EmailTemplateAccordion from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateAccordion';
import useToast from '@hooks/useToast';
import {
  getReadOnlyStyles,
  handleAccordionItemDelete,
} from '@config/functions/emailTemplateConfigurationPopupFunctions';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import IconButton from '@mui/material/IconButton';
import PayloadSelector from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/PayloadSelector';
import EmailTemplateEdocFields from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateEdocFields';

const cardStyles = {
  p: 2,
  borderRadius: '4px',
  backgroundColor: '#f7f7f7',
  border: '1px solid #c8c8c8',
};

const { modelAssetsUrls } = bpaUrls;

export default function EmailTemplateEdocCard({
  docIndex,
  doc,
  setFieldValue,
  remove,
  workflowId,
  workflowName,
  showAddReminder,
  setShowAddReminder,
  shape,
  shapeAction,
  values,
  user,
  isKanbanView,
  errors,
}) {
  const [notify] = useToast();
  const [isDeleting, setDeleting] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const accordionLabel = `Document ${docIndex + 1}`;

  const fieldName = `edocs-${docIndex}-document`;
  const documentFieldValuesFieldName = `edocs-${docIndex}-document_field_values`;

  const handleToggle = () => setOpen(!open);
  const handleIna = (moreNotes = '') =>
    setShowAddReminder({
      ...showAddReminder,
      initialNotes: `Ref: Workflows > ${workflowName} > ${shape?.name} > ${
        shapeAction?.name
      } > Edocs > ${accordionLabel}${moreNotes ? ` > ${moreNotes}` : ''}\n\n`,
      open: true,
    });

  const renderDocument = (row) => {
    const { id, name } = row?.details?.asset ?? {};

    return {
      label: name,
      static_value: id,
      mapping_field_name: null,
    };
  };

  return (
    <EmailTemplateAccordion
      open={open}
      setOpen={handleToggle}
      label={accordionLabel}
      isError={errors?.eDocs?.[docIndex] ?? false}
      endContent={() => (
        <Button
          variant="text"
          color="error"
          size="small"
          disabled={isDeleting || isKanbanView}
          onClick={() => {
            handleAccordionItemDelete({
              setDeleting,
              notify,
              remove,
              index: docIndex,
            });
          }}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </Button>
      )}
    >
      <Stack
        spacing={2}
        sx={{
          ...cardStyles,
        }}
      >
        <Box sx={{ ...getReadOnlyStyles(isKanbanView), display: 'flex' }}>
          <AutocompleteFieldV2
            idKey="static_value"
            label="Document"
            values={values}
            name={fieldName}
            fetchUrl={modelAssetsUrls.list}
            urlParams={`&microservice=eDoc&model=document&workflow=${workflowId}`}
            requestKey={`email-template-setup-eDoc-${docIndex}-${workflowId}`}
            renderRow={renderDocument}
            onChange={(newValue) => {
              if (!newValue) return;
              const newEdocs = [...values.edocs];
              newEdocs[docIndex][fieldName] = newValue;
              newEdocs[docIndex][documentFieldValuesFieldName] = {};
              setFieldValue('edocs', newEdocs);
            }}
            required
          />
          <IconButton
            aria-label="add-ina"
            color="primary"
            sx={{ pr: '1px' }}
            disabled={isKanbanView}
            onClick={() => handleIna('Document')}
          >
            <PlaylistAddIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box sx={getReadOnlyStyles(isKanbanView)}>
          <PayloadSelector
            isKanbanView={isKanbanView}
            label="Payload Selector"
            workflowId={workflowId}
            payloadSectionName="edocPayloadSection"
            payloadSectionFieldName="edocPayloadSectionKey"
          />
        </Box>

        <EmailTemplateEdocFields
          values={values}
          edoc={doc}
          edocIdx={docIndex}
          setFieldValue={setFieldValue}
          user={user}
          workflowId={workflowId}
          isKanbanView={isKanbanView}
          documentFieldName={fieldName}
          documentFieldValuesFieldName={documentFieldValuesFieldName}
          handleIna={handleIna}
        />
      </Stack>
    </EmailTemplateAccordion>
  );
}
