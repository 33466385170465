import { qrCodeHOST } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  vcard: 'v-cards',
  email: 'emails',
  sms: 'smss',
  wifi: 'wifies',
  twitter: 'twitters',
  pdf: 'pdfs',
  facebook: 'facebooks',
  mp3: 'mp3s',
  appStores: 'app-stores',
  image: 'images',
  allData: 'all-data',
};

const vcardUrls = getModelUrls(qrCodeHOST, models.vcard);
const emailUrls = getModelUrls(qrCodeHOST, models.email);
const smsUrls = getModelUrls(qrCodeHOST, models.sms);
const wifiUrls = getModelUrls(qrCodeHOST, models.wifi);
const twitterUrls = getModelUrls(qrCodeHOST, models.twitter);
const pdfUrls = getModelUrls(qrCodeHOST, models.pdf);
const facebookUrls = getModelUrls(qrCodeHOST, models.facebook);
const mp3Urls = getModelUrls(qrCodeHOST, models.mp3);
const appStoresUrls = getModelUrls(qrCodeHOST, models.appStores);
const imageUrls = getModelUrls(qrCodeHOST, models.image);
const allDataUrls = getModelUrls(qrCodeHOST, models.allData);

const urls = {
  vcardUrls,
  emailUrls,
  smsUrls,
  wifiUrls,
  twitterUrls,
  pdfUrls,
  facebookUrls,
  mp3Urls,
  appStoresUrls,
  imageUrls,
  allDataUrls,
};

export default urls;
