import * as Yup from 'yup';
import { fieldType, microservices } from '@config/constants';
import getDropdownForm from '@dropdown-forms/getDropdownForm';
import { wikiUrls } from '@config/routes';

const { bookCategoryUrls, chaptersUrls, booksUrls, tagsUrls } = wikiUrls;


export const bookCategoryMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 180,
    type: fieldType.TEXT,
    editable: true,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 300,
    type: fieldType.TEXT,
    editable: true,
    value: data && data.description,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const tagsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 180,
    editable: true,
    type: fieldType.TEXT,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const booksMeta = ({
  queryClient,
  data,
} = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    type: fieldType.LINK,
    to: '/books/',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 300,
    editable: true,
    type: fieldType.TEXT,
    value: data && data.description,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 300,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: bookCategoryUrls.list,
    requestKey: `${data?.id}-book-category`,
    validate: Yup.mixed(),
    initialValue: data?.category
      ? {
          id: data.category,
          label: data?.details?.category?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-book-category`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.WIKI.name,
          model: microservices.WIKI.models.bookCategory
        }),
    }),
  },
  {
    field: 'published',
    type: fieldType.BOOLEAN,
    headerName: 'Published',
    onForm: true,
    width: 250,
    value: data && data.published,
    editable: true,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const chaptersMeta = ({
  data,
  queryClient,
  nextOrder
} = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    onAccordionForm: true,
    type: fieldType.TEXT,
    width: 180,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder + 1,
    validate: Yup.number().required(),
  },
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/chapters/',
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    type: fieldType.TEXT,
    width: 300,
    editable: true,
    value: data && data.description,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'parent_chapter',
    headerName: 'Parent Chapter',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: chaptersUrls.list,
    requestKey: `${data?.id}-book-chapter`,
    validate: Yup.mixed(),
    initialValue: data?.parent_chapter
      ? {
          id: data.parent_chapter,
          label: data?.details?.parent_chapter?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-book-chapter`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.WIKI.name,
          model: microservices.WIKI.models.chapter
        }),
    }),
  },
  {
    field: 'book',
    headerName: 'Books',
    width: 300,
    editable: true,
    onAccordionForm: true,
    onDetailCard: false,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: booksUrls.list,
    requestKey: `${data?.id}-wiki-book`,
    validate: Yup.mixed(),
    hideColumn: true,
    initialValue: data?.book
      ? {
          id: data.book,
          label: data?.details?.book?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-wiki-book`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.WIKI.name,
          model: microservices.WIKI.models.books
        }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const sectionsMeta = ({
  data,
  nextOrder,
  queryClient
} = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    onAccordionForm: true,
    type: fieldType.TEXT,
    width: 100,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder + 1,
    validate: Yup.number().required(),
    onDetailCard: false,
  },
  {
    field: 'title',
    headerName: 'Title',
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/section/',
    width: 180,
    editable: true,
    value: data && data.title,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    type: fieldType.TEXT,
    width: 300,
    editable: true,
    value: data && data.description,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'chapter',
    headerName: 'Chapter',
    width: 300,
    editable: true,
    onAccordionForm: true,
    onDetailCard: false,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: chaptersUrls.list,
    requestKey: `${data?.id}-book-chapter`,
    validate: Yup.mixed(),
    initialValue: data?.chapter
      ? {
          id: data.chapter,
          label: data?.details?.chapter?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    getDropdownForm: () => ({
        refetch: () => {
          queryClient.invalidateQueries([
            `${data?.id}-book-chapter`,
          ]);
        },
        Form: getDropdownForm({
          microservice: microservices.WIKI.name,
          model: microservices.WIKI.models.chapter
        }),
    }),
  },
  {
    field: 'html',
    headerName: 'Content',
    width: 180,
    type: fieldType.HTML,
    editable: true,
    value: data && data.html,
    initialValue: '',
    onForm: true,
    hideColumn: true,
    validate: Yup.string().nullable(),
    gridStyle: 12,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const sectionTagsMeta = ({
  data,
  nextOrder
} = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    onAccordionForm: true,
    type: fieldType.TEXT,
    width: 100,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder + 1,
    validate: Yup.number().required(),
    onDetailCard: false,
  },
  {
    field: 'tag',
    headerName: 'Tag',
    width: 300,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: tagsUrls.list,
    requestKey: `${data?.id}-book-section-tag`,
    validate: Yup.mixed().required('Please select tag'),
    initialValue: data?.tag
      ? {
          id: data.tag,
          label: data?.details?.tag?.name,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
