import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Button,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { selectProfile } from '@redux/profileSlice';
import * as Yup from 'yup';
import { updateRecord } from '@config/functions/requests';
import { bpaUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { microservices } from '@config/constants';

const { shapesUrls } = bpaUrls;

export default function ManualInstructionsPopup({
  open,
  setOpen,
  shapeId,
  shapeDetail,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    if (!shapeDetail && !shapeDetail?.payload_section) return;

    localStorage.setItem(
      'contextFields',
      JSON.stringify(
        shapeDetail?.payload_section?.payload_section_keys.map(
          (contextField) => [
            `${contextField.label}// ${contextField.type}`,
            `{{ ${contextField.key} }}`,
          ]
        )
      )
    );
  }, [shapeDetail]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => null}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>Edit Label</DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Formik
          initialValues={{
            label: shapeDetail?.label ?? '<p></p>',
          }}
          validationSchema={Yup.object({
            label: Yup.string(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);
              await updateRecord({
                url: shapesUrls.detail(shapeId),
                values: values,
                token: user?.token,
                actAs: user?.actAs,
              });

              setOpen(false);
              resetForm();
            } catch (error) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form noValidate>
              <WysiwygEditor
                initData={shapeDetail?.label ?? '<p></p>'}
                msName={microservices.NOTIFICATION.name}
                onChange={(evt) => setFieldValue('label', evt.editor.getData())}
              />

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
