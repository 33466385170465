import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { lmsUrls } from '@config/routes';
import { facultyMeta } from '@config/meta/lms';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import {
  facultyInstructorRowsMap,
  facultyCourseRowsMap,
} from '@config/handleRows/lms';
import { FacultyInstructorForm, FacultyCourseForm } from '@config/forms/lms';

const { facultiesUrls, facultyInstructorsUrls, facultyCoursesUrls } = lmsUrls;

export default function FormDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => facultyMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/universities/${recordData?.universityId}`,
                text: 'Faculties',
              },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={facultiesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Faculty Instructor'
          prefix='0m('
          columnKey='facultyInstructor'
          urls={facultyInstructorsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              FacultyInstructorForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { instructorId, ...rest } = values;

                await createRecord({
                  values: {
                    ...rest,
                    instructorId: instructorId?.id,
                    facultyId: recordId,
                  },
                  url: facultyInstructorsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={facultyInstructorRowsMap}
          urlParams={`&facultyId=${recordId}`}
        />

        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Faculty Course'
          prefix='2$'
          columnKey='facultyCourse'
          urls={facultyCoursesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              FacultyCourseForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { courseId, ...rest } = values;

                await createRecord({
                  values: {
                    ...rest,
                    courseId: courseId?.id,
                    facultyId: recordId,
                  },
                  url: facultyCoursesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={facultyCourseRowsMap}
          urlParams={`&facultyId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
