// Microservices Hosts
export const appHost = process.env.REACT_APP_APP_HOST;
export const accountsHost = process.env.REACT_APP_ACCOUNTS_HOST;
export const pullstreamHost = process.env.REACT_APP_PULLSTREAM_HOST;
export const systemHost = process.env.REACT_APP_SYSTEM_HOST;
export const inventoryHost = process.env.REACT_APP_INVENTORY_HOST;
export const ecommerceHost = process.env.REACT_APP_ECOMMERCE_HOST;
export const notificationHost = process.env.REACT_APP_NOTIFICATION_HOST;
export const listHost = process.env.REACT_APP_LIST_HOST;
export const crmHost = process.env.REACT_APP_CRM_HOST;
export const wikiHost = process.env.REACT_APP_WIKI_HOST;
export const surveyHost = process.env.REACT_APP_SURVEY_HOST;
export const formsHost = process.env.REACT_APP_FORMS_HOST;
export const logsHost = process.env.REACT_APP_LOGS_HOST;
export const driveHost = process.env.REACT_APP_DRIVE_HOST;
export const hrHost = process.env.REACT_APP_HR_HOST;
export const eDocHost = process.env.REACT_APP_EDOC_HOST;
export const digitalDocFEHost = process.env.REACT_APP_DIGITALDOC_FE_HOST;
export const learnHost = process.env.REACT_APP_LEARN_HOST;
export const pmHost = process.env.REACT_APP_PM_HOST;
export const calendarHost = process.env.REACT_APP_CALENDAR_HOST;
export const sopHost = process.env.REACT_APP_SOP_HOST;
export const cmsHost = process.env.REACT_APP_CMS_HOST;
export const webChatHost = process.env.REACT_APP_WEBCHAT_HOST;
export const webChatSocketHost = process.env.REACT_APP_WEBCHAT_SOCKET_HOST;
export const marketingHost = process.env.REACT_APP_MARKETING_HOST;
export const bpaHost = process.env.REACT_APP_BPA_HOST;
export const urlShortnerHOST = process.env.REACT_APP_URL_SHORTENER_HOST;
export const qrCodeHOST = process.env.REACT_APP_URL_QR_CODE_HOST;
export const voipHOST = process.env.REACT_APP_URL_VOIP_HOST;
export const nodeDriveHost = process.env.REACT_APP_NODE_DRIVE_HOST;
export const lmsHost = process.env.REACT_APP_LMS_HOST;
export const computeHost = process.env.REACT_APP_COMPUTE_HOST;
export const cmsV2Host = process.env.REACT_APP_CMS_V2_HOST;
export const marketingV2Host = process.env.REACT_APP_MARKETING_V2_HOST;
export const chatHost = process.env.REACT_APP_CHAT_HOST;
