export function getActionParamAndValue({
  key,
  multipleValues,
  actionParams,
} = {}) {
  const param = (actionParams ?? []).find((row) => row.key === key);

  if (!param) {
    return null;
  }

  const values = param?.shape_action_parameter_values;

  if (multipleValues) {
    return {
      values,
      param,
    };
  }

  const [value] = values;

  return {
    value,
    param,
  };
}

export function toggleStageVisibility(stageIds, visible, setStageStates) {
  setStageStates((prevStates) => {
    const updatedStates = prevStates.map((stage) => {
      if (stageIds.includes(stage.id)) {
        return { ...stage, visible: visible ?? !stage.visible };
      }
      return stage;
    });

    // Save the updated states in localStorage
    localStorage.setItem(
      'automataStageStates',
      JSON.stringify(
        updatedStates.reduce((acc, curr) => {
          acc[curr.id] = curr.visible;
          return acc;
        }, {})
      )
    );

    return updatedStates;
  });
}


export function getInitialParamValue(key, actionParams, multipleValues = false) {
  return getActionParamAndValue({ key, actionParams, multipleValues });
};
