import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
} from '@mui/material';
import { AutocompleteFieldV2 } from '@components/Inputs';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import useToast from '@hooks/useToast';
import { createRecord } from '@config/functions/requests';
import { pmUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { useQueryClient } from 'react-query';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const { bulkCreateStagesUrls, programsUrls, projectsUrls } = pmUrls;

export default function ImportStagesModal({
  open,
  setOpen,
  workflowId,
  labels,
  editMode,
  prefix,
}) {
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [programId, setProgramId] = React.useState('');
  const [notify] = useToast();

  return (
    <Dialog maxWidth="sm" fullWidth={true} open={open}>
      {prefix ? (
        <RenderUiPrefix
          editMode={editMode}
          labels={labels}
          code={prefix}
          defaultValue="Import Stages"
        >
          {(text) => {
            return (
              <DialogTitle sx={{ pointerEvents: 'none' }}>{text}</DialogTitle>
            );
          }}
        </RenderUiPrefix>
      ) : (
        <DialogTitle sx={{ pointerEvents: 'none' }}>Import Stages</DialogTitle>
      )}
      <DialogContent maxWidth="sm" fullWidth sx={{ pt: '0px' }}>
        <Formik
          initialValues={{
            project: null,
          }}
          validationSchema={Yup.object({
            project: Yup.object().required().nullable(),
          })}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setFieldError }
          ) => {
            try {
              await createRecord({
                values: {
                  workflow_id: workflowId,
                },
                url: bulkCreateStagesUrls.detail(values.project.id),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setOpen(false);
              setProgramId('');
              queryClient.invalidateQueries({
                queryKey: ['Stages-listData'],
              });
            } catch (err) {
              console.log(err);
              notify(`There was an error, please try again.`, {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate autoComplete="off" style={{ paddingTop: '8px' }}>
              <Grid sx={{ paddingBottom: '22px' }} container spacing={3}>
                <Grid item sm={6}>
                  <AutocompleteFieldV2
                    name="program"
                    label="Programs"
                    requestKey="workflow-programs"
                    fetchUrl={programsUrls.list}
                    urlParams={`&ordering=created_at`}
                    onChange={(programId) => setProgramId(programId.id || '')}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.name,
                    })}
                    required
                  />
                </Grid>
                <Grid item sm={6}>
                  <AutocompleteFieldV2
                    name="project"
                    label="Projects"
                    requestKey="workflow-projects"
                    requestKeyOptions={[programId]}
                    fetchUrl={projectsUrls.list}
                    urlParams={`&ordering=created_at&program=${programId}`}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.name,
                    })}
                    required
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
