import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { inventoryUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { SupplierForm } from '@config/forms/inventory';
import { microservices } from '@config/constants';
import { supplierRowMap } from '@config/handleRows/inventory';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { suppliersUrls } = inventoryUrls;

export default function Suppliers({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label="Suppliers"
        prefix="0rk"
        labels={labels}
        editMode={editMode}
        urls={suppliersUrls}
        microservice={microservices.INVENTORY.name}
        model={microservices.INVENTORY.models.supplier}
        columnKey="supplierInventory"
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            SupplierForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { supplier_company, ...rest } = values;

              const response = await createRecord({
                values: {
                  ...rest,
                  supplier_company: supplier_company?.id,
                },
                url: suppliersUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={supplierRowMap}
      />
    </Box>
  );
}
