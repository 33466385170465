import * as React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { sopsUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { SOPsMeta } from '@config/meta/SOPs/SOPsMeta';
import { sopTaskRowsMap } from '@config/handleRows/sops';
import { SopTaskForm } from '@config/forms/sops';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useParams } from 'react-router-dom';

const { sopTasksUrls, sopUrls } = sopsUrls;

export default function SopDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const [sopTasksListData, setSopTasksListData] = React.useState({});
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => SOPsMeta({ data }), []);

  const SOPsTaskColOptions = React.useMemo(
    () => ({
      nextOrder: sopTasksListData?.totalCount ?? 1,
    }),
    [sopTasksListData?.totalCount]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[{ to: '/sops', text: 'SOPs' }, { text: recordData?.name }]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={sopUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.SOP.name}
          model={microservices.SOP.models.sopTask}
          columnKey='SOPsTask'
          columnOptions={SOPsTaskColOptions}
          label='SOP Task'
          prefix='0qb'
          labels={labels}
          editMode={editMode}
          urls={sopTasksUrls}
          addNewForm={{
            getFields: () => SopTaskForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                await createRecord({
                  values: { ...values, SOP: recordId },
                  url: sopTasksUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={sopTaskRowsMap}
          urlParams={`&SOP=${recordId}`}
          customSortColumnName='order'
          sortColumnBy='asc'
          getListData={(data) => setSopTasksListData(data)}
        />
      </Box>
    </Box>
  );
}
