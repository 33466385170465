import React from 'react';
import { Box } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { selectTasksBoard, setTasks } from '@redux/tasksBoardSlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { pmUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { updateRecord } from '@config/functions/requests';
import {
  handleDragEnd,
  formatDate,
  getLastSelectedDropdownOption,
} from '@config/functions/helperFunctions';

import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import PipelineStagesList from '@components/PipelineStagesList';
import TextField from '@mui/material/TextField';
import ErrorScreen from '@components/ErrorScreen';

const {
  sprintStagesUrls,
  sprintTasksUrls,
  tasksUrls,
  sprintMetaUrls,
  sprintsUrls,
  resourcesUrls,
  taskStatusUrls,
} = pmUrls;

export default function SprintTasksBoard({ labels, editMode }) {
  const { tasks } = useSelector(selectTasksBoard);
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [notify, update] = useToast();
  const initialSprintMeta = getLastSelectedDropdownOption(
    'lastSelectedSprintMeta'
  );
  const initialResource = getLastSelectedDropdownOption(
    'lastSelectedSprintResource'
  );

  const containerRef = React.useRef(null);
  const initialSprint = getLastSelectedDropdownOption('lastSelectedSprint');
  const [sprintMeta, setSprintMeta] = React.useState(initialSprintMeta);
  const [resource, setResource] = React.useState(initialResource);
  const [sprint, setSprint] = React.useState(initialSprint);
  const [stages, setStages] = React.useState([]);
  const [editPipeline, setEditPipeline] = React.useState(false);
  const [width, setWidth] = React.useState(290);

  const { data, isLoading, refetch, isError } = useRQuery({
    key: ['tasks-board', resource, sprintMeta, sprint, user.token, user?.actAs],
    url: sprintStagesUrls.list(
      `?item_order=task__order&page_size=500${
        resource?.id ? `&owner=${resource.id}` : ''
      }${sprintMeta ? `&sprint_meta=${sprintMeta.id}` : ''}${
        sprint?.id ? `&sprint=${sprint.id}` : ''
      }`
    ),
    options: {
      enabled: !!(user.token && sprintMeta),
      refetchOnWindowFocus: true,
    },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleSaveCardDrag = async ({ newList, removedItem, destId }) => {
    const tId = notify('Saving....', { loading: true });
    dispatch(setTasks(newList));
    try {
      await updateRecord({
        url:
          sprintMeta?.id && !sprint?.id
            ? sprintTasksUrls.detail(removedItem.sprint_task_id)
            : tasksUrls.detail(removedItem.id),
        values:
          sprintMeta?.id && !sprint?.id
            ? { sprint: destId }
            : { status: destId },
        token: user.token,
        actAs: user?.actAs,
      });
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  const savePipeline = async () => {
    const tId = notify('Saving....', { loading: true });
    try {
      await Promise.all(
        stages.map((s) =>
          updateRecord({
            url: taskStatusUrls.detail(s.id),
            values: {
              order: s.order,
              name: s.name,
              rotting_days: s.rotting_days,
            },
            token: user.token,
            actAs: user?.actAs,
          })
        )
      );
      update(tId, 'Your changes were saved');
      setEditPipeline(false);
      refetch();
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    if (data) {
      setStages(data);
      dispatch(setTasks(data));
    }
  }, [data, dispatch]);

  React.useEffect(() => {
    const w = localStorage.getItem('ps-opp-width');
    if (w) setWidth(+w);
  }, []);

  if (isError) {
    return (
      <ErrorScreen text='Failed to fetch the resource from server' img='' />
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title='Sprints'
        prefix='0c3'
        labels={labels}
        editMode={editMode}
        setEditPipeline={setEditPipeline}
        showEditPipeline={!!sprint}
        editPipeline={editPipeline}
        savePipeline={savePipeline}
        content2={
          <Box>
            <TextField
              sx={{ width: '80px', mr: 2, ml: 2 }}
              size='small'
              label='Width'
              variant='outlined'
              type='number'
              value={width}
              onChange={(e) => {
                setWidth(e.target.value);
                localStorage.setItem('ps-bugs-width', e.target.value);
              }}
              autoComplete='off'
            />
          </Box>
        }
        filterOptions={[
          {
            label: 'Sprint Meta',
            name: 'sprintMeta',
            requestKey: 'sprint-tasks-board-projects',
            fetchUrl: sprintMetaUrls.list,
            handleChange: (newValue) => {
              setSprintMeta(newValue);
              localStorage.setItem(
                'lastSelectedSprintMeta',
                JSON.stringify(newValue)
              );
            },
            initialValue: initialSprintMeta,
          },
          {
            label: 'Sprint',
            name: 'sprint',
            requestKey: 'sprint-tasks-board-sprints',
            fetchUrl: sprintsUrls.list,
            setCustomOptions: (data) => [
              {
                id: null,
                label: 'All',
              },
              ...data,
            ],
            handleChange: (newValue) => {
              setSprint(newValue);
              localStorage.setItem(
                'lastSelectedSprint',
                JSON.stringify(newValue)
              );
            },
            urlParams: `&ordering=start_date${
              sprintMeta ? `&sprint_meta=${sprintMeta.id}` : ''
            }`,
            renderRow: (r) => ({
              id: r.id,
              label: formatDate(new Date(r.start_date)),
            }),
            initialValue: initialSprint,
          },
          {
            label: 'Resource',
            name: 'resource',
            requestKey: 'sprint-tasks-board-resources',
            fetchUrl: resourcesUrls.list,
            setCustomOptions: (data) => [
              {
                id: null,
                label: 'All',
              },
              ...data,
            ],
            handleChange: (newValue) => {
              setResource(newValue);
              localStorage.setItem(
                'lastSelectedSprintResource',
                JSON.stringify(newValue)
              );
            },
            renderRow: (r) => ({
              id: r.id,
              label: r.email,
            }),
            orderingCol: 'email',
            initialValue: initialResource,
          },
        ]}
      />

      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            ref={containerRef}
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            {editPipeline ? (
              <PipelineStagesList
                detailLink='tasks'
                stages={stages}
                setStages={setStages}
              />
            ) : (
              <DragDropContext
                onDragEnd={(res) =>
                  handleDragEnd(res, tasks, handleSaveCardDrag)
                }
              >
                <KanbanBoardList
                  detailLink='tasks'
                  data={tasks}
                  width={+width}
                  headerLabel={`label`}
                  sprintMeta={sprintMeta}
                  containerRef={containerRef}
                  labels={labels}
                  editMode={editMode}
                />
              </DragDropContext>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
