import React from 'react';
import * as Yup from 'yup';
import { formsUrls } from '@config/routes';
import { questionOptionGroupMeta } from '@config/meta/forms/formsMeta';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import QuestionDropdown from '@dropdown-forms/form/QuestionDropdown';
import { useQueryClient } from 'react-query';

const { questionOptionGroupsUrls, questionsUrls } = formsUrls;

export default function QuestionOptionGroupDropdown({
  children,
  refetch,
  question: initialQuestion,
}) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title='Option Group'
        initialValues={{
          ...getAddFormInitialValues(
            questionOptionGroupMeta(),
            'DetailAccordionView'
          ),
          questionId: initialQuestion,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(
            questionOptionGroupMeta(),
            'DetailAccordionView'
          ),
        })}
        getPostUrl={() => questionOptionGroupsUrls.list()}
        getValues={({ questionId, ...values }) => ({
          ...values,
          questionId: questionId?.id,
        })}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key='name' label='Name' name='name' required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                key='description'
                label='Description'
                name='description'
              />
            </Grid>
            <Grid item xs={12}>
              <QuestionDropdown
                refetch={({ id, question }) => {
                  setFieldValue('questionId', {
                    id: id,
                    label: `${question || ''}`,
                  });
                  queryClient.invalidateQueries(['question-dropdown-forms']);
                }}
              >
                <AutocompleteFieldV2
                  name='questionId'
                  label='Question'
                  requestKey='question-form'
                  fetchUrl={questionsUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.question,
                  })}
                  required
                />
              </QuestionDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
