import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  TextField,
  DateTimePickerField,
  SelectField,
} from "@components/Inputs";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Grid, MenuItem } from "@mui/material";
import {
  calendarRepeatEveryChoicesOptions,
  // calendarReversedRepeatEveryChoicesOptions,
  calendarRepeatOnChoicesOptions,
} from "@config/constants";
import { parseRRule } from "@config/functions/helperFunctions";
import RenderUiPrefix from "@components/core/RenderUiPrefix";
import moment from "moment";

export default function CustomRecurranceModalWithDelete({
  open,
  setOpen,
  oSave,
  rrule,
  labels,
  editMode,
  prefix,
  showCancel = true,
  readOnly = false,
  taskRecurring = false,
  customFormProps = (props) => props,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpen(false);
  };

  let rruleObj = rrule && rrule.length ? parseRRule(rrule) : null;
  const formProps = customFormProps({
    ends: {
      default: "never",
      neverEnabled: true,
      unitlEnabled: true,
      countEnabled: true,
      countMax: null,
    },
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {prefix ? (
        <RenderUiPrefix
          editMode={editMode}
          labels={labels}
          code={prefix}
          defaultValue="Custom recurrence"
        >
          {(text) => {
            return (
              <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
            );
          }}
        </RenderUiPrefix>
      ) : (
        <DialogTitle id="responsive-dialog-title">
          Custom recurrence
        </DialogTitle>
      )}
      <DialogContent>
        <Formik
          key="recurrance-model-formik"
          initialValues={{
            by: "deadline",
            repeat_every: rruleObj ? rruleObj.INTERVAL || 1 : 1,
            ends:
              rruleObj && !rruleObj.UNTIL && !rruleObj.COUNT
                ? "never"
                : rruleObj && rruleObj.UNTIL && !rruleObj.COUNT
                ? "on"
                : rruleObj && !rruleObj.UNTIL && rruleObj.COUNT
                ? "after"
                : formProps?.ends?.default,
            repeat_unit: rruleObj
              ? rruleObj["RRULE:FREQ"]
              : calendarRepeatEveryChoicesOptions[1].value,
            repeat_on:
              rruleObj && rruleObj.BYDAY ? rruleObj.BYDAY.split(",") : [],
            end_date:
              rruleObj && rruleObj.UNTIL
                ? typeof rruleObj.UNTIL === "string"
                  ? moment(rruleObj.UNTIL, "YYYYMMDDTHHmmssZ").toDate()
                  : rruleObj.UNTIL
                : new Date(),
            end_after_occurences:
              rruleObj && rruleObj.COUNT ? rruleObj.COUNT : 1,
          }}
          validationSchema={Yup.object({
            by: Yup.string(),
            ends: Yup.string(),
            repeat_unit: Yup.string(),
            repeat_every: Yup.number(),
            repeat_on: Yup.array().required(),
            end_date: Yup.date().default(() => new Date()),
            end_after_occurences: formProps?.ends?.countMax
              ? Yup.number().min(0).max(formProps?.ends?.countMax)
              : Yup.number().min(0),
          })}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            setSubmitting(true);
            let newRrule = `RRULE:FREQ=${values.repeat_unit};INTERVAL=${values.repeat_every}`;

            if (values.repeat_on.length) {
              newRrule = `${newRrule};BYDAY=${values.repeat_on.toString()}`;
            }

            if (values.ends === "after") {
              newRrule = `${newRrule};COUNT=${values.end_after_occurences}`;
            }

            if (values.ends === "on") {
              const untilDate = new Date(values.end_date);

              const year = untilDate.getFullYear();
              const month = (untilDate.getMonth() + 1)
                .toString()
                .padStart(2, "0"); // Adding 1 to month and padding with leading zero if necessary
              const day = untilDate.getDate().toString().padStart(2, "0"); // Padding day with leading zero if necessary

              newRrule = `${newRrule};UNTIL=${year}${month}${day}`;
            }

            oSave(newRrule, values.by);
            setSubmitting(false);
          }}
        >
          {({ values, submitting, handleChange }) => (
            <Form noValidate autoComplete="off">
              <Grid
                key="recurrance-grid-container"
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ mb: 2 }}
              >
                <Grid item xs={3} sx={{ mt: 2 }}>
                  <TextField
                    label="Repeat Every"
                    name="repeat_every"
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>

                <Grid item xs={9} sx={{ mt: 2 }}>
                  <SelectField
                    key="repeat-every-select-field"
                    name="repeat_unit"
                    label="Repeat Unit"
                    value={values.repeat_unit}
                    readOnly={readOnly}
                  >
                    {calendarRepeatEveryChoicesOptions &&
                      calendarRepeatEveryChoicesOptions.map((o, oIdx) => (
                        <MenuItem value={o.value} key={oIdx.toString()}>
                          {o.label}
                        </MenuItem>
                      ))}
                  </SelectField>
                </Grid>

                <Grid item xs={12}>
                  <SelectField
                    key="repeat-on-select-field"
                    label="Repeat On"
                    name="repeat_on"
                    value={values.repeat_on}
                    readOnly={readOnly}
                    multiple
                  >
                    {calendarRepeatOnChoicesOptions &&
                      calendarRepeatOnChoicesOptions.map((o, oIdx) => (
                        <MenuItem value={o.value} key={oIdx.toString()}>
                          {o.label}
                        </MenuItem>
                      ))}
                  </SelectField>
                </Grid>

                {taskRecurring && !readOnly ? (
                  <Grid item xs={12}>
                    <Box>
                      <FormControl>
                        <FormLabel id="recurrence-by-label">
                          Recurrence By
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="recurrence-by-label"
                          name="by"
                          value={values.by}
                          onChange={handleChange}
                          readOnly={readOnly}
                        >
                          <FormControlLabel
                            value="deadline"
                            control={<Radio />}
                            label="Deadline"
                          />
                          <FormControlLabel
                            value="started"
                            control={<Radio />}
                            label="Started"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                ) : null}

                <Grid
                  item
                  xs={12}
                  sx={{
                    paddingTop:
                      taskRecurring && !readOnly
                        ? "10px !important"
                        : "inherit",
                  }}
                >
                  <Box sx={{ mt: 1 }}>
                    <FormControl>
                      <FormLabel id="ends-label">Ends</FormLabel>
                      <RadioGroup
                        aria-labelledby="ends-label"
                        name="ends"
                        value={values.ends}
                        onChange={handleChange}
                        readOnly={readOnly}
                      >
                        {formProps?.ends?.neverEnabled ? (
                          <FormControlLabel
                            value="never"
                            control={<Radio />}
                            label="Never"
                          />
                        ) : null}
                        {formProps?.ends?.unitlEnabled ? (
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              <FormControlLabel
                                value="on"
                                control={<Radio />}
                                label="On"
                              />
                            </Box>
                            <Box sx={{ mt: "5px", ml: 6, width: "160px" }}>
                              <DateTimePickerField
                                name="end_date"
                                disabled={values.ends !== "on"}
                              />
                            </Box>
                          </Box>
                        ) : null}
                        {formProps?.ends?.countEnabled ? (
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              <FormControlLabel
                                value="after"
                                control={<Radio />}
                                label="After"
                              />
                            </Box>
                            <Box sx={{ mt: "5px", ml: 4, width: "85px" }}>
                              <TextField
                                name="end_after_occurences"
                                disabled={values.ends !== "after"}
                                type="number"
                                InputProps={{
                                  readOnly: readOnly,
                                }}
                              />
                            </Box>
                          </Box>
                        ) : null}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <DialogActions sx={{ justifyContent: "space-between", pl: 0 }}>
                <Button autoFocus color="error" onClick={() => oSave("", "")}>
                  Delete
                </Button>
                <Box>
                  {readOnly ? (
                    <Button autoFocus onClick={handleClose}>
                      Close
                    </Button>
                  ) : (
                    <>
                      {showCancel ? (
                        <Button autoFocus onClick={handleClose}>
                          Cancel
                        </Button>
                      ) : null}

                      <Button type="submit" autoFocus disabled={submitting}>
                        Save
                      </Button>
                    </>
                  )}
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
