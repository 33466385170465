import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Forms from '@pages/Forms/FormsList';
import FormDetail from '@pages/Forms/FormsList/detail';
import QuestionDetail from '@pages/Forms/Questions/detail';
import QuestionOptionDetail from '@pages/Forms/QuestionOptions/detail';
import FormResponseDetail from '@pages/Forms/FormResponse/detail';
import FormPersonalityDetail from '@pages/Forms/FormPersonality/detail';
import QuestionOptionGroupsDetail from '@pages/Forms/QuestionOptionGroups/detail';
import QuestionGroupScoreRangesDetail from '@pages/Forms/QuestionGroupScoreRanges/detail';
import GroupDetail from '@pages/Forms/Groups/detail';
import ViewForm from '@pages/Forms/ViewForm';
import RecipientForm from '@pages/Forms/RecipientForm';

export default function FormRoutes() {
  return (
    <>
      <Route path='/forms' element={<WithLayout comp={Forms} />} />

      <Route path='/forms/:id' element={<WithLayout comp={FormDetail} />} />

      <Route
        path='/questions/:id'
        element={<WithLayout comp={QuestionDetail} />}
      />

      <Route
        path='/question-options/:id'
        element={<WithLayout comp={QuestionOptionDetail} />}
      />

      <Route
        path='/form-groups/:id'
        element={<WithLayout comp={GroupDetail} />}
      />

      <Route path='/view-form/:id' element={<ViewForm />} />

      <Route path='/recipient-form/:id' element={<RecipientForm />} />

      <Route
        path='/form-responses/:id'
        element={<WithLayout comp={FormResponseDetail} />}
      />

      <Route
        path='/form-personalities/:id'
        element={<WithLayout comp={FormPersonalityDetail} />}
      />

      <Route
        path='/question-option-groups/:id'
        element={<WithLayout comp={QuestionOptionGroupsDetail} />}
      />

      <Route
        path='/question-group-score-ranges/:id'
        element={<WithLayout comp={QuestionGroupScoreRangesDetail} />}
      />
    </>
  );
}
