import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { communicationUrls } from '@config/routes';
import { emailRowsMap } from '@config/handleRows/communication';
import { microservices } from '@config/constants';
import AuthWrapper from '@components/Communication/AuthWrapper';

const { notificationsUrls } = communicationUrls;
function SentEmails({
  performingAction,
  setPerformingAction,
  labels,
  editMode,
}) {
  const { id: routerId } = useParams();

  return (
    <>
      <Box>
        <ListView
          label="Sent Emails"
          prefix="02r"
          labels={labels}
          editMode={editMode}
          showBulkDeleteButton={false}
          showVisibilityInPageHeader={false}
          showVisibility={true}
          showDeleteBtn={false}
          microservice={microservices.NOTIFICATION.name}
          model={microservices.NOTIFICATION.models.notifications}
          performingAction={performingAction}
          setPerformingAction={setPerformingAction}
          urls={notificationsUrls}
          columnKey="emailCommunication"
          hideActions
          handleRowsMap={emailRowsMap}
          customSortColumnName={'sent_date_time'}
          urlParams={`&sent=true&imap_account=${routerId}`}
        />
      </Box>
    </>
  );
}

export default function AuthenticatedSentEmails(props) {
  return (
    <AuthWrapper>
      <SentEmails {...props} />
    </AuthWrapper>
  );
}
