import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { crmUrls } from '@config/routes';
import { territoriesMeta } from '@config/meta/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import {
  territoryOwnersRowsMap,
  territoryCompaniesRowsMap,
  territoryCompanyContactRowsMap,
} from '@config/handleRows/crm';
import { TerritoryOwnersForm, CompaniesForm } from '@config/forms/crm';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  territoriesUrls,
  territoryOwnersUrls,
  companiesUrls,
  companyContactUrls,
  companyTerritoriesUrls,
} = crmUrls;

export default function TerritoryDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => territoriesMeta({ data }), []);

  const companyContactsColOptions = React.useMemo(() => {
    return {
      showCompany: true,
    };
  }, []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/territories', text: 'Territories' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={territoriesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.territoryOwner}
          columnKey="territoryOwnerCRM"
          label="Owner"
          editMode={editMode}
          labels={labels}
          prefix="0s5"
          urls={territoryOwnersUrls}
          addNewForm={{
            getFields: ({ setFieldValue, values }) =>
              TerritoryOwnersForm({
                setFieldValue,
                values,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { sales_person, ...rest } = values;

                const response = await createRecord({
                  values: {
                    ...rest,
                    territory: recordId,
                    sales_person: sales_person?.id,
                  },
                  url: territoryOwnersUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={territoryOwnersRowsMap}
          urlParams={`&territory=${recordId}`}
        />

        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.company}
          columnKey="companiesCRM"
          label="Companies"
          editMode={editMode}
          labels={labels}
          prefix="0z4"
          urls={companyTerritoriesUrls}
          getCustomUrl={({ row }) => companyTerritoriesUrls.detail(row.id)}
          addNewForm={{
            getFields: () => CompaniesForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values,
                  url: companiesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={territoryCompaniesRowsMap}
          urlParams={`&territory=${recordId}`}
        />

        <DetailAccordionView
          prefix="0g5"
          labels={labels}
          editMode={editMode}
          label="Company Contacts"
          urls={companyContactUrls}
          showDeleteBtn={false}
          showBulkDeleteButton={false}
          tableBulkOptions={[
            { id: 'addToMarketingList', text: 'Add to marketing list' },
          ]}
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.companyContacts}
          columnKey="companyContactCRM"
          columnOptions={companyContactsColOptions}
          handleRowMap={territoryCompanyContactRowsMap}
          urlParams={`&territory=${recordId}`}
        />
      </Box>
    </Box>
  );
}
