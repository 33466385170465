import React from 'react';
import * as Yup from 'yup';
import { listsUrls } from '@config/routes';
import { languageMeta } from '@config/meta/list/index';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { languagesUrls } = listsUrls;

export default function LanguageDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const [hideDelivery] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        hidden={hideDelivery}
        title='Language'
        initialValues={{
          ...getAddFormInitialValues(languageMeta({}), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(languageMeta({}), 'listView'),
        })}
        getPostUrl={() => languagesUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={2}>
              <TextField label='Iso_639_1' name='iso_639_1' />
            </Grid>

            <Grid item xs={2}>
              <TextField label='Iso_639_2' name='iso_639_2' />
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <TextField label='English' name='english' required />
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <TextField label='French' name='french' />
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <TextField label='German' name='german' />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
