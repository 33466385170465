import React from 'react';
import { Grid, Button } from '@mui/material';
import {
  TextField,
  UploadField,
  CheckBoxField,
  DateTimePickerField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';
import { communicationUrls, systemUrls, crmUrls } from '@config/routes';
import { useQueryClient } from 'react-query';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import MapFieldsPopup from '@components/eDoc/MapFieldsPopup';

const { mailTemplatesUrls } = communicationUrls;
const { personsUrls } = crmUrls;
const { templatesUrls } = systemUrls;

export const DocumentsForm = ({ microservice, model }) => {
  return (
    <>
      <Grid item xs={2}>
        <TextField label='Name' name='name' multiline required />
      </Grid>

      <Grid item xs={3}>
        <TextField label='Description' name='description' multiline />
      </Grid>

      <Grid item sm={4}>
        <AutocompleteFieldV2
          name='template'
          label='Template To Copy From'
          requestKey='forms-templates'
          fetchUrl={templatesUrls.list}
          urlParams={`&ordering=created_at&microservice_name=${microservice}&model_name=${model}`}
          renderRow={(row) => ({
            id: row?.id,
            label: row?.name,
          })}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField label='Tags' name='tags' multiline />
      </Grid>

      <Grid item xs={4}>
        <UploadField
          name='document'
          required
          accept={{ 'application/pdf': ['.pdf', '.PDF'] }}
        />
      </Grid>

      <Grid item sm={1}>
        <CheckBoxField label='Publish' name='isPublished' />
      </Grid>
    </>
  );
};

export const RecipientForm = ({
  setFieldValue,
  documentFieldDefns = [],
  values,
} = {}) => {
  const queryClient = useQueryClient();
  const [showFieldMapping, setShowFieldMapping] = React.useState({
    open: false,
    data: {},
  });

  return (
    <>
      <Grid item xs={4}>
        <AutocompleteFieldV2
          name='emailTemplateId'
          label='Email Template'
          requestKey='doc-recipient-email-templates'
          fetchUrl={mailTemplatesUrls.list}
          required
        />
      </Grid>

      <Grid item xs={4}>
        <PersonDropdown
          refetch={({ id, email }) => {
            setFieldValue('personId', {
              id: id,
              label: `${email || ''}`,
            });
            queryClient.invalidateQueries(['recipients-persons']);
          }}
        >
          <AutocompleteFieldV2
            name='personId'
            label='Person'
            requestKey='recipients-persons'
            fetchUrl={personsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.email}`,
              email: row?.email,
              first_name: row?.first_name,
              last_name: row?.last_name,
            })}
            onChange={(value) => {
              setFieldValue('firstName', value?.first_name);
              setFieldValue('lastName', value?.last_name);
              setFieldValue('email', value?.email);
            }}
          />
        </PersonDropdown>
      </Grid>

      <Grid item xs={2}>
        <TextField label='First name' name='firstName' multiline required />
      </Grid>

      <Grid item xs={2}>
        <TextField label='Last name' name='lastName' multiline />
      </Grid>

      <Grid item xs={3}>
        <TextField label='Email' name='email' multiline required />
      </Grid>

      <Grid item xs={2}>
        <CheckBoxField label='Add expiry date' name='addExpiryDate' />
      </Grid>
      {values.addExpiryDate ? (
        <Grid item sm={1.5}>
          <DateTimePickerField
            label='Expiry date'
            showTime
            disablePast
            name='expiryDate'
            showTimezone
          />
        </Grid>
      ) : null}

      <Grid item sx={{ display: 'flex', alignItems: 'center' }} sm={2.5}>
        <Button
          size='small'
          variant='outlined'
          onClick={() => {
            setShowFieldMapping({ open: true, data: documentFieldDefns });
          }}
          startIcon={<CreateOutlinedIcon fontSize='small' />}
        >
          Map fields
        </Button>

        <MapFieldsPopup
          open={!!showFieldMapping?.open}
          documentFields={showFieldMapping?.data}
          handleSave={(values) => {
            console.log('values', values);
            setFieldValue('documentFieldDefnValues', values);
          }}
          setOpen={(open) => setShowFieldMapping({ open, data: {} })}
        />
      </Grid>
    </>
  );
};
