import * as Yup from 'yup';
import { fieldType } from '@config/constants';
import {
  formatToUTCDate,
  formatToUTCDateTime,
} from '@config/functions/helperFunctions';

export const systemLogsMeta = () => [
  {
    field: 'file_name',
    headerName: 'File Name',
    width: 400,
    editable: false,
  },
  {
    field: 'microservice',
    headerName: 'Microservice',
    width: 200,
    editable: false,
  },
  {
    field: 'logs_date',
    headerName: 'Log Date',
    width: 150,
    editable: false,
  },
];

export const logMeta = (users, setUserTerm, usersIsFetching) => [
  {
    field: 'user',
    headerName: 'User',
    type: 'autocomplete',
    setSearchTerm: (value) => setUserTerm(value),
    options: users.map((pt) => ({
      label: pt.label,
      id: pt.id,
    })),
    isLoading: usersIsFetching,
    width: 200,
    editable: false,
  },
  {
    field: 'microservice',
    headerName: 'Microservice',
    width: 200,
    editable: false,
  },
  {
    field: 'model',
    headerName: 'Model',
    width: 150,
    editable: false,
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 150,
    editable: false,
  },
  {
    field: 'action_datetime',
    headerName: 'Action Date Time',
    width: 150,
    editable: false,
  },
  {
    field: 'before_action_content',
    headerName: 'Before Action Content',
    width: 150,
    editable: false,
  },
  {
    field: 'after_action_content',
    headerName: 'After Action Content',
    width: 150,
    editable: false,
  },
];

export const importLogMeta = (data) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: false,
    width: 170,
    type: fieldType.LINK,
    to: 'import-logs/',
    editable: false,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'microservice',
    headerName: 'Microservice',
    onForm: false,
    editable: false,
    value: data ? data.microservice : null,
    width: 170,
    validate: Yup.string().required(),
  },
  {
    field: 'model',
    headerName: 'Model',
    onForm: false,
    editable: false,
    value: data ? data.model : null,
    width: 170,
    validate: Yup.string().required(),
  },
  {
    field: 'total_rows',
    headerName: 'Total Rows',
    onForm: false,
    editable: false,
    value: data ? data.total_rows : null,
    width: 120,
    validate: Yup.number().required(),
  },
  {
    field: 'imported',
    headerName: 'Imported',
    onForm: false,
    editable: false,
    value: data ? data.imported : null,
    width: 120,
    validate: Yup.number().required(),
  },
  {
    field: 'failed_count',
    headerName: 'Failed',
    onForm: false,
    editable: false,
    value: data ? data.failed_count : null,
    width: 120,
    validate: Yup.number().required(),
  },
  {
    field: 'started_at',
    headerName: 'Started At',
    type: fieldType.DATE_TIME,
    editable: false,
    onForm: false,
    width: 170,
    value: data ? formatToUTCDateTime(new Date(data.started_at)) : null,
    validate: Yup.string().required(),
  },
  {
    field: 'finished_at',
    headerName: 'Finished At',
    type: fieldType.DATE_TIME,
    editable: false,
    onForm: false,
    width: 170,
    value: data ? formatToUTCDateTime(new Date(data.finished_at)) : null,
    validate: Yup.string().required(),
  },
];

export const importErrorLogMeta = (data) => [
  {
    field: 'row',
    headerName: 'Row',
    onForm: false,
    width: 100,
    editable: false,
    value: data ? data.row : null,
    validate: Yup.string().required(),
  },
  {
    field: 'field',
    headerName: 'Field',
    onForm: false,
    width: 200,
    editable: false,
    value: data ? data.field : null,
    validate: Yup.string().required(),
  },
  {
    field: 'error',
    headerName: 'Error',
    onForm: false,
    width: 600,
    editable: false,
    value: data ? data.error : null,
    validate: Yup.string().required(),
  },
];

export const visitorLogMeta = (data) => [
  {
    field: 'ip',
    headerName: 'Ip Address',
    onForm: false,
    width: 170,
    // type: fieldType.LINK,
    // to: '/visitor-logs/',
    editable: false,
    value: data ? data.ip : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'utm_campaign',
    headerName: 'UTM Campaign',
    onForm: false,
    width: 170,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.Campaign : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'utm_medium',
    headerName: 'UTM Medium',
    onForm: false,
    width: 170,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.Medium : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'utm_source',
    headerName: 'UTM Source',
    onForm: false,
    width: 170,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.Source : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'location',
    headerName: 'Location',
    onForm: false,
    width: 170,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.location : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'city',
    headerName: 'City',
    onForm: false,
    width: 170,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.city : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'country',
    headerName: 'Country',
    onForm: false,
    width: 170,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.country : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    type: fieldType.DATE_TIME,
    editable: true,
    width: 200,
    onForm: true,
    initialValue: formatToUTCDate(new Date()),
    value:
      data && data.created_at
        ? formatToUTCDate(new Date(data.created_at))
        : null,
    validate: Yup.string(),
  },
];
