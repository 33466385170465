import * as React from 'react';
import Select from '@mui/material/Select';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

export default function SelectField({
  children,
  id,
  label,
  error,
  errorMessage,
  value,
  ...rest
}) {
  return (
    <FormControl
      size='small'
      error={error}
      variant={rest.variant || 'outlined'}
      sx={{ minWidth: 120, width: '100%' }}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select {...rest} value={value} label={label} fullWidth>
        {children}
      </Select>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
