import React from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { microservices } from '@config/constants';
import { TasksForm } from '@config/forms/pm';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { createRecord } from '@config/functions/requests';
import { tasksRowsMap } from '@config/handleRows/pm';
import { pmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from './DetailAccordionView';
import SubtasksList from './SubtasksList';

const { tasksUrls } = pmUrls;
// const { bulkCreateActionReminderUrls } = calendarUrls;

export default function ProjectTasksAccordion({
  projectId,
  programId,
  projectName,
  programName,
  label,
  getValues,
  urlParams,
  editMode,
  labels,
  prefix,
}) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [taskListData, setTaskListData] = React.useState({});

  const tasksColOptions = React.useMemo(() => {
    return {
      nextOrder: taskListData?.totalCount ?? 1,
      projectId,
    };
  }, [taskListData?.totalCount, projectId]);

  return (
    <DetailAccordionView
      microservice={microservices.PM.name}
      model={microservices.PM.models.task}
      editMode={editMode}
      labels={labels}
      prefix={prefix}
      label={label}
      urls={tasksUrls}
      tableBulkOptions={[{ id: 'addTaskToSprint', text: 'Add to sprint' }]}
      columnKey='tasksPM'
      columnOptions={tasksColOptions}
      customValues={{
        rebase_task_order: true,
      }}
      addNewForm={{
        getFields: ({ values, setFieldValue }) =>
          TasksForm({
            // setRepeat,
            programId,
            projectName,
            values,
            setFieldValue,
            projectId,
            programName,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError,
          setFieldError
        ) => {
          try {
            let response = {};

            const { task_type, ...rest } = values;

            if (!task_type?.id) {
              setFieldError('task_type', 'This field is required');
              return;
            }

            // if (task_type.label === 'Reminder') {
            //   response = await createRecord({
            //     values: {
            //       data: [
            //         {
            //           name: values.name,
            //           description: values.description,
            //           date_time: values.started,
            //           company: _company?.id,
            //           person: person?.id,
            //           opportunity: opportunity?.id,
            //           do_what: do_what?.id,
            //           record_id: person?.id,
            //           microservice_name: microservices.CRM.name,
            //           model_name: microservices.CRM.models.persons,
            //           calendar: calendar?.id,
            //           repeat:
            //             !repeat || repeat === 'does_not_repeat' ? '' : repeat,
            //         },
            //       ],
            //     },
            //     url: bulkCreateActionReminderUrls.list(),
            //     token: user.token,
            //     actAs: user?.actAs,
            //   });
            // } else {
            response = await createRecord({
              values: { ...getValues(rest), rebase_task_order: true },
              url: tasksUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            // }

            resetForm();
            setAddNewForm(false);
            // setRepeat(null);
            refetch();
            queryClient.invalidateQueries('dependency-tasks');
            return response?.data?.id;
          } catch (err) {
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      }}
      handleRowMap={tasksRowsMap}
      urlParams={urlParams}
      customSortColumnName='order'
      sortColumnBy='asc'
      getDetailPanelHeight={() => 338}
      getDetailPanelContent={({ row }) => (
        <SubtasksList taskId={row.id} setHeight={() => null} />
      )}
      getListData={(data) => setTaskListData(data)}
    />
  );
}
