export const workflowRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  dry_run_duration_amount: r?.dry_run_duration_amount,
  dry_run_duration_unit: r?.dry_run_duration_unit,
  dry_run: r?.dry_run,
  template: r?.template,
  meta_model: {
    id: r?.meta_model,
    label: r?.details?.meta_model?.name ?? '',
  },
});

export const stageRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  probability_percent: r.probability_percent,
  rotting_days: r.rotting_days,
  order: r.order,
  workflow: r.workflow,
  stage_full_order: r.stage_full_order,
  parent_stage: r?.details?.parent_stage
    ? {
        id: r?.parent_stage,
        label: `${r?.stage_full_order ?? ''} ${r?.details?.parent_stage?.name}`,
      }
    : null,
});

export const modelAssetStageRowsMap = (r) => ({
  id: r.id,
  workflowId: r?.workflow_details?.id,
  workflow: r?.workflow_details?.name,
  stageId: r?.stage,
  stage: r?.details?.stage?.name,
});

export const assetRowsMap = (r) => ({
  id: r?.id,
  assetModel: r?.details?.asset_model
    ? {
        id: r.asset_model,
        label: r?.details?.asset_model?.name,
        link: r?.details?.asset_model?.frontend_route,
      }
    : null,
  asset: r?.details?.asset[r?.details?.asset_model?.microservice_label] ?? null,
  asset_id: r?.asset_id,
});

export const manualWorkflowInstanceRowsMap = (r) => ({
  id: r?.id,
  entity_id: r?.details?.entity
    ? {
        id: r.entity_id,
        label: r?.details?.entity[r?.meta_model_details?.label] ?? '',
      }
    : null,
  stage: r?.details?.stage
    ? {
        id: r.stage,
        label: r?.details?.stage?.name ?? '',
      }
    : null,
});

export const assetsPopupRowsMap = (row) => ({
  id: row?.id,
  type: row?.details?.asset_model?.name,
  name: row?.details?.asset?.name,
  link: `${row?.details?.asset_model?.frontend_route}${row?.asset_id}`,
});
