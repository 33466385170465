// handleRows

export const linkRowsMap = (r) => ({
  id: r.id,
  link: `${r.domain}/${r.custom_back_half}`,
  destination_url: r.destination_url,
  qr_image: r.qr_image,
  domain: r.domain,
  custom_back_half: r.custom_back_half,
  title: r.title,
  text: r.text && r.text,
  retire: r.retire ? new Date(r.retire) : null,
  source: r.utm_detail && r.utm_detail.source,
  medium: r.utm_detail && r.utm_detail.medium,
  campaign: r.utm_detail && r.utm_detail.campaign,
  term: r.utm_detail && r.utm_detail.term,
  content: r.utm_detail && r.utm_detail.content,
  created_at: r.created_at,
});

export const utmRowsMap = (r) => ({
  id: r.id,
  Source: r.Source,
  Medium: r.Medium,
  Campaign: r.Campaign,
  Term: r.Term,
  Content: r.Content,
});
