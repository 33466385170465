import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Stack, Typography, RadioGroup, Radio, FormLabel } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { formatDateTime, formatDate } from '@config/functions/helperFunctions';
import RecurringDatePicker from './RecurringDatePicker';
import InputLabel from '@mui/material/InputLabel';
import { timeZones } from '@config/constants';

const dateFormat = 'MMM DD, YYYY';

const weekDays = [
  { label: 'S', id: 'SU' },
  { label: 'M', id: 'MO' },
  { label: 'T', id: 'TU' },
  { label: 'W', id: 'WE' },
  { label: 'T', id: 'TH' },
  { label: 'F', id: 'FR' },
  { label: 'S', id: 'SA' },
];
const recurringOptions = [
  { id: 'DAILY', label: 'day' },
  { id: 'WEEKLY', label: 'week' },
  { id: 'MONTHLY', label: 'month' },
  { id: 'YEARLY', label: 'year' },
];

function getTimeString(data) {
  const currentTime = data ? new Date(data) : new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();

  let timeString = '';

  if (hours < 10) {
    timeString += '0';
  }

  timeString += hours;
  timeString += ':';

  if (minutes < 10) {
    timeString += '0';
  }

  timeString += minutes;

  return timeString;
}

export default function DateTime({
  handleSubmit = () => null,
  recurring,
  showTime,
  label,
  data,
  color,
  disabled,
  noInfiniteRecurring,
  showTimezone,
  disablePast,
  isError,
  error,
  required,
} = {}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(
    dayjs(data ? new Date(data) : new Date())
  );
  const [timezone, setTimezone] = React.useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [date, setDate] = React.useState(dayjs(new Date()).format(dateFormat));
  const timeString = getTimeString(data);
  const [time, setTime] = React.useState(timeString || '00:00');
  const [unit, setUnit] = React.useState('');
  const [tab, setTab] = React.useState(0);
  const [selectedDays, setSelectedDays] = React.useState([]);
  const [endsAfterOccurrences, setEndsAfterOccurrences] = React.useState(1);
  const [endDate, setEndDate] = React.useState(new Date());
  const [never, setNever] = React.useState('afterOccurrences');
  const [dayValue, setDayValue] = React.useState(1);

  const dateData = dayjs(value).format('YYYY-MM-DD');
  // const dateTimeString = `${dateData} ${time === '00:00' ? timeString : time}`;
  const dateTimeString = `${dateData} ${time}`;
  const dateObject = new Date(dateTimeString);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dateToRRuleString = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
  };

  const generateRRule = () => {
    if (!unit) return null;

    let rrule = `FREQ=${unit.toUpperCase()};INTERVAL=${dayValue}`;

    if (unit === 'WEEKLY' && selectedDays) {
      rrule += `;BYDAY=${selectedDays
        .map((day) => day.toUpperCase())
        .join(',')}`;
    }

    if (never === 'endsOnDate') {
      rrule += `;UNTIL=${dateToRRuleString(endDate)}`;
    }

    if (never === 'afterOccurrences') {
      rrule += `;COUNT=${endsAfterOccurrences}`;
    }

    return rrule;
  };

  React.useEffect(() => {
    setDate(dayjs(value).format(dateFormat));
  }, [value]);

  return (
    <React.Fragment>
      <div>
        {!data ? (
          <TextField
            fullWidth
            variant="standard"
            autoComplete="off"
            label={label}
            size="small"
            error={isError}
            helperText={isError ? error : ''}
            value={
              showTime ? formatDateTime(dateObject) : formatDate(dateObject)
            }
            onClick={disabled ? '' : handleClickOpen}
            InputProps={{
              style: { color },
              readOnly: disabled,
            }}
            InputLabelProps={{
              style: { color },
            }}
            required={required}
          />
        ) : (
          <TextField
            fullWidth
            variant="standard"
            autoComplete="off"
            label={label}
            size="small"
            required={required}
            error={isError}
            helperText={isError ? error : ''}
            value={showTime ? formatDateTime(data) : formatDate(data)}
            onClick={disabled ? '' : handleClickOpen}
            // onClick={handleClickOpen}
            InputProps={{
              style: { color },
              borderColor: color,
              readOnly: disabled,
            }}
            InputLabelProps={{
              style: { color },
            }}
          />
        )}
      </div>

      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Select {showTime ? 'date and time' : 'date'}</DialogTitle>
        <div>
          {!recurring ? (
            <CalendarTab
              value={value}
              date={date}
              time={time}
              setTime={setTime}
              setValue={setValue}
              timezone={timezone}
              showTime={showTime}
              disablePast={disablePast}
              showTimezone={showTimezone}
              setTimezone={setTimezone}
            />
          ) : (
            <Box sx={{ width: '100%', px: 3 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tab}
                  onChange={(e, v) => setTab(v)}
                  aria-label="date-picker-tabs"
                >
                  <Tab label="Date & Time" {...a11yProps(0)} />
                  <Tab label="Recurring" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <CalendarTab
                  value={value}
                  date={date}
                  time={time}
                  setTime={setTime}
                  setValue={setValue}
                  timezone={timezone}
                  showTime={showTime}
                  disablePast={disablePast}
                  showTimezone={showTimezone}
                  setTimezone={setTimezone}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Stack sx={{ px: 2 }} spacing={2}>
                  <Box>
                    <Typography>Repeat every</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        variant="standard"
                        value={dayValue}
                        onChange={(e) => setDayValue(e.target.value)}
                        size="small"
                        autoComplete="off"
                      />
                      <FormControl
                        sx={{ m: 1, minWidth: 80 }}
                        variant="standard"
                        size="small"
                      >
                        <Select
                          size="small"
                          value={unit}
                          onChange={(e) => setUnit(e.target.value)}
                        >
                          {recurringOptions.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>

                  {unit === 'WEEKLY' ? (
                    <Box>
                      <Typography>Repeat on</Typography>
                      <Stack sx={{ mt: 1.5 }} direction="row" spacing={1}>
                        {weekDays.map((day) => (
                          <Box
                            key={day.id}
                            onClick={() => {
                              const found = selectedDays.find(
                                (r) => r === day.id
                              );
                              if (!found) {
                                console.log('found');
                                setSelectedDays((state) => [...state, day.id]);
                              } else {
                                console.log('obi-no');
                                setSelectedDays((state) =>
                                  state.filter((r) => r !== day.id)
                                );
                              }
                            }}
                            sx={{
                              width: '24px',
                              height: '24px',
                              fontSize: '10px',
                              borderRadius: '50%',
                              background: selectedDays.find((r) => r === day.id)
                                ? '#1a73e8'
                                : '#f1f3f4',
                              color: selectedDays.find((r) => r === day.id)
                                ? '#fff'
                                : '#80868b',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              userSelect: 'none',
                            }}
                          >
                            {day.label}
                          </Box>
                        ))}
                      </Stack>
                    </Box>
                  ) : null}

                  <FormControl>
                    <FormLabel id="ends-label">Ends</FormLabel>
                    <RadioGroup aria-labelledby="ends-label" name="ends">
                      {noInfiniteRecurring ? null : (
                        <FormControl>
                          <FormControlLabel
                            value="never"
                            control={
                              <Radio
                                checked={never === 'never'}
                                onChange={() => setNever('never')}
                              />
                            }
                            label="Never"
                          />
                        </FormControl>
                      )}

                      <RecurringDatePicker
                        never={never}
                        setNever={setNever}
                        endDate={endDate}
                        setEndDate={setEndDate}
                      />
                      <Box sx={{ display: 'flex' }}>
                        <FormControl>
                          <FormControlLabel
                            value="afterOccurrences"
                            control={
                              <Radio
                                checked={never === 'afterOccurrences'}
                                onChange={() => setNever('afterOccurrences')}
                              />
                            }
                            label="After"
                          />
                        </FormControl>
                        <Box sx={{ mt: '5px', ml: 4, width: '162px' }}>
                          <OutlinedInput
                            endAdornment={
                              <InputAdornment position="end">
                                occurrences
                              </InputAdornment>
                            }
                            labelWidth={70}
                            value={endsAfterOccurrences}
                            onChange={(e) =>
                              setEndsAfterOccurrences(e.target.value)
                            }
                            size="small"
                          />
                        </Box>
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </TabPanel>
            </Box>
          )}
        </div>
        <DialogActions sx={{ p: 4, pt: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            sx={{ py: 0.5 }}
            onClick={() => {
              handleSubmit({
                value,
                time,
                unit,
                dateObject,
                dayValue,
                never,
                endDate,
                endsAfterOccurrences,
                timezone,
                rrule: generateRRule(),
              });
              handleClose();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CalendarTab({
  setValue,
  value,
  date,
  time,
  setTime,
  showTime,
  showTimezone,
  timezone,
  setTimezone,
  disablePast,
}) {
  return (
    <Box display="flex" flexWrap="wrap">
      <Box width={{ xs: '100%', lg: '60%' }} overflow="hidden">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={value}
            disablePast={disablePast}
            views={['year', 'day']}
            onChange={(newValue) => setValue(newValue)}
          />
        </LocalizationProvider>
      </Box>
      <Box
        width={{ xs: '100%', lg: '35%' }}
        sx={{ px: { xs: 3, lg: 0 }, pb: { xs: 4, lg: 0 } }}
      >
        <Stack
          sx={{ width: { xs: '60%', lg: '100%' }, margin: 'auto' }}
          spacing={2}
        >
          <TextField
            fullWidth
            variant="standard"
            label="Date"
            value={date}
            size="small"
            readOnly
          />
          {showTime ? (
            <React.Fragment>
              <TextField
                fullWidth
                variant="standard"
                label="Time"
                size="small"
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </React.Fragment>
          ) : null}

          {showTimezone ? (
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              variant="standard"
              size="small"
            >
              <InputLabel id="date-picker-timezone">Timezone</InputLabel>
              <Select
                labelId="date-picker-timezone"
                id="date-picker-timezone"
                value={timezone}
                label="Timezone"
                onChange={(e) => setTimezone(e.target.value)}
              >
                {Array.isArray(timeZones) &&
                  timeZones.map((o, oIdx) => (
                    <MenuItem value={o.value} key={oIdx.toString()}>
                      {o.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : null}
        </Stack>
      </Box>
    </Box>
  );
}
