import { configureStore } from '@reduxjs/toolkit';
import profileReducer from '@redux/profileSlice';
import uatsBoardReducer from '@redux/uatsBoardSlice';
import bugsBoardReducer from '@redux/bugsBoardSlice';
import tasksBoardReducer from '@redux/tasksBoardSlice';
import vacancyReducer from '@redux/vacancySlice';
import opportunityReducer from '@redux/opportunitySlice';
import roomsBoardReducer from '@redux/roomsBoardSlice';
import uiPrefixesReducer from '@redux/uiPrefixesSlice';
import bpaReducer from '@redux/bpaSlice';
import operationsReducer from '@redux/operationsSlice';
import meBoardReducer from '@redux/meBoardSlice';
import reminderReducer from '@redux/reminderSlice';
import callListsReducer from '@redux/callListSlice';
import bpaBoardReducer from '@redux/bpaBoardSlice';
import pmRebaseReducer from '@redux/pmRebaseSlice';
import inasBoardReducer from '@redux/inasBoardSlice';

export const store = configureStore({
  reducer: {
    pmRebase: pmRebaseReducer,
    profile: profileReducer,
    bpaState: bpaReducer,
    uatsBoard: uatsBoardReducer,
    bugsBoard: bugsBoardReducer,
    tasksBoard: tasksBoardReducer,
    vacancyBoard: vacancyReducer,
    opportunityBoard: opportunityReducer,
    roomsBoard: roomsBoardReducer,
    uiPrefixesBoard: uiPrefixesReducer,
    operations: operationsReducer,
    reminders: reminderReducer,
    meBoard: meBoardReducer,
    callListBoard: callListsReducer,
    bpaBoard: bpaBoardReducer,
    inasBoard: inasBoardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['payload.shapeCell'],
      },
    }),
});
