import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

export default function EditableTableLink({ params }) {
  const { colDef, row, id } = params;
  const openInNewTab = colDef.linkInNewTab;
  const openBrowserPopup = colDef.openBrowserPopup;
  const href = colDef.getHref ? colDef.getHref(row) : colDef.to + id;

  const getValue = () => {
    if (typeof params.value === 'string') {
      return params.value;
    }
    if (typeof params.value === 'object' && params.value) {
      return params.value.label || '...';
    }
    return '...';
  };

  const handleOpenInNewTab = (e) => {
    e.preventDefault();
    if (openInNewTab) {
      window.open(href, '_blank');
      return;
    }

    window.location.href = href;
  };

  if (typeof href === 'string' && href.includes('http')) {
    return (
      <a style={{ cursor: 'pointer' }} onClick={handleOpenInNewTab}>
        <Box>{getValue()}</Box>
      </a>
    );
  }

  return (
    <>
      {openBrowserPopup ? (
        <Link
          onClick={() =>
            window.open(`${href}`, 'ApplicationWindow', 'width=900,height=900')
          }
        >
          <Box>{getValue()}</Box>
        </Link>
      ) : (
        <Link
          to={href}
          target={openInNewTab ? '_blank' : ''}
          rel={openInNewTab ? 'noopener noreferrer' : ''}
        >
          <Box>{getValue()}</Box>
        </Link>
      )}
    </>
  );
}
