import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { ecommerceUrls, inventoryUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { CartItemForm } from '@config/forms/ecommerce';
import ecommerceHandleRow from '@config/handleRows/ecommerce';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { cartItemsUrls, cartsUrls, deliveryStatusesUrls } = ecommerceUrls;
const { productVariantsUrls } = inventoryUrls;
const { cartItemRowMap } = ecommerceHandleRow;

export default function CartItems({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const [productVariantTerm, setProductVariantTerm] = useState([]);
  const [cartTerm, setCartTerm] = useState([]);
  const [deliveryStatusTerm, setDeliveryStatusTerm] = useState([]);

  const { data: productVariants, isFetching: fetchingProductVariants } =
    useAutocomplete({
      key: 'productVariants',
      getUrl: productVariantsUrls.list,
      inputValue: productVariantTerm,
    });

  const { data: carts, isFetching: fetchingCarts } = useAutocomplete({
    key: 'carts',
    getUrl: cartsUrls.list,
    inputValue: cartTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.customer && r.details && r.customer_details
            ? r.customer_details.first_name
            : r.customer,
      };
    },
  });

  const { data: deliveryStatuses, isFetching: fetchingDeliveryStatuses } =
    useAutocomplete({
      key: 'deliveryStatuses',
      getUrl: deliveryStatusesUrls.list,
      inputValue: deliveryStatusTerm,
    });

  const cartItemsColOptions = React.useMemo(() => {
    return {
      productVariants,
      fetchingProductVariants,
      setProductVariantTerm,
      carts,
      fetchingCarts,
      setCartTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
    };
  }, [
    productVariants,
    fetchingProductVariants,
    setProductVariantTerm,
    carts,
    fetchingCarts,
    setCartTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
  ]);


  return (
    <Box>
      <ListView
        label="Cart Items"
        prefix="06l"
        labels={labels}
        editMode={editMode}
        microservice="E-Commerce"
        model="CartItem"
        urls={cartItemsUrls}
        columnKey="cartItemEcommerce"
        columnOptions={cartItemsColOptions}
        addNewForm={{
          getFields: () =>
            CartItemForm({
              productVariants,
              fetchingProductVariants,
              setProductVariantTerm,
              carts,
              fetchingCarts,
              setCartTerm,
              deliveryStatuses,
              fetchingDeliveryStatuses,
              setDeliveryStatusTerm,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: cartItemsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify(`Cart Item Created!`, {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={cartItemRowMap}
      />
    </Box>
  );
}
