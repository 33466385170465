import React from 'react';
import { Grid } from '@mui/material';
import {
  TextField,
  CheckBoxField,
  AutocompleteFieldV2
} from '@components/Inputs';
import CountryDropdown from '@dropdown-forms/list/CountryDropdown';
import CurrencyDropdown from '@dropdown-forms/list/CurrencyDropdown';
import LanguageDropdown from '@dropdown-forms/list/LanguageDropdown';
import CityDropdown from '@dropdown-forms/list/CityDropdown';
import { listsUrls } from '@config/routes';
import { useQueryClient } from 'react-query';

const { countriesUrls, currenciesUrls, languagesUrls, citiesUrls } = listsUrls;


export const CountryForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Iso2" name="iso2" />
    </Grid>
    <Grid item xs={2}>
      <TextField label="Iso3" name="iso3" />
    </Grid>
    <Grid item xs={2}>
      <TextField label="Dial Code" name="dialcode" />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label="Continent" name="continent" />
    </Grid>
    <Grid item xs={2}>
      <TextField label="Tld" name="tld" />
    </Grid>
  </>
);
export const CurrencyForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={2}>
      <TextField label="Code" name="code" />
    </Grid>
  </>
);
export const SocialMediaForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label="Name" name="name" required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label="Url" name="url" placeholder="https://" />
    </Grid>
  </>
);
export const SicCodesForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Code" name="code" required />
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField key="description" label="Description" name="description" />
    </Grid>
  </>
);
export const TimeZonesForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label="Code" name="code" required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label="Name" name="name" required />
    </Grid>
    <Grid item xs={2}>
      <TextField label="Utc offset" name="utc_offset" />
    </Grid>
  </>
);

export const CountryCurrencyForm = ({setFieldValue, hideCountry, hideCurrency}={}) => {
  const queryClient = useQueryClient()
  return(
  <>
      {hideCountry ? (
      <Grid item xs={2} sm={4} md={3}>
        <CountryDropdown
          refetch={({ id, name }) => {
            setFieldValue('country', { id, label: name });
            queryClient.invalidateQueries(['currency-country']);
          }}
        >
          <AutocompleteFieldV2
            name='country'
            label='Country'
            requestKey='currency-country'
            fetchUrl={countriesUrls.list}
            required
          />
        </CountryDropdown>
      </Grid>
      ):null}

      {hideCurrency ? (
      <Grid item xs={2} sm={4} md={3}>
        <CurrencyDropdown
          refetch={({ id, name }) => {
            setFieldValue('currency', { id, label: name });
            queryClient.invalidateQueries(['country-currency']);
          }}
        >
          <AutocompleteFieldV2
            name='currency'
            label='Currency'
            requestKey='country-currency'
            fetchUrl={currenciesUrls.list}
            required
          />
        </CurrencyDropdown>
      </Grid>
      ):null}
  </>
)};
export const LanguageForm = () => (
  <>
    <Grid item xs={2}>
      <TextField label="Iso_639_1" name="iso_639_1" />
    </Grid>

    <Grid item xs={2}>
      <TextField label="Iso_639_2" name="iso_639_2" />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label="English" name="english" required />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label="French" name="french" />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label="German" name="german" />
    </Grid>
  </>
);

export const CountryLanguageForm = ({
  setFieldValue,
  hideCountry,
  hideLanguage,
}={}) => {
  const queryClient = useQueryClient()

  return(
  <>
    { hideCountry ? (
      <Grid item xs={2} sm={4} md={3}>
        <CountryDropdown
          refetch={({ id, name }) => {
            setFieldValue('country', { id, label: name });
            queryClient.invalidateQueries(['currency-country']);
          }}
        >
          <AutocompleteFieldV2
            name='country'
            label='Country'
            requestKey='currency-country'
            fetchUrl={countriesUrls.list}
            required
          />
        </CountryDropdown>
      </Grid>
    ):null}

    {hideLanguage ? (
      <Grid item xs={2} sm={4} md={3}>
        <LanguageDropdown
          refetch={({ id, english }) => {
            setFieldValue('language', { id, label: english });
            queryClient.invalidateQueries(['country-language']);
          }}
        >
          <AutocompleteFieldV2
            name='language'
            label='Language'
            requestKey='country-language'
            fetchUrl={languagesUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.english}`,
            })}
            required
          />
        </LanguageDropdown>
      </Grid>
      ):null}
  </>
)};
export const CityForm = ({
  setFieldValue,
  hideCountry,
}={}) => {
  const queryClient = useQueryClient()
  
  return (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label="Name" name="name" required />
    </Grid>
    <Grid item xs={2}>
      <CheckBoxField label="Capital " name="capital" />
    </Grid>
    <Grid item xs={2}>
      <TextField label="Latitude " name="latitude" />
    </Grid>
    <Grid item xs={2}>
      <TextField label="Longitude " name="longitude" />
    </Grid>
    { hideCountry ? (
      <Grid item xs={2} sm={4} md={3}>
        <CountryDropdown
          refetch={({ id, name }) => {
            setFieldValue('country', { id, label: name });
            queryClient.invalidateQueries(['country-city']);
          }}
        >
          <AutocompleteFieldV2
            name='country'
            label='Country'
            requestKey='country-city'
            fetchUrl={countriesUrls.list}
            required
          />
        </CountryDropdown>
      </Grid>
    ):null}
  </>
)};
export const PostCodesForm = ({setFieldValue, values}={}) => {
  const queryClient = useQueryClient()

  return(
  <>
    <Grid item xs={2}>
      <TextField label="Code" name="code" required />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
        <CountryDropdown
          refetch={({ id, name }) => {
            setFieldValue('country', { id, label: name });
            queryClient.invalidateQueries(['country-postcode']);
          }}
        >
          <AutocompleteFieldV2
            name='country'
            label='Country'
            requestKey='country-postcode'
            fetchUrl={countriesUrls.list}
            required
          />
        </CountryDropdown>
      </Grid>
    <Grid item xs={2} sm={4} md={3}>
        <CityDropdown
          country = {values.country}
          refetch={({ id, name }) => {
            setFieldValue('city', { id, label: name });
            queryClient.invalidateQueries(['postcode-city']);
          }}
        >
          <AutocompleteFieldV2
            name='city'
            label='City'
            requestKey='postcode-city'
            fetchUrl={citiesUrls.list}
            required
          />
        </CityDropdown>
    </Grid>
    <Grid item xs={2}>
      <TextField label="Region" name="region" />
    </Grid>
    <Grid item xs={2}>
      <TextField label="Uk Region" name="uk_region" />
    </Grid>
    <Grid item xs={2}>
      <TextField label="Latitude" name="latitude" />
    </Grid>
    <Grid item xs={2}>
      <TextField label="Longitude" name="longitude" />
    </Grid>
    
  </>
)};
