import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: null,
  uats: [],
};

export const uatsBoardSlice = createSlice({
  name: 'uatsBoard',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setUats: (state, action) => {
      state.uats = action.payload;
    },
  },
});

export const { setModal, setUats } = uatsBoardSlice.actions;

export const selectUatsBoard = (state) => state.uatsBoard;

export default uatsBoardSlice.reducer;
