import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import DetailCard from '@components/core/DetailCard';
import DetailPageHeader from '@components/DetailPageHeader';
import useToast from '@hooks/useToast';
import { hrUrls } from '@config/routes';
import { employeesMeta } from '@config/meta/hr';
import { employeeDepartmentHandleRowMap } from '@config/handleRows/hr';
import { EmployeeDepartmentForm } from '@config/forms/hr';
import { microservices } from '@config/constants';
import SalaryHistoryAccordion from '@components/hr/SalaryHistoryAccordion';
import TrainingHistoryAccordion from '@components/hr/TrainingHistoryAccordion';
import HolidayEntitlementsAccordion from '@components/hr/HolidayEntitlementsAccordion';
import DaysOffAccordion from '@components/hr/DaysOffAccordion';
import PerformanceReviewsAccordion from '@components/hr/PerformanceReviewsAccordion';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { createRecord } from '@config/functions/requests';
import { useQueryClient } from 'react-query';

const { employeesUrls, employeeDepartmentsUrls } = hrUrls;

export default function EmployeeDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => employeesMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/employees`, text: 'Employees' },
              { text: recordData?.work_email ?? 'No email' },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={employeesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          columnKey="employeeDepartmentHR"
          label="Employee Department"
          prefix="0qo"
          labels={labels}
          editMode={editMode}
          microservice={microservices.HR.name}
          model={microservices.HR.models.employeeDepartment}
          urls={employeeDepartmentsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              EmployeeDepartmentForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { department, ...rest } = values;
                const response = await createRecord({
                  values: {
                    ...rest,
                    employee: recordId,
                    department: department?.id,
                  },
                  url: employeeDepartmentsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={employeeDepartmentHandleRowMap}
          urlParams={`&employee=${recordId}`}
        />

        {user?.details?.is_superuser ||
        user?.details?.roles_names.includes('Superusers') ||
        user?.details?.roles_names.includes('Company Administrator') ||
        user?.details?.roles_names.includes('HR') ? (
          <SalaryHistoryAccordion
            recordId={recordId}
            notify={notify}
            user={user}
            prefix="0a5"
            labels={labels}
            editMode={editMode}
          />
        ) : null}

        <TrainingHistoryAccordion
          recordId={recordId}
          notify={notify}
          user={user}
          prefix="0c4"
          labels={labels}
          editMode={editMode}
        />

        <HolidayEntitlementsAccordion
          recordId={recordId}
          notify={notify}
          user={user}
          prefix="0nm"
          labels={labels}
          editMode={editMode}
        />

        <DaysOffAccordion
          departmentHeads={recordData?.department_heads}
          recordId={recordId}
          notify={notify}
          user={user}
          prefix="0l2"
          labels={labels}
          editMode={editMode}
        />

        <PerformanceReviewsAccordion
          recordId={recordId}
          notify={notify}
          user={user}
          prefix="0db"
          labels={labels}
          editMode={editMode}
        />
      </Box>
    </Box>
  );
}
