import React from 'react';
import { PieChart, ColumnChart } from 'react-chartkick';
import 'chartkick/chart.js';
import { Box, Grid, Divider } from '@mui/material';
import { hrUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import Header from '@components/KanbanBoardHeader';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Loader from '@components/Loader';

const {
  vacancyStagesUrls,
  vacanciesUrls,
  recruitmentPipelineUrls,
  recruitmentPipelineStagesUrls,
  candidatesUrls,
  employeesUrls,
  departmentsUrls,
  skillsUrls,
  daysOffUrls,
  jobLevelsUrls,
  employeeSkillsUrls,
  salaryHistoryUrls,
  trainingHistoryUrls,
  holidayEntitlementsUrls,
  performanceReviewsUrls,
} = hrUrls;

export default function HRDashboard() {
  const user = useSelector(selectProfile);
  const [selectedVacancy, setSelectedVacancy] = React.useState(null);
  const [vacancy, setVacancy] = React.useState([]);
  const [pipeline, setPipeline] = React.useState([]);
  const [pipelineStages, setPipelineStages] = React.useState([]);
  const [vacanciesData, setVacanciesData] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [employee, setEmployee] = React.useState([]);
  const [departments, setDepartments] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [daysOff, setDaysOff] = React.useState([]);
  const [jobLevel, setJobLevel] = React.useState([]);
  const [employeeSkills, setEmployeeSkills] = React.useState([]);
  const [trainingHistory, setTrainingHistory] = React.useState([]);
  const [holidayEntitlements, setHolidayEntitlements] = React.useState([]);
  const [performanceReviews, setPerformanceReviews] = React.useState([]);
  const [salaryHistory, setSalaryHistory] = React.useState([]);

  const { data: vacancyStagesList } = useRQuery({
    key: ['vacancies', selectedVacancy, user.token, user?.actAs],
    url: vacancyStagesUrls.list(
      `?vacancy=${
        selectedVacancy && selectedVacancy.id
      }&order=order&page_size=200`
    ),
    options: { enabled: !!(user.token && selectedVacancy) },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: pipelineStageList } = useRQuery({
    key: ['recruitmentPipelineStages', user.token, user?.actAs],
    url: recruitmentPipelineStagesUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: pipelineList } = useRQuery({
    key: ['recruitmentPipeline', user.token, user?.actAs],
    url: recruitmentPipelineUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: vacanciesList } = useRQuery({
    key: ['vacancies-data', user.token, user?.actAs],
    url: vacanciesUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: candidatesList } = useRQuery({
    key: ['candidates-data', user.token, user?.actAs],
    url: candidatesUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: employeesList, isLoading } = useRQuery({
    key: ['employees-data', user.token, user?.actAs],
    url: employeesUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: departmentsList } = useRQuery({
    key: ['departments-data', user.token, user?.actAs],
    url: departmentsUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: skillsList } = useRQuery({
    key: ['skills-data', user.token, user?.actAs],
    url: skillsUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: daysOffList } = useRQuery({
    key: ['daysOff-data', user.token, user?.actAs],
    url: daysOffUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: jobLevelsList } = useRQuery({
    key: ['jobLevels-data', user.token, user?.actAs],
    url: jobLevelsUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: employeeSkillsList } = useRQuery({
    key: ['employeeSkills-data', user.token, user?.actAs],
    url: employeeSkillsUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: salaryHistoryList } = useRQuery({
    key: ['salaryHistory-data', user.token, user?.actAs],
    url: salaryHistoryUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: trainingHistoryList } = useRQuery({
    key: ['trainingHistory-data', user.token, user?.actAs],
    url: trainingHistoryUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: holidayEntitlementsList } = useRQuery({
    key: ['holidayEntitlements-data', user.token, user?.actAs],
    url: holidayEntitlementsUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: performanceReviewsList } = useRQuery({
    key: ['performanceReviews-data', user.token, user?.actAs],
    url: performanceReviewsUrls.list(`?page_size=200`),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  React.useEffect(() => {
    if (!salaryHistoryList) return;
    if (!trainingHistoryList) return;
    if (!holidayEntitlementsList) return;
    if (!performanceReviewsList) return;
    if (!employeeSkillsList) return;
    if (!jobLevelsList) return;
    if (!daysOffList) return;
    if (!skillsList) return;
    if (!pipelineList) return;
    if (!pipelineStageList) return;
    if (!vacanciesList) return;
    if (!candidatesList) return;
    if (!employeesList) return;
    if (!departmentsList) return;

    setDepartments(departmentsList.totalCount);
    setEmployee(employeesList.totalCount);
    setCandidates(candidatesList.totalCount);
    setVacanciesData(vacanciesList.totalCount);
    setPipelineStages(pipelineStageList.totalCount);
    setPipeline(pipelineList.totalCount);
    setSkills(skillsList.totalCount);
    setDaysOff(daysOffList.totalCount);
    setJobLevel(jobLevelsList.totalCount);
    setEmployeeSkills(employeeSkillsList.totalCount);
    setPerformanceReviews(performanceReviewsList.totalCount);
    setHolidayEntitlements(holidayEntitlementsList.totalCount);
    setTrainingHistory(trainingHistoryList.totalCount);
    setSalaryHistory(salaryHistoryList.totalCount);
  }, [
    employeesList,
    salaryHistoryList,
    trainingHistoryList,
    holidayEntitlementsList,
    performanceReviewsList,
    employeeSkillsList,
    jobLevelsList,
    daysOffList,
    skillsList,
    pipelineList,
    pipelineStageList,
    vacanciesList,
    candidatesList,
    departmentsList,
  ]);

  React.useEffect(() => {
    if (vacancyStagesList);
    const vacancyStages =
      vacancyStagesList &&
      vacancyStagesList.map((item) => [item.name, item.items.length]);
    setVacancy(vacancyStages);
  }, [vacancyStagesList]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title="Vacancies"
        filterOptions={[
          {
            label: 'Vacancy',
            name: 'selectedVacancy',
            requestKey: 'hr-dash-board-vacancies',
            fetchUrl: vacanciesUrls.list,
            handleChange: (newValue) => {
              setSelectedVacancy(newValue);
            },
            renderRow: (r) => ({
              id: r.id,
              label: r.position_title,
            }),
            orderingCol: 'position_title',
          },
        ]}
      />
      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Grid
          key="grid-header-container"
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid
            key="grid-profile-header-container"
            sx={{ mt: 2 }}
            item
            xs={4}
            sm={4}
            md={6}
          >
            <Card variant="outlined">
              <CardContent>
                <Typography
                  sx={{
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                  variant="h6"
                >
                  <GpsFixedIcon sx={{ mr: 1 }} /> Candidate Data
                </Typography>
                {/* <Typography
                sx={{ fontSize: 14, mb: 1 }}
                color='text.secondary'
                gutterBottom
              >
                LOST THIS YEAR
              </Typography> */}
                <Divider key="Divider" sx={{ mb: 2, mt: 2 }} />
                <PieChart
                  colors={[
                    '#f1c40f',
                    '#7d3c98',
                    '#5d6d7e',
                    '#2ecc71',
                    '#e74c3c',
                    '#e5e8e8',
                    '#784212',
                    '#85c1e9 ',
                  ]}
                  legend="bottom"
                  download={true}
                  donut={true} // not fill value
                  data={vacancy}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid
            key="grid-profile-header-container"
            sx={{ mt: 2 }}
            item
            xs={4}
            sm={4}
            md={6}
          >
            <Card variant="outlined">
              <CardContent>
                <Typography
                  sx={{
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                  variant="h6"
                >
                  <GpsFixedIcon sx={{ mr: 1 }} /> HR DATA
                </Typography>
                {/* <Typography
                sx={{ fontSize: 14, mb: 1 }}
                color='text.secondary'
                gutterBottom
              >
                THIS YEAR WON
              </Typography> */}
                <Divider key="Divider" sx={{ mb: 2, mt: 2 }} />
                <ColumnChart
                  colors={['#5dade2']}
                  ytitle="Model Data"
                  xtitle="Model Name"
                  label="Data"
                  download={true}
                  data={[
                    ['Pipeline', pipeline],
                    ['PipelineStage', pipelineStages],
                    ['vacancy', vacanciesData],
                    ['Candidate', candidates],
                    ['Employee', employee],
                    ['Department', departments],
                    ['Skill', skills],
                    ['DayOff', daysOff],
                    ['JobLevel', jobLevel],
                    ['EmployeeSkills', employeeSkills],
                    ['TrainingHistory', trainingHistory],
                    ['HolidayEntitlement', holidayEntitlements],
                    ['PerformanceReviews', performanceReviews],
                    ['SalaryHistory', salaryHistory],
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
