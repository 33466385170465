import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Agents from '@pages/WebChat/Agents';
import Customers from '@pages/WebChat/Customers';
import Tickets from '@pages/WebChat/Tickets';
import TicketDetail from '@pages/WebChat/Tickets/detail';
import ClosedRoomDetail from '@pages/WebChat/ClosedRoom/detail';
import WebChats from '@pages/WebChat/WebChats';

export default function () {
  return (
    <>
      <Route path='/agents' element={<WithLayout comp={Agents} />} />
      <Route path='/customers' element={<WithLayout comp={Customers} />} />
      <Route path='/tickets' element={<WithLayout comp={Tickets} />} />
      <Route path='/tickets/:id' element={<WithLayout comp={TicketDetail} />} />
      <Route path='/web-chat' element={<WithLayout comp={WebChats} />} />
      <Route
        path='/closed-rooms/:id'
        element={<WithLayout comp={ClosedRoomDetail} />}
      />
    </>
  );
}
