import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { List } from '@mui/material';

export default function UploadComponent({
  error,
  width,
  accept,
  multiple,
  label,
  required,
  ...rest
}) {
  const [field, meta] = useField(rest);
  const { setFieldValue } = useFormikContext();
  const isError = Boolean(meta.error);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop: (acceptedFiles) => {
      setFieldValue(field?.name, multiple ? acceptedFiles : acceptedFiles[0]);
    },
  });

  return (
    <Box>
      <Box
        sx={{
          p: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px dashed #c1c7d0',
          borderRadius: '4px',
        }}
      >
        <div {...getRootProps({ className: 'dropzone' })}>
          <input
            {...getInputProps()}
            accept={accept}
            onChange={(e) => {
              if (!multiple) {
                setFieldValue(field?.name, e.target.files[0]);
                return;
              }

              if (field.value) {
                setFieldValue(field?.name, [...field.value, ...e.target.files]);
                return;
              }

              setFieldValue(field?.name, [...e.target.files]);
            }}
          />
          <Typography
            sx={{
              cursor: 'pointer',
              userSelect: 'none',
              color: '#7a869a',
            }}
          >
            {isDragActive ? (
              <span>Drop the file here...</span>
            ) : (
              <>
                <span>Drop {label || 'file'} to attach, or</span>{' '}
                <span style={{ color: 'blue' }}>browse</span>{' '}
                {required ? <span>*</span> : null}
              </>
            )}
          </Typography>
        </div>
      </Box>
      {isError ? (
        <Box sx={{ mt: 1, color: 'red', fontSize: '14px', fontWeight: '500' }}>
          {meta.error}
        </Box>
      ) : null}
      <Box>
        <List sx={{ pl: 0.5 }} dense>
          {multiple && field?.value
            ? Array.from(field?.value).map((f) => <p>{f?.name}</p>)
            : field?.value?.name}
        </List>
      </Box>
    </Box>
  );
}
