import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { communicationUrls, accountUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { LabelForm } from '@config/forms/communication/index';
import useAutocomplete from '@hooks/useAutocomplete';
import { microservices } from '@config/constants';
import { labelRowMap } from '@config/handleRows/communication';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { labelsUrls } = communicationUrls;
const { rolesUrls, usersUrls } = accountUrls;

export default function Labels({ shared = false, labels, editMode }) {
  const user = useSelector(selectProfile);
  // const [parentLabelTerm, setParentLabelTerm] = useState('');
  const [roleTerm, setRoleTerm] = useState('');
  const [userTerm, setUserTerm] = useState('');
  const [notify] = useToast();

  // const {
  //   data: parentLabels,
  //   isFetching: fetchingParentLabels,
  //   refetch: refetchParentLabelsList,
  // } = useAutocomplete({
  //   key: 'parent-labels',
  //   getUrl: labelsUrls.list,
  //   inputValue: parentLabelTerm,
  // });

  const { data: roles, isFetching: fetchingRoles } = useAutocomplete({
    key: [
      'label-roles-list',
      user.details.encryption_public_key,
      user.encryptionToken,
      user.token,
      shared,
    ],
    getUrl: rolesUrls.list,
    inputValue: roleTerm,
  });

  const { data: users, isFetching: fetchingUsers } = useAutocomplete({
    key: [
      'label-users-list',
      user.details.encryption_public_key,
      user.encryptionToken,
      user.token,
      shared,
    ],
    getUrl: usersUrls.list,
    inputValue: userTerm,
    renderRow: (r) => ({
      id: r.id,
      label: r.email,
    }),
  });

  const labelsColOptions = React.useMemo(() => {
    return {
      roles,
      setRoleTerm,
      rolesIsFetching: fetchingRoles,
      users,
      setUserTerm,
      usersIsFetching: fetchingUsers,
    };
  }, [roles, setRoleTerm, fetchingRoles, users, setUserTerm, fetchingUsers]);

  return (
    <Box>
      <ListView
        key="list-view"
        label={shared ? 'Shared with me' : 'Labels'}
        prefix="075"
        labels={labels}
        editMode={editMode}
        microservice={microservices.NOTIFICATION.name}
        model={microservices.NOTIFICATION.models.label}
        urls={labelsUrls}
        columnKey="labelCommunication"
        columnOptions={labelsColOptions}
        addNewForm={
          shared
            ? null
            : {
                getFields: ({ values, setFieldValue }) =>
                  LabelForm({
                    roles,
                    setRoleTerm,
                    fetchingRoles,
                    users,
                    setUserTerm,
                    fetchingUsers,
                    values,
                    setFieldValue,
                  }),
                handleSubmit: async (
                  values,
                  setSubmitting,
                  resetForm,
                  setAddNewForm,
                  refetch,
                  setError
                ) => {
                  try {
                    const member_only_these_roles_can_see_it = {};
                    const member_only_these_users_can_see_it = {};

                    const newValues = { ...values };

                    if (values.member_only_these_roles_can_see_it) {
                      values.member_only_these_roles_can_see_it.map(
                        (role) =>
                          (member_only_these_roles_can_see_it[role.id] =
                            role.label)
                      );
                    }

                    if (values.member_only_these_users_can_see_it) {
                      values.member_only_these_users_can_see_it.map(
                        (userObj) =>
                          (member_only_these_users_can_see_it[userObj.id] =
                            userObj.label)
                      );
                    }

                    newValues.member_only_these_roles_can_see_it =
                      member_only_these_roles_can_see_it;
                    newValues.member_only_these_users_can_see_it =
                      member_only_these_users_can_see_it;

                    const { parent_label, ...rest } = newValues;

                    const response = await createRecord({
                      values: {
                        parent_label: parent_label?.id,
                        ...rest,
                      },
                      url: labelsUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                      encryptionToken: user.encryptionToken,
                    });
                    resetForm();
                    setAddNewForm(false);
                    // notify(`Label Is Created!`, {
                    //   type: 'SUCCESS',
                    // });
                    refetch();
                    return response?.data?.id;
                  } catch (err) {
                    console.log(err);
                    handleCreateFormErrors({ err, setError, notify, values });
                  } finally {
                    setSubmitting(false);
                  }
                  return null;
                },
              }
        }
        handleRowsMap={labelRowMap}
        urlParams={shared ? '&only_others=true' : '&only_mine=true'}
      />
    </Box>
  );
}
