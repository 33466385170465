import React from 'react';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';

export const NewFolderForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label='Name' name='name' required />
    </Grid>
  </>
);
