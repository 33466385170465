import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Radio, Typography } from '@mui/material';

export default function LikertField({
  showScores,
  labels,
  options,
  value,
  setValue,
  questionOptionGroups,
  randomizeLikertOptions,
}) {
  const calculateTotalScores = (options) => {
    let totalScores = 0;
    options.forEach(({ id }) => {
      const selectedLabel = value[id];
      const labelIndex = labels.indexOf(selectedLabel);
      if (labelIndex !== -1) {
        const likertScores = options.find(
          (option) => option.id === id
        )?.likertScores;
        if (Array.isArray(likertScores) && likertScores[labelIndex]) {
          totalScores += likertScores[labelIndex];
        }
      }
    });
    return totalScores;
  };

  return (
    <Box sx={{ pt: showScores ? '' : '6px' }}>
      {showScores ? (
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontSize: '14px', mb: 1 }}>
            Total Scores: {calculateTotalScores(options)}
          </Typography>
          {questionOptionGroups.map((group) => (
            <Typography sx={{ fontSize: '14px', mb: 1 }} key={group.id}>
              {group.name} Scores: {calculateTotalScores(group.questionOptions)}
            </Typography>
          ))}
        </Box>
      ) : null}
      <TableContainer
        sx={{
          padding: '0px 0px',
          borderRadius: '10px',
          fontSize: '1.1rem',
        }}
      >
        <Table sx={{ minWidth: 650 }} size='small'>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ background: '#E8E8E8', color: '#012970' }}
              ></TableCell>
              {labels.map((label) => (
                <TableCell
                  align='center'
                  sx={{ background: '#E8E8E8', color: '#012970' }}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(options) &&
              options
                .sort((a, b) => {
                  if (randomizeLikertOptions) {
                    return Math.random() - 0.5;
                  }

                  return a.order - b.order;
                })
                .map(({ id, option, likertScores }) => (
                  <TableRow key={id}>
                    <TableCell sx={{ background: '#F6F6F6', color: '#012970' }}>
                      {option}
                    </TableCell>
                    {labels.map((label, index) => (
                      <TableCell
                        key={label}
                        align='center'
                        sx={{ background: '#F6F6F6', color: '#012970' }}
                      >
                        <Radio
                          size='small'
                          value={label}
                          checked={value[id] === label}
                          name={`${id}-radio-buttons`}
                          onChange={() => setValue({ [id]: label })}
                          inputProps={{ 'aria-label': label }}
                        />{' '}
                        {showScores &&
                        Array.isArray(likertScores) &&
                        likertScores[index]
                          ? `(${likertScores[index]})`
                          : null}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
