import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Typography,
} from '@mui/material';
import { pmUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AutocompleteField, AutocompleteFieldV2 } from '@components/Inputs';
import useToast from '@hooks/useToast';
import { createRecord } from '@config/functions/requests';
import useAutocomplete from '@hooks/useAutocomplete';
// import RenderUiPrefix from '@components/core/RenderUiPrefix';
import { useQueryClient } from 'react-query';

const { sprintMetaProjectsUrls, bulkCreateSprintTasksUrls, tasksUrls } = pmUrls;

export default function AddTaskDialogBox({
  open,
  setOpen,
  recordId,
  sprintMeta,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const [taskTerm, setTaskTerm] = React.useState('');
  const [selectedProject, setSelectedProject] = React.useState(null);

  const handleClose = () => setOpen(false);

  const { data: tasks, isFetching: fetchingTasks } = useAutocomplete({
    key: ['tasks', selectedProject, user.token, user?.actAs],
    getUrl: tasksUrls.list,
    inputValue: taskTerm,
    query: `&project=${selectedProject?.id ?? ''}`,
    orderingCol: 'order',
    renderRow: (r) => ({
      id: r.id,
      label: `${r?.task_level}${r?.order} ${r?.name}`,
    }),
  });

  return (
    <Dialog
      maxWidth='sm'
      fullWidth={true}
      open={open}
      onKeyDown={(e) => e.stopPropagation()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent maxWidth='sm' fullWidth>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Add Tasks
        </Typography>

        <Formik
          initialValues={{
            project: null,
            tasks: [],
          }}
          validationSchema={Yup.object({
            project: Yup.mixed().required(),
            tasks: Yup.array().required().min(1),
          })}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              const payload = {
                tasks: (values?.tasks ?? []).map((task) => task.id),
              };

              await createRecord({
                values: payload,
                url: bulkCreateSprintTasksUrls.detail(recordId),
                token: user.token,
                actAs: user?.actAs,
              });

              queryClient.invalidateQueries(['tasks-board']);
              setOpen(false);
              notify('Task Added', {
                type: 'SUCCESS',
              });
            } catch (err) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form noValidate autoComplete='off' sx={{ pt: '10px' }}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <AutocompleteFieldV2
                    name='project'
                    label='Project'
                    requestKey='sprint-meta-projects'
                    fetchUrl={sprintMetaProjectsUrls.list}
                    requestKeyOptions={[sprintMeta?.id]}
                    enabled={!!sprintMeta?.id}
                    onChange={(newValue) => {
                      setSelectedProject(newValue);
                    }}
                    urlParams={`&ordering=created_at&sprint_meta=${sprintMeta?.id}`}
                    renderRow={(row) => ({
                      id: row?.project,
                      label: row?.details?.project?.name || '',
                      program: row?.details?.project?.program,
                    })}
                    getData={(data) => {
                      if (data.length) {
                        console.log('data', data[0]);
                        setFieldValue('project', data[0]);
                        setSelectedProject(data[0]);
                      }
                    }}
                    required
                  />
                </Grid>
                <Grid item sm={12}>
                  <AutocompleteField
                    multiple
                    setSearchTerm={setTaskTerm}
                    isLoading={fetchingTasks}
                    options={tasks}
                    label='Tasks'
                    name='tasks'
                    required
                    disabled={!values?.project}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type='submit' disabled={isSubmitting}>
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
