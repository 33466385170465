import * as React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DetailCard from '@components/core/DetailCard';
import OpportunityNotesAccordion from '@components/CRM/OpportunityNotesAccordion';
import { useQueryClient } from 'react-query';
import {
  bpaUrls,
  crmUrls,
  calendarUrls,
  communicationUrls,
} from '@config/routes';
import ReminderPopup from '@components/ReminderPopup';
import {
  companiesMeta,
  personsMeta,
  opportunitiesMeta,
} from '@config/meta/crm';
import { workflowMeta } from '@config/meta/bpa';
import DetailAccordionView from '@components/DetailAccordionView';
import { inasRowsMap } from '@config/handleRows/crm';
import { emailRowsMap } from '@config/handleRows/communication';
import LaunchIcon from '@mui/icons-material/Launch';
import { openBrowserPopup } from '@config/functions/helperFunctions';
import PersonNotesAccordion from '@components/CRM/PersonNotesAccordion';

const { actionRemindersUrls } = calendarUrls;
const { notificationsUrls } = communicationUrls;
const { companiesUrls, personsUrls, opportunitiesUrls } = crmUrls;
const { workflowsUrls } = bpaUrls;

export default function INASidePeek({
  modelName,
  editMode,
  labels,
  recordId,
  open,
  setOpen,
}) {
  const queryClient = useQueryClient();
  const [showAddIna, setShowAddIna] = React.useState(false);
  const [recordData, setRecordData] = React.useState(null);

  const container =
    typeof window !== 'undefined' ? () => window.document.body : undefined;

  const isModelOpportunity = modelName === 'Opportunity';
  const isModelCompany = modelName === 'Company';
  const isModelPerson = modelName === 'Person';
  const isModelWorkflowDefn = modelName === 'WorkflowDefn';

  const microservice = isModelWorkflowDefn ? 'BPA' : 'CRM';

  const getSchema = React.useCallback((data) => {
    if (isModelOpportunity) {
      return opportunitiesMeta({
        data,
        queryClient,
        nameGridStyle: 6,
        descriptionGridStyle: 6,
        companyGridStyle: 6,
        companyContactGridStyle: 6,
        pipelineGridStyle: 6,
        statusGridStyle: 6,
        sentimentGridStyle: 6,
        customerPriorityGridStyle: 6,
        estimatedValueGridStyle: 6,
        estimatedCloseDateGridStyle: 6,
        colorGridStyle: 6,
        probabilityGridStyle: 6,
        dataSourceGridStyle: 6,
        channelGridStyle: 6,
        tagsGridStyle: 6,
        notesGridStyle: 6,
        ownerGridStyle: 6,
        actualValueGridStyle: 6,
        createdAtGridStyle: 6,
        colorOnDetailCard: false,
        statusOnDetailCard: false,
      });
    } else if (isModelCompany) {
      return companiesMeta({
        data,
        queryClient,
        nameGridStyle: 6,
        descriptionGridStyle: 6,
        phoneGridStyle: 6,
        websiteGridStyle: 6,
        newsUrlGridStyle: 6,
        staffUrlGridStyle: 6,
        betaPartnersGridStyle: 6,
        keywordsGridStyle: 6,
        branchOfGridStyle: 6,
        address1GridStyle: 6,
        address2GridStyle: 6,
        cityGridStyle: 6,
        countryGridStyle: 6,
        notesGridStyle: 6,
        tagsGridStyle: 6,
        createdAtGridStyle: 6,
        stateGridStyle: 6,
      });
    } else if (isModelPerson) {
      return personsMeta({
        data,
        queryClient,
        firstNameGridStyle: 6,
        middleNameGridStyle: 6,
        lastNameGridStyle: 6,
        preferredNameGridStyle: 6,
        usernameGridStyle: 6,
        emailGridStyle: 6,
        dobGridStyle: 6,
        homePhoneGridStyle: 6,
        personalMobileGridStyle: 6,
        address1GridStyle: 6,
        address2GridStyle: 6,
        cityGridStyle: 6,
        countryGridStyle: 6,
        companyOwnerGridStyle: 6,
        sourceGridStyle: 6,
        sourceNotesGridStyle: 6,
        tagsGridStyle: 6,
        colorGridStyle: 6,
        notesGridStyle: 12,
        createdAtGridStyle: 6,
        stateGridStyle: 6,
        notesCondensed: true,
      });
    } else if (isModelWorkflowDefn) {
      return workflowMeta({
        data,
        nameGridStyle: 12,
        descriptionGridStyle: 6,
        metaModelGridStyle: 6,
        dryRunGridStyle: 6,
        setDryValuesInAllShapesGridStyle: 6,
        dryRunDurationGridStyle: 6,
        dryRunDurationUnitGridStyle: 6,
      });
    }
  }, []);

  const getURLs = () => {
    if (isModelOpportunity) {
      return opportunitiesUrls;
    } else if (isModelCompany) {
      return companiesUrls;
    } else if (isModelPerson) {
      return personsUrls;
    } else if (isModelWorkflowDefn) {
      return workflowsUrls;
    }
  };

  const inasColOptions = React.useMemo(() => {
    return {
      dataPanel: true,
    };
  }, []);

  const emailHistoriesColOptions = React.useMemo(() => {
    return { allEmails: true, dataPanel: true };
  }, []);

  const emails = [
    recordData?.details?.company_contact?.work_email,
    recordData?.person_detail?.person?.email,
  ].filter((x) => x);

  return (
    <Drawer
      container={container}
      variant="temporary"
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 699,
        },
        '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.06)' },
      }}
      open={open}
      onClose={() => setOpen(false)}
      ModalProps={{
        keepMounted: true,
      }}
      anchor="right"
    >
      <Toolbar />
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Typography sx={{ fontWeight: '500', fontSize: '22px' }}>
            {isModelWorkflowDefn ? 'Workflow' : _.startCase(modelName)} Data
          </Typography>

          <Box sx={{ ml: 'auto' }}>
            <Button
              variant="text"
              endIcon={<LaunchIcon />}
              onClick={() => {
                if (isModelOpportunity) {
                  openBrowserPopup(`/opportunities/${recordId}?noDrawer=true`);
                } else if (isModelCompany) {
                  openBrowserPopup(`/companies/${recordId}?noDrawer=true`);
                } else if (isModelPerson) {
                  openBrowserPopup(`/persons/${recordId}?noDrawer=true`);
                } else if (isModelWorkflowDefn) {
                  openBrowserPopup(`/workflows/${recordId}?noDrawer=true`);
                }
              }}
            >
              Detail
            </Button>
            <Button
              variant="outlined"
              sx={{ ml: 2 }}
              onClick={() => setShowAddIna(true)}
            >
              + INA
            </Button>

            {showAddIna && (
              <ReminderPopup
                type="INA"
                microservice={microservice}
                model={modelName}
                open={showAddIna}
                setOpen={setShowAddIna}
                selectedRows={[recordId]}
                setSelectedRows={() => null}
                link={`${window.location.host}/${
                  isModelOpportunity
                    ? 'opportunities'
                    : isModelWorkflowDefn
                    ? 'bpa'
                    : 'persons'
                }/${recordId}`}
                company={recordData?.details?.company?.name}
                workflow={isModelWorkflowDefn && recordData?.name}
              />
            )}
          </Box>
        </Box>

        {isModelCompany ||
        isModelPerson ||
        isModelOpportunity ||
        isModelWorkflowDefn ? (
          <DetailCard
            urls={getURLs()}
            recordId={recordId}
            getSchema={getSchema}
            setData={setRecordData}
          />
        ) : null}

        <Box sx={{ mt: 4 }}>
          {isModelOpportunity ? (
            <OpportunityNotesAccordion
              recordId={recordId}
              editMode={editMode}
              labels={labels}
            />
          ) : null}

          {isModelPerson ? (
            <PersonNotesAccordion
              recordId={recordId}
              editMode={editMode}
              labels={labels}
            />
          ) : null}

          {(isModelOpportunity ||
            isModelPerson ||
            isModelCompany ||
            isModelWorkflowDefn) && (
            <>
              <DetailAccordionView
                columnKey="inasCRM"
                columnOptions={inasColOptions}
                autoHeight="36vh"
                label="INAs"
                urls={actionRemindersUrls}
                handleRowMap={inasRowsMap}
                refetchFunction={() =>
                  queryClient.invalidateQueries(['inas-board'])
                }
                urlParams={`&entity=${modelName}&entity_microservice=${microservice}&reminder_type=INA Reminder Event&entity_id=${recordId}`}
              />

              {(isModelOpportunity || isModelPerson) && (
                <>
                  <DetailAccordionView
                    columnKey="emailCommunication"
                    columnOptions={emailHistoriesColOptions}
                    autoHeight="36vh"
                    handleRowsMap={emailRowsMap}
                    label="Email History"
                    urls={notificationsUrls}
                    startFetching={
                      isModelOpportunity
                        ? Array.isArray(emails) && emails.length !== 0
                        : recordData?.email
                    }
                    urlParams={`&all_inbox=true&history_email=${
                      isModelOpportunity
                        ? (emails ?? []).toString()
                        : recordData?.email
                    }&ordering=-created_at`}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}
