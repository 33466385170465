import React, { useEffect } from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import { FreeSoloAutocompleteField } from '@components/Inputs';
import { TextField } from '@components/Inputs';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { crmUrls, eDocUrls } from '@config/routes';
import useRQuery from '@hooks/useRQuery';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import EmailTemplateAccordion from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateAccordion';
import {
  getAuthHeader,
  validateEmail,
} from '@config/functions/helperFunctions';
import PayloadSelector from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/PayloadSelector';
import { getReadOnlyStyles } from '@config/functions/emailTemplateConfigurationPopupFunctions';
const { personsUrls } = crmUrls;
const { documentsUrls } = eDocUrls;
const headingStyles = {
  fontSize: '15px',
  fontWeight: '500',
  color: '#121826',
  mb: 2,
};
export default function EmailTemplateEdocFields({
  values,
  edocIdx,
  setFieldValue,
  user,
  workflowId,
  isKanbanView,
  documentFieldName,
  documentFieldValuesFieldName,
  handleIna,
}) {
  const [personTerm, setPersonTerm] = React.useState('');
  const [personDetails, setPersonDetails] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const expiryDurationFieldName = `edocs-${edocIdx}-expiry_duration`;
  const payloadSectionFieldName = `edocs-${edocIdx}-PayloadSection`;
  const payloadSectionKeyFieldName = `edocs-${edocIdx}-PayloadSectionKey`;

  const handleToggle = () => setOpen(!open);
  const isTypeTextOrDate = (type) => type === 'Text' || type === 'Date';

  const { data: personsList, isFetching: fetchingPersons } = useRQuery({
    key: ['bpa-edoc-persons-data', personTerm, user.token, user?.actAs],
    url: personsUrls.list(`?search=${personTerm}&page_size=100`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  const documentId = values?.edocs[edocIdx]?.[documentFieldName]?.static_value;

  useEffect(() => {
    if (!documentId && open) setOpen(false);
  }, [documentId]);

  const { data: document, isFetching: fetchingDocument } = useRQuery({
    key: ['bpa-edoc-document-data', documentId, user.token, user?.actAs],
    url: documentsUrls.detail(documentId),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token && !!documentId },
  });

  React.useEffect(() => {
    if (!personsList || !personsList.results) return;

    setPersonDetails({
      persons: personsList.results
        .map((item) => ({
          firstName: item?.first_name,
          lastName: item?.last_name,
          email: item?.email,
          label: item?.email,
          static_value: item?.id,
          mapping_field_name: null,
        }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.static_value === value.static_value)
        ),
      firstNames: personsList.results
        .map((item) => ({
          label: item?.first_name,
          static_value: item?.first_name,
          mapping_field_name: null,
        }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.static_value === value.static_value)
        ),
      lastNames: personsList.results
        .filter((item) => item?.last_name && item?.last_name.length)
        .map((item) => ({
          label: item?.last_name,
          static_value: item?.last_name,
          mapping_field_name: null,
        }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.static_value === value.static_value)
        ),
      emails: personsList.results
        .map((item) => ({
          label: item?.email,
          static_value: item?.email,
          mapping_field_name: null,
        }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.static_value === value.static_value)
        ),
    });
  }, [personsList]);

  const fields = [
    {
      label: 'Person',
      name: `edocs-${edocIdx}-person`,
      options: personDetails?.persons ?? [],
    },
    {
      label: 'Recipient First Name',
      name: `edocs-${edocIdx}-recipient_first_name`,
      options: personDetails?.firstNames ?? [],
    },
    {
      label: 'Recipient Last Name',
      name: `edocs-${edocIdx}-recipient_last_name`,
      options: personDetails?.lastNames ?? [],
    },
    {
      label: 'Recipient Email',
      name: `edocs-${edocIdx}-recipient_email`,
      options: personDetails?.emails ?? [],
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item xs={12}>
            <Box sx={{ ...getReadOnlyStyles(isKanbanView), display: 'flex' }}>
              <IconButton
                aria-label="add"
                color="primary"
                sx={{ pl: '1px' }}
                onClick={() => {
                  const newEdocs = [...values.edocs];
                  newEdocs[edocIdx][field?.name] = {
                    label: values?.edocPayloadSectionKey?.fullLabel,
                    static_value: field?.label === 'Person' ? null : '',
                    mapping_field_name: values?.edocPayloadSectionKey?.key,
                  };
                  setFieldValue('edocs', newEdocs);
                }}
                disabled={!values?.edocPayloadSectionKey}
              >
                <SubdirectoryArrowRightIcon fontSize="inherit" />
              </IconButton>
              <Box sx={{ width: '100%' }}>
                <FreeSoloAutocompleteField
                  {...field}
                  customValue={
                    values?.edocs[edocIdx][field?.name]
                      ? [values?.edocs[edocIdx][field?.name]]
                      : []
                  }
                  label={field?.label}
                  loading={fetchingPersons}
                  setSearchTerm={setPersonTerm}
                  isOptionEqualToValue={(option, value) =>
                    option?.static_value === value?.static_value
                  }
                  onChange={({ newValue: newValues }) => {
                    if (!newValues) return;
                    const newValue = newValues[newValues.length - 1];
                    if (
                      (field?.label === 'Recipient Email' &&
                        typeof newValue === 'string' &&
                        !validateEmail(newValue)) ||
                      (field?.label === 'Person' &&
                        typeof newValue === 'string')
                    )
                      return;
                    const newEdocs = [...values.edocs];
                    newEdocs[edocIdx][field?.name] = newValue
                      ? {
                          label: newValue?.label ?? newValue,
                          static_value: newValue?.static_value ?? newValue,
                          mapping_field_name: null,
                        }
                      : null;
                    if (field?.label === 'Person') {
                      newEdocs[edocIdx][fields[1]?.name] = newValue?.firstName
                        ? {
                            label: newValue?.firstName,
                            static_value: newValue?.firstName,
                            mapping_field_name: null,
                          }
                        : null;
                      newEdocs[edocIdx][fields[2]?.name] = newValue?.lastName
                        ? {
                            label: newValue?.lastName,
                            static_value: newValue?.lastName,
                            mapping_field_name: null,
                          }
                        : null;
                      newEdocs[edocIdx][fields[3]?.name] = newValue?.email
                        ? {
                            label: newValue?.email,
                            static_value: newValue?.email,
                            mapping_field_name: null,
                          }
                        : null;
                    }
                    setFieldValue('edocs', newEdocs);
                  }}
                />
              </Box>
              <IconButton
                aria-label="add-ina"
                color="primary"
                sx={{ pr: '1px' }}
                disabled={isKanbanView}
                onClick={() => handleIna(field?.label)}
              >
                <PlaylistAddIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box sx={getReadOnlyStyles(isKanbanView)}>
            <Typography sx={headingStyles}>Expiry Duration</Typography>

            <TextField
              name={expiryDurationFieldName}
              afterDefaultOnChange={(e) => {
                const newEdocs = [...values.edocs];
                newEdocs[edocIdx][expiryDurationFieldName] = {
                  label: e.target.value,
                  static_value: e.target.value,
                  mapping_field_name: null,
                };
                setFieldValue('edocs', newEdocs);
              }}
              type="number"
              sx={{ width: '13ch' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">day(s)</InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <EmailTemplateAccordion
              open={open}
              disabled={fetchingDocument || !documentId}
              setOpen={handleToggle}
              label="Document Fields"
            >
              <Box sx={getReadOnlyStyles(isKanbanView)}>
                <PayloadSelector
                  isKanbanView={isKanbanView}
                  label="Payload Selector"
                  workflowId={workflowId}
                  payloadSectionName={payloadSectionFieldName}
                  payloadSectionFieldName={payloadSectionKeyFieldName}
                />
              </Box>
              <Box
                sx={{ ...getReadOnlyStyles(isKanbanView), flexGrow: 1, mt: 3 }}
              >
                {(document?.documentFieldDefns ?? [])
                  .filter(({ type }) => isTypeTextOrDate(type))
                  .map((field) => (
                    <Box sx={{ display: 'flex', mb: 2 }}>
                      <IconButton
                        aria-label="add"
                        color="primary"
                        sx={{ pl: '1px' }}
                        onClick={() => {
                          const newEdocs = [...values.edocs];
                          newEdocs[edocIdx][documentFieldValuesFieldName][
                            field?.id
                          ] = {
                            label:
                              values?.[payloadSectionKeyFieldName]?.fullLabel,
                            static_value: '',
                            mapping_field_name:
                              values?.[payloadSectionKeyFieldName]?.key,
                          };
                          setFieldValue('edocs', newEdocs);
                        }}
                        disabled={!values?.[payloadSectionKeyFieldName]}
                      >
                        <SubdirectoryArrowRightIcon fontSize="inherit" />
                      </IconButton>
                      <Box sx={{ width: '100%' }}>
                        <FreeSoloAutocompleteField
                          {...field}
                          customValue={
                            values?.edocs[edocIdx]?.[
                              documentFieldValuesFieldName
                            ]?.[field?.id]
                              ? [
                                  values?.edocs[edocIdx]?.[
                                    documentFieldValuesFieldName
                                  ]?.[field?.id],
                                ]
                              : []
                          }
                          label={field?.name}
                          options={[]}
                          onChange={({ newValue: newValues }) => {
                            if (!newValues) return;
                            const newValue = newValues[newValues.length - 1];
                            const newEdocs = [...values.edocs];
                            if (!newValue) {
                              delete newEdocs[edocIdx][
                                documentFieldValuesFieldName
                              ][field?.id];
                            } else {
                              newEdocs[edocIdx][documentFieldValuesFieldName][
                                field?.id
                              ] = {
                                label: newValue?.label ?? newValue,
                                static_value:
                                  newValue?.static_value ?? newValue,
                                mapping_field_name: null,
                              };
                            }
                            setFieldValue('edocs', newEdocs);
                          }}
                        />
                      </Box>
                      <IconButton
                        aria-label="add-ina"
                        color="primary"
                        sx={{ pr: '1px' }}
                        disabled={isKanbanView}
                        onClick={() =>
                          handleIna(`Document Fields > ${field?.name}`)
                        }
                      >
                        <PlaylistAddIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  ))}
              </Box>
            </EmailTemplateAccordion>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
