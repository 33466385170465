import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  Divider,
  FormControlLabel,
  Radio,
  Breadcrumbs,
  RadioGroup,
} from '@mui/material';
import DocFieldInstancePopup from '@components/eDoc/DocFieldInstancePopup';
import ErrorScreen from '@components/ErrorScreen';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { getAuthHeader, truncateText } from '@config/functions/helperFunctions';
import useRQuery from '@hooks/useRQuery';
import { eDocUrls } from '@config/routes';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateRecord, deleteRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import AddIcon from '@mui/icons-material/Add';
import DocumentFieldDefnPopup from '@components/eDoc/DocumentFieldDefnPopup';
import DocFieldInstanceForm from '@components/eDoc/DocFieldInstanceForm';
import EditIcon from '@mui/icons-material/Edit';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const { documentsUrls, documentFieldDefnsUrls, documentFieldInstancesUrls } =
  eDocUrls;

const toolbarStyles = {
  top: '55px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  background: '#fcfdfe',
  height: '48px',
  position: 'sticky',
  zIndex: '3',
  boxShadow: 'rgba(61, 66, 78, 0.3) 0px 1px 2px',
  borderRadius: '2px',
  mb: 2,
  px: 2,
};

const pageStyles = {
  position: 'relative',
  display: 'inline-block',
  boxShadow: '3px 3px 8px #0000001a',
  mb: 3,
};

export default function PDFViewer({ editMode, labels }) {
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const [numPages, setNumPages] = React.useState(0);
  const [activeDocFieldDefn, setActiveDocFieldDefn] = React.useState(null);
  const [activeDocFieldInstance, setActiveDocFieldInstance] =
    React.useState(null);
  const [isPublishing, setPublishing] = React.useState(false);
  const [showDocFieldDefn, setShowDocFieldDefn] = React.useState({
    open: false,
    action: 'create',
  });
  const [documentFieldDefns, setDocumentFieldDefns] = React.useState([]);
  const [documentFieldInstances, setDocumentFieldInstances] = React.useState(
    []
  );
  const [addFieldPopup, setDocFieldPopup] = React.useState({
    open: false,
    data: {},
  });

  const {
    data: recordData,
    isLoading,
    isError,
    refetch,
  } = useRQuery({
    key: [`${recordId}-edit-document`, recordId, user.token, user?.actAs],
    url: documentsUrls.detail(recordId),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user.token && recordId) },
  });

  const extractFieldInstances = (fieldDefns) => {
    return fieldDefns.reduce(
      (acc, { documentFieldInstances }) =>
        acc.concat(
          documentFieldInstances.filter(({ deleted }) => deleted === null)
        ),
      []
    );
  };

  const onDocLoad = ({ numPages }) => {
    setNumPages(numPages);
    const documentFieldDefns = recordData?.documentFieldDefns ?? [];
    const [first] = documentFieldDefns;
    if (first?.id) setActiveDocFieldDefn(first.id);
    setDocumentFieldDefns(documentFieldDefns);
    setDocumentFieldInstances(extractFieldInstances(documentFieldDefns));
  };

  const docFieldInstanceExists = () =>
    documentFieldInstances.find(({ id }) => activeDocFieldInstance === id)
      ?.id !== undefined;

  const onClick = (e, pageNumber, activeDocFieldDefn) => {
    const { clientX, clientY } = e;
    const page = document.querySelector(`[data-page-number="${pageNumber}"]`);
    const rect = page.getBoundingClientRect();
    const pdfX = parseInt(clientX - rect.left);
    const pdfY = parseInt(page.clientHeight - (clientY - rect.top));
    const imageX = parseInt(clientX - rect.left);
    const imageY = parseInt(clientY - rect.top);

    const data = {
      pdfX,
      pdfY,
      imageX,
      imageY,
      pageNumber,
      pageHeight: page.clientHeight,
      documentFieldDefnId: activeDocFieldDefn,
      action: 'Add',
    };

    setDocFieldPopup((prevState) => ({
      ...prevState,
      open: true,
      data,
    }));
  };

  const onPublishDocument = async (isPublished) => {
    try {
      setPublishing(true);

      await updateRecord({
        url: documentsUrls.detail(recordId),
        values: {
          isPublished: !isPublished,
          anonymous_can_see_it: !isPublished,
        },
        token: user.token,
        actAs: user?.actAs,
      });

      refetch();

      notify('Operation completed', {
        type: 'SUCCESS',
      });
    } catch (err) {
      notify('There was an error, please try again later.', {
        type: 'ERROR',
      });
    } finally {
      setPublishing(false);
    }
  };

  const onDocFieldDefnEdit = async (fieldId) => {
    setActiveDocFieldDefn(fieldId);
    setShowDocFieldDefn({ open: true, action: 'edit' });
  };

  const onDocFieldDefnDelete = async (fieldId) => {
    try {
      await deleteRecord({
        url: documentFieldDefnsUrls.detail(fieldId),
        token: user.token,
        actAs: user?.actAs,
      });
      setDocumentFieldDefns((prevState) =>
        prevState.filter(({ id }) => fieldId !== id)
      );
    } catch (error) {
      console.log(error?.response?.data ?? error?.message);
      notify('Something went wrong, try refreshing', {
        type: 'ERROR',
      });
    }
  };

  const onDocFieldInstanceDelete = async (fieldId) => {
    try {
      await deleteRecord({
        url: documentFieldInstancesUrls.detail(fieldId),
        token: user.token,
        actAs: user?.actAs,
      });
      setDocumentFieldInstances((prevState) =>
        prevState.filter(({ id }) => fieldId !== id)
      );
    } catch (error) {
      console.log(error?.response?.data ?? error?.message);
      notify('Something went wrong, try refreshing', {
        type: 'ERROR',
      });
    }
  };

  React.useEffect(() => {
    const documentFieldDefns = recordData?.documentFieldDefns ?? [];
    const [first] = documentFieldDefns;
    if (first?.id) setActiveDocFieldDefn(first.id);
    setDocumentFieldDefns(documentFieldDefns);
    setDocumentFieldInstances(extractFieldInstances(documentFieldDefns));
  }, [recordData]);

  if (isError) {
    return <ErrorScreen error='Failed to fetch the resource from server' />;
  }

  if (isLoading || !recordData) {
    return <p>loading...</p>;
  }

  return (
    <Box>
      <Breadcrumbs separator='›' aria-label='breadcrumb'>
        <Link to={`/documents/${recordData?.id}`}>Documents</Link>
        <p>{truncateText(recordData?.name)}</p>
      </Breadcrumbs>

      <Box sx={toolbarStyles}>
        <Box>{recordData.name}</Box>

        <Box>
          <Button
            disableElevation
            variant='outlined'
            disabled={isPublishing}
            onClick={() => onPublishDocument(recordData.isPublished)}
          >
            {recordData.isPublished ? 'Mark as draft' : 'Mark as published'}
          </Button>
        </Box>
      </Box>

      <DocFieldInstancePopup
        open={!!addFieldPopup?.open}
        options={addFieldPopup?.data}
        setFields={setDocumentFieldInstances}
        setOpen={(v) => setDocFieldPopup((s) => ({ ...s, open: v }))}
      />

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box
            sx={{
              background: '#fff',
              maxWidth: '22rem',
              width: '100%',
              position: 'sticky',
              zIndex: '3',
              boxShadow: 'rgba(61, 66, 78, 0.3) 0px 1px 2px',
              borderRadius: '2px',
              top: '120px',
              p: 2,
            }}
          >
            {docFieldInstanceExists() ? (
              <DocFieldInstanceForm
                user={user}
                instance={documentFieldInstances.find(
                  ({ id }) => activeDocFieldInstance === id
                )}
                setDocumentFieldInstances={setDocumentFieldInstances}
                onDelete={() =>
                  onDocFieldInstanceDelete(activeDocFieldInstance)
                }
              />
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <RenderUiPrefix
                    editMode={editMode}
                    labels={labels}
                    code='pb'
                    defaultValue='Document Fields'
                  >
                    {(text) => {
                      return (
                        <Typography
                          sx={{ fontWeight: '500', color: 'rgb(30, 30, 30)' }}
                        >
                          {text}
                        </Typography>
                      );
                    }}
                  </RenderUiPrefix>

                  <Button
                    variant='outlined'
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setShowDocFieldDefn({ open: true, action: 'create' });
                    }}
                  >
                    Create
                  </Button>
                  <DocumentFieldDefnPopup
                    open={showDocFieldDefn?.open}
                    action={showDocFieldDefn?.action}
                    setOpen={() => {
                      setShowDocFieldDefn({ open: false, action: 'create' });
                    }}
                    documentId={recordId}
                    initialValues={
                      activeDocFieldDefn
                        ? documentFieldDefns.find(
                            ({ id }) => id === activeDocFieldDefn
                          )
                        : null
                    }
                    editMode={editMode}
                    labels={labels}
                    prefix='0iw'
                  />
                </Box>
                <Divider sx={{ my: 2 }} />
                <RadioGroup
                  value={activeDocFieldDefn}
                  onChange={(e) => setActiveDocFieldDefn(e.target.value)}
                >
                  {documentFieldDefns.map(({ id, name }) => (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <FormControlLabel
                        value={id}
                        control={<Radio />}
                        label={name}
                      />

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          aria-label='delete'
                          size='small'
                          onClick={() => onDocFieldDefnEdit(id)}
                        >
                          <EditIcon fontSize='small' />
                        </IconButton>
                        <IconButton
                          aria-label='delete'
                          color='danger'
                          size='small'
                          onClick={() => onDocFieldDefnDelete(id)}
                        >
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </RadioGroup>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Document
            error={null}
            file={recordData.documentUrl}
            className='eDoc-flex-col'
            onLoadSuccess={onDocLoad}
          >
            {Array.from({ length: numPages }).map((r, index) => {
              const fields = documentFieldInstances.filter(
                (r) => r.pageNumber === index + 1
              );

              return (
                <Box key={index} sx={pageStyles}>
                  <Page
                    pageNumber={++index}
                    onClick={() => setActiveDocFieldInstance(null)}
                    onDoubleClick={(e) => onClick(e, index, activeDocFieldDefn)}
                  />
                  {fields.map(
                    ({
                      id,
                      imageX,
                      imageY,
                      width,
                      height,
                      documentFieldDefnId,
                    }) => {
                      const found = documentFieldDefns.find(
                        ({ id }) => documentFieldDefnId === id
                      );
                      const { name, type } = found ?? {};

                      return (
                        <Box
                          key={id}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: `${width}px`,
                            height: `${height}px`,
                            background: 'rgb(255, 214, 91)',
                            border:
                              activeDocFieldInstance === id
                                ? '1px solid rgb(36, 99, 209)'
                                : '1px solid rgb(184, 134, 11)',
                            borderRadius: '2px',
                            top: `${imageY}px`,
                            left: `${imageX}px`,
                            position: 'absolute',
                            cursor: 'pointer',
                            userSelect: 'none',
                            zIndex: 2,
                            px: 0.5,
                          }}
                          onClick={() => setActiveDocFieldInstance(id)}
                        >
                          <Typography sx={{ fontSize: '60%', flexGrow: 1 }}>
                            {name} - {type}
                          </Typography>
                        </Box>
                      );
                    }
                  )}
                </Box>
              );
            })}
          </Document>
        </Grid>
      </Grid>
    </Box>
  );
}
