import React from 'react';
import { Box, Button, MenuItem, Stack, Tab, Tabs } from '@mui/material';
import { Formik, Form } from 'formik';
import {
  TextField,
  SelectField,
  DateTimePickerField,
} from '@components/Inputs';
import * as Yup from 'yup';
import { updateRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { bpaUrls } from '@config/routes';

const durationUnits = ['minutes', 'hours', 'days', 'months'];

const { shapesUrls } = bpaUrls;

export default function DelayActions({ shapeDetail, shapeId }) {
  const [notify] = useToast();
  const [value, setValue] = React.useState(shapeDetail?.wait_till ? 1 : 0);
  const user = useSelector(selectProfile);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Formik
        initialValues={{
          waitUntil: shapeDetail?.wait_till || null,
          duration: shapeDetail?.duration_amount || 1,
          durationUnit: shapeDetail?.duration_unit || durationUnits[1],
          delayMessage: shapeDetail?.delay_message || '',
          dryRunDuration: shapeDetail?.dry_run_duration_amount || 2,
          dryRunDurationUnit:
            shapeDetail?.dry_run_duration_unit || durationUnits[0],
          dryRunDelayMessage: shapeDetail?.dry_run_delay_message || '',
        }}
        validationSchema={Yup.object({
          waitUntil: Yup.string().nullable(),
          duration: Yup.number().positive().required(),
          durationUnit: Yup.string().oneOf(durationUnits).required(),
          delayMessage: Yup.string(),
          dryRunDuration: Yup.number().positive().required(),
          dryRunDurationUnit: Yup.string().oneOf(durationUnits).required(),
          dryRunDelayMessage: Yup.string(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);

            const isWaitUntil = value === 1;

            await updateRecord({
              values: {
                duration_amount: isWaitUntil ? null : values.duration,
                duration_unit: isWaitUntil ? null : values.durationUnit,
                delay_message: isWaitUntil ? null : values.delayMessage,
                dry_run_duration_amount: isWaitUntil
                  ? null
                  : values?.dryRunDuration,
                dry_run_duration_unit: isWaitUntil
                  ? null
                  : values?.dryRunDurationUnit,
                dry_run_delay_message: isWaitUntil
                  ? null
                  : values?.dryRunDelayMessage,
                wait_till: isWaitUntil ? values?.waitUntil : null,
              },
              url: shapesUrls.detail(shapeId),
              token: user.token,
              actAs: user?.actAs,
            });

            notify('Operation completed', {
              type: 'SUCCESS',
            });
          } catch {
            notify('There was an error, please try again', {
              type: 'ERROR',
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Box
              sx={{
                p: 2,
                border: '1px solid #b0b0b0',
                borderRadius: '4px',
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  position: 'relative',
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="delay tabs"
                >
                  <Tab label="Duration Settings" {...a11yProps(0)} />
                  <Tab label="Wait Until" {...a11yProps(1)} />
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <Stack spacing={2}>
                  <TextField label="Duration" name="duration" />

                  <SelectField
                    label="Duration unit"
                    name="durationUnit"
                    required
                  >
                    <MenuItem value={null}>None</MenuItem>
                    {durationUnits.map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <TextField label="Delay message" name="delayMessage" />

                  <TextField label="Dry run duration" name="dryRunDuration" />

                  <SelectField
                    label="Dry run duration unit"
                    name="dryRunDurationUnit"
                    required
                  >
                    <MenuItem value={null}>None</MenuItem>
                    {durationUnits.map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <TextField
                    label="Dry run delay message"
                    name="dryRunDelayMessage"
                  />
                </Stack>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <DateTimePickerField
                  label="Date time"
                  name="waitUntil"
                  showTime
                />
              </CustomTabPanel>

              <Button
                size="small"
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                disableElevation
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
