import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { communicationUrls } from '@config/routes';
import * as Yup from 'yup';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import { useQueryClient } from 'react-query';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const { imapAccountsUrls, bulkConnectFromEmailToMailTemplatesUrls } =
  communicationUrls;

export default function AddFromEmailPopup({
  open,
  setOpen,
  selectedRows,
  selectAll,
  setSelectedRows,
  searchTerm,
  labels,
  editMode,
  prefix,
  filterParams,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();

  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="Add from email to mail templates"
          >
            {(text) => {
              return (
                <DialogTitle sx={{ pointerEvents: 'none' }}>{text}</DialogTitle>
              );
            }}
          </RenderUiPrefix>
        ) : (
          <DialogTitle sx={{ pointerEvents: 'none' }}>
            Add from email to mail templates
          </DialogTitle>
        )}

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Formik
          initialValues={{
            from_email: null,
            from_email_alias: '',
          }}
          validationSchema={Yup.object({
            from_email: Yup.mixed().required(),
            from_email_alias: Yup.string().email(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              const payload = {
                from_email: values?.from_email?.id,
                from_email_alias: values?.from_email_alias,
              };

              await createRecord({
                values: {
                  all_: selectAll,
                  ids: selectAll ? [] : selectedRows,
                  search_query: searchTerm,
                  filters: filterParams,
                  ...payload,
                },
                url: bulkConnectFromEmailToMailTemplatesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setOpen(false);
              setSelectedRows([]);
              queryClient.invalidateQueries();
              notify('Operation completed', {
                type: 'SUCCESS',
              });
            } catch (error) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form noValidate autoComplete="off" style={{ paddingTop: '6px' }}>
              <Grid sx={{ paddingBottom: '22px' }} container spacing={3}>
                <Grid item sm={12}>
                  <AutocompleteFieldV2
                    name="from_email"
                    label="From Email"
                    requestKey="mail-templates-from-email"
                    fetchUrl={imapAccountsUrls.list}
                    urlParams={`&ordering=created_at`}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.username,
                    })}
                    required
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField label="From Email Alias" name="from_email_alias" />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
