import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { ecommerceUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import ecommerceHandleRow from '@config/handleRows/ecommerce';
import { DeliveryMethodForm } from '@config/forms/ecommerce';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { deliveryMethodsUrls, paymentProvidersUrls } = ecommerceUrls;
const { deliveryMethodRowMap } = ecommerceHandleRow;

export default function DeliveryMethods({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [paymentProviderTerm, setPaymentProviderTerm] = useState('');
  const [notify] = useToast();

  const { data: paymentProviders, isFetching: fetchingPaymentProviders } =
    useAutocomplete({
      key: 'paymentProviders',
      getUrl: paymentProvidersUrls.list,
      inputValue: paymentProviderTerm,
    });

    const deliveryMethodsColOptions = React.useMemo(() => {
      return {
        paymentProviders,
        fetchingPaymentProviders,
        setPaymentProviderTerm,
      };
    }, [paymentProviders, fetchingPaymentProviders, setPaymentProviderTerm]);

  return (
    <Box>
      <ListView
        label="Delivery Methods"
        prefix="0gd"
        labels={labels}
        editMode={editMode}
        microservice="E-Commerce"
        model="DeliveryMethod"
        urls={deliveryMethodsUrls}
        columnKey="deliveryMethodEcommerce"
        columnOptions={deliveryMethodsColOptions}
        addNewForm={{
          getFields: () =>
            DeliveryMethodForm({
              paymentProviders,
              fetchingPaymentProviders,
              setPaymentProviderTerm,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: deliveryMethodsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify(`Delivery Method Created!`, {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={deliveryMethodRowMap}
      />
    </Box>
  );
}
