import { bpaUrls } from "@config/routes";
import { createRecord } from "@config/functions/requests";

const {
  chatgptTextActionUrls,
  manualChatgptTextActionUrls,
} = bpaUrls;

export const handleClose = ({ setOpen, shape, queryClient } = {}) => {
  setOpen(false);
  queryClient.invalidateQueries([`${shape?.id}-shape-and-actions`]);
};

/**
 * Checks if any key in an object includes a specified substring.
 * @param {Object} obj - The object to search through.
 * @param {string} substring - The substring to look for in the keys.
 * @returns {boolean} - Returns true if any key includes the substring, false otherwise.
 */
export const hasKeyIncludingSubstring = (obj, substring) => {
  return Object.keys(obj).some((key) => key.includes(substring));
};

export const handleAccordionItemDelete = async ({
  setDeleting,
  notify,
  remove,
  index,
} = {}) => {
  try {
    setDeleting(true);
    remove(index);
  } catch (error) {
    console.log(error);
    notify("Failed to delete the item.", {
      type: "ERROR",
    });
  } finally {
    setDeleting(false);
  }
};

export const getContextFields = (i) => ({
  name: `context_fields-${i}-name`,
  key: `context_fields-${i}-key`,
  value: `context_fields-${i}-value`,
});


export const buildLabelForInitialValueFields = (
  fullValue,
  value,
  field,
  label_type = "static_value"
) => ({
  ...value,
  label: value?.mapping_field_name
    ? `${fullValue?.details?.[label_type]?.[field]?.details?.payload_section?.name} / ${fullValue?.details?.[label_type]?.[field]?.label}`
    : value?.label,
});

export const initialContextFieldMappingDefinition = (initialContextFields) =>
  initialContextFields
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getContextFields(i);

      return {
        payload_section_key_reference: valueInObject?.payload_section_key_reference,
        [fields.name]: valueInObject?.name,
        [fields.key]: valueInObject?.key,
        [fields.value]: buildLabelForInitialValueFields(value, valueInObject?.value, "value"),
      };
    });

export const initialContextFieldsMappingDefinitionOnlyFields = (initialContextFields) => {
  const response = {};
  initialContextFields
    .filter((value) => value?.static_value)
    .map((value, i) => {
      const valueInObject = JSON.parse(value?.static_value);
      const fields = getContextFields(i);

      response[fields.name] = valueInObject?.name;
      response[fields.key] = valueInObject?.key;
    });
  return response;
};

export const getInitialValues = ({
  initialName,
  initialPersona,
  initialContext,
  initialTask,
  initialFormat,
  initialExemplar,
  initialTone,
  initialHighIntelligence,
  initialContextFields,
} = {}) => {
  const populatePlaceholders = !(initialName?.value?.static_value || initialPersona?.value?.static_value || initialContext?.value?.static_value || initialTask?.value?.static_value || initialFormat?.value?.static_value || initialExemplar?.value?.static_value || initialTone?.value?.static_value)

  return {
    answer: '',
    name: populatePlaceholders ? `Rejection of initial questions form.` : initialName?.value?.static_value ?? '',
    persona: populatePlaceholders ? `I'm Umer and i'm a recruiter at Pullstream.` : initialPersona?.value?.static_value ?? '',
    context: populatePlaceholders ? `We have sent an initial questions form to {{ context.candidateName }} And they selected these options wrong:\n\nQuestion: We will require you to complete a small task to test your capabilities as part of the recruitment process. Are you okay with that?\nTheir Answer: No\nCorrect Answer: Yes\n\nQuestion: If selected, when can you start?\nTheir Answer: 2-4 Weeks\nCorrect Answer: Immediately` : initialContext?.value?.static_value ?? '',
    task: populatePlaceholders ? `Please write an rejection email to {{ context.candidateName }} for not meeting the minimum criterias.` : initialTask?.value?.static_value ?? '',
    format: populatePlaceholders ? `Please make sure to include the reasons why they are rejected And make sure email isn't too long and please make sure to include "HR Pullstream" as regards.` : initialFormat?.value?.static_value ?? '',
    exemplar: populatePlaceholders ? `` : initialExemplar?.value?.static_value ?? '',
    tone: populatePlaceholders ? `Use clear concise language and write in a friendly tone.` : initialTone?.value?.static_value ?? '',
    high_intelligence: populatePlaceholders ? false : initialHighIntelligence?.value?.static_value.toLowerCase() === "false" || initialHighIntelligence?.value?.static_value.toLowerCase() === "" ? false : true,
    contextFieldPayloadSection: null,
    contextFieldPayloadSectionKey: null,
    context_fields: initialContextFieldMappingDefinition(initialContextFields?.values ?? []),
    ...initialContextFieldsMappingDefinitionOnlyFields(initialContextFields?.values ?? []),
  };
};


const checkGlobalFieldErrors = ({ values, setFieldError }) => {
  let hasErrors = false;

  const addError = (fieldName, message) => {
    setFieldError(fieldName, message);
    hasErrors = true;
  };

  if (!values.name) {
    addError("name", "Name is a required field.");
  }

  if (!values.persona) {
    addError("persona", "Persona is a required field.");
  }

  if (!values.task) {
    addError("task", "Task is a required field.");
  }

  return hasErrors;
};


const checkContextFieldErrors = (context_field, index, setFieldError, namesSet, keysSet) => {
  let hasErrors = false;
  const fields = getContextFields(index);
  const name = context_field?.[fields?.name]?.trim();
  const key = context_field?.[fields?.key]?.trim();

  const addError = (fieldName, message) => {
    setFieldError(fieldName, message);
    hasErrors = true;
  };

  if (!name) {
    addError(fields?.name, "Name is a required field.");
  }

  // Check if name is unique
  if (namesSet.has(name)) {
    addError(fields?.name, "Name must be unique.");
  }

  if (!key) {
    addError(fields?.key, "Key is a required field.");
  }

  // Check if key is unique
  if (keysSet.has(key)) {
    addError(fields?.key, "Key must be unique.");
  }

  if (!context_field?.[fields?.value]) {
    addError(fields?.value, "Please select a value.");
  }

  namesSet.add(name);
  keysSet.add(key);

  return hasErrors;
};


const addContextFieldsToPayload = ({
  values,
  payload,
  setFieldError,
} = {}) => {
  const namesSet = new Set();
  const keysSet = new Set();

  if (
    values?.context_fields
      .map((context_field, index) => checkContextFieldErrors(context_field, index, setFieldError, namesSet, keysSet))
      .filter((e) => e).length
  )
    return true;

  values?.context_fields.map((context_field, index) => {
    const fields = getContextFields(index);
    payload.context_fields.push({
      payload_section_key_reference: context_field?.payload_section_key_reference,
      name: context_field?.[fields?.name],
      key: context_field?.[fields?.key],
      value: context_field?.[fields?.value],
    });
  });
};

/**
 * Main function to handle the popup submit action.
 * Includes processing of different fields and payload creation.
 * @param {Object} params - The parameters including values, state setters, and other dependencies.
 */
export const handlePopupSubmit = async ({
  values,
  setSubmitting,
  setFieldValue,
  setFieldError,
  shapeAction,
  user,
  initialContextFields,
  notify,
  contextFieldGroupRef,
  isKanbanView,
  isFromKanbanHeader,
  workflowInstanceId,
  currentStep,
  setCurrentStep,
  setOpen,
} = {}) => {
  try {
    setSubmitting(true);

    const payload = {
      answer: values?.answer,
      name: values?.name,
      persona: values?.persona,
      context: values?.context,
      task: values?.task,
      format: values?.format,
      exemplar: values?.exemplar,
      tone: values?.tone,
      high_intelligence: values?.high_intelligence,
      context_fields: [],
    };

    if (isKanbanView && !isFromKanbanHeader && workflowInstanceId) payload.workflow_instance = workflowInstanceId;

    const hasGlobalFieldErrors = checkGlobalFieldErrors({
      values,
      setFieldError,
    });

    const hasContextFieldErrors = addContextFieldsToPayload({
      values,
      initialContextFields,
      shapeAction,
      payload,
      setFieldError,
    });

    const hasAnyErrors = hasGlobalFieldErrors || hasContextFieldErrors;

    if (hasAnyErrors) {
      contextFieldGroupRef.current?.scrollIntoView({
        behavior: "smooth",
      });
      return; // Exit the function early if there are any errors
    }

    console.log(payload);


    const resp = await createRecord({
      values: payload,
      url: isKanbanView && !isFromKanbanHeader ? manualChatgptTextActionUrls.detail(shapeAction?.id) : chatgptTextActionUrls.detail(shapeAction?.id),
      token: user?.token,
      actAs: user?.actAs,
    });

    if (isKanbanView && !isFromKanbanHeader) {
      if (values?.answer) {
        setOpen(false);
      } else if (currentStep === 'setup') {
        setFieldValue('answer', resp?.data?.answer)
        setCurrentStep('preview');
      }
      return;
    }

    notify("Operation completed", {
      type: "SUCCESS",
    });
  } catch (error) {
    console.log(error?.response?.data ?? error?.message);
    notify("There was an error, please refresh the page", {
      type: "ERROR",
    });
  } finally {
    setSubmitting(false);
  }
};
