import React from 'react';
import * as Yup from 'yup';
import { wikiUrls } from '@config/routes';
import { booksMeta } from '@config/meta/wiki';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import CheckboxField from '@components/Inputs/CheckBoxField';
import CategoryDropdown from '@dropdown-forms/wiki/CategoryDropdown';
import { useQueryClient } from 'react-query';

const { bookCategoryUrls, booksUrls } = wikiUrls;

export default function BookDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title='Book'
        initialValues={{
          ...getAddFormInitialValues(booksMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(booksMeta(), 'listView'),
        })}
        getPostUrl={() => booksUrls.list()}
        getValues={(values) => ({
          ...values,
          category: values?.category?.id,
        })}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <TextField key='name' label='Name' name='name' required />
            </Grid>
            <Grid item xs={6}>
              <CategoryDropdown
                refetch={({ id, name }) => {
                  setFieldValue('category', { id, label: name });
                  queryClient.invalidateQueries(['book-category']);
                }}
              >
                <AutocompleteFieldV2
                  name='category'
                  label='Category'
                  requestKey='book-category'
                  fetchUrl={bookCategoryUrls.list}
                />
              </CategoryDropdown>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                key='description'
                label='Description'
                name='description'
              />
            </Grid>
            <Grid item xs={6}>
              <CheckboxField
                key='published'
                label='Published'
                name='published'
              />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
