import React from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { webChatUrls } from '@config/routes';
import { customerRowsMap } from '@config/handleRows/webChat';
import { microservices } from '@config/constants';

const { customersUrls } = webChatUrls;

export default function Customers({ labels, editMode }) {
  return (
    <Box>
      <ListView
        label='Customers'
        prefix='052'
        labels={labels}
        editMode={editMode}
        microservice={microservices.WebChat.name}
        model={microservices.WebChat.models.customers}
        urls={customersUrls}
        columnKey='customersWebChat'
        handleRowsMap={customerRowsMap}
      />
    </Box>
  );
}
