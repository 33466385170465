import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { qrCodeMeta } from '@config/meta/qrCode';
import { qrCodeUrls } from '@config/routes';

const { allDataUrls } = qrCodeUrls;

export default function QRCardDetail() {
  const { id: recordId, str: type } = useParams();
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
    qrCodeMeta({ data }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/qr-code`, text: 'Qr Code' },
              {
                text: recordData && recordData.name_of_qrcode,
              },
            ]}
          ></DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        getCustomUrl={() => `${allDataUrls.list()}${type}/${recordId}/`}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
    </Box>
  );
}
