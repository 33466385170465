import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export default function DenseTable({ rows = [], isFetching, renderRow }) {
  return (
    <TableContainer variant='outlined' component={Paper}>
      <Table sx={{ width: '100%', height: '100%' }}>
        <TableBody>
          {!rows.length ? (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                {isFetching ? 'loading...' : 'No data found...'}
              </TableCell>
            </TableRow>
          ) : null}

          {rows.map(renderRow)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
