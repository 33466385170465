import { Box } from '@mui/material';
import React from 'react';

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
}

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export default function PDFTextField({
  type,
  imageY,
  imageX,
  width,
  height,
  placeholder,
  initialValue,
  setValue = () => null,
}) {
  const [inputValue, setInputValue] = React.useState(initialValue ?? '');

  React.useEffect(() => {
    let timeoutId = setTimeout(() => {
      setValue(
        type === 'Date' && inputValue ? formatDate(inputValue) : inputValue
      );
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: '2',
        top: `${imageY}px`,
        left: `${imageX}px`,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        width,
        height,
      }}
    >
      <input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        type={type.toLowerCase()}
        placeholder={placeholder}
        autoComplete='off'
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          borderRadius: '0',
          borderTop: '0',
          borderLeft: '0',
          borderRight: '0',
          borderBottom: '1px solid #979797',
          fontSize: '11px',
        }}
      />
    </Box>
  );
}
