import { cmsV2Host } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  pages: 'pages',
  clonePage: 'pages/clone',
  pageTemplates: 'page-templates',
  pageTemplateOptions: 'page-template-options',
  pageGroups: 'page-groups',
  pageToPageGroups: 'page-to-page-groups',
  domains: 'domains',
  menus: 'menus',
  menuOptions: 'menu-options',
  pageOptions: 'page-options',
};

const pageOptionsUrls = getModelUrls(
  cmsV2Host,
  models.pageOptions,
  false,
  'v1'
);

const menusUrls = getModelUrls(cmsV2Host, models.menus, false, 'v1');

const clonePageUrls = getModelUrls(cmsV2Host, models.clonePage, false, 'v1');

const menuOptionsUrls = getModelUrls(
  cmsV2Host,
  models.menuOptions,
  false,
  'v1'
);

const pagesUrls = getModelUrls(cmsV2Host, models.pages, false, 'v1');

const pageTemplatesUrls = getModelUrls(
  cmsV2Host,
  models.pageTemplates,
  false,
  'v1'
);

const pageTemplateOptionsUrls = getModelUrls(
  cmsV2Host,
  models.pageTemplateOptions,
  false,
  'v1'
);

const pageGroupsUrls = getModelUrls(cmsV2Host, models.pageGroups, false, 'v1');

const pageToPageGroupsUrls = getModelUrls(
  cmsV2Host,
  models.pageToPageGroups,
  false,
  'v1'
);

const domainsUrls = getModelUrls(cmsV2Host, models.domains, false, 'v1');

const urls = {
  pagesUrls,
  pageTemplatesUrls,
  pageTemplateOptionsUrls,
  pageGroupsUrls,
  pageToPageGroupsUrls,
  domainsUrls,
  menusUrls,
  menuOptionsUrls,
  pageOptionsUrls,
  clonePageUrls,
};

export default urls;
