import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import ImportLogs from '@pages/Logs/ImportLogs';
import ImportLogDetail from '@pages/Logs/ImportLogs/detail';
import Logs from '@pages/Logs/Log';
import SystemLogs from '@pages/Logs/SystemLogs';


export default function () {
  return (
    <>
      <Route path='/import-log' element={<WithLayout comp={ImportLogs} />} />
      <Route path='/import-logs/:id' element={<WithLayout comp={ImportLogDetail} />} />
      <Route path='/logs' element={<WithLayout comp={Logs} />} />
      <Route path='/system-logs' element={<WithLayout comp={SystemLogs} />} />
    </>
  );
}
