import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { lmsUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { homeworkMeta } from '@config/meta/lms';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { homeworkProgressRowsMap } from '@config/handleRows/lms';
import { HomeworkProgressForm } from '@config/forms/lms';
import { useQueryClient } from 'react-query';

const { homeworksUrls, homeworkProgressesUrls } = lmsUrls;

export default function HomeWorkDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => homeworkMeta({ data, queryClient }),
    []
  );

  const taskProgressColOptions = React.useMemo(() => {
    return {
      queryClient,
    };
  }, [queryClient]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/homeworks', text: 'Homework' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={homeworksUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Task Progress'
          prefix='0cd'
          columnKey='homeworkProgress'
          columnOptions={taskProgressColOptions}
          urls={homeworkProgressesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              HomeworkProgressForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { studentId, homeworkProgress, ...rest } = values;

                await createRecord({
                  values: {
                    ...rest,
                    studentId: studentId?.id,
                    homeworkProgress: homeworkProgress?.id,
                  },
                  url: homeworkProgressesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={homeworkProgressRowsMap}
          //   urlParams={`&moduleId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
