import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useQueryClient } from 'react-query';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { wikiUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { BooksForm } from '@config/forms/wiki';
import { bookHandleRow } from '@config/handleRows/wiki';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { booksUrls } = wikiUrls;

export default function Books({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const [notify] = useToast();

  const publishedBookRefetchFunction = () => {
    queryClient.invalidateQueries('left-drawer-book');
    queryClient.invalidateQueries('chapters-leftDrawer');
  };

  return (
    <Box>
      <ListView
        label='Books'
        prefix='00b'
        labels={labels}
        editMode={editMode}
        microservice={microservices.WIKI.name}
        model={microservices.WIKI.models.books}
        urls={booksUrls}
        columnKey='booksWiki'
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            BooksForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { category, ...rest } = values;

              const response = await createRecord({
                values: {
                  ...rest,
                  category: category?.id,
                },
                url: booksUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={bookHandleRow}
        refetchFunction={publishedBookRefetchFunction}
      />
    </Box>
  );
}
