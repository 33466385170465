import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { calendarUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { batchReminderRowsMap } from '@config/handleRows/calendar';
import { BatchReminderForm } from '@config/forms/calendar';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { batchRemindersUrls } = calendarUrls;

export default function BatchReminders({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label="Batch Reminders"
        prefix="bk2"
        labels={labels}
        editMode={editMode}
        microservice={microservices.CALENDAR.name}
        model={microservices.CALENDAR.models.batchReminder}
        urls={batchRemindersUrls}
        columnKey="batchReminderCalendar"
        addNewForm={{
          getFields: () => BatchReminderForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              console.log(values);
              const { data } = await createRecord({
                values,
                url: batchRemindersUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return data.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={batchReminderRowsMap}
      />
    </Box>
  );
}
