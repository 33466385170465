import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import DetailCard from '@components/core/DetailCard';
import DetailPageHeader from '@components/DetailPageHeader';
import { hrUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { createRecord } from '@config/functions/requests';
import { skillMeta } from '@config/meta/hr';
import { employeeSkillHandleRowMap } from '@config/handleRows/hr';
import { EmployeeSkillForm } from '@config/forms/hr';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { skillsUrls, employeeSkillsUrls } = hrUrls;

export default function EmployeeskillDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => skillMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key="detail-view-header"
            items={[
              { to: `/skills`, text: 'Skills' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={skillsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          columnKey="employeeSkillHR"
          label="Employee Skill"
          prefix="0so"
          labels={labels}
          editMode={editMode}
          microservice={microservices.HR.name}
          model={microservices.HR.models.employeeSkill}
          urls={employeeSkillsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              EmployeeSkillForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { employee, skill_level, ...rest } = values;
                const response = await createRecord({
                  values: {
                    ...rest,
                    skill: recordId,
                    employee: employee?.id,
                    skill_level: skill_level?.id,
                  },
                  url: employeeSkillsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={employeeSkillHandleRowMap}
          urlParams={`&skill=${recordId}`}
        />
      </Box>
    </Box>
  );
}
