import Modal from '@ui/Modal';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@ui/Button';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '45%',
};

export default function DisconnectPromptModal({
  open,
  disabledDisableButton = false,
  onCancelClick,
  onDisableClick,
  labels,
  editMode,
  prefix,
  prefix2
}) {
  return (
    <Modal
      open={open}
      aria-labelledby="Delete Action"
      aria-describedby="Are you absolutely sure?"
    >
      <Box key="main-container" sx={style}>
        <Box key="action-container" sx={{ display: 'inline-flex' }}>
          <FontAwesomeIcon
            key="icon"
            icon={faExclamationTriangle}
            size="3x"
            color="rgb(244, 24, 66)"
          />
          {prefix2 ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix2}
            defaultValue="Are you absolutely sure?"
          >
            {(text) => {
              return <Typography id="modal-modal-title" variant="h4" component="h2" 
                sx={{ ml: '20px', my: 'auto' }}
                >
              {text}
            </Typography>;
            }}
          </RenderUiPrefix>
        ) : (
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ ml: '20px', my: 'auto' }}
          >
            Are you absolutely sure?
          </Typography>
        )}
          
        </Box>
        {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="This action will disconnect your selected imap accounts."
          >
            {(text) => {
              return <Typography component="div" id="modal-modal-description" sx={{ mt: 2 }}>{text}</Typography>;
            }}
          </RenderUiPrefix>
        ) : (
          <Typography component="div" id="modal-modal-description" sx={{ mt: 2 }}>
              This action will disconnect your selected imap accounts.
          </Typography>
        )}
        
        <Box
          key="button-container"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '20px',
          }}
        >
          <Button
            key="Cancel"
            variant="outlined"
            color="primary"
            label="Cancel"
            size="large"
            onClick={onCancelClick}
          />
          <Button
            key="Cancel"
            variant="contained"
            color="error"
            label="Disconnect"
            size="large"
            disabled={disabledDisableButton}
            onClick={onDisableClick}
            sx={{ marginLeft: '9px' }}
          />
        </Box>
      </Box>
    </Modal>
  );
}
