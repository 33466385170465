import * as React from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@ui/Button';
import axios from 'axios';
import Loader from '@components/Loader';
import OopsConfigureApp from '@components/Calendar/OopsConfigureApp';
import ConfgureAppDialog from '@components/Calendar/ConfgureAppDialog';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { calendarUrls } from '@config/routes';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const { calendarAppsUrls, getGoogleOAuthApiUrl } = calendarUrls;

function ConfigureCalendar({
  calendarApps,
  fetchingCalendarApps,
  labels,
  editMode,
  prefix,
}) {
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="85vh"
      >
        <Box sx={{ mx: 'auto' }}>
          <Box sx={{ display: 'block' }}>
            {prefix ? (
              <RenderUiPrefix
                editMode={editMode}
                labels={labels}
                code={prefix}
                defaultValue="Connect Your Calendar Account"
              >
                {(text) => {
                  return (
                    <Typography variant="h6" gutterBottom>
                      {text}
                    </Typography>
                  );
                }}
              </RenderUiPrefix>
            ) : (
              <Typography variant="h6" gutterBottom>
                Connect Your Calendar Account
              </Typography>
            )}

            <Box sx={{ mb: 1.5 }}>
              <Button
                label="Google"
                endIcon={<Avatar src={'/img/googleIcon.png'} />}
                sx={{ color: 'black', width: '300px', boxShadow: 1 }}
                onClick={() => {
                  window.location = getGoogleOAuthApiUrl({
                    state: '',
                    client_id: calendarApps.google.client_id,
                    client_redirect_uri:
                      calendarApps.google.client_redirect_uri,
                    client_scopes: calendarApps.google.client_scopes,
                  });
                }}
                disabled={!Object.keys(calendarApps.google).length}
              />
            </Box>

            <Box sx={{ mb: 1.5 }}>
              <Button
                label="Microsoft"
                endIcon={<Avatar src={'/img/microsoftIcon.png'} />}
                sx={{ color: 'black', width: '300px', boxShadow: 1 }}
                disabled={!Object.keys(calendarApps.microsoft).length}
              />
            </Box>

            <Box sx={{ mb: 1.5 }}>
              <Button
                label="Apple"
                endIcon={<Avatar src={'/img/appleIcon.png'} />}
                sx={{ color: 'black', width: '300px', boxShadow: 1 }}
                disabled={!Object.keys(calendarApps.apple).length}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default function RestrictedConfigureCalendar({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const navigate = useNavigate();
  const [oopsConfigureOpen, setOopsConfigureOpen] = React.useState(0);
  const [configureOpen, setConfigureOpen] = React.useState(0);
  const [renderChild, setRenderChild] = React.useState(false);

  const { data: calendarApps, isFetching: fetchingCalendarApps } = useQuery(
    ['calendarApps', user.token, user?.actAs],
    () =>
      axios
        .get(calendarAppsUrls.list(), getAuthHeader(user.token, user?.actAs))
        .then((res) => res.data),
    {
      enabled: !!user.token,
    }
  );

  React.useEffect(() => {
    if (!user) return;
    if (!calendarApps) return;

    if (
      !Object.keys(calendarApps.google).length &&
      !Object.keys(calendarApps.microsoft).length &&
      !Object.keys(calendarApps.apple).length
    ) {
      if (
        !(user?.details?.roles_names || []).includes('Company Administrator')
      ) {
        setOopsConfigureOpen(true);
        setRenderChild(false);
      } else {
        setConfigureOpen(true);
        setRenderChild(false);
      }
    } else {
      setRenderChild(true);
    }
  }, [calendarApps, user]);

  if (fetchingCalendarApps || !calendarApps) {
    return (
      <>
        <Loader />
      </>
    );
  }

  if (oopsConfigureOpen)
    return (
      <OopsConfigureApp
        handleOk={() => setOopsConfigureOpen(false)}
        prefix="0qx"
        labels={labels}
        editMode={editMode}
      />
    );

  if (configureOpen)
    return (
      <ConfgureAppDialog
        open={configureOpen}
        handleCancel={() => setConfigureOpen(false)}
        handleConfigure={() => navigate('/configure-calendar')}
        prefix="08c"
        labels={labels}
        editMode={editMode}
      />
    );

  if (renderChild)
    return (
      <ConfigureCalendar
        calendarApps={calendarApps}
        fetchingCalendarApps={fetchingCalendarApps}
        prefix="0nx"
        labels={labels}
        editMode={editMode}
      />
    );

  return <></>;
}
