import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Documents from '@pages/eDoc/Documents';
import DocumentDetail from '@pages/eDoc/Documents/detail';
import EditDocument from '@pages/eDoc/EditDocument/detail';
import Signing from '@pages/eDoc/Signing/detail';
import RecipientDetail from '@pages/eDoc/Recipients/detail';

export default function () {
  return (
    <>
      <Route path='/documents' element={<WithLayout comp={Documents} />} />
      <Route
        path='/documents/:id'
        element={<WithLayout comp={DocumentDetail} />}
      />
      <Route
        path='/edit-document/:id'
        element={<WithLayout comp={EditDocument} />}
      />
      <Route
        path='/document-recipients/:id'
        element={<WithLayout comp={RecipientDetail} />}
      />
      <Route path='/signing/:id' element={<Signing />} />
    </>
  );
}
