import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    detail: {
        open: false,
        error: null,
    },
};

export const pmRebaseSlice = createSlice({
    name: 'pmRebaseSlice',
    initialState,
    reducers: {
        setRebaseDetail: (state, action) => {
            state.detail = action.payload;
        }
    },
});

export const { setRebaseDetail } = pmRebaseSlice.actions;

export const selectRebase = (state) => state.pmRebase;

export default pmRebaseSlice.reducer;
