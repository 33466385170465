import React from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

export default function DropdownAddHOC({
  children,
  setOpen,
  showEditModel,
  setShowEditModel,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Box sx={{ flex: '1' }}>{children}</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '40px' }}>
        <IconButton
          color='primary'
          component='span'
          onClick={() => setOpen(true)}
        >
          <AddIcon />
        </IconButton>
        {showEditModel ? (
          <IconButton
            color='primary'
            component='span'
            sx={{ ml: 0.5, p: 0 }}
            onClick={() => setShowEditModel(true)}
          >
            <EditIcon />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
}
