import React from 'react';
import * as Yup from 'yup';
import { pmUrls } from '@config/routes';
import { personaMeta } from '@config/meta/pm/pmMeta';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import ProjectDropdown from '@dropdown-forms/pm/ProjectDropdown';
import { useQueryClient } from 'react-query';

const { personasUrls, projectsUrls } = pmUrls;

export default function PersonaDropdown({
  children,
  refetch,
  project: initialProject,
}) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="As a"
        initialValues={{
          ...getAddFormInitialValues(personaMeta(), 'listView'),
          project: initialProject,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(personaMeta(), 'listView'),
        })}
        getPostUrl={() => personasUrls.list()}
        getValues={({ values, project }) => ({
          ...values,
          project: project?.id,
        })}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key="name" label="Name" name="name" required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
            <Grid item xs={12}>
              <ProjectDropdown
                refetch={({ id, name }) => {
                  setFieldValue('project', {
                    id: id,
                    label: `${name || ''}`,
                  });
                  queryClient.invalidateQueries(['persona-project']);
                }}
              >
                <AutocompleteFieldV2
                  name="project"
                  label="Projects"
                  requestKey="persona-project"
                  fetchUrl={projectsUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                  required
                />
              </ProjectDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
