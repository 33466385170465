import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { inventoryUrls } from '@config/routes';
import DetailCard from '@components/core/DetailCard';
import { productTypeMeta } from '@config/meta/inventory/index';
import DetailPageHeader from '@components/DetailPageHeader';
import { useQueryClient } from 'react-query';

const { productTypesUrls } = inventoryUrls;

export default function ProductTypeDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const queryClient = useQueryClient();

  const getSchema = React.useCallback(
    (data) => productTypeMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key='detail-view-header'
            items={[
              { to: `/product-types`, text: 'Product Types' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={productTypesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
    </Box>
  );
}
