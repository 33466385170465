import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { crmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { callListPipelineMeta } from '@config/meta/crm';
import { callListPipelineStagesRowsMap } from '@config/handleRows/crm';
import { CallListPipelineStageForm } from '@config/forms/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { callListPipelinesUrls, callListPipelineStagesUrls } = crmUrls;

export default function CallListPipelineDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [callPipelineStagesListData, setCallPipelineStagesListData] =
    React.useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => callListPipelineMeta({ data }),
    []
  );

  const stagesColOptions = React.useMemo(() => {
    return {
      nextOrder: callPipelineStagesListData?.totalCount ?? 1,
    };
  }, [callPipelineStagesListData?.totalCount]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/call-list-pipelines', text: 'Call List Pipelines' },
              { text: recordData?.name || 'Unnamed' },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={callListPipelinesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          prefix="0i9"
          label="Stages"
          labels={labels}
          editMode={editMode}
          columnKey="callListPipelineStageCRM"
          columnOptions={stagesColOptions}
          urls={callListPipelineStagesUrls}
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.callListPipelineStage}
          addNewForm={{
            getFields: () => CallListPipelineStageForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { data } = await createRecord({
                  values: { ...values, call_list_pipeline: recordId },
                  url: callListPipelineStagesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={callListPipelineStagesRowsMap}
          urlParams={`&call_list_pipeline=${recordId}`}
          customSortColumnName="order"
          sortColumnBy="asc"
          getListData={(data) => setCallPipelineStagesListData(data)}
        />
      </Box>
    </Box>
  );
}
