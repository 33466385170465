import { webChatHost, webChatSocketHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  agents: 'agents',
  customers: 'customers',
  tickets: 'tickets',
  rooms: 'ws/agent/rooms',
  messages: 'ws/rooms-messages',
  roomMessages: 'room-messages',
};

const roomMessagesUrls = getModelUrls(webChatHost, models.roomMessages);
const agentsUrls = getModelUrls(webChatHost, models.agents);
const customersUrls = getModelUrls(webChatHost, models.customers);
const ticketsUrls = getModelUrls(webChatHost, models.tickets);
const roomsUrls = getModelUrls(webChatSocketHost, models.rooms, true);
const messagesUrls = getModelUrls(webChatSocketHost, models.messages, true);

const urls = {
  agentsUrls,
  customersUrls,
  ticketsUrls,
  roomsUrls,
  messagesUrls,
  roomMessagesUrls,
};

export default urls;
