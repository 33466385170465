import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { computeUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { microservices } from '@config/constants';
import { blockGroupsMeta } from '@config/meta/compute';
import { blockRowsMap } from '@config/handleRows/compute';
import { BlockForm } from '@config/forms/compute';

const { blockGroupsUrls, blocksUrls } = computeUrls;

export default function BlockGroupDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [blockListData, setBlockListData] = React.useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => blockGroupsMeta({ data }), []);

  const blockColOptions = React.useMemo(
    () => ({
      nextOrder: blockListData?.totalCount ?? 1,
    }),
    [blockListData?.totalCount]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/block-groups', text: 'Block Group' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={blockGroupsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Block'
          prefix='06j'
          microservice={microservices.Compute.name}
          model={microservices.Compute.models.block}
          columnKey='blocksCompute'
          columnOptions={blockColOptions}
          urls={blocksUrls}
          addNewForm={{
            getFields: () => BlockForm({ microserviceId: recordId }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                await createRecord({
                  values: {
                    ...values,
                    groupId: recordId,
                  },
                  url: blocksUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={blockRowsMap}
          urlParams={`&groupId=${recordId}`}
          getListData={(data) => setBlockListData(data)}
          customSortColumnName='order'
          sortColumnBy='asc'
        />
      </Box>
    </Box>
  );
}
