import React from 'react';
import * as Yup from 'yup';
import { cmsV2Urls } from '@config/routes';
import { pageGroupMeta } from '@config/meta/cms-v2';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import PageDropdown from '@dropdown-forms/cms/PageDropdown.jsx';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { selectProfile } from '@redux/profileSlice';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';

const { pageGroupsUrls, domainsUrls, pagesUrls, menusUrls } = cmsV2Urls;

export default function PageGroupDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="Page Group"
        initialValues={{
          ...getAddFormInitialValues(pageGroupMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(pageGroupMeta(), 'listView'),
        })}
        getPostUrl={() => pageGroupsUrls.list()}
        handleSubmit={async (
          values,
          setSubmitting,
          resetForm,
          setOpen,
          setError,
          setFieldError
        ) => {
          try {
            const { defaultPageId, domainId, menuId, ...rest } = values;

            console.log(domainId);

            if (!domainId?.id) {
              setFieldError('domainId', 'This field is required');
              return;
            }

            const response = await createRecord({
              values: {
                defaultPageId: defaultPageId?.id,
                domainId: domainId?.id,
                menuId: menuId?.id,
                ...rest,
              },
              url: pageGroupsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });

            resetForm();
            setOpen(false);
            refetch(response);
            return response?.id;
          } catch (err) {
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        }}
        getFields={({ setFieldValue }) => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <TextField label="Name" name="name" required multiline />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteFieldV2
                name="domainId"
                label="Subdomains"
                requestKey="domainId"
                fetchUrl={domainsUrls.list}
                required
                renderRow={(row) => ({
                  id: row?.id,
                  label: row?.domain,
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Slug" name="slug" required multiline />
            </Grid>
            <Grid item xs={6}>
              <PageDropdown
                refetch={({ id, name }) => {
                  setFieldValue('defaultPageId', { id, label: name });
                  queryClient.invalidateQueries(['page-group-domain']);
                }}
              >
                <AutocompleteFieldV2
                  name="defaultPageId"
                  label="Default Page"
                  requestKey="page"
                  fetchUrl={pagesUrls.list}
                />
              </PageDropdown>
            </Grid>
            <Grid item xs={6}>
              <AutocompleteFieldV2
                name="menuId"
                label="Menu"
                requestKey="menuId"
                fetchUrl={menusUrls.list}
                renderRow={(row) => ({
                  id: row?.id,
                  label: row?.name,
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Description" name="description" />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Tags" name="tags" />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
