import { Box } from '@mui/system';
import ListView from '@components/ListView';
import { crmUrls } from '@config/routes';
import { importLogsRowsMap } from '@config/handleRows/crm';
import { microservices } from '@config/constants';

const { importLogsUrls } = crmUrls;

export default function ImportLogs({ labels, editMode }) {
  return (
    <Box>
      <ListView
        label="Import Logs"
        prefix="0rw"
        labels={labels}
        editMode={editMode}
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.importLog}
        urls={importLogsUrls}
        columnKey="importLogCRM"
        handleRowsMap={importLogsRowsMap}
        importExport={false}
        handleFunctions={() => null}
        showVisibility={false}
        hideActions={true}
      />
    </Box>
  );
}
