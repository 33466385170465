import React from 'react';
import * as Yup from 'yup';
import { crmUrls } from '@config/routes';
import { callListPipelineStageMeta } from '@config/meta/crm/index';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import CallListPipelinesDropdown from '@dropdown-forms/crm/CallListPipelinesDropdown';
import { useQueryClient } from 'react-query';


const { callListPipelineStagesUrls, callListPipelinesUrls } = crmUrls;

export default function CallListPipelineStagesDropdown({ children, refetch, callListPipeline: initialCallListPipeline }) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title="Call List Pipeline Stages"
        initialValues={{
          ...getAddFormInitialValues(callListPipelineStageMeta(), 'DetailAccordionView'),
          call_list_pipeline:initialCallListPipeline
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(callListPipelineStageMeta(), 'DetailAccordionView'),
        })}
        getPostUrl={() => callListPipelineStagesUrls.list()}
        getValues={({ call_list_pipeline,...values }) => ({
            ...values,
            call_list_pipeline: call_list_pipeline?.id
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={3}>
              <TextField label='Order' name='order' required />
            </Grid>

            <Grid item xs={4}>
              <TextField label='Name' name='name' required  />
            </Grid>

            <Grid item xs={5}>
              <TextField label='Description' name='description' multiline rows={3}/>
            </Grid>

            <Grid item xs={3}>
              <TextField label='Rotting Days' name='rotting_days' />
            </Grid>

            <Grid item xs={6}>
            <CallListPipelinesDropdown 
                refetch={({
                  id,
                  name,
                }) => {
                  setFieldValue('call_list_pipeline', {
                    id: id,
                    label: `${name || ''}`,
                  });
                  queryClient.invalidateQueries([
                    'call-list-pipeline',
                  ]);
                }}>
                <AutocompleteFieldV2
                    name='call_list_pipeline'
                    label='Call List Pipeline'
                    requestKey='call_list_pipeline'
                    fetchUrl={callListPipelinesUrls.list}
                    required
                />
              </CallListPipelinesDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
