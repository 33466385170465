import { Grid, Typography } from '@mui/material';
import { TextField, AutocompleteFieldV2, CheckBoxField } from '@components/Inputs';
import CategoryDropdown from '@dropdown-forms/wiki/CategoryDropdown';
import ChapterDropdown from '@dropdown-forms/wiki/ChapterDropdown';
import TagDropdown from '@dropdown-forms/wiki/TagDropdown';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import { wikiUrls } from '@config/routes';
import { useQueryClient } from 'react-query';


const { bookCategoryUrls, chaptersUrls, tagsUrls } = wikiUrls;

export const BookCategoryForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField key='name' label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField key='description' label='Description' name='description' />
    </Grid>
  </>
);

export const BooksForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient();

  return(
  <>
    <Grid item xs={4}>
      <TextField key='name' label='Name' name='name' required />
    </Grid>

    <Grid item xs={4}>
      <TextField key='description' label='Description' name='description' />
    </Grid>

    <Grid item xs={4}>
        <CategoryDropdown
          refetch={({ id, name }) => {
            setFieldValue('category', {
              id,
              label: `${name ?? ''}`,
            });
            queryClient.invalidateQueries(['book-category']);
          }}
        >
          <AutocompleteFieldV2
            name='category'
            label='Category'
            urlParams={`&ordering=created_at`}
            requestKey='book-category'
            fetchUrl={bookCategoryUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </CategoryDropdown>
    </Grid>
    <Grid item sm={2}>
        <CheckBoxField label='Publish' name='published' />
    </Grid>
  </>
)};

export const ChaptersForm = ({setFieldValue, bookId, bookName}={}) => {
  const queryClient = useQueryClient();

  return(
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField
        key='order'
        label='Order'
        name='order'
        type='number'
        required
      />
    </Grid>
    <Grid item xs={2} sm={4} md={2}>
      <TextField key='name' label='Name' name='name' required />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField key='description' label='Description' name='description' />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <ChapterDropdown
          book={{id:bookId, label: bookName}}
          refetch={({ id, name }) => {
            setFieldValue('parent_chapter', {
              id,
              label: `${name ?? ''}`,
            });
            queryClient.invalidateQueries(['book-parent-chapter']);
          }}
        >
          <AutocompleteFieldV2
            name='parent_chapter'
            label='Parent Chapter'
            urlParams={`&ordering=created_at`}
            requestKey='book-parent-chapter'
            fetchUrl={chaptersUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </ChapterDropdown>
    </Grid>
  </>
)};

export const TagsForm = () => (
  <Grid item xs={2} sm={4} md={3}>
    <TextField key='name' label='Name' name='name' required />
  </Grid>
);

export const SectionsForm = ({ setFieldValue }) => {
  const handleHtml = (e) => {
    if (e.editor.getData() === '') {
      setFieldValue('ad_text', '<p></p>');
      return;
    }
    setFieldValue('html', e.editor.getData());
  };

  return (
    <>
      <Grid item xs={2} sm={4} md={2}>
        <TextField key='order' label='Order' name='order' required />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <TextField key='title' label='Title' name='title' required />
      </Grid>
      <Grid item xs={2} sm={4} md={5}>
        <TextField key='description' label='Description' name='description' />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant='h6'
          sx={{
            fontWeight: '500',
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
          gutterBottom
        >
          Content
        </Typography>
        <WysiwygEditor initData={'<p></p>'} onChange={handleHtml} />
      </Grid>
    </>
  );
};

export const SectionTagsForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient();

  return(
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField key='order' label='Order' name='order' required />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
        <TagDropdown
          refetch={({ id, name }) => {
            setFieldValue('tag', {
              id,
              label: `${name ?? ''}`,
            });
            queryClient.invalidateQueries(['section-tag']);
          }}
        >
          <AutocompleteFieldV2
            name='tag'
            label='Tag'
            urlParams={`&ordering=created_at`}
            requestKey='section-tag'
            fetchUrl={tagsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
          />
        </TagDropdown>
    </Grid>
  </>
)};
