import React from 'react';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import {
  Grid,
  MenuItem,
  Box,
  Typography,
  Autocomplete,
  Chip,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import MUITextField from '@mui/material/TextField';
import ImapProviderDropdown from '@dropdown-forms/communication/ImapProviderDropdown';
import {
  TextField,
  SelectField,
  CheckBoxField,
  DateTimePickerField,
  AutocompleteField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import {
  contextFieldTypeChoices,
  smtpConnectionTypeChoices,
  microservices,
  connectionTypeChoices,
} from '@config/constants';
import LabelDropdown from '@dropdown-forms/communication/LabelDropdown';
import { useDropzone } from 'react-dropzone';
import { validateEmail } from '@config/functions/helperFunctions';
import { systemUrls, communicationUrls } from '@config/routes';
import { useQueryClient } from 'react-query';

const { labelsUrls, mailTemplatesUrls, imapAccountsUrls, imapProvidersUrls } =
  communicationUrls;
const { templatesUrls } = systemUrls;

export const LabelForm = ({
  setFieldValue,
  roles,
  setRoleTerm,
  rolesIsFetching,
  users,
  setUserTerm,
  usersIsFetching,
  values,
} = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={2} sm={4} md={4}>
        <TextField label='Name' name='name' required />
      </Grid>

      <Grid item xs={2} sm={4} md={5}>
        <TextField label='Description' name='description' />
      </Grid>

      <Grid item xs={2} sm={4} md={3}>
        <LabelDropdown
          refetch={({ id, name }) => {
            setFieldValue('parent_label', { id, label: name });
            queryClient.invalidateQueries(['parent-label']);
          }}
        >
          <AutocompleteFieldV2
            name='parent_label'
            label='Parent Label'
            requestKey='parent-label'
            fetchUrl={labelsUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
          />
        </LabelDropdown>
      </Grid>

      <Grid item xs={2} sm={4} md={4}>
        <CheckBoxField
          label='Everyone can see it'
          name='member_everyone_can_see_it'
        />
      </Grid>

      <Grid item xs={2} sm={4} md={4}>
        <CheckBoxField
          label='Anonymous can see it'
          name='member_anonymous_can_see_it'
        />
      </Grid>

      <Grid item xs={2} sm={4} md={4}>
        <CheckBoxField
          label='Everyone in Obj Company can see it'
          name='member_everyone_in_object_company_can_see_it'
        />
      </Grid>

      <Grid item xs={2} sm={4} md={6}>
        <AutocompleteField
          key='member_only_these_roles_can_see_it'
          multiple
          setSearchTerm={setRoleTerm}
          isLoading={rolesIsFetching}
          options={roles}
          label='Only These Roles Can See It'
          name='member_only_these_roles_can_see_it'
          value={values.member_only_these_roles_can_see_it || []}
        />
      </Grid>

      <Grid item xs={2} sm={4} md={6}>
        <AutocompleteField
          key='member_only_these_users_can_see_it'
          multiple
          setSearchTerm={setUserTerm}
          isLoading={usersIsFetching}
          options={users}
          label='Only These Users Can See It'
          name='member_only_these_users_can_see_it'
        />
      </Grid>
    </>
  );
};

export const NotificationLabelForm = ({ setFieldValue } = {}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={2} sm={4} md={4}>
        <LabelDropdown
          refetch={({ id, name }) => {
            setFieldValue('label', { id, label: name });
            queryClient.invalidateQueries(['notification-label']);
          }}
        >
          <AutocompleteFieldV2
            name='label'
            label='Label'
            requestKey='notification-label'
            fetchUrl={labelsUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
          />
        </LabelDropdown>
      </Grid>
    </>
  );
};

export const EmailForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  users,
  setAttachments,
  mailTemplateForm,
  contextFieldValues,
  setContextFieldValues,
  selectedMailTemplate,
  signature,
} = {}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    // accept: 'image/jpeg,image/png',
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);

  return (
    <>
      {!mailTemplateForm ? (
        <>
          <Grid item xs={12} sm={8} md={8}>
            <TextField label='Subject' name='subject' required />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <DateTimePickerField label='Schedule' name='scheduled' showTime />
          </Grid>
        </>
      ) : (
        <Grid item xs={12} sm={12} md={12}>
          <DateTimePickerField label='Schedule' name='scheduled' showTime />
        </Grid>
      )}

      <Grid item xs={12} sm={6} md={12}>
        <Autocomplete
          multiple
          options={users || []}
          value={values.to_emails}
          onChange={(event, newValue) => {
            if (newValue.map((email) => validateEmail(email)).some((t) => !t))
              return;
            setFieldValue('to_emails', newValue);
          }}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant='outlined'
                size='small'
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <MUITextField
              {...params}
              variant='outlined'
              fullWidth
              error={errors.to_emails && touched.to_emails}
              helperText={errors.to_emails}
              size='small'
              label='To Emails'
              required
              autoComplete='off'
            />
          )}
        />
        {/* <TextField label='To Email' name='email' required /> */}
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <Autocomplete
          multiple
          options={users || []}
          value={values.bcc_emails}
          onChange={(event, newValue) => {
            if (newValue.map((email) => validateEmail(email)).some((t) => !t))
              return;
            setFieldValue('bcc_emails', newValue);
          }}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant='outlined'
                size='small'
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <MUITextField
              {...params}
              error={errors.bcc_emails && touched.bcc_emails}
              helperText={errors.bcc_emails}
              variant='outlined'
              fullWidth
              size='small'
              label='Bcc Emails'
              autoComplete='off'
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <Autocomplete
          multiple
          options={users || []}
          value={values.cc_emails}
          onChange={(event, newValue) => {
            if (newValue.map((email) => validateEmail(email)).some((t) => !t))
              return;
            setFieldValue('cc_emails', newValue);
          }}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant='outlined'
                size='small'
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <MUITextField
              {...params}
              error={errors.cc_emails && touched.cc_emails}
              helperText={errors.cc_emails}
              variant='outlined'
              fullWidth
              size='small'
              label='Cc Emails'
              autoComplete='off'
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        {!mailTemplateForm ? (
          <Box key='WysiwygEditor-container' sx={{ mt: 2 }}>
            <WysiwygEditor
              key='WysiwygEditor'
              initData={signature || ''}
              msName={microservices.NOTIFICATION.name}
              onChange={(evt) =>
                setFieldValue('html_body', evt.editor.getData())
              }
            />
          </Box>
        ) : (
          <AutocompleteFieldV2
            name='template'
            label='Mail Template'
            requestKey='email-template'
            fetchUrl={mailTemplatesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
              contextFields: row?.details?.context_fields,
            })}
            required
          />
        )}
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px dashed #c1c7d0',
            p: '8px',
            borderRadius: '4px',
          }}
        >
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Typography
              sx={{ cursor: 'pointer', userSelect: 'none', color: '#7a869a' }}
            >
              Drop Attachments to attach, or{' '}
              <Typography component='span' sx={{ color: 'blue' }}>
                browse
              </Typography>
            </Typography>
          </div>
        </Box>
        <Box>
          <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
        </Box>
      </Grid>

      {selectedMailTemplate &&
      selectedMailTemplate?.contextFields?.length &&
      Object.keys(contextFieldValues).length ? (
        <Grid item xs={12} sm={12} md={12}>
          <Box>
            <ul style={{ padding: '0 0 0 1px' }}>
              {selectedMailTemplate.contextFields
                .filter(
                  (contextField) => contextField.name in contextFieldValues
                )
                .map((contextField) => {
                  return (
                    <Box sx={{ display: 'flex', mt: '3px' }}>
                      <Box>
                        <Box sx={{ fontSize: 13 }}>
                          {contextField.label || contextField.name}
                        </Box>
                        <Box sx={{ fontSize: 19 }}>
                          {contextFieldValues[contextField.name]}
                        </Box>
                      </Box>
                      <Box sx={{ ml: 'auto', mt: '15px' }}>
                        <IconButton
                          onClick={() => {
                            const newContextFields = { ...contextFieldValues };
                            delete newContextFields[contextField.name];
                            setContextFieldValues({ ...newContextFields });
                          }}
                          color='error'
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
            </ul>
          </Box>
        </Grid>
      ) : null}
      {/* <Grid item md={2}>
            <SelectField label='Type' name='type'>
                {notificationTypeOptions.map((r) => (
                    <MenuItem value={r.value} key={r.value}>
                        {r.key}
                    </MenuItem>
                ))}
            </SelectField>
        </Grid> */}
    </>
  );
};

export const EmailTemplateForm = ({ microservice, model }) => (
  <>
    <Grid item xs={12} sm={4} md={4}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={12} sm={4} md={4}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={12} sm={4} md={4}>
      <TextField label='Subject' name='subject' required />
    </Grid>

    <Grid item sm={3}>
      <AutocompleteFieldV2
        name='template'
        label='Template To Copy From'
        requestKey='mail-template-template'
        fetchUrl={templatesUrls.list}
        urlParams={`&ordering=created_at&microservice_name=${microservice}&model_name=${model}`}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.name,
        })}
      />
    </Grid>

    <Grid item xs={4}>
      <AutocompleteFieldV2
        name='from_email'
        label='From Email'
        requestKey='territory-owner-person'
        fetchUrl={imapAccountsUrls.list}
        urlParams={`&connected=true&smtp_connected=true&send_emails_from_this_account=true&ordering=created_at`}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.username,
        })}
      />
    </Grid>

    <Grid item xs={12} sm={4} md={4}>
      <TextField label='From Email Alias' name='from_email_alias' />
    </Grid>

    <Grid item xs={12} sm={4} md={4}>
      <TextField label='Tags' name='_tags' />
    </Grid>
  </>
);

export const ContextFieldForm = () => (
  <>
    <Grid item xs={3}>
      <TextField label='Name' multiline name='label' required />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Key' multiline name='name' required />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Description' multiline name='description' />
    </Grid>
    <Grid item md={3}>
      <SelectField label='Type' name='type' required>
        {contextFieldTypeChoices.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>
    <Grid item sm={2}>
      <CheckBoxField label='Mandatory' name='mandatory' />
    </Grid>
  </>
);

export const ImapAccountForm = ({ values, setFieldValue } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={4}>
        <TextField label='Display Name' name='display_name' required />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Username' name='username' required />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Password' name='password' required type='password' />
      </Grid>

      <Grid item xs={4}>
        <ImapProviderDropdown
          refetch={({ id, name }) => {
            setFieldValue('provider', { id, label: name });
            queryClient.invalidateQueries(['notification-label']);
          }}
        >
          <AutocompleteFieldV2
            name='provider'
            label='Imap Provider'
            requestKey='notification-label'
            fetchUrl={imapProvidersUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
          />
        </ImapProviderDropdown>
      </Grid>

      {/* <Grid item sm={2.5}>
        <CheckBoxField label='Encryption Enabled' name='encryption_enabled' />
      </Grid> */}

      {console.log(values.smtp_connection_type)}

      <Grid item sm={4}>
        <CheckBoxField
          label='Send Emails From This Account'
          name='send_emails_from_this_account'
          customSetValue={(checked) => {
            if (!checked) return;

            setFieldValue('smtp_username', values.username);
            setFieldValue('smtp_password', values.password);
          }}
        />
      </Grid>

      {values.send_emails_from_this_account ? (
        <>
          <Grid item xs={4}>
            <TextField label='Smtp Host' name='smtp_host' required />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              label='Smtp Port'
              name='smtp_port'
              type='number'
            />
          </Grid>

          <Grid item md={4}>
            <SelectField
              label='Smtp Connection Type'
              name='smtp_connection_type'
              value={values.smtp_connection_type}
              required
            >
              {smtpConnectionTypeChoices.map((r) => (
                <MenuItem value={r.value} key={r.value}>
                  {r.label}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={4}>
            <TextField label='Smtp Username' name='smtp_username' required />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label='Smtp Password'
              name='smtp_password'
              required
              type='password'
            />
          </Grid>
        </>
      ) : null}
    </>
  );
};

export const ImapAccountDialogForm = ({
  values,
  providers,
  fetchingProviders,
  setProviderTerm,
  refetchProviders,
  providerDefaultValue,
} = {}) => {
  return (
    <Grid container spacing={3.5}>
      <Grid item xs={6}>
        <TextField label='Display Name' name='display_name' required />
      </Grid>

      <Grid item xs={6}>
        <TextField label='Username' name='username' required />
      </Grid>

      <Grid item xs={6}>
        <TextField label='Password' name='password' required type='password' />
      </Grid>

      <Grid item xs={6}>
        {/* <ImapProviderDropdown 
          refetch={({ id, name }) => {
            setFieldValue('provider', { id, label: name });
            queryClient.invalidateQueries(['notification-label']);
          }}
         >
            <AutocompleteFieldV2
              name='provider'
              label='Imap Provider'
              requestKey='notification-label'
              fetchUrl={imapProvidersUrls.list}
              urlParams={`&ordering=created_at`}
              onChange={(providerDefaultValue)=>{
                setFieldValue('provider', providerDefaultValue)
              }}
              renderRow={(row) => ({
                id: row?.id,
                label: row?.name,
              })}
            />
        </ImapProviderDropdown> */}
        <ImapProviderDropdown refetch={refetchProviders}>
          <AutocompleteField
            label='Imap Provider'
            options={providers || []}
            name='provider'
            defaultValue={providerDefaultValue}
            optionLabel='label'
            isLoading={fetchingProviders}
            setSearchTerm={(v) => setProviderTerm(v)}
            required
          />
        </ImapProviderDropdown>
      </Grid>

      <Grid item sm={12}>
        <CheckBoxField
          label='Send Emails From This Account'
          name='send_emails_from_this_account'
        />
      </Grid>

      {values.send_emails_from_this_account ? (
        <>
          <Grid item xs={8}>
            <TextField label='Smtp Host' name='smtp_host' required />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              label='Smtp Port'
              name='smtp_port'
              type='number'
            />
          </Grid>

          <Grid item md={12}>
            <SelectField
              label='Smtp Connection Type'
              name='smtp_connection_type'
              required
            >
              {smtpConnectionTypeChoices.map((r) => (
                <MenuItem value={r.value} key={r.value}>
                  {r.label}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={6}>
            <TextField label='Smtp Username' name='smtp_username' required />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label='Smtp Password'
              name='smtp_password'
              required
              type='password'
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export const ImapProviderForm = () => (
  <>
    <Grid item xs={3}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Host' name='host' required />
    </Grid>

    <Grid item xs={3}>
      <TextField required label='Port' name='port' type='number' />
    </Grid>
    <Grid item md={3}>
      <SelectField
        label='Connection Type'
        name='connection_type'
        required
        defaultValue='TLS'
      >
        {connectionTypeChoices.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>
  </>
);
