import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { systemUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { templateMeta } from '@config/meta/system';
import { childTemplatesMap } from '@config/handleRows/system';
import { TemplatesForm } from '@config/forms/system';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useParams } from 'react-router-dom';

const { templatesUrls } = systemUrls;

export default function TemplateDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => templateMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/templates', text: 'Templates' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={templatesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix='XmQ'
          microservice={microservices.System.name}
          model={microservices.System.models.templates}
          columnKey='templateSystem'
          label='Child Templates'
          urls={templatesUrls}
          includeClient={true}
          addNewForm={{
            getFields: ({ values, setFieldValue }) =>
              TemplatesForm({
                values,
                setFieldValue,
                microservice: microservices.System.name,
                model: microservices.System.models.templates,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: {
                    ...values,
                    model: values?.model?.id,
                    clone_from: values?.clone_from?.id,
                    custom_params: {},
                    parent_template: recordId,
                  },
                  url: templatesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={childTemplatesMap}
          urlParams={`&parent_template=${recordId}`}
        />
      </Box>
    </Box>
  );
}
