import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  DialogActions,
  Button,
} from '@mui/material';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import { AutocompleteFieldV2 } from '@components/Inputs';
import { formsUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { createRecord } from '@config/functions/requests';
import { useQueryClient } from 'react-query';

const { formsListUrls, groupsUrls, cloneGroupUrls } = formsUrls;

export default function ImportGroupPopup({ open, setOpen, user, formId }) {
  const [notify] = useToast();
  const queryClient = useQueryClient();

  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>Import Group</DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Formik
          initialValues={{
            form: null,
            group: null,
          }}
          validationSchema={Yup.object({
            form: Yup.mixed(),
            group: Yup.mixed(),
          })}
          onSubmit={async ({ group, form }, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              await createRecord({
                values: {
                  sourceGroupId: group?.id,
                  sourceFormId: form?.id,
                  targetFormId: formId,
                },
                url: cloneGroupUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              setOpen(false);
              resetForm();
              queryClient.invalidateQueries(['Groups-listData']);
              notify('Operation completed', {
                type: 'SUCCESS',
              });
            } catch (error) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3} sx={{ pb: 3 }}>
                <Grid item xs={12}>
                  <AutocompleteFieldV2
                    name="form"
                    label="Form"
                    requestKey="import-group-forms"
                    fetchUrl={formsListUrls.list}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteFieldV2
                    name="group"
                    label="Group"
                    requestKey="import-group-groups"
                    fetchUrl={groupsUrls.list}
                    disabled={!values?.form?.id}
                    enabled={!!values?.form?.id}
                    requestKeyOptions={[values?.form?.id]}
                    urlParams={
                      values?.form?.id ? `&formId=${values.form.id}` : ''
                    }
                    required
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  type="submit"
                  disabled={isSubmitting || !values?.form?.id}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
