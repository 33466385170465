import { marketingHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  landingPage: 'landing-pages',
  generateLandingPage: 'autogen-landing-pages',
  genLPages: 'generate-landing-pages',
  audiences: 'audiences',
  lpAudiences: 'lp-audiences',
  keyword: 'keywords',
  lpKeyword: 'lp-keywords',
  createLpFromTemplate: 'create-lp-from-template',
  autoGenLandingPageStatus: 'generate-landing-pages',
  socialMedia: 'social-medias',
  twitterOAuth: 'twitter-oauth',
  smmCampaigns: 'smm-campaigns',
  twitterAccessToken: 'twitter-access-token',
  linkedinAccessToken: 'linkedin-access-token',
  userSmAccess: 'user-sm-access',
  bulkCreateSmPosts: 'bulk-create-smposts',
  smPosts: 'sm-posts',
  postVias: 'post-vias',
  campaignAccounts: 'add-post-via-to-smm-campaigns',
};

const postViasUrls = getModelUrls(marketingHost, models.postVias);
const campaignAccountsUrls = getModelUrls(
  marketingHost,
  models.campaignAccounts
);
const smPostsUrls = getModelUrls(marketingHost, models.smPosts);
const bulkCreateSmPostsUrls = getModelUrls(
  marketingHost,
  models.bulkCreateSmPosts
);
const userSmAccessUrls = getModelUrls(marketingHost, models.userSmAccess);
const linkedinAccessTokenUrls = getModelUrls(
  marketingHost,
  models.linkedinAccessToken
);
const twitterOAuthUrls = getModelUrls(marketingHost, models.twitterOAuth);
const smmCampaignsUrls = getModelUrls(marketingHost, models.smmCampaigns);
const landingUrls = getModelUrls(marketingHost, models.landingPage);
const genLPagesUrls = getModelUrls(marketingHost, models.genLPages);
const generateLandingPageUrls = getModelUrls(
  marketingHost,
  models.generateLandingPage
);
const audiencesUrls = getModelUrls(marketingHost, models.audiences);
const keywordUrls = getModelUrls(marketingHost, models.keyword);
const lpKeywordUrls = getModelUrls(marketingHost, models.lpKeyword);
const createLpFromTemplateUrls = getModelUrls(
  marketingHost,
  models.createLpFromTemplate
);

const autoGenLandingPageStatusUrls = getModelUrls(
  marketingHost,
  models.autoGenLandingPageStatus
);

const twitterAccessTokenUrls = getModelUrls(
  marketingHost,
  models.twitterAccessToken
);

const urls = {
  landingUrls,
  generateLandingPageUrls,
  genLPagesUrls,
  audiencesUrls,
  keywordUrls,
  lpKeywordUrls,
  createLpFromTemplateUrls,
  autoGenLandingPageStatusUrls,
  twitterAccessTokenUrls,
  smmCampaignsUrls,
  twitterOAuthUrls,
  linkedinAccessTokenUrls,
  userSmAccessUrls,
  bulkCreateSmPostsUrls,
  smPostsUrls,
  campaignAccountsUrls,
  postViasUrls,
};

export default urls;
