import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import Header from '@components/Calendar/Header';
import { calendarUrls } from '@config/routes';
import ErrorScreen from '@components/ErrorScreen';
import CalendarDnD from '@components/Calendar/CalendarDnD';
import { createRecord } from '@config/functions/requests';
import {
  onConfirmDelete,
  onCreateEventClick,
  onEventDrop,
  onEventResize,
  getRange,
} from '@config/functions/calendarFunctions';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import ViewEventModal from '@components/Calendar/ViewEventModal';
import CreateEventModal from '@components/Calendar/CreateEventModal';

const { eventsUrls, syncUrls } = calendarUrls;

const CalendarAccountEventList = ({ labels, editMode }) => {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [events, setEvents] = React.useState([]);
  const [rangeDebounced, setRangeDebounced] = React.useState(getRange());
  const [range, setRange] = React.useState(getRange());
  const [modalData, setModalData] = React.useState(null);
  const [showCreateEventModal, setShowCreateEventModal] = React.useState(false);
  const [showViewEventModal, setShowViewEventModal] = React.useState(false);

  const {
    data: eventsList,
    refetch: refetchEvents,
    isError,
  } = useRQuery({
    key: ['calendar-events', range, user.token, user?.actAs],
    url: eventsUrls.list(`?from=${range[0]}&to=${range[1]}`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  const getParams = (args) => ({
    ...args,
    notify,
    setEvents,
    userToken: user.token,
    refetchEvents,
  });

  const handleSync = async () => {
    try {
      await createRecord({
        values: {},
        url: syncUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });
      // notify(`Syncing Started In Background.`, {
      //   type: 'SUCCESS',
      // });
    } catch (err) {
      console.log(err);
      notify(`There was an error, please try again.`, {
        type: 'ERROR',
      });
    }
  };

  React.useEffect(() => {
    if (!eventsList) return;

    const events = eventsList.map((r) => ({
      ...r,
      start: new Date(r.start_date_time),
      end: new Date(r.end_date_time),
      background_color: r.details.booked
        ? 'green'
        : r.details.calendar.background_color,
      foreground_color: r.details.booked
        ? 'white'
        : r.details.calendar.foreground_color,
    }));

    setEvents([...events]);
  }, [eventsList]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRange(rangeDebounced);
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [rangeDebounced]);

  if (isError) {
    return <ErrorScreen error="Something went wrong, try reloading" />;
  }

  return (
    <Box>
      <Header handleSync={handleSync} />

      <CalendarDnD
        events={events}
        onEventDrop={(args) => onEventDrop(getParams(args))}
        onEventResize={(args) => onEventResize(getParams(args))}
        onSelectEvent={(args) => {
          setModalData(args);
          setShowViewEventModal(true);
        }}
        onSelectSlot={({ start, end }) => {
          setModalData({
            start: start,
            end: end,
            email: user.details ? user.details.email : null,
          });
          setShowCreateEventModal(true);
        }}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: !event.background_color
              ? '#2336f1'
              : event.background_color,
            foregroundColor: !event.foreground_color
              ? '#000000'
              : event.foreground_color,
          },
        })}
        onRangeChange={(newRange) => {
          const startDate =
            Array.isArray(newRange) && newRange.length
              ? new Date(newRange[0])
              : new Date(newRange.start);
          const endDate =
            Array.isArray(newRange) && newRange.length
              ? new Date(newRange[newRange.length - 1])
              : new Date(newRange.end);
          const range = [
            `${startDate.getFullYear()}-${
              startDate.getMonth() + 1
            }-${startDate.getDate()}`,
            `${endDate.getFullYear()}-${
              endDate.getMonth() + 1
            }-${endDate.getDate()}`,
          ];
          setRangeDebounced(range);
        }}
      />

      <CreateEventModal
        open={showCreateEventModal}
        setOpen={setShowCreateEventModal}
        user={user}
        allCalendars={true}
        onCreateEventClick={(values, guests) =>
          onCreateEventClick({
            values,
            guests,
            user,
            setShowCreateEventModal,
            refetchEvents,
            notify,
          })
        }
        data={modalData}
        prefix="0po"
        labels={labels}
        editMode={editMode}
      />

      <ViewEventModal
        open={showViewEventModal}
        setOpen={setShowViewEventModal}
        onConfirmDelete={() =>
          onConfirmDelete({
            modalData,
            userToken: user.token,
            setShowViewEventModal,
            refetchEvents,
            notify,
          })
        }
        event={modalData}
        prefix="0h3"
        labels={labels}
        editMode={editMode}
      />
    </Box>
  );
};

export default CalendarAccountEventList;
