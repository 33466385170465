import ColorPickerField from '@ui/Inputs/accounts/ColorPickerField';
import TextField from '@ui/Inputs/TextField';
import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import { Box } from '@mui/system';

export default function ColorPicker({ label, ...rest }) {
  const [field, meta] = useField(rest);
  const { setFieldValue } = useFormikContext();
  const [picker, setPicker] = React.useState(false);
  const [color, setColor] = React.useState(field.value);
  const isError = meta.touched && Boolean(meta.error);

  const handleChange = (col) => {
    setColor(col);
    setFieldValue(field.name, col);
  };

  return (
    <Box>
      {picker ? (
        <ColorPickerField
          label={label}
          name={field.name}
          key={field.name}
          color={color}
          onChange={handleChange}
          onBlur={() => setPicker(false)}
          {...rest}
        />
      ) : (
        <TextField
          variant="outlined"
          label={label}
          name={field.name}
          value={color}
          sx={{ input: { color: `${color}` } }}
          onClick={() => setPicker(true)}
          error={isError}
          helperText={meta.error}
          {...rest}
        />
      )}
    </Box>
  );
}
