import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export default function SlotEventDeletePrompt({
  event,
  open,
  disabledDeleteButton,
  onCancelClick,
  onDeleteAllClick,
  onDeleteClick,
}) {
  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
    >
      <DialogTitle id='delete-dialog-title' sx={{ m: 0, p: 2 }}>
        Move to trash? {onCancelClick ? (
          <IconButton
            aria-label="close"
            onClick={onCancelClick}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='delete-dialog-description'>
          {event && event.parent_event && event.details && event.details.parent_event && event.details.parent_event.event_type === "SlotEvent"
            ? 'Do you want to delete all the slots and links related to this event?'
            : 'Are you sure this will delete all the slots events and links related to this event?'
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {event && event.parent_event && event.details && event.details.parent_event && event.details.parent_event.event_type === "SlotEvent"
          ? (
            <>
              <Button
                color='error'
                onClick={onDeleteAllClick}
                disabled={disabledDeleteButton}
              >
                Delete all slots
              </Button>
              <Button
                color='error'
                onClick={onDeleteClick}
                disabled={disabledDeleteButton}
              >
                Delete selected
              </Button>
            </>
          )
          : (
            <>
              <Button
                onClick={onCancelClick}
                disabled={disabledDeleteButton}
              >
                Cancel
              </Button>
              <Button
                color='error'
                onClick={onDeleteAllClick}
                disabled={disabledDeleteButton}
              >
                Delete all slots events
              </Button>
            </>
          )
        }
      </DialogActions>
    </Dialog>
  );
}
