import React from 'react';
import { Box } from '@mui/material';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { crmUrls } from '@config/routes';
import { customerEnquiriesMeta } from '@config/meta/crm';
import { useParams } from 'react-router-dom';

const { customerEnquiriesUrls } = crmUrls;

export default function CustomerEnquiryDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      customerEnquiriesMeta({
        data,
      }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: '/customer-enquiries',
                text: 'Customer Enquiries',
              },
              {
                text:
                  recordData?.first_name ??
                  recordData?.email ??
                  recordData?.details?.person?.email,
              },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
        urls={customerEnquiriesUrls}
      />
    </Box>
  );
}
