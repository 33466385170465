import { Grid } from '@mui/material';
import { TextField, AutocompleteField } from '@components/Inputs';
import ColorPicker from '@components/accounts/ColorPicker';

export const RolesForms = () => (
  <Grid item xs={2} sm={4} md={3}>
    <TextField key="name" label="Name" name="name" required />
  </Grid>
);

export const ProfileRolesForms = ({
  values,
  errors,
  roles,
  setRoleTerm,
  isLoading,
}) => (
  <Grid item xs={2} sm={4} md={3}>
    <AutocompleteField
      label="Add Roles"
      name="add_roles"
      options={roles || []}
      isLoading={isLoading}
      setSearchTerm={setRoleTerm}
      value={values.add_roles}
      multiple
      required
    />
  </Grid>
);

export const MicroservicesForms = () => (
  <>
    <Grid item xs={2} sm={4} md={4}>
      <TextField key="name" label="Name" name="name" required />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <TextField
        key="domain_url"
        label="Domain Url"
        name="production_domain_url"
      />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <TextField
        key="devDomainUrl"
        label="Sandbox Url"
        name="sandbox_domain_url"
      />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <TextField
        key="stagingDomainUrl"
        label="Staging Url"
        name="staging_domain_rl"
      />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <ColorPicker key="brand_color" label="Brand Color" name="brand_color" />
    </Grid>
  </>
);

export const UsersForms = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField key="first_name" label="First Name" name="first_name" />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField key="last_name" label="Last Name" name="last_name" />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField key="email" label="Email" name="email" />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField
        key="password"
        label="Password"
        name="password"
        type="password"
      />
    </Grid>
  </>
);

export const permissionsForms = (
  models,
  setModelTerm,
  modelIsFetching,
  users,
  setUserTerm,
  userIsFetching,
  roles,
  setRoleTerm,
  roleIsFetching,
  methodsOptions
) => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        key="models"
        multiple
        setSearchTerm={setModelTerm}
        isLoading={modelIsFetching}
        options={models}
        label="Models"
        name="models"
      />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        key="users"
        multiple
        setSearchTerm={setUserTerm}
        isLoading={userIsFetching}
        options={users}
        label="Users"
        name="users"
      />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        key="roles"
        multiple
        setSearchTerm={setRoleTerm}
        isLoading={roleIsFetching}
        options={roles}
        label="Roles"
        name="roles"
      />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <AutocompleteField
        key="methods"
        multiple
        options={methodsOptions}
        label="Methods"
        name="methods"
      />
    </Grid>
  </>
);
