import React, { useState } from 'react';
import { Box } from '@mui/system';
import DetailAccordionView from '@components/DetailAccordionView';
import DetailCard from '@components/core/DetailCard';
import DetailPageHeader from '@components/DetailPageHeader';
import { calendarUrls } from '@config/routes';
import { batchReminderMeta } from '@config/meta/calendar/calendarMeta';
import { inasRowsMap } from '@config/handleRows/calendar';
import { useParams } from 'react-router-dom';

const { batchRemindersUrls, actionRemindersUrls } = calendarUrls;

export default function BatchReminderDetails({
  performingAction,
  setPerformingAction,
  labels,
  editMode,
}) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => batchReminderMeta({ data }),
    []
  );

  const inasColOptions = React.useMemo(() => {
    return {
      dataPanel: true,
    };
  }, []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/batch-reminders`, text: 'Batch Reminders' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={batchRemindersUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          columnKey="calendarInasCalendar"
          columnOptions={inasColOptions}
          label="INAS (In Queue)"
          urls={actionRemindersUrls}
          handleRowMap={inasRowsMap}
          urlParams={`&reminder_type=INA Reminder Event&batch_reminder=${recordId}&in_queue=true`}
        />

        <DetailAccordionView
          columnKey="calendarInasCalendar"
          columnOptions={inasColOptions}
          label="INAS (Sent)"
          urls={actionRemindersUrls}
          handleRowMap={inasRowsMap}
          urlParams={`&reminder_type=INA Reminder Event&batch_reminder=${recordId}&sent=true`}
        />
      </Box>
    </Box>
  );
}
