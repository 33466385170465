import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { accountUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { RolesForms } from '@config/forms/accounts';
import { roleHandleRow } from '@config/handleRows/accounts';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import PublicUserErrorScreen from '@components/CMS-V2/PublicUserErrorScreen';

const { rolesUrls } = accountUrls;

export default function Roles({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  const isSuperUserPermission =
    user?.details?.roles_names.includes('Superusers') ||
    user?.details?.roles_names.includes('Company Administrator') ||
    user?.details?.is_superuser;

  const rolesColOptions = React.useMemo(
    () => ({
      isSuperUserPermission: isSuperUserPermission,
    }),
    [isSuperUserPermission]
  );

  if (
    Array.isArray(user?.details?.roles_names) &&
    !(
      user?.details?.roles_names.includes('Superusers') ||
      user?.details?.is_superuser
    )
  ) {
    return <PublicUserErrorScreen />;
  }

  return (
    <Box>
      <ListView
        label='Roles'
        prefix='0ap'
        labels={labels}
        editMode={editMode}
        microservice={microservices.ACCOUNTS.name}
        model={microservices.ACCOUNTS.models.roles}
        showDeleteBtn={isSuperUserPermission}
        showBulkDeleteButton={isSuperUserPermission}
        urls={rolesUrls}
        columnKey='rolesAccounts'
        columnOptions={rolesColOptions}
        addNewForm={
          isSuperUserPermission
            ? {
                getFields: () => RolesForms(),
                handleSubmit: async (
                  values,
                  setSubmitting,
                  resetForm,
                  setAddNewForm,
                  refetch,
                  setError
                ) => {
                  try {
                    const response = await createRecord({
                      values,
                      url: rolesUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                    });
                    resetForm();
                    setAddNewForm(false);
                    // notify(`Role created`, {
                    //   type: 'SUCCESS',
                    // });
                    refetch();
                    return response?.data?.id;
                  } catch (err) {
                    console.log(err);
                    handleCreateFormErrors({ err, setError, notify, values });
                  } finally {
                    setSubmitting(false);
                  }
                  return null;
                },
              }
            : null
        }
        handleRowsMap={roleHandleRow}
      />
    </Box>
  );
}
