import React from 'react';

export default function useRemoveElementByText(textToRemove) {
  React.useEffect(() => {
    // Function to remove the element
    const removeElement = () => {
      const elements = document.querySelectorAll('div');
      elements.forEach((element) => {
        if (element.textContent === textToRemove) {
          element.remove();
        }
      });
    };

    // Run the removal function on mount and on any component updates
    removeElement();

    // Optional: add a mutation observer to keep the element removed if dynamically added
    const observer = new MutationObserver(removeElement);
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, [textToRemove]);
}
