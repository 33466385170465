import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { FieldArray } from 'formik';
import EmailTemplateAccordion from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateAccordion';
import EmailTemplateEDocCard from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateEDocCard';
import {
  getEdocFields,
  hasKeyIncludingSubstring,
} from '@config/functions/emailTemplateConfigurationPopupFunctions';

export default function EmailTemplateEDocList({
  shape,
  shapeAction,
  values,
  setValues,
  setFieldValue,
  workflowId,
  workflowName,
  showAddReminder,
  setShowAddReminder,
  isKanbanView,
  user,
  errors,
}) {
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const deleteEdoc = (idx) => {
    const keysThatNeedsToBeModified = [];
    const keysThatNeedsToBeDeleted = [];
    const beforeHandEdocs = values.edocs.slice(0, idx);
    const oldFields = getEdocFields(idx);

    Object.entries(oldFields).map(([key, value]) => {
      keysThatNeedsToBeDeleted.push(value);
    });

    const newEdocs = [
      ...beforeHandEdocs,
      ...values.edocs.slice(idx + 1).map((edoc, eidx) => {
        const oldFields = getEdocFields(idx + eidx);
        const fields = getEdocFields(idx + eidx + 1);
        const response = {
          payload_section_key_reference: edoc.payload_section_key_reference,
        };

        Object.entries(fields).map(([key, value]) => {
          response[oldFields[key]] = edoc[value];
          keysThatNeedsToBeModified.push([oldFields[key], values[value]]);
        });

        return response;
      }),
    ];

    const lastFields = getEdocFields(newEdocs.length);

    Object.entries(lastFields).map(([key, value]) => {
      keysThatNeedsToBeDeleted.push(value);
    });

    const newValues = { ...values, edocs: newEdocs };

    keysThatNeedsToBeDeleted.map((key) => {
      delete newValues[key];
    });

    keysThatNeedsToBeModified.map(([key, value]) => {
      newValues[key] = value;
    });

    setValues(newValues);
  };

  return (
    <Box>
      <EmailTemplateAccordion
        open={open}
        setOpen={handleToggle}
        label="eDocs"
        isError={hasKeyIncludingSubstring(errors, 'edocs')}
      >
        <FieldArray name="edocs">
          {({ push }) => (
            <Stack spacing={2}>
              {isKanbanView ? null : (
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      const fields = getEdocFields(values?.edocs.length);
                      const uniqueKey = uuidv4();

                      push({
                        [fields.document]: {
                          label: '',
                          static_value: '',
                          mapping_field_name: null,
                        },
                        [fields.person]: null,
                        [fields.recipient_first_name]: null,
                        [fields.recipient_last_name]: null,
                        [fields.recipient_email]: null,
                        [fields.expiry_duration]: {
                          label: '1',
                          static_value: 1,
                          mapping_field_name: null,
                        },
                        [fields.document_field_values]: {},
                        payload_section_key_reference: `payload_section_${uniqueKey}`,
                      });

                      setFieldValue(fields.expiry_duration, 1);
                    }}
                  >
                    Add New
                  </Button>
                </Box>
              )}

              <Box>
                {values?.edocs?.map((doc, index) => (
                  <EmailTemplateEDocCard
                    shape={shape}
                    shapeAction={shapeAction}
                    key={index}
                    docIndex={index}
                    remove={deleteEdoc}
                    doc={doc}
                    user={user}
                    setFieldValue={setFieldValue}
                    workflowId={workflowId}
                    workflowName={workflowName}
                    showAddReminder={showAddReminder}
                    setShowAddReminder={setShowAddReminder}
                    values={values}
                    errors={errors}
                    isKanbanView={isKanbanView}
                  />
                ))}
              </Box>
            </Stack>
          )}
        </FieldArray>
      </EmailTemplateAccordion>
    </Box>
  );
}
