import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import {
  TextField,
  SelectField,
  CheckBoxField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import {
  dataTypeOptions,
  computeOnDeleteOptions,
  computeModelFormTypes,
  computeForeignKeyTargets,
} from '@config/constants';
import { computeUrls, accountUrls } from '@config/routes';

const {
  modelsUrls,
  microservicesUrls,
  enumsUrls,
  modelFieldsUrls,
  menuDefnsUrls,
} = computeUrls;
const {
  microservicesUrls: accountsMicroservicesUrls,
  modelsUrls: accountsModelsUrls,
  modelFieldsUrls: accountsModelFieldsUrls,
} = accountUrls;

export const MicroservicesForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label='Name' name='name' multiline required />
    </Grid>

    <Grid item xs={1}>
      <TextField
        label='Version'
        name='version'
        placeholder='1.0.0'
        required
        multiline
      />
    </Grid>

    <Grid item xs={6}>
      <TextField label='Description' name='description' minRows={2} multiline />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Tags' name='tags' multiline />
    </Grid>
  </>
);

export const EnumsForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label='Name' name='name' multiline required />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Tags' name='tags' multiline />
    </Grid>
  </>
);

export const EnumValuesForm = () => (
  <>
    <Grid item xs={4}>
      <TextField
        label='Value'
        name='value'
        multiline
        required
        helperText='Each word should start with a capital letter, as in "PhoneCall." Ensure there is no space.'
      />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Tags' name='tags' multiline />
    </Grid>
  </>
);

export const ModelsForm = () => (
  <>
    <Grid item xs={4}>
      <TextField
        label='Name'
        name='name'
        multiline
        required
        helperText='Each word should start with a capital letter, as in "PhoneCall." Ensure terms are singular, avoiding plural forms.'
      />
    </Grid>

    <Grid item xs={2}>
      <TextField label='Label' name='label' multiline required />
    </Grid>

    <Grid item xs={6}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Helpful Hint' name='helpfulHint' />
    </Grid>

    <Grid item sm={1.5}>
      <CheckBoxField label='Use Form Flow?' name='useFormFlow' />
    </Grid>

    <Grid item xs={6.5}>
      <TextField label='Tags' name='tags' multiline />
    </Grid>
  </>
);

export const FormFlowsForm = () => (
  <>
    <Grid item xs={4}>
      <TextField label='Name' name='name' multiline required />
    </Grid>

    <Grid item md={2}>
      <SelectField label='Type' name='type' required>
        {computeModelFormTypes.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>
  </>
);

export const FormFlowStepsForm = () => (
  <>
    <Grid item sm={1}>
      <TextField label='Order' name='order' />
    </Grid>

    <Grid item xs={6}>
      <TextField label='Name' name='name' multiline required />
    </Grid>
  </>
);

export const FormFieldLayoutStepsForm = ({ modelId } = {}) => (
  <>
    <Grid item sm={1}>
      <TextField label='Order' name='order' required />
    </Grid>

    <Grid item xs={4}>
      <AutocompleteFieldV2
        name='fieldDefnId'
        label='Fields'
        requestKey='step-fields'
        fetchUrl={modelFieldsUrls.list}
        urlParams={`&modelId=${modelId}`}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.name,
        })}
        enabled={!!modelId}
        required
      />
    </Grid>

    <Grid item sm={1.5}>
      <TextField label='Col Span' name='colSpan' required />
    </Grid>
  </>
);

export const FieldsForm = ({ values, setFieldValue, microserviceId } = {}) => {
  return (
    <>
      <Grid item sm={1}>
        <TextField label='Order' name='order' />
      </Grid>

      <Grid item md={2}>
        <SelectField label='Data Type' name='dataType' required>
          {dataTypeOptions.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item xs={2}>
        <TextField
          label='Name'
          name='name'
          multiline
          required
          helperText='Use camelCase formatting, such as "phoneCall" or "makePhoneCall."'
        />
      </Grid>

      <Grid item xs={2}>
        <TextField label='Label' name='label' multiline required />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Description' name='description' />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Helpful Hint' name='helpfulHint' />
      </Grid>

      <Grid item xs={3}>
        <TextField label='Default Value' name='defaultValue' />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label='Min Length' name='minLength' />
      </Grid>

      <Grid item xs={1.5}>
        <TextField label='Max Length' name='maxLength' />
      </Grid>

      <Grid item sm={2}>
        <CheckBoxField label='Is Clickable Link?' name='isClickableLink' />
      </Grid>

      <Grid item sm={1.5}>
        <CheckBoxField label='Is Multiline?' name='isMultiline' />
      </Grid>

      <Grid item sm={1.5}>
        <CheckBoxField label='Show In Table?' name='showInTable' />
      </Grid>

      <Grid item sm={1.5}>
        <CheckBoxField label='Optional?' name='isOptional' />
      </Grid>

      <Grid item sm={1.5}>
        <CheckBoxField label='Is Editable?' name='isEditable' />
      </Grid>

      <Grid item sm={2.5}>
        <CheckBoxField label='Show In Detail Card?' name='showInDetailCard' />
      </Grid>

      <Grid item sm={1.5}>
        <CheckBoxField label='Unique?' name='isUnique' />
      </Grid>

      <Grid item sm={1.5}>
        <CheckBoxField label='Index?' name='isIndex' />
      </Grid>

      <Grid item sm={1.5}>
        <CheckBoxField label='Foreign Key?' name='isForeignKey' />
      </Grid>

      {values?.isForeignKey ? (
        <>
          <Grid item md={1.5}>
            <SelectField
              label='Foreign Key Target'
              name='foreignKeyTarget'
              required
            >
              {computeForeignKeyTargets.map((r) => (
                <MenuItem value={r.value} key={r.value}>
                  {r.label}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          {values?.foreignKeyTarget === 'Internal' ? (
            <>
              <Grid item xs={2}>
                <AutocompleteFieldV2
                  name='foreignKeyMicroserviceId'
                  label='Foreign Key Microservice'
                  requestKey='microservice-field'
                  fetchUrl={microservicesUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                  onChange={() => {
                    setFieldValue('foreignKeyModelId', null);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <AutocompleteFieldV2
                  requestKeyOptions={[values?.foreignKeyMicroserviceId?.id]}
                  name='foreignKeyModelId'
                  label='Foreign Key Model'
                  requestKey='model-field'
                  fetchUrl={modelsUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                  required
                  enabled={!!values?.foreignKeyMicroserviceId?.id}
                  disabled={!values?.foreignKeyMicroserviceId?.id}
                  urlParams={
                    values?.foreignKeyMicroserviceId?.id
                      ? `&microserviceId=${values.foreignKeyMicroserviceId.id}`
                      : ''
                  }
                />
              </Grid>
            </>
          ) : null}

          {values?.foreignKeyTarget === 'External' ? (
            <>
              <Grid item xs={2}>
                <AutocompleteFieldV2
                  name='externalMicroserviceId'
                  label='External Microservice'
                  requestKey='external-microservice-field'
                  fetchUrl={accountsMicroservicesUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                  onChange={() => {
                    console.log('onChange?');
                    setFieldValue('externalModelId', { id: null, label: '' });
                    setFieldValue('externalDisplayValueId', {
                      id: null,
                      label: '',
                    });
                  }}
                  required
                />
              </Grid>

              <Grid item xs={2}>
                <AutocompleteFieldV2
                  requestKeyOptions={[values?.externalMicroserviceId?.id]}
                  name='externalModelId'
                  label='External Model'
                  requestKey='external-model-field'
                  fetchUrl={accountsModelsUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                  onChange={() => {
                    setFieldValue('externalDisplayValueId', {
                      id: null,
                      label: '',
                    });
                  }}
                  required
                  enabled={!!values?.externalMicroserviceId?.id}
                  disabled={!values?.externalMicroserviceId?.id}
                  urlParams={
                    values?.externalMicroserviceId?.id
                      ? `&microservice=${values.externalMicroserviceId.id}`
                      : ''
                  }
                />
              </Grid>

              <Grid item xs={2}>
                <AutocompleteFieldV2
                  requestKeyOptions={[values?.externalModelId?.id]}
                  name='externalDisplayValueId'
                  label='External Display Value'
                  requestKey='external-display-value-field'
                  fetchUrl={accountsModelFieldsUrls.list}
                  renderRow={(row) => ({
                    id: row?.id,
                    label: row?.name,
                  })}
                  required
                  enabled={!!values?.externalModelId?.id}
                  disabled={!values?.externalModelId?.id}
                  urlParams={
                    values?.externalModelId?.id
                      ? `&model=${values.externalModelId.id}`
                      : ''
                  }
                />
              </Grid>
            </>
          ) : null}
        </>
      ) : null}

      {values?.dataType === 'Enum' ? (
        <Grid item xs={2}>
          <AutocompleteFieldV2
            name='enumDefnId'
            label='Enum'
            requestKey='microservice-enums'
            fetchUrl={enumsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.name,
            })}
            required
            enabled={!!microserviceId}
            disabled={!microserviceId}
            requestKeyOptions={[microserviceId]}
            urlParams={
              microserviceId ? `&microserviceId=${microserviceId}` : ''
            }
          />
        </Grid>
      ) : null}

      <Grid item md={2}>
        <SelectField label='On Delete' name='onDelete' required>
          {computeOnDeleteOptions.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item xs={4}>
        <TextField label='Tags' name='tags' />
      </Grid>
    </>
  );
};

export const MenusForm = ({ microserviceId, hideParentField } = {}) => (
  <>
    <Grid item sm={1}>
      <TextField label='Order' name='order' />
    </Grid>

    <Grid item xs={4}>
      <AutocompleteFieldV2
        name='modelId'
        label='Model'
        requestKey='microservice-models'
        fetchUrl={modelsUrls.list}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.name,
        })}
        required
        enabled={!!microserviceId}
        disabled={!microserviceId}
        requestKeyOptions={[microserviceId]}
        urlParams={microserviceId ? `&microserviceId=${microserviceId}` : ''}
      />
    </Grid>

    {hideParentField ? null : (
      <Grid item xs={4}>
        <AutocompleteFieldV2
          name='parentMenuId'
          label='Parent Menu'
          requestKey='microservice-menus'
          fetchUrl={menuDefnsUrls.list}
          renderRow={(row) => ({
            id: row?.id,
            label: `${row?.order}. ${row?.model?.name}`,
          })}
          enabled={!!microserviceId}
          disabled={!microserviceId}
          requestKeyOptions={[microserviceId]}
          urlParams={microserviceId ? `&microserviceId=${microserviceId}` : ''}
        />
      </Grid>
    )}
  </>
);

export const BlockGroupForm = () => (
  <>
    <Grid item sm={1}>
      <TextField label='Order' name='order' />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Name' name='name' required multiline />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Description' name='description' multiline />
    </Grid>
    <Grid item xs={3}>
      <TextField label='Tags' name='tags' />
    </Grid>
  </>
);

export const BlockForm = () => (
  <>
    <Grid item sm={1}>
      <TextField label='Order' name='order' />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Name' name='name' required multiline />
    </Grid>
    <Grid item xs={4}>
      <TextField label='Description' name='description' multiline />
    </Grid>
    <Grid item xs={3}>
      <TextField label='Tags' name='tags' />
    </Grid>
  </>
);
