import React from 'react';
import useToast from '@hooks/useToast';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { Link, useParams } from 'react-router-dom';
import { microservices } from '@config/constants';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { eDocUrls } from '@config/routes';
import { documentMeta } from '@config/meta/eDoc';
import DetailAccordionView from '@components/DetailAccordionView';
import { documentRecipientsRowMap } from '@config/handleRows/eDoc';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { createRecord } from '@config/functions/requests';
import { RecipientForm } from '@config/forms/eDoc';

const { documentsUrls, recipientsUrls } = eDocUrls;

export default function FormDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => documentMeta({ data }), []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/documents', text: 'Documents' },
              { text: recordData?.name },
            ]}
          >
            <Link to={`/edit-document/${recordId}`}>
              <Button variant='outlined'>Edit Document</Button>
            </Link>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={documentsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
        getValues={(values) => {
          return {
            ...values,
            anonymous_can_see_it: values.isPublished,
          };
        }}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          prefix='0jm'
          label='Recipient'
          labels={labels}
          editMode={editMode}
          urls={recipientsUrls}
          columnKey='documentRecipient'
          microservice={microservices.eDoc.name}
          model={microservices.eDoc.models.recipient}
          addNewForm={{
            getFields: ({ setFieldValue, values }) =>
              RecipientForm({
                setFieldValue,
                documentFieldDefns: recordData?.documentFieldDefns,
                values,
              }),
            handleSubmit: async (
              { emailTemplateId, ...values },
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                if (!emailTemplateId?.id) {
                  setFieldError(
                    'emailTemplateId',
                    'Please select an email template'
                  );
                  return;
                }

                const { personId, ...rest } = values;

                const { data } = await createRecord({
                  values: {
                    ...rest,
                    isEditable: true,
                    personId: personId?.id,
                    emailTemplateId: emailTemplateId?.id,
                    domainHost: window.location.origin,
                    documentId: recordId,
                    anonymous_can_see_it: true,
                    createdBy: user?.details?.id,
                    client: user?.actAs || user?.client_details?.id,
                  },
                  url: recipientsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={documentRecipientsRowMap}
          urlParams={`&documentId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
