import React from 'react';
import { Box } from '@mui/material';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { lmsUrls } from '@config/routes';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { categoryMeta } from '@config/meta/lms';

const { categoriesUrls } = lmsUrls;

export default function StudentDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => categoryMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/categories', text: 'Category' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={categoriesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
    </Box>
  );
}
