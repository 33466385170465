export const roleHandleRow = (c) => ({
  id: c.id,
  name: c.name,
  created_at: c.created_at,
});

export const profileRoleHandleRow = (c) => ({
  id: c?.id,
  name: c?.details?.role?.name,
  profile: c?.profile,
  role: c?.role,
  created_at: c?.created_at,
});

export const microserviceHandleRow = (c) => ({
  id: c.name,
  name: c.name,
  domain_url: c.production_domain_url,
  devDomainUrl: c.sandbox_domain_url,
  stagingDomainUrl: c.staging_domain_rl,
  brand_color: c.brand_color,
  created_at: c.created_at,
});

export const modelHandleRow = (c) => ({
  id: c.id,
  name: c.name,
  microservice:
    c.microservice && c.microservice_details
      ? c.microservice_details.name
      : c.microservice,
  created_at: c.created_at,
});

export const userHandleRow = (c) => ({
  id: c.id,
  username: c.username,
  email: c.email,
  first_name: c.first_name,
  last_name: c.last_name,
  is_active: c.is_active,
  company_joined: c.company_joined,
  email_confirmed: c.email_confirmed,
  date_joined: c.date_joined,
});

export const roleUserHandleRow = (c, roleId) => ({
  id: c.id,
  email: c.email || '...',
  username: c.username || '...',
  first_name: c.first_name || '...',
  last_name: c.last_name || '...',
  member: c.roles && c.roles.find((ele) => ele.id === roleId),
  created_at: c.created_at,
});

export const rolePermissionHandleRow = (c, rolesId) => ({
  id: c.id,
  model:
    (c.name &&
      c.microservice_details &&
      `${c.microservice_details.name} / ${c.name}`) ||
    c.name,
  post:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.role_id === rolesId && ele.methods.find((name) => name === 'POST')
      ) &&
      true) ||
    false,
  get:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.role_id === rolesId && ele.methods.find((name) => name === 'GET')
      ) &&
      true) ||
    false,
  put:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.role_id === rolesId && ele.methods.find((name) => name === 'PUT')
      ) &&
      true) ||
    false,
  patch:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.role_id === rolesId &&
          ele.methods.find((name) => name === 'PATCH')
      ) &&
      true) ||
    false,
  delete:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.role_id === rolesId &&
          ele.methods.find((name) => name === 'DELETE')
      ) &&
      true) ||
    false,
  options:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.role_id === rolesId &&
          ele.methods.find((name) => name === 'OPTIONS')
      ) &&
      true) ||
    false,
  created_at: c.created_at,
});

export const usersRolesHandleRow = (c, data) => ({
  id: c.id,
  name: c.name,
  member:
    (data && data.roles && data.roles.find((ele) => ele === c.id) && true) ||
    false,
  created_at: c.created_at,
});

export const userPermissionHandleRow = (c, userId) => ({
  id: c.id,
  model:
    (c.name &&
      c.microservice_details &&
      `${c.microservice_details.name} / ${c.name}`) ||
    c.name,
  post:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.user_id === userId && ele.methods.find((name) => name === 'POST')
      ) &&
      true) ||
    false,
  get:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.user_id === userId && ele.methods.find((name) => name === 'GET')
      ) &&
      true) ||
    false,
  put:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.user_id === userId && ele.methods.find((name) => name === 'PUT')
      ) &&
      true) ||
    false,
  patch:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.user_id === userId && ele.methods.find((name) => name === 'PATCH')
      ) &&
      true) ||
    false,
  delete:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.user_id === userId &&
          ele.methods.find((name) => name === 'DELETE')
      ) &&
      true) ||
    false,
  options:
    (c.permissions &&
      c.permissions.find(
        (ele) =>
          ele.user_id === userId &&
          ele.methods.find((name) => name === 'OPTIONS')
      ) &&
      true) ||
    false,
  created_at: c.created_at,
});

export const permissionsHandleRow = (c) => ({
  id: c.id,
  models:
    (c.model &&
      c.model.microservice &&
      `${c.model.microservice.name} / ${c.model.name}`) ||
    c.model,
  users: (c.user && c.user.email) || '...',
  roles: (c.role && c.role.name) || '...',
  methods: c.methods,
  view: c.view,
  for_anonymous: c.for_anonymous,
  created_at: c.created_at,
});

export const manageUserHandleRow = (c) => ({
  id: c.id,
  username: c.username,
  email: c.email,
  first_name: c.first_name,
  last_name: c.last_name,
  is_active: c.is_active,
  client_joined: c.client_joined,
  email_confirmed: c.email_confirmed,
  created_at: c.created_at,
});
