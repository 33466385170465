import React from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import { FreeSoloAutocompleteField } from '@components/Inputs';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { crmUrls } from '@config/routes';
import useRQuery from '@hooks/useRQuery';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  getAuthHeader,
  validateEmail,
} from '@config/functions/helperFunctions';

const { personsUrls } = crmUrls;

export default function EmailTemplateFormFields({
  values,
  form,
  formIdx,
  setFieldValue,
  user,
  handleIna,
  isKanbanView,
}) {
  const [personTerm, setPersonTerm] = React.useState('');
  const [personDetails, setPersonDetails] = React.useState({});

  const { data: personsList, isFetching: fetchingPersons } = useRQuery({
    key: ['bpa-form-persons-data', personTerm, user.token, user?.actAs],
    url: personsUrls.list(`?search=${personTerm}&page_size=100`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  React.useEffect(() => {
    if (!personsList || !personsList.results) return;

    setPersonDetails({
      persons: personsList.results
        .map((item) => ({
          firstName: item?.first_name,
          lastName: item?.last_name,
          email: item?.email,
          label: item?.email,
          static_value: item?.id,
          mapping_field_name: null,
        }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.static_value === value.static_value)
        ),
      firstNames: personsList.results
        .map((item) => ({
          label: item?.first_name,
          static_value: item?.first_name,
          mapping_field_name: null,
        }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.static_value === value.static_value)
        ),
      lastNames: personsList.results
        .filter((item) => item?.last_name && item?.last_name.length)
        .map((item) => ({
          label: item?.last_name,
          static_value: item?.last_name,
          mapping_field_name: null,
        }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.static_value === value.static_value)
        ),
      emails: personsList.results
        .map((item) => ({
          label: item?.email,
          static_value: item?.email,
          mapping_field_name: null,
        }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.static_value === value.static_value)
        ),
    });
  }, [personsList]);

  const fields = [
    {
      label: 'Person',
      name: `forms-${formIdx}-person`,
      options: personDetails?.persons ?? [],
    },
    {
      label: 'Recipient First Name',
      name: `forms-${formIdx}-recipient_first_name`,
      options: personDetails?.firstNames ?? [],
    },
    {
      label: 'Recipient Last Name',
      name: `forms-${formIdx}-recipient_last_name`,
      options: personDetails?.lastNames ?? [],
    },
    {
      label: 'Recipient Email',
      name: `forms-${formIdx}-recipient_email`,
      options: personDetails?.emails ?? [],
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex' }}>
              <IconButton
                aria-label="add"
                color="primary"
                sx={{ pl: '1px' }}
                onClick={() => {
                  const newForms = [...values.forms];
                  newForms[formIdx][field?.name] = {
                    label: values?.formPayloadSectionKey?.fullLabel,
                    static_value: field?.label === 'Person' ? null : '',
                    mapping_field_name: values?.formPayloadSectionKey?.key,
                  };
                  setFieldValue('forms', newForms);
                }}
                disabled={!values?.formPayloadSectionKey}
              >
                <SubdirectoryArrowRightIcon fontSize="inherit" />
              </IconButton>
              <Box sx={{ width: '100%' }}>
                <FreeSoloAutocompleteField
                  {...field}
                  customValue={
                    values?.forms[formIdx][field?.name]
                      ? [values?.forms[formIdx][field?.name]]
                      : []
                  }
                  label={field?.label}
                  loading={fetchingPersons}
                  setSearchTerm={setPersonTerm}
                  isOptionEqualToValue={(option, value) =>
                    option?.static_value === value?.static_value
                  }
                  onChange={({ newValue: newValues }) => {
                    if (!newValues) return;
                    const newValue = newValues[newValues.length - 1];
                    if (
                      (field?.label === 'Recipient Email' &&
                        typeof newValue === 'string' &&
                        !validateEmail(newValue)) ||
                      (field?.label === 'Person' &&
                        typeof newValue === 'string')
                    )
                      return;
                    const newForms = [...values.forms];
                    newForms[formIdx][field?.name] = newValue
                      ? {
                          label: newValue?.label ?? newValue,
                          static_value: newValue?.static_value ?? newValue,
                          mapping_field_name: null,
                        }
                      : null;
                    if (field?.label === 'Person') {
                      newForms[formIdx][fields[1]?.name] = newValue?.firstName
                        ? {
                            label: newValue?.firstName,
                            static_value: newValue?.firstName,
                            mapping_field_name: null,
                          }
                        : null;
                      newForms[formIdx][fields[2]?.name] = newValue?.lastName
                        ? {
                            label: newValue?.lastName,
                            static_value: newValue?.lastName,
                            mapping_field_name: null,
                          }
                        : null;
                      newForms[formIdx][fields[3]?.name] = newValue?.email
                        ? {
                            label: newValue?.email,
                            static_value: newValue?.email,
                            mapping_field_name: null,
                          }
                        : null;
                    }
                    setFieldValue('forms', newForms);
                  }}
                />
              </Box>
              <IconButton
                aria-label="add-ina"
                color="primary"
                sx={{ pr: '1px' }}
                disabled={isKanbanView}
                onClick={() => handleIna(field?.label)}
              >
                <PlaylistAddIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
