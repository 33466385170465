import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import Switch from '@ui/Inputs/SwitchField';

export default function SwitchField({
  label,
  color,
  labelColor = '#FFFFFF',
  ...rest
}) {
  const [field] = useField(rest);
  const { setFieldValue } = useFormikContext();
  return (
    <Switch
      checked={field.value}
      onChange={(e) => setFieldValue(field.name, e.target.checked)}
      name={field.name}
      label={label}
      color={color}
      labelColor={labelColor}
    />
  );
}
