import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@components/Inputs';
import { createRecord, updateRecord } from '@config/functions/requests';
import { eDocUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { v4 as uuid } from 'uuid';

const { documentFieldInstancesUrls } = eDocUrls;

export default function DocFieldInstancePopup({
  open,
  setOpen,
  options,
  setFields,
}) {
  const user = useSelector(selectProfile);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent maxWidth="sm" fullWidth>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {options?.action} field
        </Typography>

        <Formik
          initialValues={{
            imageX: options?.imageX || '',
            imageY: options?.imageY || '',
            width: options?.width || 78,
            height: options?.height || 18,
            pageNumber: options?.pageNumber,
          }}
          validationSchema={Yup.object({
            imageX: Yup.number().positive('Invalid X position').required(),
            imageY: Yup.number().positive('Invalid Y position').required(),
            width: Yup.number().positive('Invalid width').required(),
            height: Yup.number().positive('Invalid height').required(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              const { imageX, imageY, width, height, ...rest } = values;

              const payload = {
                ...rest,
                id: options?.id || uuid(),
                imageX: +imageX,
                imageY: +imageY,
                width: +width,
                height: +height,
                pdfX: +options?.pdfX || undefined,
                pdfY: parseInt(+options?.pageHeight - +imageY),
                pageNumber: +options?.pageNumber || undefined,
                documentFieldDefnId: options?.documentFieldDefnId,
              };

              const requestPayload = {
                values: payload,
                url: documentFieldInstancesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              };

              if (options?.action === 'Edit') {
                requestPayload.url = documentFieldInstancesUrls.detail(
                  options?.id
                );
              }

              if (options?.action === 'Add') {
                await createRecord(requestPayload);
                setFields((prevState) => [...prevState, payload]);
              } else if (options?.action === 'Edit') {
                await updateRecord(requestPayload);

                Object.keys(payload).forEach((key) => {
                  if (payload[key] === undefined) {
                    delete payload[key];
                  }
                });

                setFields((prevState) =>
                  prevState.map((field) => {
                    if (field.id === payload.id) {
                      return { ...field, ...payload };
                    }
                    return field;
                  })
                );
              }

              resetForm();
              setOpen(false);
            } catch (error) {
              console.log(error?.response?.data ?? error?.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField label="X Position" name="imageX" />
                </Grid>

                <Grid item xs={3}>
                  <TextField label="Y Position" name="imageY" />
                </Grid>

                <Grid item xs={3}>
                  <TextField label="Width" name="width" />
                </Grid>

                <Grid item xs={3}>
                  <TextField label="Height" name="height" />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
