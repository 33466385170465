import { urlShortnerHOST } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  link: 'links',
  utm: 'utms',
  combinedData: 'list-data',
  importLinks: 'import-links',
  exportLinks: 'export-links',
  bulkCreateOrUpdateLinks: 'bulk-create-or-update-links',
};

const linkUrls = getModelUrls(urlShortnerHOST, models.link);
const utmUrls = getModelUrls(urlShortnerHOST, models.utm);
const combinedDataUrls = getModelUrls(urlShortnerHOST, models.combinedData);
const importLinksUrls = getModelUrls(urlShortnerHOST, models.importLinks);
const exportLinksUrls = getModelUrls(urlShortnerHOST, models.exportLinks);
const bulkCreateOrUpdateLinksUrls = getModelUrls(
  urlShortnerHOST,
  models.bulkCreateOrUpdateLinks
);

const urls = {
  linkUrls,
  utmUrls,
  combinedDataUrls,
  importLinksUrls,
  exportLinksUrls,
  bulkCreateOrUpdateLinksUrls,
};

export default urls;
