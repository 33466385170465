import React from 'react';
import * as Yup from 'yup';
import { lmsUrls } from '@config/routes';
import { facultyMeta } from '@config/meta/lms';
import { Grid } from '@mui/material';
import { TextField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';

const { facultiesUrls } = lmsUrls;

export default function FacultyDropdown({ children, universityId, refetch }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title='Faculty'
        initialValues={{
          ...getAddFormInitialValues(facultyMeta(), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(facultyMeta(), 'listView'),
        })}
        getPostUrl={() => facultiesUrls.list()}
        getValues={(values) => ({
          ...values,
          universityId,
        })}
        getFields={() => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField label='Name' name='name' required />
            </Grid>

            <Grid item xs={12}>
              <TextField multiline label='Description' name='description' />
            </Grid>
          </Grid>
        )}
        open={open}
        setOpen={setOpen}
        refetch={refetch}
      />
    </>
  );
}
