import React from 'react';
import * as Yup from 'yup';
import { lmsUrls } from '@config/routes';
import { homeworkMeta } from '@config/meta/lms';
import { Grid, MenuItem } from '@mui/material';
import { TextField, SelectField } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import { lmsHomeworkTypes } from '@config/constants';

const { homeworksUrls } = lmsUrls;

export default function HomeworkDropdownForm({ title, children, refetch }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>
      <FormDialog
        title={title || 'Homework'}
        initialValues={{
          ...getAddFormInitialValues(homeworkMeta({}), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(homeworkMeta({}), 'listView'),
        })}
        getPostUrl={() => homeworksUrls.list()}
        getValues={(values) => ({
          ...values,
        })}
        getFields={() => (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                label='Task Title'
                name='name'
                required
                multiline
              />
            </Grid>

            <Grid item xs={6}>
              <SelectField label='Type' name='type' required>
                {lmsHomeworkTypes.map(({ label, value }) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label='Description'
                name='description'
                minRows={3}
                multiline
              />
            </Grid>

            <Grid item xs={12}>
              <TextField label='URL' name='url' multiline />
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
