import React from 'react';
import _ from 'lodash';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const AccordionStyled = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const flexStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export default function ContextFieldAccordion({
  label,
  open,
  setOpen,
  children,
  endContent,
  isError,
  disabled = false,
}) {
  const key = _.camelCase(label);

  return (
    <AccordionStyled expanded={open} onChange={setOpen} disabled={disabled}>
      <AccordionSummary aria-controls={`${key}-content`} id={`${key}-header`}>
        <Box
          sx={{
            width: '100%',
            ...flexStyles,
          }}
        >
          <Box sx={flexStyles}>
            <Typography
              sx={{
                mr: 1,
                color: isError ? '#ff1744' : undefined,
                fontWeight: isError ? '500' : undefined,
              }}
            >
              {label}
            </Typography>
            {isError ? (
              <IconButton
                sx={{ color: '#ff1744' }}
                size="small"
                aria-label="Errors"
              >
                <ErrorOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
          {endContent ? endContent() : null}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionStyled>
  );
}
