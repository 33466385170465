import React from 'react';
import { Box } from '@mui/material';
import moment from 'moment';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

const DnDCalendar = withDragAndDrop(Calendar);
const mLocalizer = momentLocalizer(moment);

export default function CalendarDnD({
  events,
  onEventDrop,
  onEventResize,
  onSelectEvent,
  onSelectSlot,
  ...others
}) {
  return (
    <Box style={{ m: 5, height: 580 }}>
      <DnDCalendar
        popup
        resizable
        selectable
        events={events}
        defaultDate={new Date()}
        defaultView={Views.MONTH}
        localizer={mLocalizer}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        scrollToTime={new Date()}
        {...others}
      />
    </Box>
  );
}
