import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import UrlShorteners from '@pages/Marketing/UrlShorteners';
import UrlShortenerDetail from '@pages/Marketing/UrlShorteners/detail';
import QRCodes from '@pages/Marketing/QRCodes';
import QRCodeDetail from '@pages/Marketing/QRCodes/detail';
import ConnectPlatform from '@pages/Marketing/ConnectPlatform';
import Campaigns from '@pages/Marketing/Campaigns';
import CampaignDetail from '@pages/Marketing/Campaigns/detail';
import ConnectTwitter from '@pages/Marketing/ConnectTwitter';
import ConnectLinkedIn from '@pages/Marketing/ConnectLinkedIn';

import MarketSegments from '@pages/Marketing/MarketSegments';
import MarketSegmentDetail from '@pages/Marketing/MarketSegments/detail';

export default function () {
  return (
    <>
      <Route
        path='/social-media-campaigns'
        element={<WithLayout comp={Campaigns} />}
      />
      <Route
        path='/social-media-campaigns/:id'
        element={<WithLayout comp={CampaignDetail} />}
      />
      <Route
        path='/connect-twitter'
        element={<WithLayout comp={ConnectTwitter} />}
      />
      <Route
        path='/connect-linkedin'
        element={<WithLayout comp={ConnectLinkedIn} />}
      />
      <Route
        path='/connect-platform'
        element={<WithLayout comp={ConnectPlatform} />}
      />
      <Route path='/links' element={<WithLayout comp={UrlShorteners} />} />
      <Route
        path='/links/:id'
        element={<WithLayout comp={UrlShortenerDetail} />}
      />
      <Route path='/qr-code' element={<WithLayout comp={QRCodes} />} />
      <Route
        path='/qr-code/:str/:id'
        element={<WithLayout comp={QRCodeDetail} />}
      />

      <Route
        path='/market-segments'
        element={<WithLayout comp={MarketSegments} />}
      />
      <Route
        path='/market-segments/:id'
        element={<WithLayout comp={MarketSegmentDetail} />}
      />
    </>
  );
}
