import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import { ButtonGroup } from '@mui/material';
import ReminderPopup from '@components/ReminderPopup';
import { microservices } from '@config/constants';
import {
  hrUrls,
  calendarUrls,
  communicationUrls,
  bpaUrls,
  nodeDriveUrls,
} from '@config/routes';
import { assessmentRowsMap } from '@config/handleRows/hr';
import { createRecord } from '@config/functions/requests';
import {
  handleCreateFormErrors,
  getAuthHeader,
} from '@config/functions/helperFunctions';
import DetailCard from '@components/core/DetailCard';
import { AssessmentForm } from '@config/forms/hr';
import useToast from '@hooks/useToast';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { inasRowsMap } from '@config/handleRows/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { emailRowsMap } from '@config/handleRows/communication';
import useRQuery from '@hooks/useRQuery';
import { candidateMeta } from '@config/meta/hr';
import DataPanelTimeline from './DataPanelTimeline';
import Accordion from '@ui/Accordion';
import JsonView from '@uiw/react-json-view';
import { lightTheme } from '@uiw/react-json-view/light';

const { assessmentsUrls, candidatesUrls } = hrUrls;
const { actionRemindersUrls } = calendarUrls;
const { notificationsUrls } = communicationUrls;
const { dataPopupUrls } = bpaUrls;
const { filesUrls } = nodeDriveUrls;

export default function AutomataSidePeek({
  instanceId,
  showManualTaskData,
  setShowManualTaskData,
}) {
  const [notify] = useToast();
  const [showAddIna, setShowAddIna] = React.useState(false);
  const [expandedAccordion, setExpandedAccordion] = React.useState(null);
  const user = useSelector(selectProfile);

  const { data, isLoading } = useRQuery({
    key: ['instance-data', instanceId, user.token, user?.actAs],
    url: dataPopupUrls.detail(instanceId, `?include_send_form_action=true`),
    options: {
      enabled: !!(user.token && instanceId),
    },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const container =
    typeof window !== 'undefined' ? () => window.document.body : undefined;

  // const nextIcon = '>';
  // const prevIcon = '<';

  const getCandidate = (data) => {
    return data?.payload?.entrypoint?.payload ?? {};
  };

  const getFullName = (data) => {
    const candidate = getCandidate(data);
    const firstName = candidate?.first_name ?? '';
    const lastName = candidate?.last_name ?? '';
    return `${firstName} ${lastName}`;
  };

  const modelId = getCandidate(data)?.id;
  const model = data?.payload?.entrypoint?.model;
  const isModelCandidate = model === 'Candidate';

  const getURLs = () => {
    if (isModelCandidate) {
      return candidatesUrls;
    }
  };

  const handleAccordionChange = (accordionName) => {
    setExpandedAccordion(
      expandedAccordion === accordionName ? null : accordionName
    );
  };

  const getSchema = React.useCallback(
    (data) => {
      if (isModelCandidate) {
        return candidateMeta({
          data,
          firstNameGridStyle: 6,
          lastNameGridStyle: 6,
          statusGridStyle: 6,
          personalEmailGridStyle: 6,
          createdAtGridStyle: 6,
          personalMobileGridStyle: 6,
          dobGridStyle: 6,
          cityGridStyle: 6,
          countryGridStyle: 6,
          address1GridStyle: 6,
          address2GridStyle: 6,
          visaRequiredGridStyle: 6,
          currentlyEmployedGridStyle: 6,
          currentEmployerGridStyle: 6,
          cvGridStyle: 6,
          yearsOfExperienceGridStyle: 6,
          coverLetterGridStyle: 12,
          linkedinGridStyle: 6,
          utmCampaignGridStyle: 6,
          utmMediumGridStyle: 6,
          utmSourceGridStyle: 6,
          utmContentGridStyle: 6,
          utmTermGridStyle: 6,
        });
      }
    },
    [isModelCandidate]
  );

  const inasColOptions = React.useMemo(() => {
    return {
      dataPanel: true,
    };
  }, []);

  const emailHistoriesColOptions = React.useMemo(() => {
    return { allEmails: true, dataPanel: true };
  }, []);

  const assessmentColOptions = React.useMemo(() => {
    return {
      includeVacancy: true,
    };
  }, []);

  return (
    <Drawer
      container={container}
      variant="temporary"
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 699,
        },
        '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.06)' },
      }}
      open={showManualTaskData}
      onClose={() => setShowManualTaskData(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      anchor="right"
    >
      {isLoading || !data ? (
        <Box sx={{ pl: 3 }}>
          <Toolbar />
          <p>loading...</p>
        </Box>
      ) : (
        <>
          <Toolbar />
          <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography sx={{ fontWeight: '500', fontSize: '22px' }}>
                {model} Data
              </Typography>

              <Box sx={{ ml: 'auto' }}>
                {/* <ButtonGroup variant='text' aria-label='text button group'>
                  <Button>{prevIcon}</Button>
                  <Button>{nextIcon}</Button>
                </ButtonGroup> */}
                <Button
                  variant="outlined"
                  sx={{ ml: 2 }}
                  onClick={() => setShowAddIna(true)}
                >
                  + INA
                </Button>
              </Box>
            </Box>

            {isModelCandidate ? (
              <DetailCard
                urls={getURLs()}
                recordId={modelId}
                getSchema={getSchema}
              />
            ) : null}

            <Box sx={{ mt: 3 }}>
              <Accordion
                label="Timeline"
                expanded={expandedAccordion === 'Timeline'}
                onChange={() => handleAccordionChange('Timeline')}
              >
                <DataPanelTimeline
                  sentItems={data?.already_sent_assets ?? []}
                />
              </Accordion>

              <Accordion
                label="Payload"
                expanded={expandedAccordion === 'Payload'}
                onChange={() => handleAccordionChange('Payload')}
              >
                <JsonView value={data?.full_payload ?? {}} style={lightTheme} />
              </Accordion>

              {getCandidate(data)?.personal_email ? (
                <>
                  <DetailAccordionView
                    externalExpanded={expandedAccordion === 'Notes'}
                    setExternalExpanded={() => handleAccordionChange('Notes')}
                    microservice={microservices.HR.name}
                    autoHeight="41vh"
                    model={microservices.HR.models.candidate}
                    columnKey="assessmentHR"
                    columnOptions={assessmentColOptions}
                    label="Assessment and Notes"
                    urls={assessmentsUrls}
                    addNewForm={{
                      getFields: () => AssessmentForm(),
                      handleSubmit: async (
                        values,
                        setSubmitting,
                        resetForm,
                        setAddNewForm,
                        refetch,
                        setError
                      ) => {
                        try {
                          let file = values.attachment;

                          if (file) {
                            const formData = new FormData();
                            formData.append('anonymous_can_see_it', true);
                            formData.append('file', file);
                            try {
                              const response = await createRecord({
                                values: formData,
                                url: filesUrls.list(),
                                token: user.token,
                                actAs: user?.actAs,
                              });
                              file = response?.data?.fileUrl;
                            } catch (err) {
                              handleCreateFormErrors({
                                err,
                                setError,
                                notify,
                                values,
                              });
                            }
                          }
                          const response = await createRecord({
                            values: {
                              ...values,
                              candidate: getCandidate(data)?.id,
                              attachment: file,
                            },
                            url: assessmentsUrls.list(),
                            token: user.token,
                            actAs: user?.actAs,
                          });
                          resetForm();
                          setAddNewForm(false);
                          refetch();
                          return response?.data?.id;
                        } catch (err) {
                          handleCreateFormErrors({
                            err,
                            setError,
                            notify,
                            values,
                          });
                        } finally {
                          setSubmitting(false);
                        }
                      },
                    }}
                    handleRowMap={assessmentRowsMap}
                    urlParams={`&candidate=${getCandidate(data)?.id}`}
                  />

                  <DetailAccordionView
                    externalExpanded={expandedAccordion === 'Email History'}
                    setExternalExpanded={() =>
                      handleAccordionChange('Email History')
                    }
                    columnKey="emailCommunication"
                    columnOptions={emailHistoriesColOptions}
                    autoHeight="41vh"
                    handleRowsMap={emailRowsMap}
                    label="Email History"
                    urls={notificationsUrls}
                    urlParams={`&all_inbox=true&history_email=${
                      getCandidate(data)?.personal_email
                    }&ordering=-created_at`}
                  />
                </>
              ) : null}

              {getCandidate(data)?.id ? (
                <DetailAccordionView
                  externalExpanded={expandedAccordion === 'INAs'}
                  setExternalExpanded={() => handleAccordionChange('INAs')}
                  columnKey="inasCRM"
                  columnOptions={inasColOptions}
                  autoHeight="41vh"
                  label="INAs"
                  urls={actionRemindersUrls}
                  handleRowMap={inasRowsMap}
                  urlParams={`&entity=Candidate&entity_microservice=HR&reminder_type=INA Reminder Event&entity_id=${
                    getCandidate(data)?.id
                  }`}
                />
              ) : null}
            </Box>
          </Box>
        </>
      )}

      {showAddIna ? (
        <ReminderPopup
          type="INA"
          microservice="HR"
          model="Candidate"
          open={showAddIna}
          setOpen={setShowAddIna}
          selectedRows={[getCandidate(data)?.id]}
          setSelectedRows={() => null}
          link={`${window.location.host}/candidates/${getCandidate(data)?.id}`}
          company={getFullName(data)}
        />
      ) : null}
    </Drawer>
  );
}
