import React from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { webChatUrls } from '@config/routes';
import { ticketRowsMap } from '@config/handleRows/webChat';
import { microservices } from '@config/constants';

const { ticketsUrls } = webChatUrls;

export default function Tickets({ labels, editMode }) {
  return (
    <Box>
      <ListView
        label='Tickets'
        prefix='04h'
        labels={labels}
        editMode={editMode}
        microservice={microservices.WebChat.name}
        model={microservices.WebChat.models.agents}
        urls={ticketsUrls}
        columnKey='ticketsWebChat'
        handleRowsMap={ticketRowsMap}
      />
    </Box>
  );
}
