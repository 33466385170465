import React from 'react';
import { Box, Typography } from '@mui/material';
import KanbanBoardHeader from './KanbanListHeader';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import _ from 'lodash';

export default function PipelineStagesList({ stages, setStages }) {
  const [active, setActive] = React.useState('');

  const updateStage = (stage) => {
    const s = stages.findIndex((r) => r.id === stage.id);
    if (s !== -1) {
      const ss = _.cloneDeep(stages);
      ss[s] = stage;
      setStages(ss);
    }
  };

  if (!stages || !stages.length) {
    return <Typography component='div'>No data found...</Typography>;
  }

  return (
    <>
      {stages.map((stage, idx) => {
        return (
          <Box
            key={stage.id}
            onMouseEnter={() => setActive(stage.id)}
            sx={{
              minWidth: 290,
              maxWidth: 290,
              m: 1,
            }}
          >
            <KanbanBoardHeader
              idx={idx}
              title={stage.name}
              count={stage.items.length}
            />

            <Box sx={{ overflowY: 'auto', maxHeight: '65vh' }}>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: '1',
                  padding: '1.2rem',
                  borderRadius: '4px',
                  background: active === stage.id ? '#fff' : '',
                  opacity: active === stage.id ? '100%' : '50%',
                  boxShadow:
                    '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                  height: '65vh',
                  transitionProperty: 'all',
                  transitionTimingFunction: ' cubic-bezier(0.4, 0, 0.2, 1)',
                  transitionDuration: '50ms',
                }}
              >
                <Stack spacing={2.5}>
                  <TextField
                    label='Order'
                    value={stage.order}
                    size='small'
                    type='number'
                    onChange={(e) =>
                      updateStage({ ...stage, order: e.target.value })
                    }
                    autoComplete='off'
                  />
                  <TextField
                    label='Name'
                    value={stage.name}
                    size='small'
                    onChange={(e) =>
                      updateStage({ ...stage, name: e.target.value })
                    }
                    autoComplete='off'
                  />
                  <TextField
                    label='Rotting Days'
                    value={stage.rotting_days}
                    size='small'
                    onChange={(e) =>
                      updateStage({ ...stage, rotting_days: e.target.value })
                    }
                    autoComplete='off'
                  />
                </Stack>
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
}
