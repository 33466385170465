import React from 'react';
import { Box } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { selectBugsBoard, setBugs } from '@redux/bugsBoardSlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { selectProfile } from '@redux/profileSlice';
import { pmUrls, crmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { updateRecord } from '@config/functions/requests';
import {
  handleDragEnd,
  getAuthHeader,
  getLastSelectedDropdownOption,
} from '@config/functions/helperFunctions';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';
import TextField from '@mui/material/TextField';
import ErrorScreen from '@components/ErrorScreen';

const { bugStagesUrls, bugsUrls, projectsUrls, programsUrls, resourcesUrls } =
  pmUrls;

const { personsUrls } = crmUrls;

export default function BugsBoard({ labels, editMode }) {
  const { bugs } = useSelector(selectBugsBoard);
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [notify, update] = useToast();
  const initialProject = getLastSelectedDropdownOption('lastSelectedProject');
  const initialProgram = getLastSelectedDropdownOption('lastSelectedProgram');
  const initialResource = getLastSelectedDropdownOption('lastSelectedResource');
  const initialStakeholder = getLastSelectedDropdownOption(
    'lastSelectedStakeholder'
  );
  const [project, setProject] = React.useState(initialProject);
  const [program, setProgram] = React.useState(initialProgram);
  const [resource, setResource] = React.useState(initialResource);
  const [stakeholder, setStakeholder] = React.useState(initialStakeholder);
  const [width, setWidth] = React.useState(290);

  const { data, isLoading, isError } = useRQuery({
    key: [
      'bugs-board',
      project,
      resource,
      program,
      stakeholder,
      user.token,
      user?.actAs,
    ],
    url: bugStagesUrls.list(
      `?order=order${project ? `&project=${project.id}` : ''}${
        resource
          ? resource.id === 'all'
            ? '&all_users=true'
            : `&user=${resource.id}`
          : '&all_users=true'
      }${program ? `&program=${program?.id ?? ''}` : ''}${
        stakeholder ? `&stakeholder_person=${stakeholder.id}` : ''
      }&page_size=1000&item_order=-updated_at`
    ),
    options: {
      enabled: !!(user.token && (program || project)),
      refetchOnWindowFocus: true,
    },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleSaveCardDrag = async ({ newList, removedItem, destId }) => {
    const tId = notify('Saving....', { loading: true });
    dispatch(setBugs(newList));
    try {
      await updateRecord({
        url: bugsUrls.detail(removedItem.id),
        values: { status: destId },
        token: user.token,
        actAs: user?.actAs,
      });
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(
        err.response && err.response.data ? err.response.data : err.message
      );
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    if (data) dispatch(setBugs(data));
  }, [data, dispatch]);

  React.useEffect(() => {
    const w = localStorage.getItem('ps-bugs-width');
    if (w) setWidth(+w);
  }, []);

  if (isError) {
    return (
      <ErrorScreen text='Failed to fetch the resource from server' img='' />
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title='Bugs'
        prefix='0zp'
        labels={labels}
        editMode={editMode}
        content2={
          <Box>
            <TextField
              sx={{ width: '80px', mr: 2, ml: 2 }}
              size='small'
              label='Width'
              variant='outlined'
              type='number'
              value={width}
              onChange={(e) => {
                setWidth(e.target.value);
                localStorage.setItem('ps-bugs-width', e.target.value);
              }}
              autoComplete='off'
            />
          </Box>
        }
        filterOptions={[
          {
            label: 'Program',
            name: 'program',
            requestKey: 'bugs-board-programs',
            fetchUrl: programsUrls.list,
            setCustomOptions: (data) => [
              {
                id: null,
                label: 'All',
              },
              ...data,
            ],
            handleChange: (newValue) => {
              setProgram(newValue);
              localStorage.setItem(
                'lastSelectedProgram',
                JSON.stringify(newValue)
              );
            },
            urlParams: '&beta_partners=true',
            initialValue: initialProgram,
          },
          {
            label: 'Project',
            name: 'project',
            requestKey: 'bugs-board-projects',
            fetchUrl: projectsUrls.list,
            handleChange: (newValue) => {
              setProject(newValue);
              localStorage.setItem(
                'lastSelectedProject',
                JSON.stringify(newValue)
              );
            },
            requestKeyOptions: [program?.id],
            urlParams: program ? `&program=${program?.id ?? ''}` : '',
            initialValue: initialProject,
          },
          {
            label: 'Resource',
            name: 'resource',
            requestKey: 'bugs-board-resource',
            fetchUrl: resourcesUrls.list,
            handleChange: (newValue) => {
              setResource(newValue);
              localStorage.setItem(
                'lastSelectedResource',
                JSON.stringify(newValue)
              );
            },
            renderRow: (r) => ({
              id: r.user,
              label: r.email,
            }),
            orderingCol: 'email',
            initialValue: initialResource,
          },
          {
            label: 'Stakeholder',
            name: 'stakeholder',
            requestKey: 'bugs-board-persons',
            fetchUrl: personsUrls.list,
            handleChange: (newValue) => {
              setStakeholder(newValue);
              localStorage.setItem(
                'lastSelectedStakeholder',
                JSON.stringify(newValue)
              );
            },
            renderRow: (r) => ({
              id: r.id,
              label: r.email,
            }),
            orderingCol: 'email',
            initialValue: initialStakeholder,
          },
        ]}
      />

      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            <DragDropContext
              onDragEnd={(res) => handleDragEnd(res, bugs, handleSaveCardDrag)}
            >
              <KanbanBoardList
                detailLink='bugs'
                data={bugs}
                width={+width}
                labels={labels}
                editMode={editMode}
              />
            </DragDropContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}
