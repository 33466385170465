import { crmHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  companies: 'companies',
  channels: 'channels',
  persons: 'persons',
  opportunities: 'opportunities',
  companyHistory: 'company-histories',
  companyContacts: 'company-contacts',
  personHistory: 'person-histories',
  import: 'import',
  importLogs: 'import-logs',
  importErrorLogs: 'import-error-logs',
  export: 'export',
  personSocialMedia: 'person-socialmedias',
  socialMediaTypes: 'social-media-types',
  clients: 'clients',
  opportunityInfluencers: 'opportunity-influencers',
  opportunityProducts: 'opportunity-products',
  pipelines: 'pipelines',
  pipelineStages: 'pipeline-stages',
  companySics: 'company-sics',
  companySocialMedias: 'company-socialmedias',
  dataNeeds: 'data-needs',
  actionPlans: 'action-plans',
  opportunityHistory: 'opportunity-histories',
  clientHistory: 'client-histories',
  opportunityStages: 'opportunity-stages',
  territories: 'territories',
  companyAccountManagers: 'company-account-managers',
  companyTerritories: 'company-territories',
  territoryOwners: 'territory-owners',
  salesPersonTargets: 'sales-person-targets',
  persons2: 'persons-without-speed-updates',
  createBulkCompanyInTerritories: 'create-bulk-company-in-territories',
  fetchBulkCompanyWebsites: 'bulk-fetch-company-websites',
  fetchBulkCompanyIntelligence: 'bulk-fetch-company-intelligence',
  companySpins: 'company-spins',
  activityNotes: 'activity-notes',
  activityKinds: 'activity-kinds',
  activityTypes: 'activity-types',
  // createBulkReminders: 'bulk-create-reminders',
  actions: 'actions',
  // actionReminders: 'action-reminders',
  // actionHistory: 'action-history',
  actionAttachments: 'action-attachments',
  reminderStages: 'action-reminder-statuses',
  sendEmailToPersons: 'send-email-to-persons',
  marketingLists: 'marketing-lists',
  createBulkPersonInMarketingLists: 'create-bulk-person-in-marketing-lists',
  createBulkOpportunities: 'create-bulk-opportunities',
  personMarketingLists: 'person-marketing-lists',
  callLists: 'call-lists',
  callListPipelines: 'call-list-pipelines',
  callListPipelineStages: 'call-list-pipeline-stages',
  callSchedules: 'call-schedules',
  callHistories: 'call-histories',
  callListStages: 'call-list-stages',
  bulkAddPersonToCallList: 'bulk-person-in-call-schedules',
  inaReminder: 'ina-action-reminder-statuses',
  resetRottingDays: 'reset-rotting-days-opportunities',
  customerEnquiries: 'customer-enquiries',
  customerEnquiryStatuses: 'customer-enquiry-statuses',
  customerEnquiryPurposes: 'customer-enquiry-purposes',
  createBulkPersonRelationships: 'create-bulk-person-relationships',
  relationships: 'relationships',
  personRelationships: 'person-relationships',
  personRelationshipHistories: 'person-relationship-histories',
};

const relationshipsUrls = getModelUrls(crmHost, models.relationships);

const personRelationshipHistoriesUrls = getModelUrls(
  crmHost,
  models.personRelationshipHistories
);

const personRelationshipsUrls = getModelUrls(
  crmHost,
  models.personRelationships
);

const createBulkPersonRelationshipsUrls = getModelUrls(
  crmHost,
  models.createBulkPersonRelationships
);

// const inaReminderUrls = getModelUrls(crmHost, models.inaReminder);
const bulkAddPersonToCallListUrls = getModelUrls(
  crmHost,
  models.bulkAddPersonToCallList
);
const personMarketingListsUrls = getModelUrls(
  crmHost,
  models.personMarketingLists
);
const callListStagesUrls = getModelUrls(crmHost, models.callListStages);
const channelsUrls = getModelUrls(crmHost, models.channels);
const callHistoriesUrls = getModelUrls(crmHost, models.callHistories);
const callListsUrls = getModelUrls(crmHost, models.callLists);
const callSchedulesUrls = getModelUrls(crmHost, models.callSchedules);
const callListPipelinesUrls = getModelUrls(crmHost, models.callListPipelines);
const callListPipelineStagesUrls = getModelUrls(
  crmHost,
  models.callListPipelineStages
);

const createBulkPersonInMarketingListsUrls = getModelUrls(
  crmHost,
  models.createBulkPersonInMarketingLists
);
const createBulkOpportunitiesUrls = getModelUrls(
  crmHost,
  models.createBulkOpportunities
);
const marketingListsUrls = getModelUrls(crmHost, models.marketingLists);
const sendEmailToPersonsUrls = getModelUrls(crmHost, models.sendEmailToPersons);
const reminderStagesUrls = getModelUrls(crmHost, models.reminderStages);
const actionsUrls = getModelUrls(crmHost, models.actions);
// const actionRemindersUrls = getModelUrls(crmHost, models.actionReminders);
// const actionHistoryUrls = getModelUrls(crmHost, models.actionHistory);
const actionAttachmentsUrls = getModelUrls(crmHost, models.actions);

// const createBulkRemindersUrls = getModelUrls(
//   crmHost,
//   models.createBulkReminders
// );
const activityKindsUrls = getModelUrls(crmHost, models.activityKinds);
const activityTypesUrls = getModelUrls(crmHost, models.activityTypes);
const activityNotesUrls = getModelUrls(crmHost, models.activityNotes);
const companySpinsUrls = getModelUrls(crmHost, models.companySpins);
const salesPersonTargetsUrls = getModelUrls(crmHost, models.salesPersonTargets);
const companyAccountManagersUrls = getModelUrls(
  crmHost,
  models.companyAccountManagers
);
const createBulkCompanyInTerritoriesUrls = getModelUrls(
  crmHost,
  models.createBulkCompanyInTerritories
);
const fetchBulkCompanyWebsitesUrls = getModelUrls(
  crmHost,
  models.fetchBulkCompanyWebsites
);
const fetchBulkCompanyIntelligenceUrls = getModelUrls(
  crmHost,
  models.fetchBulkCompanyIntelligence
);
const territoryOwnersUrls = getModelUrls(crmHost, models.territoryOwners);
const persons2Urls = getModelUrls(crmHost, models.persons2);
const territoriesUrls = getModelUrls(crmHost, models.territories);
const companyTerritoriesUrls = getModelUrls(crmHost, models.companyTerritories);
const companiesUrls = getModelUrls(crmHost, models.companies);
const personsUrls = getModelUrls(crmHost, models.persons);
const opportunitiesUrls = getModelUrls(crmHost, models.opportunities);
const companyHistoryUrls = getModelUrls(crmHost, models.companyHistory);
const personHistoryUrls = getModelUrls(crmHost, models.personHistory);
const companyContactUrls = getModelUrls(crmHost, models.companyContacts);
const importUrls = getModelUrls(crmHost, models.import);
const exportUrls = getModelUrls(crmHost, models.export);
const importLogsUrls = getModelUrls(crmHost, models.importLogs);
const importErrorLogsUrls = getModelUrls(crmHost, models.importErrorLogs);
const personSocialMediaUrls = getModelUrls(crmHost, models.personSocialMedia);
const socialMediaTypesUrls = getModelUrls(crmHost, models.socialMediaTypes);
const clientsUrls = getModelUrls(crmHost, models.clients);
const opportunityProductsUrls = getModelUrls(
  crmHost,
  models.opportunityProducts
);
const opportunityInfluencersUrls = getModelUrls(
  crmHost,
  models.opportunityInfluencers
);
const pipelinesUrls = getModelUrls(crmHost, models.pipelines);
const pipelineStagesUrls = getModelUrls(crmHost, models.pipelineStages);
const companySicsUrls = getModelUrls(crmHost, models.companySics);
const companySocialMediasUrls = getModelUrls(
  crmHost,
  models.companySocialMedias
);
const dataNeedsUrls = getModelUrls(crmHost, models.dataNeeds);
const actionPlansUrls = getModelUrls(crmHost, models.actionPlans);
const opportunityHistoryUrls = getModelUrls(crmHost, models.opportunityHistory);
const clientHistoryUrls = getModelUrls(crmHost, models.clientHistory);
const opportunityStagesUrls = getModelUrls(crmHost, models.opportunityStages);
const resetRottingDaysUrls = getModelUrls(crmHost, models.resetRottingDays);
const customerEnquiriesUrls = getModelUrls(crmHost, models.customerEnquiries);
const customerEnquiryStatusesUrls = getModelUrls(
  crmHost,
  models.customerEnquiryStatuses
);
const customerEnquiryPurposesUrls = getModelUrls(
  crmHost,
  models.customerEnquiryPurposes
);

const urls = {
  relationshipsUrls,
  channelsUrls,
  bulkAddPersonToCallListUrls,
  callListStagesUrls,
  callHistoriesUrls,
  personMarketingListsUrls,
  createBulkOpportunitiesUrls,
  createBulkPersonInMarketingListsUrls,
  marketingListsUrls,
  sendEmailToPersonsUrls,
  territoriesUrls,
  personsUrls,
  companiesUrls,
  opportunitiesUrls,
  companyHistoryUrls,
  personHistoryUrls,
  companyContactUrls,
  importUrls,
  exportUrls,
  importLogsUrls,
  importErrorLogsUrls,
  personSocialMediaUrls,
  socialMediaTypesUrls,
  clientsUrls,
  opportunityInfluencersUrls,
  opportunityProductsUrls,
  pipelinesUrls,
  pipelineStagesUrls,
  companySicsUrls,
  companySocialMediasUrls,
  dataNeedsUrls,
  actionPlansUrls,
  clientHistoryUrls,
  opportunityHistoryUrls,
  opportunityStagesUrls,
  companyAccountManagersUrls,
  companyTerritoriesUrls,
  territoryOwnersUrls,
  persons2Urls,
  salesPersonTargetsUrls,
  createBulkCompanyInTerritoriesUrls,
  fetchBulkCompanyWebsitesUrls,
  fetchBulkCompanyIntelligenceUrls,
  companySpinsUrls,
  activityNotesUrls,
  activityKindsUrls,
  activityTypesUrls,
  // createBulkRemindersUrls,
  actionsUrls,
  // actionRemindersUrls,
  // actionHistoryUrls,
  actionAttachmentsUrls,
  reminderStagesUrls,
  callListsUrls,
  callSchedulesUrls,
  callListPipelinesUrls,
  callListPipelineStagesUrls,
  // inaReminderUrls,
  resetRottingDaysUrls,
  customerEnquiriesUrls,
  customerEnquiryStatusesUrls,
  customerEnquiryPurposesUrls,
  createBulkPersonRelationshipsUrls,
  personRelationshipsUrls,
  personRelationshipHistoriesUrls,
};

export default urls;
