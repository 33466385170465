import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { nodeDriveUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { fileFolderRowsMap } from '@config/handleRows/drive';
import { NewFolderForm } from '@config/forms/drive';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import UploadBtn from '@components/Drive/UploadBtn';
import { useParams } from 'react-router-dom';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import DriveBreadcrumbs from '@components/Drive/Breadcrumbs';
import { useQueryClient } from 'react-query';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DropTooltip from '@components/Drive/DropTooltip';

const {
  foldersUrls,
  filesUrls,
  fileDownloadUrls,
  folderDownloadUrls,
  filesAndFoldersUrls,
} = nodeDriveUrls;

export default function MyDrive({ labels, editMode }) {
  const [notify, update] = useToast();
  const { id: folderId } = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [isDragging, setIsDragging] = React.useState(false);

  const { data: currentFolder, isError } = useRQuery({
    key: ['project-task-statuses', folderId, user.token, user?.actAs],
    url: foldersUrls.detail(folderId),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user.token && folderId) },
  });

  const handleDrop = async (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setIsDragging(false);

    console.log(
      droppedFiles,
      droppedFiles.filter((file) => file.type !== '')
    );

    if (!droppedFiles.filter((file) => file.type !== '').length) {
      return;
    }

    const tId = notify('Uploading....', {
      loading: true,
    });

    try {
      const uploadPromises = droppedFiles.map(async (file) => {
        const payload = new FormData();
        payload.append('everyone_can_see_it', false);
        payload.append('anonymous_can_see_it', false);
        payload.append('everyone_in_object_company_can_see_it', true);
        if (folderId) {
          payload.append('folderId', folderId);
        }
        payload.append('file', file);

        const response = await createRecord({
          values: payload,
          url: filesUrls.list(),
          token: user.token,
          company: user?.details?.company,
        });

        if (response.status === 'error') {
          throw new Error('Failed to upload file');
        }

        return response;
      });

      await Promise.all(uploadPromises);
      queryClient.invalidateQueries(['My-Drive-listData']);
      update(tId, 'Upload complete');
    } catch (error) {
      update(tId, 'Upload failed', { type: 'ERROR' });
      console.error('Error uploading files:', error);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleFileDownload = async (row) => {
    const tId = notify('Downloading....', {
      loading: true,
    });
    try {
      const response = await axios.get(fileDownloadUrls.detail(row.id), {
        ...getAuthHeader(user.token, user?.actAs),
        responseType: 'blob',
      });
      const downloadUrl = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank';
      link.setAttribute('download', row.name);
      link.click();
      update(tId, 'Download complete');
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Download failed', { type: 'ERROR' });
    }
  };

  const handleFolderDownload = async (row) => {
    const tId = notify('Downloading....', {
      loading: true,
    });
    try {
      const response = await axios.get(folderDownloadUrls.detail(row.id), {
        ...getAuthHeader(user.token, user?.actAs),
        responseType: 'blob',
      });

      const downloadUrl = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank';
      link.setAttribute('download', `${row.name}-${Date.now()}.zip`);
      link.click();
      update(tId, 'Download complete');
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Download failed', { type: 'ERROR' });
    }
  };

  return (
    <Box>
      {folderId && currentFolder && !isError ? (
        <DriveBreadcrumbs folderId={folderId} currentFolder={currentFolder} />
      ) : null}

      <Box
        sx={{ position: 'relative' }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {isDragging && <DropTooltip />}
        <ListView
          nodeAPI
          noFullDataSetSelect
          label='My Drive'
          createLabel='New Folder'
          prefix='0ts'
          labels={labels}
          editMode={editMode}
          urls={filesAndFoldersUrls}
          columnKey='fileFolderDrive'
          dynamicActions={() => [
            {
              icon: <FileDownloadOutlinedIcon sx={{ mr: 1 }} />,
              label: 'Download',
              color: '#808080',
              handleClick: async ({ row }) => {
                console.log(row);
                if (row.kind === 'File') {
                  handleFileDownload(row);
                } else {
                  handleFolderDownload(row);
                }
              },
            },
          ]}
          getCustomUrl={({ row }) => {
            if (row.kind === 'File') {
              return filesUrls.detail(row.id);
            }

            return foldersUrls.detail(row.id);
          }}
          headerContent={
            <Box sx={{ display: 'inline-block', ml: 2 }}>
              <UploadBtn folderId={folderId} />
            </Box>
          }
          addNewForm={{
            getFields: () => NewFolderForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, parentId: folderId || undefined },
                  url: foldersUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowsMap={fileFolderRowsMap}
          urlParams={folderId ? `&folderId=${folderId}` : ''}
        />
      </Box>
    </Box>
  );
}
