import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { callListsRowsMap } from '@config/handleRows/crm';
import { CallListsForm } from '@config/forms/crm';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { callListsUrls } = crmUrls;

export default function CallLists({ editMode, labels }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  return (
    <Box>
      <ListView
        prefix="0v0"
        labels={labels}
        editMode={editMode}
        label="Call Lists"
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.callList}
        urls={callListsUrls}
        columnKey="callListCRM"
        addNewForm={{
          getFields: ({ setFieldValue }) =>
            CallListsForm({
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError,
            setFieldError
          ) => {
            try {
              const { call_list_pipeline, ...rest } = values;

              if (!call_list_pipeline?.id) {
                setFieldError('call_list_pipeline', 'This field is required');
                return;
              }

              const { data } = await createRecord({
                values: {
                  ...rest,
                  call_list_pipeline: call_list_pipeline?.id,
                },
                url: callListsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={callListsRowsMap}
      />
    </Box>
  );
}
