import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import { Box, Typography, Stack, TextField, Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useQueryClient } from 'react-query';
import { communicationUrls, accountUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { createRecord, updateRecord } from '@config/functions/requests';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

// import useToast from '@hooks/useToast';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const { imapAccountsUrls } = communicationUrls;
const { loginUrls, resetPassphraseUrls } = accountUrls;

export default function DisableEncryptionModal({
  open,
  handleClose,
  rowId,
  row,
  labels,
  editMode,
  prefix,
  prefix2,
  prefix3,
}) {
  const queryClient = useQueryClient();
  const [value, setValue] = React.useState(0);
  const [step, setStep] = React.useState(1);
  const [disableContinue, setDisableContinue] = React.useState(true);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [enable, setEnable] = React.useState(row.row.encryption_enabled);
  const [userChanged, setUserChanged] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passphrase, setPassphrase] = React.useState('');
  const [passphrase2, setPassphrase2] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassphrase, setShowPassphrase] = React.useState(false);
  const [error, setError] = React.useState('');
  const user = useSelector(selectProfile);
  // const [notify] = useToast();

  React.useEffect(() => {
    if (!open) {
      setValue(0);
      setStep(1);
    }
  }, [open]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    if (newValue === 0 && error === 'Passphrase do not match') setError('');
  };

  const onClose = () => {
    handleClose();
    setError('');
    setPassphrase('');
    setPassphrase2('');
    setPassword('');
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      setError('');

      if (!password.length) {
        setError('Wrong password');
        return;
      }

      try {
        try {
          await createRecord({
            values: {
              username: user.details.username,
              password,
            },
            url: loginUrls.list(`?client=${user?.details?.client}`),
          });
        } catch (err) {
          console.log(err.response || err.message);
          setError('Wrong password');
          return;
        }

        if (
          passphrase.length &&
          passphrase2.length &&
          passphrase === passphrase2 &&
          value === 1
        ) {
          try {
            await createRecord({
              url: resetPassphraseUrls.list(),
              values: { token: user.encryptionToken, new_phrase: passphrase },
              token: user.token,
              actAs: user?.actAs,
              encryptionToken: user.encryptionToken,
            });

            queryClient.invalidateQueries(`drawer-imap-accts-${user.token}`);
            queryClient.invalidateQueries('Imap-Accounts-listData');
          } catch (err) {
            console.log(err.response || err.message);
            if (err.response && err.response.phrase) {
              setError(err.response.phrase);
            } else {
              setError('Failed to reset your passphrase');
            }
          }
        }

        if (userChanged && value === 0) {
          // Enable/Disable encryption
          await updateRecord({
            url: imapAccountsUrls.detail(rowId),
            values: { encryption_enabled: enable },
            token: user.token,
            actAs: user?.actAs,
            encryptionToken: user.encryptionToken,
          });

          queryClient.invalidateQueries('user-encryption-enabled');
          queryClient.invalidateQueries(`drawer-imap-accts-${user.token}`);
          queryClient.invalidateQueries('Imap-Accounts-listData');

          // notify(`The encryption is ${enable ? 'enabled' : 'disabled'}`, {
          //   type: 'SUCCESS',
          // });
        }

        onClose();
      } catch (err) {
        console.log(err.response || err.message);
        return;
      }
    } catch (err) {
      console.log('err-65', err.response || err.message);
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    setDisableContinue(true);

    if (passphrase.length && passphrase2.length && passphrase !== passphrase2) {
      setError('Passphrase do not match');
      setDisableContinue(true);
    }

    if (passphrase.length && passphrase2.length && passphrase === passphrase2) {
      setError('');
      setDisableContinue(false);
    }
  }, [passphrase, passphrase2]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        {!!error ? (
          <Alert sx={{ mb: 2.5 }} severity="error">
            {error}
          </Alert>
        ) : null}

        {step === 2 ? (
          <Stack spacing={2.5} sx={{ width: '100%' }}>
            {prefix ? (
              <RenderUiPrefix
                editMode={editMode}
                labels={labels}
                code={prefix}
                defaultValue="Enter your password to continue"
              >
                {(text) => {
                  return (
                    <Typography
                      sx={{ fontSize: '16px', fontWeight: '500' }}
                      variant="h4"
                    >
                      {text}
                    </Typography>
                  );
                }}
              </RenderUiPrefix>
            ) : (
              <Typography
                sx={{ fontSize: '16px', fontWeight: '500' }}
                variant="h4"
              >
                Enter your password to continue
              </Typography>
            )}

            <TextField
              fullWidth
              size="small"
              type={showPassword ? 'text' : 'password'}
              value={password}
              variant="outlined"
              label="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={showPassword}
                  onChange={() => setShowPassword((v) => !v)}
                />
              }
              label="Show password"
            />

            <Grid container sx={{ width: '100%' }}>
              <Grid item sx={{ pr: 2 }} xs={6}>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  onClick={() => {
                    setStep(1);
                    setError('');
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Stack>
        ) : (
          <>
            {prefix2 ? (
              <RenderUiPrefix
                editMode={editMode}
                labels={labels}
                code={prefix2}
                defaultValue="You can disable encryption (not recommended) or reset your
                passphrase"
              >
                {(text) => {
                  return (
                    <Typography
                      sx={{ fontSize: '18px', pb: 1.5, fontWeight: '600' }}
                      variant="h4"
                    >
                      {text}
                    </Typography>
                  );
                }}
              </RenderUiPrefix>
            ) : (
              <Typography
                sx={{ fontSize: '18px', pb: 1.5, fontWeight: '600' }}
                variant="h4"
              >
                You can disable encryption (not recommended) or reset your
                passphrase
              </Typography>
            )}

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Encryption" {...a11yProps(0)} />
                <Tab label="Reset Passphrase" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid container sx={{ pb: 2.5 }} spacing={2}>
                <Grid item xs={9}>
                  {prefix3 ? (
                    <RenderUiPrefix
                      editMode={editMode}
                      labels={labels}
                      code={prefix3}
                      defaultValue="Enable Encryption (Recommended)"
                    >
                      {(text) => {
                        return (
                          <Typography
                            sx={{ fontSize: '16px', pt: 1.2 }}
                            variant="h4"
                          >
                            {text}
                          </Typography>
                        );
                      }}
                    </RenderUiPrefix>
                  ) : (
                    <Typography sx={{ fontSize: '16px', pt: 1.2 }} variant="h4">
                      Enable Encryption (Recommended)
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <Switch
                      {...label}
                      checked={enable}
                      onChange={() => {
                        setEnable((v) => !v);
                        setUserChanged(true);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container sx={{ width: '100%' }}>
                <Grid item sx={{ pr: 2 }} xs={6}>
                  <Button fullWidth variant="outlined" onClick={onClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => setStep(2)}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container spacing={2}>
                <Stack spacing={2.5} sx={{ width: '100%', pt: 2, px: 2 }}>
                  <TextField
                    fullWidth
                    type={showPassphrase ? 'text' : 'password'}
                    size="small"
                    label="Enter Passphrase"
                    variant="outlined"
                    value={passphrase}
                    onChange={(e) => setPassphrase(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    type={showPassphrase ? 'text' : 'password'}
                    size="small"
                    label="Confirm Passphrase"
                    variant="outlined"
                    value={passphrase2}
                    onChange={(e) => setPassphrase2(e.target.value)}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showPassphrase}
                        onChange={() => setShowPassphrase((v) => !v)}
                      />
                    }
                    label="Show passphrase"
                  />

                  <Grid container sx={{ width: '100%' }}>
                    <Grid item sx={{ pr: 2 }} xs={6}>
                      <Button fullWidth variant="outlined" onClick={onClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        disabled={disableContinue}
                        onClick={() => setStep(2)}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </TabPanel>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
