import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { crmUrls } from '@config/routes';
import { importErrorLogsRowsMap } from '@config/handleRows/crm';
import { useParams } from 'react-router-dom';

const { importErrorLogsUrls } = crmUrls;

export default function ImportLogs() {
  const { id: routerId } = useParams();

  return (
    <Box>
      <ListView
        label="Import Error Logs"
        urls={importErrorLogsUrls}
        getListViewURL={(
          urls,
          page,
          searchTerm,
          pageSize,
          urlParams,
          sortColumn
        ) =>
          urls.detail(
            routerId,
            `?page=${page}&search=${searchTerm}&page_size=${pageSize}${urlParams}&ordering=${
              sortColumn.length
                ? sortColumn[0].sort === 'asc'
                  ? sortColumn[0].field
                  : `-${sortColumn[0].field}`
                : ''
            }`
          )
        }
        columnKey="importErrorLogCRM"
        handleRowsMap={importErrorLogsRowsMap}
        importExport={false}
        showSearch={false}
        hideActions={true}
      />
    </Box>
  );
}
