import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Workflows from '@pages/BPA-V2/Workflows';
import ManualWorkflows from '@pages/BPA-V2/Workflows/manual';
import WorkflowDetail from '@pages/BPA-V2/Workflows/detail';
import StageDetail from '@pages/BPA-V2/Stages/detail';
import AssetDetail from '@pages/BPA-V2/Assets/detail';
import BPADiagram from '@pages/BPA-V2/diagram';

export default function () {
  return (
    <>
      <Route path="/workflows" element={<WithLayout comp={Workflows} />} />
      <Route
        path="/manual-workflows"
        element={<WithLayout comp={ManualWorkflows} />}
      />
      <Route
        path="/workflows/:id"
        element={<WithLayout comp={WorkflowDetail} />}
      />
      <Route path="/stages/:id" element={<WithLayout comp={StageDetail} />} />
      <Route path="/assets/:id" element={<WithLayout comp={AssetDetail} />} />
      <Route path="/bpa/:id" element={<WithLayout comp={BPADiagram} />} />
    </>
  );
}
