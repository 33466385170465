import React from 'react';
import * as Yup from 'yup';
import { listsUrls } from '@config/routes';
import { postCodesMeta } from '@config/meta/list/index';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import CountyDropdown from './CountryDropdown';
import CityDropdown from './CityDropdown';
import { useQueryClient } from 'react-query';

const { postCodesUrls, countriesUrls, citiesUrls } = listsUrls;

export default function PostCodeDropdown({ children, refetch, city:initialCity }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Post code"
        initialValues={{
          ...getAddFormInitialValues(postCodesMeta({}), 'listView'),
          city:initialCity
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(postCodesMeta({}), 'listView'),
        })}
        getPostUrl={() => postCodesUrls.list()}
        getValues={({values, country, city, latitude, longitude}) => ({
          ...values,
          country:country?.id,
          city:city?.id,
          latitude: latitude || null,
          longitude: longitude || null,
        })}
        getFields={({setFieldValue, values}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={6}>
              <TextField label="Code" name="code" required />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Region" name="region" />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Uk Region" name="uk_region" />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Latitude" name="latitude" />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Longitude" name="longitude"  />
            </Grid>
            <Grid item xs={6}>
            <CountyDropdown
              refetch={({ id, name }) => {
                setFieldValue('country', { id, label: name });
                queryClient.invalidateQueries(['country-postcode']);
              }}
            >
              <AutocompleteFieldV2
                name='country'
                label='Country'
                requestKey='country-postcode'
                fetchUrl={countriesUrls.list}
                required
              />
            </CountyDropdown>
            </Grid>
            <Grid item xs={6}>
            <CityDropdown
              country = {values.country}
              refetch={({ id, name }) => {
                setFieldValue('city', { id, label: name });
                queryClient.invalidateQueries(['postcode-city']);
              }}
            >
              <AutocompleteFieldV2
                name='city'
                label='City'
                requestKey='postcode-city'
                fetchUrl={citiesUrls.list}
                required
              />
            </CityDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
