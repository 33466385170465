import React from 'react';
import * as Yup from 'yup';
import { inventoryUrls } from '@config/routes';
import { productMeta } from '@config/meta/inventory/index';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOC from '@components/DropdownAddHOC';
import ProductTypeDropdown from '@dropdown-forms/inventory/ProductTypeDropdown';
import ManufacturerDropdown from '@dropdown-forms/inventory/ManufacturerDropdown';
import { useQueryClient } from 'react-query';

const { productsUrls, manufacturersUrls, productTypesUrls } = inventoryUrls;

export default function ProductDropdown({ children, refetch }) {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();
 

  return (
    <>
      <DropdownAddHOC setOpen={setOpen}>{children}</DropdownAddHOC>

      <FormDialog
        title="Product"
        initialValues={{
          ...getAddFormInitialValues(productMeta({}), 'listView'),
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(productMeta({}), 'listView'),
        })}
        getPostUrl={() => productsUrls.list()}
        getValues={({values, product_type, manufacturer}) => ({
          ...values,
          manufacturer: manufacturer?.id,
          product_type: product_type?.id
        })}
        getFields={({setFieldValue}) => (
          <Grid container spacing={3.5}>
            <Grid item xs={12}>
              <TextField key="name" label="Name" name="name" required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                key="description"
                label="Description"
                name="description"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField key="slug" label="slug" name="slug" required />
            </Grid>
            <Grid item xs={6}>
              <TextField key="max_price" label="Max Price" name="max_price" />
            </Grid>
            <Grid item xs={6}>
              <TextField key="min_price" label="Min Price" name="min_price" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="manufacturer_code"
                label="Manufacturer Code"
                name="manufacturer_code"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField key="model" label="Model" name="model" />
            </Grid>
            <Grid item xs={6}>
            <ProductTypeDropdown
              refetch={({ id, name }) => {
                setFieldValue('product_type', { id, label: name });
                queryClient.invalidateQueries(['product-type']);
              }}
            >
              <AutocompleteFieldV2
                name='product_type'
                label='Product Type'
                requestKey='product-type'
                fetchUrl={productTypesUrls.list}
                urlParams={`&ordering=created_at`}
                required
              />
            </ProductTypeDropdown>
            </Grid>
            <Grid item xs={6}>
            <ManufacturerDropdown
              refetch={({ id, details }) => {
                setFieldValue('manufacturer', { id, label: `${details?.manufacturer_company?.name ?? ''}}` });
                queryClient.invalidateQueries(['product-manufacturer']);
              }}
            >
              <AutocompleteFieldV2
                name='manufacturer'
                label='Manufacturer'
                requestKey='product-manufacturer'
                fetchUrl={manufacturersUrls.list}
                urlParams={`&ordering=created_at`}
                required
                renderRow={(row) => ({
                  id: row?.id,
                  label: row?.details?.manufacturer_company?.name,
                })}
              />
            </ManufacturerDropdown>
            </Grid>
          </Grid>
        )}
        refetch={refetch}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
