import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function DeleteWorkflowInstancePopup({
  open,
  setOpen,
  disableBPADeleteWorkflowInstancesBtn,
  setDisableBPADeleteWorkflowInstancesBtn,
  onDeleteWorkflowInstancesClick,
  labels,
  editMode,
  prefix,
  prefix2,
}) {
  const onCancelClick = () => {
    setDisableBPADeleteWorkflowInstancesBtn(false);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      fullWidth
      maxWidth='sm'
      aria-labelledby='delete-workflow-instance'
    >
      {prefix ? (
        <RenderUiPrefix
          editMode={editMode}
          labels={labels}
          code={prefix}
          defaultValue='Delete Workflow Instances?'
        >
          {(text) => {
            return (
              <DialogTitle sx={{ pointerEvents: 'none' }}>{text}</DialogTitle>
            );
          }}
        </RenderUiPrefix>
      ) : (
        <DialogTitle id='delete-dialog-title'>
          Delete Workflow Instances?
        </DialogTitle>
      )}
      <DialogContent>
        {prefix2 ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix2}
            defaultValue='Delete Workflow Instances?'
          >
            {(text) => {
              return (
                <DialogContentText
                  id='delete-dialog-description'
                  sx={{
                    mb: '10px',
                  }}
                >
                  {text}
                </DialogContentText>
              );
            }}
          </RenderUiPrefix>
        ) : (
          <DialogContentText
            id='delete-dialog-description'
            sx={{
              mb: '10px',
            }}
          >
            Are you sure you want to delete the workflow instances? after you
            have delete the workflow instances you can't <strong>undo</strong>{' '}
            this action.
          </DialogContentText>
        )}

        <DialogActions>
          <Button onClick={onCancelClick}>Cancel</Button>
          <Button
            color='error'
            onClick={onDeleteWorkflowInstancesClick}
            disabled={disableBPADeleteWorkflowInstancesBtn}
          >
            Delete Workflow Instances
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
