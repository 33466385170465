import * as React from 'react';
import MuiModal from '@mui/material/Modal';

export default function Modal({
  open = false,
  onClose = () => null,
  ariaLabelledBy = 'Modal Title',
  ariaDescribedBy = 'Modal Description',
  children,
}) {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
    >
      {children}
    </MuiModal>
  );
}
