import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { listsUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { languageRowsMap } from '@config/handleRows/list';
import { LanguageForm } from '@config/forms/list';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { languagesUrls } = listsUrls;

export default function Country({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label='Language'
        prefix='05o'
        labels={labels}
        editMode={editMode}
        microservice={microservices.LIST.name}
        model={microservices.LIST.models.languages}
        urls={languagesUrls}
        columnKey='languageLists'
        addNewForm={{
          getFields: () => LanguageForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values,
                url: languagesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              // notify('Language Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={languageRowsMap}
        showBulkDeleteButton={false}
        hideActions
      />
    </Box>
  );
}
