import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ecommerceUrls, listsUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordianArrayView from '@components/DetailAccordionArrayView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import ecommerceHandleRows from '@config/handleRows/ecommerce';
import { ItemDeliveryForm, PaymentForm } from '@config/forms/ecommerce';
import {
  addressMeta,
} from '@config/meta/ecommerce';
import DetailPageHeader from '@components/DetailPageHeader';
import useAutocomplete from '@hooks/useAutocomplete';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  deliveryMethodsUrls,
  cartsUrls,
  deliveryStatusesUrls,
  paymentProvidersUrls,
  paymentMethodsUrls,
  cartItemsUrls,
  addressesUrls,
  paymentsUrls,
  paymentStatusesUrls,
  itemDeliveriesUrls,
} = ecommerceUrls;
const { citiesUrls, postCodesUrls } = listsUrls;
const { itemDeliveryRowMap, paymentRowMap } = ecommerceHandleRows;

export default function AddressDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [addressTerm, setAddressTerm] = useState('');
  const [deliveryStatusTerm, setDeliveryStatusTerm] = useState('');
  const [paymentStatusTerm, setPaymentStatusTerm] = useState('');
  const [paymentProviderTerm, setPaymentProviderTerm] = useState('');
  const [paymentMethodTerm, setPaymentMethodTerm] = useState('');
  const [cartTerm, setCartTerm] = useState('');
  const [cartItemTerm, setCartItemTerm] = useState('');
  const [deliveryMethodTerm, setDeliveryMethodTerm] = useState('');
  const [cityTerm, setCityTerm] = useState('');
  const [postCodeTerm, setPostCodeTerm] = useState('');
  const [notify] = useToast();

  const { data: addresses, isFetching: fetchingAddresses } = useAutocomplete({
    key: 'addresses',
    getUrl: addressesUrls.list,
    inputValue: addressTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.address1,
      };
    },
  });

  const { data: deliveryStatuses, isFetching: fetchingDeliveryStatuses } =
    useAutocomplete({
      key: 'deliveryStatuses',
      getUrl: deliveryStatusesUrls.list,
      inputValue: deliveryStatusTerm,
    });

  const { data: paymentStatuses, isFetching: fetchingPaymentStatuses } =
    useAutocomplete({
      key: 'paymentStatuses',
      getUrl: paymentStatusesUrls.list,
      inputValue: paymentStatusTerm,
    });

  const { data: carts, isFetching: fetchingCarts } = useAutocomplete({
    key: 'carts',
    getUrl: cartsUrls.list,
    inputValue: cartTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.customer && r.details && r.customer_details
            ? r.customer_details.first_name
            : r.customer,
      };
    },
  });

  const { data: paymentProviders, isFetching: fetchingPaymentProviders } =
    useAutocomplete({
      key: 'paymentProviders',
      getUrl: paymentProvidersUrls.list,
      inputValue: paymentProviderTerm,
    });

  const { data: paymentMethods, isFetching: fetchingPaymentMethods } =
    useAutocomplete({
      key: 'paymentMethods',
      getUrl: paymentMethodsUrls.list,
      inputValue: paymentMethodTerm,
    });

  const { data: cartItems, isFetching: fetchingCartItems } = useAutocomplete({
    key: 'cartItems',
    getUrl: cartItemsUrls.list,
    inputValue: cartItemTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label:
          r.product_variant && r.details && r.details.product_variant
            ? r.details.product_variant.name
            : null,
      };
    },
  });

  const { data: deliveryMethods, isFetching: fetchingDeliveryMethods } =
    useAutocomplete({
      key: 'deliveryMethods',
      getUrl: deliveryMethodsUrls.list,
      inputValue: deliveryMethodTerm,
    });

  const { data: cities, isFetching: citiesIsFetching } = useAutocomplete({
    key: 'cities',
    getUrl: citiesUrls.list,
    inputValue: cityTerm,
  });

  const { data: postCodes, isFetching: postCodesIsFetching } = useAutocomplete({
    key: 'postCodes',
    getUrl: postCodesUrls.list,
    inputValue: postCodeTerm,
    renderRow: (r) => {
      return {
        id: r.id,
        label: r.code,
      };
    },
  });

  const paymentsColOptions = React.useMemo(() => {
    return {
      carts,
      fetchingCarts,
      setCartTerm,
      addresses,
      fetchingAddresses,
      setAddressTerm,
      paymentProviders,
      fetchingPaymentProviders,
      setPaymentProviderTerm,
      paymentMethods,
      fetchingPaymentMethods,
      setPaymentMethodTerm,
      paymentStatuses,
      fetchingPaymentStatuses,
      setPaymentStatusTerm,
    };
  }, [
    carts,
    fetchingCarts,
    setCartTerm,
    addresses,
    fetchingAddresses,
    setAddressTerm,
    paymentProviders,
    fetchingPaymentProviders,
    setPaymentProviderTerm,
    paymentMethods,
    fetchingPaymentMethods,
    setPaymentMethodTerm,
    paymentStatuses,
    fetchingPaymentStatuses,
    setPaymentStatusTerm,
  ]);

  const itemDeliveriesColOptions = React.useMemo(() => {
    return {
      cartItems,
      fetchingCartItems,
      setCartItemTerm,
      deliveryMethods,
      fetchingDeliveryMethods,
      setDeliveryMethodTerm,
      deliveryStatuses,
      fetchingDeliveryStatuses,
      setDeliveryStatusTerm,
      addresses,
      fetchingAddresses,
      setAddressTerm,
    };
  }, [
    cartItems,
    fetchingCartItems,
    setCartItemTerm,
    deliveryMethods,
    fetchingDeliveryMethods,
    setDeliveryMethodTerm,
    deliveryStatuses,
    fetchingDeliveryStatuses,
    setDeliveryStatusTerm,
    addresses,
    fetchingAddresses,
    setAddressTerm,
  ]);

  const accordData = {
    itemDeliveries: {
      columnKey: "itemDeliveryEcommerce",
      columnOptions: itemDeliveriesColOptions,
      microservice: 'E-Commerce',
      model: 'itemDelivery',
      label: 'Item Deliveries',
      prefix: '038',
      urls: itemDeliveriesUrls,
      addNewForm: {
        getFields: () =>
          ItemDeliveryForm({
            cartItems,
            fetchingCartItems,
            setCartItemTerm,
            deliveryMethods,
            fetchingDeliveryMethods,
            setDeliveryMethodTerm,
            deliveryStatuses,
            fetchingDeliveryStatuses,
            setDeliveryStatusTerm,
            addresses,
            fetchingAddresses,
            setAddressTerm,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                delivery_address: recordId,
              },
              url: itemDeliveriesUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Item Delivery Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: itemDeliveryRowMap,
      urlParams: `&delivery_address=${recordId}`,
    },
    payments: {
      columnKey: "paymentEcommerce",
      columnOptions: paymentsColOptions,
      microservice: 'E-Commerce',
      model: 'Payment',
      label: 'Payments',
      prefix: '0sg',
      urls: paymentsUrls,
      addNewForm: {
        getFields: () =>
          PaymentForm({
            carts,
            fetchingCarts,
            setCartTerm,
            addresses,
            fetchingAddresses,
            setAddressTerm,
            paymentProviders,
            fetchingPaymentProviders,
            setPaymentProviderTerm,
            paymentMethods,
            fetchingPaymentMethods,
            setPaymentMethodTerm,
            paymentStatuses,
            fetchingPaymentStatuses,
            setPaymentStatusTerm,
          }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            await createRecord({
              values: {
                ...values,
                billing_address: recordId,
              },
              url: paymentsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });
            resetForm();
            setAddNewForm(false);
            // notify(`Payment Created`, {
            //   type: 'SUCCESS',
            // });
            refetch();
          } catch (err) {
            console.log(err);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      },
      handleRowMap: paymentRowMap,
      urlParams: `&billing_address=${recordId}`,
    },
  };

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      addressMeta({
        data,
        cities,
        citiesIsFetching,
        setCityTerm,
        postCodes,
        postCodesIsFetching,
        setPostCodeTerm,
      }),
    [
      cities,
      citiesIsFetching,
      setCityTerm,
      postCodes,
      postCodesIsFetching,
      setPostCodeTerm,
    ]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/addresses`, text: 'Addresses' },
              { text: recordData && recordData.first_name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={addressesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordianArrayView
          value={accordData}
          labels={labels}
          editMode={editMode}
        />
      </Box>
    </Box>
  );
}
