import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { pmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { resourceRowsMap } from '@config/handleRows/pm';
import { ResourceForm } from '@config/forms/pm';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { resourcesUrls } = pmUrls;

export default function Resources({ labels, editMode }) {
  const userId = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label='Resources'
        prefix='0da'
        labels={labels}
        editMode={editMode}
        microservice={microservices.PM.name}
        model={microservices.PM.models.resources}
        urls={resourcesUrls}
        columnKey='resourcePM'
        addNewForm={{
          getFields: () => ResourceForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { user, ...rest } = values;
              const response = await createRecord({
                values: {
                  user: user?.id,
                  ...rest,
                },
                url: resourcesUrls.list(),
                token: userId.token,
                client: userId?.details?.client,
              });
              resetForm();
              setAddNewForm(false);
              // notify(`Resource Created`, {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              console.log(err);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={resourceRowsMap}
        urlParams='&include_disabled=true'
      />
    </Box>
  );
}
