import React from 'react';
import * as Yup from 'yup';
import { bpaUrls } from '@config/routes';
import { stageMeta } from '@config/meta/bpa';
import { Grid } from '@mui/material';
import { TextField, AutocompleteFieldV2 } from '@components/Inputs';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import DropdownAddHOCNew from '@components/DropdownAddHOCNew';

const { stagesUrls } = bpaUrls;

export default function StageDropdown({
  workflow,
  children,
  initialId,
  initialOrder,
  initialName,
  initialDescription,
  refetch,
  parentStage: initialParentStage,
}) {
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState('Add');

  return (
    <>
      <DropdownAddHOCNew
        setOpen={setOpen}
        setAction={setAction}
        selected={initialId}
      >
        {children}
      </DropdownAddHOCNew>

      {open ? (
        <FormDialog
          submitType={action}
          label={`${action} Stage`}
          initialValues={{
            ...getAddFormInitialValues(stageMeta({}), 'listView'),
            parent_stage: initialParentStage,
            order: initialOrder ?? 1,
            name: initialName ?? '',
            description: initialDescription ?? '',
          }}
          validationSchema={Yup.object({
            ...getSchemaFromColumnsForForm(stageMeta({}), 'listView'),
            name: Yup.string().required(),
            order: Yup.string().matches(/^[0-9]+$/, 'Must be only digits'),
          })}
          getPostUrl={() => {
            if (action === 'Edit') {
              return stagesUrls.detail(initialId);
            }

            return stagesUrls.list();
          }}
          getValues={({ parent_stage, ...values }) => ({
            ...values,
            workflow,
            rebase: true,
            parent_stage: parent_stage?.id,
          })}
          getFields={() => (
            <Grid container spacing={3.5}>
              <Grid item xs={2}>
                <TextField label='Order' name='order' number />
              </Grid>

              <Grid item xs={10}>
                <TextField label='Name' name='name' multiline required />
              </Grid>

              {action === 'Add' ? (
                <Grid item xs={12}>
                  <AutocompleteFieldV2
                    name='parent_stage'
                    label='Parent Stage'
                    requestKey='stage-parentStage'
                    fetchUrl={stagesUrls.list}
                    urlParams={`&workflow=${workflow}&only_parent_stages=true&exclude_draft_stage=true`}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: `${row?.stage_full_order ?? ''}${
                        `${row?.order}` ?? ''
                      } ${row?.name ?? ''}`,
                    })}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <TextField
                  label='Description'
                  name='description'
                  minRows={3}
                  multiline
                />
              </Grid>

              {/* <Grid item xs={6}>
                <TextField label='Rotting Days' name='rotting_days' />
              </Grid>

              <Grid item xs={6}>
                <TextField label='Probability' name='probability_percent' />
              </Grid> */}
            </Grid>
          )}
          refetch={refetch}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
    </>
  );
}
