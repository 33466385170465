import React from 'react';
import { Box } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { selectMeBoard, setTasks } from '@redux/meBoardSlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { pmUrls, calendarUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { updateRecord } from '@config/functions/requests';
import { handleDragEnd } from '@config/functions/helperFunctions';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';
import {
  getAuthHeader,
  getLastSelectedDropdownOption,
} from '@config/functions/helperFunctions';

const { doWhatUrls } = calendarUrls;
const { meProjectTaskStagesUrls, tasksUrls, taskTypesUrls } = pmUrls;

const defaultFilters = '?order=order&page_size=200&item_order=-created_at';

export default function TasksBoard({ labels, editMode }) {
  const { tasks } = useSelector(selectMeBoard);
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [notify, update] = useToast();
  const initialTaskType = getLastSelectedDropdownOption('lastSelectedTaskType');
  const initialDoWhat = getLastSelectedDropdownOption('lastSelectedDoWhat');
  const [taskType, setTaskType] = React.useState(initialTaskType);
  const [doWhat, setDoWhat] = React.useState(initialDoWhat);
  const { data, isLoading } = useRQuery({
    key: ['tasks-board', taskType, doWhat, user.token, user?.actAs],
    url: meProjectTaskStagesUrls.list(
      `${defaultFilters}${taskType ? `&task_type=${taskType?.id}` : ''}${
        doWhat ? `&do_what=${doWhat?.id}` : ''
      }`
    ),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleSaveCardDrag = async ({ newList, removedItem, destId }) => {
    const tId = notify('Saving....', { loading: true });
    dispatch(setTasks(newList));
    try {
      await updateRecord({
        url: tasksUrls.detail(removedItem.id),
        values: { status: destId },
        token: user.token,
        actAs: user?.actAs,
      });
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(
        err.response && err.response.data ? err.response.data : err.message
      );
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    if (data) dispatch(setTasks(data));
  }, [data, dispatch]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Header
        title='Activities'
        prefix='0x7'
        labels={labels}
        editMode={editMode}
        filterOptions={[
          {
            label: 'Task Type',
            name: 'taskType',
            requestKey: 'me-board-task-types',
            fetchUrl: taskTypesUrls.list,
            handleChange: (newValue) => {
              setTaskType(newValue);
              localStorage.setItem(
                'lastSelectedTaskType',
                JSON.stringify(newValue)
              );
            },
            urlParams: `&project__name=me`,
            initialValue: initialTaskType,
          },
          {
            label: 'Do What',
            name: 'doWhat',
            requestKey: 'me-board-do-whats',
            fetchUrl: doWhatUrls.list,
            handleChange: (newValue) => {
              setDoWhat(newValue);
              localStorage.setItem(
                'lastSelectedDoWhat',
                JSON.stringify(newValue)
              );
            },
            urlParams: `&program__name=me`,
            initialValue: initialDoWhat,
          },
        ]}
      />

      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            <DragDropContext
              onDragEnd={(res) => handleDragEnd(res, tasks, handleSaveCardDrag)}
            >
              <KanbanBoardList
                detailLink='tasks'
                data={tasks}
                labels={labels}
                editMode={editMode}
              />
            </DragDropContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}
