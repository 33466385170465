import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { marketingV2Urls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { microservices } from '@config/constants';
import { marketSegmentsMeta } from '@config/meta/marketing';
import { CriteriaForm } from '@config/forms/marketing';
import { criteriaRowsMap } from '@config/handleRows/marketing';

const { marketSegmentsUrls, criteriaUrls } = marketingV2Urls;

export default function FormDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [criteria, setCriteria] = React.useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => marketSegmentsMeta({ data }),
    []
  );

  const criteriaColOptions = React.useMemo(
    () => ({
      nextOrder: criteria?.totalCount ?? 1,
    }),
    [criteria?.totalCount]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/market-segments', text: 'Market Segments' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={marketSegmentsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          prefix='03z'
          label='Criteria'
          labels={labels}
          editMode={editMode}
          columnKey='criteriaMarketSegment'
          columnOptions={criteriaColOptions}
          urls={criteriaUrls}
          microservice={microservices.Marketing.name}
          model={microservices.Marketing.models.criteria}
          addNewForm={{
            getFields: () => CriteriaForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { data } = await createRecord({
                  values: { ...values, marketSegmentId: recordId },
                  url: criteriaUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={criteriaRowsMap}
          urlParams={`&marketSegmentId=${recordId}`}
          customSortColumnName='order'
          sortColumnBy='asc'
          getListData={(data) => setCriteria(data)}
        />
      </Box>
    </Box>
  );
}
