import React from 'react';
import _ from 'lodash';
import { Grid, Typography, MenuItem } from '@mui/material';
import { selectProfile } from '@redux/profileSlice';
// import { selectRebase } from '@redux/pmRebaseSlice';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthHeader } from '@config/functions/helperFunctions';
import useRQuery from '@hooks/useRQuery';
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { updateRecord } from '@config/functions/requests';
import { useDispatch } from 'react-redux';
import { setAction } from '@redux/operationsSlice';
import { setRebaseDetail } from '@redux/pmRebaseSlice';
import { fieldType } from '@config/constants';
import WysiwygEditor from '@components/wiki/WysiwygEditor';
import { debounce } from 'lodash';
import FromError from '@components/core/FormError';
import DateTime from '@components/DateTime';
import formatColName from '@config/functions/removeUnderScoresFromStr';
import AutocompleteFieldV2 from '@ui/Inputs/AutocompleteFieldV2';
import { useQueryClient } from 'react-query';
import { TextField, CheckBoxField, SelectField, RepeatField } from '@ui/Inputs';
import RenderAutocompleteField from '@components/AutocompleteDetailCard';
import {
  formatToUTCDate,
  formatToUTCDateTime,
} from '@config/functions/helperFunctions';
import DetailCardUploadField from '../Inputs/DetailCardUploadField';

const {
  NUMBER,
  DATE,
  DATE_TIME,
  BOOLEAN,
  SELECT,
  REPEAT,
  HTML,
  AUTOCOMPLETE,
  DETAIL_LINK,
  AUTOCOMPLETE_V2,
  UPLOAD,
} = fieldType;

const htmlTextStyles = {
  fontWeight: '500',
  fontSize: '16px',
  color: 'rgba(0, 0, 0, 0.87)',
};

export default function DetailCard({
  urls,
  recordId,
  urlParams = '',
  getSchema = () => null,
  setData,
  microservice,
  getValues,
  getCustomUrl,
  catchRebasingError = false,
  onError = () => null,
  debounceDelay = 3000,
}) {
  const dispatch = useDispatch();
  const user = useSelector(selectProfile);
  const location = useLocation();
  // const pmRebase = useSelector(selectRebase);
  const [recordDetail, setRecordDetail] = React.useState(null);
  const [isUpdating, setUpdating] = React.useState('idle');
  const [error] = React.useState('');
  const [focusedField, setFocusedField] = React.useState(null);
  const [dirtyFields, setDirtyFields] = React.useState(new Set());
  const queryClient = useQueryClient();

  let debouncedUpdateRecord = null;

  const {
    data,
    loading,
    isError,
    error: dataError,
    isFetching,
    refetch,
  } = useRQuery({
    key: [`${recordId}-record-details`, recordId, user.token, user?.actAs],
    url: getCustomUrl
      ? getCustomUrl(urlParams)
      : urls.detail
      ? urls.detail(recordId, urlParams)
      : '',
    options: { enabled: !!(recordId && urls?.detail) },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const findDetailCardFields = (data) =>
    data.onDetailCard === undefined || data.onDetailCard === true;

  const setUpdatingToDone = () => {
    setUpdating('done');
    setTimeout(() => {
      setUpdating('idle');
    }, 3000);
  };

  const handleTextFieldBlur = (fieldName) => {
    if (focusedField === fieldName) return;
    setFocusedField(fieldName);
  };

  const handleFieldUpdate = (fieldName, newValue) => {
    if (typeof newValue === 'undefined') return;

    setRecordDetail((prevState) => {
      const updated = {
        ...prevState,
        fields: prevState.fields.map((f) => {
          return f.field === fieldName ? { ...f, value: newValue } : f;
        }),
      };
      return updated;
    });

    setDirtyFields((prevDirtyFields) => {
      const updatedDirtyFields = new Set(prevDirtyFields);
      updatedDirtyFields.add(fieldName);
      return updatedDirtyFields;
    });
  };

  // Use the useMemo hook to memoize the result of the getSchema function
  const schema = React.useMemo(() => getSchema(data), [data, getSchema]);

  React.useEffect(() => {
    if (dirtyFields.size < 1) return;

    debouncedUpdateRecord = debounce(async () => {
      const updatedFields = recordDetail?.fields?.filter((f) =>
        dirtyFields.has(f.field)
      );

      console.log('obi-updatedFields', updatedFields);

      const values = updatedFields.reduce((acc, item) => {
        let value = item.value;
        switch (item.type) {
          case fieldType.DATE:
            value = formatToUTCDate(value);
            break;
          case fieldType.DATE_TIME:
            value = formatToUTCDateTime(value);
            break;
          case fieldType.AUTOCOMPLETE:
            if (item.formatValue) {
              value = item.formatValue(value);
            }
            break;
          default:
            break;
        }

        acc[item.field] = value;
        return acc;
      }, {});

      console.log('obi-values', values);

      setUpdating('busy');

      try {
        const additionalValues = getValues ? await getValues(values) : {};

        const payload = { ...values, ...additionalValues };

        function updateDurationEstimate(obj) {
          // Check if duration_estimate exists in the object
          if (obj.hasOwnProperty('duration_estimate')) {
            // Check if it's an empty string or not a number
            if (obj.duration_estimate === '' || isNaN(obj.duration_estimate)) {
              obj.duration_estimate = 0;
            }
          }
        }

        updateDurationEstimate(payload);

        await updateRecord({
          url: getCustomUrl ? getCustomUrl(urlParams) : urls.detail(recordId),
          values: payload,
          token: user.token,
          actAs: user?.actAs,
          encryptionToken: user.encryptionToken,
        });

        if ('pipeline' in payload) {
          refetch();
        }

        setDirtyFields(new Set());
        setUpdatingToDone();
      } catch (err) {
        const rebaseError =
          catchRebasingError &&
          err?.response?.data?.error_code === "Can't Rebase In Past";
        if (onError && !rebaseError) onError(err);
        if (rebaseError)
          dispatch(
            setRebaseDetail({
              open: true,
              error: err?.response?.data,
            })
          );
        setUpdating('failed');
      }
    }, debounceDelay);

    debouncedUpdateRecord();

    return () => {
      if (debouncedUpdateRecord) {
        debouncedUpdateRecord.cancel();
      }
    };
  }, [dirtyFields, recordDetail]);

  React.useEffect(() => {
    if (data && !isError) {
      setRecordDetail({
        id: data.id,
        fields: schema,
      });

      if (setData) setData(data);
    }
  }, [data, isError, schema, getSchema]);

  React.useEffect(() => {
    dispatch(setAction(isUpdating));
  }, [isUpdating]);

  React.useEffect(() => {
    // Function to call when the route changes
    const handleRouteChange = () => {
      queryClient.invalidateQueries(`${recordId}-record-details`);
    };

    // Call handleRouteChange on initial mount and whenever location changes
    handleRouteChange();

    // Dependency on location ensures this effect runs on route change
  }, [recordId, queryClient, location]);

  if (isError) {
    return (
      <p>
        {dataError?.response?.status === 404
          ? `Oops, it appears the record you're looking for doesn't exist or you don't have permission to access it.`
          : `Oops, an issue occurred while retrieving the data`}
      </p>
    );
  }

  if (loading || isFetching || !data || !recordDetail) {
    return <Skeleton animation='wave' variant='rounded' height={100} />;
  }

  return (
    <Grid container direction='row' sx={{ ml: 1 }}>
      {recordDetail?.fields?.filter(findDetailCardFields).map((row) => {
        const { gridStyle, ...field } = row;

        let input;

        switch (field.type) {
          case DATE:
            input = (
              <>
                <DateTime
                  label={formatColName(field.headerName)}
                  data={field.value}
                  handleSubmit={(value) =>
                    handleFieldUpdate(field.field, value.dateObject)
                  }
                  disabled={!field.editable}
                />
                <FromError hide isError={!!error.length} error={error} />
              </>
            );
            break;
          case DATE_TIME:
            input = (
              <>
                <DateTime
                  showTime
                  showTimezone={field?.showTimezone ?? false}
                  label={formatColName(field.headerName)}
                  data={field.value}
                  handleSubmit={(value) =>
                    handleFieldUpdate(field.field, value.dateObject)
                  }
                  disabled={!field.editable}
                />
                <FromError hide isError={!!error.length} error={error} />
              </>
            );
            break;
          case BOOLEAN:
            input = (
              <>
                <Box sx={{ display: 'flex', align: 'left' }}>
                  <Box sx={{ display: 'flex', align: 'center' }}>
                    <CheckBoxField
                      id={field.field}
                      label={formatColName(field.headerName)}
                      checked={!!field.value}
                      onChange={(e) =>
                        handleFieldUpdate(field.field, e.target.checked)
                      }
                      disabled={!field.editable}
                    />
                  </Box>
                </Box>
                <FromError hide isError={!!error.length} error={error} />
              </>
            );
            break;
          case SELECT:
            input = (
              <>
                <Box>
                  <SelectField
                    id={field.field}
                    label={formatColName(field.headerName)}
                    value={field.value}
                    readOnly={!field.editable}
                    InputProps={{ readOnly: !field.editable }}
                    onChange={(e) =>
                      handleFieldUpdate(field.field, e.target.value)
                    }
                  >
                    {field.options.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Box>
                <FromError hide isError={!!error.length} error={error} />
              </>
            );
            break;
          case HTML:
            input = (
              <>
                <Typography variant='h6' sx={htmlTextStyles} gutterBottom>
                  {formatColName(field.field)}
                </Typography>

                <WysiwygEditor
                  initData={field.value}
                  onChange={(e) => {
                    if (!e.editor.getData().length) {
                      return handleFieldUpdate(field.field, '<p></p>');
                    }
                    handleFieldUpdate(field.field, e.editor.getData());
                  }}
                  readOnly={!field.editable}
                  msName={microservice}
                />
                <FromError hide isError={!!error.length} error={error} />
              </>
            );
            break;
          case REPEAT:
            input = (
              <>
                <Box>
                  <RepeatField
                    field={field.field}
                    label={formatColName(field.headerName)}
                    showDoesNotRepeat={field?.showDoesNotRepeat}
                    showCancel={field?.showCancel ?? false}
                    rrule={field.value}
                    readOnly={!field.editable}
                    InputProps={{ readOnly: !field.editable }}
                    isError={!!error.length}
                    error={error}
                    setCustomValue={(rrule) =>
                      handleFieldUpdate(field.field, rrule)
                    }
                  />
                </Box>
              </>
            );
            break;
          case AUTOCOMPLETE:
            const getAutocompleteField = () => (
              <RenderAutocompleteField
                label={field.headerName}
                data={field.options}
                name={field.field}
                isLoading={field.isLoading}
                setSearchTerm={field.setSearchTerm}
                value={field.value}
                setValue={(value) => handleFieldUpdate(field.field, value)}
                disabled={!field.editable}
                multiple={field.multiple}
              />
            );

            if (field.getDropdownForm) {
              const Mf = field.getDropdownForm();
              input = (
                <Mf.Form refetch={Mf.refetch}>
                  {getAutocompleteField(Mf?.props || {})}
                </Mf.Form>
              );
              break;
            }

            input = getAutocompleteField();
            break;
          case AUTOCOMPLETE_V2:
            const getAutocompleteV2Field = () => (
              <AutocompleteFieldV2
                label={field?.headerName}
                urlParams={field?.urlParams}
                disableSearch={field?.disableSearch}
                requestKey={field?.requestKey}
                orderingCol={field?.orderingCol}
                requestKeyOptions={field?.requestKeyOptions ?? []}
                fetchUrl={field?.fetchUrl}
                renderRow={field?.renderRow}
                getOptionLabel={field?.getOptionLabel}
                disabled={!field?.editable}
                enabled={field?.enabled}
                runEffectOnInit={field?.runEffectOnInit}
                initialValue={
                  _.isObject(field?.initialValue) ? field.initialValue : null
                }
                customOnChange={(newValue) => {
                  if (field?.customOnChange) {
                    field?.customOnChange(
                      newValue,
                      field?.value ?? field?.initialValue?.id
                    );
                  }
                  handleFieldUpdate(field?.field, newValue?.id);
                }}
              />
            );

            if (field.getDropdownForm) {
              const Mf = field.getDropdownForm();
              input = (
                <Mf.Form refetch={Mf.refetch}>
                  {getAutocompleteV2Field(Mf?.props || {})}
                </Mf.Form>
              );
              break;
            }

            input = getAutocompleteV2Field();
            break;
          case DETAIL_LINK:
            input = (
              <>
                <Typography>{formatColName(field.field)}</Typography>
                <Box sx={{ width: '100%' }}>
                  <a
                    target='_blank'
                    style={{ color: 'blue' }}
                    href={field.value}
                    rel='noreferrer'
                  >
                    {field.value}
                  </a>
                </Box>
                <FromError hide isError={!!error.length} error={error} />
              </>
            );
            break;
          case UPLOAD:
            input = (
              <DetailCardUploadField
                id={field.field}
                label={field.headerName}
                value={field.value ?? ''}
                required={field.required}
                onSubmit={(value) => handleFieldUpdate(field.field, value)}
              />
            );
            break;
          default:
            input = (
              <TextField
                fullWidth
                id={field.field}
                label={field.headerName}
                value={!field?.value && field?.value !== 0 ? '' : field?.value}
                autoFocus={focusedField === field.field}
                multiline={
                  field.type === fieldType.MULTILINE ||
                  field.type === fieldType.MULTILINE_CONDENSED
                }
                maxRows={
                  field.type === fieldType.MULTILINE_CONDENSED
                    ? 3
                    : field?.maxRows ?? undefined
                }
                rows={field?.rows ?? undefined}
                type={field.type || fieldType.TEXT}
                onChange={(e) =>
                  handleFieldUpdate(
                    field.field,
                    field?.type === NUMBER
                      ? e.target.value || null
                      : e.target.value ?? ''
                  )
                }
                onBlur={() => handleTextFieldBlur(field.field)}
                onClick={() => handleTextFieldBlur(field.field)}
                error={!!error.length}
                helperText={error}
                InputProps={{ readOnly: !field.editable }}
              />
            );
            break;
        }

        return (
          <Grid
            key={field.field}
            sx={{ pr: '0.75rem', pb: '1.35rem' }}
            item
            md={gridStyle || 4}
          >
            {input}
          </Grid>
        );
      })}
    </Grid>
  );
}
