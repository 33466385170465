import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { inventoryUrls, nodeDriveUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import {
  ProductSupplierForm,
  WarehouseInventoryForm,
  DeliveryItemForm,
  VariantValueForm,
  MediaForm,
} from '@config/forms/inventory/index';
import {
  productVariantMeta,
} from '@config/meta/inventory/index';
import DetailPageHeader from '@components/DetailPageHeader';
import { microservices } from '@config/constants';
import {
  productSupplierRowMap,
  warehouseInventoryRowMap,
  deliveryItemRowMap,
  variantValueRowMap,
  mediaRowMap,
} from '@config/handleRows/inventory';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const {
  productVariantsUrls,
  productSuppliersUrls,
  warehouseInventorysUrls,
  deliveryItemsUrls,
  variantValuesUrls,
  mediasUrls,
} = inventoryUrls;
const { filesUrls } = nodeDriveUrls;

export default function ProductVariantsDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const [notify] = useToast();

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => productVariantMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key="detail-view-header"
            items={[
              { to: `/products`, text: 'Product' },
              {
                to: `/products/${recordData.product}`,
                text:
                  recordData.details.product && recordData.details.product.name,
              },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={productVariantsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix="0jd"
          microservice={microservices.INVENTORY.name}
          model={microservices.INVENTORY.models.productSupplier}
          columnKey="productSupplierInventory"
          label="Product Suppliers"
          urls={productSuppliersUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              ProductSupplierForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { supplier, currency, ...rest } = values;
                await createRecord({
                  values: {
                    ...rest,
                    product_variant: recordId,
                    supplier: supplier?.id,
                    currency: currency?.id,
                  },
                  url: productSuppliersUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={productSupplierRowMap}
          urlParams={`&product_variant=${recordId}`}
        />
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix="0pp"
          microservice={microservices.INVENTORY.name}
          model={microservices.INVENTORY.models.warehouseInventory}
          columnKey="warehouseInventoryInventory"
          label="Warehouse Inventorys"
          urls={warehouseInventorysUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              WarehouseInventoryForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { warehouse, ...rest } = values;

                await createRecord({
                  values: {
                    ...rest,
                    warehouse: warehouse?.id,
                    product_variant: recordId,
                  },
                  url: warehouseInventorysUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={warehouseInventoryRowMap}
          urlParams={`&product_variant=${recordId}`}
        />
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix="0p4"
          microservice={microservices.INVENTORY.name}
          model={microservices.INVENTORY.models.deliveryItem}
          columnKey="deliveryItemInventory"
          label="Delivery Items"
          urls={deliveryItemsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              DeliveryItemForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { delivery, ...rest } = values;
                await createRecord({
                  values: {
                    ...rest,
                    product_variant: recordId,
                    delivery: delivery?.id,
                  },
                  url: deliveryItemsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={deliveryItemRowMap}
          urlParams={`&product_variant=${recordId}`}
        />
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix="0lo"
          microservice={microservices.INVENTORY.name}
          model={microservices.INVENTORY.models.variantValues}
          columnKey="variantValueInventory"
          label="Variant Values"
          urls={variantValuesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              VariantValueForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { key, ...rest } = values;
                await createRecord({
                  values: {
                    ...rest,
                    product_variant: recordId,
                    key: key?.id,
                  },
                  url: variantValuesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={variantValueRowMap}
          urlParams={`&product_variant=${recordId}`}
        />

        <DetailAccordionView
          label="Medias"
          prefix="06s"
          labels={labels}
          editMode={editMode}
          microservice={microservices.INVENTORY.name}
          model={microservices.INVENTORY.models.media}
          urls={mediasUrls}
          columnKey="mediaInventory"
          addNewForm={{
            getFields: ({ values, errors }) => MediaForm({ values, errors }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                let file = values.url;

                const formData = new FormData();
                formData.append('anonymous_can_see_it', true);
                formData.append('file', file);

                const { data: fileData } = await createRecord({
                  values: formData,
                  url: filesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                file = fileData.fileUrl;

                const response = await createRecord({
                  values: {
                    ...values,
                    type: 'Image',
                    url: file,
                    product_variant: recordId,
                    product: recordData.product,
                  },
                  url: mediasUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={mediaRowMap}
          urlParams={`&product_variant=${recordId}`}
        />
      </Box>
    </Box>
  );
}
