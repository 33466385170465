import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DetailCard from '@components/core/DetailCard';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { TaskBugsForm } from '@config/forms/pm';
import { createRecord } from '@config/functions/requests';
import { taskBugRowsMap } from '@config/handleRows/pm';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import {
  openBrowserPopup,
  handleCreateFormErrors,
} from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';
import { bugMeta } from '@config/meta/pm/pmMeta';
import { pmUrls } from '@config/routes';
import LaunchIcon from '@mui/icons-material/Launch';
import useToast from '@hooks/useToast';

const { bugsUrls, taskBugsUrls } = pmUrls;

export default function BugsSidePeek({ recordId, open, setOpen }) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  const container =
    typeof window !== 'undefined' ? () => window.document.body : undefined;

  const getSchema = React.useCallback((data) => {
    return bugMeta({
      data,
      queryClient,
      typeGridStyle: 6,
      reportedByNameGridStyle: 6,
      reportedByEmailGridStyle: 6,
      headlineGridStyle: 12,
      projectGridStyle: 6,
      priorityGridStyle: 6,
      osGridStyle: 6,
      browserGridStyle: 6,
      urlGridStyle: 6,
      screenshotDriveLinkGridStyle: 6,
      stepsToReproduceGridStyle: 12,
      expectedResultGridStyle: 12,
      actualResultGridStyle: 12,
      statusGridStyle: 6,
      resourceGridStyle: 6,
      signedOffGridStyle: 6,
      durationEstimateGridStyle: 6,
      durationUnitGridStyle: 6,
      durationActualGridStyle: 6,
      dueDateGridStyle: 6,
      estimatedStartDateTimeGridStyle: 6,
      dateFixedGridStyle: 6,
      resolutionGridStyle: 6,
      keywordsGridStyle: 6,
      notesGridStyle: 6,
      createdAtGridStyle: 6,
      reportedByNameOnDetailCard: false,
    });
  }, []);

  const taskBugsColOptions = React.useMemo(() => {
    return {
      queryClient,
      showBug: false,
      includeFilters: true,
    };
  }, [queryClient]);

  return (
    <Drawer
      container={container}
      variant="temporary"
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 699,
        },
        '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.06)' },
      }}
      open={open}
      onClose={() => setOpen(false)}
      ModalProps={{
        keepMounted: true,
      }}
      anchor="right"
    >
      <Toolbar />
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Typography sx={{ fontWeight: '500', fontSize: '22px' }}>
            Data
          </Typography>

          <Box sx={{ ml: 'auto' }}>
            <Button
              variant="text"
              endIcon={<LaunchIcon />}
              sx={{ mr: 1 }}
              onClick={() => {
                openBrowserPopup(`/bugs/${recordId}?noDrawer=true`);
              }}
            >
              Detail
            </Button>
          </Box>
        </Box>

        <DetailCard
          urls={bugsUrls}
          recordId={recordId}
          setData={setRecordData}
          getSchema={getSchema}
        />
        {recordData ? (
          <Box sx={{ mt: 4 }}>
            <DetailAccordionView
              microservice={microservices.PM.name}
              model={microservices.PM.models.taskbugs}
              columnKey="taskBugPM"
              columnOptions={taskBugsColOptions}
              label="Task Bugs"
              prefix="0e0"
              urls={taskBugsUrls}
              customInitialValues={{
                program: {
                  id: recordData?.program_details?.id || null,
                  label: recordData?.program_details?.name || '',
                },
                project: {
                  id: recordData?.project || null,
                  label: recordData?.details?.project?.name || '',
                },
              }}
              addNewForm={{
                getFields: ({ values, errors, setFieldValue }) =>
                  TaskBugsForm({
                    values,
                    errors,
                    setFieldValue,
                    showBug: false,
                  }),
                handleSubmit: async (
                  values,
                  setSubmitting,
                  resetForm,
                  setAddNewForm,
                  refetch,
                  setError
                ) => {
                  try {
                    const response = await createRecord({
                      values: {
                        ...values,
                        task: values?.task?.id,
                        bug: recordId,
                      },
                      url: taskBugsUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                    });
                    resetForm();
                    setAddNewForm(false);
                    refetch();
                    return response?.data?.id;
                  } catch (err) {
                    handleCreateFormErrors({ err, setError, notify, values });
                  } finally {
                    setSubmitting(false);
                  }
                },
              }}
              handleRowMap={taskBugRowsMap}
              urlParams={`&bug=${recordId}`}
            />
          </Box>
        ) : null}
      </Box>
    </Drawer>
  );
}
