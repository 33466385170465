import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { crmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { callScheduleMeta } from '@config/meta/crm';
import { callHistoryRowsMap } from '@config/handleRows/crm';
import { CallHistoryForm } from '@config/forms/crm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { callSchedulesUrls, callHistoriesUrls } = crmUrls;

function formatPerson(row) {
  const firstName = row?.first_name || 'Unnamed';
  const lastName = row?.last_name || '';
  const email = row?.email ? `(${row.email})` : '';

  return `${firstName} ${lastName} ${email}`;
}

export default function CallScheduleDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);

  // Use the useCallback hook to optimize the getSchema function

  const getSchema = React.useCallback(
    (data) =>
      callScheduleMeta({
        data,
        queryClient,
      }),
    []
  );

  const callHistoryColOptions = React.useMemo(() => {
    return {
      callListPipelineId: recordData?.details?.call_list?.call_list_pipeline,
    };
  }, [recordData?.details?.call_list?.call_list_pipeline]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/call-lists/${recordData?.call_list}`,
                text: 'Call Schedules',
              },
              { text: formatPerson(recordData?.details?.person) },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={callSchedulesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.CRM.name}
          model={microservices.CRM.models.callHistory}
          columnKey="callHistoryCRM"
          columnOptions={callHistoryColOptions}
          label="Call History"
          prefix="0m4"
          labels={labels}
          editMode={editMode}
          urls={callHistoriesUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              CallHistoryForm({
                setFieldValue,
                callListPipelineId:
                  recordData?.details?.call_list?.call_list_pipeline,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { call_list_pipeline_stage, ...rest } = values;

                const { data } = await createRecord({
                  values: {
                    ...rest,
                    call_schedule: recordId,
                    call_list_pipeline_stage: call_list_pipeline_stage?.id,
                  },
                  url: callHistoriesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={callHistoryRowsMap}
          urlParams={`&call_schedule=${recordId}`}
        />
      </Box>
    </Box>
  );
}
