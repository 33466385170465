import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: null,
  inas: [],
};

export const inasBoardSlice = createSlice({
  name: 'inasBoard',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setINAs: (state, action) => {
      state.inas = action.payload;
    },
  },
});

export const { setModal, setINAs } = inasBoardSlice.actions;

export const selectINAsBoard = (state) => state.inasBoard;

export default inasBoardSlice.reducer;
