import { CheckBoxField } from '@components/Inputs';
import Box from '@mui/material/Box';
// import AutocompleteField from '@ui/Inputs/AutocompleteField';
import AutocompleteField from '@components/Inputs/LegacyAutocompleteField';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';

export default function ObjectLevelPermissionForm({
  clients,
  setClientTerm,
  clientsIsFetching,
  roles,
  setRoleTerm,
  rolesIsFetching,
  users,
  setUserTerm,
  usersIsFetching,
  values,
  setCustomValues,
  disabled,
  includeClient = false,
}) {
  const user = useSelector(selectProfile);

  return (
    <>
      <Box>
        <CheckBoxField
          key='everyone_can_see_it'
          label='Everyone can see'
          name='everyone_can_see_it'
          disabled={disabled}
          customOnChange={(e) => {
            setCustomValues({
              ...values,
              everyone_can_see_it: e.target.checked,
            });
          }}
          checked={values.everyone_can_see_it}
        />
      </Box>

      <Box sx={{ my: 1 }}>
        <CheckBoxField
          key='anonymous_can_see_it'
          label='Only anonymous users can see'
          name='anonymous_can_see_it'
          customOnChange={(e) => {
            setCustomValues({
              ...values,
              anonymous_can_see_it: e.target.checked,
            });
          }}
          checked={values.anonymous_can_see_it}
          disabled={disabled}
        />
      </Box>

      <Box sx={{ my: 1 }}>
        <CheckBoxField
          key='everyone_in_object_company_can_see_it'
          label='Everyone in this company can see'
          name='everyone_in_object_company_can_see_it'
          customOnChange={(e) => {
            setCustomValues({
              ...values,
              everyone_in_object_company_can_see_it: e.target.checked,
            });
          }}
          checked={values.everyone_in_object_company_can_see_it}
          disabled={disabled}
        />
      </Box>

      {includeClient &&
      Array.isArray(user?.details?.roles_names) &&
      (user?.details?.is_superuser ||
        user?.details?.roles_names.includes('Template Admins')) ? (
        <Box sx={{ my: 2 }}>
          <AutocompleteField
            key='only_these_clients_can_see_it'
            multiple
            setSearchTerm={setClientTerm}
            isLoading={clientsIsFetching}
            options={clients}
            label='Only These Clients Can See It'
            name='only_these_clients_can_see_it'
            customOnValueChange={(e, newValue) => {
              const only_these_clients_can_see_it = {};
              newValue.map((selectedClient) => {
                only_these_clients_can_see_it[selectedClient.id] =
                  selectedClient.label;
              });
              setCustomValues({
                ...values,
                only_these_clients_can_see_it: only_these_clients_can_see_it,
              });
            }}
            value={Object.entries(
              values.only_these_clients_can_see_it || {}
            ).map((client) => ({
              id: client[0],
              label: client[1],
            }))}
            disabled={disabled}
          />
        </Box>
      ) : null}

      <Box sx={{ my: 2 }}>
        <AutocompleteField
          key='only_these_roles_can_see_it'
          multiple
          setSearchTerm={setRoleTerm}
          isLoading={rolesIsFetching}
          options={roles}
          label='Only These Roles Can See It'
          name='only_these_roles_can_see_it'
          customOnValueChange={(e, newValue) => {
            const only_these_roles_can_see_it = {};
            newValue.map((selectedRole) => {
              only_these_roles_can_see_it[selectedRole.id] = selectedRole.label;
            });
            setCustomValues({
              ...values,
              only_these_roles_can_see_it: only_these_roles_can_see_it,
            });
          }}
          value={Object.entries(values.only_these_roles_can_see_it || {}).map(
            (role) => ({
              id: role[0],
              label: role[1],
            })
          )}
          disabled={disabled}
        />
      </Box>

      <Box sx={{ my: 1 }}>
        <AutocompleteField
          key='only_these_users_can_see_it'
          multiple
          setSearchTerm={setUserTerm}
          isLoading={usersIsFetching}
          options={users}
          label='Only These Users Can See It'
          name='only_these_users_can_see_it'
          customOnValueChange={(e, newValue) => {
            const only_these_users_can_see_it = {};
            newValue.map((selectedUser) => {
              only_these_users_can_see_it[selectedUser.id] = selectedUser.label;
            });
            setCustomValues({
              ...values,
              only_these_users_can_see_it: only_these_users_can_see_it,
            });
          }}
          value={Object.entries(values.only_these_users_can_see_it || {}).map(
            (user) => ({
              id: user[0],
              label: user[1],
            })
          )}
          disabled={disabled}
        />
      </Box>
    </>
  );
}
