import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { cmsUrls, systemUrls } from '@config/routes';
import { pagesRowsMap } from '@config/handleRows/cms';
import { pagesMeta } from '@config/meta/cms';
import { microservices } from '@config/constants';
import useRQuery from '@hooks/useRQuery';
import Loader from '@components/Loader';
import {
  getAuthHeader,
  handleCreateFormErrors,
} from '@config/functions/helperFunctions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { useQueryClient } from 'react-query';
import { PageForm } from '@config/forms/cms';

const { pagesUrls, duplicatePageUrls } = cmsUrls;
const { clientsUrls } = systemUrls;

export default function CMSPages({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const [domain, setDomain] = React.useState('');

  const { data, isLoading } = useRQuery({
    key: ['client', user.token, user?.actAs],
    url: clientsUrls.detail(user?.actAs?.domain),
    options: { enabled: !!user.token },
    config: getAuthHeader(user.token, user?.actAs),
  });

  React.useEffect(() => {
    if (!data?.domain) return;
    if (process.env.REACT_APP_APP_ENV === 'sandbox') {
      setDomain(`https://sandbox.${data?.domain}`);
    } else {
      setDomain(`https://${data?.domain}`);
    }
  }, [data]);

  if (isLoading || !data || !domain) {
    return (
      <Box sx={{ py: 16 }}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box>
      <ListView
        label='Pages'
        prefix='066'
        labels={labels}
        editMode={editMode}
        microservice={microservices.CMS.name}
        model={microservices.CMS.models.posts}
        urls={pagesUrls}
        columns={pagesMeta({ domain, user })}
        addNewForm={{
          getFields: () => PageForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              await createRecord({
                values: {},
                url: duplicatePageUrls.detail(values.pageId),
                token: user.token,
                actAs: user?.actAs,
              });

              queryClient.invalidateQueries(['Pages-listData']);
              resetForm();
              setAddNewForm(false);
              refetch();
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        updateRecordCB={({ values }) => {
          if (values.page_status === 'Draft') {
            return {
              anonymous_can_see_it: false,
              everyone_in_object_company_can_see_it: true,
              ...values,
            };
          }
          if (values.page_status === 'Published') {
            return {
              anonymous_can_see_it: true,
              everyone_in_object_company_can_see_it: true,
              ...values,
            };
          }
          if (values.page_status === 'Trash') {
            return {
              anonymous_can_see_it: false,
              everyone_in_object_company_can_see_it: true,
              ...values,
            };
          }
          return values;
        }}
        dynamicActions={() => [
          {
            icon: <ContentCopyIcon sx={{ mr: 1 }} />,
            label: 'Duplicate',
            handleClick:
              ({ row }) =>
              async () => {
                try {
                  const pageId = row.id;

                  await createRecord({
                    values: {},
                    url: duplicatePageUrls.detail(pageId),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  queryClient.invalidateQueries(['Pages-listData']);
                } catch (err) {
                  // error
                  console.log('err', err);
                  notify(`Failed to duplicate page`, {
                    type: 'ERROR',
                  });
                }
              },
          },
        ]}
        handleRowsMap={pagesRowsMap}
      />
    </Box>
  );
}
