import * as React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@components/LMS/Accordion';
import AccordionSummary from '@components/LMS/AccordionSummary';
import AccordionDetails from '@components/LMS/AccordionDetails';
import TopBar from '@components/LMS/TopBar';
import { useQueryClient } from 'react-query';
import { Box, Grid, Container, Button, Divider } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import { lmsUrls, nodeDriveUrls, wikiUrls } from '@config/routes';
import {
  TextField,
  UploadField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import UniversityDropdown from '@dropdown-forms/lms/UniversityDropdown';
import FacultyDropdown from '@dropdown-forms/lms/FacultyDropdown';
import HomeworkDropdown from '@dropdown-forms/lms/HomeworkDropdown';
import ModuleDropdown from '@dropdown-forms/lms/ModuleDropdown';
import LessonDropdown from '@dropdown-forms/lms/LessonDropdown';
import AddIcon from '@mui/icons-material/Add';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { useNavigate } from 'react-router-dom';
import BookDropdown from '@dropdown-forms/wiki/BookDropdown';
import CategoryDropdown from '@dropdown-forms/lms/CategoryDropdown';

const { booksUrls } = wikiUrls;
const {
  facultiesUrls,
  universitiesUrls,
  homeworksUrls,
  modulesUrls,
  lessonsUrls,
  createCourseAndRelationsUrls,
  categoriesUrls,
} = lmsUrls;
const { filesUrls } = nodeDriveUrls;

function StepButtons({ backBtnText, nextBtnText, onBack, onNext, disabled }) {
  return (
    <>
      <Divider sx={{ mb: 2 }} />

      <Button
        variant='outlined'
        disableElevation
        sx={{ px: 4, mr: 2 }}
        onClick={onBack}
        disabled={disabled}
      >
        {backBtnText || 'Back'}
      </Button>
      <Button
        variant='contained'
        disableElevation
        sx={{ px: 4 }}
        onClick={onNext}
        disabled={disabled}
      >
        {nextBtnText || 'Next'}
      </Button>
    </>
  );
}

export default function CreateCourse() {
  const [notify] = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [expanded, setExpanded] = React.useState('university');
  const [childExpanded, setChildExpanded] = React.useState(false);

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChildChange = (panel) => (e, newExpanded) => {
    setChildExpanded(newExpanded ? panel : false);
  };

  const validateUniversityFields = ({ values, setFieldError }) => {
    const errors = {};

    if (!values?.university) {
      errors['university'] = 'Please select an option';
    }

    if (!values?.faculty) {
      errors['faculty'] = 'Please select an option';
    }

    Object.keys(errors).map((key) => {
      setFieldError(key, errors[key]);
    });

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  const validateCourseFields = ({ values, setFieldError }) => {
    const errors = {};

    if (!values?.courseTitle) {
      errors['courseTitle'] = 'Please enter a title';
    }

    if (!values?.courseThumbnail) {
      errors['courseThumbnail'] = 'Please upload a thumbnail';
    }

    if (!values?.courseWikiBook) {
      errors['courseWikiBook'] = 'Please select a wikibook';
    }

    Object.keys(errors).map((key) => {
      setFieldError(key, errors[key]);
    });

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  const validateModuleFields = ({ values, setFieldError }) => {
    const errors = [];

    values.modules.forEach((item, index) => {
      setFieldError(`modules.${index}`, null);

      if (!item?.id || !item?.label) {
        errors[index] = 'Please select an option';
      }
    });

    errors.forEach((message, index) => {
      setFieldError(`modules.${index}`, message);
    });

    if (errors.length) return false;

    return true;
  };

  const validateLessonFields = ({ values, setFieldError }) => {
    const errors = [];

    values.lessons.forEach((item, index) => {
      setFieldError(`lessons.${index}`, null);

      if (!item?.id || !item?.label) {
        errors[index] = 'Please select an option';
      }
    });

    errors.forEach((message, index) => {
      setFieldError(`lessons.${index}`, message);
    });

    if (errors.length) return false;

    return true;
  };

  return (
    <Box>
      <TopBar title='Create Course' active={expanded} setActive={setExpanded} />

      <Formik
        initialValues={{
          university: null,
          faculty: null,
          courseTitle: '',
          courseHomework: null,
          courseWikiBook: null,
          courseCategory: null,
          courseDescription: '',
          courseThumbnail: '',
          modules: [{ id: null, label: '', sequence: 1 }],
          lessons: [],
        }}
        onSubmit={async (
          values,
          { setSubmitting, setFieldError, resetForm }
        ) => {
          try {
            setSubmitting(true);

            const universityValid = validateUniversityFields({
              values,
              setFieldError,
            });

            if (!universityValid) {
              setExpanded('university');
              return;
            }

            const courseValid = validateCourseFields({
              values,
              setFieldError,
            });

            if (!courseValid) {
              setExpanded('course');
              return;
            }

            const moduleValid = validateModuleFields({
              values,
              setFieldError,
            });

            if (!moduleValid) {
              setExpanded('module');
              return;
            }

            const lessonValid = validateLessonFields({
              values,
              setFieldError,
            });

            if (!lessonValid) {
              setExpanded('lesson');
              return;
            }

            let fileUrl;

            try {
              const formData = new FormData();
              formData.append(
                'client',
                user?.actAs?.id || user?.details?.client
              );
              formData.append('created_by', user?.details?.id);
              formData.append('anonymous_can_see_it', true);
              formData.append('file', values.courseThumbnail);

              const { data: fileData } = await createRecord({
                values: formData,
                url: filesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              fileUrl = fileData.fileUrl;
            } catch {}

            if (!fileUrl) {
              setExpanded('course');
              setFieldError('courseThumbnail', 'Failed to upload thumbnail');
              return;
            }

            const { data: course } = await createRecord({
              values: {
                name: values.courseTitle,
                thumbnail: fileUrl,
                homeworkId: values.courseHomework?.id,
                wikiBookId: values.courseWikiBook?.id,
                categoryId: values.courseCategory?.id,
                facultyId: values.faculty?.id,
                modules: values.modules,
                lessons: values.lessons.map(({ id, sequence, module }) => ({
                  id,
                  sequence,
                  moduleId: module?.id,
                })),
              },
              url: createCourseAndRelationsUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });

            setExpanded(null);
            resetForm();
            navigate(`/courses/${course?.id}`);
            notify('Operation completed', {
              type: 'SUCCESS',
            });
          } catch (error) {
            notify('There was an error, please try again', {
              type: 'ERROR',
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          isSubmitting,
          submitForm,
          setFieldValue,
          setFieldError,
          values,
        }) => (
          <Form>
            <Container maxWidth='md'>
              <Accordion
                expanded={expanded === 'university'}
                onChange={handleChange('university')}
              >
                <AccordionSummary
                  aria-controls='university-content'
                  id='university-header'
                >
                  <Typography>University</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} sx={{ py: 2 }}>
                    <Grid item xs={6}>
                      <UniversityDropdown
                        refetch={({ id, name }) => {
                          setFieldValue('university', { id, label: name });
                          queryClient.invalidateQueries([
                            'create-cs-university',
                          ]);
                        }}
                      >
                        <AutocompleteFieldV2
                          name='university'
                          label='University'
                          requestKey='create-cs-university'
                          fetchUrl={universitiesUrls.list}
                          required
                        />
                      </UniversityDropdown>
                    </Grid>
                    <Grid item xs={6}>
                      <FacultyDropdown
                        universityId={values?.university?.id}
                        refetch={({ id, name }) => {
                          setFieldValue('faculty', { id, label: name });
                          queryClient.invalidateQueries(['create-cs-faculty']);
                        }}
                      >
                        <AutocompleteFieldV2
                          name='faculty'
                          label='Faculty'
                          requestKey='create-cs-faculty'
                          fetchUrl={facultiesUrls.list}
                          disabled={!values?.university}
                          enabled={!!values?.university?.id}
                          requestKeyOptions={[values?.university?.id]}
                          urlParams={
                            values?.university
                              ? `&universityId=${values.university?.id}`
                              : ''
                          }
                          required
                        />
                      </FacultyDropdown>
                    </Grid>
                  </Grid>

                  <StepButtons
                    backBtnText='Exit'
                    onNext={() => {
                      const isValid = validateUniversityFields({
                        values,
                        setFieldError,
                      });

                      if (isValid) setExpanded('course');
                    }}
                    onBack={() => {
                      window.location.href = '/courses';
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'course'}
                onChange={handleChange('course')}
              >
                <AccordionSummary
                  aria-controls='course-content'
                  id='course-header'
                >
                  <Typography>Course</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} sx={{ py: 2 }}>
                    <Grid item xs={6}>
                      <TextField
                        label='Course Title'
                        name='courseTitle'
                        multiline
                        required
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <HomeworkDropdown
                        refetch={({ id, name }) => {
                          setFieldValue('courseHomework', { id, label: name });
                          queryClient.invalidateQueries(['course-homework']);
                        }}
                      >
                        <AutocompleteFieldV2
                          name='courseHomework'
                          label='Course Task'
                          requestKey='course-homework'
                          fetchUrl={homeworksUrls.list}
                        />
                      </HomeworkDropdown>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label='Course Description'
                        name='courseDescription'
                        minRows={4}
                        multiline
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Box sx={{ maxWidth: '490px' }}>
                        <UploadField
                          name='courseThumbnail'
                          label='thumbnail'
                          accept='.png, .jpg, .jpeg'
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <BookDropdown
                        refetch={({ id, name }) => {
                          setFieldValue('courseWikiBook', { id, label: name });
                          queryClient.invalidateQueries(['course-wiki-book']);
                        }}
                      >
                        <AutocompleteFieldV2
                          name='courseWikiBook'
                          label='Course Wikibook'
                          requestKey='wiki-book'
                          fetchUrl={booksUrls.list}
                          required
                        />
                      </BookDropdown>
                    </Grid>
                    <Grid item xs={6}>
                      <CategoryDropdown
                        refetch={({ id, name }) => {
                          setFieldValue('courseCategory', { id, label: name });
                          queryClient.invalidateQueries(['course-category']);
                        }}
                      >
                        <AutocompleteFieldV2
                          name='courseCategory'
                          label='Course Category'
                          requestKey='course-category'
                          fetchUrl={categoriesUrls.list}
                        />
                      </CategoryDropdown>
                    </Grid>
                  </Grid>
                  <StepButtons
                    onNext={() => {
                      const isValid = validateCourseFields({
                        values,
                        setFieldError,
                      });
                      if (isValid) setExpanded('module');
                    }}
                    onBack={() => setExpanded('university')}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'module'}
                onChange={handleChange('module')}
              >
                <AccordionSummary
                  aria-controls='module-content'
                  id='module-header'
                >
                  <Typography>Module</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ py: 2 }}>
                    <FieldArray name='modules'>
                      {({ remove, push }) => (
                        <>
                          <Button
                            variant='outlined'
                            startIcon={<AddIcon />}
                            sx={{ mb: '22px' }}
                            onClick={() => {
                              push({
                                id: null,
                                label: '',
                                sequence: values?.modules?.length + 1,
                              });
                            }}
                          >
                            Add a new module
                          </Button>

                          <Grid container spacing={2}>
                            {values.modules
                              .sort((a, b) => a.sequence - b.sequence)
                              .map((module, index) => (
                                <React.Fragment key={index}>
                                  <Grid item xs={2}>
                                    <TextField
                                      label='Sequence'
                                      name={`modules.${index}.sequence`}
                                    />
                                  </Grid>

                                  <Grid item xs={8}>
                                    <ModuleDropdown
                                      refetch={({ id, name }) => {
                                        setFieldValue(`modules.${index}`, {
                                          id,
                                          label: name,
                                          sequence: module.sequence,
                                        });
                                        queryClient.invalidateQueries([
                                          `${module?.id}-create-cs-module`,
                                        ]);
                                      }}
                                    >
                                      <AutocompleteFieldV2
                                        required
                                        label='Module'
                                        disableDefaultSetValue
                                        renderRow={(row) => ({
                                          id: row?.id,
                                          label: row?.name,
                                          moduleLessons: row?.moduleLessons,
                                        })}
                                        setCustomOptions={(filteredModules) => {
                                          return filteredModules.filter(
                                            ({ id }) => {
                                              return !values.modules.some(
                                                (module) => module?.id === id
                                              );
                                            }
                                          );
                                        }}
                                        onChange={(value) => {
                                          setFieldValue(`modules.${index}`, {
                                            ...value,
                                            sequence: module.sequence,
                                            lessons: value.moduleLessons,
                                          });

                                          const updatedLessons = [
                                            ...values.lessons.filter(
                                              (lesson) =>
                                                lesson?.module?.id !== value?.id
                                            ),
                                            ...value.moduleLessons.map(
                                              (row, index) => ({
                                                id: row?.lesson?.id,
                                                label: row?.lesson?.name,
                                                sequence: index + 1,
                                                module: { ...value },
                                              })
                                            ),
                                          ];

                                          setFieldValue(
                                            'lessons',
                                            updatedLessons
                                          );
                                        }}
                                        name={`modules.${index}`}
                                        requestKey={`${module?.id}-create-cs-module`}
                                        fetchUrl={modulesUrls.list}
                                      />
                                    </ModuleDropdown>
                                  </Grid>

                                  <Grid
                                    item
                                    sx={{
                                      visibility:
                                        index > 0 ? 'visible' : 'hidden',
                                    }}
                                  >
                                    <Button
                                      variant='text'
                                      color='error'
                                      size='small'
                                      sx={{ mt: 0.5 }}
                                      onClick={() => remove(index)}
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                </React.Fragment>
                              ))}
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </Box>

                  <StepButtons
                    onNext={() => {
                      const isValid = validateModuleFields({
                        values,
                        setFieldError,
                      });

                      if (isValid) setExpanded('lesson');
                    }}
                    onBack={() => setExpanded('course')}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'lesson'}
                onChange={handleChange('lesson')}
              >
                <AccordionSummary
                  aria-controls='lesson-content'
                  id='lesson-header'
                >
                  <Typography>Lesson</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ py: 2 }}>
                    <FieldArray name='lessons'>
                      {({ remove, push }) => (
                        <>
                          <Typography sx={{ fontWeight: '500', mb: 1 }}>
                            Modules
                          </Typography>
                          {values.modules
                            .filter((r) => r.id && r.label)
                            .map(({ id, label }) => (
                              <Accordion
                                key={id}
                                expanded={childExpanded === id}
                                onChange={handleChildChange(id)}
                              >
                                <AccordionSummary
                                  aria-controls={`${id}-content`}
                                  id={`${id}-header`}
                                >
                                  <Typography>{label}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box sx={{ py: 2 }}>
                                    <Button
                                      variant='outlined'
                                      startIcon={<AddIcon />}
                                      sx={{ mb: '22px' }}
                                      onClick={() => {
                                        push({
                                          id: null,
                                          label: '',
                                          sequence:
                                            values?.lessons?.filter(
                                              (lesson) =>
                                                lesson?.module?.id === id
                                            ).length + 1,
                                          module: {
                                            id,
                                            label,
                                          },
                                        });
                                      }}
                                    >
                                      Add lesson
                                    </Button>

                                    <Grid container spacing={2}>
                                      {values.lessons
                                        .filter((r) => r?.module?.id === id)
                                        .map((lesson) => {
                                          const index =
                                            values.lessons.findIndex(
                                              (row) =>
                                                row?.id === lesson?.id &&
                                                row?.module?.id === id
                                            );

                                          return (
                                            <React.Fragment key={index}>
                                              <Grid item xs={2}>
                                                <TextField
                                                  label='Sequence'
                                                  name={`lessons.${index}.sequence`}
                                                />
                                              </Grid>

                                              <Grid item xs={8}>
                                                <LessonDropdown
                                                  refetch={() => {
                                                    queryClient.invalidateQueries(
                                                      [`lessons.${index}`]
                                                    );
                                                  }}
                                                >
                                                  <AutocompleteFieldV2
                                                    label='Lesson'
                                                    disableDefaultSetValue
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `lessons.${index}`,
                                                        {
                                                          ...value,
                                                          sequence:
                                                            lesson.sequence,
                                                          module: {
                                                            id,
                                                            label,
                                                          },
                                                        }
                                                      );
                                                    }}
                                                    name={`lessons.${index}`}
                                                    requestKey={`${lesson?.id}-create-cs-lesson`}
                                                    fetchUrl={lessonsUrls.list}
                                                    required
                                                  />
                                                </LessonDropdown>
                                              </Grid>

                                              <Grid item>
                                                <Button
                                                  variant='text'
                                                  color='error'
                                                  size='small'
                                                  onClick={() => remove(index)}
                                                >
                                                  Delete
                                                </Button>
                                              </Grid>
                                            </React.Fragment>
                                          );
                                        })}
                                    </Grid>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            ))}
                        </>
                      )}
                    </FieldArray>
                  </Box>

                  <StepButtons
                    nextBtnText='Create Course'
                    onNext={submitForm}
                    disabled={isSubmitting}
                    onBack={() => setExpanded('module')}
                  />
                </AccordionDetails>
              </Accordion>
            </Container>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
