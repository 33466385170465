import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import ConnectCalendars from '@pages/Calendar/ConnectCalendars';
import AllCalendars from '@pages/Calendar/AllCalendars';
import AllCalendarDetail from '@pages/Calendar/AllCalendars/detail';
import Googlecallback from '@pages/Calendar/google-callback';
import CalendarDetail from '@pages/Calendar/Calendars/detail';
import CalendarEventDetail from '@pages/Calendar/Events/detail';
import ConfigureCalendars from '@pages/Calendar/ConfigureCalendars';
import Slots from '@pages/Calendar/Slots/detail';
import SlotsRecipients from '@pages/Calendar/SlotsRecipients/detail';
import SlotLinks from '@pages/Calendar/SlotLinks';
import SlotLinksDetail from '@pages/Calendar/SlotLinks/detail';
import BatchReminders from '@pages/Calendar/BatchReminders';
import BatchRemindersDetail from '@pages/Calendar/BatchReminders/detail';

export default function () {
  return (
    <>
      <Route
        path="/connect-calendar"
        element={<WithLayout comp={ConnectCalendars} />}
      />
      <Route
        path="/all-calendars"
        element={<WithLayout comp={AllCalendars} />}
      />
      <Route
        path="/all-calendars/:id"
        element={<WithLayout comp={AllCalendarDetail} />}
      />
      <Route
        path="/google-callback"
        element={<WithLayout comp={Googlecallback} />}
      />
      <Route
        path="/calendar/:id"
        element={<WithLayout comp={CalendarDetail} />}
      />
      <Route
        path="/event/:id"
        element={<WithLayout comp={CalendarEventDetail} />}
      />
      <Route
        path="/configure-calendar"
        element={<WithLayout comp={ConfigureCalendars} />}
      />
      <Route path="/slots/:id" element={<Slots />} />
      <Route path="/slots-recipients/:id" element={<SlotsRecipients />} />

      <Route path="/slot-links" element={<WithLayout comp={SlotLinks} />} />
      <Route
        path="/slot-links/:id"
        element={<WithLayout comp={SlotLinksDetail} />}
      />

      <Route
        path="/batch-reminders"
        element={<WithLayout comp={BatchReminders} />}
      />

      <Route
        path="/batch-reminders/:id"
        element={<WithLayout comp={BatchRemindersDetail} />}
      />
    </>
  );
}
