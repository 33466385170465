import Modal from '@ui/Modal';
import { Box } from '@mui/system';
import { Typography, MenuItem } from '@mui/material';
import * as Yup from 'yup';
import Button from '@ui/Button';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SelectField from '@components/Inputs/SelectField';
import { accountUrls } from '@config/routes';
import { Form, Formik } from 'formik';

const { methodsOptions } = accountUrls;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '45%',
};

export default function PermissionPromptModal({
  open,
  onCancelClick,
  onPermissionClick,
}) {
  return (
    <Modal
      open={open}
      aria-labelledby='Permission Action'
      aria-describedby='Add Permissions'
    >
      <Box key='permission-model-box-container' sx={style}>
        <Box key='permission-model-box-header' sx={{ display: 'inline-flex' }}>
          <FontAwesomeIcon
            key='permission-model-font'
            icon={faUserShield}
            size='2x'
            color='primary'
          />
          <Typography
            key='permission-model-title'
            id='modal-modal-title'
            variant='h5'
            component='h2'
            sx={{ ml: '20px', my: 'auto' }}
          >
            Add Permissions
          </Typography>
        </Box>
        <Box key='permission-model-form-box' sx={{ mt: 2 }}>
          <Formik
            key='permission-model-formik'
            initialValues={{
              methods: [],
            }}
            validationSchema={Yup.object({
              methods: Yup.array().min(1, 'Select atleast 1 Method').required(),
            })}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              onPermissionClick(values);
              resetForm();
              setSubmitting(false);
            }}
          >
            <Form noValidate autoComplete='off'>
              <SelectField
                key='permission-model-select-field'
                multiple
                label='Methods'
                name='methods'
              >
                {methodsOptions &&
                  methodsOptions.map((o, oIdx) => (
                    <MenuItem value={o} key={oIdx.toString()}>
                      {o}
                    </MenuItem>
                  ))}
              </SelectField>
              <Box
                key='permission-model-button-box-container'
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: '20px',
                }}
              >
                <Button
                  key='permission-model-cancel-button'
                  variant='outlined'
                  color='primary'
                  label='Cancel'
                  size='large'
                  onClick={onCancelClick}
                />
                <Button
                  key='permission-model-submit-button'
                  variant='contained'
                  type='submit'
                  color='primary'
                  label='Submit'
                  size='large'
                  sx={{ marginLeft: '9px' }}
                />
              </Box>
            </Form>
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
}
