import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import useToast from '@hooks/useToast';
import {
  TextField,
  AutocompleteFieldV2,
  DateTimePickerField,
  CheckBoxField,
} from '@components/Inputs';
import { calendarUrls } from '@config/routes';
import EmailTemplateAccordion from '@components/BPA-V2/Popups/EmailTemplateConfigurationPopup/EmailTemplateAccordion';
import {
  getSlotFields,
  handleAccordionItemDelete,
} from '@config/functions/emailTemplateConfigurationPopupFunctions';

const cardStyles = {
  p: 2,
  borderRadius: '4px',
  backgroundColor: '#f7f7f7',
  border: '1px solid #c8c8c8',
};

const { calendarsListUrls } = calendarUrls;

export default function EmailTemplateSlotLinkCard({
  values,
  index,
  setFieldValue,
  remove,
  errors,
  isKanbanView,
}) {
  const [notify] = useToast();
  const [isDeleting, setDeleting] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => setOpen(!open);

  const fields = getSlotFields(index);
  const afterDefaultOnChange = (value, fieldName) => {
    const newCalendarSlots = [...values.calendarSlots];
    newCalendarSlots[index][fieldName] = value;
    setFieldValue('calendarSlots', newCalendarSlots);
  };

  const renderCalendar = (row) => {
    const { id, name } = row ?? {};

    return {
      label: name,
      static_value: id,
      mapping_field_name: null,
    };
  };

  return (
    <EmailTemplateAccordion
      open={open}
      setOpen={handleToggle}
      isError={errors?.calendarSlots?.[index] ?? false}
      label={`Calendar Slot ${index + 1}`}
      endContent={() => (
        <Button
          variant="text"
          color="error"
          size="small"
          disabled={isDeleting || isKanbanView}
          onClick={() => {
            handleAccordionItemDelete({
              setDeleting,
              notify,
              remove,
              index,
            });
          }}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </Button>
      )}
    >
      <Box
        sx={{
          ...cardStyles,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Name"
              name={fields?.name}
              multiline
              required
              afterDefaultOnChange={(e) =>
                afterDefaultOnChange(
                  {
                    label: e.target.value,
                    static_value: e.target.value,
                    mapping_field_name: null,
                  },
                  fields?.name
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteFieldV2
              idKey="static_value"
              label="Calendar"
              name={fields?.calendar}
              requestKey="emailTemplateSetupPopupSlotCalendars"
              fetchUrl={calendarsListUrls.list}
              renderRow={renderCalendar}
              onChange={(newValue) =>
                afterDefaultOnChange(newValue, fields?.calendar)
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              name={fields?.description}
              minRows={2}
              multiline
              afterDefaultOnChange={(e) =>
                afterDefaultOnChange(
                  {
                    label: e.target.value,
                    static_value: e.target.value,
                    mapping_field_name: null,
                  },
                  fields?.description
                )
              }
            />
          </Grid>
          <Grid item xs={3}>
            <DateTimePickerField
              label="Begin"
              showTime
              showTimezone
              name={fields?.start_date_time}
              setTimezone={(v) => {
                afterDefaultOnChange(
                  {
                    label: v,
                    static_value: v,
                    mapping_field_name: null,
                  },
                  fields?.timezone
                );
              }}
              afterDefaultOnChange={(newDate) =>
                afterDefaultOnChange(
                  {
                    label: newDate,
                    static_value: newDate,
                    mapping_field_name: null,
                  },
                  fields?.start_date_time
                )
              }
              required
            />
          </Grid>
          <Grid item xs={3}>
            <DateTimePickerField
              label="End"
              name={fields?.end_date_time}
              showTime
              showTimezone
              setTimezone={(v) => {
                afterDefaultOnChange(
                  {
                    label: v,
                    static_value: v,
                    mapping_field_name: null,
                  },
                  fields?.timezone
                );
              }}
              afterDefaultOnChange={(newDate) =>
                afterDefaultOnChange(
                  {
                    label: newDate,
                    static_value: newDate,
                    mapping_field_name: null,
                  },
                  fields?.end_date_time
                )
              }
              required
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name={fields?.slot_duration}
              label="Duration (Mins)"
              type="number"
              afterDefaultOnChange={(e) =>
                afterDefaultOnChange(
                  {
                    label: e.target.value,
                    static_value: e.target.value,
                    mapping_field_name: null,
                  },
                  fields?.slot_duration
                )
              }
              required
              number
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              name={fields?.buffer_between_slots}
              label="Buffer (Mins)"
              type="number"
              afterDefaultOnChange={(e) =>
                afterDefaultOnChange(
                  {
                    label: e.target.value,
                    static_value: e.target.value,
                    mapping_field_name: null,
                  },
                  fields?.buffer_between_slots
                )
              }
              required
              number
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name={fields?.link}
              label="Conference Link"
              afterDefaultOnChange={(e) =>
                afterDefaultOnChange(
                  {
                    label: e.target.value,
                    static_value: e.target.value,
                    mapping_field_name: null,
                  },
                  fields?.link
                )
              }
              required
            />
          </Grid>

          {isKanbanView ? null : (
            <Grid item xs={6}>
              <CheckBoxField
                label="Relative?"
                name={fields?.relative}
                customSetValue={(newValue) =>
                  afterDefaultOnChange(
                    {
                      label: `${newValue}`,
                      static_value: newValue,
                      mapping_field_name: null,
                    },
                    fields?.relative
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </EmailTemplateAccordion>
  );
}
