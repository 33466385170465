import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { communicationUrls, accountUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import DetailCard from '@components/core/DetailCard';
import { emailRowsMap } from '@config/handleRows/communication';
import { labelColumns } from '@config/meta/communication';
import DetailPageHeader from '@components/DetailPageHeader';
import { microservices } from '@config/constants';
import AuthWrapper from '@components/Communication/AuthWrapper';
import useAutocomplete from '@hooks/useAutocomplete';
import { useQueryClient } from 'react-query';

const { rolesUrls, usersUrls } = accountUrls;
const { notificationsUrls, labelsUrls } = communicationUrls;

function LabelDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [roleTerm, setRoleTerm] = useState('');
  const [userTerm, setUserTerm] = useState('');
  const queryClient = useQueryClient();

  const { data: roles, isFetching: fetchingRoles } = useAutocomplete({
    key: [
      'label-roles-list',
      user.details.encryption_public_key,
      user.encryptionToken,
      user.token,
      user?.actAs,
    ],
    getUrl: rolesUrls.list,
    inputValue: roleTerm,
  });

  const { data: users, isFetching: fetchingUsers } = useAutocomplete({
    key: [
      'label-users-list',
      user.details.encryption_public_key,
      user.encryptionToken,
      user.token,
      user?.actAs,
    ],
    getUrl: usersUrls.list,
    inputValue: userTerm,
    renderRow: (r) => ({
      id: r.id,
      label: r.email,
    }),
  });

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      labelColumns({
        data,
        queryClient,
        roles,
        setRoleTerm,
        rolesIsFetching: fetchingRoles,
        users,
        setUserTerm,
        usersIsFetching: fetchingUsers,
      }),
    [roles, setRoleTerm, fetchingRoles, users, setUserTerm, fetchingUsers]
  );

  const emailsColOptions = React.useMemo(() => {
    return { allEmails: true };
  }, []);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/labels`, text: 'Labels' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={labelsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }} key="detail-view-childs">
        <DetailAccordionView
          key="detail-view-accordion"
          microservice={microservices.NOTIFICATION.name}
          model={microservices.NOTIFICATION.models.notification}
          columnKey="emailCommunication"
          columnOptions={emailsColOptions}
          label="Emails"
          prefix="05l"
          labels={labels}
          editMode={editMode}
          urls={notificationsUrls}
          hideActions
          handleRowMap={emailRowsMap}
          urlParams={`&label=${recordId}`}
        />
      </Box>
    </Box>
  );
}

export default function AuthenticatedLabelDetails(props) {
  return (
    <AuthWrapper>
      <LabelDetails {...props} />
    </AuthWrapper>
  );
}
