import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Books from '@pages/Wiki/Books';
import BookDetail from '@pages/Wiki/Books/detail';
import ChapterDetail from '@pages/Wiki/Chapters/detail';
import SectionDetail from '@pages/Wiki/Sections/detail';
import BookCategories from '@pages/Wiki/BookCategories';
import Tags from '@pages/Wiki/Tags';
import WikiDetail from '@pages/Wiki/Wikies/detail';




export default function () {
  return (
    <>
      <Route path='/books' element={<WithLayout comp={Books} />} />
      <Route path='/books/:id' element={<WithLayout comp={BookDetail} />} />
      <Route path='/chapters/:id' element={<WithLayout comp={ChapterDetail} />} />
      <Route path='/section/:id' element={<WithLayout comp={SectionDetail} />} />
      <Route path='/book-categories' element={<WithLayout comp={BookCategories} />} />
      <Route path='/tags' element={<WithLayout comp={Tags} />} />
      <Route path='/wiki/:id' element={<WithLayout comp={WikiDetail} />} />
    </>
  );
}
