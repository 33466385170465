export const microserviceRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  version: row?.version,
  description: row?.description,
  tags: row?.tags,
  createdAt: row?.createdAt,
});

export const computeLogsRowsMap = (row) => ({
  id: row?.id,
  status: row?.status,
  duration: row?.duration,
  apiGitRepoUrl: row?.apiGitRepoUrl,
  feGitRepoUrl: row?.feGitRepoUrl,
  failureReason: row?.failureReason,
  devopsGitRepoUrl: row?.devopsGitRepoUrl,
  erdUrl: row?.erdUrl,
  tags: row?.tags,
  createdAt: row?.createdAt,
});

export const enumsRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  description: row?.description,
  tags: row?.tags,
  createdAt: row?.createdAt,
});

export const enumValuesRowsMap = (row) => ({
  id: row?.id,
  value: row?.value,
  description: row?.description,
  tags: row?.tags,
  createdAt: row?.createdAt,
});

export const modelsRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  label: row?.label,
  description: row?.description,
  helpfulHint: row?.helpfulHint,
  useFormFlow: row?.useFormFlow,
  tags: row?.tags,
  parentMenuId: row?.displayValue
    ? {
        id: row?.parentMenuId,
        label: row?.displayValue?.name ?? '',
      }
    : null,
  createdAt: row?.createdAt,
});

export const menuDefnRowsMap = (row) => ({
  id: row?.id,
  order: row?.order,
  tags: row?.tags,
  modelId: row?.model
    ? {
        id: row?.modelId,
        label: row?.model?.name ?? '',
      }
    : null,
  createdAt: row?.createdAt,
});

export const formFlowsRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  type: row?.type,
  createdAt: row?.createdAt,
});

export const formFlowStepsRowsMap = (row) => ({
  id: row?.id,
  order: row?.order,
  name: row?.name,
  createdAt: row?.createdAt,
});

export const formFieldLayoutsRowsMap = (row) => ({
  id: row?.id,
  order: row?.order,
  fieldDefnId: row?.fieldDefn
    ? {
        id: row?.fieldDefnId,
        label: row?.fieldDefn?.name ?? '',
      }
    : null,
  colSpan: row?.colSpan,
  createdAt: row?.createdAt,
});

export const fieldRowsMap = (row) => ({
  id: row?.id,
  order: row?.order,
  name: row?.name,
  isMultiline: row?.isMultiline,
  isClickableLink: row?.isClickableLink,
  isEditable: row?.isEditable,
  showInDetailCard: row?.showInDetailCard,
  label: row?.label,
  onDelete: row?.onDelete,
  description: row?.description,
  helpfulHint: row?.helpfulHint,
  dataType: row?.dataType,
  isForeignKey: row?.isForeignKey,
  showInTable: row?.showInTable,
  isOptional: row?.isOptional,
  isUnique: row?.isUnique,
  isIndex: row?.isIndex,
  defaultValue: row?.defaultValue,
  enumDefnId: row?.enumDefnId
    ? {
        id: row?.enumDefnId,
        label: row?.enumDefn?.name ?? '',
      }
    : null,
  modelId: row?.modelId,
  minLength: row?.minLength,
  maxLength: row?.maxLength,
  tags: row?.tags,
  foreignKeyMicroserviceId: row?.foreignKeyMicroserviceId
    ? {
        id: row?.foreignKeyMicroserviceId,
        label: row?.foreignKeyMicroservice?.name ?? '',
      }
    : null,
  foreignKeyModelId: row?.foreignKeyModelId
    ? { id: row?.foreignKeyModelId, label: row?.foreignKeyModel?.name ?? '' }
    : null,
  createdAt: row?.createdAt,
});

export const blockGroupRowsMap = (row) => ({
  id: row?.id,
  order: row?.order,
  name: row?.name,
  description: row?.description,
  tags: row?.tags,
  createdAt: row?.createdAt,
});

export const blockRowsMap = (row) => ({
  id: row?.id,
  order: row?.order,
  name: row?.name,
  description: row?.description,
  code: row?.code,
  tags: row?.tags,
  createdAt: row?.createdAt,
});
