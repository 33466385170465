import { Divider, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/system';

export default function ProfileCard({ title, action, children }) {
  return (
    <Card sx={{ minwidth: 275 }}>
      <Grid
        key='profile-card-grid-container'
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {title ? (
          <>
            <Grid item xs={4} sm={8} md={6}>
              <Typography
                key='profile-card-grid-typography'
                variant='h5'
                sx={{ fontWeight: 'medium', ml: 2, mt: 0.5 }}
                gutterBottom
                component='div'
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={6}>
              <Box
                key='profile-card-grid-item-box'
                sx={{ textAlign: 'right', mt: 1 }}
              >
                {action}
              </Box>
            </Grid>
          </>
        ) : null}
      </Grid>

      <Divider key='profile-card-divider' />
      <CardContent key='profile-card-card-content'>{children}</CardContent>
    </Card>
  );
}
