import React from 'react';
import useToast from '@hooks/useToast';
import { Box, Typography } from '@mui/material';
import { colors } from '@config/constants';
import INADialog from './INADialog';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { candidateAdminMeta } from '@config/meta/hr/';
import SendEmailDialog from '@components/hr/SendEmailDialog';
import MoveToLastManualStagePopup from '@components/BPA-V2/Popups/MoveToLastManualStagePopup';
import DeleteWorkflowInstancePopup from '@components/BPA-V2/Popups/DeleteWorkflowInstancePopup';
import AddTaskDialog from '@components/PM/AddTaskDialogBox';
import AddBugToSprintDialogBox from '@components/PM/AddBugToSprintDialogBox';
import { useQueryClient } from 'react-query';
import { selectProfile } from '@redux/profileSlice';
import ShowAssetsPopup from '@components/BPA-V2/Popups/ShowAssetsPopup';
import { useSelector } from 'react-redux';
import PerformManualTaskPopup from '@components/BPA-V2/ManualActions/PerformManualTaskPopup';
import { createRecord } from '@config/functions/requests';
import { bpaUrls, crmUrls } from '@config/routes';
import {
  formatToUTCDateTime,
  isSuperUserOrAutomataAdmin,
} from '@config/functions/helperFunctions';
import { toggleStageVisibility } from '@config/functions/bpaFunctions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { selectBpaBoard } from '@redux/bpaBoardSlice';

const ITEM_HEIGHT = 48;

const {
  moveToNextStageUrls,
  bulkDeleteWorkflowInstancesUrls,
  bulkMoveToLastManualStageUrls,
} = bpaUrls;
const { resetRottingDaysUrls } = crmUrls;
const { resetRottingDaysManualWorkflowUrls } = bpaUrls;

const getVacancy = (data) => {
  if (!Array.isArray(data) || !data.length) {
    return {};
  }

  const [first] = data;

  if (first?.details?.vacancy) {
    return { ...first?.details?.vacancy, id: first.vacancy };
  }

  return {};
};

const getINAMicroservice = (detailLink) => {
  if (detailLink === 'candidates') {
    return 'HR';
  }
  if (detailLink === 'opportunities') {
    return 'CRM';
  }

  return 'PM';
};

const getINALinkName = (detailLink) => {
  if (detailLink === 'candidates') {
    return `${window.location.origin}/vacancies/`;
  }

  if (detailLink === 'opportunities') {
    return `${window.location.origin}/opportunities/`;
  }

  if (detailLink === 'tasks') {
    return `${window.location.origin}/tasks/`;
  }
};

const getINAModelName = (detailLink) => {
  if (detailLink === 'candidates') {
    return 'Candidate';
  }
  if (detailLink === 'opportunities') {
    return 'Opportunity';
  }

  return 'Task';
};

export default function KanbanListHeader({
  id,
  title,
  count,
  detailLink,
  data,
  workflowId,
  manualColor,
  customColor = null,
  setStageStates,
  sprintMeta,
  order,
  childStages,
  stageStates,
  ...rest
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = React.useState(false);
  const [showSendEmail, setShowSendEmail] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [
    disableBPAMoveToLastManualStageBtn,
    setDisableBPAMoveToLastManualStageBtn,
  ] = React.useState(false);
  const [showBPAMoveToLastManualStage, setShowBPAMoveToLastManualStage] =
    React.useState(false);
  const [
    disableBPADeleteWorkflowInstancesBtn,
    setDisableBPADeleteWorkflowInstancesBtn,
  ] = React.useState(false);
  const [showBPADeleteWorkflowInstances, setShowBPADeleteWorkflowInstances] =
    React.useState(false);
  const [showBPAPerformManualTaskPopup, setShowBPAPerformManualTaskPopup] =
    React.useState(false);
  const [showAddTaskPopup, setShowAddTaskPopup] = React.useState(false);
  const [showAddBugToSprintPopup, setShowAddBugToSprintPopup] =
    React.useState(false);
  const [showAssetsPopup, setShowAssetsPopup] = React.useState(false);
  const { workflows: workflowsState } = useSelector(selectBpaBoard);

  const manualStageIds = workflowsState
    .filter((stage) => stage.manual)
    .map((stage) => stage.id);

  // Filter out manual stages from childStages
  const nonManualStages = (childStages ?? []).filter(
    (stageId) => !manualStageIds.includes(stageId)
  );

  const open = Boolean(anchorEl);
  const emailTemplate = rest?.details?.email_template;

  // const MANUAL_ACTIONS = [
  //   'send_email',
  //   'capture_data',
  //   'send_email_v2',
  //   'is_true',
  // ];

  const onRotUpdate = async (id, detailLink) => {
    if (detailLink === 'opportunities') {
      try {
        await createRecord({
          values: {
            stage: id,
            status_assigned_date: formatToUTCDateTime(new Date()),
          },
          url: resetRottingDaysUrls.list(),
          token: user.token,
          actAs: user?.actAs,
        });
        setAnchorEl(null);
        queryClient.invalidateQueries(['opportunities-board']);
        notify('Operating completed', {
          type: 'SUCCESS',
        });
      } catch (error) {
        console.log(error?.response?.data ?? error?.message);
        notify('Something went wrong, try refreshing', {
          type: 'ERROR',
        });
      }
    }

    if (detailLink === 'manual-bpa-board') {
      try {
        await createRecord({
          values: {
            stage: id,
            stage_assigned_date: formatToUTCDateTime(new Date()),
          },
          url: resetRottingDaysManualWorkflowUrls.list(),
          token: user.token,
          actAs: user?.actAs,
        });
        setAnchorEl(null);
        queryClient.invalidateQueries(['manual-bpa-board']);
        notify('Operating completed', {
          type: 'SUCCESS',
        });
      } catch (error) {
        console.log(error?.response?.data ?? error?.message);
        notify('Something went wrong, try refreshing', {
          type: 'ERROR',
        });
      }
    }
  };

  const toggleSubStages = (visible) => {
    // Pass non-manual stages to toggleStageVisibility
    toggleStageVisibility(nonManualStages, visible, setStageStates);
  };

  const areAllNonManualStagesVisible = () => {
    // Assuming stageStates is the current state of all stages
    const nonManualStagesVisibility = stageStates.filter((stage) =>
      nonManualStages.includes(stage.id)
    );

    // Check if all non-manual stages are visible
    return nonManualStagesVisibility.some((stage) => stage.visible);
  };

  const toggleButtonStyles = {
    position: 'absolute',
    right: '-4%',
    cursor: 'pointer',
    fontSize: '1.95rem',
    color: '#3367d6',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        pt: '6px',
        pb: '8px',
        px: '0.8rem',
        bgcolor: manualColor ? '#FFBF00' : customColor ?? '#dfdfdf',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        position: 'relative',
      }}
    >
      {nonManualStages?.length ? (
        <React.Fragment>
          {areAllNonManualStagesVisible() ? (
            <RemoveCircleIcon
              style={toggleButtonStyles}
              onClick={() => toggleSubStages(false)}
            />
          ) : (
            <AddCircleIcon
              style={toggleButtonStyles}
              onClick={() => toggleSubStages(true)}
            />
          )}
        </React.Fragment>
      ) : null}

      <Typography
        component='div'
        sx={{
          color: colors.dark,
          fontWeight: '500',
          mr: 1.5,
        }}
      >
        {order} {title} | {count}{' '}
        {rest?.duration_estimate ? `| ${rest?.duration_estimate}h` : ''}
      </Typography>

      {detailLink === 'tasks' ||
      detailLink === 'candidates' ||
      detailLink === 'opportunities' ||
      detailLink === 'bugs' ||
      detailLink === 'manual-bpa-board' ? (
        <Box sx={{ ml: 'auto' }}>
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            size='small'
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon fontSize='inherit' />
          </IconButton>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {/* <MenuItem size='small' onClick={() => setShowModal(true)}>
              Add INA
            </MenuItem> */}
            {detailLink === 'bugs' ? (
              <MenuItem
                size='small'
                onClick={() => setShowAddBugToSprintPopup(true)}
              >
                Add To Sprint
              </MenuItem>
            ) : null}

            {/* {detailLink === 'tasks' ? (
              <MenuItem size='small' onClick={() => setShowAddTaskPopup(true)}>
                Add Task
              </MenuItem>
            ) : null} */}

            {detailLink === 'candidates' ? (
              <MenuItem size='small' onClick={() => setShowSendEmail(true)}>
                Send email
              </MenuItem>
            ) : null}
            {detailLink === 'opportunities' ? (
              <MenuItem
                size='small'
                onClick={() => onRotUpdate(id, detailLink, setAnchorEl)}
              >
                Reset Rotting Days
              </MenuItem>
            ) : null}
            {detailLink === 'manual-bpa-board' ? (
              <MenuItem
                size='small'
                onClick={() => onRotUpdate(id, detailLink, setAnchorEl)}
              >
                Reset Rotting Days
              </MenuItem>
            ) : null}
          </Menu>

          {showAddTaskPopup ? (
            <AddTaskDialog
              open={showAddTaskPopup}
              setOpen={setShowAddTaskPopup}
              recordId={id}
              sprintMeta={sprintMeta}
            />
          ) : null}

          {showAddBugToSprintPopup ? (
            <AddBugToSprintDialogBox
              open={showAddBugToSprintPopup}
              setOpen={setShowAddBugToSprintPopup}
              bugs={data}
            />
          ) : null}

          {showSendEmail ? (
            <SendEmailDialog
              multiple
              open={showSendEmail}
              setOpen={setShowSendEmail}
              vacancy={getVacancy(data)}
              initialTemplate={
                emailTemplate
                  ? { id: emailTemplate?.id, label: emailTemplate?.name }
                  : null
              }
              kanbanStatus={id}
              meta={candidateAdminMeta({
                data: data?.length ? data[0] : {},
                queryClient,
              })}
              callback={() => {
                queryClient.invalidateQueries(['vacancies-board']);
              }}
            />
          ) : null}

          {showModal ? (
            <INADialog
              open={showModal}
              recordId={id}
              modelName={getINAModelName(detailLink)}
              msName={getINAMicroservice(detailLink)}
              link={getINALinkName(detailLink)}
              setOpen={setShowModal}
            />
          ) : null}
        </Box>
      ) : detailLink === 'bpa-board' ? (
        <Box sx={{ ml: 'auto' }}>
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            size='small'
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon fontSize='inherit' />
          </IconButton>

          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '24ch',
              },
            }}
          >
            {isSuperUserOrAutomataAdmin(user) &&
            rest?.shape_type === 'delay' ? (
              <>
                <MenuItem
                  size='small'
                  sx={{ whiteSpace: 'normal' }}
                  disabled={!Array.isArray(data) || !data?.length}
                  onClick={async () => {
                    try {
                      await createRecord({
                        values: {
                          workflow: workflowId,
                          delay_shape: rest?.shape_id,
                          workflow_instances: Array.isArray(data)
                            ? data.map((row) => row?.id)
                            : [],
                        },
                        url: moveToNextStageUrls.list(),
                        token: user.token,
                        actAs: user?.actAs,
                      });
                      queryClient.invalidateQueries(['bpa-board']);
                      notify('Operation completed', {
                        type: 'SUCCESS',
                      });
                    } catch (error) {
                      notify('There was an error, please try again', {
                        type: 'ERROR',
                      });
                    }
                  }}
                >
                  Move Forward From Delay
                </MenuItem>
              </>
            ) : null}

            {rest?.manual ? (
              <>
                <MenuItem
                  size='small'
                  disabled={!Array.isArray(data) || !data?.length}
                  onClick={() => {
                    setShowBPAPerformManualTaskPopup(true);
                  }}
                >
                  Perform Manual Task
                </MenuItem>
              </>
            ) : (
              <MenuItem
                size='small'
                onClick={() => {
                  toggleStageVisibility([id], false, setStageStates);
                }}
              >
                Hide Stage
              </MenuItem>
            )}

            {isSuperUserOrAutomataAdmin(user) ? (
              <MenuItem
                size='small'
                disabled={!Array.isArray(data) || !data?.length}
                onClick={() => {
                  setShowBPAMoveToLastManualStage(true);
                }}
              >
                Move to Previous Stage
              </MenuItem>
            ) : null}

            <MenuItem
              size='small'
              disabled={!Array.isArray(data) || !data?.length}
              onClick={() => {
                setShowBPADeleteWorkflowInstances(true);
              }}
            >
              Move to Trash
            </MenuItem>

            <MenuItem size='small' onClick={() => setShowAssetsPopup(true)}>
              Show Assets
            </MenuItem>

            {showBPAMoveToLastManualStage ? (
              <MoveToLastManualStagePopup
                open={!!showBPAMoveToLastManualStage}
                setOpen={setShowBPAMoveToLastManualStage}
                env='Kanban'
                disableBPAMoveToLastManualStageBtn={
                  disableBPAMoveToLastManualStageBtn
                }
                setDisableBPAMoveToLastManualStageBtn={
                  setDisableBPAMoveToLastManualStageBtn
                }
                onMoveToLastManualStageClick={async () => {
                  try {
                    setDisableBPAMoveToLastManualStageBtn(true);
                    await createRecord({
                      values: {
                        workflow: workflowId,
                        shape: rest?.shape_id,
                        workflow_instances: Array.isArray(data)
                          ? data.map((row) => row?.id)
                          : [],
                      },
                      url: bulkMoveToLastManualStageUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                    });
                    queryClient.invalidateQueries(['bpa-board']);
                    notify('Operation completed', {
                      type: 'SUCCESS',
                    });
                    setShowBPAMoveToLastManualStage(false);
                  } catch (error) {
                    notify('There was an error, please try again', {
                      type: 'ERROR',
                    });
                  } finally {
                    setDisableBPAMoveToLastManualStageBtn(false);
                  }
                }}
              />
            ) : null}

            {showBPADeleteWorkflowInstances ? (
              <DeleteWorkflowInstancePopup
                open={!!showBPADeleteWorkflowInstances}
                setOpen={setShowBPADeleteWorkflowInstances}
                env='Kanban'
                disableBPADeleteWorkflowInstancesBtn={
                  disableBPADeleteWorkflowInstancesBtn
                }
                setDisableBPADeleteWorkflowInstancesBtn={
                  setDisableBPADeleteWorkflowInstancesBtn
                }
                onDeleteWorkflowInstancesClick={async () => {
                  try {
                    setDisableBPADeleteWorkflowInstancesBtn(true);
                    await createRecord({
                      values: {
                        workflow: workflowId,
                        workflow_instances: Array.isArray(data)
                          ? data.map((row) => row?.id)
                          : [],
                      },
                      url: bulkDeleteWorkflowInstancesUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                    });
                    queryClient.invalidateQueries(['bpa-board']);
                    notify('Operation completed', {
                      type: 'SUCCESS',
                    });
                    setShowBPADeleteWorkflowInstances(false);
                  } catch (error) {
                    notify('There was an error, please try again', {
                      type: 'ERROR',
                    });
                  } finally {
                    setDisableBPADeleteWorkflowInstancesBtn(false);
                  }
                }}
              />
            ) : null}

            {showAssetsPopup ? (
              <ShowAssetsPopup
                open={!!showAssetsPopup}
                setOpen={setShowAssetsPopup}
                stageId={id}
                env='Kanban'
              />
            ) : null}

            {showBPAPerformManualTaskPopup ? (
              <PerformManualTaskPopup
                open={!!showBPAPerformManualTaskPopup}
                setOpen={setShowBPAPerformManualTaskPopup}
                workflowId={workflowId}
                workflowInstances={
                  Array.isArray(data) ? data.map((row) => row?.id) : []
                }
                stage={rest}
                user={user}
                isFromKanbanHeader
              />
            ) : null}
          </Menu>
        </Box>
      ) : null}
    </Box>
  );
}
