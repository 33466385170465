import * as React from 'react';
import { toast } from 'react-toastify';

export default function useToast() {
  const notify = React.useCallback((msg, options = {}) => {
    const type = options.type || 'DEFAULT';
    const toastId = Date.now();
    const autoClose = options.autoClose || 3000;

    toast.dark(msg, {
      toastId,
      autoClose,
      isLoading: !!options.loading,
      type: toast.TYPE[type],
    });

    return toastId;
  }, []);

  const update = React.useCallback(
    (toastId, msg, options = {}) => {
      const type = options.type || 'SUCCESS';

      toast.update(toastId, {
        render: msg,
        type: toast.TYPE[type],
        autoClose: 3000,
        isLoading: false,
      });
    },
    [toast]
  );

  return [notify, update];
}
