import React from 'react';
import { Container, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

export default function ComingSoon() {
  return (
    <Container
      maxWidth='sm'
      style={{ textAlign: 'center', paddingTop: '100px' }}
    >
      <ConstructionIcon style={{ fontSize: 80, color: 'grey' }} />
      <Typography variant='h2' gutterBottom>
        Coming Soon
      </Typography>
      <Typography variant='body1' color='textSecondary'>
        This section is under construction and will be available soon.
      </Typography>
    </Container>
  );
}
