import { marketingV2Host } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  criteria: 'criteria',
  marketSegments: 'market-segments',
  landingPageMarketSegments: 'landing-page-market-segments',
};

const criteriaUrls = getModelUrls(
  marketingV2Host,
  models.criteria,
  false,
  'v1'
);

const marketSegmentsUrls = getModelUrls(
  marketingV2Host,
  models.marketSegments,
  false,
  'v1'
);

const landingPageMarketSegmentsUrls = getModelUrls(
  marketingV2Host,
  models.landingPageMarketSegments,
  false,
  'v1'
);

const urls = {
  criteriaUrls,
  marketSegmentsUrls,
  landingPageMarketSegmentsUrls,
};

export default urls;
