import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography, Stack } from '@mui/material';

export default function SocialMediaAccountVisibility({
  isSubmitting,
  handleClick,
}) {
  return (
    <Stack
      spacing={2}
      sx={{
        width: '712px',
        background: '#fff',
        border: '1px solid rgb(184, 184, 184)',
        borderRadius: '4px',
        p: '2rem',
      }}
    >
      <Typography
        variant='h3'
        sx={{
          fontSize: '32px',
          lineHeight: '44px',
          fontWeight: '600',
          textAlign: 'left',
        }}
      >
        Would you like to set your account to private or public?
      </Typography>
      <Typography>
        If you keep your account public, marketing may have the ability to post
        using your account. With a private account, only you will have access to
        post and share content
      </Typography>

      <Box sx={{ textAlign: 'right' }}>
        <LoadingButton
          variant='outlined'
          onClick={() =>
            handleClick({
              accountPrivate: true,
            })
          }
          loading={isSubmitting}
          loadingIndicator='Loading…'
        >
          <span>Make Account Private</span>
        </LoadingButton>

        <LoadingButton
          sx={{ ml: 2 }}
          variant='outlined'
          loading={isSubmitting}
          loadingIndicator='Loading…'
          onClick={() =>
            handleClick({
              accountPrivate: false,
            })
          }
        >
          <span>Keep Account Public</span>
        </LoadingButton>
      </Box>
    </Stack>
  );
}
