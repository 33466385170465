import React from 'react';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { crmUrls, nodeDriveUrls } from '@config/routes';
import { OpportunityHistoryForm } from '@config/forms/crm';
import { createRecord } from '@config/functions/requests';
import { opportunityHistoryRowsMap } from '@config/handleRows/crm';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { filesUrls } = nodeDriveUrls;
const { opportunityHistoryUrls } = crmUrls;

export default function OpportunityNotesAccordion({
  recordId,
  editMode,
  labels,
} = {}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  return (
    <DetailAccordionView
      microservice={microservices.CRM.name}
      model={microservices.CRM.models.opportunityHistory}
      columnKey="opportunityHistoryCRM"
      label="Notes"
      editMode={editMode}
      labels={labels}
      prefix="0r1"
      urls={opportunityHistoryUrls}
      addNewForm={{
        getFields: ({ values, errors }) =>
          OpportunityHistoryForm({ values, errors }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            let file = '';

            const { notes, url, ...rest } = values;

            if (url) {
              const formData = new FormData();
              formData.append('anonymous_can_see_it', true);
              formData.append('file', url);

              const { data: fileData } = await createRecord({
                values: formData,
                url: filesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              file = fileData.fileUrl;
            }

            const response = await createRecord({
              values: {
                url: file,
                opportunity: recordId,
                notes: notes,
                ...rest,
              },
              url: opportunityHistoryUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });

            resetForm();
            setAddNewForm(false);
            refetch();
            return response?.data?.id;
          } catch (err) {
            console.log(err.response || err.message);
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      }}
      handleRowMap={opportunityHistoryRowsMap}
      urlParams={`&opportunity=${recordId}`}
    />
  );
}
