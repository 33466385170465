import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { CheckBoxField, AutocompleteFieldV2 } from '@components/Inputs';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RenderUiPrefix from '@components/core/RenderUiPrefix';
import { accountUrls } from '@config/routes';

const { usersUrls, rolesUrls } = accountUrls;

export default function VisibilityPopupNode({
  createdBy,
  user,
  prefix,
  labels,
  editMode,
  open,
  setOpen,
  initialValues,
  handleSave,
}) {
  const isOwner = createdBy ? createdBy === user?.details?.id : true;

  return (
    <Dialog fullWidth maxWidth='sm' open={open}>
      {prefix ? (
        <RenderUiPrefix
          editMode={editMode}
          labels={labels}
          code={prefix}
          defaultValue='Visibility'
        >
          {(text) => <DialogTitle>{text}</DialogTitle>}
        </RenderUiPrefix>
      ) : (
        <DialogTitle>Visibility</DialogTitle>
      )}
      <DialogContent>
        <Formik
          initialValues={{
            everyone_can_see_it: initialValues?.everyone_can_see_it ?? false,
            anonymous_can_see_it: initialValues?.anonymous_can_see_it ?? false,
            everyone_in_object_company_can_see_it:
              initialValues?.everyone_in_object_company_can_see_it ?? true,
            only_these_roles_can_see_it:
              initialValues?.only_these_roles_can_see_it ?? [],
            only_these_users_can_see_it:
              initialValues?.only_these_users_can_see_it ?? [],
          }}
          validationSchema={Yup.object({
            everyone_can_see_it: Yup.bool(),
            anonymous_can_see_it: Yup.bool(),
            everyone_in_object_company_can_see_it: Yup.bool(),
            only_these_roles_can_see_it: Yup.object().default([]).nullable(),
            only_these_users_can_see_it: Yup.object().default([]).nullable(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true);

              if (handleSave) {
                handleSave(values);
              }

              setOpen(false);
            } catch (error) {
              console.log(error?.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit, isSubmitting, errors }) => (
            <Form noValidate autoComplete='off'>
              <Stack spacing={2}>
                <Box>
                  <CheckBoxField
                    label='Everyone can see'
                    name='everyone_can_see_it'
                    disabled={!isOwner}
                  />
                </Box>

                <Box>
                  <CheckBoxField
                    label='Only anonymous users can see'
                    name='anonymous_can_see_it'
                    disabled={!isOwner}
                  />
                </Box>

                <Box>
                  <CheckBoxField
                    label='Everyone in this company can see'
                    name='everyone_in_object_company_can_see_it'
                    disabled={!isOwner}
                  />
                </Box>

                <Box>
                  <AutocompleteFieldV2
                    name='only_these_roles_can_see_it'
                    label='Specific Roles Access'
                    requestKey='visibility-roles-list'
                    fetchUrl={rolesUrls.list}
                    disabled={!isOwner}
                    multiple
                  />
                </Box>

                <Box>
                  <AutocompleteFieldV2
                    name='only_these_users_can_see_it'
                    label='Specific Users Access'
                    requestKey='visibility-users-list'
                    fetchUrl={usersUrls.list}
                    urlParams='&queryCode=fb0c'
                    disabled={!isOwner}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: row?.email,
                    })}
                    multiple
                  />
                </Box>
              </Stack>

              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                {isOwner ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </Button>
                ) : null}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
