import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { formsUrls } from '@config/routes';
import { groupMeta } from '@config/meta/forms/formsMeta';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { groupRowsMap, questionGroupRowsMap } from '@config/handleRows/forms';
import { GroupForm, QuestionGroupForm } from '@config/forms/forms';
import { microservices } from '@config/constants';

const { groupsUrls, questionGroupsUrls } = formsUrls;

export default function GroupDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [groupListData, setGroupListData] = React.useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => groupMeta({ data, formId: data?.formId }),
    []
  );

  const questionColOptions = React.useMemo(() => {
    return {
      hideGroup: true,
      hideQuestion: false,
      formId: recordData?.formId,
    };
  }, [recordData?.formId]);

  const subGroupColOptions = React.useMemo(
    () => ({
      formId: recordData?.formId,
      hideParentGroupColumn: true,
      nextOrder: groupListData?.totalCount ?? 1,
    }),
    [groupListData?.totalCount, recordData?.formId]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/forms/${recordData.formId}`, text: 'Groups' },
              { text: recordData?.name ?? 'No name' },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={groupsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          hideSearch
          editMode={editMode}
          labels={labels}
          label='Questions'
          prefix='0g9'
          microservice={microservices.Forms.name}
          model={microservices.Forms.models.question}
          columnKey='questionGroup'
          columnOptions={questionColOptions}
          urls={questionGroupsUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              QuestionGroupForm({
                setFieldValue,
                formId: recordData && recordData.formId,
                hideGroup: true,
                formName: recordData?.form?.name,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { questionId, ...rest } = values;

                if (!questionId?.id) {
                  setFieldError('questionId', 'This field is required');
                  return;
                }

                await createRecord({
                  values: {
                    ...rest,
                    groupId: recordId,
                    questionId: questionId?.id,
                    formId: recordData?.formId,
                  },
                  url: questionGroupsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={questionGroupRowsMap}
          urlParams={`&groupId=${recordId}`}
          sortColumnBy='asc'
        />

        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Subgroups'
          prefix='0lz'
          microservice={microservices.Forms.name}
          model={microservices.Forms.models.group}
          columnKey='groupForms'
          columnOptions={subGroupColOptions}
          urls={groupsUrls}
          addNewForm={{
            getFields: () =>
              GroupForm({
                hideParentField: true,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                await createRecord({
                  values: {
                    ...values,
                    parentGroupId: recordId,
                    formId: recordData?.formId,
                  },
                  url: groupsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={groupRowsMap}
          urlParams={`&parentGroupId=${recordId}`}
          customSortColumnName='order'
          sortColumnBy='asc'
          getListData={(data) => setGroupListData(data)}
        />
      </Box>
    </Box>
  );
}
