import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { relationshipsRowsMap } from '@config/handleRows/crm';
import { RelationshipForm } from '@config/forms/crm';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { relationshipsUrls } = crmUrls;

export default function PersonRelationships({ editMode, labels }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label="Relationships"
        editMode={editMode}
        labels={labels}
        prefix="hzo"
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.relationship}
        urls={relationshipsUrls}
        columnKey="relationshipCRM"
        addNewForm={{
          getFields: () => RelationshipForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { data } = await createRecord({
                values,
                url: relationshipsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={relationshipsRowsMap}
      />
    </Box>
  );
}
