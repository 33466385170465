import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { pmUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { projectMeta } from '@config/meta/pm/pmMeta';
import {
  hlrRowsMap,
  testsConductedRowsMap,
  stakeHoldersRowsMap,
  personaRowsMap,
  taskStatusRowsMap,
} from '@config/handleRows/pm';
import {
  HlrForm,
  StakeholderForm,
  PersonaForm,
  TaskStatusForm,
  TaskTypeForm,
} from '@config/forms/pm';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord, updateRecord } from '@config/functions/requests';
import Button from '@ui/Button';
import ConfirmActionDialog from '@components/ConfirmActionDialog';
import ProjectTasksAccordion from '@components/ProjectTasksAccordion';
import RebaseDialogBox from '@components/PM/RebaseDialogBox';
import RebasePastErrorMessageDialogBox from '@components/PM/RebasePastErrorMessage';
import ColorPicker from '@components/EditableTableComps/ColorPicker';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { selectRebase } from '@redux/pmRebaseSlice';
import { useQueryClient } from 'react-query';

const {
  hlrsUrls,
  projectsUrls,
  updateUATsStatusUrls,
  testsConductedUrls,
  stakeholdersUrls,
  personasUrls,
  taskTypesUrls,
  taskStatusUrls,
} = pmUrls;

export default function ProjectDetail({ editMode, labels }) {
  const { id: recordId } = useParams();
  const pmRebase = useSelector(selectRebase);
  const [recordData, setRecordData] = React.useState(null);
  const [showStatusDialog, setShowStatusDialog] = React.useState(false);
  const [taskStatusListData, setTaskStatusListData] = React.useState({});
  const user = useSelector(selectProfile);
  const [notify, update] = useToast();
  const queryClient = useQueryClient();
  const [rebasePopup, setRebasePopup] = React.useState(false);
  const [resolveValues, setResolveValues] = React.useState(null);

  const getValues = (values) => {
    if (values?.started) {
      return new Promise((resolve) => {
        setResolveValues(() => resolve);
        setRebasePopup(true);
      });
    }

    return Promise.resolve(values);
  };

  const onPopupSubmit = (valuesFromPopup) => {
    if (resolveValues) {
      resolveValues(valuesFromPopup);
      setResolveValues(null);
      setRebasePopup(false);
    }
  };

  const handleColorSelect = async ({ rows, setSelectedRows, color } = {}) => {
    const tId = notify('Saving....', { loading: true });
    try {
      await Promise.allSettled(
        rows.map((row) =>
          updateRecord({
            url: taskStatusUrls.detail(row),
            values: {
              colour: color,
            },
            token: user.token,
            actAs: user?.actAs,
          })
        )
      );
      setSelectedRows([]);
      queryClient.invalidateQueries(['Task-Stages-listData']);
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(err.response || err.message);
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  const handleStatusToTest = async () => {
    try {
      setShowStatusDialog(false);
      await updateRecord({
        url: updateUATsStatusUrls.list(),
        values: {
          project: recordId,
          status: 'To Test',
        },
        token: user.token,
        actAs: user?.actAs,
      });
    } catch (err) {
      notify('Failed to update UATs status', {
        type: 'ERROR',
      });
      console.log(err.response ? err.response.data : err.message);
    }
  };

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      projectMeta({
        data,
        queryClient,
      }),
    []
  );

  const taskStagesColOptions = React.useMemo(() => {
    return {
      nextOrder: taskStatusListData?.totalCount ?? 1,
      onlyParent: true,
    };
  }, [taskStatusListData?.totalCount]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: `/programs`, text: 'Programs' },
              {
                to: `/programs/${recordData.program}`,
                text:
                  recordData.details.program && recordData.details.program.name,
              },
              { to: `/programs/${recordData.program}`, text: 'Projects' },
              { text: recordData?.name },
            ]}
          >
            <Button
              type='button'
              variant='outlined'
              label='Set Status To Testing'
              onClick={() => setShowStatusDialog(true)}
            />
          </DetailPageHeader>
        </Box>
      ) : null}

      <ConfirmActionDialog
        open={showStatusDialog}
        setOpen={setShowStatusDialog}
        title='Set UATs status to testing'
        text='you are going to update the status of all UATs in this project to "To Test". please click confirm to continue'
        onConfirm={handleStatusToTest}
      />

      <DetailCard
        urls={projectsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
        getValues={getValues}
        onError={(err) => {
          if (err?.response?.data?.started) {
            notify(err?.response?.data?.started, { type: 'ERROR' });
          } else {
            notify('There was an error, please try again.', { type: 'ERROR' });
          }
        }}
        catchRebasingError={true}
        debounceDelay={0}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix='06u'
          microservice={microservices.PM.name}
          model={microservices.PM.models.hlrs}
          columnKey='hlrPM'
          label='HLRs'
          urls={hlrsUrls}
          addNewForm={{
            getFields: () => HlrForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, project: recordId },
                  url: hlrsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={hlrRowsMap}
          urlParams={`&project=${recordId}`}
        />

        <ProjectTasksAccordion
          projectId={recordId}
          programId={recordData && recordData.program}
          projectName={recordData && recordData.name}
          programName={
            recordData &&
            recordData.details.program &&
            recordData.details.program.name
          }
          label='Tasks'
          editMode={editMode}
          labels={labels}
          prefix='07x'
          getValues={(values) => ({
            ...values,
            project: recordId,
            task_type: values?.task_type?.id,
            owner: values?.owner?.id,
            status: values?.status?.id,
            predecessor: values?.predecessor?.id,
          })}
          urlParams={`&project=${recordId}&parent_task_null=true`}
        />

        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix='0qi'
          microservice={microservices.PM.name}
          model={microservices.PM.models.taskStatus}
          columnKey='taskStatusPM'
          columnOptions={taskStagesColOptions}
          label='Task Stages'
          urls={taskStatusUrls}
          customSortColumnName='order'
          sortColumnBy='asc'
          customBulkHeaderButton={(selectedRows, data, setSelectedRows) => {
            return (
              <>
                <ColorPicker
                  onChange={(color) =>
                    handleColorSelect({
                      rows: selectedRows,
                      setSelectedRows,
                      color,
                    })
                  }
                />
              </>
            );
          }}
          addNewForm={{
            getFields: () => TaskStatusForm({ hideParent: true }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, project: recordId },
                  url: taskStatusUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={taskStatusRowsMap}
          urlParams={`&project=${recordId}&only_parent=true`}
          getListData={(data) => setTaskStatusListData(data)}
        />

        <DetailAccordionView
          microservice={microservices.PM.name}
          model={microservices.PM.models.taskTypes}
          editMode={editMode}
          labels={labels}
          prefix='0l9'
          columnKey='taskTypePM'
          label='Task Types'
          urls={taskTypesUrls}
          addNewForm={{
            getFields: () => TaskTypeForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, project: recordId },
                  url: taskTypesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          startFetching={!!recordData}
          handleRowMap={taskStatusRowsMap}
          urlParams={`&project=${recordId}`}
        />

        <DetailAccordionView
          editMode={editMode}
          labels={labels}
          prefix='01b'
          microservice={microservices.PM.name}
          model={microservices.PM.models.personas}
          columnKey='personaPM'
          label='Personas'
          urls={personasUrls}
          addNewForm={{
            getFields: () => PersonaForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: { ...values, project: recordId },
                  url: personasUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();

                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={personaRowsMap}
          urlParams={`&project=${recordId}`}
        />

        <DetailAccordionView
          columnKey='testsConductedPM'
          label='Tests Conducted'
          editMode={editMode}
          labels={labels}
          prefix='02s'
          urls={testsConductedUrls}
          handleRowMap={testsConductedRowsMap}
          urlParams={`&project=${recordId}`}
        />

        <DetailAccordionView
          microservice={microservices.PM.name}
          model={microservices.PM.models.stakeholder}
          editMode={editMode}
          labels={labels}
          prefix='0a7'
          columnKey='stakeholderPM'
          label='Stakeholders'
          urls={stakeholdersUrls}
          addNewForm={{
            getFields: ({ setFieldValue }) =>
              StakeholderForm({
                setFieldValue,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const { role, person, ...rest } = values;
                const response = await createRecord({
                  values: {
                    ...rest,
                    person: person?.id,
                    role: role?.id,
                    project: recordId,
                  },
                  url: stakeholdersUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={stakeHoldersRowsMap}
          urlParams={`&project=${recordId}`}
        />
      </Box>
      <RebaseDialogBox open={rebasePopup} onPopupSubmit={onPopupSubmit} />
      <RebasePastErrorMessageDialogBox
        labels={labels}
        editMode={editMode}
        open={pmRebase.detail.open}
        err={pmRebase.detail.error}
      />
    </Box>
  );
}
