import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import RenderUiPrefix from '@components/RenderUiPrefix';
import AutocompleteFieldV2 from '@ui/Inputs/AutocompleteFieldV2';

export default function KanbanBoardHeader({
  title,
  filterOptions,
  showEditPipeline,
  editPipeline,
  setEditPipeline = () => null,
  savePipeline = () => null,
  content,
  content2,
  prefix,
  editMode,
  labels,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {prefix ? (
          <RenderUiPrefix editMode={editMode} code={prefix} labels={labels}>
            {(text) => (
              <Typography sx={{ fontSize: 24, fontWeight: 500 }} component="h3">
                {text}
              </Typography>
            )}
          </RenderUiPrefix>
        ) : (
          <Typography sx={{ fontSize: 24, fontWeight: 500 }} component="h3">
            {title}
          </Typography>
        )}

        {Array.isArray(filterOptions)
          ? filterOptions
              ?.filter((option) => option?.show !== false)
              .map((option) => (
                <Box
                  key={option?.label}
                  sx={{ marginLeft: 4, minWidth: '240px' }}
                >
                  <AutocompleteFieldV2
                    {...option}
                    label={option?.label}
                    name={option?.name}
                    requestKey={option?.requestKey}
                    requestKeyOptions={option?.requestKeyOptions ?? []}
                    fetchUrl={option?.fetchUrl}
                    urlParams={option?.urlParams}
                    disabled={option?.disabled}
                    initialValue={option?.initialValue}
                    customOnChange={option?.handleChange}
                    renderRow={option?.renderRow}
                    orderingCol={option?.orderingCol}
                    setCustomOptions={option?.setCustomOptions}
                    required
                  />
                </Box>
              ))
          : null}

        {content}
      </Box>

      {content2}

      {editPipeline ? (
        <Stack direction="row" spacing={1.5}>
          <Button variant="outlined" onClick={() => setEditPipeline(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={savePipeline}>
            Save
          </Button>
        </Stack>
      ) : showEditPipeline ? (
        <Button variant="outlined" onClick={() => setEditPipeline(true)}>
          Edit Pipeline
        </Button>
      ) : null}
    </Box>
  );
}
