import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { computeUrls } from '@config/routes';
import { enumsMeta } from '@config/meta/compute';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { enumValuesRowsMap } from '@config/handleRows/compute';
import { EnumValuesForm } from '@config/forms/compute';
import { microservices } from '@config/constants';

const { enumsUrls, enumValuesUrls } = computeUrls;

export default function EnumDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      enumsMeta({
        data,
      }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/microservice/${recordData?.microserviceId}`,
                text: 'Enums',
              },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={enumsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Enum Values'
          prefix='aq8'
          microservice={microservices.Compute.name}
          model={microservices.Compute.models.enumValue}
          importExportFields={`?enumDefnId=${recordId}`}
          columnKey='enumValuesCompute'
          urls={enumValuesUrls}
          addNewForm={{
            getFields: () => EnumValuesForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                await createRecord({
                  values: {
                    ...values,
                    enumDefnId: recordId,
                  },
                  url: enumValuesUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={enumValuesRowsMap}
          urlParams={`&enumDefnId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
