import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { cmsV2Urls } from '@config/routes';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { menuOptionRowsMap } from '@config/handleRows/cms-v2';
import { MenuOptionForm } from '@config/forms/cms-v2';
import { menuMeta } from '@config/meta/cms-v2';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { useQueryClient } from 'react-query';

const { menusUrls, menuOptionsUrls } = cmsV2Urls;

export default function MenuDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const queryClient = useQueryClient();
  const [menuOptionListData, setMenuOptionListData] = React.useState({});

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => menuMeta({ data, queryClient }),
    []
  );

  const menuOptionColOptions = React.useMemo(() => {
    return {
      menuId: recordId,
      nextOrder: menuOptionListData?.totalCount ?? 1,
    };
  }, [recordId, menuOptionListData?.totalCount]);

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/menus', text: 'Menus' },
              { text: recordData?.name ?? 'Unnamed' },
            ]}
          ></DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        urls={menusUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          label='Menu Options'
          prefix='0mz'
          labels={labels}
          editMode={editMode}
          columnKey='menuOption'
          columnOptions={menuOptionColOptions}
          urls={menuOptionsUrls}
          microservice={microservices.CMS.name}
          model={microservices.CMS.models.menuOptions}
          addNewForm={{
            getFields: ({ setFieldValue, values }) =>
              MenuOptionForm({
                values,
                setFieldValue,
                menuId: recordId,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const { parentId, pageGroupId, add_parent, ...rest } = values;

                if (add_parent && !parentId?.id) {
                  setFieldError('parentId', 'This field is required');
                  return;
                }

                if (!pageGroupId?.id) {
                  setFieldError('pageGroupId', 'This field is required');
                  return;
                }

                const { data } = await createRecord({
                  values: {
                    ...rest,
                    parentId: parentId?.id,
                    pageGroupId: pageGroupId?.id,
                    menuId: recordId,
                  },
                  url: menuOptionsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                refetch();
                resetForm();
                setAddNewForm(false);
                return data?.id;
              } catch (err) {
                console.log('error', err?.response?.data ?? err?.message);
                notify(`Alias and page group  already exists`, {
                  type: 'ERROR',
                });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={menuOptionRowsMap}
          urlParams={`&menuId=${recordId}`}
          customSortColumnName='order'
          sortColumnBy='asc'
          getListData={(data) => setMenuOptionListData(data)}
        />
      </Box>
    </Box>
  );
}
