import { Box } from '@mui/material';

export default function ReadOnlyField({ label, value, ...rest }) {
  const newText =
    typeof value === 'string' ? (
      value.split('\n').map((str, index) => {
        if (!str) {
          return <br key={label} />;
        }

        return <p key={label}>{str}</p>;
      })
    ) : (
      <p>{value}</p>
    );
  return (
    <Box key='container' {...rest}>
      <Box key='label' sx={{ fontWeight: 'medium' }}>
        {label}
      </Box>
      <Box key='newText' sx={{ marginTop: '-0.25rem', color: '#374151' }}>
        {newText}
      </Box>
    </Box>
  );
}
