import React from 'react';
import * as Yup from 'yup';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import FormDialog from '@components/FormDialog';
import { getColumns } from '@config/metaMappings';

export default function ReconnectImapAccountDialog({
  row = { row: {} },
  columnKey,
  columnOptions,
  getFields,
  open,
  setOpen,
  refetch,
  getValues = (values) => values,
  handleSubmit = null,
}) {
  const columnsMemoised = React.useMemo(
    () => getColumns(columnKey, columnOptions),
    [columnKey, columnOptions]
  );

  return (
    <>
      <FormDialog
        successMessage="Imap Account Reconnected"
        label="Reconnect Imap Account"
        submitForm={false}
        initialValues={{
          ...row.row,
          provider: row && row.row && row.row.provider && row.row.provider.id,
        }}
        validationSchema={Yup.object({
          ...getSchemaFromColumnsForForm(columnsMemoised, 'listView'),
          everyone_can_see_it: Yup.bool().nullable(),
          anonymous_can_see_it: Yup.bool().nullable(),
          everyone_in_object_company_can_see_it: Yup.bool().nullable(),
          only_these_roles_can_see_it: Yup.object().default({}),
          only_these_users_can_see_it: Yup.object().default({}),
        })}
        getValues={(values) => getValues(values)}
        getFields={getFields}
        open={open}
        handleSubmit={handleSubmit}
        setOpen={setOpen}
        refetch={refetch}
      />
    </>
  );
}
