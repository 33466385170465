import * as React from 'react';
import formatColName from '@config/functions/removeUnderScoresFromStr';
import AutocompleteField from '@ui/Inputs/AutocompleteField';

export default function RenderAutocompleteField({
  data,
  name,
  isLoading,
  setSearchTerm,
  value,
  setValue,
  handleSave,
  multiple,
  label,
}) {
  const [selected, setSelected] = React.useState(true);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    const sameValue = value && value.label === inputValue;
    if (sameValue || selected) return;
    const timeoutId = setTimeout(() => {
      setSearchTerm(inputValue);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [inputValue, selected, setSearchTerm, value]);

  return (
    <AutocompleteField
      options={data || []}
      name={name}
      isLoading={isLoading}
      getOptionLabel={(option) => option.label || inputValue}
      value={value}
      inputValue={inputValue}
      label={formatColName(label)}
      setInputValue={(term) => {
        if (term !== 'undefined') {
          setInputValue(term);
        } else {
          setInputValue('');
        }
        setSelected(false);
      }}
      setValue={(newValue) => {
        setSelected(true);
        console.log(newValue);
        if (newValue && !multiple) setValue(newValue.id);
        if (newValue && multiple) setValue(newValue);
      }}
      multiple={multiple}
      onBlur={handleSave}
    />
  );
}
