import React from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import { FreeSoloAutocompleteField } from '@components/Inputs';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

export default function ContextFieldValue({
  values,
  contextFieldIdx,
  setFieldValue,
  handleIna,
}) {
  const fields = [
    {
      label: 'Value',
      name: `context_fields-${contextFieldIdx}-value`,
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex' }}>
              <IconButton
                aria-label="add"
                color="primary"
                sx={{ pl: '1px' }}
                onClick={() => {
                  const newContextFields = [...values.context_fields];
                  newContextFields[contextFieldIdx][field?.name] = {
                    label: values?.contextFieldPayloadSectionKey?.fullLabel,
                    static_value: '',
                    mapping_field_name:
                      values?.contextFieldPayloadSectionKey?.key,
                  };
                  setFieldValue('context_fields', newContextFields);
                }}
                disabled={!values?.contextFieldPayloadSectionKey}
              >
                <SubdirectoryArrowRightIcon fontSize="inherit" />
              </IconButton>
              <Box sx={{ width: '100%' }}>
                <FreeSoloAutocompleteField
                  {...field}
                  customValue={
                    values?.context_fields[contextFieldIdx]?.[field?.name]
                      ? [values?.context_fields[contextFieldIdx]?.[field?.name]]
                      : []
                  }
                  label={field?.label}
                  options={[]}
                  onChange={({ newValue: newValues }) => {
                    if (!newValues) return;
                    const newValue = newValues[newValues.length - 1];
                    const newContextFields = [...values.context_fields];

                    newContextFields[contextFieldIdx][field?.name] = newValue
                      ? {
                          label: newValue,
                          static_value: newValue,
                          mapping_field_name: null,
                        }
                      : null;
                    setFieldValue('context_fields', newContextFields);
                  }}
                />
              </Box>
              <IconButton
                aria-label="add-ina"
                color="primary"
                sx={{ pr: '1px' }}
                onClick={() => handleIna(field?.label)}
              >
                <PlaylistAddIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
