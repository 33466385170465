import { ecommerceHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  deliveryStatuses: 'delivery-statuses',
  paymentStatuses: 'payment-statuses',
  carts: 'carts',
  discountCodes: 'discount-codes',
  paymentProviders: 'payment-providers',
  paymentMethods: 'payment-methods',
  deliveryMethods: 'delivery-methods',
  addresses: 'addresses',
  payments: 'payments',
  cartItems: 'cart-items',
  itemDeliverys: 'item-deliveries',
  productVariants: 'product-variants',
};

const deliveryStatusesUrls = getModelUrls(
  ecommerceHost,
  models.deliveryStatuses
);

const paymentStatusesUrls = getModelUrls(ecommerceHost, models.paymentStatuses);
const cartsUrls = getModelUrls(ecommerceHost, models.carts);

const discountCodesUrls = getModelUrls(ecommerceHost, models.discountCodes);

const paymentProvidersUrls = getModelUrls(
  ecommerceHost,
  models.paymentProviders
);

const paymentMethodsUrls = getModelUrls(ecommerceHost, models.paymentMethods);

const deliveryMethodsUrls = getModelUrls(ecommerceHost, models.deliveryMethods);

const paymentsUrls = getModelUrls(ecommerceHost, models.payments);
const cartItemsUrls = getModelUrls(ecommerceHost, models.cartItems);
const itemDeliveriesUrls = getModelUrls(ecommerceHost, models.itemDeliverys);

const addressesUrls = getModelUrls(ecommerceHost, models.addresses);

const urls = {
  deliveryStatusesUrls,
  paymentStatusesUrls,
  cartsUrls,
  discountCodesUrls,
  paymentProvidersUrls,
  paymentMethodsUrls,
  deliveryMethodsUrls,
  paymentsUrls,
  cartItemsUrls,
  itemDeliveriesUrls,
  addressesUrls,
};

export default urls;
