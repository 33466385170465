import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  FormControlLabel,
  Switch,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toggleStageVisibility } from '@config/functions/bpaFunctions';

export default function ShowHideStagesPopup({
  open,
  setOpen,
  stageStates,
  workflowsState,
  setStageStates,
}) {
  const automaticStages = workflowsState.filter(
    (stage) => stage?.manual === false
  );

  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>
          Show/Hide Stages
        </DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Stack direction='row' spacing={2} sx={{ mb: 2.3 }}>
          <Button
            type='button'
            onClick={() => {
              automaticStages.map(({ id }) =>
                toggleStageVisibility([id], true, setStageStates)
              );
            }}
          >
            Show All
          </Button>
          <Button
            type='button'
            onClick={() => {
              automaticStages.map(({ id }) =>
                toggleStageVisibility([id], false, setStageStates)
              );
            }}
          >
            Hide All
          </Button>
        </Stack>
        <Box sx={{ maxHeight: '390px', overflowY: 'scroll' }}>
          {automaticStages.map((stage) => (
            <Box
              key={stage?.id}
              sx={{ display: 'flex', alignItems: 'center', px: 2 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      stageStates.find((s) => s.id === stage?.id)?.visible
                    }
                    onChange={() =>
                      toggleStageVisibility(
                        [stage?.id],
                        undefined,
                        setStageStates
                      )
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={stage?.name}
              />
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
