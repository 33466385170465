import * as React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

const StyledIconButton = styled(IconButton)(({ theme, isactive }) => ({
  backgroundColor:
    isactive === 'true' ? theme.palette.primary.main : 'transparent',
  color: isactive === 'true' ? theme.palette.common.white : 'inherit',
  borderRadius: '0px 4px 4px 0px',
  marginRight: '-13px', // To align the button horizontally to the edge
  '&:hover': {
    backgroundColor:
      isactive === 'true' ? theme.palette.primary.dark : 'transparent',
  },
}));

export default function SearchComponent({ setSearch }) {
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    console.log('Searching:', searchTerm);
    setSearch(searchTerm);
  };

  const handleInputChange = (e) => {
    if (e.target.value === '') {
      setSearch('');
    }

    setSearchTerm(e.target.value);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSearch}
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 1,
        borderColor: 'divider',
        display: 'flex',
        alignItems: 'center',
        p: 0,
      }}
    >
      <TextField
        value={searchTerm}
        onKeyDown={(e) => e.key === 'Enter' && handleSearch(e)}
        onChange={handleInputChange}
        placeholder="Search..."
        fullWidth
        size="small"
        sx={{ p: 0 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StyledIconButton
                type="submit"
                onClick={handleSearch}
                isactive={searchTerm.length > 0 ? 'true' : 'false'}
              >
                <SearchIcon />
              </StyledIconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
