import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const imageStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'left',
};

export default function SignatureField({
  id,
  imageY,
  imageX,
  width,
  height,
  documentSignatures,
  setDocumentSignatures,
  setSignaturePopup,
}) {
  const signature = documentSignatures.find((s) => s.id === id);
  const { type, draw, selectedImage } = signature || {};

  const onDelete = () => {
    setDocumentSignatures((prevState) => prevState.filter((s) => s.id !== id));
  };

  const onAddSignature = () => {
    setSignaturePopup({
      open: true,
      data: { id, width, height },
    });
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: '2',
        top: `${imageY}px`,
        left: `${imageX}px`,
        width,
        height,
      }}
    >
      {signature ? (
        <>
          <img
            style={imageStyles}
            src={type === 'draw' ? draw : selectedImage}
            alt={`${signature.id}-signature`}
          />
          <IconButton
            sx={{ position: 'absolute', right: 0 }}
            aria-label='delete'
            size='small'
            onClick={onDelete}
          >
            <DeleteIcon fontSize='inherit' />
          </IconButton>
        </>
      ) : (
        <Typography
          component='button'
          variant='body2'
          style={{
            cursor: 'pointer',
            background: 'rgb(15, 23, 42)',
            color: '#fff',
            border: '1px solid rgb(15, 23, 42)',
            borderRadius: '4px',
          }}
          onClick={onAddSignature}
        >
          Add signature
        </Typography>
      )}
    </Box>
  );
}
