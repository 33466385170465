import React from 'react';
import { Box, Typography } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import KanbanBoardCard from './KanbanBoardCard';
import List from '@ui/KanbanBoards/List';

export default function KanbanBoardList({
  data,
  detailLink,
  labelCol = 'name',
  headerLabel = 'name',
  workflowId,
  selectedWorkflow,
  sprintMeta,
  isDraggable,
  stageStates,
  setStageStates,
  width,
  containerRef,
  labels,
  editMode,
}) {
  // Ref to store the current list DOM element
  const currentListRef = React.useRef(null);

  React.useEffect(() => {
    // If the current list element exists, scroll to it
    if (containerRef?.current && currentListRef?.current) {
      // Calculate the top position of the current list relative to the parent container
      const offsetLeft =
        currentListRef.current.offsetLeft - containerRef.current.offsetLeft;

      console.log(
        currentListRef.current.offsetLeft,
        containerRef.current.offsetLeft
      );

      // Scroll the parent container to ensure the current list is the first visible element
      containerRef.current.scrollLeft = offsetLeft;
    }
  }, [data]);

  if (!Array.isArray(data) || !data.length) {
    return <Typography component='div'>No data found...</Typography>;
  }

  return data
    .filter((obj) => {
      if (detailLink === 'bpa-board') {
        const found = stageStates.find(({ id }) => obj?.id === id);
        return found?.manual || found?.visible === true;
      }

      return obj;
    })
    .map((list, idx) => (
      <Droppable direction='vertical' droppableId={list.id} key={list.id}>
        {(provided, snapshot) => (
          <div
            ref={list.current ? currentListRef : null} // Assign ref to the current list
          >
            <List
              idx={idx}
              list={list}
              headerLabel={headerLabel}
              detailLink={detailLink}
              workflowId={workflowId}
              data={list.items}
              stageStates={stageStates}
              setStageStates={setStageStates}
              sprintMeta={sprintMeta}
              width={list.current ? width + 100 : +width}
            >
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: '1',
                  padding: '0.5rem',
                  background: snapshot.isDraggingOver ? 'rgba(0,0,0,0.05)' : '',
                  borderRadius: '4px',
                }}
              >
                {list.items.map((item, index) => (
                  <KanbanBoardCard
                    card={item}
                    list={list}
                    onCardClick={() => null}
                    index={index}
                    key={item.id}
                    labelCol={labelCol}
                    detailLink={detailLink}
                    workflowId={workflowId}
                    selectedWorkflow={selectedWorkflow}
                    labels={labels}
                    editMode={editMode}
                  />
                ))}
                {provided.placeholder}
              </Box>
            </List>
          </div>
        )}
      </Droppable>
    ));
}
