import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SignaturePopup({
  open,
  setOpen,
  options,
  handleSubmit,
}) {
  const { id, width, height } = options;
  const sigPad = React.useRef(null);
  const [value, setValue] = React.useState(0);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const handleChange = (e, newValue) => setValue(newValue);

  const handleClose = () => setOpen(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };

    if (file) {
      setSelectedFile(file);
      reader.readAsDataURL(file);
    }
  };

  return (
    <Dialog
      maxWidth='sm'
      fullWidth={true}
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent maxWidth='sm' fullWidth>
        <Typography variant='h6' sx={{ mb: 2, userSelect: 'none' }}>
          Add signature
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='Draw' {...a11yProps(0)} />
            <Tab label='Image' {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              variant='outlined'
              size='small'
              sx={{ mb: 1 }}
              onClick={() => {
                if (sigPad.current?.clear) {
                  sigPad.current.clear();
                }
              }}
              startIcon={<DeleteOutlineOutlinedIcon fontSize='small' />}
            >
              Clear
            </Button>
          </Box>

          <Box
            sx={{
              border: '1px solid #c2c2c2',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            <SignatureCanvas
              throttle={0}
              canvasProps={{
                width: '500px',
                height: '140px',
                className: `${id}-sig-canvas`,
              }}
              ref={sigPad}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ pt: 2 }}>
            <input type='file' accept='image/png' onChange={handleImageUpload} />

            {selectedImage && (
              <Box sx={{ mt: 2, border: '1px solid #c2c2c2', width, height }}>
                <img
                  src={selectedImage}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  alt='uploaded-signature'
                />
              </Box>
            )}
          </Box>
        </TabPanel>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit({
                id,
                selectedFile,
                selectedImage,
                draw:
                  value === 0 && sigPad.current
                    ? sigPad.current.getTrimmedCanvas().toDataURL()
                    : null,
                type: value === 0 ? 'draw' : 'image',
              });
              setValue(0);
              setSelectedImage(null);
              setSelectedFile(null);
              if (sigPad.current?.clear) {
                sigPad.current.clear();
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
