import React from 'react';
import { Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ShieldIcon from '@mui/icons-material/Shield';
import { accountUrls } from '@config/routes';
import DetailCard from '@components/core/DetailCard';
import DetailAccordionView from '@components/DetailAccordionView';
import {
  roleUserHandleRow,
  rolePermissionHandleRow,
} from '@config/handleRows/accounts';
import { rolesMeta } from '@config/meta/accounts/accountMeta';
import DetailPageHeader from '@components/DetailPageHeader';
import { createRecord } from '@config/functions/requests';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { getAuthHeader } from '@config/functions/helperFunctions';
import getStatusRejectedPromises from '@config/functions/getStatusRejectedPromises';
import PermissionPromptModal from '@components/accounts/PermissionPromptModal';

const {
  rolesUrls,
  usersUrls,
  modelsUrls,
  assignRolesUrls,
  unassignRolesUrls,
  permissionsUrls,
} = accountUrls;

export default function RolesDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const navigate = useNavigate();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const [modal, setModal] = useState({
    open: false,
    onCancelClick: () => null,
    onPermissionClick: () => null,
  });
  const [notify, update] = useToast();

  const isSuperUserPermission =
    user?.details?.roles_names.includes('Superusers') ||
    user?.details?.roles_names.includes('Company Administrator') ||
    user?.details?.is_superuser;

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => rolesMeta({ isSuperUserPermission, data }),
    []
  );

  const usersActions = {
    handleDetail: (id) => navigate(`/roles/${id.id}`),
    handleUpdate: async (values, event, refetch) => {
      const toastId = notify(`Updating...`, {
        type: 'INFO',
      });
      try {
        if (values.value) {
          await createRecord({
            url: assignRolesUrls.list(),
            values: { user: values.id, role: recordId },
            token: user.token,
            actAs: user?.actAs,
          });
        } else {
          await createRecord({
            url: unassignRolesUrls.list(),
            values: { user: values.id, role: recordId },
            token: user.token,
            actAs: user?.actAs,
          });
        }
        update(toastId, `Role Updated`, {
          type: 'SUCCESS',
        });
        console.log(refetch);
        refetch();
      } catch (err) {
        console.log(err);
        update(toastId, `There was an error, please try again.`, {
          type: 'ERROR',
        });
      }
    },
    handleAssignRoles: async (rows, refetch = () => null) => {
      try {
        await rows.map((id) =>
          createRecord({
            url: assignRolesUrls.list(),
            values: { user: id, role: recordId },
            token: user.token,
            actAs: user?.actAs,
          })
        );
        // notify(`Data Updated`, {
        //   type: 'SUCCESS',
        // });
        refetch();
      } catch {
        notify(`There was an error, please try again.`, {
          type: 'ERROR',
        });
      }
    },
    handleUnassignRoles: async (rows, refetch = () => null) => {
      try {
        await rows.map((id) =>
          createRecord({
            url: unassignRolesUrls.list(),
            values: { user: id, role: recordId },
            token: user.token,
            actAs: user?.actAs,
          })
        );
        // notify(`Data Updated`, {
        //   type: 'SUCCESS',
        // });
        refetch();
      } catch {
        notify(`There was an error, please try again.`, {
          type: 'ERROR',
        });
      }
    },
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPermissions = async (values) => {
    try {
      const resp = await axios.get(
        permissionsUrls.list(`?model=${values.id}`),
        getAuthHeader(user.token, user?.actAs)
      );
      const permissiondata = resp.data.results[0];
      const obj = values.field.toUpperCase();
      const methods = permissiondata.methods.includes(obj);
      permissiondata.methods.push(obj);

      const value = {
        models: [values.id],
        methods: methods
          ? [...new Set(permissiondata.methods.filter((e) => e !== obj))]
          : permissiondata.methods,
        roles: [recordId],
      };

      return value;
    } catch (err) {
      return false;
    }
  };

  const modelsActions = {
    handleUpdate: async (values, event, refetch) => {
      const toastId = notify(`Updating...`, {
        type: 'INFO',
      });
      try {
        const value = await getPermissions(values);
        if (value) {
          await createRecord({
            url: permissionsUrls.list(),
            values: value,
            token: user.token,
            actAs: user?.actAs,
          });
          update(toastId, `Data Updated`, {
            type: 'SUCCESS',
          });
        }
        if (value === false) {
          const val = {
            models: [values.id],
            methods: [values.field.toUpperCase()],
            roles: [recordId],
          };
          await createRecord({
            url: permissionsUrls.list(),
            values: val,
            token: user.token,
            actAs: user?.actAs,
          });
          update(toastId, `Data Updated`, {
            type: 'SUCCESS',
          });
          refetch();
        }
      } catch (err) {
        console.log(err);
        update(toastId, `There was an error, please try again.`, {
          type: 'ERROR',
        });
      }
    },
    handlePermission: (values) => async () => {
      setModal({
        open: true,
        onCancelClick: () => {
          setModal({
            ...modal,
            open: false,
          });
        },
        onPermissionClick: async (value) => {
          setModal({
            ...modal,
            open: false,
          });
          const permissionValue = {
            ...value,
            models: [values.id],
            roles: [recordId],
          };
          await createRecord({
            url: permissionsUrls.list(),
            values: permissionValue,
            token: user.token,
            actAs: user?.actAs,
          });
        },
      });
    },
    onPermissionBtnClick: async (rows, refetch = () => null) => {
      setModal({
        open: true,
        onCancelClick: () => {
          setModal({
            ...modal,
            open: false,
          });
        },
        onPermissionClick: async (value) => {
          setModal({
            ...modal,
            open: false,
          });
          const toastId = notify(`Updating...`, {
            type: 'INFO',
          });
          const promises = rows.map((rowId) =>
            createRecord({
              url: permissionsUrls.list(),
              values: {
                ...value,
                models: [rowId],
                roles: [recordId],
              },
              token: user.token,
              actAs: user?.actAs,
            })
          );
          const rejectedPromises = getStatusRejectedPromises(
            await Promise.allSettled(promises)
          );

          if (rejectedPromises.length) {
            update(toastId, `There was an error, please try again.`, {
              type: 'ERROR',
            });
            return;
          }
          update(toastId, `${rows.length} Permission Added Successfully!`, {
            type: 'SUCCESS',
          });
          refetch();
        },
      });
    },
  };

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key='detail-view-header'
            items={[
              { to: `/user-roles`, text: 'Roles' },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={rolesUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      {user.details && user.details && user.details.is_superuser && (
        <Box sx={{ mt: 5 }}>
          <DetailAccordionView
            columnKey='rolesUserAccounts'
            label='Users'
            prefix='07r'
            labels={labels}
            editMode={editMode}
            urls={usersUrls}
            handleRowMap={(c) => roleUserHandleRow(c, recordId)}
            handleFunctions={usersActions}
            urlParams={`&profile__roles=${recordId}`}
          />

          <DetailAccordionView
            columnKey='rolesPermissionAccounts'
            label='Permissions'
            urls={modelsUrls}
            handleRowMap={(c) => rolePermissionHandleRow(c, recordId)}
            rowActions={[
              {
                icon: <ShieldIcon sx={{ mr: 1 }} />,
                label: 'Permissions',
                color: '#808080',
                handleClick: modelsActions.handlePermission,
              },
            ]}
            handleFunctions={modelsActions}
          />
        </Box>
      )}

      <PermissionPromptModal
        open={modal.open}
        onCancelClick={modal.onCancelClick}
        onPermissionClick={modal.onPermissionClick}
      />
    </Box>
  );
}
