import React from 'react';
import usePSGraph from '@hooks/usePSGraph';
import { Box } from '@mui/material';
import Graph from '@components/BPA-V2/Graph';
import Sidebar from '@components/BPA-V2/Sidebar/Sidebar';
import FlowchartHeader from '@components/BPA-V2/FlowchartHeader';
import ShapeDocsPopup from '@components/BPA-V2/Popups/ShapeDocsPopup';

export default function Flowchart({
  workflowId,
  shapes,
  transitions,
  cwfShapes,
  setOrderingShapes,
  labels,
  editMode,
  workflowName,
  draftStage,
}) {
  const containerRef = React.useRef(null);
  const toolbarRef = React.useRef(null);
  const [painted, setPainted] = React.useState(false);
  const {
    graph,
    refDone,
    activeCell,
    undoManager,
    docsPopupOpen,
    setDocsPopupOpen,
  } = usePSGraph({
    containerRef,
    toolbarRef,
    workflowId,
    draftStage,
    painted,
  });

  return (
    <Box>
      <FlowchartHeader
        toolbarRef={toolbarRef}
        undoManager={undoManager}
        graph={graph}
        workflowId={workflowId}
        shapes={shapes}
        transitions={transitions}
        cwfShapes={cwfShapes}
        setOrderingShapes={setOrderingShapes}
      />

      {docsPopupOpen?.open ? (
        <ShapeDocsPopup
          open={docsPopupOpen.open}
          shapeId={docsPopupOpen.shapeId}
          workflowId={workflowId}
          setOpen={(open) => setDocsPopupOpen({ open })}
        />
      ) : null}

      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ width: ['100%', '70%'], mb: [3, 0] }}>
          <Graph
            containerRef={containerRef}
            painted={painted}
            setPainted={setPainted}
            graph={graph}
            refDone={refDone}
            workflowId={workflowId}
            shapes={shapes}
            transitions={transitions}
            cwfShapes={cwfShapes}
          />
        </Box>

        <Box
          sx={{
            width: ['100%', '30%'],
            height: '72vh',
            overflowY: 'scroll',
            p: 2,
            boxShadow:
              '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
            border: '1px solid #dbdbdb',
            mb: [3, 0],
          }}
        >
          <Sidebar
            selected={activeCell}
            workflowId={workflowId}
            graph={graph}
            workflowName={workflowName}
          />
        </Box>
      </Box>
    </Box>
  );
}
