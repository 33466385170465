import React from 'react';
import SEOHeader from '@components/SEOHeader';
import { Box, Typography } from '@mui/material';
import { formBgStyles } from '@config/constants';

export default function PublicFormErrorScreen() {
  return (
    <Box
      sx={{
        ...formBgStyles,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SEOHeader title="View Form" />
      <Box sx={{ textAlign: 'center' }}>
        <img
          src="/img/warning-custom.png"
          style={{ width: '120px', height: '120px', margin: '0 auto' }}
          alt=""
        />
        <Typography
          sx={{ my: '18px', fontWeight: '500', fontSize: '28px' }}
          variant="h4"
        >
          We can't open this form for you
        </Typography>
        <Typography sx={{ fontSize: '18px', color: 'rgb(19, 23, 34)' }}>
          If you're the owner of this form then you need to publish it to be
          able to see it.
        </Typography>
      </Box>
    </Box>
  );
}
