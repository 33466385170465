import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

export default function ConfgureAppDialog({ 
  handleOk,  
  labels,
  editMode,
  prefix 
}) {

  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby='Oop-sConnnect-App'
      >
        <DialogTitle id='Oop-sConnnect-App'>
          {'Oops'}
        </DialogTitle>
        <DialogContent>
        {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="it looks like the calendar app isn't configured. please contact your company/system adminstrator to configure a calendar app"
          >
            {(text) => {
              return <DialogContentText id='connect-app-description'>{text}</DialogContentText>;
            }}
          </RenderUiPrefix>
        ) : (
          <DialogContentText id='connect-app-description'>
            it looks like the calendar app isn't configured. please contact your company/system adminstrator to configure a calendar app
          </DialogContentText>
        )}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOk}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
