// handleRows

export const sopRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  revision: r.revision,
  purpose: r.purpose,
  scope: r.scope,
  roles: r.roles,
  definitions: r.definitions,
  created_at: r.created_at,
});

export const sopTaskRowsMap = (r) => ({
  id: r.id,
  order: r.order,
  name: r.name,
  explanation: r.explanation,
  actioned_by: r.actioned_by,
  resources_required: r.resources_required,
  conditional_requirements: r.conditional_requirements,
  potential_warning: r.potential_warning,
  notes: r.notes,
  SOP:
    r.SOP && r.details && r.details.SOP
      ? { id: r.SOP, label: r.details.SOP.name }
      : r.SOP,
  created_at: r.created_at,
});

export const sopTaskStepRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  SOPTask:
    r.SOPTask && r.details && r.details.SOPTask
      ? { id: r.SOPTask, label: r.details.SOPTask.name }
      : r.SOPTask,
  created_at: r.created_at,
});
