import React from 'react';
import { Box, Breadcrumbs, Button } from '@mui/material';
import useRQuery from '@hooks/useRQuery';
import { Link, useParams } from 'react-router-dom';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { eDocUrls } from '@config/routes';
import { truncateText } from '@config/functions/helperFunctions';
import { Document, Page, pdfjs } from 'react-pdf';

const { recipientsUrls } = eDocUrls;

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const pageStyles = {
  position: 'relative',
  display: 'inline-block',
  boxShadow: '3px 3px 8px #0000001a',
  mb: 3,
};

export default function DocumentRecipient() {
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [numPages, setNumPages] = React.useState(0);

  const { data, isLoading, isError } = useRQuery({
    key: [
      `${recordId}-document-recipient-details`,
      recordId,
      user.token,
      user?.actAs,
    ],
    url: recipientsUrls.detail(recordId),
    options: { enabled: !!recordId },
    config: getAuthHeader(user.token, user?.actAs),
  });

  const onDocLoad = ({ numPages }) => {
    setNumPages(numPages);
  };

  if (isError) {
    return <p>Failed to fetch the document</p>;
  }

  if (isLoading || !data) {
    return <p>loading...</p>;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          <Link to={`/documents/${data?.documentId}`}>Documents</Link>
          <p>{truncateText(data?.documentFilledUrl)}</p>
        </Breadcrumbs>
        <a
          href={data?.documentFilledUrl}
          target='_blank'
          style={{ textDecoration: 'none', marginLeft: '16px' }}
          rel='noreferrer'
        >
          <Button fullWidth variant='outlined'>
            Download
          </Button>
        </a>
      </Box>

      <Document
        error={null}
        file={data?.documentFilledUrl}
        className='eDoc-flex-col'
        onLoadSuccess={onDocLoad}
      >
        {Array.from({ length: numPages }).map((r, index) => (
          <Box key={index} sx={pageStyles}>
            <Page pageNumber={++index} />
          </Box>
        ))}
      </Document>
    </Box>
  );
}
