import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { salesPersonRowsMap } from '@config/handleRows/crm';
import { SalesPeopleTargetForm } from '@config/forms/crm';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { salesPersonTargetsUrls } = crmUrls;

export default function SalesPeopleTarget({ editMode, labels }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  return (
    <Box>
      <ListView
        label="Sale People Targets"
        editMode={editMode}
        labels={labels}
        prefix="0ml"
        microservice={microservices.CRM.name}
        model={microservices.CRM.models.salesPersonTarget}
        urls={salesPersonTargetsUrls}
        columnKey="salesPeopleTargetCRM"
        addNewForm={{
          getFields: ({ values, setFieldValue }) =>
            SalesPeopleTargetForm({
              values,
              setFieldValue,
            }),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const { sales_person, pipeline, pipeline_stage, ...rest } =
                values;
              const response = await createRecord({
                values: {
                  ...rest,
                  sales_person: sales_person?.id,
                  pipeline: pipeline?.id,
                  pipeline_stage: pipeline_stage?.id,
                },
                url: salesPersonTargetsUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              resetForm();
              setAddNewForm(false);
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
            return null;
          },
        }}
        handleRowsMap={salesPersonRowsMap}
      />
    </Box>
  );
}
