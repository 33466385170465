import React from 'react';
import { Box, Typography, Container, Stack } from '@mui/material';
import { Helmet } from 'react-helmet';

export default function TermsAndConditions() {
  const getFavicon = () => {
    if (typeof window === 'undefined') return '';

    if (window.location.href.includes('mazards')) {
      return 'https://drive.pullstream.com/file/634e8c8f-810a-4149-b3af-ba750abca696.ico?one=true';
    }

    if (window.location.href.includes('evcharging')) {
      return 'https://drive.pullstream.com/file/e241d62a-ffe6-47fb-a91b-e59798743e73.ico?one=true';
    }

    return '/img/ps-favicon.png';
  };

  return (
    <>
      <Helmet>
        <title>Terms and conditions</title>
        <link rel='icon' href={getFavicon()} />
      </Helmet>
      <Box sx={{ background: '#fff', py: 8 }}>
        <Container maxWidth='md'>
          <Stack spacing={2}>
            <Typography
              variant='h3'
              sx={{
                fontSize: '24px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: 'rgb(17, 24, 39)',
              }}
            >
              Terms and Conditions
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              This Website (the 'Website') and its contents are owned (unless
              indicated otherwise) by Pullstream Ltd (Pullstream), a limited
              company registered in England and Wales with registered number
              12701171. Registered address: 31 Trevelyan Court Green Lane,
              Windsor, England, SL4 3SE. Reference to "Pullstream", "we", "our",
              "us" in these terms and conditions and elsewhere in this Website
              is to Pullstream.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                fontSize: '30px',
                textAlign: 'left',
                fontWeight: 'bold',
                m: 5,
              }}
            >
              1. Using the website
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              By using our Website, you are deemed to have accepted the
              following terms and conditions. If you do not want to be legally
              bound by these terms and conditions, please do not access or use
              the Website. We reserve the right to change these terms and
              conditions at any time and you are advised to review these terms
              regularly to ensure you are aware of any changes. Your continued
              use of the Website after such changes are posted will be deemed
              agreement on your part to these terms and conditions as amended.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                fontSize: '30px',
                textAlign: 'left',
                fontWeight: 'bold',
                m: 5,
              }}
            >
              2. Intellectual property
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              2.1 Copyright and all intellectual property rights in the content
              of the website are vested in Pullstream and reserved, unless
              indicated otherwise. The content of the Website belongs to
              Pullstream unless indicated otherwise. You may use the content of
              the Website subject to the following conditions: (a) It is used
              for information purposes only (b) It is used only for your own
              personal, non-commercial use (c) Any copies or downloads of any
              content from the Website must include a notice that copyright in
              the relevant material is owned by Pullstream.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              2.2 Except as expressly provided above, nothing contained on this
              Website should be construed as conferring any licence or right to
              use any trade mark or copyright of Pullstream's or any third
              party.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                fontSize: '30px',
                textAlign: 'left',
                fontWeight: 'bold',
                m: 5,
              }}
            >
              3. Submission of comments/contributions to the website and
              Pullstream's blogs
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.1 You may submit comments to Pullstream, including without
              limitation for publication on our Blogs.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.2 Any comments submitted in connection with our Blogs are
              submitted on the basis that we will review and may edit such
              comments prior to publication on the Website, and that not all
              submissions will be published. Publication is entirely at the
              discretion of Pullstream. We accept no responsibility or liability
              in relation to the content of any material posted or published on
              this Web site by third parties or in relation to contributions by
              third parties which we publish on the Web site. You are entirely
              liable for all activities conducted by you or by others authorised
              by you or otherwise on your behalf.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.3 If and to the extent that you submit any personal data (such
              as your name and email address) to Pullstream through the Blogs,
              Pullstream confirms that it will only use any such personal data
              for the purposes for which you have provided it.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.4 Any communications which we receive from you in connection
              with the Blogs and any material that you transmit or post or
              submit via this Web site will be deemed non-confidential and
              non-proprietary and we reserve the right to use the content of
              such communications for any purpose whatsoever. In relation to
              such contributions you grant us a perpetual, royalty-free,
              non-exclusive, sub-licensable right and license to use, reproduce,
              modify, adapt, edit, publish and exercise all copyright and
              publicity rights with respect to such work world-wide and/or to
              incorporate it in other works in any media now known or later
              developed for the full term of any rights that may exist in such
              content, consistent with the privacy restrictions set forth in the
              Privacy Policy. If you do not wish to grant such rights to
              Pullstream please do not submit your contribution to this Web
              site. By submitting your contribution to this Website, you also:
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.4.1 Warrant that such contribution is your own original work and
              that you have the right to make it available to us for all the
              purposes specified above;
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.4.2 Warrant that such contribution is not defamatory, does not
              infringe the rights of any third party and does not infringe any
              law;
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.4.3 Agree to use this Website only for lawful purposes and in a
              manner that does not restrict or inhibit the use and enjoyment of
              this Web site by any third party. Prohibited behaviour includes,
              without limitation, conduct which is unlawful or which may harass
              or cause distress to any person and the submission or transmission
              of content which is defamatory, abusive, obscene, offensive,
              pornographic, indecent, discriminatory, inflammatory, in breach of
              confidence or privacy or which in the opinion of Pullstream may
              cause offence to any person or damage our reputation. You must not
              use any abusive or aggressive language, swear, threaten, harass or
              abuse any other person, including other users;
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.4.4 Agree to indemnify, defend and hold harmless Pullstream
              against any claim, liability, cost, damage, or loss we may incur
              (including without limitation legal fees) as a result of any
              violation by you of the provisions set out in this clause 3;
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.4.5 Agree to waive any moral rights in your contribution for the
              purposes of its submission to and publication on the Website and
              other purposes specified above.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.5 You must not intentionally violate any laws, rules or
              regulations.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.6 No user must post nor recommend any other Website nor refer to
              specific URLs (Website addresses) where those linked Web sites
              would infringe any of the above provisions. No user must post any
              advertisement on the Website.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.7 In using this Website, you agree not to impersonate another
              person or use a false name or a name you are unauthorised to use
              or create a false email address or try to mislead others as to the
              identity or origin of any communication.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.8 You must not corrupt the site, flood the site with information
              so as to cause the site not to function or use anything which may
              affect the function of the site for example viruses, worms, logic
              bombs or similar. You must not interfere or tamper with, remove,
              delete or otherwise alter in any way, any information which is
              included on the Web site.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.9 Pullstream reserves the right to fail any messages and/or
              remove any material whatsoever submitted to our Blogs or otherwise
              to the Website for any reason. Pullstream will not notify the
              contributor of the material of such failure or removal.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.10 Pullstream reserves the right to take any action against any
              account or user, at any time, for any reason. We may at our
              discretion terminate your access to the Website or to the
              registration areas of the Web site, or prevent you from submitting
              comments via the Website immediately and without giving you
              advance notice. If you engage in behaviour which breaches these
              terms and conditions, and we consider such behaviour to be serious
              and/or repeated, we may use whatever information is available to
              us to prevent further breaches. If your access to the Website or
              to registration areas of the Web site is terminated, you may not
              open another account with us or register again and we reserve the
              right to terminate any such account or cancel any such
              registration.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              3.11 Should you have any complaint regarding any material
              published on the Website, you can notify us of the nature and
              details of your complaint by emailing info@pullstream.com.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                fontSize: '30px',
                textAlign: 'left',
                fontWeight: 'bold',
                m: 5,
              }}
            >
              4. Registration to the website
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              When you register, you are registering as a personal user of the
              Website and you must use your own identity. You are obliged to
              provide accurate and complete registration information.
              Registration is for a single user only. Your username and password
              are personal to you and may not be used by anyone else to gain
              access to areas restricted to registered users.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                fontSize: '30px',
                textAlign: 'left',
                fontWeight: 'bold',
                m: 5,
              }}
            >
              5. Disclaimer and limitation of liability
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              5.1 This information has been carefully prepared, but it has been
              written in general terms and should be seen as broad guidance
              only. The information cannot be relied upon to cover specific
              situations and you should not act, or refrain from acting, upon
              the information contained therein without obtaining specific
              professional advice. Please contact Pullstream to discuss these
              matters in the context of your particular circumstances.
              Pullstream, its partners, employees and agents do not accept or
              assume any liability or duty of care for any loss arising from any
              action taken or not taken by anyone in reliance on this
              information or for any decision based on it. Pullstream, a UK
              limited company registered in England and Wales under number
              12701171, is a member of Pullstream International Limited, a UK
              company limited by guarantee, and forms part of the international
              Pullstream network of independent member firms. A list of members'
              names is open to inspection at our registered office, 31 Trevelyan
              Court Green Lane, Windsor, England, SL4 3SE. Pullstream is
              authorised and regulated by the Financial Conduct Authority to
              conduct investment business. Pullstream is the brand name for the
              Pullstream network and for each of the Pullstream Member Firms.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              5.2 The content of this Website is intended to provide general
              guidance on matters of interest and about the services provided by
              Pullstream. The content of this Website does not constitute, nor
              should it be relied upon as, professional advice. Please contact
              Pullstream for advice on any particular area of interest.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              5.3 In preparing and maintaining this Website every effort has
              been made to ensure the content is up to date and accurate.
              However, law and regulations change continually and unintentional
              errors can occcur and the information may be neither up to date
              nor accurate. Pullstream makes no representation or warranty
              (including liability towards third parties), express or implied,
              as to the accuracy, reliability or completeness of the information
              published on this Web site.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              5.4 Except for death or personal injury caused by negligent acts
              or omissions, Pullstream accepts no liability for any loss or
              damage whatsoever (including but not limited to any indirect,
              consequential, special, exemplary or similar damages) arising as a
              result of any decision or action taken or refrained from as a
              result of information contained on the Website or other Websites
              to which it may be linked, or as a result of your use or misuse of
              the Website.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              5.5 Without prejudice to the generality of clause 3.4.4, you agree
              to indemnify, defend and hold harmless Pullstream against any
              claim, liability, cost, damage, or loss we may incur (including
              without limitation legal fees) as a result of any violation by you
              of your obligations under these terms and conditions.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                fontSize: '30px',
                textAlign: 'left',
                fontWeight: 'bold',
                m: 5,
              }}
            >
              6. Third party websites
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              This Website contains links to Websites maintained by parties
              other than Pullstream. These links are provided simply to increase
              the information available to you and we accept no responsibility
              or liability in respect of the accuracy, timeliness, suitability
              or lawfulness of the information contained on third party
              Websites. The inclusion of a link to a third party Website does
              not constitute an endorsement or an indication of a joint venture,
              partnership or other legal arrangement not expressly stated
              between us and that third party.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                fontSize: '30px',
                textAlign: 'left',
                fontWeight: 'bold',
                m: 5,
              }}
            >
              7. Privacy policy
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              This section contains information about Pullstream's policies
              governing the collection and use of your personal information.
              From time to time we may need to make changes to this privacy
              policy. Any updates to our privacy policy will be clearly posted
              in this section of the Website. We advise you to regularly review
              this policy when you visit the Website.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.1 Information collected when you visit the Website
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.1.1 It will normally be clear when personal information is being
              collected. We may request your name, email address, postal address
              and post code, telephone number and company name, depending on the
              particular purpose for which your personal information is being
              collected.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.1.2 The personal information required will be the minimum to
              enable Pullstream to deal expeditiously with your request or
              enquiry. In certain circumstances, additional information may be
              requested to enable us to provide the most appropriate response to
              your request or enquiry, or to enable us to send you additional
              information where you have indicated you would like to receive
              this. Where additional personal information is requested this will
              be marked and its provision is voluntary.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.1.3 Sensitive information is not required or collected through
              the Website except where legally required as part of the
              recruitment process. Sensitive information includes data relating
              to race or ethnic origin, political opinions, religious or similar
              beliefs, trade union membership, physical or mental health, sexual
              life or criminal matters. Where you do provide sensitive personal
              information as part of the recruitment process, your submission of
              such information will be deemed to constitute explicit consent to
              Pullstream's use of that information in connection with the
              purpose for which it has been provided.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.1.4 We do not require you to register in order to access the Web
              site. However, registration is required to access certain areas of
              the Web site or services provided via the Web site.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.1.5 IP addresses of visitors are logged for administration and
              trouble shooting purposes. The IP address indicates the location
              of your computer on the internet. IP addresses are not used to
              track your session and are not linked to anything personally
              identifiable. This information may be aggregated to provide
              statistical information about the users of our Web site. No
              individual user will be identifiable from such information, which
              will be used to ensure that the Web site reflects the needs of our
              users.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.1.6 To ensure that our Website is well managed and to facilitate
              improved navigation within the Website, we may use cookies (small
              text files stored in a user's browser) or Web beacons (electronic
              images to count visitors who have accessed a particular page) to
              collect aggregate data. We use cookies to:
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.1.6.1 Manage our Website. Cookies identify individual users when
              they log in to the Web site and enable us to personalise the
              Website.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.1.6.2 Track information on our systems and identify categories
              of users by items such as address, browser type and pages visited.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.1.6.3 Analyse the number of visitors to different areas of the
              Website and to make sure that our Website is serving as a useful,
              effective information source.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.2 Use of your personal information
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.2.1 We will only use your personal information for the specific
              purpose for which it was provided, except where you have indicated
              you would like to receive other information from Pullstream. When
              requesting material or asking someone to contact you, you might be
              given the option to receive further information from us which may
              be of interest. If you select this option, we will use your
              personal information to contact you with information about our
              products and/or services which we believe may be of interest to
              you.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.2.2 If you wish to unsubscribe from communications sent by
              Pullstream, you may do so at any time by clicking on the
              'unsubscribe' link that appears in all messages or by emailing
              unsubscribe@pullstream.com.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.3 Your information and third parties
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.3.1 Although we may provide the personal information to service
              providers that handle information, under a data processor
              agreement, on our behalf, we do not share, sell or distribute
              personal data to or with third parties unless the transfer of your
              data to third parties is necessary for the performance of the
              purpose for which it was provided.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.3.2 Information is not provided to third parties for their own
              marketing purposes and mailings are not undertaken by Pullstream
              for third parties.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.3.3 Personal data collected by Pullstream will not be
              transferred to other Member Firms of Pullstream International.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.3.4 We may disclose, transfer, sell or assign any of the
              information described above, including personally identifiable
              information, to third parties, as a result of or in preparation
              for the sale, merger, consolidation, change in control, transfer
              of substantial assets, reorganisation or liquidation of Pullstream
              and you consent to such disclosure, transfer, sale or assignment.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.4 Retention of your information Information is only kept for the
              period required for fulfilling your request and for a reasonable
              period of time thereafter for our administration and marketing
              purposes.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.5 Security of your information
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.5.1 Generally accepted standards of technology and operational
              security have been implemented to protect personal information
              from loss, misuse, alteration and destruction. However, as you may
              be aware, no data transmission over the internet can be entirely
              secure. As a result, while we will always use reasonable
              endeavours to protect your personal information, we cannot
              guarantee that any information you submit to us will be free from
              unauthorised third party use or intrusion. Therefore, we cannot
              guarantee the security of your personal information, or your use
              of the Website.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.5.2 We cannot guarantee that the Website is free from viruses or
              malicious codes. You agree that it is your responsibility to
              satisfy yourself that your own information technology/equipment
              used to access the Website is protected against such viruses
              and/or codes.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.5.3 All employees and principals are required to keep personal
              information confidential and only authorised personnel have access
              to this information.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.6 Monitoring emails We may intercept, monitor and/or keep
              records of email communications entering and leaving our systems.
              If you communicate through our systems you will be deemed to have
              consented to such interception and monitoring.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.7 Links
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.7.1 Pullstream is not responsible for the privacy policies of
              third party Websites to which links are provided and you are
              advised to read the privacy policies on these Websites before
              providing any personal information.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.7.2 We cannot guarantee that third party Websites to which links
              are provided are free from viruses or malicious codes. You agree
              that it is your responsibility to satisfy yourself that your own
              information technology/equipment used to access a third party Web
              site is protected against such viruses and/or codes.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.8 Accessing personal information
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.8.1 You can ask to review the personal information we hold about
              you and ask us to change or delete any of this information. You
              can do this by contacting us by email at dpo@pullstream.com.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.8.2 We may charge a small administration fee of £10 in relation
              to any requests for access to personal information.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.8.3 Pullstream assumes responsibility for keeping an accurate
              record of your personal information as it is submitted. However,
              we cannot ensure the ongoing accuracy of your personal information
              after it has been submitted without your assistance. Please help
              us keep your personal information current and accurate by
              contacting us if your personal information becomes out of date.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.9 Data controller The data controller collecting the information
              described in this statement is Pullstream.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              7.10 Comments and questions If you have any comments or questions
              about this privacy policy please email dpo@pullstream.com. For
              information about data protection, visit
              www.dataprotection.gov.uk.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                fontSize: '30px',
                textAlign: 'left',
                fontWeight: 'bold',
                m: 5,
              }}
            >
              8. Severability
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              If any of these terms are determined to be illegal, invalid or
              otherwise unenforceable by reason of the laws of any state or
              country in which these terms are intended to be effective, then to
              the extent and within the jurisdiction in which that term is
              illegal, it shall be severed and deleted from these terms and the
              remaining terms shall survive, remain in full force and effect and
              continue to be binding and enforceable.
            </Typography>
            <Typography
              variant='h3'
              sx={{
                fontSize: '30px',
                textAlign: 'left',
                fontWeight: 'bold',
                m: 5,
              }}
            >
              9. Governing law and jurisdiction
            </Typography>
            <Typography
              variant='h3'
              sx={{
                typography: 'body1',
                fontSize: '16px',
                color: 'rgb(75, 85, 99)',
              }}
            >
              These terms and conditions shall be governed by and construed in
              accordance with the laws of England and Wales. As the Pullstream
              Website can be visited from locations around the world with
              different laws from those of England, when you access the Website
              you agree that the laws of England will apply to all matters
              relating to your use of the Website. You and we also agree to
              submit to the exclusive jurisdiction of the English courts with
              respect to such matters. Notwithstanding this, we reserve the
              right to bring enforcement proceedings in any court or courts
              including, if appropriate, in the courts of your country of
              residence.
            </Typography>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
