import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useToast from '@hooks/useToast';
import ListView from '@components/ListView';
import { selectProfile } from '@redux/profileSlice';
import { systemUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import { uiPrefixesMap } from '@config/handleRows/system';
import { UIPrefixesForm } from '@config/forms/system';
import { microservices } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import PublicUserErrorScreen from '@components/CMS-V2/PublicUserErrorScreen';

const { uiPrefixesPagnUrls } = systemUrls;

export default function UIPrefixes({ labels, editMode }) {
  const user = useSelector(selectProfile);
  const [notify] = useToast();

  if (
    Array.isArray(user?.details?.roles_names) &&
    !(
      user?.details?.roles_names.includes('Superusers') ||
      user?.details?.is_superuser
    )
  ) {
    return <PublicUserErrorScreen />;
  }

  return (
    <Box>
      <ListView
        label='UI Prefixes'
        prefix='0xv'
        labels={labels}
        editMode={editMode}
        microservice={microservices.System.name}
        model={microservices.System.models.uiPrefixes}
        urls={uiPrefixesPagnUrls}
        columnKey='uiLabelSystem'
        addNewForm={{
          getFields: () => UIPrefixesForm(),
          handleSubmit: async (
            values,
            setSubmitting,
            resetForm,
            setAddNewForm,
            refetch,
            setError
          ) => {
            try {
              const response = await createRecord({
                values: { ...values, everyone_can_see_it: true },
                url: uiPrefixesPagnUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setAddNewForm(false);
              // notify('Prefix Created', {
              //   type: 'SUCCESS',
              // });
              refetch();
              return response?.data?.id;
            } catch (err) {
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          },
        }}
        handleRowsMap={uiPrefixesMap}
      />
    </Box>
  );
}
