import React from 'react';
import { Grid } from '@mui/material';
import {
  TextField,
  CheckBoxField,
  SelectField,
  DateTimePickerField,
  UploadField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import MenuItem from '@mui/material/MenuItem';
import { statusOptions, transactionTypeOptions } from '@config/constants';
import ProductTypeDropdown from '@dropdown-forms/inventory/ProductTypeDropdown';
import WarehouseDropdown from '@dropdown-forms/inventory/WarehouseDropdown';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';
import DeliveryDropdown from '@dropdown-forms/inventory/DeliveryDropdown';
import VariantKeysDropdown from '@dropdown-forms/inventory/VariantKeysDropdown';
import CompanyDropdown from '@dropdown-forms/crm/CompanyDropdown';
import ManufacturerDropdown from '@dropdown-forms/inventory/ManufacturerDropdown';
import SupplierDropdown from '@dropdown-forms/inventory/SupplierDropdown';
import CurrencyDropdown from '@dropdown-forms/list/CurrencyDropdown';
import CityDropdown from '@dropdown-forms/list/CityDropdown';
import PostCodeDropdown from '@dropdown-forms/list/PostCodeDropdown';
import { inventoryUrls, crmUrls, listsUrls } from '@config/routes';
import { useQueryClient } from 'react-query';



const { productTypesUrls, manufacturersUrls, warehousesUrls, suppliersUrls, deliveriesUrls, variantKeysUrls } = inventoryUrls;
const { companiesUrls, personsUrls } = crmUrls;
const { currenciesUrls, citiesUrls, postCodesUrls } = listsUrls;



export const ProductTypeForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient();

  return(
  <>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
       <ProductTypeDropdown 
        refetch={({ id, name }) => {
          setFieldValue('parent_product_type', { id, label: name });
          queryClient.invalidateQueries(['parent-product-type']);
        }}
        >
        <AutocompleteFieldV2
          name='parent_product_type'
          label='Parent Product Type'
          requestKey='parent-product-type'
          fetchUrl={productTypesUrls.list}
          urlParams={`&ordering=created_at`}
          
        />
      </ProductTypeDropdown>
    </Grid>
  </>
)};

export const ManufacturerContactForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient();

  return(
  <>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Title' name='title' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <PersonDropdown
        refetch={({
          id,
          email,
        }) => {
          setFieldValue('person', {
            id: id,
            label: `${email || ''}`,
          });
          queryClient.invalidateQueries([
            'manufacturer-person',
          ]);
        }}
      >
        <AutocompleteFieldV2
          name='person'
          label='Person'
          requestKey='manufacturer-person'
          fetchUrl={personsUrls.list}
          urlParams={`&ordering=created_at`}
          renderRow={(row) => ({
            id: row?.id,
            label: `${row?.email}`,
          })}
          required
        />
      </PersonDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <CheckBoxField label='Status' name='status' />
    </Grid>
  </>
)};

export const ManufacturerForm = ({setFieldValue} = {}) => {
  const queryClient = useQueryClient();

  return(
  <>
    <Grid item xs={4}>
        <CompanyDropdown
          refetch={({ id, name }) => {
            setFieldValue('manufacturer_company', { id, label: name });
            queryClient.invalidateQueries(['manufacturer-companies']);
          }}
        >
          <AutocompleteFieldV2
            name='manufacturer_company'
            label='Company'
            requestKey='manufacturer-companies'
            fetchUrl={companiesUrls.list}
            urlParams={`&ordering=created_at`}
            required
          />
        </CompanyDropdown>
    </Grid>
  </>
)};

export const ProductForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient();

  return(
  <>
    <Grid item xs={2} sm={4} md={4}>
      <TextField
        label='Name'
        name='name'
        required
      />
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Slug' name='slug' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
        <ProductTypeDropdown
          refetch={({ id, name }) => {
            setFieldValue('product_type', { id, label: name });
            queryClient.invalidateQueries(['product-type']);
          }}
        >
          <AutocompleteFieldV2
            name='product_type'
            label='Product Type'
            requestKey='product-type'
            fetchUrl={productTypesUrls.list}
            urlParams={`&ordering=created_at`}
            required
          />
        </ProductTypeDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
        <ManufacturerDropdown
          refetch={({ id, details }) => {
            setFieldValue('manufacturer', { id, label: `${details?.manufacturer_company?.name ?? ''}` });
            queryClient.invalidateQueries(['product-manufacturer']);
          }}
        >
          <AutocompleteFieldV2
            name='manufacturer'
            label='Manufacturer'
            requestKey='product-manufacturer'
            fetchUrl={manufacturersUrls.list}
            urlParams={`&ordering=created_at`}
            required
            renderRow={(row) => ({
              id: row?.id,
              label: row?.details?.manufacturer_company?.name,
            })}
          />
        </ManufacturerDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Manufacturer Code' name='manufacturer_code' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Model' name='model' />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <CheckBoxField label='Discontinued' name='discontinued' />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <CheckBoxField label='In Eshop' name='in_eshop' />
    </Grid>
  </>
)};

export const ProductVariantForm = () => (
  <>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Description' name='description' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Manufacturer Code' name='manufacturer_code' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Sku Code' name='our_sku_code' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Vat Percentage' name='vat_percentage' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Price' name='price' />
    </Grid>
  </>
);

export const VariantKeyForm = () => (
  <>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Key' name='key' />
    </Grid>

    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Description' name='description' />
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <CheckBoxField label='Show In Filters' name='show_in_filters' />
    </Grid>
  </>
);

export const VariantValueForm = ({setFieldValue} = {}) => {
  const queryClient = useQueryClient()

  return(
  <>
    <Grid item xs={2} sm={4} md={4}>
        <VariantKeysDropdown
          refetch={({ id, key }) => {
            setFieldValue('key', { id, label: key });
            queryClient.invalidateQueries(['variant-key']);
          }}
        >
          <AutocompleteFieldV2
            name='key'
            label='Variant Key'
            requestKey='variant-key'
            fetchUrl={variantKeysUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.key,
            })}
            required
          />
        </VariantKeysDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Value' name='value' />
    </Grid>
  </>
)};

export const SupplierContactForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient();

  return(
  <>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Title' name='title' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <PersonDropdown
        refetch={({
          id,
          email,
        }) => {
          setFieldValue('person', {
            id: id,
            label: `${email || ''}`,
          });
          queryClient.invalidateQueries([
            'supplier-contact-person',
          ]);
        }}
      >
        <AutocompleteFieldV2
          name='person'
          label='Person'
          requestKey='supplier-contact-person'
          fetchUrl={personsUrls.list}
          urlParams={`&ordering=created_at`}
          renderRow={(row) => ({
            id: row?.id,
            label: `${row?.email}`,
          })}
        />
      </PersonDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={2}>
      <CheckBoxField label='Status' name='status' />
    </Grid>
  </>
)};

export const SupplierForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient();

  return(
  <>
    <Grid item xs={2} sm={4} md={6}>
        <CompanyDropdown
          refetch={({ id, name }) => {
            setFieldValue('supplier_company', { id, label: name });
            queryClient.invalidateQueries(['supplier-companies']);
          }}
        >
          <AutocompleteFieldV2
            name='supplier_company'
            label='Supplier Company'
            requestKey='supplier-companies'
            fetchUrl={companiesUrls.list}
            urlParams={`&ordering=created_at`}
            required
          />
        </CompanyDropdown>
    </Grid>
  </>
)};

export const WarehouseForm = ({setFieldValue, values}={}) => {
  const queryClient = useQueryClient()
  return(
  <>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Name' name='name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Description' name='description' />
    </Grid>

    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Address 1' name='address1' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
        <CityDropdown
          refetch={({ id, name }) => {
            setFieldValue('city', { id, label: name });
            queryClient.invalidateQueries(['warehouse-city']);
          }}
        >
          <AutocompleteFieldV2
            name='city'
            label='City'
            requestKey='warehouse-city'
            fetchUrl={citiesUrls.list}
            urlParams={`&ordering=created_at`}
            required
          />
        </CityDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
        <PostCodeDropdown
          city = {values.city}
          refetch={({ id, code }) => {
            setFieldValue('postcode', { id, label: code });
            queryClient.invalidateQueries(['warehouse-postcode']);
          }}
        >
          <AutocompleteFieldV2
            name='postcode'
            label='Postcode'
            requestKey='warehouse-postcode'
            fetchUrl={postCodesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.code,
            })}
            required
          />
        </PostCodeDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Keyholder Name' name='keyholder_name' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Keyholder Address 1' name='keyholder_addr1' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
        <CityDropdown
          refetch={({ id, name }) => {
            setFieldValue('keyholder_city', { id, label: name });
            queryClient.invalidateQueries(['warehouse-keyholder-city']);
          }}
        >
          <AutocompleteFieldV2
            name='keyholder_city'
            label='Keyholder City'
            requestKey='warehouse-keyholder-city'
            fetchUrl={citiesUrls.list}
            urlParams={`&ordering=created_at`}
            required
          />
        </CityDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
        <PostCodeDropdown
          city = {values.keyholder_city}
          refetch={({ id, code }) => {
            setFieldValue('keyholder_postcode', { id, label: code });
            queryClient.invalidateQueries(['warehouse-keyholder-postcode']);
          }}
        >
          <AutocompleteFieldV2
            name='keyholder_postcode'
            label='Keyholder Post Code'
            requestKey='warehouse-keyholder-postcode'
            fetchUrl={postCodesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.code,
            })}
            required
          />
        </PostCodeDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Keyholder Mobile' name='keyholder_mobile' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Keyholder Email' name='keyholder_email' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Key Code' name='keycode' required />
    </Grid>
  </>
)};

export const WarehouseInventoryForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient()
  return(
  <>
    <Grid item xs={2} sm={4} md={4}>
        <WarehouseDropdown
          refetch={({ id, name }) => {
            setFieldValue('warehouse', { id, label: name });
            queryClient.invalidateQueries(['warehouse-inventory-warehouse']);
          }}
        >
          <AutocompleteFieldV2
            name='warehouse'
            label='Warehouse'
            requestKey='inventory-warehouse'
            fetchUrl={warehousesUrls.list}
            urlParams={`&ordering=created_at`}
            required
          />
        </WarehouseDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={5}>
      <TextField label='Location in Warehouse' name='location_in_warehouse' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Quantity' name='quantity' />
    </Grid>
  </>
)};

export const ProductSupplierForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient()
  return(
  <>
    <Grid item xs={2} sm={4} md={3}>
        <SupplierDropdown
          refetch={({ id, details }) => {
            setFieldValue('supplier', { id, label: `${details?.supplier_company?.name ?? ''}` });
            queryClient.invalidateQueries(['product-manufacturer']);
          }}
        >
          <AutocompleteFieldV2
            name='supplier'
            label='Supplier'
            requestKey='product-supplier'
            fetchUrl={suppliersUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.details?.supplier_company?.name,
            })}
            required
          />
        </SupplierDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
        <CurrencyDropdown
          refetch={({ id, name }) => {
            setFieldValue('currency', { id, label: name });
            queryClient.invalidateQueries(['product-currency']);
          }}
        >
          <AutocompleteFieldV2
            name='currency'
            label='Currency'
            requestKey='product-currency'
            fetchUrl={currenciesUrls.list}
            urlParams={`&ordering=created_at`}
            required
          />
        </CurrencyDropdown>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Cost' name='cost' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Quantity' name='quantity' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Supplier Code' name='supplier_code' />
    </Grid>
  </>
)};

export const DeliveryForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Delivered By' name='delivered_by' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Received By' name='received_by' required />
    </Grid>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label='Quantity' name='quantity' />
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Delivery Note Ref' name='delivery_note_ref' />
    </Grid>
  </>
);

export const DeliveryItemForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient()

  return(
  <>
    <Grid item xs={2} sm={4} md={3}>
        <DeliveryDropdown
          refetch={({ id, delivered_by }) => {
            setFieldValue('delivery', { id, label: delivered_by });
            queryClient.invalidateQueries(['delivery-item']);
          }}
        >
          <AutocompleteFieldV2
            name='delivery'
            label='Delivery'
            requestKey='delivery-item'
            fetchUrl={deliveriesUrls.list}
            urlParams={`&ordering=created_at`}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.delivered_by,
            })}
            required
          />
        </DeliveryDropdown>
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='In Order' name='in_order' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Notes' name='notes' />
    </Grid>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Quantity' name='quantity' />
    </Grid>
  </>
)};

export const DeliveryArtifactForm = ({ errors, values } = {}) => {

  return (
    <>
      <Grid item xs={4}>
       <UploadField name='artifact' />
      </Grid>
    </>
  );
};

export const ProductArtifactForm = ({ errors, values } = {}) => {
  return (
    <>
      <Grid item xs={4}>
       <UploadField name='artifact' />
      </Grid>
    </>
  );
};

export const ProductAdditionalInfoForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Key' name='key' required />
    </Grid>

    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Value' name='value' />
    </Grid>
  </>
);

export const InventoryForm = ({setFieldValue}={}) => {
  const queryClient = useQueryClient()

  return(
  <>
    <Grid item xs={2} sm={4} md={3}>
      <SelectField label='Status' name='status'>
        <MenuItem value={'Reserved'} key={0}>
          Reserved
        </MenuItem>
        <MenuItem value={'Not for Sale'} key={1}>
          Not for Sale
        </MenuItem>
        <MenuItem value={'For Sale'} key={2}>
          For Sale
        </MenuItem>
      </SelectField>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Location In Warehouse' name='location_in_warehouse' required/>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Stock Level' name='stock_level' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Reorder Level' name='reorder_level' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Max Stock Level' name='max_stock_level' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Notes' name='notes' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
        <WarehouseDropdown
          refetch={({ id, name }) => {
            setFieldValue('warehouse', { id, label: name });
            queryClient.invalidateQueries(['inventory-warehouse']);
          }}
        >
          <AutocompleteFieldV2
            name='warehouse'
            label='Warehouse'
            requestKey='inventory-warehouse'
            fetchUrl={warehousesUrls.list}
            urlParams={`&ordering=created_at`}
            required
          />
        </WarehouseDropdown>
    </Grid>
  </>
)};

export const InventoryTransactionForm = () => (
  <>
    <Grid item xs={2} sm={4} md={3}>
      <SelectField label='Transaction Type' name='transaction_type' required>
        {transactionTypeOptions &&
          transactionTypeOptions.map((o) => (
            <MenuItem value={o.value}>{o.label}</MenuItem>
          ))}
      </SelectField>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <SelectField label='Status' name='status'>
        {statusOptions &&
          statusOptions.map((o) => (
            <MenuItem value={o.value}>{o.label}</MenuItem>
          ))}
      </SelectField>
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Quantity' name='quantity' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <DateTimePickerField label='Transaction Date' name='transaction_date' />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Po Number' name='po_number' required />
    </Grid>

    <Grid item xs={2} sm={4} md={3}>
      <TextField label='Notes' name='notes' />
    </Grid>
  </>
);

export const MediaForm = ({ values, errors }={}) => {

  return (
    <>
      <Grid item xs={4}>
       <UploadField name='url' />
      </Grid>
    </>
  );
};
