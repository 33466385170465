import * as React from 'react';
import { Box, Grid, Button, Typography, Divider, Stack } from '@mui/material';
import { Formik, Form } from 'formik';
import { hrUrls } from '@config/routes';
import {
  TextField,
  AutocompleteFieldV2,
  DateTimePickerField,
} from '@components/Inputs';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import * as Yup from 'yup';
import {
  handleCreateFormErrors,
  getAuthHeader,
} from '@config/functions/helperFunctions';
import useRQuery from '@hooks/useRQuery';
import Skeleton from '@mui/material/Skeleton';

const { employeesUrls, daysOffUrls, dayOffTypesUrls } = hrUrls;

const headingStyles = {
  color: '#012970',
  fontSize: '1.65rem',
  lineHeight: '43px',
  fontWeight: '600',
};

export default function CreateDaysOff() {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  const { data, isLoading } = useRQuery({
    key: ['employee-record-details', user?.details, user?.token, user?.actAs],
    url: employeesUrls.detail(user?.details?.details?.employee?.id),
    options: {
      enabled: !!(user?.token && user?.details?.details?.employee?.id),
    },
    config: getAuthHeader(user?.token, user?.actAs),
  });

  if (isLoading || !data) {
    return (
      <Stack spacing={2.5}>
        <Skeleton variant='rectangular' width='100%' height={210} />
      </Stack>
    );
  }

  console.log(user);

  return (
    <Box
      sx={{
        minWidth: '320px',
        maxWidth: '790px',
        mx: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'sticky',
        bottom: 0,
        zIndex: 999,
        width: '100%',
        padding: '1rem',
        background: 'white',
        border: '1px solid #dadce0',
        borderRadius: '8px',
      }}
    >
      <Box sx={{ p: 1, position: 'relative' }}>
        <Typography sx={headingStyles}>Time Off Request</Typography>
        <Divider sx={{ mb: 3 }} />

        <Formik
          initialValues={{
            notes: '',
            days_requested: '',
            type: '',
            employee: {
              id: user?.details?.details?.employee?.id,
              label: user?.details?.details?.employee?.work_email,
            },
            days_off_from: new Date(),
            approved_by: data?.department_heads,
          }}
          validationSchema={Yup.object({
            notes: Yup.string(),
            days_requested: Yup.number().required(),
            type: Yup.mixed(),
            employee: Yup.mixed(),
            days_off_from: Yup.date().required(),
            approved_by: Yup.mixed(),
          })}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setError, setFieldError }
          ) => {
            try {
              setSubmitting(true);

              const { type, employee, approved_by, ...rest } = values;

              if (!type?.id) {
                setFieldError('type', 'This field is required');
                return;
              }

              if (!employee?.id) {
                setFieldError('employee', 'This field is required');
                return;
              }

              await createRecord({
                values: {
                  ...rest,
                  type: type?.id,
                  employee: employee?.id,
                  approved_by: approved_by?.id,
                },
                url: daysOffUrls.list(),
                token: user?.token,
                actAs: user?.actAs,
              });

              resetForm();
              notify('Operation completed', {
                type: 'SUCCESS',
              });
            } catch (err) {
              console.log(err.response || err.message);
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate autoComplete='off'>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ mb: 2 }}
              >
                <Grid item xs={4}>
                  <TextField
                    label='Days Requested'
                    type='number'
                    name='days_requested'
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateTimePickerField
                    showTime
                    label='First Day of Leave'
                    name='days_off_from'
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteFieldV2
                    name='type'
                    label='Type'
                    requestKey='day-off-type'
                    fetchUrl={dayOffTypesUrls.list}
                    urlParams={`&ordering=created_at`}
                    renderRow={(row) => ({
                      id: row?.id,
                      label: `${row?.name}`,
                    })}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField label='Notes' name='notes' multiline rows={3} />
                </Grid>
              </Grid>
              <Box sx={{ mt: 3 }}>
                <Button
                  type='submit'
                  disabled={isSubmitting}
                  sx={{ width: '110px', borderRadius: 3, mr: 1 }}
                  variant='contained'
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
