import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TextField,
  DateTimePickerField,
  CheckBoxField,
} from '@components/Inputs';

export const LinkForm = ({ values } = {}) => {
  return (
    <>
      <Grid item xs={4}>
        <TextField label='Name' name='title' required multiline />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label='Destination url'
          name='destination_url'
          placeholder='https://'
          required
        />
      </Grid>

      <Grid item xs={4}>
        <TextField label='Custom back half' name='custom_back_half' multiline />
      </Grid>
      <Grid item xs={8}>
        <TextField label='Text' name='text' multiline />
      </Grid>

      <Grid item xs={2} sm={4} md={2}>
        <DateTimePickerField label='Expiry Date' name='retire' showTime />
      </Grid>

      <Grid item xs={2} sm={4} md={2}>
        <CheckBoxField
          label='Add UTMs'
          name='addUTMs'
        />
      </Grid>

      {values.addUTMs ? (
        <>
          <Grid item xs={4}>
            <TextField label='Source' name='source' required />
          </Grid>
          <Grid item xs={4}>
            <TextField label='Medium' name='medium' required />
          </Grid>
          <Grid item xs={4}>
            <TextField label='Campaign' name='campaign' required />
          </Grid>
          <Grid item xs={4}>
            <TextField label='Content' name='content' required />
          </Grid>
          <Grid item xs={4}>
            <TextField label='Term' name='term' required />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
              Tip: To create multiple URLs, separate each UTM with a comma
            </Typography>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export const UtmForm = () => (
  <>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Source' name='Source' required />
    </Grid>

    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Medium' name='Medium' required />
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Campaign' name='Campaign' required />
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Term' name='Term' />
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='Content' name='Content' />
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
      <TextField label='UTM Tag' name='UTM_tag' />
    </Grid>
  </>
);
