import * as React from 'react';
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Stack,
  Alert,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { TextField } from '@components/Inputs';
import * as Yup from 'yup';
import { accountUrls, systemUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import axios from 'axios';
import {
  getClientOptions,
  getAuthHeader,
} from '@config/functions/helperFunctions';
import { getUserStorageKey } from '@config/constants';
import { setProfile } from '@redux/profileSlice';
import { useDispatch } from 'react-redux';
import PageWrapper from '@ui/Auth/PageWrapper';
import { Link, useNavigate } from 'react-router-dom';
import { appHost } from '@config/msHosts';

const { loginV2Urls } = accountUrls;
const { clientOptionsUrls } = systemUrls;

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPWD, setShowPWD] = React.useState(false);
  const [error, setError] = React.useState('');

  const updateState = (payload) => {
    const payloadJSON = JSON.stringify(payload);
    localStorage.setItem(getUserStorageKey(), payloadJSON);
    console.log('payload', payload);
    dispatch(setProfile(payload));
    console.log('obi-login-redirect');
    navigate('/');
  };

  React.useEffect(() => {
    if (localStorage.getItem(getUserStorageKey())) {
      console.log('obi-login-redirect');
      window.location.href = '/';
    }
  }, []);

  return (
    <PageWrapper>
      <Stack spacing={3} sx={{ width: '400px', mx: 'auto' }}>
        <Box>
          <Typography
            variant="h2"
            sx={{ fontSize: '28px', fontWeight: '500', mb: 0.5 }}
          >
            Welcome back
          </Typography>
          <Typography variant="p" sx={{ color: 'gray' }}>
            Please enter your details
          </Typography>
        </Box>

        <Box>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={Yup.object({
              username: Yup.string()
                .min(3, 'Invalid username or email')
                .required('Invalid username or email'),
              password: Yup.string()
                .min(8, 'Invalid password')
                .required('Invalid password'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                setError('');

                const host = window.location.host;

                const response = await createRecord({
                  values: {
                    ...values,
                    domain: host.includes('localhost') ? appHost : host,
                  },
                  url: loginV2Urls.list(),
                });
                console.log('Got response back...', Date.now());

                const config = getAuthHeader(response?.data?.auth?.access);

                const clientId =
                  response?.data?.user_details?.client_details?.id;
                const company =
                  response?.data?.user_details?.client_details?.client_company;

                const payload = {
                  token: response?.data?.auth?.access,
                  refreshToken: response?.data?.auth?.refresh,
                  details: {
                    ...response?.data?.user_details,
                    originalClient: company,
                  },
                };

                console.log(
                  clientOptionsUrls.list(`?client_company=${clientId}`),
                  'Get client options...',
                  Date.now()
                );
                const { data: optionsList } = await axios.get(
                  clientOptionsUrls.list(`?_client=${clientId}`),
                  config
                );
                console.log('Got response back...', Date.now());

                if (
                  optionsList &&
                  Array.isArray(optionsList.results) &&
                  optionsList.results.length
                ) {
                  const options = getClientOptions(optionsList.results);
                  updateState({ ...payload, ...options });
                } else {
                  updateState(payload);
                }

                resetForm();
              } catch (err) {
                if (err?.response?.data?.detail) {
                  setError(err.response.data.detail);
                } else {
                  console.log(err.response || err.message);
                  setError('Something went wrong, try reloading');
                }
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Stack spacing={3}>
                  {error ? (
                    <Alert icon={false} severity="error">
                      {error}
                    </Alert>
                  ) : null}

                  <Box>
                    <TextField name="username" label="Username or email" />
                  </Box>
                  <Box>
                    <TextField
                      type={showPWD ? 'text' : 'password'}
                      name="password"
                      label="Password"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={(e) => setShowPWD(e.target.checked)}
                      sx={{ userSelect: 'none' }}
                      label="Show password"
                    />
                    <Link
                      to="/auth/forgot"
                      style={{ textDecoration: 'none', color: 'blue' }}
                    >
                      Forgot password?
                    </Link>
                  </Box>

                  <Box>
                    <Button
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                    >
                      Log in
                    </Button>
                  </Box>

                  <Box sx={{ textAlign: 'center' }}>
                    <Link
                      to="/auth/register"
                      style={{ textDecoration: 'none', color: '#444' }}
                    >
                      Don't have an account?
                    </Link>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </PageWrapper>
  );
}
