import * as React from 'react';
import { Autocomplete, Chip } from '@mui/material';
import MUITextField from '@mui/material/TextField';

export default function MultipleAutocompleteField({
  label,
  options,
  value,
  onChange,
  isError,
  error,
  ...rest
}) {
  return (
    <Autocomplete
      {...rest}
      multiple
      options={options || []}
      value={value}
      onChange={onChange}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            size="small"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <MUITextField
          {...params}
          error={isError}
          helperText={error}
          variant="outlined"
          fullWidth
          size="small"
          label={label}
          autoComplete="off"
        />
      )}
    />
  );
}
