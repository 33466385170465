import React from 'react';
import { Box, Button } from '@mui/material';

export default function ViewFormActions({
  lastGroups,
  setLastGroups,
  setCurrentGroup,
  lastQuestions,
  setLastQuestions,
  setCurrentQuestion,
  isSubmitting,
  onContinue,
  showSaveDraft,
  handleSaveDraft,
  isSavingDraft,
}) {
  return (
    <Box
      sx={{
        minWidth: '320px',
        maxWidth: '790px',
        mx: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'sticky',
        bottom: 0,
        zIndex: 999,
        width: '100%',
        padding: '1rem',
        background: 'white',
        border: '1px solid #dadce0',
        borderRadius: '8px',
      }}
    >
      <Button
        type='button'
        variant='outlined'
        onClick={() => {
          if (lastGroups.length > 0) {
            const prevGroup = lastGroups.pop();
            setLastGroups([...lastGroups]);
            setCurrentGroup(prevGroup);
          }

          if (lastQuestions.length > 0) {
            const prevQuestion = lastQuestions.pop();
            setLastQuestions([...lastQuestions]);
            setCurrentQuestion(prevQuestion);
          }
        }}
        disableElevation
        sx={{ width: '90px', borderRadius: 3, mr: 2 }}
      >
        Back
      </Button>

      <Box>
        {showSaveDraft ? (
          <Button
            type='button'
            variant='outlined'
            disableElevation
            disabled={isSavingDraft}
            onClick={handleSaveDraft}
            sx={{ width: '110px', borderRadius: 3, mr: 1 }}
          >
            {isSavingDraft ? 'Saving...' : 'Save Draft'}
          </Button>
        ) : null}

        <Button
          type='button'
          variant='contained'
          disableElevation
          disabled={isSubmitting}
          onClick={onContinue}
          sx={{ width: '100px', borderRadius: 3 }}
        >
          {isSubmitting ? 'Submitting...' : 'Continue'}
        </Button>
      </Box>
    </Box>
  );
}
