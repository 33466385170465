import { appHost } from '@config/msHosts';


export const doWhatRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  created_at: r.created_at,
});

export const inasRowsMap = (row) => ({
  id: row?.id,
  name: row?.name,
  notes: row?.ina_notes,
  progress: row?.progress,
  reminder_start_datetime: row?.details && row?.details?.event?.start_date_time,
  responsible:
    row?.details && row?.details.responsible
      ? { id: row?.responsible, label: row?.details.responsible.work_email }
      : null,

  do_what:
    row?.details && row?.details.do_what
      ? { id: row?.do_what, label: row?.details.do_what.name }
      : null,
  created_at: row?.created_at,
});

export const batchReminderRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  start_date_time: r.start_date_time,
  timezone: r.timezone,
  // rrule: 'daily',
  rrule: r?.rrule,
  created_at: r.created_at,
});

export const calendarHandleRow = (c) => ({
  id: c.id,
  name: c.name,
  timezone: c.timezone,
  sync: c.sync,
});

export const linkHandleRow = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  link: `${appHost}/slots/${c.id}`,
  shortened_url: c.shortened_url,
  created_at: c.created_at,
});

export const linkRecipientsRowMap = (row) => ({
  id: row.id,
  first_name: row.first_name,
  last_name: row.last_name,
  email: row.email,
  person: row?.details?.person
    ? {
      id: row?.person,
      label: `${row?.details.person?.email ?? ''}`,
    }
    : null,
  mail_template: row?.details?.mail_template
    ? {
      id: row?.mail_template,
      label: `${row?.details.mail_template?.name ?? ''}`,
      contextFields: row?.details?.mail_template?.details?.context_fields,
    }
    : null,
  created_at: row.created_at,
});


export const linkSlotHandleRow = (c) => ({
  id: c.id,
  slot:
    c && c.slot_details ? { id: c.slot, label: c.slot_details.title } : null,
  start_date_time:
    c && c.slot_details ? new Date(c.slot_details.start_date_time) : null,
  end_date_time:
    c && c.slot_details ? new Date(c.slot_details.end_date_time) : null,
});
