import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailCard from '@components/core/DetailCard';
import DetailPageHeader from '@components/DetailPageHeader';
import { communicationUrls } from '@config/routes';
import { imapAccountMeta } from '@config/meta/communication';
import AuthWrapper from '@components/Communication/AuthWrapper';
import { useQueryClient } from 'react-query';


const { imapAccountsUrls } = communicationUrls;

function ImapAccountDetails() {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const queryClient = useQueryClient();


  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) => imapAccountMeta({ data, queryClient }),
    []
  );

  return (
    <>
      <Box>
        {recordData ? (
          <Box>
            <DetailPageHeader
              items={[
                { to: `/imap-accounts`, text: 'Imap Accounts' },
                { text: recordData && recordData.username },
              ]}
            ></DetailPageHeader>
          </Box>
        ) : null}

        <DetailCard
          urls={imapAccountsUrls}
          recordId={recordId}
          getSchema={getSchema}
          setData={setRecordData}
        />
      </Box>
    </>
  );
}

export default function AuthenticatedImapAccountDetails(props) {
  return (
    <AuthWrapper>
      <ImapAccountDetails {...props} />
    </AuthWrapper>
  );
}
