import { pmHost, accountsHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  cloneSystemTemplates: 'clone-system-templates',
  sprintMetaProjects: 'sprint-meta-projects',
  programs: 'programs',
  projects: 'projects',
  personas: 'personas',
  hlrs: 'hlrs',
  backlogs: 'backlogs',
  uats: 'acceptance-tests',
  resources: 'resources',
  testsConducted: 'test-conducteds',
  featureRequests: 'feature-requests',
  bugs: 'bugs',
  uatStages: 'uats-stages',
  sprintMeta: 'sprint-metas',
  sprints: 'sprints',
  sprintTasks: 'sprint-tasks',
  bulkCreateSprintTasks: 'bulk-create-sprint-tasks',
  bulkCreateSprintBugs: 'bulk-create-sprint-bugs',
  tasks: 'tasks',
  taskStatus: 'task-status',
  bugComments: 'bug-comments',
  bugStages: 'bug-stages',
  taskTypes: 'task-types',
  taskBugs: 'task-bugs',
  taskBacklogs: 'task-backlogs',
  updateUATsStatus: 'update-project-uats',
  taskStages: 'task-stages',
  sprintStages: 'sprint-stages',
  backlogArtifacts: 'backlog-artifacts',
  userAccounts: 'accounts/users',
  duplicateProject: 'duplicate/project',
  taskComments: 'task-comments',
  stakeholders: 'stakeholders',
  roles: 'roles',
  inas: 'inas',
  inaStages: 'ina-stages',
  workCodes: 'work-codes',
  timeSheets: 'time-sheets',
  workTimes: 'working-times',
  meProjectTaskStages: 'me-project-task-stages',
  bulkCreateStages: 'bulk-create-stages',
  import: 'import',
  export: 'export',
  bulkConnectTasksToSprint: 'bulk-connect-tasks-to-sprint',
  duplicateTask: 'duplicate-task',
};

const duplicateTaskUrls = getModelUrls(pmHost, models.duplicateTask);
const bulkConnectTasksToSprintUrls = getModelUrls(
  pmHost,
  models.bulkConnectTasksToSprint
);
const bulkCreateStagesUrls = getModelUrls(pmHost, models.bulkCreateStages);
const meProjectTaskStagesUrls = getModelUrls(
  pmHost,
  models.meProjectTaskStages
);
const importUrls = getModelUrls(pmHost, models.import);
const exportUrls = getModelUrls(pmHost, models.export);
const workTimesUrls = getModelUrls(pmHost, models.workTimes);
const timeSheetsUrls = getModelUrls(pmHost, models.timeSheets);
const workCodesUrls = getModelUrls(pmHost, models.workCodes);
const programsUrls = getModelUrls(pmHost, models.programs);
const cloneSystemTemplatesUrls = getModelUrls(
  pmHost,
  models.cloneSystemTemplates
);
const sprintMetaProjectsUrls = getModelUrls(pmHost, models.sprintMetaProjects);
const projectsUrls = getModelUrls(pmHost, models.projects);
const personasUrls = getModelUrls(pmHost, models.personas);
const hlrsUrls = getModelUrls(pmHost, models.hlrs);
const backlogsUrls = getModelUrls(pmHost, models.backlogs);
const uatsUrls = getModelUrls(pmHost, models.uats);
const resourcesUrls = getModelUrls(pmHost, models.resources);
const testsConductedUrls = getModelUrls(pmHost, models.testsConducted);
const featureRequestsUrls = getModelUrls(pmHost, models.featureRequests);
const bugsUrls = getModelUrls(pmHost, models.bugs);
const uatStagesUrls = getModelUrls(pmHost, models.uatStages);
const sprintMetaUrls = getModelUrls(pmHost, models.sprintMeta);
const sprintsUrls = getModelUrls(pmHost, models.sprints);
const sprintTasksUrls = getModelUrls(pmHost, models.sprintTasks);
const bulkCreateSprintTasksUrls = getModelUrls(
  pmHost,
  models.bulkCreateSprintTasks
);
const bulkCreateSprintBugsUrls = getModelUrls(
  pmHost,
  models.bulkCreateSprintBugs
);
const tasksUrls = getModelUrls(pmHost, models.tasks);
const taskStatusUrls = getModelUrls(pmHost, models.taskStatus);
const bugCommentsUrls = getModelUrls(pmHost, models.bugComments);
const bugStagesUrls = getModelUrls(pmHost, models.bugStages);
const taskTypesUrls = getModelUrls(pmHost, models.taskTypes);
const taskBacklogsUrls = getModelUrls(pmHost, models.taskBacklogs);
const updateUATsStatusUrls = getModelUrls(pmHost, models.updateUATsStatus);
const taskStagesUrls = getModelUrls(pmHost, models.taskStages);
const sprintStagesUrls = getModelUrls(pmHost, models.sprintStages);
const userAccountsUrls = getModelUrls(accountsHost, models.userAccounts);
const duplicateProjectUrls = getModelUrls(pmHost, models.duplicateProject);
const backlogArtifactsUrls = getModelUrls(pmHost, models.backlogArtifacts);
const taskBugsUrls = getModelUrls(pmHost, models.taskBugs);
const taskCommentsUrls = getModelUrls(pmHost, models.taskComments);
const stakeholdersUrls = getModelUrls(pmHost, models.stakeholders);
const rolesUrls = getModelUrls(pmHost, models.roles);
const inasUrls = getModelUrls(pmHost, models.inas);
const inaStagesUrls = getModelUrls(pmHost, models.inaStages);

const urls = {
  importUrls,
  exportUrls,
  cloneSystemTemplatesUrls,
  inasUrls,
  inaStagesUrls,
  sprintMetaProjectsUrls,
  programsUrls,
  projectsUrls,
  personasUrls,
  hlrsUrls,
  backlogsUrls,
  uatsUrls,
  resourcesUrls,
  testsConductedUrls,
  featureRequestsUrls,
  bugsUrls,
  uatStagesUrls,
  sprintMetaUrls,
  sprintsUrls,
  sprintTasksUrls,
  bulkCreateSprintTasksUrls,
  bulkCreateSprintBugsUrls,
  tasksUrls,
  taskStatusUrls,
  bugCommentsUrls,
  bugStagesUrls,
  taskTypesUrls,
  taskBacklogsUrls,
  updateUATsStatusUrls,
  taskStagesUrls,
  sprintStagesUrls,
  userAccountsUrls,
  duplicateProjectUrls,
  backlogArtifactsUrls,
  taskBugsUrls,
  taskCommentsUrls,
  stakeholdersUrls,
  rolesUrls,
  workCodesUrls,
  timeSheetsUrls,
  workTimesUrls,
  meProjectTaskStagesUrls,
  bulkCreateStagesUrls,
  bulkConnectTasksToSprintUrls,
  duplicateTaskUrls,
};

export default urls;
