import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { inventoryUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import DetailAccordionView from '@components/DetailAccordionView';
import { createRecord } from '@config/functions/requests';
import DetailCard from '@components/core/DetailCard';
import useToast from '@hooks/useToast';
import { InventoryTransactionForm } from '@config/forms/inventory';
import { inventoryMeta } from '@config/meta/inventory';
import DetailPageHeader from '@components/DetailPageHeader';
import { microservices } from '@config/constants';
import { inventoryTransactionRowMap } from '@config/handleRows/inventory';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { inventorysUrls, inventoryTransactionsUrls } = inventoryUrls;

export default function InventoryDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const [notify] = useToast();

  const getSchema = React.useCallback(
    (data) => inventoryMeta({ data, queryClient }),
    []
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            key="detail-view-header"
            items={[
              { to: `/products`, text: 'Product' },
              {
                to: `/products/${recordData.product}`,
                text:
                  recordData.details.product && recordData.details.product.name,
              },
              {
                text: recordData && recordData.location_in_warehouse,
              },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        urls={inventorysUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />
      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          microservice={microservices.INVENTORY.name}
          model={microservices.INVENTORY.models.inventoryTransaction}
          columnKey="inventoryTransactioInventory"
          label="Inventory Transactions"
          prefix="0dn"
          labels={labels}
          editMode={editMode}
          urls={inventoryTransactionsUrls}
          addNewForm={{
            getFields: () => InventoryTransactionForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError
            ) => {
              try {
                const response = await createRecord({
                  values: {
                    ...values,
                    inventory: recordId,
                  },
                  url: inventoryTransactionsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
                return response?.data?.id;
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={inventoryTransactionRowMap}
          urlParams={`&inventory=${recordId}`}
        />
      </Box>
    </Box>
  );
}
