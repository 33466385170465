import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import {
  TextField,
  DateTimePickerField,
  CheckBoxField,
} from '@components/Inputs';
import { bpaUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
// import { formatToUTCDateTime } from '@config/functions/helperFunctions';
import { useQueryClient } from 'react-query';

const { bulkTriggerUrls } = bpaUrls;

export default function CaptureDataPopup({
  open,
  setOpen,
  actionParams,
  workflowId,
  user,
  workflowInstances = [],
  manualInstructions,
}) {
  const [notify] = useToast();
  const queryClient = useQueryClient();
  const [manualAction] = actionParams;
  const questions = [...(manualAction?.shape_action_parameter_values ?? [])];

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      onKeyDown={(e) => e.stopPropagation()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent maxWidth="sm" fullWidth>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Perform Manual Task
        </Typography>

        <p
          style={{ marginBottom: '18px' }}
          dangerouslySetInnerHTML={{
            __html: manualInstructions,
          }}
        />

        <Formik
          initialValues={{}}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              const payload = values;

              if (!Object.keys(values).length) {
                questions.map(({ key, type }) => {
                  const initialValue =
                    type === 'Boolean' ? false : type === 'Decimal' ? 0.0 : '';
                  payload[key] = initialValue;
                });
              }

              const createdByExists = questions.find(
                ({ key }) => key === 'created_by'
              );

              if (createdByExists) {
                payload.created_by = user?.details?.id;
              }

              await createRecord({
                values: {
                  workflow: workflowId,
                  manual: true,
                  payload,
                  workflow_instances: [...workflowInstances],
                },
                url: bulkTriggerUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });
              queryClient.invalidateQueries(['bpa-board']);
              notify('Operation completed', {
                type: 'SUCCESS',
              });
              resetForm();
              setOpen(false);
            } catch (error) {
              console.log(error?.response?.data ?? error?.message);
              notify('There was an error, please refresh the page', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Grid container spacing={2}>
                {questions
                  .sort((a, b) => a?.order - b?.order)
                  .filter(({ key }) => key !== 'created_by')
                  .map(({ id, key, label, type }, index) => (
                    <Grid item key={id} xs={12}>
                      {type === 'Boolean' ? null : (
                        <Typography sx={{ mb: 1 }}>
                          {index + 1}. {label}
                        </Typography>
                      )}

                      {type === 'Decimal' ? (
                        <TextField fullWidth name={key} type="number" />
                      ) : type === 'Date' ? (
                        <Box sx={{ maxWidth: '90px' }}>
                          <DateTimePickerField name={key} />
                        </Box>
                      ) : type === 'Boolean' ? (
                        <CheckBoxField name={key} label={label} />
                      ) : (
                        <TextField
                          fullWidth
                          name={key}
                          multiline
                          minRows={type === 'Multiline' ? 3 : undefined}
                        />
                      )}
                    </Grid>
                  ))}
              </Grid>

              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
